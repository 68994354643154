import React from "react";
import styled from "styled-components";

import EntityRow from "./EntityRow";

const Wrapper = styled.div`
  margin-left: 24px;
`;

const ChildrenItems = ({
  id,
  items,
  onExpand,
  onCollapse,
  onUpdate,
  nodeSet,
  node,
  parentDataComponentId,
}) => {
  return items.map(({ name, entityName, relationName, endpoint }, index) => (
    <Wrapper key={`${id}-${entityName}-${index}`}>
      <EntityRow
        id={entityName}
        root={node}
        name={name}
        entityName={entityName}
        relationName={relationName}
        endpoint={endpoint}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onUpdate={onUpdate}
        nodeSet={nodeSet}
        properties={node.children[entityName]?.properties}
        childNodes={node.children[entityName]?.children}
        parentDataComponentId={parentDataComponentId}
      />
    </Wrapper>
  ));
};

export default ChildrenItems;
