import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Loader from "../../../../ui/Loader";
import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";
import { StyledCircularProgress } from "../../../../ui/BWActionButton";

export const FeeActions = ({
  projectId,
  generateAndDownloadReport,
  loading,
}) => {
  const options = [
    {
      content: "Preview Fee Sheet",
      onClick: () => {
        generateAndDownloadReport({
          format: "pdf",
          showPreview: true,
          projectId,
          templateName: "fee-sheet",
        });
      },
    },
    {
      content: "Print Fee Sheet",
      onClick: () => {
        generateAndDownloadReport({
          format: "pdf",
          projectId,
          templateName: "fee-sheet",
        });
      },
    },
  ];
  return (
    <Loader width="300px">
      {loading ? (
        <StyledCircularProgress size={20} />
      ) : (
        <DropdownMenu buttonText="Actions" options={options} />
      )}
    </Loader>
  );
};

FeeActions.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  generateAndDownloadReport: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    loading:
      state.reports.loaders["fee-sheet-pdf-true"] ||
      state.reports.loaders["fee-sheet-pdf-false"],
  };
};

export default connect(mapStateToProps)(FeeActions);
