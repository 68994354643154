import React, { useMemo, useCallback, useState } from "react";
import BWModels from "benjaminwest-models";

import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import styled from "styled-components";

import propTypes from "../../../../../../constants/propTypes";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import BWGridLocal from "../../../../../ui/BWGrid/BWGridLocal";
import GridHeader from "../../../../../ui/BWGrid/GridHeader";
import GridTable from "../../../../../ui/BWGrid/GridTable";
import PopoverVoidConfirmation from "./PopoverVoidConfirmation";
import { statusMap, Pending } from "../components";
import { SubHeader, Header } from "./components";
import Date from "../../../../../ui/BWGrid/columns/Date";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import { PRIMARY_DATE_FORMAT } from "../../../../../../constants/formats";

const Wrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

export const formatWithNegatives = (amount, formatter) => {
  const formattedAmount = formatter.format(Math.abs(amount));
  return amount < 0 ? `(${formattedAmount})` : formattedAmount;
};

const columns = ["id", "amount", "type", "receivedAt", "paymentRecord"];
export const getColumnsOptions = formatter => ({
  id: {
    title: "#",
    bold: true,
  },
  amount: {
    title: "Amount",
    bold: true,
    render: ({ amount }) => {
      return formatWithNegatives(amount, formatter);
    },
  },
  type: {
    title: "Type",
    filter: "select",
    filterOptions: {
      options: BWModels.loadSchema("FundingPayment").__utils.types,
    },
  },
  receivedAt: {
    title: "Payment Received Date",
    render: ({ receivedAt }) =>
      receivedAt && <Date date={receivedAt} format={PRIMARY_DATE_FORMAT} />,
  },
  paymentRecord: {
    fill: true,
    title: "Record Number",
  },
});

const FundingPayments = ({
  rows,
  funding,
  dataComponentId,
  onVoidFundingRequest,
  number,
  toggleShowList,
  showVoid,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const headerActions = useMemo(
    () => [
      {
        text: "ADD Payment",
        icon: <AddCircle />,
        handler: toggleShowList,
        disableIfProjectClosed: true,
      },
    ],
    [toggleShowList]
  );

  const { projectCurrency } = funding;

  const formatter = useCurrencyFormatter(projectCurrency?.currency);

  const handleOpenVoidConfirmation = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );

  const handleCloseVoidConfirmation = useCallback(() => setAnchorEl(null), []);
  const handleVoid = useCallback(() => {
    handleCloseVoidConfirmation();
    onVoidFundingRequest();
  }, [handleCloseVoidConfirmation, onVoidFundingRequest]);

  const { gridConfig, columnOptions } = useMemo(() => {
    return {
      gridConfig: { pageSize: 0, totalRows: rows.length },
      columnOptions: getColumnsOptions(formatter),
    };
  }, [rows, formatter]);

  const StatusWrapper = statusMap[funding.status] || Pending;
  const balance = funding.amountRequested - funding.totalPayment;

  return (
    <Wrapper>
      <BWGridLocal
        gridConfig={gridConfig}
        rows={rows}
        emptyStateText="No Payments Recorded"
        noBorder
      >
        <GridHeader
          headerOverride={
            <div>
              <Header>{`Balance ${formatter.format(balance)}`}</Header>
              <SubHeader>
                Status <StatusWrapper>{funding.status}</StatusWrapper>
              </SubHeader>
            </div>
          }
          actions={funding.status != "Paid" ? headerActions : []}
          isMultiline
        />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
      <ActionButtons
        isModal
        hideCancelButton
        hideDangerButton={!showVoid}
        dangerButtonText="Void Funding Request"
        onDanger={handleOpenVoidConfirmation}
        listeners={[dataComponentId]}
        alignRight
        additionalProps={{ danger: { disableIfProjectClosed: true } }}
      />

      <PopoverVoidConfirmation
        title={number}
        anchorEl={anchorEl}
        onVoid={handleVoid}
        onClose={handleCloseVoidConfirmation}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      />
    </Wrapper>
  );
};

FundingPayments.defaultProps = {
  rows: [],
  funding: {},
};

FundingPayments.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contingency),
  funding: propTypes.funding,
  dataComponentId: PropTypes.string,
  onVoidFundingRequest: PropTypes.func,
  number: PropTypes.string,
  toggleShowList: PropTypes.func,
  showVoid: PropTypes.bool,
};

export default FundingPayments;
