import { connect } from "react-redux";
import AccountingPage from "./AccountingPage";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
};

export const mapStateToProps = state => {
  return {
    role: state.auth.role,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPage);
