import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import get from "lodash.get";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import { consultingDCId } from "../../../../inputs/inputConfigs/projects";
import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import useActions from "../../../../hooks/useActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import Loader from "../../../../ui/Loader";
import { isPOChangeDisabled } from "../../../PurchaseOrderDetail/utils";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import RegularText from "../../../../ui/Typography/RegularText";
import {
  destroyDataComponentResource,
  initDataComponent,
  performCreateRequest,
} from "../../../../../actions/dataComponentActions";
import { useListPurchaseOrders } from "./useListPurchaseOrders";
import { dataComponentId } from "../../../PurchaseOrderDetail/connect";

const projectDesignerDataComponentId = "ProjectDesigner";

export const Message = styled(RegularText)`
  padding: 24px 24px 0 24px;
`;

export const purchaseOrdersBulkDesignerIdDCId =
  "purchase-orders-bulk-designerId";
export const designerPurchaseOrderDataComponentId = "designer-purchase-orders";

const getMessage = (
  consultingCompanies,
  originalDesignerId,
  newDesignerId,
  isReplacing
) => {
  const originalDesignerName = get(
    consultingCompanies.find(({ id }) => id == originalDesignerId),
    "name"
  );
  const newDesignerName = get(
    consultingCompanies.find(({ id }) => id == newDesignerId),
    "name"
  );

  return isReplacing
    ? `Are you sure you want to replace ${originalDesignerName} from the project with ${newDesignerName}?`
    : `Are you sure you want to remove ${originalDesignerName} from the project?`;
};

// eslint-disable-next-line max-lines-per-function
export const DesignerConfirmationModal = ({
  projectId,
  originalDesignerId,
  newDesignerId,
  isReplacing,
  onConfirm,
}) => {
  const actions = useActions({
    initDataComponent,
    performCreateRequest,
    closeModalDialog,
    showSnackNotificationAction,
    destroyDataComponentResource,
  });

  useEffect(() => {
    actions.initDataComponent(
      purchaseOrdersBulkDesignerIdDCId,
      PurchaseOrder,
      [],
      "purchase-orders"
    );
  }, [actions]);

  const consultingCompanies = useV2DatacomponentResources(
    consultingDCId,
    [],
    RequestTypes.LIST
  );

  const message = getMessage(
    consultingCompanies,
    originalDesignerId,
    newDesignerId,
    isReplacing
  );

  const { isLoading, data: purchaseOrders } = useListPurchaseOrders(
    projectId,
    originalDesignerId
  );

  const fields = useMemo(
    () => ({
      groups: [
        {
          items: purchaseOrders.map((purchaseOrder, index) => ({
            input: {
              InputComponent: CheckboxWithError,
              label: `${purchaseOrder.number} (${purchaseOrder.status}${
                purchaseOrder.revisionStatus
                  ? ` - CO ${purchaseOrder.revisionStatus}`
                  : ""
              })`,
              name: `purchaseOrders[${index}]`,
              disabled: isPOChangeDisabled(purchaseOrder),
            },
            grid: { xs: 12 },
          })),
        },
      ],
    }),
    [purchaseOrders]
  );

  const handleSubmit = useCallback(
    ({ purchaseOrders: formPurchaseOrders }) => {
      const updatedPurchaseOrders = purchaseOrders
        .filter((_, index) => formPurchaseOrders[index])
        .map(({ id }) => ({ id, designerId: newDesignerId || null }));
      actions.performCreateRequest(
        purchaseOrdersBulkDesignerIdDCId,
        updatedPurchaseOrders
      );
      onConfirm();
      actions.closeModalDialog();

      actions.destroyDataComponentResource(
        projectDesignerDataComponentId,
        RequestTypes.FIND
      );
      actions.destroyDataComponentResource(dataComponentId, RequestTypes.FIND);
    },
    [actions, newDesignerId, onConfirm, purchaseOrders]
  );

  return (
    <FormikForm
      initialValues={{
        purchaseOrders: purchaseOrders.map(
          purchaseOrder => !isPOChangeDisabled(purchaseOrder)
        ),
      }}
      onSubmit={handleSubmit}
      ignoreCache
      enableReinitialize
    >
      {({ handleSubmit, ...formikProps }) => {
        return (
          <Fragment>
            <Message
              style={{
                paddingBottom: isEmpty(
                  get(formikProps, "values.purchaseOrders")
                )
                  ? "24px"
                  : "0",
              }}
            >
              {message}
            </Message>
            <Loader loading={isLoading}>
              {!isEmpty(get(formikProps, "values.purchaseOrders")) && (
                <Fragment>
                  <Message>
                    {isReplacing ? "Replace" : "Remove"} Designer from all
                    selected POs below:
                  </Message>
                  <FormGrid fields={fields} {...formikProps} />
                </Fragment>
              )}
            </Loader>
            <ActionButtons
              sendButtonText="Confirm"
              onSend={handleSubmit}
              listeners={[
                purchaseOrdersBulkDesignerIdDCId,
                designerPurchaseOrderDataComponentId,
              ]}
              isModal
            />
          </Fragment>
        );
      }}
    </FormikForm>
  );
};
