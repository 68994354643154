import React from "react";
import styled from "styled-components";

import { StyledRow } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/BWTableRow";
import useErrorImportStore from "../../../../hooks/useErrorImportStore";
import shallow from "zustand/shallow";

const Row = styled(({ isInvalidRow, ...props }) => <StyledRow {...props} />)`
  background: ${({ isInvalidRow }) => isInvalidRow && "rgba(255, 23, 68, 0.1)"};
`;

const RowComponent = props => {
  const { row } = props;
  const [isInvalidRow] = useErrorImportStore(
    state => [state.actions.isInvalidRow, state.ignoreErrors], //Doing this force to the component to be re-rendered when the ignoreError flag changes
    shallow
  );

  const isInvalid = isInvalidRow(row.id);

  return <Row {...props} isInvalidRow={isInvalid} />;
};

export default RowComponent;
