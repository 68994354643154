import React from "react";

import { Cell } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";
import PropTypes from "prop-types";
import styled from "styled-components";
import useErrorImportStore from "../../../../hooks/useErrorImportStore";
import shallow from "zustand/shallow";

const column = {};

const Row = styled.tr`
  background: ${({ isInvalidRow }) => isInvalidRow && "rgba(255, 23, 68, 0.1)"};
`;

function WrapperDetailRow({ children, row }) {
  const [isInvalidRow] = useErrorImportStore(
    state => [state.actions.isInvalidRow, state.ignoreErrors], //Doing this force to the component to be re-rendered when the ignoreError flag changes
    shallow
  );

  const isInvalid = isInvalidRow(row.id);

  const prevSpan = 2;
  const posSpan = children.length - 8 - prevSpan;
  return (
    <Row isInvalidRow={isInvalid}>
      <Cell column={column} colSpan={prevSpan} />
      {children}
      <Cell column={column} colSpan={posSpan} />
    </Row>
  );
}
WrapperDetailRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  row: PropTypes.shape({}),
};

export default WrapperDetailRow;
