import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";

import PopoverDeleteConfirmation from "../../../../ui/PopoverDeleteConfirmation";
import propTypes from "../../../../../constants/propTypes";
import { IconButton } from "../../../../ui/Grid/utilComponents/dataTypeProviders/ActionIconsProvider";

const DeleteSubmittalButton = ({ submittalFile, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );

  const handleDelete = useCallback(() => {
    onSubmit(submittalFile);
    handleClose();
  }, [handleClose, submittalFile, onSubmit]);

  return (
    <Fragment>
      <Tooltip title="Delete">
        <IconButton onClick={handleOpen}>
          <Delete />
        </IconButton>
      </Tooltip>
      <PopoverDeleteConfirmation
        title={submittalFile.file?.filename}
        anchorEl={anchorEl}
        onDelete={handleDelete}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </Fragment>
  );
};

DeleteSubmittalButton.propTypes = {
  submittalFile: propTypes.submittalFile,
  onSubmit: PropTypes.func.isRequired,
};

export default DeleteSubmittalButton;
