import React from "react";
import PropTypes from "prop-types";

import { GridLocalSection } from "../../../layout/GridLocalSection";
import propTypes from "../../../../constants/propTypes";
import { renderRequirementStatus } from "../../SubmittalsManager/RequirementsGrid/Grid";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";
import { useLabeledText } from "../../../inputs/TextInputWithError";
import { LabeledTextContext } from "../../../../withPORevision/withLabeledTextOption";
import { withSelectedRows } from "../../../ui/BWGrid";
import { RequirementsSectionActions } from "./RequirementsSectionActions";

const columns = ["requirement.name", "files", "submittalApprovedId"];
const columnsOptions = {
  "requirement.name": { title: "Name", sortingEnabled: false, bold: true },
  files: { sortingEnabled: false },
  submittalApprovedId: {
    title: "Status",
    sortingEnabled: false,
    render: renderRequirementStatus,
  },
};
const rowMenu = (
  onOpenEditModal,
  onOpenDeleteModal,
  onOpenReviewSubmittalsModal
) => [
  { text: "Edit", onClick: onOpenEditModal },
  { text: "Review Submittals", onClick: onOpenReviewSubmittalsModal },
  { separator: true },
  { text: "Delete", onClick: onOpenDeleteModal },
];

const RequirementsSection = ({
  rows,
  onOpenAddModal,
  onOpenEditModal,
  onOpenDeleteModal,
  onOpenReviewSubmittalsModal,
  loading,
  role,
  specId,
}) => {
  const isProjectClosed = useIsProjectClosed();
  const isLabeled = useLabeledText(!isProjectClosed);
  const rowMenuList =
    isLabeled || role.reference === "vendor-portal"
      ? undefined
      : rowMenu(
          onOpenEditModal,
          onOpenDeleteModal,
          onOpenReviewSubmittalsModal
        );

  return (
    <LabeledTextContext.Provider value={isLabeled}>
      <GridLocalSection
        id="requirements-section"
        columns={columns}
        columnsOptions={columnsOptions}
        rowMenu={rowMenuList}
        gridConfig={{ pageSize: 0, totalRows: rows.length }}
        rows={rows}
        addButtonText={
          role?.reference === "vendor-portal" ? null : "ADD REQUIREMENT"
        }
        gridSubheaderText="Requirements and Submittals"
        addClick={onOpenAddModal}
        revisionReference="specRequirements"
        isLoading={loading}
        headerOverride={
          <RequirementsSectionActions rows={rows} specId={specId} />
        }
        showSelectionColumn
        showSelectAll
      />
    </LabeledTextContext.Provider>
  );
};
RequirementsSection.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contingency),
  role: PropTypes.shape({}),
  onOpenAddModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  onOpenReviewSubmittalsModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  specId: PropTypes.string,
};

export default withSelectedRows(RequirementsSection);
