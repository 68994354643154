import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ReportsLayout from "./ReportsLayout";
import { generateAndDownloadReport } from "../../../actions/reportsActions";
import propTypes from "../../../constants/propTypes";
import { usePerformRetrieveList } from "../../hooks/usePerformRetrieveList";
import ProjectReport from "../../../models/ProjectReport";
import { LoaderContext } from "../../ui/Loader";

export const ReportsContainer = ({
  projectId,
  generateAndDownloadReport,
  reportsLoader,
}) => {
  const [projectReportCategoryId, setProjectReportCategoryId] = useState("-1");

  const handleDownloadReport = (
    { id, templateName },
    format,
    showPreview = false
  ) => {
    generateAndDownloadReport({
      id,
      format,
      projectId,
      showPreview,
      templateName,
    });
  };
  const handleChangeReportCategoryId = projectReportCategoryId => {
    setProjectReportCategoryId(projectReportCategoryId);
  };
  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [
        { columnName: "projectReportCategoryId", direction: "asc" },
        { columnName: "sequenceIndex", direction: "asc" },
      ],
    }),
    []
  );
  const dataComponent = useMemo(
    () => ({
      dataComponentId: "projectReportsDataComponentId",
      model: ProjectReport,
      includes: ["projectReportCategory"],
      apiRoute: "project-reports",
    }),
    []
  );
  const { isLoading, data: projectReports = [] } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const reportCategories = useMemo(
    () =>
      Object.values(
        projectReports.reduce(
          (map, { projectReportCategory }) => ({
            ...map,
            [projectReportCategory.id]: projectReportCategory,
          }),
          {}
        )
      ),
    [projectReports]
  );

  const filteredReports = useMemo(
    () =>
      projectReportCategoryId !== "-1"
        ? projectReports.filter(
            projectReport =>
              projectReport.projectReportCategoryId === projectReportCategoryId
          )
        : projectReports,
    [projectReports, projectReportCategoryId]
  );

  const totalReports = filteredReports.length;

  return (
    <LoaderContext.Provider value={{ loading: isLoading }}>
      <ReportsLayout
        totalReports={totalReports}
        projectReports={filteredReports}
        reportsLoader={reportsLoader}
        reportCategories={reportCategories}
        projectReportCategoryId={projectReportCategoryId}
        handleDownloadReport={handleDownloadReport}
        handleChangeReportCategoryId={handleChangeReportCategoryId}
        isLoading={isLoading}
      />
    </LoaderContext.Provider>
  );
};

ReportsContainer.propTypes = {
  projectId: PropTypes.string,
  generateAndDownloadReport: PropTypes.func,
  reportsLoader: propTypes.reportsLoader,
};

export const mapStateToProps = state => {
  return {
    reportsLoader: state.reports.loaders,
  };
};

const mapDispatchToProps = {
  generateAndDownloadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
