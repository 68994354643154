import React, { useEffect, useState } from "react";
import AreasSectionContainer from "../../ProjectCreate/AreasSection/AreasSectionContainer";
import {
  initDataComponent,
  performFindRequest,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { connect } from "react-redux";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import Area from "../../../../models/Area";
import AreaType from "../../../../models/AreaType";
import PropTypes from "prop-types";
import {
  getBluechipResourceById,
  getCurrentBluechipResourcesForRequestType,
} from "../../../../utils/bluechipUtils";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";

import FormikForm from "../../../../components/forms/FormikForm";

import {
  onCreateArea,
  onUpdateArea,
  onDeleteArea,
  processRequests,
} from "./AreaFunctions";
import { LoaderContext } from "../../../ui/Loader";

const projectDataComponentId = "ProjectDetail";
const areaDataComponentId = "Areas";
const areaTypesDataComponentId = "select-areaTypes";

const AreaPage = props => {
  const {
    match: { params },
    initDataComponent,
    performRetrieveListRequest,
    performFindRequest,
    performCreateRequest,
    performDeleteRequest,
    performUpdateRequest,
    showSnackNotificationAction,
    closeModalDialog,
    areaDataComponent,
    project,
    areas,
    areaTypes,
    isLoading,
  } = props;

  const [preDataComponent, setPreDataComponent] = useState(null);

  useEffect(() => setPreDataComponent(areaDataComponent), [areaDataComponent]);

  useEffect(() => {
    processRequests(
      preDataComponent,
      areaDataComponent,
      project.id,
      areaDataComponentId,
      {
        performRetrieveListRequest,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );
  }, [
    areaDataComponent,
    preDataComponent,
    project.id,
    performFindRequest,
    showSnackNotificationAction,
    closeModalDialog,
    performRetrieveListRequest,
  ]);

  useEffect(() => {
    initDataComponent(
      areaDataComponentId,
      Area,
      ["areaType", "areaRooms(withOrderBySequenceIndex)"],
      "areas"
    );
    performRetrieveListRequest(areaDataComponentId, {
      rootFilters: {
        $where: { projectId: params.projectId },
      },
      sort: [
        { columnName: "name", direction: "asc" },
        { columnName: "id", direction: "asc" },
      ],
      pageSize: -1,
    });
    initDataComponent(areaTypesDataComponentId, AreaType, [], "area-types");
    performRetrieveListRequest(areaTypesDataComponentId, {
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
    });
  }, [initDataComponent, performRetrieveListRequest, params.projectId]);

  const values = {
    areas: areas,
  };

  return (
    <LoaderContext.Provider value={{ loading: isLoading }}>
      <FormikForm>
        {() => (
          <AreasSectionContainer
            values={values}
            areaTypes={areaTypes}
            onCreateArea={area =>
              onCreateArea(
                area,
                params.projectId,
                areaDataComponent,
                areaDataComponentId,
                performCreateRequest
              )
            }
            onDeleteArea={areaId =>
              onDeleteArea(
                areaId,
                areaDataComponent,
                areaDataComponentId,
                performDeleteRequest
              )
            }
            onUpdateArea={area =>
              onUpdateArea(
                area,
                areaDataComponent,
                areaDataComponentId,
                performUpdateRequest
              )
            }
          />
        )}
      </FormikForm>
    </LoaderContext.Provider>
  );
};

AreaPage.propTypes = {
  match: PropTypes.object.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  areaDataComponent: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  areas: PropTypes.array.isRequired,
  areaTypes: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const dataComponent = getDataComponent(projectDataComponentId, state);
  const areaDataComponent = getDataComponent(areaDataComponentId, state);

  const project = getBluechipResourceById(dataComponent, state, projectId);
  const areaTypes =
    getCurrentBluechipResourcesForRequestType(
      areaTypesDataComponentId,
      state
    ) || [];

  const areas =
    getCurrentBluechipResourcesForRequestType(areaDataComponentId, state) || [];

  const areaDataComponentFlattened = getDataComponentFlattenedRequestState(
    areaDataComponentId,
    state,
    REQUEST_TYPES.LIST
  );

  return {
    role: state.auth.role,
    areaDataComponent,
    project,
    areas,
    areaTypes,
    isLoading: areaDataComponentFlattened.loading,
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  showSnackNotificationAction,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaPage);
