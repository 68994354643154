import React from "react";

import { inputs } from "../../../inputs/inputConfigs";
import { QuantityError } from "../QuantityError";

const getProjectCurrencyInput = (projectId, disabledCurrency) => ({
  ...inputs.projectCurrency,
  isDisabled: disabledCurrency,
  APIOptions: {
    ...inputs.projectCurrency.APIOptions,
    rootFilters: {
      $where: { projectId },
    },
    fields: [
      "project_currencies.id",
      "isDefault",
      "currency.name",
      "conversionRate",
    ],
  },
});

export const commonOptions = (
  projectId,
  onBaseQuantityChange,
  handleAtticStockChange,
  handleOverageChange,
  spec,
  selectedCurrency,
  quantityErrorMessage
) => {
  const disabledCurrency = !!(
    spec?.purchaseOrderId || spec?.isAssignedToBidGroup
  );
  const disabledDueToInvoiceQty = spec.totalQuantityPaid > 0;
  return [
    {
      items: [
        {
          input: {
            ...inputs.customNumber,
            autoFocus: true,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.specCategory,
            isDisabled: disabledDueToInvoiceQty,
          },
          grid: { xs: 8 },
        },
        {
          input: { ...inputs.specDescription },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.area,
            APIOptions: {
              ...inputs.area.APIOptions,
              rootFilters: {
                $where: { projectId },
              },
            },
          },
          grid: { xs: 4 },
        },
        {
          input: inputs.unitOfMeasure,
          grid: { xs: 4 },
        },
        {
          input: getProjectCurrencyInput(projectId, disabledCurrency),
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.priceCents,
            inputProps: {
              maskProps: { allowNegative: true },
              currency: selectedCurrency,
            },
            disabled: disabledDueToInvoiceQty,
          },
          grid: { xs: 4 },
        },
      ],
    },
    {
      items: [
        {
          input: {
            ...inputs.baseQuantity,
            handleChange: onBaseQuantityChange,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.atticStock,
            handleChange: handleAtticStockChange,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.overagePercent,
            handleChange: handleOverageChange,
          },
          grid: { xs: 4 },
        },
        {
          input: inputs.overageValue,
          grid: { xs: 4 },
        },
        {
          input: inputs.totalQuantity,
          grid: { xs: 4 },
        },
        {
          element: <QuantityError errorMessage={quantityErrorMessage} />,
          grid: { xs: 12 },
        },
      ],
    },
    {
      items: [
        {
          input: {
            ...inputs.designerBudget,
            inputProps: {
              maskProps: { allowNegative: true },
              currency: selectedCurrency,
            },
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.designerQuantity,
          },
          grid: { xs: 4 },
        },
      ],
    },
  ];
};

export default ({
  clientId,
  projectId,
  onBaseQuantityChange,
  handleAtticStockChange,
  handleOverageChange,
  selectedCurrency,
  spec,
  isProjectClosed,
  disableVendorField,
}) => {
  return {
    groups: [
      ...commonOptions(
        projectId,
        onBaseQuantityChange,
        handleAtticStockChange,
        handleOverageChange,
        spec,
        selectedCurrency
      ),
      {
        items: [
          {
            input: {
              ...inputs.vendorAutocomplete,
              menuPlacement: "top",
              isDisabled: disableVendorField,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.glCodes,
              dataComponentId: "select-glCodes",
              menuPlacement: "top",
              APIOptions: {
                ...inputs.glCodes.APIOptions,
                rootFilters: {
                  $where: {
                    clientId,
                  },
                },
              },
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.statusComment,
              name: "forecastComment",
              label: "Project Forecast Comment",
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
