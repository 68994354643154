import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";

import propTypes from "../../../../../constants/propTypes";
import { IconButton } from "../../../../ui/Grid/utilComponents/dataTypeProviders/ActionIconsProvider";

const DeleteSubmittalButton = ({ submittalFile, onClick }) => {
  const handleClick = () => onClick(submittalFile);

  return (
    <Tooltip title="Edit">
      <IconButton onClick={handleClick}>
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

DeleteSubmittalButton.propTypes = {
  submittalFile: propTypes.submittalFile,
  onClick: PropTypes.func.isRequired,
};

export default DeleteSubmittalButton;
