import styled from "styled-components";

export function contentExceedsThrershoold(value) {
  const numberOfLineBreaks = value.split("\n").length;
  return (value && value.length > 100) || numberOfLineBreaks > 1;
}

export function allowResize(disabled, value) {
  if (!disabled) return true;
  return contentExceedsThrershoold(value);
}

export const Input = styled.textarea`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  border: none;
  padding: 0;
  display: flex;
  flex: 1;
  resize: vertical;
  width: 100%;
  min-height: 20px;
  height: ${({ value }) =>
    contentExceedsThrershoold(value) ? "60px" : "20px"};
  background: transparent;
  &:disabled {
    background-color: unset;
  }
`;
