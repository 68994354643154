import React, { useCallback } from "react";
import pluralize from "pluralize";
import { useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  quotingBidGroupsDCId,
  refreshBidGroupsList,
  refreshCategoriesList,
  initBidGroupsDC,
  initBidGroupSpecDetail,
  initQuotingCategoriesDC,
  fetchAllBidGroups,
  allBidGroupsDataComponentId,
} from "../../../../actions/quotingActions";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { setReload } from "../../../../actions/dataComponentActions";
import {
  processUpdateRequestStatus,
  processDeleteRequestStatus,
} from "../../../../utils/dataComponentUtils";
import BidGroupTable from "./BidGroupTable";
import BWInfiniteScroll from "../../../ui/BWInfiniteScroll";
import { useDeleteProcessRequests } from "../../../hooks/useProcessRequest";
import { handleError } from "../../BidGroupDetail/Quotes/QuotingRequestHandlers";

export const bidGroupSpecDetailId = "bid-group-details";
export const bidGroupSpecUpdateId = "bid-group-update-id";
const selectSpecCategoriesId = "Quoting-categoriesList";

export const BidGroupListContainer = ({
  bidGroups,
  refreshBidGroupsList,
  fetchAllBidGroups,
  refreshCategoriesList,
  initBidGroupsDC,
  initBidGroupSpecDetail,
  initQuotingCategoriesDC,
  showSnackNotificationAction,
  dataComponent,
  specDataComponent,
  specCategories,
  setReload,
  shouldReload,
  projectId,
  openModalDialog,
  onClearFilter,
  filters,
}) => {
  useEffect(() => {
    initBidGroupsDC();
    initBidGroupSpecDetail();
    initQuotingCategoriesDC();
    refreshBidGroupsList(projectId)(0);
    refreshCategoriesList(projectId);
    fetchAllBidGroups(projectId);
    onClearFilter();
  }, [
    initBidGroupsDC,
    initBidGroupSpecDetail,
    refreshBidGroupsList,
    refreshCategoriesList,
    projectId,
    onClearFilter,
    initQuotingCategoriesDC,
    fetchAllBidGroups,
  ]);
  const prevSpecDataComponentRef = useRef();

  const onSuccess = useCallback(() => {
    setReload("Quoting-BidGroups-Unassigned", true);
    setReload(quotingBidGroupsDCId, true);
    fetchAllBidGroups(projectId);
  }, [fetchAllBidGroups, projectId, setReload]);

  useEffect(() => {
    processDeleteRequestStatus(
      prevSpecDataComponentRef.current,
      specDataComponent,
      { onSuccess }
    );
    processUpdateRequestStatus(
      prevSpecDataComponentRef.current,
      specDataComponent,
      { onSuccess }
    );
    prevSpecDataComponentRef.current = specDataComponent;
  }, [
    fetchAllBidGroups,
    onSuccess,
    projectId,
    refreshBidGroupsList,
    setReload,
    specDataComponent,
  ]);

  useEffect(() => {
    if (shouldReload) {
      refreshBidGroupsList(projectId, filters)(0);
      setReload(quotingBidGroupsDCId, false);
    }
  }, [setReload, refreshBidGroupsList, shouldReload, projectId, filters]);

  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      onSuccess();
      showSnackNotificationAction("Bid Group removed successfully.");
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });

  const handleOpenCreateBidModal = useCallback(
    (selectedIds, extraProps) => () => {
      const title = extraProps.editBidGroup
        ? ["Edit Bid Group Name"]
        : [
            "Create Bid Group",
            `${pluralize("Spec", selectedIds.length, true)} Selected`,
          ];
      openModalDialog(title, "CreateBidGroup", {
        projectId,
        selectedIds,
        ...extraProps,
      });
    },
    [openModalDialog, projectId]
  );

  return (
    <Fragment>
      <BWInfiniteScroll
        dataComponentId={quotingBidGroupsDCId}
        scrollThreshold={0.5}
        loadNext={refreshBidGroupsList(projectId, filters)}
        mapRowToComponent={bidGroup => (
          <BidGroupTable
            key={bidGroup.id}
            bidGroup={bidGroup}
            onOpenCreateBidModal={handleOpenCreateBidModal}
            bidGroups={bidGroups}
            specCategories={specCategories}
          />
        )}
      />
    </Fragment>
  );
};

BidGroupListContainer.propTypes = {
  openModalDialog: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  fetchAllBidGroups: PropTypes.func.isRequired,
  refreshCategoriesList: PropTypes.func.isRequired,
  refreshBidGroupsList: PropTypes.func.isRequired,
  initBidGroupsDC: PropTypes.func.isRequired,
  initBidGroupSpecDetail: PropTypes.func.isRequired,
  initQuotingCategoriesDC: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  specDataComponent: propTypes.dataComponent,
  specCategories: PropTypes.arrayOf(propTypes.specCategory),
  setReload: PropTypes.func.isRequired,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  shouldReload: PropTypes.bool,
  onClearFilter: PropTypes.func,
  showSnackNotificationAction: PropTypes.func,
  filters: PropTypes.object,
};

BidGroupListContainer.defaultProps = {
  bidGroups: [],
};

const mapStateToProps = (state, { projectId }) => {
  const dataComponent = getDataComponent(quotingBidGroupsDCId, state);

  const specDataComponent = getDataComponent(bidGroupSpecDetailId, state);
  const specUpdateDataComponent = getDataComponent(bidGroupSpecUpdateId, state);

  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    quotingBidGroupsDCId,
    state
  );
  const bidGroupSpecDetailDataComponent = getDataComponentFlattenedRequestState(
    bidGroupSpecDetailId,
    state
  );

  const bidGroupSpecDetailDeleteDataComponent = getDataComponentFlattenedRequestState(
    bidGroupSpecDetailId,
    state,
    REQUEST_TYPES.DELETE
  );
  const bidGroupSpecDetailUpdateDataComponent = getDataComponentFlattenedRequestState(
    bidGroupSpecDetailId,
    state,
    REQUEST_TYPES.UPDATE
  );

  const specCategoryDataComponent = getDataComponentFlattenedRequestState(
    selectSpecCategoriesId,
    state
  );

  const bidGroups =
    getBluechipResources(
      getDataComponentFlattenedRequestState(allBidGroupsDataComponentId, state),
      state
    ) || [];
  const specCategories =
    getBluechipResources(specCategoryDataComponent, state) || [];

  const shouldReload =
    flattenedDataComponent.reload && !flattenedDataComponent.loading;

  return {
    specCategories,
    projectId,
    dataComponent,
    loading: [
      flattenedDataComponent,
      specCategoryDataComponent,
      bidGroupSpecDetailDataComponent,
      bidGroupSpecDetailUpdateDataComponent,
      bidGroupSpecDetailDeleteDataComponent,
    ].some(({ loading }) => loading),
    shouldReload,
    specDataComponent,
    specUpdateDataComponent,
    bidGroups,
  };
};

export const mapDispatchToProps = dispatch => ({
  refreshBidGroupsList: (...props) => pageNumber =>
    dispatch(refreshBidGroupsList(...props)(pageNumber)),
  ...bindActionCreators(
    {
      fetchAllBidGroups,
      refreshCategoriesList,
      initBidGroupsDC,
      initBidGroupSpecDetail,
      initQuotingCategoriesDC,
      openModalDialog,
      setReload,
      showSnackNotificationAction,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BidGroupListContainer);
