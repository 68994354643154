import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  Check,
  VerticalAlignWrapper,
} from "../../../SubmittalsManager/RequirementsGrid/SubmittalsTable/components";
import PropTypes from "prop-types";

const ApproveFormatter = ({ value: isActive }) => {
  return (
    <VerticalAlignWrapper>
      <Check isApproved={isActive} />
    </VerticalAlignWrapper>
  );
};

ApproveFormatter.propTypes = {
  value: PropTypes.bool,
};

export const StatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ApproveFormatter}
    {...props}
    for={["isActive"]}
  />
);
