import React from "react";
import PropTypes from "prop-types";
import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

import propTypes from "../../../../constants/propTypes";

const BWGridTableHeaderRow = ({ setSort, isLoading, tableComponents }) => {
  const {
    HeaderRowComponent,
    HeaderCellComponent,
    HeaderSortLabelComponent,
  } = tableComponents;

  return (
    <TableHeaderRow
      showSortingControls={!!setSort}
      rowComponent={props => HeaderRowComponent(isLoading)(props)}
      isLoading={isLoading}
      cellComponent={HeaderCellComponent}
      sortLabelComponent={HeaderSortLabelComponent}
    />
  );
};

BWGridTableHeaderRow.propTypes = {
  tableComponents: propTypes.tableComponents,
  isLoading: PropTypes.bool,
  setSort: PropTypes.func,
};

export default BWGridTableHeaderRow;
