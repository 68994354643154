import { Fragment } from "react";
import React from "react";
import styled from "styled-components";

const Key = styled.dt({});
const Value = styled.dd({});
const AttributeContainer = styled.dl`
  margin: 0;
  margin-left: 42px;

  ${Key} {
    display: inline-block;
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 6px;
    line-height: 24px;
    font-weight: bold;
  }

  ${Value} {
    display: inline-block;
    margin-left: 0;
    margin-right: 24px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;

const AttributesArea = ({ attributes }) => {
  attributes = attributes.filter(attr => attr.value && attr.value !== "");

  if (attributes.length === 0) {
    return null;
  } else {
    return (
      <AttributeContainer>
        {attributes.map(({ key, value }) => (
          <Fragment key={key}>
            <Key>{key}:</Key>
            <Value>{value}</Value>
          </Fragment>
        ))}
      </AttributeContainer>
    );
  }
};

export default AttributesArea;
