import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";
import Remove from "@material-ui/icons/Remove";

import Paper from "../../mui/core/Paper";
import { LabeledTextContext } from "../../../withPORevision";

import { useAreaDetailPageContext } from "./areaDetailutils";
import { LabeledTextWrapper, TextWrapper } from "../../inputs/LabeledText";
import InputLoader from "../../ui/Loader/InputLoader";
import InputLabel from "../../mui/core/InputLabel";
import Grid from "../../mui/core/Grid";
import { GroupWrapper } from "../../forms/FormGrid";
import Subheader from "../../ui/Typography/Subheader";
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter";

const InputText = styled(Subheader)`
  min-height: 24px;
  display: flex;
  align-items: center;
`;

const LabeledText = ({ label, text }) => (
  <InputLoader>
    <LabeledTextWrapper>
      <InputLabel>{label}</InputLabel>
      <TextWrapper>
        <InputText>{text}</InputText>
      </TextWrapper>
    </LabeledTextWrapper>
  </InputLoader>
);

LabeledText.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function getSize(isMultiple, length, squareFoot) {
  if (isMultiple) return `${length} Keys`;
  return typeof squareFoot === "number" ? `${squareFoot} SF` : <Remove />;
}

const MainInfo = () => {
  const { area } = useAreaDetailPageContext();
  const currencyFormatter = useCurrencyFormatter();
  const values = useMemo(() => {
    const isMultiple = _get(area, "areaType.isMultiple", false);
    const areaRooms = _get(area, "areaRooms", []);
    const squareFoot = _get(area, "squareFoot", "0");
    const size = getSize(isMultiple, areaRooms.length, squareFoot);

    return {
      description: _get(area, "description"),
      clientBudgetCents: currencyFormatter.format(
        _get(area, "clientBudgetCents", 0)
      ),
      size,
    };
  }, [area, currencyFormatter]);

  return (
    <LabeledTextContext.Provider value={true}>
      <Paper>
        <GroupWrapper>
          <Grid container>
            <Grid item xs={3}>
              <LabeledText
                label="Area Client Budget"
                text={values.clientBudgetCents}
              />
            </Grid>
            <Grid item xs={3}>
              <LabeledText label="Size" text={values.size} />
            </Grid>
            <Grid item xs={8}>
              <LabeledText label="Description" text={values.description} />
            </Grid>
          </Grid>
        </GroupWrapper>
      </Paper>
    </LabeledTextContext.Provider>
  );
};

export default MainInfo;
