import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../constants/propTypes";
import { LoaderContext } from "../../ui/Loader";
import { PageContext } from "./areaDetailutils";
import { Content } from "../../layout/Content";

import NavigationPane from "./NavigationPane";
import MainInfo from "./MainInfo";
import Specs from "./Specs";

const AreaDetail = ({
  actions,
  clientId,
  projectId,
  areaId,
  loading,
  area,
  links,
}) => {
  useEffect(() => {
    actions.initAreaDetails(projectId, areaId);
  }, [actions, projectId, areaId]);

  const pageValue = useMemo(() => {
    return { area, areaId, projectId, clientId, links };
  }, [area, areaId, projectId, clientId, links]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <PageContext.Provider value={pageValue}>
        <Content>
          <NavigationPane />
          <MainInfo />
          <Specs projectId={projectId} />
        </Content>
      </PageContext.Provider>
    </LoaderContext.Provider>
  );
};

AreaDetail.propTypes = {
  actions: PropTypes.shape({ initAreaDetails: PropTypes.func }).isRequired,
  clientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  areaId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  area: propTypes.area,
  links: PropTypes.shape({ last: PropTypes.string, next: PropTypes.string }),
};

export default AreaDetail;
