import React from "react";
import styled from "styled-components";
import MUIList from "@material-ui/core/List";
import MUIListItem from "@material-ui/core/ListItem";
import MUIListItemText from "@material-ui/core/ListItemText";
import MUIListItemIcon from "@material-ui/core/ListItemIcon";
import AddCircle from "@material-ui/icons/AddCircle";

import useAddSpecToPOStore from "../../../../hooks/useAddSpecToPOStore";

const List = styled(MUIList)`
  padding: 0;
`;

const ListItem = styled(MUIListItem)`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ListItemIcon = styled(MUIListItemIcon)`
  color: #57abff;
  margin-right: 0;
`;

const ListItemText = styled(MUIListItemText)`
  padding: 0 8px;
  > span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #57abff;
  }
`;

const stateActionsSelector = state => state.actions;

const MainOptions = () => {
  const stateActions = useAddSpecToPOStore(stateActionsSelector);
  return (
    <List>
      <ListItem onClick={stateActions.openCreateSpec} button>
        <ListItemIcon>
          <AddCircle />
        </ListItemIcon>
        <ListItemText primary="Create a new spec" />
      </ListItem>
      <ListItem onClick={stateActions.openMoveSpec} button>
        <ListItemIcon>
          <AddCircle />
        </ListItemIcon>
        <ListItemText primary="Add a spec" />
      </ListItem>
      <ListItem button onClick={stateActions.openCopySpec}>
        <ListItemIcon>
          <AddCircle />
        </ListItemIcon>
        <ListItemText primary="Copy a spec from another project" />
      </ListItem>
      <ListItem onClick={stateActions.openDuplicateSpec} button>
        <ListItemIcon>
          <AddCircle />
        </ListItemIcon>
        <ListItemText primary="Duplicate a spec" />
      </ListItem>
    </List>
  );
};

MainOptions.propTypes = {};

export default MainOptions;
