import styled from "styled-components";
import InputLabel from "../../../mui/core/InputLabel";

export const GroupLabel = styled(InputLabel)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 14px;
`;

export const PermissionContainer = styled.div`
  padding: 0px 24px 24px 24px;
`;

export const PermissionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`;

export const SelectedCounter = styled.span`
  color: #78cc18;
`;

export const PermissionError = styled.div`
  font-size: 12px;
  color: red;
  flex: 1;
`;

export const PermissionSection = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  max-height: 50vh;
  overflow-y: auto;
  @media (max-height: 600px) {
    max-height: 40vh;
  }
`;
