import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import { FieldArray } from "formik";
import Joi from "joi-browser";
import _snakeCase from "lodash/snakeCase";

import templateFields from "./templateFields";
import Details from "./Details";
import propTypes from "../../../../../constants/propTypes";
import { FormikForm, ActionButtons, FormGrid } from "../../../../forms";

export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let i = new_index - arr.length + 1;
    while (i--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const getValidationSchema = () =>
  BWModels.loadSchema("SpecCategory").concat(
    Joi.object().keys({
      specCategoryDetails: Joi.array().items(
        BWModels.loadSchema("SpecCategoryDetail")
      ),
    })
  );

export default class CreateTemplateForm extends Component {
  detailHasError = (index, errors) => {
    for (var key in errors) {
      if (key.startsWith(`specCategoryDetails[${index}]`)) {
        return true;
      }
    }
    return false;
  };

  removeEditingState = (details, formikArrayHelpers, errors) =>
    details.forEach((detail, index) => {
      if (this.detailHasError(index, errors)) return;
      const { isEditing, ...newDetail } = detail;
      formikArrayHelpers.replace(index, newDetail);
    });

  edit = (index, detail, { formikArrayHelpers, errors, setFieldTouched }) => {
    let replacementRoomState = detail;
    if (!detail.isEditing && this.detailHasError(index, errors)) {
      setFieldTouched(`specCategoryDetails[${index}].name`);
      replacementRoomState = { ...replacementRoomState, isEditing: true };
    }
    formikArrayHelpers.replace(index, replacementRoomState);
  };

  remove = (index, { formikArrayHelpers }) => {
    formikArrayHelpers.remove(index);
  };

  reorder = (
    destination,
    source,
    { formikArrayHelpers, setFieldValue, values }
  ) => {
    formikArrayHelpers.move(source.index, destination.index);
    setFieldValue(
      "detailsIdSequence",
      array_move(values.detailsIdSequence, source.index, destination.index)
    );
  };

  detailsListProps = formikProps => {
    return {
      onAdd: () => this.props.onOpenAddModal(formikProps.values),
      onEdit: (index, room) => this.edit(index, room, formikProps),
      onRemove: index => this.remove(index, formikProps),
      onReorder: ({ destination, source }) =>
        this.reorder(destination, source, formikProps),
    };
  };

  handleSubmit = (values, actions) => {
    const { onSubmit } = this.props;
    const { specCategoryDetails = [], isCom, key, ...specCategory } = values;
    onSubmit(
      {
        ...specCategory,
        key: key || _snakeCase(specCategory.name).toUpperCase(),
        specCategoryDetails: specCategoryDetails.map(
          ({ isEditing, isTemp, ...detail }) => {
            if (isTemp) {
              delete detail.id;
            }
            return detail;
          }
        ),
      },
      actions
    );
  };

  render() {
    const { dataComponentId } = this.props;
    return (
      <FormikForm
        initialValues={this.props.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={getValidationSchema()}
        ignoreValidationFieldNames={[
          /specCategoryDetails\[\d*\].specCategoryId/,
          /specCategoryDetails\[\d*\].id/,
          "key",
        ]}
      >
        {({ handleSubmit, ...formikProps }) => (
          <Fragment>
            <FormGrid fields={templateFields} {...formikProps} />
            <FieldArray
              name="specCategoryDetails"
              render={formikArrayHelpers => (
                <Details
                  {...formikProps}
                  {...this.detailsListProps({
                    formikArrayHelpers,
                    ...formikProps,
                  })}
                />
              )}
            />
            <ActionButtons
              sendButtonText={this.props.isEditing ? "Save" : "Create Template"}
              onSend={handleSubmit}
              isModal={true}
              listeners={[dataComponentId]}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}

CreateTemplateForm.propTypes = {
  initialValues: propTypes.specCategory,
  onSubmit: PropTypes.func.isRequired,
  onOpenAddModal: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  dataComponentId: PropTypes.string.isRequired,
};
