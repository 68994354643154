import { appPageBuilder } from "./utils";
import Clients from "../components/pages/Clients";
import ClientCreate from "../components/pages/ClientCreate";
import ClientEdit from "../components/pages/ClientEdit";
import ClientDetail from "../components/pages/ClientDetail";
import PropertyCreate from "../components/pages/PropertyCreate";
import PropertyEdit from "../components/pages/PropertyEdit";
import PropertyDuplicate from "../components/pages/PropertyDuplicate";
import EntityDetail from "../components/pages/EntityDetail";

const AppClients = appPageBuilder(Clients, "Clients");
const AppClientCreate = appPageBuilder(ClientCreate, "Create Client", true);
const AppClientEdit = appPageBuilder(ClientEdit, "Edit Client", true);
const AppClientDetail = appPageBuilder(ClientDetail, "Client Detail", true);
const AppPropertyCreate = appPageBuilder(PropertyCreate, "Create Property");
const AppPropertyEdit = appPageBuilder(PropertyEdit, "Property Settings - #");
const AppPropertyDuplicate = appPageBuilder(
  PropertyDuplicate,
  "Duplicate Property - #"
);
const AppEntityDetail = appPageBuilder(EntityDetail, "Entity Detail");

export default [
  {
    path: "/clients",
    component: AppClients,
    exact: true,
  },
  {
    path: "/clients/create",
    component: AppClientCreate,
    exact: true,
  },
  {
    path: "/clients/edit/:clientId",
    component: AppClientEdit,
    exact: true,
  },
  {
    path: "/clients/:clientId/properties/create",
    component: AppPropertyCreate,
    exact: true,
  },
  {
    path: "/clients/:clientId/properties/:propertyId/settings",
    component: AppPropertyEdit,
    exact: true,
  },
  {
    path: "/clients/:clientId/properties/:propertyId/duplicate",
    component: AppPropertyDuplicate,
    exact: true,
  },
  {
    path: "/clients/:clientId/entities/:entityId",
    component: AppEntityDetail,
    exact: true,
  },
  {
    path: "/clients/:clientId",
    component: AppClientDetail,
  },
];
