import {
  initDataComponent,
  performRetrieveListRequest,
} from "../actions/dataComponentActions";
import ProjectCurrency from "../models/ProjectCurrency";

export const projectCurrenciesDataComponentId = "select-project-currencies";

export function fetchProjectCurrencies(
  projectId,
  dataComponentId = projectCurrenciesDataComponentId
) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        ProjectCurrency,
        ["currency"],
        "project-currencies",
        undefined,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        sort: [{ columnName: "currency.name", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
}
