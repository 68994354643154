import BWModels from "benjaminwest-models";
import _baseGet from "lodash/_baseGet";
import _defaultTo from "lodash/defaultTo";

import SelectWithError, {
  SelectWithError as RawSelectWithError,
} from "../SelectWithError";
import DatePickerWithError from "../DatePickerWithError";
import FilePickerWithError from "../FilePickerWithError";
import TextInputWithError from "../TextInputWithError";
import { getOptionsFromSchema } from "../utils";
import Requirement from "../../../models/Requirement";
import RadioGroup from "../RadioGroup";
import LocationCombined from "../../ui/LocationCombined";

export const getAddressName = shipAddress => {
  const description = _baseGet(shipAddress, "description");
  const name = _defaultTo(_baseGet(shipAddress, "name"), "");
  return _defaultTo(description, name);
};

export const getShipAddressLabel = site => {
  if (site.location) {
    return `${getAddressName(site)} - ${LocationCombined({
      location: site.location,
    })}`;
  }
  if (site.id == "-1") {
    return "TBD";
  }
  return "None";
};
export const filterByStartsWith = (option, inputValue) => {
  if (inputValue.length === 0) {
    return true;
  }
  return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
};

export default {
  paymentTerms: {
    InputComponent: SelectWithError,
    label: "Payment Terms",
    name: "paymentTermId",
    dataComponentId: "select-payment-terms",
    fullWidth: true,
    valueProperty: "id",
    labelProperty: "content",
    nullAsEmpty: true,
    castFunction: String,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    filterOption: filterByStartsWith,
  },
  poProjectManager: {
    InputComponent: SelectWithError,
    label: "Project Manager Listed on PO",
    name: "projectManagerId",
    dataComponentId: "select-users",
    nullAsEmpty: true,
    fullWidth: true,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    valueProperty: "id",
    labelProperty: "name",
    required: true,
    emphasizeRequired: true,
  },
  poFreight: {
    InputComponent: RawSelectWithError,
    label: "Freight",
    name: "freightVendorId",
    options: [],
    nullAsEmpty: true,
    fullWidth: true,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    valueProperty: "id",
    labelProperty: "name",
    displayEmpty: true,
  },
  poShipVia: {
    InputComponent: RawSelectWithError,
    name: "shipVia",
    label: "Ship Via",
    get options() {
      const projectSchema = BWModels.loadSchema("PurchaseOrder");
      return projectSchema.__utils.shipVia;
    },
    fullWidth: true,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  approvalFile: {
    InputComponent: FilePickerWithError,
    name: "file",
    label: "Select File",
    fullWidth: true,
    inputFileProps: {
      accept: "text/csv, application/vnd.ms-excel",
    },
    helperText: "Provide a valid .xls or .csv file or paste Box URL link",
  },
  poVendorNotes: {
    InputComponent: TextInputWithError,
    label: "Content",
    name: "vendorNotes",
    fullWidth: true,
    multiline: true,
  },
  changeOrderNotes: {
    InputComponent: TextInputWithError,
    label: "Revision Notes",
    name: "currentRevision.notes",
    rows: 2,
    fullWidth: true,
    multiline: true,
    resize: "vertical",
    required: true,
    emphasizeRequired: true,
    avoidOnChange: true,
  },
  changeOrderRevisionType: {
    InputComponent: RawSelectWithError,
    label: "Revision Type",
    name: "currentRevision.type",
    options: getOptionsFromSchema("Revision", "types"),
    displayEmpty: false,
    fullWidth: true,
    required: true,
    emphasizeRequired: true,
  },
  requirementId: {
    isSingleAutocomplete: true,
    InputComponent: SelectWithError,
    label: "Select Requirement",
    name: "requirementId",
    isAutocomplete: true,
    dataComponentId: "select-requirements",
    fullWidth: true,
    ignoreCaption: true,
    APIOptions: {
      model: Requirement,
      route: "requirements",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    valueProperty: "id",
    labelProperty: "name",
  },
  shipToSite: {
    InputComponent: RadioGroup,
    label: "Where to Ship?",
    name: "shipToSite",
    options: [
      { label: "Ship to Site", value: "true" },
      { label: "Ship to Vendor", value: "false" },
    ],
    onChangeParser: value => value === "true",
    emptyValue: "true",
  },
  poShipAddressId: {
    InputComponent: SelectWithError,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    name: "shipAddressId",
    label: "Location",
    displayEmpty: false,
    dataComponentId: "select-project-sites",
    fullWidth: true,
    labelProperty: getShipAddressLabel,
  },
  poToClient: {
    InputComponent: DatePickerWithError,
    label: "PO to Client",
    name: "poToClient",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  clientApproved: {
    InputComponent: DatePickerWithError,
    label: "Client Approved",
    name: "clientApproved",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  poToDesigner: {
    InputComponent: DatePickerWithError,
    label: "PO to Designer",
    name: "poToDesigner",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  designerApproved: {
    InputComponent: DatePickerWithError,
    label: "Designer Approved",
    name: "designerApproved",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  vendorReceived: {
    InputComponent: DatePickerWithError,
    label: "Vendor Received",
    name: "vendorReceived",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  sentToVendor: {
    InputComponent: DatePickerWithError,
    label: "PO Sent to Vendor",
    name: "sentToVendor",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
  followUpDate: {
    InputComponent: DatePickerWithError,
    label: "Follow Up Date",
    name: "followUpDate",
    disablePast: false,
    fullWidth: true,
    ignoreLabeledText: true,
  },
};
