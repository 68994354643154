import * as REQUEST_TYPES from "../constants/RequestTypes";

export function getActiveRequest(
  dataComponent,
  types = Object.values(REQUEST_TYPES)
) {
  const requestState = dataComponent.requestState;
  return types.find(
    operation => requestState[operation] && requestState[operation].loading
  );
}

export function haveActiveRequests(
  dataComponents,
  types = Object.values(REQUEST_TYPES)
) {
  const arr = Array.isArray(dataComponents) ? dataComponents : [dataComponents];
  return arr.some(dataComponent => !!getActiveRequest(dataComponent, types));
}

export function isPendingRequest(dataComponent, operation) {
  const requestState = dataComponent.requestState;
  return !!(requestState[operation] && requestState[operation].loading);
}

export function isSuccessfulRequest(dataComponent, operation) {
  const requestState = dataComponent.requestState;
  return !!(
    requestState[operation] &&
    !requestState[operation].loading &&
    requestState[operation].success
  );
}

export function isFailedRequest(dataComponent, operation) {
  const requestState = dataComponent.requestState;
  return !!(
    requestState[operation] &&
    !requestState[operation].loading &&
    requestState[operation].error
  );
}

export function getCreatedId(dataComponent) {
  const requestStatus = dataComponent.requestState[REQUEST_TYPES.CREATE];

  return requestStatus &&
    requestStatus.rowIndex &&
    requestStatus.rowIndex.length
    ? requestStatus.rowIndex[0]
    : null;
}

export function getCreatedIds(dataComponent) {
  const requestStatus = dataComponent.requestState[REQUEST_TYPES.CREATE];

  return requestStatus &&
    requestStatus.rowIndex &&
    requestStatus.rowIndex.length
    ? requestStatus.rowIndex
    : null;
}

export function getUpdatedId(dataComponent) {
  const requestStatus = dataComponent.requestState[REQUEST_TYPES.UPDATE];

  return requestStatus &&
    requestStatus.rowIndex &&
    requestStatus.rowIndex.length
    ? requestStatus.rowIndex[0]
    : null;
}

export function getFoundId(dataComponent) {
  const requestStatus = dataComponent.requestState[REQUEST_TYPES.FIND];

  return requestStatus &&
    requestStatus.rowIndex &&
    requestStatus.rowIndex.length
    ? requestStatus.rowIndex[0]
    : null;
}

export function processListRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.LIST
  );
}

export function processFindRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.FIND
  );
}

export function processCreateRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.CREATE
  );
}

export function processImportRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.IMPORT
  );
}

export function processUpdateRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.UPDATE
  );
}

export function processUpsertRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.UPDATE
  );

  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.CREATE
  );
}

export function processDeleteRequestStatus(
  prevDataComponent,
  dataComponent,
  callbacks
) {
  processRequestStatus(
    prevDataComponent,
    dataComponent,
    callbacks,
    REQUEST_TYPES.DELETE
  );
}

export function processRequestStatus(
  prevDataComponent,
  dataComponent,
  { onSuccess, onError, onRequestStart },
  operation
) {
  if (
    !dataComponent ||
    !dataComponent.requestState ||
    !dataComponent.requestState[operation]
  ) {
    return;
  }

  let wasLoading = false;
  if (prevDataComponent && prevDataComponent.requestState[operation]) {
    wasLoading = prevDataComponent.requestState[operation].loading;
  }

  _handleSuccess(onSuccess, wasLoading, dataComponent, operation);
  _handleError(onError, wasLoading, dataComponent, operation);
  _handleRequestStart(onRequestStart, wasLoading, dataComponent, operation);
}

function _handleSuccess(onSuccess, wasLoading, dataComponent, operation) {
  if (
    onSuccess &&
    wasLoading &&
    isSuccessfulRequest(dataComponent, operation)
  ) {
    onSuccess();
  }
}

function _handleError(onError, wasLoading, dataComponent, operation) {
  if (onError && wasLoading && isFailedRequest(dataComponent, operation)) {
    onError(dataComponent.requestState[operation].error);
  }
}

function _handleRequestStart(
  onRequestStart,
  wasLoading,
  dataComponent,
  operation
) {
  if (
    onRequestStart &&
    !wasLoading &&
    isPendingRequest(dataComponent, operation)
  ) {
    onRequestStart();
  }
}
