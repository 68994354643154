import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Slider } from "@material-ui/core";
import _get from "lodash/get";
import panAndZoomHoc from "react-pan-and-zoom-hoc";

import propTypes from "../../../../constants/propTypes";
import {
  PannableAndZoomableContainer,
  SliderLabel,
  SliderWrapper,
  ProfileEditPictureWrapper,
  PictureWrapper,
} from "./components";

export const PannableAndZoomableFigure = panAndZoomHoc(
  PannableAndZoomableContainer
);
const sizeNumber = 183;
const size = `${sizeNumber}px`;

const defaultMetadata = {
  x: 0,
  y: 0,
  zoom: 1,
};

const ProfilePictureSection = ({
  user,
  file,
  onUpdateZoomOrPan,
  initialMetadata,
}) => {
  initialMetadata = initialMetadata || defaultMetadata;
  const [scale, setScale] = useState(initialMetadata.zoom || 1);
  const [position, setPosition] = useState([
    initialMetadata.x,
    initialMetadata.y,
  ]);

  useEffect(() => {
    if (file) {
      setPosition([0, 0]);
      setScale(1);
    }
  }, [file]);

  const handleSliderChange = (event, newScale) => {
    setScale(newScale);

    onUpdateZoomOrPan({
      zoom: newScale,
    });
  };

  const handlePanMove = (x, y) => {
    setPosition([x, y]);
    onUpdateZoomOrPan({
      x,
      y,
      zoom: scale,
    });
  };

  const viewBox = useMemo(() => {
    return `0 0 ${sizeNumber} ${sizeNumber}`;
  }, []);

  const src = file
    ? URL.createObjectURL(file)
    : _get(user, "profilePicture.s3SignedUrl");

  const [x, y] = position;

  const style = useMemo(
    () => ({
      transform: `scale(${scale}, ${scale})`,
      transformOrigin: `${(x * 100).toFixed(2)}% ${(y * 100).toFixed(2)}%`,
    }),
    [scale, x, y]
  );

  return (
    <ProfileEditPictureWrapper>
      <PictureWrapper scale={scale}>
        <PannableAndZoomableFigure
          onPanMove={handlePanMove}
          x={x}
          y={y}
          width={size}
          height={size}
        >
          <svg width={size} height={size} fill="none">
            <svg width={size} height={size} viewBox={viewBox}>
              <image
                x="0"
                y="0"
                style={style}
                xlinkHref={src}
                width={size}
                height={size}
              ></image>
            </svg>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M183 0H0V183H183V0ZM91 161C130.765 161 163 128.765 163 89C163 49.2355 130.765 17 91 17C51.2354 17 19 49.2355 19 89C19 128.765 51.2354 161 91 161Z"
              fill="black"
              fillOpacity="0.3"
            />
          </svg>
        </PannableAndZoomableFigure>

        <SliderWrapper>
          <SliderLabel>Zoom</SliderLabel>
          <Slider
            value={scale}
            max={2}
            min={1}
            step={0.1}
            onChange={handleSliderChange}
          />
        </SliderWrapper>
      </PictureWrapper>
    </ProfileEditPictureWrapper>
  );
};

ProfilePictureSection.defaultProps = {
  user: {},
  initialMetadata: {
    x: 0,
    y: 0,
    zoom: 1,
  },
};

ProfilePictureSection.propTypes = {
  user: propTypes.user,
  file: PropTypes.object,
  onUpdateZoomOrPan: PropTypes.func,
  initialMetadata: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    zoom: PropTypes.number,
  }),
};

export default ProfilePictureSection;
