import create from "zustand";
import { devtools } from "zustand/middleware";

function selectVendorReducer(set) {
  return function(vendor) {
    set(state => ({ ...state, selectedVendor: vendor }));
  };
}

function clearSelectionReducer(set) {
  return function(state) {
    set({ ...state, selectedVendor: null });
  };
}

export default create(
  devtools(
    set => ({
      selectedVendor: null,
      selectVendor: selectVendorReducer(set),
      clearSelection: clearSelectionReducer(set),
    }),
    "VendorScope"
  )
);
