import styled from "styled-components";

export const FundReceivedWrapper = styled.div`
  padding: 24px;
`;

export const FundReceivedLabel = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
`;
export const FundReceivedText = styled.div`
  color: rgba(0, 0, 0, 0.87);
`;

export const Header = styled.div`
  font-weight: normal;
  font-size: 20px;
  font-family: Domine, sans-serif;
  font-style: normal;

  color: rgba(0, 0, 0, 0.87);
`;

export const SubHeader = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 24px;
  align-items: center;
  padding: 8px 0px;
  justify-content: space-between;
  width: 104px;
`;
