import React, { useMemo } from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import { TableWrapper } from "./components";
import propTypes from "../../../../constants/propTypes";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const columns = [
  "contact.name",
  "contact.jobTitle",
  "contact.email",
  "contact.phone",
];
const pageNumber = 0;

export const columnOptions = () => ({
  "contact.name": {
    title: "Name",
    filter: true,
    bold: true,
    width: "380px",
  },
  "contact.jobTitle": {
    title: "Job Title",
    filter: true,
    width: "240px",
    render: row => {
      return `${row.contact.jobTitle}`;
    },
  },
  "contact.email": {
    title: "Email",
    filter: false,
    width: "280px",
    render: row => {
      return `${row.contact.email}`;
    },
  },
  "contact.phone": {
    title: "Phone Number",
    filter: false,
    width: "240px",
  },
});
const sorting = [{ columnName: "customNumber", direction: "asc" }];

export const getDefaultSelection = (rows, selectedContacts) => () => {
  const defaultSelection = rows.reduce((result, vendorContact, index) => {
    if (selectedContacts.includes(vendorContact.contact.id))
      return [...result, index];
    return result;
  }, []);

  return {
    [pageNumber]: defaultSelection,
  };
};

const ContactsList = ({ contacts, selectedContacts, loading }) => {
  const isProjectClosed = useIsProjectClosed();
  const { selection } = useWithSelectedRowContext();
  const selectedCount = (selection[pageNumber] || []).length;
  const title = pluralize("Contact", selectedCount, true);

  const defaultSelection = useMemo(
    getDefaultSelection(contacts, selectedContacts),
    [contacts, selectedContacts]
  );

  const rows = useMemo(() => {
    return contacts.map((contact, i) => {
      contact.disabled =
        (!contact.isActive && !selection[0]?.includes(i)) || isProjectClosed;
      return contact;
    });
  }, [contacts, isProjectClosed, selection]);

  return (
    <TableWrapper>
      <BWGridLocal
        noBorder
        id="bidgroup-selected-specs"
        sorting={sorting}
        rows={rows}
        gridConfig={{ pageSize: 0 }}
        emptyStateText="No Contacts"
        revisionReference="contacts"
        showSelectionColumn
        showSelectAll={!isProjectClosed}
        isLoading={loading}
        defaultSelection={defaultSelection}
      >
        <GridHeader headerText={`${title} Selected`} />
        <GridTable columns={columns} columnOptions={columnOptions()} />
      </BWGridLocal>
    </TableWrapper>
  );
};

ContactsList.propTypes = {
  bidGroup: propTypes.bidGroup,
  contacts: PropTypes.arrayOf(propTypes.vendorContact),
  selectedContacts: PropTypes.arrayOf(PropTypes.string),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default ContactsList;
