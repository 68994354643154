import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash";

import { Paper } from "../../../../../mui/core";
import EntityRow from "./EntityRow";

export const updateEffect = ({ rootRef, root, onUpdate }) => () => {
  if (rootRef.current === root) return;
  rootRef.current = root;
  if (!root) {
    onUpdate();
  }
};

const LazyTreeView = ({
  rows,
  root,
  setRoot,
  nodeSet,
  onExpand,
  onCollapse,
  onUpdate,
  parentDataComponentId,
}) => {
  const rootRef = useRef(root);

  useEffect(updateEffect({ rootRef, root, onUpdate }), [onUpdate, root]);

  const { entityName, properties: rootProperties, children: rootChildren } =
    root || {};

  const rootEntity = camelCase(entityName);

  return (
    <Paper noBottomMargin noBorder>
      {rows
        .filter(({ entityName }) => entityName === rootEntity || !root)
        .map(({ id, name, entityName, endpoint, properties, relations }) => (
          <EntityRow
            id={id}
            key={name}
            root={root}
            setRoot={setRoot}
            onUpdate={onUpdate}
            endpoint={endpoint}
            name={name}
            entityName={camelCase(entityName)}
            selectableItems={properties}
            childrenItems={relations}
            onExpand={onExpand}
            onCollapse={onCollapse}
            nodeSet={nodeSet}
            properties={entityName === rootEntity ? rootProperties : {}}
            childNodes={entityName === rootEntity ? rootChildren : {}}
            parentDataComponentId={parentDataComponentId}
          />
        ))}
    </Paper>
  );
};

LazyTreeView.propTypes = {
  rows: PropTypes.array.isRequired,
  root: PropTypes.object,
  setRoot: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  nodeSet: PropTypes.object.isRequired,
  parentDataComponentId: PropTypes.string,
};

LazyTreeView.defaultProps = {
  rows: [],
};

export default LazyTreeView;
