import React, { useEffect } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import { connect } from "react-redux";
import ShipmentsNeedsFollowUp from "../../Dashboard/ShipmentsNeedsFollowUp";
import PurchaseOrdersNeedsFollowUp from "../../Dashboard/PurchaseOrdersNeedsFollowUp";
import ProjectStatusSection from "./ProjectStatusSection/ProjectStatusSection";
import FundingStatusSection from "./FundingStatusSection";
import ContractStatusSection from "./ContractStatusSection";
import ProjectNoteSection from "./ProjectNoteSection";
import BaseTab from "../Accounting/BaseTab";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import ProjectForecast from "../../../../models/ProjectForecast";
import PurchaseOrdersRequiringRefunds from "../../../pages/Dashboard/PurchaseOrdersRequiringRefunds";

import ProjectSummary from "../../../../models/ProjectSummary";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  shipmentsNeedsFollowUpColumns,
  posNeedingAttentionColumns,
} from "./columns";
import Project from "../../../../models/Project";

const StyledGridItem = styled(Grid)`
  display: flex;
  padding-right: 24px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;
const projectDataComponentId = "ProjectDetail";
const forecastDataComponentId = "ProjectForecastDetails";
const summaryDataComponentId = "ProjectSummary";
const projectFundingDataComponentId = "ProjectFunding";

export const getShipmentsNeedsFollowUpAPIFilters = projectId => ({
  rootFilters: {
    $where: {
      needsFollowUp: true,
      "spec.projectId": projectId,
    },
  },
  sort: [{ columnName: "specId", order: "desc" }],
  params: {
    omitDefaultModifier: true,
  },
});
export const getPurchaseOrdersNeedsFollowUpAPIFilters = projectId => ({
  rootFilters: {
    $where: {
      needsFollowUp: true,
      projectId,
    },
  },
  sort: [{ columnName: "id", order: "desc" }],
  params: {
    omitDefaultModifier: true,
    modifiers: ["withBasicFields", "withNeedsFollowUp"],
  },
});
export const shouldShowFundingRequired = (
  totalFundsReceived,
  totalInvoicesAmount
) => {
  return totalFundsReceived - totalInvoicesAmount <= 0;
};
const ProjectOverviewPage = props => {
  const projectId = props.match.params.projectId;
  const {
    performFindRequest,
    initDataComponent,
    project,
    projectForecast,
    projectSummary,
    projectFunding,
  } = props;

  const shipmentsNeedsFollowUpApiFilters = getShipmentsNeedsFollowUpAPIFilters(
    projectId
  );
  const purchaseOrdersNeedsFollowUpAPIFilters = getPurchaseOrdersNeedsFollowUpAPIFilters(
    projectId
  );
  useEffect(() => {
    initDataComponent(
      summaryDataComponentId,
      ProjectSummary,
      [],
      "projects/summary"
    );
    performFindRequest(summaryDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);

  useEffect(() => {
    initDataComponent(
      projectFundingDataComponentId,
      Project,
      [],
      "project-with-fundings"
    );
    performFindRequest(projectFundingDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);

  useEffect(() => {
    initDataComponent(
      forecastDataComponentId,
      ProjectForecast,
      [],
      "projects/forecast"
    );
    performFindRequest(forecastDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);

  return (
    <React.Fragment>
      <ProjectNoteSection project={project} />
      <Grid container>
        <StyledGridItem item xs={12} md={6} lg={4}>
          <BaseTab text="Project Status">
            <ProjectStatusSection
              project={project}
              projectSummary={projectSummary}
            />
          </BaseTab>
        </StyledGridItem>
        <StyledGridItem item xs={12} md={6} lg={4}>
          <BaseTab
            text="Funding Status"
            statusText={"Funding Required"}
            showStatusTag={shouldShowFundingRequired(
              projectFunding.totalFundsReceived,
              projectFunding.totalInvoicesAmount
            )}
          >
            <FundingStatusSection project={projectFunding} />
          </BaseTab>
        </StyledGridItem>
        <StyledGridItem item xs={12} md={6} lg={4}>
          <BaseTab text="Contract Status">
            <ContractStatusSection
              project={project}
              projectForecast={projectForecast}
            />
          </BaseTab>
        </StyledGridItem>
      </Grid>
      <PurchaseOrdersRequiringRefunds
        projectIds={[projectId]}
        projectView={true}
      />
      <ShipmentsNeedsFollowUp
        apiFilters={shipmentsNeedsFollowUpApiFilters}
        columns={shipmentsNeedsFollowUpColumns}
      />
      <PurchaseOrdersNeedsFollowUp
        apiFilters={purchaseOrdersNeedsFollowUpAPIFilters}
        columns={posNeedingAttentionColumns}
      />
    </React.Fragment>
  );
};
export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;

  const dataComponent = getDataComponent(projectDataComponentId, state);
  const project = getBluechipResourceById(dataComponent, state, projectId);

  const summaryDataComponent = getDataComponentFlattenedRequestState(
    summaryDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const projectSummary = getBluechipResourceById(
    summaryDataComponent,
    state,
    projectId
  ) || {
    projectStatus: {},
  };

  const projectForecaseDataComponent = getDataComponent(
    forecastDataComponentId,
    state
  );
  const projectForecast = getBluechipResourceById(
    projectForecaseDataComponent,
    state,
    projectId
  );

  const projectFundingDataComponent = getDataComponent(
    projectFundingDataComponentId,
    state
  );
  const projectFunding =
    getBluechipResourceById(projectFundingDataComponent, state, projectId) ||
    {};

  return {
    project,
    projectForecast,
    projectSummary,
    projectFunding,
  };
};
const mapDispatchToProps = {
  performFindRequest,
  initDataComponent,
};
ProjectOverviewPage.propTypes = {
  project: propTypes.project.isRequired,
  projectForecast: propTypes.projectForecast,
  projectSummary: propTypes.projectSummary,
  projectFunding: propTypes.project,
  match: PropTypes.object.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
};
ProjectOverviewPage.default = {
  project: {},
  projectForecast: {},
  projectSummary: {
    projectStatus: {},
  },
  projectFunding: {},
  performFindRequest: () => {},
  initDataComponent: () => {},
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectOverviewPage);
