import moment from "moment";

const sanitizeValue = value => {
  if (value.trim) {
    return value.trim();
  }
  return value;
};

export const operationBuildersLocal = {
  equal: ({ columnName, value }) => ({
    [columnName]: { operator: "equal", value: sanitizeValue(value) },
  }),
  ilike: ({ columnName, value }) => ({
    [columnName]: { operator: "ilike", value: sanitizeValue(value) },
  }),
  between: ({ columnName, value }) => {
    if (!value) return {};
    if (!(value instanceof moment)) value = moment(new Date(value));
    const startOfDay = value.startOf("day").toDate();
    const endOfDay = value.endOf("day").toDate();
    return {
      [columnName]: {
        operator: "between",
        value: { $lte: endOfDay, $gte: startOfDay },
      },
    };
  },
  multiselect: ({ columnName, value }) => ({
    [columnName]: { operator: "in", value },
  }),
};

export const operationBuildersAPI = {
  equal: ({ columnName, value }) => ({ [columnName]: sanitizeValue(value) }),
  ilike: ({ columnName, value }) => ({
    [columnName]: { $ilike: `%${sanitizeValue(value)}%` },
  }),
  in: ({ columnName, value: values }) => ({
    [columnName]: { $in: values },
  }),
  jsonIn: ({ columnName, value: values }) => ({
    [columnName]: { $jsonIn: values },
  }),
  jsonInRaw: ({ columnName, value: values }) => ({
    [columnName]: { $jsonInRaw: values },
  }),
  $exist: ({ columnName, value: values }) => ({
    [columnName]: { $exist: values },
  }),
  integerContains: ({ columnName, value }) => ({
    [columnName]: { $integerContains: `%${sanitizeValue(value)}%` },
  }),
  between: ({ columnName, value }) => {
    if (!value) return {};
    if (!(value instanceof moment)) value = moment(new Date(value));
    const startOfDay = value.startOf("day").toDate();
    const endOfDay = value.endOf("day").toDate();
    return {
      [columnName]: { $lte: endOfDay, $gte: startOfDay },
    };
  },
};
