import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ForgotPasswordPage from "./ForgotPasswordPage";
import { sendPasswordResetEmail } from "../../../actions/authActions";
import SnackNotificationContainer from "../../ui/SnackNotifications/SnackNotificationContainer";

export class ForgotPasswordContainer extends Component {
  state = {
    formikActions: {},
  };

  componentDidUpdate() {
    const { errorPayload } = this.props;
    const { formikActions } = this.state;

    if (errorPayload && formikActions.setSubmitting) {
      this.state.formikActions.setSubmitting(false);
    }
  }

  handleSendPasswordResetEmail = ({ email }, formikActions) => {
    const { callbackUrl, resetPassword } = this.props;
    this.props.sendPasswordResetEmail(email, callbackUrl, resetPassword);
    this.setState({ formikActions });
  };

  handleCancel = email => {
    const url = "/";
    this.props.push(email ? `${url}?email=${encodeURIComponent(email)}` : url);
  };

  render() {
    const { errorPayload, email, loading, resetPassword } = this.props;
    return (
      <Fragment>
        <ForgotPasswordPage
          resetPassword={resetPassword}
          errorPayload={errorPayload}
          handleCancel={this.handleCancel}
          handleSendPasswordResetEmail={this.handleSendPasswordResetEmail}
          defaultEmail={email}
          loading={loading}
        />
        <SnackNotificationContainer />
      </Fragment>
    );
  }
}

ForgotPasswordContainer.propTypes = {
  errorPayload: propTypes.errorPayload,
  push: PropTypes.func.isRequired,
  email: PropTypes.string,
  callbackUrl: PropTypes.string,
  resetPassword: PropTypes.bool,
  loading: PropTypes.bool,
  sendPasswordResetEmail: PropTypes.func.isRequired,
};

export const getAttr = (query, attr) => {
  return query[attr] ? decodeURIComponent(query[attr]) : "";
};

const mapStateToProps = ({ auth, router }) => ({
  resetPassword: getAttr(router.location.query, "resetPassword") === "true",
  email: getAttr(router.location.query, "email"),
  callbackUrl: getAttr(router.location.query, "callback"),
  errorPayload: auth.error,
  loading: auth.loading,
});

const mapDispatchToProps = { sendPasswordResetEmail, push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
