import TextInputWithError from "../../inputs/TextInputWithError";
import SelectWithError from "../../inputs/SelectWithError";
import DatePickerWithError from "../DatePickerWithError";
import AssignToSelect from "../../pages/Notes/NoteCreate/AssignToSelect";
import InputWithMention from "../../pages/Notes/NoteCreate/InputWithMention";
import EmailInput, {
  isValidEmail,
} from "../../pages/Notes/NoteCreate/EmailInput";
import CreatableSelectInput from "../../pages/Notes/NoteCreate/CreatableSelectInput";
import Tag from "../../../models/Tag";

export default {
  project: {
    InputComponent: SelectWithError,
    label: "Select Project",
    name: "projectId",
    dataComponentId: "select-projects",
    fullWidth: true,
    styleMenuOption: true,
    required: true,
    isAutocomplete: true,
    isSingleAutocomplete: true,
  },
  noteHeadline: {
    InputComponent: TextInputWithError,
    label: "Headline",
    name: "subject",
    fullWidth: true,
  },
  noteDetail: {
    InputComponent: InputWithMention,
    label: "Detail",
    name: "detail",
    multiline: true,
    rowsMax: 5,
    fullWidth: true,
    required: true,
  },
  reason: {
    InputComponent: TextInputWithError,
    label: "Reason",
    name: "reason",
    fullWidth: true,
  },
  deliveryNotificationNumber: {
    InputComponent: TextInputWithError,
    label: "Delivery Notification Number",
    name: "deliveryNotificationNumber",
    fullWidth: true,
  },
  noteEmailSentBy: {
    InputComponent: EmailInput,
    label: "Email Sent by",
    name: "emailSentBy",
    dataComponentId: "select-notes-emails-sent-by",
    fullWidth: true,
    isMulti: false,
    validate: isValidEmail,
  },
  noteEmailSentTo: {
    InputComponent: EmailInput,
    label: "Email Sent to",
    name: "emailSentTo",
    dataComponentId: "select-notes-emails-sent-to",
    fullWidth: true,
    submitData: true,
    isMulti: true,
    validate: isValidEmail,
  },
  noteCarrierName: {
    InputComponent: TextInputWithError,
    label: "Carrier",
    name: "carrierName",
    fullWidth: true,
  },
  noteTrackingNumber: {
    InputComponent: TextInputWithError,
    label: "Tracking Number",
    name: "trackingNumber",
    fullWidth: true,
  },
  noteCallWith: {
    InputComponent: TextInputWithError,
    label: "Call With",
    name: "callWith",
    fullWidth: true,
  },
  noteSubjectLine: {
    InputComponent: TextInputWithError,
    label: "Subject Line",
    name: "subject",
    fullWidth: true,
  },
  noteTime: {
    InputComponent: DatePickerWithError,
    label: "Time",
    name: "time",
    pickerType: "dateTime",
    splitDateTimePicker: true,
  },
  noteAssignTo: {
    InputComponent: AssignToSelect,
    name: "assignTo",
    label: "Assign To",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
  },
  tags: {
    InputComponent: CreatableSelectInput,
    name: "tags",
    label: "Tags",
    dataComponentId: "select-tags-creatable-notes",
    fullWidth: true,
    labelProperty: "name",
    model: Tag,
    url: "tags",
    columnNameToSortAndFilter: "name",
    isMulti: true,
  },
};
