import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Paper } from "../mui/core";

import SectionHeader from "../layout/SectionHeader";

const PageSection = ({
  children,
  className,
  useMainheader,
  headerText,
  pageSectionRef,
  noBorder,
  ...props
}) => {
  let subheaderText = useMainheader ? undefined : headerText;
  let header = useMainheader ? headerText : undefined;
  return (
    <Paper ref={pageSectionRef} className={className} noBorder={noBorder}>
      <SectionHeader
        {...props}
        subheaderText={subheaderText}
        headerText={header}
      />
      {children}
    </Paper>
  );
};

PageSection.defaultProps = {
  useMainheader: false,
};

PageSection.propTypes = {
  headerOverride: PropTypes.element,
  headerText: PropTypes.string,
  subheaderText: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.node,
      handler: PropTypes.func,
    })
  ),
  className: PropTypes.string,
  children: PropTypes.node,
  useMainheader: PropTypes.bool,
  pageSectionRef: PropTypes.object,
};

export default styled(PageSection)({});
