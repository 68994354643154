import React from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import BWModels from "benjaminwest-models";
import _isEmpty from "lodash/isEmpty";
import memoizeOne from "memoize-one";

import { Content } from "../../layout/Content";
import Paper from "../../mui/core/Paper";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import Vendor from "../../../models/Vendor";
import propTypes from "../../../constants/propTypes";
import AddressBook from "../AddressBook/AddressBook";
import { genDetailRowComponents } from "../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { RowComponent } from "../../ui/BWGrid/helperComponents/EditableTextDetailRow/genDetailRowComponents/v2";
import VendorStatusTag from "./VendorStatusTag";
import { parseVendorName } from "../VendorCreate/VendorCreateContainer";

export const getRowMenu = (
  handleEditVendor,
  handleDeleteVendorClick
) => row => [
  { text: "Edit", onClick: () => handleEditVendor(row.id) },
  { separator: true },
  {
    text: "Delete",
    onClick: () => handleDeleteVendorClick(row),
  },
];

const getStatusOptions = () => BWModels.loadSchema("Vendor").__utils.statuses;

const relationFilter = ({ value }) => ({
  $or: [
    {
      name: {
        $ilike: `%${parseVendorName(value)}%`,
      },
    },
    {
      poName: {
        $ilike: `%${parseVendorName(value)}%`,
      },
    },
    {
      w9Name: {
        $ilike: `%${parseVendorName(value)}%`,
      },
    },
    {
      brands: {
        $ilikeContains: `%${parseVendorName(value)}%`,
      },
    },
  ],
});

const getTableComponents = memoizeOne((dataComponentId, displayComments) => {
  if (!displayComments) return {};
  return {
    ...genDetailRowComponents(
      "id",
      [
        {
          path: "comments",
          label: "",
          placeholder: "Add Comment",
          isEmptyFn: Boolean,
          showLabel: false,
        },
      ],
      BWModels.loadSchema("Vendor"),
      dataComponentId,
      { prevSpan: 2, colSpan: 2, posSpan: 2 }
    ),
    RowComponent,
  };
});

const VendorsPage = ({
  addressBook,
  dataComponent,
  handleRowClick,
  handleEditVendor,
  handleAddVendorClick,
  handleDeleteVendorClick,
  vendorCategories,
}) => (
  <Content>
    <Paper>
      <BWGridAPI
        apiRoute="vendors"
        model={Vendor}
        includes={["category"]}
        dataComponent={dataComponent}
        defaultSorting={[
          {
            columnName: "name",
            direction: "asc",
          },
        ]}
        apiFilters={{
          params: { omitScopeFilter: true },
        }}
        tableComponents={getTableComponents(
          dataComponent.dataComponentId,
          true
        )}
        alwaysDisplayDetailRow
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Vendors`}
          actions={[
            {
              text: "ADD VENDOR",
              icon: <AddCircle />,
              handler: handleAddVendorClick,
            },
          ]}
        />
        <GridTable
          columns={["name", "status", "category.name", "cleanWebsite"]}
          columnOptions={{
            name: {
              bold: true,
              filter: true,
              filterOptions: {
                operator: relationFilter,
              },
              render: ({ name, brands }) =>
                `${name}${_isEmpty(brands) ? "" : ` (${brands.join(", ")})`}`,
            },
            status: {
              fill: true,
              filter: "multiselect",
              filterOptions: {
                operator: "jsonIn",
                options: getStatusOptions(),
              },
              render: ({ status }) =>
                status &&
                status.map(status => (
                  <VendorStatusTag key={status}>{status}</VendorStatusTag>
                )),
            },
            "category.name": {
              title: "Category",
              align: "right",
              filter: "select",
              filterOptions: {
                options: vendorCategories,
              },
            },
            cleanWebsite: { align: "right", title: "Website" },
          }}
          rowMenu={getRowMenu(handleEditVendor, handleDeleteVendorClick)}
          onClick={handleRowClick}
        />
      </BWGridAPI>
    </Paper>
    <Paper>
      <AddressBook addressBook={addressBook} />
    </Paper>
  </Content>
);

VendorsPage.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  dataComponent: PropTypes.object.isRequired,
  handleEditVendor: PropTypes.func.isRequired,
  handleAddVendorClick: PropTypes.func.isRequired,
  handleDeleteVendorClick: PropTypes.func.isRequired,
  vendorCategories: PropTypes.arrayOf(propTypes.vendorCategory),
  addressBook: PropTypes.object.isRequired,
};
VendorsPage.defaultProps = {
  handleRowClick: () => {},
  dataComponent: {},
  handleEditVendor: () => {},
  handleAddVendorClick: () => {},
  handleDeleteVendorClick: () => {},
  vendorCategories: [],
  addressBook: {},
};

export default VendorsPage;
