import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";

import propTypes from "../constants/propTypes";

const defaultActivities = [];

const isCreated = revisionActivities => currentReference => {
  const item = revisionActivities.find(
    ({ reference, operation }) =>
      reference === currentReference && operation === "create"
  );
  return !!item;
};

const isUpdated = revisionActivities => (
  currentReference,
  currentColumnName
) => {
  const item = revisionActivities.find(
    ({ reference, columnName, operation }) =>
      reference === currentReference &&
      columnName === currentColumnName &&
      operation === "update"
  );
  return !!item;
};

const getDeletedItems = revisionActivities => parentReference => {
  const regexp = new RegExp(`^${parentReference}\\.\\d+$`);
  return revisionActivities
    .filter(
      ({ reference, operation }) =>
        regexp.test(reference) && operation === "delete"
    )
    .map(item => {
      const obj = item.plainObject || { id: "" };
      return { ...obj, id: obj.id.toString(), __deleted__: true };
    });
};

const hasModifiedChildrens = (
  isActive,
  revisionActivities
) => parentReference => {
  if (!isActive) return false;
  const parentRegexp = new RegExp(`^${parentReference}\\.`);
  const ownRegexp = new RegExp(`^${parentReference}$`);
  return !!revisionActivities.find(
    ({ reference }) => parentRegexp.test(reference) || ownRegexp.test(reference)
  );
};

const defaultContextValue = {
  isActive: false,
  activities: [],
  isUpdated: isUpdated(defaultActivities),
  isCreated: isCreated(defaultActivities),
  hasModifiedChildrens: hasModifiedChildrens(false, defaultActivities),
  getDeletedItems: getDeletedItems(defaultActivities),
};

export const PORevisionContext = createContext(defaultContextValue);

/**
 * A public higher-order component to use the PO revision state
 */
export default function(WrappedComponent) {
  const PORevisionWrapper = ({
    isRevisionActive,
    revisionActivities,
    ...props
  }) => {
    const value = useMemo(() => {
      if (!isRevisionActive) return defaultContextValue;
      return {
        isActive: isRevisionActive,
        activities: revisionActivities,
        isUpdated: isUpdated(revisionActivities),
        isCreated: isCreated(revisionActivities),
        hasModifiedChildrens: hasModifiedChildrens(
          isRevisionActive,
          revisionActivities
        ),
        getDeletedItems: getDeletedItems(revisionActivities),
      };
    }, [isRevisionActive, revisionActivities]);

    return (
      <PORevisionContext.Provider value={value}>
        <WrappedComponent {...props} revisionValue={value} />
      </PORevisionContext.Provider>
    );
  };

  PORevisionWrapper.propTypes = {
    purchaseOrderId: PropTypes.string,
    isRevisionActive: PropTypes.bool,
    revisionActivities: PropTypes.arrayOf(propTypes.revisionActivities),
  };
  return PORevisionWrapper;
}
