import React from "react";
import { connect } from "react-redux";
import propTypes from "../../constants/propTypes";
import { roleCan } from "../../utils/roleUtils";

/**
 *
 * @param WrappedComponent
 * @param permissionsList {Array|String} list of permission.name that the wrapped
 * component should be rendered for.
 */
const withRenderOnPermissionActive = (WrappedComponent, permissionsList) => {
  const Wrapper = ({ role, ...props }) => {
    if (!roleCan(role, permissionsList)) return null;
    return <WrappedComponent {...props} />;
  };

  Wrapper.propTypes = {
    role: propTypes.userRole,
  };

  const mapStateToProps = ({ auth: { role } }) => {
    return {
      role,
    };
  };

  return connect(mapStateToProps)(Wrapper);
};

export default withRenderOnPermissionActive;
