import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import ClientProjectsPage from "./ClientProjectsPage";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import Project from "../../../models/Project";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../actions/layoutActions";
import { openNotesModal } from "../../../actions/notesActions";

import { pushWithReturnUrl } from "../../../actions/navigationActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "../../../actions/dataComponentActions";
import { dataComponentProjectUserStarsId } from "../Dashboard/MyProjects/MyProjectsContainer";
import { onHandleTransferProject } from "../Projects/ProjectFunctions";
import { dataComponentId } from "../ProjectEdit/ProjectEditContainer";
import { useDatacomponent } from "../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../hooks/useProcessRequest";

export const ClientProjectsContainer = ({
  performUpdateRequest,
  closeModalDialog,
  ...props
}) => {
  const dataComponent = useDatacomponent(dataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      props.setReload(props.dataComponent.dataComponentId, true);
    },
  });

  return (
    <ClientProjectsPage
      {...props}
      model={Project}
      includes={["property.[location, entity]"]}
      apiRoute="projects"
      transferProject={(project, propertyId) =>
        onHandleTransferProject(
          project,
          propertyId,
          dataComponentId,
          performUpdateRequest,
          closeModalDialog
        )
      }
    />
  );
};
ClientProjectsContainer.propTypes = {
  clientId: PropTypes.string,
  dataComponent: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => ({
  clientId: ownProps.match.params.clientId,
  dataComponent: getDataComponentFlattenedRequestState(
    "ClientProjectsGrid",
    state
  ),
  dataComponentProjectUserStars: getDataComponentFlattenedRequestState(
    dataComponentProjectUserStarsId,
    state
  ),
  loggedInUserId: state.auth.userId,
});

const mapDispatchToProps = {
  push,
  openModalDialog,
  openNotesModal,
  initDataComponent,
  setReload,
  performRetrieveListRequest,
  pushWithReturnUrl,
  performUpdateRequest,
  closeModalDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientProjectsContainer)
);
