import PropTypes from "prop-types";
import styled from "styled-components";
import React, { Fragment } from "react";
import RawButton from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

function Button({ icon, children, ...buttonProps }) {
  return (
    <RawButton {...buttonProps}>
      {icon}
      {children && <div>{children}</div>}
    </RawButton>
  );
}

Button.propTypes = {
  icon: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const StyledButton = styled(Button)`
  padding: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  min-width: auto;
  color: rgba(255, 255, 255, 0.7);
  div {
    margin-left: 8px;
    text-transform: none;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
`;

function ControlButtons({
  className,
  onClose,
  onEdit,
  isSelectCOM,
  currentSelectedId,
}) {
  return (
    <div className={className}>
      {!isSelectCOM && currentSelectedId && (
        <Fragment>
          <StyledButton icon={<Edit />} onClick={onEdit}>
            Edit
          </StyledButton>
        </Fragment>
      )}
      <StyledButton icon={<Close />} onClick={onClose} />
    </div>
  );
}

ControlButtons.propTypes = {
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isSelectCOM: PropTypes.bool,
  onEdit: PropTypes.func,
  currentSelectedId: PropTypes.string,
};

const StyledControlButtons = styled(ControlButtons)`
  height: 40px;
  background-color: #1c3d53;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
`;

export default StyledControlButtons;
