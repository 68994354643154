import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { groupBy } from "lodash";
import { isEmpty } from "lodash";
import styled from "styled-components";

export const Title = styled.p`
  padding-bottom: 14px;
`;

export const CancelCOIssueSummary = ({ invoicePayments = {} }) => {
  const { specPaymentsInChangeOrder, specPrevQuantities } = invoicePayments;
  const canCancelIssue =
    isEmpty(invoicePayments) || isEmpty(specPaymentsInChangeOrder);

  if (canCancelIssue && isEmpty(specPrevQuantities)) {
    return (
      <Title>
        Cancelling Change Order Issue will update the <b>PO Status</b> to{" "}
        <b>CO Pending Approval</b>, keeping the <b>Spec</b> updates as they are
        on issued PO but reverting <b>Spec Shipment</b> quantities to pre-issue
        values.
      </Title>
    );
  }

  const rows = specPrevQuantities;

  const invoices = groupBy(rows, "invoiceNumber");

  return (
    <Fragment>
      <Title>
        Cancelling Change Order Issue will update the <b>PO Status</b> to{" "}
        <b>CO Pending Approval</b>, keeping the <b>Spec</b> updates as they are
        on issued PO but reverting <b>Spec Shipment</b> quantities to pre-issue
        values. The following displays affected <b>Spec Shipments</b> along with
        their pre-issue quantities.
      </Title>
      {Object.keys(invoices).map(invoiceNumber => (
        <Fragment key={invoiceNumber}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td style={{ paddingRight: "24px", width: "65%" }}>
                  <b>Spec Shipments</b>
                </td>
                <td>
                  <b>Current Qty.</b>
                </td>
                <td>
                  <b>Prev. Qty.</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {invoices[invoiceNumber].map(
                (
                  { invoiceNumber, customNumber, currentQuantity, quantity },
                  i
                ) => (
                  <tr key={`${invoiceNumber}-${i}`}>
                    <td>{customNumber}</td>
                    <td>{currentQuantity}</td>
                    <td>{quantity}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Fragment>
      ))}
    </Fragment>
  );
};
CancelCOIssueSummary.propTypes = {
  invoicePayments: PropTypes.object,
};
