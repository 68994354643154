import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import _keyBy from "lodash/keyBy";

import withPagination, {
  PaginationContext,
  PaginationStateContext,
} from "./PaginationControl";
import withFiltering, {
  FilteringContext,
  FilteringStateContext,
} from "./FilteringControl";
import { operationBuildersLocal } from "../../BWGrid/filterFunctions";
import Grid from "../Grid";

export const LocalGrid = ({
  gridId,
  rows,
  columns,
  columnBands,
  columnExtensions,
  dataTypeProviders,
  fixedColumns,
  filteringBuilders,
  filteringDataTypeProviders,
  tableComponents,
  pagingPanelProps,
}) => {
  const {
    paginateResults,
    getPage,
    getPageSize,
    setPage,
    setPageSize,
  } = useContext(PaginationContext);
  const { onFiltersChange, filterResults } = useContext(FilteringContext);

  const { results, paginationState, filteringState } = useMemo(() => {
    const { results: filteredResults, ...filteringState } = filterResults(rows);
    const { results, ...paginationState } = paginateResults(filteredResults);
    return { results, paginationState, filteringState };
  }, [rows, filterResults, paginateResults]);

  const filterProps = useMemo(() => {
    const displayFilters = columns.some(column => column.filter);
    return {
      id: gridId,
      displayFilters,
      columnOptions: _keyBy(columns, "name"),
      onFiltersChange,
      operationBuilders: filteringBuilders,
    };
  }, [columns, gridId, onFiltersChange, filteringBuilders]);

  const pagingStateProps = useMemo(() => {
    return {
      currentPage: getPage(),
      pageSize: getPageSize(),
      onCurrentPageChange: setPage,
      onPageSizeChange: setPageSize,
      total: paginationState.total,
    };
  }, [getPage, getPageSize, setPageSize, setPage, paginationState.total]);

  return (
    <FilteringStateContext.Provider value={filteringState}>
      <PaginationStateContext.Provider value={paginationState}>
        <Grid
          rows={results}
          gridId={gridId}
          columns={columns}
          columnBands={columnBands}
          columnExtensions={columnExtensions}
          dataTypeProviders={dataTypeProviders}
          fixedColumns={fixedColumns}
          filteringDataTypeProviders={filteringDataTypeProviders}
          tableComponents={tableComponents}
          pagingPanelProps={pagingPanelProps}
          filterProps={filterProps}
          pagingStateProps={pagingStateProps}
        />
      </PaginationStateContext.Provider>
    </FilteringStateContext.Provider>
  );
};

LocalGrid.defaultProps = {
  rows: [],
  columns: [],
  filteringBuilders: operationBuildersLocal,
};

LocalGrid.propTypes = {
  gridId: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })
  ),
  columnBands: PropTypes.arrayOf(PropTypes.shape({})), // examples at: https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table-band-header/
  columnExtensions: PropTypes.arrayOf(
    PropTypes.shape({ columnName: PropTypes.string, width: PropTypes.string }) // https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table/#tablecolumnextension
  ),
  dataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  filteringDataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  tableComponents: PropTypes.objectOf(PropTypes.func),
  fixedColumns: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.string),
    right: PropTypes.arrayOf(PropTypes.string),
  }),
  pagingPanelProps: PropTypes.shape({
    hidePaginationControl: PropTypes.bool,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  filteringBuilders: PropTypes.objectOf(PropTypes.func),
};

export default withPagination(withFiltering(LocalGrid));
