import React, { useCallback } from "react";

import {
  formSectionPropTypes,
  FormPageSection,
} from "../../../layout/FormPageSection";
import { createTeamFields } from "./createTeamFields";

const memberTypes = {
  freightVendorId: "Freight Vendor",
  warehouseVendorId: "Warehouse Vendor",
  projectManagerId: "Project Manager",
  designerId: "Interior Designer",
  secondaryDesignerId: "Secondary Interior Designer",
  generalContractorId: "General Contractor",
  otherConsultantId: "Other",
};

const TeamSectionForm = props => {
  const {
    project,
    values,
    touched,
    setFieldValue,
    openModalDialog,
    ...rest
  } = props;

  const syncProjectMemberContacts = useCallback(
    ({ target: { name, value } }) => {
      const clearRelatedContacts = () =>
        setFieldValue(
          "projectMemberContacts",
          values.projectMemberContacts.filter(
            contact => contact.memberType !== memberTypes[name]
          )
        );

      const originalDesignerId = project[name];
      const newDesignerId = value || null;
      const isReplacing = !!newDesignerId;

      const onConfirm = () => {
        setFieldValue(name, newDesignerId);
        clearRelatedContacts();
      };

      if (
        ["designerId", "secondaryDesignerId"].includes(name) &&
        !!originalDesignerId
      ) {
        openModalDialog(
          isReplacing ? "Replace Designer" : "Remove Designer",
          "DesignerConfirmationModal",
          {
            isReplacing,
            projectId: project.id,
            originalDesignerId,
            newDesignerId,
            onConfirm,
          },
          true,
          false
        );
        return true;
      }
      clearRelatedContacts();
    },
    [openModalDialog, project, setFieldValue, values.projectMemberContacts]
  );

  const fields = createTeamFields(values, syncProjectMemberContacts);

  return (
    <FormPageSection
      headerText="Teams"
      isPage={true}
      fields={fields}
      {...rest}
    />
  );
};
TeamSectionForm.propTypes = formSectionPropTypes;

export default TeamSectionForm;
