import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Component } from "react";

import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { openNotesModal } from "../../../../actions/notesActions";
import {
  openModalDialog,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import MyProjects from "./MyProjects";
import propTypes from "../../../../constants/propTypes";
import ProjectUserStar from "../../../../models/ProjectUserStar";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { initUserComponent } from "../../../../actions/usersActions";
import { updateAuthenticatedUser } from "../../../../actions/authActions";
import { onHandleTransferProject } from "../../Projects/ProjectFunctions";

const dataComponentId = "DashboardProjects";
export const dataComponentProjectUserStarsId =
  "dataComponentProjectUserStarsId";

export class MyProjectsContainer extends Component {
  componentDidMount() {
    this.props.initDataComponent(
      dataComponentProjectUserStarsId,
      ProjectUserStar,
      [],
      "project-user-stars",
      true
    );

    this.props.initUserComponent();
  }

  componentDidUpdate({
    dataComponentProjectUserStars: prevDataComponentProjectUserStars,
  }) {
    const { dataComponentProjectUserStars, setReload } = this.props;

    if (
      !prevDataComponentProjectUserStars.reload &&
      dataComponentProjectUserStars.reload === true
    ) {
      this.props.performRetrieveListRequest(dataComponentProjectUserStarsId, {
        rootFilters: {
          $where: { userId: this.props.userId },
        },
      });
      setReload(dataComponentProjectUserStarsId, false);
    }
  }

  handleRowClick = ({ id, property }) => {
    this.props.push(`/clients/${property.entity.clientId}/projects/${id}`);
  };
  handleTransferProject = (project, propertyId) => {
    onHandleTransferProject(
      project,
      propertyId,
      dataComponentId,
      this.props.performUpdateRequest,
      this.props.closeModalDialog
    );
  };
  render() {
    const {
      userId,
      dataComponent,
      openNotesModal,
      openModalDialog,
      pushWithReturnUrl,
      initDataComponent,
      performRetrieveListRequest,
      updateAuthenticatedUser,
      dashboardShowInClosing,
      dashboardShowClosed,
    } = this.props;

    return (
      <MyProjects
        userId={userId}
        initDataComponent={initDataComponent}
        performRetrieveListRequest={performRetrieveListRequest}
        dataComponent={dataComponent}
        handleRowClick={this.handleRowClick}
        openNotesModal={openNotesModal}
        openModalDialog={openModalDialog}
        pushWithReturnUrl={pushWithReturnUrl}
        transferProject={this.handleTransferProject}
        updateAuthenticatedUser={updateAuthenticatedUser}
        dashboardShowInClosing={dashboardShowInClosing}
        dashboardShowClosed={dashboardShowClosed}
      />
    );
  }
}

MyProjectsContainer.propTypes = {
  push: PropTypes.func,
  userId: PropTypes.string,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  dataComponentProjectUserStars: propTypes.dataComponent,
  setReload: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func,
  closeModalDialog: PropTypes.func.isRequired,
  initUserComponent: PropTypes.func.isRequired,
  updateAuthenticatedUser: PropTypes.func.isRequired,
  dashboardShowInClosing: PropTypes.bool,
  dashboardShowClosed: PropTypes.bool,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const dataComponentProjectUserStars = getDataComponentFlattenedRequestState(
    dataComponentProjectUserStarsId,
    state
  );
  return {
    dataComponent,
    dataComponentProjectUserStars,
    userId: state.auth.userId,
    dashboardShowInClosing: state.auth.dashboardShowInClosing,
    dashboardShowClosed: state.auth.dashboardShowClosed,
  };
};

const mapDispatchToProps = {
  push,
  initDataComponent,
  setReload,
  performRetrieveListRequest,
  performUpdateRequest,
  openNotesModal,
  openModalDialog,
  pushWithReturnUrl,
  closeModalDialog,
  initUserComponent,
  updateAuthenticatedUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProjectsContainer);
