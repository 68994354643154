import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ListItemIcon } from "@material-ui/core";
import _noop from "lodash/noop";

import RegularText from "../../../../../ui/Typography/RegularText";
import {
  ArrayIcon,
  BooleanIcon,
  DateIcon,
  EnumIcon,
  JsonIcon,
  NumberIcon,
  TextIcon,
} from "./icons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c7c7c7;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled(ListItemIcon)`
  margin: 0px 12px 0px 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const Name = styled(RegularText)``;

const mapDatabaseType = type => {
  const types = [
    {
      name: "text",
      prefixes: ["character", "text"],
    },
    {
      name: "number",
      prefixes: [
        "integer",
        "decimal",
        "numeric",
        "number",
        "bigint",
        "double precision",
        "real",
        "inet",
      ],
    },
    {
      name: "date",
      prefixes: ["date", "timestamp"],
    },
    {
      name: "boolean",
      prefixes: ["boolean"],
    },
    {
      name: "enum",
      prefixes: ["user-defined"],
    },
    {
      name: "json",
      prefixes: ["jsonb", "json"],
    },
    {
      name: "array",
      prefixes: ["array"],
    },
  ];
  const mappedType = types.find(({ prefixes }) =>
    prefixes.some(prefix =>
      type.toLocaleLowerCase().includes(prefix.toLocaleLowerCase())
    )
  );
  return mappedType?.name;
};

export const TypeIcon = {
  text: TextIcon,
  number: NumberIcon,
  date: DateIcon,
  boolean: BooleanIcon,
  enum: EnumIcon,
  json: JsonIcon,
  array: ArrayIcon,
};

export const ColumnRow = ({ name, type, onClick = _noop, onDoubleClick }) => {
  const Icon = mapDatabaseType(type)
    ? TypeIcon[mapDatabaseType(type)]
    : TypeIcon.text;
  const handleClick = evt => {
    switch (evt.detail) {
      case 1:
        onClick(evt);
        break;
      case 2:
        onDoubleClick(evt);
        break;
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Name>{name}</Name>
    </Wrapper>
  );
};

ColumnRow.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func.isRequired,
};
