import React, { useEffect, useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";

import CardWithGraphic from "./CardWithGraphic";
import propTypes from "../../../../../../constants/propTypes";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import ForecastByAreaSectionBarChart from "./ForecatByAreaSectionBarChart";
export const forecastAreaComponentId = "area-price-component-id";

const format = value => currencyFormatter.format(value || 0);

const ForecastByAreaSection = ({
  actions,
  projectId,
  forecastByAreas,
  areas,
}) => {
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    actions.fetchForecastByArea(projectId, forecastAreaComponentId);
    actions.fetchAreasByProject(projectId);
  }, [actions, projectId]);

  useEffect(() => {
    setFilteredItems(
      areas.map(({ name, id }) => ({
        label: name,
        value: id,
        isChecked: true,
      }))
    );
  }, [areas]);

  const handleToggleItem = item => {
    setFilteredItems(
      filteredItems.map(filteredItem =>
        item.value === `${filteredItem.value}` ? item : filteredItem
      )
    );
  };

  const getForecastTotalById = useCallback(
    id => {
      const forecastByArea = forecastByAreas.find(
        ({ area }) => `${area.id}` === id
      );
      if (!forecastByArea) return;
      return forecastByArea.totalPrice;
    },
    [forecastByAreas]
  );

  const items = useMemo(() => {
    const validIds = filteredItems
      .filter(({ isChecked }) => isChecked)
      .map(({ value }) => value);

    return areas
      .filter(({ id }) => validIds.includes(id))
      .map(({ name, id }) => ({
        label: name,
        value: getForecastTotalById(id),
      }));
  }, [areas, filteredItems, getForecastTotalById]);

  return (
    <CardWithGraphic
      title="Forecast By Area"
      items={items}
      searchItems={filteredItems}
      onChangeFilters={handleToggleItem}
      searchModelName="Area"
      graphItems={items}
      tickFormatter={format}
      tooltipFormatter={value => [format(value), "Total Forecast"]}
      valueFormatter={format}
    >
      <ForecastByAreaSectionBarChart
        data={items}
        tickFormatter={format}
        tooltipFormatter={value => [format(value), "Total Forecast"]}
      />
    </CardWithGraphic>
  );
};

ForecastByAreaSection.propTypes = {
  actions: PropTypes.shape({
    fetchForecastByArea: PropTypes.func,
    fetchAreasByProject: PropTypes.func,
  }).isRequired,
  projectId: PropTypes.string,
  forecastByAreas: PropTypes.array,
  areas: PropTypes.arrayOf(propTypes.area),
};

export default ForecastByAreaSection;
