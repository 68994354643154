import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import {
  RowMenuProvider,
  dateTypeBuilder,
} from "../../../../ui/Grid/utilComponents/dataTypeProviders";
import { StatusTypeProvider } from "./dataTypeProviders";
import LocalGrid from "../../../../ui/Grid/LocalGrid";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

export const downloadFile = row => {
  const downloadLink = document.createElement("a");
  downloadLink.href = row.file.s3SignedUrl;
  downloadLink.download = row.file.filename;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  return downloadLink;
};

const getRowMenu = (
  onReplace,
  onFlag,
  onUnflag,
  onDelete,
  switchFlagDisabled
) => row => {
  return [
    { text: "Replace File", onClick: onReplace, disableIfProjectClosed: true },
    row.isActive
      ? {
          text: "Unflag as Active Contract",
          onClick: onUnflag,
          disabled: switchFlagDisabled,
          disableIfProjectClosed: true,
        }
      : {
          text: "Flag as Active Contract",
          onClick: onFlag,
          disableIfProjectClosed: true,
        },
    {
      text: "Delete File",
      disabled: row.isActive,
      onClick: onDelete,
      disableIfProjectClosed: true,
    },
    { text: "Download File", onClick: downloadFile },
  ];
};

const ContractGrid = ({ rows, onDelete, onFlag, onUnflag, onReplace }) => {
  const activeContracts = rows.filter(row => row.isActive);
  return (
    <LocalGrid
      gridId="submittals-files"
      rows={rows}
      columns={[
        { name: "file.filename", title: "Filename", bold: true },
        { name: "uploadedAt", title: "Date Uploaded" },
        { name: "isActive", title: "Status" },
        {
          type: "rowMenu",
          getCellValue: getRowMenu(
            onReplace,
            onFlag,
            onUnflag,
            onDelete,
            activeContracts.length === 1
          ),
        },
      ]}
      tableComponents={{ GridWrapper: ({ children }) => children }}
      dataTypeProviders={[
        dateTypeBuilder(["uploadedAt"], PRIMARY_DATE_FORMAT),
        RowMenuProvider,
        StatusTypeProvider,
      ]}
    />
  );
};
ContractGrid.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contractFile),
  onDelete: PropTypes.func,
  onFlag: PropTypes.func,
  onUnflag: PropTypes.func,
  onReplace: PropTypes.func,
};

export default ContractGrid;
