import * as BID_TYPES from "../bidTypes";
import * as CELL_TYPES from "./cellTypes";

export const comVendorBackground = "#F1F1F1";

export const getBackGroundColor = bgVendor => {
  if (bgVendor.isAlternateQuote) {
    return "#EDF0F7";
  }

  if (bgVendor && bgVendor.bidType === BID_TYPES.COM_SPEC_ONLY) {
    return comVendorBackground;
  }
  return null;
};
export const buildTotalCell = (
  rowsByType,
  columnName,
  bgVendor,
  vendorIndex,
  defaultProjectCurrency,
  extendedSamplePricingValue,
  extendedProductionPricingValue
) => {
  const { quoteProjectCurrency } = bgVendor;

  rowsByType[columnName][`quote_${vendorIndex}_sampleCOMQuantity`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_samplePricing`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_extendedSamplePricing`] = {
    expanded: false,
    value:
      typeof extendedSamplePricingValue == "number"
        ? extendedSamplePricingValue * quoteProjectCurrency.conversionRate
        : extendedSamplePricingValue,
    type: CELL_TYPES.BOLD,
    projectCurrency: defaultProjectCurrency,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_productionCOMQuantity`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][
    `quote_${vendorIndex}_extendedProductionCOMQuantity`
  ] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_productionPricing`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_extendedProductionPricing`] = {
    expanded: false,
    value: extendedProductionPricingValue * quoteProjectCurrency.conversionRate,
    type: CELL_TYPES.BOLD,
    projectCurrency: defaultProjectCurrency,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_selectCOM`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
  rowsByType[columnName][`quote_${vendorIndex}_chevron`] = {
    expanded: false,
    value: "",
    type: CELL_TYPES.EMPTY_CELL,
    backGroundColor: getBackGroundColor(bgVendor),
  };
};
export const buildFinalTotalCell = (
  bgVendor,
  defaultProjectCurrency,
  hasCOMFunc,
  value
) => {
  const hasCOMs = hasCOMFunc();
  switch (bgVendor.bidType) {
    case BID_TYPES.SPEC_ONLY: {
      if (!hasCOMs) {
        return {
          type: CELL_TYPES.SELECT_COM_FIRST,
          value: "",
          expanded: true,
          projectCurrency: defaultProjectCurrency,
          backGroundColor: getBackGroundColor(bgVendor),
        };
      }
      return {
        type: CELL_TYPES.TOTAL,
        value: value,
        expanded: true,
        projectCurrency: defaultProjectCurrency,
        backGroundColor: getBackGroundColor(bgVendor),
      };
    }
    default: {
      return {
        type: CELL_TYPES.TOTAL,
        value: value,
        expanded: true,
        projectCurrency: defaultProjectCurrency,
        backGroundColor: getBackGroundColor(bgVendor),
      };
    }
  }
};
export const buildExpandedCell = (bgVendor, value, props = {}) => {
  return {
    expanded: true,
    value,
    backGroundColor: getBackGroundColor(bgVendor),
    ...props,
  };
};
export const setDefaultValuesForVendorQuoteCell = (
  rowsByType,
  bgVendor,
  vendorIndex
) => {
  Object.values(rowsByType).forEach(row => {
    row[`quote_${vendorIndex}_sampleCOMQuantity`] = {
      expanded: true,
      value: "",
    };
    row[`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_samplePricing`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_extendedSamplePricing`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_productionCOMQuantity`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_productionPricing`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_extendedProductionPricing`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_selectCOM`] = {
      noRender: true,
    };
    row[`quote_${vendorIndex}_chevron`] = {
      noRender: true,
    };
  });
};
