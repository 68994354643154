import React, { useMemo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { BWGridLocal, GridHeader, GridTable } from "../../../../ui/BWGrid";
import AddCircle from "@material-ui/icons/AddCircle";
import pluralize from "pluralize";

import { RowComponent } from "./components";
import { renderActions } from "./DeleteButtons";
import propTypes from "../../../../../constants/propTypes";
import EditableColumn, {
  StyledEdit,
} from "../../../../ui/BWGrid/columns/EditableColumn";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import { LoaderContext } from "../../../../ui/Loader";

const columns = ["name", "specsCount", "actions"];
const rowInputs = {
  name: {
    InputComponent: TextInputWithError,
    name: "name",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};

const Name = ({ name }) => (
  <EditableColumn placeholder="Write Bid Group Name">{name}</EditableColumn>
);
Name.propTypes = { name: PropTypes.string };

const buildColumnOptions = onToggleAddBidGroup => ({
  name: {
    title: "Name",
    bold: true,
    editable: true,
    filter: "text",
    render: Name,
  },
  specsCount: {
    title: "Specs",
    align: "left",
    fill: true,
  },
  actions: {
    title: " ",
    sortingEnabled: false,
    render: renderActions(onToggleAddBidGroup),
  },
});

const Grid = ({ bidGroups, setAdding, isAdding }) => {
  const { loading } = useContext(LoaderContext);

  const tableComponents = useMemo(
    () => ({
      RowComponent,
      CellComponent: editableCellCreator(
        rowInputs,
        tableRow => `bidGroups.[${tableRow.rowId}].name`
      ),
    }),
    []
  );

  const rows = useMemo(() => {
    if (isAdding) return [{ __fixedTop__: true }, ...bidGroups];
    return bidGroups;
  }, [bidGroups, isAdding]);

  const handleToggleAddBidGroup = useCallback(() => setAdding(!isAdding), [
    setAdding,
    isAdding,
  ]);

  const actions = useMemo(
    () => [
      {
        text: "ADD BID GROUP",
        icon: <AddCircle />,
        handler: handleToggleAddBidGroup,
        disabled: isAdding,
        disableIfProjectClosed: true,
      },
    ],
    [handleToggleAddBidGroup, isAdding]
  );

  const columnOptions = useMemo(
    () => buildColumnOptions(handleToggleAddBidGroup),
    [handleToggleAddBidGroup]
  );

  return (
    <BWGridLocal
      rows={rows}
      gridConfig={{ pageSize: 10, totalRows: rows.length }}
      tableComponents={tableComponents}
      noBorder
      isLoading={loading}
      id="manage-bid-groups"
    >
      <GridHeader
        headerText={`${pluralize("Bid Group", rows.length, true)}`}
        actions={actions}
      />
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGridLocal>
  );
};

Grid.propTypes = {
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  setAdding: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
};

export default Grid;
