import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _omitBy from "lodash/omitBy";
import _isPlainObject from "lodash/isPlainObject";
import BWModels from "benjaminwest-models";

import editSpecFields from "./editSpecFields";
import propTypes from "../../../../../constants/propTypes";
import { withRevisionReference } from "../../../../../withPORevision";
import {
  calculateOverageQuantity,
  calculateTotalQuantity,
} from "../../../Specs/calculations";
import SpecFormikForm from "../../../Specs/SpecFormikForm";
import { listenersPropType } from "../../../../hocs/withRequestListener";

const defaultCalculatedFieldValue = "--";

const omitted = [
  "overageValue",
  "totalQuantity",
  "qtySubtotal",
  "price",
  "totalPrice",
  "totalForecast",
  "isCommitted",
];

export function getOverageValue(overagePercent, baseQuantity, atticStock) {
  if (overagePercent) {
    return calculateOverageQuantity(overagePercent, baseQuantity, atticStock);
  }

  return defaultCalculatedFieldValue;
}
export function getTotalQuantity(baseQuantity, atticStock, overagePercent) {
  let overageValue;
  if (overagePercent) {
    overageValue = calculateOverageQuantity(
      overagePercent,
      baseQuantity,
      atticStock
    );
  }
  if (baseQuantity || atticStock || overageValue) {
    return calculateTotalQuantity(baseQuantity, atticStock, overageValue);
  }

  return defaultCalculatedFieldValue;
}
export function getVendorId(spec) {
  if (spec.purchaseOrder && spec.purchaseOrder.vendor) {
    return spec.purchaseOrder.vendor.id;
  }

  return spec.vendorId;
}
export function shouldDisableVendorField(spec) {
  return !!spec.purchaseOrderId;
}
function QuickEditSpecForm(props) {
  const {
    onSubmit,
    spec,
    setSaveAndNext,
    disableNextButton,
    onCancel,
    loadingSave,
    setRef,
    listeners,
  } = props;

  const initialValues = useMemo(() => {
    const { glCodes, priceCents, ...data } = spec;
    const overageValue = getOverageValue(
      spec.overagePercent / 100,
      spec.baseQuantity,
      spec.atticStock
    );

    const totalQuantity = getTotalQuantity(
      spec.baseQuantity,
      spec.atticStock,
      spec.overagePercent / 100
    );
    const vendorId = getVendorId(spec);

    return {
      vendorId: vendorId,
      glCodes,
      ..._omitBy(
        data,
        (value, name) =>
          value === null ||
          _isPlainObject(value) ||
          Array.isArray(value) ||
          omitted.includes(name)
      ),
      priceCents: (priceCents || 0) / 100,
      totalQuantity: totalQuantity,
      overageValue: overageValue,
    };
  }, [spec]);

  const buildSubmit = (onSubmit, goToNext, ref, customErrorMessage) => (
    ...args
  ) => {
    if (customErrorMessage) return;
    setSaveAndNext(goToNext);
    setRef(ref);
    onSubmit(...args);
  };

  return (
    <SpecFormikForm
      spec={spec}
      initialValues={initialValues}
      onSubmit={onSubmit}
      buildSubmit={buildSubmit}
      validationSchema={BWModels.loadSchema("Spec")}
      createFields={editSpecFields}
      disableVendorField={shouldDisableVendorField(spec)}
      sendButtonText={"Save"}
      saveAndCreateAnotherText={"Save & Next"}
      onCancel={onCancel}
      additionalProps={{ secondary: { disabled: disableNextButton } }}
      loadingSave={loadingSave}
      listeners={listeners}
    />
  );
}

QuickEditSpecForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setSaveAndNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableNextButton: PropTypes.bool,
  spec: propTypes.spec.isRequired,
  loadingSave: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
  listeners: listenersPropType,
};

export default withRevisionReference(QuickEditSpecForm);
