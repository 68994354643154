import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import { handleStatusUpdate } from "./ProjectRequestHandlers";

export const patchProject = (
  project,
  payload,
  dataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(dataComponentId, project.id, payload);
};
export const onHandleTransferProject = (
  project,
  propertyId,
  dataComponentId,
  performUpdateRequest,
  closeModalDialog
) => {
  patchProject(project, { propertyId }, dataComponentId, performUpdateRequest);
  closeModalDialog();
};
export const processUpdateRequestForPatchProject = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  showSnackNotificationAction
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleStatusUpdate(
        closeModalDialog,
        showSnackNotificationAction,
        "The project was successfully transferred."
      );
    },
    onError: () => {
      handleStatusUpdate(
        closeModalDialog,
        showSnackNotificationAction,
        "There was an error transferring the project."
      );
    },
  });
};
