import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../forms/FormGrid";
import useScopedCurrency from "../../../../hooks/useScopedCurrency";
import createContigencyFields from "./contingencyFields";
import { FormikForm, ActionButtons } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";

import CurrencyInput from "../../../../inputs/CurrencyInput";

const ContingencyForm = ({ onSubmitContingency, isEditing, contingency }) => {
  const scopedCurrency = useScopedCurrency();

  const contingencyFields = createContigencyFields(scopedCurrency, event => {
    if (event.target.value === "$") {
      contingencyFields.groups[0].items[2].input.InputProps = {
        inputComponent: CurrencyInput,
      };
      contingencyFields.groups[0].items[2].input.inputProps = {
        allowNegative: true,
      };
    } else {
      delete contingencyFields.groups[0].items[2].input.InputProps;
      delete contingencyFields.groups[0].items[2].input.inputProps;
    }
  });
  return (
    <FormikForm
      initialValues={contingency}
      onSubmit={onSubmitContingency}
      validationSchema={BWModels.loadSchema("Contingency")}
      ignoreValidationFieldNames={["projectId"]}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Fragment>
          <FormGrid fields={contingencyFields} {...formikProps} />
          <ActionButtons
            sendButtonText={isEditing ? "Save" : "Create Contingency"}
            onSend={handleSubmit}
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

ContingencyForm.propTypes = {
  onSubmitContingency: PropTypes.func.isRequired,
  contingency: propTypes.contingency,
  isEditing: PropTypes.bool,
};

export default ContingencyForm;
