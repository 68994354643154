import React, { Fragment, useState } from "react";
import styled from "styled-components";

import { italicTextStyle, textStyle } from "./components";
import { CollapseButton } from "../../../../ui/CollapseButton";

const Wrapper = styled.div`
  margin: 0px 24px 24px 24px;
`;

const TemplateWrapper = styled.div`
  white-space: pre-wrap;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 24px;
  max-height: calc(65vh - 72px);
  overflow-y: auto;

  @media (max-width: 600px) {
    max-height: calc(65vh - 120px);
  }
`;

const CollapseWrapper = styled.div`
  position: absolute;
  right: 40px;
  bottom: 100px;
`;

export const EmailTemplate = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Wrapper>
      <TemplateWrapper>
        <span style={textStyle}>[Recipient Name],</span>
        <br />
        <br />
        <p style={textStyle}>
          Below is a link to an RFQ for the <span>[Project Name]</span>. Please
          confirm receipt by replying to this{isCollapsed ? "..." : " email."}
        </p>
        <CollapseWrapper>
          <CollapseButton
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </CollapseWrapper>
        {!isCollapsed && (
          <Fragment>
            <br />
            <p style={textStyle}>
              PLEASE NOTE: Estimated delivery dates are only place holders.
            </p>
            <br />
            <p style={textStyle}>
              <b>
                **We are now utilizing a new RFQ system. Please follow the link
                below to fill out the RFQ form. The link will no longer be
                available after the specified Due Date of{" "}
                <span>[RFQ Due Date]</span>. Your immediate attention is
                appreciated. Please let us know if you have any questions about
                the new system. Please send a notification email once you have
                filled out and submitted so we may review.**
              </b>
            </p>
            <br />
            <p style={textStyle}>
              RFQ Link: <span>[Vendor Portal Link]</span>
            </p>
            <br />
            <p style={textStyle}>
              If there are any items that are listed as (VERIFY), kindly fill in
              the blanks with the requested information.
            </p>
            <br />
            <p style={textStyle}>
              We request that you only use our form when returning pricing, but
              if you must, you may include your own quote form ONLY if you have
              already filled ours out COMPLETELY. Please fax or email to my
              attention, no later than end of business due date. If meeting this
              due date is a problem, kindly advise.
            </p>
            <br />
            <p style={textStyle}>
              <b>
                *Please be sure to provide production pricing (in addition to
                the model room requested) based on the quantities listed in each
                spec.
              </b>
            </p>
            <br />
            <p style={textStyle}>
              If this RFQ should be forwarded to another party, please advise
              and provide the appropriate contact information (name, email
              address, and phone number).
            </p>
            <br />
            <p style={italicTextStyle}>
              <b>
                The quantities are estimate only and most likely will change.
                Please advise pricing of near increments as well.
              </b>
            </p>
            <br />
            <p style={italicTextStyle}>
              <b>
                We appreciate receiving your best pricing as we will not have
                time to re-bid.
              </b>
            </p>
            <br />
            <p style={textStyle}>Please contact with any questions.</p>
          </Fragment>
        )}
      </TemplateWrapper>
    </Wrapper>
  );
};
