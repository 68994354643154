import React, { useCallback } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import ProjectAddressGrid from "./ProjectAddressGrid";
import { dataComponentIdDeleteSite } from "../../../../actions/projectsActions";

const buildOpenAddAddressHandler = (
  openModalDialog,
  formikArrayHelpers,
  projectId
) => () => {
  openModalDialog(
    "Create Site",
    "CreateEditAddress",
    {
      formikArrayHelpers,
      address: {
        name: undefined,
        projectId,
        location: {
          address: undefined,
          address2: undefined,
          city: undefined,
          zip: undefined,
          country: undefined,
          state: undefined,
        },
        contacts: [],
      },
      submitText: "Site",
      descriptionField: true,
    },
    false,
    false,
    {
      width: "800px",
    }
  );
};

const buildOpenEditAddressHandler = (openModalDialog, formikArrayHelpers) => (
  address,
  { rowId }
) => {
  openModalDialog(
    `Edit Ship to Site ${address.name}`,
    "CreateEditAddress",
    {
      formikArrayHelpers,
      address,
      index: rowId,
      descriptionField: true,
    },
    false,
    false,
    {
      width: "800px",
    }
  );
};

const buildOpenDeleteAddressHandler = (openModalDialog, formikArrayHelpers) => (
  { id, name },
  { rowId }
) => {
  openModalDialog(
    `Delete ${name}`,
    "DeleteAddress",
    {
      addressId: id,
      dataComponentId: dataComponentIdDeleteSite,
      title: name,
      formikArrayHelpers,
      index: rowId,
    },
    false
  );
};

const RenderedField = ({
  formikArrayHelpers,
  rows,
  projectId,
  openModalDialog,
}) => {
  const handleOpenAddModal = useCallback(
    buildOpenAddAddressHandler(openModalDialog, formikArrayHelpers, projectId),
    [openModalDialog, formikArrayHelpers, projectId]
  );

  const handleOpenEditModal = useCallback(
    buildOpenEditAddressHandler(openModalDialog, formikArrayHelpers),
    [openModalDialog, formikArrayHelpers]
  );

  const handleOpenDeleteModal = useCallback(
    buildOpenDeleteAddressHandler(openModalDialog, formikArrayHelpers),
    [openModalDialog, formikArrayHelpers]
  );

  return (
    <ProjectAddressGrid
      rows={rows}
      onOpenAddModal={handleOpenAddModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
    />
  );
};
RenderedField.propTypes = {
  projectId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  rows: PropTypes.arrayOf(propTypes.remitAddress),
  openModalDialog: PropTypes.func.isRequired,
};

export default RenderedField;
