import React, { Component, createContext } from "react";
import memoizeOne from "memoize-one";
import _isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

export const defaultValue = {
  isValid: () => ({ valid: true }),
  options: {},
};

export const WithFloatInputValidatorContext = createContext(defaultValue);

function withFloatInputValidator(WrappedComponent, isValid, options = {}) {
  class FloatInputValidator extends Component {
    getValue = memoizeOne(row => {
      return { isValid: obj => isValid(obj, row), options };
    }, _isEqual);

    render() {
      return (
        <WithFloatInputValidatorContext.Provider
          value={this.getValue(this.props.row)}
        >
          <WrappedComponent {...this.props} />
        </WithFloatInputValidatorContext.Provider>
      );
    }
  }
  FloatInputValidator.propTypes = {
    row: PropTypes.shape({}),
  };
  return FloatInputValidator;
}

export default withFloatInputValidator;
