import React, { useState, useEffect, useCallback, useMemo } from "react";
import _groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UserRoleForm from "../CreateUserRole/UserRoleForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../ui/Loader";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import {
  dataComponentPermissionsId,
  dataComponentId,
} from "../UserRolesTable/UserRolesTableContainer";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const EditUserRoleContainer = ({
  dataComponent,
  closeModalDialog,
  userRole,
  setReload,
  performUpdateRequest,
  permissionsByGroup,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  }, [
    closeModalDialog,
    dataComponent,
    formikActions,
    prevDataComponent,
    setReload,
  ]);

  const handleEditCode = useCallback(
    ({ permissions, currentUsers, ...userRole }, formikActions) => {
      const userRolePermissions = permissions.reduce(
        (result, isActive, permissionId) => {
          return isActive !== undefined
            ? [
                ...result,
                {
                  permissionId,
                  active: isActive,
                },
              ]
            : result;
        },
        []
      );
      performUpdateRequest(dataComponentId, userRole.id, {
        ...userRole,
        userRolePermissions,
      });
      setFormikAction(formikActions);
    },
    [performUpdateRequest]
  );

  const initialValues = useMemo(() => {
    const { userRolePermissions, ...values } = userRole;
    return {
      ...values,
      permissions: (userRolePermissions || []).reduce(
        (result, { permissionId, active }) => {
          result[permissionId] = active;
          return result;
        },
        []
      ),
    };
  }, [userRole]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <UserRoleForm
        initialValues={initialValues}
        onSubmit={handleEditCode}
        permissionsByGroup={permissionsByGroup}
        dataComponentId={dataComponentId}
      />
    </LoaderContext.Provider>
  );
};

EditUserRoleContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  userRole: propTypes.userRole,
  performUpdateRequest: PropTypes.func,
  permissionsByGroup: PropTypes.shape({}),
  loading: PropTypes.bool,
  setReload: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const permissionsDataComponent = getDataComponentFlattenedRequestState(
    dataComponentPermissionsId,
    state
  );
  const permissions =
    getBluechipResources(permissionsDataComponent, state) || [];
  return {
    dataComponent,
    permissionsByGroup: _groupBy(permissions, "permissionGroup.label"),
    loading: dataComponent.loading && permissionsDataComponent.loading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserRoleContainer);
