import React from "react";

import styled from "styled-components";
import { Child, ActionButton } from "../../../../forms/ActionButtons";
import CircularProgress from "@material-ui/core/CircularProgress";

export const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  left: -8px;
  color: rgba(0, 0, 0, 0.54);
`;

const StyledActionButton = styled(ActionButton)`
  color: rgba(0, 0, 0, 0.54);
`;

const ApproveButton = ({ children, loading, ...props }) => {
  return (
    <StyledActionButton className="approve" {...props} loading={loading}>
      {loading && <StyledCircularProgress size={20} />}
      <Child>{children}</Child>
    </StyledActionButton>
  );
};

export default ApproveButton;
