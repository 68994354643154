import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import _isUndefined from "lodash/isUndefined";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import useDebounce from "../../pages/Notes/NoteCreate/InputWithMention/useDebounce";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performRetrieveListRequest,
  destroyDataComponentResource,
} from "../../../actions/dataComponentActions";
import * as RequestTypes from "../../../constants/RequestTypes";
import useScope from "../../hooks/useScope";
import Scope from "../../../models/Scope";

const useInitDataComponent = (
  APIOptions,
  dataComponentId,
  initDataComponent,
  destroyDataComponentResource
) => () => {
  const { model, route, includes = [], omitDestroyDataComponent } = APIOptions;
  initDataComponent(dataComponentId, model, includes, route, true, "v2");
  return () =>
    omitDestroyDataComponent
      ? null
      : destroyDataComponentResource(dataComponentId, RequestTypes.LIST);
};

export const getValueIds = value => {
  return Array.isArray(value)
    ? value.map(currentValue => _get(currentValue, "id", currentValue))
    : _get(value, "id", value);
};

const getScopeFilter = (APIOptions, scopeId) => {
  return scopeId &&
    Scope.filterScopeModels.includes(APIOptions.model.name) &&
    !APIOptions.omitFilterScopeModels
    ? { "scope.id": scopeId }
    : {};
};

const handleFetchItems = (
  APIOptions,
  dataComponentId,
  performRetrieveListRequest,
  value,
  pageSize
) => () => {
  if (APIOptions.fetchOnlyOnce) return;
  if (value && value !== "-1" && !["undefined", "null"].includes(value)) {
    const mapIds = getValueIds(value);
    performRetrieveListRequest(dataComponentId, {
      pageSize: pageSize,
      ...APIOptions,
      rootFilters: {
        $where: {
          id: Array.isArray(mapIds) ? { $in: mapIds } : mapIds,
        },
      },
    });
  }
};

const preventReload = (APIOptions, debounceQuery, query) =>
  _isUndefined(APIOptions) ||
  APIOptions.fetchOnlyOnce ||
  _isUndefined(debounceQuery) ||
  debounceQuery !== query;

const reFetch = (shouldFetch, reloadDataComponent) => {
  if (shouldFetch) {
    reloadDataComponent("");
  }
};

const getLabelProperty = (labelProperty, apiFilterLabelProperty) =>
  typeof labelProperty === "function" ? apiFilterLabelProperty : labelProperty;

function withAPIFilter(WrappedComponent, pageSize = 10) {
  const APIFilter = ({
    dataComponent = {},
    performRetrieveListRequest,
    initDataComponent,
    destroyDataComponentResource,
    APIOptions,
    apiFilterLabelProperty,
    office,
    ...props
  }) => {
    const { dataComponentId, value } = props;
    const labelProperty = getLabelProperty(
      props.labelProperty,
      apiFilterLabelProperty
    );
    const [query, setQuery] = useState("");
    const debounceQuery = useDebounce(query, 700);
    const additionalFilter = useMemo(
      () => _get(APIOptions, "rootFilters.$where", {}),
      [APIOptions]
    );
    const additionalSort = useMemo(() => _get(APIOptions, "sort", []), [
      APIOptions,
    ]);
    const scope = useScope();

    const scopeFilter = useMemo(
      () => getScopeFilter(APIOptions, scope?.id || office?.scopeId),
      [APIOptions, office, scope]
    );

    const reloadDataComponent = useCallback(
      query => {
        performRetrieveListRequest(dataComponentId, {
          pageSize,
          ...APIOptions,
          rootFilters: {
            $where: {
              [labelProperty]: {
                $ilike: `%${query}%`,
              },
              ...additionalFilter,
              ...scopeFilter,
            },
          },
          sort: additionalSort,
        });
      },
      [
        APIOptions,
        additionalFilter,
        additionalSort,
        dataComponentId,
        labelProperty,
        performRetrieveListRequest,
        scopeFilter,
      ]
    );

    useEffect(
      useInitDataComponent(
        APIOptions,
        dataComponentId,
        initDataComponent,
        destroyDataComponentResource
      ),
      [APIOptions, dataComponentId, initDataComponent]
    );

    useEffect(
      handleFetchItems(
        APIOptions,
        dataComponentId,
        performRetrieveListRequest,
        value,
        pageSize
      ),
      [APIOptions, dataComponentId, performRetrieveListRequest, value]
    );

    useEffect(() => {
      reFetch(APIOptions.fetchOnlyOnce, reloadDataComponent);
    }, [APIOptions.fetchOnlyOnce, reloadDataComponent]);

    useEffect(() => {
      if (preventReload(APIOptions, debounceQuery, query)) return;
      reloadDataComponent(debounceQuery);
    }, [APIOptions, debounceQuery, query, reloadDataComponent, value]);

    const handleInputChange = useCallback(query => setQuery(query), []);

    const handleChange = useCallback(
      event => {
        props.onChange(event);

        if (_isEmpty(event.target.value)) {
          reFetch(!APIOptions.fetchOnlyOnce, reloadDataComponent);
        }
      },
      [APIOptions, props, reloadDataComponent]
    );

    const handleMenuOpen = event => {
      props.onMenuOpen && props.onMenuOpen(event);

      reFetch(!APIOptions.fetchOnlyOnce, reloadDataComponent);
    };

    return (
      <WrappedComponent
        {...props}
        onMenuOpen={handleMenuOpen}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isLoading={!!dataComponent.loading}
      />
    );
  };

  const mapDispatchToProps = {
    initDataComponent,
    performRetrieveListRequest,
    destroyDataComponentResource,
  };

  const mapStateToProps = (state, { dataComponentId }) => {
    const dataComponent = getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    );
    return {
      dataComponent,
      office: state.auth?.office,
    };
  };

  const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(APIFilter);

  const Wrapper = ({ APIOptions, ...props }) =>
    APIOptions ? (
      <ConnectedComponent APIOptions={APIOptions} {...props} />
    ) : (
      <WrappedComponent {...props} />
    );

  return Wrapper;
}

export default withAPIFilter;
