import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _omitBy from "lodash/omitBy";

import {
  initDataComponent,
  performRetrieveListRequest,
  setPage,
  setReload,
} from "../../../../actions/dataComponentActions";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";

import { handleError } from "../NoteCreate/NoteCreateContainer";
import { LoaderContext } from "../../../ui/Loader/Loader";
import propTypes from "../../../../constants/propTypes";
import TablePagination from "../../../mui/core/TablePagination";
import { buildQuery } from "./queryFunctions";
import Note from "../../../../models/Note";
import Tag from "../../../../models/Tag";
import ListHeader from "./ListHeader";
import NoteList, { List } from "./NoteList";

export const dataComponentId = "NoteListDataComponent";

export const handleSuccess = setReload => () => {
  setReload(dataComponentId, true);
};

const validateDeleteStatus = (
  setReload,
  showSnackNotificationAction,
  dataComponent,
  prevDataComponent
) => () => {
  return processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(setReload),
    onError: handleError(showSnackNotificationAction),
  });
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: 0;
  margin-right: 24px;
`;

const StyledPaginationControlDiv = styled.div`
  height: 55px;
  min-height: 55px;
  display: flex;
  align-items: center;
`;

const initComponent = (initDataComponent, performRetrieveListRequest) => () => {
  initDataComponent(
    dataComponentId,
    Note,
    [
      "author",
      "project",
      "purchaseOrders.vendor",
      "shipments.spec",
      "specs",
      "invoices",
      "tags",
      "users",
    ],
    "notes",
    false,
    "v2"
  );
  initDataComponent("notes-tags-list", Tag, [], "tags");
  performRetrieveListRequest("notes-tags-list");
};

const shouldFetchNotes = (loaded, reload, wasAlreadyLoaded) => {
  return !loaded || reload || !wasAlreadyLoaded.current;
};

const checkFetchNotes = (
  loaded,
  shouldReload,
  setShouldReload,
  setReload,
  performRetrieveListRequest,
  filters,
  loggedInUserId,
  page,
  context,
  wasAlreadyLoaded
) => () => {
  if (shouldFetchNotes(loaded, shouldReload, wasAlreadyLoaded)) {
    performRetrieveListRequest(dataComponentId, {
      rootFilters: {
        $where: _omitBy(
          buildQuery(filters, context, {
            loggedInUserId,
          }),
          _isNil
        ),
      },
      sort: [{ columnName: "time", direction: "desc" }],
      pageNumber: wasAlreadyLoaded.current ? page : 0,
    });
    setShouldReload(false);
    setReload(dataComponentId, false);
    wasAlreadyLoaded.current = true;
  }
};

const resetPage = (notesState, reload, setPage, filters, context) => () => {
  if (notesState.filters !== filters || notesState.context !== context) {
    setPage(dataComponentId, 0);
  }
};

export const NoteListContainer = ({
  initDataComponent,
  dataComponent,
  performRetrieveListRequest,
  totalRows,
  page,
  loading,
  notes,
  loggedInUserId,
  setPage,
  reload,
  setReload,
  filters,
  context,
}) => {
  const wasAlreadyLoaded = useRef(false);
  const [loaded, setLoaded] = useState(_isEmpty(context));
  const [shouldReload, setShouldReload] = useState(reload);
  const [notesState, setNotesState] = useState({ filters, context, page });
  const [displayFilters, setDisplayFilters] = useState(false);

  useEffect(initComponent(initDataComponent, performRetrieveListRequest), [
    initDataComponent,
    performRetrieveListRequest,
  ]);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(
    validateDeleteStatus(
      setReload,
      showSnackNotificationAction,
      dataComponent,
      prevDataComponent
    ),
    [dataComponent, setReload, showSnackNotificationAction]
  );

  useEffect(
    checkFetchNotes(
      loaded,
      shouldReload,
      setShouldReload,
      setReload,
      performRetrieveListRequest,
      filters,
      loggedInUserId,
      page,
      context,
      wasAlreadyLoaded
    ),
    [
      loaded,
      shouldReload,
      setShouldReload,
      setReload,
      performRetrieveListRequest,
      filters,
      loggedInUserId,
      page,
      context,
    ]
  );

  useEffect(() => {
    if (
      notesState.page !== page ||
      (page === 0 &&
        (notesState.filters !== filters || notesState.context !== context))
    ) {
      setNotesState({ context, filters, page });
      setShouldReload(true);
    }
  }, [notesState, context, filters, page]);

  useEffect(resetPage(notesState, reload, setPage, filters, context), [
    notesState,
    reload,
    setPage,
    filters,
    context,
  ]);

  useEffect(() => {
    if (!loaded) setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (reload) setShouldReload(true);
  }, [reload]);

  const handleChangePage = useCallback(
    pageNumber => {
      setPage(dataComponentId, pageNumber);
    },
    [setPage]
  );

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Wrapper>
        <ListHeader
          totalRows={totalRows}
          displayFilters={displayFilters}
          setDisplayFilters={setDisplayFilters}
        />
        <List filtersAreActives={displayFilters}>
          <NoteList
            notes={notes}
            requestInitialSearch={!wasAlreadyLoaded.current}
          />
        </List>
        <StyledPaginationControlDiv>
          <TablePagination
            count={totalRows}
            page={page}
            onChangePage={handleChangePage}
            withoutPageSizeSelection
          />
        </StyledPaginationControlDiv>
      </Wrapper>
    </LoaderContext.Provider>
  );
};

NoteListContainer.defaultProps = {
  totalRows: 0,
  page: 0,
  loading: true,
};

NoteListContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  totalRows: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  reload: PropTypes.bool,
  notes: PropTypes.arrayOf(propTypes.note),
  setPage: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  loggedInUserId: PropTypes.string,
  filters: propTypes.notesFilters,
  context: propTypes.notesContext,
  dataComponent: PropTypes.shape({}),
};

const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  return {
    dataComponent: getDataComponent(dataComponentId, state),
    notes: dataComponent.loading
      ? undefined
      : getBluechipResources(dataComponent, state) || [],
    totalRows: dataComponent.totalRows,
    loading: dataComponent.loading,
    loggedInUserId: state.auth.userId,
    page: dataComponent.pageNumber,
    reload: dataComponent.reload,
    filters: state.notes.filters,
    context: state.notes.context,
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  setPage,
  setReload,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteListContainer);
