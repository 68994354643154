import React, { useMemo, Fragment } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import _orderBy from "lodash/orderBy";
import BWModels from "benjaminwest-models";

import FormikForm from "../../forms/FormikForm";
import HeaderSection from "./HeaderSection";
import DetailsSection from "./DetailsSection";
import QuoteSection from "./QuoteSection";
import PONotesSection from "./PONotesSection";
import IssuedHistory from "./IssuedHistory";
import Specs from "./Specs";
import { Wrapper, MainSection, BottomSection } from "./components";
import ShippingContactSection from "./ShippingContactSection";
import propTypes from "../../../constants/propTypes";
import { Content } from "../../layout/Content";
import AddNote from "./AddNote";
import VendorNotes from "./DetailsSection/VendorNotes";
import ChageOrderDetails from "./ChageOrderDetails";
import POInvoicesContainer from "./Invoices/POInvoicesContainer";
import { isPOChangeDisabled } from "./utils";
import SpecShipmentsContainer from "../ProjectDetail/Shipments/SpecShipments/SpecShipmentsContainer";
import { dataComponentId } from "./connect";
import DirtyAutoSaveFormPrompt from "../../forms/DirtyAutoSaveFormPrompt";
import { useLoadedProject } from "../../hooks/useIsProjectClosed";

export const getInitialValues = purchaseOrder => {
  const { currentRevision = {}, vendor = {}, ...po } = purchaseOrder;
  const { notes, type } = currentRevision;
  const { discounts = [] } = vendor;
  po.discounts = discounts.map(discount => {
    return {
      purchaseOrderId: purchaseOrder.id,
      discountId: discount.discountId,
    };
  });
  return { ...po, currentRevision: { notes, type } };
};

const noDelayFields = [
  "vendorId",
  "status",
  "shipAddressId",
  "shipVendorAddressId",
  "shipToSite",
  "purchaseOrderDiscounts",
  "revisionStatus",
  "freightVendorId",
  "designerId",
  "statusComment",
  "miscellaneous",
  "notes",
  "currentRevision",
  "showManagerRoutingInstructions",
];

const PurchaseOrderDetailPage = ({
  purchaseOrder,
  purchaseOrderContacts,
  openSubmittalsManagerModal,
  handleDownloadPDF,
  onOpenNotesModal,
  clientId,
  purchaseOrderId,
  onSubmit,
  onOpenApprovalManagerClick,
  openModalDialog,
  closeModalDialog,
  onRevert,
  onCancel,
  nextPOId,
  lastPOId,
  projectId,
  loading,
}) => {
  const schema = BWModels.loadSchema("PurchaseOrder");

  const initialValues = useMemo(() => getInitialValues(purchaseOrder), [
    purchaseOrder,
  ]);

  const visibleRevisions = useMemo(
    () =>
      _orderBy(_get(purchaseOrder, "revisions", []), "version", "desc").filter(
        revision => !!revision.issueDate
      ),
    [purchaseOrder]
  );

  const { projectCurrency = {} } = purchaseOrder;

  const project = useLoadedProject();

  return (
    <Content>
      <FormikForm
        initialValues={initialValues}
        valueOverrides={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        autoSave
        enableReinitialize
        noDelayFields={noDelayFields}
        delayAutoSave={4000}
      >
        {formikProps => (
          <Fragment>
            <HeaderSection
              clientId={clientId}
              purchaseOrder={purchaseOrder}
              purchaseOrderContacts={purchaseOrderContacts}
              openSubmittalsManagerModal={openSubmittalsManagerModal}
              onOpenNotesModal={onOpenNotesModal}
              handleDownloadPDF={handleDownloadPDF}
              onOpenApprovalManagerClick={onOpenApprovalManagerClick}
              openModalDialog={openModalDialog}
              closeModalDialog={closeModalDialog}
              onRevert={onRevert}
              onCancel={onCancel}
              nextPOId={nextPOId}
              lastPOId={lastPOId}
              project={project}
              loading={loading}
              {...formikProps}
            />
            <Wrapper>
              <MainSection>
                <ChageOrderDetails
                  {...formikProps}
                  purchaseOrder={purchaseOrder}
                />
                {visibleRevisions.length > 0 && (
                  <IssuedHistory
                    purchaseOrder={purchaseOrder}
                    handleDownloadPDF={handleDownloadPDF}
                    revisions={visibleRevisions}
                    loading={loading}
                  />
                )}
                <DetailsSection
                  purchaseOrder={purchaseOrder}
                  projectId={projectId}
                  projectCurrencies={project?.projectCurrencies}
                  {...formikProps}
                />
                <QuoteSection purchaseOrder={purchaseOrder} {...formikProps} />
              </MainSection>
              <ShippingContactSection
                formikFormValues={formikProps.values}
                purchaseOrder={purchaseOrder}
                {...formikProps}
              />
            </Wrapper>
            <DirtyAutoSaveFormPrompt dataComponentId={dataComponentId} />
          </Fragment>
        )}
      </FormikForm>
      <Wrapper>
        <BottomSection>
          <Specs
            purchaseOrder={purchaseOrder}
            clientId={clientId}
            purchaseOrderId={purchaseOrderId}
            isPOChangeDisabled={isPOChangeDisabled(purchaseOrder)}
          />
          {purchaseOrder.status === "Issued" && (
            <SpecShipmentsContainer
              purchaseOrder={purchaseOrder}
              isPODetailPage={true}
            />
          )}
          <FormikForm
            initialValues={initialValues}
            valueOverrides={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema}
            autoSave
            enableReinitialize
          >
            {formikProps => (
              <Fragment>
                <POInvoicesContainer
                  purchaseOrder={purchaseOrder}
                  projectCurrency={projectCurrency}
                />
                <VendorNotes purchaseOrder={purchaseOrder} {...formikProps} />
                <PONotesSection
                  purchaseOrder={purchaseOrder}
                  {...formikProps}
                />
                <AddNote purchaseOrder={purchaseOrder} loading={loading} />
                <DirtyAutoSaveFormPrompt dataComponentId={dataComponentId} />
              </Fragment>
            )}
          </FormikForm>
        </BottomSection>
      </Wrapper>
    </Content>
  );
};

PurchaseOrderDetailPage.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  openSubmittalsManagerModal: PropTypes.func,
  onOpenApprovalManagerClick: PropTypes.func,
  onOpenNotesModal: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  purchaseOrderId: PropTypes.string,
  handleDownloadPDF: PropTypes.func,
  onSubmit: PropTypes.func,
  onRevert: PropTypes.func,
  onCancel: PropTypes.func,
  nextPOId: PropTypes.string,
  lastPOId: PropTypes.string,
  project: propTypes.project,
  projectId: PropTypes.string,
  loading: PropTypes.bool,
};

export default PurchaseOrderDetailPage;
