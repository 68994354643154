import React, { Component, createContext, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _noop from "lodash/noop";

import propTypes from "../../../constants/propTypes";
import LoginPage from "./LoginPage";
import { verifyEmail, login } from "../../../actions/authActions";
import SnackNotificationContainer from "../../ui/SnackNotifications/SnackNotificationContainer";
import { getAttr } from "../ForgotPassword/ForgotPasswordContainer";

export const getInitialPage = (role, callbackUrl) => {
  if (callbackUrl) return callbackUrl;
  if (!role) return "/";
  if (role.name === "Client Portal") return "/projects";
  if (role.name === "Vendor Portal") return "/rfqs";
  return "/dashboard";
};

const isCodeSentToEmail = role =>
  ["Client Portal", "Vendor Portal"].some(roleName => roleName === role.name);

const shouldVerifyTwoFactor = (prevToken, token, verifyTwoFactor) =>
  !verifyTwoFactor && !prevToken && token;

export const LoginContext = createContext({
  emailVerified: false,
  onSubmit: _noop,
  handleForgotPassword: _noop,
  handleBack: _noop,
  loading: false,
  defaultEmail: null,
  verifyTwoFactor: false,
  sentToEmail: false,
});

export class LoginContainer extends Component {
  static propTypes = {
    emailVerified: PropTypes.bool,
    errorPayload: propTypes.errorPayload,
    loading: PropTypes.bool,
    token: PropTypes.string,
    email: PropTypes.string,
    callbackUrl: PropTypes.string,
    push: PropTypes.func.isRequired,
    verifyEmail: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    role: propTypes.userRole,
  };

  state = {
    formikActions: {},
    verifyTwoFactor: false,
    emailVerified: false,
  };

  componentDidUpdate({
    token: prevToken,
    loading: prevLoading,
    emailVerified: prevEmailVerified,
  }) {
    const { errorPayload, token, emailVerified } = this.props;
    const { formikActions, verifyTwoFactor } = this.state;

    if (shouldVerifyTwoFactor(prevToken, token, verifyTwoFactor)) {
      this.setState({ verifyTwoFactor: true });
    } else if (prevLoading && errorPayload && formikActions.setSubmitting) {
      this.state.formikActions.setSubmitting(false);
    }

    if (prevEmailVerified !== emailVerified) {
      this.setState({
        emailVerified,
      });
    }
  }

  handleSubmit = ({ email, password }, formikActions) => {
    const { callbackUrl } = this.props;

    this.setState({ formikActions });

    if (this.state.emailVerified) {
      this.props.login(email, password, callbackUrl);
      return;
    }

    this.props.verifyEmail(email, callbackUrl);
  };

  handleForgotPassword = email => {
    const { callbackUrl } = this.props;
    const url = `/forgotPassword?callback=${callbackUrl}&resetPassword=true`;
    this.props.push(`${url}&email=${encodeURIComponent(email)}`);
  };

  handleBack = () => {
    this.setState({
      emailVerified: false,
    });
  };

  render() {
    const { errorPayload, loading, email, role } = this.props;
    return (
      <Fragment>
        <SnackNotificationContainer />
        <LoginContext.Provider
          value={{
            emailVerified: this.state.emailVerified,
            loading,
            verifyTwoFactor: this.state.verifyTwoFactor,
            sentToEmail: isCodeSentToEmail(role),
            defaultEmail: email,
            handleForgotPassword: this.handleForgotPassword,
            errorPayload,
            onSubmit: this.handleSubmit,
            handleBack: this.handleBack,
          }}
        >
          <LoginPage errorPayload={errorPayload} />
        </LoginContext.Provider>
      </Fragment>
    );
  }
}

export const mapStateToProps = ({ auth, router }) => {
  return {
    errorPayload: auth.error,
    emailVerified: auth.emailVerified,
    loading: auth.loadingAuthentication || auth.verifyingEmail,
    token: auth.token,
    email: getAttr(router.location.query, "email"),
    callbackUrl: getAttr(router.location.query, "callback"),
    role: auth.role,
  };
};

const mapDispatchToProps = { verifyEmail, login, push };

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
