import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useCreateProcessRequests } from "../../../../../hooks/useProcessRequest";
import propTypes from "../../../../../../constants/propTypes";
import PaymentForm from "./PaymentForm";
import PaymentsList from "./PaymentsList";

export const dataComponentIdCreate = "FundingCreate";

const FeePayments = ({
  fee,
  feePaymentComponent,
  createFeePayment,
  closeModalDialog,
  performAfterCreatePayment,
  showSnackNotificationAction,
}) => {
  const [showList, setShowList] = useState(true);

  const feePaymentRef = useRef();

  useCreateProcessRequests(feePaymentComponent, {
    onSuccess: () => {
      performAfterCreatePayment && performAfterCreatePayment(fee);
      toggleShowList();
    },
    onError: error => {
      showSnackNotificationAction(error.title);
    },
  });

  const toggleShowList = feePayment => {
    feePaymentRef.current = undefined;
    if (feePayment?.id) {
      feePaymentRef.current = feePayment;
    }
    setShowList(!showList);
  };

  if (!fee.id) {
    closeModalDialog();
  }

  return (
    <Fragment>
      {showList ? (
        <PaymentsList toggleShowList={toggleShowList} fee={fee || {}} />
      ) : (
        <PaymentForm
          dataComponent={feePaymentComponent}
          fee={fee}
          feePayment={feePaymentRef.current}
          toggleShowList={toggleShowList}
          onSubmit={createFeePayment}
          showSnackNotificationAction={showSnackNotificationAction}
        />
      )}
    </Fragment>
  );
};

FeePayments.defaultProps = {
  fee: {
    feePayments: [],
  },
};

FeePayments.propTypes = {
  fee: propTypes.fee,
  updateFee: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  feePaymentComponent: propTypes.component,
  createFeePayment: PropTypes.func,
  closeModalDialog: PropTypes.func,
  performAfterCreatePayment: PropTypes.func,
  showSnackNotificationAction: PropTypes.func,
};

export default FeePayments;
