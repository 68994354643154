import { createGlobalStyle } from "styled-components";
import DomineRegular from "./assets/fonts/Domine/Domine-Regular.ttf";
import OpenSans from "./assets/fonts/open-sans/OpenSans-Regular.ttf";
import OpenSansBold from "./assets/fonts/open-sans/OpenSans-Bold.ttf";

export const ThemeStyle = createGlobalStyle`
@font-face {
  font-family: Domine;
  src: url(${DomineRegular});
}

@font-face {
  font-family: "Open Sans";
  src: url(${OpenSans});
}

@font-face {
  font-family: "Open Sans Bold";
  src: url(${OpenSansBold});
}
`;
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0 !important;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: rgb(241, 241, 241);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  div {
    margin: 0;
  }

  *:focus {
    outline: none;
  }

  #app {
    height: 100%;
  }
`;
