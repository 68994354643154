import styled from "styled-components";
import { ListItemText } from "@material-ui/core";
import React from "react";

export default styled(props => (
  <ListItemText classes={{ primary: "primary" }} {...props} />
))`
  margin: 0;
  padding: 0;

  & .primary {
    font-family: "Open Sans", sans-serif;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }
`;
