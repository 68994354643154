import Joi from "joi-browser";

const JoiInstance = Joi.extend(joi => ({
  base: joi.string().valid(Joi.ref("password")),
  name: "resetPassword",
  coerce(value, state, options) {
    const {
      parent: { password },
    } = state;
    if (value !== password) {
      return this.createError("resetPassword.invalid", {}, state, options);
    }
    return value;
  },
}));
const passwordValidation = Joi.string()
  .regex(/^(?=.*\d).{8,32}$/)
  .required();

export default Joi.object({
  oldPassword: passwordValidation,
  password: passwordValidation,
  passwordConfirmation: JoiInstance.resetPassword().required(),
});
