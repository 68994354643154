import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListItem, ListItemText, Menu } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Person from "@material-ui/icons/Person";

import Loader from "../../../ui/Loader";
import {
  HeaderIconsContainer,
  iconStyle,
  UserMenuIcon,
  UserMenuItem,
} from "../components";
import SwitchPortalMenuItem from "./SwitchPortalMenuItem";

const PersonIconWrapper = styled.div`
  margin-right: 20px;
  @media (min-width: 600px) {
    display: none;
  }
`;
const PersonIcon = styled(Person)({
  ...iconStyle,
  margin: "0",
});
const DropDownIcon = styled(ArrowDropDown)`
  margin-left: 8px;
  font-size: 24px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MenuItem = styled(UserMenuItem)`
  padding-top: 16px;
  padding-bottom: 16px;
  height: unset;
`;

const UserName = styled(props => (
  <ListItemText classes={{ primary: "primary" }} {...props} />
))`
  padding: 0 0 0 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 600px) {
    display: none;
  }
  & .primary {
    padding: 0;
    color: rgb(255, 255, 255);
    line-height: 26px;
    display: inline;
  }
`;

export const UserMenuButton = styled(ListItem)`
  width: auto;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
`;

export function UserOptions({ onClick, authenticatedUserName }) {
  return (
    <Tooltip title="User Options">
      <UserMenuButton button={true} aria-owns="user_menu" onClick={onClick}>
        <Loader
          loading={!authenticatedUserName}
          width="150px"
          height="26px"
          inverted
        >
          <PersonIconWrapper>
            <PersonIcon />
          </PersonIconWrapper>
          <UserName id="user_name" primary={authenticatedUserName} />
          <DropDownIcon />
        </Loader>
      </UserMenuButton>
    </Tooltip>
  );
}
UserOptions.propTypes = {
  onClick: PropTypes.func.isRequired,
  authenticatedUserName: PropTypes.string,
};

export function HeaderMenu({
  onLogout,
  anchorElement,
  onClose,
  settingsMenuItem,
}) {
  return (
    <Menu
      id="user_menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={onClose}
    >
      {settingsMenuItem}
      <MenuItem onClick={onLogout}>
        <UserMenuIcon>
          <RemoveCircle />
        </UserMenuIcon>
        Log Out
      </MenuItem>
      <SwitchPortalMenuItem onClose={onClose} />
    </Menu>
  );
}

HeaderMenu.propTypes = {
  onLogout: PropTypes.func,
  onOpenSettings: PropTypes.func,
  anchorElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onClose: PropTypes.func,
  settingsMenuItem: PropTypes.node,
};

function AlternatePortalHeader({
  onClick,
  authenticatedUserName,
  anchorElement,
  onCloseUserMenu,
  onLogout,
  settingsMenuItem,
}) {
  return (
    <HeaderIconsContainer>
      <UserOptions
        onClick={onClick}
        authenticatedUserName={authenticatedUserName}
      />
      <HeaderMenu
        onLogout={onLogout}
        onClose={onCloseUserMenu}
        anchorElement={anchorElement}
        settingsMenuItem={settingsMenuItem}
      />
    </HeaderIconsContainer>
  );
}

AlternatePortalHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  authenticatedUserName: PropTypes.string,
  anchorElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onCloseUserMenu: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onLogout: PropTypes.func,
  settingsMenuItem: PropTypes.node,
};

export default AlternatePortalHeader;
