import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";

import propTypes from "../../../constants/propTypes";
import { Header } from "./Header";
import { logout, openAccountSettingsModal } from "../../../actions/authActions";
import { openModalDialog } from "../../../actions/layoutActions";
import { openNotesModal } from "../../../actions/notesActions";
import { getInitialPage } from "../../pages/Login/LoginContainer";

export const handleOpenGenerateCustomReports = openModalDialog => () => {
  openModalDialog(
    "Generate Custom/Query Report",
    "GenerateCustomReport",
    {},
    false,
    true,
    {
      width: "629px",
    }
  );
};

export const HeaderContainer = ({
  openNotesModal,
  title,
  autoSaveRequestState,
  autoSaveQueueState,
  isDetailPage,
  isNavbarDrawerOpen,
  isCellNumberVerified,
  name,
  email,
  logout,
  push,
  goBack,
  openAccountSettingsModal,
  handleDrawerToggle,
  role,
  openModalDialog,
}) => {
  const handleGoBack = () => {
    goBack();
  };
  const handleGoHome = () => {
    push(getInitialPage(role));
  };

  const handleOpenSettings = () => {
    openAccountSettingsModal(name, email);
  };

  const handleOpenModal = () => {
    openNotesModal();
  };

  const onOpenGenerateCustomReport = useCallback(
    handleOpenGenerateCustomReports(openModalDialog),
    [openModalDialog]
  );

  return (
    <Header
      text={title}
      autoSaveRequestState={autoSaveRequestState}
      autoSaveQueueState={autoSaveQueueState}
      isDetailPage={isDetailPage}
      isNavbarDrawerOpen={isNavbarDrawerOpen}
      isCellNumberVerified={isCellNumberVerified}
      authenticatedUserName={name}
      logout={logout}
      onOpenNotesModal={handleOpenModal}
      onOpenSettings={handleOpenSettings}
      onGoHome={handleGoHome}
      handleDrawerToggle={handleDrawerToggle}
      handleGoBack={handleGoBack}
      useDarkBackground={role.name !== "Vendor Portal"}
      onOpenGenerateCustomReport={onOpenGenerateCustomReport}
      role={role}
    />
  );
};

HeaderContainer.propTypes = {
  title: PropTypes.string,
  token: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  isDetailPage: PropTypes.bool,
  isNavbarDrawerOpen: PropTypes.bool,
  isCellNumberVerified: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
  openAccountSettingsModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  autoSaveRequestState: propTypes.requestState,
  autoSaveQueueState: PropTypes.shape({}),
  role: PropTypes.shape({}),
  handleDrawerToggle: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { token, name, email, role, isCellNumberVerified },
    layout,
  } = state;
  return {
    token,
    name,
    email,
    role,
    title: layout.headerTitle || ownProps.headerTitle,
    isCellNumberVerified,
  };
};

const mapDispatchToProps = {
  logout,
  openNotesModal,
  openAccountSettingsModal,
  push,
  goBack,
  openModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
