import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../constants/propTypes";
import {
  DetailAndCycle,
  NavigationArea,
  TitleArea,
} from "../../layout/DetailAndCycle";

const BidGroupHeader = ({
  nextBidGroupId,
  lastBidGroupId,
  clientId,
  projectId,
  bidGroup,
}) => {
  return (
    <DetailAndCycle>
      <NavigationArea
        nextItemRoute={
          nextBidGroupId &&
          `/clients/${clientId}/projects/${projectId}/quoting/bid-groups/${nextBidGroupId}/vendors`
        }
        lastItemRoute={
          lastBidGroupId &&
          `/clients/${clientId}/projects/${projectId}/quoting/bid-groups/${lastBidGroupId}/vendors`
        }
      />
      <TitleArea title={`BG ${bidGroup.nameWithSequenceIdentifier}`} />
    </DetailAndCycle>
  );
};

BidGroupHeader.propTypes = {
  nextBidGroupId: PropTypes.string,
  lastBidGroupId: PropTypes.string,
  bidGroup: propTypes.bidGroup,
  projectId: PropTypes.string,
  clientId: PropTypes.string,
};

export default BidGroupHeader;
