import React, { useCallback } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { CompareArrows } from "@material-ui/icons";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import { useLoadedProject } from "../../../../hooks/useIsProjectClosed";
import { useLabeledText } from "../../../../inputs/TextInputWithError";

const Wrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 1;
`;

export const getNextDesignerId = (designerId, project) => {
  if (designerId == project?.designerId) {
    return project?.secondaryDesignerId;
  }
  if (designerId == project?.secondaryDesignerId) {
    return project?.designerId;
  }
  return project?.designerId || project?.secondaryDesignerId;
};

const getTitle = (nextDesignerId, project) => {
  if (nextDesignerId == project?.designerId) {
    return "Switch to Primary Designer";
  }

  if (nextDesignerId == project?.secondaryDesignerId) {
    return "Switch to Secondary Designer";
  }
};

export const SwitchDesigner = ({ designerId }) => {
  const project = useLoadedProject();

  const { setFieldValue } = useFormikContext();
  const labeledText = useLabeledText();

  const nextDesignerId = getNextDesignerId(designerId, project);
  const title = getTitle(nextDesignerId, project);

  const handleSwitchDesigner = useCallback(() => {
    setFieldValue("designerId", nextDesignerId);
  }, [nextDesignerId, setFieldValue]);

  if (!project || !nextDesignerId) {
    return null;
  }

  return (
    <Wrapper>
      <IconButton
        aria-label="Toggle visibility"
        onClick={handleSwitchDesigner}
        disabled={labeledText}
      >
        <Tooltip title={title}>
          <CompareArrows />
        </Tooltip>
      </IconButton>
    </Wrapper>
  );
};
SwitchDesigner.propTypes = {
  designerId: PropTypes.string,
};
