import React from "react";
import PropTypes from "prop-types";

import { GridLocalSection } from "../../../layout/GridLocalSection";
import propTypes from "../../../../constants/propTypes";
import useScopedCurrency from "../../../hooks/useScopedCurrency";

export const renderUnit = scopedCurrency => ({ unit = "" }) =>
  unit.replace("$", scopedCurrency.symbol);

const columns = ["contingencyType", "name", "unit", "value"];
const columnsOptions = scopedCurrency => {
  return {
    name: { bold: true },
    unit: {
      width: "100px",
      align: "center",
      render: renderUnit(scopedCurrency),
    },
    value: { fill: true },
  };
};
const rowMenu = (onEdit, onDelete) => [
  { text: "Edit", onClick: onEdit, disableIfProjectClosed: true },
  { separator: true },
  { text: "Delete", onClick: onDelete, disableIfProjectClosed: true },
];

const ContingenciesSection = ({
  rows,
  onOpenAddModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  const scopedCurrency = useScopedCurrency();
  return (
    <GridLocalSection
      id="contigencies-section"
      columns={columns}
      columnsOptions={columnsOptions(scopedCurrency)}
      rowMenu={rowMenu(onOpenEditModal, onOpenDeleteModal)}
      gridConfig={{ pageSize: 0, totalRows: rows.length }}
      rows={rows}
      addButtonText="ADD CONTINGENCY"
      gridHeaderText="Contingencies"
      addClick={onOpenAddModal}
    />
  );
};
ContingenciesSection.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contingency),
  onOpenAddModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default ContingenciesSection;
