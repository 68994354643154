import React, { createContext, memo } from "react";
import _get from "lodash/get";
import { useIsProjectClosed } from "../components/hooks/useIsProjectClosed";

// import PropTypes from "prop-types";

export const LabeledTextContext = createContext(false);

/**
 * A public higher-order component to disable multiple fields
 */
function withLabeledTextOption(WrappedComponent) {
  const LabeledTextWrapper = ({ purchaseOrder, initialValues, ...props }) => {
    const status = _get(purchaseOrder, "status");
    const revisionStatus = _get(purchaseOrder, "revisionStatus");
    const awardedTo = _get(initialValues, "awardedBidGroupVendorSpecQuoteId");
    const isProjectClosed = useIsProjectClosed();
    const value =
      awardedTo || (status === "Issued" && !revisionStatus) || isProjectClosed;

    return (
      <LabeledTextContext.Provider value={value}>
        <WrappedComponent
          purchaseOrder={purchaseOrder}
          initialValues={initialValues}
          {...props}
        />
      </LabeledTextContext.Provider>
    );
  };

  LabeledTextWrapper.propTypes = {};
  return memo(LabeledTextWrapper);
}
export default withLabeledTextOption;
