import { createContext } from "react";
import memoize from "memoize-one";
import queryString from "query-string";
import _get from "lodash/get";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performDeleteRequest,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import {
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import {
  addSpecDetailsComponentId,
  initSpecDetail,
  updateSpec,
} from "../../../actions/specDetailActions";
import { openNotesModal } from "../../../actions/notesActions";
import { push } from "connected-react-router";
import {
  openDuplicateModalAction,
  openDeleteModalAction,
} from "../../../actions/specsActions";

const dataComponentSettingsId = "specDetailsSettings";
export const dataComponentId = "SpecDetail";
export const specDetailsChildrenDataComponentId =
  "SpecDetail-SpecDetailsChildren";
export const specDetailComDataComponentId = "COMLibrary-specDetailCom";

export const PageContext = createContext({});

export const mapStateToProps = () => {
  const parseSpec = memoize(spec => {
    if (!spec) return null;
    return spec.specDetails ? spec : { ...spec, specDetails: [] };
  });

  return (state, ownProps) => {
    const { returnUrl, sectionName = "" } = queryString.parse(location.search);
    const backTo = {
      backText: `BACK TO ${sectionName.toUpperCase()}`,
      backRoute: returnUrl,
    };
    const {
      specId,
      clientId,
      projectId,
      bidGroupVendorId,
    } = ownProps.match.params;
    const query = queryString.parse(ownProps.location.search);

    const dataComponent = getDataComponentFlattenedRequestState(
      dataComponentId,
      state,
      REQUEST_TYPES.FIND
    );

    const specDetailsChildrenDataComponent = getDataComponent(
      specDetailsChildrenDataComponentId,
      state
    );

    const specDetailsComDataComponent = getDataComponent(
      specDetailComDataComponentId,
      state
    );

    const addSpecDetailsDataComponent = getDataComponent(
      addSpecDetailsComponentId,
      state
    );

    const spec = parseSpec(
      getBluechipResourceById(dataComponent, state, specId)
    );

    const dataComponentSettings = getDataComponentFlattenedRequestState(
      dataComponentSettingsId,
      state,
      REQUEST_TYPES.UPDATE
    );

    return {
      layout: state.layout,
      specId,
      loading: dataComponent.loading,
      updateLoading: dataComponentSettings.loading,
      clientId,
      projectId,
      spec,
      purchaseOrderId: _get(spec, "purchaseOrderId"),
      nextSpecId: _get(dataComponent, "links.next.meta.id"),
      lastSpecId: _get(dataComponent, "links.last.meta.id"),
      role: state.auth.role,
      backTo: query["back-to"] || backTo,
      specDetailsChildrenDataComponent,
      specDetailsComDataComponent,
      addSpecDetailsDataComponent,
      bidGroupVendorId,
    };
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  initSpecDetail,
  performRetrieveListRequest,
  performUpdateRequest,
  performFindRequest,
  updateSpec,
  performDeleteRequest,
  openModalDialog,
  setAutoSaveComponentId,
  openNotesModal,
  push,
  openDuplicateModalAction,
  openDeleteModalAction,
};
