import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import { Field, connect } from "formik";

import InputWrapper from "../../../../../inputs/InputWrapper";
import Loader from "../../../../../ui/Loader";
import TextInputWithError from "../../../../../inputs/TextInputWithError";
import RegularText from "../../../../../ui/Typography/RegularText";
import { hasError } from "../../../../../../utils/formValidationUtils";
import { Link, StyledSpan, ValueWrapper } from "../components";

const defaultProps = {
  fullWidth: true,
  multiline: true,
  resize: "vertical",
  rows: 2,
  ignoreCaption: true,
  ignoreLabeledText: true,
};

export const Value = ({ helperText, isReadOnly, value, onClick }) => {
  const handleClick = useCallback(() => onClick(), [onClick]);
  return (
    <ValueWrapper
      onClick={isReadOnly ? undefined : handleClick}
      isReadOnly={isReadOnly}
    >
      <RegularText as="span">{helperText}</RegularText> {!value ? " " : `-`}
      <StyledSpan>{!value ? "" : `${value}`}</StyledSpan>
    </ValueWrapper>
  );
};

Value.propTypes = {
  helperText: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export const Input = connect(({ onToggleEditing, formik, ...props }) => {
  const { handleSubmit, setFieldValue } = formik;
  const inputRef = useRef(null);

  useEffect(() => {
    const elem = inputRef.current;
    elem.focus();
    setTimeout(() => {
      elem.selectionStart = elem.selectionEnd = elem.value.length;
    }, 0);
  }, []);

  const handleBlur = useCallback(
    ({ target: { value } }) => {
      setFieldValue(props.name, value);
      handleSubmit();
      onToggleEditing(!value);
    },
    [handleSubmit, onToggleEditing, props.name, setFieldValue]
  );

  const InputProps = useMemo(
    () => ({
      disableUnderline: true,
      onBlur: handleBlur,
    }),
    [handleBlur]
  );

  return (
    <InputWrapper {...props} InputProps={InputProps} inputRef={inputRef} />
  );
});

const buildToggleDisplayLink = (
  isReadOnly,
  actions,
  dataComponentId,
  isEditing,
  setDisplayActionLink
) => displayLink => {
  if (isReadOnly) return;
  if (displayLink) setDisplayActionLink(true);
  actions.setEditableTextRow(dataComponentId, !isEditing);
};

const valueChangeEffect = ({
  value,
  prevValue,
  setDisplayActionLink,
}) => () => {
  if (value !== prevValue.current) {
    if (value) {
      setDisplayActionLink(false);
    }
    prevValue.current = value;
  }
};

const TextField = ({
  path,
  value,
  helperText,
  isEditing,
  dataComponentId,
  actions,
  isReadOnly,
  spanRef,
}) => {
  const prevValue = useRef(value);
  const [displayActionLink, setDisplayActionLink] = useState(!value);

  useEffect(valueChangeEffect({ value, prevValue, setDisplayActionLink }), [
    value,
  ]);

  const handleToggleDisplayLink = useCallback(() => {
    setDisplayActionLink(!displayActionLink);
    actions.setEditableTextRow(dataComponentId, true);
  }, [dataComponentId, displayActionLink, actions]);

  const handleToggleIsEditing = useCallback(
    buildToggleDisplayLink(
      isReadOnly,
      actions,
      dataComponentId,
      isEditing,
      setDisplayActionLink
    ),
    [isReadOnly, actions, dataComponentId, isEditing]
  );

  if (displayActionLink && !isReadOnly) {
    return (
      <Link onClick={handleToggleDisplayLink}>
        <Loader width="300px">Add {helperText}</Loader>
      </Link>
    );
  }

  if (!isEditing) {
    return (
      <Value
        isReadOnly={isReadOnly}
        onClick={handleToggleIsEditing}
        value={value}
        helperText={helperText}
      />
    );
  }

  const clientHeight = spanRef?.current?.parentElement?.clientHeight;
  const height = clientHeight ? Math.ceil(clientHeight) : null;

  return (
    <Fragment>
      <Field name={path}>
        {({ field, form }) => (
          <Input
            {...defaultProps}
            InputComponent={TextInputWithError}
            name={path}
            {...field}
            hasError={hasError(path, form)}
            onToggleEditing={handleToggleIsEditing}
            height={height}
          />
        )}
      </Field>
    </Fragment>
  );
};

TextField.propTypes = {
  path: PropTypes.string,
  helperText: PropTypes.string,
  isEditing: PropTypes.bool,
  actions: PropTypes.shape({
    setEditableTextRow: PropTypes.func,
  }),
};

export default TextField;
