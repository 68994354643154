import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DiscountForm from "../CreateDiscount/DiscountForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import discountFields from "../CreateDiscount/programDiscountFields";
import vendorDiscountFields from "../CreateDiscount/vendorDiscountFields";

export const dataComponentId = "DiscountsGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const EditDiscountContainer = ({
  dataComponent,
  closeModalDialog,
  discount,
  setReload,
  performUpdateRequest,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  });

  const handleEditCode = useCallback(
    ({ program, ...discount }, formikActions) => {
      performUpdateRequest(dataComponentId, discount.id, discount);
      setFormikAction(formikActions);
    },
    [performUpdateRequest]
  );
  const getDiscountFields = () => {
    if (discount.type === "Vendor") {
      return vendorDiscountFields;
    }
    return discountFields;
  };

  return (
    <DiscountForm
      initialValues={discount}
      onSubmit={handleEditCode}
      discountFields={getDiscountFields()}
      dataComponentId={dataComponent.dataComponentId}
    />
  );
};

EditDiscountContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  discount: propTypes.discount,
  performUpdateRequest: PropTypes.func,
  setReload: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDiscountContainer);
