import inputs from "../../../../../components/inputs/inputConfigs/specCategory";

export default {
  groups: [
    {
      items: [
        {
          input: inputs.specCategoryName,
          grid: { xs: 6 },
        },
        {
          input: inputs.specCategoryType,
          grid: { xs: 6 },
        },
        {
          input: inputs.specCategoryKey,
          grid: { xs: 6 },
        },
        {
          input: inputs.isActive,
          grid: { xs: 6 },
        },
      ],
    },
  ],
};
