import React from "react";
import PropTypes from "prop-types";

import Paper from "../../../../mui/core/Paper";
import { vendorQuoteDataComponentId } from "../../../../../actions/vendorPortalRFQActions";

import SectionHeader from "../../../../layout/SectionHeader";
import { ActionButtons } from "../../../../forms";
import { submittionDetails } from "./quoteFields";
import FormGrid from "../../../../forms/FormGrid";
import QuoteFileGrid from "./QuoteFilesGrid";
import { CancelButton } from "../../../../forms/ActionButtons";

function SubmissionDetails({
  values,
  quoteIsOpen,
  quoteIsOver,
  onSubmit,
  onReturn,
}) {
  const disabled = quoteIsOver || !quoteIsOpen;
  return (
    <Paper>
      <SectionHeader headerText="Submission Details" />
      <FormGrid fields={submittionDetails(!quoteIsOpen)} />
      <QuoteFileGrid values={values} />
      <ActionButtons
        sendButtonText="Submit"
        onSend={onSubmit}
        hideCancelButton
        isModal
        additionalProps={{ send: { disabled } }}
        listeners={[vendorQuoteDataComponentId]}
      >
        <CancelButton onClick={onReturn} disabled={disabled}>
          Back to RFQs
        </CancelButton>
      </ActionButtons>
    </Paper>
  );
}

SubmissionDetails.propTypes = {
  values: PropTypes.shape({}),
  isDraft: PropTypes.bool,
  quoteIsOpen: PropTypes.bool,
  quoteIsOver: PropTypes.bool,
  onSubmit: PropTypes.func,
  onReturn: PropTypes.func,
};

export default SubmissionDetails;
