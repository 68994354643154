import { createSelector } from "reselect";
import { connect } from "react-redux";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { extractRelationsFromSearch } from "../../NoteList/queryFunctions";
import AssingToSelect from "./AssignToSelect";
import { fetchAssignToRelations } from "../../../../../actions/notesActions";

const getShipments = (state, { shipmentComponent }) =>
  getBluechipResources(shipmentComponent, state) || [];

const getPurchaseOrders = (state, { purchaseComponent }) =>
  getBluechipResources(purchaseComponent, state) || [];

const getSpecs = (state, { specComponent }) =>
  getBluechipResources(specComponent, state) || [];

const getInvoices = (state, { invoiceComponent }) =>
  getBluechipResources(invoiceComponent, state) || [];

export const generateValues = (
  purchaseOrders = [],
  invoices = [],
  specs = [],
  shipments = []
) => {
  const POValues = purchaseOrders
    .filter(purchaseOrder => !!purchaseOrder)
    .filter(({ status }) => status !== "Canceled")
    .map(({ id, name }) => ({
      rawId: id,
      name,
      id: name,
      type: "PO",
    }));

  const invoicesValues = invoices
    .filter(invoice => !!invoice)
    .map(({ id, number }) => ({
      rawId: id,
      name: `INV #${number}`,
      id: `INV #${number}`,
      type: "INV",
    }));

  const specsValues = specs
    .filter(spec => !!spec)
    .map(({ id, customNumber }) => ({
      rawId: id,
      name: `SPEC #${customNumber}`,
      id: `SPEC #${customNumber}`,
      type: "SPEC",
    }));

  const shipmentValues = shipments
    .filter(shipment => !!shipment)
    .map(({ id, number }) => ({
      rawId: id,
      name: `SHIP #${number}`,
      id: `SHIP #${number}`,
      type: "SHIP",
    }));
  return [...POValues, ...invoicesValues, ...specsValues, ...shipmentValues];
};

const getResources = createSelector(
  getPurchaseOrders,
  getInvoices,
  getSpecs,
  getShipments,
  generateValues
);

export const dataComponentIds = {
  purchaseComponent: "select-purchase-orders",
  invoiceComponent: "select-invoices",
  specComponent: "select-specs",
  shipmentComponent: "select-shipments",
};

export const mapStateToProps = state => {
  const dataComponents = Object.keys(dataComponentIds).reduce(
    (sources, key) => {
      sources[key] = getDataComponentFlattenedRequestState(
        dataComponentIds[key],
        state
      );
      return sources;
    },
    {}
  );

  const relations = extractRelationsFromSearch(state.notes.filters.search) || {
    po: [],
    inv: [],
    spec: [],
    ship: [],
  };
  return {
    options: getResources(state, dataComponents),
    relations,
    isLoading:
      Object.values(dataComponents).some(
        dataComponent => dataComponent.loading
      ) || state.notes.isRelationsLoading,
    isRelationsLoading: state.notes.isRelationsLoading,
    search: state.notes.filters.search,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  fetchAssignToRelations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssingToSelect);
