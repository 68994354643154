import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import propTypes from "../../../../../constants/propTypes";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { LoaderContext } from "../../../../ui/Loader";
import QuickEditSpecForm from "./QuickEditSpecForm";
import withPORevision, {
  LabeledTextContext,
} from "../../../../../withPORevision";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import {
  mapStateToProps,
  mapDispatchToProps,
  PODataComponentId,
} from "./connect";
import { isPOChangeDisabled } from "../../utils";
import { cleanCurrencyValue } from "../../../../../utils/currencyFormatter";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

export const processRequestStatus = (
  { dataComponent, prevDataComponent, dataComponentId },
  { purchaseOrder, nextSpec, clientId, ref },
  {
    closeModalDialog,
    setReload,
    performFindRequest,
    openQuickEditModalAction,
    destroyDataComponent,
    getNextSpecId,
    fetchPurchaseOrder,
  },
  formikActions,
  revisionValue,
  isSaveAndNext
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      if (isSaveAndNext) {
        ref?.current?.scrollTo(0, 0);
        openQuickEditModalAction(
          dataComponentId,
          nextSpec,
          clientId,
          getNextSpecId
        );
      } else {
        closeModalDialog();
        setReload(dataComponentId, true);
        destroyDataComponent(`${dataComponentId}Clone`);
      }

      if (purchaseOrder) {
        fetchPurchaseOrder(purchaseOrder.id, purchaseOrder.projectId);
      }

      if (revisionValue.isActive) {
        performFindRequest(
          PODataComponentId,
          purchaseOrder.id,
          {},
          { omitDefaultModifier: true }
        );
      }
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const findPO = (initDataComponent, performFindRequest, purchaseOrderId) => {
  if (purchaseOrderId) {
    initDataComponent(
      PODataComponentId,
      PurchaseOrder,
      ["currentRevision.revisionActivities", "vendor"],
      "purchase-orders",
      true,
      undefined
    );
    performFindRequest(
      PODataComponentId,
      purchaseOrderId,
      {},
      { omitDefaultModifier: true }
    );
  }
};

const submit = async (
  cloneDataComponentId,
  data,
  spec,
  revisionValue,
  quickUpdateSpec
) => {
  const dataToSent = { ...data };
  if (dataToSent.priceCents) {
    dataToSent.priceCents = cleanCurrencyValue(dataToSent.priceCents * 100);
  }
  const params = revisionValue.isActive ? { update_po_revision: true } : {};
  quickUpdateSpec(cloneDataComponentId, spec.id, spec.projectId, dataToSent, {
    ...params,
    omitDefaultModifier: true,
    modifiers: [
      "withQuantityPrice",
      "withTotalQuantityPaid",
      "withTotalQuantityShipped",
      "withAssignedToBidGroup",
    ],
  });
};

export const QuickEditSpecContainer = ({
  cloneDataComponent,
  dataComponentId,
  revisionValue,
  purchaseOrder,
  purchaseOrderId,
  specId,
  projectId,
  clientId,
  nextSpecId,
  getNextSpecId,
  performFindRequest,
  openQuickEditModalAction,
  destroyDataComponent,
  quickUpdateSpec,
  initDataComponent,
  initQuickEditSpecForm,
  closeModalDialog,
  setReload,
  spec,
  nextSpec,
  loading,
  loadingSave,
  fetchPurchaseOrder,
  initQuickEditSpec,
  cloneDataComponentId,
}) => {
  const [formikActions, setFormikActions] = useState(null);
  const [prevDataComponent, setPrevDatacomponent] = useState(
    cloneDataComponent
  );
  const [isSaveAndNext, setSaveAndNext] = useState(false);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    initQuickEditSpec(specId, dataComponentId, cloneDataComponentId);
  }, [cloneDataComponentId, dataComponentId, initQuickEditSpec, specId]);

  useEffect(() => {
    setPrevDatacomponent(cloneDataComponent);
  }, [cloneDataComponent]);

  useEffect(() => {
    processRequestStatus(
      { dataComponent: cloneDataComponent, prevDataComponent, dataComponentId },
      { purchaseOrder, nextSpec, clientId, ref },
      {
        performFindRequest,
        closeModalDialog,
        setReload,
        openQuickEditModalAction,
        destroyDataComponent,
        getNextSpecId,
        fetchPurchaseOrder,
      },
      formikActions,
      revisionValue,
      isSaveAndNext
    );
  }, [
    closeModalDialog,
    openQuickEditModalAction,
    destroyDataComponent,
    setReload,
    dataComponentId,
    cloneDataComponent,
    prevDataComponent,
    purchaseOrder,
    formikActions,
    revisionValue,
    performFindRequest,
    isSaveAndNext,
    nextSpec,
    clientId,
    getNextSpecId,
    fetchPurchaseOrder,
    ref,
  ]);

  useEffect(() => {
    initQuickEditSpecForm(specId, projectId, clientId, dataComponentId);
  }, [initQuickEditSpecForm, specId, projectId, clientId, dataComponentId]);

  useEffect(
    () => findPO(initDataComponent, performFindRequest, purchaseOrderId),
    [initDataComponent, performFindRequest, purchaseOrderId]
  );

  const handleSubmit = useCallback(
    (data, formikActions) => {
      submit(cloneDataComponentId, data, spec, revisionValue, quickUpdateSpec);
      setFormikActions(formikActions);
    },
    [cloneDataComponentId, spec, revisionValue, quickUpdateSpec]
  );

  const handleCancel = useCallback(() => {
    closeModalDialog();
    if (isSaveAndNext) setReload(dataComponentId, true);
    destroyDataComponent(`${dataComponentId}Clone`);
  }, [
    isSaveAndNext,
    setReload,
    dataComponentId,
    destroyDataComponent,
    closeModalDialog,
  ]);

  const isProjectClosed = useIsProjectClosed();

  return (
    <LabeledTextContext.Provider
      value={isPOChangeDisabled(purchaseOrder) || isProjectClosed}
    >
      <LoaderContext.Provider value={{ loading }}>
        <QuickEditSpecForm
          spec={spec}
          onSubmit={handleSubmit}
          setSaveAndNext={setSaveAndNext}
          setRef={setRef}
          disableNextButton={!nextSpecId}
          revisionReference={`specs.${_get(spec, "id")}`}
          onCancel={handleCancel}
          loadingSave={loadingSave}
          listeners={[cloneDataComponentId, dataComponentId]}
        />
      </LoaderContext.Provider>
    </LabeledTextContext.Provider>
  );
};

QuickEditSpecContainer.propTypes = {
  spec: propTypes.spec.isRequired,
  nextSpec: propTypes.spec,
  purchaseOrder: propTypes.purchaseOrder,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  destroyDataComponent: PropTypes.func.isRequired,
  openQuickEditModalAction: PropTypes.func.isRequired,
  quickUpdateSpec: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  initQuickEditSpecForm: PropTypes.func.isRequired,
  cloneDataComponent: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  purchaseOrderId: PropTypes.string,
  specId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  nextSpecId: PropTypes.string,
  getNextSpecId: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
  loadingSave: PropTypes.bool,
  fetchPurchaseOrder: PropTypes.func,
  initQuickEditSpec: PropTypes.func,
  cloneDataComponentId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(QuickEditSpecContainer));
