import BWInfiniteScroll from "../components/ui/BWInfiniteScroll";
import {
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "./dataComponentActions";
import { closeModalDialog } from "./layoutActions";

export const expeditingPOList = "Expediting-PurchaseOrderList";

export function getLoadNextPOPage(projectId, filters = {}) {
  return function loadNext(pageNumber) {
    return performRetrieveListRequest(expeditingPOList, {
      rootFilters: {
        $where: { projectId, status: "Issued", ...filters },
      },
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: BWInfiniteScroll.pageSize,
      pageNumber,
      params: {
        omitDefaultModifier: true,
        modifiers: ["withBasicFields", "withNeedsFollowUp"],
      },
    });
  };
}

export function saveSpecStatusComment(
  selectedSpecShipments,
  statusComment,
  specDataComponentId,
  parentDataComponentId,
  overwriteComments
) {
  return async dispatch => {
    const specs = selectedSpecShipments
      .filter(({ statusComment }) => overwriteComments || !statusComment)
      .map(specShipment => ({ id: specShipment.id, statusComment }));
    await dispatch(performUpdateRequest(specDataComponentId, specs));
    dispatch(setReload(parentDataComponentId, true));
    dispatch(closeModalDialog());
  };
}

export function savePurchaseOrderStatusComment(
  purchaseOrderId,
  statusComment,
  purchaseOrderDataComponentId,
  parentDataComponentId,
  overwriteComments
) {
  return async dispatch => {
    await dispatch(
      performUpdateRequest(purchaseOrderDataComponentId, purchaseOrderId, {
        statusComment,
        overwriteComments,
      })
    );
    dispatch(setReload(parentDataComponentId, true));
    dispatch(closeModalDialog());
  };
}
