import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { withSelectedRows } from "../../../ui/BWGrid";
import ActionButtons from "../../../forms/ActionButtons";
import ContactsList from "./ContactsList";
import propTypes from "../../../../constants/propTypes";
import { Footer } from "../AddVendorSpec/components";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const AddVendorContact = ({
  selectedIds,
  bidGroup,
  closeModalDialog,
  onUpdateBidGroupVendor,
  contacts,
  selectedContacts,
  loading,
}) => {
  const isProjectClosed = useIsProjectClosed();

  const handleSend = useCallback(() => {
    onUpdateBidGroupVendor(selectedIds);
  }, [onUpdateBidGroupVendor, selectedIds]);

  return (
    <div>
      <ContactsList
        loading={loading}
        selectedIds={selectedIds}
        selectedContacts={selectedContacts}
        bidGroup={bidGroup}
        contacts={contacts}
        onUpdateBidGroupVendor={onUpdateBidGroupVendor}
      />
      <Footer>
        <ActionButtons
          onCancel={closeModalDialog}
          sendButtonText={"Save"}
          disabled={loading}
          hideCancelButton={loading}
          onSend={isProjectClosed ? null : handleSend}
        />
      </Footer>
    </div>
  );
};

AddVendorContact.propTypes = {
  bidGroup: propTypes.bidGroup,
  onUpdateBidGroupVendor: PropTypes.func,
  closeModalDialog: PropTypes.func,
  contacts: PropTypes.arrayOf(propTypes.vendorContact),
  selectedContacts: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
};

export default withSelectedRows(AddVendorContact);
