import React, { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import RegularText from "../../../../ui/Typography/RegularText";
import {
  Button,
  DropDownIcon,
  StyledTitle,
  ExportContainer,
  ExportIcon,
  Separator,
  SwitchContainer,
  SwitchLabel,
} from "./components";
import VendorSelect from "./VendorSelects";
import { DisplayVendorContext } from "./withDisplayVendorControl";
import { Switch } from "@material-ui/core";
import { AlternateQuotesContext } from "../../BidGroupContainer";

const LabelControl = ({ exportAnalysis }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { showAlternateQuotes, setShowAlternateQuotes } = useContext(
    AlternateQuotesContext
  );
  const { statuses } = useContext(DisplayVendorContext);

  const message = useMemo(() => {
    const count = statuses.reduce(
      (count, isAvailable) => (isAvailable ? count + 1 : count),
      0
    );
    return count === statuses.length
      ? "Show All"
      : pluralize("Vendor", count, true);
  }, [statuses]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTitle>
      <RegularText as="span">View</RegularText>
      <Button onClick={handleClick}>
        <RegularText as="span" intent="light">
          {message}
        </RegularText>
        <DropDownIcon />
      </Button>
      <VendorSelect
        onClose={handleClose}
        open={!!anchorEl}
        anchorEl={anchorEl}
      />
      <Separator />
      <ExportContainer onClick={exportAnalysis}>
        <ExportIcon />
        Export
      </ExportContainer>
      <Separator />
      <SwitchContainer>
        <SwitchLabel>Show Alternate Quotes as Columns</SwitchLabel>
        <Switch
          checked={showAlternateQuotes}
          onChange={() => setShowAlternateQuotes(!showAlternateQuotes)}
          color="primary"
        />
      </SwitchContainer>
    </StyledTitle>
  );
};

LabelControl.propTypes = {
  exportAnalysis: PropTypes.func,
};

export default LabelControl;
