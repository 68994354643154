import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BWModels from "benjaminwest-models";
import { RelatedSpecsWrapper } from "./components";
import { inputs } from "../../../../../components/inputs/inputConfigs";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import { FormikForm, FormGrid } from "../../../../forms";

const StyledRelatedSpecsWrapper = styled(RelatedSpecsWrapper)`
  padding: 0 2px;
`;

const ActionButtonsSection = styled.div`
  padding: 0 24px;
`;

const getSpecDetailComSchema = () => BWModels.loadSchema("SpecDetailCom");

const getInitialValues = selectedSpecDetailCom => {
  const initialValues = {
    notes: "",
  };
  if (selectedSpecDetailCom) {
    initialValues.notes = selectedSpecDetailCom.notes;
  }

  return initialValues;
};

const handleAddCOMToSpec = ({ notes }, addCOMToSpec) => {
  addCOMToSpec(notes);
};

const SelectCOMInputs = ({
  addCOMToSpec,
  disabled,
  selectedSpecDetailCom,
  dataComponentId,
}) => {
  const buttonsProps = useMemo(() => ({ send: { disabled } }), [disabled]);
  return (
    <StyledRelatedSpecsWrapper>
      <FormikForm
        onSubmit={values => {
          handleAddCOMToSpec(values, addCOMToSpec);
        }}
        enableReinitialize={true}
        validationSchema={getSpecDetailComSchema()}
        initialValues={getInitialValues(selectedSpecDetailCom)}
        ignoreValidationFieldNames={["specDetailId", "specId"]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={{
                groups: [{ items: [{ input: inputs.notes }] }],
              }}
              values={values}
              errors={errors}
              {...formikProps}
            />

            <ActionButtonsSection>
              <ActionButtons
                sendButtonText="ADD"
                onSend={handleSubmit}
                hideCancelButton={true}
                additionalProps={buttonsProps}
                listeners={[dataComponentId]}
              />
            </ActionButtonsSection>
          </Fragment>
        )}
      </FormikForm>
    </StyledRelatedSpecsWrapper>
  );
};

SelectCOMInputs.propTypes = {
  addCOMToSpec: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectCOMInputs;
