import { isSuperAdmin } from "../../../../utils/roleUtils";
import { inputs } from "../../../inputs/inputConfigs";
import { MSAContractFileInput } from "./MSAContractFileInput";

const addressGroup = {
  items: [
    {
      input: {
        ...inputs.location,
        required: true,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.locationAddress2,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.locationCity,
        required: true,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.locationState,
        required: true,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.locationCountry,
        required: true,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.locationPostalCode,
        required: true,
      },
      grid: { xs: 4 },
    },
  ],
};
export default (userRole, isUpdate) => {
  const fields = {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.name,
              label: "Client Name",
              required: true,
            },
            grid: { xs: 4 },
          },
          { grid: { xs: 8 } },
          {
            input: inputs.msaClient,
            grid: { xs: 4, noPaddingLeft: true },
          },
          {
            input: inputs.draftClient,
            grid: { xs: 4 },
          },
          {
            grid: { xs: 4 },
          },
          {
            input: {
              InputComponent: MSAContractFileInput,
              name: "msaFile",
              label: "Add MSA Contract",
              fullWidth: true,
              useFileWrapper: true,
              displayMenu: true,
              inputFileProps: {
                accept: ".pdf",
                isForm: !isUpdate,
              },
            },
            grid: { xs: 4 },
          },
        ],
      },
      addressGroup,
      {
        items: [
          {
            input: { ...inputs.website },
            grid: { xs: 4 },
          },
          {
            input: { ...inputs.federalId },
            grid: { xs: 4 },
          },
          {
            grid: { xs: 4 },
          },
        ],
      },
      {
        items: [
          {
            input: inputs.notes,
            grid: { xs: 8 },
          },
        ],
      },
    ],
  };

  if (isSuperAdmin(userRole)) {
    fields.groups.push({
      items: [
        {
          input: { ...inputs.scopeId, isDisabled: isUpdate },
          grid: {
            xs: 4,
          },
        },
      ],
    });
  }

  return fields;
};
