export const overrideAttributes = [
  "customNumber",
  "description",
  "priceCents",
  "baseQuantity",
  "atticStock",
  "designerQuantity",
  "forecastComment",
  "glCodes",
  "clientBudgetCents",
  "overagePercent",
  "overageValue",
  "isPlaceholder",
  "purchaseOrderId",
  "specCategoryId",
  "areaId",
  "unitOfMeasureId",
  "projectCurrencyId",
  "vendorId",
  "specDetailIdSequence",
  "sampleQuantity",
  "awardedBidGroupVendorSpecQuoteId",
  "price",
  "isCommitted",
  "isAssignedToBidGroup",
  "shipmentTotalQty",
  "totalPoDiscount",
  "totalQuantity",
  "totalPrice",
  "totalPriceWithDiscount",
  "designerExtendedCost",
  "totalForecast",
  "totalQuantityWithoutOverage",
  "specDetails",
  "requirements",
];
