import styled from "styled-components";
import Counter from "../../../../ui/Counter";

export const ShipmentColumn = styled.div`
  display: flex;
  align-items: center;
  ${Counter} {
    height: 24px;
    width: 33px;
    line-height: 24px;
    font-size: 14px;
    border-radius: 13px;
    margin-left: 0;
    margin-right: 8px;
  }
`;

export const PlaceholderCounter = styled.div`
  height: 24px;
  width: 33px;
  margin-right: 8px;
`;

export const ShipmentsTotalSpan = styled.span`
  margin-top: 2px;
`;
