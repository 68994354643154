import React, {
  createContext,
  memo,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import _noop from "lodash/noop";
import _get from "lodash/get";
import _chunk from "lodash/chunk";
import { isEqual, memoize } from "lodash";
import { useWithPaginationContext } from "../../../withPagination";
import { usePrevious } from "../../hooks/usePrevious";

export const WithSelectedRowsContext = createContext({
  selection: {},
  selectedIds: [],
  setTotalRows: _noop,
  setSelection: _noop,
  resetSelection: _noop,
  setRows: _noop,
  setAllRows: _noop,
  rows: [],
  allRows: [],
  totalRows: 0,
  selectedRows: [],
});

export const useWithSelectedRowContext = () =>
  useContext(WithSelectedRowsContext);

const getActions = memoize(
  ({ setSelectionInfo, selectionInfo, pageNumber, rows }) => {
    const selectAll = (rows, pageSize) => {
      const pages = _chunk(rows, pageSize);
      const selection = pages.reduce(
        ({ selection, selectedIdsByPage, selectedIds }, items, key) => {
          const ids = items.map(({ id }) => id);
          const indexes = items.map((_, index) => index);
          return {
            selection: {
              ...selection,
              [key]: indexes,
            },
            selectedIdsByPage: [...selectedIdsByPage, ids],
            selectedIds: selectedIds.concat(ids),
          };
        },
        {
          selection: {},
          selectedIdsByPage: [],
          selectedIds: [],
        }
      );
      setSelectionInfo(selection);
    };

    const setSelection = (selection, currentPage = pageNumber) => {
      const getSelectedIdByPage = (idsInPage, page) => {
        const selectedBypage = _get(selection, `${page}`, []);
        if (selectedBypage.length === 0) {
          return [];
        }
        return idsInPage;
      };

      const selectedIdsByPage = selectionInfo.selectedIdsByPage.map(
        getSelectedIdByPage
      );

      const selectionForCurrentPage = _get(
        selection,
        `${currentPage}`,
        []
      ).filter(i => Boolean(rows[i]));
      selectedIdsByPage[currentPage] =
        rows.length > 0 ? selectionForCurrentPage.map(i => rows[i].id) : [];

      return setSelectionInfo({
        selection,
        selectedIdsByPage,
        selectedIds: selectedIdsByPage.reduce(
          (ids, idsInPage) => [...ids, ...idsInPage],
          []
        ),
      });
    };

    const setSelectedIds = (selectedIds, rows, pageSize) => {
      const idSet = new Set(selectedIds);
      const pages = _chunk(rows, pageSize);

      const selection = pages.reduce(
        ({ selection, selectedIdsByPage, selectedIds }, items, key) => {
          const ids = items
            .filter(({ id }) => idSet.has(id))
            .map(({ id }) => id);
          const indexes = items
            .map(({ id }, index) => (idSet.has(id) ? index : -1))
            .filter(i => i != -1);
          return {
            selection: {
              ...selection,
              [key]: indexes,
            },
            selectedIdsByPage: [...selectedIdsByPage, ids],
            selectedIds: selectedIds.concat(ids),
          };
        },
        {
          selection: {},
          selectedIdsByPage: [],
          selectedIds: [],
        }
      );
      setSelectionInfo(selection);
    };

    return {
      selectAll,
      setSelection,
      setSelectedIds,
    };
  }
);

/**
 * A public higher-order component to use selected id functionality
 */
function withSelectedRows(WrappedComponent, paginationGridId) {
  const SelectedRowsWrapper = ({
    paginationGridId: componentPaginationGridId,
    ...props
  }) => {
    const [selectionInfo, setSelectionInfo] = useState({
      selection: {},
      selectedIdsByPage: [],
      selectedIds: [],
    });
    const [pageNumber, setPageNumber] = useState(0);
    const [rows, setRows] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    const paginationContext = useWithPaginationContext();
    const pageSize = paginationContext.getPageSize(
      paginationGridId || componentPaginationGridId
    );

    const prevAllRows = useRef([]);
    const prevPageSize = usePrevious(pageSize);

    const { selectAll, setSelection, setSelectedIds } = getActions({
      setSelectionInfo,
      selectionInfo,
      pageNumber,
      rows,
    });

    useEffect(() => {
      if (!isEqual(prevAllRows.current, allRows) || prevPageSize != pageSize) {
        setSelectedIds(selectionInfo.selectedIds, allRows, pageSize);
      }
      prevAllRows.current = allRows;
    }, [
      allRows,
      pageSize,
      prevPageSize,
      selectionInfo.selectedIds,
      setSelectedIds,
    ]);

    const resetSelection = useCallback(() => {
      setSelectionInfo({
        selection: {},
        selectedIdsByPage: [],
        selectedIds: [],
      });
    }, []);

    const value = useMemo(() => {
      const set = new Set(selectionInfo.selectedIds);
      const selectedRows = allRows.filter(row => set.has(row.id));
      return {
        setSelection,
        setRows,
        setAllRows,
        setTotalRows,
        resetSelection,
        totalRows,
        rows,
        allRows,
        selectAll,
        setPageNumber,
        setSelectedIds,
        selectedRows,
        ...selectionInfo,
      };
    }, [
      setSelection,
      resetSelection,
      totalRows,
      rows,
      allRows,
      selectAll,
      setSelectedIds,
      selectionInfo,
    ]);

    return (
      <WithSelectedRowsContext.Provider value={value}>
        <WrappedComponent
          {...props}
          selectedIds={value.selectedIds}
          setRows={value.setRows}
          setSelection={value.setSelection}
          resetSelection={value.resetSelection}
        />
      </WithSelectedRowsContext.Provider>
    );
  };

  return memo(SelectedRowsWrapper);
}

export default withSelectedRows;
