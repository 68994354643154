import React from "react";
import styled from "styled-components";

import { hasContacts } from "./SendRFQ";
import { BWTableRow } from "../../../../ui/BWGrid/gridInternalComponents";

const hasItems = row => !!row.specs.length;
const hasError = row => !hasContacts(row) || !hasItems(row);

export const Row = styled(BWTableRow)`
  background: ${({ row }) =>
    !hasError(row) ? "transparent" : "rgba(255, 23, 68, 0.1)"};
  }
  td:last-child {
    font-weight: ${({ row }) => (hasContacts(row) ? "inherit" : "bold")};
    color: ${({ row }) => (hasContacts(row) ? "inherit" : "red")};
  }
  td:nth-last-child(2) {
    font-weight: ${({ row }) => (hasItems(row) ? "inherit" : "bold")};
    color: ${({ row }) => (hasItems(row) ? "inherit" : "red")};
  }
`;

export const RowComponent = ({ ...props }) => {
  return <Row {...props} />;
};
