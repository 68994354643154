import React from "react";
import _noop from "lodash/noop";
import styled from "styled-components";

import { StyledBWSelectCell } from "../../../../ui/BWGrid/components";
import { StyledRow } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/BWTableRow";

const Row = styled(StyledRow)`
  pointer-events: none;
`;

export function RowComponent(props) {
  const { row } = props;
  if (row?.__deleted__) {
    return <Row {...props} />;
  }
  return <StyledRow {...props} />;
}

export function SelectionCell(props) {
  const { row } = props;
  if (row?.__deleted__) {
    return (
      <StyledBWSelectCell
        {...props}
        selected={false}
        disabled={true}
        onToggle={_noop}
      />
    );
  }
  return <StyledBWSelectCell {...props} />;
}
