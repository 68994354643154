import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import { EditorComponent } from "../../BWGrid/gridInternalComponents/dataTypeProviders/StaticMultiSelectTypeProvider";

export default columns => {
  const MultiSelectTypeProvider = () => {
    return <DataTypeProvider for={columns} editorComponent={EditorComponent} />;
  };
  MultiSelectTypeProvider.propTypes = {};
  return MultiSelectTypeProvider;
};
