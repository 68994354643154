import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const DiscountForm = ({
  onSubmit,
  initialValues,
  discountFields,
  dataComponentId,
}) => {
  return (
    <FormikForm
      validationSchema={BWModels.loadSchema("Discount")}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={discountFields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

DiscountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.discount,
  dataComponentId: PropTypes.string.isRequired,
};

export default DiscountForm;
