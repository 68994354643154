import TextInputWithError from "../../inputs/TextInputWithError";
import CurrencyInput from "../CurrencyInput";

export default {
  areaName: {
    InputComponent: TextInputWithError,
    label: "Area Name",
    name: "name",
    fullWidth: true,
    autoFocus: true,
  },
  areaRoomName: {
    InputComponent: TextInputWithError,
    name: "name",
    label: "Room Name",
    fullWidth: true,
    autoFocus: true,
  },
  areaRoomCount: {
    InputComponent: TextInputWithError,
    name: "roomCount",
    label: "Count",
    fullWidth: true,
  },
  areaSquareFoot: {
    InputComponent: TextInputWithError,
    label: "Square Foot",
    name: "squareFoot",
    fullWidth: true,
  },
  areaBudgetCents: {
    InputComponent: TextInputWithError,
    label: "Client Area Budget",
    name: "clientBudgetCents",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
};
