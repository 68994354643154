import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { validateAuthentication } from "./actions/authActions";

export default function withAuthentication(Component) {
  const AuthenticatedComponent = ({
    token,
    validateAuthentication,
    push,
    ...props
  }) => {
    useEffect(() => {
      if (!token) {
        validateAuthentication();
      }
    }, [token, validateAuthentication]);

    return token ? <Component {...props} /> : null;
  };

  AuthenticatedComponent.propTypes = {
    validateAuthentication: PropTypes.func,
    token: PropTypes.string,
  };

  const mapDispatchToProps = { validateAuthentication };

  const mapStateToProps = ({ auth }) => ({
    token: auth.token,
  });

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
