import Flag from "../Flag";

describe("Flag model", () => {
  test("belongsTo relations", () => {
    expect(Flag.belongsTo.map(relation => relation.name)).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(Flag.hasMany.map(relation => relation.name)).toMatchSnapshot();
  });
});
