import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import Loader from "../../../ui/Loader";
import InputWrapper from "../../../inputs/InputWrapper";
import { inputs } from "../../../inputs/inputConfigs";
import { StyledCircularProgress } from "../../../ui/BWActionButton";

export const StyledActionsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftActions = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-right: 24px;
`;

export const getMoreActions = (
  invoiceId,
  projectId,
  generateAndDownloadReport
) => {
  const options = [
    {
      content: "Preview Check Request",
      onClick: () => {
        generateAndDownloadReport({
          format: "pdf",
          showPreview: true,
          invoiceId: invoiceId,
          projectId,
          templateName: "invoice-check-request-log",
        });
      },
    },
    {
      content: "Print Check Request",
      onClick: () => {
        generateAndDownloadReport({
          format: "pdf",
          invoiceId: invoiceId,
          projectId,
          templateName: "invoice-check-request-log",
        });
      },
    },
  ];

  return {
    moreActions: {
      ...inputs.moreActions,
      buttonText: "More",
      options,
    },
  };
};

export const ActionsSection = ({
  invoiceId,
  projectId,
  generateAndDownloadReport,
  loading,
}) => {
  const inputs = useMemo(() => {
    return getMoreActions(invoiceId, projectId, generateAndDownloadReport);
  }, [generateAndDownloadReport, invoiceId, projectId]);

  return (
    <StyledActionsSection>
      <LeftActions>
        <Loader width="300px">
          {loading ? (
            <StyledCircularProgress size={20} />
          ) : (
            <InputContainer>
              <InputWrapper {...inputs.moreActions} />
            </InputContainer>
          )}
        </Loader>
      </LeftActions>
    </StyledActionsSection>
  );
};

ActionsSection.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  generateAndDownloadReport: PropTypes.func.isRequired,
  generatingReport: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    loading:
      state.reports.loaders["invoice-check-request-log-pdf-true"] ||
      state.reports.loaders["invoice-check-request-log-pdf-false"],
  };
};

export default connect(mapStateToProps)(ActionsSection);
