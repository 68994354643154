import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import RegularText from "../../../../ui/Typography/RegularText";
import InputLabel from "../../../../mui/core/InputLabel";
import Switch from "../../../../mui/core/Switch";

const ControlWrapper = styled.div`
  padding: 0 24px 24px;
  ${InputLabel} {
    margin-bottom: 10px;
    display: inline-block;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Switch} {
    margin-top: -12px;
    margin-bottom: -12px;
  }
`;

export class ShortPaidControl extends Component {
  static propTypes = {
    purchaseOrder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortPaid: PropTypes.bool,
    }),
    initDataComponent: PropTypes.func.isRequired,
    performUpdateRequest: PropTypes.func.isRequired,
    dataComponent: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.initDataComponent(
      "POShortPaid",
      PurchaseOrder,
      [],
      "purchase-orders"
    );
  }

  onChange = event => {
    const checked = event.target.checked;
    const { purchaseOrder, performUpdateRequest, dataComponent } = this.props;
    if ((dataComponent.requestState[REQUEST_TYPES.UPDATE] || {}).loading) {
      return;
    }
    performUpdateRequest(dataComponent.dataComponentId, purchaseOrder.id, {
      shortPaid: checked,
    });
  };

  render() {
    const { purchaseOrder } = this.props;

    return (
      <ControlWrapper>
        <InputLabel>Purchase Order Contains Discounted Items</InputLabel>
        <SwitchWrapper>
          <RegularText>Merchandise Invoice to be Short Paid</RegularText>
          <Switch
            value="shortPaid"
            color="primary"
            checked={purchaseOrder.shortPaid}
            onChange={this.onChange}
          />
        </SwitchWrapper>
      </ControlWrapper>
    );
  }
}

const mapStateToProps = state => {
  const shortPaidDataComponent = getDataComponent("POShortPaid", state);
  return {
    dataComponent: shortPaidDataComponent,
  };
};
const mapDispatchToProps = { initDataComponent, performUpdateRequest };

export default connect(mapStateToProps, mapDispatchToProps)(ShortPaidControl);
