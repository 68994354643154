import React, { Component } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import styles from "styled-components";

import FormGrid from "../../../../components/forms/FormGrid";
import editSpecDetailDescriptionFields from "./editSpecDetailDescriptionFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm, {
  validateForm,
} from "../../../../components/forms/FormikForm";
import { withRevisionReference } from "../../../../withPORevision";

const Wrapper = styles.div`
  textarea {
    resize: vertical;
  }
`;

export const validate = ({
  values,
  validationSchema,
  ignoreValidationFieldNames,
}) => {
  const errors = validateForm(
    values,
    validationSchema,
    ignoreValidationFieldNames
  );

  return errors;
};

export class EditSpecDetailDescriptionForm extends Component {
  static propTypes = {
    specDetail: PropTypes.object,
    editSpecDetailDescription: PropTypes.func,
    dataComponentId: PropTypes.string.isRequired,
  };

  specDetailInitialValues = {
    title: "",
    description: "",
    unitsNeeded: 0,
    unitsPerCase: 0,
  };

  render() {
    const {
      specDetail = {},
      editSpecDetailDescription,
      spec,
      dataComponentId,
    } = this.props;
    const validationSchema = BWModels.loadSchema("SpecDetail");

    return (
      <FormikForm
        initialValues={{
          ...this.specDetailInitialValues,
          ...specDetail,
        }}
        onSubmit={editSpecDetailDescription}
        validate={values =>
          validate({
            values,
            validationSchema,
            spec,
            ignoreValidationFieldNames: [
              "specId",
              "unitsNeeded",
              "unitsPerCase",
              "preview",
            ],
          })
        }
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Wrapper>
            <FormGrid
              fields={editSpecDetailDescriptionFields(
                specDetail ? specDetail.locked : false
              )}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              onSend={handleSubmit}
              isModal={true}
              listeners={[dataComponentId]}
            />
          </Wrapper>
        )}
      </FormikForm>
    );
  }
}

export default withRevisionReference(EditSpecDetailDescriptionForm);
