import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import Loader from "../../../../ui/Loader";
import LocationCombined from "../../../../ui/LocationCombined";
import { Title } from "../../../../ui/Typography/SectionTitle";

import { ContactNameText, ItemPaperWrapper, SubheaderText } from "./components";
import { getContactAttribute } from "./ProjectOverview";

export const ClientSection = ({ client, property, projectMemberContacts }) => {
  return (
    <ItemPaperWrapper>
      <Title>Client</Title>
      <SubheaderText>
        <Loader width="200px" height="20px">
          {client.name}
        </Loader>
      </SubheaderText>
      <Loader width="200px" height="20px">
        <LocationCombined location={property.location} ignoreZip={false} />
      </Loader>
      {projectMemberContacts.length > 0 && (
        <React.Fragment>
          <SubheaderText>Contacts</SubheaderText>
          <Loader width="200px" height="20px">
            {projectMemberContacts.map((projectMemberContact, index) => {
              return (
                <div key={index}>
                  <ContactNameText>{`${projectMemberContact.contact.firstName} ${projectMemberContact.contact.lastName}`}</ContactNameText>
                  <span>{projectMemberContact.contact.phone}</span>
                  {getContactAttribute(projectMemberContact.contact, "email")}
                </div>
              );
            })}
          </Loader>
        </React.Fragment>
      )}
    </ItemPaperWrapper>
  );
};

ClientSection.propTypes = {
  client: propTypes.client,
  property: propTypes.property,
  projectMemberContacts: PropTypes.array,
};
