import TextInputWithError from "../../inputs/TextInputWithError";
import { SelectWithError as RawSelectWithError } from "../SelectWithError";
import { getOptionsFromSchema } from "../utils";

export default {
  termContent: {
    InputComponent: TextInputWithError,
    label: "Content",
    name: "content",
    fullWidth: true,
    multiline: true,
  },
  termDisplayOrder: {
    InputComponent: RawSelectWithError,
    label: "Display Order",
    name: "displayOrder",
    fullWidth: true,
  },
  termRelation: {
    InputComponent: RawSelectWithError,
    label: "Relation",
    name: "relation",
    options: getOptionsFromSchema("Term", "relation"),
    nullAsEmpty: true,
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
  },
};
