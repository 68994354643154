import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import EditClientContactForm from "../EditClientContact/EditClientContactForm";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";

const dataComponentId = "ClientContactsGrid";

export class CreateClientContactContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, showSnackNotificationAction } = this.props;
    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateRequestSuccess,
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  handleCreateRequestSuccess = () => {
    const { closeModalDialog, setReload } = this.props;

    closeModalDialog();
    setReload(dataComponentId, true);
  };

  handleCreateContact = (clientContact, formikActions) => {
    const { performCreateRequest } = this.props;

    performCreateRequest(dataComponentId, clientContact);
    this.setState({ formikActions });
  };

  render() {
    const { clientId, dataComponent } = this.props;

    return (
      <EditClientContactForm
        clientId={clientId}
        sendButtonText="Create Contact"
        onSubmit={this.handleCreateContact}
        dataComponentId={dataComponent.dataComponentId}
      />
    );
  }
}

CreateClientContactContainer.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  clientId: PropTypes.string,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  setReload,
  closeModalDialog,
  performCreateRequest,
  showSnackNotificationAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateClientContactContainer)
);
