import React, { useEffect, useContext, createContext } from "react";
import styled from "styled-components";
import { connect } from "formik";

import propTypes from "../../../../constants/propTypes";
import PropTypes from "prop-types";

export const EditLink = styled.label`
  cursor: pointer;
  color: #57abff;
  &:hover {
    color: #57abffcc;
  }
`;

export const ProjectNumberContext = createContext({ nextNumber: "" });

export const withProjectNumber = Component => {
  const WithProjectNumber = ({ nextNumber, ...props }) => {
    return (
      <ProjectNumberContext.Provider value={{ nextNumber }}>
        <Component {...props} />
      </ProjectNumberContext.Provider>
    );
  };

  WithProjectNumber.propTypes = {
    nextNumber: PropTypes.string,
  };

  return WithProjectNumber;
};

export const ProjectNumberHelper = ({ formik }) => {
  const { nextNumber } = useContext(ProjectNumberContext);
  const { setFieldValue } = formik;
  const { contractFiles, sequenceIndex } = formik.values;

  useEffect(() => {
    if (contractFiles.length > 0 && !sequenceIndex) {
      setFieldValue("numberPrefix", nextNumber.toString());
    }
  }, [setFieldValue, sequenceIndex, contractFiles, nextNumber]);

  return null;
};

ProjectNumberHelper.propTypes = {
  formik: propTypes.formik,
};

export default connect(ProjectNumberHelper);
