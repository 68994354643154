import { useMemo } from "react";

const useSelection = (selectedIds = [], rows) => {
  const rowIds = rows.map(({ id }) => id);
  const selection = useMemo(
    () =>
      rows.reduce((selection, row, index) => {
        return selectedIds.includes(row.id) ? [...selection, index] : selection;
      }, []),
    [rows, selectedIds]
  );

  const generateSelection = selectedIndexs => {
    const filteredSelectedIds = selectedIds.filter(id => !rowIds.includes(id));
    const items = rows.filter((_, index) => selectedIndexs.includes(index));

    return [...filteredSelectedIds, ...items.map(({ id }) => id)];
  };
  return [selection, generateSelection];
};
export default useSelection;
