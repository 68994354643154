import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import { inputs } from "../../../../../components/inputs/inputConfigs";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";
import { quotingBidGroupsDCId } from "../../../../../actions/quotingActions";

const bidGroupFields = {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.baseName,
            label: "Bid Group Name",
            required: true,
            avoidDebounce: true,
          },
        },
      ],
    },
  ],
};

const BidGroupForm = ({ onSubmit, initialValues }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("BidGroup")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={bidGroupFields}
            values={values}
            errors={errors}
            {...formikProps}
          />

          <ActionButtons
            listeners={[quotingBidGroupsDCId]}
            sendButtonText="Save"
            onSend={handleSubmit}
            isModal={true}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

BidGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: propTypes.bidGroup,
};

export default BidGroupForm;
