import React, { useEffect } from "react";
import { connect } from "react-redux";
import _get from "lodash.get";
import { omit, isEqual } from "lodash";

import {
  cloneDataComponentSetRequestState,
  performRetrieveListRequest,
  setReload,
} from "../../../actions/dataComponentActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
  getDataComponentRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  getBluechipResourceById,
  getCurrentBluechipResourcesForRequestType,
} from "../../../utils/bluechipUtils";
import { BWCell } from "./gridInternalComponents";
import Loader from "../Loader";
import * as RequestTypes from "../../../constants/RequestTypes";
import { _coalesce } from "./utils";
import { openModalDialog } from "../../../actions/layoutActions";
import { usePrevious } from "../../hooks/usePrevious";

// eslint-disable-next-line complexity
const shouldLoadDataComponent = (
  { isFindRequest, rowId, loading, success, error },
  { dataComponent, rowIndexChanged }
) => {
  if (isFindRequest) return true;
  return (
    !loading &&
    dataComponent.model &&
    rowId == 0 &&
    ((!success && !error) ||
      rowIndexChanged ||
      (!loading && _get(dataComponent, "requestState.list.reload", false)))
  );
};

const loadDataComponent = ({
  performRetrieveListRequest,
  dataComponent,
  parentDataComponent,
}) => {
  performRetrieveListRequest(dataComponent.dataComponentId, {
    rootFilters: {
      $where: {
        id: {
          $in: parentDataComponent.rowIndex,
        },
      },
    },
    pageSize: parentDataComponent.pageSize,
  });
};

const propFieldsToOmit = [
  "columnName",
  "rowId",
  "Model",
  "apiRoute",
  "apiFilters",
  "requestType",
  "isLazy",
];

export const BWCellWrapper = ({
  id,
  isLoading,
  success,
  lazyData,
  isFindRequest,
  render,
  ...props
}) => {
  const cleanProps = omit(props, propFieldsToOmit);

  if (!isLoading && success && lazyData) {
    const lazyRow = isFindRequest
      ? lazyData
      : lazyData.find(row => row.id == id);
    return <BWCell {...cleanProps}>{render(lazyRow)}</BWCell>;
  }

  return (
    <BWCell {...cleanProps}>
      <Loader loading={true} />
    </BWCell>
  );
};

const LazyCell = ({
  id,
  lazyData,
  performRetrieveListRequest,
  loading,
  success,
  error,
  isFindRequest,
  render,
  cloneDataComponentSetRequestState,
  parentDataComponent,
  dataComponent,
  dataComponentId,
  rowId,
  requestType,
  parentRequestState,
  cleanRequestState,
  rowIndex,
  parentRowIndex,
  isParentLoading,
  openModalDialog,
  setReload,
  ...props
}) => {
  const isLoading = loading || isParentLoading;

  useEffect(() => {
    if (!dataComponent.model) {
      cloneDataComponentSetRequestState(
        parentDataComponent.dataComponentId,
        dataComponentId,
        cleanRequestState(parentRequestState)
      );
    }

    const rowIndexChanged = !isEqual(
      [...parentRowIndex].sort(),
      [...rowIndex].sort()
    );

    if (
      shouldLoadDataComponent(
        { isFindRequest, rowId, loading, success, error },
        {
          dataComponent,
          rowIndexChanged,
        }
      )
    ) {
      setReload(dataComponentId, false);
      loadDataComponent({
        performRetrieveListRequest,
        dataComponent,
        parentDataComponent,
        requestType,
      });
    }
  }, [
    cleanRequestState,
    cloneDataComponentSetRequestState,
    dataComponent,
    dataComponentId,
    error,
    isFindRequest,
    loading,
    parentDataComponent,
    parentRequestState,
    parentRowIndex,
    performRetrieveListRequest,
    requestType,
    rowId,
    rowIndex,
    setReload,
    success,
  ]);

  const prevError = usePrevious(error);
  useEffect(() => {
    if (!prevError && error) {
      openModalDialog(false, "ReloadModal", {
        title: "Loading Invoice Totals failed due to heavy server traffic.",
        onReload: () => setReload(dataComponentId, true),
      });
    }
  }, [error, prevError, openModalDialog, setReload, dataComponentId]);

  return (
    <BWCellWrapper
      id={id}
      isLoading={isLoading}
      success={success}
      lazyData={lazyData}
      isFindRequest={isFindRequest}
      render={render}
      {...props}
    />
  );
};

const mapStateToProps = (state, { parentDataComponent, requestType, id }) => {
  const isFindRequest = requestType === RequestTypes.FIND;
  const dataComponentId = isFindRequest
    ? `${parentDataComponent.dataComponentId}-${id}`
    : `${parentDataComponent.dataComponentId}-lazy`;
  const dataComponent = getDataComponent(dataComponentId, state);
  const lazyData =
    requestType === RequestTypes.FIND
      ? getBluechipResourceById(dataComponent, state, id)
      : getCurrentBluechipResourcesForRequestType(dataComponentId, state);
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const loading = _coalesce(
    _get(dataComponent, `requestState.${requestType}.loading`),
    flattenedDataComponent.loading
  );
  const success = _coalesce(
    _get(dataComponent, `requestState.${requestType}.success`),
    flattenedDataComponent.success
  );

  const error = _coalesce(
    _get(dataComponent, `requestState.${requestType}.error`),
    flattenedDataComponent.error
  );

  const parentRequestState = getDataComponentRequestState(
    parentDataComponent.dataComponentId,
    state
  );
  const rowIndex = _coalesce(
    _get(dataComponent, `requestState.${requestType}.rowIndex`),
    []
  );
  const parentRowIndex = _coalesce(parentDataComponent.rowIndex, []);

  return {
    lazyData: lazyData || [],
    loading,
    isParentLoading: _coalesce(
      parentDataComponent.loading,
      !isEqual([...parentRowIndex].sort(), [...rowIndex].sort())
    ),
    success,
    error,
    isFindRequest,
    parentDataComponent,
    dataComponent,
    parentRequestState,
    dataComponentId,
    parentRowIndex,
    rowIndex,
  };
};

const mapDispatchToProps = {
  cloneDataComponentSetRequestState,
  performRetrieveListRequest,
  openModalDialog,
  setReload,
};

export const LazyCellContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LazyCell);

const lazyCellCreator = ({
  FallbackCellComponent,
  lazyOptions,
  waiting,
  ...additionalProps
}) => {
  return function CellWrapper({ children, ...props }) {
    const {
      column,
      tableRow,
      row: { id },
    } = props;

    const { name: columnName, render } = column;

    if (
      waiting ||
      !lazyOptions ||
      (lazyOptions &&
        (!lazyOptions[columnName] || !lazyOptions[columnName].isLazy(tableRow)))
    ) {
      return (
        <FallbackCellComponent {...props}>{children}</FallbackCellComponent>
      );
    }

    return (
      <LazyCellContainer
        id={id}
        columnName={columnName}
        render={render}
        rowId={tableRow.rowId}
        {...lazyOptions[columnName]}
        {...additionalProps}
        {...props}
      />
    );
  };
};

export default lazyCellCreator;
