import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ProjectPrice from "./ProjectPrice";
import GroupsBar from "./GroupsBar";
import propTypes from "../../../../constants/propTypes";

const ForecastPage = ({
  totalSpecs,
  projectForecast,
  projectCategoryPrices,
  projectAreaPrices,
  project,
  route,
}) => (
  <Fragment>
    <ProjectPrice projectForecast={projectForecast} project={project} />

    <GroupsBar
      totalSpecs={totalSpecs}
      route={route}
      projectCategoryPrices={projectCategoryPrices}
      projectAreaPrices={projectAreaPrices}
      project={project}
    />
  </Fragment>
);

ForecastPage.defaultProps = {
  projectCategoryPrices: [],
};

ForecastPage.propTypes = {
  totalSpecs: PropTypes.number,
  projectForecast: propTypes.projectForecast,
  projectCategoryPrices: PropTypes.arrayOf(PropTypes.object),
  projectAreaPrices: PropTypes.arrayOf(PropTypes.object),
  project: propTypes.project,
  route: propTypes.route,
};

export default ForecastPage;
