import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { TitleContainer } from "../../../layout/AppLayout/ModalDialog/ModalTitle";
import { FormikForm } from "../../../forms";
import {
  getFields,
  CategoryDropdown,
  TrackingTypeDropdown,
  Container,
} from "../NoteCreate/NoteCreatePage";
import {
  StyledTitle,
  Label,
  NoteCategoriesContainer,
} from "../NoteCreate/components";
import NoteForm from "../NoteCreate/NoteForm";
import propTypes from "../../../../constants/propTypes";
import { customValidator } from "../NoteCreate/EmailInput";

const NoteEditPage = ({
  category: initialCategory,
  onUpdateNote,
  onDeleteNote,
  onCancel,
  note,
  userId,
}) => {
  const [category, setCategory] = useState(initialCategory);
  const [trackingType, setTrackingType] = useState(_get(note, "trackingType"));
  const [prevNote, setPrevNote] = useState(note);

  useEffect(() => {
    setPrevNote(note);
  }, [note]);

  useEffect(() => {
    if (note.id !== prevNote.id) {
      setCategory(note.category);
      setTrackingType(note.trackingType);
    }
  }, [note, prevNote]);

  const initialValues = {
    ...note,
    authorId: note.authorId ? note.authorId : userId,
  };

  const fields = getFields(null, category, trackingType);
  return (
    <Fragment>
      <TitleContainer>
        <StyledTitle>Edit Note</StyledTitle>

        <NoteCategoriesContainer>
          <Label>Category:</Label>
          <CategoryDropdown value={category} onChange={setCategory} />
          {category === "Tracking" && (
            <TrackingTypeDropdown
              value={trackingType}
              onChange={setTrackingType}
            />
          )}
        </NoteCategoriesContainer>
      </TitleContainer>
      <Container>
        <FormikForm
          initialValues={initialValues}
          valueOverrides={{ category, trackingType }}
          ignoreCache
          onSubmit={onUpdateNote}
          validationSchema={customValidator}
          enableReinitialize
          onDeleteNote={onDeleteNote}
        >
          {({ handleSubmit, values, errors, setValues, ...formikProps }) => (
            <NoteForm
              onSubmit={handleSubmit}
              onDeleteNote={onDeleteNote}
              onCancel={onCancel}
              fields={fields}
              errors={errors}
              values={values}
              formikProps={formikProps}
            />
          )}
        </FormikForm>
      </Container>
    </Fragment>
  );
};

NoteEditPage.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  category: PropTypes.string,
  projectId: PropTypes.string,
  onUpdateNote: PropTypes.func.isRequired,
  note: propTypes.note,
};

export default NoteEditPage;
