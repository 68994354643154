import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";

import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";
import { useWithSelectedRowContext } from "../../../../ui/BWGrid/withSelectedRows";

export const Actions = ({ setDisplayUpdateSpecs }) => {
  const { rows: rows, selectedIds } = useWithSelectedRowContext();

  const handleUpdateSelectedSpecsClick = useCallback(() => {
    setDisplayUpdateSpecs(true);
  }, [setDisplayUpdateSpecs]);

  const options = useMemo(
    () => [
      {
        content: "Update Selected Specs",
        onClick: handleUpdateSelectedSpecsClick,
        disabled: !selectedIds.length,
      },
    ],
    [handleUpdateSelectedSpecsClick, selectedIds.length]
  );

  if (_isEmpty(rows)) {
    return null;
  }

  return <DropdownMenu buttonText="Actions" options={options} />;
};
Actions.propTypes = {
  setDisplayUpdateSpecs: PropTypes.func.isRequired,
};
