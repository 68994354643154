import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreatePurchaseOrderForm from "./CreatePurchaseOrderForm";
import BWModels from "benjaminwest-models";

import propTypes from "../../../../constants/propTypes";
import Vendor from "../../../../models/Vendor";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  quotingPODCId,
  handleSuccessCreatePO,
} from "../../../../actions/quotingActions";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { createSelector } from "reselect";

export class CreatePurchaseOrderContainer extends Component {
  static propTypes = {
    handleSuccessCreatePO: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    performCreateRequest: PropTypes.func.isRequired,
    initVendorsSelectDC: PropTypes.func.isRequired,
    dataComponent: propTypes.dataComponent,
    projectId: PropTypes.string,
    originDataComponentId: PropTypes.string.isRequired,
    originPOId: PropTypes.string,
    specs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
      .isRequired,
  };

  get validationSchema() {
    return BWModels.loadSchema("PurchaseOrder");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, handleSuccessCreatePO } = this.props;
    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => handleSuccessCreatePO(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  createPurchaseOrder = (purchaseOrder, formikActions) => {
    const { specs } = this.props;
    this.props.performCreateRequest(quotingPODCId, {
      ...purchaseOrder,
      status: "Draft",
      specs,
    });
    this.setState({ formikActions });
  };

  componentDidMount() {
    this.props.initVendorsSelectDC();
  }

  render() {
    const { closeModalDialog, projectId } = this.props;
    return (
      <CreatePurchaseOrderForm
        projectId={projectId}
        validationSchema={this.validationSchema}
        createPurchaseOrder={this.createPurchaseOrder}
        closeModalDialog={closeModalDialog}
        dataComponentId={quotingPODCId}
      />
    );
  }
}

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(quotingPODCId, state);
  return {
    dataComponent,
  };
};

export const makeMapDispatchToProps = dispatch => {
  const dispatchFunctionsSelector = createSelector(
    (_, { originDataComponentId }) => originDataComponentId,
    (_, { projectId }) => projectId,
    (_, { originPOId }) => originPOId,
    (_, { scopeId }) => scopeId,
    (originDataComponentId, projectId, originPOId, scopeId) => ({
      initVendorsSelectDC: () => {
        dispatch(
          initDataComponent(
            "select-vendors",
            Vendor,
            [],
            "vendors",
            false,
            "v2"
          )
        );
        dispatch(
          performRetrieveListRequest("select-vendors", {
            sort: [{ columnName: "name", direction: "asc" }],
            pageSize: -1,
            rootFilters: {
              $where: {
                "scope.id": scopeId,
              },
            },
            fields: ["vendors.id", "name", "status", "scopeId"],
          })
        );
      },
      handleSuccessCreatePO: () =>
        dispatch(
          handleSuccessCreatePO(originDataComponentId, projectId, originPOId)
        ),
      ...bindActionCreators(
        { closeModalDialog, performCreateRequest },
        dispatch
      ),
    })
  );
  return (_, ownProps) => {
    return dispatchFunctionsSelector(null, ownProps);
  };
};

export default withRouter(
  connect(mapStateToProps, makeMapDispatchToProps)(CreatePurchaseOrderContainer)
);
