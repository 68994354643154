import React from "react";
import styled from "styled-components";

import { Paper } from "../../mui/core";
import { Container, Page, Title } from "../Login/components";
import useActions from "../../hooks/useActions";
import {
  herokuStatus,
  herokuDatabaseStatus,
  herokuRedisStatus,
  apiVersion,
  databaseStatus,
  messagingStatus,
  emailStatus,
  lambdaStatus,
} from "../../../actions/statusActions";
import { Heroku } from "./Heroku";
import { ServiceStatus, StatusSpec } from "./ServiceStatus";
import { STATUS, StatusIndicator } from "./StatusIndicator";

export const ServiceStatuses = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 393px;
  min-height: 440px;
  margin-top: 48px;
`;

export const Header = styled.div`
  font-family: "Domine", serif;
  font-size: 20px;
  line-height: 28px;
  padding: 24px;
  display: flex;
  align-self: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 24px;
`;

export const Status = () => {
  const actions = useActions({
    herokuStatus,
    herokuDatabaseStatus,
    herokuRedisStatus,
    apiVersion,
    databaseStatus,
    messagingStatus,
    emailStatus,
    lambdaStatus,
  });

  actions.herokuStatus();
  actions.herokuDatabaseStatus();
  actions.herokuRedisStatus();
  actions.apiVersion();
  actions.databaseStatus();
  actions.messagingStatus();
  actions.emailStatus();
  actions.lambdaStatus();

  return (
    <Page>
      <Container>
        <Title />
        <ServiceStatuses>
          <Header>Service Statuses</Header>
          <Wrapper>
            <Heroku />
            <ServiceStatus
              name="Heroku - Database"
              service="herokuDatabaseStatus"
              isSuccess={json =>
                json.state === "provisioned" ? STATUS.SUCCESS : STATUS.ERROR
              }
            />
            <ServiceStatus
              name="Heroku - Redis"
              service="herokuRedisStatus"
              isSuccess={json =>
                json.state === "provisioned" ? STATUS.SUCCESS : STATUS.ERROR
              }
            />
            <StatusSpec>
              <StatusIndicator
                loading={false}
                status={STATUS.SUCCESS}
                service={`Client - ${process.env.VERSION || "TBD"}`}
              />
            </StatusSpec>
            <ServiceStatus
              name={json => `API - ${json?.version || "TBD"}`}
              service="apiVersion"
            />
            <ServiceStatus
              name="Database"
              service="databaseStatus"
              threshold={1000}
            />
            <ServiceStatus
              name="Messaging"
              service="messagingStatus"
              threshold={2000}
            />
            <ServiceStatus
              name="Email"
              service="emailStatus"
              threshold={2000}
            />
            <ServiceStatus
              name="Lambda - File Size Reducer"
              service="lambdaStatus"
              threshold={2000}
              isSuccess={({
                data: {
                  attributes: { configuration: { state } = {} } = {},
                } = {},
              }) => (state === "Active" ? STATUS.SUCCESS : STATUS.ERROR)}
            />
          </Wrapper>
        </ServiceStatuses>
      </Container>
    </Page>
  );
};

Status.propTypes = {};
