import React, { useRef } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";

import ActionButtons from "../../../forms/ActionButtons";
import propTypes from "../../../../constants/propTypes";
import { spreadBidGroupVendorSpecsWithComs } from "../Quotes/bidGroupVendorSpecUtils";

const Container = styled.div`
  padding: 16px;
`;

const TextWrapper = styled.div`
  white-space: pre-wrap;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 24px;
  max-height: calc(65vh - 72px);
  overflow-y: auto;

  @media (max-width: 600px) {
    max-height: calc(65vh - 120px);
  }
`;

const thStyle = {
  color: "rgba(0, 0, 0, 0.87)",
  textAlign: "center",
  fontSize: "12px",
  fontFamily: "Arial,sans-serif",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  paddingTop: "16px",
  paddingBottom: "16px",
};

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
};

const tdStyle = {
  fontFamily: "Arial,sans-serif",
  textAlign: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  fontWeight: "auto",
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.87)",
  padding: "12px 0px",
};

const tdStyleCOM = {
  ...tdStyle,
  color: "#7a8398",
};

const tdStyleWithBold = {
  ...tdStyle,
  fontWeight: "bold",
};

const thStyleSec = {
  ...thStyle,
  textAlign: "center",
  color: "rgba(0, 0, 0, 0.54)",
};

const signDivStyle = {
  fontFamily: "Arial,sans-serif",
  fontSize: "16px",
  marginTop: "24px",
  color: "rgba(0, 0, 0, 0.54)",
};

const textStyle = {
  fontFamily: "Arial,sans-serif",
  fontSize: "16px",
  marginBottom: "8px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 1)",
};

const italicTextStyle = {
  ...textStyle,
  fontStyle: "italic",
};
const redTextStyle = {
  ...textStyle,
  color: "red",
};

const getRoles = (memberUsers = [], user) => {
  return memberUsers
    .filter(({ userId }) => user.id === userId)
    .map(({ memberType }) => memberType);
};

const handleCopy = ({ textRef, showSnackNotificationAction }) => () => {
  const str = textRef.current.innerHTML;
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
  showSnackNotificationAction("Content Copied!");
};

const VendorEmailPreview = ({
  bidGroup,
  bidGroupVendor,
  project,
  showSnackNotificationAction,
}) => {
  const textRef = useRef();
  const { specs } = bidGroup;
  const user = _get(bidGroup, "rfq.user", { name: "" });
  const roles = getRoles(project.projectMemberUsers, user);
  const bidGroupVendorSpecs = bidGroupVendor.bidGroupVendorSpecs || [];
  const rows = spreadBidGroupVendorSpecsWithComs(
    bidGroupVendorSpecs.map(bd => ({
      ...bd,
      spec: specs.find(({ id }) => id === bd.specId),
    }))
  );

  return (
    <Container>
      <TextWrapper
        contentEditable="true"
        ref={textRef}
        suppressContentEditableWarning={true}
      >
        <div style={textStyle}>{bidGroupVendor.vendor.name},</div>
        <p style={textStyle}>
          Please find attached RFQ for the {project.name}. Please confirm
          receipt by replying to this email.
        </p>
        <p style={textStyle}>
          PLEASE NOTE: Estimated delivery dates are only place holders; Model
          Room timeframe has not been set.
        </p>

        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Number</th>
              <th style={thStyleSec}>Description</th>
              <th style={thStyleSec}>Type</th>
              <th style={thStyleSec}>UOM</th>
              <th style={thStyleSec}>Production QTY</th>
              {bidGroup.enableSampleQuantity === 1 && (
                <th style={thStyleSec}>Sample QTY</th>
              )}
            </tr>
          </thead>
          <tbody>
            {(rows || [])
              .sort((a, b) =>
                a.spec.customNumber.localeCompare(b.spec.customNumber)
              )
              .map(
                (
                  {
                    type,
                    sampleQuantity,
                    spec: {
                      customNumber,
                      description,
                      unitOfMeasure,
                      specCategory,
                      totalQuantityWithoutOverage,
                    },
                  },
                  index
                ) => (
                  <tr key={index}>
                    <td style={tdStyleWithBold}>{customNumber}</td>
                    <td
                      style={
                        type === "COM"
                          ? { ...tdStyleCOM, width: "300px" }
                          : { ...tdStyle, width: "300px" }
                      }
                    >
                      {description}
                    </td>
                    <td style={type === "COM" ? tdStyleCOM : tdStyle}>
                      {specCategory.name}
                    </td>
                    <td style={tdStyle}>{unitOfMeasure?.name || ""}</td>
                    <td style={tdStyle}>{totalQuantityWithoutOverage}</td>
                    {bidGroup.enableSampleQuantity === 1 && (
                      <td style={tdStyle}>{sampleQuantity}</td>
                    )}
                  </tr>
                )
              )}
          </tbody>
        </table>
        <br />

        <p style={textStyle}>
          If there are any items that are listed as (VERIFY/HIGHLIGHTED), kindly
          fill in the blanks with the requested information.
        </p>
        <p style={textStyle}>
          We request that you only use our form when returning pricing, but if
          you must, you may include your own quote form ONLY if you have already
          filled ours out COMPLETELY. Please email to my attention, no later
          than end of business due date. If meeting this due date is a problem,
          kindly advise.
        </p>

        <br />
        <p style={italicTextStyle}>
          * Please be sure to provide production pricing (in addition to the
          model room requested) based on the quantities listed.
        </p>
        <br />

        <p style={textStyle}>
          If this RFQ should be forwarded to another party, please advise and
          provide the appropriate contact information (name, email address, and
          phone number).
        </p>

        <br />
        <p style={{ ...italicTextStyle, ...redTextStyle }}>
          <b>
            The quantities are estimates only and most likely will change.
            Please advise pricing of near increments as well.
          </b>
        </p>
        <br />
        <p style={italicTextStyle}>
          <b>
            We appreciate receiving your best pricing as we will not have time
            to re-bid.
          </b>
        </p>
        <p style={textStyle}>
          Please contact with any questions.
          <br />
          <br />
          (1 attachment)
          <br />
          <br />
          Much Appreciated,
        </p>
        <div style={signDivStyle}>
          {_get(user, "name", "")}
          <br />
          {roles.join("/")}
        </div>
      </TextWrapper>

      <ActionButtons
        sendButtonText="Copy Email HTML"
        onSend={handleCopy({ textRef, showSnackNotificationAction })}
        isModal
      />
    </Container>
  );
};

VendorEmailPreview.propTypes = {
  bidGroup: propTypes.bidGroup,
  bidGroupVendor: propTypes.bidGroupVendor,
  project: propTypes.project,
  showSnackNotificationAction: PropTypes.func,
};

VendorEmailPreview.defaultProps = {
  project: {},
  rows: [],
};

export default VendorEmailPreview;
