import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FeeForm from "./FeeForm";
import { closeModalDialog } from "../../../../../../actions/layoutActions";
import { fetchFeeSummaries } from "../../../../../../actions/feeSummaryActions";
import propTypes from "../../../../../../constants/propTypes";
import {
  performCreateRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import { processUpsertRequestStatus } from "../../../../../../utils/dataComponentUtils";
import { ProjectContext } from "../../../../../hooks/useIsProjectClosed";
import { LabeledTextContext } from "../../../../../../withPORevision/withLabeledTextOption";

export const dataComponentId = "FeesGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions,
  fetchFeeSummaries
) => {
  processUpsertRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      fetchFeeSummaries();
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateFeeContainer = ({
  dataComponent,
  closeModalDialog,
  projectId,
  feeId,
  setReload,
  fee: { id: sourceId, ...fee },
  performCreateRequest,
  performUpdateRequest,
  fetchFeeSummaries,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions,
      fetchFeeSummaries
    );
  });

  const handleCreateFee = useCallback(
    (
      { revenueSharingProject, workScope, feePayments, ...fee },
      formikActions
    ) => {
      if (feeId) {
        performUpdateRequest(dataComponentId, feeId, fee);
      } else {
        performCreateRequest(dataComponentId, fee);
      }
      setFormikAction(formikActions);
    },
    [feeId, performCreateRequest, performUpdateRequest]
  );
  const projectContext = useContext(ProjectContext);
  return (
    <ProjectContext.Provider
      value={{
        ...projectContext,
        ignoreProjectClosed: true,
      }}
    >
      <LabeledTextContext.Provider value={fee.sharedFromFeeId}>
        <FeeForm
          isUpdate={!!sourceId}
          initialValues={{
            projectId,
            feeSubScopeId: "1",
            oseAmount: 0,
            ffeAmount: 0,
            ...fee,
          }}
          onSubmit={handleCreateFee}
          dataComponentId={dataComponent.dataComponentId}
        />
      </LabeledTextContext.Provider>
    </ProjectContext.Provider>
  );
};

CreateFeeContainer.defaultProps = {
  fee: {
    status: undefined,
    scope: undefined,
    billingTerm: undefined,
    serviceCategory: undefined,
    amount: undefined,
    amountPaid: 0,
    revenueSharing: false,
    billingDate: undefined,
    ffeAmount: 0,
  },
};

CreateFeeContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  projectId: PropTypes.string,
  fee: propTypes.fee,
  performCreateRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  feeId: PropTypes.string,
  setReload: PropTypes.func,
  fetchFeeSummaries: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  performUpdateRequest,
  setReload,
  fetchFeeSummaries,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFeeContainer);
