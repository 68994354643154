import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import TableRowMUI from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";

export const LinearContainer = styled(({ isLoading, ...props }) => (
  <div {...props} />
))`
  width: 100%;
  min-height: 4px;
  margin-bottom: 2px;
`;

const StyledTr = styled(({ isLoading, ...props }) => <tr {...props} />)`
  ${props =>
    !props.isLoading &&
    css`
      display: none;
    `}
`;

export const TableRow = ({
  children,
  row,
  tableRow,
  isLoading,
  ...restProps
}) => (
  <Fragment>
    <TableRowMUI {...restProps}>{children}</TableRowMUI>
    <StyledTr isLoading={isLoading}>
      <th colSpan={children.length}>
        <LinearContainer>
          <LinearProgress variant="query" />
        </LinearContainer>
      </th>
    </StyledTr>
  </Fragment>
);

TableRow.propTypes = {
  children: PropTypes.node,
  row: PropTypes.any,
  tableRow: PropTypes.object,
  isLoading: PropTypes.bool,
};
