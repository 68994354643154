import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import TermForm from "../CreatePaymentTerm/PaymentTermForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { reloadDataComponent } from "../../../../../actions/initializeStoreActions";
import { PAYMENT_TERMS_SELECT } from "../../../../../constants/DataComponents";

export const dataComponentId = "paymentTermsGrid";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      reloadDataComponent(PAYMENT_TERMS_SELECT);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const EditPaymentTermContainer = ({
  dataComponent,
  closeModalDialog,
  paymentTerm,
  setReload,
  reloadDataComponent,
  performUpdateRequest,
  loading,
  params,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions
    );
  });

  const handleEdit = useCallback(
    (instance, formikActions) => {
      performUpdateRequest(dataComponentId, paymentTerm.id, instance, params);
      setFormikAction(formikActions);
    },
    [performUpdateRequest, paymentTerm.id, params]
  );

  return (
    <TermForm
      initialValues={paymentTerm}
      loading={loading}
      onSubmit={handleEdit}
      isUpdate
    />
  );
};

EditPaymentTermContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  paymentTerm: propTypes.paymentTerm,
  performUpdateRequest: PropTypes.func,
  setReload: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  params: PropTypes.shape({
    termContext: PropTypes.string,
    contextValue: PropTypes.string,
  }),
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.update.loading"),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPaymentTermContainer);
