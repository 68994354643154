import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Warning from "@material-ui/icons/Warning";
import ChatBubble from "@material-ui/icons/ChatBubble";

import { StyledTextField } from "../../../../mui/core/TextField";
import RegularText from "../../../../ui/Typography/RegularText";
import { StyledBWCell } from "../../gridInternalComponents/BWCell";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  color: #979797;
  ${StyledTextField} {
    .root {
      padding-top: 0;
    }
    .input {
      cursor: text;
      color: #979797;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
    }
  }
`;

export const Cell = styled(StyledBWCell)`
  overflow: visible;
`;

export const Link = styled.a`
  color: #57abff;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const ChatIcon = styled(ChatBubble)`
  color: #d8d8d8;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 2px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const StyledCircularProgress = styled(({ status, ...props }) => (
  <CircularProgress {...props} />
))`
  position: absolute;
  color: #57abff;
  opacity: ${({ status }) => (status === "pending" ? 1 : 0)};
  display: ${({ status }) => (status === "pending" ? "auto" : "none")};

  transition: opacity 1s;
`;

export const StyledCheck = styled(({ status, ...props }) => (
  <CheckCircle {...props} />
))`
  position: absolute;
  color: #72b327;
  opacity: ${({ status }) => (status === "success" ? 0.6 : 0)};
  transition: opacity 1s;
`;

export const StyledWarning = styled(({ status, ...props }) => (
  <Warning {...props} />
))`
  position: absolute;
  color: #ff9100;
  opacity: ${({ status }) => (status === "fail" ? 0.6 : 0)};
  transition: opacity 1s;
`;

export const ValueWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  cursor: ${({ onClick, isReadOnly }) =>
    onClick && !isReadOnly ? "pointer" : "default"};
  ${RegularText} {
    margin-right: 0.4em;
  }
`;

export const StyledSpan = styled.span`
  white-space: break-spaces;
  margin-left: 0.4em;
`;

export const ReferenceSpan = styled.span`
  white-space: break-spaces;
  margin-left: 0.4em;
  visibility: hidden;
  position: fixed;
`;
