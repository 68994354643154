import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FlagForm from "../CreateFlag/FlagForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../ui/Loader";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { dataComponentId } from "../BrandsTable/FlagDetail/FlagDetailContainer";
import { reloadDataComponent } from "../../../../actions/initializeStoreActions";
import { PROGRAMS_SELECT } from "../../../../constants/DataComponents";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions,
  brandId
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      reloadDataComponent(PROGRAMS_SELECT);
      setReload(`${dataComponentId}-${brandId}`, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const EditFlagContainer = ({
  dataComponent,
  closeModalDialog,
  flag,
  brandId,
  setReload,
  performUpdateRequest,
  reloadDataComponent,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions,
      brandId
    );
  }, [
    closeModalDialog,
    reloadDataComponent,
    dataComponent,
    formikActions,
    prevDataComponent,
    setReload,
    brandId,
  ]);

  const handleEditCode = useCallback(
    ({ programsTotal, ...flag }, formikActions) => {
      performUpdateRequest(`${dataComponentId}-${flag.brandId}`, flag.id, flag);
      setFormikAction(formikActions);
    },
    [performUpdateRequest]
  );

  const initialValues = {
    ...flag,
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FlagForm
        initialValues={initialValues}
        onSubmit={handleEditCode}
        dataComponentId={`${dataComponentId}-${flag.brandId}`}
      />
    </LoaderContext.Provider>
  );
};

EditFlagContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  flag: propTypes.flag,
  performUpdateRequest: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  setReload: PropTypes.func,
  brandId: PropTypes.string,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = (state, { brandId }) => {
  const dataComponent = getDataComponent(
    `${dataComponentId}-${brandId}`,
    state
  );
  return {
    dataComponent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFlagContainer);
