import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import TermPage from "./TermPage";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import { fetchProjectTerms } from "../../../../actions/termsActions";
import { setReload } from "../../../../actions/dataComponentActions";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

export const dataComponentId = "TermsProjectGrid";

const TermContainer = ({
  flattenedDataComponent,
  additionalFilters,
  projectId,
  fetchProjectTerms,
  terms,
  dataComponent,
  project,
}) => {
  useEffect(() => {
    fetchProjectTerms(dataComponentId, projectId);
  }, [fetchProjectTerms, projectId]);

  return (
    <TermPage
      dataComponent={flattenedDataComponent}
      additionalFilters={additionalFilters}
      projectId={projectId}
      terms={terms}
      loading={dataComponent.loading}
      project={project}
    />
  );
};

TermContainer.propTypes = {
  terms: PropTypes.arrayOf(propTypes.term),
  additionalFilters: PropTypes.shape({}),
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  projectId: PropTypes.string,
  fetchProjectTerms: PropTypes.func,
  project: propTypes.project,
};

export const mapStateToProps = (state, { match: { params }, project }) => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const terms = getBluechipResources(dataComponent, state) || [];
  return {
    project,
    projectId: params.projectId,
    terms: terms,
    dataComponent,
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  setReload,
  fetchProjectTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermContainer);
