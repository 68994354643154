import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import { BWIntent } from "../../../ui/BWIntent";
import TagText from "../../../ui/Tags/TagText";
import { getStatuses, rowMenuFactory } from "../../Projects/ProjectsPage";
import Grid from "./Grid";
import { SwitchContext } from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";

const intentMap = {
  Completed: BWIntent.SUCCESS,
  Contracted: BWIntent.PRIMARY,
  Created: BWIntent.PRIMARY,
  Canceled: BWIntent.DANGER,
};

export const Status = ({ status }) => (
  <TagText intent={intentMap[status]}>{status}</TagText>
);

Status.propTypes = {
  status: PropTypes.string,
};

const MyProjects = ({
  userId,
  dataComponent,
  handleRowClick,
  updateAuthenticatedUser,
  dashboardShowInClosing,
  dashboardShowClosed,
  ...props
}) => {
  const handleInClosingToggle = () => {
    updateAuthenticatedUser({
      dashboardShowInClosing: !dashboardShowInClosing,
    });
  };

  const handleClosedToggle = () => {
    updateAuthenticatedUser({ dashboardShowClosed: !dashboardShowClosed });
  };

  const statuses = getStatuses(dashboardShowInClosing, dashboardShowClosed);

  return (
    <SwitchContext.Provider
      value={{
        onToggle: [handleInClosingToggle, handleClosedToggle],
        checked: [dashboardShowInClosing, dashboardShowClosed],
        label: ["Show In Closing Projects", "Show Closed Projects"],
      }}
    >
      <Grid
        dataComponent={dataComponent}
        selectedStatuses={statuses}
        getRowMenu={rowMenuFactory(props, "DASHBOARD", true)}
        onRowClick={handleRowClick}
        userId={userId}
      />
    </SwitchContext.Provider>
  );
};

MyProjects.propTypes = {
  userId: PropTypes.string,
  handleRowClick: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  updateAuthenticatedUser: PropTypes.func.isRequired,
  dashboardShowInClosing: PropTypes.bool,
  dashboardShowClosed: PropTypes.bool,
};

export default MyProjects;
