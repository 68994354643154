import { FORM_IDS, initialState } from "./constants";

export function openAddressBookFormReducer(set) {
  return function() {
    set(state => ({ ...state, formId: FORM_IDS.SELECT_CONTACT }));
  };
}

export function openRemitAddressFormReducer(set) {
  return function(hasTransitioned = initialState.hasTransitioned) {
    set(state => ({
      ...state,
      formId: FORM_IDS.REMIT_ADDRESS,
      hasTransitioned,
    }));
  };
}

export function openCreateContactFormReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.CREATE_CONTACT,
    }));
  };
}

export function setTitleModalReducer(set) {
  return function(titleModal) {
    set(state => ({ ...state, titleModal }));
  };
}

export function setVendorReducer(set) {
  return function(vendor) {
    set(state => ({ ...state, vendor }));
  };
}

export function resetStoreReducer(set) {
  return function() {
    set(state => ({ ...state, ...initialState }));
  };
}

export function setCurrentFormReducer(set) {
  return function(currentForm) {
    set(state => ({ ...state, currentForm }));
  };
}
