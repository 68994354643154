import { inputs } from "../../../../components/inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.name,
            label: "Name",
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
