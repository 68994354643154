import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CircularProgress from "@material-ui/core/CircularProgress";
import Search from "@material-ui/icons/Search";
import styled from "styled-components";

const LinksWrapper = styled.div`
  margin-bottom: 8px;
`;

const ReportName = styled.div`
  margin-right: 16px;
`;

const ReportChipContainer = styled.div`
  position: relative;
  display: inline-block;
  &:not(:last-child) {
    margin-right: 16px;
  }
  margin-top: 8px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  color: white;
`;

import propTypes from "../../../constants/propTypes";
import {
  Row,
  Name,
  Category,
  StyledReportList,
  ReportChip,
} from "./components";
import { Title } from "../../ui/Typography/SectionTitle";
import Loader from "../../ui/Loader";

export const ReportLink = ({ label, Icon, onClick, loading, ...report }) => {
  return (
    <ReportChipContainer>
      <ReportChip
        icon={<Icon fontSize="inherit" />}
        label={label}
        onClick={() => !loading && onClick()}
        loading={loading}
        clickable
        color="primary"
        href={`#${report.name}`}
      />
      {loading && <StyledCircularProgress size={20} />}
    </ReportChipContainer>
  );
};

ReportLink.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  Icon: propTypes.component,
  report: propTypes.report,
};

const ReportList = ({
  reportsLoader,
  projectReports,
  handleDownloadReport,
  totalReports,
}) => (
  <StyledReportList>
    <Row>
      <Loader width="150px" height="28px">
        <Title>{totalReports} Reports</Title>
      </Loader>
    </Row>
    {projectReports.map(
      ({
        hasPdf,
        hasCsv,
        hasXls,
        isSingle,
        projectReportCategory: { name: categoryName },
        ...projectReport
      }) => (
        <Row key={`${categoryName}-${projectReport.name}`}>
          <ReportName>
            <Loader width="150px" height="24px">
              <Category>{projectReport.name}</Category>
            </Loader>
            <Loader width="100px" height="16px">
              <Name>{!isSingle ? categoryName : ""}</Name>
            </Loader>
          </ReportName>
          <LinksWrapper>
            <Loader width="150px" height="32px">
              {hasCsv && (
                <ReportLink
                  {...projectReport}
                  label=".CSV"
                  Icon={ArrowDownward}
                  loading={
                    reportsLoader[`${projectReport.templateName}-csv-false`]
                  }
                  onClick={() => handleDownloadReport(projectReport, "csv")}
                />
              )}
              {hasXls && (
                <ReportLink
                  {...projectReport}
                  label=".XLS"
                  Icon={ArrowDownward}
                  loading={
                    reportsLoader[`${projectReport.templateName}-xls-false`]
                  }
                  onClick={() => handleDownloadReport(projectReport, "xls")}
                />
              )}
              {hasPdf && (
                <Fragment>
                  <ReportLink
                    {...projectReport}
                    label=".PDF"
                    Icon={ArrowDownward}
                    loading={
                      reportsLoader[`${projectReport.templateName}-pdf-false`]
                    }
                    onClick={() => handleDownloadReport(projectReport, "pdf")}
                  />
                  <ReportLink
                    {...projectReport}
                    label="Preview"
                    Icon={Search}
                    loading={
                      reportsLoader[`${projectReport.templateName}-pdf-true`]
                    }
                    onClick={() =>
                      handleDownloadReport(projectReport, "pdf", true)
                    }
                  />
                </Fragment>
              )}
            </Loader>
          </LinksWrapper>
        </Row>
      )
    )}
  </StyledReportList>
);

ReportList.propTypes = {
  handleDownloadReport: PropTypes.func,
  projectReports: PropTypes.array,
  reportsLoader: propTypes.reportsLoader,
  totalReports: PropTypes.number,
};

ReportList.defaultProps = {
  projectReportsLoader: [],
};

export default ReportList;
