import styled from "styled-components";

export const BaseOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
`;

export const DefaultOverlay = styled(BaseOverlay)`
  align-items: start;
  padding-top: calc(8vh);
`;

export const WideOverlay = styled(BaseOverlay)`
  align-items: center;
`;

export const Wrapper = styled.div`
  display: inline-block;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.18);
`;

export const BaseContent = styled.div`
  background-color: rgb(255, 255, 255);
`;

export const DefaultContent = styled(BaseContent)`
  width: ${({ width }) => (width ? width : "560px")};
  overflow: ${({ scrollDisabled }) => (scrollDisabled ? "none" : "auto")};
  max-height: 75vh;
  @media (max-width: 768px) {
    width: calc(100vw - 48px) !important;
  }
`;

export const WideContent = styled(BaseContent)`
  width: calc(100vw - 48px);
  height: calc(100vh - 48px);
  @media (max-width: 768px) {
    width: calc(100vw - 48px) !important;
  }
  @media (max-width: 1280px) {
    overflow: scroll;
  }
`;
