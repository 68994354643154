import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  performCreateRequest,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import withPORevision from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";
import Form from "./Form";

const dataComponentId = "SpecDetail-SpecDetailsChildren";

export class CaseBasedQuantityCalculationContainer extends Component {
  initialValues = {
    specId: this.props.spec.id,
    type: "QCCaseBased",
    unitsNeeded: 0,
    unitsPerCase: 0,
  };

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: closeModalDialog,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: closeModalDialog,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  saveCaseBasedQuantityCalculation = (formikSpecDetail, formikActions) => {
    const {
      specDetail,
      performCreateRequest,
      performUpdateRequest,
      revisionValue,
    } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    if (!specDetail) {
      performCreateRequest(dataComponentId, formikSpecDetail, params);
    } else {
      performUpdateRequest(
        dataComponentId,
        specDetail.id,
        formikSpecDetail,
        params
      );
    }

    this.setState({ formikActions });
  };

  render() {
    const { specDetail, spec } = this.props;

    if (specDetail) {
      const { unitsNeeded, unitsPerCase } = specDetail;
      this.initialValues = {
        ...this.initialValues,
        unitsNeeded,
        unitsPerCase,
      };
    }

    return (
      <Form
        initialValues={this.initialValues}
        onSubmit={this.saveCaseBasedQuantityCalculation}
        revisionReference={`specs.${spec.id}.specDetails.${_get(
          specDetail,
          "id"
        )}`}
        dataComponentId={dataComponentId}
      />
    );
  }
}

CaseBasedQuantityCalculationContainer.propTypes = {
  spec: propTypes.spec,
  specDetail: propTypes.specDetail,
  dataComponent: PropTypes.object,
  closeModalDialog: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);

  return {
    dataComponent,
  };
};

export const mapDispatchToProps = {
  performFindRequest,
  performUpdateRequest,
  performCreateRequest,
  performRetrieveListRequest,
  closeModalDialog,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withPORevision(
      CaseBasedQuantityCalculationContainer,
      "SpecDetail",
      "purchaseOrder"
    )
  )
);
