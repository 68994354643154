import React from "react";
import PropTypes from "prop-types";

import { ImportOption, StyledAddCircle, Separation } from "./components";

const ImportOptions = ({
  openImportSpecModal,
  openModalDialog,
  project,
  clientId,
  areaId,
  projectName,
}) => {
  const handleImportFromProject = () => {
    openImportSpecModal({
      project,
      clientId,
      projectName,
      step: "selection",
      isFromProjects: true,
      areaId,
    });
  };

  const handleImportFromFile = () => {
    openImportSpecModal({
      project,
      clientId,
      projectName,
      step: "edition",
      isFromProjects: false,
      areaId,
    });
  };

  const handleImportFromPDF = () =>
    openModalDialog(
      "Import Specs from PDF",
      "ImportSpecsPDF",
      {},
      false,
      true,
      {
        width: "unset",
        height: "calc(100vh - 100px)",
        layoutType: "wide",
      }
    );

  return (
    <div>
      <ImportOption onClick={handleImportFromFile}>
        <StyledAddCircle /> Upload File
      </ImportOption>
      <Separation />
      {process.env.IMPORT_FROM_PDF === "true" && (
        <React.Fragment>
          <ImportOption onClick={handleImportFromPDF}>
            <StyledAddCircle /> Import from PDF
          </ImportOption>
          <Separation />
        </React.Fragment>
      )}
      <ImportOption onClick={handleImportFromProject}>
        <StyledAddCircle /> Import from project
      </ImportOption>
    </div>
  );
};

ImportOptions.propTypes = {
  openImportSpecModal: PropTypes.func,
  openModalDialog: PropTypes.func,
  project: PropTypes.shape({}),
  areaId: PropTypes.string,
  clientId: PropTypes.string,
  projectName: PropTypes.string,
};

export default ImportOptions;
