export const textStyle = {
  fontFamily: "Arial,sans-serif",
  fontSize: "16px",
  marginBottom: "8px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 1)",
};

export const italicTextStyle = {
  ...textStyle,
  fontStyle: "italic",
};
