import React, { Fragment } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import _isNil from "lodash/isNil";
import _get from "lodash/get";
import styled from "styled-components";

import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import Date, { MissingDateIcon } from "../../../BWGrid/columns/Date";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";
export RowMenuProvider from "./RowMenuProvider";
export ActionIconsProvider from "./ActionIconsProvider";

const CurrencyFormatter = ({ value, selectedProjectCurrency = {} }) => {
  const { currency } = selectedProjectCurrency;
  return useCurrencyFormatter(currency).format(value);
};

export const currencyTypeBuilder = (columns, selectedProjectCurrency) => {
  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={props => (
        <CurrencyFormatter
          {...props}
          selectedProjectCurrency={
            (typeof selectedProjectCurrency == "function"
              ? selectedProjectCurrency(props)
              : selectedProjectCurrency) || {
              ..._get(props, "row.projectCurrency"),
              conversionRate: 1,
            }
          }
        />
      )}
      {...props}
      for={columns}
    />
  );
  return CurrencyTypeProvider;
};

const UnitFormatter = ({ value }) => {
  if (_isNil(value)) return "";

  const { value: realValue, unitOfMeasure } = value;

  if (unitOfMeasure === "EA") {
    return realValue;
  }
  return `${realValue}${(unitOfMeasure || "").toLowerCase()}`;
};

export const unitTypeBuilder = columns => {
  const UnitTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={UnitFormatter}
      {...props}
      for={columns}
    />
  );
  return UnitTypeProvider;
};

const NumberFormatter = withEmptyIcon => ({ value }) => {
  const emptyValue = withEmptyIcon ? <MissingDateIcon /> : "";
  return value || emptyValue;
};

export const numberTypeBuilder = (columns, withEmptyIcon = true) => {
  const UnitTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={NumberFormatter(withEmptyIcon)}
      {...props}
      for={columns}
    />
  );
  return UnitTypeProvider;
};

const DateFormatter = format => ({ value }) => {
  const date = <Date date={value} format={format} />;
  return date;
};

export const dateTypeBuilder = (columns, format = PRIMARY_DATE_FORMAT) => {
  const DateTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={DateFormatter(format)}
      {...props}
      for={columns}
    />
  );
  return DateTypeProvider;
};

export const editableTextTypeBuilder = columns => {
  const TextTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={({ value }) => <Fragment>{value}</Fragment>}
      {...props}
      for={columns}
    />
  );
  return TextTypeProvider;
};

const StyledSpan = styled.span`
  color: #57abff;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
  z-index: 2;
  padding: 10px 10px 10px 0;
`;

export const poLinkTypeBuilder = (column, onClickNavigation) => {
  const POLinkTypeProvider = props => {
    return (
      <DataTypeProvider
        formatterComponent={props => {
          const poNumber = _get(props.row, "sequenceIndex")
            ? String(_get(props.row, "sequenceIndex")).padStart(3, "0")
            : "";
          return (
            <StyledSpan
              onClick={event => {
                onClickNavigation(props.row);
                event.stopPropagation();
              }}
            >
              {poNumber}
            </StyledSpan>
          );
        }}
        {...props}
        for={[column]}
      />
    );
  };
  return POLinkTypeProvider;
};

export const projectLinkTypeBuilder = (
  column,
  labelPath,
  onClickNavigation
) => {
  const ProjectLinkTypeProvider = props => {
    return (
      <DataTypeProvider
        formatterComponent={props => {
          const label = _get(props.row, labelPath);
          return (
            <StyledSpan
              onClick={event => {
                onClickNavigation(props.row);
                event.stopPropagation();
              }}
            >
              {label}
            </StyledSpan>
          );
        }}
        {...props}
        for={[column]}
      />
    );
  };
  return ProjectLinkTypeProvider;
};
