import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import BWModels from "benjaminwest-models";

import { FormGrid } from "../../../../forms/FormGrid";
import { getFields } from "./fields";
import { ActionButtons, FormikForm } from "../../../../forms";
import useActions from "../../../../hooks/useActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import { usePrevious } from "../../../../hooks/usePrevious";

export const ExportShippingPaymentsModal = () => {
  const actions = useActions({ generateAndDownloadReport, closeModalDialog });

  const onSubmit = useCallback(
    ({ exportType, ...props }) => {
      actions.generateAndDownloadReport({
        ...props,
        templateName: `shipping-payments-${exportType}`,
        format: "xls",
      });
    },
    [actions]
  );

  const validationSchema = useMemo(
    () =>
      BWModels.Joi.object({
        shipmentsBilled: BWModels.Joi.boolean(),
        shipmentsUnbilled: BWModels.Joi.boolean(),
        billedDateFrom: BWModels.Joi.date().empty(null),
        billedDateTo: BWModels.Joi.date().empty(null),
        exportType: BWModels.Joi.select()
          .options(["summarized", "detailed"])
          .required(),
      }),
    []
  );

  const isGeneratingReport = useSelector(
    state =>
      state.reports.loaders[`shipping-payments-summarized-xls-false`] ||
      state.reports.loaders[`shipping-payments-detailed-xls-false`]
  );

  const prevLoading = usePrevious(isGeneratingReport);

  useEffect(() => {
    if (prevLoading && !isGeneratingReport) {
      actions.closeModalDialog();
    }
  }, [actions, isGeneratingReport, prevLoading]);

  return (
    <FormikForm
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        shipmentsBilled: false,
        shipmentsUnbilled: false,
        billedDateFrom: null,
        billedDateTo: null,
        exportType: "summarized",
      }}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={getFields(formikProps.values.shipmentsBilled)}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            additionalProps={{
              send: {
                isLoading: isGeneratingReport,
                disabled:
                  !formikProps.values.shipmentsBilled &&
                  !formikProps.values.shipmentsUnbilled,
              },
            }}
            sendButtonText="EXPORT"
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};
