export default {
  small: {
    base: {
      width: "28px",
      height: "28px",
      padding: "2px 0",
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: "normal",
    },
    primary: {
      borderColor: "#ffffff",
      backgroundColor: "#4a90e2",
    },
    surplus: {
      borderColor: "#ffffff",
      backgroundColor: "#bdbdbd",
    },
  },
  medium: {
    base: {
      width: "36px",
      height: "36px",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "600",
    },
    primary: {
      borderColor: "#27587a",
      backgroundColor: "#4a90e2",
    },
    surplus: {
      borderColor: "#ffffff",
      backgroundColor: "#bdbdbd",
    },
  },
};
