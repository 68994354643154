import React, { Fragment } from "react";
import PropTypes from "prop-types";

import FormGrid from "../../../../forms/FormGrid";
import requirementFields from "./requirementFields";
import { ActionButtons, FormikForm } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";

const RequirementForm = ({
  onSubmit,
  selectedRequirements,
  specRequirement,
  onChange,
  value,
}) => {
  return (
    <FormikForm>
      {() => (
        <Fragment>
          <FormGrid
            fields={requirementFields(
              value,
              selectedRequirements,
              specRequirement,
              onChange
            )}
          />
          <ActionButtons
            additionalProps={{
              send: {
                disabled:
                  specRequirement &&
                  specRequirement.requirement.id === value.id,
              },
            }}
            onSend={onSubmit}
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

RequirementForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  selectedRequirements: PropTypes.arrayOf(propTypes.requirement),
  specRequirement: propTypes.specRequirement,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(propTypes.requirement),
    propTypes.requirement,
  ]),
};

export default RequirementForm;
