import styled from "styled-components";
import { StyledFormControlLabel } from "../../../inputs/CheckboxWithError";
import { Checkbox } from "../../../mui/core";

export const FieldContainer = styled.div({});
export const Row = styled.div`
  display: flex;
  padding: 0 24px;
  margin-top: ${({ isFirst }) => isFirst && "18px"};

  ${FieldContainer} {
    margin-left: 0;
    margin-bottom: 24px;
  }

  ${StyledFormControlLabel} {
    ${Checkbox} {
      padding: 0;
      margin-right: 16px;
    }
  }
`;
