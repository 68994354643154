import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  performUpdateRequest,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import {
  setHeaderTitle,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import { initializeClientDependencies } from "../../../actions/clientActions";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import propTypes from "../../../constants/propTypes";
import { LoaderContext } from "../../ui/Loader";
import _get from "lodash/get";
import ClientForm from "../ClientCreate/ClientForm";
import { uploadFiles } from "../../../actions/filesActions";

const dataComponentId = "ClientEdit";

export class ClientEditContainer extends Component {
  componentDidMount() {
    const {
      client,
      clientId,
      performFindRequest,
      setAutoSaveComponentId,
      initializeClientDependencies,
    } = this.props;

    setAutoSaveComponentId(dataComponentId);
    initializeClientDependencies(dataComponentId);
    performFindRequest(dataComponentId, clientId);

    if (client) {
      this.props.setHeaderTitle(`Edit ${client.name}`);
    }
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
  }

  handleEditClient = async (patchBody, formikActions) => {
    const { performUpdateRequest, client, uploadFiles } = this.props;

    const files = await uploadFiles(
      patchBody,
      client.id,
      ["msaFile"],
      "clients"
    );
    files.reduce((client, { field, ...file }) => {
      client[field] = file;
      return client;
    }, patchBody);
    performUpdateRequest(dataComponentId, client.id, patchBody);
    this.setState({ formikActions });
  };

  render() {
    const { client, loading } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <ClientForm client={client} onSubmit={this.handleEditClient} isUpdate />
      </LoaderContext.Provider>
    );
  }
}

ClientEditContainer.propTypes = {
  loading: PropTypes.bool,
  client: propTypes.client,
  clientId: PropTypes.string,
  setHeaderTitle: PropTypes.func,
  dataComponent: PropTypes.object,
  performFindRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  setAutoSaveComponentId: PropTypes.func,
  initializeClientDependencies: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const clientId = ownProps.match.params.clientId;
  return {
    clientId,
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
    client: getBluechipResourceById(dataComponent, state, clientId),
  };
};

export const mapDispatchToProps = {
  initializeClientDependencies,
  setAutoSaveComponentId,
  performUpdateRequest,
  performFindRequest,
  setHeaderTitle,
  uploadFiles,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientEditContainer)
);
