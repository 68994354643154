import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CreateTemplateForm from "./CreateTemplateForm";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performCreateRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../../utils/dataComponentUtils";
import { Icon } from "../../../SpecDetail/addSpecDetailMenuItems";
import { getOptionsFromSchema } from "../../../../inputs/utils";

export const dataComponentId = "SpecCategoriesGrid";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });

  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const addDescriptionAndReOpenModal = (
  openModalDialog,
  { specCategoryDetails = [], detailsIdSequence = [], ...values },
  id,
  sourceId
) => {
  const isDescription = id === "Description";
  const tempId = Date.now().toString();
  detailsIdSequence.push(tempId);
  openModalDialog(
    sourceId ? `Edit ${values.name}` : "Spec Template",
    "CreateTemplate",
    {
      instance: {
        id: sourceId,
        ...values,
        detailsIdSequence,
        specCategoryDetails: [
          ...specCategoryDetails,
          {
            id: tempId,
            isTemp: true,
            type: id,
            name: isDescription ? "" : id,
            isEditing: isDescription,
          },
        ],
      },
    },
    false
  );
};

const CreateTemplateContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  instance: {
    id: sourceId,
    specCategoryDetails = [],
    detailsIdSequence = [],
    ...instance
  },
  performCreateRequest,
  performUpdateRequest,
  openModalDialog,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  });

  const handleOpenAddModal = useCallback(
    values => {
      const { specCategoryDetails = [] } = values;
      const items = getOptionsFromSchema("SpecCategoryDetail", "detailTypes")();
      openModalDialog(
        "Select Section",
        "MenuModal",
        {
          items: items
            .filter(
              ({ id }) =>
                id === "Description" ||
                !specCategoryDetails.find(detail => detail.type === id)
            )
            .map(({ id }) => ({
              children: (
                <Fragment>
                  <Icon /> Add {id}
                </Fragment>
              ),
              onClick: () =>
                addDescriptionAndReOpenModal(
                  openModalDialog,
                  values,
                  id,
                  sourceId
                ),
            })),
        },
        false
      );
    },
    [openModalDialog, sourceId]
  );

  const handleSubmit = useCallback(
    (newEntity, formikActions) => {
      setFormikAction(formikActions);
      if (sourceId) {
        return performUpdateRequest(dataComponentId, sourceId, newEntity);
      }
      performCreateRequest(dataComponentId, newEntity);
    },
    [performCreateRequest, sourceId, performUpdateRequest]
  );

  const filteredSequence = detailsIdSequence.filter(
    id => !!specCategoryDetails.find(detail => detail.id === id)
  );

  return (
    <CreateTemplateForm
      initialValues={{
        ...instance,
        detailsIdSequence: filteredSequence,
        specCategoryDetails: filteredSequence.map(id =>
          specCategoryDetails.find(detail => detail.id === id)
        ),
      }}
      onSubmit={handleSubmit}
      onOpenAddModal={handleOpenAddModal}
      isEditing={!!sourceId}
      dataComponentId={dataComponentId}
    />
  );
};

CreateTemplateContainer.defaultProps = {
  instance: {
    name: undefined,
    type: undefined,
    specCategoryDetails: [],
    isActive: true,
  },
};

CreateTemplateContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  instance: propTypes.specCategory,
  closeModalDialog: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  openModalDialog,
  performCreateRequest,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTemplateContainer);
