import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import Edit from "@material-ui/icons/Edit";
import _isNil from "lodash/isNil";

import propTypes from "../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../withPORevision";

export const StyledEdit = styled(Edit)`
  color: #979797;
  margin-left: 10px;
  font-size: 12px;
  width: 12px;
  height: 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Placeholder = styled.div`
  color: #bdbdbd;
  white-space: nowrap;
`;

const EditableColumn = ({ children, placeholder }) => {
  const isLabeled = useContext(LabeledTextContext);
  if (isLabeled) {
    return typeof children === "string" ? children : null;
  }

  return (
    <Fragment>
      {_isNil(children) ? <Placeholder>{placeholder}</Placeholder> : children}
      <StyledEdit />
    </Fragment>
  );
};
EditableColumn.propTypes = {
  children: propTypes.component,
  placeholder: propTypes.component,
};

export default EditableColumn;
