import React from "react";
import styled from "styled-components";
import Loader from "../../../../../ui/Loader";

export const TextWrapper = styled.div`
  padding: 0px 24px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TextWrapperWithLoading = ({ children, ...props }) => (
  <TextWrapper {...props}>
    <Loader>{children}</Loader>
  </TextWrapper>
);
