import React from "react";
import PropTypes from "prop-types";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import { ScopeWrapper } from "./components";
import TagText from "../../../ui/Tags/TagText";
import { BWIntent } from "../../../ui/BWIntent";

const ScopeFormatter = ({ row: { workScope } }) => {
  return (
    <ScopeWrapper>
      <TagText intent={BWIntent.PRIMARY}>{workScope?.name}</TagText>
    </ScopeWrapper>
  );
};

ScopeFormatter.propTypes = {
  row: PropTypes.object,
};

export const scopeTypeBuilder = columns => {
  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={ScopeFormatter}
      {...props}
      for={columns}
    />
  );
  return CurrencyTypeProvider;
};
