import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../../../../forms/ActionButtons";
import RegularText from "../../../../ui/Typography/RegularText";
import cancelFields from "./cancelFields";
import InputWrapper from "../../../../inputs/InputWrapper";
import { Select } from "./components";

export const Message = styled(RegularText)`
  padding: 24px;
`;

const { comments } = cancelFields;

const CancelPOConfirmation = ({
  onSubmit,
  onDissmiss,
  notes,
  onChangeNotes,
}) => (
  <Fragment>
    <Message>
      Please provide reasons why this purchase order needs to be canceled:
    </Message>
    <Select>
      <InputWrapper {...comments} value={notes} onChange={onChangeNotes} />
    </Select>
    <ActionButtons
      cancelButtonText="RETURN"
      onCancel={onDissmiss}
      onDanger={onSubmit}
      dangerButtonText="Cancel Purchase Order"
      isModal
      emphasizeCancel
    />
  </Fragment>
);

CancelPOConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDissmiss: PropTypes.func.isRequired,
  onChangeNotes: PropTypes.func.isRequired,
  notes: PropTypes.string,
};

export default CancelPOConfirmation;
