import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { RadioGroup as MUIRadioGroup } from "../../../../mui/core";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

const CheckIcon = styled(({ isChecked, ...props }) => (
  <CheckCircle {...props} />
))`
  display: flex;
  color: ${props => (props.isChecked ? "#72b327" : "#D8D8D8")};
`;
const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const DiscountTitle = styled.div`
  flex-grow: 1;
`;
const DiscountAmount = styled.div`
  margin: 8px 0px;
  font-family: Domine, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${props => (props.isChecked ? "rgba(0, 0, 0, 0.87)" : "#979797")};
`;
const DiscountProgram = styled.div`
  margin-bottom: 8px;
  color: ${props => (props.isChecked ? "rgba(0, 0, 0, 0.87)" : "#979797")};
`;
const DiscountCheckboxGroup = props => {
  const { options = [], onChange, direction } = props;
  const [checkedOptions, setCheckedOptions] = useState([]);
  useEffect(() => {
    const selectedOptions = [];
    options.map(option => {
      if (option.isChecked) {
        selectedOptions.push(option.name);
      }
    });
    setCheckedOptions(selectedOptions);
  }, [options]);
  const handleChange = useCallback(
    (event, name) => {
      const checked = !checkedOptions.includes(name);
      const target = {
        name: "purchaseOrderDiscounts",
        value: [],
      };
      options.map(option => {
        if (option.name !== name && option.isChecked) {
          target.value.push({
            discountId: option.name,
          });
        }
      });
      if (checked) {
        target.value.push({
          discountId: name,
        });
      }
      onChange({
        target,
      });
    },
    [checkedOptions, options, onChange]
  );

  const isProjectClosed = useIsProjectClosed();

  return (
    <MUIRadioGroup direction={direction}>
      {options.map(({ label, name, amount, isChecked }, index) => {
        return (
          <DiscountWrapper key={index}>
            <DiscountTitle>
              <DiscountAmount isChecked={isChecked}>{amount}</DiscountAmount>
              <DiscountProgram isChecked={isChecked}>{label}</DiscountProgram>
            </DiscountTitle>
            <IconButton
              disabled={isProjectClosed}
              onClick={event => handleChange(event, name)}
            >
              <CheckIcon isChecked={isChecked} />
            </IconButton>
          </DiscountWrapper>
        );
      })}
    </MUIRadioGroup>
  );
};

DiscountCheckboxGroup.defaultProps = {
  direction: "row",
};

DiscountCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.string,
};

export default DiscountCheckboxGroup;
