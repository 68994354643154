import React, { useEffect, useState } from "react";
import { Cached } from "@material-ui/icons";
import { BaseModel } from "@blue-chip/core";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import pluralize from "pluralize";

import { Button } from "../../../layout/SectionHeader";
import useActions from "../../../hooks/useActions";
import {
  initDataComponent,
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { useDatacomponent, useLoading } from "../../../hooks/useDatacomponent";
import { useCreateProcessRequests } from "../../../hooks/useProcessRequest";
import { dataComponentId as posRequiringRefundsDataComponentId } from "./PurchaseOrdersRequiringRefundsContainer";

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const RotatingCachedIcon = styled(Cached)`
  animation: ${rotate} 2s linear infinite;
`;

const getDisabled = (isLoading, seconds) => isLoading || seconds > 0;
const getIcon = isLoading => (isLoading ? <RotatingCachedIcon /> : <Cached />);
const getTitle = seconds =>
  seconds ? `Please wait ${pluralize("second", seconds, true)}.` : "";

const dataComponentId = "refresh-pos-requiring-refunds-materialized-view";
export const UpdateNowButton = ({ lastRefreshedAt }) => {
  const initialDelay = moment(lastRefreshedAt).isAfter(
    moment().subtract(1, "minute")
  )
    ? 60
    : 0;
  const [seconds, setSeconds] = useState(initialDelay);

  const actions = useActions({
    initDataComponent,
    performCreateRequest,
    setReload,
  });

  useEffect(() => {
    if (seconds === 0) {
      return;
    }
    setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds]);

  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      BaseModel,
      [],
      "refresh-purchase-orders-requiring-refund",
      true,
      "v2"
    );
  }, [actions]);

  const dataComponent = useDatacomponent(dataComponentId);
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.setReload(posRequiringRefundsDataComponentId, true);
    },
  });

  const isLoading = useLoading(dataComponentId);

  const isDisabled = getDisabled(isLoading, seconds);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    actions.performCreateRequest(dataComponentId, {});
  };

  return (
    <Tooltip title={getTitle(seconds)}>
      <span>
        <Button
          intent={isDisabled ? "primaryInverted" : "primary"}
          onClick={handleClick}
          text={"Update Now"}
          icon={getIcon(isLoading)}
        />
      </span>
    </Tooltip>
  );
};
UpdateNowButton.propTypes = {
  lastRefreshedAt: PropTypes.string,
};
