import React, { useState } from "react";
import { Tooltip as MUITooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const Tooltip = ({ title, onChange, children }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const setTooltipIsOpenWrapper = value => evt => {
    setTooltipIsOpen(value);
    if (onChange) {
      onChange(evt);
    }
  };

  const onClick = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    setTooltipIsOpenWrapper(!tooltipIsOpen)(evt);
  };

  return (
    <MUITooltip
      open={tooltipIsOpen}
      onOpen={setTooltipIsOpenWrapper(true)}
      onClose={setTooltipIsOpenWrapper(false)}
      title={title}
      onClick={onClick}
      leaveTouchDelay={3000}
    >
      {children}
    </MUITooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Tooltip;
