import React, { memo, useMemo } from "react";

import { inputs } from "../../../../inputs/inputConfigs";

import { StyledEdit } from "../../../Quoting/PurchaseOrderList/components";
import BWGrid from "../../../../ui/BWGrid/BWGrid";
import GridTable from "../../../../ui/BWGrid/GridTable";
import propTypes from "../../../../../constants/propTypes";
import Column, { editableColumns } from "./Column";
import getRows from "./getRows";
import editableCellCreator, { StyledBWCell } from "./editableCellCreator";
import PropTypes from "prop-types";
import withFloatInputValidator from "../../../../inputs/FloatInput/withFloatInputValidator";
import validator from "./validator";

const rowInputs = currency => ({
  depositsPaid: {
    ...inputs.price,
    name: "depositsPaid",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "70px",
    },
    inputProps: {
      maskProps: { allowNegative: true },
      currency,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
  assetsPaid: {
    ...inputs.price,
    name: "assetsPaid",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "70px",
    },
    inputProps: {
      maskProps: { allowNegative: true },
      currency,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
});

const buildColumn = (isEditable, projectCurrency) => (row, path, rowData) => {
  return (
    row && (
      <Column
        row={row}
        path={path}
        rowData={rowData}
        isEditable={isEditable}
        projectCurrency={projectCurrency}
      />
    )
  );
};

const columns = [
  "title",
  "assetsPaid",
  "assetsToDate",
  "depositsPaid",
  "depositsToDate",
  "net",
  "notProcessed",
];

export const updateInvoice = (invoice, { value, field, setFieldValue }) => {
  const rows = [
    "merchandise",
    "overage",
    "tax",
    "accruedUseTax",
    "freightWarehousing",
    "install",
    "otherCost",
    "discount",
  ];

  const totalAssetsPaid = rows.reduce(
    (prev, current) =>
      prev +
      (field.name !== `${current}AssetsPaid`
        ? invoice[`${current}AssetsPaid`]
        : value),
    0
  );
  const totalDepositsPaid = rows.reduce(
    (prev, current) =>
      prev +
      (field.name !== `${current}DepositsPaid`
        ? invoice[`${current}DepositsPaid`]
        : value),
    0
  );

  setFieldValue("totalAssetsPaid", totalAssetsPaid);
  setFieldValue("totalDepositsPaid", totalDepositsPaid);
};

export const getColumnOptions = (isEditable = true, invoice, setFieldValue) => {
  const projectCurrency = invoice.purchaseOrder?.projectCurrency;
  return {
    title: {
      title: " ",
      render: buildColumn(false),
    },
    assetsPaid: {
      title: "Assets",
      render: buildColumn(isEditable, projectCurrency),
      editable: isEditable,
      editableOptions: {
        afterSave: (value, { field }) =>
          updateInvoice(invoice, { value, field, setFieldValue }),
      },
    },
    assetsToDate: {
      title: " ",
      render: buildColumn(isEditable, projectCurrency),
      editable: isEditable,
    },
    depositsPaid: {
      title: "Deposits",
      render: buildColumn(isEditable, projectCurrency),
      editableOptions: {
        afterSave: (value, { field }) =>
          updateInvoice(invoice, { value, field, setFieldValue }),
      },
    },
    depositsToDate: {
      title: " ",
      render: buildColumn(isEditable, projectCurrency),
    },
    net: {
      title: "Net",
      render: buildColumn(isEditable, projectCurrency),
    },
    notProcessed: {
      render: buildColumn(isEditable, projectCurrency),
    },
  };
};

const gridConfig = {
  totalRows: 0,
  pageSize: 0,
};

const PricingSectionPage = ({
  invoice,
  readOnly,
  formikProps: { setFieldValue, initialValues: originalInvoice },
  setCurrentCellFunc,
  pageSectionRef,
}) => {
  const rows = useMemo(() => getRows(invoice), [invoice]);
  const columnOptions = useMemo(
    () => getColumnOptions(!readOnly, invoice, setFieldValue),
    [invoice, readOnly, setFieldValue]
  );

  const openNextFallback = useMemo(
    () => () => {
      setTimeout(
        () =>
          pageSectionRef.current.nextElementSibling
            .querySelector("textarea")
            .focus(),
        0
      );
    },
    [pageSectionRef]
  );

  const openPrevFallback = useMemo(
    () => () => {
      setTimeout(() => {
        const prevInput = pageSectionRef.current.querySelectorAll("input")[2];
        if (prevInput) {
          return prevInput.focus();
        }
        [
          ...pageSectionRef.current.previousElementSibling
            .querySelector("table")
            .querySelector("tbody")
            .querySelectorAll("div"),
        ]
          .at(-1)
          .click();
      }, 0);
    },
    [pageSectionRef]
  );

  const tableComponents = useMemo(
    () => ({
      CellComponent: withFloatInputValidator(
        editableCellCreator(
          rowInputs(invoice.purchaseOrder?.projectCurrency),
          ({ rowId }, { name }) => editableColumns[rowId][name],
          openNextFallback,
          openPrevFallback
        ),
        validator(invoice, originalInvoice)
      ),
    }),
    [invoice, openNextFallback, openPrevFallback, originalInvoice]
  );

  return (
    <BWGrid
      rows={rows}
      gridConfig={gridConfig}
      tableComponents={
        !readOnly ? tableComponents : { CellComponent: StyledBWCell }
      }
      setCurrentCellFunc={setCurrentCellFunc}
    >
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGrid>
  );
};

PricingSectionPage.propTypes = {
  invoice: propTypes.invoice,
  readOnly: PropTypes.bool,
  formikProps: PropTypes.object,
  setCurrentCellFunc: PropTypes.func.isRequired,
  pageSectionRef: PropTypes.object.isRequired,
};

export default memo(PricingSectionPage);
