import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import styled from "styled-components";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Menu, MenuItem } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getIsProjectClosed,
  ProjectContext,
} from "../../hooks/useIsProjectClosed";

const StyledDiv = styled.div`
  display: inline-block;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`;

const StyledArrowDropdown = styled(ArrowDropDown)`
  margin: -2px 0 0 8px;
`;

export const MenuItemWrapper = React.forwardRef(
  ({ useSeparator, hasIcon, ...itemProps }, ref) => (
    <MenuItem ref={ref} {...itemProps} />
  )
);

export const StyledMenuItem = styled(MenuItemWrapper)`
  border-bottom: ${({ useSeparator }) =>
    useSeparator ? "1px solid rgba(0, 0, 0, 0.12)" : null};
  padding: 12px ${({ hasIcon }) => (hasIcon ? "16px" : "14px")};
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 20px;
`;

export const StyledMenuItemLabel = styled(StyledMenuItem)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  padding-bottom: 4px;

  &:hover {
    background: white;
    cursor: auto;
  }
`;

const OptionIcon = styled(ListItemIcon)`
  color: rgba(0, 0, 0, 0.24);
`;

export const CustomMenuItem = React.forwardRef(
  ({ isLabel, ...itemProps }, ref) => {
    if (!isLabel) return <StyledMenuItem ref={ref} {...itemProps} />;
    return <StyledMenuItemLabel ref={ref} {...itemProps} />;
  }
);

class DropdownMenu extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        isLabel: PropTypes.bool,
        icon: PropTypes.node,
        useSeparator: PropTypes.bool,
      })
    ),
    Button: propTypes.component,
    Icon: propTypes.component,
    buttonText: PropTypes.string,
    tooltip: PropTypes.string,
  };

  state = {
    anchorEl: null,
  };

  static contextType = ProjectContext;

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { Button, Icon, buttonText, options = [], tooltip } = this.props;
    const { anchorEl } = this.state;

    const projectContext = this.context;
    const isProjectClosed = getIsProjectClosed(projectContext);

    return (
      <Fragment>
        {Icon ? (
          <Tooltip title={tooltip}>
            <Button
              aria-owns={anchorEl ? "dropdownMenu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <Icon />
            </Button>
          </Tooltip>
        ) : (
          <StyledDiv>
            <StyledButton
              aria-owns={anchorEl ? "dropdownMenu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              {buttonText}
              <StyledArrowDropdown />
            </StyledButton>
          </StyledDiv>
        )}

        <Menu
          id="dropdownMenu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          disableAutoFocusItem
        >
          {options.map(
            (
              { onClick, content, icon, disableIfProjectClosed, ...itemProps },
              i
            ) => {
              const disabled =
                itemProps.disabled ||
                (isProjectClosed && disableIfProjectClosed);
              return (
                <CustomMenuItem
                  hasIcon={!!icon}
                  key={i}
                  onClick={() => {
                    this.handleClose();
                    onClick && onClick();
                  }}
                  {...itemProps}
                  disabled={disabled}
                >
                  {icon && <OptionIcon>{icon}</OptionIcon>}
                  {content}
                </CustomMenuItem>
              );
            }
          )}
        </Menu>
      </Fragment>
    );
  }
}

export default DropdownMenu;
