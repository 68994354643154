import React from "react";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";

import { Paper } from "../../../mui/core";
import PaymentTerm from "../../../../models/PaymentTerm";
import propTypes from "../../../../constants/propTypes";
import { columnOptions as officeTableColumnOptions } from "../OfficesTable/OfficesTable";

const columns = ["content", "isArchived"];
export const columnOptions = {
  content: { title: "Payment Term", bold: true, filter: true },
  isArchived: officeTableColumnOptions.isArchived,
};
const rowMenu = ({ onOpenEditModal, onOpenDeleteModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
  { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Payment Term",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const PaymentTermsPage = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={PaymentTerm}
        apiRoute="payment-terms"
        apiFilters={{
          sort: [{ columnName: "content" }],
          rootFilters: {
            displayArchived: true,
          },
        }}
        defaultSorting={[{ columnName: "content", direction: "asc" }]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Payment Terms`}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu({ onOpenEditModal, onOpenDeleteModal })}
          showSelectionColumn={true}
          showSelectAll={true}
        />
      </BWGridAPI>
    </Paper>
  );
};

PaymentTermsPage.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default PaymentTermsPage;
