import styled from "styled-components";
import React from "react";
import _get from "lodash/get";

import { Row } from "../../../../ui/Grid/utilComponents/tableComponents";

const StyledRowComponent = styled(
  ({ rowWasDeleted, rowWasCreated, ...props }) => <Row {...props} />
)`
  > td,
  th {
    border-bottom: none;
    background-color: ${({ onClick }) => !onClick && "#ffffff !important"};
    ${({ rowWasCreated, rowWasDeleted }) =>
      (rowWasCreated || rowWasDeleted) &&
      `
      font-style: italic;
      font-weight: bold;
    `}
    &:not(:nth-child(2)) {
      opacity: ${({ rowWasDeleted }) => rowWasDeleted && "0.3"};
    }
    &:nth-child(2) {
      position: relative;
      > * {
        opacity: ${({ rowWasDeleted }) => rowWasDeleted && "0.3"};
      }
      &:after {
        ${({ rowWasCreated, rowWasDeleted }) =>
          (rowWasCreated || rowWasDeleted) &&
          `
        position: absolute;
        content: "\\25CF";
        font-size: 10px;
        top: 2px;
        left: 2px;
        overflow: visible;
      `}
        ${({ rowWasDeleted }) => rowWasDeleted && "color: #ff1744;"}
      }
    }
  }
`;

function RowComponent(props) {
  const { onClick, tableRow } = props;
  const row = _get(tableRow, "row", {});

  return (
    <StyledRowComponent
      rowWasDeleted={row.__deleted__}
      {...props}
      onClick={row.__deleted__ ? undefined : onClick}
    />
  );
}

export default RowComponent;
