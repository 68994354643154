import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";

import TextField from "./TextField";
import { setEditableTextRow } from "../../../../../../actions/editableTextDetailRowActions";

export const mapStateToProps = (state, { dataComponentId }) => {
  return {
    isEditing: _get(
      state.editableTextDetailRow,
      `${dataComponentId}.isEditing`
    ),
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setEditableTextRow,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextField);
