import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import propTypes from "../../../../../../constants/propTypes";
import { Paper as MUIPaper } from "../../../../../mui/core";
import QuoteFileGrid from "../../../../VendorRFQs/QuoteDetail/QuoteForm/QuoteFilesGrid/QuoteFileGrid";
import { BWActionButton } from "../../../../../ui/BWActionButton";
import { GetApp } from "@material-ui/icons";

const Paper = styled(MUIPaper)`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  align-self: end;
`;

export const DownloadQuoteFiles = ({
  bidGroupVendor: { id, quoteFiles },
  downloadQuoteFiles,
  loading,
}) => {
  const handleDownloadAll = useMemo(() => () => downloadQuoteFiles(id), [
    downloadQuoteFiles,
    id,
  ]);

  return (
    <Paper noBorder>
      <ButtonWrapper>
        <BWActionButton
          icon={<GetApp />}
          onClick={handleDownloadAll}
          text="Download All"
          loading={loading}
        >
          Download All
        </BWActionButton>
      </ButtonWrapper>
      <QuoteFileGrid rows={quoteFiles} quoteStatus="submitted" />
    </Paper>
  );
};

DownloadQuoteFiles.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor,
  downloadQuoteFiles: PropTypes.func,
  loading: PropTypes.bool,
};
