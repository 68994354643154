export default class StatusService {
  constructor() {
    this._herokuAppName = process.env.HEROKU_APP_NAME;
    this._herokuApiKey = process.env.HEROKU_API_KEY;
  }

  async herokuStatus() {
    const response = await fetch(
      `${this._herokuEndpoint()}/dynos`,
      this._herokuHeaders()
    );
    return this._processResponse(response);
  }

  async herokuDatabaseStatus() {
    const response = await fetch(
      `${this._herokuEndpoint()}/addons/${process.env.HEROKU_DATABASE_NAME}`,
      this._herokuHeaders()
    );
    return this._processResponse(response);
  }

  async herokuRedisStatus() {
    const response = await fetch(
      `${this._herokuEndpoint()}/addons/${process.env.HEROKU_REDIS_NAME}`,
      this._herokuHeaders()
    );
    return this._processResponse(response);
  }

  async apiVerion() {
    return await this._fetchTimedRequest(`${process.env.API_URL}/version`);
  }

  async databaseStatus() {
    return await this._fetchTimedRequest(
      `${process.env.API_URL}/status/database`
    );
  }

  async messagingStatus() {
    return await this._fetchTimedRequest(
      `${process.env.API_URL}/status/messaging`
    );
  }

  async emailStatus() {
    return await this._fetchTimedRequest(`${process.env.API_URL}/status/email`);
  }

  async lambdaStatus() {
    return await this._fetchTimedRequest(
      `${process.env.API_URL}/status/lambda`
    );
  }

  async _fetchTimedRequest(endpoint) {
    const start = performance.now();
    const response = await fetch(endpoint);
    const end = performance.now();
    const time = end - start;
    return this._processResponse(response, time);
  }

  async _processResponse(response, time) {
    if (!response.ok) {
      throw new Error("Could not retrieve status.");
    }
    const json = await response.json();
    return { json, time };
  }

  _herokuHeaders() {
    return {
      headers: {
        Accept: "application/vnd.heroku+json; version=3",
        Authorization: `Bearer ${this._herokuApiKey}`,
      },
    };
  }

  _herokuEndpoint() {
    return `https://api.heroku.com/apps/${this._herokuAppName}`;
  }
}
