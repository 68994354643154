import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { closeModalDialog } from "../../../../../../actions/layoutActions";

import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { processUpdateRequestStatus } from "../../../../../../utils/dataComponentUtils";

import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../../../reducers/dataComponentReducer";
import QuoteForm from "./QuoteForm";
import { LoaderContext } from "../../../../../ui/Loader";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../../actions/dataComponentActions";
import { getCurrentBluechipResourcesForRequestType } from "../../../../../../utils/bluechipUtils";
import { bidGroupVendorDataComponentId } from "../../../../../../actions/bidGroupActions";

export const QuoteEdit = ({
  bidGroupVendor,
  closeModalDialog,
  performUpdateRequest,
  loading,
  dataComponent,
  projectId,
  projectDefaultCurrency,
}) => {
  const [formikActions, setFormikActions] = useState(null);
  const [prevDataComponent, setPrevDatacomponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDatacomponent(dataComponent);
  }, [dataComponent]);

  const handleSubmit = useCallback(
    (data, formikActions) => {
      performUpdateRequest(
        bidGroupVendorDataComponentId,
        bidGroupVendor.id,
        data
      );
      setFormikActions(formikActions);
    },
    [bidGroupVendor.id, performUpdateRequest]
  );

  useEffect(() => {
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
      },
      onError: error => handleRequestError(error, formikActions),
    });
  }, [closeModalDialog, dataComponent, formikActions, prevDataComponent]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <QuoteForm
        bidGroupVendor={bidGroupVendor}
        onCancel={closeModalDialog}
        onSubmit={handleSubmit}
        projectDefaultCurrency={projectDefaultCurrency}
        dataComponentId={bidGroupVendorDataComponentId}
        projectId={projectId}
      />
    </LoaderContext.Provider>
  );
};

QuoteEdit.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor,
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  loading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  projectId: PropTypes.string,
  projectDefaultCurrency: propTypes.projectCurrency,
};

export const mapStateToProps = (state, { match }) => {
  const projectCurrencies = getCurrentBluechipResourcesForRequestType(
    "select-project-currencies",
    state
  );
  const projectId = match.params.projectId;
  const projectDefaultCurrency = projectCurrencies
    ? projectCurrencies.find(
        pcItem => pcItem.projectId == projectId && pcItem.isDefault
      )
    : null;
  return {
    projectId,
    dataComponent: getDataComponent(bidGroupVendorDataComponentId, state),
    loading: getDataComponentFlattenedRequestState(
      bidGroupVendorDataComponentId,
      state,
      REQUEST_TYPES.UPDATE
    ).loading,
    projectDefaultCurrency,
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performUpdateRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteEdit)
);
