import React, { useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FieldArray, connect as formikConnect } from "formik";

import { openModalDialog } from "../../../../actions/layoutActions";
import RenderedField from "./RenderedField";

const name = "sites";

export const ProjectAddressGridContainer = ({ formik, openModalDialog }) => {
  const originalValues = formik.values[name];
  const rows = useMemo(() => [...originalValues], [originalValues]);
  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => (
        <RenderedField
          openModalDialog={openModalDialog}
          formikArrayHelpers={formikArrayHelpers}
          projectId={formik.values.id}
          rows={rows}
        />
      )}
    />
  );
};

ProjectAddressGridContainer.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }),
  openModalDialog: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(
  null,
  mapDispatchToProps
)(formikConnect(ProjectAddressGridContainer));
