import styled from "styled-components";
import React from "react";
import { WideOverlay } from "../../../../layout/AppLayout/ModalDialog/ModalDialog";

export const ModalContent = styled.div`
  background-color: rgb(255, 255, 255);
  min-width: 560px;
  max-height: calc(100vh - 148px);
  overflow-y: auto;
  position: relative;
`;

export const ContentWrapper = props => <ModalContent {...props} />;

export const Overlay = props => <WideOverlay {...props} />;

export const GridWrapper = styled.div`
  width: calc(100vw - 48px);
`;

export const FormWrapper = styled.div`
  width: 828px;
`;
