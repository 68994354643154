import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import React from "react";

const GridContainer = styled(({ noPaddingLeft, ...props }) => (
  <Grid spacing={3} {...props} />
))({});
const GridItem = styled(({ noPaddingLeft, ...props }) => (
  <Grid xs {...props} />
))`
  padding: 4px 12px !important;
  ${({ noPaddingLeft }) =>
    noPaddingLeft ? "padding-left: 0px !important;" : ""}
`;

export default styled(props =>
  props.container ? <GridContainer {...props} /> : <GridItem {...props} />
)({});
