import styled from "styled-components";
export const DnDItemWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const DnDItemsWrapper = styled.div`
  padding-top: 18px;
  padding-bottom: 12px;
`;
