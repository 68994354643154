import React, { useMemo, useRef, useEffect } from "react";
import _get from "lodash/get";

import createGeneralInfoFields from "./createGeneralInfoFields";
import {
  FormPageSection,
  formSectionPropTypes,
} from "../../../layout/FormPageSection";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import { inputs } from "../../../inputs/inputConfigs";
import * as RequestTypes from "../../../../constants/RequestTypes";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../actions/layoutActions";
import useActions from "../../../hooks/useActions";
import { useUserRoleCan } from "../../../hooks/useUserRole";

const getHandleStatusChange = ({ closeModalDialog, openModalDialog }) => ({
  target: { value },
}) => {
  if (value !== "In Closing") {
    return;
  }

  openModalDialog(
    "Project Status - In Closing",
    "GeneralConfirmation",
    {
      title: "Please specify brand and flag before moving project into closing",
      sendButtonText: "Continue",
      onSubmit: closeModalDialog,
      hideCancelButton: true,
    },
    false,
    true
  );
};

const GeneralInformationSection = props => {
  const brandRef = useRef();
  const brandId = _get(props.values, "brandId");
  const flagId = _get(props.values, "flagId");
  const propertyId = _get(props.values, "propertyId");

  useEffect(() => {
    brandRef.current = brandId;
  }, [brandId]);

  const previousBrandId = brandRef.current;

  if (previousBrandId && previousBrandId != brandId) {
    props.setFieldValue("flagId", null);
    props.setFieldValue("programId", null);
  }

  const actions = useActions({ openModalDialog, closeModalDialog });

  const properties = useV2DatacomponentResources(
    inputs.propertyId.dataComponentId,
    [],
    RequestTypes.LIST
  );

  const canCloseProjects = useUserRoleCan("close-projects");

  const selectedProperty = properties.find(
    property => property.id === propertyId
  );

  const fields = useMemo(
    () =>
      createGeneralInfoFields({
        brandId,
        flagId,
        clientId: props.clientId,
        selectedProperty,
        isCancelled: props.values.isCancelled,
        cancelledBy: props.values.cancelledBy,
        setFieldValue: props.setFieldValue,
        handleStatusChange: getHandleStatusChange(actions),
        canCloseProjects,
      }),
    [
      actions,
      brandId,
      canCloseProjects,
      flagId,
      props.clientId,
      props.setFieldValue,
      props.values.cancelledBy,
      props.values.isCancelled,
      selectedProperty,
    ]
  );

  return <FormPageSection {...props} fields={fields} headerText="General" />;
};
GeneralInformationSection.propTypes = formSectionPropTypes;

export default GeneralInformationSection;
