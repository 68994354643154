import React from "react";
import styled from "styled-components";

import PrimaryActionButton from "../../../../forms/ActionButtons/PrimaryActionButton";
import { useFormikContext } from "formik";

export const Button = styled(PrimaryActionButton)``;

export const AddQueryReportParamButton = () => {
  const {
    values: { queryReportParams },
    setFieldValue,
  } = useFormikContext();

  const handleClick = () => {
    queryReportParams.push({
      name: null,
      type: null,
    });
    setFieldValue("queryReportParams", queryReportParams);
  };

  return <Button onClick={handleClick}>Add Query Param</Button>;
};
