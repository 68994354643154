import React, { useEffect, memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _omit from "lodash/omit";
import _uniq from "lodash/uniq";
import _get from "lodash/get";
import NoteCreatePage from "./NoteCreatePage";
import {
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  setReload,
  fetchData,
} from "../../../../actions/dataComponentActions";
import { dataComponentId } from "../NoteList/NoteListContainer";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import Project from "../../../../models/Project";
import Tag from "../../../../models/Tag";
import { noteCreateDetailClose } from "../../../../actions/notesActions";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader";

const getProjectFilters = context => {
  if (context.projectId || context.clientId)
    return {
      rootFilters: {
        $where: {
          id: context.projectId,
          "property.entity.clientId": context.clientId,
        },
      },
    };
  return {};
};

export const fetchNoteDependencies = (
  initDataComponent,
  performRetrieveListRequest,
  context
) => () => {
  initDataComponent("select-projects", Project, [], "projects");
  initDataComponent("select-tags", Tag, [], "tags");

  performRetrieveListRequest("select-projects", {
    ...getProjectFilters(context),
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
  });
  performRetrieveListRequest("select-tags");
};

const getRelatedIds = (assignTo = [], regExp) => {
  return assignTo
    .filter(({ name }) => regExp.test(name))
    .map(({ rawId: id }) => ({ id }));
};

export const handleError = showSnackNotificationAction => ({ data }) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

export const handleSuccess = (
  showSnackNotificationAction,
  noteCreateCancel,
  setReload,
  fetchData
) => () => {
  noteCreateCancel();
  fetchData(dataComponentId);
  setReload(dataComponentId, true);
  showSnackNotificationAction("Note has been completed successfully");
};

const validateCreateStatus = (
  processCreateRequestStatus,
  noteCreateDetailClose,
  setReload,
  showSnackNotificationAction,
  dataComponent,
  prevDataComponent,
  fetchData
) => () => {
  return processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(
      showSnackNotificationAction,
      noteCreateDetailClose,
      setReload,
      fetchData
    ),
    onError: handleError(showSnackNotificationAction),
  });
};

export const transformMentions = ({ detail, mentions = [] }) => {
  const uniqueMentions = _uniq(mentions);
  const filteredMentions = [];
  const parsedDetail = uniqueMentions.reduce((detail, mention) => {
    const replaced = detail.replace(
      new RegExp(mention.name, "g"),
      `[~${mention.id}]`
    );
    if (replaced !== detail) filteredMentions.push(mention.id);
    return replaced;
  }, detail);
  return [parsedDetail, JSON.stringify(filteredMentions)];
};

export const generateNoteData = noteData => {
  const shipments = getRelatedIds(noteData.assignTo, /SHIP/);
  const purchaseOrders = getRelatedIds(noteData.assignTo, /PO/);
  const specs = getRelatedIds(noteData.assignTo, /SPEC/);
  const invoices = getRelatedIds(noteData.assignTo, /INV/);
  const omittedColumnsByCategory =
    noteData.noteCategory === "Tracking"
      ? ["assignTo", "tags", "trackingNumber"]
      : ["assignTo", "tags"];

  const [detail, mentions] = transformMentions(noteData);

  return {
    ..._omit(
      noteData,
      [...omittedColumnsByCategory],
      "project",
      "users",
      "author"
    ),
    purchaseOrders,
    shipments,
    specs,
    invoices,
    detail,
    mentions,
    tags: _get(noteData, "tags", []).map(({ id, name }) => ({ id, name })),
  };
};

export const NoteCreateContainer = ({
  setReload,
  initDataComponent,
  fetchData,
  performRetrieveListRequest,
  noteCreateDetailClose,
  performCreateRequest,
  dataComponent,
  showSnackNotificationAction,
  userId,
  context,
  filters,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    fetchNoteDependencies(
      initDataComponent,
      performRetrieveListRequest,
      context
    ),
    [initDataComponent, context]
  );

  useEffect(
    validateCreateStatus(
      processCreateRequestStatus,
      noteCreateDetailClose,
      setReload,
      showSnackNotificationAction,
      dataComponent,
      prevDataComponent,
      fetchData
    ),
    [
      dataComponent,
      noteCreateDetailClose,
      setReload,
      showSnackNotificationAction,
    ]
  );

  const createNote = note => {
    performCreateRequest(dataComponentId, generateNoteData(note));
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <NoteCreatePage
        userId={userId}
        projectId={context.projectId}
        category={filters.category}
        createNote={createNote}
        onCancel={noteCreateDetailClose}
        assignTo={context.assignTo}
      />
    </LoaderContext.Provider>
  );
};

NoteCreateContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  noteCreateDetailClose: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  dataComponent: PropTypes.shape({}).isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  context: propTypes.notesContext,
  filters: propTypes.notesFilters,
  assignTo: PropTypes.array,
};

NoteCreateContainer.defaultProps = {
  loading: false,
};

export const mapStateToProps = ({ auth: { userId }, ...state }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const projectsDataComponent = getDataComponent("select-projects", state);

  return {
    userId,
    dataComponent,
    loading: _get(projectsDataComponent, "requestState.list.loading"),
    context: state.notes.context,
    filters: state.notes.filters,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  fetchData,
  performRetrieveListRequest,
  noteCreateDetailClose,
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(NoteCreateContainer));
