import _get from "lodash/get";

import {
  performCreateRequest,
  performUpdateRequest,
  fetchDataRequest,
} from "./dataComponentActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { uploadFile } from "./filesActions";
import { dataComponentId } from "./specDetailActions";

const dataComponentIdCreate = "Submittals";

function uploadFiles(submittal, projectId, specId, getSrcFile) {
  return async dispatch => {
    const { isUpdated, file, ...fileWrapper } = submittal.file;
    if (!isUpdated) return getSrcFile ? submittal.file : undefined;

    const key = `projects/${projectId}/specs/${specId}/submittals`;
    const filename = `${Date.now()}-${file.name}`;
    await dispatch(uploadFile({ key, filename }, file));
    return {
      ...fileWrapper,
      s3Key: `${key}/${filename}`,
      filename: file.name,
    };
  };
}

export function saveSubmittal({ id, ...submittal }, projectId, specId) {
  return async dispatch => {
    const operation = id ? REQUEST_TYPES.UPDATE : REQUEST_TYPES.CREATE;
    dispatch(fetchDataRequest(dataComponentIdCreate, operation));
    const file = await dispatch(uploadFiles(submittal, projectId, specId));

    if (id) {
      return dispatch(
        performUpdateRequest(dataComponentIdCreate, id, {
          ...submittal,
          file,
        })
      );
    }
    return dispatch(
      performCreateRequest(dataComponentIdCreate, {
        ...submittal,
        file,
      })
    );
  };
}

export function saveSpecSubmittal(
  submittal,
  { id: specRequirementId },
  spec,
  onUpdateSpec,
  specRequirementIndex,
  submittalIndex
) {
  return async dispatch => {
    const specRequirement = spec.specRequirements.find(
      ({ id }) => id == specRequirementId
    );

    const { id: specId, projectId } = spec;
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    const file = await dispatch(
      uploadFiles(submittal, projectId, specId, true)
    );

    const submittalToSave = {
      ...submittal,
      file: { ...file, metadata: { size: submittal.file.size } },
    };

    const submittalFiles = _get(specRequirement, "submittalFiles", []);
    let updatedSpecRequirement;

    if (typeof submittalIndex !== "number") {
      updatedSpecRequirement = {
        ...specRequirement,
        files: submittalFiles.length + 1,
        submittalFiles: [...submittalFiles, submittalToSave],
      };
    } else {
      updatedSpecRequirement = {
        ...specRequirement,
        submittalFiles: submittalFiles.map((submittal, index) => {
          return index === submittalIndex ? submittalToSave : submittal;
        }),
      };
    }

    spec.specRequirements[specRequirementIndex] = updatedSpecRequirement;
    onUpdateSpec({
      specRequirements: spec.specRequirements.map(
        ({ spec, ...specRequirement }) => specRequirement
      ),
    });

    return updatedSpecRequirement.submittalFiles.at(-1);
  };
}
