import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import userRoleFields, { permissionFields } from "./userRoleFields";
import FormGrid from "../../../forms/FormGrid";
import ActionButtons from "../../../forms/ActionButtons";
import FormikForm from "../../../forms/FormikForm";
import propTypes from "../../../../constants/propTypes";
import {
  PermissionContainer,
  PermissionError,
  PermissionHeader,
  PermissionSection,
  SelectedCounter,
} from "./components";

const getTotalSelectedPermissions = ({ permissions = {} }) => {
  return Object.values(permissions).filter(permission => permission).length;
};

const UserRoleForm = ({
  onSubmit,
  initialValues,
  permissionsByGroup,
  dataComponentId,
}) => {
  const handleCustomSubmit = useCallback(
    async (values, formikProps, handleSubmit) => {
      if (!values.permissions || !values.permissions.find(value => value)) {
        formikProps.setStatus("submitted");
        const formErrors = await formikProps.validateForm(values);
        formikProps.setErrors({
          permissions: "Please select at least one of the permissions",
          ...formErrors,
        });
      } else {
        handleSubmit();
      }
    },
    []
  );

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("UserRole")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={userRoleFields}
            values={values}
            errors={errors}
            {...formikProps}
          />

          <PermissionContainer>
            <PermissionHeader>
              Select Permissions
              <SelectedCounter>
                {getTotalSelectedPermissions(values)} Selected
              </SelectedCounter>
            </PermissionHeader>
            {errors.permissions && (
              <PermissionError>{errors.permissions}</PermissionError>
            )}
            <PermissionSection>
              <FormGrid
                fields={permissionFields(permissionsByGroup)}
                values={values}
                errors={errors}
                {...formikProps}
              />
            </PermissionSection>
          </PermissionContainer>

          <ActionButtons
            onSend={() => handleCustomSubmit(values, formikProps, handleSubmit)}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

UserRoleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.glCode,
  dataComponentId: PropTypes.string.isRequired,
};

export default UserRoleForm;
