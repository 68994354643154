import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { startCase } from "lodash";

import { STATUS, StatusIndicator } from "./StatusIndicator";
import { StatusSpec } from "./ServiceStatus";

export const Heroku = () => {
  const { success, loading, data: { json = [] } = {} } = useSelector(
    state => state.status.herokuStatus
  );

  return (
    <Fragment>
      <StatusSpec>
        <StatusIndicator
          loading={loading}
          status={success ? STATUS.SUCCESS : STATUS.ERROR}
          service="Heroku"
        />
      </StatusSpec>
      {json.map(({ type, state }, key) => (
        <StatusSpec key={key}>
          <StatusIndicator
            status={state === "up" ? STATUS.SUCCESS : STATUS.ERROR}
            service={`Heroku - ${startCase(type)}`}
          />
        </StatusSpec>
      ))}
    </Fragment>
  );
};
