import { inputs } from "../../../../inputs/inputConfigs";

export const headerFields = projectId => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.customNumber,
            name: inputs.customNumber.name,
            label: "Number",
            required: true,
          },
          grid: { xs: 3 },
        },
        {
          input: {
            ...inputs.specCategory,
            dataComponentId: "select-comSpecCategories",
            APIOptions: {
              ...inputs.specCategory.APIOptions,
              rootFilters: {
                $where: {
                  isCom: true,
                },
              },
            },
          },
          grid: { xs: 9 },
        },
        {
          input: {
            ...inputs.specDescription,
            required: true,
            rows: 1,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.area,
            name: inputs.area.name,
            APIOptions: {
              ...inputs.area.APIOptions,
              omitDestroyDataComponent: true,
              rootFilters: {
                $where: { projectId },
              },
            },
            required: true,
          },
          grid: { xs: 3 },
        },
        {
          input: {
            ...inputs.specDetailPreview,
            name: `specDetails[4].file`,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export const comDetailsFields = () => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.specDetailColor,
            name: `specDetails[0].description`,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.specDetailPattern,
            name: `specDetails[1].description`,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.specDetailType,
            name: `specDetails[5].description`,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.specDetailCertificate,
            name: `specDetails[2].description`,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.specDetailFlameCare,
            name: `specDetails[3].description`,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

export const bottomFields = () => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.vendorAutocomplete,
            label: "Vendor",
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.glCodes,
            dataComponentId: "select-glCodes",
            APIOptions: undefined,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.statusComment,
            name: "forecastComment",
            label: "Project Forecast Comment",
            rows: 1,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

export const detailsForm = (
  handleBaseQuantityChange,
  handleOverageChange,
  handleAtticStockChange,
  selectedCurrency,
  projectId
) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.unitOfMeasure,
            name: inputs.unitOfMeasure.name,
            APIOptions: {
              ...inputs.unitOfMeasure.APIOptions,
              omitDestroyDataComponent: true,
            },
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.projectCurrency,
            APIOptions: {
              ...inputs.projectCurrency.APIOptions,
              omitDestroyDataComponent: true,
              rootFilters: {
                $where: { projectId },
              },
            },
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.priceCents,
            name: "priceCents",
            inputProps: {
              maskProps: { allowNegative: true },
              currency: selectedCurrency,
            },
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.baseQuantity,
            handleChange: handleBaseQuantityChange,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.overagePercent,
            handleChange: handleOverageChange,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.atticStock,
            handleChange: handleAtticStockChange,
          },
          grid: { xs: 4 },
        },
        {
          input: inputs.overageValue,
          grid: { xs: 4 },
        },
        {
          input: inputs.totalQuantity,
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.notes,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});
