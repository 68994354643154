import React, { Fragment, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { BWGrid, GridTable } from "../../../../ui/BWGrid";
import propTypes from "../../../../../constants/propTypes";
import Date from "../../../../ui/BWGrid/columns/Date";
import DeleteSubmittalButton from "./DeleteSubmittalButton";
import ActionRow from "./ActionRow";
import {
  GridWrapper,
  CellComponent,
  HeaderCellComponent,
  Check,
  VerticalAlignWrapper,
  Edit,
} from "./components";
import LinesEllipsis from "../../../../ui/LinesEllipsis";
import styled from "styled-components";

const columns = [
  "file.filename",
  "createdAt",
  "receivedDescription",
  "approvedDescription",
  "approved",
  "actions",
];
export const Anchor = styled(Text)`
  color: #57abff;
  text-decoration: none;
`;
export const renderFilename = row => {
  const file = _get(row, "file");
  const filename = _get(row, "file.filename");
  const url = _get(file, "s3SignedUrl");
  return (
    <VerticalAlignWrapper>
      <Anchor as="a" href={url} target="_blank">
        <LinesEllipsis width="100px">{filename}</LinesEllipsis>
      </Anchor>
    </VerticalAlignWrapper>
  );
};

export const getColumnOptions = (
  specRequirement,
  { onApproveSubmittal, onDeleteSubmittal, onDisplayUploadFormClick, disabled }
) => {
  const renderApprovedColumn = row => {
    const isApproved = row.id === specRequirement.submittalApprovedId;
    const approvedId = isApproved ? null : row.id;

    const onClick = disabled
      ? null
      : () => onApproveSubmittal(specRequirement.id, approvedId);

    return (
      <VerticalAlignWrapper>
        <Tooltip title="Approve">
          <Check
            disabled={disabled}
            isApproved={isApproved}
            onClick={onClick}
          />
        </Tooltip>
      </VerticalAlignWrapper>
    );
  };

  const renderActionsColumn = row => {
    const { __expanded__, __isClicked__, ...submittal } = row;
    if (disabled) {
      return null;
    }
    return (
      <Fragment>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onDisplayUploadFormClick(specRequirement, submittal)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <DeleteSubmittalButton
          submittalFile={submittal}
          onSubmit={onDeleteSubmittal}
        />
      </Fragment>
    );
  };

  return {
    "file.filename": { title: "Filename", render: renderFilename },
    createdAt: {
      title: "Date Uploaded",
      render: ({ createdAt }) =>
        createdAt && <Date date={createdAt} format="YYYY-MM-DD" />,
    },
    receivedDescription: { title: "Received", fill: true },
    approvedDescription: { title: "Approved", fill: true },
    approved: {
      title: " ",
      render: renderApprovedColumn,
    },
    actions: {
      title: " ",
      render: renderActionsColumn,
    },
  };
};

const Table = ({
  onApproveSubmittal,
  onDeleteSubmittal,
  onDeleteRequirement,
  onDisplayUploadFormClick,
  row: specRequirement,
  disabled,
}) => {
  const submittalFiles = _get(specRequirement, "submittalFiles", []);
  const columnOptions = useMemo(
    () =>
      getColumnOptions(specRequirement, {
        onApproveSubmittal,
        onDeleteSubmittal,
        onDisplayUploadFormClick,
        disabled,
      }),
    [
      specRequirement,
      onApproveSubmittal,
      onDeleteSubmittal,
      onDisplayUploadFormClick,
      disabled,
    ]
  );

  const handleDisplayUploadFormClick = useCallback(
    () => onDisplayUploadFormClick(specRequirement),
    [onDisplayUploadFormClick, specRequirement]
  );

  return (
    <Fragment>
      <GridWrapper>
        <BWGrid
          rows={submittalFiles}
          gridConfig={{ pageSize: 0, totalRows: submittalFiles.length }}
          tableComponents={{ CellComponent, HeaderCellComponent }}
        >
          <GridTable columns={columns} columnOptions={columnOptions} />
        </BWGrid>
      </GridWrapper>
      <ActionRow
        onDeleteRequirement={onDeleteRequirement}
        onDisplayUploadFormClick={handleDisplayUploadFormClick}
        specRequirement={specRequirement}
      />
    </Fragment>
  );
};

Table.propTypes = {
  row: PropTypes.shape({
    submittalFiles: PropTypes.arrayOf(propTypes.submittalFile),
  }),
  onApproveSubmittal: PropTypes.func.isRequired,
  onDeleteSubmittal: PropTypes.func.isRequired,
  onDeleteRequirement: PropTypes.func.isRequired,
  onDisplayUploadFormClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Table;
