import React from "react";
import styled from "styled-components";
import _isNil from "lodash/isNil";

import { StyledBWHeadCell } from "../../../BWGrid/components";
import Subheader from "../../../Typography/Subheader";

import Loader from "../../../Loader";

const StyledHeadCell = styled(StyledBWHeadCell)`
  border-bottom: ${({ column: { borderStyle } }) =>
    borderStyle
      ? `1px ${borderStyle}  rgba(224, 224, 224, 1)`
      : `1px "solid" rgba(224, 224, 224, 1)`};
  border-left: none !important;
  border-right: ${({ column: { level, displayBorderRight } }) =>
    displayBorderRight || !_isNil(level)
      ? "1px solid rgba(224, 224, 224, 1)"
      : "none"};
  padding-left: ${({ column: { firstColumn } }) => firstColumn && "24px"};
  padding-right: ${({ column: { displayBorderRight } }) =>
    displayBorderRight && "24px"};
  background-color: ${({ tableColumn: { column } }) =>
    column.backGroundColor ? column.backGroundColor : "#FFFFFF"};
  ${({ column: { level } }) =>
    !_isNil(level) &&
    `
    border-bottom: none;
    padding-top: 24px;
    padding-bottom: 0;
    padding-left: 24px;
  `}
`;

const HeadCell = ({ children, ...props }) => {
  if (!_isNil(props.column.level)) {
    return (
      <StyledHeadCell {...props}>
        <Subheader>
          <Loader width="150px">{children}</Loader>
        </Subheader>
      </StyledHeadCell>
    );
  }
  return (
    <StyledHeadCell scope="col" {...props}>
      <Loader width="150px">{children}</Loader>
    </StyledHeadCell>
  );
};

export default HeadCell;
