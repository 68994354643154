import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MUIIconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { DataTypeProvider } from "@devexpress/dx-react-grid";

export const IconButton = styled(MUIIconButton)`
  padding: 2px;
  margin-left: 4px;
  color: #0e1524;
`;

function ActionsColumn({ row, actionIcons }) {
  const handleClickBuilder = onClick => evt => {
    evt.stopPropagation();
    evt.preventDefault();
    onClick && onClick(evt, row);
  };

  return actionIcons.map(({ icon, onClick, tooltip }) => (
    <Tooltip key={tooltip} title={tooltip}>
      <IconButton onClick={handleClickBuilder(onClick)}>{icon}</IconButton>
    </Tooltip>
  ));
}

ActionsColumn.propTypes = {
  row: PropTypes.shape({}),
  actionIcons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({}),
      onClick: PropTypes.func,
      tooltip: PropTypes.string,
    })
  ),
};

const ActionIconsFormatter = ({ value: actionIcons, row }) => {
  return <ActionsColumn row={row} actionIcons={actionIcons} />;
};

ActionIconsFormatter.propTypes = {
  row: PropTypes.shape({}),
  value: PropTypes.arrayOf(
    PropTypes.shape({ icon: PropTypes.shape({}), onClick: PropTypes.func })
  ),
};

const ActionIconsProvider = props => {
  return (
    <DataTypeProvider
      formatterComponent={ActionIconsFormatter}
      {...props}
      for={["actionIcons"]}
    />
  );
};

export default ActionIconsProvider;
