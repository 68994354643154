import { inputs } from "../../../../inputs/inputConfigs";
import { UsaStates } from "usa-states";

const states = new UsaStates({ includeTerritories: true }).states;

const contactPath = "contact";

const fields = ({ vendorContactStatusInput }) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            name: contactPath + "." + inputs.firstName.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            name: contactPath + "." + inputs.lastName.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            name: contactPath + "." + inputs.phone.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Fax",
            name: `${contactPath}.fax`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Cell Number",
            name: `${contactPath}.cellphone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Work Number",
            name: `${contactPath}.workPhone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            name: contactPath + "." + inputs.contactRole.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            name: contactPath + "." + inputs.email.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: vendorContactStatusInput,
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

function remapOption(option, replacers) {
  const nOption = {
    ...option,
  };
  Object.keys(replacers).forEach(term => {
    nOption.name = nOption.name.replace(`[${term}]`, replacers[term]);
  });
  return nOption;
}

export function getVendorStatusOptions(vendor) {
  const vendorState = states.find(
    state => state.abbreviation === vendor.location.stateCode
  );
  const replacers = { State: vendorState ? vendorState.name : "Local" };
  return inputs.vendorContactStatus.options.map(option =>
    remapOption(option, replacers)
  );
}

export default vendor => {
  const vendorContactStatusInput = {
    ...inputs.vendorContactStatus,
    options: getVendorStatusOptions(vendor),
  };

  return fields({ vendorContactStatusInput });
};
