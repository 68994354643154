import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  initAreaDetails,
  areaDetailDataComponentId,
} from "../../../actions/areasActions";
import { getResourcesV2, getResourceLinks } from "../../../utils/bluechipUtils";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { isPendingRequest } from "../../../utils/dataComponentUtils";
import AreaDetail from "./AreaDetail";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";

export const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  const dataComponent = getDataComponent(areaDetailDataComponentId, state);
  const area = getResourcesV2(dataComponent, state, REQUEST_TYPES.FIND, {});

  const links = getResourceLinks(area);

  return {
    projectId: params.projectId,
    clientId: params.clientId,
    areaId: params.areaId,
    area,
    links,
    loading: isPendingRequest(dataComponent, REQUEST_TYPES.FIND),
  };
};

export const mapDispatchToProps = dispatch => {
  return { actions: bindActionCreators({ initAreaDetails }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaDetail);
