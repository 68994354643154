const getNextCellInColumn = (
  cells,
  currentColumn,
  initialColumn,
  initialRow
) => {
  let j = currentColumn === initialColumn ? initialRow + 1 : 0;
  for (; j < cells[currentColumn].length; j++) {
    if (cells[currentColumn][j]) {
      return { rowId: j, columnId: currentColumn };
    }
  }
};

export const getNextCell = (cells, rowId, columnId) => {
  for (let i = columnId; i < cells.length; i++) {
    if (!cells[i]) {
      continue;
    }
    const nextCell = getNextCellInColumn(cells, i, columnId, rowId);
    if (nextCell) return nextCell;
  }
  return {};
};

const getPrevCellInColumn = (
  cells,
  currentColumn,
  initialColumn,
  initialRow
) => {
  let j =
    currentColumn === initialColumn
      ? initialRow - 1
      : cells[currentColumn].length - 1;
  for (; j >= 0; j--) {
    if (cells[currentColumn][j]) {
      return { rowId: j, columnId: currentColumn };
    }
  }
};

export const getPrevCell = (cells, rowId, columnId) => {
  for (let i = columnId; i >= 0; i--) {
    if (!cells[i]) {
      continue;
    }
    const prevCell = getPrevCellInColumn(cells, i, columnId, rowId);
    if (prevCell) return prevCell;
  }
  return {};
};

export default { getNextCell, getPrevCell };
