import React from "react";
import PropTypes from "prop-types";

import { FormPageSection } from "../../../layout/FormPageSection";
import createRemarksFields from "./createRemarksFields";

const Section = ({ setCurrentCell, ...formGridProps }) => {
  return (
    <FormPageSection
      headerText="Remarks"
      fields={createRemarksFields(formGridProps.checkNumber, setCurrentCell)}
      {...formGridProps}
    />
  );
};

Section.propTypes = {
  setCurrentCell: PropTypes.func.isRequired,
};

export default Section;
