import React from "react";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";

import propTypes from "../../../../constants/propTypes";
import { EditorComponent } from "./dataTypeProviders/TextTypeProvider";

const BWGridFilterRow = ({ displayFilters, ...props }) => {
  return displayFilters ? (
    <TableFilterRow {...props} editorComponent={EditorComponent} />
  ) : null;
};
BWGridFilterRow.propTypes = propTypes.filteringComponent;

export default BWGridFilterRow;
