import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateSpecForm from "./CreateSpecForm";
import BWModels from "benjaminwest-models";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { fetchData } from "../../../../actions/dataComponentActions";

import {
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import Area from "../../../../models/Area";
import Spec from "../../../../models/Spec";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";

import { handleRequestError } from "../../../../utils/formValidationUtils";
import {
  getCreatedId,
  processCreateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";

export const dataComponentId = "CreateSpec";

export class CreateSpecContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func.isRequired,
    updateDataComponent: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    propertyId: PropTypes.string,
    areaId: PropTypes.string,
    clientId: PropTypes.string,
    initDataComponent: PropTypes.func,
    performCreateRequest: PropTypes.func,
    setReload: PropTypes.func,
    performRetrieveListRequest: PropTypes.func,
    dataComponent: PropTypes.object,
    createdItem: PropTypes.object,
    showSnackNotificationAction: PropTypes.func,
    projectCurrencyId: PropTypes.string,
  };

  state = {
    isSaveAndCreateAnother: false,
    initialValues: {
      projectId: this.props.projectId || -1,
      description: "",
      customNumber: undefined,
      areaId: this.props.areaId,
      specCategoryId: undefined,
      totalQuantity: "--",
      overageValue: "--",
      projectCurrencyId: this.props.projectCurrencyId,
    },
    ref: null,
  };

  get specSchema() {
    return BWModels.loadSchema("Spec");
  }

  componentDidMount() {
    const {
      initDataComponent,
      performRetrieveListRequest,
      projectId,
    } = this.props;

    initDataComponent(dataComponentId, Spec, [], "specs");

    initDataComponent("select-areas", Area, [], "areas");
    performRetrieveListRequest("select-areas", {
      rootFilters: {
        $where: {
          projectId,
        },
      },
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
    });
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleCreateSuccess = () => {
    const {
      createdItem,
      closeModalDialog,
      showSnackNotificationAction,
      setReload,
    } = this.props;
    const { formikActions } = this.state;

    formikActions.setSubmitting(false);
    formikActions.resetForm();
    formikActions.setValues({
      ...this.state.initialValues,
    });

    setReload("SpecsGrid", true);

    if (this.state.isSaveAndCreateAnother) {
      this.state.ref?.current?.scrollTo(0, 0);
    } else {
      closeModalDialog();
    }

    showSnackNotificationAction(
      `Spec ${createdItem.customNumber} ${createdItem.description} has been created`
    );

    this.setState({
      isSaveAndCreateAnother: false,
    });
  };

  createSpec = (spec, formikActions) => {
    const updatedSpecs = { ...spec };

    if (updatedSpecs.priceCents) {
      updatedSpecs.priceCents = cleanCurrencyValue(spec.priceCents * 100);
    }

    const { projectId, performCreateRequest } = this.props;
    performCreateRequest(dataComponentId, { ...updatedSpecs, projectId });

    this.setState({ formikActions });
  };
  setSaveAndCreateAnother = saveAndCreateAnother => {
    this.setState({
      isSaveAndCreateAnother: saveAndCreateAnother,
    });
  };

  setRef = ref => this.setState({ ref });

  render() {
    const { closeModalDialog, projectId } = this.props;
    return (
      <CreateSpecForm
        projectId={projectId}
        specSchema={this.specSchema}
        createSpec={this.createSpec}
        closeModalDialog={closeModalDialog}
        initialValues={this.state.initialValues}
        setSaveAndCreateAnother={this.setSaveAndCreateAnother}
        setRef={this.setRef}
        listeners={[dataComponentId]}
      />
    );
  }
}

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const createdId = getCreatedId(dataComponent);
  return {
    dataComponent,
    createdId,
    createdItem: getBluechipResourceById(dataComponent, state, createdId),
  };
};

export const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  updateDataComponent: fetchData,
  showSnackNotificationAction,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSpecContainer);
