import {
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  fetchDataRequest,
  performUpdateRequest,
} from "./dataComponentActions";
import Client from "../models/Client";
import BidGroupVendor from "../models/BidGroupVendor";
import { uploadFile } from "./filesActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { bidGroupVendorSpecDataComponentId } from "./bidGroupActions";
import BidGroupVendorSpec from "../models/BidGroupVendorSpec";
import BidGroupVendorSpecQuote from "../models/BidGroupVendorSpecQuote";
import { bidGroupVendorSpecQuoteComponentId } from "../components/pages/BidGroupDetail/Quotes/QuotesPage";

export const clientFilterDataComponentId = "VendorPortalClientFilter";
export const vendorQuoteDataComponentId = "VendorPortalBidGroupVendorQuote";
export const vendorQuoteSpecDataComponentId =
  "VendorPortalBidGroupVendorSpecQuote";

export const fetchClientFilters = vendor => {
  return dispatch => {
    if (!vendor) return;
    dispatch(
      initDataComponent(
        clientFilterDataComponentId,
        Client,
        [],
        "clients",
        false,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(clientFilterDataComponentId, {
        pageSize: -1,
        sort: [{ columnName: "name", direction: "desc" }],
        rootFilters: {
          $where: {
            "entities.properties.projects.bidGroups.bidGroupVendors.vendorId":
              vendor.id,
          },
        },
      })
    );
  };
};

export const fetchBidGroupVendor = bidGroupId => {
  return dispatch => {
    dispatch(
      initDataComponent(
        bidGroupVendorSpecQuoteComponentId,
        BidGroupVendorSpecQuote,
        ["quoteItems"],
        "bid-group-vendor-spec-quotes",
        false,
        "v2"
      )
    );

    dispatch(
      initDataComponent(
        bidGroupVendorSpecDataComponentId,
        BidGroupVendorSpec,
        ["quotes.[quoteItems]", "spec.[specDetails.[specDetailComs.spec]]"],
        "bid-group-vendor-specs",
        false,
        "v2"
      )
    );

    dispatch(
      initDataComponent(
        vendorQuoteDataComponentId,
        BidGroupVendor,
        [
          `bidGroup.[
            rfq,
            project.[
              property.[location, entity.client],
              projectManager,
              designer,
              generalContractor,
              otherConsultant,
              projectLead,
              projectMemberUsers.user,
              projectMemberContacts.contact,
              projectCurrencies.currency,
            ]
          ]`,
          "bidGroupVendorSpecs.[quotes.quoteItems, spec.[specDetails.specDetailComs.spec.unitOfMeasure,unitOfMeasure]]",
          "quoteFiles",
          "vendor",
          "quoteProjectCurrency.currency",
        ],
        "bid-group-vendors",
        false,
        "v2"
      )
    );
    dispatch(performFindRequest(vendorQuoteDataComponentId, bidGroupId));
  };
};

export function uploadFiles(bidGroupId, bidGroupVendorId, bidGroupVendor) {
  return async dispatch => {
    const field = "quoteFiles";
    const files = (bidGroupVendor[field] || [])
      .filter(file => file.isUpdated)
      .map(({ isUpdated, ...file }) => file);
    const key = `rfqs/${bidGroupId}/vendor_quote/${bidGroupVendorId}`;
    return await Promise.all(
      files.map(async ({ file }) => {
        const filename = file.name;
        await dispatch(uploadFile({ key, filename }, file));
        return {
          ...file,
          field,
          s3Key: `${key}/${filename}`,
          filename,
        };
      })
    );
  };
}

export function updateBidGroupVendor(
  bidGroupId,
  bidGroupVendorId,
  bidGroupVendor
) {
  return async dispatch => {
    dispatch(
      fetchDataRequest(vendorQuoteDataComponentId, REQUEST_TYPES.UPDATE)
    );
    const files = await dispatch(
      uploadFiles(bidGroupId, bidGroupVendorId, bidGroupVendor)
    );
    const bgVendor = bidGroupVendor.quoteFiles
      ? files.reduce(
          (bgVendor, { field, ...file }) => {
            bgVendor[field].push(file);
            return bgVendor;
          },
          {
            ...bidGroupVendor,
            quoteFiles: bidGroupVendor.quoteFiles.filter(
              ({ isUpdated }) => !isUpdated
            ),
          }
        )
      : bidGroupVendor;
    delete bgVendor.bidGroup;
    delete bgVendor.quoteStatus;
    bgVendor.selectedContacts = JSON.stringify(bgVendor.selectedContacts);
    dispatch(
      performUpdateRequest(
        vendorQuoteDataComponentId,
        bidGroupVendorId,
        bgVendor
      )
    );
  };
}
