import React from "react";
import propTypes from "../../../../../constants/propTypes";

import { Wrapper, RowItemWrapper, Title, Value } from "./components";
import ColumnItem from "./ColumnItem";

import _get from "lodash/get";

const ProjectStatusSection = props => {
  const { projectSummary } = props;

  const values = [
    [
      {
        title: "Specs Quoted",
        value: _get(projectSummary, "projectStatus.specsQuoted.value", 0),
        total: _get(projectSummary, "projectStatus.specsQuoted.total", 0),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.specsQuoted.needsFollowUp",
          false
        ),
      },
    ],
    [
      {
        title: "Specs on PO",
        value: _get(
          projectSummary,
          "projectStatus.specsOnPurchaseOrder.value",
          0
        ),
        total: _get(
          projectSummary,
          "projectStatus.specsOnPurchaseOrder.total",
          0
        ),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.specsOnPurchaseOrder.needsFollowUp",
          false
        ),
      },
    ],
    [
      {
        title: "POs Issued",
        value: _get(projectSummary, "projectStatus.posIssues.value", 0),
        total: _get(projectSummary, "projectStatus.posIssues.total", 0),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.posIssues.needsFollowUp",
          false
        ),
      },
    ],
    [
      {
        title: "POs Shipped",
        value: _get(projectSummary, "projectStatus.posShipped.value", 0),
        total: _get(projectSummary, "projectStatus.posShipped.total", 0),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.posShipped.needsFollowUp",
          false
        ),
      },
    ],
    [
      {
        title: "POs Delivered",
        value: _get(projectSummary, "projectStatus.posDelivered.value", 0),
        total: _get(projectSummary, "projectStatus.posDelivered.total", 0),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.posDelivered.needsFollowUp",
          false
        ),
      },
    ],
    [
      {
        title: "Invoices Paid",
        value: _get(projectSummary, "projectStatus.invoicesPaid.value", 0),
        total: _get(projectSummary, "projectStatus.invoicesPaid.total", 0),
        needsFollowUp: _get(
          projectSummary,
          "projectStatus.invoicesPaid.needsFollowUp",
          false
        ),
      },
    ],
  ];

  return (
    <Wrapper>
      <RowItemWrapper>
        <Title>Total Specs</Title>
        <Value>{projectSummary.projectStatus.totalSpecs}</Value>
      </RowItemWrapper>
      <RowItemWrapper>
        <Title>Billable Change Orders</Title>
        <Value>
          {projectSummary.projectStatus.totalBilledChangeOrdersToDate}
        </Value>
      </RowItemWrapper>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} id={key} />
      ))}
    </Wrapper>
  );
};

ProjectStatusSection.defaultProps = {
  projectSummary: {},
};

ProjectStatusSection.propTypes = {
  projectSummary: propTypes.projectSummary.isRequired,
};

export default ProjectStatusSection;
