import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import VendorInfo from "./VendorInfo";
import RemitAddressInfo from "./RemitAddressInfo";
import propTypes from "../../../../constants/propTypes";

export const Wrapper = styled.div`
  width: 35%;
  padding-left: 24px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin-left: 0px;
    width: 100%;
    min-width: 100%;
    padding-left: 0px;
  }
`;

const AdditionalInformationSection = ({
  invoice,
  maxHeight,
  updateInvoice,
}) => {
  return (
    <Wrapper maxHeight={maxHeight}>
      <VendorInfo invoice={invoice} />
      <RemitAddressInfo invoice={invoice} updateInvoice={updateInvoice} />
    </Wrapper>
  );
};
AdditionalInformationSection.propTypes = {
  invoice: propTypes.invoice,
  maxHeight: PropTypes.number,
  updateInvoice: PropTypes.func.isRequired,
};

export default AdditionalInformationSection;
