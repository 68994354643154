import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class GridTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnOptions: PropTypes.object,
    rowMenu: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.element,
          text: PropTypes.string,
          onClick: PropTypes.func,
          separator: PropTypes.bool,
        })
      ),
    ]),
    onClick: PropTypes.func,
  };

  // Move to BWGridAPI where these props are now received
  static defaultProps = {
    showSelectionColumn: false,
    showSelectAll: false,
  };

  static isGridTable = true;

  render() {
    return null;
  }
}
