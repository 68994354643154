import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";
import Paper from "../../mui/core/Paper";
import Call from "@material-ui/icons/Call";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import Settings from "@material-ui/icons/Settings";
import { PageHeader, TitleArea, AttributeList } from "../../layout/PageHeader";
import { Content } from "../../layout/Content";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import * as tableConfigs from "../../../components/ui/gridTableConfigs/detailPageProjects";
import propTypes from "../../../constants/propTypes";
import { ActionIcon } from "../ProjectDetail/components";
import { Button } from "../../layout/PageHeader/TitleArea";
import Tooltip from "@material-ui/core/Tooltip";

export const UserInfoCreation = styled.div`
  margin-top: 16px;
`;

const UserInfoContact = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const UserInfoContactEmail = styled.div`
  padding-right: 21px;
`;

export const LinkEmail = styled.a`
  color: white;
  text-decoration: none;
`;

const UserInfoContactIcon = styled(EmailOutlined)`
  margin-right: 8px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: rgba(255, 255, 255, 0.7);
`;

const StyledCall = styled(Call)`
  margin-right: 8px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: rgba(255, 255, 255, 0.7);
`;

export const UserDetailPageHeader = ({ user, onUserSettingsClick }) => {
  const {
    name,
    email,
    phoneNumber,
    createdAt,
    userRole = {},
    team = {},
    office = {},
  } = user || {};
  const options = { year: "2-digit", month: "numeric", day: "numeric" };
  const dateCreatedAt = new Date(Date.parse(createdAt));
  const attributes = [
    { key: "Office", value: _get(office, "name") },
    { key: "Role", value: _get(userRole, "name") },
    { key: "Team", value: _get(team, "name") },
  ];

  return (
    <PageHeader>
      <TitleArea title={name}>
        <Tooltip title="User Settings">
          <Button onClick={onUserSettingsClick}>
            <ActionIcon Icon={Settings} />
          </Button>
        </Tooltip>
      </TitleArea>
      <div>
        <AttributeList attributes={attributes} />
      </div>
      <UserInfoCreation>
        Created on {dateCreatedAt.toLocaleDateString("en-US", options)}
      </UserInfoCreation>
      <UserInfoContact>
        {email && (
          <Fragment>
            <UserInfoContactIcon />
            <UserInfoContactEmail>
              <LinkEmail href={`mailto:${email}`}>{email}</LinkEmail>
            </UserInfoContactEmail>
          </Fragment>
        )}
        {phoneNumber && (
          <Fragment>
            <StyledCall />
            <span>{phoneNumber}</span>
          </Fragment>
        )}
      </UserInfoContact>
    </PageHeader>
  );
};

UserDetailPageHeader.propTypes = {
  user: propTypes.user,
  onUserSettingsClick: PropTypes.func.isRequired,
};

export const columns = [
  "number",
  "name",
  "projectRole",
  "type",
  "status",
  "property.name",
  "startDate",
  "endDate",
];

export const getColumnOptions = () => ({
  ...tableConfigs.columnOptions,
  projectRole: {
    title: "Project Role",
    render: ({ projectRole }) => projectRole?.join(", "),
  },
});

export default class UserDetailPage extends Component {
  static propTypes = {
    gridDataComponent: PropTypes.object.isRequired,
    user: PropTypes.object,
    userRole: PropTypes.object,
    team: PropTypes.object,
    office: PropTypes.object,
    onUserSettingsClick: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      gridDataComponent,
      user,
      onUserSettingsClick,
      onRowClick,
    } = this.props;
    if (!user) return null;

    const columnOptions = getColumnOptions(user.id);

    return (
      <Fragment>
        <UserDetailPageHeader
          user={user}
          onUserSettingsClick={onUserSettingsClick}
        />
        <Content>
          <Paper>
            <BWGridAPI
              {...this.props}
              dataComponent={gridDataComponent}
              rootFilters={{
                $where: {
                  $or: [
                    { "projectLead.id": user.id },
                    { "projectMemberUsers.userId": user.id },
                  ],
                },
              }}
            >
              <GridHeader
                headerText={`${gridDataComponent.totalRows} Projects`}
              />
              <GridTable
                columns={columns}
                columnOptions={columnOptions}
                onClick={onRowClick}
              />
            </BWGridAPI>
          </Paper>
        </Content>
      </Fragment>
    );
  }
}
