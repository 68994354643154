import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Remove from "@material-ui/icons/Remove";
import pluralize from "pluralize";

import Counters from "./Counters";
import RoomTypeTable, { tablePropTypes, IconWrapper } from "./RoomTypeTable";
import ActionButtons from "../../../forms/ActionButtons";
import { inputs } from "../../../inputs/inputConfigs";
import InputWrapper from "../../../inputs/InputWrapper";
import { calcTotalNeeded, calcRecommendedCaseQtyOrder } from "./calcFunctions";
import { Field } from "formik";

import {
  hasError,
  getErrorMessage,
} from "../../../../utils/formValidationUtils";
import { withRevisionReference } from "../../../../withPORevision";

const unitsPerCaseInput = inputs.unitsPerCase;

const MainSectionWrapper = styled.div`
  min-height: 540px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
`;

const PlaceholderValue = ({ value }) => {
  return (
    value || (
      <IconWrapper>
        <Remove />
      </IconWrapper>
    )
  );
};
PlaceholderValue.propTypes = {
  value: PropTypes.number,
};

export class PARBasedQuantityCalculationForm extends Component {
  get totalNeeded() {
    return calcTotalNeeded(this.props.items);
  }

  get unitsPerCaseInput() {
    const input = { ...unitsPerCaseInput };
    return (
      <Field name={input.name}>
        {({ field, form }) => (
          <InputWrapper
            {...input}
            {...field}
            hasError={hasError(input.name, form)}
            errorMessage={getErrorMessage(input.name, form)}
          />
        )}
      </Field>
    );
  }

  get recommendedCaseQtyOrder() {
    const { unitsPerCase } = this.props.values;
    const { items } = this.props;
    const cQtyOrder =
      unitsPerCase && unitsPerCase > 0
        ? calcRecommendedCaseQtyOrder(unitsPerCase, items)
        : 0;
    return <PlaceholderValue value={cQtyOrder} />;
  }

  get itemsReq() {
    const { unitsPerCase } = this.props.values;
    const { items } = this.props;
    const itemsReq =
      unitsPerCase && unitsPerCase > 0
        ? calcRecommendedCaseQtyOrder(unitsPerCase, items) * unitsPerCase
        : 0;
    return <PlaceholderValue value={itemsReq} />;
  }

  handleOnAddClick = () => {
    this.props.onAddClick();
    this.forceUpdate();
  };

  handleOnRemoveClick = index => {
    this.props.onRemoveClick(index);
    this.forceUpdate();
  };

  render() {
    const { items, onSave, onUpdateRow, formikProps } = this.props;
    const gridHeaderText = pluralize("Room", items.length, true);
    return (
      <Fragment>
        <MainSectionWrapper>
          <RoomTypeTable
            gridHeaderText={gridHeaderText}
            addClick={this.handleOnAddClick}
            onRemoveClick={this.handleOnRemoveClick}
            onUpdateRow={onUpdateRow}
            gridConfig={{ totalRows: items.length, pageSize: 0 }}
            rows={items}
            formikProps={formikProps}
          />
          <Counters
            totalNeeded={this.totalNeeded}
            unitsPerCaseInput={this.unitsPerCaseInput}
            recommendedCaseQtyOrder={this.recommendedCaseQtyOrder}
            itemsReq={this.itemsReq}
          />
        </MainSectionWrapper>
        <ActionButtons
          onSend={onSave}
          isModal={true}
          listeners={[this.props.dataComponentId]}
        />
      </Fragment>
    );
  }
}

PARBasedQuantityCalculationForm.propTypes = {
  ...tablePropTypes.propTypes,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      roomCount: PropTypes.number,
      perRoom: PropTypes.number,
      par: PropTypes.number,
    })
  ).isRequired,
  dataComponentId: PropTypes.string.isRequired,
};

export default withRevisionReference(PARBasedQuantityCalculationForm);
