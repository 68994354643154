import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withLabeledTextOption } from "../../../../withPORevision";
import { setReload } from "../../../../actions/dataComponentActions";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import {
  processUpdateRequestStatus,
  getActiveRequest,
} from "../../../../utils/dataComponentUtils";
import propTypes from "../../../../constants/propTypes";

import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import { openQuickEditModalAction } from "../../../../actions/specsActions";
import { openAddSpecOptionsModalAction } from "../../../../actions/purchaseOrderSpecsActions";
import { LoaderContext } from "../../../ui/Loader";
import Grid from "./Grid";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { updateSpecs } from "../../../../actions/quotingActions";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import {
  openModalDialog,
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import pluralize from "pluralize";
import {
  getShipmentsDataComponentId,
  getSpecShipmentsDataComponentId,
} from "../../ProjectDetail/Shipments/SpecShipments/SpecShipmentsContainer";
import { dataComponentId as invoicesDataComponentId } from "../Invoices/POInvoicesContainer";
import { fetchPurchaseOrder } from "../../../../actions/purchaseOrdersActions";

export const dataComponentId = "PurchaseOrderDetail-PurchaseOrderList";

export const getNextSpecId = (rowIndex, specId) => {
  if (!rowIndex) return null;

  const currentIndex = rowIndex.indexOf(specId);
  if (currentIndex === -1) {
    return rowIndex[0];
  }
  return rowIndex[currentIndex + 1];
};
const PODataComponentId = "PODetail";

const processRequestResponse = (
  dataComponentId,
  prevDataComponent,
  dataComponent,
  setReload
) => () => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(dataComponentId, true);
    },
  });
};

export const handleUpdateRequestError = showSnackNotificationAction => ({
  data,
}) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

export const Specs = ({
  purchaseOrder,
  dataComponent,
  pushWithReturnUrl,
  clientId,
  purchaseOrderId,
  PODataComponent,
  openQuickEditModalAction,
  openAddSpecOptionsModalAction,
  setReload,
  loading,
  updateSpecs,
  showSnackNotificationAction,
  isPOChangeDisabled,
  openModalDialog,
  closeModalDialog,
  fetchPurchaseOrder,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(PODataComponent);
  const dataComponentUnFlattened = useDatacomponent(dataComponentId);

  useEffect(() => {
    setPrevDataComponent(PODataComponent);
  }, [PODataComponent]);

  useEffect(
    processRequestResponse(
      dataComponentId,
      prevDataComponent,
      PODataComponent,
      setReload
    ),
    [PODataComponent, dataComponent, prevDataComponent, setReload]
  );

  useUpdateProcessRequests(dataComponentUnFlattened, {
    onSuccess: () => {
      setReload(dataComponentId, true);
      setReload(getSpecShipmentsDataComponentId(purchaseOrder), true);
      setReload(getShipmentsDataComponentId(purchaseOrder), true);
      setReload(invoicesDataComponentId, true);
      fetchPurchaseOrder(purchaseOrder.id, projectId);
    },
    onError: handleUpdateRequestError(showSnackNotificationAction),
  });

  const { projectId, number } = purchaseOrder;
  const handleGoToSpecDetails = useCallback(
    specId => {
      pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/specs/${specId}`,
        `PO #${number}`,
        { navigateBetweenPO: true }
      );
    },
    [pushWithReturnUrl, clientId, number, projectId]
  );

  const handleOpenQuickEditModal = useCallback(
    spec =>
      openQuickEditModalAction(dataComponentId, spec, clientId, getNextSpecId),
    [openQuickEditModalAction, clientId]
  );
  const handleOpenAddSpecModal = useCallback(
    () => openAddSpecOptionsModalAction(purchaseOrder),
    [openAddSpecOptionsModalAction, purchaseOrder]
  );

  const handleRemoveSpec = useCallback(
    ({ id, customNumber, totalQuantityShipped }) => {
      openModalDialog(
        `Remove Spec - ${customNumber}`,
        "GeneralConfirmation",
        {
          title: totalQuantityShipped
            ? `Spec# ${customNumber} cannot be removed from PO# ${number}. Shipped quantity is ${totalQuantityShipped}.`
            : `Are you sure you want to remove ${customNumber} from PO# ${number}?`,
          sendButtonText: "Confirm",
          cancelButtonText: "Back",
          onSubmit: () => {
            updateSpecs(dataComponent, null, [id], purchaseOrder, projectId);
            closeModalDialog();
          },
          disabled: !!totalQuantityShipped,
        },
        false,
        true
      );
    },
    [
      closeModalDialog,
      dataComponent,
      number,
      openModalDialog,
      projectId,
      purchaseOrder,
      updateSpecs,
    ]
  );

  const handleOpenEditAction = useCallback(
    selectedIds => () => {
      openModalDialog(
        `Modify Multiple Specs`,
        "EditMultipleSpecsModal",
        {
          selectedSpecIds: selectedIds,
        },
        true,
        false,
        {
          subtitle: pluralize("Spec", selectedIds.length, true),
        }
      );
    },
    [openModalDialog]
  );

  const selectedProjectCurrency = purchaseOrder.projectCurrency;

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Grid
        dataComponent={dataComponent}
        purchaseOrderId={purchaseOrderId}
        selectedProjectCurrency={selectedProjectCurrency}
        onGoToSpecDetails={handleGoToSpecDetails}
        onOpenQuickEditModal={handleOpenQuickEditModal}
        onOpenAddSpecModal={handleOpenAddSpecModal}
        onRemoveSpec={handleRemoveSpec}
        isPOChangeDisabled={isPOChangeDisabled}
        onOpenEditAction={handleOpenEditAction}
      />
    </LoaderContext.Provider>
  );
};

Specs.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  PODataComponent: propTypes.purchaseOrder.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  clientId: PropTypes.string,
  purchaseOrderId: PropTypes.string,
  pushWithReturnUrl: PropTypes.func.isRequired,
  openAddSpecOptionsModalAction: PropTypes.func.isRequired,
  openQuickEditModalAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  updateSpecs: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func,
  isPOChangeDisabled: PropTypes.bool.isRequired,
  fetchPurchaseOrder: PropTypes.func,
  openModalDialog: PropTypes.func,
  closeModalDialog: PropTypes.func,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const PODataComponent = getDataComponent(PODataComponentId, state);
  return {
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    PODataComponent,
    loading: !!(
      getActiveRequest(dataComponent) ||
      getActiveRequest(PODataComponent, [REQUEST_TYPES.FIND])
    ),
  };
};

const mapDispatchToProps = {
  pushWithReturnUrl,
  setReload,
  openQuickEditModalAction,
  openAddSpecOptionsModalAction,
  updateSpecs,
  showSnackNotificationAction,
  fetchPurchaseOrder,
  openModalDialog,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLabeledTextOption(Specs));
