import React, { useMemo } from "react";
import { IconButton as MuiIconButton } from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";

import { FormGrid } from "../../../forms";
import { inputs } from "../../../inputs/inputConfigs";
import { InputLabel } from "../../../mui/core";
import {
  ActionButtonWrapper,
  Button,
} from "../../ProjectCreate/ContractSection/createContractFields";
import styled from "styled-components";

const IconButton = styled(MuiIconButton)`
  padding: 0;
  &:hover {
    background-color: unset;
  }
`;

export const getFields = ({ form: { values }, name, push, remove }) => {
  const handleAdd = () => {
    push(null);
  };

  const handleRemove = index => () => remove(index);

  const brands = values[name];
  const items = brands.reduce(
    (array, brand, i) => [
      ...array,
      {
        input: {
          ...inputs.baseName,
          name: `brands[${i}]`,
          required: true,
        },
        grid: { xs: 4 },
      },
      {
        element: (
          <IconButton onClick={handleRemove(i)} aria-label="Delete">
            <Delete style={{ color: "#ff1744" }} />
          </IconButton>
        ),
        grid: { xs: 1 },
      },
      {
        grid: { xs: 7 },
      },
    ],
    []
  );
  return {
    groups: [
      {
        items: [
          {
            element: <InputLabel>Brands</InputLabel>,
            grid: { xs: 9 },
          },
          {
            element: (
              <ActionButtonWrapper>
                <Button
                  icon={<AddCircle />}
                  text={"Add Brand"}
                  onClick={handleAdd}
                />
              </ActionButtonWrapper>
            ),
            grid: { xs: 3 },
          },
          ...items,
        ],
      },
    ],
  };
};

export const BrandArray = ({ formikArrayHelpers }) => {
  const fields = useMemo(() => getFields(formikArrayHelpers), [
    formikArrayHelpers,
  ]);

  return <FormGrid fields={fields} {...formikArrayHelpers.form} />;
};
