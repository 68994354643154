import React, { createContext, useMemo, useCallback, useState } from "react";

export const DisplayVendorContext = createContext();

const withDisplayVendorControl = WrappedComponent => {
  const Component = props => {
    const [bgVendors, setBGVendors] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const handleToggleStatus = useCallback(
      index => {
        const copy = [...statuses];
        copy[index] = !statuses[index];
        setStatuses(copy);
      },
      [statuses]
    );

    const handleShowAll = useCallback(() => {
      setStatuses(new Array(bgVendors.length + 1).fill(true));
    }, [bgVendors]);

    const handleSetBGVendors = useCallback(bgVendors => {
      setBGVendors(bgVendors);
      setStatuses(new Array(bgVendors.length + 1).fill(true));
    }, []);

    const value = useMemo(() => {
      return {
        setBGVendors: handleSetBGVendors,
        bgVendors,
        statuses,
        isBudgetSectionVisible: statuses[0],
        availableBGVendors: bgVendors.filter((_, index) => statuses[index + 1]),
        onToggleStatus: handleToggleStatus,
        onShowAll: handleShowAll,
      };
    }, [
      bgVendors,
      statuses,
      handleToggleStatus,
      handleSetBGVendors,
      handleShowAll,
    ]);

    return (
      <DisplayVendorContext.Provider value={value}>
        <WrappedComponent {...props} />
      </DisplayVendorContext.Provider>
    );
  };
  Component.propTypes = {};

  return Component;
};

export default withDisplayVendorControl;
