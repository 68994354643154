import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import GLCodesSection from "./GLCodesSection";

const GLCodesSectionContainer = ({ values, clientId }) => {
  return <GLCodesSection values={values} clientId={clientId} />;
};

GLCodesSectionContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
};

export default connect(null, { initDataComponent, performRetrieveListRequest })(
  GLCodesSectionContainer
);
