import React from "react";
import PropTypes from "prop-types";

import DeleteConfirmation from "../../ui/DeleteConfirmation";

const DeleteAddressBookContactContainer = props => {
  const {
    title,
    addressBookContactId,
    formikArrayHelpers,
    index,
    onDeleteContact,
  } = props;

  const handleDelete = () => {
    onDeleteContact(addressBookContactId, index, formikArrayHelpers);
  };

  return <DeleteConfirmation title={title} onDelete={handleDelete} />;
};

DeleteAddressBookContactContainer.propTypes = {
  title: PropTypes.string.isRequired,
  addressBookContactId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.string,
  onDeleteContact: PropTypes.func.isRequired,
};

export default DeleteAddressBookContactContainer;
