import React, { useCallback } from "react";
import { connect } from "react-redux";
import { connect as formikConnect } from "formik";
import PropTypes from "prop-types";

import ContactItem from "./ContactItem";
import { ContactList } from "./components";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { performDeleteRequest } from "../../../../../actions/dataComponentActions";
import { poContactdataComponentId } from "../../../../../actions/purchaseOrdersContactActions";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import propTypes from "../../../../../constants/propTypes";

export const commonPropTypes = {
  vendorContacts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  isDeleting: PropTypes.bool,
  vendor: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
  }),
  type: PropTypes.string,
  onClickItem: PropTypes.func,
};

export const List = props => {
  const {
    onClickItem,
    vendorContacts,
    isDeleting,
    deleteContact,
    purchaseOrderContacts,
    projectContacts,
    ...adProps
  } = props;

  const handleRemove = useCallback(
    projectContact => () =>
      !isDeleting &&
      deleteContact(
        purchaseOrderContacts.find(
          poContact => poContact.projectSiteContactId == projectContact.id
        ).id
      ),
    [deleteContact, isDeleting, purchaseOrderContacts]
  );

  return (
    <ContactList>
      {projectContacts.map(projectContact => (
        <ContactItem
          key={projectContact.id}
          contactId={projectContact.contactId}
          vendorContact={projectContact}
          button={false}
          onRemove={handleRemove(projectContact)}
          disabled={isDeleting}
          {...adProps}
        />
      ))}
      {vendorContacts.map(({ id, vendorContact, consultingContact }) => (
        <ContactItem
          key={id}
          contactId={
            vendorContact
              ? vendorContact.contactId
              : consultingContact.contactId
          }
          onRemove={() => !isDeleting && deleteContact(id)}
          disabled={isDeleting}
          vendorContact={vendorContact || consultingContact}
          secondaryClick={onClickItem}
          type={"vendor"}
          {...adProps}
        />
      ))}
    </ContactList>
  );
};
List.propTypes = {
  ...commonPropTypes,
  deleteContact: PropTypes.func.isRequired,
  projectContacts: PropTypes.arrayOf(propTypes.projectContact),
};

const mapStateToProps = (state, { vendorContacts, projectContacts }) => {
  const contactsListDataComponent = getDataComponentFlattenedRequestState(
    poContactdataComponentId,
    state,
    REQUEST_TYPES.DELETE
  );

  return {
    vendorContacts: vendorContacts,
    projectContacts: projectContacts,
    isDeleting: contactsListDataComponent.loading,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    deleteContact: poContactId => {
      dispatch(performDeleteRequest(poContactdataComponentId, poContactId));
    },
  };
};

export default formikConnect(
  connect(mapStateToProps, mapDispatchToProps)(List)
);
