import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import {
  FundingWrapper,
  FundingType,
  FundingIcon,
  ContentWrapper,
} from "./components";
import { openModalDialog } from "../../../../../actions/layoutActions";

const CreateFundingType = ({ openModalDialog, projectId, project }) => {
  const isCashflow = project.fundingType === "Cashflow";
  const preferenceText = "Preferred By Client";
  const handleCreateFunding = useCallback(() => {
    openModalDialog(
      null,
      "CreateFunding",
      {
        project,
        projectId,
      },
      false,
      true
    );
  }, [openModalDialog, project, projectId]);
  const handleCreateBatchFunding = useCallback(() => {
    openModalDialog(
      ["Select Invoices for Funding Request", "Batch Funding"],
      "CreateBatchFunding",
      {
        project,
        projectId,
      },
      false,
      true,
      {
        layoutType: "wide",
        width: "1200px",
        components: { Content: ContentWrapper },
      }
    );
  }, [openModalDialog, project, projectId]);
  return (
    <div>
      <FundingWrapper>
        <FundingType onClick={handleCreateBatchFunding}>
          <FundingIcon />
          <div>Batch Funding</div>
        </FundingType>
        <div>{!isCashflow && preferenceText}</div>
      </FundingWrapper>

      <FundingWrapper>
        <FundingType onClick={handleCreateFunding}>
          <FundingIcon />
          Cashflow Funding
        </FundingType>
        <div>{isCashflow && preferenceText}</div>
      </FundingWrapper>
    </div>
  );
};

CreateFundingType.propTypes = {
  openModalDialog: PropTypes.func,
  projectId: PropTypes.string,
  project: propTypes.project,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(null, mapDispatchToProps)(CreateFundingType);
