import React from "react";
import styled from "styled-components";

import InternalBWTableRow from "../../gridInternalComponents/BWTableRow";

export const StyledRow = styled(InternalBWTableRow)`
  > td,
  th {
    border-bottom: none;
  }
`;

const BWTableRow = props => {
  return <StyledRow {...props} />;
};

export default BWTableRow;
