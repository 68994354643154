import { connect } from "react-redux";

import Grid from "./Grid";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";

import { pushWithReturnUrl } from "../../../../actions/navigationActions";

const projectsDataComponentId = "ProjectsGrid";

export const mapStateToProps = state => {
  const { userId } = state.auth;
  return {
    dataComponent: getDataComponentFlattenedRequestState(
      projectsDataComponentId,
      state
    ),
    currentUserId: userId,
  };
};
export const mapDispatchToProps = {
  pushWithReturnUrl,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(Grid),
  ["project-index-client-projects"]
);
