import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import SerifTitle from "../../ui/Typography/SerifTitle";
import { IconButton } from "@material-ui/core";

const TitleAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;
const TitleOuterWrapper = styled.div`
  display: flex;
  align-items: left;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
const TitleInnerWrapper = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 800px) {
    flex-direction: column;
    align-items: left;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (min-width: 1100px) {
    flex-direction: row;
  }
  @media (max-width: 800px) {
    align-items: center;
  }
`;

const BaseTitle = styled(SerifTitle)`
  display: contents;
  font-size: 24px;
  line-height: 32px;
  height: 32px;
  color: rgb(255, 255, 255);
  font-weight: bold;
`;

const Id = styled(BaseTitle)`
  padding-right: 16px;
`;

export const Title = styled(BaseTitle)`
  font-weight: normal;
`;

export const TitleContent = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 1024px) {
    justify-content: flex-end;
  }
  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
  @media (max-width: 600px) {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  align-items: center;
`;
export const Button = styled(IconButton)`
  width: 40px;
  height: 40px;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  text-align: center;
`;

const TitleArea = ({ id, title, dates, info, children }) => (
  <TitleAreaContainer>
    <TitleOuterWrapper>
      <TitleInnerWrapper>
        <TitleWrapper>
          {id && (
            <ContentWrapper>
              <Id>{id}&nbsp;&nbsp;&nbsp;</Id>
            </ContentWrapper>
          )}
          {title && (
            <ContentWrapper>
              <Title>{title}</Title>
            </ContentWrapper>
          )}
          {info && <ContentWrapper>{info}</ContentWrapper>}
        </TitleWrapper>
        {dates}
      </TitleInnerWrapper>
      <TitleContent>{children}</TitleContent>
    </TitleOuterWrapper>
  </TitleAreaContainer>
);

TitleArea.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  dates: PropTypes.element,
  info: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TitleArea;
