import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader";
import AddRequirementRow from "./AddRequirementRow";
import UploadSubmittal from "./UploadSubmittal";
import Grid from "./Grid";
import {
  processDeleteRequestStatus,
  processUpdateRequestStatus,
  processCreateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { PanelWrapper, GridWrapper } from "./components";
import { mapStateToProps, mapDispatchToProps } from "./connect";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

export const dataComponentId = "SpecRequirements";
export const specsDataComponentId = "ReviewSubmittals";
export const submittalsDataComponentId = "Submittals";

export const processDeleteRequest = (
  preDataComponent,
  dataComponent,
  { setReload, showSnackNotificationAction }
) => {
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(specsDataComponentId, true);
      showSnackNotificationAction("The item has been deleted successfully");
    },
  });
};

export const processSaveRequest = (
  preDataComponent,
  dataComponent,
  { onHideUploadSubmittalForm, setReload, showSnackNotificationAction }
) => {
  const onSuccess = () => {
    onHideUploadSubmittalForm();
    setReload(specsDataComponentId, true);
    showSnackNotificationAction("The file has been saved successfully");
  };
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess,
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess,
  });
};

export const processRequests = (
  { preSubmittalsDC, submittalsDatacomponent },
  { preDataComponent, dataComponent },
  callbacks
) => {
  processDeleteRequest(preSubmittalsDC, submittalsDatacomponent, callbacks);
  processDeleteRequest(preDataComponent, dataComponent, callbacks);
  processSaveRequest(preSubmittalsDC, submittalsDatacomponent, callbacks);
};

export const resetSetAdding = (
  requirements,
  prevRequirements,
  setAdding
) => () => {
  if (requirements.length !== prevRequirements.length) {
    return setAdding(false);
  }
  const ids = requirements.map(requirement => requirement.id);
  const hasChanges = prevRequirements.some(
    requirement => !ids.includes(requirement.id)
  );
  if (hasChanges) {
    setAdding(false);
  }
};

// eslint-disable-next-line max-lines-per-function
const RequirementsGrid = ({
  specId,
  projectId,
  requirements,
  dataComponent,
  submittalsDatacomponent,
  loading,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  showSnackNotificationAction,
  setReload,
  onTitleChange,
  saveSubmittal,
  spec,
  setTitle,
}) => {
  const [isAdding, setAdding] = useState(false);
  const [prevRequirements, setPrevRequirements] = useState(requirements);
  const [shouldDisplayUploadForm, setDisplayUploadForm] = useState(false);
  const [currentSubmittal, setCurrentSubmittal] = useState({});
  const [preSubmittalsDC, setPrevSubmittalsDC] = useState(null);
  const [preDataComponent, setDataComponent] = useState(null);

  useEffect(() => {
    setPrevRequirements(requirements);
  }, [requirements]);

  useEffect(resetSetAdding(requirements, prevRequirements, setAdding), [
    requirements,
    prevRequirements,
  ]);

  useEffect(() => {
    setDisplayUploadForm(false);
    setCurrentSubmittal({});
  }, [specId]);

  useEffect(() => setPrevSubmittalsDC(submittalsDatacomponent), [
    submittalsDatacomponent,
  ]);

  useEffect(() => setDataComponent(dataComponent), [dataComponent]);

  const handleHideUploadSubmittalForm = useCallback(() => {
    onTitleChange();
    setDisplayUploadForm(false);
    setCurrentSubmittal({});
  }, [onTitleChange]);

  useEffect(
    () =>
      processRequests(
        { preSubmittalsDC, submittalsDatacomponent },
        { preDataComponent, dataComponent },
        {
          onHideUploadSubmittalForm: handleHideUploadSubmittalForm,
          setReload,
          showSnackNotificationAction,
        }
      ),
    [
      dataComponent,
      preDataComponent,
      preSubmittalsDC,
      submittalsDatacomponent,
      setReload,
      handleHideUploadSubmittalForm,
      showSnackNotificationAction,
    ]
  );

  const hadleAddRequirementClick = useCallback(() => {
    setAdding(true);
  }, []);

  const handleRemoveRequirementRowClick = useCallback(() => {
    setAdding(false);
  }, []);

  const handleCreateRequirement = useCallback(
    ({ target: { value } }) => {
      performCreateRequest(dataComponentId, { specId, requirementId: value });
    },
    [performCreateRequest, specId]
  );

  const handleCreateSubmittal = useCallback(
    submittal => saveSubmittal(submittal, projectId, specId),
    [saveSubmittal, projectId, specId]
  );

  const handleApproveSubmittal = useCallback(
    (id, submittalApprovedId) => {
      performUpdateRequest(dataComponentId, id, { submittalApprovedId });
    },
    [performUpdateRequest]
  );

  const handleDeleteSubmittal = useCallback(
    id => {
      performDeleteRequest(submittalsDataComponentId, id);
    },
    [performDeleteRequest]
  );

  const handleDeleteRequirement = useCallback(
    id => {
      performDeleteRequest(dataComponentId, id);
    },
    [performDeleteRequest]
  );

  const handleDisplayUploadSubmittalForm = useCallback(
    (specRequirement, submittal) => {
      onTitleChange(specRequirement);
      setDisplayUploadForm(true);
      setCurrentSubmittal({ specRequirement, submittal });
    },
    [onTitleChange]
  );

  const handleUpdateSpec = useCallback(
    spec => {
      performUpdateRequest(specsDataComponentId, specId, spec);
    },
    [performUpdateRequest, specId]
  );

  const isProjectClosed = useIsProjectClosed();
  const disabled = isAdding || isProjectClosed;

  return (
    <LoaderContext.Provider value={{ loading }}>
      {shouldDisplayUploadForm ? (
        <UploadSubmittal
          initialValues={currentSubmittal.submittal}
          specRequirement={currentSubmittal.specRequirement}
          onSubmit={handleCreateSubmittal}
          onCancel={handleHideUploadSubmittalForm}
          dataComponentId={submittalsDataComponentId}
        />
      ) : (
        <PanelWrapper>
          <GridWrapper>
            <Grid
              spec={spec}
              requirements={requirements}
              onDeleteRequirement={handleDeleteRequirement}
              onDisplayUploadSubmittalForm={handleDisplayUploadSubmittalForm}
              onApproveSubmittal={handleApproveSubmittal}
              onDeleteSubmittal={handleDeleteSubmittal}
              onAddRequirementClick={hadleAddRequirementClick}
              onUpdateSpec={handleUpdateSpec}
              setTitle={setTitle}
              setReload={setReload}
              disabled={disabled}
            />
          </GridWrapper>
          <AddRequirementRow
            isActive={isAdding}
            onRemove={handleRemoveRequirementRowClick}
            onCreate={handleCreateRequirement}
          />
        </PanelWrapper>
      )}
    </LoaderContext.Provider>
  );
};

RequirementsGrid.propTypes = {
  specId: PropTypes.string,
  projectId: PropTypes.string,
  purchaseOrderId: PropTypes.string,
  requirements: PropTypes.arrayOf(propTypes.specRequirement),
  dataComponent: propTypes.dataComponent,
  submittalsDatacomponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  performCreateRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  saveSubmittal: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  spec: propTypes.spec,
};

RequirementsGrid.defaultProps = {
  requirements: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(RequirementsGrid);
