import React, {
  useRef,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import RootRef from "@material-ui/core/RootRef";

export const HOVER_CLASS = "hover-active";

const RowReferenceContext = createContext({});

export const useRowReference = () => useContext(RowReferenceContext);

const Row = ({ isDisabledRow, isIncomplete, hasClickHandler, ...props }) => {
  const ref = useRef();

  const rowReference = useMemo(() => {
    return {
      ref,
      toggleRowHover: hoverActive => {
        if (hoverActive) {
          ref.current.classList.add(HOVER_CLASS);
          return;
        }
        ref.current.classList.remove(HOVER_CLASS);
      },
    };
  }, []);

  useEffect(() => {
    rowReference.toggleRowHover(true);
  }, [rowReference]);

  return (
    <RowReferenceContext.Provider value={rowReference}>
      <RootRef rootRef={ref}>
        <Table.Row {...props} />
      </RootRef>
    </RowReferenceContext.Provider>
  );
};

export default Row;
