import React, { useState } from "react";
import _get from "lodash/get";

export const Image = ({
  file,
  description,
  placeholder,
  ImageComponent,
  ...props
}) => {
  const originalSrc = _get(file, "s3SignedUrl", placeholder);
  const minSrc = _get(file, "s3SignedMinUrl", originalSrc);

  const [src, setSrc] = useState(minSrc);

  const onError = () => setSrc(originalSrc);

  /**
   * While updating the image, sometimes we keep the same URL and the image
   * doesn't refresh on the UI. This timestamp className will force it to refresh.
   */
  const className = Date.now();

  return ImageComponent ? (
    <ImageComponent
      {...props}
      className={className}
      src={src}
      originalSrc={originalSrc}
      alt={description}
      onError={onError}
    />
  ) : (
    React.createElement("img", {
      ...props,
      className,
      src,
      alt: description,
      onError,
    })
  );
};
