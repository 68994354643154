import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { duplicateProject } from "../../../../actions/projectsActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { getCreatedId } from "../../../../utils/dataComponentUtils";

import DuplicateProject from "./DuplicateProject";

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        closeModalDialog,
        push,
        duplicateProject,
        showSnackNotificationAction,
      },
      dispatch
    ),
  };
};

export const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    newProjectId: getCreatedId(dataComponent),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateProject);
