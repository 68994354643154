import React from "react";
import { Redirect } from "react-router";

import withAuthentication from "../withAuthentication";
import withPagination from "../withPagination";
import AppLayout from "../components/layout/AppLayout";
import { Content } from "../components/layout/Content";
import propTypes from "../constants/propTypes";

export const appPageBuilder = (
  PageContainer,
  headerTitle,
  isDetailPage = false
) => {
  const WrappedPageContainer = withAuthentication(
    withPagination(PageContainer)
  );
  const PageWithAppLayout = props => (
    <AppLayout headerTitle={headerTitle} isDetailPage={isDetailPage}>
      <WrappedPageContainer {...props} />
    </AppLayout>
  );
  return PageWithAppLayout;
};

export const buildSubPage = ChildPage => {
  const SubPage = props => (
    <Content>
      <ChildPage {...props} />
    </Content>
  );
  return SubPage;
};

/**
 * @deprecated Please use redirectTo.
 * @param {*} PageTo
 * @param {*} url
 * @returns
 */
export const replacePageWith = (PageTo, url) => {
  const ReplacePage = props => {
    props.history.replace(`${props.location.pathname}/${url}`);
    return <PageTo {...props} />;
  };

  return ReplacePage;
};

export const redirectTo = url => {
  const RedirectComponent = props => {
    return <Redirect to={`${props.match.url}/${url}`} />;
  };
  RedirectComponent.propTypes = {
    match: propTypes.matchRouter,
  };
  return RedirectComponent;
};
