import React from "react";
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";

import { ContactList, ListItem } from "../components";
import EmptyState from "../../../../../ui/EmptyState";
import ContactItem, {
  ContactItem as RawContactItem,
  ContactItemWrapper,
} from "../ContactItem";
import propTypes from "../../../../../../constants/propTypes";
import Loader from "../../../../../ui/Loader";

const vendorContactsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    contact: RawContactItem.propTypes.contact,
  })
);

const List = ({
  vendor,
  vendorContacts,
  isCreating,
  onClick,
  displayPlaceholder,
  loading,
}) => (
  <ListItem button={false}>
    <ContactList>
      <Loader loading={loading} height="70px" width="300px" count={10}>
        {vendorContacts.length > 0 &&
          vendorContacts.map(vendorContact => (
            <ContactItem
              key={vendorContact.id}
              vendorContact={vendorContact}
              vendor={vendor}
              disabled={isCreating}
              onClick={() => onClick(vendorContact.id)}
              type={"vendor"}
            />
          ))}
        {vendorContacts.length === 0 && displayPlaceholder && (
          <ContactItemWrapper>
            <ListItemText>
              <EmptyState>No results</EmptyState>
            </ListItemText>
          </ContactItemWrapper>
        )}
      </Loader>
    </ContactList>
  </ListItem>
);

List.propTypes = {
  vendor: propTypes.vendor,
  vendorContacts: vendorContactsPropType,
  onClick: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,
  displayPlaceholder: PropTypes.bool,
  loading: PropTypes.bool,
};

export default List;
