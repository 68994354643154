import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _drop from "lodash/drop";
import _includes from "lodash/includes";
import _orderBy from "lodash/orderBy";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";

export const retrieveList = (
  apiFilters,
  selectedFilters,
  dataComponent,
  {
    getPage,
    getSort,
    setReload,
    setShouldReload,
    performRetrieveListRequest,
    pageSize,
  }
) => {
  if (!dataComponent.apiRoute) return;
  const { dataComponentId } = dataComponent;
  const rootFilters = _get(apiFilters, "rootFilters", {});
  const $where = _get(rootFilters, "$where", {});
  const sort = _get(apiFilters, "sort", getSort(dataComponentId));

  performRetrieveListRequest({
    ...apiFilters,
    rootFilters: {
      ...rootFilters,
      $where: {
        ...$where,
        ...selectedFilters,
      },
    },
    pageNumber: getPage(dataComponentId),
    sort,
    pageSize,
  });
  setReload(false);
  setShouldReload(false);
};

export const debouncedFilteringChangeHandler = _debounce(
  (changes, { setSelectedFilters, onSetPage, setSelection }) => {
    setSelectedFilters(changes);
    onSetPage(0, true);
    setSelection([]);
  },
  1500
);

export const getPage = (context, dataComponent) => () => {
  if (context.isDefault) {
    return dataComponent.pageNumber;
  }
  return context.getPage(dataComponent.dataComponentId);
};

export const getSort = (
  context,
  dataComponent,
  additionalSorting = []
) => () => {
  if (context.isDefault) {
    return dataComponent.sort;
  }

  let sort = context.getSort(dataComponent.dataComponentId);

  if (sort) {
    sort = [...sort, ...additionalSorting];
  }

  return sort;
};

export const checkBackPageEffect = (
  isLoading,
  rows,
  pageNumber,
  handleSetPage
) => () => {
  if (!isLoading && (!rows || rows.length === 0) && pageNumber > 0) {
    handleSetPage(pageNumber - 1);
  }
};

export const checkSorting = (
  context,
  dataComponent,
  prevDefaultSorting,
  defaultSorting,
  handleSetSort
) => () => {
  if (prevDefaultSorting !== defaultSorting) {
    handleSetSort(defaultSorting || getSort(context, dataComponent)());
  }
};

export const getPaginatedItems = (items, page = 0, pageSize = 10) => {
  if (pageSize === 0) return items;
  const offset = page * pageSize;
  return _drop(items, offset).slice(0, pageSize);
};

export const getSortedItems = (items, sorting) => {
  if (_isEmpty(sorting)) return items;
  const { columnName, direction } = sorting[0];
  return _orderBy(items, [columnName], [direction]);
};

export const getFiltedItems = (items, filters = []) => {
  if (_isEmpty(items)) return items;
  return items.filter(
    item =>
      !item.__fixedTop__ &&
      Object.keys(filters).every(columnName => {
        const { operator, value } = filters[columnName];
        const operationFunc = operations[operator];
        const columnValue = _get(item, columnName) || "";
        return operationFunc(columnValue, value || "");
      })
  );
};

export const getRows = (
  rows,
  id,
  { sort, currentPage = 0, pageSize = 10, filters }
) => {
  if (!rows) return { pageRows: [], rows: [] };
  const fixedItems = rows.filter(row => row.__fixedTop__);
  const modifiedItems = getSortedItems(getFiltedItems(rows, filters), sort);
  modifiedItems.splice(currentPage * pageSize, 0, ...fixedItems);
  return {
    pageRows: getPaginatedItems(modifiedItems, currentPage, pageSize),
    rows: modifiedItems,
  };
};

const getValue = possibleValue => {
  if (typeof possibleValue === "object") {
    return _isNil(possibleValue.value) ? possibleValue : possibleValue.value;
  }
  return possibleValue;
};

export const operations = {
  ilike: (actualValue, subString) => {
    return _includes(
      getValue(actualValue)
        .toString()
        .toLowerCase(),
      subString.toString().toLowerCase()
    );
  },
  equal: (actualValue, expectedValue) =>
    getValue(actualValue) === expectedValue,
  in: (actualValue, expectedValue) => expectedValue.indexOf(actualValue) !== -1,
  between: (actualValue, { $lte: end, $gte: start }) => {
    const date = new Date(actualValue);
    return date >= start && date <= end;
  },
};
