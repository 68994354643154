import inputs from "../../../../../components/inputs/inputConfigs/fee";

export default {
  groups: [
    {
      items: [
        {
          input: inputs.feeBillDate,
          grid: { xs: 6 },
        },
        {
          input: inputs.feeInvoiceReferenceNumber,
          grid: { xs: 6 },
        },
      ],
    },
  ],
};
