export const SPEC_CATEGORIES_SELECT = "select-specCategories";
export const UNIT_OF_MEASURE_SELECT = "select-unit-of-measure";
export const VENDOR_CATEGORIES_SELECT = "select-vendorCategories";
export const INVOICE_SELECT = "select-invoice";
export const PURCHASE_ORDERS_SELECT = "select-purchaseOrders";
export const ADDRESS_BOOKS_SELECT = "select-address-books";
export const PROGRAMS_SELECT = "select-programs";
export const BRANDS_SELECT = "select-brands";
export const FLAGS_SELECT = "select-flags";
export const PAYMENT_TERMS_SELECT = "select-payment-terms";
export const TEAMS_SELECT = "select-payment-terms";
export const AREA_TYPES_SELECT = "select-areaTypes";
export const AREAS_SELECT = "select-areas";
export const VENDORS_SELECT = "select-vendors";
export const CONTACTS_SELECT = "select-contacts";
export const CURRENCIES_SELECT = "select-currencies";
export const SCOPES_SELECT = "select-scopes";
