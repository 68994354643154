import { inputs } from "../../../../../../components/inputs/inputConfigs";

export default (shipToSite, vendorSites, shipVendorId, setFieldValue) => {
  const items = [
    {
      input: inputs.shipToSite,
      grid: { xs: 12 },
    },
  ];

  if (shipToSite) {
    items.push({
      input: inputs.poShipAddressId,
      grid: { xs: 12 },
    });
  } else {
    items.push(
      {
        input: {
          ...inputs.vendorId,
          label: "Vendor",
          name: "shipVendorId",
          displayEmpty: false,
          handleChange: () => setFieldValue("shipVendorAddressId", null),
        },
        grid: { xs: 12 },
      },
      {
        input: {
          ...inputs.poShipAddressId,
          name: "shipVendorAddressId",
          dataComponentId: null,
          isDisabled: !shipVendorId,
          options: [{ id: "-1" }, ...vendorSites],
        },
        grid: { xs: 12 },
      }
    );
  }

  return {
    groups: [
      {
        items,
      },
    ],
  };
};
