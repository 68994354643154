import styled from "styled-components";
import { Button } from "@material-ui/core";

export default styled(Button)`
  padding: 16px 24px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;
