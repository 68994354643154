import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

export default function scopedCurrencyReducer(
  state = initialState.scopedCurrency,
  action
) {
  switch (action.type) {
    case actionTypes.SET_SCOPED_CURRENCY: {
      return action.payload || initialState.scopedCurrency;
    }
    case actionTypes.RESET_SCOPED_CURRENCY: {
      return initialState.scopedCurrency;
    }
    default:
      return state;
  }
}
