import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Tabs from "../../../mui/core/Tabs";
import Tab from "../../../mui/core/Tab";
import { ModalStateContext } from "./withModalState";

export const ModalTab = styled(Tab)`
  min-width: 133px;
  width: 133px;
  max-width: 133px;
`;

class ModalTabs extends Component {
  static contextType = ModalStateContext;

  state = {
    tabIndex: 0,
  };

  handleTabChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  componentDidMount() {
    const { addTabActions } = this.context;
    const { onTabChange, tabs } = this.props;

    addTabActions &&
      addTabActions("onTabChange", elementTypeID => {
        const index = tabs.findIndex(
          tab => tab.elementTypeID === elementTypeID
        );
        this.handleTabChange(null, index);
        onTabChange(elementTypeID);
      });
  }

  render() {
    const { tabs, onTabChange } = this.props;
    const { disabledTabs = [] } = this.context;
    if (!tabs || tabs.length === 0) {
      return null;
    }
    const { tabIndex } = this.state;

    return (
      <Fragment>
        <Tabs value={tabIndex} onChange={this.handleTabChange}>
          {tabs.map((tab, i) => (
            <ModalTab
              key={i}
              label={tab.content}
              disabled={disabledTabs.includes(tab.elementTypeID)}
              onClick={() => onTabChange(tab.elementTypeID)}
            />
          ))}
        </Tabs>
      </Fragment>
    );
  }
}

ModalTabs.defaultProps = {
  tabs: [],
};

ModalTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  onTabChange: PropTypes.func,
};

export default ModalTabs;
