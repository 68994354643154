import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

export class DetailAndCycle extends Component {
  static propTypes = {
    children: PropTypes.array,
    isNavbarDrawerOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { children } = this.props;

    return <Wrapper>{children}</Wrapper>;
  }
}

export const mapStateToProps = ({ layout }) => ({
  isNavbarDrawerOpen: layout.isNavbarDrawerOpen,
});

export default withRouter(connect(mapStateToProps)(DetailAndCycle));
