import React, { memo, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { allPurchaseOrdersDataComponentId } from "../../../actions/quotingActions";
import propTypes from "../../../constants/propTypes";
import { performUpdateRequest } from "../../../actions/dataComponentActions";
import { getCurrencyFromSpecs } from "./UnassignedSpecs/unassignedSpecsUtils";
import { getPONumberLabel } from "./PurchaseOrderList/PurchaseOrderListActions";

export const unassignedSecUpdateId = "unassigned-spec-update-id";

export const QuotingActions = ({
  purchaseOrders,
  handleCreatePurchaseOrderClick,
  performUpdateRequest,
  selectedSpecs,
}) => {
  const handleMoveToPO = useCallback(
    (purchaseOrder, selectedSpecs, performUpdateRequest) => () => {
      const revisionIsActive = !!purchaseOrder.revisionStatus;
      performUpdateRequest(
        unassignedSecUpdateId,
        selectedSpecs.map(spec => ({
          id: spec.id,
          purchaseOrderId: purchaseOrder.id,
        })),
        revisionIsActive && {
          update_po_revision: true,
        }
      );
    },
    []
  );

  const selectedCurrency = getCurrencyFromSpecs(selectedSpecs);

  if (selectedSpecs.length > 0 && !selectedCurrency) {
    return <span>Select specs with same currency</span>;
  }

  return (
    <DropdownMenu
      buttonText="Assign To"
      options={[
        {
          content: "Add to New PO",
          onClick: handleCreatePurchaseOrderClick,
          disabled: selectedSpecs.length === 0,
          useSeparator: true,
        },
        {
          content: "Add To",
          isLabel: true,
        },
        ...purchaseOrders.map(
          ({ number, projectCurrencyId, ...purchaseOrder }) => ({
            disabled:
              selectedSpecs.length === 0 ||
              projectCurrencyId != selectedCurrency,
            content: getPONumberLabel({ number }),
            onClick: handleMoveToPO(
              purchaseOrder,
              selectedSpecs,
              performUpdateRequest
            ),
          })
        ),
      ]}
    />
  );
};

QuotingActions.propTypes = {
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  handleCreatePurchaseOrderClick: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  selectedSpecs: PropTypes.array,
};

const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    allPurchaseOrdersDataComponentId,
    state
  );
  return {
    purchaseOrders: getBluechipResources(flattenedDataComponent, state) || [],
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(QuotingActions));
