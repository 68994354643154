import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../../constants/propTypes";
import SpecDetailCom from "../../../../../models/SpecDetailCom";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import EditSpecDetailCOMForm from "./EditSpecDetailCOMForm";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import withPORevision from "../../../../../withPORevision";
import { handleError } from "../../../AddressBook/AddressBookRequestHandlers";

const dataComponentId = "COMLibrary-specDetailCom";

export class EditSpecDetailCOMContainer extends Component {
  state = {};

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, SpecDetailCom, [], "spec-detail-coms");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      showSnackNotificationAction,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => {
        handleRequestError(error, this.state.formikActions);
        handleError(error.data, showSnackNotificationAction);
      },
    });
  }

  handleSubmit = (data, formikActions) => {
    const { performUpdateRequest, specDetailCom, revisionValue } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;
    performUpdateRequest(
      dataComponentId,
      specDetailCom.id,
      { notes: data.notes },
      params
    );
    this.setState({ formikActions });
  };

  render() {
    const { specDetailCom, specDetail } = this.props;
    const ownRef = `specs.${specDetail.specId}.specDetails.${specDetail.id}.specDetailComs.${specDetailCom.id}`;
    return (
      <EditSpecDetailCOMForm
        specDetailCom={specDetailCom}
        onSubmit={this.handleSubmit}
        revisionReference={ownRef}
      />
    );
  }
}

EditSpecDetailCOMContainer.propTypes = {
  specDetailCom: propTypes.specDetailCom.isRequired,
  specDetail: propTypes.specDetail,
  dataComponent: PropTypes.object.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  initDataComponent,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(EditSpecDetailCOMContainer, "SpecDetail", "purchaseOrder"));
