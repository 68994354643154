import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";

const Option = styled.div`
  min-width: 175px;
`;

export const BulkActions = ({ onOpenUpdateTermsModal }) => {
  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: <Option>Sync Client Terms</Option>,
          onClick: onOpenUpdateTermsModal,
        },
      ]}
    />
  );
};

BulkActions.propTypes = {
  onOpenUpdateTermsModal: PropTypes.func.isRequired,
};

export default BulkActions;
