import React from "react";
import PropTypes from "prop-types";

import Terms from "../Settings/Terms";

const TermsWrapper = ({ client }) => {
  return (
    <Terms
      termContext="clientId"
      contextValue={client.id}
      additionalFilters={{ entityId: { $exist: false } }}
      scopeId={client.scopeId}
      disableSyncGlobalTerms
    />
  );
};

TermsWrapper.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default TermsWrapper;
