import {
  initDataComponent,
  performRetrieveListRequest,
  destroyDataComponentResource,
} from "./dataComponentActions";
import {
  clientContactDCId,
  freightDCId,
  usersDCId,
  consultingDCId,
  vendorContactsDCId,
  warehouseDCID,
  warehouseContactsDCId,
} from "../components/inputs/inputConfigs/projects";
import User from "../models/User";
import Vendor from "../models/Vendor";
import ConsultingCompany from "../models/ConsultingCompany";
import ClientContact from "../models/ClientContact";
import VendorContact from "../models/VendorContact";
import * as RequestTypes from "../constants/RequestTypes";

const freightParams = scopeId => ({
  rootFilters: {
    $where: {
      "scope.id": scopeId,
      "category.name": "Freight",
    },
  },
  sort: [{ columnName: "name", direction: "asc" }],
  fields: ["vendors.id", "name", "status", "scopeId"],
});

const warehouseParams = scopeId => ({
  rootFilters: {
    $where: {
      "scope.id": scopeId,
      "category.name": {
        $in: ["Vendor", "Freight"],
      },
    },
  },
  sort: [{ columnName: "name", direction: "asc" }],
  fields: ["vendors.id", "name", "status", "scopeId"],
});

const wrapParams = (params = {}) => ({
  ...params,
  pageSize: -1,
});

export function initAndRetrieveSelects(clientId, scopeId) {
  return dispatch => {
    dispatch(
      initDataComponent(usersDCId, User, ["userRole"], "users", false, "v2")
    );
    dispatch(
      initDataComponent(freightDCId, Vendor, [], "vendors", false, "v2")
    );
    dispatch(
      initDataComponent(warehouseDCID, Vendor, [], "vendors", false, "v2")
    );
    dispatch(
      initDataComponent(
        consultingDCId,
        ConsultingCompany,
        ["consultingCompanyContacts.contact"],
        "consulting-companies",
        false,
        "v2"
      )
    );
    dispatch(
      initDataComponent(
        clientContactDCId,
        ClientContact,
        ["contact"],
        "client-contacts",
        false,
        "v2"
      )
    );
    setTimeout(() => {
      dispatch(
        performRetrieveListRequest(
          usersDCId,
          wrapParams({
            rootFilters: {
              $where: { "office.scope.id": scopeId },
            },
            sort: [{ columnName: "name", direction: "asc" }],
            fields: ["users.id", "name"],
          })
        )
      );
      dispatch(
        performRetrieveListRequest(
          freightDCId,
          wrapParams(freightParams(scopeId))
        )
      );
      dispatch(
        performRetrieveListRequest(
          warehouseDCID,
          wrapParams(warehouseParams(scopeId))
        )
      );
      dispatch(
        performRetrieveListRequest(
          consultingDCId,
          wrapParams({
            rootFilters: {
              $where: { "scope.id": scopeId },
            },
            sort: [{ columnName: "name", direction: "asc" }],
            fields: ["consulting_companies.id", "name", "type", "scopeId"],
          })
        )
      );
      dispatch(
        performRetrieveListRequest(
          clientContactDCId,
          wrapParams({
            rootFilters: {
              $where: { clientId },
            },
            sort: [{ columnName: "contact.firstName", direction: "asc" }],
          })
        )
      );
    }, 3000);
  };
}
export function destroySelects() {
  return dispatch => {
    dispatch(destroyDataComponentResource(usersDCId, RequestTypes.LIST));
    dispatch(destroyDataComponentResource(freightDCId, RequestTypes.LIST));
    dispatch(destroyDataComponentResource(warehouseDCID, RequestTypes.LIST));
    dispatch(destroyDataComponentResource(consultingDCId, RequestTypes.LIST));
    dispatch(
      destroyDataComponentResource(clientContactDCId, RequestTypes.LIST)
    );
    dispatch(
      destroyDataComponentResource(vendorContactsDCId, RequestTypes.LIST)
    );
    dispatch(
      destroyDataComponentResource(warehouseContactsDCId, RequestTypes.LIST)
    );
  };
}

export function loadVendorContacts(dataComponentId, vendorId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        VendorContact,
        ["vendor", "contact"],
        "vendor-contacts",
        false,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            vendorId,
          },
        },
        sort: [{ columnName: "contact.name", direciton: "asc" }],
        pageSize: -1,
      })
    );
  };
}
