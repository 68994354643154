import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import _get from "lodash/get";

import ProjectCreatePage from "./ProjectCreatePage";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import {
  setHeaderTitle,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";
import {
  initComponents,
  createProject,
  destroyComponents,
} from "../../../actions/projectsActions";
import { handleRequestGlobalErrors } from "../../../utils/formValidationUtils";
import { getCurrentBluechipResourcesForRequestType } from "../../../utils/bluechipUtils";
import {
  getCreatedId,
  processCreateRequestStatus,
} from "../../../utils/dataComponentUtils";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import { closeModalDialog } from "../../../actions/layoutActions";
import {
  onCreateArea,
  onUpdateArea,
  onDeleteArea,
} from "../ProjectDetail/Areas/AreaLocalFunctions";
import { withProjectNumber } from "./ContractSection/ProjectNumberHelper";

export const dataComponentId = "ProjectCreate";
export const dataClientComponentId = "ClientDetail";
const areaTypesDataComponentId = "select-areaTypes";

import propTypes from "../../../constants/propTypes";
export class ProjectCreateContainer extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    initComponents: PropTypes.func.isRequired,
    setHeaderTitle: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    createProject: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    showSnackNotificationAction: PropTypes.func.isRequired,
    destroyComponents: PropTypes.func.isRequired,
    role: propTypes.userRole,
  };

  state = { client: { name: "" } };

  componentDidMount() {
    const { initComponents, clientId, client } = this.props;
    if (client)
      this.props.setHeaderTitle(`Create New Project for ${client.name}`);

    initComponents(dataComponentId, clientId);
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
    this.props.destroyComponents(dataComponentId);
    this.props.destroyComponents(dataClientComponentId);
  }

  componentDidUpdate({ dataComponent: prevDataComponent, client: prevClient }) {
    const { dataComponent, client, showSnackNotificationAction } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateSuccess,
      onError: handleRequestGlobalErrors(
        showSnackNotificationAction,
        this.state.formikActions
      ),
    });

    // Set title
    if (_get(prevClient, "id") !== _get(client, "id")) {
      this.props.setHeaderTitle(`Create New Project for ${client.name}`);
    }
  }

  handleCreateSuccess = () => {
    const { createdId, clientId } = this.props;
    this.state.formikActions.resetForm();
    setTimeout(() => {
      this.props.push(`/clients/${clientId}/projects/${createdId}`);
      this.props.closeModalDialog();
    }, 0);
  };

  createProject = (project, formikActions) => {
    const { createProject } = this.props;
    createProject(project);
    this.setState({ formikActions });
  };
  render() {
    const project = {};

    return (
      <ProjectCreatePage
        project={project}
        areaTypes={this.props.areaTypes}
        {...this.props}
        createProject={this.createProject}
        onCreateArea={(area, formikHelpers) =>
          onCreateArea(area, formikHelpers, this.props.closeModalDialog)
        }
        onDeleteArea={(areaId, index, formikHelpers) =>
          onDeleteArea(index, formikHelpers, this.props.closeModalDialog)
        }
        onUpdateArea={(area, index, formikHelpers) =>
          onUpdateArea(area, index, formikHelpers, this.props.closeModalDialog)
        }
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const clientDataComponent = getDataComponentFlattenedRequestState(
    dataClientComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const createdId = getCreatedId(dataComponent);
  const currencies = getCurrentBluechipResourcesForRequestType(
    "select-currencies",
    state
  );

  const clientId = ownProps.match.params.clientId;
  const client = getBluechipResourceById(clientDataComponent, state, clientId);
  const areaTypes =
    getCurrentBluechipResourcesForRequestType(
      areaTypesDataComponentId,
      state
    ) || [];

  const {
    auth: { role },
  } = state;

  const workScopes = getCurrentBluechipResourcesForRequestType(
    "select-work-scopes",
    state
  );

  return {
    client,
    currencies,
    clientId,
    createdId,
    dataComponent,
    loading: _get(dataComponent, "requestState.create.loading", false),
    nextNumber: _get(dataComponent, "requestState.list.next_number"),
    areaTypes,
    role,
    workScopes,
  };
};

const mapDispatchToProps = {
  push,
  initComponents,
  setHeaderTitle,
  createProject,
  closeModalDialog,
  destroyComponents,
  showSnackNotificationAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withProjectNumber(ProjectCreateContainer))
);
