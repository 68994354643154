import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import CancelPOConfirmation from "./CancelPOConfirmation";
import CancelPOIssuedConfirmation from "./CancelPOIssuedConfirmation";
import CancelPONotes from "./CancelPONotes";
import Vendor from "../../../../../models/Vendor";
import { FormikForm } from "../../../../forms";

const simpleCancelationStatuses = [
  "Draft",
  "Pending Approval",
  "Approved",
  "Denied",
];

export class CancelPOContainer extends Component {
  state = {
    resolution: null,
    vendorId: null,
    displayResolutions: false,
    displayNotes: false,
    notes: "",
  };

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent("select-vendors", Vendor, [], "vendors");
  }

  componentDidUpdate(
    { dataComponent: prevDataComponent },
    { resolution: prevResolution }
  ) {
    const {
      dataComponent,
      showSnackNotificationAction,
      closeModalDialog,
      performRetrieveListRequest,
    } = this.props;

    const { resolution } = this.state;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        showSnackNotificationAction(
          "The purchase order has been updated successfully"
        );
        closeModalDialog();
      },
      onError: this.handleRequestError,
    });

    if (prevResolution !== resolution && resolution === "new_po") {
      performRetrieveListRequest("select-vendors", {
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
      });
    }
  }

  handleRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleSubmit = () => {
    const { id, performUpdateRequest, dataComponentId, status } = this.props;
    const { displayNotes, resolution, vendorId, notes } = this.state;
    if (!simpleCancelationStatuses.includes(status) && !displayNotes) {
      return this.handleDisplayResolutions();
    }
    performUpdateRequest(
      dataComponentId,
      id,
      { status: "Canceled", cancellationNotes: notes },
      { resolution: resolution || "unassign", target_vendor: vendorId }
    );
  };

  buildHandler = property => ({ target: { value } }) =>
    this.setState({ [property]: value });

  handleChangeResolution = this.buildHandler("resolution");
  handleChangeVendor = this.buildHandler("vendorId");
  handleChangeNotes = this.buildHandler("notes");
  handleDisplayNotes = () =>
    this.setState({ displayResolutions: false, displayNotes: true });
  handleDisplayResolutions = () =>
    this.setState({ displayResolutions: true, displayNotes: false });

  getComponent = () => {
    const { title, status, id, specsDataComponent } = this.props;
    const {
      vendorId,
      resolution,
      displayNotes,
      displayResolutions,
      notes,
    } = this.state;
    if (displayResolutions) {
      return (
        <CancelPOIssuedConfirmation
          title={title}
          onSubmit={this.handleDisplayNotes}
          purchaseOrderId={id}
          dataComponent={specsDataComponent}
          onChangeResolution={this.handleChangeResolution}
          onChangeVendor={this.handleChangeVendor}
          resolution={resolution}
          vendorId={vendorId}
        />
      );
    }
    if (displayNotes) {
      return (
        <CancelPONotes
          onDissmiss={this.handleDisplayResolutions}
          onSubmit={this.handleSubmit}
          notes={notes}
          onChangeNotes={this.handleChangeNotes}
        />
      );
    }
    return (
      <CancelPOConfirmation
        title={title}
        onSubmit={this.handleSubmit}
        isSimple={simpleCancelationStatuses.includes(status)}
      />
    );
  };

  render() {
    return <FormikForm>{() => this.getComponent()}</FormikForm>;
  }
}

CancelPOContainer.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  specsDataComponent: propTypes.dataComponent,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { dataComponentId }) => {
  return {
    dataComponent: getDataComponent(dataComponentId, state),
    specsDataComponent: getDataComponentFlattenedRequestState(
      "RelatedSpecs",
      state
    ),
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  closeModalDialog,
  showSnackNotificationAction,
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelPOContainer);
