import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { ChatIcon } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";

import { InputTextArea as Input, InputTextAreaWrapper } from "../components";

const defaultProps = {
  fullWidth: true,
  multiline: true,
  resize: "vertical",
  rows: 2,
  ignoreCaption: true,
  ignoreLabeledText: true,
};

const StyledChatIcon = styled(ChatIcon)`
  margin-top: 10px;
`;

const InputTextArea = ({ handleBlur, ...props }) => {
  const [value, setValue] = useState(props.value);

  return (
    <InputTextAreaWrapper>
      <StyledChatIcon />
      <Input
        data-testid="inputTextArea"
        {...defaultProps}
        {...props}
        placeholder={"Add Forecast comment"}
        value={value}
        onChange={({ target: { value } }) => {
          setValue(value);
        }}
        onBlur={() => handleBlur(value)}
      />
    </InputTextAreaWrapper>
  );
};

export default InputTextArea;
