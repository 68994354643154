import { SelectWithError } from "../../inputs/SelectWithError";
import { inputs } from "../../inputs/inputConfigs";

export const statusFilters = {
  Draft: {
    status: "Draft",
  },
  "Pending Approval": {
    status: "Pending Approval",
  },
  Approved: {
    status: "Approved",
  },
  Issued: {
    status: "Issued",
  },
  "CO in Progress": {
    revisionStatus: "In Progress",
  },
  "CO Pending Approval": {
    revisionStatus: "Pending Approval",
  },
  "CO Approved": {
    revisionStatus: "Approved",
  },
  "Issued, Revised": {
    status: "Issued",
    isRevised: true,
  },
  "Needs Follow Up": {
    needsFollowUp: true,
  },
};

export const getFilters = (filterOptions, element) => {
  const {
    specIds = [],
    vendorIds = [],
    poIds = [],
    awardedIds = [],
    name,
    status = [],
  } = filterOptions;
  const filtersPO = {
    specIds: {
      "specs.id": {
        $in: specIds,
      },
    },
    vendorIds: {
      vendorId: {
        $in: vendorIds,
      },
    },
    poIds: {
      id: {
        $in: poIds,
      },
    },
    name: {
      "specs.description": {
        $ilike: `%${name || ""}%`,
      },
    },
    status: {
      $or: status.map(status => statusFilters[status]),
    },
  };
  const filtersBidGroup = {
    specIds: {
      "bidGroupSpecs.specId": {
        $in: specIds,
      },
    },
    vendorIds: {
      "bidGroupVendors.vendorId": {
        $in: awardedIds ? [...vendorIds, ...awardedIds] : vendorIds,
      },
    },
    awardedIds: {
      $and: {
        "bidGroupVendors.vendorId": {
          $in: awardedIds,
        },
        "bidGroupVendors.bidGroupVendorSpecs.quotes.awardedBidGroupVendorSpecQuoteId": {
          exists: true,
        },
      },
    },
    name: {
      "bidGroupSpecs.spec.description": {
        $ilike: `%${name || ""}%`,
      },
    },
  };
  const selectedOptions = element === "po-groups" ? filtersPO : filtersBidGroup;
  return {
    $and: Object.keys(filterOptions).reduce(
      (result, key) => [...result, selectedOptions[key]],
      []
    ),
  };
};

export const buildOnFilter = ({
  projectId,
  closeModalDialog,
  filterMethod,
  setState,
  element,
}) => filterOptions => {
  setState({ filterOptions });
  filterMethod(projectId, getFilters(filterOptions, element));
  closeModalDialog();
};

export const poFields = (projectId, poIds) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.specIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.specIds.APIOptions,
              rootFilters: {
                $where: {
                  projectId,
                  purchaseOrderId: {
                    $in: poIds,
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.vendorIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.vendorIds.APIOptions,
              rootFilters: {
                $where: {
                  $and: {
                    "purchaseOrders.id": { $in: poIds },
                    "purchaseOrders.status": { $notEqual: "Canceled" },
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.poIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.poIds.APIOptions,
              rootFilters: {
                $where: {
                  $and: {
                    id: { $in: poIds },
                    status: { $notEqual: "Canceled" },
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.baseName,
            ignoreLabeledText: true,
            label: "Spec Description",
          },
          grid: { xs: 12 },
        },
        {
          input: {
            label: "Status",
            InputComponent: SelectWithError,
            name: "status",
            fullWidth: true,
            isAutocomplete: true,
            isAutocompleteMulti: true,
            options: Object.keys(statusFilters).map(status => ({
              id: status,
              name: status,
            })),
          },
        },
      ],
    },
  ],
});

export const bidGroupFields = (projectId, bidGroupsIds) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.specIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.specIds.APIOptions,
              rootFilters: {
                $where: {
                  projectId,
                  "bidGroupSpecs.bidGroupId": {
                    $in: bidGroupsIds,
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.vendorIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.vendorIds.APIOptions,
              rootFilters: {
                $where: {
                  "bidGroupVendors.bidGroupId": { $in: bidGroupsIds },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.awardedTo,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.awardedTo.APIOptions,
              rootFilters: {
                $where: {
                  "bidGroupVendors.bidGroupId": { $in: bidGroupsIds },
                  "bidGroupVendors.bidGroupVendorSpecs.quotes.awardedBidGroupVendorSpecQuoteId": {
                    exists: true,
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.baseName,
            ignoreLabeledText: true,
            label: "Spec Description",
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});
