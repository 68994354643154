import React from "react";
import styled from "styled-components";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import BWCell from "../../../../ui/BWGrid/gridInternalComponents/BWCell";
import { BWHeadCell } from "../../../../ui/BWGrid/BWGrid";

const StyledCell = styled(BWCell)`
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 12px;
  }
`;

const StyledHeadCell = styled(BWHeadCell)`
  &:first-child {
    padding-left: 0;
  }
`;

export const GridWrapper = styled.div`
  padding-left: 24px;
  padding-top: 8px;
`;

export const CellComponent = props => <StyledCell {...props} />;
export const HeaderCellComponent = props => <StyledHeadCell {...props} />;

export const VerticalAlignWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckCircleWrapper = styled(({ isApproved, selectRef, ...props }) => (
  <CheckCircle {...props} />
))`
  color: ${({ isApproved }) =>
    isApproved ? "#72b327" : "rgba(0, 0, 0, 0.24)"};
  cursor: ${({ onClick }) => onClick && "pointer"};
`;

export const Check = React.forwardRef(({ ...props }, ref) => (
  <CheckCircleWrapper selectRef={ref} {...props} />
));

export const Delete = styled(DeleteIcon)`
  color: #0e1524;
`;

export const Edit = styled(EditIcon)`
  color: #0e1524;
`;
