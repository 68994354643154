import React from "react";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RootRef from "@material-ui/core/RootRef";

export const ListWrapper = ({
  isEditing,
  draggableProps,
  innerRef,
  ...props
}) => {
  if (!innerRef) return <ListItem {...props} />;
  return (
    <RootRef rootRef={innerRef}>
      <ListItem {...props} {...draggableProps} />
    </RootRef>
  );
};

export const CustomListItem = styled(ListWrapper)`
  padding: 16px 0 ${({ isEditing }) => (isEditing ? "12px" : "16px")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  min-height: 57px;
  &:last-child {
    border-bottom: none;
  }
`;

export const ListActions = styled(ListItemSecondaryAction)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Icon = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 8px;
  color: #000000;
`;

export const ReorderIcon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px;
  color: #000000;
`;

export const CustomItemText = styled(ListItemText)`
  span {
    color: rgba(0, 0, 0, 0.87);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const NameInputContainer = styled.div`
  width: 180px;
`;

export const CountInputContainer = styled.div`
  width: 160px;
  margin-left: 10px;
`;
