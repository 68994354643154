import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import List from "@material-ui/core/List";
import NavbarContainer from "./NavbarContainer";
import SnackNotificationContainer from "../../ui/SnackNotifications/SnackNotificationContainer";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import propTypes from "../../../constants/propTypes";
import { ClickAwayListener } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const Wrapper = styled.div`
  display: flex;
  height: auto !important;
  min-height: 100%;
`;

// This utilizes trick for forcing sidebar to be full height of screen or
// content from this SO answer: https://stackoverflow.com/a/8451485/52509
const StyledDrawer = styled(props => (
  <Drawer classes={{ paper: "paper" }} {...props} />
))`
  position: fixed;
  margin-top: 56px;
  height: calc(100vh - 56px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.18);
  @media (max-width: 600px) {
    margin-top: 0px;
    height: 100vh;
    z-index: 1000;
    width: 0px;
  }
  & .paper {
    position: relative;
    white-space: nowrap;
    width: ${props => (props.open ? 256 : 56)}px;
    transition-property: width;
    transition-duration: ${props =>
      props.open
        ? props.theme.transitions.duration.enteringScreen
        : props.theme.transitions.duration.leavingScreen}ms;
    transition-timing-function: ${props =>
      props.theme.transitions.easing.sharp};
    transition-delay: 0s;
    overflow-x: ${({ open }) => (open ? "auto" : "hidden")};
    @media (max-width: 600px) {
      width: ${props => (props.open ? 256 : 0)}px;
      border-top: 17px solid rgba(0, 0, 0, 0.08);
    }
  }
`;

const DrawerButton = styled(({ isOpen, ...props }) => (
  <IconButton {...props} />
))`
  padding: 12px;
  margin-left: ${({ isOpen }) => (isOpen ? "198" : "4")}px;
`;

const Main = styled.main`
  flex-grow: 1;
  padding-top: 56px;
  overflow-x: auto;
  overflow-y: visible;
  margin-left: ${props => (props.isNavbarDrawerOpen ? 256 : 56)}px;
  transition-property: margin-left;
  transition-duration: ${props =>
    props.open
      ? props.theme.transitions.duration.enteringScreen
      : props.theme.transitions.duration.leavingScreen}ms;
  transition-timing-function: ${props => props.theme.transitions.easing.sharp};
  transition-delay: 0s;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

const Content = styled.div({});

const DrawerContent = ({ children, isNavbarDrawerOpen }) => (
  <Main isNavbarDrawerOpen={isNavbarDrawerOpen}>
    <Content>{children}</Content>
    <SnackNotificationContainer />
  </Main>
);

DrawerContent.propTypes = {
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  children: PropTypes.object,
};

export class DrawerWrapper extends Component {
  shouldComponentUpdate = ({ modalDialog: { isOpen, freezeBackground } }) =>
    !isOpen || !freezeBackground;

  render() {
    const {
      isNavbarDrawerOpen,
      handleDrawerToggle,
      closeNavbarDrawer,
      children,
      width,
    } = this.props;

    return (
      <Wrapper>
        <ClickAwayListener
          onClickAway={closeNavbarDrawer}
          mouseEvent={
            isNavbarDrawerOpen && isWidthDown("xs", width) ? "onClick" : false
          }
          touchEvent={
            isNavbarDrawerOpen && isWidthDown("xs", width)
              ? "onTouchEnd"
              : false
          }
        >
          <React.Fragment>
            <StyledDrawer variant="permanent" open={isNavbarDrawerOpen}>
              <List disablePadding={true}>
                <NavbarContainer />
                <DrawerButton
                  onClick={handleDrawerToggle}
                  isOpen={isNavbarDrawerOpen}
                >
                  {isNavbarDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
                </DrawerButton>
              </List>
            </StyledDrawer>
            <DrawerContent isNavbarDrawerOpen={isNavbarDrawerOpen}>
              {children}
            </DrawerContent>
          </React.Fragment>
        </ClickAwayListener>
      </Wrapper>
    );
  }
}

DrawerWrapper.propTypes = {
  isDetailPage: PropTypes.bool,
  modalDialog: propTypes.modalDialog,
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  children: PropTypes.object,
  handleDrawerToggle: PropTypes.func.isRequired,
  closeNavbarDrawer: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(DrawerWrapper);
