import React, { createContext, Fragment, useContext } from "react";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import PropTypes from "prop-types";

import { FormControlLabel, Switch } from "../../mui/core";
import { Content, PagingGridRow } from "../../ui/BWGrid/GridRow";

export const SwitchContext = createContext();

const StatusSwitch = ({ onToggle, checked, label }) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onToggle} color="primary" />}
      label={label}
    />
  );
};
StatusSwitch.propTypes = {
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

export const Switches = () => {
  const switchContext = useContext(SwitchContext);

  if (!switchContext) {
    return null;
  }

  const { onToggle, checked, label } = switchContext;

  const onToggleArray = Array.isArray(onToggle) ? onToggle : [onToggle];
  const checkedArray = Array.isArray(checked) ? checked : [checked];
  const labelArray = Array.isArray(label) ? label : [label];

  const statusSwitchProps = [];

  onToggleArray.forEach((onToggle, index) => {
    statusSwitchProps.push({
      onToggle,
      checked: checkedArray[index],
      label: labelArray[index],
    });
  });

  return (
    <Fragment>
      {statusSwitchProps.map(({ onToggle, checked, label }, index) => (
        <StatusSwitch
          key={index}
          onToggle={onToggle}
          checked={checked}
          label={label}
        />
      ))}
    </Fragment>
  );
};

const PagingPaneWithStatusSwitch = () => {
  return (
    <Plugin name="GridRowPlugin">
      <Template name="footer">
        <PagingGridRow>
          <Content>
            <Switches />
          </Content>
          <TemplatePlaceholder name="pagingPanel" />
        </PagingGridRow>
      </Template>
    </Plugin>
  );
};

export default PagingPaneWithStatusSwitch;
