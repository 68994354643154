import React from "react";
import styled from "styled-components";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const flagColors = {
  yellow: "#FF9100",
  red: "#FF1744",
  green: "#72B327",
};

export const BillingStatusWrapper = styled(({ billingFlag, ...props }) => (
  <div {...props} />
))`
  display: flex;
  align-items: center;
  background: ${props => flagColors[props.billingFlag] || ""};
  padding: 2px 8px;
  border-radius: 50px;
  width: fit-content;
  line-height: 20px;
`;

export const DollarIcon = styled(AttachMoneyIcon)`
  color: #ffffff;
  font-size: 20px;
`;

export const BillingText = styled.span`
  color: #ffffff;
  line-height: 20px;
`;
