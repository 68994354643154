import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SpecDetailTitle from "./SpecDetailTitle";
import ActionDetailLink from "./ActionDetailLink";
import {
  PORevisionContext,
  RevisionReferenceContext,
  LabeledTextContext,
} from "../../../withPORevision";

const StyledSpecDetailDescription = styled.div`
  padding-bottom: 12px;
`;

const Description = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  text-overflow: ellipsis;
  position: relative;
  ${({ isUpdated }) =>
    isUpdated &&
    `
  font-style: italic;
  font-weight: 600;
  color: #000000;
  `}
  &:after {
    ${({ isUpdated }) =>
      isUpdated &&
      `
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    top: -8px;
    left: -8px;
    overflow: visible;
    color: #ff9100;
  `}
  }
`;

const SpecDetailDescription = props => {
  const { title, description, id } = props.specDetail;
  const { isUpdated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);
  const isLabeled = useContext(LabeledTextContext);
  const detailReference = `${reference}.${id}`;
  return (
    <StyledSpecDetailDescription>
      <SpecDetailTitle isUpdated={isUpdated(detailReference, "title")}>
        {title ? title : "-"}
      </SpecDetailTitle>
      {description && (
        <Description isUpdated={isUpdated(detailReference, "description")}>
          {description}
        </Description>
      )}
      <ActionDetailLink
        visible={!description && !isLabeled}
        specDetail={props.specDetail}
        type="Description"
      />
    </StyledSpecDetailDescription>
  );
};

SpecDetailDescription.propTypes = {
  specDetail: PropTypes.object,
};

export default SpecDetailDescription;
