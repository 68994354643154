import TextInputWithError from "../TextInputWithError";
import CheckboxWithError from "../CheckboxWithError";
import DatePickerWithError from "../DatePickerWithError";
import CurrencyInput from "../CurrencyInput";

export default {
  approvedToPay: {
    name: "approvedToPay",
    InputComponent: CheckboxWithError,
    label: "Approved to Pay",
    ignoreCaption: true,
  },
  shipmentQty: {
    InputComponent: TextInputWithError,
    label: "Quantity",
    name: "quantity",
    fullWidth: true,
    limit: 0,
    limitType: "value",
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { prefix: "" },
    },
  },
  estimatedShipDate: {
    InputComponent: DatePickerWithError,
    label: "Estimated Ship",
    name: "estimatedShipDate",
    disablePast: true,
    fullWidth: true,
  },
  estimatedDeliveryDate: {
    InputComponent: DatePickerWithError,
    label: "Estimated Delivery",
    name: "estimatedDeliveryDate",
    disablePast: true,
    fullWidth: true,
  },
  shipmentTrackingNumber: {
    InputComponent: TextInputWithError,
    label: "Tracking Number",
    name: "trackingNumber",
    fullWidth: true,
  },
  shipmentReceivedBy: {
    InputComponent: TextInputWithError,
    label: "Received By",
    name: "receivedBy",
    fullWidth: true,
  },
  actualShipDate: {
    InputComponent: DatePickerWithError,
    label: "Actual Ship",
    name: "actualShipDate",
    fullWidth: true,
  },
  actualDeliveryDate: {
    InputComponent: DatePickerWithError,
    label: "Actual Delivery",
    name: "actualDeliveryDate",
    fullWidth: true,
  },
  notes: {
    InputComponent: TextInputWithError,
    label: "Notes",
    name: "notes",
    fullWidth: true,
    multiline: true,
    rows: 2,
  },
};
