import React from "react";
import shallow from "zustand/shallow";
import styled from "styled-components";
import pluralize from "pluralize";
import InfoIcon from "@material-ui/icons/Info";

import useErrorImportStore from "../../../../hooks/useErrorImportStore";

const TextWrapper = styled.div`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ff1744;
  align-items: center;
`;

const Count = styled.span`
  margin-left: 10px;
`;

const ViewAll = styled.a`
  color: #57abff;
  margin-left: 8px;
  cursor: pointer;
`;

const ViewInvalid = styled.a`
  color: #bdbdbd;
  margin-left: 8px;
  cursor: pointer;
`;

function InvalidItems() {
  const [actions, displayValidRows] = useErrorImportStore(
    state => [
      state.actions,
      state.displayValidRows,
      state.importError,
      state.localError,
      state.ignoreErrors,
    ], //Doing this force to the component to be re-rendered when the errors get updated changes
    shallow
  );

  if (!actions.isInvalid()) return null;

  return (
    <TextWrapper>
      <InfoIcon />
      <Count>{pluralize("Invalid Item", actions.getCount(), true)}</Count>
      {displayValidRows ? (
        <ViewInvalid onClick={() => actions.setDisplayValidRows(false)}>
          Click To View
        </ViewInvalid>
      ) : (
        <ViewAll onClick={() => actions.setDisplayValidRows(true)}>
          View All Items
        </ViewAll>
      )}
    </TextWrapper>
  );
}

export default InvalidItems;
