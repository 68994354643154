import React from "react";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import Reorder from "@material-ui/icons/Reorder";
import { ReorderIcon, Icon, ListActions } from "./components";

export const ActionIcons = ({
  allowEditing,
  isEditing,
  onSwitchEditMode,
  onRemove,
  index,
  ...dragHandleProps
}) => (
  <ListActions>
    {allowEditing && (
      <Icon onClick={onSwitchEditMode}>{isEditing ? <Save /> : <Edit />}</Icon>
    )}
    <Icon onClick={() => onRemove(index)}>
      <Delete />
    </Icon>
    <ReorderIcon {...dragHandleProps}>
      <Reorder />
    </ReorderIcon>
  </ListActions>
);

ActionIcons.propTypes = {
  onSwitchEditMode: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool,
  allowEditing: PropTypes.bool,
};

export default ActionIcons;
