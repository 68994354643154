import styled from "styled-components";
import { COM_PREVIEW } from "../../../../constants/specs";
import { StyledMenu } from "../../../ui/DragAndDropList/Menu";

export const Content = styled.div`
  padding-bottom: 24px;
`;

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  ${StyledMenu} {
    padding-top: 0;
  }
  & > * {
    ${({ isDeleted }) => isDeleted && "opacity: 0.4"}
  }
  ${({ isDeleted, isCreated }) =>
    (isDeleted || isCreated) &&
    `
    font-style: italic;
    font-weight: 600;
    color: #000000!important;
  `}
  &:after {
    ${({ isDeleted, isCreated }) =>
      (isDeleted || isCreated) &&
      `
      position: absolute;
      content: "\\25CF";
      font-size: 10px;
      top: -12px;
      left: -6px;
      overflow: visible;
    `}
    ${({ isCreated }) => isCreated && "color: #ff9100;"}
    ${({ isDeleted }) => isDeleted && "color: #ff1744;"}
  }
`;

export const ComMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ComInfo = styled.div`
  display: flex;
  justify-content: left;
  color: rgba(0, 0, 0, 0.54);
`;

export const ComIcon = styled.div`
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 2px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src}),
    url(${({ originalSrc }) => originalSrc}), url(${COM_PREVIEW});
`;

export const Title = styled.div`
  margin: 0;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Id = styled.span`
  padding-right: 8px;
`;

export const ComType = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54);
`;

export const Notes = styled.div`
  margin-top: 8px;
  position: relative;
  ${({ isUpdated }) =>
    isUpdated &&
    `
    font-style: italic;
    font-weight: 600;
    color: #000000;
  `}
  &:after {
    ${({ isUpdated }) =>
      isUpdated &&
      `
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    top: -8px;
    left: -8px;
    overflow: visible;
    color: #ff9100;
  `}
  }
`;
