export const handleSuccess = (
  fetchData,
  vendorShipToSiteDataComponentId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog
) => {
  fetchData(vendorShipToSiteDataComponentId);
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleError = (data, showSnackNotificationAction) => {
  const emailError =
    data.errors.find(error => error.source === "contact.email") || {};
  emailError.title = "One of the emails already exists on another Contact";
  data.errors.map(error => {
    showSnackNotificationAction(error.title);
  });
};
