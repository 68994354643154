import { Table } from "@devexpress/dx-react-grid-material-ui";
import PropTypes from "prop-types";
import React, { Fragment, memo, useMemo } from "react";
import get from "lodash.get";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import propTypes from "../../../../constants/propTypes";
import Loader from "../../Loader";

const clickHandler = (onClick, displayCollapseButton, ...args) =>
  onClick && !displayCollapseButton ? () => onClick(...args) : null;

const getBWCellContent = (cellValue, isLoading) => {
  return <Loader loading={isLoading}>{cellValue}</Loader>;
};

export const DefaultCellContainer = styled.div`
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}` : "auto")};
  max-width: ${({ width }) => (width ? `${width}` : "auto")};
  overflow: ${({ overflow }) => (overflow ? `${overflow}` : "hidden")};
  text-overflow: ellipsis;
`;

const getTitle = ({ column, row, tableRow }, cellValue) => {
  const title = column.render
    ? column.render(row, column.name, tableRow)
    : cellValue;

  return typeof title === "string" ? title : "";
};

const parseRowChildren = (
  children,
  renderCollapseButton,
  expanded,
  onClick
) => {
  return (
    <Fragment>
      {renderCollapseButton ? (
        <Table.Cell>
          <IconButton onClick={onClick}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Table.Cell>
      ) : null}
      {children.slice(renderCollapseButton ? 1 : 0)}
    </Fragment>
  );
};

const ContentWrapper = ({ children, column, title }) => {
  return column.minWidth || column.width || column.overflow ? (
    <DefaultCellContainer
      minWidth={column.minWidth}
      width={column.width}
      overflow={column.overflow}
      title={title}
    >
      {children}
    </DefaultCellContainer>
  ) : (
    children
  );
};

ContentWrapper.propTypes = {
  column: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

const getClickableState = (disableRow, row, onClick, displayCollapseButton) => {
  const isDisabledRow = disableRow ? disableRow(row) : false;
  return [
    !!onClick && !isDisabledRow,
    isDisabledRow,
    displayCollapseButton && !!onClick && !isDisabledRow,
  ];
};

export const makeBWCell = (
  onClick,
  CellComponent,
  isLoading,
  disableRow,
  displayCollapseButton
) => {
  const BWCell = props => {
    const { column, row, value, tableRow } = props;
    let cellValue = get(row, column.name) || row[column.name];
    const title = getTitle(props, cellValue);
    if (column.getCellValue) {
      cellValue = column.getCellValue(row, column.name, tableRow);
    }

    const content = getBWCellContent(cellValue, isLoading);
    const [hasClickHandler, isDisabledRow] = getClickableState(
      disableRow,
      row,
      onClick
    );

    return (
      <CellComponent
        {...props}
        title={title}
        hasClickHandler={hasClickHandler && !displayCollapseButton}
        isDisabledRow={isDisabledRow}
        onClick={clickHandler(
          hasClickHandler && onClick,
          displayCollapseButton,
          row,
          {
            ...column,
            rowId: tableRow.rowId,
            value,
          }
        )}
      >
        <ContentWrapper column={column} title={title}>
          {content}
        </ContentWrapper>
      </CellComponent>
    );
  };
  return memo(BWCell);
};

export const makeRow = (
  onClick,
  RowComponent,
  disableRow,
  displayCollapseButton
) => {
  const Row = props => {
    const [
      hasClickHandler,
      isDisabledRow,
      renderCollapseButton,
    ] = getClickableState(
      disableRow,
      props.row,
      onClick,
      displayCollapseButton
    );
    const expanded = props.row.__expanded__;
    return (
      <RowComponent
        isDisabledRow={isDisabledRow}
        hasClickHandler={hasClickHandler}
        data-id={props.row.id}
        {...props}
      >
        {parseRowChildren(props.children, renderCollapseButton, expanded, () =>
          onClick(props.row, props.tableRow)
        )}
      </RowComponent>
    );
  };
  return memo(Row);
};

const makeTable = (onReorder, TableComponent) => {
  const Table = props => {
    return <TableComponent {...props} onReorder={onReorder} />;
  };
  return memo(Table);
};

const BWGridTable = ({
  expandRow,
  tableComponents,
  isLoading,
  columnExtensions,
  onClick,
  onReorder,
  disableRow,
  alwaysDisplayDetailRow,
  displayCollapseButton,
  NoDataCellComponent,
}) => {
  const {
    TableComponent,
    RowComponent,
    CellComponent,
    DetailRowComponent,
    TableContainerComponent,
    BodyComponent,
    NoDataRowComponent,
  } = tableComponents;
  const onRowClick =
    DetailRowComponent && !alwaysDisplayDetailRow ? expandRow : onClick;

  const rowComponent = useMemo(
    () => makeRow(onRowClick, RowComponent, disableRow, displayCollapseButton),
    [onRowClick, RowComponent, disableRow, displayCollapseButton]
  );

  const cellComponent = useMemo(
    () =>
      makeBWCell(
        onRowClick,
        CellComponent,
        isLoading,
        disableRow,
        displayCollapseButton
      ),
    [onRowClick, CellComponent, isLoading, disableRow, displayCollapseButton]
  );

  const tableComponent = useMemo(() => makeTable(onReorder, TableComponent), [
    onReorder,
    TableComponent,
  ]);

  return (
    <Table
      tableComponent={tableComponent}
      rowComponent={rowComponent}
      cellComponent={cellComponent}
      columnExtensions={columnExtensions}
      containerComponent={TableContainerComponent}
      bodyComponent={BodyComponent}
      noDataCellComponent={NoDataCellComponent}
      noDataRowComponent={NoDataRowComponent}
    />
  );
};

BWGridTable.propTypes = {
  isLoading: PropTypes.bool,
  alwaysDisplayDetailRow: PropTypes.bool,
  displayCollapseButton: PropTypes.bool,
  disableRow: PropTypes.func,
  expandRow: PropTypes.func,
  onClick: PropTypes.func,
  onReorder: PropTypes.func,
  columnExtensions: PropTypes.arrayOf(PropTypes.shape({})),
  tableComponents: propTypes.tableComponents,
  NoDataCellComponent: PropTypes.func,
};

export default memo(BWGridTable);
