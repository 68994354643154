import React from "react";
import { Checkbox } from "@material-ui/core";
import styled from "styled-components";

export default styled(({ narrow, ...props }) => <Checkbox {...props} />)`
  ${({ narrow }) =>
    narrow &&
    `
    padding-top: 8px;
    padding-bottom: 8px;
  `}
`;
