import React, { Fragment, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../../forms/FormGrid";
import ActionButtons from "../../../../../forms/ActionButtons";
import { ProjectContext } from "../../../../../hooks/useIsProjectClosed";
import FormikForm from "../../../../../forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";

import { inputs } from "../../../../../../components/inputs/inputConfigs";
import { LabeledTextContext } from "../../../../../../withPORevision/withLabeledTextOption";

const fields = {
  groups: [
    {
      items: [
        {
          input: { ...inputs.referenceNumber, required: true },
          grid: { xs: 8 },
        },
        {
          input: {
            ...inputs.amount,
            label: "Amount",
            required: true,
            inputProps: {
              maskProps: { allowNegative: true },
            },
          },
          grid: { xs: 4 },
        },
        {
          input: { ...inputs.paymentDate, required: true },
          grid: { xs: 4 },
        },
      ],
    },
  ],
};

const PaymentForm = ({
  onSubmit,
  fee,
  feePayment,
  toggleShowList,
  dataComponent,
}) => {
  const initialValues = useMemo(() => {
    return (
      feePayment || {
        feeId: fee.id,
        amount: fee.amount - fee.amountPaid,
        paymentDate: new Date(),
      }
    );
  }, [fee.amount, fee.amountPaid, fee.id, feePayment]);

  const maxAmount = Number(
    Number(fee.amount - fee.amountPaid + (feePayment?.amount || 0)).toFixed(2)
  );
  const validationSchema = useMemo(
    () =>
      BWModels.loadSchema("FeePayment").append({
        amount: BWModels.Joi.number()
          .max(maxAmount)
          .required(),
      }),
    [maxAmount]
  );
  const projectContext = useContext(ProjectContext);
  const isLabeled =
    fee.revenueSharing ||
    !["Billed", "Partially Paid", "Paid"].includes(fee.status);
  return (
    <ProjectContext.Provider
      value={{
        ...projectContext,
        ignoreProjectClosed: true,
      }}
    >
      <LabeledTextContext.Provider value={isLabeled}>
        <FormikForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, errors, ...formikProps }) => (
            <Fragment>
              <FormGrid
                fields={fields}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <ActionButtons
                isModal
                onSend={handleSubmit}
                onCancel={toggleShowList}
                sendButtonText="Save Payment"
                listeners={[dataComponent.dataComponentId]}
                disabled={isLabeled}
              />
            </Fragment>
          )}
        </FormikForm>
      </LabeledTextContext.Provider>
    </ProjectContext.Provider>
  );
};

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fee: propTypes.fee,
  toggleShowList: PropTypes.func,
  dataComponent: propTypes.dataComponent,
};

export default PaymentForm;
