import React from "react";
import { useRef, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _noop from "lodash/noop";

import { performUpdateRequest } from "../../../actions/dataComponentActions";
import FloatInput, {
  FloatInput as RawFloatInput,
} from "../../inputs/FloatInput";
import BWCell from "./gridInternalComponents/BWCell";
import { WithEditableCellContext } from "./withEditableCellController";
import { EditableWrapper } from "./components";
import { buildPressEventHandlers } from "../../../utils/eventUtils";
import { setAutoSaveComponentId } from "../../../actions/layoutActions";

const buildHandleChange = (
  beforeSave,
  afterSave = _noop,
  appendValues,
  columnName,
  dataComponentId,
  initialValue,
  performUpdateRequest,
  params,
  id,
  setAutoSaveComponentId,
  row
) => async value => {
  setAutoSaveComponentId(dataComponentId);
  setTimeout(() => {
    const parsedValue = beforeSave ? beforeSave(value) : value;
    const appendedValues = appendValues ? appendValues(value, row) : {};
    if (value === initialValue) return;
    performUpdateRequest(
      dataComponentId,
      id,
      {
        [columnName]: parsedValue,
        ...appendedValues,
      },
      params
    );
    afterSave(parsedValue, row);
  }, 100);
};

export const EditableCellAPI = ({
  input: { ignoreProjectClosed = false, ...input },
  children,
  dataComponentId,
  performUpdateRequest,
  setAutoSaveComponentId,
  params,
  ...props
}) => {
  const { column, row, tableRow } = props;
  const { editableOptions = {}, OuterComponent = () => null } = column;
  const columnId = column.columnId;
  const rowId = tableRow.rowId;

  const { addCell, removeCell, setCurrentCell, currentCell } = useContext(
    WithEditableCellContext
  );

  const {
    beforeSave,
    afterSave,
    appendValues,
    formatValue,
    formatId,
  } = editableOptions;
  const anchorEl = useRef(null);
  const isEditable =
    currentCell.columnId === columnId && currentCell.rowId === rowId;

  useEffect(() => {
    addCell(rowId, columnId);
    return () => removeCell(rowId, columnId);
  }, [rowId, columnId, addCell, removeCell]);

  const startEditMode = useCallback(() => {
    setCurrentCell({ rowId, columnId });
  }, [setCurrentCell, rowId, columnId]);

  const initialValue = formatValue ? formatValue(row) : row[column.name];
  const id = formatId ? formatId(row) : row.id;
  const handleChange = useCallback(
    buildHandleChange(
      beforeSave,
      afterSave,
      appendValues,
      column.name,
      dataComponentId,
      initialValue,
      performUpdateRequest,
      params,
      id,
      setAutoSaveComponentId,
      row
    ),
    [
      beforeSave,
      afterSave,
      appendValues,
      column.name,
      dataComponentId,
      initialValue,
      performUpdateRequest,
      params,
      id,
      setAutoSaveComponentId,
    ]
  );

  const { onClick, onKeyUp } = buildPressEventHandlers(
    startEditMode,
    false,
    false
  );

  return (
    <BWCell {...props}>
      <EditableWrapper
        ref={anchorEl}
        onClick={onClick}
        onKeyUp={onKeyUp}
        ignoreProjectClosed={ignoreProjectClosed}
      >
        {children}
      </EditableWrapper>
      <OuterComponent row={row} />
      <FloatInput
        input={input}
        onChange={handleChange}
        autoFocus
        name={column.name}
        value={initialValue}
        visible={isEditable}
        hasError={false}
        anchorEl={() => anchorEl.current}
      />
    </BWCell>
  );
};

EditableCellAPI.propTypes = {
  input: RawFloatInput.propTypes.input,
  row: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}),
};

export const mapStateToProps = () => {
  return {};
};

export const mapDispatchToProps = {
  performUpdateRequest,
  setAutoSaveComponentId,
};

const ConnectedEditableCellAPI = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableCellAPI);

export default ConnectedEditableCellAPI;

export function editableCellCreator(
  rowInputs,
  dataComponentId,
  params,
  isEditable
) {
  return function CellWrapper({ children, ...props }) {
    const { column, tableRow } = props;
    if (isEditable) {
      if (!isEditable(column, tableRow))
        return <BWCell {...props}>{children}</BWCell>;
    }

    if (!column.editable) return <BWCell {...props}>{children}</BWCell>;

    const input =
      typeof rowInputs === "function"
        ? rowInputs(tableRow)[column.name]
        : rowInputs[column.name];
    return (
      <ConnectedEditableCellAPI
        {...props}
        input={input}
        dataComponentId={dataComponentId}
        params={params}
      >
        {children}
      </ConnectedEditableCellAPI>
    );
  };
}
