import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";
import { dataComponentId } from "../../../ProjectDetailContainer";
import { inputs } from "../../../../../../components/inputs/inputConfigs";
import { useIsProjectClosed } from "../../../../../hooks/useIsProjectClosed";

const getNotesFields = isProjectClosed => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.statusComment,
            label: "Note",
            name: "notes",
            required: true,
            ignoreLabeledText: !isProjectClosed,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

const validationSchema = Joi.object().keys({
  notes: Joi.string()
    .trim()
    .required(),
});

const ProjectNote = ({ actions, project }) => {
  const isProjectClosed = useIsProjectClosed();

  const onUpdateNote = project => {
    actions.performUpdateRequest(dataComponentId, project.id, project);
  };

  return (
    <FormikForm
      initialValues={{ id: project.id, notes: project.notes }}
      onSubmit={onUpdateNote}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <FormGrid fields={getNotesFields(isProjectClosed)} />
          <ActionButtons
            listeners={[dataComponentId]}
            onSend={handleSubmit}
            isModal={true}
            additionalProps={{ send: { disableIfProjectClosed: true } }}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

ProjectNote.propTypes = {
  actions: PropTypes.shape({
    performUpdateRequest: PropTypes.func.isRequired,
  }),
  project: propTypes.project,
};

export default ProjectNote;
