import React, { useState } from "react";

import { Paper } from "../../../mui/core";
import { SwitchContext } from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import Grid from "./Grid";

const Approvals = () => {
  const [showAllProject, setShowAllProject] = useState(false);
  const handleFilterByToggle = () => {
    setShowAllProject(!showAllProject);
  };

  return (
    <SwitchContext.Provider
      value={{
        onToggle: handleFilterByToggle,
        checked: showAllProject,
        label: "Display All Projects",
      }}
    >
      <Paper>
        <Grid showAllProject={showAllProject} />
      </Paper>
    </SwitchContext.Provider>
  );
};

export default Approvals;
