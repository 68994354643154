import React from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import styled from "styled-components";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ActionButtons from "../../../forms/ActionButtons";
import RegularText from "../../../ui/Typography/RegularText";

const Message = styled(RegularText)`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  padding: 24px;
`;

const ExportConfirm = ({ count, anchorEl, onSubmit, onClose }) => {
  const { height, width } = useWindowDimensions();

  return (
    <Popover
      anchorReference={"anchorPosition"}
      open={!!anchorEl}
      onClose={onClose}
      anchorPosition={{ left: width / 2, top: height / 2 }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Wrapper>
        <Message>Do you want to download {count} notes as PDF?</Message>
        <ActionButtons
          onSend={onSubmit}
          sendButtonText="DOWNLOAD"
          onCancel={onClose}
        />
      </Wrapper>
    </Popover>
  );
};

ExportConfirm.propTypes = {
  anchorEl: PropTypes.shape({}),
  count: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExportConfirm;
