import styled from "styled-components";
import React from "react";
import { TitleTag } from "../../layout/PageHeader";

export const Dates = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
  @media (max-width: 768px) {
    margin-top: 0px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 16px;
    margin-bottom: 8px;
  }
`;

export const Scopes = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  margin-right: 8px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
  & > p {
    margin-top: 4px;
  }
`;

export const Currency = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  white-space: nowrap;
  @media (max-width: 768px) {
    align-self: center;
  }
`;

export const AttributeContainer = styled.div({});

export const TitleWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
  }
`;
export const Title = styled.div`
  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    margin-right: 4px;
  }
`;
export const LocationAndTeamContainer = styled.div`
  display: flex;
  margin-top: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    margin-top: 24px;
  }
`;

export const LocationContainer = styled.div({});

export const Name = styled.div`
  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 2px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    color: rgba(255, 255, 255, 1);
  }
`;
export const PropertyName = styled.div`
  @media (max-width: 768px) {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
export const PropertyAddress = styled.div`
  white-space: nowrap;
  @media (max-width: 768px) {
    white-space: inherit;
  }
  @media (min-width: 768px) {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
`;

export const TeamContainer = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    margin-bottom: 16px;
  }
  @media (min-width: 1024px) {
    margin-left: 22px;
  }
`;

export const TagListLabel = styled.div`
  display: inline-block;
  margin-left: 6px;
  color: rgba(255, 255, 255, 0.7);
`;
export const PersonTagListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: flex-end;
  @media (max-width: 1024px) {
    justify-content: flex-start;
    margin-top: 8px;
    & > div:first-child {
      margin-left: 0px;
    }
  }
  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    margin-top: 16px;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const Buttons = styled.div`
  padding-top: 1px;
  margin-right: -15px;
  padding-right: 7px;
`;
export const ActionIcon = styled(({ Icon, ...props }) => <Icon {...props} />)`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px;
`;
export const styledIcon = Icon => styled(Icon)`
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
`;

export const StatusWrapper = styled.span`
  display: flex;
  align-items: center;
  ${TitleTag} {
    &:first-child {
      margin-left: 8px;
    }
  }
`;
