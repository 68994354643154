import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";

import { ContactList, ListItem } from "../components";
import EmptyState from "../../../../../ui/EmptyState";
import VendorItem from "./VendorItem";
import propTypes from "../../../../../../constants/propTypes";
import Loader from "../../../../../ui/Loader/Loader";

const List = ({ vendors, onClick, displayPlaceholder, loading }) => {
  const handleClick = useCallback(vendorId => () => onClick(vendorId), [
    onClick,
  ]);
  return (
    <ListItem button={false}>
      <ContactList>
        <Loader loading={loading} height="60px" count={10}>
          {vendors.length > 0 &&
            vendors.map(vendor => (
              <VendorItem
                key={vendor.id}
                vendor={vendor}
                onClick={handleClick(vendor.id)}
              />
            ))}
          {vendors.length === 0 && displayPlaceholder && !loading && (
            <div>
              <ListItemText>
                <EmptyState>No results</EmptyState>
              </ListItemText>
            </div>
          )}
        </Loader>
      </ContactList>
    </ListItem>
  );
};

List.propTypes = {
  vendors: PropTypes.arrayOf(propTypes.vendor),
  onClick: PropTypes.func.isRequired,
  displayPlaceholder: PropTypes.bool,
  loading: PropTypes.bool,
};

export default List;
