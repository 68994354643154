import React, { useMemo } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";
import _get from "lodash/get";
import pluralize from "pluralize";

import { CustomCell, CustomRow } from "../../../Specs/SpecsPage";
import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import Funding from "../../../../../models/Funding";
import Paper from "../../../../mui/core/Paper";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { columns, getColumnOptions, getRowMenu } from "./tableOptions";

const getTableComponents = memoizeOne(dataComponentId => {
  const detailRowComponents = genDetailRowComponents(
    "id",
    [
      {
        path: "notes",
        label: "Notes",
        placeholder: "Add Notes",
        isEmptyFn: Boolean,
      },
    ],
    BWModels.loadSchema("Funding"),
    dataComponentId,
    { prevSpan: 2, colSpan: 7, posSpan: 2 }
  );
  return {
    ...detailRowComponents,
    CellComponent: CustomCell,
    RowComponent: CustomRow,
  };
});

const FundingsList = ({
  dataComponent,
  projectId,
  onOpenCreateModal,
  onOpenPaidConfirmationModal,
  fundingCount,
  onOpenBatchFundingReport,
}) => {
  const columnOptions = useMemo(() => getColumnOptions(), []);
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Funding}
        apiRoute="fundings"
        includes={[
          "projectBudgetFile",
          "cashflowFile",
          "fundingPayments",
          "projectCurrency",
        ]}
        defaultSorting={[{ columnName: "number", direction: "asc" }]}
        rootFilters={{
          $where: {
            projectId,
          },
        }}
        tableComponents={getTableComponents(dataComponent.dataComponentId)}
        alwaysDisplayDetailRow
        ignoreOptimizations
      >
        <GridHeader
          headerText={`${fundingCount} Funding ${pluralize(
            "Request",
            fundingCount,
            false
          )}`}
          actions={[
            {
              text: "Create funding Request",
              icon: <AddCircle />,
              handler: onOpenCreateModal,
              disableIfProjectClosed: true,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={onOpenPaidConfirmationModal}
          disableRow={row => _get(row, "status") === "Void"}
          rowMenu={getRowMenu({ onOpenBatchFundingReport })}
        />
      </BWGridAPI>
    </Paper>
  );
};
FundingsList.propTypes = {
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenPaidConfirmationModal: PropTypes.func.isRequired,
  onOpenBatchFundingReport: PropTypes.func.isRequired,
  fundingCount: PropTypes.number,
};
export default FundingsList;
