import {
  inputs,
  handleCurrencyInputChange,
} from "../../../../../../components/inputs/inputConfigs";

export const amountRequestedFields = (
  { id, projectId, selectedProjectCurrency, method },
  handleFundingCurrencyChange,
  handleCurrencyValueChange
) => {
  const isDisabled = method == "Batch";
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.projectCurrency,
              isDisabled,
              handleChange: handleFundingCurrencyChange,
              APIOptions: {
                ...inputs.projectCurrency.APIOptions,
                rootFilters: {
                  $where: { projectId },
                },
              },
            },
            grid: { xs: id ? 4 : 6 },
          },
          {
            input: {
              ...inputs.amountRequested,
              disabled: isDisabled,
              inputProps: {
                maskProps: { allowNegative: true },
                currency: selectedProjectCurrency?.currency,
              },
              handleChange: handleCurrencyInputChange(
                handleCurrencyValueChange,
                "amountRequested"
              ),
            },
            grid: { xs: id ? 4 : 6 },
          },
        ],
      },
    ],
  };
};

export const cashflowFileField = ({ id }) => ({
  groups: [
    {
      items: [
        {
          input: id ? inputs.cashflowFileWithDownload : inputs.cashflowFile,
          grid: { xs: id ? 9 : 12 },
        },
      ],
    },
  ],
});

export const projectBudgetFileField = ({ id }) => ({
  groups: [
    {
      items: [
        {
          input: id
            ? inputs.projectBudgetFileWithDownload
            : inputs.projectBudgetFile,
          grid: { xs: id ? 9 : 12 },
        },
      ],
    },
  ],
});

export const noteField = {
  groups: [
    {
      items: [
        {
          input: inputs.notes,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
