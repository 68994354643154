export const handleSuccess = (
  performRetrieveListRequest,
  vendorContactsDataComponentId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog
) => {
  performRetrieveListRequest(vendorContactsDataComponentId);
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleError = (data, showSnackNotificationAction) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};
