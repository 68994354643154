import { inputs } from "../../../../components/inputs/inputConfigs";

const getInputForPricingTrype = type => {
  switch (type) {
    case "Client Budget": {
      return inputs.clientBudgetCents;
    }
    case "Price": {
      return inputs.priceCents;
    }
  }
};

export default type => ({
  groups: [{ items: [{ input: getInputForPricingTrype(type) }] }],
});
