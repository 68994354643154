import styled from "styled-components";
import Remove from "@material-ui/icons/Remove";

export const DashIcon = styled(Remove)`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;
