import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

import VendorColumn from "../../Specs/VendorColumn";
import { Shipments } from "../../PurchaseOrderDetail/ShippingContactSection/Shipments/Table";
import DateColumn from "../../../ui/BWGrid/columns/Date";
import { DashIcon } from "../../../ui/gridTableConfigs/detailPageProjects";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const POPropTypes = {
  status: PropTypes.string.isRequired,
  approvedAt: PropTypes.string,
  followUpDate: PropTypes.string,
  estimatedShipDate: PropTypes.string,
  estimatedDeliveryDate: PropTypes.string,
};

export const EarliestDate = (shipments, fieldName) => {
  if (!shipments) return <DashIcon />;

  const earliestDate = shipments.reduce((previous, currentShipment) => {
    return Date.parse(currentShipment[fieldName]) < Date.parse(previous) ||
      !previous
      ? currentShipment[fieldName]
      : previous;
  }, null);

  if (!earliestDate) return <DashIcon />;

  return <DateColumn date={earliestDate} format={PRIMARY_DATE_FORMAT} />;
};

export const columns = [
  "customNumber",
  "description",
  "purchaseOrder.number",
  "purchaseOrder.vendor.name",
  "unitOfMeasure",
  "totalQuantityWithoutOverage",
  "shipmentsCount",
  "estimatedShipDate",
  "actualShipDate",
];

export const columnOptions = {
  customNumber: { title: "Spec Number", bold: true, filter: true },
  description: { width: "300px", filter: true },
  "purchaseOrder.number": { title: "PO #", filter: true },
  "purchaseOrder.vendor.name": {
    title: "Vendor",
    fill: true,
    render: VendorColumn,
    filter: true,
  },
  unitOfMeasure: { title: "UOM" },
  totalQuantityWithoutOverage: {
    sortingEnabled: false,
    title: "Qty",
  },
  shipmentsCount: { title: "Shipments", align: "left", render: Shipments },
  estimatedShipDate: {
    title: "Estimated Ship Date",
    render: ({ shipments }) => EarliestDate(shipments, "estimatedShipDate"),
    sortingEnabled: false,
  },
  actualShipDate: {
    title: "Actual Ship Date",
    render: ({ shipments }) => EarliestDate(shipments, "actualShipDate"),
    sortingEnabled: false,
  },
};

export const getRootFilters = memoize((projectId, purchaseOrderId) => {
  if (purchaseOrderId) {
    return {
      $where: {
        purchaseOrderId,
      },
    };
  }

  return {
    $where: {
      projectId,
      "purchaseOrder.status": "Issued",
    },
  };
});
