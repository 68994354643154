import React from "react";

import propTypes from "../../../../../constants/propTypes";
import Loader from "../../../../ui/Loader";
import LocationCombined from "../../../../ui/LocationCombined";
import { Title } from "../../../../ui/Typography/SectionTitle";

import { ContactNameText, ItemPaperWrapper, SubheaderText } from "./components";
import { getContactAttribute } from "./ProjectOverview";

export const PurchasingAgentSection = ({ project, projectLead }) => {
  return (
    <ItemPaperWrapper>
      <Title>Purchasing Agent</Title>
      <SubheaderText>
        <Loader width="200px" height="20px">
          {project.office.name}
        </Loader>
      </SubheaderText>
      <Loader width="200px" height="20px">
        <LocationCombined
          location={project.office.location}
          ignoreZip={false}
        />
      </Loader>
      <SubheaderText>Contacts</SubheaderText>
      <Loader width="200px" height="20px">
        {projectLead && (
          <div>
            <ContactNameText>{`${projectLead.firstName} ${projectLead.lastName}`}</ContactNameText>
            <span number={projectLead.phoneNumber} />
            {getContactAttribute(projectLead, "fax")}
            {getContactAttribute(projectLead, "email")}
          </div>
        )}
      </Loader>
    </ItemPaperWrapper>
  );
};

PurchasingAgentSection.propTypes = {
  project: propTypes.project,
  projectLead: propTypes.user,
};
