import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import createGeneralInfoFields from "./createGeneralInfoFields";
import propTypes from "../../../../constants/propTypes";
import { withLabeledTextOption } from "../../../../withPORevision";
import Vendor from "../../../../models/Vendor";
import { FormPageSection } from "../../../layout/FormPageSection";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const DetailsSection = ({
  purchaseOrder,
  freightVendor,
  projectId,
  projectCurrencies,
  ...formikProps
}) => {
  const currency = purchaseOrder?.projectCurrency?.currency;
  const currencyFormatter = useCurrencyFormatter(currency);
  const isProjectClosed = useIsProjectClosed();

  return (
    <FormPageSection
      fields={createGeneralInfoFields(
        freightVendor,
        purchaseOrder,
        formikProps.values,
        currencyFormatter,
        projectId,
        projectCurrencies,
        isProjectClosed,
        formikProps.setFieldValue
      )}
      headerText="Purchase Order Details"
      {...formikProps}
    />
  );
};

DetailsSection.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  projectId: PropTypes.string,
  freightVendor: propTypes.vendor,
};

export const getFreightVendor = (project, resources) => {
  if (!project) {
    return null;
  }
  if (project.freightVendor) {
    return project.freightVendor;
  }
  const freightVendorId = _get(project, "freightVendorId");
  if (!freightVendorId) return null;
  return Vendor.query(resources).find(project.freightVendorId.toString());
};

const mapStateToProps = ({ resources }, { purchaseOrder }) => {
  const project = purchaseOrder.project;
  return {
    freightVendor: getFreightVendor(project, resources),
  };
};

export default withLabeledTextOption(connect(mapStateToProps)(DetailsSection));
