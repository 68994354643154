import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { FormikForm, FormGrid, ActionButtons } from "../../../forms";
import { Paper } from "../../../mui/core";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader";
import inputs from "../../../inputs/inputConfigs/login";
import { useCreateProcessRequests } from "../../../hooks/useProcessRequest";
import { codeToErrorInfoMap } from "../../../../utils/formValidationUtils";
import gridSchema from "./gridSchema";
import styled from "styled-components";

const Title = styled.div`
  font-family: Domine, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
`;
const SubTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;
const TitleWrapper = styled.div`
  padding: 24px 24px 0px 24px;
  margin-bottom: 8px;
`;

const createForgotPasswordFields = () => ({
  groups: [
    {
      items: [
        {
          input: inputs.oldPassword,
          grid: { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.password, label: "New Password" },
          grid: { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 },
        },
        {
          grid: { xs: 12 },
        },
        {
          input: inputs.repeatPassword,
          grid: { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 },
        },
      ],
    },
  ],
});

const UpdatePassword = ({ actions, dataComponent }) => {
  const [formik, setFormik] = useState();

  const handleSubmit = ({ passwordConfirmation, ...values }, formik) => {
    setFormik(formik);
    actions.resetPassword(values);
  };

  const loading = useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      formik.resetForm();
      actions.showSnackNotificationAction("Password was updated successfully");
    },
    onError: payload => {
      const errorCode = _get(payload, "data.errors.0.code", "noInfo");
      actions.showSnackNotificationAction(codeToErrorInfoMap[errorCode].title);
    },
  });

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Paper>
        <TitleWrapper>
          <Title>Account</Title>
          <SubTitle>Change Password</SubTitle>
        </TitleWrapper>
        <FormikForm onSubmit={handleSubmit} validationSchema={gridSchema}>
          {({ handleSubmit, values, errors, ...formikProps }) => (
            <Fragment>
              <FormGrid
                fields={createForgotPasswordFields()}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <ActionButtons
                hideCancelButton
                isModal
                onSend={handleSubmit}
                sendButtonText={"Change password"}
              />
            </Fragment>
          )}
        </FormikForm>
      </Paper>
    </LoaderContext.Provider>
  );
};

UpdatePassword.propTypes = {
  dataComponent: propTypes.dataComponent,
  actions: PropTypes.shape({
    resetForm: PropTypes.func,
    showSnackNotificationAction: PropTypes.func,
  }),
};

export default UpdatePassword;
