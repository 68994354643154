import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import Date from "../../../../ui/BWGrid/columns/Date";
import OptionalColumn from "../../../../ui/BWGrid/columns/OptionalColumn";
import EditableColumn, {
  StyledEdit,
} from "../../../../ui/BWGrid/columns/EditableColumn";
import TagText from "../../../../ui/Tags/TagText";
import Invoice from "../../../../../models/Invoice";
import Paper from "../../../../mui/core/Paper";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCellAPI";
import inputs from "../../../../inputs/inputConfigs/invoice";
import Price from "../../../../ui/Price";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import lazyCellCreator from "../../../../ui/BWGrid/LazyLoaderCellAPI";
import { POStatusBadgeWrapper } from "../../../../inputs/LabeledPONumber";
import BoldText from "../../../../ui/Typography/BoldText";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

export const OkToIssue = ({ okToIssue, isVoid }) => {
  if (!okToIssue && !isVoid) return "";
  return isVoid ? (
    <TagText intent="danger">Void</TagText>
  ) : (
    <TagText intent="success">Yes</TagText>
  );
};

OkToIssue.propTypes = {
  okToIssue: PropTypes.bool,
  isVoid: PropTypes.bool,
};

export const CheckNumber = ({ checkNumber, id, okToIssue, purchaseOrder }) => {
  if (id && okToIssue && !purchaseOrder.revisionStatus) {
    return (
      <EditableColumn placeholder="Add Check #">{checkNumber}</EditableColumn>
    );
  }
  return checkNumber ? checkNumber : "-";
};
CheckNumber.propTypes = {
  checkNumber: PropTypes.string,
  id: PropTypes.number,
  okToIssue: PropTypes.bool,
};

export const PurchaseOrderNumber = ({
  purchaseOrder: { number, revisionStatus } = {},
}) => {
  return (
    <POStatusBadgeWrapper revisionStatus={revisionStatus} badgeSize={20}>
      <BoldText>{number}</BoldText>
    </POStatusBadgeWrapper>
  );
};
PurchaseOrderNumber.propTypes = {
  purchaseOrder: PropTypes.object,
};

const columns = [
  "okToIssue",
  "number",
  "purchaseOrder.number",
  "invoicedDate",
  "checkRequest",
  "purchaseOrder.vendor.name",
  "amount",
  "dueDate",
  "checkNumber",
  "paymentDate",
];
export const columnOptions = {
  okToIssue: {
    title: "Ok to Issue",
    bold: true,
    render: OkToIssue,
    filter: "select",
    filterOptions: {
      operator: "equal",
      options: [
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ],
    },
  },
  number: { title: "Invoice #", filter: true, bold: true },
  "purchaseOrder.number": {
    title: "PO #",
    filter: true,
    bold: true,
    render: PurchaseOrderNumber,
  },
  invoicedDate: {
    render: ({ id, invoicedDate }) =>
      id && <Date date={invoicedDate} format={PRIMARY_DATE_FORMAT} />,
  },
  checkRequest: {
    title: "CR #",
    filter: true,
    bold: true,
    render: ({ id, checkRequest }) =>
      id && <OptionalColumn>{checkRequest}</OptionalColumn>,
  },
  "purchaseOrder.vendor.name": { title: "Vendor", filter: true, fill: true },
  amount: {
    title: "Amount",
    sortingEnabled: false,
    render: ({ id, amount, purchaseOrder }) => {
      const { currency } = purchaseOrder?.projectCurrency || {};
      return id && <Price number={amount} currency={currency} />;
    },
  },
  dueDate: {
    title: "Due Date",
    render: ({ id, dueDate }) =>
      id && <Date date={dueDate} format={PRIMARY_DATE_FORMAT} />,
  },
  checkNumber: {
    title: "Check #",
    editable: true,
    render: CheckNumber,
  },
  paymentDate: {
    title: "Payment Date",
    filter: "picker",
    render: ({ id, paymentDate }) =>
      id && <Date date={paymentDate} format={PRIMARY_DATE_FORMAT} />,
  },
};

const actions = onOpenCreateModal => [
  {
    text: "ADD VENDOR INVOICE",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
    disableIfProjectClosed: true,
  },
];

const rowInputs = {
  checkNumber: {
    ...inputs.checkNumber,
    label: undefined,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};

export const lazyOptions = {
  amount: {
    isLazy: () => true,
    requestType: RequestTypes.LIST,
    cleanRequestState: ({
      list: { params, pageNumber, ...list },
      ...requestState
    }) => ({
      list: { ...list, loading: false, success: false, reload: false },
      ...requestState,
    }),
  },
};

export const isEditable = (column, tableRow) => {
  return (
    tableRow.row.okToIssue &&
    column.name === "checkNumber" &&
    !tableRow.row.purchaseOrder.revisionStatus &&
    !tableRow.row.isVoid
  );
};

export const isVoidDisabled = ({
  isVoid,
  checkNumber,
  invoiceSpecs = [],
  purchaseOrder,
}) => {
  return (
    isVoid ||
    checkNumber ||
    invoiceSpecs.some(({ quantity }) => quantity != 0) ||
    !!purchaseOrder.revisionStatus
  );
};

export const getDisableInvoiceOption = (
  invoice,
  onOpenVoidInvoiceModal,
  onOpenDeleteInvoiceModal
) => {
  return !isVoidDisabled(invoice) && !invoice.okToIssue
    ? {
        text: "Delete",
        onClick: () => onOpenDeleteInvoiceModal(invoice),
        disableIfProjectClosed: true,
      }
    : {
        text: "Void",
        onClick: () => onOpenVoidInvoiceModal(invoice),
        disabled: isVoidDisabled(invoice),
        disableIfProjectClosed: true,
      };
};

const InvoicesList = ({
  dataComponent,
  projectId,
  onOpenCreateModal,
  onRowClick,
  onDownloadCheckRequestLog,
  onOpenVoidInvoiceModal,
  onOpenDeleteInvoiceModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Invoice}
        apiRoute="invoices"
        includes={[
          "purchaseOrder.[vendor,projectCurrency.currency]",
          "invoiceSpecs",
        ]}
        defaultSorting={[{ columnName: "checkRequest", direction: "asc" }]}
        apiFilters={{
          rootFilters: {
            $where: {
              "purchaseOrder.projectId": projectId,
            },
          },
          params: { omitTotals: true },
        }}
        tableComponents={{
          CellComponent: lazyCellCreator({
            FallbackCellComponent: editableCellCreator(
              rowInputs,
              dataComponent.dataComponentId,
              {},
              (column, tableRow) => isEditable(column, tableRow)
            ),
            parentDataComponent: dataComponent,
            waiting: dataComponent.loading,
            lazyOptions,
          }),
        }}
        lazyOptions={lazyOptions}
      >
        <GridHeader
          headerText={pluralize("Invoice", dataComponent.totalRows, true)}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={onRowClick}
          rowMenu={row => [
            {
              text: "Preview Check Request",
              onClick: invoice => onDownloadCheckRequestLog(invoice, true),
            },
            {
              text: "Print Check Request",
              onClick: invoice => onDownloadCheckRequestLog(invoice, false),
            },
            { separator: true },
            getDisableInvoiceOption(
              row,
              onOpenVoidInvoiceModal,
              onOpenDeleteInvoiceModal
            ),
          ]}
        />
      </BWGridAPI>
    </Paper>
  );
};
InvoicesList.propTypes = {
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onDownloadCheckRequestLog: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenVoidInvoiceModal: PropTypes.func.isRequired,
  onOpenDeleteInvoiceModal: PropTypes.func.isRequired,
};
export default InvoicesList;
