import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import PONotesSection from "./PONotesSection";
import { datacomponentCommoditiesId } from "./createPONotesFields";
import Commodity from "../../../../models/Commodity";
import { withLabeledTextOption } from "../../../../withPORevision";

const PONotesContainer = ({
  initDataComponent,
  performRetrieveListRequest,
  values,
}) => {
  useEffect(() => {
    initDataComponent(
      datacomponentCommoditiesId,
      Commodity,
      [],
      "commodities",
      false,
      "v2"
    );
    performRetrieveListRequest(datacomponentCommoditiesId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
    });
  }, [initDataComponent, performRetrieveListRequest]);

  return <PONotesSection values={values} />;
};

PONotesContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
};

export default withLabeledTextOption(
  connect(null, { initDataComponent, performRetrieveListRequest })(
    PONotesContainer
  )
);
