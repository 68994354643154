import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import * as QUEUE_STATUSES from "../../../../constants/queue";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";

import {
  initQueueProcess,
  createQueueProcess,
} from "../../../../actions/queuesActions";

import {
  destroyDataComponent,
  destroyDataComponentResource,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import {
  openImportSpecOptionsModal,
  importSpecs,
  uploadSpecsFromXls,
  fetchUnitOfMeasures,
  fetchVendors,
  unitOfMeasureDataComponentId,
} from "../../../../actions/specsActions";
import { fetchAreasByProject } from "../../../../actions/areasActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import ImportSpecs, {
  queueId,
  dataComponentId,
  queueCreateSpecsId,
  specCategoryDataComponentId,
} from "./ImportSpecs";
import {
  fetchProjectCurrencies,
  projectCurrenciesDataComponentId,
} from "../../../../actions/currencyActions";
import { fetchPurchaseOrder } from "../../../../actions/purchaseOrdersActions";

export const getResources = (dataComponentId, state) => {
  return getBluechipResources(
    getDataComponentFlattenedRequestState(dataComponentId, state),
    state
  );
};

export const mapStateToProps = (state, { project, areaId }) => {
  const { queues, auth } = state;
  const queue = queues[queueId];
  const bulkQueue = queues[queueCreateSpecsId];
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const specCategoryDataComponent = getDataComponentFlattenedRequestState(
    specCategoryDataComponentId,
    state
  );

  const isLoading =
    _get(flattenedDataComponent, "loading", false) ||
    _get(specCategoryDataComponent, "loading", false) ||
    _get(queue, "status", false) === QUEUE_STATUSES.QUEUE_PROCESSING ||
    _get(bulkQueue, "status", false) === QUEUE_STATUSES.QUEUE_PROCESSING;

  const projectCurrencies = getResources(
    projectCurrenciesDataComponentId,
    state
  );

  const defaultProjectCurrencyId = projectCurrencies?.find(
    projectCurrency => projectCurrency.isDefault
  )?.id;

  return {
    defaultAreaId: areaId,
    areas: getResources("select-areas", state),
    categories: getResources("select-specCategories", state),
    vendors: getResources("select-vendors", state),
    unitOfMeasures: getResources(unitOfMeasureDataComponentId, state),
    projectId: project.id,
    project: project,
    bulkQueue,
    isLoading,
    queue,
    specs: getResources(dataComponentId, state),
    flattenedDataComponent,
    defaultProjectCurrencyId,
    projectCurrencies,
    userId: auth.userId,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeModalDialog,
      initDataComponent,
      performCreateRequest,
      performRetrieveListRequest,
      showSnackNotificationAction,
      createQueueProcess,
      initQueueProcess,
      importSpecs,
      fetchPurchaseOrder,
      openImportSpecOptionsModal,
      uploadSpecsFromXls,
      fetchAreasByProject,
      fetchUnitOfMeasures,
      fetchVendors,
      setReload,
      fetchProjectCurrencies,
      destroyDataComponent,
      destroyDataComponentResource,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportSpecs);
