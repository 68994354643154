import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

const reducerMap = {
  [actionTypes.STATUS_REQUEST]: (state, payload) => ({
    ...state,
    [payload.service]: {
      loading: true,
      success: false,
      error: false,
    },
  }),
  [actionTypes.STATUS_SUCCESS]: (state, payload) => ({
    ...state,
    [payload.service]: {
      loading: false,
      success: true,
      error: false,
      data: payload.data,
    },
  }),
  [actionTypes.STATUS_FAILURE]: (state, payload) => ({
    ...state,
    [payload.service]: {
      loading: false,
      success: false,
      error: payload.error,
    },
  }),
  default: state => state,
};

export default function(state = initialState.status, action) {
  const { type, payload } = action;
  return (reducerMap[type] || reducerMap.default)(state, payload);
}
