import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import DirectionsIcon from "@material-ui/icons/Directions";
import FilterListIcon from "@material-ui/icons/FilterList";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Loader from "../../ui/Loader/Loader";

const categoryIcons = {
  "Create Note": NoteAddIcon,
  All: FilterListIcon,
  General: AssignmentIcon,
  Email: EmailIcon,
  "Phone Call": PhoneIcon,
  Meeting: TurnedInIcon,
  Tracking: DirectionsIcon,
};

export const IconWrapper = styled(({ isHighlighted, ...props }) => (
  <span {...props} />
))`
  display: inline-block;
  height: 24px;
`;

const StyledIcon = styled(({ isHighlighted, Icon, ...props }) => (
  <Icon {...props} />
))`
  color: ${({ isHighlighted }) => (isHighlighted ? "#57abff" : null)};
  width: 24px;
  height: 24px;
`;

const NoteIcon = ({ category, isHighlighted }) => {
  const Icon = categoryIcons[category];
  return (
    <Loader height="24px" width="24px">
      <IconWrapper>
        {Icon && <StyledIcon isHighlighted={isHighlighted} Icon={Icon} />}
      </IconWrapper>
    </Loader>
  );
};

NoteIcon.propTypes = {
  category: PropTypes.oneOf(Object.keys(categoryIcons)),
  isHighlighted: PropTypes.bool,
};

export default NoteIcon;
