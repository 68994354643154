import React from "react";
import PropTypes from "prop-types";

import PagingPanel from "../../../BWGrid/PagingPanel";

const GridPagingPanel = ({
  totalRows,
  hidePaginationControl,
  rowsPerPageOptions,
}) => {
  const hasEnoughRowsForPagination = totalRows > 10;
  return (
    <PagingPanel
      display={hasEnoughRowsForPagination}
      rowsPerPageOptions={!hidePaginationControl && rowsPerPageOptions}
    />
  );
};

GridPagingPanel.defaultProps = {
  rowsPerPageOptions: [10, 25, 50, 100],
  hidePaginationControl: false,
  totalRows: 0,
};

GridPagingPanel.propTypes = {
  totalRows: PropTypes.number,
  hidePaginationControl: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  pageSizes: PropTypes.number,
};

export default GridPagingPanel;
