import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";
import { useSelectedRowAPIContext } from "../../../ui/BWGrid/withSelectedRowsAPI";
import useActions from "../../../hooks/useActions";
import { openModalDialog } from "../../../../actions/layoutActions";

export const GridActions = ({ dataComponentId }) => {
  const { selectedRows } = useSelectedRowAPIContext();
  const actions = useActions({ openModalDialog });

  const handleDeleteClick = () => {
    actions.openModalDialog(
      `Remove Spec(s) - ${pluralize("spec", selectedRows.length, true)}`,
      "BulkDeletePOSpecs",
      {
        specs: selectedRows,
        dataComponentId,
      },
      false,
      false,
      {
        width: "650px",
      }
    );
  };

  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: `Remove ${pluralize("Spec", selectedRows.length)}`,
          onClick: handleDeleteClick,
          disabled: !selectedRows.length,
          disableIfProjectClosed: true,
        },
      ]}
    />
  );
};
GridActions.propTypes = {
  dataComponentId: PropTypes.string.isRequired,
};
