import { inputs } from "../../../../inputs/inputConfigs";

export default (scopedCurrency, onChangeUnit) => {
  const contingencyUnit = {
    ...inputs.contingencyUnit,
    options: inputs.contingencyUnit.options.map(({ id, name }) => ({
      id,
      name: name.replace("$", scopedCurrency.symbol),
    })),
  };

  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.baseName,
              label: "Name",
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.contingencyType,
              label: "Type",
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...contingencyUnit,
              handleChange: onChangeUnit,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.contingencyValue,
              required: true,
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
