import { inputs } from "../../../inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        { input: inputs.project, grid: { xs: 12 } },
        { input: inputs.noteTime, grid: { xs: 12 } },
        { input: inputs.noteTrackingNumber, grid: { xs: 12 } },
        { input: inputs.noteCarrierName, grid: { xs: 12 } },
        { input: inputs.noteHeadline, grid: { xs: 12 } },
        {
          input: {
            ...inputs.noteDetail,
            label: "Shipment Notes",
            required: true,
          },
          grid: { xs: 12 },
        },
        { input: inputs.noteAssignTo, grid: { xs: 12 } },
        { input: inputs.tags, grid: { xs: 12 } },
      ],
    },
  ],
};
