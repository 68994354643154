import React, { Fragment } from "react";
import TableRowMUI from "@material-ui/core/TableRow";

const HeadRow = ({ tableRow, children, ...props }) => {
  return (
    <Fragment>
      <TableRowMUI {...props}>{children}</TableRowMUI>
    </Fragment>
  );
};
export default HeadRow;
