import React from "react";

import { inputs } from "../../inputs/inputConfigs";
import { QuantityError } from "../Specs/QuantityError";

export default (
  handleBaseQuantityChange,
  handleAtticStockChange,
  handleOverageChange,
  isAnyShipmentApprovedToPay,
  isLabeled,
  quantityErrorMessage
) => {
  return {
    groups: [
      {
        items: [
          {
            input: { ...inputs.unitOfMeasure, isDisabled: isLabeled },
          },
          {
            input: {
              ...inputs.baseQuantity,
              delay: 2000,
              handleChange: handleBaseQuantityChange,
            },
          },
          {
            input: {
              ...inputs.atticStock,
              delay: 2000,
              handleChange: handleAtticStockChange,
            },
          },
          {
            input: {
              ...inputs.overagePercent,
              delay: 2000,
              handleChange: handleOverageChange,
            },
          },
          {
            input: {
              ...inputs.overageValue,
            },
          },
          {
            input: {
              ...inputs.totalQuantity,
            },
          },
          {
            element: <QuantityError errorMessage={quantityErrorMessage} />,
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
