import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  performFindRequest,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import Specs from "./Specs";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";

import { dataComponentId } from "../../ProjectDetail/ProjectDetailContainer";

export const mapStateToProps = (state, { projectId }) => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    project: getBluechipResourceById(dataComponent, state, projectId) || {},
  };
};

// Review note: This should be removed in future PRs after BW-3544 get merge.
// TODO: Remove this actions and calls to it inside the Specs component once BW-3544
export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { performFindRequest, initDataComponent },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Specs);
