import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import SerifTitle from "../../../ui/Typography/SerifTitle";

const Text = styled(SerifTitle)`
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 4px;
`;

const Breadcrumb = styled(Text)`
  cursor: ${({ onClick }) => (onClick ? "pointer" : null)};
  &:hover {
    background-color: ${({ onClick }) => (onClick ? "#E0E0E0" : null)};
  }
`;

const Separator = () => <Text>/</Text>;

const Wrapper = styled.div`
  margin-left: 12px;
  display: flex;
`;

const Breadcrumbs = ({ breadcrumbs, initNotesModal }) => {
  const lastIndex = breadcrumbs.length - 1;
  return (
    <Wrapper>
      {breadcrumbs.map((resource, key) => {
        const handleClick = resource.clickParams
          ? () =>
              initNotesModal(
                resource.clickParams.context,
                resource.clickParams.filters
              )
          : null;
        return (
          <Fragment key={key}>
            <Breadcrumb onClick={handleClick}>{resource.label}</Breadcrumb>
            {key !== lastIndex && <Separator />}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};
Breadcrumbs.propTypes = {
  breadcrumbs: propTypes.breadcrumbs,
  initNotesModal: PropTypes.func.isRequired,
};

export default Breadcrumbs;
