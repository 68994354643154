import { inputs } from "../../../../../inputs/inputConfigs";

export default setPurchaseOrderId => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.purchaseOrderId,
              handleChange: event => {
                setPurchaseOrderId(event.target.value);
              },
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
