import { BaseModel } from "@blue-chip/core";
import VendorCategory from "./VendorCategory";
import Location from "./Location";
import Discount from "./Discount";
import VendorContact from "./VendorContact";
import BidGroupVendor from "./BidGroupVendor";

export default class Vendor extends BaseModel {
  static get belongsTo() {
    return [BidGroupVendor, VendorCategory, Vendor, Location];
  }
  static get hasMany() {
    return [VendorContact, Discount];
  }

  static isVendorDisabled(vendor) {
    return vendor?.status?.includes("locked");
  }

  static labelProperty(vendor) {
    if (!vendor.name) {
      return "";
    }

    return `${vendor.name}${
      vendor?.status?.includes("locked") ? " (Locked)" : ""
    }`;
  }
}
