import React, { Fragment, useCallback, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PrimaryActionButton from "../../../forms/ActionButtons/PrimaryActionButton";
import {
  openModalDialog,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import { Field } from "../../../forms/FormGrid";
import { LoaderContext } from "../../../ui/Loader";
import { FormHelperText } from "@material-ui/core";

const StyledButton = styled(PrimaryActionButton)`
  width: 143px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const ErrorMessage = styled(FormHelperText)`
  margin-top: 3px;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #f44336;
`;

const Button = ({ disabled, okToIssueError, updateInvoice }) => {
  const { loading } = useContext(LoaderContext);

  const handleClick = useCallback(() => {
    updateInvoice({
      okToIssue: true,
    });
  }, [updateInvoice]);

  return loading ? null : (
    <Fragment>
      <StyledButton onClick={handleClick} disabled={disabled}>
        Ok to Issue
      </StyledButton>
      <ErrorMessage>{okToIssueError}</ErrorMessage>
    </Fragment>
  );
};

Button.propTypes = {
  onChange: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  okToIssueError: PropTypes.string,
  form: PropTypes.shape({
    values: PropTypes.shape({}),
  }),
  updateInvoice: PropTypes.func.isRequired,
};

const ConnectedButton = connect(null, { openModalDialog, closeModalDialog })(
  Button
);

const OkToIssueButton = ({ disabled, okToIssueError, updateInvoice }) => {
  const name = "okToIssue";
  return (
    <Field name={name}>
      {({ field, form }) => (
        <ConnectedButton
          {...field}
          form={form}
          disabled={disabled}
          okToIssueError={okToIssueError}
          updateInvoice={updateInvoice}
        />
      )}
    </Field>
  );
};

export default OkToIssueButton;
