import React from "react";
import styled from "styled-components";
import { components } from "react-select";
import _get from "lodash/get";

import Subheader from "../../../ui/Typography/Subheader";
import PropTypes from "prop-types";

const SubHeaderWrapper = ({ children, className }) => (
  <Subheader className={className}>{children}</Subheader>
);
SubHeaderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export const NoOptionsMessage = styled(SubHeaderWrapper)`
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.54);
`;

const PlaceholderWrapper = styled.span`
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

export const Placeholder = props => {
  const placeholder = _get(props, "selectProps.placeholder", "All");
  return <PlaceholderWrapper>{placeholder}</PlaceholderWrapper>;
};

export const Input = styled(components.Input)`
  margin-left: -2px;
  height: 24px;
`;

export const ClearIndicator = styled(components.ClearIndicator)`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 0 !important;
`;

export const ValueContainer = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  overflow: hidden;
`;

export const IndicatorSeparator = () => null;
IndicatorSeparator.propTypes = {};

export const DropdownIndicator = () => null;
DropdownIndicator.propTypes = {};
