import React, { createContext, useMemo, useState } from "react";

export const QuoteViewByContext = createContext();

const withQuoteViewByControl = WrappedComponent => {
  const Component = props => {
    const [currentView, setCurrentView] = useState("Vendors");

    const handleCurrentViewChanged = view => {
      setCurrentView(view);
    };
    const value = useMemo(() => {
      return {
        currentView,
        handleCurrentViewChanged,
      };
    }, [currentView]);

    return (
      <QuoteViewByContext.Provider value={value}>
        <WrappedComponent {...props} />
      </QuoteViewByContext.Provider>
    );
  };
  Component.propTypes = {};

  return Component;
};

export default withQuoteViewByControl;
