import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import _get from "lodash/get";

import Subheader from "../../../ui/Typography/Subheader";
import styled from "styled-components";

const Item = styled.div`
  color: rgba(0, 0, 0, 0.87);
  width: auto;
  height: 24px;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: content-box;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  min-height: 16px;
  line-height: 1.5em;
  white-space: nowrap;
  padding: 11px 16px;
  ${Subheader} {
    color: ${({ isDisabled }) => isDisabled && "rgba(0, 0, 0, 0.54)"};
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: rgba(0, 0, 0, 0.14);
  `}
  ${({ isFocused }) =>
    isFocused &&
    `
    background-color: rgba(0, 0, 0, 0.08);
  `}
  border-top: ${({ useSeparator }) =>
    useSeparator && "1px solid rgba(0, 0, 0, 0.12)"};
`;

const Option = ({
  isFocused,
  innerProps,
  label,
  data = {},
  innerRef,
  isDisabled,
  isSelected,
  className,
}) => {
  const menuItemProps = _get(data, "menuItemProps", {});
  return (
    <Fragment>
      <Item
        className={className}
        selected={isFocused}
        component="div"
        ref={innerRef}
        isDisabled={isDisabled}
        isFocused={!isDisabled && isFocused}
        isSelected={isSelected}
        {...innerProps}
        {...menuItemProps}
      >
        <Subheader>{label}</Subheader>
      </Item>
      {data.separator ? <Divider /> : null}
    </Fragment>
  );
};

Option.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  innerProps: PropTypes.shape({}).isRequired,
  isFocused: PropTypes.bool,
};

export default Option;
