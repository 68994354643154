export const shipmentsNeedsFollowUpColumns = [
  "spec.purchaseOrder.number",
  "spec.customNumber",
  "spec.description",
  "spec.purchaseOrder.vendor.name",
  "id",
  "quantity",
  "estimatedShipDate",
  "estimatedDeliveryDate",
  "actualShipDate",
  "actualDeliveryDate",
  "needsFollowUp",
];
export const posNeedingAttentionColumns = [
  "number",
  "vendor.name",
  "status",
  "estimatedShipDate",
  "estimatedDeliveryDate",
];
