import React, { Component } from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./components";
import { columns, columnOptions } from "./gridProps";
import { BWGridAPI, GridHeader, GridRow, GridTable } from "../../../ui/BWGrid";
import { FormControlLabel, Switch } from "../../../mui/core";
import { ExpeditingFiltersContext } from "../Expediting/ExpeditingFiltersContext";

export const rowMenu = (onDownloadPO, cancelPurchaseOrder) => row => {
  const options = [
    { text: "Download as PDF", onClick: () => onDownloadPO(row, "pdf") },
  ];
  if (row.status === "Issued") {
    options.push({
      text: "Cancel Issue",
      onClick: () => cancelPurchaseOrder(row),
      disableIfProjectClosed: true,
    });
  }
  return options;
};
const filterBySettings = {
  active: {
    $notEqual: "Canceled",
  },
};

export class PurchaseOrderPage extends Component {
  state = {
    filterBy: "active",
  };

  static contextType = ExpeditingFiltersContext;

  handleRowClick = po => {
    const { projectId, clientId } = this.props;
    this.props.push(
      `/clients/${clientId}/projects/${projectId}/purchase-orders/${po.id}`
    );
  };

  handleFilterByToggle = () => {
    this.setState({
      filterBy: this.state.filterBy === "active" ? "all" : "active",
    });
  };

  render() {
    const {
      dataComponent,
      onDownloadPO,
      onCancelPurchaseOrder,
      apiFilters,
      rfqs,
    } = this.props;
    const { filterBy } = this.state;
    const filter = {
      ...apiFilters,
      rootFilters: {
        ...apiFilters.rootFilters,
        $where: {
          ...apiFilters.rootFilters.$where,
          status: filterBySettings[filterBy],
          ...this.context.filters,
        },
      },
    };
    return (
      <Wrapper>
        <BWGridAPI {...this.props} apiFilters={filter} ignoreOptimizations>
          <GridHeader
            headerText={`${dataComponent.totalRows} Purchase Orders`}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions(rfqs)}
            rowMenu={rowMenu(onDownloadPO, onCancelPurchaseOrder)}
            onClick={this.handleRowClick}
          />
          <GridRow>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.filterBy === "all"}
                  onChange={this.handleFilterByToggle}
                  color="primary"
                />
              }
              label="Show Canceled Purchase Orders"
            />
          </GridRow>
        </BWGridAPI>
      </Wrapper>
    );
  }
}

PurchaseOrderPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  apiFilters: PropTypes.shape({}),
  onCancelPurchaseOrder: PropTypes.func.isRequired,
  rfqs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};
export default PurchaseOrderPage;
