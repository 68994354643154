import {
  initDataComponent,
  performCreateRequest,
  performUpdateRequest,
  performRetrieveListRequest,
} from "./dataComponentActions";
import RemitAddress from "../models/RemitAddress";
import VendorContact from "../models/VendorContact";

export const CreateDataComponentId = "CreateRemitAddress";

export const POCDataComponentId = "RemitAddressPointOfContact";

export function createRemitAddressDataComponent() {
  return initDataComponent(
    CreateDataComponentId,
    RemitAddress,
    ["location"],
    "remit-addresses",
    true,
    "v2"
  );
}

export function createRemitAddress(remitAddress) {
  return performCreateRequest(CreateDataComponentId, remitAddress);
}

export function updateRemitAddress(remitAddress) {
  return performUpdateRequest(
    CreateDataComponentId,
    remitAddress.id,
    remitAddress
  );
}

export function initPointOfContactComponent(vendorId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        POCDataComponentId,
        VendorContact,
        ["contact"],
        "vendor-contacts",
        false,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(POCDataComponentId, {
        rootFilters: {
          $where: {
            vendorId,
          },
        },
        sort: [{ columnName: "contact.firstName", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
}
