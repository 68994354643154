import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import officeFields from "./officeFields";
import FormGrid from "../../../../components/forms/FormGrid";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import propTypes from "../../../../constants/propTypes";

const OfficeForm = ({ onSubmit, initialValues, dataComponentId, role }) => {
  const ignoreValidationFieldNames =
    role.name !== "Super Admin" ? ["scopeId"] : [];
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("Office")}
      ignoreValidationFieldNames={ignoreValidationFieldNames}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={officeFields(role)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

OfficeForm.defaultProps = {
  initialValues: {
    name: "",
    location: {
      address: undefined,
      address2: undefined,
      city: undefined,
      zip: undefined,
      country: undefined,
      state: undefined,
    },
  },
};

OfficeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.office,
  dataComponentId: PropTypes.string.isRequired,
  role: propTypes.userRole,
};

export default OfficeForm;
