import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { ClickAwayListener, IconButton } from "@material-ui/core";

import ResultList from "./ResultList";
import { StyledInput, SearchIcon, StyledTooltip } from "./components";
import useDebounce from "../../../pages/Notes/NoteCreate/InputWithMention/useDebounce";
import propTypes from "../../../../constants/propTypes";

const menuFocusKeyCodes = [40];
const hasQueryChange = (query, debounceQuery, showInput) =>
  query && query === debounceQuery && showInput;

const reloadResults = (query, debounceQuery, showInput, search) => {
  if (hasQueryChange(query, debounceQuery, showInput)) {
    search(query);
  }
};

const buildInputProps = focusMenu => () => ({
  onKeyDown: evt => {
    if (menuFocusKeyCodes.includes(evt.keyCode)) focusMenu(evt);
  },
});

const buildToggleSearchInput = ({
  setShowInput,
  showInput,
  clearInput,
  setShowResult,
  currentRef,
}) => () => {
  if (!showInput) clearInput();
  if (showInput) setShowResult(false);
  setShowInput(!showInput);
  setTimeout(() => {
    if (!showInput) setShowResult(true);
    currentRef.focus();
  }, 500);
};

const SearchOption = ({
  search,
  initSearch,
  results,
  pushWithReturnUrl,
  dataComponent,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const debounceQuery = useDebounce(query, 400);
  const inputEl = useRef(null);
  const menuEl = useRef(null);
  const currentRef = _get(inputEl, "current");
  const searchLoading = _get(dataComponent, "loading", false);

  useEffect(() => {
    setLoading(searchLoading);
  }, [searchLoading]);

  useEffect(() => {
    initSearch();
  }, [initSearch]);

  const clearInput = useCallback(() => {
    setQuery("");
    currentRef.value = "";
  }, [currentRef]);

  const toggleSearchInput = useCallback(
    buildToggleSearchInput({
      setShowInput,
      showInput,
      clearInput,
      setShowResult,
      currentRef,
    }),
    [clearInput, currentRef, showInput]
  );

  useEffect(() => {
    setLoading(!_isEmpty(query));
    reloadResults(query, debounceQuery, showInput, search);
  }, [debounceQuery, query, search, showInput]);

  const handleChange = useCallback(event => {
    setQuery(_get(event, "target.value"));
  }, []);

  const clear = useCallback(() => {
    setShowInput(false);
    setShowResult(false);
    clearInput();
  }, [clearInput]);

  const handleClose = useCallback(() => {
    clear();
  }, [clear]);

  const handleClick = useCallback(
    (link, sectionName, returnUrl) => {
      pushWithReturnUrl(link, sectionName, { returnUrl });
      clear();
    },
    [clear, pushWithReturnUrl]
  );

  const focusMenu = useCallback(event => {
    event.preventDefault();
    if (!menuEl.current) return;
    menuEl.current.querySelector("li").focus();
  }, []);

  const inputProps = useMemo(buildInputProps(focusMenu), [focusMenu]);

  const isEmptyQuery = _isEmpty(query);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ display: "flex" }}>
        {showInput ? null : (
          <StyledTooltip title={"Search"} hidden={showInput}>
            <IconButton onClick={toggleSearchInput}>
              <SearchIcon />
            </IconButton>
          </StyledTooltip>
        )}
        <StyledInput
          onAdornmentClick={toggleSearchInput}
          placeholder="Search"
          onChange={handleChange}
          inputRef={inputEl}
          hidden={!showInput}
          inputProps={inputProps}
        />
        <ResultList
          loading={loading}
          anchorEl={currentRef}
          onClose={handleClose}
          openMenu={showResult}
          results={isEmptyQuery ? [] : results}
          onClick={handleClick}
          menuEl={menuEl}
          showInstructions={isEmptyQuery}
        />
      </div>
    </ClickAwayListener>
  );
};

SearchOption.propTypes = {
  dataComponent: propTypes.dataComponent,
  search: PropTypes.func.isRequired,
  initSearch: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default SearchOption;
