import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AssignmentIcon from "@material-ui/icons/Assignment";
import propTypes from "../../../../constants/propTypes";

import Loader from "../../../ui/Loader";
import Breadcrumbs from "./Breadcrumbs";

const Wrapper = styled.div`
  display: flex;
  padding: 32px;
  padding-bottom: 40px;
`;

export const Header = ({ breadcrumbs, initNotesModal }) => (
  <Wrapper>
    <AssignmentIcon />
    <Loader height="28px" width="300px">
      <Breadcrumbs breadcrumbs={breadcrumbs} initNotesModal={initNotesModal} />
    </Loader>
  </Wrapper>
);

Header.propTypes = {
  breadcrumbs: propTypes.breadcrumbs,
  initNotesModal: PropTypes.func.isRequired,
};

export default Header;
