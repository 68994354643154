import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const MentionContainer = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
`;

const Mention = ({ value }) => <MentionContainer>{value}</MentionContainer>;

Mention.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Mention;
