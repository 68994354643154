export const generateGridRows = bidGroupVendorSpecs => {
  return bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    return {
      id: bidGroupVendorSpec.id,
      customNumber: bidGroupVendorSpec.spec.customNumber,
      description: bidGroupVendorSpec.spec.description,
      unitOfMeasure: bidGroupVendorSpec.spec.unitOfMeasure,
      quantity: bidGroupVendorSpec.spec.totalQuantityWithoutOverage,
    };
  });
};
export const handleUnawardSpec = (
  bidGroupVendorSpecs,
  onUnAwardSpec,
  dataComponentId,
  setSnackMessage
) => {
  const specs = bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const spec = {
      id: parseInt(bidGroupVendorSpec.spec.id),
      vendorId: parseInt(bidGroupVendorSpec.spec.originalVendorId),
      originalVendorId: null,
      awardedBidGroupVendorSpecQuoteId: null,
    };

    return spec;
  });
  onUnAwardSpec(specs, dataComponentId, setSnackMessage);
};
