import { useState, useEffect } from "react";
import _throttle from "lodash/throttle";

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const calcInnerWidth = _throttle(function() {
      setWidth(window.innerWidth);
    }, 100);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return width;
};
