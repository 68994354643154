import React, { useMemo } from "react";
import fields from "./fields";
import styled from "styled-components";
import { connect } from "formik";
import PropTypes from "prop-types";

import { FormPageSection } from "../../../layout/FormPageSection";
import PageSection from "../../../layout/PageSection";
import propTypes from "../../../../constants/propTypes";

const StyledWrapper = styled.div`
  ${PageSection} {
    margin-top: 24px;
  }
`;

const AccountingSectionLayout = ({ formik, isUpdate }) => {
  const { isTaxExempt } = formik.values;
  const memoFields = useMemo(() => fields({ isTaxExempt }, isUpdate), [
    isTaxExempt,
    isUpdate,
  ]);

  return (
    <StyledWrapper>
      <FormPageSection
        {...formik}
        fields={memoFields}
        headerText="Accounting"
      />
    </StyledWrapper>
  );
};

AccountingSectionLayout.propTypes = {
  formik: propTypes.formik,
  isUpdate: PropTypes.bool,
};

export default connect(AccountingSectionLayout);
