import { FORM_IDS, initialState } from "./constants";

export function openMainOptionsReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.MAIN,
    }));
  };
}

export function openCreateSpecReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.CREATE_SPEC,
    }));
  };
}

export function openMoveSpecReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.MOVE_SPEC,
    }));
  };
}

export function openCopySpecReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.COPY_SPEC,
    }));
  };
}

export function openDuplicateSpecReducer(set) {
  return function() {
    set(state => ({
      ...state,
      formId: FORM_IDS.DUPLICATE_SPEC,
    }));
  };
}

export function setPOReducer(set) {
  return function(purchaseOrder) {
    set(state => ({ ...state, purchaseOrder }));
  };
}

export function activateFiltersReducer(set) {
  return function() {
    set(state => ({ ...state, filtersActive: true }));
  };
}
export function resetStoreReducer(set) {
  return function() {
    set(state => ({ ...state, ...initialState }));
  };
}
