import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";

const CountersContainerWrapper = styled.div`
  display: flex;
  padding: 24px;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
`;

const CounterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 12.5px;
  width: 179px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

const HighlightedText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 8px;
`;

const Counters = ({
  totalNeeded,
  unitsPerCaseInput,
  recommendedCaseQtyOrder,
  itemsReq,
}) => (
  <CountersContainerWrapper>
    <CounterColumn>
      <div>Total Needed</div>
      <HighlightedText> {totalNeeded} </HighlightedText>
    </CounterColumn>
    <CounterColumn>{unitsPerCaseInput}</CounterColumn>
    <CounterColumn>
      Recommended Case Quantity Order
      <HighlightedText>{recommendedCaseQtyOrder}</HighlightedText>
      {itemsReq} Items Required
    </CounterColumn>
  </CountersContainerWrapper>
);

Counters.propTypes = {
  totalNeeded: PropTypes.number.isRequired,
  unitsPerCaseInput: PropTypes.node.isRequired,
  recommendedCaseQtyOrder: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  itemsReq: PropTypes.oneOfType([PropTypes.number, PropTypes.node]).isRequired,
};

export default Counters;
