import React from "react";
import styled from "styled-components";
import VendorInfo from "./VendorInfo";
import DesignerInfo from "./DesignerInfo";
import FreightInfo from "./FreightInfo";
import ShipToSiteContainer from "./ShipToSite";
import DiscountContainer from "./DiscountInfo";
import propTypes from "../../../../constants/propTypes";
import { withLabeledTextOption } from "../../../../withPORevision";

import { dataComponentId } from "../connect";
import { hasDiscount } from "../DetailsSection/createGeneralInfoFields";
import PurchaseOrderFiles from "./PurchaseOrderFiles";
export const RightSection = styled.div`
  padding-left: 24px;
  width: 35%;
  min-width: 20%;
  @media (max-width: 1024px) {
    padding-left: 0px;
    width: 100%;
    min-width: 100%;
  }
`;

export const getDiscounts = (
  purchaseOrder,
  vendorDiscounts,
  purchaseOrderDiscounts,
  hasDiscount
) => {
  const discountOptions = [];
  vendorDiscounts.map(discount => {
    const percent = discount.discountPercent.toFixed(2);
    if (discount.type === "Vendor") {
      discountOptions.push({
        name: discount.id,
        label: "BW Vendor Discount",
        amount: `${percent}%`,
        isChecked: hasDiscount(discount, purchaseOrderDiscounts),
      });
    }
    const { project = {} } = purchaseOrder;
    if (project.program && discount.programId === project.program.id) {
      discountOptions.push({
        name: discount.id,
        label: `${discount.program.name} Discount`,
        amount: `${percent}%`,
        isChecked: hasDiscount(discount, purchaseOrderDiscounts),
      });
    }
  });
  return discountOptions;
};
export const ShippingContactSection = ({
  formikFormValues,
  purchaseOrder,
  ...formikProps
}) => {
  const { vendor = {} } = purchaseOrder;
  const { discounts: vendorDiscounts = [] } = vendor;
  const { purchaseOrderDiscounts = [] } = purchaseOrder;

  return (
    <RightSection>
      <VendorInfo
        purchaseOrder={purchaseOrder}
        dataComponentId={dataComponentId}
      />
      <FreightInfo purchaseOrder={formikFormValues} />
      <DesignerInfo
        purchaseOrder={formikFormValues}
        dataComponentId={dataComponentId}
      />
      <ShipToSiteContainer
        purchaseOrder={formikFormValues}
        purchaseOrderId={formikFormValues.id}
        shipAddress={purchaseOrder && purchaseOrder.shipAddress}
        shipVendor={purchaseOrder && purchaseOrder.shipVendor}
        shipVendorAddressId={purchaseOrder && purchaseOrder.shipVendorAddressId}
        shipVendorAddress={purchaseOrder && purchaseOrder.shipVendorAddress}
        shipAddressId={purchaseOrder && purchaseOrder.shipAddressId}
      />
      <DiscountContainer
        discounts={getDiscounts(
          purchaseOrder,
          vendorDiscounts,
          purchaseOrderDiscounts,
          hasDiscount
        )}
        {...formikProps}
      />
      <PurchaseOrderFiles purchaseOrder={purchaseOrder} />
    </RightSection>
  );
};

ShippingContactSection.propTypes = {
  formikFormValues: propTypes.purchaseOrder.isRequired,
};

export default withLabeledTextOption(ShippingContactSection);
