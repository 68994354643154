import { connect } from "react-redux";
import { connect as formikConnect } from "formik";
import { withRouter } from "react-router";
import _get from "lodash/get";
import { createSelector } from "reselect";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../../utils/bluechipUtils";
import { createDeepEqualSelector } from "../../../../../../utils/selectors";
import AddVendorMenu from "./AddVendorMenu";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../../actions/dataComponentActions";

import Vendor from "../../../../../../models/Vendor";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";

export const dataComponentId = "vendorsList";

const mapStateToProps = (
  state,
  { dataComponentId: updateActiondataComponentId }
) => {
  const contactsDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const createRequestStatus = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.CREATE
  );
  const vendorsDataComponent = getDataComponent("CreateVendorContact", state);
  return {
    loading: contactsDataComponent.loading,
    vendors: getBluechipResources(contactsDataComponent, state) || [],
    isCreating: createRequestStatus.loading,
    vendorsDataComponent,
    dataComponent: getDataComponent(updateActiondataComponentId, state),
  };
};

export const mapDispatchToProps = (dispatch, { scopeId }) => {
  const contactsSelector = createDeepEqualSelector(
    (_, { poContacts }) => poContacts,
    poContacts => poContacts || []
  );

  const defaultActions = {
    loadVendors: () =>
      dispatch(
        performRetrieveListRequest(dataComponentId, {
          rootFilters: {
            $where: {
              "scope.id": scopeId,
            },
          },
          pageSize: -1,
          fields: ["vendors.id", "name", "status", "scopeId"],
          sort: [{ columnName: "name", direciton: "asc" }],
        })
      ),
    initDataComponent: () =>
      dispatch(
        initDataComponent(
          dataComponentId,
          Vendor,
          ["location"],
          "vendors",
          false,
          "v2"
        )
      ),
  };

  const dispatchPropsSelector = createSelector(
    (_, { specId }) => specId,
    (_, { poId }) => poId,
    (_, { updateActiondataComponentId }) => updateActiondataComponentId,
    (_, { setFieldValue }) => setFieldValue,
    contactsSelector,
    (specId, poId, updateActiondataComponentId, setFieldValue) => {
      return vendorId => {
        setFieldValue("vendorId", vendorId);
      };
    }
  );

  return (dispatch, props) => {
    const {
      match: {
        params: { specId, poId },
      },
      dataComponentId: updateActiondataComponentId,
      formik,
    } = props;

    return {
      ...defaultActions,
      updateSpecVendorId: dispatchPropsSelector(null, {
        specId,
        poId,
        updateActiondataComponentId,
        poContacts: _get(formik?.values, "poContacts", []),
        setFieldValue: formik?.setFieldValue,
      }),
    };
  };
};

export default withRouter(
  formikConnect(connect(mapStateToProps, mapDispatchToProps)(AddVendorMenu))
);
