import React, { Fragment } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

function InternationalPhone({ areaCode, phone, extension }) {
  return (
    <Fragment>
      {areaCode}{" "}
      <NumberFormat
        value={phone}
        displayType={"text"}
        thousandSeparator="."
        decimalSeparator=""
        isNumericString
      />{" "}
      {extension && `Ext. ${extension}`}
    </Fragment>
  );
}

InternationalPhone.propTypes = {
  areaCode: PropTypes.string,
  phone: PropTypes.string,
  extension: PropTypes.string,
};

function TollPhone({ phone, extension }) {
  return (
    <Fragment>
      <NumberFormat
        value={phone}
        displayType={"text"}
        format="#.###.###.####"
        isNumericString
      />{" "}
      {extension && `Ext. ${extension}`}
    </Fragment>
  );
}

TollPhone.propTypes = {
  phone: PropTypes.string,
  extension: PropTypes.string,
};

export default function PhoneNumber({ number }) {
  if (!number) return "";

  number = number.toString();
  const parts = number.split(" ");

  if (/^\+/.test(number)) {
    const [areaCode, phone, ...extension] = parts;
    return (
      <InternationalPhone
        areaCode={areaCode}
        phone={phone}
        extension={extension.join(" ")}
      />
    );
  }

  if (/^18/.test(number)) {
    const [phone, ...extension] = parts;
    return <TollPhone phone={phone} extension={extension.join(" ")} />;
  }

  return (
    <NumberFormat value={number} displayType={"text"} format="###.###.####" />
  );
}

PhoneNumber.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
