import React from "react";
import { Content, Title, Wrapper } from "../../layout/DetailAndCycle/TitleArea";
import Loader from "../../ui/Loader";
import PropTypes from "prop-types";

const TitleArea = ({ title, children }) => {
  return (
    <Loader height="32px">
      <Wrapper>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </Wrapper>
    </Loader>
  );
};

TitleArea.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TitleArea;
