import React, { useMemo } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { numericOnBlur, numericOnChange } from "./utils";

const PercentageInput = ({
  inputRef,
  onChange,
  decimalScale,
  onBlur,
  ...props
}) => {
  const name = props.name;
  const bindedOnChange = useMemo(() => numericOnChange(onChange, name), [
    onChange,
    name,
  ]);
  const bindedOnBlur = useMemo(() => numericOnBlur(onBlur, name), [
    onBlur,
    name,
  ]);
  return (
    <NumberFormat
      fixedDecimalScale
      {...props}
      suffix="%"
      getInputRef={inputRef}
      decimalScale={decimalScale || 3}
      onValueChange={bindedOnChange}
      onBlur={bindedOnBlur}
      decimalSeparator={"."}
    />
  );
};

PercentageInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PercentageInput;
