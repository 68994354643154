import inputs from "../../../../components/inputs/inputConfigs/team";

export default () => {
  return {
    groups: [
      {
        items: [
          { input: inputs.teamName, grid: { xs: 12 } },
          { input: inputs.teamUsers, grid: { xs: 12 } },
          { grid: { xs: 12 } },
          { grid: { xs: 12 } },
        ],
      },
    ],
  };
};
