import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import SpecTable from "./SpecTable";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Spec from "../../../../../models/Spec";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";

export class SpecTableContainer extends Component {
  render() {
    return (
      <SpecTable
        {...this.props}
        apiRoute="specs"
        model={Spec}
        includes={[
          "area",
          "specCategory",
          "vendor",
          "glCodes",
          "unitOfMeasure",
        ]}
      />
    );
  }
}

SpecTableContainer.propTypes = {
  dataComponent: PropTypes.object.isRequired,
};

export const mapStateToProps = (
  state,
  { rootFilters = {}, dataComponentId, projectId }
) => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
  rootFilters: {
    $where: {
      projectId,
      ...rootFilters,
    },
  },
});

export const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SpecTableContainer));
