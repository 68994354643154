import React from "react";
import PropTypes from "prop-types";

import { BWGridLocal, GridHeader, GridTable } from "../../../../ui/BWGrid";

import { StyledPaper } from "./components";
import propTypes from "../../../../../constants/propTypes";
import _get from "lodash.get";

import { getActions } from "../../../PurchaseOrderDetail/HeaderSection/headerFields";
import purchaseOrderStatuses from "../../../../../constants/purchaseOrderStatuses";
import { StatusColumn } from "../../../ProjectDetail/PurchaseOrder/gridProps";
import Price from "../../../../ui/Price";

const columns = [
  "number",
  "vendor.name",
  "status",
  "specTotalPrice",
  "specExtendedTotalPrice",
];

const columnOptions = () => ({
  number: {
    title: "Number",
    bold: true,
    filter: true,
    render: row => _get(row, "number"),
  },
  "vendor.name": {
    filter: true,
    title: "Vendor",
    render: row => _get(row, "vendor.name"),
    width: "300px",
  },
  status: {
    fill: true,
    title: "Status",
    render: StatusColumn,
    filter: "select",
    filterOptions: {
      options: purchaseOrderStatuses.map(status => ({
        id: status,
        name: status,
      })),
    },
  },
  specTotalPrice: {
    title: "Production Price",
    align: "right",
    filter: false,
    render: function CurrencyColumn(row) {
      return (
        <Price
          number={row.specTotalPrice / 100}
          currency={row.projectCurrency.currency}
        />
      );
    },
  },
  specExtendedTotalPrice: {
    title: "Ext. Production Price",
    align: "right",
    filter: false,
    render: function CurrencyColumn(row) {
      return (
        <Price
          number={row.specExtendedTotalPrice / 100}
          currency={row.projectCurrency.currency}
        />
      );
    },
  },
});
const getRowMenu = (
  purchaseOrder,
  openApprovalManager,
  revertPurchaseOrder,
  cancelPurchaseOrder,
  updatePurchaseOrderStatus
) => {
  const actions = getActions(purchaseOrder);
  const options = actions.map(action =>
    action({
      purchaseOrder: {
        ...purchaseOrder,
        hasFreightContacts: true,
      },
      setFieldValue: (_, value) => {
        updatePurchaseOrderStatus(purchaseOrder, value);
      },
      onOpenApprovalManagerClick: openApprovalManager,
      onRevert: revertPurchaseOrder,
      onCancel: cancelPurchaseOrder,
    })
  );

  return options.map(option => {
    return {
      text: option.content,
      onClick: option.onClick,
      disabled: option.disabled,
      disableIfProjectClosed: true,
    };
  });
};

export const PurchaseOrderTable = props => {
  const {
    loading,
    purchaseOrders,
    goToPurchaseOrderDetail,
    onHandleOpenApprovalManagerClick,
    onHandleRevertPurchaseOrder,
    onHandleCancelPurchaseOrder,
    onUpdatePurchaseOrderStatus,
  } = props;
  const totalRows = purchaseOrders.length;
  const headerText = `Purchase Orders`;
  return (
    <StyledPaper>
      <BWGridLocal
        id={`bid-group-purchaseOrder`}
        sorting={[{ columnName: "customNumber", direction: "asc" }]}
        rows={purchaseOrders}
        gridConfig={{ pageSize: 10, totalRows: totalRows }}
        isLoading={loading}
      >
        <GridHeader headerText={headerText} />
        <GridTable
          columns={columns}
          columnOptions={columnOptions()}
          onClick={goToPurchaseOrderDetail}
          rowMenu={row =>
            getRowMenu(
              row,
              onHandleOpenApprovalManagerClick,
              onHandleRevertPurchaseOrder,
              onHandleCancelPurchaseOrder,
              onUpdatePurchaseOrderStatus
            )
          }
        />
      </BWGridLocal>
    </StyledPaper>
  );
};

PurchaseOrderTable.propTypes = {
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  loading: PropTypes.bool,
  onOpenCreateBidModal: PropTypes.func,
  onHandleOpenApprovalManagerClick: PropTypes.func.isRequired,
  onHandleRevertPurchaseOrder: PropTypes.func.isRequired,
  onHandleCancelPurchaseOrder: PropTypes.func.isRequired,
  onUpdatePurchaseOrderStatus: PropTypes.func.isRequired,
  goToPurchaseOrderDetail: PropTypes.func.isRequired,
};

export default PurchaseOrderTable;
