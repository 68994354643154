import React from "react";
import PropTypes from "prop-types";
import { PagingState } from "@devexpress/dx-react-grid";

import propTypes from "../../../../constants/propTypes";

function getPageSettings(gridConfig) {
  const currentPage = gridConfig.pageNumber;
  const pageSize = gridConfig.pageSize;
  return { currentPage, pageSize };
}

const BWGridPagingState = ({ gridConfig, setPageSize, setPage, pageSize }) => {
  const { currentPage } = getPageSettings(gridConfig);
  return (
    <PagingState
      currentPage={currentPage}
      pageSize={pageSize}
      onCurrentPageChange={pageNumber => {
        setPage(pageNumber);
      }}
      onPageSizeChange={pageSize => {
        setPageSize(pageSize);
      }}
    />
  );
};

BWGridPagingState.propTypes = {
  gridConfig: propTypes.gridConfig,
  setPageSize: PropTypes.func,
  pageSize: PropTypes.number,
  setPage: PropTypes.func,
};

export default BWGridPagingState;
