import moment from "moment";
import _isEmpty from "lodash/isEmpty";

import FeePayment from "../models/FeePayment";
import FeeSummary from "../models/FeeSummary";
import {
  performRetrieveListRequest,
  performUpdateRequest,
  initDataComponent,
  performCreateRequest,
} from "./dataComponentActions";
import { openModalDialog } from "./layoutActions";
import { PRIMARY_DATE_FORMAT } from "../constants/formats";

export const dataComponentId = "freeSummaryId";
export const dataComponentFeePaymentId = "FeePayment";

export const initFeeSummaryDataComponent = projectId => () => {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        FeeSummary,
        [],
        `projects/${projectId}/fee-summaries`
      )
    );
  };
};

export const fetchFeeSummaries = () => {
  return dispatch => {
    dispatch(performRetrieveListRequest(dataComponentId, {}));
  };
};

export const updateFee = (
  ids,
  payload,
  instanceDataComponentId = dataComponentId
) => {
  return dispatch => {
    dispatch(performUpdateRequest(instanceDataComponentId, ids, payload));
  };
};

export const createFeePayment = feePayment => {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentFeePaymentId,
        FeePayment,
        ["fee"],
        "fee-payments",
        undefined,
        "v2"
      )
    );
    dispatch(performCreateRequest(dataComponentFeePaymentId, feePayment));
  };
};

export const openPaymentsDialog = ({
  fee,
  dataComponentId,
  performAfterCreatePayment,
}) => {
  return async dispatch => {
    dispatch(
      openModalDialog(
        "Manage Fee Payments",
        "FeePayments",
        {
          feeId: fee.id,
          dataComponentId,
          sendButtonText: "Save Changes",
          hideCancelButton: true,
          isUpdate: true,
          performAfterCreatePayment,
        },
        false,
        true,
        {
          width: "790px",
          subtitle: "Fee",
          secondSubtitle: `Bill Date: ${
            fee.billDate
              ? moment(fee.billDate).format(PRIMARY_DATE_FORMAT)
              : "--"
          } | Invoice: ${
            _isEmpty(fee.invoiceReferenceNumber)
              ? "--"
              : fee.invoiceReferenceNumber
          }`,
        }
      )
    );
  };
};
