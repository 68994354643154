import styled from "styled-components";

export const ItemWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
  padding: 16px 24px;
  border-top: ${({ emptyColumn }) =>
    !emptyColumn && "1px solid rgba(0, 0, 0, 0.12)"};
`;

export const OptionsWrapper = styled.div`
  display: flex;

  ${ItemWrapper} {
    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
`;

export const Value = styled.div`
  font-size: 20px;
  font-family: Domine, sans-serif;
  width: ${({ noTitle }) => (noTitle ? "100%" : "auto")};
`;
