import create from "zustand";
import { devtools } from "zustand/middleware";
import { getRoomColumns } from "../../pages/Specs/ImportSpecs/gridOptions";

import buildValidationSchema from "./buildValidationSchema";

function formatErrorPaths(error, rows) {
  if (!error?.details) return null;
  return error.details.reduce((errors, error) => {
    const [rowIndex, attribute] = error.path;
    const rowId = rows[rowIndex].id;

    if (!errors[rowId]) {
      errors[rowId] = {};
    }
    errors[rowId][attribute] = true;

    return errors;
  }, {});
}

function validateLocalRows(set, get) {
  return function(rows) {
    const { error } = buildValidationSchema(getRoomColumns(rows)).validate(
      rows,
      {
        abortEarly: false,
        allowUnknown: true,
      }
    );
    const localError = formatErrorPaths(error, rows);
    const { displayValidRows } = get();
    set({
      localError,
      hasError: Boolean(localError),
      displayValidRows: displayValidRows ? true : !localError,
    });
  };
}

function validateImport(set) {
  return function(globalError, rows) {
    const importError = formatErrorPaths(globalError, rows);
    set({
      importError,
      hasError: Boolean(importError),
      ignoreErrors: !importError,
      displayValidRows: true,
    });
  };
}

function isInvalid(get) {
  return function() {
    const { localError } = get();
    //return ignoreErrors ? false : Boolean(localError);
    return Boolean(localError);
  };
}

export function isInvalidRow(get) {
  return function(rowId) {
    const { localError } = get();
    //if (ignoreErrors) return false;
    return Boolean(localError?.[rowId]);
  };
}

function isInvalidImportField(get) {
  return function(rowId, field) {
    const { importError } = get();
    return Boolean(importError?.[rowId]?.[field]);
  };
}

export function isInvalidLocalField(get) {
  return function(rowId, field) {
    const { localError } = get();
    //if (ignoreErrors) return false;
    return Boolean(localError?.[rowId]?.[field]);
  };
}

function getInvalidRows(get) {
  return function() {
    const { localError } = get();
    const error = localError || {};
    return Object.keys(error);
  };
}

export function getCount(get) {
  return function() {
    const { localError } = get();
    const error = localError;
    return Object.keys(error).length;
  };
}

const initialState = {
  importError: null,
  localError: null,
  displayValidRows: true,
  ignoreErrors: true,
  hasError: false,
};

const useErrorImportStore = create(
  devtools(
    (set, get) => ({
      ...initialState,
      actions: {
        setDisplayValidRows: displayValidRows => set({ displayValidRows }),
        setIgnoreErrors: ignoreErrors => set({ ignoreErrors }),
        validateImport: validateImport(set),
        validateLocalRows: validateLocalRows(set, get),
        isInvalidRow: isInvalidRow(get),
        isInvalidImportField: isInvalidImportField(get),
        isInvalidLocalField: isInvalidLocalField(get),
        isInvalid: isInvalid(get),
        getCount: getCount(get),
        getInvalidRows: getInvalidRows(get),
        reset: () => set({ ...initialState }),
      },
    }),
    "ErrorImport"
  )
);

export default useErrorImportStore;
