import LoginContainer from "../components/pages/Login";
import ResetPasswordContainer from "../components/pages/ResetPassword";
import ForgotPasswordContainer from "../components/pages/ForgotPassword";
import Users from "../components/pages/Users";
import { appPageBuilder } from "./utils";
import UserDetail from "../components/pages/UserDetail";
import VerifyUserDetails from "../components/pages/Login/VerifyUserDetails";

const AppUsers = appPageBuilder(Users, "User Index");
const AppUserDetail = appPageBuilder(UserDetail, "User Detail", true);

export default [
  { path: "/", component: LoginContainer, exact: true },
  {
    path: "/forgotPassword",
    component: ForgotPasswordContainer,
    exact: true,
  },
  {
    path: "/set-password",
    component: ResetPasswordContainer,
    exact: true,
  },
  {
    path: "/users",
    component: AppUsers,
    exact: true,
  },
  {
    path: "/users/:userId",
    exact: true,
    component: AppUserDetail,
  },
  {
    path: "/account-settings",
    component: VerifyUserDetails,
    exact: true,
  },
];
