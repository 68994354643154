import React from "react";
import styled from "styled-components";

import { genDetailRowComponents } from "./v1";
import RowDetailGrid from "../RowDetailGrid";
import { Row } from "../../../../Grid/utilComponents/tableComponents";

const StyledRowComponent = styled(Row)`
  > td,
  th {
    border-bottom: none;
    background-color: ${({ onClick }) => !onClick && "#ffffff !important"};
  }
`;

export const RowComponent = props => <StyledRowComponent {...props} />;

export const genDetailRowComponentsV2 = (
  path,
  idPath,
  validationSchema,
  dataComponentId,
  colSpan,
  prevColSpan,
  textInputProps,
  isReadOnly
) => {
  const {
    DetailRowComponent,
    WrapperDetailRow,
    DetailCellComponent,
  } = genDetailRowComponents(
    path,
    idPath,
    validationSchema,
    dataComponentId,
    colSpan,
    prevColSpan,
    textInputProps,
    isReadOnly
  );

  return {
    tableComponents: {
      DXGrid: RowDetailGrid,
      RowComponent,
    },
    dxGridProps: {
      tableComponents: {
        WrapperDetailRow,
        DetailRowComponent,
        DetailCellComponent,
      },
    },
  };
};
