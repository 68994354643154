import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import brandFields from "./brandFields";
import FormGrid from "../../../../components/forms/FormGrid";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import propTypes from "../../../../constants/propTypes";

const BrandForm = ({ onSubmit, initialValues, dataComponentId }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("Brand")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={brandFields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

BrandForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.glCode,
  dataComponentId: PropTypes.string.isRequired,
};

export default BrandForm;
