import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _get from "lodash/get";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";

import propTypes from "../../../../../constants/propTypes";
import TagText from "../../../../ui/Tags/TagText";
import {
  Wrapper,
  GridContainer,
  Button,
  Text,
  Author,
  Date,
} from "./components";
import { BWIntent } from "../../../../ui/BWIntent";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const Details = ({ project }) => {
  if (!project.notes) return null;

  const author = project.notesAuthor;
  return (
    <Fragment>
      <Author>
        - {_get(author, "firstName")} {_get(author, "lastName")}.
      </Author>
      <Date intent="light">
        {moment(project.notesUpdatedAt).format(PRIMARY_DATE_FORMAT)}
      </Date>
    </Fragment>
  );
};

Details.propTypes = {
  project: propTypes.project,
};

const NoteSection = ({ project, actions, dataComponent }) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => actions.closeModalDialog(),
  });

  const text = project.notes || "Add a Project Note";
  const buttonTitle = `Click to ${
    project.notes ? "edit" : "add"
  } a Project Note`;
  const modalTitle = `${project.notes ? "Add" : "Edit"} Project Note`;

  const handleClick = () => {
    actions.openModalDialog(
      modalTitle,
      "ProjectNoteModal",
      { project },
      true,
      true
    );
  };

  return (
    <GridContainer container>
      <Grid item xs={12}>
        <Wrapper>
          <Button
            title={buttonTitle}
            aria-label={buttonTitle}
            onClick={handleClick}
          >
            <Assignment />
          </Button>
          <TagText intent={BWIntent.PRIMARY}>EM</TagText>
          <Text
            intent={project.notes ? "main" : "light"}
            title={text}
            aria-label="Project Note"
            as="main"
          >
            {text}
          </Text>
          <Details project={project} />
        </Wrapper>
      </Grid>
    </GridContainer>
  );
};

NoteSection.propTypes = {
  project: propTypes.project,
  dataComponent: propTypes.dataComponent,
  actions: PropTypes.shape({
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
  }).isRequired,
};

export default NoteSection;
