import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import { closeModalDialog } from "../../../../actions/layoutActions";
import { performDeleteRequest } from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";
import withPORevision from "../../../../withPORevision";

const dataComponentId = "SpecDetail-SpecDetailsChildren";

export class DeleteSpecDetailContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    specDetailId: PropTypes.string.isRequired,
    dataComponent: propTypes.dataComponent.isRequired,
    performDeleteRequest: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    revisionValue: PropTypes.shape({
      isActive: PropTypes.bool,
      activities: PropTypes.arrayOf(propTypes.revisionActivities),
    }),
  };

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: () => {},
    });
  }

  deleteSpecDetail = () => {
    const { specDetailId, performDeleteRequest, revisionValue } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    performDeleteRequest(dataComponentId, specDetailId, params);
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation title={title} onDelete={this.deleteSpecDetail} />
    );
  }
}

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(DeleteSpecDetailContainer, "SpecDetail", "purchaseOrder"));
