import VendorShipToSite from "../VendorShipToSite";

describe("VendorShipToSitee Model", () => {
  test("belongsTo relations", () => {
    expect(
      VendorShipToSite.belongsTo.map(relation => relation.name)
    ).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(
      VendorShipToSite.hasMany.map(relation => relation.name)
    ).toMatchSnapshot();
  });
});
