import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";
import FormGrid from "../../../../forms/FormGrid";
import FormikForm from "../../../../forms/FormikForm";
import SectionHeader from "../../../../ui/Typography/SectionHeader";
import ActionButtons from "../../../../forms/ActionButtons";
import createShipmentFields from "../CreateShipment/createShipmentFields";
import propTypes from "../../../../../constants/propTypes";

const shipmentInitialValues = {
  quantity: 0,
  estimatedShipDate: undefined,
  estimatedDeliveryDate: undefined,
  trackingNumber: undefined,
  receivedBy: undefined,
  actualShipDate: undefined,
  actualDeliveryDate: undefined,
  notes: undefined,
  specId: -1,
};

const getShipmentSchema = remainingQty =>
  BWModels.loadSchema("Shipment").concat(
    Joi.object().keys({
      quantity: Joi.number().max(remainingQty),
    })
  );

const EditShipmentForm = ({
  remainingQty,
  shipment,
  onSubmit,
  loading,
  onCancel,
  spec,
  totalQuantityApprovedToPay,
}) => {
  return (
    <Fragment>
      <SectionHeader>Shipment Notes</SectionHeader>
      <FormikForm
        onSubmit={onSubmit}
        initialValues={shipment || shipmentInitialValues}
        validationSchema={getShipmentSchema(remainingQty)}
        enableReinitialize={true}
      >
        {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
          return (
            <Fragment>
              <FormGrid
                fields={createShipmentFields(
                  remainingQty,
                  values,
                  setFieldValue,
                  spec,
                  totalQuantityApprovedToPay
                )}
                values={values}
                errors={errors}
                ignoreValidationFieldNames={["specId"]}
                {...formikProps}
              />

              <ActionButtons
                isModal
                onSend={handleSubmit}
                loading={loading}
                onCancel={onCancel}
              />
            </Fragment>
          );
        }}
      </FormikForm>
    </Fragment>
  );
};

EditShipmentForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  shipment: propTypes.shipment,
  remainingQty: PropTypes.number,
  onCancel: PropTypes.func,
  spec: propTypes.spec,
};

export default EditShipmentForm;
