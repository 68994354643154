import { BaseModel } from "@blue-chip/core";
import Entity from "./Entity";
import Location from "./Location";
import GLCode from "./GLCode";

export default class Client extends BaseModel {
  static get belongsTo() {
    return [Location];
  }
  static get hasMany() {
    return [Entity, GLCode];
  }
}
