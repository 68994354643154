import TextInputWithError from "../../inputs/TextInputWithError";
import { SelectWithError as RawSelectWithError } from "../SelectWithError";
import { getOptionsFromSchema } from "../utils";

export default {
  specCategoryName: {
    InputComponent: TextInputWithError,
    label: "Template Name",
    name: "name",
    fullWidth: true,
    required: true,
  },
  specCategoryType: {
    InputComponent: RawSelectWithError,
    label: "Select Type",
    name: "type",
    options: getOptionsFromSchema("SpecCategory", "types"),
    required: true,
    fullWidth: true,
  },
  specCategoryKey: {
    InputComponent: RawSelectWithError,
    label: "Select Template Type",
    name: "key",
    options: getOptionsFromSchema("SpecCategory", "keys"),
    nullAsEmpty: true,
    fullWidth: true,
  },
  specCategoryDetailName: {
    InputComponent: TextInputWithError,
    name: "name",
    label: "Add Title",
    fullWidth: true,
    autoFocus: true,
  },
  isActive: {
    InputComponent: RawSelectWithError,
    label: "Status",
    name: "isActive",
    options: [
      { id: true, name: "Active" },
      { id: false, name: "Inactive" },
    ],
    displayEmpty: false,
    fullWidth: true,
  },
};
