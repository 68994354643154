import React from "react";
import styled from "styled-components";
import Inbox from "@material-ui/icons/Inbox";

import RegularText from "../../ui/Typography/RegularText";

const StateIcon = styled(Inbox)`
  font-size: 48px;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 48px;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
`;

const EmptyState = () => (
  <Wrapper>
    <StateIcon />
    <RegularText intent="empty">No Spec Selected</RegularText>
  </Wrapper>
);

export default EmptyState;
