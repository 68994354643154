import React from "react";

const labels = {
  FREIGHT_ITEM: "Freight",
  INSTALL_ITEM: "Install",
  WAREHOUSING_ITEM: "Warehousing",
  VENDOR_FREIGHT_ITEM: "Vendor Freight",
  COM_ITEM: "COM",
  OTHER_COST: "Other Costs",
};

const KeyColumn = ({ specKey }) => labels[specKey] || "";

export const getKeyColumn = row => <KeyColumn specKey={row.key} />;

export default KeyColumn;
