import React, { useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { openModalDialog } from "../../../../actions/layoutActions";

import RequirementsSection from "./RequirementsSection";
import { Content } from "./ReviewSubmittals/components";
import { SpecDetailContext } from "../SpecDetailContainer";

const name = "specRequirements";

const buildOpenAddRequirementHandler = (
  openModalDialog,
  formikArrayHelpers,
  rows
) => () => {
  openModalDialog(
    "Create Requirement",
    "CreateEditRequirement",
    {
      formikArrayHelpers,
      specRequirement: null,
      selectedRequirements: rows.map(row => row.requirement),
    },
    true,
    false,
    { scrollDisabled: true }
  );
};

export const buildOpenEditRequirementHandler = (
  openModalDialog,
  formikArrayHelpers,
  rows
) => (specRequirement, { rowId }) => {
  openModalDialog(
    `Edit ${specRequirement.requirement.name} Requirement`,
    "CreateEditRequirement",
    {
      formikArrayHelpers,
      specRequirement,
      selectedRequirements: rows.map(row => row.requirement),
      index: rowId,
    },
    true,
    false,
    { scrollDisabled: true }
  );
};

const buildOpenDeleteRequirementHandler = (
  openModalDialog,
  formikArrayHelpers
) => (specRequirement, { rowId }) => {
  openModalDialog(
    `Delete ${specRequirement.requirement.name}`,
    "DeleteRequirement",
    {
      requirement: specRequirement,
      title: specRequirement.requirement.name,
      formikArrayHelpers,
      index: rowId,
    },
    false
  );
};

const buildOpenReviewSubmittalsHandler = (
  openModalDialog,
  formikArrayHelpers,
  spec,
  onUpdateSpec
) => (specRequirement, { rowId }) => {
  openModalDialog(
    ["Review Submittals", `${spec.customNumber} ${spec.description}`],
    "ReviewSubmittals",
    {
      specRequirement,
      formikArrayHelpers,
      index: rowId,
      onUpdateSpec,
    },
    false,
    true,
    { scrollDisabled: true, components: { Content }, width: "auto" }
  );
};

export const RequirementsSectionContainer = ({
  loading,
  values,
  openModalDialog,
  role,
  specId,
}) => {
  const rows = values[name] || [];

  const { onUpdateSpec } = useContext(SpecDetailContext);
  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => (
        <RequirementsSection
          loading={loading}
          rows={rows}
          role={role}
          specId={specId}
          onOpenAddModal={buildOpenAddRequirementHandler(
            openModalDialog,
            formikArrayHelpers,
            rows
          )}
          onOpenEditModal={buildOpenEditRequirementHandler(
            openModalDialog,
            formikArrayHelpers,
            rows
          )}
          onOpenDeleteModal={buildOpenDeleteRequirementHandler(
            openModalDialog,
            formikArrayHelpers
          )}
          onOpenReviewSubmittalsModal={buildOpenReviewSubmittalsHandler(
            openModalDialog,
            formikArrayHelpers,
            values,
            onUpdateSpec
          )}
        />
      )}
    />
  );
};

RequirementsSectionContainer.propTypes = {
  role: PropTypes.shape({}),
  values: PropTypes.shape({}),
  openModalDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  specId: PropTypes.string,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default withRouter(
  connect(null, mapDispatchToProps)(RequirementsSectionContainer)
);
