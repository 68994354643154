import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { GlobalStyle, ThemeStyle } from "./components";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./App";
import SentryHandler from "./SentryHandler";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

export default class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    const { store, history } = this.props;
    return (
      <Fragment>
        <GlobalStyle />
        <ThemeStyle />
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <SentryHandler>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </MuiThemeProvider>
            </SentryHandler>
          </ConnectedRouter>
        </Provider>
      </Fragment>
    );
  }
}
