import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import UsersPage from "./UsersPage";
import { openModalDialog } from "../../../actions/layoutActions";
import { openUserSettingsModal } from "../../../actions/usersActions";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import propTypes from "../../../constants/propTypes";

const dataComponentId = "UsersGrid";

export class UsersContainer extends Component {
  handleAddUserClick = () => {
    this.props.openModalDialog(
      "Create User",
      "CreateUserContainer",
      {},
      true,
      false,
      { width: "790px" }
    );
  };

  handleUserSettingsClick = user => {
    this.props.openUserSettingsModal(user);
  };

  handleRowClick = ({ id }) => {
    this.props.push(`/users/${id}`);
  };

  handleOpenDeleteModal = user => {
    const { openModalDialog } = this.props;
    openModalDialog(
      `Delete ${user.name}`,
      "StandardDelete",
      { dataComponentId, title: user.name, id: user.id },
      true
    );
  };

  render() {
    const { dataComponent } = this.props;

    return (
      <UsersPage
        onAddUserClick={this.handleAddUserClick}
        onUserSettingsClick={this.handleUserSettingsClick}
        onOpenDeleteModal={this.handleOpenDeleteModal}
        onRowClick={this.handleRowClick}
        dataComponent={dataComponent}
      />
    );
  }
}

UsersContainer.propTypes = {
  dataComponent: propTypes.dataComponent.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  openUserSettingsModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  openModalDialog,
  openUserSettingsModal,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
