import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { withSelectedRows } from "../../../../ui/BWGrid";
import {
  getColumns,
  getSortedRows,
  isEditable,
  rowInputs as getRowInputs,
} from "../../../BidGroupDetail/Quotes/BidGroupTable/BidGroupTable";
import propTypes from "../../../../../constants/propTypes";
import { WithSelectedRowsContext } from "../../../../ui/BWGrid/withSelectedRows";
import { spreadBidGroupVendorSpecsWithComs } from "../../../BidGroupDetail/Quotes/bidGroupVendorSpecUtils";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableLocalCell";
import {
  bidGroupVendorSpecQuoteComponentId,
  createAlternateQuote,
  updateNote,
} from "../../../BidGroupDetail/Quotes/QuotesPage";
import useActions from "../../../../hooks/useActions";
import { openModalDialog } from "../../../../../actions/layoutActions";
import {
  performUpdateRequest,
  performCreateRequest,
} from "../../../../../actions/dataComponentActions";

import { useUpdateNoteSuccess, useCreateAlternateQuoteSuccess } from "./hooks";
import { LabeledTextContext } from "../../../../../withPORevision";
import QuoteGrid from "./QuoteGrid";
import { pushWithReturnUrl } from "../../../../../actions/navigationActions";

export const BidGroupTable = ({
  bidGroupVendor: srcBGVendor,
  handleBidGroupVendorSpecsUpdate,
}) => {
  const { totalRows } = useContext(WithSelectedRowsContext);
  const isLabeled = useContext(LabeledTextContext);
  const bidGroupVendorRef = useRef();
  const [snackMessage, setSnackMessage] = useState("");
  const [bidGroupVendor, setBidGroupVendor] = useState(srcBGVendor);
  const [columns, setColumns] = useState(
    getColumns(bidGroupVendor.bidGroup?.enableSampleQuantity)
  );
  const [productionTotal, setProductionTotal] = useState(0);
  const [extProductionTotal, setExtProductionTotal] = useState(0);
  const [gridRows, setGridRows] = useState([]);
  const [rowInputs, setRowInputs] = useState([]);

  const actions = useActions({
    openModalDialog,
    performUpdateRequest,
    performCreateRequest,
    pushWithReturnUrl,
  });

  useUpdateNoteSuccess(snackMessage, bidGroupVendor, setBidGroupVendor);
  useCreateAlternateQuoteSuccess(
    snackMessage,
    bidGroupVendor,
    setBidGroupVendor
  );

  useEffect(() => {
    setBidGroupVendor(srcBGVendor);
  }, [srcBGVendor]);

  useEffect(() => {
    const { bidGroupVendorSpecs = [] } = bidGroupVendor;
    const columns = getColumns(bidGroupVendor.bidGroup?.enableSampleQuantity);
    const rows = spreadBidGroupVendorSpecsWithComs(bidGroupVendorSpecs);
    const sortedRows = getSortedRows(rows);
    setColumns(columns);
    setGridRows(sortedRows);
    bidGroupVendorRef.current = bidGroupVendor;
    const bidGroupRowInputs = getRowInputs(bidGroupVendor);
    setRowInputs(bidGroupRowInputs);
  }, [bidGroupVendor]);

  useEffect(() => {
    const total = gridRows.reduce((accum, row) => {
      return accum + (row.productionPricing || 0);
    }, 0);
    const extTotal = gridRows.reduce((accum, row) => {
      return accum + (row.extendedProductionPricing || 0);
    }, 0);

    setProductionTotal(total);
    setExtProductionTotal(extTotal);
  }, [gridRows]);

  const tableComponent = isLabeled
    ? {}
    : {
        CellComponent: editableCellCreator(rowInputs, (column, tableRow) =>
          isEditable(column, tableRow)
        ),
      };

  const handleOpenNotesModal = row => {
    actions.openModalDialog(
      [
        `Note for ${_get(bidGroupVendor, "vendor.name")}`,
        `${row.spec.customNumber} ${row.spec.description}`,
      ],
      "QuotesNotesContainer",
      {
        bidGroupVendorSpecQuote: row.quote,
        dataComponentId: bidGroupVendorSpecQuoteComponentId,
        performUpdateRequest: actions.performUpdateRequest,
        onUpdateNote: updateNote,
        setSnackMessage,
        readOnly: true,
      },
      true,
      true
    );
  };

  const handleNotesIconClick = (_, row) => handleOpenNotesModal(row);

  const openCreateAlternateQuoteModal = (row, bidGroupVendor) => {
    actions.openModalDialog(
      [
        "Create Alternate Quote",
        `${row.spec.customNumber} ${row.spec.description}`,
      ],
      "CreateAlternateQuoteContainer",
      {
        bidGroupVendorSpec: row,
        bidGroupVendor,
        index: row.bidGroupVendorSpecId,
        createAlternateQuote,
        dataComponentId: bidGroupVendorSpecQuoteComponentId,
        performCreateRequest: actions.performCreateRequest,
        setSnackMessage,
      },
      false,
      false,
      {
        width: "800px",
      }
    );
  };

  const handleRowsUpdate = useCallback(
    updatedRows => {
      setGridRows(updatedRows);
      handleBidGroupVendorSpecsUpdate(updatedRows);
    },
    [handleBidGroupVendorSpecsUpdate]
  );

  const goToSpecDetail = bidGroupSpec => {
    actions.pushWithReturnUrl(
      `/rfqs/${srcBGVendor.id}/specs/${bidGroupSpec.spec.id}`,
      "REQUEST FOR QUOTE"
    );
  };

  return (
    <QuoteGrid
      columns={columns}
      bidGroupVendor={bidGroupVendor}
      gridRows={gridRows}
      totalRows={totalRows}
      productionTotal={productionTotal}
      extProductionTotal={extProductionTotal}
      selectedProjectCurrency={bidGroupVendor.quoteProjectCurrency}
      tableComponents={tableComponent}
      setGridRows={handleRowsUpdate}
      onOpenNotesModal={handleOpenNotesModal}
      onNotesIconClick={handleNotesIconClick}
      openCreateAlternateQuoteModal={openCreateAlternateQuoteModal}
      isLabeled={isLabeled}
      onGoToSpecDetail={goToSpecDetail}
    />
  );
};

BidGroupTable.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor,
  handleBidGroupVendorSpecsUpdate: PropTypes.func,
};

export default withSelectedRows(BidGroupTable);
