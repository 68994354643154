import React from "react";
import PropTypes from "prop-types";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import _get from "lodash/get";

import {
  TimeText,
  OuterWrapper,
  Wrapper,
  Body,
  DismissText,
  FirstLine,
  Headline,
  HeadlineText,
  HeadlineTextContainer,
  Link,
} from "./components";
import propTypes from "../../../../constants/propTypes";

const LatestNews = ({ latestNews, isVisible, onDismiss, onViewAll }) => (
  <OuterWrapper isVisible={isVisible}>
    {latestNews && (
      <Wrapper>
        <FirstLine>
          <Headline>
            <QuestionAnswerIcon />
            <HeadlineTextContainer>
              <HeadlineText>{_get(latestNews, "headline")}</HeadlineText>
            </HeadlineTextContainer>
          </Headline>
          <Link onClick={onDismiss}>
            <CloseIcon />
            <DismissText>DISMISS</DismissText>
          </Link>
        </FirstLine>
        <Body>
          <TimeText>
            {moment(_get(latestNews, "createdAt")).calendar()}
          </TimeText>
          <Link onClick={onViewAll}>View All News</Link>
        </Body>
      </Wrapper>
    )}
  </OuterWrapper>
);
LatestNews.propTypes = {
  latestNews: propTypes.news,
  isVisible: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onViewAll: PropTypes.func.isRequired,
};

export default LatestNews;
