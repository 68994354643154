export const extractRelationsFromSearch = search => {
  // Check if the search string contains substrings like PO#345 SHIP#232-ed. Can be many
  const relations = search.match(/"\w+\s*#(\w|\s|-|\.)+"|(\w+\s*#(\w|-|\.)+)/g);
  if (!Array.isArray(relations)) return null;

  const idsByType = { po: [], ship: [], spec: [], inv: [] };
  // Iterate over the matched substring, extract the ids and store them in separate arrays.

  relations.forEach(relation => {
    const sanitizedRelation = relation.replace(/^"|"$/g, "");
    const [key, id] = sanitizedRelation.split("#");
    const sanitizedKey = key.toLowerCase().trim();
    const sanitizedId = id.trim();
    if (idsByType[sanitizedKey]) {
      idsByType[sanitizedKey].push(sanitizedId);
    }
  });
  return idsByType;
};

const relationPaths = {
  po: "purchaseOrders.number",
  ship: "shipments.number",
  spec: "specs.customNumber",
  inv: "invoices.number",
};

const relationIdPaths = {
  po: "purchaseOrders.id",
  ship: "shipments.id",
  spec: "specs.id",
  inv: "invoices.id",
};

const entitiesSearchRegex = {
  po: /"PO\s*#*"*/,
  ship: /"SHIP\s*#*"*/,
  spec: /"SPEC\s*#*"*/,
  inv: /"INV\s*#*"*/,
};

export const getEntitySearchQuery = search => {
  const entityKey = Object.keys(entitiesSearchRegex).find(entity =>
    entitiesSearchRegex[entity].test(search)
  );
  if (entityKey) {
    return { [relationIdPaths[entityKey]]: { $exists: true } };
  }

  return {};
};

export const getRelationsSearchQuery = search => {
  const idsByType = extractRelationsFromSearch(search);
  if (!idsByType) return {};

  // Transform the list of ids to an objection filter query object like
  // {
  //    "purchaseOrders.id": {$in: ["345"]}
  //    "shipments.id": {$in: ["232"]}
  // }
  return Object.keys(idsByType).reduce((query, key) => {
    if (idsByType[key].length > 0) {
      query[relationPaths[key]] = { $in: idsByType[key] };
    }
    return query;
  }, {});
};

export const getTextSearchQuery = search => {
  // Remove relation tags from search and replace white spaces with %
  //.replace(/"\w+#*(\w|\s|-|\.)*"|(\w+#*(\w|-|\.)*)/g, "")
  const parsedSearch = search
    .replace(/"\w+\s*#(\w|\s|-|\.)+"|(\w+\s*#(\w|-|\.)+)/g, "")
    .trim()
    .replace(/"PO\s*#*"*/, "")
    .replace(/"SHIP\s*#*"*/, "")
    .replace(/"SPEC\s*#*"*/, "")
    .replace(/"INV\s*#*"*/, "")
    .replace(/\s+/g, "%");
  if (!parsedSearch) return {};

  const searchField = [
    "detail",
    "subject",
    "emailSentBy",
    "emailSentTo",
    "callWith",
    "author.firstName",
    "author.lastName",
  ];
  return {
    $or: searchField.map(key => {
      return { [key]: { $ilike: `%${parsedSearch}%` } };
    }),
  };
};

export const getTagsSearch = tags => {
  if (!tags) return {};
  return {
    "tags.id": { $in: tags },
  };
};

export const getTimeSearch = timeBoundaries => {
  const operations = [];
  if (timeBoundaries.from) {
    operations.push({ $gte: timeBoundaries.from });
  }

  if (timeBoundaries.to) {
    operations.push({ $lte: timeBoundaries.to });
  }

  switch (operations.length) {
    case 1:
      return { createdAt: operations[0] };
    case 2:
      return { createdAt: { $and: operations } };
    default:
      return {};
  }
};

const getClientQuery = ({ clientId }) => ({
  "project.property.entity.clientId": clientId,
});

const getProjectQuery = ({ projectId }) => ({
  projectId,
});

const contextQueryMap = {
  clientId: getClientQuery,
  projectId: getProjectQuery,
};

// Check if items in context can generate a query modifier and use it.
export const getContextQuery = context =>
  Object.keys(contextQueryMap).reduce(
    (accum, key) =>
      context[key]
        ? {
            ...accum,
            ...contextQueryMap[key](context),
          }
        : accum,
    {}
  );

export const buildQuery = (filters, context, extraInfo) => ({
  category: filters.category,
  authorId: filters.checks.myNotes ? extraInfo.loggedInUserId : null,
  "users.id": filters.checks.myMentions ? extraInfo.loggedInUserId : null,
  ...getEntitySearchQuery(filters.search),
  ...getRelationsSearchQuery(filters.search),
  ...getTextSearchQuery(filters.search),
  ...getTagsSearch(filters.tags),
  ...getTimeSearch(filters.timeBoundaries),
  ...getContextQuery(context),
});
