import { useCallback, useMemo, useState } from "react";
import { getFilteredItems } from "../../../ui/Grid/LocalGrid/FilteringControl";

export default (gridId, totalRows) => {
  const [filters, setFilters] = useState([]);

  const filterResults = useCallback(
    items => {
      const filteredItems = getFilteredItems(filters, items);
      return {
        results: [...filteredItems, ...totalRows],
        count: filteredItems.length,
      };
    },
    [filters, totalRows]
  );

  const handleFiltersChange = useCallback(filters => setFilters(filters), []);

  return useMemo(() => {
    return {
      isDefault: false,
      filterResults,
      onFiltersChange: handleFiltersChange,
    };
  }, [handleFiltersChange, filterResults]);
};
