import {
  performDeleteRequest,
  performCreateRequest,
} from "./dataComponentActions";
import { blueChipActions } from "../store/blueChipConfig";
import ProjectUserStar from "../models/ProjectUserStar";
import { setReload } from "./dataComponentActions";
import * as RequestTypes from "../constants/RequestTypes";
import { getDataComponent } from "../reducers/dataComponentReducer";

export function updateProjectResource(projectId, requestType, dataComponentId) {
  return async (dispatch, getState) => {
    const state = getState();
    const dataComponent = getDataComponent(dataComponentId, state);
    if (!requestType) return;
    const currentReqState = dataComponent.requestState[requestType];
    if (currentReqState.loading || !currentReqState.success) return;

    const { rowIndex } = dataComponent.requestState[requestType];
    const project = state.resources.projects[projectId];
    const projectUserStars = {
      data: rowIndex.map(id => ({
        id,
        type: ProjectUserStar.pluralName(),
      })),
    };

    const isFavorited = requestType === RequestTypes.CREATE;

    const nProject = {
      ...project,
      attributes: {
        ...project.attributes,
        isFavorited,
      },
      relationships: {
        ...project.relationships,
        projectUserStars,
      },
    };
    blueChipActions.updateResources({ data: nProject });
  };
}

export function unstarProject(dataComponentId, projectUserStar, project) {
  return async dispatch => {
    await dispatch(performDeleteRequest(dataComponentId, projectUserStar.id));
    dispatch(
      updateProjectResource(project.id, RequestTypes.DELETE, dataComponentId)
    );
    dispatch(setReload("dataComponentProjectUserStarsId", true));
  };
}

export function starProject(dataComponentId, project, userId) {
  return async dispatch => {
    await dispatch(
      performCreateRequest(dataComponentId, {
        projectId: project.id,
        userId,
      })
    );
    dispatch(
      updateProjectResource(project.id, RequestTypes.CREATE, dataComponentId)
    );
    dispatch(setReload("dataComponentProjectUserStarsId", true));
  };
}
