import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import TagText from "../../ui/Tags/TagText";
import { titleCase } from "../../../utils/stringUtils";
import { BWIntent } from "../../ui/BWIntent";

const STATUS_TO_INTENT = {
  locked: BWIntent.DANGER,
  "requires approval": BWIntent.WARNING,
  watch: BWIntent.WATCH,
};

const VendorStatusTag = styled(({ children, ...props }) =>
  children ? (
    <TagText {...props} intent={STATUS_TO_INTENT[children]}>
      {titleCase(children)}
    </TagText>
  ) : (
    <div />
  )
)`
  margin-left: 6px;
  min-width: 99px;
`;

VendorStatusTag.propTypes = {
  children: PropTypes.string,
};

export default VendorStatusTag;
