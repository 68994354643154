import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  openModalDialog,
  closeModalDialog,
} from "../../../../../actions/layoutActions";
import NoteSection from "./NoteSection";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { dataComponentId } from "../../ProjectDetailContainer";

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return { dataComponent };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { openModalDialog, closeModalDialog },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteSection);
