import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BidGroupForm from "./BidGroupForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  refreshBidGroupsList,
  quotingBidGroupsDCId,
  fetchAllBidGroups,
} from "../../../../../actions/quotingActions";
import { dataComponentId } from "../../UnassignedSpecs/UnassignedOnBidGroups/UnassignedSpecsContainer";
import {
  cloneDataComponent,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../../utils/dataComponentUtils";

import { bidGroupSpecDetailId } from "../BidGroupListContainer";

export const processRequestResponse = (
  { prevDataComponent, dataComponent },
  { closeModalDialog, setReload, refreshBidGroupsList, projectId },
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      refreshBidGroupsList(projectId)(0);
    },
    onError: error => handleRequestError(error, formikActions),
  });
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      refreshBidGroupsList(projectId)(0);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const CreateBidGroupContainer = ({
  projectId,
  dataComponent,
  closeModalDialog,
  performCreateRequest,
  performDeleteRequest,
  setReload,
  refreshBidGroupsList,
  selectedIds,
  editBidGroup,
  performUpdateRequest,
  cloneDataComponent,
  ...props
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    cloneDataComponent(
      bidGroupSpecDetailId,
      `${bidGroupSpecDetailId}-clone`,
      true,
      false
    );
  }, [cloneDataComponent]);

  useEffect(() => {
    processRequestResponse(
      { prevDataComponent, dataComponent },
      { closeModalDialog, setReload, refreshBidGroupsList, projectId },
      formikActions
    );
  }, [
    closeModalDialog,
    dataComponent,
    formikActions,
    prevDataComponent,
    setReload,
    refreshBidGroupsList,
    projectId,
  ]);

  const handleCreateBid = useCallback(
    (bidGroup, formikActions) => {
      const { currentBidGroup, resetSelection } = props;
      if (currentBidGroup) {
        const filteredBidGroupSpecs = currentBidGroup.bidGroupSpecs.filter(
          ({ specId }) => selectedIds.includes(specId)
        );
        performDeleteRequest(
          `${bidGroupSpecDetailId}-clone`,
          filteredBidGroupSpecs.map(({ id }) => id)
        );
        resetSelection && resetSelection([]);
      }

      performCreateRequest(quotingBidGroupsDCId, {
        ...bidGroup,
        specs: selectedIds.map(id => ({ id })),
      });
      setFormikAction(formikActions);
    },
    [performCreateRequest, performDeleteRequest, props, selectedIds]
  );

  const handleUpdateBid = useCallback(
    (bidGroup, formikActions) => {
      const { currentBidGroup } = props;
      performUpdateRequest(quotingBidGroupsDCId, currentBidGroup.id, {
        name: bidGroup.name,
      });
      setFormikAction(formikActions);
    },
    [performUpdateRequest, props]
  );

  const initialValues = useMemo(
    () => ({
      name: editBidGroup ? props.currentBidGroup.name : undefined,
      projectId,
    }),
    [editBidGroup, projectId, props]
  );

  return (
    <BidGroupForm
      initialValues={initialValues}
      onSubmit={editBidGroup ? handleUpdateBid : handleCreateBid}
    />
  );
};

CreateBidGroupContainer.propTypes = {
  projectId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  performCreateRequest: PropTypes.func,
  performDeleteRequest: PropTypes.func,
  setReload: PropTypes.func,
  refreshBidGroupsList: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  currentBidGroup: propTypes.bidGroup,
  setSelection: PropTypes.func,
  cloneDataComponent: PropTypes.func,
  editBidGroup: PropTypes.bool,
};

export const mapDispatchToProps = dispatch => ({
  refreshBidGroupsList: (...props) => pageNumber => {
    dispatch(refreshBidGroupsList(...props)(pageNumber));
    dispatch(fetchAllBidGroups(...props));
  },
  ...bindActionCreators(
    {
      closeModalDialog,
      performCreateRequest,
      performUpdateRequest,
      performDeleteRequest,
      setReload,
      cloneDataComponent,
    },
    dispatch
  ),
});

const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(quotingBidGroupsDCId, state);
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const selectedIds =
    ownProps.selectedIds || flattenedDataComponent.selectedIds;
  return {
    dataComponent,
    selectedIds,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBidGroupContainer);
