import React from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";

import DangerConfirmation from "../../../ui/DangerConfirmation";

const PopoverDeleteConfirmation = ({
  anchorEl,
  onDelete,
  onClose,
  title,
  ...popoverProps
}) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "center",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...popoverProps}
  >
    <DangerConfirmation
      onDanger={onDelete}
      actionMessage="Delete this Note"
      dangerButtonText="DELETE"
      cancelButtonText="DISMISS"
      onCancel={onClose}
    />
  </Popover>
);

PopoverDeleteConfirmation.propTypes = {
  anchorEl: PropTypes.shape({}),
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default PopoverDeleteConfirmation;
