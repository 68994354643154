import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";

import propTypes from "../../../../constants/propTypes";
import { getOptionsFrom, getColumns, getEditableOptions } from "./gridOptions";
import { GridWrapper } from "./components";
import tableComponents from "./tableComponents";
import GridMenu from "./GridMenu";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import { usePrevious } from "../../../hooks/usePrevious";

const pageSize = 10;
export const gridId = "spec-import-data-edition";

const SpecsList = ({
  specs,
  vendors,
  areas,
  categories,
  unitOfMeasures,
  isLoading,
  onUpdateAttributes,
  isFromProjects,
  projectCurrencies,
  poProjectCurrency,
  setDisplayUpdateSpecs,
  isImportToPO,
}) => {
  const columnOptions = useMemo(
    () =>
      getEditableOptions(
        {
          vendors: getOptionsFrom(specs, "vendorId", vendors),
          categories: getOptionsFrom(
            specs,
            "specCategoryId",
            categories.filter(({ isActive }) => isActive)
          ),
          specCategories: categories.filter(({ isActive }) => isActive),
          unitOfMeasures: getOptionsFrom(
            specs,
            "unitOfMeasureId",
            unitOfMeasures
          ),
          areas: getOptionsFrom(specs, "areaId", areas),
          projectCurrencies: poProjectCurrency
            ? [poProjectCurrency]
            : getOptionsFrom(specs, "projectCurrencyId", projectCurrencies),
          fullProjectCurrencies: poProjectCurrency
            ? [poProjectCurrency]
            : projectCurrencies,
        },
        onUpdateAttributes,
        isFromProjects,
        specs,
        poProjectCurrency,
        isImportToPO
      ),
    [
      specs,
      vendors,
      categories,
      unitOfMeasures,
      areas,
      poProjectCurrency,
      projectCurrencies,
      isImportToPO,
      onUpdateAttributes,
      isFromProjects,
    ]
  );

  const columns = useMemo(() => getColumns(isFromProjects, specs), [
    isFromProjects,
    specs,
  ]);

  const { selectAll } = useWithSelectedRowContext();
  const prevSpecs = usePrevious(specs);
  useEffect(() => {
    if (prevSpecs && !prevSpecs.length && specs.length) {
      selectAll(specs, pageSize);
    }
  }, [prevSpecs, selectAll, specs]);

  return (
    <GridWrapper>
      <BWGridLocal
        noBorder
        className="grid-root"
        id={gridId}
        emptyStateText="Please select a source first"
        rows={specs}
        tableComponents={tableComponents(onUpdateAttributes)}
        gridConfig={{ pageSize, totalRows: specs.length }}
        isLoading={isLoading}
        alwaysDisplayDetailRow
        showSelectionColumn
        showSelectAll
      >
        <GridHeader
          headerText="Spec"
          withCounter
          headerOverride={
            <GridMenu setDisplayUpdateSpecs={setDisplayUpdateSpecs} />
          }
        />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
    </GridWrapper>
  );
};

SpecsList.defaultProps = {
  vendors: [],
  areas: [],
  isFromProjects: true,
  categories: [],
};

SpecsList.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  areas: PropTypes.arrayOf(propTypes.area),
  categories: PropTypes.arrayOf(propTypes.specCategory),
  vendors: PropTypes.arrayOf(propTypes.vendor),
  unitOfMeasures: PropTypes.arrayOf(propTypes.unitOfMeasure),
  isLoading: PropTypes.bool,
  isFromProjects: PropTypes.bool,
  onUpdateAttributes: PropTypes.func.isRequired,
  projectCurrencies: PropTypes.array,
  poProjectCurrency: PropTypes.object,
  setDisplayUpdateSpecs: PropTypes.func.isRequired,
  isImportToPO: PropTypes.bool,
};

export default SpecsList;
