import React, { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Paper } from "@material-ui/core";

import Subheader from "../../../../ui/Typography/Subheader";
import SerifTitle from "../../../../ui/Typography/SerifTitle";
import RegularText from "../../../../ui/Typography/RegularText";
import propTypes from "../../../../../constants/propTypes";
import { REQUIRES_APPROVAL_STATUS } from "../../../../../constants/vendor";
import InputLoader from "../../../../ui/Loader/InputLoader";
import Loader from "../../../../ui/Loader";
import { PlaceholderList } from "./components";
import AddVendorMenu from "./AddVendorMenu";
import {
  getAddress,
  getAddressComplement,
} from "../ShipToSite/CurrentAddresses";
import { TitleTag } from "../../../../layout/DetailAndCycle";
import { CollapseButton } from "../../../../ui/CollapseButton";
import { Person } from "@material-ui/icons";
import useScope from "../../../../hooks/useScope";
import VendorStatusTag from "../../../Vendors/VendorStatusTag";

const NoContactWrapper = styled.div`
  align-self: center;
  padding-top: 24px;
`;

const NoContactIcon = styled(Person)`
  color: #ff1744;
`;

export const VendorContainer = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    background: #f5f5f5;
  }
  padding: 12px 24px 0 24px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom && "24px"};
  ${RegularText} {
    display: block;
  }
`;

const VendorHeaderContainer = styled.header`
  position: sticky;
  top: 0;
  align-self: start;
  background: white;
  z-index: 1;
  ${Subheader} {
    margin-bottom: 12px;
    padding: 24px 0px 0px 24px;
  }
  ${SerifTitle} {
    margin-bottom: 4px;
  }

  ${TitleTag} {
    margin-bottom: 8px;
  }
`;

export const VendorName = ({ vendor, padding }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding,
      }}
    >
      <SerifTitle>{_get(vendor, "name")}</SerifTitle>
      {_get(vendor, "status") &&
      vendor.status.includes(REQUIRES_APPROVAL_STATUS) ? (
        <VendorStatusTag>requires approval</VendorStatusTag>
      ) : null}
    </div>
  );
};
VendorName.propTypes = {
  vendor: propTypes.vendor,
  padding: PropTypes.string,
};

export const VendorInfo = ({
  vendor,
  disabledOptions,
  onClick,
  paddingBottom,
}) => {
  return (
    <VendorContainer paddingBottom={paddingBottom}>
      <div>
        <VendorName vendor={vendor} />
        <RegularText>
          <RegularText as="span">
            <Loader height="20px" width="250px">
              {getAddress(vendor)}
            </Loader>
          </RegularText>
          <RegularText as="span">
            <Loader height="20px" width="250px">
              {getAddressComplement(vendor)}
            </Loader>
          </RegularText>
        </RegularText>
      </div>
      <div>
        {!disabledOptions && (
          <IconButton onClick={onClick} aria-label="Edit">
            <EditIcon />
          </IconButton>
        )}
      </div>
    </VendorContainer>
  );
};
VendorInfo.propTypes = {
  vendor: propTypes.vendor,
  disabledOptions: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  onClick: PropTypes.func,
};

const SubHeaderWrapper = styled.div`
  display: flex;
  place-items: flex-end;
  justify-content: space-between;
`;

const VendorHeader = ({
  vendor,
  title,
  subtitle = null,
  dataComponentId,
  disabledOptions,
  noContacts,
  isCollapsed,
  setIsCollapsed,
  required = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const scope = useScope();

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Fragment>
      <VendorHeaderContainer>
        <Paper elevation={0}>
          <SubHeaderWrapper>
            <Subheader required={required} emphasizeRequired>
              {title}
            </Subheader>
            {noContacts ? (
              <NoContactWrapper>
                <NoContactIcon />
              </NoContactWrapper>
            ) : null}
            {setIsCollapsed ? (
              <CollapseButton
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
              />
            ) : null}
          </SubHeaderWrapper>
          {subtitle}
        </Paper>
      </VendorHeaderContainer>
      {isCollapsed ? null : (
        <Fragment>
          <InputLoader>
            {vendor.id ? (
              <VendorInfo
                vendor={vendor}
                disabledOptions={disabledOptions}
                onClick={handleClick}
                paddingBottom
              />
            ) : (
              <PlaceholderList
                disabledOptions={disabledOptions}
                onClick={handleClick}
              />
            )}
          </InputLoader>
          <AddVendorMenu
            dataComponentId={dataComponentId}
            anchorEl={anchorEl}
            onClose={handleClose}
            scopeId={scope.id}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

VendorHeader.propTypes = {
  vendor: propTypes.vendor,
  vendorSpec: PropTypes.bool,
  dataComponentId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  disabledOptions: PropTypes.bool,
  noContacts: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  required: PropTypes.bool,
};

VendorHeader.defaultProps = {
  vendor: {},
  title: "Vendor Information",
};

export default VendorHeader;
