import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import propTypes from "../../../../../../constants/propTypes";
import { EditLink } from "../components";

const Wrapper = styled.div`
  padding-right: 24px;
`;

export const DownloadQuoteFilesLink = ({ bidGroupVendor, openModalDialog }) => {
  if (!bidGroupVendor?.quoteFiles?.length) {
    return null;
  }

  const openDownloadLink = () => {
    openModalDialog(
      "Download Quote Files",
      "DownloadQuoteFiles",
      {
        bidGroupVendor,
      },
      true,
      true
    );
  };

  return (
    <Wrapper>
      <EditLink onClick={openDownloadLink}>Download Quote Files</EditLink>
    </Wrapper>
  );
};

DownloadQuoteFilesLink.propTypes = {
  openModalDialog: PropTypes.func,
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
};
