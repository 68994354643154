import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

export default columns => {
  const EmptyTypeProvider = () => {
    return <DataTypeProvider for={columns} editorComponent={() => null} />;
  };
  EmptyTypeProvider.propTypes = {};
  return EmptyTypeProvider;
};
