import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import { connect } from "react-redux";

import { setNoteFilters } from "../../../../actions/notesActions";
import RegularText from "../../../ui/Typography/RegularText";
import NoteIcon from "../NoteIcon";
import { TooltipWrapper } from "./TooltipWrapper";

const List = styled.ul`
  position: relative;
  padding: 16px 0 8px;
  list-style: none;
  margin: 0;
  margin-bottom: 8px;
  &:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
  }
`;

const Item = styled(({ active, ...props }) => <li {...props} />)`
  padding: 6px 5px;
  cursor: pointer;
  ${RegularText} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.54);
    background-color: ${({ active }) =>
      active ? "rgba(87, 171, 255, 0.24)" : undefined};
    padding: 8px 11px;
  }
  &:hover {
    ${RegularText} {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;

const CategoryText = styled(RegularText)`
  @media (max-width: 1170px) {
    text-align: center;
    display: block !important;
  }

  @media (max-width: 768px) {
    text-align: initial;
    display: flex !important;
  }
`;

const FlowingText = styled.span`
  @media (max-width: 1170px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: initial;
  }
`;

const CategoryList = ({ currentCategoryId, setNoteFilters }) => {
  const categories = BWModels.loadSchema("Note").__utils.categories;

  const handleChangeCategory = category => setNoteFilters({ category });

  return (
    <List>
      <Item
        active={!currentCategoryId}
        onClick={() => handleChangeCategory(null)}
      >
        <CategoryText>
          <FlowingText>All</FlowingText>
          <TooltipWrapper title={"All"}>
            <NoteIcon category={"All"} />
          </TooltipWrapper>
        </CategoryText>
      </Item>
      {categories.map(category => (
        <Item
          key={category.id}
          active={currentCategoryId === category.id}
          onClick={() => handleChangeCategory(category.id)}
        >
          <CategoryText>
            <FlowingText>{category.name}</FlowingText>
            <TooltipWrapper title={category.name}>
              <NoteIcon category={category.id} />
            </TooltipWrapper>
          </CategoryText>
        </Item>
      ))}
    </List>
  );
};
CategoryList.propTypes = {
  currentCategoryId: PropTypes.string,
  setNoteFilters: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  notes: {
    filters: { category: currentCategoryId },
  },
}) => ({ currentCategoryId });

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
