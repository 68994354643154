import create from "zustand";
import { devtools } from "zustand/middleware";
export { FORM_IDS } from "./constants";
import { initialState } from "./constants";
import {
  resetStoreReducer,
  setPOReducer,
  openCreateSpecReducer,
  openCopySpecReducer,
  openDuplicateSpecReducer,
  openMainOptionsReducer,
  openMoveSpecReducer,
  activateFiltersReducer,
} from "./reducers";

export default create(
  devtools(
    set => ({
      ...initialState,
      actions: {
        setPO: setPOReducer(set),
        openMainOptions: openMainOptionsReducer(set),
        openCreateSpec: openCreateSpecReducer(set),
        openMoveSpec: openMoveSpecReducer(set),
        openCopySpec: openCopySpecReducer(set),
        openDuplicateSpec: openDuplicateSpecReducer(set),
        activateFilters: activateFiltersReducer(set),
        resetStore: resetStoreReducer(set),
      },
    }),
    "AddSpecToPO"
  )
);
