import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import SearchResult from "../../../../models/SearchResult";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";

import SearchOption from "./SearchOption";
const dataComponentId = "search-results";

export const mapDispatchToProps = dispatch => ({
  search: query => {
    return dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          query,
        },
      })
    );
  },
  initSearch: () => {
    return dispatch(
      initDataComponent(dataComponentId, SearchResult, [], "global-search")
    );
  },
  pushWithReturnUrl: bindActionCreators(pushWithReturnUrl, dispatch),
  initDataComponent,
});

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const results = getBluechipResources(dataComponent, state);
  return {
    dataComponent,
    results: results || [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOption);
