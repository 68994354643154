import React, { Fragment, useCallback } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";

import CheckboxWithError from "../../../inputs/CheckboxWithError";
import { InputLabel } from "../../../mui/core";
import { WorkScopeSubWorkScope } from "./WorkScopeSubWorkScope";
import Grid from "../../../mui/core/Grid";
import { Divider } from "@material-ui/core";

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const isWorkScopeSelected = (workScopes, workScope) =>
  workScopes.some(({ id }) => id == workScope.id);

export const WorkScopeFields = ({ workScopeOptions }) => {
  const {
    values: { id: projectId, workScopes = [] },
    setFieldValue,
  } = useFormikContext();

  const handleChange = useCallback(
    targetWorkScope => ({ target: { value: isAdding } }) => {
      const updatedWorkScopes = isAdding
        ? [...workScopes, targetWorkScope]
        : workScopes.filter(workScope => workScope.id != targetWorkScope.id);

      setFieldValue("workScopes", updatedWorkScopes);
    },
    [setFieldValue, workScopes]
  );

  return (
    <Wrapper>
      <Grid container>
        {workScopeOptions.map((workScope, i) => (
          <Fragment key={i}>
            <Grid item xs={12}>
              <CheckboxWithError
                key={workScope.id}
                label={workScope.name}
                value={isWorkScopeSelected(workScopes, workScope)}
                onChange={handleChange(workScope)}
              />
            </Grid>
            {isWorkScopeSelected(workScopes, workScope) ? (
              <Fragment>
                <Grid item xs={12}>
                  <InputLabel>{workScope.name} Subscopes</InputLabel>
                </Grid>
                {workScope.workScopeSubWorkScopes.map(workScopeSubWorkScope => (
                  <Grid key={workScopeSubWorkScope.id} item xs={4}>
                    <WorkScopeSubWorkScope
                      workScopeSubWorkScope={workScopeSubWorkScope}
                      projectId={projectId}
                    />
                  </Grid>
                ))}
              </Fragment>
            ) : null}
            <Divider
              style={{
                width: "100%",
                marginTop: isWorkScopeSelected(workScopes, workScope)
                  ? "12px"
                  : 0,
              }}
            />
          </Fragment>
        ))}
      </Grid>
    </Wrapper>
  );
};

WorkScopeFields.propTypes = {
  workScopeOptions: PropTypes.array.isRequired,
};
