import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { EditRequirementType } from "./EditRequirementType";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { requirementTypeDataComponentId } from "../RequirementTypeContainer";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../utils/formValidationUtils";
import propTypes from "../../../../../constants/propTypes";

const EditRequirementTypeContainer = ({
  requirement,
  dataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Requirement type saved successfully.");
      setReload(requirementTypeDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleEditRequirementType = values => {
    performUpdateRequest(
      requirementTypeDataComponentId,
      requirement.id,
      values
    );
  };

  return (
    <EditRequirementType
      requirement={requirement}
      handleEditRequirementType={handleEditRequirementType}
      closeModalDialog={closeModalDialog}
    />
  );
};

EditRequirementTypeContainer.propTypes = {
  requirement: propTypes.requirement.isRequired,
  dataComponent: propTypes.dataComponent,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(requirementTypeDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRequirementTypeContainer);
