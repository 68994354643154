import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { initProjectDetail } from "../../../actions/projectsActions";
import * as actionTypes from "../../../constants/ActionTypes";
import { getDefaultProjectCurrency } from "../../helpers";

const ProjectLoader = ({ projectId, project }) => {
  const dispatch = useRef(useDispatch());

  useEffect(() => {
    if (projectId) {
      dispatch.current(initProjectDetail(projectId));
    } else {
      dispatch.current({ type: actionTypes.RESET_SCOPED_CURRENCY });
      dispatch.current({ type: actionTypes.RESET_SCOPE });
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      const projectCurrency = getDefaultProjectCurrency(
        project.projectCurrencies
      );
      if (projectCurrency?.currency) {
        dispatch.current({
          type: actionTypes.SET_SCOPED_CURRENCY,
          payload: projectCurrency?.currency,
        });
      }
      dispatch.current({
        type: actionTypes.SET_SCOPE,
        payload: project.property?.entity?.client?.scope,
      });
    }
  }, [project]);

  return null;
};

export default ProjectLoader;
