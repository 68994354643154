import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import { noteDetailOpen } from "../../../../actions/notesActions";
import NoteIcon from "../NoteIcon";
import NoteHeader from "./NoteHeader";
import Loader from "../../../ui/Loader/Loader";
import NoteDetail from "./NoteDetail";

export const NoteWrapper = styled.div`
  margin-bottom: 8px;
  padding: 8px;
  padding-right: 16px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const DescriptionWrapper = styled.div`
  display: inline-block;
  margin-left: -4px;
  width: calc(100% - 24px);
  vertical-align: top;
`;

const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
`;

const NotePreview = styled.div`
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 20px;
`;

export const Note = ({ note, noteDetailOpen }) => (
  <NoteWrapper onClick={() => noteDetailOpen(note.id)}>
    <NoteIcon category={note.category} isHighlighted={true} />
    <DescriptionWrapper>
      <DescriptionColumn>
        <NoteHeader note={note} />
        <NotePreview>
          <Loader height="40px">
            <NoteDetail note={note} />
          </Loader>
        </NotePreview>
      </DescriptionColumn>
    </DescriptionWrapper>
  </NoteWrapper>
);

Note.propTypes = {
  note: propTypes.note,
  noteDetailOpen: PropTypes.func,
};

const mapDispatchToProps = {
  noteDetailOpen,
};

export default connect(null, mapDispatchToProps)(Note);
