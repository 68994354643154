import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import Requirement from "../../../../models/Requirement";

export const columns = ["name"];

export const columnOptions = {
  name: { title: "Name", filter: true },
};

const rowMenu = ({ handleDeleteRequirement, onOpenEditModal }) => [
  {
    text: "Edit",
    onClick: onOpenEditModal,
  },
  {
    text: "Delete",
    onClick: handleDeleteRequirement,
  },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Requirement Type",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const RequirementType = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  handleDeleteRequirement,
}) => {
  return (
    <BWGridAPI
      dataComponent={dataComponent}
      model={Requirement}
      defaultSorting={[
        {
          columnName: "name",
          direction: "asc",
        },
      ]}
      apiRoute="requirements"
      includes={[]}
    >
      <GridHeader
        headerText={`${dataComponent.totalRows} Requirements Types`}
        actions={actions(onOpenCreateModal)}
      />
      <GridTable
        columns={columns}
        columnOptions={columnOptions}
        rowMenu={rowMenu({ handleDeleteRequirement, onOpenEditModal })}
      />
    </BWGridAPI>
  );
};

RequirementType.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  handleDeleteRequirement: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
};

export default RequirementType;
