import React from "react";
import pluralize from "pluralize";
import _get from "lodash/get";
import PropTypes from "prop-types";

import Paper from "../../../mui/core/Paper";

import propTypes from "../../../../constants/propTypes";
import Shipment from "../../../../models/Shipment";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import VendorColumn from "./VendorColumn";
import CustomNumberColumn from "./CustomNumberColumn";
import Date from "../../../ui/BWGrid/columns/Date";
import NeedsFollowUpColumn from "../../ProjectDetail/Shipments/NeedsFollowUpColumn";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const columnOptions = {
  "spec.project.number": {
    title: "Project Number",
    bold: true,
    width: "73px",
  },
  "spec.purchaseOrder.number": {
    title: "PO Number",
    bold: true,
    width: "73px",
  },
  "spec.customNumber": {
    title: "Spec #",
    bold: true,
    width: "73px",
    render: CustomNumberColumn,
  },
  "spec.description": {
    fill: true,
    title: "Description",
    render: shipment => _get(shipment, "spec.description"),
  },
  "spec.purchaseOrder.vendor.name": {
    title: "Vendor",
    render: shipment => {
      return (
        shipment.spec && (
          <VendorColumn vendorId={shipment.spec.purchaseOrder.vendorId} />
        )
      );
    },
  },
  id: {
    title: "Delivery",
    render: shipment => `Ship ${shipment.id}`,
  },
  quantity: {
    render: shipment =>
      `${shipment.quantity} of ${_get(shipment, "spec.baseQuantity")}`,
  },
  estimatedShipDate: {
    title: "Est. Ship",
    render: function estimatedShipDate(shipment) {
      return (
        <Date date={shipment.estimatedShipDate} format={PRIMARY_DATE_FORMAT} />
      );
    },
  },
  estimatedDeliveryDate: {
    title: "Est. Delivery",
    render: function estimatedDeliveryDate(shipment) {
      return (
        <Date
          date={shipment.estimatedDeliveryDate}
          format={PRIMARY_DATE_FORMAT}
        />
      );
    },
  },
  actualShipDate: {
    title: "Actual Ship",
    render: shipment =>
      shipment && (
        <Date date={shipment.actualShipDate} format={PRIMARY_DATE_FORMAT} />
      ),
  },
  actualDeliveryDate: {
    title: "Actual Delivery",
    render: shipment =>
      shipment && (
        <Date date={shipment.actualDeliveryDate} format={PRIMARY_DATE_FORMAT} />
      ),
  },
  isNeedsFollowUp: {
    title: " ",
    render: NeedsFollowUpColumn,
    sortingEnabled: false,
  },
};

const ShipmentsNeedsFollowUpGrid = ({
  columns,
  dataComponent,
  onRowClick,
  apiFilters,
}) => {
  return (
    <Paper>
      <BWGridAPI
        apiRoute="shipments"
        model={Shipment}
        dataComponent={dataComponent}
        includes={[
          "spec.[project,purchaseOrder.[vendor, project.property.entity]]",
        ]}
        apiFilters={apiFilters}
      >
        <GridHeader
          headerText={`${pluralize(
            "Shipment",
            dataComponent.totalRows,
            true
          )} Needs Follow Up`}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={onRowClick}
        />
      </BWGridAPI>
    </Paper>
  );
};

ShipmentsNeedsFollowUpGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataComponent: propTypes.dataComponent,
  apiFilters: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default ShipmentsNeedsFollowUpGrid;
