import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import PopoverDeleteConfirmation from "../../../../ui/PopoverDeleteConfirmation";
import propTypes from "../../../../../constants/propTypes";
import { Delete } from "./components";

const DeleteSubmittalButton = ({ submittalFile, onSubmit, popoverProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );

  const handleDelete = useCallback(() => {
    onSubmit(submittalFile.id);
    handleClose();
  }, [handleClose, submittalFile.id, onSubmit]);

  return (
    <Fragment>
      <Tooltip title="Delete">
        <IconButton onClick={handleOpen}>
          <Delete />
        </IconButton>
      </Tooltip>
      <PopoverDeleteConfirmation
        title={submittalFile.file.filename}
        anchorEl={anchorEl}
        onDelete={handleDelete}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        {...popoverProps}
      />
    </Fragment>
  );
};

DeleteSubmittalButton.propTypes = {
  submittalFile: propTypes.submittalFile,
  onSubmit: PropTypes.func.isRequired,
  popoverProps: PropTypes.shape({}),
};

export default DeleteSubmittalButton;
