import Client from "../models/Client";
import { initDataComponent } from "../actions/dataComponentActions";
import {
  populateCountryResources,
  populateStateResources,
} from "./countryStateActions";

export function initializeClientDependencies(dataComponentId) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        Client,
        ["location", "msaFile"],
        "clients"
      )
    );

    dispatch(populateCountryResources());
    dispatch(populateStateResources());
  };
}
