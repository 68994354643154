import React from "react";

import { FormPageSection } from "../../../layout/FormPageSection";
import { createPOFields } from "./createPOFields";
import { LabeledTextContext } from "../../../../withPORevision";

const Section = formGridProps => {
  const { discount, purchaseOrder } = formGridProps.initialValues;
  const currency = purchaseOrder?.projectCurrency?.currency;
  return (
    <LabeledTextContext.Provider value={true}>
      <FormPageSection
        headerText="PO Data"
        fields={createPOFields(discount, currency)}
        {...formGridProps}
      />
    </LabeledTextContext.Provider>
  );
};

Section.propTypes = {};

export default Section;
