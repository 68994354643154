import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Delete from "@material-ui/icons/Delete";

export const AddCell = styled.span`
  font-size: 14px;
  color: #979797;
`;

export const StyledAdd = styled(CheckCircleIcon)`
  color: ${({ disabled }) => (disabled ? "#d8d8d8" : "#78CC18")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
`;

export const StyleDelete = styled(Delete)`
  color: #000000;
  cursor: pointer;
  margin-left: 8px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
