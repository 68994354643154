import { inputs } from "../../../../../components/inputs/inputConfigs";
import { SelectWithError as RawSelectWithError } from "../../../../inputs/SelectWithError";
import TextInputWithError from "../../../../inputs/TextInputWithError";

export default {
  resolutions: {
    InputComponent: RawSelectWithError,
    label: "Select",
    name: "resolutions",
    options: [
      { id: "new_po", name: "Move to a New Vendor" },
      { id: "unassign", name: "Unassign Specs" },
      { id: "delete", name: "Delete Specs" },
    ],
    fullWidth: true,
  },
  comments: {
    InputComponent: TextInputWithError,
    label: "Comments",
    name: "comments",
    fullWidth: true,
    multiline: true,
    rows: 4,
  },
  vendors: inputs.vendorId,
};
