import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../../components/forms/FormGrid";
import specDetailComFields from "./specDetailComFields";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";
import { Button } from "../../../../mui/core";
import { withRevisionReference } from "../../../../../withPORevision";

export const DeleteButton = styled(Button)`
  color: rgba(0, 0, 0, 0.54);
`;

class EditSpecDetailCOMForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    specDetailCom: propTypes.specDetailCom.isRequired,
  };

  render() {
    const { onSubmit, specDetailCom } = this.props;

    return (
      <FormikForm
        initialValues={specDetailCom}
        onSubmit={onSubmit}
        validationSchema={BWModels.loadSchema("SpecDetailCom")}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={specDetailComFields}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons onSend={handleSubmit} isModal={true} />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}

export default withRevisionReference(EditSpecDetailCOMForm);
