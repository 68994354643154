import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";

import HeaderContainer from "./HeaderContainer";
import ModalDialogContainer from "./ModalDialog/ModalDialogContainer";
import ProjectLoader from "./ProjectLoader";
import {
  closeNavbarDrawer,
  openNavbarDrawer,
  clearAutoSaveId,
} from "../../../actions/layoutActions";
import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import {
  getDataComponentFlattenedRequestState,
  getDataComponentRequestState,
} from "../../../reducers/dataComponentReducer";
import DrawerWrapper from "./DrawerWrapper";
import propTypes from "../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import Spec from "../../../models/Spec";
import { ProjectContext } from "../../hooks/useIsProjectClosed";

const projectDataComponentId = "ProjectDetail";
const specDataComponentId = "SpecDetailHeader";

export class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { project: this.props.project };
  }

  handleDrawerToggle = () => {
    this.props.isNavbarDrawerOpen
      ? this.props.closeNavbarDrawer()
      : this.props.openNavbarDrawer();
  };

  componentDidMount() {
    const { initDataComponent, performFindRequest, specId } = this.props;

    if (specId) {
      initDataComponent(specDataComponentId, Spec, [], "specs");
      performFindRequest(specDataComponentId, specId);
    }
  }
  componentDidUpdate(prevProps) {
    const { clearAutoSaveId, router, project } = this.props;
    if (
      prevProps.router.location.pathname !== router.location.pathname &&
      router.action !== "REPLACE" &&
      !router.location?.state?.keepAutoSaveComponentId
    ) {
      clearAutoSaveId();
    }

    if (prevProps.project !== project) {
      this.setState({ project });
    }
  }

  componentWillUnmount() {
    const { clearAutoSaveId } = this.props;
    clearAutoSaveId();
  }

  render() {
    const {
      headerTitle,
      isDetailPage,
      isNavbarDrawerOpen,
      modalDialog,
      autoSaveRequestState,
      autoSaveQueueState,
      projectId,
      spec,
    } = this.props;

    const { project } = this.state;

    const titleStrings = [`RPM ${global.appVersion}`];

    if (project) {
      titleStrings.push(`${project.number || "TBD"} ${project.name}`);
    }
    if (spec) {
      titleStrings.push(`${spec.customNumber} ${spec.description}`);
    }

    window.document.title = titleStrings.join(" : ");

    return (
      <ProjectContext.Provider
        value={{
          project,
          setProject: project => {
            this.setState({ project });
          },
        }}
      >
        <CssBaseline />
        <ProjectLoader projectId={projectId} project={project} />
        <ModalDialogContainer />
        <HeaderContainer
          headerTitle={headerTitle}
          isDetailPage={isDetailPage}
          isNavbarDrawerOpen={isNavbarDrawerOpen}
          autoSaveRequestState={autoSaveRequestState || {}}
          autoSaveQueueState={autoSaveQueueState}
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <DrawerWrapper
          modalDialog={modalDialog}
          isDetailPage={isDetailPage}
          isNavbarDrawerOpen={isNavbarDrawerOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          closeNavbarDrawer={this.props.closeNavbarDrawer}
        >
          {this.props.children}
        </DrawerWrapper>
      </ProjectContext.Provider>
    );
  }
}

AppLayout.propTypes = {
  isDetailPage: PropTypes.bool,
  modalDialog: propTypes.modalDialog,
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.object,
  openNavbarDrawer: PropTypes.func.isRequired,
  closeNavbarDrawer: PropTypes.func.isRequired,
  clearAutoSaveId: PropTypes.func.isRequired,
  autoSaveRequestState: propTypes.requestState,
  autoSaveQueueState: PropTypes.shape({}),
  autoSaveComponentId: PropTypes.string,
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  projectId: PropTypes.string,
  project: propTypes.project,
  specId: PropTypes.string,
  spec: propTypes.spec,
  router: PropTypes.shape({
    location: PropTypes.shape({}),
    action: PropTypes.string,
  }),
};

export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.children
    ? ownProps.children.props.match.params.projectId
    : null;
  const specId = ownProps.children
    ? ownProps.children.props.match.params.specId
    : null;

  const {
    layout: {
      isNavbarDrawerOpen,
      autoSaveComponentId,
      autoSaveQueueId,
      modalDialog,
    },
    router,
    queues,
  } = state;
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const specDataComponent = getDataComponentFlattenedRequestState(
    specDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const autoSaveQueueState = queues[autoSaveQueueId] || null;

  return {
    isNavbarDrawerOpen,
    autoSaveComponentId,
    autoSaveRequestState: autoSaveComponentId
      ? getDataComponentRequestState(autoSaveComponentId, state)
      : null,
    autoSaveQueueState,
    modalDialog,
    router,
    projectId,
    project: getBluechipResourceById(projectDataComponent, state, projectId),
    specId,
    spec: getBluechipResourceById(specDataComponent, state, specId),
  };
};

export const mapDispatchToProps = {
  openNavbarDrawer,
  closeNavbarDrawer,
  clearAutoSaveId,
  initDataComponent,
  performFindRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
