import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import RegularText from "../../../../ui/Typography/RegularText";
import BoldText from "../../../../ui/Typography/BoldText";

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  border: 1px solid #57abff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px;
`;

export const GridContainer = styled(Grid)`
  margin-bottom: 24px;
`;

export const Button = styled(IconButton)`
  color: #57abff;
  margin-right: 8px;
  padding: 4px;

  min-width: 24px;
  min-height: 24px;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

export const Text = styled(RegularText)`
  display: inline;
  margin: 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Date = styled(RegularText)`
  margin-left: 8px;
`;

export const Author = styled(BoldText)`
  white-space: nowrap;
`;
