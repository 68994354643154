import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";

import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import Contacts from "./Contacts";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { resendAccessEmail } from "../../../../actions/contactActions";

const dataComponentId = "ConsultingCompanies";
const gridDataComponentId = "ConsultingCompanyContacts";

export class ContactsContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { push, dataComponent } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => push("/consultingCompanies"),
    });
  }

  handleAddContact = () => {
    const { consultingCompany, openModalDialog } = this.props;

    openModalDialog(
      "Create Contact",
      "CreateConsultingCompanyContact",
      {
        consultingCompanyId: consultingCompany.id,
      },
      true
    );
  };

  handleEditContact = ({ id }) => {
    const { openModalDialog } = this.props;

    openModalDialog(
      "Edit Contact",
      "EditConsultingCompanyContact",
      {
        consultingCompanyContactId: id,
      },
      true
    );
  };

  handleDeleteContact = ({ id, contact }) => {
    const { openModalDialog } = this.props;
    const { firstName, lastName } = contact;
    const title = `${firstName} ${lastName}`;

    openModalDialog(
      `Delete ${title}`,
      "DeleteConsultingCompanyContact",
      {
        title,
        consultingCompanyContactId: id,
      },
      true,
      false
    );
  };

  handleResendAccessEmail = ({ contact }) => {
    this.props.resendAccessEmail(contact, "Client", "Consulting Company");
  };

  render() {
    const { gridDataComponent, consultingCompany } = this.props;

    if (!consultingCompany) {
      return null;
    }

    return (
      <Contacts
        gridDataComponent={gridDataComponent}
        consultingCompany={consultingCompany}
        handleAddContact={this.handleAddContact}
        handleEditContact={this.handleEditContact}
        handleDeleteContact={this.handleDeleteContact}
        handleResendAccessEmail={this.handleResendAccessEmail}
      />
    );
  }
}

ContactsContainer.propTypes = {
  push: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  gridDataComponent: propTypes.dataComponent,
  consultingCompanyId: PropTypes.string,
  consultingCompany: propTypes.consultingCompany,
  resendAccessEmail: PropTypes.func,
};

const mapDispatchToProps = {
  push,
  openModalDialog,
  initDataComponent,
  performFindRequest,
  resendAccessEmail,
};

export const mapStateToProps = (state, ownProps) => {
  const consultingCompanyId = ownProps.match.params.consultingCompanyId;
  const dataComponent = getDataComponent(dataComponentId, state);
  const gridDataComponent = getDataComponentFlattenedRequestState(
    gridDataComponentId,
    state
  );

  return {
    dataComponent,
    gridDataComponent,
    consultingCompanyId,
    consultingCompany: getBluechipResourceById(
      dataComponent,
      state,
      consultingCompanyId
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactsContainer)
);
