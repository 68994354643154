import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Paper } from "../../../../../mui/core";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 24px;
`;

const statusStyling = {
  Yes: {
    fontWeight: "inherit",
    color: "inherit",
  },
  No: {
    fontWeight: "bold",
    color: "red",
  },
};

const EmailAttempt = ({
  emailAttempt: { vendorName, name, email, success },
}) => {
  const status = success ? "Yes" : "No";
  return (
    <tr>
      <td style={{ paddingRight: "24px" }}>{vendorName}</td>
      <td style={{ paddingRight: "24px" }}>{name}</td>
      <td style={{ paddingRight: "24px" }}>{email}</td>
      <td
        style={{
          paddingRight: "24px",
          fontWeight: statusStyling[status].fontWeight,
          color: statusStyling[status].color,
        }}
      >
        {status}
      </td>
    </tr>
  );
};

EmailAttempt.propTypes = {
  emailAttempt: PropTypes.shape({
    vendorName: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    success: PropTypes.bool,
  }),
};

export const Summary = ({ sendRfqSummary }) => {
  return (
    <Wrapper>
      <Paper noBottomMargin noBorder>
        <h3>Email Deliveries</h3>
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Vendor</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th style={{ textAlign: "left" }}>Email</th>
              <th style={{ textAlign: "left" }}>Success</th>
            </tr>
          </thead>
          <tbody>
            {sendRfqSummary.map(({ emailAttempts }, key) => (
              <Fragment key={key}>
                {emailAttempts.map((emailAttempt, key) => (
                  <EmailAttempt key={key} emailAttempt={emailAttempt} />
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </Paper>
    </Wrapper>
  );
};

Summary.propTypes = {
  sendRfqSummary: PropTypes.array,
};
