// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`VendorShipToSitee Model belongsTo relations 1`] = `
Array [
  "Vendor",
  "Location",
]
`;

exports[`VendorShipToSitee Model hasMany relations 1`] = `
Array [
  "VendorContact",
]
`;
