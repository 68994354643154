import React from "react";
import { connect } from "formik";
import styled from "styled-components";
import _get from "lodash/get";

import propTypes from "../../../../constants/propTypes";
import { COM_PREVIEW } from "../../../../constants/specs";

const Img = styled.img`
  width: 121px;
`;

export const FilePreview = ({ formik: { values } }) => {
  const isUpdated = _get(values, "preview.isUpdated");
  if (isUpdated) {
    return <Img src={URL.createObjectURL(values.preview.file)} />;
  }
  const url = _get(values, "preview.s3SignedUrl", COM_PREVIEW);
  return <Img src={url} />;
};

FilePreview.propTypes = {
  formik: propTypes.formik,
};

export default connect(FilePreview);
