import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import statusFormFields from "./statusFormFields";
import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";
import { useCreateProcessRequests } from "../../../../../hooks/useProcessRequest";

const StatusForm = ({
  onSubmit,
  loading,
  isPaid,
  funding,
  toggleShowList,
  dataComponent,
  performAfterCreatePayment,
  showSnackNotificationAction,
}) => {
  const initialValues = useMemo(() => {
    return { fundingId: funding.id };
  }, [funding]);

  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      performAfterCreatePayment();
      toggleShowList();
    },
    onError: error => {
      showSnackNotificationAction(error.title);
    },
  });

  const validationSchema = useMemo(
    () => BWModels.loadSchema("FundingPayment"),
    []
  );

  const { projectCurrency } = funding;

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={statusFormFields(projectCurrency?.currency)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            isModal
            onSend={handleSubmit}
            onCancel={toggleShowList}
            sendButtonText="Save Payment"
            hideDangerButton={loading || isPaid}
            listeners={[dataComponent.dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

StatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  number: PropTypes.string,
  initialValues: propTypes.glCode,
  showSnackNotificationAction: PropTypes.func,
  toggleShowList: PropTypes.func,
};

export default StatusForm;
