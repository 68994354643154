import React, { useState } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import BidGroupHeader from "./BigGroupHeader";
import SpecsGrid from "./SpecsGrid";
import { Paper } from "../../../../mui/core";

export const BidGroupTable = ({
  bidGroup,
  bidGroups,
  specCategories,
  onOpenCreateBidModal,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  if (isCollapsed) {
    return (
      <Paper>
        <BidGroupHeader
          bidGroup={bidGroup}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          onOpenCreateBidModal={onOpenCreateBidModal}
          bidGroups={bidGroups}
        />
      </Paper>
    );
  }

  return (
    <SpecsGrid
      bidGroup={bidGroup}
      bidGroups={bidGroups}
      specCategories={specCategories}
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      onOpenCreateBidModal={onOpenCreateBidModal}
    />
  );
};

BidGroupTable.propTypes = {
  bidGroup: propTypes.bidGroup,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  specCategories: PropTypes.arrayOf(propTypes.specCategory),
  onOpenCreateBidModal: PropTypes.func,
};

export default BidGroupTable;
