import React, { Fragment } from "react";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { Title } from "../../../ui/Typography/SectionTitle";
import Paper from "../../../mui/core/Paper";
import { GridLocalSection } from "../../../layout/GridLocalSection";
import POStatusTag from "../../../ui/POStatusTag";

export const Wrapper = styled(Paper)`
  margin-top: 24px;
  table {
    min-width: auto !important;
  }
  ${Title} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const GridLocal = styled(GridLocalSection)`
  margin-top: 0;
  tbody tr:last-child td {
    border-bottom: none;
  }
`;

export const PODatesContainer = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
`;

export const StyledArrowForward = styled(ArrowForward)`
  color: rgba(0, 0, 0, 0.12);
  align-self: center;
  height: 16px;
`;

export const PODatesTitle = styled.div`
  margin-right: 4px;
`;

export const SpanError = styled.span`
  color: red;
  padding: 10px 25px 10px 25px;
  line-height: 4;
`;

export const StyledSpanInstruction = styled.span`
  color: rgba(0, 0, 0, 0.38);
`;

export const HeaderOverride = ({ purchaseOrder }) => (
  <Fragment>
    <POStatusTag purchaseOrder={purchaseOrder} spaceBetween={false} />
  </Fragment>
);
HeaderOverride.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
};
