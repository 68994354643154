import React from "react";
import styled from "styled-components";
import { Button as MuiButton } from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";
import PropTypes from "prop-types";
import get from "lodash.get";

import Column from "./Column";
import { StyledEdit } from "../../../Quoting/PurchaseOrderList/components";
import { inputs } from "../../../../inputs/inputConfigs";
import { StyledListItemIcon } from "../../../../ui/BWActionButton";
import { BWIntent } from "../../../../ui/BWIntent";
import formatter from "../../../../../utils/currencyFormatter";

export const buildColumn = (
  isEditable,
  renderValue,
  isPercentage,
  pricingTiers
) => (row, path, rowData) => {
  return (
    row && (
      <Column
        row={row}
        rowData={rowData}
        path={path}
        isEditable={isEditable}
        renderValue={renderValue}
        isPercentage={isPercentage}
        pricingTiers={pricingTiers}
      />
    )
  );
};

const Button = styled(MuiButton)`
  padding: 0;
  &:hover {
    background-color: unset;
  }
`;
const RemoveButton = ({ onRemoveAction, ...buttonProps }) => {
  return (
    <Button
      onClick={onRemoveAction}
      style={{ padding: 0 }}
      disableRipple
      disableFocusRipple
      {...buttonProps}
    >
      <StyledListItemIcon style={{ color: BWIntent.values.buttons.danger }}>
        <RemoveCircle />
      </StyledListItemIcon>
    </Button>
  );
};
RemoveButton.propTypes = {
  onRemoveAction: PropTypes.func.isRequired,
};

export const getFeeRangeMax = row =>
  row.feeRangeMax || row.ffeSpendTo * (row.tierPercentage / 100);

export const getColumnOptions = ({
  onRemoveAction,
  pricingTiers,
  isEditable,
  onFfeSpendToUpdate,
  syncMinAmount,
}) => ({
  tierTitle: {
    title: " ",
    render: row => (row.sequenceIndex ? `Tier ${row.sequenceIndex}` : ""),
  },
  ffeSpendFrom: {
    title: "FFE Spend",
    render: buildColumn(false),
  },
  ffeSpendTo: {
    title: " ",
    render: buildColumn(isEditable, null, null, pricingTiers),
    editableOptions: {
      afterSave: onFfeSpendToUpdate,
    },
  },
  tierPercentage: {
    title: "Greater of",
    render: buildColumn(isEditable, null, true),
    editableOptions: {
      afterSave: syncMinAmount("tierPercentage"),
    },
  },
  minimumAmount: {
    title: " ",
    render: buildColumn(false),
  },
  feeRangeMin: {
    title: "Fee Range",
    render: buildColumn(false, row => row.minimumAmount),
  },
  feeRangeMax: {
    title: " ",
    render: buildColumn(false, getFeeRangeMax, null, pricingTiers),
  },
  actions: {
    title: " ",
    render: function renderRemoveButton(_, __, { rowId } = {}) {
      return isEditable && rowId !== 1 && rowId == pricingTiers.length - 1 ? (
        <RemoveButton onRemoveAction={onRemoveAction} />
      ) : null;
    },
    width: "40px",
  },
});

export const columns = [
  "tierTitle",
  "ffeSpendFrom",
  "ffeSpendTo",
  "tierPercentage",
  "minimumAmount",
  "feeRangeMin",
  "feeRangeMax",
  "actions",
];

export const columnsWithBorder = ["tierTitle", "ffeSpendTo", "minimumAmount"];
const editableColumns = ["ffeSpendTo", "tierPercentage"];

export const isCellEditable = editable => ({ row, rowId }, { name }) => {
  if (typeof row[name] == "string" || !editable) {
    return false;
  }

  return (
    (name == "minimumAmount" && rowId == 1) || editableColumns.includes(name)
  );
};

export const rowInputs = {
  minimumAmount: {
    ...inputs.price,
    name: "minimumAmount",
    label: "Greater of - Minimum",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "100px",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
  ffeSpendTo: {
    ...inputs.price,
    name: "ffeSpendTo",
    label: "FFE Spend to",
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "100px",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
  tierPercentage: {
    ...inputs.saleTaxPercent,
    name: "tierPercentage",
    label: "Tier Percentage",
    InputProps: {
      ...inputs.saleTaxPercent.InputProps,
      endAdornment: <StyledEdit />,
    },
    inputProps: {
      decimalScale: 2,
      fixedDecimalScale: true,
    },
    FloatInputProps: {
      topMargin: "0",
      height: "100px",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
};

const validators = {
  ffeSpendTo: ({ pricingTiers, rowReference, value }) => {
    const ffeSpendFrom = get({ pricingTiers }, `${rowReference}.ffeSpendFrom`);
    if (value <= ffeSpendFrom) {
      return `Needs to be greater than ${formatter.format(ffeSpendFrom)}`;
    }
  },
  tierPercentage: ({ pricingTiers, index, value }) => {
    const { tierPercentage: prevTierPercentage } = pricingTiers[index - 1];
    if (value >= prevTierPercentage) {
      return `Needs to be lower than ${prevTierPercentage}%`;
    }
    const nextTierPercentage = pricingTiers[index + 1]?.tierPercentage;
    if (value <= nextTierPercentage) {
      return `Needs to be greater than ${nextTierPercentage}%`;
    }
  },
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const validateFloatInput = pricingTiers => prop => {
  const [key] = Object.keys(prop);
  const [rowReference, property] = key.split(".");

  const validator = validators[property];

  if (!validator) {
    return {
      valid: true,
    };
  }

  const index = Number(rowReference.replace(/[^0-9]/g, ""));
  const value = prop[key];
  const error = validator({ pricingTiers, rowReference, index, value });

  return {
    valid: !error,
    helperText: error,
  };
};

export const isValid = pricingTiers =>
  Object.keys(validators).reduce(
    (res, key) =>
      res &&
      pricingTiers.slice(1).reduce(
        (res, pricingTier, index) =>
          res &&
          validateFloatInput(pricingTiers)({
            [`pricingTiers[${index + 1}].${key}`]: pricingTier[key],
          }).valid,
        true
      ),
    true
  );
