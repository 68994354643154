import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ViewList from "@material-ui/icons/ViewList";

import NavigationBarTab from "../../../layout/NavigationBar/NavigationBarTab";
import Counter from "../../../ui/Counter";
import withRequestListener, {
  useHasRequestActive,
} from "../../../hocs/withRequestListener";

export const ShipmentsTab = ({ counter, onUpdate }) => {
  const hasActiveRequest = useHasRequestActive("shipments");
  const prevActiveRequest = useRef(false);

  useEffect(() => {
    if (prevActiveRequest.current && !hasActiveRequest) {
      onUpdate();
    }
    prevActiveRequest.current = hasActiveRequest;
  }, [hasActiveRequest, onUpdate]);

  return (
    <NavigationBarTab>
      <ViewList />
      <span>Shipments</span>
      <Counter value={counter} />
    </NavigationBarTab>
  );
};

ShipmentsTab.propTypes = {
  project: PropTypes.object,
  counter: PropTypes.number,
  onUpdate: PropTypes.func,
};

export default withRequestListener(ShipmentsTab);
