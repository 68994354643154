export const handleSuccessForPurchaseOrder = (
  setReload,
  dataComponentId,
  showSnackNotificationAction,
  snackMessage
) => {
  setReload(dataComponentId, true);
  showSnackNotificationAction(snackMessage);
};
export const handleError = (data, showSnackNotificationAction) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};
