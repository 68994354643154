import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Assignment from "@material-ui/icons/Assignment";
import InputAdornment from "@material-ui/core/InputAdornment";
import _get from "lodash/get";

import TextField from "../mui/core/TextField";
import InputLoader from "../ui/Loader/InputLoader";
import RowMenu from "../menus/RowMenu";
import DragDropInputFile from "./DragDrop/DragDropInputFile";

export const InputFile = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
`;

const RowMenuWrapper = styled.div`
  align-self: center;
`;

const Wrapper = styled.div`
  display: flex;
`;

const buildFileWrapper = (file, oldValue) => {
  if (!oldValue) {
    return {
      s3Key: `temp/${file.name}`,
      filename: file.name,
      isUpdated: true,
      metadata: { size: file.size },
      file,
    };
  }
  return {
    ...oldValue,
    filename: file.name,
    isUpdated: true,
    metadata: { size: file.size },
    file,
  };
};

export const getFileValue = (isMultiple, useFileWrapper, value, oldValue) => {
  if (!useFileWrapper) return value;
  if (!isMultiple) {
    return buildFileWrapper(value, oldValue);
  }
  const files = [];
  for (let i = 0; i < value.length; i++) {
    files.push(
      buildFileWrapper(
        value[i],
        oldValue ? oldValue.find(item => item.id === value[i].id) : null
      )
    );
  }
  return files;
};

const getInitialValue = (value, useFileWrapper) =>
  useFileWrapper ? _get(value, "filename", "") : value || "";

const labelShouldShrink = (inputValue, hasFocus) => !!(inputValue || hasFocus);

export const processChange = (
  files,
  setInputValue,
  useFileWrapper,
  multiple,
  value
) => {
  if (files.length === 0) {
    setInputValue && setInputValue("");
    return null;
  }
  setInputValue && setInputValue(files.map(file => file.name).join(", "));
  return getFileValue(
    multiple,
    useFileWrapper,
    multiple ? files : files[0],
    value
  );
};

export const FileMenu = ({
  displayMenu,
  hideDeleteOption,
  value,
  handleClick,
  handleDelete,
}) => {
  const linkEl = useRef(null);

  const menuItems = [
    {
      text: "Download",
      disabled: !value?.s3SignedUrl,
      onClick: () => linkEl.current.click(),
    },
    {
      text: "Replace",
      onClick: () => handleClick({ stopPropagation: () => null }),
    },
  ];

  if (!hideDeleteOption) {
    menuItems.push({
      text: "Delete",
      onClick: handleDelete,
    });
  }

  return (
    <Fragment>
      {displayMenu && value && (
        <RowMenuWrapper>
          <RowMenu
            row={{ id: 0 }}
            tableRow={{ rowId: 0 }}
            menuItems={menuItems}
          />
        </RowMenuWrapper>
      )}
      <a
        ref={linkEl}
        href={value && value.s3SignedUrl}
        target="_blank"
        rel="noopener noreferrer"
      ></a>
    </Fragment>
  );
};
FileMenu.propTypes = {
  hideDeleteOption: PropTypes.bool,
  displayMenu: PropTypes.bool,
  value: PropTypes.shape({}),
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
};

const EndAdornment = ({ handleClick, hideAdornment }) => {
  if (hideAdornment) {
    return null;
  }

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleClick}>
        <Assignment />
      </IconButton>
    </InputAdornment>
  );
};
EndAdornment.propTypes = {
  handleClick: PropTypes.func,
  hideAdornment: PropTypes.bool,
};

const FilePickerWithError = ({
  label,
  limitType,
  limit,
  placeholder,
  value,
  error,
  errorMessage,
  ignoreCaption,
  InputProps = {},
  helperText = " ",
  onChange,
  name,
  inputFileProps = {},
  useFileWrapper,
  InputLabelProps,
  displayMenu: displayFileMenu,
  hideDeleteOption,
  ...inputProps
}) => {
  const errorHelperText = error ? errorMessage : null;
  const defaultHelperText = ignoreCaption ? null : helperText;

  const [inputValue, setInputValue] = useState(
    getInitialValue(value, useFileWrapper)
  );
  const [hasFocus, setFocus] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInputValue(getInitialValue(value, useFileWrapper));
  }, [value, useFileWrapper]);

  const handleClick = useCallback(evt => {
    evt.stopPropagation();
    setOpen(true);
  }, []);

  const handleChange = useCallback(
    evt => {
      const files = Array.prototype.slice.call(evt.target.files);
      if (files.length === 0) {
        return;
      }
      const newValue = processChange(
        files,
        setInputValue,
        useFileWrapper,
        inputFileProps.multiple,
        value
      );
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
    },
    [inputFileProps, useFileWrapper, name, onChange, value, setInputValue]
  );

  const handleDelete = useCallback(() => {
    onChange({ target: { name: [`${name}Id`], value: null } });
    onChange({ target: { name, value: null } });
  }, [name, onChange]);

  const handleFocus = () => setFocus(true);

  const handleBlur = () => setFocus(false);

  const handleClose = () => setOpen(false);

  const props = {
    ...inputProps,
    label,
    value: inputValue,
    error,
    name,
    helperText: errorHelperText || defaultHelperText,
  };

  const displayMenu = !!(displayFileMenu && value);

  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <Wrapper>
        <DragDropInputFile
          open={open}
          onClose={handleClose}
          onChange={handleChange}
          {...inputFileProps}
        />
        <TextField
          onClick={handleClick}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: labelShouldShrink(inputValue, hasFocus),
            ...InputLabelProps,
          }}
          InputProps={{
            ...InputProps,
            readOnly: true,
            endAdornment: (
              <EndAdornment
                handleClick={handleClick}
                hideAdornment={displayMenu}
              />
            ),
          }}
        />
        <FileMenu
          displayMenu={displayMenu}
          value={value}
          handleClick={handleClick}
          handleDelete={handleDelete}
          hideDeleteOption={hideDeleteOption}
        />
      </Wrapper>
    </InputLoader>
  );
};

FilePickerWithError.propTypes = {
  useFileWrapper: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  displayMenu: PropTypes.bool,
  hideDeleteOption: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FilePickerWithError;
