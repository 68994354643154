import React, { Component } from "react";
import PropTypes from "prop-types";

import PageSection from "./PageSection";
import FormGrid, { FormGrid as InternalFormGrid } from "../forms/FormGrid";

export const formSectionPropTypes = {
  values: PropTypes.object.isRequired,
};

export class FormPageSection extends Component {
  render() {
    const { headerText, children, pageSectionProps, fields } = this.props;
    return (
      <PageSection headerText={headerText} {...pageSectionProps}>
        <FormGrid fields={fields} />
        {children}
      </PageSection>
    );
  }
}

FormPageSection.propTypes = {
  ...PageSection.propTypes,
  fields: InternalFormGrid.propTypes.fields,
};
