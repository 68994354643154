import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAuthenticatedUser } from "../../../../actions/authActions";

import FavoriteControl from "./FavoriteControl";

export const mapStateToProps = ({ auth }, { projectId }) => {
  const { favoriteProjectIds = [], loadingAuthentication } = auth;
  return {
    favoriteProjectIds,
    isStarred: favoriteProjectIds.includes(projectId),
    loading: loadingAuthentication,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ updateAuthenticatedUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteControl);
