import initialState from "./initialState";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import * as actions from "../constants/ActionTypes";

function createResourceReducer(state, dataComponentId, requestType, payload) {
  return { ...state, [`${dataComponentId}_${requestType}`]: payload };
}

function upsertListResourceIfExistReducer(
  state,
  dataComponentId,
  requestType,
  { data }
) {
  const id = `${dataComponentId}_${requestType}`;
  const resource = state[id];
  if (!resource) return state;

  const existingById = resource.data.reduce((accum, elem) => {
    accum[elem.id] = elem;
    return accum;
  }, {});

  const existingIds = Object.keys(existingById);
  const newIds = [];

  const toUpdateById = data.reduce((accum, elem) => {
    accum[elem.id] = elem;
    if (!existingIds.includes(elem.id)) {
      newIds.push(elem.id);
    }
    return accum;
  }, {});

  const allIds = [...newIds, ...existingIds];

  const newData = allIds.reduce((accum, itemId) => {
    const item = toUpdateById[itemId]
      ? toUpdateById[itemId]
      : existingById[itemId];
    accum.push(item);
    return accum;
  }, []);

  return {
    ...state,
    [id]: {
      data: newData,
      meta: resource.meta,
    },
  };
}

function updateResourceIfExistReducer(
  state,
  dataComponentId,
  requestType,
  { data, meta = {} }
) {
  const id = `${dataComponentId}_${requestType}`;
  const resource = state[id];
  if (!resource || resource.data.id !== data.id) return state;

  return {
    ...state,
    [id]: {
      data: { ...resource.data, ...data },
      meta: { ...resource.meta, ...meta },
    },
  };
}

function destroyResourceReducer(state, dataComponentId, requestType) {
  return { ...state, [`${dataComponentId}_${requestType}`]: undefined };
}

function destroyListResourceIfExistReducer(
  state,
  dataComponentId,
  requestType,
  { data }
) {
  const id = `${dataComponentId}_${requestType}`;
  const resource = state[id];
  if (!resource) return state;
  const deletedIds = data.map(({ id }) => id);

  const newData = resource.data.filter(({ id }) => {
    return !deletedIds.includes(id);
  }, []);

  return {
    ...state,
    [id]: {
      data: newData,
      meta: resource.meta,
    },
  };
}

const reducers = {
  [actions.DATA_COMPONENT_RESOURCE_CREATE]: createResourceReducer,
  [actions.DATA_COMPONENT_RESOURCE_UPDATE_IF_EXISTS]: updateResourceIfExistReducer,
  [actions.DATA_COMPONENT_RESOURCE_UPSERT_LIST_IF_EXISTS]: upsertListResourceIfExistReducer,
  [actions.DATA_COMPONENT_RESOURCE_DESTROY]: destroyResourceReducer,
  [actions.DATA_COMPONENT_RESOURCE_DESTROY_LIST_IF_EXIST]: destroyListResourceIfExistReducer,
};

export default function(state = initialState.dataComponentResources, action) {
  const {
    type,
    dataComponentId,
    payload = {},
    requestType = REQUEST_TYPES.LIST,
  } = action;
  const reducer = reducers[type];
  if (reducer) {
    return reducer(state, dataComponentId, requestType, payload);
  }
  return state;
}
