import create from "zustand";
import { devtools } from "zustand/middleware";
export { FORM_IDS } from "./constants";
import { initialState } from "./constants";
import {
  setTitleModalReducer,
  openAddressBookFormReducer,
  openCreateContactFormReducer,
  openRemitAddressFormReducer,
  resetStoreReducer,
  setCurrentFormReducer,
  setVendorReducer,
} from "./reducers";

export default create(
  devtools(
    set => ({
      ...initialState,
      actions: {
        setTitleModal: setTitleModalReducer(set),
        setVendor: setVendorReducer(set),
        openRemitAddressForm: openRemitAddressFormReducer(set),
        openAddressBookForm: openAddressBookFormReducer(set),
        openCreateContactForm: openCreateContactFormReducer(set),
        setCurrentForm: setCurrentFormReducer(set),
        resetStore: resetStoreReducer(set),
      },
    }),
    "CreateRemitAddress"
  )
);
