import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import {
  performDeleteRequest,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";
import Vendor from "../../../../models/Vendor";

const dataComponentId = "VendorsDelete";

export class DeleteVendorContainer extends Component {
  componentDidMount() {
    this.props.initDataComponent(dataComponentId, Vendor, [], "vendors");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleDeleteRequestSuccess,
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestSuccess = () => {
    const { closeModalDialog, push, goBackOnSuccess } = this.props;
    if (goBackOnSuccess) push("/vendors");
    closeModalDialog();
  };

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteVendor = () => {
    const { id, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, id);
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.deleteVendor} />;
  }
}

DeleteVendorContainer.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  goBackOnSuccess: PropTypes.bool,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  initDataComponent,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteVendorContainer);
