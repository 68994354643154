import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoaderContext } from "../../../ui/Loader";
import EntityForm from "../CreateEntity/EntityForm";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  populateCountryResources,
  populateStateResources,
} from "../../../../actions/countryStateActions";

import {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
} from "../../../../actions/dataComponentActions";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";

const dataComponentId = "EntitiesGrid";

export class EditEntityContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    populateCountryResources: PropTypes.func,
    populateStateResources: PropTypes.func,
    client: PropTypes.shape({}),
    performFindRequest: PropTypes.func,
    performUpdateRequest: PropTypes.func,
  };

  componentDidMount() {
    const { performFindRequest, id } = this.props;
    performFindRequest(dataComponentId, id);
    this.props.populateCountryResources();
    this.props.populateStateResources();
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleSubmit = ({ client, ...entity }, formikActions) => {
    const { performUpdateRequest, id } = this.props;
    delete entity.country_id;
    delete entity.state_id;
    delete entity.termsCount;
    delete entity.numProjects;

    performUpdateRequest(dataComponentId, id, entity);
    this.setState({ formikActions });
  };

  render() {
    const { entity, loading } = this.props;

    const initialValues = {
      ...entity,
    };
    return (
      <LoaderContext.Provider value={{ loading }}>
        <EntityForm
          {...this.props}
          editEntity={this.editEntity}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          dataComponentId={dataComponentId}
        />
      </LoaderContext.Provider>
    );
  }
}

export const mapDispatchToProps = {
  closeModalDialog,
  populateCountryResources,
  populateStateResources,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
};

export const mapStateToProps = (state, { id }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const entityDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const entity = getBluechipResourceById(entityDataComponent, state, id);

  return {
    dataComponent,
    loading: entityDataComponent.loading,
    entity,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEntityContainer);
