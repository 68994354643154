import { inputs } from "../../../../components/inputs/inputConfigs";

export default () => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.locationName,
              label: "Site Name",
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.description,
              label: "Site Description",
              limitType: false,
              avoidDebounce: true,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.location,
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 12 },
          },
          {
            input: inputs.locationAddress2,
            grid: { xs: 12 },
            avoidDebounce: true,
          },
          {
            input: {
              ...inputs.locationCountry,
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationState,
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationCity,
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationPostalCode,
              required: true,
              avoidDebounce: true,
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
