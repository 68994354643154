import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import { withRouter } from "react-router";
import get from "lodash.get";

import { FormikForm, ActionButtons, FormGrid } from "../../forms";
import {
  calculateOverageQuantity,
  calculateTotalQuantity,
} from "./calculations";
import styled from "styled-components";
import propTypes from "../../../constants/propTypes";
import { useV2DatacomponentResources } from "../../hooks/useResources";
import * as RequestTypes from "../../../constants/RequestTypes";
import { validateForm } from "../../forms/FormikForm";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";
import { useDatacomponent, useLoading } from "../../hooks/useDatacomponent";
import { dataComponentId } from "./CreateSpec/CreateSpecContainer";
import { useCreateProcessRequests } from "../../hooks/useProcessRequest";
import { LoaderContext } from "../../ui/Loader";

const defaultCalculatedFieldValue = "--";

const Wrapper = styled.div`
  max-height: calc(65vh - 72px);
  overflow-y: auto;
  margin-bottom: 24px;
`;

export const handleBaseQuantityChange = (
  setBaseQuantity,
  setFieldValue,
  overagePercent,
  atticStock
) => event => {
  const value = parseFloat(event.target.value);

  if (isNaN(value)) {
    setBaseQuantity(0);
    setFieldValue("baseQuantity", null);
    return;
  }

  setBaseQuantity(value);

  const overageValue = calculateOverageQuantity(
    overagePercent,
    value,
    atticStock
  );

  setFieldValue("overageValue", overageValue);

  setFieldValue(
    "totalQuantity",
    calculateTotalQuantity(value, atticStock, overageValue)
  );
};

export const handleAtticStockChange = (
  setAtticStock,
  setFieldValue,
  baseQuantity,
  overagePercent
) => event => {
  const value = parseFloat(event.target.value);

  if (isNaN(value)) {
    setAtticStock(0);
    setFieldValue("atticStock", null);
    return;
  }
  setAtticStock(value);

  const overageValue = calculateOverageQuantity(
    overagePercent,
    baseQuantity,
    value
  );

  setFieldValue("overageValue", overageValue);

  setFieldValue(
    "totalQuantity",
    calculateTotalQuantity(baseQuantity, value, overageValue)
  );
};

export const handleOverageChange = (
  setOveragePercent,
  setFieldValue,
  baseQuantity,
  atticStock
) => event => {
  const percent = parseFloat(event.target.value) / 100;

  if (isNaN(percent)) {
    setOveragePercent(0);

    setFieldValue("overagePercent", null);
    setFieldValue("overageValue", defaultCalculatedFieldValue);

    setFieldValue(
      "totalQuantity",
      calculateTotalQuantity(baseQuantity, atticStock)
    );

    return;
  }
  setOveragePercent(percent);

  const overageValue = calculateOverageQuantity(
    percent,
    baseQuantity,
    atticStock
  );

  setFieldValue("overageValue", overageValue);

  setFieldValue(
    "totalQuantity",
    calculateTotalQuantity(baseQuantity, atticStock, overageValue)
  );
};

export const handleCurrencyValueChange = (
  setFieldValue,
  selectedProjectCurrency,
  changingCurrency,
  setChangingCurrency
) => (event, fieldName) => {
  if (!selectedProjectCurrency) return;
  if (changingCurrency) {
    setChangingCurrency(false);
    return;
  }
  const value = event.target.value || 0;

  const newFieldNameValue = Math.round(value * 100 * 1000000) / 100000000;

  setFieldValue(fieldName, newFieldNameValue);
};

export const addVal = (a, b) => {
  const numberOfDecimalPlaces = x => {
    if (!x || x.toString().indexOf(".") === -1) {
      return 1;
    }
    return x.toString().length - x.toString().indexOf(".");
  };
  const factor = Math.max(numberOfDecimalPlaces(a), numberOfDecimalPlaces(b));
  return (
    (Math.round(a * Math.pow(10, factor)) +
      Math.round(b * Math.pow(10, factor))) /
    Math.pow(10, factor)
  );
};

export const getQuantityErrorMessage = (spec, values) => {
  const totalQuantityShipped = get(spec, "totalQuantityShipped", 0);
  const totalQuantityPaid = get(spec, "totalQuantityPaid", 0);
  const initialBaseQuantity = get(spec, "baseQuantity", 0);
  const initialAtticStock = get(spec, "atticStock", 0);
  const initialOverageValue = get(spec, "overageValue", 0);
  const baseQuantity = get(values, "baseQuantity", initialBaseQuantity);
  const atticStock = get(values, "atticStock", initialAtticStock);
  const overageValue = get(values, "overageValue", initialOverageValue);
  const totalQuantityWithoutOverage = addVal(baseQuantity, atticStock);
  const totalQuantityWithOverage = addVal(
    totalQuantityWithoutOverage,
    isNaN(overageValue) ? 0 : overageValue
  );
  if (totalQuantityWithOverage < totalQuantityPaid) {
    const message = `Total quantity paid is ${totalQuantityPaid}. Base Quantity + Attic Stock + Overage Value has to be equal to or greater than the total quantity paid.`;
    return message;
  }
  if (totalQuantityWithoutOverage < totalQuantityShipped) {
    const message = `Total quantity shipped is ${totalQuantityShipped}. Base Quantity + Attic Stock has to be equal to or greater than the total quantity shipped.`;
    return message;
  }
};

export const validateSpec = (spec, isPatch = false) => values => {
  const validationSchema = BWModels.loadSchema("Spec");

  const errors = validateForm(values, validationSchema, undefined, isPatch);

  const quantityErrorMessage = getQuantityErrorMessage(spec, values);

  if (quantityErrorMessage) {
    errors.baseQuantity = quantityErrorMessage;
    errors.atticStock = quantityErrorMessage;
  }

  return errors;
};

function SpecFormikForm(props) {
  const {
    spec,
    initialValues,
    onSubmit,
    buildSubmit,
    validationSchema,
    createFields,
    disableVendorField,
    sendButtonText,
    saveAndCreateAnotherText,
    cancelButtonText,
    onCancel,
    additionalProps = {},
    loadingSave,
    projectCurrency,
    match,
    listeners,
  } = props;
  const [baseQuantity, setBaseQuantity] = useState(spec.baseQuantity || 0);
  const [atticStock, setAtticStock] = useState(spec.atticStock || 0);
  const [overagePercent, setOveragePercent] = useState(
    spec.overagePercent / 100 || 0
  );

  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );

  const isProjectClosed = useIsProjectClosed();
  const clientId = match.params.clientId;

  const dataComponent = useDatacomponent(dataComponentId);
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => setOveragePercent(0),
  });

  useEffect(() => {
    setBaseQuantity(spec.baseQuantity || 0);
    setAtticStock(spec.atticStock || 0);
    setOveragePercent(spec.overagePercent / 100 || 0);
  }, [spec]);

  const ref = useRef(null);

  const { loading: loadingContext } = useContext(LoaderContext);
  const loading = useLoading(listeners) || loadingContext;

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FormikForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        ignoreCache
      >
        {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
          const selectedProjectCurrency =
            projectCurrency ||
            projectCurrencies.find(
              projectCurrency => projectCurrency.id == values.projectCurrencyId
            );

          const quantityErrorMessage =
            getQuantityErrorMessage(spec, values) || errors.totalQuantityError;

          const fields = createFields({
            clientId,
            projectId: initialValues.projectId,
            onBaseQuantityChange: handleBaseQuantityChange(
              setBaseQuantity,
              setFieldValue,
              overagePercent,
              atticStock
            ),
            handleAtticStockChange: handleAtticStockChange(
              setAtticStock,
              setFieldValue,
              baseQuantity,
              overagePercent
            ),
            handleOverageChange: handleOverageChange(
              setOveragePercent,
              setFieldValue,
              baseQuantity,
              atticStock
            ),
            selectedCurrency: selectedProjectCurrency?.currency,
            disableVendorField,
            spec,
            quantityErrorMessage,
            isProjectClosed,
          });

          return (
            <Fragment>
              <Wrapper ref={ref}>
                <FormGrid
                  fields={fields}
                  purchaseOrder={spec && spec.purchaseOrder}
                  values={values}
                  errors={errors}
                  {...formikProps}
                />
              </Wrapper>
              <ActionButtons
                onSend={buildSubmit(
                  handleSubmit,
                  false,
                  ref,
                  quantityErrorMessage
                )}
                sendButtonText={sendButtonText}
                onSecondary={buildSubmit(
                  handleSubmit,
                  true,
                  ref,
                  quantityErrorMessage
                )}
                secondaryButtonText={saveAndCreateAnotherText}
                cancelButtonText={cancelButtonText}
                onCancel={onCancel}
                isModal={true}
                additionalProps={{
                  ...additionalProps,
                  secondary: {
                    ...get(additionalProps, "secondary", {}),
                    isLoading:
                      get(additionalProps, "secondary.isLoading", false) ||
                      loadingSave ||
                      loading,
                  },
                }}
                loading={loadingSave}
                listeners={listeners}
              />
            </Fragment>
          );
        }}
      </FormikForm>
    </LoaderContext.Provider>
  );
}
SpecFormikForm.defaultProps = {
  spec: {},
  initialValues: {},
  onSubmit: () => {},
  buildSubmit: () => {},
  validationSchema: {},
  createFields: () => {},
  disableVendorField: false,
  sendButtonText: "Send",
  saveAndCreateAnotherText: "Save & Next",
  onCancel: () => {},
  cancelButtonText: "Cancel",
  additionProps: {},
};
SpecFormikForm.propTypes = {
  spec: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  buildSubmit: PropTypes.func.isRequired,
  createFields: PropTypes.func.isRequired,
  disableVendorField: PropTypes.bool,
  sendButtonText: PropTypes.string.isRequired,
  saveAndCreateAnotherText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  additionalProps: propTypes.buttonAdditionalProps,
  projectCurrency: PropTypes.shape({}),
};
export default withRouter(SpecFormikForm);
