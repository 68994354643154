import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";
import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import { ActionButtons } from "../../../../forms";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import { StyledEdit } from "../../../../ui/BWGrid/columns/EditableColumn";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import CurrencyInput from "../../../../inputs/CurrencyInput";
import FormikForm from "../../../../forms/FormikForm";
import FormGrid from "../../../../forms/FormGrid";
import { COM_TYPE } from "../bidGroupVendorSpecUtils";
import Joi from "joi-browser";
import {
  handleCreateAlternateQuote,
  isEditable,
  getPricingEditColumn,
  getQuantityEditColumn,
  generateGridRows,
  getInputName,
  getSampleQuantityEditColumn,
} from "./CreateAlternateQuoteFunctions";
import { Wrapper } from "./components";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";

const columns = [
  "customNumber",
  "description",
  "unitOfMeasure.name",
  "sampleQuantity",
  "samplePricing",
  "quantity",
  "productionPricing",
];
export const columnOptions = (gridRows, setGridRows, currencyFormatter) => ({
  customNumber: {
    title: "Number",
    bold: true,
    filter: true,
  },
  description: {
    filter: true,
    fill: true,
    width: "120px",
  },
  "unitOfMeasure.name": { title: "UOM", bold: true, width: "50px" },
  sampleQuantity: {
    name: "sampleQuantity",
    title: "Sample  QTY",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.sampleQuantity = newValue;
        setGridRows(rows);
      },
    },
    render: row => {
      if (row.type === COM_TYPE) {
        return getSampleQuantityEditColumn(row);
      }
      return row.sampleQuantity;
    },
  },
  samplePricing: {
    name: "samplePricing",
    title: "Sample Pricing",
    wordWrapEnabled: true,
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.samplePricing = newValue;
        setGridRows(rows);
      },
    },
    render: row => {
      if (row.type === COM_TYPE) {
        return;
      }
      return getPricingEditColumn(
        row,
        "samplePricing",
        "Sample Pricing",
        currencyFormatter
      );
    },
  },
  quantity: {
    title: "QTY",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.productionQuantity = newValue;
        setGridRows(rows);
      },
    },
    render: row => {
      if (row.type === COM_TYPE) {
        return getQuantityEditColumn(row);
      }
      return row.productionQuantity;
    },
  },
  productionPricing: {
    name: "productionPricing",
    title: "Production Pricing",
    wordWrapEnabled: true,
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.productionPricing = newValue;
        setGridRows(rows);
      },
    },
    render: row => {
      if (row.type === COM_TYPE) {
        return;
      }
      return getPricingEditColumn(
        row,
        "productionPricing",
        "Production Pricing",
        currencyFormatter
      );
    },
  },
});
const fields = {
  groups: [
    {
      items: [
        {
          input: {
            InputComponent: TextInputWithError,
            label: "Notes",
            name: "notes",
            fullWidth: true,
            required: true,
          },
        },
      ],
    },
  ],
};

const CreateAlternateQuoteContainer = props => {
  const {
    bidGroupVendor,
    bidGroupVendorSpec,
    createAlternateQuote,
    dataComponentId,
    performCreateRequest,
    setSnackMessage,
  } = props;
  const [gridRows, setGridRows] = useState([]);

  const currency = bidGroupVendor?.quoteProjectCurrency?.currency;
  const currencyFormatter = useCurrencyFormatter(currency);

  useEffect(() => {
    const rows = generateGridRows([bidGroupVendorSpec]);

    setGridRows(rows);
  }, [bidGroupVendorSpec]);

  const rowInputs = {
    sampleQuantity: {
      InputComponent: TextInputWithError,
      type: "number",
      name: "quantity",
      fullWidth: true,
      InputProps: {
        endAdornment: <StyledEdit />,
      },
    },
    quantity: {
      InputComponent: TextInputWithError,
      type: "number",
      name: "quantity",
      fullWidth: true,
      InputProps: {
        endAdornment: <StyledEdit />,
      },
    },
    samplePricing: {
      InputComponent: TextInputWithError,
      name: "samplePricing",
      fullWidth: true,
      InputProps: {
        inputComponent: CurrencyInput,
        endAdornment: <StyledEdit />,
      },
      inputProps: {
        currency: currency,
      },
    },
    productionPricing: {
      InputComponent: TextInputWithError,
      name: "productionPricing",
      fullWidth: true,
      InputProps: {
        inputComponent: CurrencyInput,
        endAdornment: <StyledEdit />,
      },
      inputProps: {
        currency: currency,
      },
    },
  };

  const tableComponent = useMemo(
    () => ({
      CellComponent: editableCellCreator(
        rowInputs,
        getInputName,
        (column, tableRow) => isEditable(column, tableRow)
      ),
    }),
    [rowInputs]
  );

  return (
    <Wrapper>
      <FormikForm
        initialValues={{
          notes: "",
        }}
        validationSchema={Joi.object({ notes: Joi.string() })}
        onSubmit={data =>
          handleCreateAlternateQuote(
            data,
            bidGroupVendorSpec,
            gridRows,
            createAlternateQuote,
            dataComponentId,
            performCreateRequest,
            setSnackMessage,
            bidGroupVendor?.quoteProjectCurrency?.conversionRate
          )
        }
        ignoreCache
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <React.Fragment>
            <BWGridLocal
              id={`create-bid-group-spec-create-alternate-quote`}
              sorting={[{ columnName: "spec.customNumber", direction: "asc" }]}
              rows={gridRows}
              gridConfig={{ pageSize: -1, totalRows: gridRows.length }}
              isLoading={false}
              tableComponents={tableComponent}
              editableNavigationDirection="horizontal"
              emptyStateText="No specs to quote"
              hideFooter
              noBorder
              noBottomMargin
            >
              <GridTable
                columns={columns}
                columnOptions={columnOptions(
                  gridRows,
                  setGridRows,
                  currencyFormatter
                )}
              />
              <GridRow showPagingPanel={false} />
            </BWGridLocal>
            <FormGrid
              fields={fields}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              isModal={true}
              sendButtonText={"Create"}
              onSend={handleSubmit}
              listeners={[dataComponentId]}
            />
          </React.Fragment>
        )}
      </FormikForm>
    </Wrapper>
  );
};

CreateAlternateQuoteContainer.propTypes = {
  bidGroupVendorSpec: propTypes.bidGroupVendorSpec,
  bidGroupVendor: propTypes.bidGroupVendor,
  dataComponentId: PropTypes.string.isRequired,
  createAlternateQuote: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
};

export default CreateAlternateQuoteContainer;
