import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import { Ballot as MaterialArticleIcon } from "@material-ui/icons";

import { iconStyle } from "../components";

const InsertChartIcon = styled(MaterialArticleIcon)({
  ...iconStyle,
  fill: "white",
});

export const CustomReportsIcon = ({ onClick }) => {
  return (
    <Tooltip title={"Custom/Query Reports"}>
      <IconButton onClick={onClick}>
        <InsertChartIcon />
      </IconButton>
    </Tooltip>
  );
};

CustomReportsIcon.propTypes = {
  onClick: PropTypes.func,
};
