import { inputs } from "../../../../../components/inputs/inputConfigs";
import { commonOptions } from "../../../../../components/pages/Specs/CreateSpec/createSpecFields";

export default ({
  clientId,
  projectId,
  onBaseQuantityChange,
  handleAtticStockChange,
  handleOverageChange,
  selectedCurrency,
  disableVendorField,
  spec,
  quantityErrorMessage,
  isProjectClosed,
}) => {
  return {
    groups: [
      ...commonOptions(
        projectId,
        onBaseQuantityChange,
        handleAtticStockChange,
        handleOverageChange,
        spec,
        selectedCurrency,
        quantityErrorMessage
      ),
      {
        items: [
          {
            input: {
              ...inputs.vendorAutocomplete,
              menuPlacement: "top",
              isDisabled: disableVendorField,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.glCodes,
              dataComponentId: "select-glCodes",
              menuPlacement: "top",
              APIOptions: {
                ...inputs.glCodes.APIOptions,
                rootFilters: {
                  $where: {
                    clientId,
                  },
                },
              },
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.statusComment,
              name: "forecastComment",
              label: "Project Forecast Comment",
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
