import React from "react";
import { connect } from "react-redux";
import { Print } from "@material-ui/icons";
import _get from "lodash/get";
import ContactIcon from "@material-ui/icons/Contacts";
import PropTypes from "prop-types";

import { BWActionButton } from "../../ui/BWActionButton";
import Loader from "../../ui/Loader";
import { generateAndDownloadReport } from "../../../actions/reportsActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import { openModalDialog } from "../../../actions/layoutActions";
import { dataComponentId } from "./ProjectEditContainer";
import { onHandleTransferProject } from "../Projects/ProjectFunctions";
import { closeModalDialog } from "../../../actions/layoutActions";
import propTypes from "../../../constants/propTypes";

export const handleReassignProjectClick = (
  project,
  openModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  transferProject,
  client
) => {
  const clientName = _get(client, "name", "");
  const scopeId = _get(client, "scopeId");

  openModalDialog(
    "Switch Client",
    "TransferProjectContainer",
    {
      project: project,
      initDataComponent,
      performRetrieveListRequest,
      onTransferProject: transferProject,
      scopeId,
    },
    false,
    false,
    {
      layoutType: "default",
      freezeBackground: true,
      subtitle: `${project.name} For Client: ${clientName}`,
    }
  );
};

const HeaderButtons = ({
  project,
  openModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  generateAndDownloadReport,
  performUpdateRequest,
  closeModalDialog,
  client,
  isPrinting,
}) => {
  const handlePrint = projectId => {
    generateAndDownloadReport({
      format: "pdf",
      projectId: projectId,
      templateName: "project-detail",
    });
  };

  const transferProject = (project, propertyId) =>
    onHandleTransferProject(
      project,
      propertyId,
      dataComponentId,
      performUpdateRequest,
      closeModalDialog
    );
  return (
    <Loader width="240px" height="30px">
      <BWActionButton
        icon={<Print />}
        text={"Print"}
        loading={isPrinting}
        onClick={() => handlePrint(project.id)}
      />
      <BWActionButton
        icon={<ContactIcon />}
        text={"Switch Client"}
        onClick={() =>
          handleReassignProjectClick(
            project,
            openModalDialog,
            initDataComponent,
            performRetrieveListRequest,
            transferProject,
            client
          )
        }
      />
    </Loader>
  );
};

HeaderButtons.propTypes = {
  project: propTypes.project,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  client: propTypes.client,
  isPrinting: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    isPrinting: state.reports.loaders["project-detail-pdf-false"],
  };
};

const mapDispatchToProps = {
  openModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  generateAndDownloadReport,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtons);
