import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "../../mui/core/Paper";
import { BWGridAPI, GridHeader, GridRow, GridTable } from "../../ui/BWGrid";
import * as tableConfigs from "../../../components/ui/gridTableConfigs/detailPageProjects";
import { FormControlLabel, Switch } from "../../mui/core";

const purchaseOrderStatusFilters = {
  canceled: { "purchaseOrders.status": { $notEqual: "Canceled" } },
  all: {},
};

const columns = [
  "number",
  "name",
  "type",
  "status",
  "property.name",
  "projectLead.name",
  "startDate",
  "endDate",
];

export default class VendorProjectsPage extends Component {
  state = {
    purchaseOrderStatusFilter: "canceled",
  };

  handleRowClick = ({
    id,
    property: {
      entity: { clientId },
    },
  }) => {
    this.props.push(`/clients/${clientId}/projects/${id}`);
  };

  handleSwitchClosedPOProjects = () => {
    this.setState({
      purchaseOrderStatusFilter:
        this.state.purchaseOrderStatusFilter === "canceled"
          ? "all"
          : "canceled",
    });
  };

  render() {
    const { purchaseOrderStatusFilter } = this.state;
    const { dataComponent, vendorId } = this.props;
    const { columnOptions } = tableConfigs;

    return (
      <Paper>
        <BWGridAPI
          {...this.props}
          rootFilters={{
            $where: {
              $and: {
                "purchaseOrders.vendorId": vendorId,
                ...purchaseOrderStatusFilters[purchaseOrderStatusFilter],
              },
            },
          }}
        >
          <GridHeader headerText={`${dataComponent.totalRows} Projects`} />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            onClick={this.handleRowClick}
          />
          <GridRow>
            <FormControlLabel
              control={
                <Switch
                  checked={purchaseOrderStatusFilter !== "canceled"}
                  onChange={this.handleSwitchClosedPOProjects}
                  color="primary"
                />
              }
              label="Show Canceled PO Projects"
            />
          </GridRow>
        </BWGridAPI>
      </Paper>
    );
  }
}

VendorProjectsPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  vendorId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};
