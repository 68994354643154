import React from "react";
import fields from "./createGLCodeFields";
import { FormPageSection } from "../../../layout/FormPageSection";
import PropTypes from "prop-types";

const GLCodesSection = ({ values, clientId }) => {
  return (
    <FormPageSection
      headerText="GL Codes"
      isPage={true}
      fields={fields(clientId)}
      values={values}
    />
  );
};

GLCodesSection.propTypes = {
  values: PropTypes.shape({}),
  clientId: PropTypes.string.isRequired,
};

export default GLCodesSection;
