import { useMemo } from "react";

import useScopedCurrency from "./useScopedCurrency";

/**
 *
 * @return {{format: (function(*=): string)}}
 */
function useCurrencyFormatter(currency, decimalPlaces = 2) {
  let scopedCurrency = useScopedCurrency();

  if (currency) {
    scopedCurrency = currency;
  }
  return useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: scopedCurrency.isoCode,
      minimumFractionDigits: decimalPlaces,
    });

    return { format: number => formatter.format(number || 0) };
  }, [scopedCurrency, decimalPlaces]);
}

export default useCurrencyFormatter;
