import styled from "styled-components";
import Paper from "../../../mui/core/Paper";
import BoldText from "../../../ui/Typography/BoldText";
import SerifTitle from "../../../ui/Typography/SerifTitle";

export default styled(Paper)`
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 16px;
  ${BoldText} {
    line-height: 24px;
  }
  ${SerifTitle} {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    line-height: 28px;
  }
`;
