import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import { ActionButtons } from "../../../../components/forms";

import * as QUEUE_STATUSES from "../../../../constants/queue";

const getButtonText = (isFromProjects, step, selectedIds, rows) => {
  const emptyText = isFromProjects ? "No Spec Selected" : "No File Selected";
  const actionText = {
    selection: "Select",
    edition: "Import",
  };

  if (selectedIds.length > 0)
    return `${actionText[step]} ${pluralize("spec", selectedIds.length, true)}`;

  return rows.length === 0 ? emptyText : "Import Specs";
};

const shouldActionDisable = (invalidSelectedRowIds, step, selectedIds) => {
  return (
    !selectedIds.length ||
    !!(invalidSelectedRowIds.length && step === "edition")
  );
};

export const Buttons = ({
  isFromProjects,
  onImport,
  onCancel,
  bulkQueue,
  selectedIds,
  step,
  onEditableModel,
  rows,
  invalidSelectedRowIds,
}) => {
  const onSend =
    isFromProjects && step === "selection" ? onEditableModel : onImport;
  const actionText = getButtonText(isFromProjects, step, selectedIds, rows);
  return (
    <ActionButtons
      onSend={onSend}
      sendButtonText={actionText}
      onCancel={onCancel}
      isModal={true}
      additionalProps={{
        send: {
          disabled: shouldActionDisable(
            invalidSelectedRowIds,
            step,
            selectedIds
          ),
          isLoading:
            bulkQueue && bulkQueue.status === QUEUE_STATUSES.QUEUE_PROCESSING,
        },
      }}
    />
  );
};

Buttons.propTypes = {
  isFromProjects: PropTypes.bool,
  onCancel: PropTypes.func,
  selectedIds: PropTypes.array,
  onImport: PropTypes.func.isRequired,
  bulkQueue: propTypes.queue,

  step: PropTypes.oneOf(["selection", "edition"]),
  onEditableModel: PropTypes.func,
  rows: PropTypes.arrayOf(propTypes.spec),
  invalidSelectedRowIds: PropTypes.arrayOf(PropTypes.string),
};
export default Buttons;
