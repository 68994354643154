import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";

import propTypes from "../../constants/propTypes";

/**
 * Possible actions.
 * @enum {string}
 */
export const Actions = {
  SHOW: "show",
  HIDE: "hide",
};

export function shouldDisplay(actionOnRole, roleNames, actualRoleName) {
  const includes = roleNames.includes(actualRoleName);

  if (includes) {
    return actionOnRole === Actions.SHOW;
  }
  return actionOnRole === Actions.HIDE;
}

/**
 *
 * @param WrappedComponent
 * @param roleNames {Array|String} list of role.name that the wrapped
 * @param actionOnRole {Actions} action to perform when the role name is present
 * component should be rendered for.
 */
const withRenderControlByRole = (WrappedComponent, roleNames, actionOnRole) => {
  roleNames = Array.isArray(roleNames) ? roleNames : [roleNames];
  const Wrapper = ({ role, ...props }) => {
    const actualRoleName = _get(role, "name", "guest");
    if (!shouldDisplay(actionOnRole, roleNames, actualRoleName)) return null;
    return <WrappedComponent {...props} />;
  };

  Wrapper.propTypes = {
    role: propTypes.userRole,
  };

  const mapStateToProps = ({ auth: { role } }) => {
    return {
      role,
    };
  };

  return connect(mapStateToProps)(Wrapper);
};

export default withRenderControlByRole;
