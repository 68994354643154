import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import _get from "lodash/get";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import propTypes from "../../../../constants/propTypes";
import { RemoveIcon } from "../components";

const columns = ["name", "categoryId", "website", "actions"];

const columnOptions = ({ removeSelectedItem }) => ({
  name: {
    title: "Vendor Name",
    filter: false,
    bold: true,
  },
  categoryId: {
    render: row => row && _get(row, "category.name"),
    title: "Category",
    filter: false,
  },
  website: {
    title: "Website",
    fullWidth: true,
  },
  actions: {
    title: " ",
    align: "right",
    render: row =>
      row && (
        <div>
          <RemoveIcon onClick={() => removeSelectedItem(row)} />
        </div>
      ),
  },
});
const sorting = [{ columnName: "name", direction: "asc" }];

const SelectedVendor = ({ vendors, removeSelectedItem }) => {
  const totalRows = useMemo(() => vendors.length, [vendors.length]);
  const headerText = useMemo(() => pluralize("Vendor", totalRows, true), [
    totalRows,
  ]);

  return (
    <BWGridLocal
      noBorder
      id="bidgroup-selected-shipments"
      sorting={sorting}
      rows={vendors}
      gridConfig={{ pageSize: 10, totalRows }}
      emptyStateText="No Vendor Selected"
      emptyStateProps={{ intent: "ligth" }}
      revisionReference="specs"
    >
      <GridHeader headerText={headerText} />
      <GridTable
        columns={columns}
        columnOptions={columnOptions({ removeSelectedItem })}
      />
    </BWGridLocal>
  );
};

SelectedVendor.propTypes = {
  vendors: PropTypes.arrayOf(propTypes.vendor),
  removeSelectedItem: PropTypes.func,
};

export default SelectedVendor;
