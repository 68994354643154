import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../constants/propTypes";
import NavigationBar from "../../layout/NavigationBar/NavigationBar";
import ShowHideControl from "./Analysis/ShowHideControl";
import ViewByControl from "./Quotes/ViewByControl/ViewByControl";

const navigationTabs = [
  {
    label: "Vendors",
    path: "/vendors",
  },
  {
    label: "Quotes",
    path: "/quotes",
  },
  {
    label: "Analysis",
    path: "/analysis",
  },
];

const VendorActionCard = ({
  generateAndDownloadReport,
  bidGroup,
  bidGroupVendors,
  paymentTerms,
}) => {
  return (
    <NavigationBar
      moreTabColor={"rgba(0,0,0,0.38)"}
      navigationTabs={navigationTabs}
      leftChildren={
        <React.Fragment>
          <ViewByControl />
          <ShowHideControl
            generateAndDownloadReport={generateAndDownloadReport}
            bidGroup={bidGroup}
            bidGroupVendors={bidGroupVendors}
            paymentTerms={paymentTerms}
          />
        </React.Fragment>
      }
    />
  );
};

VendorActionCard.propTypes = {
  bidGroup: propTypes.bidGroup,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  paymentTerms: PropTypes.arrayOf(propTypes.paymentTerm),
  generateAndDownloadReport: PropTypes.func,
};

export default VendorActionCard;
