import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import QuotingPage from "./QuotingPage";
import {
  initQuotingCategoriesDC,
  initQuotingPurchaseOrderDC,
  quotingBidGroupsDCId,
  quotingPODCId,
  refreshPurchaseOrderList,
  refreshBidGroupsList,
} from "../../../actions/quotingActions";
import { processDeleteRequestStatus } from "../../../utils/dataComponentUtils";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import propTypes from "../../../constants/propTypes";
import {
  openModalDialog,
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";
import withScrollOnLeave from "../../../withScrollOnLeave";
import {
  bidGroupFields,
  poFields,
  buildOnFilter,
  getFilters,
} from "./filterOptions";

const projectDetailComponentId = "ProjectDetail";

export class QuotingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { filterOptions: {} };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.projectId !== nextProps.projectId ||
      this.props.dataComponent !== nextProps.dataComponent ||
      this.state.filterOptions !== nextState.filterOptions
    );
  }

  componentDidMount() {
    this.props.initQuotingCategoriesDC();
    this.props.initQuotingPurchaseOrderDC();
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, refreshPurchaseOrderList, projectId } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => refreshPurchaseOrderList(projectId)(0),
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;
    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleClearFilter = () => {
    this.setState({ filterOptions: {} });
  };

  handleOpenFilter = () => {
    const {
      openModalDialog,
      closeModalDialog,
      projectId,
      refreshBidGroupsList,
      refreshPurchaseOrderList,
    } = this.props;

    const element = this.element;
    const options = {
      "po-groups": {
        title: "Direct to Purchase Order",
        fields: poFields,
        dataComponentId: quotingPODCId,
        filterMethod: (...props) => refreshPurchaseOrderList(...props)(0),
      },
      "bid-groups": {
        title: "Bid Groups",
        dataComponentId: quotingBidGroupsDCId,
        filterMethod: (...props) => refreshBidGroupsList(...props)(0),
        fields: bidGroupFields,
      },
    };

    const option = options[element];

    openModalDialog(
      "Filter",
      "QuotingFilter",
      {
        projectId,
        dataComponentId: option.dataComponentId,
        onFilter: buildOnFilter({
          projectId,
          closeModalDialog,
          filterMethod: option.filterMethod,
          setState: this.setState.bind(this),
          element,
        }),
        initialValues: this.state.filterOptions,
        defaultFilters: {
          projectId,
        },
        ...option,
      },
      false,
      false,
      {
        scrollDisabled: true,
        subtitle: option.title,
      }
    );
  };

  get element() {
    return this.props.location.pathname.split("/").pop();
  }

  render() {
    const { projectId, isClosedProject, route, role, location } = this.props;
    return (
      <QuotingPage
        filterOptions={this.state.filterOptions}
        filters={getFilters(this.state.filterOptions, this.element)}
        isClosedProject={isClosedProject}
        projectId={projectId}
        route={route}
        location={location}
        role={role}
        onOpenFilter={this.handleOpenFilter}
        onClearFilter={this.handleClearFilter}
      />
    );
  }
}

QuotingContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  isClosedProject: PropTypes.bool,
  initQuotingCategoriesDC: PropTypes.func.isRequired,
  initQuotingPurchaseOrderDC: PropTypes.func.isRequired,
  refreshPurchaseOrderList: PropTypes.func.isRequired,
  refreshBidGroupsList: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  location: PropTypes.object,
  route: propTypes.route,
  role: propTypes.userRole,
};

export const mapStateToProps = (state, { match }) => {
  const poListDataComponent = getDataComponent(quotingPODCId, state);
  const projectId = match.params.projectId;
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDetailComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const project = getBluechipResourceById(
    projectDataComponent,
    state,
    projectId
  );
  const isClosedProject =
    project && ["In Closing", "Closed"].includes(project.status);
  return {
    role: state.auth.role,
    projectId,
    dataComponent: poListDataComponent,
    isClosedProject,
  };
};

export const mapDispatchToProps = dispatch => ({
  refreshPurchaseOrderList: (...props) => pageNumber =>
    dispatch(refreshPurchaseOrderList(...props)(pageNumber)),
  refreshBidGroupsList: (...props) => pageNumber =>
    dispatch(refreshBidGroupsList(...props)(pageNumber)),
  ...bindActionCreators(
    {
      initQuotingCategoriesDC,
      initQuotingPurchaseOrderDC,
      showSnackNotificationAction,
      openModalDialog,
      closeModalDialog,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScrollOnLeave(QuotingContainer));
