import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  padding: 4px 12px !important;
`;
