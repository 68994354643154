import React, { useMemo } from "react";

import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import styled from "styled-components";

import propTypes from "../../../../../../constants/propTypes";
import BWGridLocal from "../../../../../ui/BWGrid/BWGridLocal";
import GridHeader from "../../../../../ui/BWGrid/GridHeader";
import GridTable from "../../../../../ui/BWGrid/GridTable";
import Date from "../../../../../ui/BWGrid/columns/Date";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import { PRIMARY_DATE_FORMAT } from "../../../../../../constants/formats";

const Wrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

export const Header = styled.div`
  font-weight: normal;
  font-size: 20px;
  font-family: Domine, sans-serif;
  font-style: normal;

  color: rgba(0, 0, 0, 0.87);
`;

export const SubHeader = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 24px;
  align-items: center;
  padding: 8px 0px;
`;

export const formatWithNegatives = (amount, formatter) => {
  const formattedAmount = formatter.format(Math.abs(amount));
  return amount < 0 ? `(${formattedAmount})` : formattedAmount;
};

const Status = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Billed = styled(Status)`
  color: #57abff;
  padding-left: 14px;
`;

export const Partial = styled(Status)`
  color: #ffb74d;
  padding-left: 14px;
`;

export const statusMap = {
  Billed,
  "Partially Paid": Partial,
};

const columns = ["referenceNumber", "amount", "paymentDate"];
export const getColumnsOptions = formatter => ({
  referenceNumber: {
    fill: true,
    title: "Reference Number",
  },
  amount: {
    title: "Amount",
    bold: true,
    render: ({ amount }) => {
      return formatWithNegatives(amount, formatter);
    },
  },
  paymentDate: {
    title: "Payment Date",
    render: ({ paymentDate }) =>
      paymentDate && <Date date={paymentDate} format={PRIMARY_DATE_FORMAT} />,
  },
});

const PaymentsList = ({ fee, toggleShowList }) => {
  const headerActions = useMemo(
    () =>
      !fee.revenueSharing &&
      ["Billed", "Partially Paid"].indexOf(fee.status) !== -1
        ? [
            {
              text: "Add Payment",
              icon: <AddCircle />,
              handler: toggleShowList,
            },
          ]
        : [],
    [fee.revenueSharing, fee.status, toggleShowList]
  );

  const { projectCurrency, feePayments } = fee;

  const formatter = useCurrencyFormatter(projectCurrency?.currency);

  const { gridConfig, columnOptions } = useMemo(() => {
    return {
      gridConfig: { pageSize: 0, totalRows: feePayments.length },
      columnOptions: getColumnsOptions(formatter),
    };
  }, [feePayments, formatter]);

  const StatusWrapper = statusMap[fee.status] || Billed;
  const balance = fee.amount - fee.amountPaid;

  const rowMenu = useMemo(() => {
    return [{ text: "Edit", onClick: toggleShowList }];
  }, [toggleShowList]);

  return (
    <Wrapper>
      <BWGridLocal
        gridConfig={gridConfig}
        rows={feePayments}
        emptyStateText="No Payments Recorded"
        noBorder
      >
        <GridHeader
          headerOverride={
            <div>
              <Header>{`Balance ${formatter.format(balance)}`}</Header>
              <SubHeader>
                Status <StatusWrapper>{fee.status}</StatusWrapper>
              </SubHeader>
            </div>
          }
          actions={headerActions}
          isMultiline
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu}
        />
      </BWGridLocal>
    </Wrapper>
  );
};

PaymentsList.defaultProps = {
  fee: { fungindPayments: [] },
};

PaymentsList.propTypes = {
  fee: propTypes.fee,
  toggleShowList: PropTypes.func,
};

export default PaymentsList;
