import React from "react";
import PropTypes from "prop-types";

import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const DeleteShipToSiteContainer = props => {
  const {
    title,
    vendorShipToSiteId,
    formikArrayHelpers,
    index,
    onDeleteShipToSite,
  } = props;

  const handleDelete = () => {
    onDeleteShipToSite(vendorShipToSiteId, index, formikArrayHelpers);
  };

  return <DeleteConfirmation title={title} onDelete={handleDelete} />;
};

DeleteShipToSiteContainer.propTypes = {
  title: PropTypes.string.isRequired,
  vendorShipToSiteId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.string,
  onDeleteShipToSite: PropTypes.func.isRequired,
};

export default DeleteShipToSiteContainer;
