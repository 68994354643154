import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InvoiceForm from "./InvoiceForm";
import { showSnackNotificationAction } from "../../../../../../actions/layoutActions";
import propTypes from "../../../../../../constants/propTypes";
import {
  performCreateRequest,
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import RemitAddress from "../../../../../../models/RemitAddress";
import PurchaseOrder from "../../../../../../models/PurchaseOrder";
import { handleRequestGlobalErrors } from "../../../../../../utils/formValidationUtils";
import { getBluechipResources } from "../../../../../../utils/bluechipUtils";
import { processCreateRequestStatus } from "../../../../../../utils/dataComponentUtils";
import { dataComponentId } from "../InvoicesContainer";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  formikActions,
  showSnackNotificationAction
) => () => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onError: handleRequestGlobalErrors(
      showSnackNotificationAction,
      formikActions
    ),
  });
};

const getNewInvoice = (
  { number, purchaseOrderId, remitAddressId, remitAddress },
  purchaseOrder
) => {
  if (remitAddressId !== "action-add") {
    return { number, purchaseOrderId, remitAddressId };
  }
  return {
    number,
    purchaseOrderId,
    remitAddress: { ...remitAddress, vendorId: purchaseOrder.vendorId },
  };
};

export const CreateInvoiceContainer = ({
  dataComponent,
  showSnackNotificationAction,
  performCreateRequest,
  permissionsByGroup,
  initDataComponent,
  projectId,
  clientId,
  performRetrieveListRequest,
  purchaseOrders,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    if (purchaseOrder) {
      performRetrieveListRequest("select-remit-addresses", {
        rootFilters: {
          $where: {
            vendorId: purchaseOrder.vendorId,
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
      });
    }
  }, [performRetrieveListRequest, purchaseOrder]);

  useEffect(() => {
    initDataComponent(
      "select-purchase-orders",
      PurchaseOrder,
      ["vendor"],
      "purchase-orders"
    );
    initDataComponent(
      "select-remit-addresses",
      RemitAddress,
      [],
      "remit-addresses"
    );

    performRetrieveListRequest("select-purchase-orders", {
      rootFilters: {
        $where: {
          projectId,
          status: "Issued",
          revisionStatus: {
            $exists: false,
          },
        },
      },
      sort: [{ columnName: "number", direction: "asc" }],
      pageSize: -1,
      params: {
        omitDefaultModifier: true,
        modifiers: ["withBasicFields"],
      },
    });
  }, [clientId, initDataComponent, performRetrieveListRequest, projectId]);

  useEffect(
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      formikActions,
      showSnackNotificationAction
    )
  );

  const handleCreateInvoice = useCallback(
    (invoice, formikActions) => {
      const newInvoice = getNewInvoice(invoice, purchaseOrder);
      performCreateRequest(dataComponentId, newInvoice);
      setFormikAction(formikActions);
    },
    [performCreateRequest, purchaseOrder]
  );

  const handlePurchaseOrderIdChange = useCallback(
    purchaseOrderId => {
      const purchaseOrder = purchaseOrders.find(
        ({ id }) => id === purchaseOrderId
      );
      if (purchaseOrder) {
        setPurchaseOrder(purchaseOrder);
        return purchaseOrder;
      }
    },
    [purchaseOrders]
  );

  return (
    <InvoiceForm
      permissionsByGroup={permissionsByGroup}
      onSubmit={handleCreateInvoice}
      setPurchaseOrderId={handlePurchaseOrderIdChange}
    />
  );
};

CreateInvoiceContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  clientId: PropTypes.string,
  performCreateRequest: PropTypes.func,
  permissionsByGroup: PropTypes.shape({}),
  loading: PropTypes.bool,
  performRetrieveListRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  performCreateRequest,
  initDataComponent,
  performRetrieveListRequest,
  showSnackNotificationAction,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);

  const purchaseOrdersDataComponent = getDataComponentFlattenedRequestState(
    "select-purchase-orders",
    state
  );
  const purchaseOrders =
    getBluechipResources(purchaseOrdersDataComponent, state) || [];

  return {
    dataComponent,
    purchaseOrders,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CreateInvoiceContainer));
