import TextInputWithError from "../TextInputWithError";
import FilePickerWithError from "../FilePickerWithError";
import CurrencyInput from "../CurrencyInput";
import DatePickerWithError from "../DatePickerWithError";
import FilePickerAndDowloadWithError from "../FilePickerAndDownloadWithError";
import { SelectWithError } from "../SelectWithError";
import { getOptionsFromSchema } from "../utils";

export default {
  amountRequested: {
    name: "amountRequested",
    InputComponent: TextInputWithError,
    label: "Amount Requested",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { allowNegative: true },
    },
  },
  amount: {
    name: "amount",
    InputComponent: TextInputWithError,
    label: "Fund received",
    fullWidth: true,
    inputProps: {
      maskProps: { allowNegative: true },
    },
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  paymentReceivedAt: {
    InputComponent: DatePickerWithError,
    label: "Payment Received Date",
    name: "receivedAt",
    fullWidth: true,
  },
  paymentRecord: {
    name: "paymentRecord",
    InputComponent: TextInputWithError,
    label: "Funding Record Number",
    fullWidth: true,
    helperText: "Please provide a valid Record Number",
  },
  fundsReceived: {
    name: "fundsReceived",
    InputComponent: TextInputWithError,
    label: "Funds Received",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  fundingTypes: {
    InputComponent: SelectWithError,
    name: "type",
    label: "Funding Type",
    options: getOptionsFromSchema("FundingPayment", "types"),
    displayEmpty: false,
    fullWidth: true,
  },
  projectBudgetFileWithDownload: {
    InputComponent: FilePickerAndDowloadWithError,
    name: "projectBudgetFile",
    label: "Select Project Budget File",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {
      isForm: true,
    },
  },
  cashflowFileWithDownload: {
    InputComponent: FilePickerAndDowloadWithError,
    name: "cashflowFile",
    label: "Select Cashflow File",
    fullWidth: true,
    useFileWrapper: true,
    helperText: ".xls only",
    inputFileProps: {
      accept: ".xls,.xlsx",
      isForm: true,
    },
  },
  cashflowFile: {
    InputComponent: FilePickerWithError,
    name: "cashflowFile",
    label: "Select Cashflow File",
    fullWidth: true,
    useFileWrapper: true,
    helperText: ".xls only",
    inputFileProps: {
      accept: ".xls,.xlsx",
      isForm: true,
    },
  },
  projectBudgetFile: {
    InputComponent: FilePickerWithError,
    name: "projectBudgetFile",
    label: "Select Project Budget File",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {
      isForm: true,
    },
  },
};
