import React, { useContext } from "react";
import _get from "lodash/get";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InputAdornment } from "@material-ui/core";

import { StyledTextField } from "../../../../inputs/TextInputWithError";
import propTypes from "../../../../../constants/propTypes";
import CurrencyInput from "../../../../inputs/CurrencyInput";
import { LoaderContext } from "../../../Loader";
import { Button, clearText, Close } from "./TextTypeProvider";

export const FilterTextField = styled(StyledTextField)`
  margin: 0px;
  & .input {
    font-weight: normal;
    font-size: 14px;
  }
`;

export const EditorComponent = ({ onValueChange, value, ...props }) => {
  const { loading } = useContext(LoaderContext);

  const placeholder = _get(props, "column.placeholder", "Filter...");
  const handleChange = ({ target: { value } }) => {
    onValueChange(value);
  };

  return (
    <FilterTextField
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      InputProps={{
        inputComponent: CurrencyInput,
        readOnly: loading,
        endAdornment: (
          <InputAdornment position="end">
            <Button disabled={loading} onClick={clearText(handleChange, value)}>
              <Close />
            </Button>
          </InputAdornment>
        ),
      }}
      inputProps={{
        maskProps: { prefix: "" },
      }}
    />
  );
};
EditorComponent.defaultProps = {
  value: "",
};

EditorComponent.propTypes = {
  onValueChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};

const NumberTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(
    column => columnOptions[column].filter === "number"
  );
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={EditorComponent} />
  ) : null;
};
NumberTypeProvider.propTypes = propTypes.filteringComponent;

export default NumberTypeProvider;
