import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";

import SearchBox from "../../../../../ui/SearchBox";
import { MenuContainer, SearchItem } from "../components";
import List from "./List";
import propTypes from "../../../../../../constants/propTypes";

const StyledMenuContainer = styled(MenuContainer)`
  ul {
    width: 371px;
  }
`;

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const filterVendors = memoize((items, searchVal) => {
  const searchReg = new RegExp(escapeRegExp(searchVal), "i");
  return items.filter(item => searchReg.test(item.name));
});

const AddVendorMenu = ({
  vendors,
  initDataComponent,
  updateSpecVendorId,
  loadVendors,
  anchorEl,
  onClose,
  loading,
}) => {
  const [searchVal, setSearchVal] = useState("");

  const isVendorListEmpty = _isEmpty(vendors);

  useEffect(() => {
    if (anchorEl && isVendorListEmpty) {
      initDataComponent();
      loadVendors();
    }
  }, [initDataComponent, loadVendors, anchorEl, isVendorListEmpty]);

  const menuProps = useCallback(() => {
    return {
      anchorEl: anchorEl,
      open: Boolean(anchorEl),
      onClose: onClose,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    };
  }, [anchorEl, onClose]);

  const setSearchText = useCallback(({ target: { value } }) => {
    setSearchVal(value);
  }, []);

  const getVendors = useCallback(() => {
    return filterVendors(vendors, searchVal);
  }, [searchVal, vendors]);

  const handleSelectVendorClick = useCallback(
    vendorId => {
      updateSpecVendorId(vendorId);
      onClose();
    },
    [onClose, updateSpecVendorId]
  );

  return (
    <StyledMenuContainer {...menuProps()}>
      <SearchItem button={false}>
        <SearchBox
          name="searchVal"
          value={searchVal}
          onChange={setSearchText}
        />
      </SearchItem>
      <List
        displayPlaceholder={true}
        vendors={getVendors()}
        onClick={handleSelectVendorClick}
        loading={loading}
      />
    </StyledMenuContainer>
  );
};

AddVendorMenu.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(propTypes.vendor),
  initDataComponent: PropTypes.func.isRequired,
  loadVendors: PropTypes.func.isRequired,
  updateSpecVendorId: PropTypes.func.isRequired,
};

AddVendorMenu.defaultProps = {
  vendors: [],
};
export default AddVendorMenu;
