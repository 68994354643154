import React, { Fragment } from "react";
import * as BID_TYPES from "../../bidTypes";

export const getBackGroundColor = bgVendor => {
  if (bgVendor.isAlternateQuote) {
    return "#EDF0F7";
  }

  return bgVendor.bidType === BID_TYPES.COM_SPEC_ONLY ? "#F1F1F1" : null;
};
export const getBorderStyle = bgVendor => {
  return bgVendor.bidType === BID_TYPES.COM_SPEC_ONLY ? "dashed" : null;
};
/* eslint-disable max-lines-per-function  */
/* eslint-disable sonarjs/cognitive-complexity */
export default (
  bgVendors,
  isBudgetSectionVisible,
  isCOMVisible,
  isSampleColumnsVisible
) => () => {
  const staticColumns = [
    { name: "indicator", title: " ", width: "24px" },
    {
      name: "description",
      title: "Description",
      filter: "text",
    },
    {
      name: "customNumber",
      title: "Spec Number",
      bold: true,
      filter: "text",
    },
    {
      name: "totalSampleQuantity",
      title: (
        <Fragment>
          Total Sample
          <br />
          Qty.
        </Fragment>
      ),
      bold: true,
    },
    {
      name: "totalProductionQuantity",
      title: (
        <Fragment>
          Total. Production
          <br />
          Qty.
        </Fragment>
      ),
      bold: true,
      displayBorderRight: true,
    },
  ];

  if (isBudgetSectionVisible) {
    staticColumns.push(
      {
        name: "designerBudget",
        title: (
          <Fragment>
            Designer
            <br />
            Budget
          </Fragment>
        ),
        firstColumn: true,
        backGroundColor: "#EDF0F7",
      },
      {
        name: "designerQuantity",
        title: (
          <Fragment>
            Designer
            <br />
            Quantity
          </Fragment>
        ),
        backGroundColor: "#EDF0F7",
      },
      {
        name: "designerExtendedCost",
        title: (
          <Fragment>
            Ext. Designer
            <br />
            Pricing
          </Fragment>
        ),
        displayBorderRight: true,
        backGroundColor: "#EDF0F7",
      }
    );
  }

  return bgVendors.reduce((columns, bgVendor, vendorIndex) => {
    const currentColumns = [];

    if (isCOMVisible && isSampleColumnsVisible) {
      currentColumns.push(
        {
          name: `quote_${vendorIndex}_sampleCOMQuantity`,
          title: (
            <Fragment>
              Sample
              <br />
              COM Qty.
              <br />
              per Each
            </Fragment>
          ),
          firstColumn: true,
        },
        {
          name: `quote_${vendorIndex}_extendedSampleCOMQuantity`,
          title: (
            <Fragment>
              Ext. Sample
              <br />
              COM Qty.
            </Fragment>
          ),
        }
      );
    }

    if (isSampleColumnsVisible) {
      currentColumns.push(
        {
          name: `quote_${vendorIndex}_samplePricing`,
          title: (
            <Fragment>
              Sample
              <br />
              Pricing
            </Fragment>
          ),
          firstColumn: !isCOMVisible,
        },
        {
          name: `quote_${vendorIndex}_extendedSamplePricing`,
          title: (
            <Fragment>
              Ext. Sample
              <br />
              Pricing
            </Fragment>
          ),
        }
      );
    }

    if (isCOMVisible) {
      currentColumns.push(
        {
          name: `quote_${vendorIndex}_productionCOMQuantity`,
          title: (
            <Fragment>
              COM Qty.
              <br />
              per Each
            </Fragment>
          ),
          firstColumn: !isSampleColumnsVisible,
          backGroundColor: getBackGroundColor(bgVendor),
          borderStyle: getBorderStyle(bgVendor),
        },
        {
          name: `quote_${vendorIndex}_extendedProductionCOMQuantity`,
          title: (
            <Fragment>
              Ext.
              <br />
              COM Qty.
            </Fragment>
          ),
          backGroundColor: getBackGroundColor(bgVendor),
          borderStyle: getBorderStyle(bgVendor),
        }
      );
    }

    currentColumns.push(
      {
        name: `quote_${vendorIndex}_productionPricing`,
        title: (
          <Fragment>
            Production
            <br />
            Pricing
          </Fragment>
        ),
        firstColumn: !isCOMVisible && !isSampleColumnsVisible,
        backGroundColor: getBackGroundColor(bgVendor),
        borderStyle: getBorderStyle(bgVendor),
      },
      {
        name: `quote_${vendorIndex}_extendedProductionPricing`,
        title: (
          <Fragment>
            Ext. Production
            <br />
            Pricing
          </Fragment>
        ),
        backGroundColor: getBackGroundColor(bgVendor),
        borderStyle: getBorderStyle(bgVendor),
      },
      {
        name: `quote_${vendorIndex}_selectCOM`,
        title: " ",
        backGroundColor: getBackGroundColor(bgVendor),
        borderStyle: getBorderStyle(bgVendor),
      },
      {
        name: `quote_${vendorIndex}_chevron`,
        title: " ",
        backGroundColor: getBackGroundColor(bgVendor),
        borderStyle: getBorderStyle(bgVendor),
        displayBorderRight: true,
      }
    );
    columns.push(...currentColumns);
    return columns;
  }, staticColumns);
};
