import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PurchaseOrdersNeedsFollowUpGrid from "./PurchaseOrdersNeedsFollowUpGrid";
import Project from "../../../../models/Project";
import propTypes from "../../../../constants/propTypes";
import { goToPurchaseOrderDetail } from "../../../../actions/dashboardActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";

const dataComponentId = "DashboardPOsNeedsFollowUp";

export const PurchaseOrdersNeedsFollowUpContainer = ({
  columns,
  dataComponent,
  apiFilters,
  goToPurchaseOrderDetail,
}) => {
  return (
    <PurchaseOrdersNeedsFollowUpGrid
      columns={columns}
      dataComponent={dataComponent}
      apiFilters={apiFilters}
      onRowClick={goToPurchaseOrderDetail}
    />
  );
};

PurchaseOrdersNeedsFollowUpContainer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataComponent: propTypes.dataComponent,
  project: propTypes.project,
  apiFilters: PropTypes.object.isRequired,
  goToPurchaseOrderDetail: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  return {
    dataComponent,
    project: Project.query(state.resources).find(),
  };
};

const mapDispatchToProps = {
  goToPurchaseOrderDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrdersNeedsFollowUpContainer);
