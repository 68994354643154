import React, { useMemo } from "react";
import styled from "styled-components";

import { COM_PREVIEW } from "../../../../../../constants/specs";

export const COMPreviewContainer = styled.div`
  border-radius: 2px;
  margin: 12px;
  margin-left: 16px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${({ imgUrl }) => (imgUrl ? imgUrl : COM_PREVIEW)});
`;

export const FilePreview = props => {
  const file = props.preview?.file?.file;

  const imgUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
  }, [file]);

  return <COMPreviewContainer {...props} imgUrl={imgUrl} />;
};

export default FilePreview;
