import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";

import PopoverDeleteConfirmation from "../../../../../ui/PopoverDeleteConfirmation";
import propTypes from "../../../../../../constants/propTypes";
import { IconButton } from "../../../../../ui/Grid/utilComponents/dataTypeProviders/ActionIconsProvider";
import styled from "styled-components";

const Progress = styled(CircularProgress)`
  color: rgb(87, 171, 255);
`;

function DeleteFileButton({ fileWrapper, onSubmit, isLoading, isActive }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );

  const handleDelete = useCallback(() => {
    onSubmit(fileWrapper);
    handleClose();
  }, [handleClose, fileWrapper, onSubmit]);

  return (
    <Fragment>
      <Tooltip title="Delete">
        <IconButton disabled={isLoading} onClick={handleOpen}>
          {isActive ? <Progress size={24} /> : <Delete />}
        </IconButton>
      </Tooltip>
      <PopoverDeleteConfirmation
        title={fileWrapper.file.filename}
        anchorEl={anchorEl}
        onDelete={handleDelete}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
    </Fragment>
  );
}

DeleteFileButton.propTypes = {
  fileWrapper: PropTypes.shape({ key: PropTypes.string, file: propTypes.file }),
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default DeleteFileButton;
