export const onHandleCOMClicked = (
  bgVendor,
  comItem,
  selectedCOMs,
  setSelectedCOMs
) => {
  const updatedSelectedCOMs = Object.assign({}, selectedCOMs);
  comItem.bgVendorId = bgVendor.id;
  if (
    updatedSelectedCOMs[comItem.specId] &&
    updatedSelectedCOMs[comItem.specId].bgVendorId === bgVendor.id
  ) {
    delete updatedSelectedCOMs[comItem.specId];
  } else {
    updatedSelectedCOMs[comItem.specId] = comItem;
  }
  setSelectedCOMs(updatedSelectedCOMs);
};
