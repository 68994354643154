import React, { useRef } from "react";
import PropTypes from "prop-types";

import ContactForm from "./ContactForm";
import propTypes from "../../../constants/propTypes";

import { handleRequestError } from "../../../utils/formValidationUtils";
import { useCreateProcessRequests } from "../../hooks/useProcessRequest";
import { useDatacomponent } from "../../hooks/useDatacomponent";

import { addressBookDataComponentId } from "./AddressBook";

import useUserRole from "../../hooks/useUserRole";

const CreateContactContainer = props => {
  const {
    formikArrayHelpers,
    closeModalDialog,
    contact,
    index,
    onCreateContact,
    onUpdateContact,
  } = props;
  const isEditing = typeof index !== "undefined";

  const formikActionsRef = useRef();

  const dataComponent = useDatacomponent(addressBookDataComponentId);
  useCreateProcessRequests(dataComponent, {
    onError: error => {
      if (error.data.errors.some(error => error.source === "firstName")) {
        error.data.errors.push({ code: "unique", source: "lastName" });
      }
      handleRequestError(error, formikActionsRef.current);
    },
  });

  const handleCreateContact = (contact, formikActions) => {
    formikActionsRef.current = formikActions;

    const tempContact = { ...contact };
    if (tempContact.status != "inact") {
      tempContact.status = "act";
    }

    onCreateContact(tempContact, formikArrayHelpers);
  };

  const handleEditContact = contact => {
    onUpdateContact(contact, index, formikArrayHelpers);
  };

  const userRole = useUserRole();

  return (
    <ContactForm
      isEditing={isEditing}
      contact={contact}
      onSubmit={isEditing ? handleEditContact : handleCreateContact}
      closeModalDialog={closeModalDialog}
      userRole={userRole}
    />
  );
};

CreateContactContainer.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  onCreateContact: PropTypes.func.isRequired,
  onUpdateContact: PropTypes.func.isRequired,
  contact: propTypes.contact,
  index: PropTypes.string,
};

export default CreateContactContainer;
