import { BaseModel } from "@blue-chip/core";
import Project from "./Project";
import Entity from "./Entity";
import Location from "./Location";

export default class Property extends BaseModel {
  static get hasMany() {
    return [Project];
  }

  static get belongsTo() {
    return [Entity, Location];
  }
}
