import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import FormGrid from "../../../../components/forms/FormGrid";
import createClientContactFields from "./createClientContactFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";

export default class CreateClientContactForm extends Component {
  static propTypes = {
    createClientContact: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    validationSchema: PropTypes.object.isRequired,
  };

  clientContactInitialValues = {
    contact: {
      firstName: "",
      lastName: "",
      phone: "",
      fax: "",
      email: "",
      status: "inact",
      jobTitle: "",
    },
    canAccessClientPortal: false,
  };

  handleCancel = () => {
    this.props.closeModalDialog();
  };

  render() {
    const { createClientContact, validationSchema } = this.props;

    return (
      <FormikForm
        initialValues={this.clientContactInitialValues}
        onSubmit={createClientContact}
        validationSchema={validationSchema}
        ignoreValidationFieldNames={["name", "contactId", "clientId"]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createClientContactFields(this.props)}
              values={values}
              errors={errors}
              {...formikProps}
            />

            <ActionButtons
              onSend={handleSubmit}
              sendButtonText="CREATE CONTACT"
              onCancel={this.handleCancel}
              isModal={true}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}
