import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { saveSpecSubmittal } from "../../../../../actions/submittalActions";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import useActions from "../../../../hooks/useActions";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

import { Wrapper } from "../ReviewSubmittals/components";
import { dataComponentId } from "../../../../../actions/specDetailActions";
import Grid from "../ReviewSubmittals/Grid";
import EditSubmittal from "../ReviewSubmittals/EditSubmittal";
import { uploadFileActions } from "./uploadFileActions";
import propTypes from "../../../../../constants/propTypes";
import { DragDropDashboard } from "../../../../inputs/DragDrop/DragDropDashboard";
import { LoaderContext } from "../../../../ui/Loader";

const UploadFiles = ({
  spec: propSpec,
  selectedSpecRequirementIds,
  onUpdateSpec,
  afterSave,
  ...props
}) => {
  const [selectedSubmittal, setSelectedSubmittal] = useState(null);
  const [submittalFiles, setSubmittalFiles] = useState([]);
  const [loaderActive, setLoaderActive] = useState();
  const newSubmittalFilesRef = useRef([]);
  const uploadCount = useRef(0);

  const actions = useActions({ saveSpecSubmittal });

  const spec =
    useV2DatacomponentResources(dataComponentId, null, REQUEST_TYPES.FIND) ||
    propSpec;

  const specRequirements = spec.specRequirements || props.specRequirements;
  const selectedSpecRequirements = specRequirements.filter(specRequirement =>
    selectedSpecRequirementIds.includes(specRequirement.id)
  );

  const handleSelectSubmittal = submittalFile =>
    setSelectedSubmittal(submittalFile);

  const handleClear = () => {
    setSelectedSubmittal(null);
    setLoaderActive(null);
    afterSave && afterSave();
  };

  const {
    handleUpload,
    handleSave,
    handleApprove,
    handleDelete,
  } = uploadFileActions({
    selectedSpecRequirements,
    actions,
    spec,
    onUpdateSpec,
    specRequirements,
    newSubmittalFilesRef,
    uploadCount,
    setSubmittalFiles,
    submittalFiles,
    setLoaderActive,
    handleClear,
  });

  const allSubmittalFiles = selectedSpecRequirements.reduce(
    (arr, { submittalFiles = [] }) => [...arr, ...submittalFiles],
    []
  );

  const referenceSubmittalFiles = submittalFiles.map(
    ({ file: { s3Key }, uploadCount }) => ({
      ...allSubmittalFiles.find(
        submittalFile => s3Key == submittalFile.file.s3Key
      ),
      uploadCount,
    })
  );
  const referenceSpecRequirement = specRequirements.find(
    specRequirement =>
      specRequirement.id == referenceSubmittalFiles.at(0)?.specRequirementId
  );

  if (selectedSubmittal) {
    return (
      <EditSubmittal
        submittal={selectedSubmittal}
        specRequirement={referenceSpecRequirement}
        onApprove={handleApprove(selectedSubmittal)}
        onSubmit={handleSave}
        onCancel={handleClear}
        dataComponentId={dataComponentId}
        disableSelectFile
        loaderActive={loaderActive}
      />
    );
  }

  return (
    <Wrapper>
      <LoaderContext.Provider value={{ loading: loaderActive }}>
        <Grid
          gridId={"bulk-submittal-files"}
          specRequirement={{
            ...referenceSpecRequirement,
            submittalFiles: referenceSubmittalFiles,
          }}
          onDelete={handleDelete}
          onSelect={handleSelectSubmittal}
          loaderActive={loaderActive}
        />
      </LoaderContext.Provider>
      <DragDropDashboard onChange={handleUpload} height="100%" />
    </Wrapper>
  );
};

UploadFiles.propTypes = {
  spec: propTypes.spec,
  selectedSpecRequirementIds: PropTypes.array.isRequired,
  onUpdateSpec: PropTypes.func.isRequired,
  specRequirements: PropTypes.array,
  afterSave: PropTypes.func,
};

export default UploadFiles;
