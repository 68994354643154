import React, { Fragment, useCallback } from "react";
import { AddCircle } from "@material-ui/icons";
import PropTypes from "prop-types";

import { Button } from "../../../layout/SectionHeader";

export const UploadButton = ({ loading, openModalDialog }) => {
  const handleClick = useCallback(
    evt => {
      if (loading) return;
      evt.stopPropagation();
      openModalDialog(
        "Import Shipping Payment",
        "ImportShippingPayments",
        {},
        false,
        true,
        {
          width: "790px",
        }
      );
    },
    [loading, openModalDialog]
  );

  return (
    <Fragment>
      <Button
        text={"IMPORT SHIPPING PAYMENTS"}
        onClick={handleClick}
        icon={<AddCircle />}
        loading={loading}
      />
    </Fragment>
  );
};

UploadButton.propTypes = {
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func,
};
