import { inputs } from "../../../inputs/inputConfigs";

export default values => {
  const fields = {
    groups: [
      {
        items: [
          { input: inputs.projectFundingType, grid: { xs: 4 } },
          { input: inputs.projectFundingDue, grid: { xs: 4 } },
        ],
      },
    ],
  };

  if (values.fundingDue && values.fundingDue !== "As Needed") {
    fields.groups[0].items.push({
      input:
        values.fundingDue === "Monthly"
          ? inputs.projectFundingDueDate
          : inputs.fundingDueWeekday,
      grid: { xs: 4 },
    });
  }
  return fields;
};
