export const OPEN_ERROR_DIALOG = "OPEN_ERROR_DIALOG";
export const CLOSE_ERROR_DIALOG = "CLOSE_ERROR_DIALOG";

export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_UPDATE = "AUTHENTICATION_UPDATE";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const SEND_PASSWORD_RESET_EMAIL_REQUEST =
  "SEND_PASSWORD_RESET_EMAIL_REQUEST";
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  "SEND_PASSWORD_RESET_EMAIL_SUCCESS";
export const SEND_PASSWORD_RESET_EMAIL_FAILURE =
  "SEND_PASSWORD_RESET_EMAIL_FAILURE";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const SEND_VERIFICATION_CODE_FAILURE = "SEND_VERIFICATION_CODE_FAILURE";
export const VALIDATE_VERIFICATION_CODE = "VALIDATE_VERIFICATION_CODE";
export const VALIDATE_VERIFICATION_CODE_FAILURE =
  "VALIDATE_VERIFICATION_CODE_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CLEANUP_STATE = "CLEANUP_STATE";

export const LAYOUT_SET_AUTOSAVE_COMPONENT = "LAYOUT_SET_AUTOSAVE_COMPONENT";
export const LAYOUT_SET_AUTOSAVE_QUEUE = "LAYOUT_SET_AUTOSAVE_QUEUE";
export const LAYOUT_SHOW_LOADER = "LAYOUT_SHOW_LOADER";
export const LAYOUT_OPEN_NAVBAR_DRAWER = "LAYOUT_OPEN_NAVBAR_DRAWER";
export const LAYOUT_SET_HEADER_TITLE = "LAYOUT_SET_HEADER_TITLE";
export const LAYOUT_UNSET_HEADER_TITLE = "LAYOUT_UNSET_HEADER_TITLE";
export const LAYOUT_CLOSE_NAVBAR_DRAWER = "LAYOUT_CLOSE_NAVBAR_DRAWER";
export const LAYOUT_OPEN_MODAL_DIALOG = "LAYOUT_OPEN_MODAL_DIALOG";
export const LAYOUT_CLOSE_MODAL_DIALOG = "LAYOUT_CLOSE_MODAL_DIALOG";
export const SET_MODAL_DIALOG_TITLE = "SET_MODAL_DIALOG_TITLE";
export const LAYOUT_CHANGE_MODAL_DIALOG_ELEMENT =
  "LAYOUT_CHANGE_MODAL_DIALOG_ELEMENT";
export const LAYOUT_SHOW_SNACK_NOTIFICATION = "LAYOUT_SHOW_SNACK_NOTIFICATION";
export const LAYOUT_ACTION_REQUIRED_NOTIFICATION =
  "LAYOUT_ACTION_REQUIRED_NOTIFICATION";
export const LAYOUT_DISMISS_SNACK_NOTIFICATION =
  "LAYOUT_DISMISS_SNACK_NOTIFICATION";

export const DATA_COMPONENT_INIT = "DATA_COMPONENT_INIT";
export const DATA_COMPONENT_CLONE = "DATA_COMPONENT_CLONE";
export const DATA_COMPONENT_CLONE_SET_REQUEST_STATE =
  "DATA_COMPONENT_CLONE_SET_REQUEST_STATE";
export const DATA_COMPONENT_CLONE_OVERWRITE_PROP =
  "DATA_COMPONENT_CLONE_OVERWRITE_PROP";
export const DATA_COMPONENT_DESTROY = "DATA_COMPONENT_DESTROY";
export const DATA_COMPONENT_SET_PAGE = "DATA_COMPONENT_SET_PAGE";
export const DATA_COMPONENT_SET_RELOAD = "DATA_COMPONENT_SET_RELOAD";
export const DATA_COMPONENT_SET_PAGE_SIZE = "DATA_COMPONENT_SET_PAGE_SIZE";
export const DATA_COMPONENT_SET_SORT = "DATA_COMPONENT_SET_SORT";
export const DATA_COMPONENT_SET_SELECTION = "DATA_COMPONENT_SET_SELECTION";
export const DATA_COMPONENT_SET_SELECTION_V2 =
  "DATA_COMPONENT_SET_SELECTION_V2";
export const DATA_COMPONENT_REORDER = "DATA_COMPONENT_REORDER";
export const DATA_COMPONENT_SET_API_FILTERS = "DATA_COMPONENT_SET_API_FILTERS";
export const DATA_COMPONENT_REQUEST = "DATA_COMPONENT_REQUEST";
export const DATA_COMPONENT_SUCCESS = "DATA_COMPONENT_SUCCESS";
export const DATA_COMPONENT_FAILURE = "DATA_COMPONENT_FAILURE";

export const DATA_COMPONENT_RESOURCE_CREATE = "DATA_COMPONENT_RESOURCE_CREATE";
export const DATA_COMPONENT_RESOURCE_UPSERT_LIST_IF_EXISTS =
  "DATA_COMPONENT_RESOURCE_UPSERT_LIST_IF_EXISTS";
export const DATA_COMPONENT_RESOURCE_UPDATE_IF_EXISTS =
  "DATA_COMPONENT_RESOURCE_UPDATE_IF_EXISTS";
export const DATA_COMPONENT_RESOURCE_DESTROY =
  "DATA_COMPONENT_RESOURCE_DESTROY";

export const DATA_COMPONENT_RESOURCE_DESTROY_LIST_IF_EXIST =
  "DATA_COMPONENT_RESOURCE_DESTROY_LIST_IF_EXIST";

export const FETCH_ENTITY_LIST_REQUEST = "FETCH_ENTITY_LIST_REQUEST";
export const FETCH_ENTITY_LIST_SUCCESS = "FETCH_ENTITY_LIST_SUCCESS";
export const FETCH_ENTITY_LIST_FAILURE = "FETCH_ENTITY_LIST_FAILURE";

export const FETCH_ENTITY_INSTANCE_REQUEST = "FETCH_ENTITY_INSTANCE_REQUEST";
export const FETCH_ENTITY_INSTANCE_SUCCESS = "FETCH_ENTITY_INSTANCE_SUCCESS";
export const FETCH_ENTITY_INSTANCE_FAILURE = "FETCH_ENTITY_INSTANCE_FAILURE";

export const SPEC_DETAIL_REQUEST = "SPEC_DETAIL_REQUEST";
export const SPEC_DETAIL_SUCCESS = "SPEC_DETAIL_SUCCESS";
export const SPEC_DETAIL_FAILURE = "SPEC_DETAIL_FAILURE";

export const NOTE_INIT = "NOTE_INIT";
export const NOTE_CLEAR = "NOTE_CLEAR";
export const NOTE_SET_FILTERS = "NOTE_SET_FILTERS";
export const NOTE_CREATE_START = "NOTE_CREATE_START";
export const NOTE_CREATE_DETAIL_CLOSE = "NOTE_CREATE_DETAIL_CLOSE";
export const NOTE_DETAIL_OPEN = "NOTE_DETAIL_OPEN";
export const NOTE_FETCH_RELATIONS_REQUEST = "NOTE_FETCH_RELATIONS_REQUEST";
export const NOTE_FETCH_RELATIONS_SUCCESS = "NOTE_FETCH_RELATIONS_SUCCESS";

export const GENERATE_REPORT_REQUEST = "GENERATE_REPORT_REQUEST";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAILURE = "GENERATE_REPORT_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const DOWNLOAD_QUOTE_FILES_REQUEST = "DOWNLOAD_QUOTE_FILES_REQUEST";
export const DOWNLOAD_QUOTE_FILES_SUCCESS = "DOWNLOAD_QUOTE_FILES_SUCCESS";
export const DOWNLOAD_QUOTE_FILES_FAILURE = "DOWNLOAD_QUOTE_FILES_FAILURE";

export const UPDATE_NOTE_LAST_VISITED = "UPDATE_NOTE_LAST_VISITED";

export const CREATE_QUEUE_REQUEST = "QUEUE_REQUEST";
export const CREATE_QUEUE_SUCCESS = "QUEUE_SUCCESS";
export const CREATE_QUEUE_FAILURE = "QUEUE_FAILURE";

export const VALIDATE_QUEUE_REQUEST = "VALIDATE_QUEUE_REQUEST";
export const VALIDATE_QUEUE_SUCCESS = "VALIDATE_QUEUE_SUCCESS";
export const VALIDATE_QUEUE_FAILURE = "VALIDATE_QUEUE_FAILURE";

export const JOB_QUEUE_COMPLETED = "JOB_QUEUE_COMPLETED";
export const INIT_QUEUE_JOB = "INIT_QUEUE_JOB";

export const SET_DELETED_BID_GROUP = "SET_DELETED_BID_GROUP";
export const UNDO_DELETED_BID_GROUP = "UNDO_DELETED_BID_GROUP";
export const RESET_DELETED_BID_GROUPS = "RESET_DELETED_BID_GROUPS";

export const SET_SCOPED_CURRENCY = "SET_SCOPED_CURRENCY";
export const RESET_SCOPED_CURRENCY = "RESET_SCOPED_CURRENCY";

export const SET_COLLAPSIBLE_ITEM_STATUS = "SET_COLLAPSIBLE_ITEM_STATUS";

export const SET_EDITABLE_ROW = "SET_EDITABLE_ROW";

export const STATUS_REQUEST = "STATUS_REQUEST";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAILURE = "STATUS_FAILURE";

export const SET_SCOPE = "SET_SCOPE";
export const RESET_SCOPE = "RESET_SCOPE";
