import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

const reducerMap = {
  [actionTypes.UPLOAD_FILE_REQUEST]: (state, { key }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [key]: true,
    },
  }),
  [actionTypes.UPLOAD_FILE_SUCCESS]: (state, { key }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [key]: false,
    },
  }),
  [actionTypes.UPLOAD_FILE_FAILURE]: (state, { key }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [key]: false,
    },
  }),
  [actionTypes.DOWNLOAD_QUOTE_FILES_REQUEST]: (
    state,
    { bidGroupVendorId }
  ) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [bidGroupVendorId]: true,
    },
  }),
  [actionTypes.DOWNLOAD_QUOTE_FILES_SUCCESS]: (
    state,
    { bidGroupVendorId }
  ) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [bidGroupVendorId]: false,
    },
  }),
  [actionTypes.DOWNLOAD_QUOTE_FILES_FAILURE]: (
    state,
    { bidGroupVendorId }
  ) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [bidGroupVendorId]: false,
    },
  }),
  default: state => state,
};

export default function(state = initialState.fileUploads, action) {
  const { type, payload } = action;

  return (reducerMap[type] || reducerMap.default)(state, payload);
}
