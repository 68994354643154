import React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "formik";
import InputWrapper from "../../../inputs/InputWrapper";
import {
  getErrorMessage,
  hasError,
} from "../../../../utils/formValidationUtils";
import styled from "styled-components";
import PropTypes from "prop-types";

const FieldContainer = styled.div({});
const Row = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 0 24px;

  ${FieldContainer} {
    margin-left: 0;
    margin-bottom: 24px;
  }
`;

const CommentsRow = props => {
  const { input } = props;

  return (
    <Row>
      <Grid item xs={12}>
        <FieldContainer>
          <Field name={input.name} fullWidth={input.fullWidth}>
            {({ field, form }) => (
              <InputWrapper
                {...input}
                {...field}
                label={input.label}
                hasError={hasError(input.name, form)}
                errorMessage={getErrorMessage(input.name, form)}
                fullWidth
              />
            )}
          </Field>
        </FieldContainer>
      </Grid>
    </Row>
  );
};
CommentsRow.propTypes = {
  input: PropTypes.object.isRequired,
};
export default CommentsRow;
