import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Reorder from "@material-ui/icons/Reorder";

const StyledDragAndDrop = styled.div`
  position: relative;
  display: flex;
  top: 12px;
  &:first-child {
    margin-left: 24px;
  }
`;

const StyledIconButton = styled(({ isDeleted, ...props }) => (
  <div {...props} />
))`
  height: 24px;
  width: 24px;
  padding: 0;
  color: rgb(0, 0, 0, 0.54);
  ${({ isDeleted }) =>
    isDeleted &&
    `
  color: rgb(0, 0, 0, 0.3);
  cursor: not-allowed;
  `}
  ${({ isDeleted }) =>
    !isDeleted &&
    `
  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
  }
  `}
`;

const DragAndDrop = ({ className, ...dragHandleProps }) => {
  return (
    <StyledDragAndDrop className={className}>
      <StyledIconButton {...dragHandleProps}>
        <Reorder />
      </StyledIconButton>
    </StyledDragAndDrop>
  );
};

DragAndDrop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
};

export default styled(DragAndDrop)({});
