import React, { useCallback } from "react";
import { useParams } from "react-router";
import POInvoicesList from "./POInvoicesList";
import useActions from "../../../hooks/useActions";
import { initDataComponent } from "../../../../actions/dataComponentActions";
import { useEffect } from "react";
import Invoice from "../../../../models/Invoice";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { useSelector } from "react-redux";
import propTypes from "../../../../constants/propTypes";

export const dataComponentId = "POInvoicesGrid";

const POInvoicesContainer = ({ purchaseOrder, projectCurrency }) => {
  const { projectId, clientId, poId } = useParams();

  const actions = useActions({
    initDataComponent,
    pushWithReturnUrl,
    generateAndDownloadReport,
  });

  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      Invoice,
      [],
      "invoices",
      true,
      "v2"
    );
  }, [actions]);

  const { flattenedDataComponent } = useSelector(state => ({
    flattenedDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  }));

  const handleRowClick = useCallback(
    ({ id }) => {
      actions.pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/invoices/${id}`,
        "PURCHASE ORDER"
      );
    },
    [actions, clientId, projectId]
  );

  const handleDownloadCheckRequestLog = useCallback(
    ({ id: invoiceId }, showPreview) => {
      actions.generateAndDownloadReport({
        format: "pdf",
        invoiceId,
        projectId,
        templateName: "invoice-check-request-log",
        showPreview,
      });
    },
    [actions, projectId]
  );

  return (
    <POInvoicesList
      projectCurrency={projectCurrency}
      onDownloadCheckRequestLog={handleDownloadCheckRequestLog}
      onRowClick={handleRowClick}
      dataComponent={flattenedDataComponent}
      poId={poId}
      invoicesCount={purchaseOrder.invoicesCount}
    />
  );
};

POInvoicesContainer.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  projectCurrency: propTypes.projectCurrency.isRequired,
};

export default POInvoicesContainer;
