import React from "react";

import useVendorScopeStore from "../../hooks/useVendorScopeStore";
import VendorContactsContainer from "./VendorContactsContainer";
import { Content } from "../../layout/Content";

const VendorPortalEntrypoint = () => {
  const selectedVendor = useVendorScopeStore(state => state.selectedVendor);

  if (!selectedVendor) return null;

  return (
    <Content>
      <VendorContactsContainer
        vendor={selectedVendor}
        ignoreAddressBook
        match={{ params: { vendorId: selectedVendor.id } }}
      />
    </Content>
  );
};

export default VendorPortalEntrypoint;
