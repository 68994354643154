import { useMemo } from "react";
import { designerPurchaseOrderDataComponentId } from "./DesignerConfirmationModal";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import { usePerformRetrieveList } from "../../../../hooks/usePerformRetrieveList";

export const useListPurchaseOrders = (projectId, originalDesignerId) => {
  const dataComponent = useMemo(
    () => ({
      dataComponentId: designerPurchaseOrderDataComponentId,
      model: PurchaseOrder,
      includes: [],
      apiRoute: "purchase-orders",
    }),
    []
  );
  const filters = useMemo(
    () => ({
      sort: [{ columnName: "sequenceIndex", direction: "asc" }],
      rootFilters: {
        $where: {
          designerId: originalDesignerId,
          projectId,
        },
      },
      pageSize: -1,
    }),
    [originalDesignerId, projectId]
  );

  return usePerformRetrieveList(dataComponent, filters);
};
