import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "formik";
import TextField from "../../mui/core/TextField";
import {
  ProgramTagContainer,
  ProgramTagError,
  ProgramTag,
  ProgramTagHeader,
  ProgramTagSection,
  SelectedCounter,
} from "./components";

export const ProgramField = styled(TextField)`
  & .input {
    height: initial;
  }
`;

const handleAddNewProgram = (
  formik,
  inputValue,
  name,
  value,
  setValue,
  setInputValue
) => event => {
  event.stopPropagation();
  if (event.key !== "Enter") return;
  if (inputValue.trim() === "") return;

  const existingProgram = value.find(
    program => program.name === inputValue.trim()
  );

  if (existingProgram) {
    formik.setErrors({
      programs: `Program ${inputValue} already exists`,
    });
    return;
  }

  event.preventDefault();
  const programs = [...value, { name: inputValue }];
  setValue(programs);
  formik.setFieldValue(name, programs);
  setInputValue("");
};

export const ProgramInput = ({
  errorMessage,
  formik,
  name,
  input,
  ignoreCaption,
  onChange,
  onBlur,
  value: initialValue,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState("");
  const addNewProgram = useCallback(
    handleAddNewProgram(
      formik,
      inputValue,
      name,
      value,
      setValue,
      setInputValue
    ),
    [formik, inputValue, name, value]
  );

  const deleteProgram = useCallback(
    selectedKey => () => {
      const programs = value.filter((_, key) => selectedKey !== key);
      setValue(programs);
      formik.setFieldValue(name, programs);
    },
    [formik, name, value]
  );

  return (
    <div>
      <ProgramField
        {...props}
        value={inputValue}
        onChange={({ target: { value } }) => setInputValue(value)}
        onKeyDown={addNewProgram}
      />
      <ProgramTagContainer>
        <ProgramTagHeader>Hit &quot;Enter&quot; to add</ProgramTagHeader>
        <SelectedCounter>
          <span>{value.length} Programs Created</span>
          {errorMessage ? (
            <ProgramTagError>{errorMessage}</ProgramTagError>
          ) : null}
        </SelectedCounter>
        <ProgramTagSection>
          {value.map((program, key) => (
            <ProgramTag
              label={program.name}
              key={key}
              onDelete={deleteProgram(key)}
            />
          ))}
        </ProgramTagSection>
      </ProgramTagContainer>
    </div>
  );
};

ProgramInput.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
};

ProgramInput.defaultProps = {
  value: [],
};

export default connect(ProgramInput);
