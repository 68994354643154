import { connect } from "react-redux";

import ProjectDetailContainer from "./ProjectDetailContainer";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../../actions/dataComponentActions";
import { setHeaderTitle } from "../../../../../actions/layoutActions";

export const dataComponentId = "ProjectsDetailsDataComponentId";
export const summaryDataComponentId = "ProjectSummaryDataComponentId";

export const mapStateToProps = (state, ownProps) => {
  const { userId, role } = state.auth;
  const projectId = ownProps.match.params.projectId;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const summaryDataComponent = getDataComponentFlattenedRequestState(
    summaryDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  return {
    projectId,
    dataComponent,
    project:
      getBluechipResourceById(dataComponent, state, projectId) || undefined,
    projectSummary: getBluechipResourceById(
      summaryDataComponent,
      state,
      projectId
    ),
    role,
    currentUserId: userId,
    loading: dataComponent.loading,
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  setHeaderTitle,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailContainer);
