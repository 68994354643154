import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import invoiceFields from "./invoiceFields";
import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import { dataComponentId } from "../InvoicesContainer";

const initialValues = {
  number: null,
  remitAddress: {
    name: undefined,
    location: {
      address: undefined,
      address2: undefined,
      city: undefined,
      zip: undefined,
      country: undefined,
      state: undefined,
    },
  },
};

export const getSchema = () => {
  const Joi = BWModels.Joi;
  return Joi.object().keys({
    purchaseOrderId: Joi.fkReq(),
  });
};

export const buildHandler = (
  isAddingRemitAddress,
  setAddingNewRA
) => newStatus => {
  if (newStatus === isAddingRemitAddress) return;
  setAddingNewRA(newStatus);
};

const InvoiceForm = ({ onSubmit, setPurchaseOrderId }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getSchema()}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <FormGrid fields={invoiceFields(setPurchaseOrderId)} />
          <ActionButtons
            listeners={[dataComponentId]}
            sendButtonText="ADD INVOICE"
            onSend={handleSubmit}
            isModal={true}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

InvoiceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setPurchaseOrderId: PropTypes.func,
  isUpdate: PropTypes.bool,
};

export default InvoiceForm;
