import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";

const Option = styled.div`
  min-width: 175px;
`;

export const BulkActions = ({ handleExport }) => {
  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: <Option>Export</Option>,
          onClick: handleExport,
        },
      ]}
    />
  );
};

BulkActions.propTypes = {
  handleExport: PropTypes.func.isRequired,
};

export default BulkActions;
