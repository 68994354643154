import create from "zustand";
import { devtools } from "zustand/middleware";

const openManageShipmentsModal = (
  openModalDialog,
  spec,
  shipmentsDataComponentId
) => {
  const { customNumber, description } = spec;
  openModalDialog(
    ["Manage Shipments", `#${customNumber} ${description}`],
    "ManageShipmentsModal",
    {
      spec,
      shipmentsDataComponentId,
    },
    false,
    true,
    {
      width: "800px",
    }
  );
};

const editShipment = (get, set) => (index, shipment) => {
  const { shipments } = get();
  const newShipments = [...shipments];
  newShipments[index] = { ...newShipments[index], ...shipment };
  set({ shipments: newShipments });
};

const initialState = {
  shipments: null,
  spec: null,
};

const useShipmentStore = create(
  devtools(
    (set, get) => ({
      ...initialState,
      actions: {
        isFromManageShipments: () => get().shipments != null,
        setShipments: shipments => set({ shipments }),
        addShipment: shipment =>
          set({ shipments: [...get().shipments, shipment] }),
        editShipment: editShipment(get, set),
        getShipments: () => get().shipments,
        setSpec: spec => set({ spec }),
        getSpec: () => get().spec,
        reset: () => set({ ...initialState }),
        openManageShipmentsModal: (openModalDialog, shipmentsDataComponentId) =>
          openManageShipmentsModal(
            openModalDialog,
            get().spec,
            shipmentsDataComponentId
          ),
      },
    }),
    "ShipmentsStore"
  )
);

export default useShipmentStore;
