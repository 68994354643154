import React from "react";
import PropTypes from "prop-types";

import ActionButtons from "../../../forms/ActionButtons";
import { Title, Wrapper } from "./Dialog";

const SessionExpiredDialog = ({ onSend }) => {
  return (
    <Wrapper>
      <Title>Your session has timed out.</Title>
      <ActionButtons
        onSend={onSend}
        sendButtonText="REFRESH"
        hideCancelButton
      />
    </Wrapper>
  );
};

SessionExpiredDialog.propTypes = {
  onSend: PropTypes.func.isRequired,
  seconds: PropTypes.number,
};

export default SessionExpiredDialog;
