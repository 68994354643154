import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";

export const ProfilePictureWrapper = styled.div`
  padding: 24px 24px 0px 24px;
`;

export const Container = styled.div`
  .label {
    width: 650px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 12px 0px 16px 0px;
  color: rgba(0, 0, 0, 0.54);
`;

export const PictureWrapper = styled.div`
  height: 64px;
  width: 64px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #57abff;
  margin-right: 24px;
`;

export const Picture = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 24px;
  transform: scale(${({ zoom }) => zoom});
  transform-origin: ${({ pan }) => pan};
`;

export const EmptyState = styled.div`
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.2);
`;

export const EditLink = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  color: #57abff;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ResetPasswordLink = styled(EditLink)`
  justify-content: flex-end;
  flex-grow: 1;

  svg {
    margin-right: 8px;
  }
`;

export const EditIcon = styled(CreateIcon)`
  margin-right: 8px;
`;

export const PasswordTitle = styled.div`
  padding-top: 36px;
  padding-left: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TabContainer = styled.div`
  .label {
    width: 100%;
  }
`;
