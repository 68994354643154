import React, { Fragment } from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";

import { updateSpecField } from "./columnOptions";
import { Cell } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";
import { StyledRow } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/BWTableRow";
import InputTextArea from "../ImportSpecs/editableControls/InputTextArea";
import RowComponent from "../ImportSpecs/tableComponents/RowComponent";
import WrapperDetailRow from "../ImportSpecs/tableComponents/WrapperDetailRow";

const DetailCellComponent = ({ children }) => (
  <Fragment>
    <Cell column={{}} colSpan={7}>
      {children}
    </Cell>
    <Cell column={{}} colSpan={5}></Cell>
  </Fragment>
);

DetailCellComponent.propTypes = {
  children: PropTypes.object,
};

const tableComponents = memoizeOne(
  (setSpecs, specsFields, showErrorStyling) => {
    const components = {
      DetailRowComponent: detailRowComponent(setSpecs, specsFields),
      RowComponent: StyledRow,
      DetailCellComponent,
    };
    return showErrorStyling
      ? { ...components, WrapperDetailRow, RowComponent }
      : components;
  }
);

export const detailRowComponent = memoizeOne((setSpecs, specsFields) => props =>
  buildTextAreaColumn(
    "",
    specWithValue =>
      updateSpecField(specWithValue, "forecastComment", setSpecs, specsFields),
    "forecastComment"
  )(props.row)
);

const buildTextAreaColumn = (
  defaultValue,
  onChangeValue,
  fieldName,
  InputComponent
) => {
  return spec => {
    return (
      spec && (
        <InputTextArea
          ignoreCaption
          InputProps={{
            inputComponent: InputComponent,
            disableUnderline: true,
          }}
          value={spec[fieldName] || defaultValue}
          handleBlur={value => {
            return onChangeValue({ specId: spec.id, value: value });
          }}
        />
      )
    );
  };
};

export default tableComponents;
