import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  SLOW: "slow",
};

export const COLORS = {
  slow: "#daa520",
  error: "#FF1744",
  success: "#72B327",
  loading: "rgb(239,241,246)",
};

export const Circle = styled(({ loading, color, ...props }) => (
  <span {...props} />
))`
  line-height: 1;
  display: inline-block;
  overflow: hidden;
  position: relative;

  margin-right: 12px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: ${({ loading, color }) =>
    loading ? COLORS.loading : color};

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 500px;
    top: 0;
    left: -500px;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0)
    );
    animation: progress 1.2s ease-in-out infinite;
    display: ${({ loading }) => (loading ? "initial" : "none")};
  }
`;

export const StatusIndicator = ({ loading, status = "loading", service }) => {
  return (
    <Fragment>
      <Circle loading={loading} color={COLORS[status]} />
      {service}
    </Fragment>
  );
};

StatusIndicator.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string,
  service: PropTypes.string,
};
