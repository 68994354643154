import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircle from "@material-ui/icons/CheckCircle";

export const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: "tooltip" }}
    {...props}
  />
))`
  & .tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    background-color: #424242;
    font-weight: bold;
    color: #ffffff;
    height: 32px;
  }
`;

export const PACheckCircle = styled(({ isActive, ...props }) => (
  <CheckCircle {...props} />
))`
  display: flex;
  color: ${props => (props.isActive ? "#72B327" : "rgba(0,0,0,0.24)")};
`;
