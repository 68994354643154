import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import * as RequestTypes from "../../constants/RequestTypes";
import { useListProcessRequests } from "../hooks/useProcessRequest";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../reducers/dataComponentReducer";
import { getResourcesV2 } from "../../utils/bluechipUtils";
import propTypes from "../../constants/propTypes";
import { BWActionButton } from "./BWActionButton";

const hasMore = ({ totalPages, pageNumber }) =>
  totalPages != 0 && totalPages > pageNumber + 1;

const buildHandleLoadNext = ({
  loadNext,
  nextPageNumberRef,
  rows,
  loading,
}) => () => {
  if (loading) {
    return;
  }
  if (nextPageNumberRef.current === 0 && !isEmpty(rows)) {
    nextPageNumberRef.current++;
  }
  loadNext(nextPageNumberRef.current);
  nextPageNumberRef.current++;
};

export const BWInfiniteScroll = ({
  mapRowToComponent,
  loadedRows,
  pageNumber,
  totalPages,
  dataComponent,
  loadNext,
  loading,
  scrollThreshold,
}) => {
  const [rows, setRows] = useState([]);
  const nextPageNumberRef = useRef(0);

  useEffect(() => {
    if (pageNumber === 0) {
      setRows([]);
      nextPageNumberRef.current = 0;
    }
  }, [pageNumber, loadedRows]);

  useListProcessRequests(dataComponent, {
    onSuccess: () => setRows(rows.concat(loadedRows)),
    onError: () => nextPageNumberRef.current--,
  });

  const handleLoadNext = buildHandleLoadNext({
    loadNext,
    nextPageNumberRef,
    rows,
    loading,
  });

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={handleLoadNext}
      hasMore={hasMore({ totalPages, pageNumber })}
      scrollThreshold={scrollThreshold}
      loader={
        <div style={{ textAlign: "center" }}>
          <BWActionButton
            onClick={handleLoadNext}
            disabled={loading}
            loading={loading}
            text={loading ? "Loading More..." : "Load More"}
          >
            {loading ? "Loading More..." : "Load More"}
          </BWActionButton>
        </div>
      }
      endMessage={
        <p style={{ textAlign: "center", marginTop: "24px" }}>
          All items were loaded
        </p>
      }
    >
      {rows.map(mapRowToComponent)}
    </InfiniteScroll>
  );
};

BWInfiniteScroll.propTypes = {
  mapRowToComponent: PropTypes.func.isRequired,
  loadedRows: PropTypes.array,
  pageNumber: PropTypes.number,
  totalPages: PropTypes.number,
  dataComponent: propTypes.dataComponent,
  loadNext: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  scrollThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

BWInfiniteScroll.pageSize = Number(process.env.INFINITE_SCROLL_PAGE_SIZE) || 20;

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const loadedRows = getResourcesV2(
    dataComponent,
    state,
    RequestTypes.LIST,
    []
  );
  const { pageNumber, totalPages } = dataComponent.requestState.list || {};

  return {
    dataComponent,
    loadedRows,
    pageNumber,
    totalPages,
    loading: getDataComponentFlattenedRequestState(
      dataComponentId,
      state,
      RequestTypes.LIST
    ).loading,
  };
};

export default connect(mapStateToProps, null)(BWInfiniteScroll);
