import styled from "styled-components";

const NavigationBarTab = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    font-size: 24px;
  }
  > * {
    display: flex;
  }
`;

NavigationBarTab.displayName = "NavigationBarTab";

export default NavigationBarTab;
