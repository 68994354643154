import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

export const ImportOption = styled(Wrapper)`
  color: #57abff;
  padding: 25px;
  font-size: 14px;
  flex-direction: row;
  font-weight: 600;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Separation = styled(Wrapper)`
  background: rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 1px;
`;

export const StyledAddCircle = styled(AddCircle)`
  margin-right: 8px;
`;
