import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SerifTitle from "./SerifTitle";

export const Title = styled(SerifTitle)`
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Secondary = styled(Title)`
  margin: 0 0 0 16px;
  color: rgba(0, 0, 0, 0.38);
`;

const SectionTitle = ({ children, secondary }) => (
  <Fragment>
    <Title>{children}</Title>
    {secondary && <Secondary>{secondary}</Secondary>}
  </Fragment>
);

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  secondary: PropTypes.string,
};

export default SectionTitle;
