import React, { Fragment, useCallback, useMemo } from "react";
import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";
import _isEmpty from "lodash/isEmpty";
import { Divider } from "@material-ui/core";

import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import { inputs } from "../../../../inputs/inputConfigs";
import { GroupLabel } from "../../../../inputs/ProgramInput/components";
import { useWithSelectedRowContext } from "../../../../ui/BWGrid/withSelectedRows";

const getFields = (categories, areas, vendors, projectCurrencies) => ({
  groups: [
    {
      items: [
        {
          element: <GroupLabel>Update Selected Specs</GroupLabel>,
          grid: { xs: 12 },
        },
        {
          element: <Divider />,
          grid: { xs: 12 },
        },
        {
          input: {
            ..._omit(inputs.specCategory, "APIOptions"),
            options: categories,
            required: false,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.area, "APIOptions"),
            options: areas,
            required: false,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.vendorAutocomplete, "APIOptions"),
            options: vendors,
            required: false,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ..._omit(inputs.projectCurrency, "APIOptions"),
            options: projectCurrencies,
            required: false,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export const UpdateSpecs = ({
  rows,
  setRows,
  setDisplayUpdateSpecs,
  categories,
  areas,
  vendors,
  projectCurrencies,
}) => {
  const { selectedIds } = useWithSelectedRowContext();

  const fields = useMemo(
    () => getFields(categories, areas, vendors, projectCurrencies),
    [areas, categories, projectCurrencies, vendors]
  );

  const handleCancel = useCallback(() => {
    setDisplayUpdateSpecs(false);
  }, [setDisplayUpdateSpecs]);

  const handleSubmit = useCallback(
    values => {
      setRows(
        rows.map(row =>
          selectedIds.includes(row.id)
            ? { ...row, ..._omitBy(values, _isEmpty) }
            : row
        )
      );
      handleCancel();
    },
    [handleCancel, rows, selectedIds, setRows]
  );

  return (
    <FormikForm
      initialValues={{
        specCategoryId: null,
        areaId: null,
        vendorId: null,
        projectCurrencyId: null,
      }}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Fragment>
          <FormGrid {...formikProps} fields={fields} />
          <ActionButtons
            onSend={formikProps.handleSubmit}
            onCancel={handleCancel}
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};
