import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DiscountsPage from "./DiscountsPage";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import Vendor from "../../../../models/Vendor";

const dataComponentId = "DiscountsGrid";
const vendorDataComponentId = "VendorDetail";

const DiscountsContainer = ({
  vendorId,
  vendor,
  dataComponent,
  initDataComponent,
  performFindRequest,
  openModalDialog,
}) => {
  useEffect(() => {
    initDataComponent(
      vendorDataComponentId,
      Vendor,
      ["parent", "category", "location"],
      "vendors"
    );
  }, [initDataComponent]);
  useEffect(() => {
    performFindRequest(vendorDataComponentId, vendorId);
  }, [performFindRequest, vendorId]);
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Add Discount",
      "CreateDiscountType",
      {
        vendorId,
      },
      false,
      true,
      {
        layoutType: "default",
        scrollDisabled: true,
        subtitle: `${vendor.name}`,
      }
    );
  }, [openModalDialog, vendor, vendorId]);

  const handleOpenEditModal = useCallback(
    discount => {
      openModalDialog(
        `Edit Discount`,
        "EditDiscount",
        {
          discount,
        },
        false,
        false,
        {
          scrollDisabled: true,
        }
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    row => {
      openModalDialog(
        `Delete Discount`,
        "DeleteDiscount",
        {
          discountId: row.id,
          title: `the ${row.discountPercent}% discount from the ${row.programNameWithDiscountType} program`,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <DiscountsPage
      count={dataComponent.totalRows}
      vendorId={vendorId}
      dataComponent={dataComponent}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
    />
  );
};

DiscountsContainer.propTypes = {
  vendorId: PropTypes.string,
  vendor: propTypes.vendor,
  dataComponent: propTypes.dataComponent,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func,
  openModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const vendorId = ownProps.match.params.vendorId;
  const vendorDataComponent = getDataComponent(vendorDataComponentId, state);

  const vendor = getBluechipResourceById(vendorDataComponent, state, vendorId);
  return {
    vendorId,
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    vendor: vendor,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  openModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsContainer);
