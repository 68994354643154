import PropTypes from "prop-types";
import React from "react";

import propTypes from "../../../../constants/propTypes";
import Loader from "../../../ui/Loader";
import FilePreview from "./FilePreview";

const COMPreview = ({
  specDetails = [],
  height = "60px",
  width = "60px",
  editable = false,
  handleUpdatePreview,
}) => {
  const preview = specDetails.find(detail => detail.type === "Preview");

  return (
    <Loader height={height} width={width} inverted={true}>
      {preview ? (
        <FilePreview
          preview={preview}
          height={height}
          width={width}
          editable={editable}
          handleUpdatePreview={previewFile =>
            handleUpdatePreview(preview.id, previewFile)
          }
        />
      ) : null}
    </Loader>
  );
};

COMPreview.propTypes = {
  specDetails: PropTypes.arrayOf(propTypes.specDetail),
  height: PropTypes.string,
  width: PropTypes.string,
  editable: PropTypes.bool,
  handleUpdatePreview: PropTypes.func,
};

export default COMPreview;
