import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import { haveActiveRequests } from "../../../../utils/dataComponentUtils";
import {
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { saveSubmittal } from "../../../../actions/submittalActions";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import {
  dataComponentId,
  specsDataComponentId,
  submittalsDataComponentId,
} from "./RequirementsGridContainer";

export const mapStateToProps = (state, { specId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const submittalsDatacomponent = getDataComponent(
    submittalsDataComponentId,
    state
  );
  const specsDataComponent = getDataComponentFlattenedRequestState(
    specsDataComponentId,
    state
  );
  const spec = getBluechipResourceById(specsDataComponent, state, specId);
  const loading = haveActiveRequests([
    dataComponent,
    submittalsDatacomponent,
    getDataComponent(specsDataComponentId, state),
  ]);
  return {
    dataComponent,
    submittalsDatacomponent,
    loading,
    projectId: spec.projectId,
    purchaseOrderId: spec.purchaseOrderId,
    requirements: spec.specRequirements,
    spec,
  };
};

export const mapDispatchToProps = {
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  setReload,
  saveSubmittal,
  showSnackNotificationAction,
};
