import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import SpecsList from "./SpecsList";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  fetchDataRequest,
  fetchDataFailed,
  fetchDataSuccess,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import InvoiceSpec from "../../../../models/InvoiceSpec";
import { LoaderContext } from "../../../ui/Loader";

export const dataComponentId = "SpecInvoicesGrid";
export const parentDataComponentId = "InvoiceDetail";

const SpecsContainer = ({
  updateState,
  fetchDataRequest,
  fetchDataFailed,
  fetchDataSuccess,
  initDataComponent,
  readOnly,
  specs,
  projectCurrency,
  openInvoiceDataInput,
}) => {
  const { loading: isLoading } = useContext(LoaderContext);

  const { loading, success, error } = updateState;

  useEffect(() => {
    if (loading) {
      fetchDataRequest(dataComponentId, REQUEST_TYPES.FIND);
    }
  }, [fetchDataRequest, loading]);

  useEffect(() => {
    if (success) {
      fetchDataSuccess(dataComponentId, REQUEST_TYPES.FIND, {});
    }
  }, [fetchDataSuccess, success]);

  useEffect(() => {
    if (error) {
      fetchDataFailed(dataComponentId, REQUEST_TYPES.FIND, error);
    }
  }, [fetchDataFailed, error]);

  useEffect(() => {
    initDataComponent(dataComponentId, InvoiceSpec, [], "invoice-specs");
  }, [initDataComponent]);

  return (
    <SpecsList
      specs={specs}
      projectCurrency={projectCurrency}
      readOnly={readOnly}
      loading={loading || isLoading}
      openInvoiceDataInput={openInvoiceDataInput}
    />
  );
};

SpecsContainer.propTypes = {
  updateState: propTypes.dataComponent,
  fetchDataRequest: PropTypes.func.isRequired,
  fetchDataFailed: PropTypes.func.isRequired,
  fetchDataSuccess: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  specs: PropTypes.array,
  projectCurrency: propTypes.projectCurrency,
  openInvoiceDataInput: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  return {
    updateState: getDataComponentFlattenedRequestState(
      parentDataComponentId,
      state,
      REQUEST_TYPES.FIND
    ),
  };
};

export const mapDispatchToProps = {
  fetchDataSuccess,
  fetchDataFailed,
  fetchDataRequest,
  initDataComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecsContainer);
