import React from "react";

import VendorInfo from "./VendorInfo";
import propTypes from "../../../../../constants/propTypes";

const VendorInfoContainer = ({ invoice }) =>
  invoice ? <VendorInfo invoice={invoice} /> : null;

VendorInfoContainer.propTypes = {
  invoice: propTypes.invoice,
};

export default VendorInfoContainer;
