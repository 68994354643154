import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { openModalDialog } from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import propTypes from "../../../../../constants/propTypes";

import FlagsTable from "./FlagsTable";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";

export const dataComponentId = "flagsGrid";
export const FlagsTableContainer = ({
  openModalDialog,
  dataComponent,
  brandId,
}) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create Flag", "CreateFlag", { brandId }, false);
  }, [brandId, openModalDialog]);

  const handleOpenEditModal = useCallback(
    flag => {
      openModalDialog(
        `Edit ${flag.name}`,
        "EditFlag",
        {
          flag,
          brandId: flag.brandId,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, name, brandId }) => {
      openModalDialog(
        `Delete ${name}`,
        "DeleteFlag",
        {
          brandId,
          flagId: id,
          title: name,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <FlagsTable
      brandId={brandId}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      dataComponent={dataComponent}
    />
  );
};

FlagsTableContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  brandId: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, { brandId }) => ({
  dataComponent: getDataComponentFlattenedRequestState(
    `${dataComponentId}-${brandId}`,
    state
  ),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  performRetrieveListRequest,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlagsTableContainer);
