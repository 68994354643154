import { statusFilter } from "./Grid";

export const getColumns = ({ getRowMenu, statuses, workScopes }) => [
  {
    name: "isFavorite",
    width: "64px",
    title: "Fav.",
  },
  {
    type: "rowMenu",
    getCellValue: getRowMenu,
    width: "64px",
  },
  {
    name: "number",
    title: "Number",
    bold: true,
    filter: true,
    fill: true,
  },
  {
    name: "property.name",
    title: "Property",
    width: "20vw",
    filter: true,
  },
  {
    name: "name",
    title: "Project Name",
    filter: true,
  },
  {
    name: "status",
    filter: true,
    filterOptions: {
      operator: statusFilter,
      options: statuses,
    },
  },
  {
    name: "team.name",
    title: "BW Team",
  },
  {
    name: "billingFlag",
    title: "Billing",
  },
  {
    name: "workScopes.id",
    title: "Scope",
    filter: true,
    filterOptions: {
      operator: "in",
      options: workScopes,
    },
  },
];
