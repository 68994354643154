import { UsaStates } from "usa-states";
import memoize from "memoize-one";
const states = new UsaStates({ includeTerritories: true }).states;

const getReplacers = memoize(vendor => {
  if (!vendor) return { State: "" };
  if (!vendor.id || !vendor.location) return { State: "" };
  const vendorState = states.find(
    state => state.abbreviation === vendor.location.stateCode
  );
  if (!vendorState) return { State: "" };
  return { State: vendorState.name };
});

export function getContactStatus(vendor, contactStatus) {
  const replacers = getReplacers(vendor);
  return Object.keys(replacers).reduce((text, term) => {
    return text.replace(`[${term}]`, replacers[term]);
  }, contactStatus || "");
}
