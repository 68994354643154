import { BaseModel } from "@blue-chip/core";
import Vendor from "./Vendor";
import Location from "./Location";
import VendorContact from "./VendorContact";

export default class VendorShipToSite extends BaseModel {
  static get hasMany() {
    return [VendorContact];
  }
  static get belongsTo() {
    return [Vendor, Location];
  }
}
