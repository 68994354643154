import React from "react";
import styled from "styled-components";
import Trash from "@material-ui/icons/Delete";

import { inputs } from "../../../inputs/inputConfigs";
import InputWrapper from "../../../inputs/InputWrapper";
import TextField from "../../../mui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

const Row = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 24px;
  padding-right: 12px;
`;

const Input = styled.div`
  width: 50%;
  ${TextField} {
    & .root {
      margin: 8px 0;
    }
    & .label {
      top: -5px;
    }
  }
`;

const TrashIcon = styled(Trash)`
  color: #0e1524;
`;

const requirementId = {
  ...inputs.requirementId,
  label: "",
  placeholder: inputs.requirementId.label,
  textFieldProps: {
    InputLabelProps: {
      shrink: false,
    },
    InputProps: {
      disableUnderline: true,
    },
  },
};

const AddRequirementRow = ({ isActive, onRemove, onCreate }) => {
  if (!isActive) return null;
  return (
    <Row>
      <Input>
        <InputWrapper {...requirementId} onChange={onCreate} />
      </Input>
      <IconButton size="small" onClick={onRemove}>
        <TrashIcon />
      </IconButton>
    </Row>
  );
};

AddRequirementRow.propTypes = {};

export default AddRequirementRow;
