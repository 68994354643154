import React from "react";
import styled from "styled-components";
import BWTableRow from "../../../../ui/BWGrid/gridInternalComponents/BWTableRow";
import Delete from "@material-ui/icons/Delete";
import { Wrapper } from "../../../../ui/BWGrid/columns/EditableColumn";
import Paper from "../../../../mui/core/Paper";
import RegularText from "../../../../ui/Typography/RegularText";
import { BWActionButton } from "../../../../ui/BWActionButton";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

export const DeleteIcon = styled(Delete)`
  display: none;
`;

export const GridWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ${Wrapper} {
    min-width: 200px;
  }
  ${Paper} {
    margin-bottom: 0;
  }
`;

const Row = styled(BWTableRow)`
  &:hover {
    ${DeleteIcon} {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
`;

export const RowComponent = props => <Row {...props} />;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Label = styled(RegularText)`
  padding: 0;
  color: #979797;
  font-size: 14px;
`;

export const Button = styled(props => (
  <BWActionButton disableRipple disableFocusRipple {...props} />
))`
  padding: 0;
  margin-left: 8px;
  text-transform: capitalize;
  font-size: 8px;
  cursor: pointer;
  min-width: unset;
  &:hover {
    background-color: unset;
  }
`;

export const DeleteButton = ({ id, onClick, disabled }) => (
  <DeleteButtonWrapper>
    <IconButton
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        onClick(id);
      }}
    >
      <DeleteIcon />
    </IconButton>
  </DeleteButtonWrapper>
);

DeleteButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
