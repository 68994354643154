import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import moment from "moment";
import Remove from "@material-ui/icons/Remove";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { StyledBWTableRow } from "../../../../ui/BWGrid/gridInternalComponents/BWTableRow";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const DeleteButtonWrapper = styled.div`
  display: none;
`;

const DeleteIcon = styled(Delete)`
  font-size: 22px;
`;

export const GridWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
  ${StyledBWTableRow} {
    &:hover ${DeleteButtonWrapper} {
      display: block;
    }
  }
`;

export const UploadPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 0 10px;
`;

export const Date = styled.span`
  font-weight: bold;
`;

export const DashIcon = styled(Remove)`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const Text = styled.span`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Status = styled(Text)`
  color: rgba(0, 0, 0, 0.38);
  line-height: 24px;
  font-weight: bold;
`;

const Anchor = styled(Text)`
  color: #57abff;
  text-decoration: none;
`;

export const pad = (number, size = 3) => {
  var s = String(number);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
};

export const VersionColumn = ({ revision }) => {
  const version = _get(revision, "version");
  return typeof version === "number" ? (
    <Text>{pad(version)}</Text>
  ) : (
    <DashIcon />
  );
};

VersionColumn.propTypes = {
  revision: PropTypes.shape({ version: PropTypes.number }),
};

export const DateColumn = ({ createdAt }) => {
  return createdAt ? (
    <Text>{moment(createdAt).format(PRIMARY_DATE_FORMAT)}</Text>
  ) : (
    <DashIcon />
  );
};

DateColumn.propTypes = {
  createdAt: PropTypes.string,
};

export const StatusColumn = ({ status }) => {
  return status ? <Status>{status}</Status> : <DashIcon />;
};

StatusColumn.propTypes = {
  status: PropTypes.string,
};

export const UserColumn = ({ user }) => {
  const username = _get(user, "name");
  return username ? <Text>{username}</Text> : <DashIcon />;
};

UserColumn.propTypes = {
  user: PropTypes.shape({ name: PropTypes.string }),
};

export const FileLinkColumn = ({ file }) => {
  const filename = _get(file, "filename");
  const url = _get(file, "s3SignedUrl");
  return (
    filename && (
      <Anchor as="a" href={url} target="_blank">
        {filename}
      </Anchor>
    )
  );
};

FileLinkColumn.propTypes = {
  file: PropTypes.shape({ filename: PropTypes.string }),
};

export const DeleteButton = ({
  isDeleting,
  onDeleteApproval,
  deletableVersions,
  id,
  revision,
  disabled,
}) => {
  const isDeleteable = revision
    ? deletableVersions.includes(revision.version.toString())
    : false;
  const isDisabled = isDeleting || disabled || !isDeleteable;
  return (
    <DeleteButtonWrapper>
      <Tooltip
        title={
          isDeleteable
            ? "Delete"
            : "The last approval file for this revision can't be deleted"
        }
      >
        <span>
          <IconButton
            disabled={isDisabled}
            onClick={() => onDeleteApproval(id)}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </DeleteButtonWrapper>
  );
};

DeleteButton.propTypes = {
  onDeleteApproval: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  deletableVersions: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  revision: PropTypes.shape({ version: PropTypes.number }),
  disabled: PropTypes.bool,
};
