import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 24px;
`;
const CommentsLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;
const Comments = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0e1524;
`;

const VendorCommentsContainer = props => {
  const { comments } = props;

  return (
    <Wrapper>
      <CommentsLabel>Comments</CommentsLabel>
      <Comments>{comments}</Comments>
    </Wrapper>
  );
};
VendorCommentsContainer.propTypes = {
  comments: PropTypes.string.isRequired,
};

export default VendorCommentsContainer;
