import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Inbox from "@material-ui/icons/Inbox";
import RegularText from "./Typography/RegularText";
import RegularTextSmall from "./Typography/RegularTextSmall";

const intensityValues = {
  soft: "rgba(0, 0, 0, 0.24)",
  normal: "rgba(0, 0, 0, 0.38)",
  strong: "rgba(0, 0, 0, 0.54)",
};

export const Wrapper = styled(({ intensity, padding, ...props }) => (
  <div {...props} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: ${({ intensity }) => intensityValues[intensity]};
  padding: ${({ padding }) => padding};
`;

export const Icon = styled(({ intensity, size, ...props }) => (
  <Inbox {...props} />
))`
  margin-bottom: 8px;
  width: ${({ size }) => (size === "small" ? "24px" : "48px")};
  height: ${({ size }) => (size === "small" ? "24px" : "48px")};
  color: ${({ intensity }) => intensityValues[intensity]};
`;

const EmptyState = ({ children, padding, intensity, size, intent }) => {
  const Text = size === "small" ? RegularTextSmall : RegularText;
  return (
    <Wrapper intensity={intensity} padding={padding}>
      <Icon size={size} intensity={intensity} />
      <Text as="div" intent={intent}>
        {children}
      </Text>
    </Wrapper>
  );
};

EmptyState.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large"]),
  intensity: PropTypes.oneOf(["soft", "normal", "strong"]),
  intent: PropTypes.string,
  padding: PropTypes.string,
};

EmptyState.defaultProps = {
  children: "No results to show",
  size: "large",
  padding: "48px",
  intensity: "normal",
};

export default EmptyState;
