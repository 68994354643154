import FreightInfo from "./FreightInfoContainer";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import {
  freightContactdataComponentId,
  poContactdataComponentId,
} from "../../../../../actions/purchaseOrdersContactActions";
import {
  genMapDispatchToProps,
  genMapStateToProps,
} from "../contactComponentsUtils";

const mapStateToProps = (state, { purchaseOrder }) => {
  return genMapStateToProps({
    state,
    vendorDC: "PODetailFreightVendor",
    contactsDC: freightContactdataComponentId,
    vendorId: purchaseOrder.freightVendorId,
    contactType: "freight",
    purchaseOrderContacts: getBluechipResourcesByType(
      poContactdataComponentId,
      state
    ),
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { purchaseOrder } = ownProps;
  return {
    goToVendorProfile: () =>
      dispatch(push(`/vendors/${purchaseOrder.freightVendorId}`)),
    ...genMapDispatchToProps({
      dispatch,
      vendorId: purchaseOrder.freightVendorId,
      vendorDC: "PODetailFreightVendor",
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightInfo);
