import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import BidGroupVendor from "./BidGroupVendor";
import BidGroupVendorSpecQuote from "./BidGroupVendorSpecQuote";

export default class BidGroupVendorSpec extends BaseModel {
  static get belongsTo() {
    return [BidGroupVendor, Spec];
  }
  static get hasMany() {
    return [BidGroupVendorSpecQuote];
  }
}
