import React from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import BWModels from "benjaminwest-models";
import propTypes from "../../../../constants/propTypes";

import CreateVendorContactForm from "../CreateVendorContact/CreateVendorContactForm";
import { parseContactStatus } from "../CreateVendorContact/CreateVendorContactContainer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { vendorContactsDataComponentId } from "../VendorContactsContainer";
import { usePrevious } from "../../../hooks/usePrevious";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import { handleCreateContactError } from "../CreateVendorContact/CreateVendorContactFunctions";

export const formatVendorContactStatus = contact => {
  if (typeof contact.contactStatus === "string") {
    contact.contactStatus = contact.contactStatus.split(",");
  }
};

const EditVendorContactContainer = props => {
  const {
    vendor,
    vendorContact,
    onUpdateVendorContact,
    ignoreAddressBook,
    formikPropsRef,
  } = props;

  formatVendorContactStatus(vendorContact);

  const initialValues = {
    contact: {},
    ...vendorContact,
  };

  const validationSchema = () => {
    return BWModels.loadSchema("VendorContact").concat(
      Joi.object().keys({
        contact: BWModels.loadSchema("Contact"),
      })
    );
  };
  const handleEditContact = contact => {
    parseContactStatus(contact);
    onUpdateVendorContact(contact);
  };

  const dataComponent = useDatacomponent(vendorContactsDataComponentId);
  const prevDataComponent = usePrevious(dataComponent);
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onError: handleCreateContactError(
      formikPropsRef.current,
      showSnackNotificationAction
    ),
  });

  return (
    <CreateVendorContactForm
      isEdit
      initialValues={initialValues}
      vendor={vendor}
      onSubmit={handleEditContact}
      validationSchema={validationSchema()}
      id={vendorContact.id}
      ignoreAddressBook={ignoreAddressBook}
      formikPropsRef={formikPropsRef}
    />
  );
};
EditVendorContactContainer.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  vendor: propTypes.vendor.isRequired,
  vendorContact: propTypes.vendorContact.isRequired,
  onUpdateVendorContact: PropTypes.func.isRequired,
  ignoreAddressBook: PropTypes.bool,
  formikPropsRef: PropTypes.shape({}),
};

export default EditVendorContactContainer;
