import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Content } from "../../layout/Content";
import { FormikForm, ActionButtons, PromptIfDirty } from "../../forms";
import propTypes from "../../../constants/propTypes";
import { NavigationArea } from "../../layout/DetailAndCycle/";

import { LoaderContext } from "../../ui/Loader";
import fields from "./GeneralInformationSection/createPropertyFields";
import { FormPageSection } from "../../layout/FormPageSection";

const PropertyForm = ({
  propertySchema,
  onSave,
  initialValues,
  loading,
  isUpdate,
  ignoreCache,
  clientId,
  dataComponentId,
}) => (
  <LoaderContext.Provider value={{ loading }}>
    <Content>
      {isUpdate && (
        <NavigationArea backText={"Back to Property Index"} hideActions />
      )}
      <FormikForm
        initialValues={initialValues}
        onSubmit={onSave}
        validationSchema={propertySchema}
        ignoreValidationFieldNames={["postalCode"]}
        enableReinitialize={true}
        autoSave={isUpdate}
        ignoreCache={ignoreCache}
      >
        {({ handleSubmit }) => (
          <Fragment>
            <FormPageSection
              headerText="General Information"
              fields={fields(clientId)}
            />

            {!isUpdate && (
              <ActionButtons
                onSend={handleSubmit}
                sendButtonText={"CREATE PROPERTY"}
                listeners={[dataComponentId]}
              />
            )}
            <PromptIfDirty />
          </Fragment>
        )}
      </FormikForm>
    </Content>
  </LoaderContext.Provider>
);

PropertyForm.propTypes = {
  propertySchema: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValues: propTypes.property,
  loading: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  ignoreCache: PropTypes.bool,
  autoSave: PropTypes.bool,
  clientId: PropTypes.string,
  dataComponentId: PropTypes.string.isRequired,
};

export default PropertyForm;
