import React from "react";
import styled from "styled-components";
import { ListItemIcon } from "@material-ui/core";
import TableChartIcon from "@material-ui/icons/TableChart";
import PropTypes from "prop-types";

import RegularText from "../../../../../ui/Typography/RegularText";
import { ViewIcon } from "./icons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c7c7c7;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "white" : "unset")};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "unset")};
`;

const IconWrapper = styled(ListItemIcon)`
  margin: 0px 12px 0px 24px;
  height: 24px;
`;

const Name = styled(RegularText)``;

const TypeIcon = {
  table: TableChartIcon,
  view: ViewIcon,
};

export const TableRow = ({
  name,
  type,
  isSelected,
  onClick,
  onDoubleClick,
}) => {
  const Icon = TypeIcon[type];

  const handleClick = evt => {
    switch (evt.detail) {
      case 1:
        onClick(evt);
        break;
      case 2:
        onDoubleClick(evt);
        break;
    }
  };

  return (
    <Wrapper isSelected={isSelected} onClick={handleClick}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Name>{name}</Name>
    </Wrapper>
  );
};

TableRow.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};
