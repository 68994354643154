import React from "react";
import styled from "styled-components";

import EditableCell from "../../../../ui/BWGrid/EditableCell";
import BWCell from "../../../../ui/BWGrid/gridInternalComponents/BWCell";
import { editableColumns } from "./Column";

const columnsWithBorder = {
  title: true,
  assetsToDate: true,
  depositsToDate: true,
  net: true,
};

const buildCellBorder = column => {
  return columnsWithBorder[column.name]
    ? "1px solid #cccccc !important"
    : "none";
};

export const StyledBWCell = styled(BWCell)`
  border-right: ${({ column, buildBorder = buildCellBorder }) =>
    buildBorder(column)};
`;

export const StyledEditableCell = styled(EditableCell)`
  border-right: ${({ column, buildBorder = buildCellBorder }) =>
    buildBorder(column)};
`;

export default (
  rowInputs,
  getInputName,
  openNextFallback,
  openPrevFallback,
  buildBorder = buildCellBorder,
  isEditable = (tableRow, column) =>
    editableColumns[tableRow.rowId][column.name]
) => {
  return function CellWrapper({ children, ...props }) {
    const { column, tableRow } = props;
    if (!isEditable(tableRow, column)) {
      return (
        <StyledBWCell buildBorder={buildBorder} {...props}>
          {children}
        </StyledBWCell>
      );
    }
    const input = rowInputs[column.name];
    return (
      <StyledEditableCell
        {...props}
        input={input}
        getInputName={getInputName}
        openNextFallback={openNextFallback}
        openPrevFallback={openPrevFallback}
        buildBorder={buildBorder}
      >
        {children}
      </StyledEditableCell>
    );
  };
};
