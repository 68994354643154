import React, { useCallback, useEffect, useMemo, useState } from "react";
import _pick from "lodash/pick";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../../actions/layoutActions";
import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { LoaderContext } from "../../../../../ui/Loader";
import FundingForm from "./FundingForm";
import {
  initCreateComponents,
  createFunding,
  updateFunding,
  performAfterCreate,
} from "../../../../../../actions/fundingActions";
import FundingDetails from "./FundingDetails";
import { getBluechipResourceById } from "../../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../../../actions/dataComponentActions";
import Funding from "../../../../../../models/Funding";

const METHOD_TYPE = "Cashflow";
export const dataComponentIdCreate = "FundingCreate";
const fundingDataComponentId = "FundingDataComponentId";

const processRequestResponse = (
  processRequestStatus,
  prevDataComponent,
  dataComponent,
  formikActions,
  performAfterCreate
) => {
  processRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      performAfterCreate(formikActions);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateFunding = ({
  projectId,
  initCreateComponents,
  createFunding,
  loading,
  dataComponent,
  performAfterCreate,
  openModalDialog,
  closeModalDialog,
  hideCancelButton,
  updateFunding,
  sendButtonText,
  funding,
  project,
  initDataComponent,
  performFindRequest,
  paymentsCount,
  isFundingPaidConfirmation,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [formikActions, setFormikAction] = useState(null);
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      processUpdateRequestStatus,
      prevDataComponent,
      dataComponent,
      formikActions,
      performAfterCreate
    );
    processRequestResponse(
      processCreateRequestStatus,
      prevDataComponent,
      dataComponent,
      formikActions,
      performAfterCreate
    );
  }, [dataComponent, formikActions, performAfterCreate, prevDataComponent]);

  useEffect(() => {
    if (funding && funding.id) {
      initDataComponent(fundingDataComponentId, Funding, [], "fundings", "v2");
      performFindRequest(fundingDataComponentId, funding.id);
    }
  }, [funding, initDataComponent, performFindRequest]);

  const handleCreateFunding = useCallback(
    ({ projectCurrency, ...funding }, formikActions) => {
      setFormikAction(formikActions);
      createFunding(funding);
    },
    [createFunding]
  );

  const handleUpdateFunding = useCallback(
    (fundingData, formikActions) => {
      const updatedFunding = { id: funding.id, ...fundingData };
      delete updatedFunding.projectCurrency;
      setFormikAction(formikActions);
      updateFunding(funding.id, updatedFunding, dataComponentIdCreate);
    },
    [funding, updateFunding]
  );

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Fund Request",
      "CreateFundingType",
      {
        projectId,
        project,
      },
      false,
      true,
      {
        subtitle: "Select Funding Type",
      }
    );
  }, [openModalDialog, project, projectId]);

  useEffect(() => {
    initCreateComponents();
  }, [initCreateComponents]);

  const defaultProjectCurrency = project?.projectCurrencies?.find(
    projectCurrency => projectCurrency.isDefault
  );

  const initialValues = useMemo(() => {
    return {
      projectId,
      projectCurrencyId: defaultProjectCurrency?.id,
      useTaxPayment: false,
      method: METHOD_TYPE,
      ..._pick(funding, [
        "projectCurrencyId",
        "projectCurrency",
        "amountRequested",
        "id",
        "cashflowFile",
        "projectBudgetFile",
        "notes",
        "useTaxPayment",
        "method",
      ]),
    };
  }, [projectId, defaultProjectCurrency, funding]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      {(!funding.id || paymentsCount === 0) && (
        <FundingForm
          sendButtonText={sendButtonText}
          hideCancelButton={hideCancelButton}
          onSubmit={funding.id ? handleUpdateFunding : handleCreateFunding}
          onCancel={handleOpenCreateModal}
          initialValues={initialValues}
          dataComponentId={dataComponentIdCreate}
          loading={loading}
          closeModalDialog={closeModalDialog}
          isFundingPaidConfirmation={isFundingPaidConfirmation}
        />
      )}
      {funding.id && paymentsCount > 0 && <FundingDetails funding={funding} />}
    </LoaderContext.Provider>
  );
};

CreateFunding.propTypes = {
  projectId: PropTypes.string,
  initCreateComponents: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  createFunding: PropTypes.func.isRequired,
  performAfterCreate: PropTypes.func,
  loading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  project: propTypes.project,
  hideCancelButton: PropTypes.bool,
  updateFunding: PropTypes.func,
  sendButtonText: PropTypes.string,
  funding: propTypes.funding,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  paymentsCount: PropTypes.number,
  isFundingPaidConfirmation: PropTypes.bool,
};

CreateFunding.defaultProps = {
  funding: {},
};

export const mapStateToProps = (state, { funding }) => {
  const dataComponent = getDataComponent(dataComponentIdCreate, state);

  let paymentsCount = funding?.paymentCount || 0;

  if (funding && funding.id) {
    const fundingDataComponent = getDataComponentFlattenedRequestState(
      fundingDataComponentId,
      state,
      REQUEST_TYPES.FIND
    );

    const fundingDB = getBluechipResourceById(
      fundingDataComponent,
      state,
      funding.id
    );

    paymentsCount = fundingDB?.paymentCount || 0;
  }

  const loading = [
    REQUEST_TYPES.CREATE,
    REQUEST_TYPES.UPDATE,
    REQUEST_TYPES.FIND,
  ].some(
    action =>
      getDataComponentFlattenedRequestState(
        dataComponentIdCreate,
        state,
        action
      ).loading ||
      getDataComponentFlattenedRequestState(
        fundingDataComponentId,
        state,
        action
      ).loading
  );

  return {
    dataComponent,
    loading,
    paymentsCount,
  };
};

export const mapDispatchToProps = {
  initCreateComponents,
  performAfterCreate,
  createFunding,
  openModalDialog,
  closeModalDialog,
  updateFunding,
  initDataComponent,
  performFindRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFunding);
