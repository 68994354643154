import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Menu } from "@material-ui/core";

import CheckboxWithError from "../../../inputs/CheckboxWithError";
import { getOptionsFromSchema } from "../../../inputs/utils";
import { MenuItem } from "../../../mui/core";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  align-self: flex-end;
`;

const getLabel = cancelledBy => `Cancelled ${cancelledBy || ""}`;

export const CancelledCheckbox = ({
  setFieldValue,
  isCancelled,
  cancelledBy,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const options = getOptionsFromSchema("Project", "cancelledByOptions")();

  const isProjectClosed = useIsProjectClosed();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkboxChange = event => {
    const {
      target: { value },
    } = event;
    if (!value) {
      setFieldValue("isCancelled", false);
      setFieldValue("cancelledBy", null);
      handleClose();
    } else {
      handleClick(event);
    }
  };

  const menuChange = value => () => {
    setFieldValue("isCancelled", true);
    setFieldValue("cancelledBy", value);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <CheckboxWrapper>
        <CheckboxWithError
          label={getLabel(cancelledBy)}
          value={isCancelled}
          onChange={checkboxChange}
          disabled={isProjectClosed}
          formControlLabelProps={{
            onClick: event => {
              event.stopPropagation();
              event.preventDefault();
              handleClick(event);
            },
          }}
        />
      </CheckboxWrapper>
      <Menu
        id={"cancelled-by-menu"}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disabled={isProjectClosed}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map(({ id, name }, index) => (
          <MenuItem
            key={index}
            value={id}
            onClick={menuChange(id)}
            disabled={isProjectClosed}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
};

CancelledCheckbox.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  isCancelled: PropTypes.bool,
  cancelledBy: PropTypes.string,
};
