import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DiscountWrapper, DiscountType, DiscountIcon } from "./components";
import { openModalDialog } from "../../../../../actions/layoutActions";

const CreateDiscountType = props => {
  const { openModalDialog, vendorId } = props;
  const handleCreateBatchFunding = useCallback(() => {
    openModalDialog(
      "Create Vendor Discount",
      "CreateVendorDiscountContainer",
      {
        vendorId,
      },
      false,
      true,

      {
        scrollDisabled: true,
      }
    );
  }, [openModalDialog, vendorId]);
  const handleCreateFunding = useCallback(() => {
    openModalDialog(
      "Create Program Discount",
      "CreateDiscount",
      {
        vendorId,
      },
      false,
      true,
      {
        scrollDisabled: true,
      }
    );
  }, [openModalDialog, vendorId]);
  return (
    <div>
      <DiscountWrapper>
        <DiscountType onClick={handleCreateFunding}>
          <DiscountIcon />
          Create Program Discount
        </DiscountType>
      </DiscountWrapper>
      <DiscountWrapper>
        <DiscountType onClick={handleCreateBatchFunding}>
          <DiscountIcon />
          Create BW Discount
        </DiscountType>
      </DiscountWrapper>
    </div>
  );
};

CreateDiscountType.propTypes = {
  openModalDialog: PropTypes.func,
  vendorId: PropTypes.string,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(null, mapDispatchToProps)(CreateDiscountType);
