import { SelectWithError as RawSelectWithError } from "../../../../inputs/SelectWithError";
import TextInputWithError from "../../../../inputs/TextInputWithError";

export default {
  contacts: {
    InputComponent: RawSelectWithError,
    label: "Select Contact",
    name: "contact",
    options: [],
    submitData: true,
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: false,
    required: true,
  },
  comments: {
    InputComponent: TextInputWithError,
    label: "Comments",
    name: "comments",
    fullWidth: true,
  },
};
