import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import SpecDetailCom from "./SpecDetailCom";
import SpecDetailRoomType from "./SpecDetailRoomType";
import File from "./File";

export default class SpecDetail extends BaseModel {
  static get belongsTo() {
    return [Spec, File];
  }

  static get hasMany() {
    return [SpecDetailCom, SpecDetailRoomType];
  }
}
