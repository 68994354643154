import useActions from "../../../../../hooks/useActions";
import { initFilters } from "../../../../../../actions/purchaseOrderSpecsActions";
import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import shallow from "zustand/shallow";
import { useEffect, useMemo } from "react";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import * as DATA_COMPONENTS from "../../../../../../constants/DataComponents";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";

const defaultList = [];

function mapOptions(options) {
  return options.map(({ name }) => ({ id: name, name }));
}

const specToPOStoreSelector = state => [
  state.purchaseOrder,
  state.filtersActive,
  state.actions,
];

export default function() {
  const [purchaseOrder, filtersActive, stateActions] = useAddSpecToPOStore(
    specToPOStoreSelector,
    shallow
  );
  const actions = useActions({ initFilters });

  useEffect(() => {
    if (filtersActive) return;

    stateActions.activateFilters();
    actions.initFilters(purchaseOrder);
  }, [filtersActive, stateActions, actions, purchaseOrder]);

  const categories = useV2DatacomponentResources(
    DATA_COMPONENTS.SPEC_CATEGORIES_SELECT,
    defaultList,
    REQUEST_TYPES.LIST
  );

  const unitOfMeasures = useV2DatacomponentResources(
    DATA_COMPONENTS.UNIT_OF_MEASURE_SELECT,
    defaultList,
    REQUEST_TYPES.LIST
  );

  const areas = useV2DatacomponentResources(
    DATA_COMPONENTS.AREAS_SELECT,
    defaultList,
    REQUEST_TYPES.LIST
  );

  const vendors = useV2DatacomponentResources(
    DATA_COMPONENTS.VENDORS_SELECT,
    defaultList,
    REQUEST_TYPES.LIST
  );

  return useMemo(() => {
    return {
      categories: mapOptions(categories),
      areas: mapOptions(areas),
      vendors: mapOptions(vendors),
      unitOfMeasures: mapOptions(unitOfMeasures),
    };
  }, [categories, areas, vendors, unitOfMeasures]);
}
