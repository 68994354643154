import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _noop from "lodash/noop";

import BaseTab from "../BaseTab";
import SummarySection from "./SummarySection";
import AccountSection from "./AccountSection";
import FundingSection from "./FundingSection";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import Project from "../../../../../models/Project";
import ProjectSummary from "../../../../../models/ProjectSummary";
import { LoaderContext } from "../../../../ui/Loader";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../../actions/dataComponentActions";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import propTypes from "../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import useScopedCurrency from "../../../../hooks/useScopedCurrency";

const projectDataComponentId = "ProjectDetailWithFunding";
const summaryDataComponentId = "ProjectSummary";
export const SummaryContainer = ({
  loading,
  projectId,
  project,
  performFindRequest,
  initDataComponent,
  projectSummary,
}) => {
  let scopedCurrency = useScopedCurrency();

  useEffect(() => {
    initDataComponent(
      projectDataComponentId,
      Project,
      [],
      "project-with-fundings"
    );
    initDataComponent(
      summaryDataComponentId,
      ProjectSummary,
      [],
      "projects/summary"
    );
    performFindRequest(projectDataComponentId, projectId);
    performFindRequest(summaryDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <BaseTab text="Summary">
        <SummarySection project={project} currency={scopedCurrency} />
      </BaseTab>
      <BaseTab text="Funding">
        <FundingSection project={project} projectSummary={projectSummary} />
      </BaseTab>
      <BaseTab text="Account Name">
        <AccountSection
          project={project}
          projectForecast={projectSummary}
          currency={scopedCurrency}
        />
      </BaseTab>
    </LoaderContext.Provider>
  );
};

SummaryContainer.propTypes = {
  project: propTypes.project,
  projectSummary: propTypes.projectSummary,
  loading: PropTypes.bool,
  performFindRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

SummaryContainer.defaultProps = {
  projectSummary: {
    forecastTotal: 0,
  },
  project: {
    fundingDue: "",
    accountingDue: "",
    currency: {
      name: "",
      isoCode: "",
    },
  },
};

export const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const summaryDataComponent = getDataComponentFlattenedRequestState(
    summaryDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const project = getBluechipResourceById(
    projectDataComponent,
    state,
    projectId
  );

  const projectSummary = getBluechipResourceById(
    summaryDataComponent,
    state,
    projectId
  );
  return {
    project: project || _noop(),
    projectId,
    loading: [projectDataComponent, summaryDataComponent].some(
      ({ loading }) => loading
    ),
    projectSummary: projectSummary || _noop(),
  };
};

const mapDispatchToProps = {
  performFindRequest,
  initDataComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryContainer);
