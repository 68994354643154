import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

export default function AutoSaveManager(delay = 1500) {
  this.bulkData = {};
  this.delay = delay;
  this.noDelayFields = [];
  this.onSave = () => {};

  this.commit = _debounce(changes => {
    if (!_isEqual(changes === this.bulkData)) {
      this.onSave(this.bulkData);
    }
  }, delay);
}

AutoSaveManager.prototype.setOptions = function(onSave, noDelayFields) {
  this.onSave = onSave;
  this.noDelayFields = noDelayFields;
};

AutoSaveManager.prototype.hasNoDelayFields = function(values) {
  const fields = this.noDelayFields;
  const keys = Object.keys(values);

  return (
    keys.length > 0 &&
    fields.some(field => {
      if (field instanceof RegExp) {
        return field.test(keys[0]);
      }
      return field === keys[0];
    })
  );
};

AutoSaveManager.prototype.hasValidValues = function(values) {
  return !_isEmpty(values) && !_isEqual(values, this.bulkData);
};

AutoSaveManager.prototype.push = function(changes) {
  if (!this.hasValidValues(changes)) return;
  this.bulkData = changes;
  this.commit(changes);

  if (this.hasNoDelayFields(changes)) {
    this.commit.flush();
  }
};

AutoSaveManager.prototype.flushAutoSave = function() {
  return () => {
    this.commit.flush();
  };
};
