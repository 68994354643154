import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  updateUser,
  initUserComponent,
} from "../../../../actions/usersActions";

import {
  logout,
  validateAuthentication,
} from "../../../../actions/authActions";

import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import AccountSettings, { updateUserDataComponentId } from "./AccountSettings";

export const mapStateToProps = ({
  auth: {
    cellNumber,
    userId,
    email,
    name,
    profilePicture,
    isCellNumberVerified,
  },
  ...state
}) => {
  const dataComponent = getDataComponent(
    updateUserDataComponentId,
    state,
    REQUEST_TYPES.UPDATE
  );

  return {
    userData: {
      cellNumber,
      profilePicture,
      email,
      name,
      id: userId,
    },
    dataComponent,
    isCellNumberVerified,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        updateUser,
        initUserComponent,
        closeModalDialog,
        openModalDialog,
        showSnackNotificationAction,
        validateAuthentication,
        logout,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
