import React from "react";
import PropTypes from "prop-types";
import _without from "lodash/without";

import FavoriteStar from "../../../ui/BWGrid/FavoriteStar";

const FavoriteControl = ({
  isStarred,
  favoriteClientIds,
  clientId,
  actions,
  loading,
}) => {
  const handleToggle = isStarred => {
    const newFavorites = _without(favoriteClientIds, clientId);
    if (isStarred) {
      newFavorites.push(clientId);
    }
    actions.updateAuthenticatedUser({ favoriteClientIds: newFavorites });
  };

  return (
    <FavoriteStar
      isStarred={isStarred}
      onClick={handleToggle}
      loading={loading}
    />
  );
};

FavoriteControl.propTypes = {
  isStarred: PropTypes.bool,
  favoriteClientIds: PropTypes.arrayOf(PropTypes.string),
  clientId: PropTypes.string,
  actions: PropTypes.shape({
    updateAuthenticatedUser: PropTypes.func,
  }),
  loading: PropTypes.bool,
};

export default FavoriteControl;
