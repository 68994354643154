import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { bindActionCreators } from "redux";

import {
  expeditingPOList,
  getLoadNextPOPage,
} from "../../../../actions/expeditingActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import PurchaseOrderRow from "./PurchaseOrderRow";
import BWInfiniteScroll from "../../../ui/BWInfiniteScroll";
import { initDataComponent } from "../../../../actions/dataComponentActions";
import PurchaseOrder from "../../../../models/PurchaseOrder";
import { ExpeditingFiltersContext } from "../Expediting/ExpeditingFiltersContext";

export const dataComponentId = "SpecShipmentsGrid";
export const shipmentsDataComponentId = "Shipments";

export const handleOpenManageShipments = ({
  openModalDialog,
  closeModalDialog,
  reset,
  props = {},
}) => spec => {
  const { customNumber, description } = spec;
  openModalDialog(
    ["Manage Shipments", `#${customNumber} ${description}`],
    "ManageShipmentsModal",
    {
      spec,
      ...props,
    },
    false,
    true,
    {
      width: "800px",
      onCloseModal: () => {
        reset();
        closeModalDialog();
      },
    }
  );
};

export const handleOpenEditAllAction = ({
  openModalDialog,
  purchaseOrder,
  props = {},
}) => () => () => {
  openModalDialog(
    `Modify Multiple Shipments (All)`,
    "EditMultipleShipmentsAllModal",
    {
      purchaseOrder,
      ...props,
    },
    true,
    false
  );
};

export const handleOpenEditAction = ({
  openModalDialog,
  props = {},
}) => selectedIds => () => {
  openModalDialog(
    `Modify Multiple Shipments`,
    "EditMultipleShipmentsModal",
    {
      selectedSpecIds: selectedIds,
      ...props,
    },
    true,
    false,
    {
      subtitle: pluralize("Spec Shipment", selectedIds.length, true),
    }
  );
};

const ShipmentsContainer = ({
  match: {
    params: { projectId },
  },
  initDataComponent,
  getLoadNextPOPage,
}) => {
  const { filters } = useContext(ExpeditingFiltersContext);
  useEffect(() => {
    initDataComponent(
      expeditingPOList,
      PurchaseOrder,
      [],
      "purchase-orders",
      true,
      "v2"
    );
    getLoadNextPOPage(projectId, filters)(0);
  }, [filters, getLoadNextPOPage, initDataComponent, projectId]);

  return (
    <BWInfiniteScroll
      dataComponentId={expeditingPOList}
      loadNext={getLoadNextPOPage(projectId, filters)}
      scrollThreshold={0.7}
      mapRowToComponent={purchaseOrder => (
        <PurchaseOrderRow
          key={purchaseOrder.id}
          purchaseOrder={purchaseOrder}
        />
      )}
    />
  );
};

ShipmentsContainer.propTypes = {
  match: PropTypes.object.isRequired,
  dataComponent: PropTypes.object.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  getLoadNextPOPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(expeditingPOList, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ initDataComponent }, dispatch),
  getLoadNextPOPage: (...props) => pageNumber =>
    dispatch(getLoadNextPOPage(...props)(pageNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsContainer);
