import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClientCreatePage from "./ClientCreatePage";
import {
  populateCountryResources,
  populateStateResources,
} from "../../../actions/countryStateActions";
import { push } from "connected-react-router";

import {
  initDataComponent,
  performCreateRequest,
} from "../../../actions/dataComponentActions";

import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../utils/formValidationUtils";
import {
  getCreatedId,
  processCreateRequestStatus,
} from "../../../utils/dataComponentUtils";

import Client from "../../../models/Client";
import get from "lodash.get";
import { some } from "lodash";
import { uploadFiles } from "../../../actions/filesActions";

export const dataComponentId = "ClientCreate";

export class ClientCreateContainer extends Component {
  componentDidMount() {
    const {
      initDataComponent,
      populateCountryResources,
      populateStateResources,
    } = this.props;

    initDataComponent(
      dataComponentId,
      Client,
      ["location", "msaFile"],
      "clients"
    );
    populateCountryResources();
    populateStateResources();
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleCreateSuccess = () => {
    const { push, createdId } = this.props;

    push(`/clients/${createdId}`);
  };

  createClient = async (client, formikActions) => {
    const { performCreateRequest, uploadFiles } = this.props;

    const files = await uploadFiles(client, client.id, ["msaFile"], "clients");
    files.reduce((client, { field, ...file }) => {
      client[field] = file;
      return client;
    }, client);
    performCreateRequest(dataComponentId, client);

    this.setState({ formikActions });
  };

  goBack = () => {
    this.props.push(`/clients`);
  };

  render() {
    return (
      <ClientCreatePage
        goBack={this.goBack}
        createClient={this.createClient}
        isLoading={this.props.isLoading}
      />
    );
  }
}

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const createdId = getCreatedId(dataComponent);

  const isLoading = some(Object.values(get(state, "files.loaders")), Boolean);
  return {
    dataComponent,
    createdId,
    isLoading,
  };
};

ClientCreateContainer.propTypes = {
  populateCountryResources: PropTypes.func,
  populateStateResources: PropTypes.func,
  clientContacts: PropTypes.array,
  push: PropTypes.func,
  initDataComponent: PropTypes.func,
  performCreateRequest: PropTypes.func,
  dataComponent: PropTypes.object,
  createdId: PropTypes.string,
  isLoading: PropTypes.bool,
};

export const mapDispatchToProps = {
  populateCountryResources,
  populateStateResources,
  initDataComponent,
  performCreateRequest,
  push,
  uploadFiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCreateContainer);
