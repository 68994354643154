import React, { useContext, forwardRef, useMemo } from "react";
import styled from "styled-components";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import RootRef from "@material-ui/core/RootRef";
import _noop from "lodash/noop";

import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";

export const StyledBWCell = styled(
  ({
    isLoading,
    hasClickHandler,
    wasUpdatedInRevision,
    rowWasCreated,
    rowWasDeleted,
    isDisabledRow,
    setRef,
    ...props
  }) => <Table.Cell {...props} />
)`
  width: ${({ column }) => (column.fill ? "100%" : "auto")};
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${({ column }) =>
    column.name === "gridTableRowMenu" ? "11px" : ""};
  cursor: ${({ hasClickHandler }) => (hasClickHandler ? "pointer" : "unset")};
  ${({ wasUpdatedInRevision, rowWasCreated, rowWasDeleted }) =>
    (wasUpdatedInRevision || rowWasCreated || rowWasDeleted) &&
    `
  font-style: italic;
  font-weight: bold;
  `}

  ${({ column }) =>
    column.fill &&
    `
    max-width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      max-width: inherit;
    }
    `}
  ${({ column: { minWidth } }) => (minWidth ? `min-width: ${minWidth};` : "")}
  ${({ column: { maxWidth } }) => (maxWidth ? `max-width: ${maxWidth};` : "")}
  ${({ column: { whiteSpace } }) =>
    whiteSpace ? `white-space: ${whiteSpace};` : ""}
  ${({ wasUpdatedInRevision }) =>
    wasUpdatedInRevision &&
    `
  &:before {
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    color: #ff9100;
    top: 2px;
    left: 0;
    overflow: visible;
  }
  &:first-child:before {
    left: 16px;
  }
  `}
`;

const BWCell = forwardRef(({ buildBorder, ...props }, ref) => {
  const { isActive, isCreated, isUpdated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);
  const {
    row: { __deleted__, ...row },
    column,
  } = props;

  const additionalProps = useMemo(() => {
    if (!isActive || !row.id) {
      return {};
    }

    const ownReference = `${reference}.${row.id}`;
    return {
      rowWasCreated: isCreated(ownReference),
      rowWasDeleted: __deleted__,
      wasUpdatedInRevision: isUpdated(ownReference, column.name),
    };
  }, [
    isActive,
    isCreated,
    isUpdated,
    reference,
    row.id,
    column.name,
    __deleted__,
  ]);

  if (column.name === "gridTableRowMenu" && __deleted__) {
    return null;
  }
  return (
    <RootRef rootRef={ref || _noop}>
      <StyledBWCell {...props} {...additionalProps} />
    </RootRef>
  );
});

export default BWCell;
