import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import isEmpty from "lodash/isEmpty";

import { usePrevious } from "./usePrevious";

export const useUppy = ({
  accept = null,
  multiple,
  onUpload,
  onUploadComplete,
}) => {
  const acceptAnyFile = isEmpty(accept);
  const uppy = useMemo(
    () =>
      new Uppy({
        restrictions: {
          allowedFileTypes: acceptAnyFile ? null : [...accept.split(",")],
          maxNumberOfFiles: multiple ? null : 1,
        },
      }).use(Dashboard, {
        proudlyDisplayPoweredByUppy: false,
      }),
    [accept, acceptAnyFile, multiple]
  );

  useEffect(() => {
    /**
     * Clears upload event subscription to avoid duplicate calls
     */
    uppy.off("upload");
    uppy.on("upload", () => onUpload(uppy));
  }, [uppy, onUpload]);

  const loaders = useSelector(state => state.files.loaders);
  const uploading = Object.values(loaders).some(loading => loading);
  const prevUploading = usePrevious(uploading);
  useEffect(() => {
    if (prevUploading && !uploading) {
      onUploadComplete(uppy);
    }
  }, [onUploadComplete, prevUploading, uploading, uppy]);

  return uppy;
};
