import React, { useCallback, useState } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { withSelectedRows } from "../../../ui/BWGrid";
import ActionButtons from "../../../forms/ActionButtons";
import SpecsList from "./SpecsList";
import propTypes from "../../../../constants/propTypes";
import { Footer } from "./components";
import { FormikForm } from "../../../forms";

export const AddVendorSpec = ({
  selectedIds,
  bidGroup,
  bidGroupVendor,
  closeModalDialog,
  onUpdateBidGroupVendor,
  selectedSpecs,
  loading,
}) => {
  const bidGroupVendorSpecs = _get(bidGroupVendor, "bidGroupVendorSpecs", []);

  const specs = _get(bidGroup, "specs", []).map(({ id: specId }) => {
    const vendorSpec =
      bidGroupVendorSpecs.find(
        ({ specId: vendorSpecId }) => vendorSpecId === specId
      ) || {};

    return {
      specId,
      deliveryDate: vendorSpec.deliveryDate,
      shipDate: vendorSpec.shipDate,
    };
  });
  const [updatedAttributes, setUpdatedAttributes] = useState(specs);
  const setField = (value, columnName, row) => {
    const attributes = updatedAttributes.map(item => {
      if (item.specId === row.id) {
        return {
          ...item,
          [columnName]: value,
        };
      }
      return item;
    });
    setUpdatedAttributes(attributes);
  };

  const handleSend = useCallback(() => {
    onUpdateBidGroupVendor(selectedIds, updatedAttributes);
  }, [onUpdateBidGroupVendor, selectedIds, updatedAttributes]);

  return (
    <FormikForm initialValues={specs} onSubmit={handleSend}>
      {({ handleSubmit }) => (
        <div>
          <SpecsList
            loading={loading}
            selectedIds={selectedIds}
            selectedSpecs={selectedSpecs}
            bidGroup={bidGroup}
            bidGroupVendor={bidGroupVendor}
            onUpdateBidGroupVendor={onUpdateBidGroupVendor}
            setField={setField}
            updatedAttributes={updatedAttributes}
          />
          <Footer>
            <ActionButtons
              onCancel={closeModalDialog}
              sendButtonText={"Save"}
              disabled={loading}
              loading={loading}
              hideCancelButton={loading}
              onSend={handleSubmit}
            />
          </Footer>
        </div>
      )}
    </FormikForm>
  );
};

AddVendorSpec.propTypes = {
  bidGroup: propTypes.bidGroup,
  onUpdateBidGroupVendor: PropTypes.func,
  closeModalDialog: PropTypes.func,
  selectedSpecs: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  bidGroupVendor: propTypes.bidGroupVendor,
};

export default withSelectedRows(AddVendorSpec);
