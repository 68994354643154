import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { createDeepEqualSelector } from "../../utils/selectors";
import { useDatacomponent } from "./useDatacomponent";
import { getResourcesV2 } from "../../utils/bluechipUtils";

export function useBluechipResources(Model, includes, ids) {
  const selector = useMemo(() => {
    return createSelector(
      state => state.resources,
      createDeepEqualSelector(
        (_, { includes }) => includes,
        includes => includes
      ),
      createDeepEqualSelector(
        (_, { ids }) => ids,
        ids => ids
      ),
      (resources, includes, ids) => {
        const recovered = Model.query(resources)
          .includes(includes)
          .where({ id: ids })
          .toObjects();
        return Array.isArray(ids) ? recovered : recovered[0];
      }
    );
  }, [Model]);

  return useSelector(state => selector(state, { includes, ids }));
}

export function useV2DatacomponentResources(
  dataComponentId,
  defaultValue,
  requestType
) {
  const dataComponent = useDatacomponent(dataComponentId);
  return useSelector(state =>
    getResourcesV2(dataComponent, state, requestType, defaultValue)
  );
}
