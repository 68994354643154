import { connect } from "react-redux";

import ProjectOverview, {
  dataComponentId,
  fundingsDataComponentId,
  summaryDataComponentId,
} from "./ProjectOverview";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../../actions/dataComponentActions";

export const mapStateToProps = (state, ownProps) => {
  const { userId } = state.auth;
  const projectId = ownProps.match.params.projectId;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const summaryDataComponent = getDataComponentFlattenedRequestState(
    summaryDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const fundingsDataComponent = getDataComponentFlattenedRequestState(
    fundingsDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    projectId,
    dataComponent,
    project:
      getBluechipResourceById(dataComponent, state, projectId) || undefined,
    projectSummary:
      getBluechipResourceById(summaryDataComponent, state, projectId) ||
      undefined,
    projectFundings:
      getBluechipResourceById(fundingsDataComponent, state, projectId) ||
      undefined,
    currentUserId: userId,
    loading: [summaryDataComponent, fundingsDataComponent, dataComponent].some(
      ({ loading }) => loading
    ),
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);
