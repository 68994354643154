import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BWModels from "benjaminwest-models";

import PropertyForm from "../PropertyCreate/PropertyForm";
import {
  initDataComponent,
  performUpdateRequest,
  performFindRequest,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";
import {
  setHeaderTitle,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import Property from "../../../models/Property";
import propTypes from "../../../constants/propTypes";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import _get from "lodash/get";

const dataComponentId = "PropertyEdit";

const defaultLocation = {
  address: null,
  country: null,
  state: null,
  stateCode: null,
  zip: null,
  city: null,
};

export class PropertyEditContainer extends Component {
  componentDidMount() {
    const {
      setAutoSaveComponentId,
      initDataComponent,
      performFindRequest,
      propertyId,
      property,
      setHeaderTitle,
    } = this.props;
    initDataComponent(dataComponentId, Property, ["location"], "properties");
    setAutoSaveComponentId(dataComponentId);

    performFindRequest(dataComponentId, propertyId);
    if (property.id) {
      setHeaderTitle(`Property Settings - #${property.id} ${property.name}`);
    }
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
  }

  componentDidUpdate({
    dataComponent: prevDataComponent,
    property: prevProperty,
  }) {
    const { dataComponent, property, setHeaderTitle } = this.props;

    if (prevProperty.id !== property.id) {
      setHeaderTitle(`Property Settings - #${property.id} ${property.name}`);
    }

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  updateProperty = (property, formikActions) => {
    const { performUpdateRequest, propertyId } = this.props;
    const updatedProperty = { ...property };
    if (updatedProperty.location) {
      updatedProperty.location = {
        ...defaultLocation,
        ...property.location,
      };
    }
    performUpdateRequest(dataComponentId, propertyId, updatedProperty);
    this.setState({ formikActions });
  };

  render() {
    const { property, clientId, loading, dataComponent } = this.props;
    const propertySchema = BWModels.loadSchema("Property");
    return (
      <PropertyForm
        autoSave
        isUpdate
        clientId={clientId}
        initialValues={property}
        propertySchema={propertySchema}
        onSave={this.updateProperty}
        loading={loading}
        dataComponentId={dataComponent.dataComponentId}
      />
    );
  }
}

PropertyEditContainer.propTypes = {
  property: propTypes.property,
  push: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  propertyId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  setHeaderTitle: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func,
  loading: PropTypes.bool,
};

export const mapStateToProps = dataComponentId => (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const { propertyId, clientId } = ownProps.match.params;
  const property =
    getBluechipResourceById(dataComponent, state, propertyId) || {};
  return {
    propertyId,
    property,
    clientId,
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading", false),
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performUpdateRequest,
  performFindRequest,
  performRetrieveListRequest,
  setHeaderTitle,
  setAutoSaveComponentId,
  push,
};

export default withRouter(
  connect(
    mapStateToProps(dataComponentId),
    mapDispatchToProps
  )(PropertyEditContainer)
);
