import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RemoveIcon from "@material-ui/icons/Remove";

import propTypes from "../../../../constants/propTypes";
export const MissingDateIcon = styled(RemoveIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
`;

const OptionalColumn = ({ children, customMissing }) =>
  children ? children : customMissing || <MissingDateIcon />;

OptionalColumn.propTypes = {
  children: propTypes.component,
  customMissing: PropTypes.string,
};

export default OptionalColumn;
