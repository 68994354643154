import React from "react";
import {
  CustomCell,
  Table,
  CustomFilterCell,
  CustomHeadCell,
} from "./components";

export const getTableComponents = (isCOMVisible, isSampleColumnsVisible) => {
  const CustomCellWrapper = props => (
    <CustomCell
      {...props}
      isCOMVisible={isCOMVisible}
      isSampleColumnsVisible={isSampleColumnsVisible}
    />
  );

  return {
    CellComponent: CustomCellWrapper,
    TableComponent: Table,
    FilterCellComponent: CustomFilterCell,
    HeaderCellComponent: CustomHeadCell,
    PagingPanelTemplate: () => null,
  };
};

export const getColumnExtensions = () => () => {
  return [
    { columnName: "indicator", sortingEnabled: false },
    { columnName: "description", sortingEnabled: false },
    { columnName: "customNumber", fill: true, sortingEnabled: false },
    { columnName: "totalSampleQuantity", sortingEnabled: false },
    { columnName: "totalProductionQuantity", sortingEnabled: false },
    {
      columnName: "emptyColumn",
      align: "center",
      width: "600px",
      sortingEnabled: false,
    },
  ];
};

export const fixedColumns = {
  left: [
    "indicator",
    "description",
    "customNumber",
    "totalSampleQuantity",
    "totalProductionQuantity",
  ],
};

export getColumnBands, { getVendorName } from "./columnBands";

export getColumns from "./columns";

export getProviders from "./dataTypeProviders";

export getFilteringProviders from "./filteringDataTypeProviders";
