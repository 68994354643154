import PropTypes from "prop-types";
import React from "react";

import propTypes from "../../../../../../constants/propTypes";
import Loader from "../../../../../ui/Loader";
import FilePreview from "./FilePreview";

const COMPreview = ({ specDetails = [], height = "60px", width = "60px" }) => {
  const preview = specDetails.find(detail => detail.type === "Preview");

  return (
    <Loader height={height} width={width} inverted={true}>
      {preview ? (
        <FilePreview preview={preview} height={height} width={width} />
      ) : null}
    </Loader>
  );
};

COMPreview.propTypes = {
  specDetails: PropTypes.arrayOf(propTypes.specDetail),
  height: PropTypes.string,
  width: PropTypes.string,
};

export default COMPreview;
