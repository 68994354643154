import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performDeleteRequest,
} from "../../../../actions/dataComponentActions";
import BidGroupVendor from "../../../../models/BidGroupVendor";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

export const dataComponentId = "DeleteBidGroupVendor";

export class DeleteBidGroupVendorContainer extends Component {
  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, BidGroupVendor, [], "bid-group-vendors");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog, onSuccess } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        onSuccess && onSuccess();
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteVendorContact = () => {
    const { id, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, id);
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation title={title} onDelete={this.deleteVendorContact} />
    );
  }
}

DeleteBidGroupVendorContainer.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  onSuccess: PropTypes.func,
  performDeleteRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteBidGroupVendorContainer);
