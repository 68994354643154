import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ContingencyForm from "./ContingencyForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import { cleanUpBody } from "../../../../../actions/dataComponentActions";
import propTypes from "../../../../../constants/propTypes";

const CreateEditContingencyContainer = ({
  formikArrayHelpers,
  closeModalDialog,
  contingency,
  index,
}) => {
  const handleCreateContingency = ({ value, ...contingency }) => {
    formikArrayHelpers.push({ ...contingency, value: Number(value) });
    closeModalDialog();
  };

  const handleEditContingency = ({ value, ...contingency }) => {
    formikArrayHelpers.replace(index, {
      ...cleanUpBody(contingency),
      value: Number(value),
    });
    closeModalDialog();
  };

  const isEditing = typeof index !== "undefined";
  return (
    <ContingencyForm
      onSubmitContingency={
        isEditing ? handleEditContingency : handleCreateContingency
      }
      isEditing={isEditing}
      contingency={contingency}
    />
  );
};
CreateEditContingencyContainer.propTypes = {
  closeModalDialog: PropTypes.func,
  formikArrayHelpers: PropTypes.shape({}),
  contingency: propTypes.contingency,
  index: PropTypes.number,
};

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateEditContingencyContainer);
