import { BaseModel } from "@blue-chip/core";
import Project from "./Project";

export default class ShippingPayment extends BaseModel {
  static get tableName() {
    return "shipping_payments";
  }

  static get belongsTo() {
    return [Project];
  }
}
