import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import UserSettingsForm from "./UserSettingsForm";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";

const dataComponentId = "UsersGrid";

export class UserSettingsContainer extends Component {
  state = {};

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload("UsersGrid", true);
      },
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  saveUser = (
    { name, hasUnreadNotes, __isClicked__, __expanded__, ...data },
    formikActions
  ) => {
    const { performUpdateRequest, user } = this.props;
    performUpdateRequest(dataComponentId, user.id, data);
    this.setState({ formikActions });
  };

  render() {
    const { user, office, userRole, role, userRoles } = this.props;
    return (
      <UserSettingsForm
        user={user}
        office={office}
        userRole={userRole}
        saveUser={this.saveUser}
        dataComponentId={dataComponentId}
        role={role}
        userRoles={userRoles}
      />
    );
  }
}

UserSettingsContainer.propTypes = {
  loading: PropTypes.bool,
  user: propTypes.user.isRequired,
  office: propTypes.office,
  userRole: propTypes.userRole.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object.isRequired,
  role: propTypes.userRole,
  userRoles: PropTypes.array,
};

const mapStateToProps = state => {
  const {
    auth: { role },
  } = state;
  const dataComponent = getDataComponent(dataComponentId, state);

  const userRoleDataComponent = getDataComponentFlattenedRequestState(
    "select-userRoles",
    state
  );

  return {
    dataComponent,
    role,
    userRoles: getResourcesV2(userRoleDataComponent, state),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsContainer);
