import React, { memo } from "react";
import PropTypes from "prop-types";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

export const VendorAction = ({ options }) => {
  return <DropdownMenu buttonText="Actions" options={options} />;
};

VendorAction.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      content: PropTypes.string,
    })
  ),
};

export default memo(VendorAction);
