import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../../../../forms/ActionButtons";
import RegularText from "../../../../ui/Typography/RegularText";

const Message = styled(RegularText)`
  padding: 24px;
`;

const ArchiveConfirmation = ({ count, onSubmit, option }) => (
  <Fragment>
    <Message>
      Are you sure you’d like to {option} {count} records?
    </Message>
    <ActionButtons
      onDanger={onSubmit}
      dangerButtonText={option}
      isModal
      emphasizeCancel
    />
  </Fragment>
);

ArchiveConfirmation.propTypes = {
  count: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  option: PropTypes.string,
};

export default ArchiveConfirmation;
