import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import InvalidItems from "../editableControls/InvalidItems";
import { Actions } from "./Actions";

const Wrapper = styled.div`
  display: flex;
`;

export const GridMenu = ({ setDisplayUpdateSpecs }) => {
  return (
    <Wrapper>
      <Actions setDisplayUpdateSpecs={setDisplayUpdateSpecs} />
      <InvalidItems />
    </Wrapper>
  );
};
GridMenu.propTypes = {
  setDisplayUpdateSpecs: PropTypes.func.isRequired,
};
