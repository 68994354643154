import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { detailsForm } from "../ComFormFields";
import FormGrid from "../../../../../forms/FormGrid";

import propTypes from "../../../../../../constants/propTypes";
import TopDetails from "./TopDetails";
import ActionButtons from "../../../../../forms/ActionButtons";
import {
  handleBaseQuantityChange,
  handleOverageChange,
} from "../../../../Specs/SpecFormikForm";
import {
  calculateOverageQuantity,
  calculateTotalQuantity,
} from "../../../../Specs/calculations";

export const handleAtticStockChange = (
  event,
  setFieldValue,
  setAtticStock,
  baseQuantity,
  overagePercent
) => {
  const value = parseFloat(event.target.value);

  if (isNaN(value)) {
    setAtticStock(0);

    setFieldValue("atticStock", null);
  }
  setAtticStock(value);

  const overageValue = calculateOverageQuantity(
    overagePercent,
    baseQuantity,
    value
  );

  setFieldValue("overageValue", overageValue);

  setFieldValue(
    "totalQuantity",
    calculateTotalQuantity(baseQuantity, value, overageValue)
  );
};

const ComDetailSection = ({
  itemSelected,
  loading,
  handleSubmit,
  handleCancel,
  setFieldValue,
  projectCurrencies,
  values,
  projectId,
}) => {
  const [baseQuantity, setBaseQuantity] = useState(
    itemSelected.baseQuantity || 0
  );
  const [atticStock, setAtticStock] = useState(itemSelected.atticStock || 0);
  const [overagePercent, setOveragePercent] = useState(
    itemSelected.overagePercent / 100 || 0
  );

  const selectedProjectCurrency = projectCurrencies.find(
    projectCurrency => projectCurrency.id == values.projectCurrencyId
  );

  return (
    <Fragment>
      <TopDetails loading={loading} itemSelected={itemSelected || {}} />
      <FormGrid
        fields={detailsForm(
          handleBaseQuantityChange(
            setBaseQuantity,
            setFieldValue,
            overagePercent,
            atticStock
          ),
          handleOverageChange(
            setOveragePercent,
            setFieldValue,
            baseQuantity,
            atticStock
          ),
          event =>
            handleAtticStockChange(
              event,
              setFieldValue,
              setAtticStock,
              baseQuantity,
              overagePercent
            ),
          selectedProjectCurrency?.currency,
          projectId
        )}
      />
      <ActionButtons
        isModal={true}
        onSend={handleSubmit}
        sendButtonText="Save"
        cancelButtonText="Cancel"
        onCancel={handleCancel}
        listeners={["ComSpec"]}
      />
    </Fragment>
  );
};

ComDetailSection.propTypes = {
  loading: PropTypes.bool,
  itemSelected: propTypes.spec,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  setFieldValue: PropTypes.func,
  projectCurrencies: PropTypes.array,
  values: PropTypes.object,
  projectId: PropTypes.string,
};

export default ComDetailSection;
