import React, { useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import FormGrid from "../../../forms/FormGrid";
import ActionButtons from "../../../forms/ActionButtons";
import { inputs } from "../../../inputs/inputConfigs";
import Subheader from "../../../ui/Typography/Subheader";
import propTypes from "../../../../constants/propTypes";
import { dataComponentId } from "../EditSpecDetailDescription/EditSpecDetailDescriptionContainer";

const Wrapper = styled.div`
  height: 60vh;
  overflow: auto;
  margin-bottom: 24px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
`;

const PlaceHolder = styled(Subheader)`
  color: #979797;
`;

const SpecDetailsForm = ({ details, onSubmit }) => {
  const fields = useMemo(
    () => ({
      groups: details.map((detail, index) => ({
        items: [
          {
            element: <PlaceHolder>{index + 1}</PlaceHolder>,
            grid: { xs: 1 },
          },
          {
            input: {
              ...inputs.title,
              name: `details[${index}].title`,
              disabled: detail.locked,
            },
            grid: { xs: 11 },
          },
          {
            element: <PlaceHolder />,
            grid: { xs: 1 },
          },
          {
            input: {
              ...inputs.description,
              name: `details[${index}].description`,
              rows: 2,
            },
            grid: { xs: 11 },
          },
        ],
      })),
    }),
    [details]
  );

  return (
    <Fragment>
      <Wrapper>
        <FormGrid fields={fields} />
      </Wrapper>
      <ActionButtons
        listeners={[dataComponentId]}
        sendButtonText="UPDATE DESCRIPTIONS"
        onSend={onSubmit}
        isModal={true}
      />
    </Fragment>
  );
};

SpecDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  details: PropTypes.arrayOf(propTypes.specDetail).isRequired,
};

export default SpecDetailsForm;
