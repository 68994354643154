import React from "react";
import styled from "styled-components";
import _get from "lodash/get";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { Content, PagingGridRow } from "../../../../../ui/BWGrid/GridRow";
import ActionButtons from "../../../../../forms/ActionButtons";
import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import { useFlattenedDatacomponent } from "../../../../../hooks/useDatacomponent";
import {
  specsGridDataComponentId,
  specsSelectionGridId,
} from "../../../../../../actions/purchaseOrderSpecsActions";

const Row = styled(PagingGridRow)`
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default (submitTextFn, onSubmit) => {
  const PagingPaneWithButtons = () => {
    const stateActions = useAddSpecToPOStore(state => state.actions);
    const srcDataComponent = useFlattenedDatacomponent(specsSelectionGridId);
    const selected = _get(srcDataComponent, "selectedIds.length", 0);

    const handleSubmit = () => onSubmit(srcDataComponent.selectedIds);

    return (
      <Plugin name="GridRowPlugin">
        <Template name="footer">
          <Row>
            <Content>
              <ActionButtons
                listeners={[specsGridDataComponentId]}
                additionalProps={{ send: { disabled: selected === 0 } }}
                onCancel={stateActions.openMainOptions}
                sendButtonText={submitTextFn(selected)}
                onSend={handleSubmit}
              />
            </Content>
            <TemplatePlaceholder name="pagingPanel" />
          </Row>
        </Template>
      </Plugin>
    );
  };

  PagingPaneWithButtons.propTypes = {};

  return PagingPaneWithButtons;
};
