import React, { useCallback } from "react";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";
import useActions from "../../../hooks/useActions";
import { openModalDialog } from "../../../../actions/layoutActions";

export const Actions = () => {
  const actions = useActions({ openModalDialog });

  const handleExport = useCallback(() => {
    actions.openModalDialog(
      "Export Shipping Payments",
      "ExportShippingPaymentsModal",
      {},
      true,
      true
    );
  }, [actions]);

  const options = [
    {
      content: "Export",
      onClick: handleExport,
    },
  ];
  return <DropdownMenu buttonText="Actions" options={options} />;
};
Actions.propTypes = {};
