import React from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Description,
  InfoWrapper,
  PreviewImageWrapper,
} from "../../../../SpecDetail/SpecDetailPreview";
import SpecDetailTitle from "../../../../SpecDetail/SpecDetailTitle";
import Loader from "../../../../../ui/Loader";
import Subheader from "../../../../../ui/Typography/Subheader";

export const SpecDetailPreview = styled.div`
  padding: 12px 24px 0 22px;
  display: flex;
`;

const PlaceHolder = styled(Subheader)`
  color: #979797;
  padding-right: 36px;
`;

const PreviewComponent = ({ specDetail, index }) => {
  const { title, preview } = specDetail;
  const description = _get(preview, "filename", "-");
  return (
    <SpecDetailPreview>
      <PlaceHolder>{index + 1}</PlaceHolder>
      <PreviewImageWrapper>
        <Loader>
          <img src={preview} alt={description} />
        </Loader>
      </PreviewImageWrapper>
      <InfoWrapper>
        <SpecDetailTitle>{title ? title : "-"}</SpecDetailTitle>
        <Description>{description ? description : "-"}</Description>
      </InfoWrapper>
    </SpecDetailPreview>
  );
};
PreviewComponent.propTypes = {
  specDetail: PropTypes.shape({}),
  index: PropTypes.number,
};

export default PreviewComponent;
