import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import LabelControl from "./LabelControl";
import { formatVendorsToSpecRows } from "../analysisFunctions";
import propTypes from "../../../../../constants/propTypes";
import { getExpandedSpecsAndCOMItems } from "../AnalysisPage";
import { DisplayVendorContext } from "./withDisplayVendorControl";

export const ShowHideControl = ({
  location: { pathname },
  match: {
    params: { projectId, bidGroupId },
  },
  generateAndDownloadReport,
  bidGroup,
  bidGroupVendors,
  paymentTerms,
}) => {
  const { isBudgetSectionVisible } = useContext(DisplayVendorContext);

  if (!/analysis$/.test(pathname)) return <div />;

  const exportAnalysis = () => {
    const { specs = [] } = bidGroup;
    const { expandedSpecs, comItems } = getExpandedSpecsAndCOMItems(specs);

    const isSampleColumnsVisible = !!bidGroup.enableSampleQuantity;
    const isCOMVisible = !isEmpty(comItems);

    const analysisData = formatVendorsToSpecRows(
      expandedSpecs,
      bidGroupVendors,
      paymentTerms,
      comItems,
      [],
      undefined,
      undefined,
      isCOMVisible,
      isSampleColumnsVisible
    );

    generateAndDownloadReport({
      format: "xls",
      templateName: "bid-group-analysis",
      analysisData: {
        isBudgetSectionVisible: !!isBudgetSectionVisible,
        ...analysisData,
      },
      projectId,
      bidGroupId,
      bidGroupVendorIds: bidGroupVendors.map(({ id }) => id),
    });
  };
  return <LabelControl exportAnalysis={exportAnalysis} />;
};

ShowHideControl.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
  match: PropTypes.shape({
    params: PropTypes.shape({ projectId: PropTypes.string }),
  }),
  bidGroup: propTypes.bidGroupVendor,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  paymentTerms: PropTypes.arrayOf(propTypes.paymentTerm),
  generateAndDownloadReport: PropTypes.func,
};

export default withRouter(ShowHideControl);
