import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { RawHeader } from "../../../layout/SectionHeader";
import { GridRow } from "../index";

export const RawGridHeader = ({ className, ...props }) => {
  return (
    <GridRow className={className}>
      <RawHeader {...props} />
    </GridRow>
  );
};

RawGridHeader.propTypes = {
  headerOverride: PropTypes.element,
  headerText: PropTypes.string,
  subheaderText: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.node,
      handler: PropTypes.func,
    })
  ),
  className: PropTypes.string,
};

const GridHeader = styled(RawGridHeader)({});

GridHeader.isGridRow = true;

export const useHeaderComponent = ({
  headerOverride,
  headerText,
  subheaderText,
  secondaryText,
  actions,
  children,
  isMultiline,
}) => {
  return useMemo(() => {
    const Header = () => {
      return (
        <GridHeader
          headerText={headerText}
          isMultiline={isMultiline}
          actions={actions}
          headerOverride={headerOverride}
          subheaderText={subheaderText}
          secondaryText={secondaryText}
        >
          {children}
        </GridHeader>
      );
    };
    Header.propTypes = {};
    return Header;
  }, [
    headerText,
    isMultiline,
    actions,
    headerOverride,
    subheaderText,
    secondaryText,
    children,
  ]);
};

export default GridHeader;
