import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import _endsWith from "lodash/endsWith";
import _get from "lodash/get";

import propTypes from "../../../../../constants/propTypes";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import InputTrigger from "../../../../../custom/react-input-trigger";
import User from "../../../../../models/User";
import MentionMenu from "./MentionMenu";
import mentionHandler from "./mentionHandler";
import { escapeStringRegexp } from "../../../../../utils/stringUtils";

const getMenuPosition = (ref, { selectionEnd, selectionStart, left, top }) => {
  const HTMLELementPosition = ref.getBoundingClientRect();
  const parent = ref.closest(".add-note-paper");
  return {
    left: left + HTMLELementPosition.left - 20 - _get(parent, "offsetLeft", 0),
    top: top + HTMLELementPosition.top - _get(parent, "offsetTop", 0),
    endPoint: selectionEnd,
    startPoint: selectionStart,
  };
};

const closeKeyCodes = [27, 32];
const InputWithMention = ({
  initDataComponent,
  performRetrieveListRequest,
  users: allUsers,
  formik,
  usersDataComponentId,
  ...props
}) => {
  const [cursorPosition, setCursorPosition] = useState({});
  const [endTrigger, setEndTrigger] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const ref = useRef(null);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState(allUsers);

  useEffect(() => {
    initDataComponent(usersDataComponentId, User, [], "users");
    performRetrieveListRequest(usersDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fields: ["id", "firstName", "lastName", "name"],
    });
  }, [initDataComponent, performRetrieveListRequest, usersDataComponentId]);

  useEffect(() => {
    const filteredUsers = allUsers.filter(user =>
      new RegExp(escapeStringRegexp(query), "i").test(user.name)
    );
    setUsers(filteredUsers);
  }, [query, allUsers]);

  const startMention = ({ cursor }) => {
    const element = ref.current;
    if (mentionHandler.isValidTriggerEntry(cursor, element)) {
      setCursorPosition(getMenuPosition(element, cursor));
      setOpenMenu(true);
    }
  };

  const handleType = ({ text = "", cursor: { selectionEnd } }) => {
    const showCloseSelect = !text && cursorPosition.endPoint !== selectionEnd;
    if (showCloseSelect) return closeSelect();
    if (_endsWith(text, " ")) return;

    setQuery(text);
    setCursorPosition({
      ...cursorPosition,
      endPoint: selectionEnd,
    });
  };

  const handleSelectOption = user => {
    const element = ref.current;
    const text = mentionHandler.parseTextWithMention(
      cursorPosition,
      element,
      user
    );

    /*
     * handleSelectionOption and handleType callbacks are running in parallel and it causing that query
     * set some character from the selected user. I wait here 10 ms until endTrigger is performed and
     * then modify the formik values in order to avoid this behavior.
     * */
    setTimeout(() => {
      formik.setValues({
        ...formik.values,
        detail: text,
        mentions: [...formik.values.mentions, user],
      });
    }, 10);

    closeSelect();
    element.focus();
  };

  const closeSelect = () => {
    endTrigger();
    setOpenMenu(false);
    setQuery("");
  };

  const handleEndTrigger = endTriggerHandler =>
    setEndTrigger(() => endTriggerHandler);

  const inputProps = {
    onKeyDown: evt => {
      if (closeKeyCodes.includes(evt.keyCode)) closeSelect();
    },
  };

  return (
    <Fragment>
      <InputTrigger
        trigger={{
          keyCode: 50,
          shiftKey: true,
          key: "@",
        }}
        onStart={startMention}
        onType={handleType}
        onCancel={closeSelect}
        inputRef={ref}
        endTrigger={handleEndTrigger}
      >
        <TextInputWithError {...props} inputRef={ref} inputProps={inputProps} />
      </InputTrigger>

      <MentionMenu
        openMenu={openMenu}
        handleClose={() => setOpenMenu(false)}
        onSelectOption={handleSelectOption}
        top={cursorPosition.top}
        left={cursorPosition.left}
        users={users}
        anchorEl={ref.current}
      />
    </Fragment>
  );
};

InputWithMention.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(propTypes.user),
  formik: propTypes.formik,
  usersDataComponentId: PropTypes.string.isRequired,
};

InputWithMention.defaultProps = {
  users: [],
};

export default InputWithMention;
