import { useEffect, useContext } from "react";
import _isEqual from "lodash/isEqual";

import { checkBackPageEffect, getSort } from "../../BWGrid/gridAPIFunctions";
import { LoaderContext } from "../../Loader";

export const useInitGridStatus = (
  { initDataComponent, setSelection, setPageSize },
  { setShouldReload },
  { reload, pageSize }
) => {
  useEffect(() => {
    initDataComponent();
  }, [initDataComponent]);

  useEffect(() => {
    if (reload) {
      setSelection({});
      setShouldReload(true);
    }
  }, [setShouldReload, reload, setSelection]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);
};

export const useReloadEffects = (
  { setSelection },
  { setShouldReload, onRetrieveList, onSetPage, onSetSort },
  {
    shouldReload,
    rows,
    selectedFilters,
    defaultSorting,
    apiFilters,
    pageNumber,
    prevReferences,
    context,
    dataComponent,
  }
) => {
  const { loading } = useContext(LoaderContext);

  useEffect(() => {
    if (shouldReload) {
      onRetrieveList(apiFilters, selectedFilters);
    }
  }, [apiFilters, selectedFilters, shouldReload, onRetrieveList]);

  useEffect(checkBackPageEffect(loading, rows, pageNumber, onSetPage), [
    loading,
    rows,
    pageNumber,
    onSetPage,
  ]);

  useEffect(() => {
    const prevAPIFilters = prevReferences.current.apiFilters;
    if (apiFilters !== prevAPIFilters && prevAPIFilters) {
      prevReferences.current.apiFilters = apiFilters;
      onSetPage(0);
      setShouldReload(true);
      setSelection({});
    }
  }, [setShouldReload, apiFilters, prevReferences, onSetPage, setSelection]);

  useEffect(() => {
    const prevSelectedFilters = prevReferences.current.selectedFilters;
    if (
      !_isEqual(selectedFilters, prevSelectedFilters) &&
      prevSelectedFilters
    ) {
      prevReferences.current.selectedFilters = selectedFilters;
      setShouldReload(true);
    }
  }, [prevReferences, selectedFilters, setShouldReload]);

  useEffect(() => {
    if (
      !getSort(context, dataComponent)() &&
      prevReferences.current.defaultSorting !== defaultSorting
    ) {
      prevReferences.current.defaultSorting = defaultSorting;
      onSetSort(defaultSorting);
    }
  }, [defaultSorting, prevReferences, onSetSort, context, dataComponent]);
};
