import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import { setReload } from "../../../../../../actions/dataComponentActions";

import RegularText from "../../../../../ui/Typography/RegularText";
import ActionButtons from "../../../../../forms/ActionButtons";
import propTypes from "../../../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../../../actions/layoutActions";
import { processUpdateRequestStatus } from "../../../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";

export const Message = styled(RegularText)`
  padding: 24px;
`;

export class VoidFeeContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponentId,
      dataComponent,
      closeModalDialog,
      setReload,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(dataComponentId, true);
      },
    });
  }

  handleVoidFee = () => {
    const { onVoidFee, feeId } = this.props;
    onVoidFee({ id: feeId });
  };

  render() {
    const { title } = this.props;
    return (
      <Fragment>
        <Message>Are you sure you want to Void Fee {title}?</Message>
        <ActionButtons
          onDanger={this.handleVoidFee}
          dangerButtonText="Void Fee"
          cancelButtonText="Dismiss"
          isModal
          emphasizeCancel
        />
      </Fragment>
    );
  }
}

VoidFeeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  feeId: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  onVoidFee: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  setReload,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidFeeContainer);
