import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";

import VendorAction from "./VendorAction";
import {
  BWGridLocal,
  GridHeader,
  GridTable,
  withSelectedRows,
} from "../../../ui/BWGrid";
import { ItemRowWrapper } from "../components";
import propTypes from "../../../../constants/propTypes";
import { renderSelectCell } from "../../../ui/SelectCell";
import { ItemRow } from "./BidGroupVendorsContactsItemRow";
import { SpecItemRow } from "./BidGroupVendorsSpecItemRow";
import EditColumn from "./EditColumn";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";
import { WithSelectedRowsContext } from "../../../ui/BWGrid/withSelectedRows";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const onRemoveVendorSuccess = (setGridRows, gridRows) => row => () => {
  const updatedGridRows = gridRows.filter(gridRow => gridRow.id != row.id);
  setGridRows(updatedGridRows);
};

export const rfqSentStatusContent = {
  "RFQ Sent": "Mark RFQ Not Sent",
  "RFQ Not Sent": "Mark RFQ Sent",
};
const quoteReceivedStatusContent = {
  "Quote Received": "Mark Quote Not Received",
  "Quote Not Received": "Mark Quote Received",
};
export const getMarkBGVStatusAction = (
  bidGroupVendor,
  column,
  updateBidGroupVendor,
  onOpenEditDate,
  statusContent,
  nonEmptyLabel,
  emptyLabel
) => {
  const status = bidGroupVendor[column] ? nonEmptyLabel : emptyLabel;
  return {
    text: statusContent[status],
    content: statusContent[status],
    onClick: () => {
      if (status === nonEmptyLabel) {
        return updateBidGroupVendor(bidGroupVendor.id, {
          [column]: null,
        });
      }
      onOpenEditDate(
        column,
        `${nonEmptyLabel} Date`,
        [bidGroupVendor.id],
        bidGroupVendor.vendor.name,
        { [column]: new Date() },
        true
      )();
    },
    disableIfProjectClosed: true,
  };
};

export const getRowMenu = (
  onDownloadPDF,
  onOpenDeleteModal,
  onOpenPreviewEmail,
  onRemoveVendorSuccess,
  updateBidGroupVendor,
  onOpenEditDate
) => row => {
  return [
    { text: "Preview RFQ Email", onClick: () => onOpenPreviewEmail(row) },
    { text: "Export RFQ as PDF", onClick: () => onDownloadPDF(row) },
    getMarkBGVStatusAction(
      row,
      "rfqSentAt",
      updateBidGroupVendor,
      onOpenEditDate,
      rfqSentStatusContent,
      "RFQ Sent",
      "RFQ Not Sent"
    ),
    getMarkBGVStatusAction(
      row,
      "quoteReceivedAt",
      updateBidGroupVendor,
      onOpenEditDate,
      quoteReceivedStatusContent,
      "Quote Received",
      "Quote Not Received"
    ),
    ...(row.bidComplete
      ? [
          {
            text: "Allow Resubmit",
            onClick: () =>
              updateBidGroupVendor(row.id, {
                bidComplete: null,
              }),
          },
        ]
      : []),
    {
      text: "Remove Vendor",
      onClick: () => onOpenDeleteModal(row, onRemoveVendorSuccess(row)),
      disableIfProjectClosed: true,
    },
  ];
};
export const getUniqRelationFromVendors = (
  uniqId,
  relation
) => bidGroupVendors => {
  const filtered = _uniqBy(bidGroupVendors, uniqId);
  return filtered.reduce((result, vendor) => {
    const item = vendor[relation];
    if (!item) return result;
    return [...result, item];
  }, []);
};

const columns = [
  "vendor.name",
  "vendor.categoryId",
  "bidType",
  "selectedContacts",
  "items",
  "shipDate",
  "deliveryDate",
  "rfqSentAt",
  "quoteReceivedAt",
];

export const getSelectedContacts = (row, handleClick) => {
  const options = row.contacts
    ? row.contacts.map(({ contact }) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`,
      }))
    : [];

  const renderValue = selectedIds => {
    const selectedItems = selectedIds.map(selectedId =>
      options.find(({ id }) => id === selectedId)
    );
    // eslint-disable-next-line react/jsx-no-undef
    return (
      <ItemRowWrapper onClick={handleClick}>
        {selectedItems.length > 0 ? (
          <React.Fragment>{`${pluralize(
            "Contact",
            selectedIds.length,
            true
          )}`}</React.Fragment>
        ) : (
          "Select Contacts"
        )}
      </ItemRowWrapper>
    );
  };

  return renderSelectCell(row, options, {
    multiple: true,
    name: "selectedContacts",
    renderValue,
  });
};

export const getDateLabel = (bidGroupVendor, columnName, isProjectClosed) => {
  if (!bidGroupVendor[columnName] && isProjectClosed) return "-";
  if (!bidGroupVendor[columnName]) return "Select Date";
  return moment(bidGroupVendor[columnName]).format(PRIMARY_DATE_FORMAT);
};

const getDateColumnOption = (title, property) => ({
  title,
  filter: false,
  sortingEnabled: false,
  render: row =>
    row[property] ? moment(row[property]).format(PRIMARY_DATE_FORMAT) : null,
});

export const getColumnOptions = ({
  categories,
  bidGroup,
  onOpenSelectVendorContacts,
  onOpenAddVendorSpec,
  getBidTypes,
  handleChange,
  onOpenEditDate,
  isProjectClosed,
}) => ({
  "vendor.name": {
    title: "Vendor Name",
    filter: true,
    bold: true,
  },
  "vendor.categoryId": {
    render: row => row && _get(row, "vendor.category.name"),
    title: "Category",
    filter: "select",
    filterOptions: {
      options: categories,
    },
  },
  shipDate: {
    render: row =>
      row && (
        <EditColumn
          onClick={() => {
            onOpenEditDate("shipDate", "Ship Date", [row.id], row.vendor.name, {
              shipDate: row.shipDate,
            })();
          }}
          label={getDateLabel(row, "shipDate", isProjectClosed)}
        />
      ),
    title: "Ship Date",
    filter: false,
    sortingEnabled: false,
  },
  deliveryDate: {
    render: row =>
      row && (
        <EditColumn
          onClick={() =>
            onOpenEditDate(
              "deliveryDate",
              "Delivery Date",
              [row.id],
              row.vendor.name,
              { deliveryDate: row.deliveryDate }
            )()
          }
          label={getDateLabel(row, "deliveryDate", isProjectClosed)}
        />
      ),
    title: "Delivery Date",
    filter: false,
    sortingEnabled: false,
  },
  rfqSentAt: getDateColumnOption("RFQ Sent", "rfqSentAt"),
  quoteReceivedAt: getDateColumnOption("Quote Received", "quoteReceivedAt"),
  bidType: {
    filter: "select",
    filterOptions: {
      options: getBidTypes(),
    },
    title: "Attributes",
    render: row =>
      renderSelectCell(row, getBidTypes(), {
        name: "bidType",
        onChange: handleChange(row),
        isProjectClosed,
      }),
  },
  selectedContacts: {
    filter: false,
    title: "Contacts",
    render: row => {
      return (
        row && (
          <ItemRow
            row={row}
            contacts={row.vendor && row.vendor.contacts}
            onOpenSelectVendorContact={onOpenSelectVendorContacts}
          />
        )
      );
    },
  },
  items: {
    filter: false,
    sortingEnabled: false,
    render: row =>
      row && (
        <SpecItemRow
          row={row}
          specs={bidGroup.specs}
          onOpenAddVendorSpec={onOpenAddVendorSpec}
        />
      ),
  },
});

const sorting = [{ columnName: "vendor.name", direction: "asc" }];

export const getTableComponents = gridRows => {
  if (gridRows.length === 0) {
    return {
      TableFilterRow: () => null,
      HeaderRowComponent: () => () => null,
      BodyComponent: () => null,
    };
  }
  return {};
};

const parseValue = value => {
  return Array.isArray(value) ? JSON.stringify(value) : value;
};

export const performChange = (
  gridRows,
  setGridRows,
  patchItem,
  row,
  value,
  name
) => {
  const updatedRows = gridRows.map(gridRow => {
    if (gridRow.id === row.id) return { ...row, [name]: value };
    return gridRow;
  });

  setGridRows(updatedRows);
  patchItem(row.id, {
    [name]: parseValue(value),
  });
};
export const BidGroupVendors = ({
  bidGroup,
  loading,
  onOpenDeleteModal,
  onOpenAddVendor,
  onOpenAddVendorSpec,
  onOpenSelectVendorContacts,
  onOpenPreviewEmail,
  onDownloadPDF,
  loaders,
  patchItem,
  getBidTypes,
  onOpenEditDate,
  onOpenSendRFQ,
  updateBidGroupVendor,
}) => {
  const [gridRows, setGridRows] = useState([]);

  const { selectedIds, selectedRows } = useContext(WithSelectedRowsContext);

  const totalRows = bidGroup && _get(bidGroup, "bidGroupVendors.length", 0);
  const { bidGroupVendors = [] } = bidGroup;
  const vendors = bidGroupVendors.map(({ vendor }) => vendor);
  const categories =
    bidGroup && getUniqRelationFromVendors("categoryId", "category")(vendors);

  const handleChange = useCallback(
    row => ({ target: { value, name } }) => {
      performChange(gridRows, setGridRows, patchItem, row, value, name);
    },
    [gridRows, patchItem]
  );

  useEffect(() => {
    if (!loading) {
      setGridRows(bidGroupVendors);
    }
  }, [bidGroupVendors, loading]);

  const isProjectClosed = useIsProjectClosed();

  const actions = useMemo(
    () => [
      {
        text: "Select Vendors",
        icon: <AddCircleIcon />,
        handler: onOpenAddVendor,
        disableIfProjectClosed: true,
      },
    ],
    [onOpenAddVendor]
  );
  const columnOptions = useMemo(
    () =>
      getColumnOptions({
        categories,
        bidGroup,
        onOpenDeleteModal,
        onOpenSelectVendorContacts,
        onOpenAddVendorSpec,
        onDownloadPDF,
        loaders,
        getBidTypes,
        handleChange,
        onOpenEditDate,
        isProjectClosed,
      }),
    [
      categories,
      bidGroup,
      onOpenDeleteModal,
      onOpenSelectVendorContacts,
      onOpenAddVendorSpec,
      onDownloadPDF,
      loaders,
      getBidTypes,
      handleChange,
      onOpenEditDate,
      isProjectClosed,
    ]
  );

  const tableComponents = useMemo(() => {
    getTableComponents(gridRows);
  }, [gridRows]);

  const optionActions = useMemo(
    () => [
      {
        content: "Set Ship Date",
        onClick: onOpenEditDate("shipDate", "Ship Date", selectedIds),
      },
      {
        content: "Set Delivery Date",
        onClick: onOpenEditDate("deliveryDate", "Delivery Date", selectedIds),
      },
      {
        content: "Send RFQ",
        onClick: onOpenSendRFQ(selectedRows),
      },
    ],
    [onOpenEditDate, selectedIds, selectedRows, onOpenSendRFQ]
  );

  const headerOverride =
    selectedIds.length > 0 ? <VendorAction options={optionActions} /> : null;

  const gridId = "bid-group-vendors";

  return (
    <BWGridLocal
      id={gridId}
      sorting={sorting}
      rows={gridRows}
      gridConfig={{ pageSize: 10, totalRows }}
      emptyStateText="No Vendors Selected"
      emptyStateProps={{ intent: "empty", padding: "24px" }}
      revisionReference="specs"
      isLoading={loading}
      tableComponents={tableComponents}
      showSelectAll
      showSelectionColumn
    >
      <GridHeader
        headerText={"Vendors"}
        actions={actions}
        headerOverride={headerOverride}
        withCounter
      />
      <GridTable
        columns={columns}
        columnOptions={columnOptions}
        rowMenu={getRowMenu(
          onDownloadPDF,
          onOpenDeleteModal,
          onOpenPreviewEmail,
          onRemoveVendorSuccess(setGridRows, gridRows),
          updateBidGroupVendor,
          onOpenEditDate
        )}
      />
    </BWGridLocal>
  );
};

BidGroupVendors.defaultProps = {
  selectedIds: [],
};

BidGroupVendors.propTypes = {
  bidGroup: propTypes.bidGroup,
  loading: PropTypes.bool,
  loaders: PropTypes.object,
  onOpenDeleteModal: PropTypes.func,
  onOpenAddVendor: PropTypes.func,
  onOpenAddVendorSpec: PropTypes.func,
  onOpenSelectVendorContacts: PropTypes.func.isRequired,
  onDownloadPDF: PropTypes.func,
  patchItem: PropTypes.func.isRequired,
  getBidTypes: PropTypes.func.isRequired,
  onOpenPreviewEmail: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onOpenEditDate: PropTypes.func,
  updateBidGroupVendor: PropTypes.func,
  onOpenSendRFQ: PropTypes.func,
};

export default withSelectedRows(BidGroupVendors);
