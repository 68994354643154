import React from "react";
import PropTypes from "prop-types";

import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const DeleteVendorContactContainer = props => {
  const {
    title,
    vendorContactId,
    formikArrayHelpers,
    index,
    onDeleteVendorContact,
  } = props;

  const handleDelete = () => {
    onDeleteVendorContact(vendorContactId, index, formikArrayHelpers);
  };

  return <DeleteConfirmation title={title} onDelete={handleDelete} />;
};

DeleteVendorContactContainer.propTypes = {
  title: PropTypes.string.isRequired,
  vendorContactId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.string,
  onDeleteVendorContact: PropTypes.func.isRequired,
};

export default DeleteVendorContactContainer;
