import React from "react";
import memoizeOne from "memoize-one";
import BWModels from "benjaminwest-models";

import { projectAccountingType } from "../../ProjectCreate/TeamSection/createTeamFields";
import PersonList from "../../../ui/Tags/PersonList";
import { PACheckCircle } from "./Components/components";
import { RowComponent } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/genDetailRowComponents/v2";
import { genDetailRowComponents } from "../../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { editableCellCreator } from "../../../ui/BWGrid/EditableCellAPI";
import DatePickerWithError from "../../../inputs/DatePickerWithError";
import { DashIcon } from "../../../ui/gridTableConfigs/detailPageProjects";
import DateColumn from "../../../ui/BWGrid/columns/Date";
import PagingPaneWithStatusSwitch from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const buildMemberUserFilter = team => ({ value }) => {
  const memberUserIdKeys = {
    "Accounting Support": "accountingSupportMemberUserIds",
  };
  return {
    [memberUserIdKeys[team]]: {
      $jsonInRaw: value,
    },
  };
};

export const buildOfficeFilter = () => filterProps => {
  return { officeId: { $in: filterProps.value } };
};

export const ProjectDate = (project, fieldName) => {
  if (!project) return <DashIcon />;
  return <DateColumn date={project[fieldName]} format={PRIMARY_DATE_FORMAT} />;
};

export const columns = [
  "number",
  "name",
  "pasFirstName",
  "hasAccounting",
  "accountingDue",
  "fundingDay",
  "accountingBankAccount",
  "bankReconComplete",
  "cashFlowComplete",
  "softCloseDate",
  "monthlyReportsComplete",
  "lastReviewed",
  "useTaxBalance",
  "openPoBalance",
  "deposits",
  "cash",
];

export const getColumnOptions = (users, offices, currencyFormatter) => {
  return {
    number: {
      title: "Number",
      filter: true,
      filterOptions: { operator: "ilike" },
    },
    name: { filter: true, fill: true },
    pasFirstName: {
      title: "Accounting Support",
      filter: "multiselect",
      minWidth: "64px",
      fullWidth: true,
      wordWrapEnabled: true,
      filterOptions: {
        operator: buildMemberUserFilter(projectAccountingType),
        options: users.map(user => {
          return { id: user.id, name: `${user.firstName} ${user.lastName}` };
        }),
      },
      parseSort: sort => ({ ...sort, nulls: "first" }),
      render: row => {
        if (
          row.projectMemberUsers &&
          row.projectMemberUsers.length > 0 &&
          row.projectMemberUsers.filter(
            projectMemberUser =>
              projectMemberUser.memberType === "Accounting Support"
          ).length > 0
        ) {
          return (
            <PersonList
              members={row.projectMemberUsers.filter(
                projectMemberUser =>
                  projectMemberUser.memberType === "Accounting Support"
              )}
              type="user"
              maxTags={3}
            />
          );
        }
        return "";
      },
    },
    hasAccounting: {
      title: "PA",
      filter: "select",
      filterOptions: {
        options: [
          { id: true, name: "True" },
          { id: false, name: "False" },
        ],
      },
      render: function PA(row) {
        return <PACheckCircle isActive={row.hasAccounting} />;
      },
    },
    accountingDue: {
      title: "Processing Day",
      filter: true,
      minWidth: "64px",
      fullWidth: true,
      wordWrapEnabled: true,
      parseSort: sort => [
        { ...sort, columnName: "accountingDueWeekDayNumber" },
        sort,
      ],
      render: ({ accountingDue }) => accountingDue || "-",
    },
    fundingDay: {
      title: "Funding Due",
      filter: true,
      minWidth: "54px",
      fullWidth: true,
      wordWrapEnabled: true,
      render: ({ fundingDay }) => fundingDay || "-",
    },
    accountingBankAccount: { title: "Bank Account", filter: true },
    bankReconComplete: {
      title: "Bank Recon Complete",
      filter: "picker",
      editable: true,
      render: project => ProjectDate(project, "bankReconComplete"),
    },
    cashFlowComplete: {
      title: "Cashflow Complete",
      filter: "picker",
      editable: true,
      render: project => ProjectDate(project, "cashFlowComplete"),
    },
    softCloseDate: {
      title: "Soft Close Complete",
      filter: "picker",
      editable: true,
      render: project => ProjectDate(project, "softCloseDate"),
    },
    monthlyReportsComplete: {
      title: "Monthly Reports Complete",
      filter: "picker",
      editable: true,
      render: project => ProjectDate(project, "monthlyReportsComplete"),
    },
    lastReviewed: {
      title: "Last Reviewed",
      filter: "picker",
      editable: true,
      render: project => ProjectDate(project, "lastReviewed"),
    },
    useTaxBalance: {
      title: "Use Tax Balance",
      render: row => {
        return currencyFormatter.format(row.useTaxBalance);
      },
    },
    openPoBalance: {
      title: "Open PO Balance",
      render: row => {
        return currencyFormatter.format(row.openPoBalance);
      },
    },
    deposits: {
      title: "Deposits Balance",
      render: row => {
        return currencyFormatter.format(row.deposits);
      },
    },
    cash: {
      title: "Cash Balance",
      sortingEnabled: false,
      render: row => {
        return currencyFormatter.format(
          row.totalFundsReceived - row.expenditures
        );
      },
    },
  };
};

export const rowInputs = {
  bankReconComplete: {
    InputComponent: DatePickerWithError,
    name: "bankReconComplete",
    fullWidth: true,
    isApplyOnChange: true,
  },
  cashFlowComplete: {
    InputComponent: DatePickerWithError,
    name: "cashFlowComplete",
    fullWidth: true,
    isApplyOnChange: true,
  },
  softCloseDate: {
    InputComponent: DatePickerWithError,
    name: "softCloseDate",
    fullWidth: true,
    isApplyOnChange: true,
  },
  monthlyReportsComplete: {
    InputComponent: DatePickerWithError,
    name: "monthlyReportsComplete",
    fullWidth: true,
    isApplyOnChange: true,
  },
  lastReviewed: {
    InputComponent: DatePickerWithError,
    name: "lastReviewed",
    fullWidth: true,
    isApplyOnChange: true,
  },
};

export const isEditableRow = canEditSoftCloseDate => column => {
  const editableColumns = [
    "bankReconComplete",
    "cashFlowComplete",
    "monthlyReportsComplete",
    "lastReviewed",
  ];
  if (canEditSoftCloseDate) {
    editableColumns.push("softCloseDate");
  }
  return editableColumns.indexOf(column.name) !== -1;
};

export const getTableComponents = memoizeOne(
  (dataComponentId, displayComments, canEditSoftCloseDate) => {
    if (!displayComments) return {};
    return {
      CellComponent: editableCellCreator(
        rowInputs,
        dataComponentId,
        {},
        isEditableRow(canEditSoftCloseDate)
      ),
      ...genDetailRowComponents(
        "id",
        [
          {
            path: "accountingMatrixNotes",
            label: "",
            placeholder: "Add Comment",
            isEmptyFn: Boolean,
            showLabel: false,
          },
        ],
        BWModels.loadSchema("Project"),
        dataComponentId,
        { prevSpan: 2, colSpan: 7, posSpan: 9 }
      ),
      RowComponent,
      PagingPanelTemplate: PagingPaneWithStatusSwitch,
    };
  }
);
