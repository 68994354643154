import { inputs } from "../../../inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        { input: inputs.project, grid: { xs: 12 } },
        { input: inputs.noteTime, grid: { xs: 12 } },
        {
          input: {
            ...inputs.noteCallWith,
            label: "Meeting With",
            helperText: "Separate attendees with commas (,)",
          },
          grid: { xs: 12 },
        },
        { input: inputs.noteDetail, grid: { xs: 12 } },
        { input: inputs.noteAssignTo, grid: { xs: 12 } },
        { input: inputs.tags, grid: { xs: 12 } },
      ],
    },
  ],
};
