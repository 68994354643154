import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { GridLocalSection } from "../../../../layout/GridLocalSection";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import { calculateQuantityTotals } from "./SpecEstimatedDates";
import propTypes from "../../../../../constants/propTypes";
import {
  columns,
  getColumnOptions,
  getHeaderText,
  getRowMenu,
  buildRowInputs,
  isEditable,
} from "./gridProps";

export const calcTotalQuantityApprovedToPay = shipments =>
  shipments.reduce(
    (sum, { quantity, approvedToPay }) => (sum += approvedToPay ? quantity : 0),
    0
  );

const ManageShipmentsGrid = ({
  spec,
  values,
  formikProps,
  formikArrayHelpers,
  quantitySetters: { setQuantity, setLeft, setShipped },
  handleAddShipment,
  handleAddTrackingNote,
  handleDeleteShipment,
  handleEditShipment,
  handleOpenNotesModal,
}) => {
  useEffect(() => {
    const { quantity, left, shipped } = calculateQuantityTotals(
      spec,
      values.shipments
    );
    setQuantity(quantity);
    setLeft(left);
    setShipped(shipped);
  }, [setLeft, setQuantity, setShipped, spec, values.shipments]);

  const getFieldError = useCallback(
    (tableRow, column) => {
      const errorMessage =
        formikProps.errors[`shipments[${tableRow.rowId}].${column.name}`];

      return errorMessage != "No information associated to error."
        ? errorMessage
        : null;
    },
    [formikProps.errors]
  );

  const totalQuantityApprovedToPay = calcTotalQuantityApprovedToPay(
    values.shipments
  );

  const tableComponent = useMemo(() => {
    return {
      CellComponent: editableCellCreator(
        tableRow => buildRowInputs(formikProps.setFieldValue, tableRow),
        (tableRow, column) => `shipments[${tableRow.rowId}].${column.name}`,
        isEditable(spec, totalQuantityApprovedToPay),
        (tableRow, column) => getFieldError(tableRow, column)
      ),
    };
  }, [
    formikProps.setFieldValue,
    getFieldError,
    spec,
    totalQuantityApprovedToPay,
  ]);

  return (
    <React.Fragment>
      <GridLocalSection
        id="manage-shipments-grid"
        columns={columns}
        columnsOptions={getColumnOptions(spec, totalQuantityApprovedToPay)}
        rowMenu={getRowMenu(
          handleEditShipment(values),
          handleDeleteShipment(formikArrayHelpers),
          handleOpenNotesModal(values),
          handleAddTrackingNote(values),
          spec,
          totalQuantityApprovedToPay
        )}
        tableComponents={tableComponent}
        gridConfig={{ pageSize: 0, totalRows: values.shipments.length }}
        rows={values.shipments}
        addButtonText={"ADD SHIPMENT"}
        gridHeaderText={getHeaderText(values.shipments)}
        addClick={handleAddShipment(values)}
        noBorder
      />
    </React.Fragment>
  );
};

ManageShipmentsGrid.propTypes = {
  spec: propTypes.spec,
  values: PropTypes.object,
  formikProps: PropTypes.object,
  formikArrayHelpers: PropTypes.object,
  quantitySetters: PropTypes.object,
  handleAddShipment: PropTypes.func,
  handleAddTrackingNote: PropTypes.func,
  handleDeleteShipment: PropTypes.func,
  handleEditShipment: PropTypes.func,
  handleOpenNotesModal: PropTypes.func,
};

export default ManageShipmentsGrid;
