import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { format, friendlyFormat } from "../ChartFormatters";
import { useWindowWidth } from "../../../../hooks/useWindowWidth";

export const getAspectRatio = windowSize => {
  if (windowSize < 600) {
    return 6.0 / 8.0;
  }
  if (windowSize < 960) {
    return 4.0 / 3.0;
  }
  if (windowSize < 1280) {
    return 5.0 / 3.0;
  }
  return 8.0 / 3.0;
};
const ProjectOverviewBarChart = props => {
  const { data } = props;
  const windowSize = useWindowWidth();

  return (
    <ResponsiveContainer width={"100%"} aspect={getAspectRatio(windowSize)}>
      <BarChart
        data={data}
        margin={{
          top: 24,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          type="category"
          tick={{
            fontSize: 12,
            fontFamily: "Open Sans, sans-serif",
            fill: "rgba(0, 0, 0, 0.87)",
          }}
          tickCount={5}
          width={60}
          tickMargin={5}
          interval={0}
          angle={-45}
          height={80}
          textAnchor="end"
        />
        <YAxis dataKey="value" type="number" tickFormatter={friendlyFormat} />
        <Tooltip
          cursor={{ fill: "transparent" }}
          formatter={value => [format(value), "Amount"]}
        />
        <Bar dataKey="value" fill="#57ABFF" />
      </BarChart>
    </ResponsiveContainer>
  );
};
ProjectOverviewBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
export default ProjectOverviewBarChart;
