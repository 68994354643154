import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { PDFController } from "./PDFController";
import SpecForm from "./SpecForm";
import { useImportProcessRequests } from "../../../hooks/useProcessRequest";
import { previewDataComponentId } from "../../../../actions/specsActions";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { DragDropDashboard } from "../../../inputs/DragDrop/DragDropDashboard";

const Wrapper = styled.div`
  display: flex;
  max-height: calc(100vh - 100px);
`;

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", e => resolve(e.target.result));
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(file);
  });
}

const shouldIgnoreSelection = ({
  selection,
  selectedInputName,
  pdfControllerRef,
  extractedText,
}) =>
  !selectedInputName ||
  !pdfControllerRef.current.contains(selection.baseNode) ||
  !extractedText.length ||
  selection.baseNode.nodeType !== Node.TEXT_NODE;

export const ImportSpecsPDF = () => {
  const [selectedInputName, setSelectedInputName] = useState("customNumber");
  const [fileData, setFileData] = useState(null);
  const [selectLineOnClick, setSelectLineOnClick] = useState(false);

  const pdfControllerRef = React.useRef(null);
  const formikPropsRef = React.useRef(null);

  const onFileChange = async event => {
    const [file] = event.target.files;
    const data = await readFile(file);
    setFileData({ data, filename: file.name });
  };

  useEffect(() => {
    const onSelectionChange = () => {
      const selection = document.getSelection();
      const extractedText = selectLineOnClick
        ? selection.baseNode?.textContent || ""
        : selection.toString();
      if (
        shouldIgnoreSelection({
          selection,
          selectedInputName,
          pdfControllerRef,
          extractedText,
        })
      ) {
        return;
      }
      formikPropsRef.current.setFieldValue(selectedInputName, extractedText);
    };
    document.addEventListener("selectionchange", onSelectionChange);
    return () =>
      document.removeEventListener("selectionchange", onSelectionChange);
  }, [selectLineOnClick, selectedInputName]);

  useEffect(() => {
    const onClick = ({ target: { nodeName } }) => {
      if (
        !["INPUT", "TEXTAREA"].includes(nodeName) &&
        !pdfControllerRef.current.contains(document.getSelection().baseNode)
      ) {
        setSelectedInputName(null);
      }
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  const previewDataComponent = useDatacomponent(previewDataComponentId);
  useImportProcessRequests(previewDataComponent, {
    onSuccess: () => {
      setFileData(null);
    },
  });

  const noData = useMemo(
    () => (
      <DragDropDashboard
        accept={".pdf"}
        onChange={onFileChange}
        height="100%"
        width="50vw"
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <React.Fragment>
      <Wrapper>
        <SpecForm
          selectedInputName={selectedInputName}
          setSelectedInputName={setSelectedInputName}
          selectLineOnClick={selectLineOnClick}
          setSelectLineOnClick={setSelectLineOnClick}
          formikPropsRef={formikPropsRef}
        />
        <PDFController
          pdfControllerRef={pdfControllerRef}
          formikPropsRef={formikPropsRef}
          fileData={fileData}
          noData={noData}
        />
      </Wrapper>
    </React.Fragment>
  );
};
