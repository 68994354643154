import React, { useCallback } from "react";
import PropTypes from "prop-types";

import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import DangerConfirmation from "../../../../../ui/DangerConfirmation";

const VoidInvoiceContainer = ({ invoice, dataComponentId }) => {
  const actions = useActions({
    closeModalDialog,
    showSnackNotificationAction,
    setReload,
    performUpdateRequest,
  });

  const dataComponent = useDatacomponent(dataComponentId);

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      actions.setReload(dataComponentId, true);
    },
    onError: ({ data: { errors } }) => {
      errors.forEach(error => {
        if (error.global) {
          actions.showSnackNotificationAction(error.title);
        }
      });
    },
  });

  const handleVoid = useCallback(() => {
    actions.performUpdateRequest(dataComponentId, invoice.id, { isVoid: true });
  }, [actions, dataComponentId, invoice]);

  return (
    <DangerConfirmation
      dangerButtonText="Void"
      actionMessage={`void ${invoice.number}`}
      onDanger={handleVoid}
    />
  );
};

VoidInvoiceContainer.propTypes = {
  invoice: PropTypes.object.isRequired,
  dataComponentId: PropTypes.string.isRequired,
};

export default VoidInvoiceContainer;
