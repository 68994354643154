import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";

import propTypes from "../../../../../constants/propTypes";
import RegularText from "../../../../ui/Typography/RegularText";
import BoldText from "../../../../ui/Typography/BoldText";
import { saveSpecSubmittal } from "../../../../../actions/submittalActions";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import UIDate from "../../../../ui/BWGrid/columns/Date";
import useActions from "../../../../hooks/useActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

import {
  Wrapper,
  Header,
  StatusLabel,
  Subheader,
  StatusLine,
} from "./components";
import { RequirementStatus } from "../../../SubmittalsManager/RequirementsGrid/Grid";
import { dataComponentId } from "../../../../../actions/specDetailActions";
import MainSection from "./MainSection";
import { DragDropDashboard } from "../../../../inputs/DragDrop/DragDropDashboard";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const FileName = styled(BoldText)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ReviewSubmittals = ({
  specRequirement: srcSpecRequirement,
  formikArrayHelpers,
  index,
  onUpdateSpec,
}) => {
  const [selectedSubmittal, setSelectedSubmittal] = useState(null);
  const [loaderActive, setLoaderActive] = useState(null);
  const actions = useActions({ saveSpecSubmittal });
  const dataComponent = useDatacomponent(dataComponentId);

  const spec = useV2DatacomponentResources(
    dataComponentId,
    [],
    REQUEST_TYPES.FIND
  );

  const specRequirement = spec.specRequirements?.find(
    specRequirement => specRequirement.id == srcSpecRequirement.id
  );

  const handleClear = () => {
    setSelectedSubmittal(null);
    setLoaderActive(null);
  };

  const handleSelectSubmittal = submittalFile =>
    setSelectedSubmittal(submittalFile);

  useUpdateProcessRequests(dataComponent, {
    onSuccess: handleClear,
  });

  const handleUpload = evt => {
    const file = evt.target.files[0];
    if (!file) return;

    setLoaderActive("upload");
    actions.saveSpecSubmittal(
      {
        file: { file, isUpdated: true },
      },
      specRequirement,
      spec,
      onUpdateSpec,
      index
    );
  };

  const handleDelete = submittalFile => {
    const submittalFiles = _get(specRequirement, "submittalFiles", []);
    const updatedSpecRequirement = {
      ...specRequirement,
      files: submittalFiles.length - 1,
      submittalFiles: submittalFiles.filter(
        currentSubmittalFile => currentSubmittalFile !== submittalFile
      ),
    };
    formikArrayHelpers.replace(index, updatedSpecRequirement);
  };

  const handleSave = (submittal, submittalIndex) => {
    actions.saveSpecSubmittal(
      submittal,
      specRequirement,
      spec,
      onUpdateSpec,
      index,
      submittalIndex
    );
  };

  const handleApprove = submittal => {
    setLoaderActive("approve");
    formikArrayHelpers.replace(index, {
      ...specRequirement,
      submittalApprovedId: _get(submittal, "id", null),
      submittalApproved: submittal,
    });
  };

  return (
    <Wrapper>
      <Header>
        <Subheader>{specRequirement.requirement.name}</Subheader>
        <StatusLabel intent="light">Status</StatusLabel>
        <StatusLine>
          <RequirementStatus
            submittalApprovedId={specRequirement.submittalApprovedId}
          />
          {specRequirement.submittalApprovedId && (
            <Fragment>
              <FileName>
                {_get(specRequirement.submittalApproved, "file.filename")}
              </FileName>
              <RegularText>
                <UIDate
                  date={_get(specRequirement.submittalApproved, "createdAt")}
                  format={PRIMARY_DATE_FORMAT}
                />
              </RegularText>
            </Fragment>
          )}
        </StatusLine>
      </Header>

      <MainSection
        specRequirement={specRequirement}
        selectedSubmittal={selectedSubmittal}
        onDelete={handleDelete}
        onSelect={handleSelectSubmittal}
        onClearSelection={handleClear}
        onSave={handleSave}
        loaderActive={loaderActive}
        setLoaderActive={setLoaderActive}
        onApprove={handleApprove}
      />

      {!selectedSubmittal && (
        <DragDropDashboard onChange={handleUpload} height="100%" />
      )}
    </Wrapper>
  );
};

ReviewSubmittals.propTypes = {
  specRequirement: propTypes.specRequirement,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.number,
  onUpdateSpec: PropTypes.func.isRequired,
};

export default ReviewSubmittals;
