import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";

const DragAndDropList = props => {
  const { children, name } = props;

  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => children(formikArrayHelpers)}
    />
  );
};

DragAndDropList.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default DragAndDropList;
