import { Fragment } from "react";
import React from "react";

import styled from "styled-components";

export const AttributeListContainer = styled.dl`
  margin: 0;
`;

export const AttributeListKey = styled.dt`
  display: inline-block;
  margin-right: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

export const AttributeListValue = styled.dd`
  display: inline-block;
  margin-left: 10px;
  margin-right: 24px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

const AttributeList = ({ attributes }) => {
  attributes = attributes.filter(attr => attr.value && attr.value !== "");

  if (attributes.length === 0) {
    return null;
  } else {
    return (
      <AttributeListContainer>
        {attributes.map(({ key, value }) => (
          <Fragment key={key}>
            <AttributeListKey>{key}:</AttributeListKey>
            <AttributeListValue>{value}</AttributeListValue>
          </Fragment>
        ))}
      </AttributeListContainer>
    );
  }
};

export default AttributeList;
