import { deserialize } from "deserialize-json-api/src";
import _isPlainObject from "lodash/isPlainObject";
import _get from "lodash/get";

import { serializeGetParams } from "./utils";
import store from "../store/store";
import { openModalDialog } from "../actions/layoutActions";

export function checkDisplayExpireSessionModal(headers) {
  const { displayExpireSessionModal } = require("../actions/authActions");
  if (headers && headers.get("X-BW-Max-Age-Session")) {
    store.dispatch(
      displayExpireSessionModal(parseInt(headers.get("X-BW-Max-Age-Session")))
    );
  }
}

export function logoutService() {
  const { logout } = require("../actions/authActions");
  store.dispatch(logout(true));
}

const fetchWrapper = async (input, init = {}) => {
  try {
    init.referrerPolicy = "no-referrer";
    const response = await fetch(input, init);
    checkDisplayExpireSessionModal(response.headers);
    return response;
  } catch (e) {
    return { ok: false, status: -1 };
  }
};

const parseData = data =>
  _isPlainObject(data) || Array.isArray(data) ? JSON.stringify(data) : data;

export default class ApiService {
  constructor(autoDeserialize = true) {
    this.autoDeserialize = autoDeserialize;
  }

  async get(path, getParameters = {}, headers = {}, options = {}) {
    const requestHeaders = { ...this._headers(), ...headers };
    const response = await fetchWrapper(
      this._url(path) + serializeGetParams(getParameters),
      {
        headers: requestHeaders,
        credentials: "include",
      }
    );

    if (options.returnRawResponse) {
      return response;
    }

    return this._processResponse(response);
  }

  async post(path, data, getParameters = {}, headers = {}, options = {}) {
    const requestHeaders = { ...this._headers(data), ...headers };
    const response = await fetchWrapper(
      this._url(path) + serializeGetParams(getParameters),
      {
        method: "POST",
        body: parseData(data),
        headers: requestHeaders,
        credentials: "include",
      }
    );

    if (options.returnRawResponse) {
      return response;
    }

    return this._processResponse(response);
  }

  async patch(path, data, getParameters = {}, headers = {}, options = {}) {
    const requestHeaders = { ...this._headers(data), ...headers };
    if (data.id) {
      delete data.id;
    }
    const response = await fetchWrapper(
      this._url(path) + serializeGetParams(getParameters),
      {
        method: "PATCH",
        body: parseData(data),
        headers: requestHeaders,
        credentials: "include",
      }
    );

    if (options.returnRawResponse) {
      return response;
    }

    return this._processResponse(response);
  }

  async put(path, data, getParameters = {}, headers = {}) {
    const requestHeaders = { ...this._headers(data), ...headers };
    if (data.id) {
      delete data.id;
    }
    const response = await fetchWrapper(
      this._url(path) + serializeGetParams(getParameters),
      {
        method: "PUT",
        body: parseData(data),
        headers: requestHeaders,
        credentials: "include",
      }
    );

    return this._processResponse(response);
  }

  async delete(path, getParameters = {}, headers = {}, data) {
    const requestHeaders = { ...this._headers(data), ...headers };
    const response = await fetchWrapper(
      this._url(path) + serializeGetParams(getParameters),
      {
        method: "DELETE",
        body: parseData(data),
        headers: requestHeaders,
        credentials: "include",
      }
    );

    return this._processResponse(response, true);
  }

  async _processResponse(response, skipDeserialize = false) {
    await this._checkStatus(response);
    let data = await this._responseToJson(response);
    if (this.autoDeserialize && !skipDeserialize) {
      data = await data.deserializeData();
    }
    return data;
  }

  async _deserialize(data) {
    return deserialize(data).data;
  }

  _url(path) {
    return process.env.API_URL + path;
  }

  _headers(data) {
    const headers = {
      Accept: "application/json",
    };
    if (_isPlainObject(data) || Array.isArray(data)) {
      headers["Content-Type"] = "application/json";
    }
    return headers;
  }

  responseIsJSON(response) {
    const responseContentType = response.headers
      ? response.headers.get("content-type")
      : null;
    const jsonContentTypesInResponseHeaders = [
      "application/json",
      "application/vnd.api+json",
    ].filter(
      jsonContentType =>
        responseContentType &&
        responseContentType.indexOf(jsonContentType) !== -1
    );
    return jsonContentTypesInResponseHeaders.length > 0;
  }

  getErrorMessage(status, url) {
    switch (status) {
      case -1:
        return "Failed request";
      case 401:
        return "You have been logged out of the session. Please log back in.";
      default:
        return `Failed request (${status}) ${url}`;
    }
  }

  // eslint-disable-next-line complexity
  async _checkStatus(response) {
    if (!response.ok) {
      let errorMessage = this.getErrorMessage(response.status, response.url);
      let errorData = undefined;

      if (this.responseIsJSON(response)) {
        errorData = await this._responseToJson(response);
        const { errors } = errorData;
        if (errors.length > 0 && errors[0].detail) {
          errorMessage += `: ${errors[0].detail}`;
        }
      }

      if (response.status === 401) {
        logoutService();
      }

      const error = new Error(errorMessage);
      error.data = errorData;
      error.response = response;
      const isDatabasePerformanceError = _get(
        errorData,
        "errors[0].isDatabasePerformanceError"
      );
      if (isDatabasePerformanceError) {
        store.dispatch(
          openModalDialog(false, "ReloadModal", {
            title:
              "The request could not be performed due to an error in the database. Please try again in a minute. If the problem continues or repeats later, please report to RMP2 Support via email.",
            onReload: () => window.location.reload(),
            retryDelay: 60,
          })
        );
      } else {
        throw error;
      }
    }
  }

  async _responseToJson(response) {
    const jsonResponse = await response.json();
    if ("deserializeData" in jsonResponse) {
      throw new Error(
        "Response has a `deserializeData` attribute, which is not supported by ApiService"
      );
    }
    jsonResponse.deserializeData = async () => {
      const d = await this._deserialize(jsonResponse);
      return d;
    };
    return jsonResponse;
  }
}
