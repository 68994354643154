import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import ControlButtons from "./ControlButtons";
import ListSection from "./ListSection";
import DetailSection from "./DetailSection";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import { connect } from "react-redux";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  addCOMToSpec,
  editSpecDetailCom,
} from "../../../../actions/specDetailActions";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  performCreateRequest,
} from "../../../../actions/dataComponentActions";
import get from "lodash.get";

import {
  processCreateRequestStatus,
  getFoundId,
  isPendingRequest,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";

import {
  getBluechipResources,
  getBluechipResourceById,
} from "../../../../utils/bluechipUtils";
import Spec from "../../../../models/Spec";
import SpecDetailCom from "../../../../models/SpecDetailCom";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import propTypes from "../../../../constants/propTypes";
import withPORevision from "../../../../withPORevision";

import Vendor from "../../../../models/Vendor";
import CreateComItemContainer from "./CreateComItem/CreateComItemContainer";

import {
  createComForSpec,
  updatePreview,
} from "../../../../actions/specDetailActions";
import SpecDetail from "../../../../models/SpecDetail";

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DetailPanel = styled.div`
  display: flex;
  width: 560px;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

const ListPanel = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const gridDataComponentId = "COMLibrary-Specs-Grid";
const specDetailComDataComponentId = "COMLibrary-specDetailCom";

const selectedSpecDataComponentId = "COMLibraryDetail-selectedSpec";
const relatedDetailsDataComponentId = "COMLibraryDetail-relatedSpecDetailComs";
const vendorsComponentId = "COMLibraryDetail-vendors";
const editPreviewDataComponentId = "COMLibraryDetail-editPreview";

export class COMLibrary extends Component {
  state = {
    currentSelectedId: null,
    selectedSpecDetailCom: null,
    isCreatingComSpec: false,
  };

  componentDidMount() {
    const { initDataComponent, performRetrieveListRequest } = this.props;

    initDataComponent(
      specDetailComDataComponentId,
      SpecDetailCom,
      [],
      "spec-detail-coms"
    );

    initDataComponent(
      selectedSpecDataComponentId,
      Spec,
      ["specDetails.preview", "specCategory"],
      "specs"
    );

    initDataComponent(
      relatedDetailsDataComponentId,
      SpecDetailCom,
      ["specDetail.spec.[area, specCategory]"],
      "spec-detail-coms"
    );

    initDataComponent(
      editPreviewDataComponentId,
      SpecDetail,
      ["spec", "preview"],
      "spec-details"
    );

    initDataComponent(vendorsComponentId, Vendor, [], "vendors", "v2");

    performRetrieveListRequest(vendorsComponentId, {
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
    });
  }

  componentDidUpdate(prevProps) {
    const { specDetailComDataComponent, editPreviewDataComponent } = this.props;

    processCreateRequestStatus(
      prevProps.specDetailComDataComponent,
      specDetailComDataComponent,
      {
        onSuccess: this.handleCreateOrPatchSuccess,
        // eslint-disable-next-line
        onError: error => {
          /* TODO ???? */
        },
      }
    );

    processUpdateRequestStatus(
      prevProps.specDetailComDataComponent,
      specDetailComDataComponent,
      {
        onSuccess: this.handleCreateOrPatchSuccess,
        // eslint-disable-next-line
        onError: error => {
          /* TODO ???? */
        },
      }
    );

    processUpdateRequestStatus(
      prevProps.editPreviewDataComponent,
      editPreviewDataComponent,
      {
        onSuccess: () => null,
        onError: error => this.props.showSnackNotificationAction(error.message),
      }
    );

    this.processFirstSelection(prevProps);
  }

  handleEdit = () => {
    const {
      pushWithReturnUrl,
      clientId,
      projectId,
      closeModalDialog,
    } = this.props;
    const { currentSelectedId } = this.state;

    pushWithReturnUrl(
      `/clients/${clientId}/projects/${projectId}/specs/${currentSelectedId}`,
      "PROJECT DETAIL"
    );
    closeModalDialog();
  };

  handleUpdatePreview = (spec, specDetailId, preview) => {
    this.props.updatePreview(
      editPreviewDataComponentId,
      spec.id,
      spec.projectId,
      specDetailId,
      preview
    );
  };

  handleCreateOrPatchSuccess = () => {
    this.props.closeModalDialog();
  };

  processFirstSelection = ({ rows: prevRows }) => {
    const { rows } = this.props;

    if (
      !rows ||
      !rows.length > 0 ||
      rows == prevRows ||
      this.state.currentSelectedId
    ) {
      return;
    }

    this.onSelect(rows[0].id);
  };

  handleClickRelatedSpec = url => {
    const { closeModalDialog, pushWithReturnUrl } = this.props;

    closeModalDialog();
    pushWithReturnUrl(url, "PROJECT DETAIL");
  };

  onSelect = currentSelectedId => {
    const {
      performRetrieveListRequest,
      performFindRequest,
      isSelectCOM,
      specDetail,
    } = this.props;

    performFindRequest(selectedSpecDataComponentId, currentSelectedId, {
      specDetails: {
        $where: {
          $or: [
            {
              type: "Description",
            },
            {
              type: "Preview",
            },
          ],
        },
      },
    });

    if (!isSelectCOM) {
      performRetrieveListRequest(relatedDetailsDataComponentId, {
        rootFilters: {
          $where: { specId: currentSelectedId },
        },
      });
    }

    this.setState({
      currentSelectedId,
      ...(specDetail
        ? {
            selectedSpecDetailCom: _get(specDetail, "coms", []).find(
              ({ specId }) => specId === currentSelectedId
            ),
          }
        : {}),
    });
  };

  handleAddComToSpec = selectedDetails => notes => {
    const {
      spec,
      currentSelected,
      addCOMToSpec,
      editSpecDetailCom,
      revisionValue,
    } = this.props;
    const { selectedSpecDetailCom } = this.state;

    let revisionIsActive = revisionValue.isActive;
    const params = {
      ...(revisionIsActive
        ? {
            update_po_revision: true,
          }
        : {}),
      selectedDetailIds: selectedDetails
        .filter(specDetail => specDetail.isSelected)
        .map(specDetail => specDetail.id),
    };

    if (selectedSpecDetailCom) {
      editSpecDetailCom(
        specDetailComDataComponentId,
        selectedSpecDetailCom,
        notes,
        params
      );
    } else {
      addCOMToSpec(
        specDetailComDataComponentId,
        spec,
        currentSelected,
        notes,
        params
      );
    }
  };

  render() {
    const {
      gridDataComponentFlat,
      isSelectCOM,
      specDetail,
      clientId,
      projectId,
      excludeComIds,
      closeModalDialog,
      relatedSpecs,
      currentSelected,
      detailSectionLoading,
      vendors,
      initDataComponent,
      performRetrieveListRequest,
      performCreateRequest,
      spec,
      createComForSpec,
    } = this.props;
    const { selectedSpecDetailCom, isCreatingComSpec } = this.state;

    if (!projectId) return null;

    let modalBody = (
      <React.Fragment>
        <ControlButtons
          isSelectCOM={isSelectCOM}
          onClose={closeModalDialog}
          onEdit={this.handleEdit}
          currentSelectedId={this.state.currentSelectedId}
        />
        <ListPanel>
          <ListSection
            dataComponent={gridDataComponentFlat}
            projectId={projectId}
            specDetail={specDetail}
            excludeComIds={excludeComIds}
            currentSelectedId={this.state.currentSelectedId}
            selectHandler={this.onSelect}
            isSelectCOM={isSelectCOM}
            vendors={vendors}
            clickCreateComItem={() =>
              this.setState({ ...this.state, isCreatingComSpec: true })
            }
            spec={spec}
          />
        </ListPanel>
        <DetailPanel>
          <DetailSection
            handleClickRelatedSpec={this.handleClickRelatedSpec}
            loading={detailSectionLoading}
            clientId={clientId}
            isSelectCOM={isSelectCOM}
            relatedSpecs={relatedSpecs}
            itemSelected={currentSelected}
            addCOMToSpec={this.handleAddComToSpec}
            selectedSpecDetailCom={selectedSpecDetailCom}
            dataComponentId={specDetailComDataComponentId}
            handleUpdatePreview={this.handleUpdatePreview}
          />
        </DetailPanel>
      </React.Fragment>
    );

    if (isCreatingComSpec) {
      modalBody = (
        <React.Fragment>
          <CreateComItemContainer
            handleCancel={() =>
              this.setState({ ...this.state, isCreatingComSpec: false })
            }
            projectId={projectId}
            clientId={clientId}
            specSelected={spec}
            initDataComponent={initDataComponent}
            performRetrieveListRequest={performRetrieveListRequest}
            closeModalDialog={closeModalDialog}
            performCreateRequest={performCreateRequest}
            createComForSpec={createComForSpec}
          />
        </React.Fragment>
      );
    }

    return <Wrapper>{modalBody}</Wrapper>;
  }
}

export const mapStateToProps = (state, ownProps) => {
  const gridDataComponentFlat = getDataComponentFlattenedRequestState(
    gridDataComponentId,
    state
  );

  const specDetailComDataComponent = getDataComponent(
    specDetailComDataComponentId,
    state
  );

  const selectedSpecDataComponent = getDataComponent(
    selectedSpecDataComponentId,
    state
  );

  const editPreviewDataComponent = getDataComponent(
    editPreviewDataComponentId,
    state
  );

  const relatedDetailsDataComponent = getDataComponentFlattenedRequestState(
    relatedDetailsDataComponentId,
    state
  );

  const vendorsDataComponent = getDataComponentFlattenedRequestState(
    vendorsComponentId,
    state
  );

  let specDetailComs = getBluechipResources(relatedDetailsDataComponent, state);

  const relatedSpecs = addAreaAndSpecCategoryFromBluechip(
    state,
    specDetailComs
  );

  let detailSectionLoading;
  if (!ownProps.isSelectCOM) {
    detailSectionLoading =
      relatedDetailsDataComponent.loading ||
      isPendingRequest(selectedSpecDataComponent, REQUEST_TYPES.FIND) ||
      isPendingRequest(editPreviewDataComponent, REQUEST_TYPES.UPDATE);
  } else {
    detailSectionLoading =
      isPendingRequest(selectedSpecDataComponent, REQUEST_TYPES.FIND) ||
      isPendingRequest(editPreviewDataComponent, REQUEST_TYPES.UPDATE);
  }
  return {
    projectId: ownProps.projectId,
    clientId: ownProps.clientId,
    gridDataComponentFlat,
    editPreviewDataComponent,
    rows: getBluechipResources(gridDataComponentFlat, state),
    vendors: getBluechipResources(vendorsDataComponent, state),
    specDetailComDataComponent,
    currentSelected: getBluechipResourceById(
      selectedSpecDataComponent,
      state,
      getFoundId(selectedSpecDataComponent)
    ),
    relatedSpecs,
    detailSectionLoading,
  };
};

export const addAreaAndSpecCategoryFromBluechip = (state, specDetailComs) => {
  let relatedSpecs = [];
  if (specDetailComs) {
    // TODO FIXME temp Bluechip workaround to get double nested belongsTo
    specDetailComs = specDetailComs.map(specDetailCom => {
      let spec = Spec.query(state.resources)
        .includes(["area", "specCategory"])
        .where({ id: specDetailCom.specDetail.spec.id })
        .toObjects();
      if (spec && spec.length) {
        spec = spec[0];
      } else {
        // Use existing info as is if not found
        spec = specDetailCom.specDetail.spec;
      }

      // Replacing non-populated spec with spec populated with area and specCategory
      return {
        ...specDetailCom,
        specDetail: {
          spec,
        },
      };
    });

    relatedSpecs = specDetailComs.map(detail => ({
      id: get(detail, "specDetail.spec.id"),
      customNumber: get(detail, "specDetail.spec.customNumber"),
      description: get(detail, "specDetail.spec.description"),
      category: get(detail, "specDetail.spec.specCategory.name"),
      area: get(detail, "specDetail.spec.area.name"),
      projectId: get(detail, "specDetail.spec.projectId"),
    }));
  }

  return relatedSpecs;
};

COMLibrary.propTypes = {
  gridDataComponentFlat: PropTypes.object.isRequired,
  closeModalDialog: PropTypes.func,
  isSelectCOM: PropTypes.bool,
  projectId: PropTypes.string,
  spec: PropTypes.object,
  rows: PropTypes.array,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  addCOMToSpec: PropTypes.func,
  editSpecDetailCom: PropTypes.func,
  specDetail: propTypes.specDetail,
  specDetailComDataComponent: PropTypes.object,
  clientId: PropTypes.string,
  pushWithReturnUrl: PropTypes.func,
  performFindRequest: PropTypes.func,
  relatedSpecs: PropTypes.arrayOf(propTypes.relatedSpec),
  currentSelected: PropTypes.object,
  detailSectionLoading: PropTypes.bool,
  excludeComIds: PropTypes.arrayOf(PropTypes.string),
  vendors: PropTypes.array,
  performCreateRequest: PropTypes.func,
  createComForSpec: PropTypes.func,
  updatePreview: PropTypes.func,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
  editPreviewDataComponent: PropTypes.object,
  showSnackNotificationAction: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  addCOMToSpec,
  editSpecDetailCom,
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  pushWithReturnUrl,
  performCreateRequest,
  createComForSpec,
  updatePreview,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(COMLibrary, "SpecDetail", "purchaseOrder", true));
