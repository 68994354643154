import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";
import { useWithSelectedRowContext } from "../../../../ui/BWGrid/withSelectedRows";
import useActions from "../../../../hooks/useActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import ShippingPayment from "../../../../../models/ShippingPayment";
import { StyledCircularProgress } from "../../../../ui/BWActionButton";
import { openModalDialog } from "../../../../../actions/layoutActions";

export const unbillShippingPaymentsDataComponentId =
  "unbillShippingPaymentsDataComponentId";

export const ShippingPaymentsActions = ({ projectId }) => {
  const { selectedIds, selectedRows } = useWithSelectedRowContext();

  const actions = useActions({
    initDataComponent,
    generateAndDownloadReport,
    performUpdateRequest,
    openModalDialog,
  });

  useEffect(() => {
    actions.initDataComponent(
      unbillShippingPaymentsDataComponentId,
      ShippingPayment,
      [],
      "shipping-payments",
      true,
      "v2"
    );
  }, [actions]);

  const handleMarkBilled = useCallback(() => {
    actions.openModalDialog(
      ["Mark as Billed", `${selectedIds.length} Shipping Payments Selected`],
      "MarkBilledModal",
      {
        projectId,
        selectedIds,
      },
      true,
      true
    );
  }, [actions, projectId, selectedIds]);

  const handleEdit = useCallback(() => {
    actions.openModalDialog(
      ["Edit", `${selectedIds.length} Shipping Payments Selected`],
      "MarkBilledModal",
      {
        projectId,
        selectedIds,
        edit: true,
      },
      true,
      true
    );
  }, [actions, projectId, selectedIds]);

  const options = useMemo(
    () => [
      {
        content: "Mark as Billed",
        onClick: handleMarkBilled,
        disabled:
          isEmpty(selectedRows) ||
          selectedRows.some(({ status }) => status == "Billed"),
      },
      {
        content: "Mark as Unbilled",
        onClick: () =>
          actions.performUpdateRequest(
            unbillShippingPaymentsDataComponentId,
            selectedIds.map(id => ({
              id,
              status: "Unbilled",
              billedDate: null,
              bwInvoiceNumber: null,
            }))
          ),
        disabled:
          isEmpty(selectedRows) ||
          selectedRows.some(({ status }) => status == "Unbilled"),
      },
      {
        content: "Edit",
        disabled:
          isEmpty(selectedRows) ||
          selectedRows.some(({ status }) => status == "Unbilled"),
        onClick: handleEdit,
      },
      {
        content: "Print Reimbursement Report",
        onClick: () =>
          actions.generateAndDownloadReport({
            projectId,
            ids: selectedIds,
            templateName: "shipping-payments",
            format: "pdf",
          }),
        disabled: !selectedIds.length,
      },
    ],
    [
      actions,
      handleEdit,
      handleMarkBilled,
      projectId,
      selectedIds,
      selectedRows,
    ]
  );

  const isGeneratingReport = useSelector(
    state => state.reports.loaders["shipping-payments-pdf-false"]
  );

  if (isGeneratingReport) {
    return <StyledCircularProgress size={24} />;
  }

  return <DropdownMenu buttonText="Actions" options={options} />;
};

ShippingPaymentsActions.propTypes = {
  projectId: PropTypes.string.isRequired,
};
