import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import memoizeOne from "memoize-one";

import {
  VendorsWrapper,
  SelectedVendorsWrapper,
  Aside,
  Footer,
  Main,
} from "./components";
import SelectedVendors from "./SelectedVendors";
import UnselectedVendors from "./UnselectedVendors";
import ActionButtons from "../../../forms/ActionButtons";
import ModalTitle from "../../../layout/AppLayout/ModalDialog/ModalTitle";
import propTypes from "../../../../constants/propTypes";
import { dataComponentBGVendorId } from "./AddVendorContainer";

const getActionText = memoizeOne(length => {
  return length > 0 ? `Add ${pluralize("Vendor", length, true)}` : "Add Vendor";
});

const getHeaderText = memoizeOne(length => {
  return length > 0 ? pluralize("Vendor", length, true) : "No Vendor Selected";
});

const AddVendor = ({
  bidGroup,
  vendors,
  vendorsLoading,
  closeModalDialog,
  onCreateBidGroupVendors,
}) => {
  const [unassignedItems, setUnassignedItems] = useState(vendors);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setUnassignedItems(vendors);
  }, [vendors]);

  const addSelectedItem = useCallback(
    item => {
      setSelectedItems([...selectedItems, item]);
      setUnassignedItems(unassignedItems.filter(({ id }) => id !== item.id));
    },
    [selectedItems, unassignedItems]
  );

  const handleSubmit = useCallback(() => {
    const quoteProjectCurrencyId =
      bidGroup.specs && bidGroup.specs.length
        ? bidGroup.specs[0].projectCurrencyId
        : null;
    onCreateBidGroupVendors(
      selectedItems.map(({ id: vendorId }) => ({
        vendorId,
        bidGroupId: bidGroup.id,
        quoteProjectCurrencyId,
      }))
    );
  }, [bidGroup.id, bidGroup.specs, onCreateBidGroupVendors, selectedItems]);

  const removeSelectedItem = useCallback(
    item => {
      const filteredSelectedItems = selectedItems.filter(
        ({ id }) => id !== item.id
      );
      const selectedIds = filteredSelectedItems.map(({ id }) => id);
      setUnassignedItems(vendors.filter(({ id }) => !selectedIds.includes(id)));
      setSelectedItems(filteredSelectedItems);
    },
    [selectedItems, vendors]
  );

  const headerText = getHeaderText(selectedItems.length);
  const actionText = getActionText(selectedItems.length);

  return (
    <VendorsWrapper>
      <Main>
        <UnselectedVendors
          vendors={unassignedItems}
          loading={vendorsLoading}
          addSelectedItem={addSelectedItem}
        />
      </Main>
      <Aside>
        <ModalTitle
          width={"100%"}
          title={"Select Vendors"}
          subtitle={headerText}
        />
        <SelectedVendorsWrapper>
          <SelectedVendors
            bidGroup={bidGroup}
            vendors={selectedItems}
            loading={vendorsLoading}
            removeSelectedItem={removeSelectedItem}
          />
        </SelectedVendorsWrapper>
        <Footer>
          <ActionButtons
            onDanger={closeModalDialog}
            dangerButtonText="Cancel"
            sendButtonText={actionText}
            onSend={handleSubmit}
            disabled={selectedItems.length === 0}
            listeners={[dataComponentBGVendorId]}
            hideCancelButton
            emphasizeCancel
          />
        </Footer>
      </Aside>
    </VendorsWrapper>
  );
};

AddVendor.propTypes = {
  vendors: PropTypes.arrayOf(propTypes.vendor),
  removeSelectedItem: PropTypes.func,
  bidGroup: propTypes.bidGroup,
  vendorsLoading: PropTypes.bool,
  closeModalDialog: PropTypes.func,
  onCreateBidGroupVendors: PropTypes.func,
};

export default AddVendor;
