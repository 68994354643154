import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import FormGrid from "../../../../forms/FormGrid";
import ActionButtons from "../../../../forms/ActionButtons";
import FormikForm from "../../../../forms/FormikForm";

const validationSchema = Joi.object().keys({
  notes: Joi.string().required(),
});
import propTypes from "../../../../../constants/propTypes";
import { handleUpdateNote } from "./QuotoeNoteFunctions";
import quoteNoteFields from "./notesFields";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

const QuoteNotesContainer = props => {
  const {
    bidGroupVendorSpecQuote,
    dataComponentId,
    onUpdateNote,
    performUpdateRequest,
    setSnackMessage,
    readOnly,
  } = props;

  const isProjectClosed = useIsProjectClosed();
  const disabled = readOnly || isProjectClosed;
  return (
    <FormikForm
      initialValues={{
        id: bidGroupVendorSpecQuote.id,
        notes: bidGroupVendorSpecQuote.notes,
      }}
      onSubmit={values =>
        handleUpdateNote(
          values,
          onUpdateNote,
          dataComponentId,
          performUpdateRequest,
          setSnackMessage
        )
      }
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <FormGrid fields={quoteNoteFields(disabled)} />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
            disabled={disabled}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};
QuoteNotesContainer.propTypes = {
  bidGroupVendorSpecQuote: propTypes.bidGroupVendorSpec,
  dataComponentId: PropTypes.string.isRequired,
  onUpdateNote: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default QuoteNotesContainer;
