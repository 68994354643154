const isInvalidEvent = (evt, forceSameTarget) =>
  (forceSameTarget && evt.currentTarget !== evt.target) ||
  evt.isPropagationStopped();

export const buildPressEventHandlers = (
  handler,
  forceSameTargetOnKeyUp = true,
  forceSameTargetOnClick = true,
  includedKeys = ["Enter"],
  dismissSnackNotificationAction
) => {
  const onClick = evt => {
    if (isInvalidEvent(evt, forceSameTargetOnClick)) return;
    dismissSnackNotificationAction && dismissSnackNotificationAction();
    evt.preventDefault();
    evt.stopPropagation();
    handler(evt);
  };

  const onKeyUp = evt => {
    if (
      !includedKeys.includes(evt.key) ||
      isInvalidEvent(evt, forceSameTargetOnKeyUp)
    )
      return;
    evt.preventDefault();
    evt.stopPropagation();
    handler(evt);
  };

  return { onClick, onKeyUp };
};
