import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormikForm, FormGrid } from "../../../../components/forms";
import createPurchaseOrderFields from "./createPurchaseOrderFields";
import { RequiresApprovalActionButton } from "./RequiresApprovalActionButton";

class CreatePurchaseOrderForm extends Component {
  purchaseOrderInitialValues = {
    projectId: this.props.projectId,
    vendorId: "",
  };

  handleCancel = () => {
    this.props.closeModalDialog();
  };

  render() {
    const {
      validationSchema,
      createPurchaseOrder,
      dataComponentId,
    } = this.props;
    return (
      <FormikForm
        initialValues={this.purchaseOrderInitialValues}
        onSubmit={createPurchaseOrder}
        validationSchema={validationSchema}
      >
        {({ values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createPurchaseOrderFields()}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <RequiresApprovalActionButton
              handleCancel={this.handleCancel}
              dataComponentId={dataComponentId}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}

CreatePurchaseOrderForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
  createPurchaseOrder: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
};

export default CreatePurchaseOrderForm;
