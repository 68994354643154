import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import {
  createRemitAddressDataComponent,
  updateRemitAddress,
  CreateDataComponentId,
} from "../../../../actions/remitAddressAction";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";

import useActions from "../../../hooks/useActions";
import useCreateRemitAddressStore from "../../../hooks/useCreateRemitAddressStore";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { parsePointOfContact } from "../CreateRemitAddress/createRemitAddressFunctions";
import Form from "../CreateRemitAddress/Form";

const EditRemitAddress = ({ remitAddress, titleModal, vendor }) => {
  const formikActionsRef = useRef();
  const stateActions = useCreateRemitAddressStore(state => state.actions);

  const actions = useActions({
    createRemitAddressDataComponent,
    updateRemitAddress,
    closeModalDialog,
  });

  useEffect(() => {
    actions.createRemitAddressDataComponent();
    stateActions.openRemitAddressForm();
    stateActions.setTitleModal(titleModal);
    stateActions.setVendor(vendor);

    const pointOfContacts = _get(remitAddress, "pointOfContacts", []);
    stateActions.setCurrentForm({ ...remitAddress, pointOfContacts });
    return () => {
      stateActions.resetStore();
    };
  }, [actions, stateActions, titleModal, vendor, remitAddress]);

  const dataComponent = useDatacomponent(CreateDataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => actions.closeModalDialog(),
    onError: error => handleRequestError(error, formikActionsRef.current),
  });

  const handleEditRemitAddress = (remitAddress, formikActions) => {
    formikActionsRef.current = formikActions;
    actions.updateRemitAddress({
      ...remitAddress,
      pointOfContacts: remitAddress.pointOfContacts.map(parsePointOfContact),
    });
  };

  return (
    <Form
      vendorId={remitAddress.vendorId}
      onSubmit={handleEditRemitAddress}
      dataComponentId={CreateDataComponentId}
      isUpdate
    />
  );
};

EditRemitAddress.propTypes = {
  remitAddress: propTypes.remitAddress,
  vendor: propTypes.vendor,
  titleModal: PropTypes.string,
};

export default EditRemitAddress;
