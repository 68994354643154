import {
  initDataComponent,
  performRetrieveListRequest,
} from "./dataComponentActions";
import Vendor from "../models/Vendor";

export const dataComponentId = "VendorPortalVendorSelector";

export const fetchGlobalVendors = () => {
  return (dispatch, getState) => {
    const { auth } = getState();
    dispatch(
      initDataComponent(
        dataComponentId,
        Vendor,
        ["location"],
        "vendors",
        false,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        pageSize: -1,
        sort: [{ columnName: "name", direction: "desc" }],
        rootFilters: {
          $where: { "contacts.contact.user.id": auth.userId },
        },
      })
    );
  };
};
