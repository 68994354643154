import styled from "styled-components";

export const Wrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 24px 24px 24px;
`;
export const ItemWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 4px 0px;
`;
export const RowItemWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
  padding: 6px 0px;
`;

export const OptionsWrapper = styled.div`
  display: flex;

  & ${/* sc-selector */ Wrapper}:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: 14px;
`;

export const Value = styled.div`
  font-size: 20px;
  font-family: Domine, sans-serif;
  width: ${({ noTitle }) => (noTitle ? "100%" : "auto")};
`;
export const Bar = styled.div`
  background: rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: 100%;
  height: 8px;
`;
export const Fill = styled.div`
  background-color: ${props => (props.needsFollowUp ? "#FF1744" : "#72B327")};
  width: ${props => props.width}%;
  height: 8px;
`;
