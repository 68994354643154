import React from "react";
import { useHistory } from "react-router-dom";
import pluralize from "pluralize";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import _get from "lodash/get";
import moment from "moment";

import StatusTag from "../../ui/Tags/StatusTag";
import TagScope from "../../ui/Tags/TagScope";
import FavoriteControl from "./FavoriteControl";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import theme from "../../ui/Tags/theme";
import PersonList from "../../ui/Tags/PersonList";
import { BillingStatusWrapper, DollarIcon, BillingText } from "./components";

export const TeamFormatter = ({
  row: { projectLead, projectMemberUsers = [] },
}) => {
  const members = [];
  if (projectLead) {
    const projectLeadMemberUser = {
      memberType: "Project Lead",
      user: projectLead,
    };
    members.push(projectLeadMemberUser);
  }
  projectMemberUsers.map(projectMemberUser => {
    members.push(projectMemberUser);
  });
  return (
    <ThemeProvider theme={{ tag: theme.small }}>
      <PersonList members={members} type="user" maxTags={3} />
    </ThemeProvider>
  );
};

TeamFormatter.propTypes = {
  row: PropTypes.shape({ scopeFfe: PropTypes.bool, scopeOse: PropTypes.bool }),
};

export const TeamTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={TeamFormatter}
    {...props}
    for={["team.name"]}
  />
);

export const ScopeFormatter = ({
  row: { workScopes, projectSubWorkScopes, isPublicSubScopeNotes },
}) => {
  return (
    <TagScope
      workScopes={workScopes}
      projectSubWorkScopes={projectSubWorkScopes}
      isPublicSubScopeNotes={isPublicSubScopeNotes}
    />
  );
};

ScopeFormatter.propTypes = {
  row: PropTypes.shape({
    projectSubWorkScopes: PropTypes.array,
  }),
};

export const ScopeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ScopeFormatter}
    {...props}
    for={["workScopes.id"]}
  />
);

const StatusFormatter = ({ value: status }) => {
  return <StatusTag>{status}</StatusTag>;
};

StatusFormatter.propTypes = {
  value: PropTypes.string,
};

export const StatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={StatusFormatter}
    {...props}
    for={["status"]}
  />
);

export const propertyFormatter = row => {
  const property = _get(row, "property", {});
  const city = _get(property, "location.city", "");
  const state = _get(property, "location.state", "");

  const address = property.location
    ? `, ${[city, state].filter(value => value).join(", ")}`
    : "";
  return `${property.name}${address}`;
};

export const PropertyTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ row }) => propertyFormatter(row)}
    {...props}
    for={["property"]}
  />
);

export const FavoriteFormatter = ({ row }) => {
  return <FavoriteControl projectId={row.id} />;
};

FavoriteFormatter.propTypes = {
  row: PropTypes.shape({ name: PropTypes.string, msaClient: PropTypes.bool }),
};

export const FavoriteTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={FavoriteFormatter}
    {...props}
    for={["isFavorite"]}
  />
);

export const BillingStatusFormatter = ({ row }) => {
  const history = useHistory();
  const labelOptions = {
    green: () => "Ok",
    red: () => {
      const dueDate = moment(row.billingDueDate).startOf("day");
      const days = moment()
        .startOf("day")
        .diff(dueDate, "days");
      return `${days} ${pluralize("Day", days)}`;
    },
    yellow: () => {
      const billingDate = moment(row.billingPendingdate).startOf("day");
      const days = billingDate.diff(moment().startOf("day"), "days");
      return `${days} ${pluralize("Day", days)}`;
    },
  };

  const handleClick = event => {
    event.stopPropagation();
    history.push(`/billing/approvals`);
  };

  const label = _get(labelOptions, row.billingFlag, () => {})(row);

  return label ? (
    <BillingStatusWrapper onClick={handleClick} billingFlag={row.billingFlag}>
      <DollarIcon />
      <BillingText>{label}</BillingText>
    </BillingStatusWrapper>
  ) : null;
};

BillingStatusFormatter.propTypes = {
  row: PropTypes.shape({
    billingDueDate: PropTypes.date,
    billingPendingdate: PropTypes.date,
  }),
};

export const BillingStatusProvider = props => (
  <DataTypeProvider
    formatterComponent={BillingStatusFormatter}
    {...props}
    for={["billingFlag"]}
  />
);
