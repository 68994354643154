import {
  fetchDataRequest,
  performUpdateRequest,
  performRetrieveListRequest,
  initDataComponent,
} from "./dataComponentActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import Term from "../models/Term";

export function reorderTerms(dataComponentId, itemId, newIndex, params) {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.LIST));

    dispatch(
      performUpdateRequest(
        dataComponentId,
        itemId,
        {
          displayOrder: newIndex,
        },
        params
      )
    );
  };
}

export function fetchProjectTerms(dataComponentId, projectId) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        Term,
        [],
        `projects/${projectId}/terms`
      )
    );

    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {},
        pageSize: -1,
      })
    );
  };
}
