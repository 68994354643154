import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotesWrapper from "./NotesWrapper";
import { clearNotes } from "../../../actions/notesActions";
import {
  initUserComponent,
  updateUser,
  updateNoteLastVisited,
} from "../../../actions/usersActions";

const NotesContainer = ({
  clearNotes,
  userId,
  initUserComponent,
  updateNoteLastVisited,
  updateUser,
  onCloseModal,
}) => {
  useEffect(() => {
    const now = new Date();
    initUserComponent();
    updateUser(userId, {
      noteLastVisited: now,
    });
    updateNoteLastVisited(now);
  }, [initUserComponent, updateNoteLastVisited, updateUser, userId]);
  useEffect(() => {
    return () => clearNotes();
  }, [clearNotes]);
  return <NotesWrapper onCloseModal={onCloseModal} />;
};

NotesContainer.propTypes = {
  clearNotes: PropTypes.func.isRequired,
  userId: PropTypes.string,
  initUserComponent: PropTypes.func,
  updateNoteLastVisited: PropTypes.func,
  updateUser: PropTypes.func,
  onCloseModal: PropTypes.func,
};

const mapDispatchToProps = {
  clearNotes,
  initUserComponent,
  updateUser,
  updateNoteLastVisited,
};

const mapStateToProps = ({ auth: { userId } }) => {
  return { userId };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
