import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { connect } from "react-redux";

import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
} from "../../../ui/Grid/filterDataTypeProviders";
import { useFlattenedDatacomponent } from "../../../hooks/useDatacomponent";
import PagingPaneWithStatusSwitch, {
  Switches,
} from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { genDetailTableComponents } from "../../../ui/BWGrid/genDetailTableComponents";
import Project from "../../../../models/Project";
import { BWGridAPI, GridHeader, GridRow, GridTable } from "../../../ui/BWGrid";
import { columns, getColumnOptions } from "./gridProps";
import ProjectFees from "./ProjectFees";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import Fee from "../../../../models/Fee";
import { getDataComponentId } from "./ProjectFees/ProjectFeesContainer";

export const dataComponentId = "billing-received-table";

export const loadFees = (
  performRetrieveListRequest,
  dataComponentId,
  projectId
) => {
  performRetrieveListRequest(dataComponentId, {
    rootFilters: {
      $where: {
        projectId,
      },
    },
    sort: [{ columnName: "billDate", direction: "asc" }],
    pageSize: -1,
  });
};

const getStautsFilterArray = (showInClosingProjects, showClosedProjects) => {
  const statusFilterArray = [];
  if (!showInClosingProjects) {
    statusFilterArray.push("In Closing");
  }
  if (!showClosedProjects) {
    statusFilterArray.push("Closed");
  }
  return statusFilterArray;
};

const Grid = ({
  showInClosingProjects,
  showClosedProjects,
  initDataComponent,
  performRetrieveListRequest,
}) => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);
  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider(["projectLead.name", "name", "number"]),
      buildStaticSelectTypeProvider(["projectLead.name"]),
    ],
    []
  );

  const statusFilterArray = getStautsFilterArray(
    showInClosingProjects,
    showClosedProjects
  );

  const onExpand = ({ id }) => {
    const dataComponentId = getDataComponentId(id);
    initDataComponent(
      dataComponentId,
      Fee,
      ["feePayments(withOrderByPaymentDate)"],
      "fees",
      true,
      "v2"
    );
    loadFees(performRetrieveListRequest, dataComponentId, id);
  };

  return (
    <BWGridAPI
      model={Project}
      apiRoute="projects"
      includes={["projectLead", "projectCurrencies.currency"]}
      defaultSorting={[{ columnName: "number", direction: "asc" }]}
      apiFilters={{
        rootFilters: {
          $where: {
            status: { $notIn: statusFilterArray },
          },
        },
      }}
      displayCollapseButton
      dataComponent={dataComponent}
      columnExtensions={[]}
      tableComponents={{
        PagingPanelTemplate: PagingPaneWithStatusSwitch,
        GridHeader: useHeaderComponent({
          headerText: `${pluralize("Project", dataComponent.totalRows, true)}`,
        }),
        ...genDetailTableComponents({ colSpan: 7, prevColSpan: 0 }),
        DetailRowComponent: ProjectFees,
      }}
      filteringDataTypeProviders={filteringDataTypeProviders}
      onExpand={onExpand}
    >
      <GridHeader headerText={`${dataComponent.totalRows} Projects`} />
      <GridTable columns={columns} columnOptions={getColumnOptions()} />
      <GridRow>
        <Switches />
      </GridRow>
    </BWGridAPI>
  );
};

Grid.propTypes = {
  showInClosingProjects: PropTypes.bool,
  showClosedProjects: PropTypes.bool,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(null, mapDispatchToProps)(Grid);
