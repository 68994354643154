import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import TagText from "../../../../ui/Tags/TagText";
import propTypes from "../../../../../constants/propTypes";
import QuotingActions from "../../UnassignedSpecs/UnassignedOnBidGroups/QuotingActions";

const Wrapper = styled.div({});

const StyledTagText = styled(TagText)`
  margin-right: 16px;
`;

const getText = (count, prefix, suffix, emptyLabel) => {
  const isSet = count !== 0;
  return isSet
    ? `${count} ${prefix}${count > 1 ? "s" : ""} ${suffix}`
    : emptyLabel;
};

const countBidGroupVendorProperty = (bidGroupVendors, property) =>
  bidGroupVendors.filter(bidGroupVendor => !!bidGroupVendor[property]).length;

const BidGroupStatus = ({
  bidGroup,
  bidGroups,
  onOpenCreateBidModal,
  resetSelection,
  selectedIds,
  selectedProjectCurrencyId,
}) => {
  const labelOptions = useMemo(
    () => ({
      remove: "Remove Spec(s)",
      create: "Create Another Bid Group",
      edit: "Rename Bid Group",
      add: "Move To",
    }),
    []
  );

  const rfqCount = countBidGroupVendorProperty(
    bidGroup.bidGroupVendors,
    "rfqSentAt"
  );
  const isRfqSent = rfqCount !== 0;
  const rfqSentText = getText(rfqCount, "RFQ", "Sent", "RFQ Not Sent");

  const quoteReceivedCount = countBidGroupVendorProperty(
    bidGroup.bidGroupVendors,
    "quoteReceivedAt"
  );
  const isQuoteReceived = quoteReceivedCount !== 0;
  const quoteReceivedText = getText(
    quoteReceivedCount,
    "Quote",
    "Received",
    "No Quotes Received"
  );

  return (
    <Wrapper>
      <StyledTagText intent={isRfqSent ? "success" : "none"}>
        {rfqSentText}
      </StyledTagText>
      <StyledTagText intent={isQuoteReceived ? "success" : "none"}>
        {quoteReceivedText}
      </StyledTagText>
      <QuotingActions
        onOpenCreateBidModal={onOpenCreateBidModal(selectedIds, {
          currentBidGroup: bidGroup,
          resetSelection,
        })}
        onOpenEditBidModal={onOpenCreateBidModal(selectedIds, {
          currentBidGroup: bidGroup,
          editBidGroup: true,
        })}
        bidGroups={bidGroups}
        currentBidGroup={bidGroup}
        labelOptions={labelOptions}
        isBidGroupOption
        selectedIds={selectedIds}
        selectedProjectCurrencyId={selectedProjectCurrencyId}
        resetSelection={resetSelection}
      />
    </Wrapper>
  );
};

BidGroupStatus.propTypes = {
  bidGroup: propTypes.bidGroup.isRequired,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  onOpenCreateBidModal: PropTypes.func,
  resetSelection: PropTypes.func,
  selectedIds: PropTypes.array,
  selectedProjectCurrencyId: PropTypes.string,
};

export default BidGroupStatus;
