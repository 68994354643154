import { useMemo } from "react";
import Spec from "../../../../../models/Spec";
import { usePerformRetrieveList } from "../../../../hooks/usePerformRetrieveList";

const dataComponent = {
  dataComponentId: "deletedSpecsDataComponentId",
  model: Spec,
  includes: [],
  apiRoute: "specs",
};

const getRevisionActivitySpecIds = (revisionActivities = [], operation) =>
  revisionActivities
    .filter(
      revisionActivity =>
        revisionActivity.operation == operation &&
        /^specs\.[0-9]+$/.test(revisionActivity.reference)
    )
    .map(({ reference }) => reference.split(".").at(1));

export const useRetrieveSpecs = ({ purchaseOrder, revisionActivities }) => {
  const createdSpecIds = useMemo(
    () => getRevisionActivitySpecIds(revisionActivities, "create"),
    [revisionActivities]
  );
  const deletedSpecIds = useMemo(
    () => getRevisionActivitySpecIds(revisionActivities, "delete"),
    [revisionActivities]
  );
  const updatedQuantities = revisionActivities.filter(
    ({ operation }) => operation == "update"
  );

  const filters = useMemo(
    () => ({
      pageSize: -1,
      rootFilters: {
        $where: {
          $or: [
            {
              id: {
                $in: deletedSpecIds,
              },
            },
            {
              purchaseOrderId: purchaseOrder.id,
            },
          ],
        },
      },
      sort: [{ columnName: "purchaseOrderId", direction: "asc" }],
      params: { omitDefaultModifier: true, modifiers: ["withQuantityPrice"] },
    }),
    [deletedSpecIds, purchaseOrder.id]
  );
  const { isLoading, data: specs } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const permanentDeletedSpecs = specs.filter(
    ({ purchaseOrderId }) =>
      purchaseOrderId && purchaseOrderId != purchaseOrder.id
  );

  const deletedSpecIdsSet = new Set(
    [...permanentDeletedSpecs].map(({ id }) => id)
  );
  const createdSpecIdsSet = new Set(createdSpecIds);

  const filteredSpecs = specs
    .filter(
      ({ id }) => !deletedSpecIdsSet.has(id) && !createdSpecIdsSet.has(id)
    )
    .map(spec => ({
      ...spec,
      totalQuantity:
        updatedQuantities.find(
          ({ reference, columnName }) =>
            reference == `specs.${spec.id}` && columnName == "totalQuantity"
        )?.previous || spec.totalQuantity,
      priceCents:
        updatedQuantities.find(
          ({ reference, columnName }) =>
            reference == `specs.${spec.id}` && columnName == "priceCents"
        )?.previous || spec.priceCents,
    }));

  return {
    isLoading,
    specs: filteredSpecs,
    permanentDeletedSpecs,
  };
};
