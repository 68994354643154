import React, { useEffect } from "react";
import styled from "styled-components";

import { openModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import { LoaderContext } from "../../../../ui/Loader";
import Subheader from "../../../../ui/Typography/Subheader";
import Paper from "../../../../mui/core/Paper";
import useActions from "../../../../hooks/useActions";
import {
  initPOFiles,
  poFilesDataComponentId,
} from "../../../../../actions/purchaseOrdersActions";
import { haveActiveRequests } from "../../../../../utils/dataComponentUtils";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import FileLink from "../../../SpecDetail/AdditionalInformation/SpecFiles/FileLink";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

const fileTypes = [
  { label: "Invoice", key: "invoice" },
  { label: "Care & Warranty", key: "careWarranty" },
];

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-bottom: 16px;
`;

const PurchaseOrderFiles = ({ purchaseOrder }) => {
  const purchaseOrderId = purchaseOrder?.id;

  const actions = useActions({ initPOFiles, openModalDialog });
  const dataComponent = useDatacomponent(poFilesDataComponentId);
  const poFiles = useV2DatacomponentResources(
    poFilesDataComponentId,
    [],
    RequestTypes.LIST
  );

  useEffect(() => {
    if (purchaseOrderId) {
      actions.initPOFiles(purchaseOrderId);
    }
  }, [purchaseOrderId, actions]);

  const loading = haveActiveRequests(dataComponent);
  const isProjectClosed = useIsProjectClosed();

  const handleOpenManager = (label, fileType, acceptImage) => {
    actions.openModalDialog(
      ["Purchase Order Files", `${label} for PO# ${purchaseOrder.number}`],
      "AddPOFile",
      { fileType, acceptImage, purchaseOrderId },
      false,
      true
    );
  };

  return (
    <LoaderContext.Provider value={{ loading: loading || !purchaseOrderId }}>
      <Paper>
        <Header>
          <Subheader>Purchase Order Files</Subheader>
        </Header>
        {fileTypes.map(({ label, key, acceptImage }) => (
          <FileLink
            key={key}
            label={label}
            fileType={key}
            files={poFiles.filter(poFile => poFile.key === key)}
            acceptImage={acceptImage}
            onOpenManager={handleOpenManager}
            isLabeled={isProjectClosed}
          />
        ))}
      </Paper>
    </LoaderContext.Provider>
  );
};

PurchaseOrderFiles.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
};

export default PurchaseOrderFiles;
