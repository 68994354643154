import { isEmpty } from "lodash";

export const handleSuccess = (
  performFindRequest,
  projectDataComponentId,
  projectId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog,
  onSuccess
) => {
  performFindRequest(projectDataComponentId, projectId);
  showSnackNotificationAction(snackMessage);
  closeModalDialog && closeModalDialog();
  onSuccess && onSuccess();
};
export const handleError = (data, showSnackNotificationAction) => {
  const errors = data?.errors.filter(({ global }) => global);
  if (isEmpty(errors)) {
    showSnackNotificationAction("There was an error processing the request");
    return;
  }
  errors.forEach(({ title }) => showSnackNotificationAction(title));
};
