import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Dialog from "./Dialog";
import {
  logout,
  validateAuthentication,
} from "../../../../actions/authActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import SessionExpiredDialog from "./SessionExpiredDialog";

export const DialogContainer = ({
  logout,
  validateAuthentication,
  closeModalDialog,
}) => {
  const [counter, setCounter] = useState(60);
  const [sessionExpired, setSessionExpired] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    if (counter > 0) {
      timeout.current = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setSessionExpired(true);
    }
    return () => clearTimeout(timeout.current);
  }, [counter, logout]);

  const handleDismiss = () => {
    closeModalDialog();
    validateAuthentication(false);
  };

  if (sessionExpired) {
    return <SessionExpiredDialog onSend={logout} />;
  }

  return <Dialog onDismiss={handleDismiss} seconds={counter} />;
};

DialogContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  validateAuthentication: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

export default connect(null, {
  logout,
  validateAuthentication,
  closeModalDialog,
})(DialogContainer);
