import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InputWrapper from "../../../inputs/InputWrapper";
import CheckboxWithError from "../../../inputs/CheckboxWithError";
import { setNoteFilters } from "../../../../actions/notesActions";
import propTypes from "../../../../constants/propTypes";
import { TooltipWrapper } from "./TooltipWrapper";

import PersonIcon from "@material-ui/icons/Person";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";

const List = styled.ul`
  position: relative;
  padding: 0;
  padding-bottom: 8px;
  margin: 0;
  margin-bottom: 24px;
  list-style: none;
  &:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
  }
`;

const Item = styled.li`
  padding-left: 20px;

  @media (max-width: 1170px) {
    padding-left: 0px;
    text-align: center;
    display: grid;
  }
`;

const StyledTooltipWrapper = styled(TooltipWrapper)`
  @media (max-width: 1170px) {
    span:nth-child(2) {
      display: none;
    }

    > label {
      margin-right: 0px !important;
    }
  }

  @media (max-width: 768px) {
    span:nth-child(2) {
      display: initial;
    }

    span label {
      margin-right: initial;
    }
  }
`;

const checkboxes = [
  {
    name: "myNotes",
    label: "View My Notes",
    icon: <FormatQuoteIcon style={{ fill: "dimgrey" }} />,
    checkedIcon: <FormatQuoteIcon style={{ fill: "#57abff" }} />,
  },
  {
    name: "myMentions",
    label: "View My Mentions",
    icon: <PersonIcon style={{ fill: "dimgrey" }} />,
    checkedIcon: <PersonIcon style={{ fill: "#57abff" }} />,
  },
];

const CheckboxList = ({ filters, setNoteFilters }) => {
  const handleChangeChecks = ({ target: { name, value } }) =>
    setNoteFilters({ checks: { ...filters.checks, [name]: value } });

  return (
    <List>
      <Item>
        {checkboxes.map(({ name, label, icon, checkedIcon }) => (
          <StyledTooltipWrapper key={name} title={label}>
            <InputWrapper
              key={name}
              InputComponent={CheckboxWithError}
              name={name}
              label={label}
              value={filters.checks[name]}
              isNotesFilter
              onChange={handleChangeChecks}
              icon={icon}
              checkedIcon={checkedIcon}
            />
          </StyledTooltipWrapper>
        ))}
      </Item>
    </List>
  );
};
CheckboxList.propTypes = {
  filters: propTypes.notesFilters,
  setNoteFilters: PropTypes.func.isRequired,
};

const mapStateToProps = ({ notes: { filters } }) => ({ filters });

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxList);
