import React, { useEffect, useRef, useState } from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import RootRef from "@material-ui/core/RootRef";
import styled from "styled-components";
import _isNil from "lodash/isNil";
import _get from "lodash/get";

import BoldText from "../../../Typography/BoldText";
import Loader from "../../../Loader";

export const CellContentWrapper = styled(({ align, bold, ...props }) => {
  if (bold) {
    return <BoldText as="span" {...props} />;
  }
  return <span {...props} />;
})`
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-align: ${({ align }) => align};
`;

const getCellContent = (children, value) => {
  if (!_isNil(children)) return children;
  if (typeof value !== "object") return _isNil(value) ? "" : value;
  if (React.isValidElement(value)) return value;
  return "";
};

const componentByScope = { row: "th", default: "td" }; //for accessibility purposes

export const StyledCell = styled(
  ({ isFixed, maxWidth, minWidth, ...props }) => {
    const { scope = "default" } = props;
    return <Table.Cell {...props} component={componentByScope[scope]} />;
  }
)`
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  position: ${({ isFixed }) => isFixed && "sticky !important"};
  overflow: visible;
  border-bottom: ${({ column: { borderStyle } }) =>
    borderStyle
      ? `1px ${borderStyle}  rgba(224, 224, 224, 1)`
      : `1px "solid" rgba(224, 224, 224, 1)`};
  border-right: ${({ column: { displayBorderRight } }) =>
    displayBorderRight ? "1px solid rgba(224, 224, 224, 1)" : "none"};
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${({ column: { displayBorderRight } }) =>
    displayBorderRight && "24px"};
  padding-left: ${({ column: { firstColumn } }) => firstColumn && "24px"};
  background-color: ${({ column: { backGroundColor } }) =>
    backGroundColor ? backGroundColor : "#FFFFFF"} !important;
  cursor: unset;
`;

const Cell = ({ children, value, ...props }) => {
  const ref = useRef(null);
  const [title, setTitle] = useState("Empty");
  const isFixed = !!_get(props, "tableColumn.fixed");
  const width = _get(props, "column.width");
  const maxWidth = _get(props, "column.maxWidth");
  const minWidth = _get(props, "column.minWidth");
  const align = _get(props, "tableColumn.align");
  const scope = props.column.scope;

  const content = getCellContent(children, value);

  useEffect(() => {
    setTitle(_get(ref, "current.innerText", "Empty"));
  }, [content]);

  const cellChildren = (
    <CellContentWrapper align={align} bold={props.column.bold}>
      {content}
    </CellContentWrapper>
  );

  return (
    <RootRef rootRef={ref}>
      <StyledCell
        isFixed={isFixed}
        maxWidth={width || maxWidth}
        minWidth={width || minWidth}
        {...props}
        scope={scope}
        title={title}
      >
        <Loader>{cellChildren}</Loader>
      </StyledCell>
    </RootRef>
  );
};

export default Cell;
