import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import DatePickerWithError from "../../../../inputs/DatePickerWithError";
import RadioGroup from "../../../../inputs/RadioGroup";

const inputs = {
  shipmentsBilled: {
    name: "shipmentsBilled",
    InputComponent: CheckboxWithError,
    label: "Shipments Billed",
    ignoreCaption: true,
  },
  shipmentsUnbilled: {
    name: "shipmentsUnbilled",
    InputComponent: CheckboxWithError,
    label: "Shipments Unbilled",
    ignoreCaption: true,
  },
  billedDateFrom: {
    name: "billedDateFrom",
    InputComponent: DatePickerWithError,
    label: "Billed Date From",
    fullWidth: true,
  },
  billedDateTo: {
    name: "billedDateTo",
    InputComponent: DatePickerWithError,
    label: "Billed Date To",
    fullWidth: true,
  },
  exportType: {
    name: "exportType",
    InputComponent: RadioGroup,
    label: "Export Type",
    options: [
      {
        label: "Summarized",
        value: "summarized",
      },
      {
        label: "Detailed",
        value: "detailed",
      },
    ],
    formLabelProps: {
      required: true,
    },
  },
};

export const getFields = shipmentsBilled => ({
  groups: [
    {
      items: [
        {
          input: inputs.shipmentsBilled,
          grid: { xs: 12, noPaddingLeft: true },
        },
        {
          input: shipmentsBilled ? inputs.billedDateFrom : null,
          grid: { xs: 6 },
        },
        {
          input: shipmentsBilled ? inputs.billedDateTo : null,
          grid: { xs: 6 },
        },
        {
          input: inputs.shipmentsUnbilled,
          grid: { xs: 12, noPaddingLeft: true },
        },
      ],
    },
    {
      items: [
        {
          input: inputs.exportType,
          grid: { xs: 12 },
        },
      ],
    },
  ],
});
