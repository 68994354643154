import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TagText from "./TagText";
import { BWIntent } from "../BWIntent";
import { titleCase } from "../../../utils/stringUtils";

const STATUS_TO_INTENT = {
  "In Progress": BWIntent.SUCCESS,
  "Needs Follow Up": BWIntent.DANGER,
  "In Closing": BWIntent.SUCCESS,
  Contracted: BWIntent.SUCCESS,
  "On Hold": BWIntent.SUCCESS,
  Created: BWIntent.PRIMARY,
  "Funding Required": BWIntent.DANGER,
};

const StatusTag = styled(({ children, ...props }) =>
  children ? (
    <TagText {...props} intent={STATUS_TO_INTENT[children]}>
      {titleCase(children)}
    </TagText>
  ) : (
    <div />
  )
)`
  min-width: 99px;
`;

StatusTag.propTypes = {
  children: PropTypes.string,
};

export default StatusTag;
