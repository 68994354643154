import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import pluralize from "pluralize";

import SerifTitle from "../Typography/SerifTitle";
import RegularText from "../Typography/RegularText";
import ActionButtons from "../../forms/ActionButtons";
import { closeModalDialog } from "../../../actions/layoutActions";
import useActions from "../../hooks/useActions";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Title = styled(SerifTitle)`
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
`;

export const CountDownText = styled(RegularText)`
  margin-bottom: 16px;
`;

export const ReloadModal = ({ title, onReload, retryDelay = 30 }) => {
  const [seconds, setSeconds] = useState(retryDelay);

  const actions = useActions({ closeModalDialog });

  useEffect(() => {
    if (seconds === 0) {
      return;
    }
    setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds]);

  const handleReload = useCallback(() => {
    onReload();
    actions.closeModalDialog();
  }, [actions, onReload]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <CountDownText>
        Please try Reload
        {seconds ? ` after ${pluralize("second", seconds, true)}` : ""}.
      </CountDownText>
      <ActionButtons
        onSend={handleReload}
        sendButtonText="Reload"
        additionalProps={{
          send: {
            disabled: seconds > 0,
          },
        }}
        hideCancelButton
      />
    </Wrapper>
  );
};
ReloadModal.propTypes = {
  title: PropTypes.string.isRequired,
  onReload: PropTypes.func.isRequired,
  retryDelay: PropTypes.number,
};
