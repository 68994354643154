import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

export const OnScheduleIcon = styled(CheckCircleIcon)`
  color: #72b327;
`;

export const NeedsFollowUpIcon = styled(ReportProblemIcon)`
  color: #ff1744;
`;

const NeedsFollowUpColumn = ({ needsFollowUp }) =>
  needsFollowUp ? <NeedsFollowUpIcon /> : <OnScheduleIcon />;

NeedsFollowUpColumn.propTypes = {
  needsFollowUp: PropTypes.bool.isRequired,
};

export default NeedsFollowUpColumn;
