import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import Reorder from "@material-ui/icons/Reorder";
import { SortableHandle } from "react-sortable-hoc";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(Reorder)`
  margin-right: 20px;
  cursor: move;
  cursor: grab;
`;

const BWDraggableCell = SortableHandle(({ children, ...props }) => {
  return (
    <Wrapper>
      <Icon {...props} />
      {children}
    </Wrapper>
  );
});

BWDraggableCell.propTypes = {
  children: propTypes.component,
  isActive: PropTypes.bool,
};

export default (options, render) => {
  const Builder = (...args) =>
    options.dragHandle ? (
      <BWDraggableCell>{render(...args)}</BWDraggableCell>
    ) : (
      render(...args)
    );
  return Builder;
};
