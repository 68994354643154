import React, { forwardRef } from "react";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import Inbox from "@material-ui/icons/Inbox";
import EditIcon from "@material-ui/icons/Edit";

import SelectWithError from "../../../inputs/SelectWithError";
import Input from "../../../inputs/TextInputWithError";
import { PanelButtons } from "../../../forms/ActionButtons";
import AddCircle from "@material-ui/icons/AddCircle";

export const FormWrapper = styled.div`
  padding: 24px;
`;

export const SelectWrapper = styled.div`
  width: 60%;
`;

export const InputFile = styled.input`
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 65vh;
`;

export const ImportOption = styled(Wrapper)`
  color: #57abff;
  padding: 25px;
  font-size: 14px;
  flex-direction: row;
  font-weight: 600;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const StyledAddCircle = styled(AddCircle)`
  margin-right: 8px;
`;

export const ButtonsWrapper = styled.div`
  ${PanelButtons} {
    padding: 12px 0;
  }
`;

export const GridWrapper = styled.div`
  display: flex;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 24px;
  & > .grid-root {
    width: 100%;
  }
`;

export const Icon = styled(Inbox)`
  font-size: 48px;
`;

export const EmptyStateWrapper = styled(GridWrapper)`
  margin-bottom: 24px;
  min-height: 434px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
`;

export const Select = styled(SelectWithError)`
  width: 100%;
  && span {
    font-weight: normal !important;
    font-size: 14px;
  }
`;

export const StyledInputNumber = styled(({ isInvalid, ...props }) => (
  <Input {...props} />
))`
  input {
    font-weight: normal;
    font-size: 14px;
    color: ${({ isInvalid }) => isInvalid && "#ff1744"};
  }
`;

export const InputNumber = forwardRef((props, ref) => (
  <StyledInputNumber inputRef={ref} {...props} />
));

export const InputTextArea = styled(({ isInvalid, ...props }) => (
  <Input {...props} />
))`
  textarea {
    font-weight: normal;
    font-size: 14px;
    color: ${({ isInvalid }) => isInvalid && "#ff1744"};
  }
`;

export const DownloadLink = styled.a`
  color: #57abff;
  cursor: pointer;
  margin-left: 4px;
`;

export const StyledEditIcon = styled(EditIcon)`
  font-size: 18px;
  color: #979797;
  width: 18px;
  height: 18px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 80px;
`;

export const InputTextAreaWrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

export const StyledFormControl = styled(FormControl)`
  width: 60%;
`;
