import _get from "lodash/get";

const requiredFields = [
  "projectCurrencyId",
  "projectManagerId",
  "estimatedShipDate",
  "estimatedDeliveryDate",
  "freightVendorId",
  "shipVia",
  "leadTimesFrom",
  "leadTimesTo",
  "leadTimeForSubmittal",
  "factoryLocation",
  "fob",
  "paymentTermId",
  "hasFreightContacts",
  "hasVendorContacts",
  "hasDesignerContacts",
];
const requiredFieldsForChangeOrder = [
  ...requiredFields,
  "currentRevision.type",
  "currentRevision.notes",
];

const fieldsMessage = {
  projectCurrencyId: "'Currency' should be set",
  projectManagerId: "'Project Manager Listed on PO' should be set",
  estimatedShipDate: "'Estimated Ship' should be set",
  estimatedDeliveryDate: "'Estimated Delivery' should be set",
  freightVendorId: "'Freight' should be set",
  shipVia: "'Ship Via' should be set",
  shipAddressId: "Shipping location is missing",
  shipVendorAddressId: "Shipping location is missing",
  leadTimesFrom: "'Lead Times From' is missing",
  leadTimesTo: "'Lead Times To' is missing",
  leadTimeForSubmittal: "'Lead Time for Submittal' is missing",
  factoryLocation: "'Factory Location' is missing",
  fob: "'FOB Customs Cleared DDP' should be set",
  paymentTermId: "'Payment Terms' is missing",
  hasFreightContacts: "The PO should have at least one Freight contact",
  hasVendorContacts: "The PO should have at least one Vendor contact",
  hasDesignerContacts: "The PO should have at least one Designer contact",
};

export const commonIgnoredStatuses = ["Draft", "Denied", "Canceled"];

export const checkHasContactsByType = (purchaseOrderContacts = [], type) =>
  purchaseOrderContacts &&
  purchaseOrderContacts.filter(({ contactType }) => contactType === type)
    .length > 0;

export const getMissingRequiredFieldsForTransition = (
  po,
  ignoredStatuses = [],
  transitionToStatus = ""
) => {
  if (ignoredStatuses.includes(po.status)) return {};
  const fieldsToRequire =
    transitionToStatus === "Request Change Order Approval"
      ? requiredFieldsForChangeOrder
      : requiredFields;
  const errors = fieldsToRequire.reduce((errors, attribute) => {
    if (_get(po, attribute) == null) {
      return {
        ...errors,
        [attribute]: "This field can't be empty",
      };
    }
    return errors;
  }, {});

  if (!po.shipAddressId && !po.shipVendorAddressId && !po.shipVendorId) {
    errors.shipAddressId = "This field can't be empty";
    errors.shipVendorAddressId = "This field can't be empty";
  }
  return errors;
};

export const hasAllRequiredFieldsForTransition = (
  values,
  srcPurchaseOrder,
  ignoredStatuses,
  formikProps
) => {
  if (values.status === srcPurchaseOrder.status) return true;
  const errors = getMissingRequiredFieldsForTransition(values, ignoredStatuses);
  const isCompleted = Object.keys(errors).length === 0;
  if (!isCompleted) {
    formikProps.setFieldValue(
      "status",
      formikProps.initialValues.status,
      false
    );
    formikProps.setSubmitting(false);
    formikProps.setErrors({ ...formikProps.errors, ...errors });
  }
  return isCompleted;
};

export const getErrorMessage = missingFields => {
  const attrs = Object.keys(missingFields);
  return attrs.map(attr => fieldsMessage[attr]).join(", ");
};

export const poRequiredRevision = (purchaseOrderInstance, poValues) =>
  !poValues.revisionStatus &&
  purchaseOrderInstance.revisionStatus === "Approved";

export const isPOChangeDisabled = purchaseOrder =>
  _get(purchaseOrder, "status") === "Issued" &&
  !_get(purchaseOrder, "revisionStatus");
