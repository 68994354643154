import { BaseModel } from "@blue-chip/core";
import File from "./File";
import Project from "./Project";
import Team from "./Team";
import Office from "./Office";
import UserRole from "./UserRole";

export default class User extends BaseModel {
  static get belongsTo() {
    return [Project, UserRole, Office, Team, File];
  }
}
