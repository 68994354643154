import { FeeBillingDueDate } from "./components";

export const columns = [
  "number",
  "name",
  "billedShipmentsCount",
  "unbilledShipmentsCount",
  "amountOfUnbilledShipments",
  "feeBillingDueDate",
];

export const getColumnOptions = formatter => {
  return {
    number: {
      title: "Project Number",
      width: "14vw",
      filter: true,
    },
    name: {
      title: "Project Name",
      width: "24vw",
      filter: true,
    },
    billedShipmentsCount: {
      title: "# Shipments Billed",
      width: "4vw",
    },
    unbilledShipmentsCount: {
      title: "# Shipments Unbilled",
    },
    amountOfUnbilledShipments: {
      title: "Amount of Unbilled Shipments",
      render: ({ amountOfUnbilledShipments }) => {
        return formatter.format(amountOfUnbilledShipments);
      },
    },
    feeBillingDueDate: {
      title: "Billing Due Date",
      render: FeeBillingDueDate,
    },
  };
};
