import React, { memo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";

import {
  performRetrieveListRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import { BWGridLocal, GridHeader, GridTable } from "../../../../ui/BWGrid";
import { areEqual } from "../../../../ui/Grid/GridAPI";
import { columns, rowMenu } from "./gridProps";
import { columnOptions } from "./gridProps";
import propTypes from "../../../../../constants/propTypes";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { dataComponentId as feesDataComponentId, loadFees } from "../Grid";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import Fee from "../../../../../models/Fee";
import { openPaymentsDialog } from "../../../../../actions/feeSummaryActions";
import { openModalDialog } from "../../../../../actions/layoutActions";
import { getHandleOpenVoidModal } from "../../../ProjectDetail/Accounting/Fees/FeesContainer";
import { getDefaultProjectCurrency } from "../../../../helpers";

export const getDataComponentId = projectId =>
  `FeesPaymentReceived-${projectId}`;

const getTableComponents = memoizeOne(dataComponentId => ({
  ...genDetailRowComponents(
    "comment",
    "id",
    BWModels.loadSchema("Fee"),
    dataComponentId,
    5,
    2,
    { helperText: "Comment" },
    false,
    { apiRoute: "fees", model: Fee }
  ),
}));

const ProjectFees = ({
  fees,
  row: { id: projectId, projectCurrencies },
  dataComponent,
  performRetrieveListRequest,
  setReload,
  openPaymentsDialog,
  openModalDialog,
  performUpdateRequest,
}) => {
  const dataComponentId = dataComponent.dataComponentId;

  const performAfterCreatePayment = () => {
    setReload(feesDataComponentId, true);
    loadFees(performRetrieveListRequest, dataComponentId, projectId);
  };

  const { currency } = getDefaultProjectCurrency(projectCurrencies);
  const formatter = useCurrencyFormatter(currency);

  const handleUpdateStatus = status => ({ id }) => {
    performUpdateRequest(dataComponentId, id, { status });
  };

  const handleOpenVoidModal = getHandleOpenVoidModal({
    openModalDialog,
    handleUpdateStatus,
    dataComponentId,
  });

  return (
    <BWGridLocal
      id={dataComponentId}
      sorting={[{ columnName: "billDate", direction: "asc" }]}
      rows={fees}
      gridConfig={{ pageSize: -1, totalRows: fees.length }}
      isLoading={dataComponent.loading}
      emptyStateProps={{ intent: "empty", padding: "115px" }}
      noBorder
      tableComponents={getTableComponents(dataComponent.dataComponentId)}
      alwaysDisplayDetailRow
    >
      <GridHeader headerText={`${fees.length} Fees`} />
      <GridTable
        columns={columns}
        columnOptions={columnOptions(formatter)}
        rowMenu={rowMenu({
          openPaymentsDialog,
          dataComponentId,
          performAfterCreatePayment,
          handleOpenVoidModal,
        })}
      />
    </BWGridLocal>
  );
};

ProjectFees.propTypes = {
  fees: PropTypes.arrayOf(propTypes.fee),
  row: propTypes.project,
  dataComponent: propTypes.dataComponent,
  performRetrieveListRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  openPaymentsDialog: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, { row: { id: projectId } }) => {
  const dataComponentId = getDataComponentId(projectId);
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const fees = getBluechipResources(dataComponent, state) || [];
  return {
    dataComponent,
    fees,
  };
};

const mapDispatchToProps = {
  performRetrieveListRequest,
  setReload,
  openPaymentsDialog,
  openModalDialog,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ProjectFees, areEqual));
