import React from "react";
import PropTypes from "prop-types";

import {
  Title,
  Subtitle,
  TitleContainer,
} from "../../../layout/AppLayout/ModalDialog/ModalTitle";
import RegularTextSmall from "../../Typography/RegularTextSmall";
import { Wrapper, ContactInformation, Avatar } from "./components";
import { DetailLine, DetailLineEmail } from "./DetailLine";

export const initials = (first, last) =>
  `${first.slice(0, 1)}${last.slice(0, 1)}`;

export const displayLabel = (phoneNumber, fax, email) => {
  return phoneNumber || fax || email;
};
const ContactCard = ({
  personType,
  firstName,
  lastName,
  phoneNumber,
  email,
  fax,
}) => {
  return (
    <Wrapper>
      <TitleContainer>
        <Subtitle aria-label="Full Name">
          {firstName} {lastName}
        </Subtitle>
        <Title arial-label="Role">{personType}</Title>
        <Avatar role="presentation">
          <Subtitle>{initials(firstName, lastName)}</Subtitle>
        </Avatar>
      </TitleContainer>
      <ContactInformation aria-labelledby="contact-details-label">
        {displayLabel(phoneNumber, fax, email) && (
          <RegularTextSmall intent="light" id="contact-details-label">
            Contact Details
          </RegularTextSmall>
        )}
        {phoneNumber && <DetailLine label="Phone Number" value={phoneNumber} />}
        {fax && <DetailLine label="Fax" value={fax} />}
        {email && <DetailLineEmail value={email} />}
      </ContactInformation>
    </Wrapper>
  );
};

ContactCard.propTypes = {
  personType: PropTypes.string,
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  fax: PropTypes.string,
};

export default ContactCard;
