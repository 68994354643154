import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";

import CheckboxWithError from "../../../inputs/CheckboxWithError";
import TextInputWithError from "../../../inputs/TextInputWithError";

const Wrapper = styled.div``;

export const WorkScopeSubWorkScope = ({ projectId, workScopeSubWorkScope }) => {
  const {
    values: { projectSubWorkScopes = [] },
    setFieldValue,
  } = useFormikContext();

  const projectSubWorkScope = projectSubWorkScopes.find(
    ({ workScopeSubWorkScopeId }) =>
      workScopeSubWorkScopeId == workScopeSubWorkScope.id
  );
  const isActive = !!projectSubWorkScope;

  const handleCheckboxChange = useCallback(
    ({ target: { value: isAdding } }) => {
      const updatedProjectSubWorkScopes = isAdding
        ? [
            ...projectSubWorkScopes,
            { projectId, workScopeSubWorkScopeId: workScopeSubWorkScope.id },
          ]
        : projectSubWorkScopes.filter(
            ({ workScopeSubWorkScopeId }) =>
              workScopeSubWorkScopeId != workScopeSubWorkScope.id
          );
      setFieldValue("projectSubWorkScopes", updatedProjectSubWorkScopes);
    },
    [projectId, projectSubWorkScopes, setFieldValue, workScopeSubWorkScope.id]
  );

  const handleSubWorkScopeDescriptionChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue(
        `projectSubWorkScopes[${projectSubWorkScopes.indexOf(
          projectSubWorkScope
        )}].description`,
        value
      );
    },
    [projectSubWorkScope, projectSubWorkScopes, setFieldValue]
  );

  return (
    <Wrapper>
      <CheckboxWithError
        key={workScopeSubWorkScope.subWorkScope.id}
        label={workScopeSubWorkScope.subWorkScope.name}
        value={isActive}
        onChange={handleCheckboxChange}
      />
      <TextInputWithError
        value={projectSubWorkScope?.description}
        onChange={handleSubWorkScopeDescriptionChange}
        ignoreCaption
        fullWidth
        inputProps={{ disabled: !isActive }}
        isDisabled={!isActive}
      />
    </Wrapper>
  );
};

WorkScopeSubWorkScope.propTypes = {
  projectId: PropTypes.string,
  workScopeSubWorkScope: PropTypes.object.isRequired,
};
