import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";

export class SentryHandler extends Component {
  static propTypes = {
    isAuth: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
    userId: PropTypes.string,
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);
    if (process.env.SENTRY_PUBLIC_KEY) {
      const sentryPk = process.env.SENTRY_PUBLIC_KEY;
      const sentryPId = process.env.SENTRY_PROJECT_ID;
      const dsn = `https://${sentryPk}@o334289.ingest.us.sentry.io/${sentryPId}`;
      Sentry.init({
        environment: process.env.SENTRY_ENV,
        dsn,
        integrations: [
          Sentry.replayIntegration({
            stickySession: true,
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
              window.location.origin,
              process.env.API_URL,
            ],
          }),
        ],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isAuth && process.env.SENTRY_PUBLIC_KEY) {
      Sentry.getCurrentScope().setUser({
        name: nextProps.name,
        email: nextProps.email,
        userId: nextProps.userId,
      });
    }
    return true;
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth.token,
  name: auth.name,
  email: auth.email,
  userId: auth.userId,
});

export default connect(mapStateToProps)(SentryHandler);
