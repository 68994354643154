import { withRouter } from "react-router";
import { memo, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import Spec from "../../../../../models/Spec";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import VendorInfo from "./../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo";
import propTypes from "../../../../../constants/propTypes";
import React from "react";
import { dataComponentId } from "../../SpecDetailContainer";
import { LabeledTextContext } from "../../../../../withPORevision";
import { isPOChangeDisabled } from "../../../PurchaseOrderDetail/utils";

const VendorInfoContainer = ({
  purchaseOrder,
  purchaseOrderContacts,
  vendor,
  vendorContacts,
  spec,
  role,
}) => {
  const isLabeled = useContext(LabeledTextContext);

  if (role.name === "Vendor Portal") {
    return null;
  }

  const isDisabled = purchaseOrder && isPOChangeDisabled(purchaseOrder);
  return (
    <VendorInfo
      purchaseOrder={purchaseOrder}
      disabledOptions={isDisabled || isLabeled}
      spec={spec}
      dataComponentId={dataComponentId}
      purchaseOrderContacts={purchaseOrderContacts}
      vendor={vendor}
      vendorContacts={vendorContacts}
      displayShortPaidControl={false}
      displayAddContact={true}
      vendorSpec
    />
  );
};
VendorInfoContainer.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  purchaseOrderContacts: PropTypes.array,
  vendor: propTypes.vendor,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  spec: propTypes.spec,
  role: propTypes.userRole,
};

VendorInfoContainer.defaultProps = {
  purchaseOrder: {},
  purchaseOrderContacts: [],
};

const getPurchaseOrder = createSelector(
  state => state.resources,
  (_, { specId }) => specId,
  (resources, specId) => {
    const spec = Spec.query(resources).find(specId);
    if (!spec) return undefined;

    return PurchaseOrder.query(resources)
      .where({
        id: spec.purchaseOrderId,
      })
      .includes(["vendor.location", "poContacts.vendorContact.contact"])
      .toObjects()[0];
  }
);

const getSpec = createSelector(
  state => state.resources,
  (_, specId) => specId,
  (resources, specId) => {
    if (!specId) return undefined;
    const spec = Spec.query(resources).find(specId);
    if (!spec) return undefined;

    return spec;
  }
);

const getPOContacts = createSelector(
  purchaseOrder => purchaseOrder && purchaseOrder.id,
  purchaseOrder => {
    return (purchaseOrder && purchaseOrder.poContacts) || [];
  }
);

const mapStateToProps = (state, { match }) => {
  const { specId } = match.params;

  const spec = getSpec(state, specId);
  const purchaseOrder = getPurchaseOrder(state, { specId });
  const purchaseOrderContacts = getPOContacts(purchaseOrder);

  return { purchaseOrder, purchaseOrderContacts, spec, role: state.auth.role };
};

export default withRouter(connect(mapStateToProps)(memo(VendorInfoContainer)));
