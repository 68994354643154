import React, { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFPage = styled(Page)`
  border: 8px solid #525659;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 0px 0 rgba(0, 0, 0, 0.18);
`;

export const PDFDocument = styled(Document)`
  display: flex;
  ${({ numPages, file }) =>
    numPages && file ? "" : "justify-content: center;"}
  align-items: center;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  border-left: solid 1px rgba(0, 0, 0, 0.12);

  & .react-pdf__message--no-data {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Footer = styled.div`
  height: 66px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

export const PDFController = memo(
  ({ pdfControllerRef, formikPropsRef, fileData, noData }) => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const file = fileData?.data
      ? { data: new Uint8Array(new Uint8Array(fileData.data)) }
      : null;
    return (
      <Wrapper>
        <PDFDocument
          inputRef={pdfControllerRef}
          file={file}
          numPages={numPages}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
          noData={noData}
          loading={"Loading PDF file"}
          error={
            <React.Fragment>Failed to load PDF file {noData}</React.Fragment>
          }
        >
          {[...Array(numPages)].map((_, i) => (
            <PDFPage
              key={i}
              pageNumber={i + 1}
              onLoadSuccess={page => parsePage(page, formikPropsRef)}
            />
          ))}
        </PDFDocument>
        <Footer />
      </Wrapper>
    );
  }
);

async function parsePage(page, formikPropsRef) {
  const ops = await page.getOperatorList();

  const fns = ops.fnArray,
    args = ops.argsArray;

  const imgs = await Promise.all(
    args
      .map((arg, i) => {
        if (fns[i] !== pdfjs.OPS.paintImageXObject) {
          return;
        }

        const imgKey = arg[0];
        const imgObj = page.objs.get(imgKey);
        return new Promise(async resolve => {
          const bmp = imgObj.bitmap;
          const canvas = document.createElement("canvas");
          canvas.style.display = "none";
          canvas.width = bmp.width;
          canvas.height = bmp.height;
          const ctx = canvas.getContext("bitmaprenderer");
          ctx.transferFromImageBitmap(bmp);
          const blob = await new Promise(res => canvas.toBlob(res));
          resolve(URL.createObjectURL(blob));
        });
      })
      .filter(fn => !!fn)
  );

  const specDetails = imgs.map(img => ({
    title: "Preview",
    type: "Preview",
    description: null,
    preview: img,
  }));

  formikPropsRef.current.setFieldValue("specDetails", [
    ...formikPropsRef.current.values.specDetails,
    ...specDetails,
  ]);
}
