import { BaseModel } from "@blue-chip/core";
import PurchaseOrder from "./PurchaseOrder";
import Shipment from "./Shipment";
import SpecCategory from "./SpecCategory";

export default class SpecShipment extends BaseModel {
  static get belongsTo() {
    return [PurchaseOrder, SpecCategory];
  }

  static get hasMany() {
    return [Shipment];
  }
}
