import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../forms/ActionButtons";
import RegularText from "./Typography/RegularText";

export const Message = styled(RegularText)`
  padding: 24px;
`;

const DangerConfirmation = ({
  actionMessage,
  dangerButtonText,
  onDanger,
  ...buttonsProps
}) => (
  <Fragment>
    <Message>Are you sure you want to {actionMessage}?</Message>
    <ActionButtons
      onDanger={onDanger}
      dangerButtonText={dangerButtonText}
      isModal
      emphasizeCancel
      {...buttonsProps}
    />
  </Fragment>
);

DangerConfirmation.propTypes = {
  actionMessage: PropTypes.string.isRequired,
  dangerButtonText: PropTypes.string.isRequired,
  onDanger: PropTypes.func,
};

export default DangerConfirmation;
