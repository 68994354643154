import React, { Fragment } from "react";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { columns, columnOptions, getTableComponents } from "./gridData";
import { BWGridAPI, GridTable, withSelectedRows } from "../../../ui/BWGrid";
import { genDetailRowComponents } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import PurchaseOrderListActions, {
  buildActions,
} from "./PurchaseOrderListActions";
import { isSpecReadOnly } from "../../PurchaseOrderDetail/Specs/Grid";
import {
  updateSpecs,
  openCreatePurchaseOrderModal,
} from "../../../../actions/quotingActions";
import {
  performRetrieveListRequest,
  initDataComponent,
  setSelection,
  setReload,
} from "../../../../actions/dataComponentActions";
import {
  showSnackNotificationAction,
  openModalDialog,
} from "../../../../actions/layoutActions";
import withPORevision from "../../../../withPORevision";
import RowHeader from "./RowHeader";
import Spec from "../../../../models/Spec";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import propTypes from "../../../../constants/propTypes";

export const specDetailId = "spec-details";
export const specUpdateId = "spec-update-id";

export const SpecsGrid = ({
  purchaseOrder,
  setSelection: dataComponentIdSetSelection,
  relatedPOs,
  specUpdateDataComponent,
  revisionValue,
  isClosedProject,
  specDataComponent,
  flattenedSpecDataComponent,
  updateSpecs,
  openCreatePurchaseOrderModal,
  scopeId,
  clientId,
  push,
  openModalDialog,
  isCollapsed,
  setIsCollapsed,
  refreshPurchaseOrderList,
}) => {
  const {
    projectCurrency: { currency },
  } = purchaseOrder;

  const tableComponents = {
    ...genDetailRowComponents(
      "statusComment",
      "id",
      BWModels.loadSchema("Spec"),
      specUpdateDataComponent.dataComponentId,
      5,
      2,
      { helperText: "Status Comment" },
      isSpecReadOnly
    ),
    ...getTableComponents(
      specUpdateDataComponent.dataComponentId,
      revisionValue.isRevisionActive,
      currency
    ),
  };

  const selectedIds = flattenedSpecDataComponent.selectedIds || [];
  const setSelection = selection =>
    dataComponentIdSetSelection(
      flattenedSpecDataComponent.dataComponentId,
      selection
    );

  const {
    onMove,
    onSplit,
    handleCancel,
    handleUploadQuote,
    handleOpenSpecDetails,
  } = buildActions({
    purchaseOrder,
    scopeId,
    clientId,
    specDataComponent,
    updateSpecs,
    push,
    setSelection,
    openCreatePurchaseOrderModal,
    openModalDialog,
    selectedIds,
  });

  useUpdateProcessRequests(specDataComponent, {
    onSuccess: refreshPurchaseOrderList,
  });

  return (
    <Fragment>
      <RowHeader
        purchaseOrder={purchaseOrder}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        actionsComponent={
          isClosedProject ? null : (
            <PurchaseOrderListActions
              disabled={selectedIds.length === 0}
              relatedPOs={relatedPOs}
              onMove={onMove}
              onSplit={onSplit}
              onCancel={handleCancel}
              onUploadQuote={handleUploadQuote}
              purchaseOrder={purchaseOrder}
            />
          )
        }
      />
      <BWGridAPI
        id={`purchaseOrder-${purchaseOrder.id}`}
        dataComponent={flattenedSpecDataComponent}
        model={Spec}
        includes={["specCategory", "projectCurrency.currency"]}
        apiRoute="specs"
        apiFilters={{
          rootFilters: {
            $where: {
              purchaseOrderId: purchaseOrder.id,
            },
          },
          params: {
            omitDefaultModifier: true,
            modifiers: ["withQuantityPrice"],
          },
        }}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        showSelectionColumn={!isClosedProject}
        showSelectAll={!isClosedProject}
        revisionReference="specs"
        tableComponents={tableComponents}
        alwaysDisplayDetailRow
        noBorder
      >
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={handleOpenSpecDetails}
        />
      </BWGridAPI>
    </Fragment>
  );
};

SpecsGrid.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  setSelection: PropTypes.func.isRequired,
  relatedPOs: PropTypes.array.isRequired,
  specUpdateDataComponent: propTypes.dataComponent.isRequired,
  revisionValue: PropTypes.object.isRequired,
  isClosedProject: PropTypes.bool,
  specDataComponent: propTypes.dataComponent.isRequired,
  flattenedSpecDataComponent: propTypes.dataComponent.isRequired,
  updateSpecs: PropTypes.func.isRequired,
  openCreatePurchaseOrderModal: PropTypes.func.isRequired,
  scopeId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func.isRequired,
  refreshPurchaseOrderList: PropTypes.func.isRequired,
};

export const getDataComponentId = ({ id }) => `${specDetailId}-${id}`;

export const mapStateToProps = (state, { purchaseOrder, match }) => {
  const specDataComponent = getDataComponent(
    getDataComponentId(purchaseOrder),
    state
  );
  const flattenedSpecDataComponent = getDataComponentFlattenedRequestState(
    getDataComponentId(purchaseOrder),
    state
  );
  const specUpdateDataComponent = getDataComponent(specUpdateId, state);

  return {
    specDataComponent,
    flattenedSpecDataComponent,
    specUpdateDataComponent,
    clientId: match.params.clientId,
    scopeId: state.scope.id,
  };
};

export const mapDispatchToProps = {
  updateSpecs,
  openCreatePurchaseOrderModal,
  push,
  showSnackNotificationAction,
  initDataComponent,
  performRetrieveListRequest,
  openModalDialog,
  setSelection,
  setReload,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSelectedRows(withPORevision(SpecsGrid)))
);
