import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Price from "../../../../ui/Price";
import Edit from "@material-ui/icons/Edit";

const clearColumns = [
  ["assetsPaid", "assetsToDate", "depositsPaid", "depositsToDate"],
  ["assetsPaid", "assetsToDate", "depositsToDate"],
  ["assetsPaid", "assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  ["assetsToDate", "depositsToDate"],
  [],
];

export const editableColumns = [
  {},
  { depositsPaid: "merchandiseDepositsPaid" },
  { depositsPaid: "overageDepositsPaid" },
  { assetsPaid: "taxAssetsPaid", depositsPaid: "taxDepositsPaid" },
  {
    assetsPaid: "accruedUseTaxAssetsPaid",
    depositsPaid: "accruedUseTaxDepositsPaid",
  },
  {
    assetsPaid: "freightWarehousingAssetsPaid",
    depositsPaid: "freightWarehousingDepositsPaid",
  },
  {
    assetsPaid: "installAssetsPaid",
    depositsPaid: "installDepositsPaid",
  },
  {
    assetsPaid: "otherCostAssetsPaid",
    depositsPaid: "otherCostDepositsPaid",
  },
  {
    assetsPaid: "discountAssetsPaid",
    depositsPaid: "discountDepositsPaid",
  },
  {},
];

const StyledValue = styled.span`
  display: flex;
  align-items: center;
  color: ${({ clear }) => (clear ? "#979797" : "rgba(0, 0, 0, 0.87)")};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  width: ${({ expanded }) => expanded && "210px"};
`;

const EditIcon = styled(Edit)`
  color: #979797;
  font-size: 12px;
  margin-left: 10px;
  width: 12px;
  height: 12px;
`;

const Column = ({ row, path, rowData, isEditable, projectCurrency = {} }) => {
  const value = row[path];
  const { currency } = projectCurrency;
  return (
    <StyledValue
      bold={value === "Check Request Total"}
      clear={clearColumns[rowData.rowId].includes(path)}
      expanded={path === "title"}
    >
      {typeof value === "string" ? (
        value
      ) : (
        <Price number={row[path]} currency={currency} />
      )}
      {editableColumns[rowData.rowId][path] && isEditable && <EditIcon />}
    </StyledValue>
  );
};

Column.propTypes = {
  row: PropTypes.shape({}),
  rowData: PropTypes.shape({}),
  path: PropTypes.string,
  isEditable: PropTypes.bool,
  projectCurrency: PropTypes.object,
};

export default Column;
