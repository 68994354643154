import React, { Fragment, useState, useCallback } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { BarLabel, BarControls } from "./components";
import NavigationBar from "../../../layout/NavigationBar/NavigationBar";
import Weekend from "@material-ui/icons/Weekend";
import Terrain from "@material-ui/icons/Terrain";
import propTypes from "../../../../constants/propTypes";
import { Switch } from "../../../mui/core";
import { DisplayCommentsContext } from "./SpecTable/SpecTable";

const navigationTabs = [
  {
    label: "Categories",
    path: "/categories",
    icon: <Weekend />,
  },
  {
    label: "Areas",
    path: "/areas",
    icon: <Terrain />,
  },
];

const GroupsBar = ({
  totalSpecs,
  projectCategoryPrices,
  projectAreaPrices,
  project,
  route,
}) => {
  const [displayComments, setDisplayComments] = useState(true);
  const [displayDiscount, setDisplayDiscount] = useState(false);
  const handleToggleComments = useCallback(
    evt => {
      evt.stopPropagation();
      setDisplayComments(!displayComments);
    },
    [displayComments]
  );

  const handleToggleDiscount = event => {
    event.stopPropagation();
    setDisplayDiscount(!displayDiscount);
  };

  return (
    <Fragment>
      <NavigationBar
        title={`${totalSpecs} Specs`}
        navigationTabs={navigationTabs}
      >
        <BarControls>
          <BarLabel>Show Comments</BarLabel>
          <Switch
            checked={displayComments}
            color="primary"
            onChange={handleToggleComments}
          />
          <BarLabel>Show Discounts</BarLabel>
          <Switch
            checked={displayDiscount}
            color="primary"
            onChange={handleToggleDiscount}
          />
          <BarLabel>Filter By</BarLabel>
        </BarControls>
      </NavigationBar>

      <DisplayCommentsContext.Provider value={displayComments}>
        {renderRoutes(route.routers, {
          totalSpecs,
          projectCategoryPrices,
          projectAreaPrices,
          project,
          displayDiscount,
        })}
      </DisplayCommentsContext.Provider>
    </Fragment>
  );
};

GroupsBar.propTypes = {
  totalSpecs: PropTypes.number,
  projectCategoryPrices: PropTypes.arrayOf(PropTypes.object),
  projectAreaPrices: PropTypes.arrayOf(PropTypes.object),
  project: propTypes.project,
  route: propTypes.route,
};

export default GroupsBar;
