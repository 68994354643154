import _omitBy from "lodash/omitBy";
import get from "lodash.get";
import moment from "moment";
import pluralize from "pluralize";
import { PRIMARY_DATE_FORMAT } from "../constants/formats";

export const priority = {
  highest: 0, // Requirement or main priority
  high: 1, // Empty validations
  medium: 2, // Type data format validations
  low: 3, // Especific validations for data format.
  lowest: 4,
};

export const codeToErrorInfoMap = {
  emailFailure: {
    title: "Invalid email",
    detail:
      "Please check the email you entered. If you are having issues logging" +
      " in, please contact an administrator.",
    priority: priority.highest,
  },
  authFailure: {
    title: "Password incorrect",
    detail:
      "Please check your password. If you are having issues logging" +
      " in, please change your password by clicking on RESET PASSWORD.",
    priority: priority.highest,
  },
  verifyCodeFailure: {
    title: "Verification failed, please try again",
    detail: "Enter the code you received in your device.",
    priority: priority.highest,
  },
  unique: {
    title: "Another record with the same value already exists",
    priority: priority.highest,
  },
  "any.required": {
    title: "This field can't be empty",
    priority: priority.highest,
  },
  "object.base": {
    title: "This field can't be empty",
    priority: priority.highest,
  },
  type: {
    title: "Value is not accepted for this field",
    priority: priority.medium,
  },
  "any.empty": {
    title: "This field can't be empty",
    priority: priority.high,
  },
  "resetPassword.invalid": {
    title: "Password does not match",
    priority: priority.high,
  },
  "selectFk.emptySelect": {
    title: "You must choose a valid option",
    priority: priority.high,
  },
  "select.emptySelect": {
    title: "You must choose a valid option",
    priority: priority.high,
  },
  "url.valid": {
    title: "Value is not a valid URL",
    priority: priority.medium,
  },
  "password.valid": {
    title: "Invalid Password",
    priority: priority.medium,
  },
  "phone.length": {
    title: "Number must contains between 7 to 10 characters",
    priority: priority.medium,
  },
  "phone.valid": {
    title: "Value contains invalid characters",
    priority: priority.medium,
  },
  "phone.tollfree_length": {
    title: "Toll Number must contains 11 characters",
    priority: priority.medium,
  },
  "phone.e164_country_code_length": {
    title: "Country code must contains between 1 and 3 characters",
    priority: priority.medium,
  },
  "phone.e164_country_code_valid": {
    title: "Country code contains invalid characters",
    priority: priority.medium,
  },
  "phone.e164_phone_length": {
    title: "Number must contains max 12 characters",
    priority: priority.medium,
  },
  "phone.regex.base": {
    title: "Number should start with +",
    priority: priority.medium,
  },
  "singleWord.valid": {
    title: "Value contains whitespaces",
    priority: priority.medium,
  },
  "string.regex.base": {
    title: "Value contains invalid characters",
    priority: priority.medium,
  },
  "string.regex.invert.base": {
    title: "Value contains invalid characters",
    priority: priority.medium,
  },
  "any.allowOnly": {
    title: "Value is not valid",
    priority: priority.medium,
  },
  "date.base": {
    title: "Value is not a valid Date",
    priority: priority.medium,
  },
  "any.invalid": {
    title: "Value is not valid",
    priority: priority.low,
  },
  "string.base": {
    title: "This field can't be empty",
    priority: priority.low,
  },
  "string.email": {
    title: "Value is not a valid email address",
    priority: priority.low,
  },
  "number.integer": {
    title: "Value can't be a fraction",
    priority: priority.low,
  },
  "string.min": {
    title: "Value is too short",
    priority: priority.low,
  },
  "string.max": {
    title: "The max amount of characters is ",
    priority: priority.low,
  },
  "date.max": {
    title: ({ joiDetail }) => {
      const max = moment(joiDetail.context.limit).format(PRIMARY_DATE_FORMAT);
      return `The date must be smaller than or equal to ${max}`;
    },
    priority: priority.low,
  },
  "date.min": {
    title: ({ joiDetail }) => {
      const max = moment(joiDetail.context.limit).format(PRIMARY_DATE_FORMAT);
      return `The date must be larger than or equal to ${max}`;
    },
    priority: priority.low,
  },
  "number.positive": {
    title: "Value must be greater than 0",
    priority: priority.low,
  },
  "number.base": {
    title: "Value must be a number",
    priority: priority.low,
  },
  "number.min": {
    title: "The value must be greater or equal than ",
    priority: priority.low,
  },
  "number.max": {
    title: "The value must be lower or equal than ",
    priority: priority.low,
  },
  "array.min": {
    title: ({ joiDetail }) => {
      const min = joiDetail.context.limit;
      return `This field must have at least ${pluralize(
        "item",
        min,
        true
      )} selected`;
    },
    priority: priority.low,
  },
  "array.unique": {
    title: "Another record with the same value already exists",
    priority: priority.highest,
  },
  genericError: {
    title: ({ joiDetail }) => {
      return joiDetail.message;
    },
  },
  noInfo: {
    title: "No information associated to error.",
    priority: priority.lowest,
  },
};

export const defaultSettings = {
  abortEarly: false,
  skipFunctions: true,
  convert: true,
  allowUnknown: true,
};

const limitErrors = ["string.max", "number.max", "number.min"];

const formatErrorMessage = errorInfo => {
  const message = errorInfo.detail ? errorInfo.detail : errorInfo.title;
  return typeof message === "function" ? message(errorInfo) : message;
};

export function mapJoiErrorToCompatibleError(joiError) {
  const errorInfoObjects = {};
  joiError.details.forEach(detail => {
    let name = `${detail.path.join(".")}`;
    // replace indices with bracket indices
    name = name.replace(/\.(\d+)(\.?)/g, "[$1]$2");
    let errorInfo = {
      ...codeToErrorInfoMap[detail.type],
      joiDetail: detail,
    };

    if (!errorInfo.title) {
      errorInfo = codeToErrorInfoMap["noInfo"];
    }

    if (limitErrors.includes(detail.type)) {
      errorInfo.title = `${codeToErrorInfoMap[detail.type].title}${
        detail.context.limit
      }`;
    }

    if (!errorInfoObjects[name]) {
      errorInfoObjects[name] = errorInfo;
    } else if (
      errorInfoObjects[name] &&
      errorInfoObjects[name].priority > errorInfo.priority
    ) {
      // Replace with higher priority error
      errorInfoObjects[name] = errorInfo;
    }
  });

  const errors = {};
  Object.entries(errorInfoObjects).forEach(([key, errorInfo]) => {
    errors[key] = formatErrorMessage(errorInfo);
  });

  return errors;
}

export function validate(validationSchema, values, validationSettings = {}) {
  const { isPatch, ...settings } = validationSettings;
  const options = {
    ...defaultSettings,
    ...settings,
  };
  const schema = isPatch ? validationSchema.forPATCH(values) : validationSchema;
  const { error } = schema.validate(values, options);

  if (error) throw error;
}

export const validateWithJoiSchema = (
  values,
  validationSchema,
  validationSettings = {}
) => {
  if (!validationSchema) return {};
  let errors = {};

  try {
    validate(validationSchema, values, validationSettings);
  } catch (joiError) {
    // console.log("errors", joiError.details);
    errors = mapJoiErrorToCompatibleError(joiError);
  }
  return errors;
};

function defaultMapper(errors, errorMeta, message = "Unknown field error") {
  errors[errorMeta.source] = message;
}

export function convertServerErrorToFormikErrors(
  error,
  mapper = defaultMapper
) {
  const errors = {};
  if (!error || !error.data || !error.data.errors) {
    return errors;
  }

  error.data.errors.forEach(errorMeta => {
    let message = errorMeta.title;
    const errorInfo = codeToErrorInfoMap[errorMeta.code];
    if (errorInfo) {
      message = errorInfo.detail ? errorInfo.detail : errorInfo.title;
    }

    mapper(errors, errorMeta, message);
  });

  return errors;
}

export function hasError(name, form) {
  return form.status === "submitted" && !!form.errors[name];
}

export function getDirtyFields({ dirty, values, initialValues }) {
  if (!dirty) return {};
  return _omitBy(values, (value, key) => initialValues[key] == value);
}

export function getErrorMessage(name, form) {
  return form.errors[name];
}

export function getScopedErrors(errors, errorScope) {
  let scopedErrors = errors;

  if (errorScope) {
    scopedErrors = Object.entries(errors).reduce(
      (newErrors, [errorKey, message]) => {
        if (errorKey.startsWith(errorScope)) {
          newErrors[errorKey.replace(errorScope + ".", "")] = message;
        }

        return newErrors;
      },
      {}
    );
  }

  return scopedErrors;
}

export function handleRequestError(error, formikProps, options = {}) {
  formikProps.setSubmitting(false);
  formikProps.setStatus("submitted");

  let errors = convertServerErrorToFormikErrors(error, options.errorMapper);
  errors = getScopedErrors(errors, options.errorScope);
  formikProps.setErrors({ ...formikProps.errors, ...errors });
}

export const handleRequestGlobalErrors = (
  showSnackNotificationAction,
  formikProps,
  options = {}
) => {
  return response => {
    const errors = get(response, "data.errors", []);
    errors.forEach(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
    handleRequestError(response, formikProps, options);
  };
};
