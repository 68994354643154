import Select from "@material-ui/core/Select";
import styled from "styled-components";

export default styled(Select)`
  color: white;
  font-family: "Open Sans Bold", sans-serif;

  svg {
    color: white;
  }

  &::before {
    border-bottom-color: white;
  }

  &::after {
    border-bottom-color: white;
  }

  &:hover {
    &::before {
      border-bottom-color: white !important;
    }
  }
`;
