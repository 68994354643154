import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import { Content } from "../../../layout/Content";
import Paper from "../../../mui/core/Paper";
import Project from "../../../../models/Project";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import GridAPI from "../../../ui/Grid/GridAPI";
import { buildTextTypeProvider } from "../../../ui/Grid/filterDataTypeProviders";
import { PropertyTypeProvider } from "./../dataTypeProviders";

export const handleRowClick = (row, push) => {
  push(`/projects/${row.id}/overview`);
};
const Grid = ({ dataComponent, pushWithReturnUrl }) => {
  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider([
        "name",
        "property.name",
        "property.entity.client.name",
      ]),
    ],
    []
  );

  const rowProps = useMemo(
    () => ({
      onClick: row => handleRowClick(row, pushWithReturnUrl),
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [pushWithReturnUrl]
  );

  return (
    <Content>
      <Paper>
        <GridAPI
          apiRoute="projects"
          model={Project}
          includes={["property.[location, entity.client]"]}
          defaultSorting={[{ columnName: "number", direction: "desc" }]}
          dataComponent={dataComponent}
          rowProps={rowProps}
          columns={[
            {
              name: "number",
              title: "Number",
              bold: true,
              filter: true,
              width: "5vw",
            },
            {
              name: "property.name",
              title: "Property",
              width: "20vw",
              filter: true,
            },
            {
              name: "name",
              title: "Project Name",
              width: "20vw",
              filter: true,
            },
            {
              name: "property.entity.client.name",
              title: "Client",
              filter: true,
            },
          ]}
          tableComponents={{
            GridHeader: useHeaderComponent({
              headerText: pluralize("Project", dataComponent.totalRows, true),
            }),
          }}
          dataTypeProviders={[PropertyTypeProvider]}
          filteringDataTypeProviders={filteringDataTypeProviders}
        />
      </Paper>
    </Content>
  );
};

Grid.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  currentUserId: PropTypes.string,
  pushWithReturnUrl: PropTypes.func.isRequired,
};

export default Grid;
