import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";

export const ProfilePictureWrapper = styled.div`
  padding: 24px 24px 0px 24px;
`;

export const ProfileEditPictureWrapper = styled.div`
  padding: 24px;
`;

export const Container = styled.div`
  .label {
    width: 600px;
  }
`;

export const PannableAndZoomableContainer = styled.div`
  cursor: move;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 12px 0px 16px 0px;
  color: rgba(0, 0, 0, 0.54);
`;

export const SliderLabel = styled(Label)`
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`;

export const PictureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 180px;
  flex-grow: 1;
  padding: 0px 30px;
`;

export const Picture = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 24px;
  border: 1px solid #57abff;
`;

export const EmptyState = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 24px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid #57abff;
`;

export const EditLink = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  color: #57abff;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ResetPasswordLink = styled(EditLink)`
  justify-content: flex-end;
  flex-grow: 1;

  svg {
    margin-right: 8px;
  }
`;

export const EditIcon = styled(CreateIcon)`
  margin-right: 8px;
`;
