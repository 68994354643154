import React from "react";
import PropTypes from "prop-types";
import RemoveIcon from "@material-ui/icons/Remove";
import pluralize from "pluralize";
import styled from "styled-components";
import _get from "lodash/get";

import Spec from "../../../models/Spec";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import TagText from "../../ui/Tags/TagText";
import Paper from "../../mui/core/Paper";
import {
  ColumnWrapper,
  ExtendedPrice,
  SelectedPrice,
} from "../PurchaseOrderDetail/Specs/components";

const GridWrapper = styled(Paper)`
  width: 100%;
  overflow: auto;
  height: max-content;
`;

const SectionWrapper = styled.div`
  display: flex;
  padding: 24px;
  width: calc(100% - 560px);
  overflow-y: auto;
`;

const RequirementsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled(TagText)`
  margin-right: 16px;
`;

export const DashIcon = styled(RemoveIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const columns = [
  "customNumber",
  "description",
  "specCategory.name",
  "unitOfMeasure.name",
  "baseQuantity",
  "price",
  "totalPrice",
  "requirements",
];

const renderTotalRequirements = row => {
  const requirements = _get(row, "specRequirements", []);
  const total = requirements.length;
  if (total === 0) return 0;

  const pending = requirements.reduce(
    (count, requirement) =>
      requirement.submittalApprovedId === null ? count + 1 : count,
    0
  );
  let intent = "success";
  let tagText = "Approved";
  if (pending > 0) {
    intent = "none";
    tagText = `${pending} Pending`;
  }

  return (
    <RequirementsWrapper>
      <Tag intent={intent}>{tagText}</Tag>
      {total}
    </RequirementsWrapper>
  );
};

export const columnOptions = {
  customNumber: { title: "Spec Number", bold: true, filter: true },
  description: {
    filter: true,
    fill: true,
    render: spec => spec && spec.description,
  },
  "specCategory.name": {
    title: "Type",
    render: spec =>
      spec.specCategory && (
        <ColumnWrapper
          id={spec.id}
          value={spec.specCategory.name}
          field="specCategoryId"
        />
      ),
    filter: true,
  },
  "unitOfMeasure.name": { title: "UOM" },
  baseQuantity: {
    title: "Qty",
    render: ({ totalQuantity }) => totalQuantity,
  },
  price: { title: "Price", render: SelectedPrice, sortingEnabled: false },
  totalPrice: {
    title: "Ext. Price",
    render: ExtendedPrice,
    sortingEnabled: false,
  },
  requirements: {
    sortingEnabled: false,
    align: "right",
    render: renderTotalRequirements,
  },
};

const ReviewSubmittals = ({ dataComponent, purchaseOrderId, onSelectSpec }) => {
  return (
    <SectionWrapper>
      <GridWrapper>
        <BWGridAPI
          dataComponent={dataComponent}
          apiRoute="specs"
          model={Spec}
          includes={[
            "area",
            "project",
            "specCategory",
            "specRequirements.[requirement,submittalFiles.file,submittalApproved.file]",
            "unitOfMeasure",
          ]}
          rootFilters={{
            $where: {
              purchaseOrderId,
            },
          }}
        >
          <GridHeader
            subheaderText={pluralize("Item", dataComponent.totalRows, true)}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            onClick={onSelectSpec}
          />
        </BWGridAPI>
      </GridWrapper>
    </SectionWrapper>
  );
};

ReviewSubmittals.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  purchaseOrderId: PropTypes.string.isRequired,
  onSelectSpec: PropTypes.func.isRequired,
};

export default ReviewSubmittals;
