import React, { Fragment, useCallback } from "react";
import { Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";

import { DragDropDashboard } from "../DragDropDashboard";

const DragDropInputFile = ({
  onChange,
  open,
  onClose,
  accept = "",
  multiple,
  isForm,
}) => {
  const handleChange = useCallback(
    evt => {
      onChange(evt);
      isForm && onClose();
    },
    [isForm, onChange, onClose]
  );

  const confirmActionLabel = isForm ? "Confirm" : "Upload";

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <IconButton
          style={{ position: "absolute", right: 0, zIndex: 1 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <DragDropDashboard
          onChange={handleChange}
          onComplete={onClose}
          accept={accept}
          multiple={multiple}
          locale={{
            strings: {
              uploadXFiles: {
                0: `${confirmActionLabel} %{smart_count} file`,
                1: `${confirmActionLabel} %{smart_count} files`,
              },
            },
          }}
        />
      </Dialog>
    </Fragment>
  );
};
DragDropInputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  isForm: PropTypes.bool,
};

export default DragDropInputFile;
