import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Button } from "../../mui/core";

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  color: white;
`;

export const PanelButtons = styled(
  ({ alignRight, isStacked, isModal, hasThreeOption, ...props }) => (
    <div {...props} />
  )
)`
  display: ${({ hasThreeOption }) => (hasThreeOption ? "block" : "flex")};
  white-space: nowrap;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media (min-width: 600px) {
    display: flex;
    flex-direction: ${({ isStacked }) => (isStacked ? "column" : "row")};
  }
  padding: ${({ isModal }) => (isModal ? "0 24px 24px" : "0")};

  justify-content: ${({ alignRight }) => (alignRight ? "flex-end" : "auto")};

  ${Button} {
    &:not(:last-child) {
      margin: ${({ isStacked }) => (isStacked ? "0 0 24px" : "0 16px 0 0")};
    }
    @media (max-width: 600px) {
      &:not(:last-child) {
        margin: 0 0 24px;
      }
    }

    &:last-child {
      float: ${({ hasThreeOption }) => (hasThreeOption ? "right" : "none")};
    }
    @media (max-width: 960px) {
      width: 100%;
    }
  }
`;

export const Child = styled(({ loading, ...props }) => <span {...props} />)`
  opacity: ${({ loading }) => (loading ? "0" : "1")};
`;

export const ActionButton = ({ loading, disabled, ...props }) => (
  <Button disabled={disabled || loading} {...props} />
);

export const CancelButton = styled(ActionButton)`
  color: rgba(0, 0, 0, 0.54);
`;

export const CancelButtonWithEmphasis = props => (
  <ActionButton {...props} variant="contained" color="primary" />
);
