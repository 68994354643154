import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { useSelector } from "react-redux";
import moment from "moment";

import Fee from "../../../../models/Fee";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import GridAPI from "../../../ui/Grid/GridAPI";
import RowDetailGrid from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/RowDetailGrid";
import BWTableRow from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/BWTableRow";
import {
  dateTypeBuilder,
  currencyTypeBuilder,
} from "../../../ui/Grid/utilComponents/dataTypeProviders";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
  buildStaticMultiSelectTypeProvider,
} from "../../../ui/Grid/filterDataTypeProviders";
import { RowMenuProvider } from "../../../ui/Grid/utilComponents";
import {
  WrapperDetailRow,
  DetailCellComponent,
  EditableDetailRow,
} from "../Available/DetailRow";
import { scopeTypeBuilder } from "./dataTypeProviders";
import { updateFee } from "../../../../actions/feeSummaryActions";
import { setReload } from "../../../../actions/dataComponentActions";
import {
  openModalDialog,
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import useActions from "../../../hooks/useActions";
import {
  useFlattenedDatacomponent,
  useDatacomponent,
} from "../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { Actions } from "./Actions";
import PagingPaneWithStatusSwitch from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { useListWorkScopes } from "../../../hooks/useListWorkScopes";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import { getColumns } from "./gridProps";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import { feeSubscopeDataComponent, subScopeFilters } from "../Available/Grid";
import { getDefaultProjectCurrency } from "../../../helpers";

export const billingTermFilter = ({ value }) => {
  return value ? { billingTerm: value } : {};
};

export const scopeFilter = ({ value }) => {
  return { scope: { $in: value } };
};

export const getSelectedProjectCurrency = ({
  row: {
    project: { projectCurrencies },
  },
}) => {
  return getDefaultProjectCurrency(projectCurrencies);
};

export const dataComponentId = "billing-approval-table";

const Grid = ({ showAllProject }) => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);
  const actions = useActions({
    updateFee,
    showSnackNotificationAction,
    setReload,
    openModalDialog,
    closeModalDialog,
  });

  useUpdateProcessRequests(useDatacomponent(dataComponentId), {
    onSuccess: () => {
      actions.showSnackNotificationAction("Fee has been updated successfully");
      actions.setReload(dataComponentId, true);
    },
  });
  const userId = useSelector(state => state.auth.userId);
  const handleUpdateStatus = ({ ids }) => {
    actions.updateFee(
      ids.map(id => ({ id, status: "Approved" })),
      {},
      dataComponentId
    );
  };
  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider([
        "project.projectLead.name",
        "project.name",
        "project.number",
      ]),
      buildStaticSelectTypeProvider(["billingTerm", "feeSubScope.name"]),
      buildStaticMultiSelectTypeProvider(["workScope.id"]),
    ],
    []
  );

  const workScopes = useListWorkScopes();
  const { data: feeSubScopes } = usePerformRetrieveList(
    feeSubscopeDataComponent,
    subScopeFilters
  );
  const columns = useMemo(
    () => getColumns(workScopes, billingTermFilter, feeSubScopes),
    [feeSubScopes, workScopes]
  );

  const date = moment()
    .add(7, "days")
    .format("YYYY-MM-DD");
  const additionalFilters = showAllProject
    ? {}
    : {
        "project.projectLead.id": userId,
      };
  return (
    <GridAPI
      model={Fee}
      apiRoute="fees"
      includes={[
        "project.[projectLead,projectCurrencies.currency]",
        "workScope",
        "feeSubScope",
      ]}
      defaultSorting={[
        { columnName: "project.number", direction: "asc" },
        { columnName: "billingDate", direction: "asc" },
      ]}
      apiFilters={{
        rootFilters: {
          $where: {
            status: "Pending",
            billingDate: { $lte: date },
            sharedFromFeeId: {
              $exists: false,
            },
            ...additionalFilters,
          },
        },
      }}
      dxGridProps={{
        tableComponents: {
          WrapperDetailRow: WrapperDetailRow,
          DetailRowComponent: EditableDetailRow,
          DetailCellComponent,
        },
      }}
      showSelectionColumn={true}
      showSelectAll={true}
      dataComponent={dataComponent}
      columns={columns}
      columnExtensions={[]}
      tableComponents={{
        PagingPanelTemplate: PagingPaneWithStatusSwitch,
        GridHeader: useHeaderComponent({
          headerText: `${pluralize(
            "Fee",
            dataComponent.totalRows,
            true
          )} Pending Approval`,
          headerOverride: (
            <Actions
              dataComponent={dataComponent}
              openModalDialog={actions.openModalDialog}
              closeModalDialog={actions.closeModalDialog}
              handleUpdateStatus={handleUpdateStatus}
            />
          ),
        }),
        DXGrid: RowDetailGrid,
        RowComponent: BWTableRow,
      }}
      dataTypeProviders={[
        dateTypeBuilder(["billingDate"], PRIMARY_DATE_FORMAT),
        currencyTypeBuilder(["amount"], getSelectedProjectCurrency),
        scopeTypeBuilder(["workScope.id"]),
        RowMenuProvider,
      ]}
      filteringDataTypeProviders={filteringDataTypeProviders}
    />
  );
};

Grid.propTypes = {
  showAllProject: PropTypes.bool,
};

export default Grid;
