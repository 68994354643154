import { inputs } from "../../../inputs/inputConfigs";

export default clientId => {
  return {
    groups: [
      {
        items: [
          {
            input: { ...inputs.locationName, required: true },
            grid: { xs: 4 },
          },
          {
            input: {
              ...inputs.entity,
              APIOptions: {
                ...inputs.entity.APIOptions,
                rootFilters: { $where: { clientId } },
              },
              required: true,
            },
            grid: { xs: 4 },
          },
        ],
      },
      {
        items: [
          {
            input: inputs.location,
            grid: { xs: 4 },
          },
          {
            input: inputs.locationAddress2,
            grid: { xs: 4 },
          },
          {
            input: inputs.locationCity,
            grid: { xs: 4 },
          },
          {
            input: inputs.locationState,
            grid: { xs: 4 },
          },
          {
            input: inputs.locationCountry,
            grid: { xs: 4 },
          },
          {
            input: inputs.locationPostalCode,
            grid: { xs: 4 },
          },
        ],
      },
    ],
  };
};
