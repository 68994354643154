import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _omit from "lodash/omit";
import _get from "lodash/get";

import EditClientContactForm from "./EditClientContactForm";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
} from "../../../../actions/dataComponentActions";
import ClientContact from "../../../../models/ClientContact";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader/Loader";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";

const dataComponentId = "EditClientContact";

export class EditClientContactContainer extends Component {
  componentDidMount() {
    const {
      initDataComponent,
      performFindRequest,
      clientContactId,
    } = this.props;

    initDataComponent(
      dataComponentId,
      ClientContact,
      ["contact"],
      "client-contacts"
    );
    performFindRequest(dataComponentId, clientContactId);
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      showSnackNotificationAction,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  handleEditContact = ({ client, ...clientContact }, formikActions) => {
    const { performUpdateRequest } = this.props;

    performUpdateRequest(
      dataComponentId,
      clientContact.id,
      _omit(clientContact, "contact.memberType")
    );
    this.setState({ formikActions });
  };

  render() {
    const { clientContact, loading, dataComponent } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <EditClientContactForm
          sendButtonText="Save"
          clientContact={clientContact}
          onSubmit={this.handleEditContact}
          dataComponentId={dataComponent.dataComponentId}
        />
      </LoaderContext.Provider>
    );
  }
}

EditClientContactContainer.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  clientContactId: PropTypes.string.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dataComponent: PropTypes.object,
  clientContact: propTypes.clientContact,
};

const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
    clientContact: getBluechipResourceById(
      dataComponent,
      state,
      ownProps.clientContactId
    ),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  showSnackNotificationAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditClientContactContainer)
);
