import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Filter from "./Filter";
import ReportList from "./ReportList";
import propTypes from "../../../constants/propTypes";

const ReportsLayout = ({
  totalReports,
  projectReports,
  reportsLoader,
  reportCategories,
  projectReportCategoryId,
  handleDownloadReport,
  handleChangeReportCategoryId,
}) => {
  return (
    <Fragment>
      <Filter
        projectReportCategoryId={projectReportCategoryId}
        reportCategories={reportCategories}
        handleChangeReportCategoryId={handleChangeReportCategoryId}
      />

      <ReportList
        totalReports={totalReports}
        reportsLoader={reportsLoader}
        projectReports={projectReports}
        handleDownloadReport={handleDownloadReport}
      />
    </Fragment>
  );
};

ReportsLayout.propTypes = {
  projectId: PropTypes.string,
  projectReportCategoryId: PropTypes.string,
  handleDownloadReport: PropTypes.func,
  handleChangeReportCategoryId: PropTypes.func,
  projectReports: PropTypes.array,
  reportCategories: PropTypes.arrayOf(PropTypes.object),
  reportsLoader: propTypes.reportsLoader,
  totalReports: PropTypes.number,
};

export default ReportsLayout;
