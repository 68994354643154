import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

export const NavigationRoutes = ({
  match,
  pageProps,
  navigationTabs,
  defaultTab,
}) => (
  <Switch>
    {navigationTabs.map(({ path, exact = true, component: SubPage }) => (
      <Route
        key={path}
        exact={exact}
        path={`${match.path}${path}`}
        render={() => <SubPage {...pageProps} />}
      />
    ))}
    <Redirect
      from={match.url}
      to={`${match.url}${navigationTabs[defaultTab].path}`}
      exact={true}
    />
  </Switch>
);

NavigationRoutes.propTypes = {
  match: PropTypes.object.isRequired,
  pageProps: PropTypes.object,
  defaultTab: PropTypes.number,
  navigationTabs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
      exact: PropTypes.bool,
    })
  ),
};

NavigationRoutes.defaultProps = {
  defaultTab: 0,
};

export default withRouter(NavigationRoutes);
