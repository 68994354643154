import TextInputWithError from "../../../inputs/TextInputWithError";
import CurrencyInput from "../../../inputs/CurrencyInput";

export default (onDesignerBudgetChange, onDesignerQuantityChange, spec) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              InputComponent: TextInputWithError,
              name: "designerBudget",
              label: "Designer Budget",
              fullWidth: true,
              delay: 2000,
              InputProps: {
                inputComponent: CurrencyInput,
              },
              inputProps: {
                currency: spec.projectCurrency?.currency,
              },
              handleChange: onDesignerBudgetChange,
            },
            grid: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
          },
          {
            input: {
              InputComponent: TextInputWithError,
              name: "designerQuantity",
              label: "Designer Quantity",
              fullWidth: true,
              delay: 2000,
              handleChange: onDesignerQuantityChange,
            },
            grid: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
          },
          {
            input: {
              InputComponent: TextInputWithError,
              name: "designerExtendedCost",
              label: "Extended Cost",
              disabled: true,
              InputProps: {
                inputComponent: CurrencyInput,
                disableUnderline: true,
              },
              inputProps: {
                currency: spec.projectCurrency?.currency,
              },
            },
            grid: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
          },
        ],
      },
    ],
  };
};
