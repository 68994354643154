import React from "react";
import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";

const Content = styled.div`
  background-color: rgb(255, 255, 255);
  width: 1200px;
  overflow: auto;
  max-height: 75vh;
`;

export const ContentWrapper = props => <Content {...props} />;

export const DiscountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0px 24px;

  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const DiscountIcon = styled(AddCircle)`
  margin-right: 8px;
  font-size: 24px;
`;

export const DiscountType = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  color: #57abff;

  &:hover {
    color: rgba(87, 171, 255, 0.8);
  }
`;
