import React, { Component } from "react";
import PropTypes from "prop-types";
import { GridLocalSection } from "../../../layout/GridLocalSection";
import _get from "lodash.get";
import Price from "../../../ui/Price";

export const getAreaTypeOptions = areaTypes => {
  return areaTypes.map(areaType => {
    return { id: areaType.name, name: areaType.name };
  });
};
export const renderSize = row => {
  const squareFoot = _get(row, "squareFoot", "");
  const areaRooms = _get(row, "areaRooms", []);

  if (row.areaType && row.areaType.isMultiple) {
    return `${areaRooms.length} Keys`;
  }

  if (squareFoot) {
    return `${squareFoot} SF`;
  }

  return "";
};

export const ClientAreaBudget = ({ clientBudgetCents }) => {
  return clientBudgetCents && <Price number={clientBudgetCents} />;
};

export default class AreasSectionLayout extends Component {
  static propTypes = {
    onOpenAddModal: PropTypes.func.isRequired,
    onOpenEditModal: PropTypes.func.isRequired,
    onOpenDeleteModal: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    rows: PropTypes.array,
    areaTypes: PropTypes.array,
    dismissSnackNotificationAction: PropTypes.func,
  };

  addButtonText = "ADD AREA";
  gridHeaderText = "Areas";

  render() {
    const {
      rows,
      areaTypes,
      onOpenAddModal,
      onOpenEditModal,
      onOpenDeleteModal,
      onRowClick,
      dismissSnackNotificationAction,
    } = this.props;

    const columns = [
      "name",
      "description",
      "areaType.name",
      "clientBudgetCents",
      "numberOfSpecs",
      "size",
    ];
    const columnsOptions = {
      name: { bold: true, filter: true },
      description: { width: "370px", filter: true },
      "areaType.name": {
        title: "Area Type",
        filter: "select",
        filterOptions: {
          options: getAreaTypeOptions(areaTypes),
        },
      },
      clientBudgetCents: {
        title: "Area Client Budget",
        sortingEnabled: false,
        render: ClientAreaBudget,
      },
      numberOfSpecs: {
        title: "Specs",
        width: "85px",
        sortingEnabled: false,
      },
      size: {
        sortingEnabled: false,
        render: row => renderSize(row),
      },
    };
    return (
      <GridLocalSection
        id="project-areas"
        columns={columns}
        columnsOptions={columnsOptions}
        rowMenu={[
          {
            text: "Edit",
            onClick: onOpenEditModal,
            disableIfProjectClosed: true,
          },
          { separator: true },
          {
            text: "Delete",
            onClick: onOpenDeleteModal,
            disableIfProjectClosed: true,
          },
        ]}
        gridConfig={{
          pageSize: 0,
          totalRows: rows.length,
        }}
        rows={rows}
        sorting={[{ columnName: "name", direction: "asc" }]}
        addButtonText={this.addButtonText}
        gridHeaderText={this.gridHeaderText}
        addClick={onOpenAddModal}
        onRowClick={onRowClick}
        dismissSnackNotificationAction={dismissSnackNotificationAction}
      />
    );
  }
}
