import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";

import Subheader from "../ui/Typography/Subheader";
import InputLabel from "../mui/core/InputLabel";
import InputLoader from "../ui/Loader/InputLoader";
import { LabeledTextWrapper, Text } from "./LabeledText";

export const TextWrapper = styled(Subheader)`
  display: flex;
  min-height: 30px;
`;

export const ErrorIconWrapper = React.forwardRef(
  ({ color, badgeSize, ...props }, ref) => <ErrorIcon ref={ref} {...props} />
);

export const Error = styled(ErrorIconWrapper)`
  vertical-align: middle;
  align-self: center;
  color: ${({ color = "#ff1744" }) => color};
  width: ${({ badgeSize }) => badgeSize}px;
  height: ${({ badgeSize }) => badgeSize}px;
`;

const Warning = ({ revisionStatus, badgeSize }) => {
  if (!revisionStatus) return null;
  return (
    <Tooltip title={`CO ${revisionStatus}`} placement="right">
      <Error badgeSize={badgeSize} />
    </Tooltip>
  );
};
Warning.propTypes = {
  revisionStatus: PropTypes.string,
  badgeSize: PropTypes.number,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const POStatusBadgeWrapper = ({
  children,
  revisionStatus,
  badgeSize = 24,
}) => (
  <Wrapper>
    {children}
    <Warning revisionStatus={revisionStatus} badgeSize={badgeSize} />
  </Wrapper>
);
POStatusBadgeWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  revisionStatus: PropTypes.string,
  badgeSize: PropTypes.number,
};

export const LabeledPONumber = ({
  label,
  value: { number, revisionStatus } = {},
}) => {
  return (
    <InputLoader>
      <LabeledTextWrapper>
        {label && <InputLabel>{label}</InputLabel>}
        <TextWrapper>
          <POStatusBadgeWrapper revisionStatus={revisionStatus}>
            <Text>{number}</Text>
          </POStatusBadgeWrapper>
        </TextWrapper>
      </LabeledTextWrapper>
    </InputLoader>
  );
};

LabeledPONumber.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
};
