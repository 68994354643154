import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import OfficeForm from "../CreateOffice/OfficeForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../ui/Loader";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { dataComponentId } from "../OfficesTable/OfficesTableContainer";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const EditOfficeContainer = ({
  dataComponent,
  closeModalDialog,
  office,
  setReload,
  performUpdateRequest,
  loading,
  role,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  }, [
    closeModalDialog,
    dataComponent,
    formikActions,
    prevDataComponent,
    setReload,
  ]);

  const handleEditCode = useCallback(
    ({ id, ...office }, formikActions) => {
      performUpdateRequest(dataComponentId, id, office);
      setFormikAction(formikActions);
    },
    [performUpdateRequest]
  );

  const initialValues = {
    ...office,
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <OfficeForm
        initialValues={initialValues}
        onSubmit={handleEditCode}
        dataComponentId={dataComponentId}
        role={role}
      />
    </LoaderContext.Provider>
  );
};

EditOfficeContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  office: propTypes.office,
  performUpdateRequest: PropTypes.func,
  loading: PropTypes.bool,
  setReload: PropTypes.func,
  role: propTypes.userRole,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const {
    auth: { role },
  } = state;
  return {
    dataComponent,
    role,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOfficeContainer);
