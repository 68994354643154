import React, { useMemo } from "react";
import styled from "styled-components";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";
import pluralize from "pluralize";

import { Content } from "../../../../layout/Content";
import { BWGridAPI, GridHeader, GridTable } from "../../../../ui/BWGrid";
import PropTypes from "prop-types";
import Flag from "../../../../../models/Flag";
import propTypes from "../../../../../constants/propTypes";
import Chip from "@material-ui/core/Chip";

export const StyledChip = styled(({ invert, ...props }) => <Chip {...props} />)`
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 20px;
  padding: 12px 6px;
  margin-right: 4px;
`;
export const StyledContent = styled(Content)`
  padding-right: 0px;
`;

export const renderPrograms = row => {
  if (!row.programs || row.programs.length === 0) {
    return "";
  }
  return row.programs.map(program => {
    return <StyledChip key={program.id} label={program.name} />;
  });
};
const columns = ["name", "programsTotal", "programs"];
export const columnOptions = {
  name: { bold: true },
  programsTotal: {
    title: "Programs",
  },
  programs: {
    title: " ",
    fill: true,
    render: row => renderPrograms(row),
  },
};
const buildRowMenu = ({ onOpenEditModal, onOpenDeleteModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
  { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
];

const getActions = onOpenCreateModal => [
  {
    text: "Create Flag",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const FlagsTable = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
  brandId,
}) => {
  const rowMenu = useMemo(
    () => buildRowMenu({ onOpenEditModal, onOpenDeleteModal }),
    [onOpenEditModal, onOpenDeleteModal]
  );

  const actions = useMemo(() => getActions(onOpenCreateModal), [
    onOpenCreateModal,
  ]);

  return (
    <StyledContent>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Flag}
        apiRoute="flags"
        includes={["programs"]}
        apiFilters={{
          rootFilters: {
            $where: {
              brandId,
            },
          },
        }}
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
      >
        <GridHeader
          headerText={pluralize("Flag", dataComponent.totalRows, true)}
          actions={actions}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu}
          showSelectionColumn={true}
          showSelectAll={true}
        />
      </BWGridAPI>
    </StyledContent>
  );
};

FlagsTable.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  brandId: PropTypes.string,
};

export default FlagsTable;
