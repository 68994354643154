import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";

import PopoverDeleteConfirmation from "../../../../ui/PopoverDeleteConfirmation";
import propTypes from "../../../../../constants/propTypes";
import { DangerButton } from "../../../../forms/ActionButtons";

const DeleteRequirementButton = ({ specRequirement, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleOpen = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );

  const handleDelete = useCallback(() => {
    onSubmit(specRequirement.id);
    handleClose();
  }, [handleClose, specRequirement.id, onSubmit]);

  return (
    <Fragment>
      <DangerButton onClick={handleOpen} disableIfProjectClosed>
        DELETE REQUIREMENT
      </DangerButton>
      <PopoverDeleteConfirmation
        title={specRequirement.requirement.name}
        anchorEl={anchorEl}
        onDelete={handleDelete}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      />
    </Fragment>
  );
};

DeleteRequirementButton.propTypes = {
  specRequirement: propTypes.specRequirement,
  onSubmit: PropTypes.func.isRequired,
};

export default DeleteRequirementButton;
