import _omit from "lodash/omit";

import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import {
  handleSuccess,
  handleError,
} from "./CreateVendorContactsRequestHandlers";

export const handleCreateContactError = (
  formikProps,
  showSnackNotificationAction
) => response => {
  const firstNameError = response.data.errors.find(
    error => error.source === "firstName"
  );
  if (firstNameError) {
    firstNameError.source = "contact.firstName";
    response.data.errors.push({
      code: "unique",
      source: "contact.lastName",
    });
  }
  if (formikProps) {
    handleRequestError(response, formikProps);
  }
  handleError(response.data, showSnackNotificationAction);
};

export const onCreateVendorContact = (
  vendorContact,
  addressBook,
  dataComponentId,
  performCreateRequest
) => {
  if (vendorContact.contactId) {
    delete vendorContact.contact;
  }

  performCreateRequest(dataComponentId, vendorContact, {
    addressBookId: addressBook.id,
  });
};
export const onUpdateVendorContact = (
  vendorContact,
  dataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(
    dataComponentId,
    vendorContact.id,
    _omit(vendorContact, "contact.memberType")
  );
};
export const onDeleteVendorContact = (
  vendorContactId,
  dataComponentId,
  performDeleteRequest
) => {
  performDeleteRequest(dataComponentId, vendorContactId);
};
const processSaveRequest = (
  preDataComponent,
  dataComponent,
  vendorContactsDataComponentId,
  {
    performRetrieveListRequest,
    showSnackNotificationAction,
    closeModalDialog,
    formikProps,
  }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        vendorContactsDataComponentId,
        showSnackNotificationAction,
        "The vendor contact has been saved successfully",
        closeModalDialog
      );
    },
    onError: handleCreateContactError(formikProps, showSnackNotificationAction),
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        vendorContactsDataComponentId,
        showSnackNotificationAction,
        "The vendor contact has been updated successfully",
        closeModalDialog
      );
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        vendorContactsDataComponentId,
        showSnackNotificationAction,
        "The vendor contact has been deleted successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processRequests = (
  preDataComponent,
  dataComponent,
  vendorDataComponentId,
  callbacks
) => {
  processSaveRequest(
    preDataComponent,
    dataComponent,
    vendorDataComponentId,
    callbacks
  );
};
