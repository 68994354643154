import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ControlButtons from "./ControlButtons";
import NavBar from "./NavBar";
import NoteList from "./NoteList";
import NoteDetails from "./NoteDetails";
import Header from "./Header";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  @media (max-width: 768px) {
    flex-direction: column;
    overflow: auto;
    width: calc(100vw - 48px);
  }
`;

const NavListSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const NavListBody = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NotesWrapper = ({ onCloseModal }) => {
  return (
    <Wrapper>
      <ControlButtons onCloseModal={onCloseModal} />
      <NavListSection>
        <Header />
        <NavListBody>
          <NavBar />
          <NoteList />
        </NavListBody>
      </NavListSection>
      <NoteDetails />
    </Wrapper>
  );
};

NotesWrapper.propTypes = {
  onCloseModal: PropTypes.func,
};

export default memo(NotesWrapper);
