import { inputs } from "../../../inputs/inputConfigs";

const path = "contact";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            required: true,
            name: `${path}.${inputs.firstName.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            required: true,
            name: `${path}.${inputs.lastName.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            required: true,
            name: `${path}.${inputs.phone.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Fax",
            name: `${path}.${inputs.fax.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Cell Number",
            name: `${path}.cellphone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Work Number",
            name: `${path}.workPhone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            required: true,
            name: `${path}.${inputs.contactRole.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            required: true,
            name: `${path}.${inputs.email.name}`,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.canAccessClientPortal,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.status,
            name: `${path}.${inputs.status.name}`,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
};
