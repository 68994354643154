import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from "react";
import _noop from "lodash/noop";

export const ModalStateContext = createContext({
  modalState: {},
  setModalState: _noop,
  disabledTabs: [],
  setDisabledTabs: _noop(),
  tabActions: {},
  addTabActions: _noop(),
});

export const useModalStateContext = () => useContext(ModalStateContext);

export default function withModalState(Component) {
  const WithModalStateWrapper = props => {
    const [modalState, setModalState] = useState({});
    const [disabledTabs, setDisabledTabs] = useState([]);
    const [tabActions, setTabActions] = useState({});

    const addTabActions = useCallback(
      (name, action) => {
        setTabActions({
          ...tabActions,
          [name]: action,
        });
      },
      [tabActions]
    );

    const contextValue = useMemo(
      () => ({
        modalState,
        setModalState,
        disabledTabs,
        setDisabledTabs,
        tabActions,
        addTabActions,
      }),
      [addTabActions, disabledTabs, modalState, tabActions]
    );

    return (
      <ModalStateContext.Provider value={contextValue}>
        <Component {...props} setModalState={setModalState} />
      </ModalStateContext.Provider>
    );
  };

  WithModalStateWrapper.propTypes = {};

  return WithModalStateWrapper;
}
