import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import BidGroupVendor from "../../../../models/BidGroupVendor";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import AddVendorSpec from "./AddVendorSpec";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
export const dataComponentId = "VendorsGrid";
export const dataComponentBGVendorId = "BidGroupVendor";
export const dataComponentBGVendorSpecId = "BidGroupVendorSpec";
export const BGDataComponentId = "BidGroupDetailId";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload("VendorsGrid", true);
      closeModalDialog();
    },
  });
};

export const AddVendorSpecContainer = ({
  bidGroup,
  initDataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  dataComponent,
  bidGroupVendor,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    initDataComponent(
      dataComponentBGVendorId,
      BidGroupVendor,
      ["bidGroup", "vendor", "specs", "bidGroupVendorSpecs"],
      "bid-group-vendors"
    );
  }, [initDataComponent]);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload
    );
  }, [
    bidGroup,
    bidGroupVendor,
    closeModalDialog,
    setReload,
    dataComponent,
    prevDataComponent,
  ]);

  const selectedSpecs = useMemo(() => {
    return _get(bidGroupVendor, "specs", []).map(({ id }) => id);
  }, [bidGroupVendor]);

  const handleUpdateBidGroupVendor = useCallback(
    (selectedIds, updatedAttributes) => {
      const { bidGroupVendorSpecs = [] } = bidGroupVendor;
      const updatedBidGroupVendorSpecs = [];
      selectedIds.map(selectedId => {
        const attributes =
          updatedAttributes.find(({ specId }) => specId === selectedId) || {};

        const bidGroupVendorSpec = bidGroupVendorSpecs.find(
          bidGroupVendorSpec => bidGroupVendorSpec.specId === selectedId
        );

        const spec = bidGroup.specs.find(spec => spec.id === selectedId);

        let updatedBidGroupVendorSpec = {
          specId: selectedId,
          quotes: [
            {
              quoteItems: [
                {
                  specId: selectedId,
                  productionQuantity: spec.baseQuantity + spec.atticStock,
                  overagePercent: spec.overagePercent,
                },
              ],
            },
          ],
          ...attributes,
        };

        if (bidGroupVendorSpec) {
          delete updatedBidGroupVendorSpec.quotes;
          updatedBidGroupVendorSpec = {
            ...bidGroupVendorSpec,
            ...updatedBidGroupVendorSpec,
          };
        }

        updatedBidGroupVendorSpecs.push(updatedBidGroupVendorSpec);
      });

      performUpdateRequest(dataComponentBGVendorId, bidGroupVendor.id, {
        bidGroupVendorSpecs: updatedBidGroupVendorSpecs,
      });
    },
    [bidGroupVendor, performUpdateRequest, bidGroup]
  );

  return (
    <AddVendorSpec
      selectedSpecs={selectedSpecs}
      loading={loading}
      bidGroup={bidGroup}
      onUpdateBidGroupVendor={handleUpdateBidGroupVendor}
      closeModalDialog={closeModalDialog}
      bidGroupVendor={bidGroupVendor}
    />
  );
};

AddVendorSpecContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  bidGroup: propTypes.bidGroup,
  bidGroupVendor: propTypes.bidGroupVendor,
  dataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
};

AddVendorSpecContainer.defaultProps = {
  loading: false,
};

export const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(
      dataComponentBGVendorId,
      state,
      REQUEST_TYPES.UPDATE
    ),
    vendorsDataComponent: getDataComponent(BGDataComponentId, state),
    loading: getDataComponentFlattenedRequestState(
      dataComponentBGVendorId,
      state,
      REQUEST_TYPES.UPDATE
    ).loading,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVendorSpecContainer);
