import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  setHeaderTitle,
  setAutoSaveComponentId,
  showSnackNotificationAction,
} from "../../../actions/layoutActions";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import {
  performUpdateRequest,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import { initializeVendorDependencies } from "../../../actions/vendorActions";
import { LoaderContext } from "../../ui/Loader";
import propTypes from "../../../constants/propTypes";
import VendorForm from "../VendorCreate/VendorForm";
import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import {
  handleVendorCreateError,
  parseVendorName,
} from "../VendorCreate/VendorCreateContainer";

const dataComponentId = "VendorEdit";

export class VendorEditContainer extends Component {
  componentDidMount() {
    const {
      vendor,
      vendorId,
      performFindRequest,
      setAutoSaveComponentId,
      initializeVendorDependencies,
    } = this.props;

    setAutoSaveComponentId(dataComponentId);
    initializeVendorDependencies(dataComponentId);
    performFindRequest(dataComponentId, vendorId);

    if (vendor) {
      this.props.setHeaderTitle(`Edit ${vendor.name}`);
    }
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onError: handleVendorCreateError(
        this.props.showSnackNotificationAction,
        this.state?.formikActions
      ),
    });
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
  }

  handleEditVendor = (
    { parentId, name, poName, w9Name, ...patchBody },
    formikActions
  ) => {
    const { performUpdateRequest, vendor } = this.props;

    performUpdateRequest(dataComponentId, vendor.id, {
      ...patchBody,
      name: parseVendorName(name),
      poName: parseVendorName(poName),
      w9Name: parseVendorName(w9Name),
    });
    this.setState({ formikActions });
  };

  render() {
    const { vendor, loading } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <VendorForm
          vendor={vendor}
          sendButtonText="Save"
          onSubmit={this.handleEditVendor}
          isUpdate
          dataComponentId={dataComponentId}
        />
      </LoaderContext.Provider>
    );
  }
}

VendorEditContainer.propTypes = {
  loading: PropTypes.bool,
  vendor: propTypes.vendor,
  vendorId: PropTypes.string,
  setHeaderTitle: PropTypes.func,
  dataComponent: PropTypes.object,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  initializeVendorDependencies: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const vendorId = ownProps.match.params.vendorId;
  return {
    vendorId,
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
    vendor: getBluechipResourceById(dataComponent, state, vendorId),
  };
};

const mapDispatchToProps = {
  setHeaderTitle,
  performFindRequest,
  performUpdateRequest,
  setAutoSaveComponentId,
  showSnackNotificationAction,
  initializeVendorDependencies,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VendorEditContainer)
);
