import BWModels from "benjaminwest-models";

export const currencyToNumber = value => {
  if (typeof value !== "string") {
    return value;
  }
  return Number(value.replace(/[^\d.-]/g, ""));
};

export const numericOnChange = (onChange, name) => values => {
  const parsedValue =
    values.value.length === 0 ? undefined : currencyToNumber(values.value);
  const value = isNaN(parsedValue) ? values.value : parsedValue;
  onChange({
    target: {
      name,
      value,
    },
  });
};

export const numericOnBlur = (onBlur, name) => ({
  target: { value: rawValue },
}) => {
  const parsedValue =
    rawValue.length === 0 ? undefined : currencyToNumber(rawValue);
  const value = isNaN(parsedValue) ? rawValue : parsedValue;
  onBlur({
    target: {
      name,
      value,
    },
  });
};

export const getOptionsFromSchema = (modelName, optionsKey) => () => {
  const schema = BWModels.loadSchema(modelName);
  return schema.__utils[optionsKey];
};

export const NullComponent = () => null;

export const removeControlCharacters = (value, omitControlChars) =>
  omitControlChars && typeof value == "string"
    ? // eslint-disable-next-line no-control-regex
      value.replace(/[\uE000-\uE00F\u0000-\u0009\u007F-\u009F]/g, " ")
    : value;
