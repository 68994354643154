import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InvoicesList from "./InvoicesList";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../../reducers/dataComponentReducer";
import propTypes from "../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import {
  initDataComponent,
  setReload,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../../../actions/layoutActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { pushWithReturnUrl } from "../../../../../actions/navigationActions";
import { FormikForm } from "../../../../forms";

export const dataComponentId = "invoicesGrid";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  handleRowClick,
  closeModalDialog
) => () => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      handleRowClick({
        id: dataComponent.requestState[RequestTypes.CREATE].rowIndex[0],
      });
    },
  });
};

const InvoicesContainer = ({
  flattenedDataComponent,
  dataComponent,
  openModalDialog,
  closeModalDialog,
  setAutoSaveComponentId,
  projectId,
  pushWithReturnUrl,
  clientId,
  generateAndDownloadReport,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  const handleRowClick = useCallback(
    ({ id, isVoid }) => {
      if (isVoid) {
        return;
      }

      pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/invoices/${id}`,
        "INVOICES"
      );
    },
    [projectId, clientId, pushWithReturnUrl]
  );

  useEffect(() => {
    setAutoSaveComponentId(dataComponentId);
  }, [setAutoSaveComponentId]);

  useEffect(
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      handleRowClick,
      closeModalDialog
    )
  );

  const handleDownloadCheckRequestLog = (
    { id: invoiceId },
    showPreview = false
  ) => {
    generateAndDownloadReport({
      format: "pdf",
      invoiceId,
      projectId,
      templateName: "invoice-check-request-log",
      showPreview,
    });
  };

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Add Invoice",
      "CreateInvoice",
      {
        projectId,
      },
      false
    );
  }, [openModalDialog, projectId]);

  const handleOpenVoidModal = useCallback(
    invoice => {
      openModalDialog(
        "Void Invoice",
        "VoidInvoice",
        {
          invoice,
          dataComponentId,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    invoice => {
      openModalDialog(
        "Delete Invoice",
        "DeleteInvoice",
        {
          invoice,
          dataComponentId,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <FormikForm>
      {() => (
        <InvoicesList
          onDownloadCheckRequestLog={handleDownloadCheckRequestLog}
          onRowClick={handleRowClick}
          onOpenCreateModal={handleOpenCreateModal}
          onOpenVoidInvoiceModal={handleOpenVoidModal}
          onOpenDeleteInvoiceModal={handleOpenDeleteModal}
          dataComponentId={dataComponentId}
          dataComponent={flattenedDataComponent}
          projectId={projectId}
        />
      )}
    </FormikForm>
  );
};

InvoicesContainer.propTypes = {
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  pushWithReturnUrl: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { projectId, clientId },
    },
  } = props;
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    projectId,
    clientId,
    dataComponent,
    loading: dataComponent.loading,
    flattenedDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  setReload,
  performRetrieveListRequest,
  openModalDialog,
  setAutoSaveComponentId,
  pushWithReturnUrl,
  generateAndDownloadReport,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesContainer);
