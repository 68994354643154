import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoaderContext } from "../../../ui/Loader";
import EditSpecPricingForm from "./EditSpecPricingForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { performUpdateRequest } from "../../../../actions/dataComponentActions";
import _get from "lodash/get";
import propTypes from "../../../../constants/propTypes";
import withPORevision from "../../../../withPORevision";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";

const dataComponentId = "SpecDetail";

export class EditSpecPricingContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleEditPricing = ({ priceCents, clientBudgetCents }, formikActions) => {
    const { spec, performUpdateRequest, revisionValue } = this.props;
    const pricingProps = {
      priceCents: cleanCurrencyValue(priceCents * 100),
      clientBudgetCents: cleanCurrencyValue(clientBudgetCents * 100),
    };

    performUpdateRequest(
      dataComponentId,
      spec.id,
      pricingProps,
      revisionValue.isActive && {
        update_po_revision: true,
      }
    );
    this.setState({ formikActions });
  };

  render() {
    const { spec, row, loading } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <EditSpecPricingForm
          row={row}
          spec={spec}
          handleEditPricing={this.handleEditPricing}
        />
      </LoaderContext.Provider>
    );
  }
}

EditSpecPricingContainer.propTypes = {
  spec: propTypes.spec,
  loading: PropTypes.bool,
  row: PropTypes.object.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(EditSpecPricingContainer, "SpecDetail", "purchaseOrder"));
