import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import RegularText from "../../../../ui/Typography/RegularText";
import MUICheckbox from "../../../../mui/core/Checkbox";
import { useCheckboxManager } from "../CheckboxManager";
import { PageContext } from "../../selectors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled(MUICheckbox)`
  padding: 0;
  margin-right: 24px;
`;

export const Anchor = styled.a`
  color: #57abff;
  margin-left: 4px;
  cursor: pointer;
`;

const SelectAllCheckbox = () => {
  const { isAllChecked, setAllStatus } = useCheckboxManager();

  const handleChange = useCallback(
    (_, checked) => {
      setAllStatus(checked);
    },
    [setAllStatus]
  );

  return (
    <Checkbox disableRipple checked={isAllChecked()} onChange={handleChange} />
  );
};

const SelectionActions = ({ actions }) => {
  const { getSelectedIds } = useCheckboxManager();
  const { spec } = useContext(PageContext);
  const selectedIds = getSelectedIds();

  const handleDelete = () => {
    actions.openBulkDeleteSpecDetails(spec, selectedIds);
  };

  return (
    <Wrapper>
      <SelectAllCheckbox />
      {selectedIds.length === 0 ? (
        <RegularText>Select All</RegularText>
      ) : (
        <RegularText>
          {selectedIds.length} Details Selected{" "}
          <Anchor onClick={handleDelete}>Delete Details</Anchor>
        </RegularText>
      )}
    </Wrapper>
  );
};

SelectionActions.propTypes = {
  actions: PropTypes.shape({
    openBulkDeleteSpecDetails: PropTypes.func.isRequired,
  }),
};

export default SelectionActions;
