import { useMemo } from "react";
import { usePerformRetrieveList } from "./usePerformRetrieveList";
import WorkScope from "../../models/WorkScope";

export const useListWorkScopes = () => {
  const filters = useMemo(() => ({}), []);
  const dataComponentDef = useMemo(
    () => ({
      dataComponentId: "workScopeListDataComponentId",
      model: WorkScope,
      includes: [],
      apiRoute: "work-scopes",
    }),
    []
  );
  const { data: workScopes } = usePerformRetrieveList(
    dataComponentDef,
    filters,
    []
  );

  return workScopes;
};
