import BidGroupVendorSpec from "../BidGroupVendorSpec";

describe("BidGroupVendorSpec Model", () => {
  test("belongsTo relations", () => {
    expect(
      BidGroupVendorSpec.belongsTo.map(relation => relation.name)
    ).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(
      BidGroupVendorSpec.hasMany.map(relation => relation.name)
    ).toMatchSnapshot();
  });
});
