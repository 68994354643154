import { useEffect } from "react";

import { useLoading } from "./useDatacomponent";
import useActions from "./useActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../actions/dataComponentActions";
import { useV2DatacomponentResources } from "./useResources";
import * as RequestTypes from "../../constants/RequestTypes";

export const usePerformRetrieveList = (
  dataComponent,
  filters,
  defaultData = []
) => {
  const { dataComponentId } = dataComponent;

  const actions = useActions({ initDataComponent, performRetrieveListRequest });

  useEffect(() => {
    const {
      dataComponentId,
      model,
      includes,
      apiRoute,
      skipInitIfExists,
    } = dataComponent;

    actions.initDataComponent(
      dataComponentId,
      model,
      includes,
      apiRoute,
      skipInitIfExists,
      "v2"
    );
    actions.performRetrieveListRequest(dataComponentId, filters);
  }, [actions, dataComponent, filters]);

  const isLoading = useLoading(dataComponentId, { LIST: RequestTypes.LIST });

  const data = useV2DatacomponentResources(
    dataComponentId,
    defaultData,
    RequestTypes.LIST
  );

  return { isLoading, data };
};
