import React, { useEffect, useState } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { connect as formikConnect } from "formik";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import _isEmpty from "lodash/isEmpty";

import {
  openModalDialog,
  closeModalDialog,
} from "../../../actions/layoutActions";
import propTypes from "../../../constants/propTypes";

export const buildSubmitAndNavigate = ({
  onSubmit,
  push,
  location,
  closeModalDialog,
}) => () => {
  onSubmit({
    afterSubmit: () => {
      push(location.pathname);
      closeModalDialog();
    },
  });
};

export const buildBeforeUnload = ({ dirty }) => event => {
  if (dirty) event.returnValue = true;
};

export const handleNavigate = ({
  location,
  push,
  closeModalDialog,
  setLocation,
}) => {
  if (location) {
    push(location.pathname);
    closeModalDialog();
    setLocation(null);
  }
};

export const handleConfirmNavigationClick = ({
  openModalDialog,
  closeModalDialog,
  push,
  setLocation,
  onSubmit,
  dataComponentId,
  location,
  initialLocation,
}) => newLocation => {
  if (initialLocation.pathname !== newLocation.pathname) {
    openModalDialog(
      [
        "Unsaved changes",
        "You will lose any unsaved changes if you leave this page",
      ],
      "DirtyFormDialog",
      {
        submitText: "Save",
        onSubmit: buildSubmitAndNavigate({
          onSubmit,
          push,
          location: newLocation,
          closeModalDialog,
        }),
        discardText: "Discard",
        onDiscard: () => {
          setLocation(newLocation);
        },
        listeners: [dataComponentId],
      },
      true,
      true
    );

    return !!location || false;
  }

  return true;
};

const DirtyFormPrompt = ({
  withSubmitCount,
  withTouchedEmpty,
  formik,
  openModalDialog,
  closeModalDialog,
  push,
  onSubmit,
  dataComponentId,
  location: initialLocation,
}) => {
  const [location, setLocation] = useState(null);

  const { dirty: formikDirty, submitCount, touched } = formik;

  const dirty = formikDirty && !(withTouchedEmpty && _isEmpty(touched));

  useEffect(() => {
    handleNavigate({ location, push, closeModalDialog, setLocation });
  }, [closeModalDialog, location, push]);

  useEffect(() => {
    const beforeUnload = buildBeforeUnload({ dirty });
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [dataComponentId, dirty, onSubmit, openModalDialog]);

  return (
    <Prompt
      when={withSubmitCount ? dirty && submitCount === 0 : dirty}
      message={handleConfirmNavigationClick({
        openModalDialog,
        closeModalDialog,
        push,
        setLocation,
        onSubmit,
        dataComponentId,
        location,
        initialLocation,
      })}
    />
  );
};

DirtyFormPrompt.propTypes = {
  formik: PropTypes.shape({
    dirty: PropTypes.bool,
    submitCount: PropTypes.number,
  }),
  withSubmitCount: PropTypes.bool,
  withTouchedEmpty: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  location: propTypes.location.isRequired,
};

DirtyFormPrompt.defaultProps = {
  withSubmitCount: true,
};

const mapDispatchToProps = {
  openModalDialog,
  closeModalDialog,
  push,
};

export default connect(
  null,
  mapDispatchToProps
)(formikConnect(withRouter(DirtyFormPrompt)));
