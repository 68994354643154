import React, { useContext } from "react";
import styled from "styled-components";
import memoize from "memoize-one";

import { BWTableRow } from "../../../../ui/BWGrid/gridInternalComponents";
import { LoaderContext } from "../../../../ui/Loader";
import { StyledSelectorCheckbox } from "./Selector";

const RowWrapper = ({ loading, isSelected, ...props }) => (
  <BWTableRow {...props} />
);

export const StyledBWTableRow = styled(RowWrapper)`
  background-color: ${({ isSelected }) =>
    isSelected ? "rgba(255, 255, 255, 0.7)" : null};
  &:hover {
    > td {
      background-color: ${({ hasClickHandler }) =>
        hasClickHandler && "rgba(255, 255, 255, 0.7) !important"};
    }
  }
  ${StyledSelectorCheckbox} {
    color: ${({ isSelected }) =>
      isSelected ? "rgb(116,177,52)" : "rgb(184,184,184)"};
  }
`;

export function buildClickHandler(loading, itemId, selectHandler) {
  return loading ? undefined : () => selectHandler(itemId);
}

export const tableRowBuilder = memoize((currentSelectedId, selectHandler) => {
  return function TableRow(props) {
    const { loading } = useContext(LoaderContext);
    const {
      row: { id: itemId },
    } = props;
    const isSelected = currentSelectedId && itemId === currentSelectedId;
    const handleClick = buildClickHandler(loading, itemId, selectHandler);
    return (
      <StyledBWTableRow
        isSelected={isSelected}
        onClick={handleClick}
        {...props}
        hasClickHandler={!!handleClick}
      />
    );
  };
});
