import React, { Component } from "react";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import User from "../../../models/User";
import { Content } from "../../layout/Content";
import Paper from "../../mui/core/Paper";
import TagText from "../../ui/Tags/TagText";
import { BWIntent } from "../../ui/BWIntent";

const intentMap = {
  Locked: BWIntent.DANGER,
  Active: BWIntent.PRIMARY,
};

export const Status = ({ locked }) => {
  const status = locked ? "Locked" : "Active";
  return <TagText intent={intentMap[status]}>{status}</TagText>;
};

Status.propTypes = {
  locked: PropTypes.bool,
};

const columns = [
  "locked",
  "name",
  "office.name",
  "userRole.name",
  "email",
  "phoneNumber",
  "team.name",
];
const columnOptions = {
  locked: {
    title: "Status",
    render: Status,
  },
  name: { bold: true, filter: true },
  "office.name": { title: "Office", filter: true },
  "userRole.name": { title: "Role", filter: true },
  email: { title: "Email Address", filter: true },
  phoneNumber: {
    title: "Phone Number",
    filter: true,
  },
  "team.name": { title: "Team", fill: true, filter: true },
};
const rowMenu = events => [
  { icon: <Edit />, text: "Edit", onClick: events.onUserSettingsClick },
  { separator: true },
  { icon: <Cancel />, text: "Delete User", onClick: events.onOpenDeleteModal },
];

export default class UsersPage extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
    onAddUserClick: PropTypes.func.isRequired,
    onUserSettingsClick: PropTypes.func.isRequired,
    onOpenDeleteModal: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      dataComponent,
      onAddUserClick,
      onRowClick,
      onUserSettingsClick,
      onOpenDeleteModal,
    } = this.props;

    return (
      <Content>
        <Paper>
          <BWGridAPI
            dataComponent={dataComponent}
            model={User}
            includes={["userRole", "office", "team"]}
            apiRoute="users"
            defaultSorting={[
              {
                columnName: "name",
                direction: "asc",
              },
            ]}
          >
            <GridHeader
              headerText={`${dataComponent.totalRows} Users`}
              actions={[
                {
                  text: "ADD USER",
                  icon: <AddCircle />,
                  handler: onAddUserClick,
                },
              ]}
            />
            <GridTable
              columns={columns}
              columnOptions={columnOptions}
              rowMenu={rowMenu({ onUserSettingsClick, onOpenDeleteModal })}
              showSelectionColumn={true}
              showSelectAll={true}
              onClick={onRowClick}
            />
          </BWGridAPI>
        </Paper>
      </Content>
    );
  }
}
