import Vendor from "../models/Vendor";
import VendorCategory from "../models/VendorCategory";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "./dataComponentActions";
import { openModalDialog, showSnackNotificationAction } from "./layoutActions";
import { populateStateResources } from "./countryStateActions";

export function fetchVendorCategories() {
  return async dispatch => {
    dispatch(
      initDataComponent(
        "select-vendorCategories",
        VendorCategory,
        [],
        "vendor-categories"
      )
    );
    dispatch(
      performRetrieveListRequest("select-vendorCategories", {
        sort: [{ columnName: "name", direction: "asc" }],
      })
    );
  };
}

export function initializeVendorDependencies(dataComponentId) {
  return async dispatch => {
    fetchVendorCategories();

    dispatch(
      initDataComponent(dataComponentId, Vendor, ["location"], "vendors")
    );

    dispatch(populateStateResources());
  };
}

export function openVendorEmailPreview(
  bidGroupVendor,
  bidGroup,
  project,
  props
) {
  return async dispatch => {
    dispatch(
      openModalDialog(
        "Vendor Email Preview",
        "VendorEmailPreview",
        {
          bidGroup,
          bidGroupVendor,
          project,
          ...props,
          showSnackNotificationAction: (message, hideDuration) =>
            dispatch(showSnackNotificationAction(message, hideDuration)),
        },
        true,
        false,
        {
          width: "700px",
          maxHeight: "700px",
          scrollDisabled: true,
          // layoutType: "wide",
        }
      )
    );
  };
}
