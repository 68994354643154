import { useEffect } from "react";

export const useSetClientPricing = ({
  feeBasis,
  isUpdate,
  setFieldValue,
  client,
}) => {
  useEffect(() => {
    if (feeBasis == "Tiered Pricing" && !isUpdate) {
      const [clientPricing] = client.clientPricings
        .filter(({ isActive }) => isActive)
        .sort((a, b) => b.version - a.version);
      setFieldValue("clientPricing", clientPricing);
    }
  }, [client, feeBasis, isUpdate, setFieldValue]);
};
