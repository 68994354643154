import React from "react";
import { connect } from "react-redux";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { Paper } from "../../../mui/core";
import QueryReport from "../../../../models/QueryReport";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import {
  gridActions,
  getColumnOptions,
  getColumns,
  rowMenu,
} from "./gridConfig";
import { queryReportDataComponentId } from "../../../../actions/queryReportsActions";
import {
  performDeleteRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import useActions from "../../../hooks/useActions";
import {
  useCreateProcessRequests,
  useDeleteProcessRequests,
  useUpdateProcessRequests,
} from "../../../hooks/useProcessRequest";
import useUserRole from "../../../hooks/useUserRole";
import { SCOPES_SELECT } from "../../../../constants/DataComponents";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";

const createOnSuccess = (message, actions) => () => {
  actions.closeModalDialog();
  actions.performRetrieveListRequest(queryReportDataComponentId);
  actions.showSnackNotificationAction(message);
};

export const QueryReportsContainer = ({
  dataComponent,
  flattenedDataComponent,
  scopes,
}) => {
  const actions = useActions({
    openModalDialog,
    closeModalDialog,
    performDeleteRequest,
    performRetrieveListRequest,
    showSnackNotificationAction,
  });

  useCreateProcessRequests(dataComponent, {
    onSuccess: createOnSuccess("Query Report created successfully.", actions),
  });
  useUpdateProcessRequests(dataComponent, {
    onSuccess: createOnSuccess("Query Report updated successfully.", actions),
  });
  useDeleteProcessRequests(dataComponent, {
    onSuccess: createOnSuccess("Query Report removed successfully.", actions),
  });

  const handleCreateQueryReport = () => {
    actions.openModalDialog(false, "SaveQueryReport", {}, true, true, {
      width: "unset",
      layoutType: "wide",
    });
  };

  const handleUpdateQueryReport = queryReport => {
    actions.openModalDialog(
      false,
      "SaveQueryReport",
      { queryReport },
      true,
      true,
      {
        width: "unset",
        layoutType: "wide",
      }
    );
  };

  const handleDeleteQueryReport = ({ id, name }) => {
    actions.openModalDialog(
      "Delete Query Report",
      "GeneralConfirmation",
      {
        title: `Are you sure you want to delete ${name}?`,
        sendButtonText: "Delete Query Report",
        cancelButtonText: "Cancel",
        onSubmit: () => {
          actions.performDeleteRequest(queryReportDataComponentId, id);
          actions.closeModalDialog();
        },
      },
      false,
      true
    );
  };

  const userRole = useUserRole();

  return (
    <Paper>
      <BWGridAPI
        apiRoute="query-reports"
        model={QueryReport}
        includes={["queryReportParams", "scopes"]}
        dataComponent={flattenedDataComponent}
      >
        <GridHeader
          headerText={`${pluralize(
            "Query Report",
            flattenedDataComponent.totalRows,
            true
          )}`}
          actions={gridActions(handleCreateQueryReport)}
        />
        <GridTable
          columns={getColumns(userRole)}
          columnOptions={getColumnOptions(scopes)}
          rowMenu={rowMenu({
            handleDeleteQueryReport,
            handleUpdateQueryReport,
          })}
        />
      </BWGridAPI>
    </Paper>
  );
};

QueryReportsContainer.propTypes = {
  dataComponent: propTypes.dataComponent.isRequired,
  flattenedDataComponent: propTypes.dataComponent.isRequired,
  scopes: PropTypes.array,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    queryReportDataComponentId,
    state
  );

  const dataComponent = getDataComponent(queryReportDataComponentId, state);
  const scopesDataComponent = getDataComponentFlattenedRequestState(
    SCOPES_SELECT,
    state
  );
  return {
    dataComponent,
    flattenedDataComponent,
    scopes: getResourcesV2(scopesDataComponent, state) || [],
  };
};

export default connect(mapStateToProps, null)(QueryReportsContainer);
