import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import FormGrid from "../../../../components/forms/FormGrid";
import ActionButtons from "../../../../components/forms/ActionButtons";
import createSpecPricingFields from "./createSpecPricingFields";
import FormikForm from "../../../../components/forms/FormikForm";
import propTypes from "../../../../constants/propTypes";

const getValidationSchema = () => {
  return BWModels.loadSchema("Spec");
};

const getInitialValues = ({ priceCents, clientBudgetCents }) => ({
  priceCents: priceCents / 100,
  clientBudgetCents: clientBudgetCents / 100,
});

const EditSpecPricingForm = ({ row, spec, handleEditPricing }) => (
  <FormikForm
    initialValues={getInitialValues(spec)}
    onSubmit={handleEditPricing}
    validationSchema={getValidationSchema()}
    ignoreValidationFieldNames={[
      "projectId",
      "areaId",
      "specCategoryId",
      "customNumber",
    ]}
  >
    {({ handleSubmit, values, errors, ...formikProps }) => (
      <Fragment>
        <FormGrid
          fields={createSpecPricingFields(row.type)}
          values={values}
          errors={errors}
          {...formikProps}
        />
        <ActionButtons onSend={handleSubmit} isModal={true} />
      </Fragment>
    )}
  </FormikForm>
);

EditSpecPricingForm.propTypes = {
  spec: propTypes.spec,
  row: PropTypes.object.isRequired,
};

export default EditSpecPricingForm;
