import React, { useState } from "react";

import { Paper } from "../../../mui/core";
import { SwitchContext } from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import Grid from "./Grid";

export const toggleSwitch = (fn, currentValue) => () => fn(!currentValue);

const Received = () => {
  const [showInClosingProjects, setShowInClosingProjects] = useState(false);
  const [showClosedProjects, setShowClosedProjects] = useState(false);

  const handleShowInClosingToggle = toggleSwitch(
    setShowInClosingProjects,
    showInClosingProjects
  );
  const handleShowClosedToggle = toggleSwitch(
    setShowClosedProjects,
    showClosedProjects
  );

  return (
    <SwitchContext.Provider
      value={{
        onToggle: [handleShowInClosingToggle, handleShowClosedToggle],
        checked: [showInClosingProjects, showClosedProjects],
        label: ["Show In Closing Projects", "Show Closed Projects"],
      }}
    >
      <Paper>
        <Grid
          showInClosingProjects={showInClosingProjects}
          showClosedProjects={showClosedProjects}
        />
      </Paper>
    </SwitchContext.Provider>
  );
};

export default Received;
