import React, { useCallback } from "react";
import EditColumn from "./EditColumn";
import propTypes from "../../../../constants/propTypes";
import PropTypes from "prop-types";
import _get from "lodash/get";
import pluralize from "pluralize";

const hasAllSpecs = (specsLength, bidGroupSpecsLength) =>
  specsLength === bidGroupSpecsLength;

export const SpecItemRow = ({ row, specs, onOpenAddVendorSpec }) => {
  const bidGroupSpecsLength = _get(row, "specs.length", 0);
  const specsLength = _get(specs, "length", 0);
  const allSpecs = hasAllSpecs(specsLength, bidGroupSpecsLength);

  const handleClick = useCallback(() => {
    onOpenAddVendorSpec(row);
  }, [onOpenAddVendorSpec, row]);

  const getText = useCallback(() => {
    if (!bidGroupSpecsLength) return "Select Items";
    if (allSpecs) return `All Items`;

    return pluralize("Item", bidGroupSpecsLength, true);
  }, [allSpecs, bidGroupSpecsLength]);

  return <EditColumn onClick={handleClick} label={getText()} />;
};

SpecItemRow.propTypes = {
  row: propTypes.bidGroupVendor,
  specs: PropTypes.arrayOf(propTypes.spec),
  onOpenAddVendorSpec: PropTypes.func,
};

export default SpecItemRow;
