import { inputs } from "../../../inputs/inputConfigs";

export const datacomponentCommoditiesId = "select-commodities";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.commodities,
            dataComponentId: datacomponentCommoditiesId,
          },
        },
      ],
    },
  ],
};
