export const handleTabChange = (event, push, url) => {
  const { path } = event.currentTarget.attributes;
  if (path) {
    push(`${url}${path.nodeValue}`);
  }
};
export const handleOpenMoreMenu = (event, setAnchorElement) => {
  setAnchorElement(event.currentTarget);
};
export const handleCloseMoreMenu = setAnchorElement => {
  setAnchorElement(null);
};
export const handleMoreItemClicked = (url, push, path, setAnchorElement) => {
  setAnchorElement(null);
  push(`${url}${path}`);
};
