import React, { Fragment } from "react";
import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";
import _get from "lodash/get";

const StyledAddCircle = styled(AddCircle)`
  margin-right: 8px;
`;

export const Icon = () => <StyledAddCircle />;

export function openCOM({
  spec,
  specDetail,
  excludeComIds,
  openModalDialog,
  projectId,
  clientId,
  purchaseOrderId,
}) {
  openModalDialog(
    false,
    "COMLibrary",
    {
      spec,
      specDetail,
      isSelectCOM: true,
      excludeComIds,
      projectId,
      clientId,
      purchaseOrderId,
    },
    false,
    false,
    {
      layoutType: "wide",
      freezeBackground: true,
    }
  );
}

export function openDescription({
  spec,
  specDetail,
  openModalDialog,
  purchaseOrderId,
}) {
  openModalDialog(
    ["Description", `${spec.customNumber} ${spec.description}`],
    "EditSpecDetailDescription",
    { spec, specDetail, purchaseOrderId },
    false,
    false
  );
}

export function openPreview({
  spec,
  specDetail,
  openModalDialog,
  purchaseOrderId,
}) {
  openModalDialog(
    [
      `${specDetail ? "" : "Add"} Preview`,
      `${spec.customNumber} ${spec.description}`,
    ],
    "EditPreview",
    { spec, specDetail, purchaseOrderId },
    false,
    false
  );
}

export function openPARBasedQuantityCalculation({
  spec,
  specDetail,
  openModalDialog,
  purchaseOrderId,
}) {
  openModalDialog(
    [
      "PAR Based Quantity Calculation",
      `${spec.customNumber} ${spec.description}`,
    ],
    "PARBasedQuantityCalculation",
    { spec, specDetail, purchaseOrderId },
    false,
    true,
    {
      width: "650px",
    }
  );
}

export function openCaseBasedQuantityCalculation({
  spec,
  specDetail,
  openModalDialog,
  purchaseOrderId,
}) {
  openModalDialog(
    [
      "Case Based Quantity Calculation",
      `${spec.customNumber} ${spec.description}`,
    ],
    "CaseBasedQuantityCalculation",
    { spec, specDetail, purchaseOrderId },
    false
  );
}

export const getExcludedCOMIds = spec => {
  const specDetails = Object.values(spec.specDetails) || [];
  const comSpecDetail = specDetails.find(
    specDetail => specDetail.type === "COM"
  );
  return _get(comSpecDetail, "specDetailComs", []).map(({ specId }) => specId);
};

export const openAddDetailModal = (
  spec,
  openDescription,
  addSpecDetailMenuItems,
  openModalDialog,
  menuItemsProps,
  addSpecDetails
) => {
  const onClickArgs = {
    spec,
    specDetail: null,
    openModalDialog,
    addSpecDetails,
    ...menuItemsProps,
  };

  if (spec.specCategory && spec.specCategory.key === "COM_ITEM") {
    // COM items can only have descriptions added
    const items = addCOMDetailMenuItems(onClickArgs);
    openModalDialog(
      ["Add Details", `${spec.customNumber} ${spec.description}`],
      "MenuModal",
      { spec, items },
      false,
      true
    );
    return;
  }

  onClickArgs.excludeComIds = getExcludedCOMIds(spec);

  const items = addSpecDetailMenuItems(onClickArgs);
  openModalDialog(
    ["Add Details", `${spec.customNumber} ${spec.description}`],
    "MenuModal",
    { spec, items },
    false,
    true
  );
};

export const addCOMDetailMenuItems = onClickArgs => {
  const { addSpecDetails, spec } = onClickArgs;

  const menuItems = [
    {
      children: (
        <Fragment>
          <Icon /> Add Description
        </Fragment>
      ),
      onClick: () => openDescription(onClickArgs),
    },
    {
      children: (
        <Fragment>
          <Icon /> ADD DEFAULT SPEC CATEGORY DETAILS
        </Fragment>
      ),
      onClick: () => addSpecDetails(spec),
    },
  ];
  return menuItems;
};

export default function addSpecDetailMenuItems(onClickArgs) {
  const hideMenuItemAndTypeMap = {};
  if (onClickArgs.spec.specDetails) {
    onClickArgs.spec.specDetails.map(({ type }) => {
      hideMenuItemAndTypeMap[type] = true;
    });
  }

  const { addSpecDetails } = onClickArgs;

  const menuItems = [
    {
      children: (
        <Fragment>
          <Icon /> Add Description
        </Fragment>
      ),
      onClick: () => openDescription(onClickArgs),
    },
    {
      children: (
        <Fragment>
          <Icon /> Add Preview
        </Fragment>
      ),
      onClick: () => openPreview(onClickArgs),
    },
    {
      children: (
        <Fragment>
          <Icon /> Add COM LIBRARY Item
        </Fragment>
      ),
      onClick: () => openCOM(onClickArgs),
    },
  ];

  if (addSpecDetails) {
    menuItems.push({
      children: (
        <Fragment>
          <Icon /> ADD DEFAULT SPEC CATEGORY DETAILS
        </Fragment>
      ),
      onClick: () => addSpecDetails(onClickArgs.spec),
    });
  }

  if (!hideMenuItemAndTypeMap["QCParBased"]) {
    menuItems.push({
      children: (
        <Fragment>
          <Icon /> Add PAR Based Quantity Calculation
        </Fragment>
      ),
      onClick: () => openPARBasedQuantityCalculation(onClickArgs),
    });
  }

  if (!hideMenuItemAndTypeMap["QCCaseBased"]) {
    menuItems.push({
      children: (
        <Fragment>
          <Icon /> Add Case Based Quantity Calculation
        </Fragment>
      ),
      onClick: () => openCaseBasedQuantityCalculation(onClickArgs),
    });
  }

  return menuItems;
}
