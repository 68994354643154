import React from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import PropTypes from "prop-types";
import { useWindowWidth } from "../../../../../hooks/useWindowWidth";

export const getAspectRatio = windowSize => {
  if (windowSize < 600) {
    return 3.0 / 12.0;
  }
  if (windowSize < 960) {
    return 3.0 / 9.0;
  }
  if (windowSize < 1280) {
    return 3.0 / 6.0;
  }
  return 4.0 / 3.0;
};
const ForecastByAreaSectionBarChart = props => {
  const { data, tickFormatter, tooltipFormatter } = props;
  const windowSize = useWindowWidth();

  return (
    <ResponsiveContainer width="100%" aspect={getAspectRatio(windowSize)}>
      <BarChart
        data={data}
        margin={{
          top: 48,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        layout="vertical"
        maxBarSize={22}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="value"
          type="number"
          tickFormatter={tickFormatter}
          angle={-45}
          height={100}
          textAnchor="end"
        />
        <YAxis
          dataKey="label"
          type="category"
          tick={{ fontSize: 12, fill: "rgba(0, 0, 0, 0.87)" }}
          width={80}
          tickMargin={8}
          angle={45}
          textAnchor="end"
        />
        <Tooltip
          cursor={{ fill: "transparent" }}
          formatter={tooltipFormatter}
        />
        <Bar dataKey="value" fill="#57abff" />
      </BarChart>
    </ResponsiveContainer>
  );
};
ForecastByAreaSectionBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  tooltipFormatter: PropTypes.func,
  tickFormatter: PropTypes.func,
};
export default ForecastByAreaSectionBarChart;
