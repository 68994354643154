import React, { useCallback, useState } from "react";
import styled from "styled-components";
import UppyDashboard from "@uppy/react/lib/Dashboard";
import {
  CircularProgress as MuiCircularProgress,
  Dialog,
} from "@material-ui/core";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import pluralize from "pluralize";

import "@uppy/drag-drop/dist/style.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import theme from "../../../theme";
import { useUppy } from "../../hooks/useUppy";

const Wrapper = styled.div`
  height: 100%;

  & .uppy-Root {
    height: 100%;
  }

  & .uppy-Dashboard {
    height: 100%;
  }
`;

export const ReactDashboard = styled(UppyDashboard)`
  & .uppy-c-btn-primary {
    background-color: ${theme.palette.primary.main} !important;
    text-transform: uppercase;
    padding: 16px 24px !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }

  & .uppy-c-btn-primary:focus {
    box-shadow: none;
  }

  & .uppy-Dashboard-AddFiles-info {
    display: block !important;
  }
`;
const CircularProgress = styled(MuiCircularProgress)`
  color: white;
`;

const acceptList = accept => {
  const formats = accept.split(",");
  if (formats.length == 1) {
    return accept;
  }

  if (formats.length == 2) {
    return formats[0] + " and " + formats[1];
  }

  return formats.slice(0, -1).join(", ") + ", and " + formats.at(-1);
};
export const getNote = (accept, multiple) =>
  `${multiple ? "Multiple files" : "One file"} in ${
    isEmpty(accept)
      ? "any format"
      : "the following " +
        pluralize("format", accept.split(",").length) +
        " " +
        acceptList(accept)
  } can be uploaded.`;

export const DragDropDashboard = ({
  accept,
  multiple,
  onChange,
  onComplete = noop,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const onUpload = useCallback(
    uppy => {
      setLoading(true);
      /**
       * Set timeout to let setLoading free to update UI since onChange is dislaying it
       */
      setTimeout(() => {
        const files = uppy.getFiles().map(({ data }) => data);
        onChange({ target: { files } });
      }, 0);
    },
    [onChange]
  );

  const onUploadComplete = useCallback(
    uppy => {
      uppy.getFiles().forEach(({ id }) => uppy.removeFile(id));
      onComplete();
      setLoading(false);
    },
    [onComplete]
  );

  const uppy = useUppy({
    accept,
    multiple,
    onUpload,
    onUploadComplete,
  });

  return (
    <Wrapper>
      <ReactDashboard
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        note={getNote(accept, multiple)}
        style={{ height: "100%" }}
        {...props}
      />
      <Dialog
        open={loading}
        PaperProps={{
          elevation: 0,
          style: { backgroundColor: "transparent", display: "contents" },
        }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Dialog>
    </Wrapper>
  );
};
