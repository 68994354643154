import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import propTypes from "../../../../../../constants/propTypes";
import StatusForm from "./StatusForm";
import { LoaderContext } from "../../../../../ui/Loader";
import FundingPayments from "./FundingPayments";

const VOID_STATUS = "Void";
export const dataComponentIdCreate = "FundingCreate";

const FundingPaidConfirmation = ({
  updateFunding,
  funding,
  dataComponent,
  performAfterCreate,
  fundingPaymentComponent,
  createFundingPayment,
  performAfterCreatePayment,
  showSnackNotificationAction,
}) => {
  const [formikActions, setFormikAction] = useState(null);
  const [showList, setShowList] = useState(true);

  const isLoading = useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      performAfterCreate(formikActions);
    },
    onError: error => {
      showSnackNotificationAction(error.title);
    },
  });

  const handleVoidFundingRequest = useCallback(
    (_, formikActions) => {
      setFormikAction(formikActions);
      updateFunding(funding.id, {
        status: VOID_STATUS,
      });
    },
    [funding, updateFunding]
  );

  const toggleShowList = () => {
    setShowList(!showList);
  };

  return (
    <LoaderContext.Provider value={{ isLoading }}>
      {showList ? (
        <FundingPayments
          dataComponentId={dataComponentIdCreate}
          onVoidFundingRequest={handleVoidFundingRequest}
          number={funding.id}
          rows={funding.fundingPayments}
          toggleShowList={toggleShowList}
          showVoid={
            funding.fundingPayments
              ? funding.fundingPayments.length === 0
              : true
          }
          funding={funding || {}}
        />
      ) : (
        <StatusForm
          dataComponent={fundingPaymentComponent}
          funding={funding}
          toggleShowList={toggleShowList}
          onSubmit={createFundingPayment}
          showSnackNotificationAction={showSnackNotificationAction}
          performAfterCreatePayment={performAfterCreatePayment}
        />
      )}
    </LoaderContext.Provider>
  );
};

FundingPaidConfirmation.defaultProps = {
  funding: {
    fundingPayments: [],
  },
};

FundingPaidConfirmation.propTypes = {
  funding: propTypes.funding,
  updateFunding: PropTypes.func.isRequired,
  performAfterCreate: PropTypes.func,
  loading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  fundingPaymentComponent: propTypes.component,
  createFundingPayment: PropTypes.func,
  performAfterCreatePayment: PropTypes.func,
  showSnackNotificationAction: PropTypes.func,
};

export default FundingPaidConfirmation;
