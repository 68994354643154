import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  showSnackNotificationAction,
  dismissSnackNotificationAction,
} from "../../../actions/layoutActions";
import SnackNotification from "./SnackNotification";

class SnackNotificationContainer extends Component {
  static propTypes = {
    snackNotification: PropTypes.object,
    showSnackNotificationAction: PropTypes.func,
    dismissSnackNotificationAction: PropTypes.func,
  };

  handleClose = () => {
    this.props.dismissSnackNotificationAction();
  };

  render() {
    return (
      <SnackNotification
        snackNotification={this.props.snackNotification}
        onClose={this.handleClose}
      />
    );
  }
}

const mapStateToProps = ({ layout }) => ({
  snackNotification: layout.snackNotification,
});

const mapDispatchToProps = {
  showSnackNotificationAction,
  dismissSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackNotificationContainer);
