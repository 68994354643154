import React from "react";
import moment from "moment";
import styled from "styled-components";
import { startCase } from "lodash";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

import { Error } from "../../../../inputs/LabeledPONumber";
import { BWIntent } from "../../../../ui/BWIntent";

const WarningIconWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

export const WarningIcon = ({ row: { shipment }, fieldName, targetField }) => {
  if (
    !shipment ||
    !targetField ||
    !moment(shipment[targetField]).isAfter(shipment[fieldName], "day")
  ) {
    return null;
  }

  return (
    <WarningIconWrapper>
      <Tooltip
        title={`${startCase(fieldName)} cannot be before ${startCase(
          targetField
        )}`}
        placement="right-start"
      >
        <Error badgeSize={20} />
      </Tooltip>
    </WarningIconWrapper>
  );
};
WarningIcon.propTypes = {
  row: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  targetField: PropTypes.string.isRequired,
};

export const shouldDisplayFutureWarning = (shipment, fieldName) =>
  shipment && fieldName && moment(shipment[fieldName]).isAfter(moment(), "day");

export const ShipmentFutureDeliveryWarning = ({
  row: { shipment },
  fieldName,
}) => {
  return shouldDisplayFutureWarning(shipment, fieldName) ? (
    <FutureDeliveryWarningIcon fieldName={fieldName} />
  ) : null;
};
ShipmentFutureDeliveryWarning.propTypes = {
  row: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
};

export const FutureDeliveryWarningIcon = ({ fieldName }) => {
  return (
    <WarningIconWrapper>
      <Tooltip
        title={`${startCase(fieldName)} is a future date`}
        placement="right-start"
      >
        <Error
          color={BWIntent.values.backgroundColors.warning}
          badgeSize={20}
        />
      </Tooltip>
    </WarningIconWrapper>
  );
};
FutureDeliveryWarningIcon.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
