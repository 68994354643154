import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Joi from "joi-browser";

import { FormikForm, FormGrid, ActionButtons } from "../../../../forms";
import {
  getFields,
  getFilterDataOptions,
  getInitialValues,
  mapDataType,
} from "./fields";
import { LabeledTextContext } from "../../../../../withPORevision/withLabeledTextOption";
import {
  selectCustomReportDataComponentId,
  selectQueryReportDataComponentId,
} from "./GenerateCustomReportContainer";
import CustomReport from "../../../../../models/CustomReport";
import QueryReport from "../../../../../models/QueryReport";
import useUserRole from "../../../../hooks/useUserRole";
import { ProjectContext } from "../../../../hooks/useIsProjectClosed";

export const Footer = styled.footer`
  padding: 24px;
  height: 96px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const buildValidationSchema = ({
  selectedReport: { queryReportParams = [], ...selectedReport } = {},
}) => {
  const validationObject = {
    reportId: Joi.string().required(),
  };

  const validationType = {
    number: Joi.number(),
    integer: Joi.number().integer(),
    text: Joi.string().trim(),
    date: Joi.date(),
    enum: Joi.any(),
    boolean: Joi.boolean(),
    id: Joi.alternatives().try(Joi.number(), Joi.array().min(1)),
  };

  const filterDataOptions = getFilterDataOptions(selectedReport);

  filterDataOptions.forEach(({ selectName, filter, dataType, columnName }) => {
    if (filter === "range") {
      validationObject[selectName] = Joi.array().items(
        validationType[mapDataType(dataType, columnName)]
      );
      return;
    }

    validationObject[selectName] = validationType[
      mapDataType(dataType, columnName)
    ].required();
  });

  queryReportParams.forEach(({ name, type }) => {
    validationObject[name] = validationType[mapDataType(type)].required();
  });

  return Joi.object().keys(validationObject);
};

export const generateCustomReportDataComponentId = "generate-custom-report";

export const GenerateCustomReport = ({
  customReports,
  queryReports,
  reportsLoader,
  loading,
  initDataComponent,
  performRetrieveListRequest,
  closeModalDialog,
  generateAndDownloadReport,
}) => {
  const [reportType, setReportType] = useState("customReport");
  const [reportId, setReportId] = useState(null);

  const userRole = useUserRole();

  useEffect(() => {
    initDataComponent(
      selectCustomReportDataComponentId,
      CustomReport,
      ["customReportDataOptions"],
      "custom-reports",
      true,
      "v2"
    );
    initDataComponent(
      selectQueryReportDataComponentId,
      QueryReport,
      ["queryReportParams"],
      "query-reports",
      true,
      "v2"
    );
    performRetrieveListRequest(selectCustomReportDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      rootFilters: {
        $where: {
          userRoleIds: {
            $jsonInRaw: `{${userRole.id}}`,
          },
        },
      },
    });
    performRetrieveListRequest(selectQueryReportDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      rootFilters: {
        $where: {
          userRoleIds: {
            $jsonInRaw: `{${userRole.id}}`,
          },
        },
      },
    });
  }, [initDataComponent, performRetrieveListRequest, userRole.id]);

  const reports = reportType === "customReport" ? customReports : queryReports;
  const selectedReport = reports.find(report => report.id === reportId);

  const initialValues = useMemo(() => getInitialValues(selectedReport), [
    selectedReport,
  ]);

  const validationSchema = useMemo(
    () => buildValidationSchema({ selectedReport }),
    [selectedReport]
  );

  const projectContext = useContext(ProjectContext);

  return (
    <ProjectContext.Provider
      value={{
        ...projectContext,
        ignoreProjectClosed: true,
      }}
    >
      <FormikForm
        initialValues={initialValues}
        valueOverrides={initialValues}
        onSubmit={({ reportId, description, ...values }) => {
          const queries = [];
          Object.keys(values).forEach(key => {
            if (reportType === "customReport") {
              return queries.push({
                relation: key,
                queryType: selectedReport.customReportDataOptions.find(
                  ({ selectName }) => selectName === key
                ).filter,
                queryParam: values[key],
              });
            }
            queries.push({
              name: key,
              value: values[key],
            });
          });
          generateAndDownloadReport({ reportType, reportId, queries });
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          values,
          errors,
          setFieldValue,
          setValues,
          ...formikProps
        }) => {
          return (
            <LabeledTextContext.Provider value={false}>
              <FormGrid
                fields={getFields({
                  reportType,
                  selectedReport,
                  setReportId,
                  setReportType,
                  setFieldValue,
                })}
                values={values}
                errors={errors}
                {...formikProps}
              />
              <Footer>
                <ActionButtons
                  onCancel={closeModalDialog}
                  sendButtonText={"Excel Export"}
                  listeners={[generateCustomReportDataComponentId]}
                  disabled={loading}
                  hideCancelButton={loading}
                  loading={loading || reportsLoader["undefined-csv-false"]}
                  onSend={handleSubmit}
                />
              </Footer>
            </LabeledTextContext.Provider>
          );
        }}
      </FormikForm>
    </ProjectContext.Provider>
  );
};

export default GenerateCustomReport;
