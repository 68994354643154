import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Settings from "@material-ui/icons/Settings";

import {
  PageHeader,
  TitleArea,
  NavigationRoutes,
} from "../../layout/PageHeader";
import Entities from "../Entities";
import Properties from "../Properties";
import Projects from "../ClientProjects";
import Contacts from "../ClientContacts";
import Terms from "./Terms";
import GlCodes from "./GLCodes";
import LocationCombined from "../../ui/LocationCombined";
import { ActionIcon } from "../ProjectDetail/components";
import { Button } from "../../layout/PageHeader/TitleArea";
import Tooltip from "@material-ui/core/Tooltip";
import Reports from "./Reports";
import TieredFees from "./TieredFees";
import { Assignment } from "@material-ui/icons";

const navigationTabs = [
  { path: `/entities`, label: "Entities", component: Entities },
  { path: `/properties`, label: "Properties", component: Properties },
  { path: `/projects`, label: "Projects", component: Projects },
  { path: `/contacts`, label: "Contacts", component: Contacts },
  { path: `/terms`, label: "Terms", component: Terms },
  { path: `/gl-codes`, label: "GL Codes", component: GlCodes },
  { path: `/reports`, label: "Reports", component: Reports },
  { path: `/tiered-fees`, label: "Tiered Fees", component: TieredFees },
];

const Website = styled.p`
  padding-top: 4px;
`;

const ClientDetailPage = ({ client, onOpenMenu }) => {
  const { name, location, website, msaFileId } = client;

  return (
    <Fragment>
      <PageHeader navigationTabs={navigationTabs}>
        <TitleArea
          title={name}
          info={
            msaFileId ? (
              <Assignment style={{ margin: "3px 3px 3px 8px" }} />
            ) : null
          }
        >
          <Tooltip title="Edit">
            <Button onClick={onOpenMenu}>
              <ActionIcon Icon={Settings} />
            </Button>
          </Tooltip>
        </TitleArea>
        <div>
          <p>
            <LocationCombined location={location} />
          </p>
          {website && <Website>{website}</Website>}
        </div>
      </PageHeader>

      <NavigationRoutes
        navigationTabs={navigationTabs}
        pageProps={{ client }}
      />
    </Fragment>
  );
};

ClientDetailPage.propTypes = {
  client: PropTypes.object,
  onOpenMenu: PropTypes.func,
};

ClientDetailPage.defaultProps = {
  client: {},
};

export default ClientDetailPage;
