import { useEffect, useMemo, useCallback, memo } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { getDirtyFields } from "../../utils/formValidationUtils";
import AutoSaveManager from "./AutoSaveManager";

const AutoSave = ({
  formik,
  onSave,
  noDelayFields,
  delay,
  flushAutoSave,
  setFlushAutoSave,
}) => {
  const autoSaveManager = useMemo(() => new AutoSaveManager(delay), [delay]);
  const performSave = useCallback(
    values => {
      formik.setStatus("submitted");

      const handleSave = errors => {
        if (!_isEmpty(errors)) return;
        onSave(values, formik);
        const { values: formikValues, resetForm } = formik;
        resetForm({ values: formikValues });
        autoSaveManager.bulkData = {};
      };
      formik.validateForm(values).then(handleSave);
    },
    [autoSaveManager, formik, onSave]
  );

  useEffect(() => {
    autoSaveManager.setOptions(performSave, noDelayFields);
  }, [autoSaveManager, noDelayFields, performSave]);

  useEffect(() => {
    const values = getDirtyFields(formik);
    autoSaveManager.push(values);
  }, [autoSaveManager, formik]);

  useEffect(() => {
    if (!flushAutoSave) setFlushAutoSave(() => autoSaveManager.flushAutoSave());
  }, [autoSaveManager, flushAutoSave, setFlushAutoSave]);

  return null;
};

AutoSave.defaultProps = { noDelayFields: [] };
AutoSave.propTypes = {
  formik: PropTypes.object,
  onSave: PropTypes.func,
  noDelayFields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
  ),
  flushAutoSave: PropTypes.func,
  setFlushAutoSave: PropTypes.func,
};

export default memo(AutoSave);
