import React, { Fragment, useMemo, useEffect } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { EditIcon } from "../../../../BidGroupDetail/components";
import { MissingIcon } from "../../Funding/components";
import propTypes from "../../../../../../constants/propTypes";
import DateColumn from "../../../../../ui/BWGrid/columns/Date";
import Loader from "../../../../../ui/Loader";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { dataComponentId } from "../../../../Accounting/Projects/ProjectsContainer";
import { useIsProjectClosed } from "../../../../../hooks/useIsProjectClosed";
import { PRIMARY_DATE_FORMAT } from "../../../../../../constants/formats";

const ProjectEditableAttribute = ({
  project,
  title,
  attribute,
  initEditProject,
  dataComponentUpdate,
  showSnackNotificationAction,
  openModalDialog,
}) => {
  const isLoading = useUpdateProcessRequests(dataComponentUpdate, {
    onSuccess: () => {
      showSnackNotificationAction("The Project has been updated successfully");
    },
  });
  useEffect(() => {
    initEditProject(dataComponentId);
  }, [initEditProject]);

  const handleOpenPicker = () => {
    openModalDialog(
      `${title} Date`,
      "EditProjectDateColumn",
      {
        project,
        column: attribute,
      },
      false
    );
  };

  const formattedValue = useMemo(() => {
    return _get(project, attribute) ? (
      <DateColumn
        date={_get(project, attribute)}
        format={PRIMARY_DATE_FORMAT}
      />
    ) : (
      <MissingIcon />
    );
  }, [attribute, project]);

  const isProjectClosed = useIsProjectClosed();

  return (
    <Fragment>
      <Loader loading={isLoading} height="18px" width="200px">
        {formattedValue}
        {!isProjectClosed && <EditIcon onClick={handleOpenPicker} />}
      </Loader>
    </Fragment>
  );
};

ProjectEditableAttribute.propTypes = {
  updateProject: PropTypes.func,
  initEditProject: PropTypes.func,
  showSnackNotificationAction: PropTypes.func,
  project: propTypes.project,
  projectId: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  dataComponentUpdate: propTypes.dataComponent,
  openModalDialog: PropTypes.func,
  title: PropTypes.string,
};

export default ProjectEditableAttribute;
