import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import {
  createRemitAddressDataComponent,
  createRemitAddress,
  CreateDataComponentId,
} from "../../../../actions/remitAddressAction";
import { closeModalDialog } from "../../../../actions/layoutActions";

import useActions from "../../../hooks/useActions";
import useCreateRemitAddressStore from "../../../hooks/useCreateRemitAddressStore";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import { useCreateProcessRequests } from "../../../hooks/useProcessRequest";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import propTypes from "../../../../constants/propTypes";
import { parsePointOfContact } from "./createRemitAddressFunctions";
import Form from "./Form";

const CreateRemitAddress = ({
  vendorId,
  titleModal,
  vendor,
  processDataComponent = noop,
}) => {
  const formikActionsRef = useRef();
  const stateActions = useCreateRemitAddressStore(state => state.actions);

  const actions = useActions({
    createRemitAddressDataComponent,
    createRemitAddress,
    closeModalDialog,
  });

  useEffect(() => {
    actions.createRemitAddressDataComponent();
    stateActions.openRemitAddressForm();
    stateActions.setTitleModal(titleModal);
    stateActions.setVendor(vendor);
    stateActions.setCurrentForm({ vendorId, pointOfContacts: [] });
    return () => {
      stateActions.resetStore();
    };
  }, [actions, stateActions, titleModal, vendor, vendorId]);

  const dataComponent = useDatacomponent(CreateDataComponentId);
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      processDataComponent(dataComponent);
    },
    onError: error => handleRequestError(error, formikActionsRef.current),
  });

  const handleCreateRemitAddress = (remitAddress, formikActions) => {
    formikActionsRef.current = formikActions;
    actions.createRemitAddress({
      ...remitAddress,
      pointOfContacts: remitAddress.pointOfContacts.map(parsePointOfContact),
      vendorId,
    });
  };

  return (
    <Form
      vendorId={vendorId}
      onSubmit={handleCreateRemitAddress}
      dataComponentId={CreateDataComponentId}
    />
  );
};

CreateRemitAddress.propTypes = {
  vendorId: PropTypes.string,
  vendor: propTypes.vendor,
  titleModal: PropTypes.string,
  processDataComponent: PropTypes.func,
};

export default CreateRemitAddress;
