import React from "react";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import InputLabel from "../../mui/core/InputLabel";

export const ProgramTag = styled(({ loading, ...props }) => (
  <Chip {...props} classes={{ icon: "icon", label: "label" }} />
))`
  background-color: #f1f1f1;
  opacity: ${({ loading }) => (loading ? "0.5" : "1")};
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px;
  margin-bottom: 8px;

  & .icon {
    margin-left: 8px;
    font-size: 16px;
  }

  & .icon,
  .label {
    opacity: ${({ loading }) => (loading ? "0" : "1")};
  }
`;

export const GroupLabel = styled(InputLabel)`
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 14px;
`;

export const ProgramTagContainer = styled.div({});

export const ProgramTagHeader = styled.header`
  margin: 8px 0px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`;

export const SelectedCounter = styled.div`
  margin-top: 18px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;

export const ProgramTagError = styled.div`
  font-size: 12px;
  color: red;
`;

export const ProgramTagSection = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 3px;
  max-height: 450px;
  overflow-y: auto;
  padding: 16px;
`;
