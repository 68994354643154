import React from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import {
  ItemWrapper,
  OptionsWrapper,
  Title,
  Value,
} from "../Accounting/Fees/FeeSummary/components";
import Loader from "../../../ui/Loader";
import propTypes from "../../../../constants/propTypes";
import PriceRow from "./PriceRow";
import { TabContainer, ColumnCard } from "./components";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";

export const ColumnItem = ({ row: { title, value } }) => {
  return (
    <OptionsWrapper>
      <ItemWrapper>
        {title && (
          <Title>
            <Loader width="200px">{title}</Loader>
          </Title>
        )}
        <Value noTitle={!title}>
          <Loader width="200px">{value}</Loader>
        </Value>
      </ItemWrapper>
    </OptionsWrapper>
  );
};

ColumnItem.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.any,
  }),
};

export const getProjectCostRowsFunding = (currencyFormatter, project) => {
  return [
    {
      title: "Total Funds Requested",
      value: currencyFormatter.format(project.totalFundings),
    },
    {
      title: "Total Funds Received",
      value: currencyFormatter.format(project.totalFundsReceived),
    },
    {
      title: "Funds Pending",
      value: currencyFormatter.format(
        project.totalFundings - project.totalFundsReceived
      ),
    },
    {
      title: "Cash Balance",
      value: currencyFormatter.format(
        project.totalFundsReceived - project.expenditures
      ),
    },
  ];
};

const projectCostRows = [
  { attr: "uncommittedPrice", fixed: true, title: "Total Uncommitted" },
  { attr: "committedPrice", fixed: true, title: "Total Committed" },
  { attr: "total", fixed: true, title: "Total Merchandise Cost" },
  {
    attr: "totalVendorFreightCost",
    title: "Total Vendor Freight Cost",
  },
  {
    attr: "totalFreightCost",
    title: "Total Freight Cost",
  },
  {
    attr: "totalWarehousingCost",
    title: "Total Warehousing Cost",
  },
  {
    attr: "totalInstallCost",
    title: "Total Install Cost",
  },
];

export const getProjectCostRowsProjectCost = (
  currencyFormatter,
  projectPrice
) => {
  return projectCostRows.reduce((rows, { attr, fixed, title }) => {
    const value = projectPrice[attr];

    if (!value && !fixed) return rows;

    rows.push({ title, value: currencyFormatter.format(value) });
    return rows;
  }, []);
};

const defaultContingencies = [{ name: "1" }, { name: "2" }, { name: "3" }];
const getContingencies = projectForecast => {
  if (!_get(projectForecast, "contingencies")) return defaultContingencies;
  return projectForecast.contingencies.filter(({ value }) => value > 0);
};

const ProjectPrice = ({ projectForecast, project }) => {
  const currencyFormatter = useCurrencyFormatter();
  const firstValues = [
    {
      title: "Contract Budget",
      value: currencyFormatter.format(project.contractBudget),
    },
    ...getProjectCostRowsProjectCost(currencyFormatter, projectForecast),
    ..._get(projectForecast, "otherCosts", []).map(otherCost => ({
      title: `Total ${otherCost.name} Cost`,
      value: currencyFormatter.format(otherCost.value),
    })),
    {
      value: [
        ...getContingencies(projectForecast).map(({ name, value }) => (
          <PriceRow
            small
            name={name}
            value={value}
            key={`contingency-${name}`}
            applyLastBorder
          />
        )),
        <PriceRow
          small
          name="Taxes"
          value={projectForecast.salesTax}
          key={`Taxes`}
          applyLastBorder
        />,

        <PriceRow
          name="Total Forecast"
          value={projectForecast.totalForecastAmount}
          key={`total-forecast`}
          applyLastBorder
        />,
      ],
    },
  ];

  const secondValues = getProjectCostRowsFunding(currencyFormatter, project);
  return (
    <TabContainer>
      <ColumnCard text="Project Cost">
        {firstValues.map((row, key) => (
          <ColumnItem row={row} key={key} />
        ))}
      </ColumnCard>
      <ColumnCard text="Funding">
        {secondValues.map((row, key) => (
          <ColumnItem row={row} key={key} />
        ))}
      </ColumnCard>
    </TabContainer>
  );
};

ProjectPrice.propTypes = {
  projectForecast: propTypes.projectForecast,
  project: propTypes.project,
};

export default ProjectPrice;
