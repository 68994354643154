export const uploadFileActions = ({
  selectedSpecRequirements,
  actions,
  spec,
  onUpdateSpec,
  specRequirements,
  newSubmittalFilesRef,
  uploadCount,
  setSubmittalFiles,
  submittalFiles,
  setLoaderActive,
  handleClear,
}) => {
  const getSubmittalFile = (uploadCount, submittalFiles) => {
    const uploadCountS3KeySet = new Set(
      newSubmittalFilesRef.current
        .filter(submittalFile => submittalFile.uploadCount == uploadCount)
        .map(({ file: { s3Key } }) => s3Key)
    );
    return submittalFiles.find(submittalFile =>
      uploadCountS3KeySet.has(submittalFile.file.s3Key)
    );
  };

  const handleUpload = async evt => {
    const file = evt.target.files[0];
    if (!file) return;

    setLoaderActive("upload");
    for await (const specRequirement of selectedSpecRequirements) {
      const newSubmittalFile = await actions.saveSpecSubmittal(
        {
          file: { file, isUpdated: true },
        },
        specRequirement,
        spec,
        onUpdateSpec,
        specRequirements.findIndex(({ id }) => id == specRequirement.id)
      );
      newSubmittalFilesRef.current.push({
        ...newSubmittalFile,
        specRequirement,
        uploadCount: uploadCount.current,
      });
    }
    uploadCount.current++;
    setSubmittalFiles([...submittalFiles, newSubmittalFilesRef.current.at(-1)]);
    handleClear();
  };

  const handleSave = async ({
    approvedDescription,
    receivedDescription,
    uploadCount,
  }) => {
    setLoaderActive("edit");
    for await (const specRequirement of selectedSpecRequirements) {
      const { submittalFiles } = specRequirements.find(
        ({ id }) => id == specRequirement.id
      );
      const submittalFile = getSubmittalFile(uploadCount, submittalFiles);
      await actions.saveSpecSubmittal(
        {
          ...submittalFile,
          approvedDescription,
          receivedDescription,
        },
        specRequirement,
        spec,
        onUpdateSpec,
        specRequirements.findIndex(({ id }) => id == specRequirement.id),
        submittalFiles.findIndex(({ id }) => id == submittalFile.id)
      );
    }
    handleClear();
  };

  const handleApprove = ({ uploadCount }) => async submittal => {
    const isApproved = !!submittal;
    setLoaderActive("approve");
    selectedSpecRequirements.forEach(specRequirement => {
      const { submittalFiles } = specRequirements.find(
        ({ id }) => id == specRequirement.id
      );
      const submittalFile = getSubmittalFile(uploadCount, submittalFiles);
      specRequirement.submittalApprovedId = isApproved
        ? submittalFile.id
        : null;
    });
    await onUpdateSpec({
      specRequirements: specRequirements.map(
        ({ submittalApproved, spec, ...specRequirement }) => specRequirement
      ),
    });
    handleClear();
  };

  const handleDelete = async ({ uploadCount }) => {
    selectedSpecRequirements.forEach(specRequirement => {
      const { submittalFiles } = specRequirements.find(
        ({ id }) => id == specRequirement.id
      );
      const submittalFile = getSubmittalFile(uploadCount, submittalFiles);
      specRequirement.submittalFiles = specRequirement.submittalFiles.filter(
        ({ id }) => id != submittalFile.id
      );
    });
    await onUpdateSpec({
      specRequirements: specRequirements.map(
        ({ submittalApproved, spec, ...specRequirement }) => specRequirement
      ),
    });
    setSubmittalFiles(
      submittalFiles.filter(
        submittalFile => submittalFile.uploadCount != uploadCount
      )
    );
    handleClear();
  };

  return {
    handleUpload,
    handleSave,
    handleApprove,
    handleDelete,
  };
};
