import React, { useMemo, useState } from "react";
import RootRef from "@material-ui/core/RootRef";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import useSticky from "../../hooks/useSticky";
import { connect } from "react-redux";
import {
  StyledMenu,
  StyledListItem,
  StyledListItemText,
} from "../../menus/RowMenu";
import {
  handleTabChange,
  handleOpenMoreMenu,
  handleCloseMoreMenu,
  handleMoreItemClicked,
} from "./NavigationTabsHandlers";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { useWindowWidth } from "../../hooks/useWindowWidth";
import { List, ListItemIcon } from "@material-ui/core";

const OFFSET_MENU_OPEN = 458;
const OFFSET_MENU_CLOSED = 277;

export const NavigationTabs = ({
  location: { pathname },
  isNavbarDrawerOpen,
  navigationTabs,
  children,
  match,
  push,
  Tabs,
  Tab,
  moreTabColor,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const size = useWindowWidth();
  const { isSticky, element } = useSticky();

  const [anchorElement, setAnchorElement] = useState(null);

  const tabIndex = useMemo(() => {
    const activeTab = navigationTabs.find(({ path }) =>
      pathname.startsWith(`${match.url}${path}`)
    );
    return activeTab ? activeTab.path : false;
  }, [match.url, navigationTabs, pathname]);
  const appendMore = hiddenTabs => {
    if (hiddenTabs.length > 0) {
      return [
        <Tab
          key={"1"}
          onClick={event => handleOpenMoreMenu(event, setAnchorElement)}
          style={{
            color: moreTabColor ? moreTabColor : "#FFFFFF",
            paddingTop: "0px",
            minHeight: "48px",
            opacity: "1",
          }}
          label={"More"}
          icon={
            <ArrowDropDown
              style={{
                position: "absolute",
                right: "8px",
              }}
            />
          }
        />,
        <StyledMenu
          key={"2"}
          id={"rowMenu_hiddentabs"}
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={() => handleCloseMoreMenu(setAnchorElement)}
        >
          <List component="nav">
            {hiddenTabs.map((hiddenTab, index) => (
              <StyledListItem
                key={index}
                onClick={() =>
                  handleMoreItemClicked(
                    match.url,
                    push,
                    hiddenTab.path,
                    setAnchorElement
                  )
                }
                style={{
                  minWidth: "166px",
                }}
              >
                {hiddenTab.icon && (
                  <ListItemIcon>{hiddenTab.icon}</ListItemIcon>
                )}
                <StyledListItemText primary={hiddenTab.label} />
              </StyledListItem>
            ))}
          </List>
        </StyledMenu>,
      ];
    }
  };

  if (navigationTabs.length === 0) return null;

  const hiddenTabs = [];
  const offsetWidth =
    navigationTabs.length <= 2
      ? size
      : isNavbarDrawerOpen
      ? size - OFFSET_MENU_OPEN
      : size - OFFSET_MENU_CLOSED;

  return (
    <RootRef rootRef={element}>
      <Tabs
        value={tabIndex}
        isSticky={isSticky}
        width={offsetWidth}
        onChange={event => handleTabChange(event, push, match.url)}
      >
        {children}
        {navigationTabs.map(({ label, path, icon }, index) => {
          const tabPosition = 166 * (index + 1);
          if (tabPosition >= offsetWidth) hiddenTabs.push({ label, path });
          return (
            <Tab
              label={label}
              path={path}
              value={path}
              key={path}
              icon={icon}
              style={tabPosition >= offsetWidth ? { display: "none" } : {}}
            />
          );
        })}
        {appendMore(hiddenTabs)}
      </Tabs>
    </RootRef>
  );
};

NavigationTabs.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  navigationTabs: propTypes.navigationTabs,
  push: PropTypes.func,
  Tabs: propTypes.component.isRequired,
  Tab: propTypes.component.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  moreTabColor: PropTypes.string,
};

NavigationTabs.defaultProps = {
  navigationTabs: [],
  isNavbarDrawerOpen: true,
};
export const mapStateToProps = ({ layout }) => ({
  isNavbarDrawerOpen: layout.isNavbarDrawerOpen,
});
export default connect(mapStateToProps)(NavigationTabs);
