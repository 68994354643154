import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import ShipToSiteLayout from "./ShipToSiteLayout";
import { FieldArray } from "formik";
import FormikForm from "../../../forms/FormikForm";

const createComponent = "CreateShipToSiteContainer";
const deleteComponent = "DeleteShipToSiteContainer";
const name = "locations";

const buildOpenDeleteHandler = (
  openModalDialog,
  formikArrayHelpers,
  onDeleteShipToSite
) => ({ id, name }, { rowId }) => {
  openModalDialog(
    `Delete ${name}`,
    deleteComponent,
    {
      vendorShipToSiteId: id,
      title: name,
      formikArrayHelpers,
      index: `${rowId}`,
      onDeleteShipToSite,
    },
    false
  );
};

const buildOpenEditHandler = (
  openModalDialog,
  formikArrayHelpers,
  onCreateShipToSite,
  onUpdateShipToSite,
  vendorContacts,
  showSnackNotificationAction
) => (shipToSite, { rowId }) => {
  openModalDialog(
    "Edit Site",
    createComponent,
    {
      formikArrayHelpers,
      shipToSite,
      index: `${rowId}`,
      onCreateShipToSite,
      onUpdateShipToSite,
      vendorContacts,
      showSnackNotificationAction,
      isEditing: true,
    },
    false,
    true,
    {
      width: "800px",
    }
  );
};

const ShipToSiteContainer = ({
  vendorContacts,
  openModalDialog,
  showSnackNotificationAction,
  values,
  onCreateShipToSite,
  onUpdateShipToSite,
  onDeleteShipToSite,
}) => {
  const handleAddClick = useCallback(
    (formikArrayHelpers, onCreateShipToSite, onUpdateShipToSite) => {
      openModalDialog(
        "Add Site",
        createComponent,
        {
          formikArrayHelpers,
          onCreateShipToSite,
          onUpdateShipToSite,
          showSnackNotificationAction: showSnackNotificationAction,
          vendorContacts: vendorContacts,
          isEditing: false,
        },
        false,
        true,
        {
          width: "800px",
        }
      );
    },
    [openModalDialog, showSnackNotificationAction, vendorContacts]
  );

  return (
    <FormikForm>
      {() => (
        <FieldArray name={name}>
          {formikArrayHelpers => (
            <ShipToSiteLayout
              rows={values[name]}
              onOpenAddModal={() =>
                handleAddClick(
                  formikArrayHelpers,
                  onCreateShipToSite,
                  onUpdateShipToSite
                )
              }
              onOpenEditModal={buildOpenEditHandler(
                openModalDialog,
                formikArrayHelpers,
                onCreateShipToSite,
                onUpdateShipToSite,
                vendorContacts,
                showSnackNotificationAction
              )}
              onOpenDeleteModal={buildOpenDeleteHandler(
                openModalDialog,
                formikArrayHelpers,
                onDeleteShipToSite
              )}
            />
          )}
        </FieldArray>
      )}
    </FormikForm>
  );
};

ShipToSiteContainer.propTypes = {
  openModalDialog: PropTypes.func.isRequired,
  values: PropTypes.object,
  vendorContacts: PropTypes.array.isRequired,
  onCreateShipToSite: PropTypes.func.isRequired,
  onDeleteShipToSite: PropTypes.func.isRequired,
  onUpdateShipToSite: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  openModalDialog,
  showSnackNotificationAction,
};

export default withRouter(
  connect(null, mapDispatchToProps)(ShipToSiteContainer)
);
