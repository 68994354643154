import styled from "styled-components";
import { Button } from "@material-ui/core";

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
  font-family: Domine, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
`;
export const TitleRowWrapper = styled.div`
  margin: 6px 0px 0px 0px;
`;
export const ExpandActionWrapper = styled.div`
  padding: 0px;
`;
export const ExpandButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 0px;
  text-transform: uppercase;
  color: #57abff;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

export const OutstandingStatus = styled.span`
  font-size: 14px;
  color: red;
`;
