import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";
import { inputs } from "../../../inputs/inputConfigs";
const { quote: quoteFields } = inputs;

const addRequiredToInput = input => {
  return {
    ...input,
    required: true,
    emphasizeRequired: true,
  };
};

export default purchaseOrder => {
  const currency = purchaseOrder?.projectCurrency?.currency;
  const isProjectClosed = useIsProjectClosed();
  return {
    groups: [
      {
        items: [
          {
            input: quoteFields.quoteEstimatedShipDate,
            grid: { xs: 4 },
          },
          {
            input: quoteFields.vat,
            grid: { xs: 4 },
          },
          {
            grid: { xs: 4 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimesFrom),
            grid: { xs: 4 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimesTo),
            grid: { xs: 4 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimeForSubmittal),
            grid: { xs: 4 },
          },
          {
            input: quoteFields.dropDeadDates,
            grid: { xs: 12 },
          },
          {
            input: addRequiredToInput(quoteFields.factoryLocation),
            grid: { xs: 12 },
          },
          {
            input: quoteFields.quoteDate,
            grid: { xs: 4 },
          },
          {
            input: quoteFields.quoteProvider,
            grid: { xs: 8 },
          },
          {
            input: addRequiredToInput(quoteFields.fob),
            grid: { xs: 12 },
          },
          {
            input: {
              ...quoteFields.estimatedFreightToWarehouse,
              name: "estimatedFreightToWarehouse",
              inputProps: { currency },
            },
            grid: { xs: 4 },
          },
          {
            input: {
              ...quoteFields.potentialTariffAmount,
              name: "potentialTariff",
              label: "Potential Tariff",
              inputProps: { currency },
            },
            grid: { xs: 4 },
          },
          {
            input: {
              ...quoteFields.miscellaneousCosts,
              name: "miscellaneousCost",
              inputProps: { currency },
            },
            grid: { xs: 4 },
          },
          {
            input: quoteFields.miscellaneous,
            grid: { xs: 12 },
          },
          {
            input: quoteFields.notes,
            grid: { xs: 12 },
          },
          {
            input: addRequiredToInput({
              ...inputs.paymentTerms,
              filter: paymentTerms => {
                return paymentTerms.filter(
                  paymentTerm =>
                    !paymentTerm.isArchived ||
                    paymentTerm.id === purchaseOrder.paymentTermId
                );
              },
              ignoreLabeledText: !isProjectClosed,
            }),
            grid: { xs: 12 },
          },
          {
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
