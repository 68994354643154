import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import { FormikForm, ActionButtons } from "../../forms";
import GeneralInformationSection from "./GeneralInformationSection";
import ContractSection from "./ContractSection";
import FundingSection from "./FundingSection";
import TeamSection from "./TeamSection";
import AreasSection from "./AreasSection";
import SitesSection from "./SitesSection";
import AccountingSection from "./AccountingSection";
import ContingenciesSection from "./ContingenciesSection";
import propTypes from "../../../constants/propTypes";
import DirtyFormPrompt from "../../forms/DirtyFormPrompt";

const noDelayFields = [
  "addresses",
  /^contractFiles/,
  "sites",
  "contingencies",
  "programId",
  "varianceType",
  "feeScheduleGenerated",
];

export const ProjectForm = ({
  initialValues,
  onSubmit,
  onCreateArea,
  onDeleteArea,
  onUpdateArea,
  sendButtonText,
  isUpdate,
  loading,
  valueOverrides,
  clientId,
  project,
  areaTypes,
  client,
  role,
  currencies,
  workScopes,
  dataComponentId,
}) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("Project")}
      autoSave={isUpdate}
      valueOverrides={valueOverrides}
      ignoreCache
      enableReinitialize
      noDelayFields={noDelayFields}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <GeneralInformationSection
            clientId={clientId}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ContractSection
            values={values}
            errors={errors}
            client={client}
            role={role}
            isUpdate={isUpdate}
            currencies={currencies}
            workScopes={workScopes}
            {...formikProps}
          />
          <AreasSection
            values={values}
            areaTypes={areaTypes}
            errors={errors}
            {...formikProps}
            onCreateArea={onCreateArea}
            onDeleteArea={onDeleteArea}
            onUpdateArea={onUpdateArea}
          />
          <SitesSection />
          <ContingenciesSection
            values={values}
            errors={errors}
            {...formikProps}
          />
          <FundingSection values={values} errors={errors} {...formikProps} />
          <AccountingSection isUpdate={isUpdate} />
          <TeamSection
            clientId={clientId}
            project={project}
            client={client}
            values={values}
            errors={errors}
            {...formikProps}
          />
          {!isUpdate && (
            <ActionButtons
              loading={loading}
              onSend={handleSubmit}
              sendButtonText={sendButtonText}
            />
          )}
          {!isUpdate && (
            <DirtyFormPrompt
              onSubmit={handleSubmit}
              dataComponentId={dataComponentId}
              withSubmitCount={false}
              withTouchedEmpty
            />
          )}
        </Fragment>
      )}
    </FormikForm>
  );
};

ProjectForm.defaultProps = {
  isUpdate: false,
};

ProjectForm.propTypes = {
  client: propTypes.client,
  initialValues: PropTypes.shape({}).isRequired,
  valueOverrides: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  sendButtonText: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  role: propTypes.userRole,
  currencies: PropTypes.array,
};

export default ProjectForm;
