// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`UserRolePermission Model belongsTo relations 1`] = `
Array [
  "UserRole",
  "Permission",
]
`;

exports[`UserRolePermission Model pluralName 1`] = `"userRolesPermissions"`;
