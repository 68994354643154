import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";
import PropTypes from "prop-types";
import _pick from "lodash/pick";
import { useRef } from "react";
import _map from "lodash/map";

import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import { inputs } from "../../../../inputs/inputConfigs";

import { Paper } from "../../../../mui/core";
import ModalTitle from "../../../../layout/AppLayout/ModalDialog/ModalTitle";
import {
  queryReportDataComponentId,
  testQueryReportDataComponentId,
} from "../../../../../actions/queryReportsActions";
import { QueryReportParamFields } from "./QueryReportParamFields";
import { EditorField } from "./EditorField";
import { EditorHelper } from "./EditorHelper/EditorHelper";
import { tablesDataComponentId } from ".";
import { BaseModel } from "@blue-chip/core";
import { isSuperAdmin } from "../../../../../utils/roleUtils";
import useUserRole from "../../../../hooks/useUserRole";
import propTypes from "../../../../../constants/propTypes";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

const getGeneralFields = userRole => ({
  groups: [
    {
      items: [
        {
          input: { ...inputs.name, avoidDebounce: true },
          grid: { xs: 6 },
        },
        {
          input: { ...inputs.description, multiline: false },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.userRole,
            label: "User Roles",
            name: "userRoleIds",
            multiple: true,
            isSingleAutocomplete: false,
            isAutocompleteMulti: true,
            valueProperty: "id",
            labelProperty: "name",
          },
          grid: { xs: 12 },
        },
        {
          input: isSuperAdmin(userRole)
            ? {
                ...inputs.scopeId,
                name: "scopes",
                isSingleAutocomplete: false,
                isAutocompleteMulti: true,
                submitData: true,
              }
            : null,
          grid: { xs: 3 },
        },
      ],
    },
  ],
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow: auto;
`;

const FormGridWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-bottom: 24px;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  overflow-y: auto;
`;

const validateDuplicates = queryReportParams => {
  const paramsMap = queryReportParams.reduce((map, { name }, i) => {
    const arr = map[name] || [];
    arr.push(i);
    return { ...map, [name]: arr };
  }, {});
  const duplicateArray = Object.values(paramsMap).filter(arr => arr.length > 1);
  for (const duplicates of duplicateArray) {
    const error = new Error("Custom Joi Error");
    error.isJoi = true;
    error.details = [
      {
        message: `Name cannot be duplicated.`,
        path: [`queryReportParams[${duplicates.at(-1)}].name`],
        type: "genericError",
      },
    ];
    return error;
  }
};

const validateCamelCase = queryReportParams => {
  for (const [i, { name }] of queryReportParams.entries()) {
    if (!/^([a-z]+)(([A-Z]([a-z]+))*)$/.test(name)) {
      const error = new Error("Custom Joi Error");
      error.isJoi = true;
      error.details = [
        {
          message: `Name needs to be in camelcase (e.g. isActive) style.`,
          path: [`queryReportParams[${i}].name`],
          type: "genericError",
        },
      ];
      return error;
    }
  }
};

const validateParamQuery = (queryReportParams, query) => {
  for (const [, { name }] of queryReportParams.entries()) {
    if (!query.includes(`:${name}`)) {
      const error = new Error("Custom Joi Error");
      error.isJoi = true;
      error.details = [
        {
          message: `Param "${name}" missing in the query.`,
          path: ["query"],
          type: "genericError",
        },
      ];
      return error;
    }
  }
};

export const validateQueryReportParams = ({ queryReportParams, query }) => {
  return (
    validateDuplicates(queryReportParams) ||
    validateCamelCase(queryReportParams) ||
    validateParamQuery(queryReportParams, query)
  );
};

const getValidationSchema = userRole => ({
  validate: (values, options) => {
    const validationObject = {
      queryReportParams: BWModels.Joi.array().items(
        BWModels.loadSchema("QueryReportParam")
      ),
    };

    if (isSuperAdmin(userRole)) {
      validationObject.scopes = BWModels.Joi.array().min(1);
    }

    const schema = BWModels.loadSchema("QueryReport").concat(
      BWModels.Joi.object().keys(validationObject)
    );
    const { error: defaultError } = schema.validate(values, options);
    const error = defaultError || validateQueryReportParams(values);
    return { error };
  },
});

export const SaveQueryReport = ({
  queryReport: paramQueryReport,
  tables,
  testQueryReport,
  testAndSaveQueryReport,
  closeModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  office,
}) => {
  const userRoles = useV2DatacomponentResources(
    "select-userRoles",
    [],
    REQUEST_TYPES.LIST
  );

  const queryReport = paramQueryReport || {
    name: "",
    query: "",
    queryReportParams: [],
    scopes: [office.scope],
    userRoleIds: _map(userRoles, "id"),
  };

  const editorRef = useRef();
  const selectionRef = useRef();

  const title = `${queryReport.id ? "Update" : "Create"} Query Report`;

  const userRole = useUserRole();

  const validationSchema = useMemo(() => getValidationSchema(userRole), [
    userRole,
  ]);

  useEffect(() => {
    initDataComponent(
      tablesDataComponentId,
      BaseModel,
      [],
      "tables",
      true,
      "v2"
    );
    performRetrieveListRequest(tablesDataComponentId, {});
  }, [initDataComponent, performRetrieveListRequest]);

  const handleReplaceText = text => {
    if (!selectionRef.current) {
      return;
    }

    const { from, to } = selectionRef.current.main;

    editorRef.current.view.dispatch({ changes: { from, to, insert: text } });
    editorRef.current.view.focus();
    editorRef.current.view.dispatch({
      selection: { anchor: to + text.length, head: to + text.length },
    });
  };

  return (
    <Paper noBorder noBottomMargin>
      <FormikForm
        initialValues={_pick(
          {
            ...queryReport,
            userRoleIds: queryReport.userRoleIds.map(id => `${id}`),
          },
          [
            "id",
            "name",
            "description",
            "query",
            "queryReportParams",
            "userRoleIds",
            "scopes",
          ]
        )}
        validationSchema={validationSchema}
        onSubmit={testAndSaveQueryReport}
        enableReinitialize
        ignoreCache
      >
        {formikProps => (
          <Wrapper>
            <ModalTitle
              title={title}
              width={"unset"}
              isShowCloseIcon
              closeModalDialog={closeModalDialog}
            />
            <FormGridWrapper>
              <EditorHelper tables={tables} replaceText={handleReplaceText} />
              <RightPanel>
                <FormGrid
                  fields={getGeneralFields(userRole)}
                  {...formikProps}
                />
                <QueryReportParamFields {...formikProps} />
                <EditorField
                  tables={tables}
                  editorRef={editorRef}
                  setSelection={selection => (selectionRef.current = selection)}
                  testQueryReport={testQueryReport}
                  {...formikProps}
                />
              </RightPanel>
            </FormGridWrapper>
            <ActionButtons
              onSend={formikProps.handleSubmit}
              sendButtonText="Test & Save"
              listeners={[
                testQueryReportDataComponentId,
                queryReportDataComponentId,
              ]}
              isModal
            />
          </Wrapper>
        )}
      </FormikForm>
    </Paper>
  );
};

SaveQueryReport.propTypes = {
  testAndSaveQueryReport: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  office: propTypes.office,
};
