import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";

import termFields from "./termFields";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";
import useUserRole from "../../../../hooks/useUserRole";
import { isSuperAdmin } from "../../../../../utils/roleUtils";

export const getValidationSchema = isScopeRequired => {
  const schema = BWModels.loadSchema("Term").concat(
    Joi.object().keys({
      relation: Joi.array().min(1),
    })
  );
  if (isScopeRequired) {
    return schema.keys({
      scopeId: BWModels.Joi.selectFk().required(),
    });
  }
  return schema;
};

const isScopeRequired = (userRole, isGlobalTerm, isUpdate) =>
  isSuperAdmin(userRole) && isGlobalTerm && !isUpdate;

const TermForm = ({
  onSubmit,
  initialValues,
  maxPosition,
  isUpdate,
  loading,
  isGlobalTerm,
}) => {
  const userRole = useUserRole();
  const fields = useMemo(
    () => termFields(isGlobalTerm ? userRole : null, isUpdate, maxPosition),
    [isGlobalTerm, isUpdate, maxPosition, userRole]
  );
  const transformedValues = useMemo(() => {
    const { displayOrder } = initialValues;
    return {
      ...initialValues,
      displayOrder:
        typeof displayOrder === "undefined"
          ? displayOrder
          : displayOrder.toString(),
    };
  }, [initialValues]);
  return (
    <FormikForm
      initialValues={transformedValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(
        isScopeRequired(userRole, isGlobalTerm, isUpdate)
      )}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={fields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            loading={loading}
            sendButtonText={isUpdate ? "Save" : "Add Term"}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

TermForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  maxPosition: PropTypes.number,
  initialValues: propTypes.term,
  isGlobalTerm: PropTypes.bool,
};

export default TermForm;
