import React from "react";
import PropTypes, { arrayOf, shape } from "prop-types";
import _get from "lodash/get";

import propTypes from "../../../../../constants/propTypes";
import COMPreview from "../COMPreview";
import Details from "./Details";
import {
  TopDetailWrapper,
  SubHeader,
  SerifTitle,
  DetailsContainer,
} from "./components";
import Loader, { LoaderContext } from "../../../../ui/Loader";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

export const buildTopDetailsSpecDetails = specDetails => {
  let specDetailsModified = specDetails.map(detail => ({ ...detail }));

  specDetailsModified = specDetailsModified.map(detail => {
    if (!detail.description || detail.description == "") {
      detail.description = "--";
    }
    return detail;
  });
  return specDetailsModified;
};

const getSubHeader = (itemSelected, isSelectCOM) => {
  return isSelectCOM || !itemSelected.id
    ? "Select COM"
    : `COM #${itemSelected.id}`;
};

const TopDetails = ({
  itemSelected,
  isSelectCOM,
  specDetails,
  loading,
  handleUpdatePreview,
  onSpecDetailsUpdate,
}) => {
  const specDetailsModified = buildTopDetailsSpecDetails(specDetails);

  const isProjectClosed = useIsProjectClosed();

  return (
    <LoaderContext.Provider value={{ loading }}>
      <TopDetailWrapper>
        <SubHeader>
          <Loader width="96px" inverted={true}>
            {getSubHeader(itemSelected, isSelectCOM)}
          </Loader>
        </SubHeader>
        <SerifTitle>
          <Loader width="240px" inverted={true}>
            {_get(itemSelected, "description", "No COM Selected")}
          </Loader>
        </SerifTitle>
        <DetailsContainer>
          <COMPreview
            specDetails={specDetails}
            height="121px"
            width="121px"
            editable={!isProjectClosed}
            handleUpdatePreview={handleUpdatePreview}
          />
          <Details
            specDetails={specDetailsModified}
            categoryName={_get(itemSelected, "specCategory.name")}
            onSpecDetailsUpdate={onSpecDetailsUpdate}
            loading={loading}
          />
        </DetailsContainer>
      </TopDetailWrapper>
    </LoaderContext.Provider>
  );
};

TopDetails.propTypes = {
  loading: PropTypes.bool,
  itemSelected: shape({
    id: PropTypes.string,
    specDetails: arrayOf(propTypes.specDetail),
  }),
  specDetails: arrayOf(propTypes.specDetail),
  isSelectCOM: PropTypes.bool,
  handleUpdatePreview: PropTypes.func,
  onSpecDetailsUpdate: PropTypes.func,
};

export default React.memo(TopDetails);
