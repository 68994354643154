import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import { closeModalDialog } from "../../actions/layoutActions";
import { ActionButtons } from "../forms";
import { Message } from "./DangerConfirmation";

const Wrapper = styled.div`
  padding: 24px;
`;

const GeneralConfirmation = ({
  title,
  onSubmit,
  onCancel,
  closeModalDialog,
  children,
  ...buttonProps
}) => {
  return (
    <Fragment>
      {title && <Message>{title}</Message>}
      {children && <Wrapper>{children}</Wrapper>}
      <ActionButtons
        onSend={onSubmit}
        isModal={true}
        onCancel={onCancel || closeModalDialog}
        {...buttonProps}
      />
    </Fragment>
  );
};

GeneralConfirmation.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSubmit: PropTypes.func,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(null, mapDispatchToProps)(GeneralConfirmation);
