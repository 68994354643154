import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  calculateOverageQuantity,
  calculateTotalQuantity,
} from "../../Specs/calculations";
import createSpecDetailQuantityFields from "../createSpecDetailQuantityFields";
import SectionHeader from "../../../ui/Typography/SectionHeader";
import { FormGrid } from "../../../forms";
import PropTypes from "prop-types";
import {
  handleBaseQuantityChange,
  handleOverageChange,
} from "../../Specs/SpecFormikForm";
import { isAnyShipmentApprovedToPay } from "../../Specs/specContainerFunctions";
import { LabeledTextContext } from "../../../../withPORevision";

export const QuantityForm = ({
  handleSubmit,
  spec,
  setFieldValue,
  quantityErrorMessage,
  ...formikProps
}) => {
  const isLabeled = useContext(LabeledTextContext);
  const [baseQuantity, setBaseQuantity] = useState(spec.baseQuantity || 0);
  const [atticStock, setAtticStock] = useState(spec.atticStock || 0);
  const [overagePercent, setOveragePercent] = useState(
    spec.overagePercent / 100 || 0
  );

  useEffect(() => {
    setBaseQuantity(spec.baseQuantity || 0);
    setAtticStock(spec.atticStock || 0);
    setOveragePercent(spec.overagePercent / 100 || 0);
  }, [spec]);

  function handleAtticStockChange(event) {
    const value = parseFloat(event.target.value);

    if (isNaN(value)) {
      setAtticStock(0);
      setFieldValue("atticStock", null);
    }
    setAtticStock(value);

    const overageValue = calculateOverageQuantity(
      overagePercent,
      baseQuantity,
      value
    );

    setFieldValue("overageValue", overageValue);

    setFieldValue(
      "totalQuantity",
      calculateTotalQuantity(baseQuantity, value, overageValue)
    );
  }

  const fields = createSpecDetailQuantityFields(
    handleBaseQuantityChange(
      setBaseQuantity,
      setFieldValue,
      overagePercent,
      atticStock
    ),
    handleAtticStockChange,
    handleOverageChange(
      setOveragePercent,
      setFieldValue,
      baseQuantity,
      atticStock
    ),
    isAnyShipmentApprovedToPay(spec.shipments),
    isLabeled,
    quantityErrorMessage
  );
  return (
    <Fragment>
      <SectionHeader>Quantity</SectionHeader>
      <FormGrid
        fields={fields}
        purchaseOrder={spec && spec.purchaseOrder}
        {...formikProps}
      />
    </Fragment>
  );
};

QuantityForm.propTypes = {
  handleSubmit: PropTypes.func,
  formikProps: PropTypes.object,
  quantityErrorMessage: PropTypes.string,
};

export default QuantityForm;
