import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";
import _isNull from "lodash/isNull";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";

import {
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import Area from "../../../../models/Area";
import Project from "../../../../models/Project";
import CopyForm from "./CopyForm";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getIsTaxExempt } from "../ImportSpecs/ImportSpecs.js";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import SpecCategory from "../../../../models/SpecCategory";
import Vendor from "../../../../models/Vendor";
import {
  fetchProjectCurrencies,
  projectCurrenciesDataComponentId,
} from "../../../../actions/currencyActions";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";
import { unitOfMeasureDataComponentId } from "../../../../actions/specsActions";
import UnitOfMeasure from "../../../../models/UnitOfMeasure";
import { gridId } from "./SpecsList.js";
import withSelectedRows, {
  useWithSelectedRowContext,
} from "../../../ui/BWGrid/withSelectedRows.js";

export const dataComponentId = "SpecsGrid";
export const areaTypesIdComponent = "select-area-types";
export const categoryTypesIdComponent = "select-category-types";
export const vendorsIdComponent = "select-vendors";

const initComponents = (
  initDataComponent,
  performRetrieveListRequest,
  projectId,
  clientId
) => {
  initDataComponent(areaTypesIdComponent, Area, [], "areas");
  initDataComponent("select-projects", Project, [], "projects");

  performRetrieveListRequest("select-projects", {
    rootFilters: {
      $where: {
        "property.entity.clientId": clientId,
        id: {
          $notEqual: projectId,
        },
      },
    },
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
  });

  initDataComponent(
    categoryTypesIdComponent,
    SpecCategory,
    [],
    "spec-categories",
    false,
    "v2"
  );
  performRetrieveListRequest(categoryTypesIdComponent, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["spec_categories.id", "name", "isActive"],
    params: {
      modifiers: ["withLinked"],
    },
  });

  initDataComponent(
    unitOfMeasureDataComponentId,
    UnitOfMeasure,
    [],
    "unit-of-measures",
    false,
    "v2"
  );
  performRetrieveListRequest(unitOfMeasureDataComponentId, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["unit_of_measures.id", "name"],
  });

  initDataComponent(vendorsIdComponent, Vendor, [], "vendors", false, "v2");
  performRetrieveListRequest(vendorsIdComponent, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["vendors.id", "name", "status", "scopeId"],
  });
};

export const handleCreateSuccess = (
  closeModalDialog,
  showSnackNotificationAction,
  setReload
) => () => {
  setReload("SpecsGrid", true);
  showSnackNotificationAction("Specs have been copied successfully");
  closeModalDialog();
};

export const handleCreateError = showSnackNotificationAction => () => {
  showSnackNotificationAction("Error while trying to copy specs");
};

const processDatacomponentChange = (
  prevDataComponent,
  dataComponent,
  handleCreateSuccess,
  handleCreateError
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleCreateSuccess,
    onError: handleCreateError,
  });
};

const fieldsToOmit = [
  "id",
  "area",
  "specCategory",
  "purchaseOrder",
  "specDetailIdSequence",
  "isCommitted",
  "purchaseOrderId",
  "vendor",
  "statusComment",
  "glCodes",
  "shipmentTotalQty",
  "overageValue",
  "totalQuantity",
  "price",
  "totalPrice",
  "totalForecast",
  "createdAt",
  "updateAt",
  "specDetails",
  "awardedBidGroupVendorSpecQuoteId",
  "bidGroupVendorSpecQuote",
  "isAssignedToBidGroup",
  "projectCurrency",
  "project",
  "shipments",
  "unitOfMeasure",
  "bidGroups",
];

const CopyContainer = ({
  projectId,
  clientId,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  selectedSpecs,
  dataComponent,
  flattenedDataComponent,
  defaultAreaId,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
  fetchProjectCurrencies,
  projectCurrencies,
  specCategories,
  areas,
  vendors,
}) => {
  const [prevDatacomponent, setPrevDatacomponent] = useState(dataComponent);
  const [specs, setSpecs] = useState(
    selectedSpecs.map(spec => ({
      ...spec,
      specCategory: spec.specCategory.isActive ? spec.specCategory : null,
      specCategoryId: spec.specCategory.isActive ? spec.specCategoryId : null,
      areaId: null,
      priceCents: spec.priceCents / 100,
    }))
  );
  const [targetProject, setTargetProject] = useState(null);

  const { selectedIds } = useWithSelectedRowContext();

  useEffect(() => {
    initComponents(
      initDataComponent,
      performRetrieveListRequest,
      projectId,
      clientId,
      fetchProjectCurrencies
    );
  }, [
    initDataComponent,
    performRetrieveListRequest,
    projectId,
    clientId,
    fetchProjectCurrencies,
  ]);

  useEffect(() => {
    const defaultProjectCurrency = projectCurrencies?.find(
      projectCurrency => projectCurrency.isDefault
    );

    setSpecs(specs =>
      specs.map(spec => ({
        ...spec,
        projectCurrency: defaultProjectCurrency,
        projectCurrencyId: defaultProjectCurrency?.id,
      }))
    );
  }, [projectCurrencies]);

  useEffect(() => {
    if (targetProject) {
      performRetrieveListRequest(areaTypesIdComponent, {
        rootFilters: {
          $where: {
            projectId: targetProject.id,
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
      });
      fetchProjectCurrencies(targetProject.id);
    }
  }, [performRetrieveListRequest, fetchProjectCurrencies, targetProject]);

  useEffect(() => {
    processDatacomponentChange(
      prevDatacomponent,
      dataComponent,
      handleCreateSuccess(
        closeModalDialog,
        showSnackNotificationAction,
        setReload
      ),
      handleCreateError(showSnackNotificationAction)
    );
  }, [
    prevDatacomponent,
    dataComponent,
    closeModalDialog,
    showSnackNotificationAction,
    setReload,
  ]);

  useEffect(() => {
    setPrevDatacomponent(dataComponent);
  }, [dataComponent]);

  const handleCopy = updatedTargetProject => {
    const newSpecs = specs
      .filter(spec => selectedIds.includes(spec.id))
      .map(spec => {
        spec.priceCents = cleanCurrencyValue(spec.priceCents * 100);
        spec.projectId = updatedTargetProject.id;
        spec.areaId = spec.areaId || defaultAreaId;
        spec.sourceSpecId = spec.id;
        spec.cloningBetweenProjects = true;
        spec.isTaxExempt = getIsTaxExempt(updatedTargetProject, spec);
        return _omitBy(_omit(spec, fieldsToOmit), _isNull);
      });
    performCreateRequest(dataComponentId, newSpecs);
  };

  return (
    <CopyForm
      specs={specs}
      setSpecs={setSpecs}
      dataComponent={flattenedDataComponent}
      targetProject={targetProject}
      onSetTargetProject={setTargetProject}
      defaultAreaId={defaultAreaId}
      onCopy={handleCopy}
      projectCurrencies={projectCurrencies}
      specCategories={specCategories}
      areas={areas}
      vendors={vendors}
    />
  );
};

CopyContainer.propTypes = {
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  defaultAreaId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  selectedSpecs: PropTypes.arrayOf(propTypes.spec),
  performRetrieveListRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  fetchProjectCurrencies: PropTypes.func.isRequired,
  projectCurrencies: PropTypes.array,
  specCategories: PropTypes.array,
  areas: PropTypes.array,
  vendors: PropTypes.array,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const projectCurrencies = getBluechipResources(
    getDataComponentFlattenedRequestState(
      projectCurrenciesDataComponentId,
      state
    ),
    state
  );
  const specCategories = getBluechipResources(
    getDataComponentFlattenedRequestState("select-specCategories", state),
    state
  );
  const areas = getBluechipResources(
    getDataComponentFlattenedRequestState(areaTypesIdComponent, state),
    state
  );
  const vendors = getBluechipResources(
    getDataComponentFlattenedRequestState(vendorsIdComponent, state),
    state
  );

  const selectedSpecs = getBluechipResources(
    flattenedDataComponent,
    state,
    flattenedDataComponent.selectedIds
  );

  return {
    dataComponent,
    flattenedDataComponent,
    defaultAreaId: _get(
      getDataComponentFlattenedRequestState(areaTypesIdComponent, state),
      "rowIndex[0]"
    ),
    selectedSpecs,
    projectCurrencies: projectCurrencies || [],
    specCategories,
    areas,
    vendors,
  };
};

export const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  showSnackNotificationAction,
  setReload,
  fetchProjectCurrencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSelectedRows(CopyContainer, gridId));
