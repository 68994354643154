import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";

import { ActionButton } from "../../../../ui/BWActionButton";
import { dataComponentId } from "../Grid";
import { shippingPaymentSummaryDataComponentId } from "../Summary";

export const NotFoundProjects = ({ notFoundProjects }) => {
  const tableRef = useRef(null);
  if (!notFoundProjects.length) {
    return null;
  }

  const handleCopy = () => {
    let range = document.createRange();
    range.selectNodeContents(tableRef.current);
    let select = window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
    document.execCommand("copy");
  };

  return (
    <Fragment>
      <h3>
        <span style={{ paddingRight: "24px" }}>
          {notFoundProjects.length} Projects Not Found
        </span>
        <ActionButton text={"Copy"} onClick={handleCopy} />
      </h3>
      <table ref={tableRef}>
        <thead>
          <tr>
            <td style={{ paddingRight: "24px" }}>
              <b>Project Number</b>
            </td>
            <td>
              <b>Project Name</b>
            </td>
          </tr>
        </thead>
        <tbody>
          {notFoundProjects.map(({ projectNumberPrefix, projectName }, i) => (
            <tr key={i}>
              <td>{projectNumberPrefix}</td>
              <td>{projectName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};
NotFoundProjects.propTypes = {
  notFoundProjects: PropTypes.array,
};

export const getImportRequestCallbacks = ({
  importSummary,
  openModalDialog,
  setReload,
  showSnackNotificationAction,
  performRetrieveListRequest,
  inputEl,
}) => ({
  onSuccess: () => {
    const {
      fileRows,
      insertedRows,
      notFoundProjects,
      totalProjectsParsed,
    } = importSummary;
    openModalDialog(
      "Import Information",
      "GeneralConfirmation",
      {
        title: (
          <span>
            {fileRows} rows processed
            <br />
            {totalProjectsParsed} projects parsed
            <br />
            {insertedRows} shipping payments inserted
          </span>
        ),
        children: <NotFoundProjects notFoundProjects={notFoundProjects} />,
        cancelButtonText: "Close",
      },
      false,
      true
    );
    setReload(dataComponentId, true);
    performRetrieveListRequest(shippingPaymentSummaryDataComponentId);
    inputEl.current.value = "";
  },
  onError: error => {
    inputEl.current.value = "";
    const errors = error?.data?.errors;
    if (typeof errors === "string") {
      showSnackNotificationAction(errors);
      return;
    }
    showSnackNotificationAction("There was an error importing the file.");
  },
});
