const generateRow = (id, title, invoice, field) => ({
  id,
  title,
  field,
  assetsPaid: invoice[`${field}AssetsPaid`],
  assetsToDate: invoice[`${field}AssetsToDate`],
  depositsPaid: invoice[`${field}DepositsPaid`],
  depositsToDate: invoice[`${field}DepositsToDate`],
  net: invoice[`${field}Net`],
  notProcessed:
    typeof invoice[`${field}NotProcessed`] === "undefined"
      ? ""
      : invoice[`${field}NotProcessed`],
});

export default invoice => [
  {
    id: "0",
    title: " ",
    assetsPaid: "Paid",
    assetsToDate: "To Date",
    depositsPaid: "Paid",
    depositsToDate: "To Date",
    net: "",
    notProcessed: "",
  },
  generateRow("1", "Merchandise", invoice, "merchandise"),
  generateRow("2", "Overage", invoice, "overage"),
  generateRow("3", `Tax (${invoice.taxPercent}%)`, invoice, "tax"),
  generateRow(
    "4",
    `Accrued Use Tax (${invoice.accruedUseTaxPercent}%)`,
    invoice,
    "accruedUseTax"
  ),
  generateRow("5", "Freight/Warehousing", invoice, "freightWarehousing"),
  generateRow("6", "Install", invoice, "install"),
  generateRow("7", "Other costs", invoice, "otherCost"),
  generateRow("8", "Discount", invoice, "discount"),
  {
    id: "10",
    title: "Check Request Total",
    assetsPaid: invoice.totalAssetsPaid,
    assetsToDate: invoice.totalAssetsToDate,
    depositsPaid: invoice.totalDepositsPaid,
    depositsToDate: invoice.totalDepositsToDate,
    net: invoice.amount,
    notProcessed: "",
  },
];
