import React from "react";
import { Checkbox } from "@material-ui/core";
import BWModels from "benjaminwest-models";

import ActionButton from "./ActionButton";

export const billingTermFilter = ({ value }) => {
  return value ? { billingTerm: value } : {};
};
export const isWaiverRequiredFilter = ({ value }) =>
  value
    ? { "project.isWaiverRequired": true }
    : {
        $or: [
          { "project.isWaiverRequired": false },
          { "project.isWaiverRequired": { $exists: false } },
        ],
      };

export const columns = ({ updateFee, feeSubScopes }) => [
  {
    title: "",
    getCellValue: function getActionButton(fee) {
      return <ActionButton fee={fee} updateFee={updateFee} />;
    },
    width: "64px",
  },
  {
    name: "project.projectLead.name",
    title: "Project Lead",
    width: "15vw",
    filter: true,
  },
  {
    name: "project.office.name",
    title: "Office",
    filter: true,
    minWidth: "260px",
    maxWidth: "260px",
    whiteSpace: "inherit",
  },
  {
    name: "project.isWaiverRequired",
    title: "Lien Waiver",
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "True" },
        { id: false, name: "False" },
      ],
      operator: isWaiverRequiredFilter,
    },
    getCellValue: function getLienWaiver(fee) {
      return <Checkbox disabled checked={fee.project?.isWaiverRequired} />;
    },
  },
  {
    name: "feeSubScope.name",
    title: "Sub Scope",
    minWidth: "150px",
    filter: "select",
    filterOptions: {
      options: feeSubScopes.map(({ name }) => ({ id: name, name })),
    },
  },
  {
    name: "project.number",
    title: "Project Number",
    width: "8vw",
    filter: true,
  },
  {
    name: "project.name",
    title: "Project Name",
    filter: true,
    minWidth: "260px",
    maxWidth: "260px",
    whiteSpace: "inherit",
  },
  {
    name: "billingTerm",
    filter: true,
    width: "8vw",
    filterOptions: {
      options: BWModels.loadSchema("Fee").__utils.billingTerms,
      operator: billingTermFilter,
    },
  },
  {
    name: "billingDateTrunc",
    title: "Billing Date",
    width: "8vw",
    filter: "picker",
  },
  {
    name: "amount",
    title: "Fee Amount",
    width: "10vw",
    filter: true,
    filterOptions: {
      operator: "equal",
    },
  },
];
