import styled from "styled-components";

export default styled.div`
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
  position: relative;
  ${({ isUpdated }) =>
    isUpdated &&
    `
  font-style: italic;
  font-weight: 600;
  color: #000000;
`}
  &:after {
    ${({ isUpdated }) =>
      isUpdated &&
      `
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    top: -8px;
    left: -8px;
    overflow: visible;
    color: #ff9100;
  `}
  }
`;
