import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import pluralize from "pluralize";

import { MainSection, Wrapper } from "../components";
import BidGroupVendors from "./BidGroupVendors";
import RfqForm from "./RfqForm";
import BidGroupSpecs from "./BidGroupSpecs";
import propTypes from "../../../../constants/propTypes";
import BidGroupVendor from "../../../../models/BidGroupVendor";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import Spec from "../../../../models/Spec";
import { getBidTypes } from "./bidTypes";
import { useDatacomponent } from "../../../hooks/useDatacomponent";

//import { dataComponentId } from "../BidGroupContainer"; - Not used due to jest failing
const dataComponentId = "BidGroupDetailId";
export const bidGroupVendorDataComponentId = "BidGroupVendorDataComponentId";
export const specsGridDataComponentId = "SpecsGrid";

export const getNextSpecId = (specs, _, specId) => {
  if (!specs) {
    return null;
  }

  const rowIndex = specs.map(spec => spec.id);

  const currentIndex = rowIndex.indexOf(specId);
  if (currentIndex === -1) {
    return rowIndex[0];
  }
  return rowIndex[currentIndex + 1];
};
export const patchItem = (performUpdateRequest, dataComponentId, id, item) => {
  performUpdateRequest(dataComponentId, id, item);
};

export const getOnUpdateDate = (
  openModalDialog,
  closeModalDialog,
  updateBidGroupVendor
) => (column, label, ids, name, initialValues, isRfqSentAt) => () => {
  openModalDialog(
    `Set ${label}`,
    "EditDateModal",
    {
      initialValues,
      onSubmit: data => {
        closeModalDialog();
        updateBidGroupVendor(ids.map(id => ({ ...data, id })));
      },
      column,
      label,
      isRfqSentAt,
    },
    true,
    false,
    {
      subtitle: name ? name : pluralize("Vendors", ids.length, true),
    }
  );
};

// eslint-disable-next-line max-lines-per-function
export const Vendors = ({
  clientId,
  projectId,
  openModalDialog,
  loading,
  loaders,
  bidGroup,
  project,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  generateAndDownloadReport,
  openQuickEditModalAction,
  setAutoSaveComponentId,
  openVendorEmailPreview,
  updateBidGroupVendor,
  closeModalDialog,
  updateVendorSpecDataComponent,
  showSnackNotificationAction,
  pushWithReturnUrl,
}) => {
  useUpdateProcessRequests(updateVendorSpecDataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Vendor have been updated successfully");
    },
  });

  const dataComponent = useDatacomponent(
    `${specsGridDataComponentId}SpecClone`
  );
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      performFindRequest(dataComponentId, bidGroup.id, {
        $where: {
          projectId: bidGroup.projectId, //projectId is necessary for retrieveing nextId/prevId within project
        },
      });
    },
  });

  useEffect(() => {
    initDataComponent(
      specsGridDataComponentId,
      Spec,
      ["specCategory", "vendor"],
      "specs"
    );
    initDataComponent(
      bidGroupVendorDataComponentId,
      BidGroupVendor,
      [],
      "bid-group-vendors"
    );
  }, [initDataComponent]);

  const handleOpenSpecDetailsPage = ({ id }) => {
    pushWithReturnUrl(
      `/clients/${clientId}/projects/${projectId}/specs/${id}`,
      `BG #${bidGroup.number} - ${bidGroup.name}`,
      { navigateBetweenBG: bidGroup.id }
    );
  };

  const handleOpenEdit = getOnUpdateDate(
    openModalDialog,
    closeModalDialog,
    updateBidGroupVendor
  );

  const handleOpenSendRFQ = selectedVendors => () => {
    openModalDialog(
      "Send RFQ",
      "SendRFQ",
      {
        vendors: selectedVendors,
        updateBidGroupVendor,
      },
      true,
      true,
      {
        subtitle: bidGroup.name,
        width: "829px",
      }
    );
  };

  const handleOpenAddVendor = useCallback(() => {
    openModalDialog(
      null,
      "AddVendor",
      {
        bidGroup,
      },
      true,
      false,
      {
        layoutType: "wide",
      }
    );
  }, [bidGroup, openModalDialog]);

  const handleOpenPreviewEmail = row => {
    openVendorEmailPreview(row, bidGroup, project);
  };

  const handleDownloadPDF = useCallback(
    ({ id }, params = {}) => {
      generateAndDownloadReport({
        format: "pdf",
        referenceId: id,
        bidGroupVendorId: id,
        templateName: "request-for-quote-details",
        ...params,
      });
    },
    [generateAndDownloadReport]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, vendor }, onSuccess) => {
      openModalDialog(
        `Delete ${vendor.name}`,
        "DeleteBidGroupVendor",
        { id, title: vendor.name, onSuccess },
        true,
        false
      );
    },
    [openModalDialog]
  );
  const handleOpenSelectVendorContacts = useCallback(
    row => {
      openModalDialog(
        `Select Contacts for ${_get(row, "vendor.name")}`,
        "SelectVendorContactContainer",
        {
          bidGroup,
          bidGroupVendor: row,
          vendor: row.vendor,
        },
        true,
        false,
        {
          subtitle: `${bidGroup.name}`,
          width: "800px",
        }
      );
    },
    [bidGroup, openModalDialog]
  );
  const handleOpenAddVendorSpec = useCallback(
    row => {
      const subtitle = pluralize("Spec", bidGroup.specsCount, true);
      openModalDialog(
        `Select Specs for ${_get(row, "vendor.name")}`,
        "AddVendorSpec",
        {
          bidGroup,
          bidGroupVendor: row,
        },
        true,
        false,
        {
          subtitle: `${bidGroup.name} - ${subtitle}`,
          width: "800px",
        }
      );
    },
    [bidGroup, openModalDialog]
  );
  const handleUpdateBidGroup = useCallback(
    data => {
      setAutoSaveComponentId(dataComponentId);
      performUpdateRequest(dataComponentId, bidGroup.id, data);
    },
    [bidGroup.id, performUpdateRequest, setAutoSaveComponentId]
  );
  const handleOpenQuickEditModal = useCallback(
    (specs, spec) => {
      return openQuickEditModalAction(
        specsGridDataComponentId,
        spec,
        clientId,
        (rowIndex, specId) => getNextSpecId(specs, rowIndex, specId)
      );
    },
    [openQuickEditModalAction, clientId]
  );
  const onPatchItem = (id, payload) => {
    setAutoSaveComponentId(bidGroupVendorDataComponentId);
    patchItem(performUpdateRequest, bidGroupVendorDataComponentId, id, payload);
  };

  const onOpenQuickEditModal = useCallback(
    (spec, allRows) => handleOpenQuickEditModal(allRows, spec),
    [handleOpenQuickEditModal]
  );
  const onToggleEnableSampleQuantity = enableSampleQuantity => {
    setAutoSaveComponentId(dataComponentId);
    patchItem(performUpdateRequest, dataComponentId, bidGroup.id, {
      enableSampleQuantity: enableSampleQuantity ? 1 : 0,
    });
  };
  return (
    <Wrapper>
      <MainSection>
        <RfqForm rfq={bidGroup.rfq} onSubmit={handleUpdateBidGroup} />
        <BidGroupVendors
          onOpenAddVendor={handleOpenAddVendor}
          onOpenDeleteModal={handleOpenDeleteModal}
          onDownloadPDF={handleDownloadPDF}
          onOpenPreviewEmail={handleOpenPreviewEmail}
          onOpenAddVendorSpec={handleOpenAddVendorSpec}
          onOpenSelectVendorContacts={handleOpenSelectVendorContacts}
          bidGroup={bidGroup}
          loading={loading}
          loaders={loaders}
          patchItem={onPatchItem}
          getBidTypes={getBidTypes}
          onOpenEditDate={handleOpenEdit}
          onOpenSendRFQ={handleOpenSendRFQ}
          updateBidGroupVendor={updateBidGroupVendor}
        />
        <BidGroupSpecs
          bidGroup={bidGroup}
          loading={loading}
          onOpenSpecDetailsPage={handleOpenSpecDetailsPage}
          bidGroupSpecsDataComponentId={specsGridDataComponentId}
          onToggleEnableSampleQuantity={onToggleEnableSampleQuantity}
          onOpenQuickEditModal={onOpenQuickEditModal}
        />
      </MainSection>
    </Wrapper>
  );
};

Vendors.propTypes = {
  loading: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  loaders: PropTypes.object,
  bidGroup: propTypes.bidGroup,
  project: propTypes.project,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  openQuickEditModalAction: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func,
  generateAndDownloadReport: PropTypes.func,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  openVendorEmailPreview: PropTypes.func.isRequired,
  history: PropTypes.object,
  updateBidGroupVendor: PropTypes.func,
  closeModalDialog: PropTypes.func,
  updateVendorSpecDataComponent: propTypes.dataComponent,
  showSnackNotificationAction: PropTypes.func,
  pushWithReturnUrl: PropTypes.func,
};

export default Vendors;
