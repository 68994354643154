import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import pluralize from "pluralize";
import styled from "styled-components";

import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";
import BWGridAPI from "../../../../../ui/BWGrid/BWGridAPI";
import { GridHeader, GridTable, GridRow } from "../../../../../ui/BWGrid";
import Invoice from "../../../../../../models/Invoice";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import Price from "../../../../../ui/Price";

const ButtonsWrapper = styled.div`
  padding: 12px 0;
`;

const renderCurrency = (data, field) => {
  const { currency } = data.purchaseOrder?.projectCurrency || {};
  return data.id && <Price number={data[field]} currency={currency} />;
};

const columns = [
  "number",
  "purchaseOrder.number",
  "purchaseOrder.vendor.name",
  "merchandiseNet",
  "overageNet",
  "taxNet",
  "accruedUseTaxNet",
  "freightWarehousingNet",
  "discountNet",
  "miscellaneousNet",
  "amount",
];
export const getColumnOptions = (
  invoicesFilterOptions,
  purchaseOrdersFilterOptions
) => () => {
  return {
    number: {
      title: "Invoice Number",
      bold: true,
      filter: "select",
      filterOptions: {
        operator: ({ value }) => ({ id: value }),
        options: invoicesFilterOptions.map(({ id, number }) => ({
          id,
          name: number,
        })),
      },
    },
    "purchaseOrder.number": {
      title: "PO #",
      filter: "select",
      filterOptions: {
        operator: ({ value }) => ({ "purchaseOrder.id": value }),
        options: purchaseOrdersFilterOptions.map(({ id, number }) => ({
          id,
          name: number,
        })),
      },
      bold: true,
    },
    "purchaseOrder.vendor.name": { title: "Vendor", fill: true },
    merchandiseNet: {
      title: "Merchandise",
      render: renderCurrency,
    },
    overageNet: {
      title: "Overage",
      render: renderCurrency,
    },
    taxNet: {
      title: "Tax",
      render: renderCurrency,
    },
    accruedUseTaxNet: {
      title: "Accrued Tax",
      render: renderCurrency,
    },
    freightWarehousingNet: {
      title: "Freight/Warehousing",
      render: renderCurrency,
    },
    miscellaneousNet: {
      title: "Miscellaneous",
      render: renderCurrency,
    },
    discountNet: {
      title: "Discount",
      render: renderCurrency,
    },
    amount: {
      title: "Total",
      render: renderCurrency,
    },
  };
};

export const dataComponentId = "FundingCreate";

const InvoicesGrid = ({
  onSubmit,
  onCancel,
  projectId,
  dataComponent,
  initialValues,
  invoicesFilterOptions,
  purchaseOrdersFilterOptions,
  total,
}) => {
  const currencyFormatter = useCurrencyFormatter();

  const columnOptions = useMemo(
    getColumnOptions(
      invoicesFilterOptions,
      purchaseOrdersFilterOptions,
      currencyFormatter
    ),
    [invoicesFilterOptions, purchaseOrdersFilterOptions, currencyFormatter]
  );

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      ignoreValidationFieldNames={[
        "type",
        "processingDate",
        "fundsReceived",
        "paymentRecord",
        "amountRequested",
      ]}
      validationSchema={BWModels.loadSchema("Funding")}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <BWGridAPI
            id="invoices-fundings"
            dataComponent={dataComponent}
            model={Invoice}
            apiRoute="invoices-fundings"
            includes={[
              "purchaseOrder.[vendor, projectCurrency.currency]",
              "fundings",
            ]}
            defaultSorting={[{ columnName: "number", direction: "asc" }]}
            showSelectionColumn
            showSelectAll
            rootFilters={{
              $where: {
                "purchaseOrder.projectId": projectId,
              },
            }}
          >
            <GridHeader
              headerText={pluralize("Invoice", dataComponent.totalRows, true)}
            />
            <GridTable columns={columns} columnOptions={columnOptions} />
            <GridRow>
              <ButtonsWrapper>
                <ActionButtons
                  onSend={handleSubmit}
                  onCancel={onCancel}
                  additionalProps={{
                    send: { disabled: total === 0 },
                  }}
                  sendButtonText={`Issue Funding Request for ${currencyFormatter.format(
                    total
                  )}`}
                  listeners={[dataComponentId]}
                />
              </ButtonsWrapper>
            </GridRow>
          </BWGridAPI>
        </Fragment>
      )}
    </FormikForm>
  );
};

InvoicesGrid.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialValues: propTypes.glCode,
  dataComponent: propTypes.dataComponent,
  projectId: PropTypes.string,
  total: PropTypes.number,
  invoicesFilterOptions: PropTypes.arrayOf(propTypes.invoice),
  purchaseOrdersFilterOptions: PropTypes.arrayOf(propTypes.purchaseOrder),
};

export default InvoicesGrid;
