import { renderRoutes } from "react-router-config";

import users from "./users";
import clients from "./clients";
import projects from "./projects";
import vendorPortal from "./vendorPortal";
import vendors from "./vendors";
import dashboard from "./dashboard";
import billing from "./billing";
import accounting from "./accounting";
import capacity from "./capacity";
import settings from "./settings";
import consultingCompanies from "./consultingCompanies";
import Status from "../components/pages/Status";

const Routes = () => {
  return renderRoutes([
    ...accounting,
    ...dashboard,
    ...capacity,
    ...consultingCompanies,
    ...billing,
    ...users,
    ...projects,
    ...clients,
    ...vendors,
    ...settings,
    ...vendorPortal,
    {
      path: "/status",
      component: Status,
    },
  ]);
};

export default Routes;
