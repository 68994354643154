import React from "react";
import styled from "styled-components";
import Search from "@material-ui/icons/Search";
import PropTypes from "prop-types";

import Input from "../mui/core/Input";
import InputAdornment from "../mui/core/InputAdornment";

export const SearchPanel = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const StyledInput = styled(props => (
  <Input
    {...props}
    classes={{ input: "input" }}
    startAdornment={
      <InputAdornment position="start" classes={{ root: "adornment" }}>
        <Search />
      </InputAdornment>
    }
  />
))`
  margin-top: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  width: 244px;
  &:before,
  &:after {
    border: none !important;
  }
  & .input {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.54);
  }
  & .adornment {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const SearchBox = props => (
  <SearchPanel>
    <StyledInput {...props} />
  </SearchPanel>
);
SearchBox.defaultProps = {
  placeholder: "Search",
};
SearchBox.propTypes = {
  placeholder: PropTypes.string,
};

export default SearchBox;
