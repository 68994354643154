import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ProjectsPage from "./ProjectsPage";
import PropTypes from "prop-types";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  openModalDialog,
  closeModalDialog,
} from "../../../actions/layoutActions";
import { openNotesModal } from "../../../actions/notesActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import {
  initDataComponent,
  setReload,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import { showSnackNotificationAction } from "../../../actions/layoutActions";
import propTypes from "../../../constants/propTypes";
import ProjectUserStar from "../../../models/ProjectUserStar";
import { dataComponentProjectUserStarsId } from "../Dashboard/MyProjects/MyProjectsContainer";
import {
  onHandleTransferProject,
  processUpdateRequestForPatchProject,
} from "./ProjectFunctions";
import withRenderOnPermissionActive from "../../hocs/withRenderOnPermissionActive";
import { updateAuthenticatedUser } from "../../../actions/authActions";

export const projectsDataComponentId = "ProjectsGrid";

export const handleTransferProject = (
  project,
  propertyId,
  performUpdateRequest,
  closeModalDialog
) => {
  onHandleTransferProject(
    project,
    propertyId,
    projectsDataComponentId,
    performUpdateRequest,
    closeModalDialog
  );
};

export const ProjectsContainer = ({
  initDataComponent,
  projectsDataComponent,
  dataComponentProjectUserStars,
  setReload,
  showSnackNotificationAction,
  performRetrieveListRequest,
  performUpdateRequest,
  closeModalDialog,
  updateAuthenticatedUser,
  ...props
}) => {
  const [prevProjectDataComponent, setPrevProjectDataComponent] = useState(
    projectsDataComponent
  );

  useEffect(() => {
    setPrevProjectDataComponent(projectsDataComponent);
  }, [projectsDataComponent]);

  useEffect(() => {
    initDataComponent(
      dataComponentProjectUserStarsId,
      ProjectUserStar,
      [],
      "project-user-stars",
      true
    );
  }, [initDataComponent]);

  useEffect(() => {
    processUpdateRequestForPatchProject(
      prevProjectDataComponent,
      projectsDataComponent,
      closeModalDialog,
      showSnackNotificationAction
    );
  }, [
    closeModalDialog,
    prevProjectDataComponent,
    projectsDataComponent,
    showSnackNotificationAction,
  ]);

  return (
    <ProjectsPage
      {...props}
      initDataComponent={initDataComponent}
      performRetrieveListRequest={performRetrieveListRequest}
      updateAuthenticatedUser={updateAuthenticatedUser}
      transferProject={(project, propertyId) =>
        handleTransferProject(
          project,
          propertyId,
          performUpdateRequest,
          closeModalDialog
        )
      }
    />
  );
};

ProjectsContainer.propTypes = {
  projectsDataComponent: propTypes.dataComponent,
  dataComponentProjectUserStars: propTypes.dataComponent,
  dataComponent: propTypes.dataComponent,
  loggedInUserId: PropTypes.string.isRequired,
  initDataComponent: PropTypes.func,
  setReload: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
  updateAuthenticatedUser: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  dataComponentProjectUserStars: getDataComponentFlattenedRequestState(
    dataComponentProjectUserStarsId,
    state
  ),
  projectsDataComponent: getDataComponent(projectsDataComponentId, state),
  dataComponent: getDataComponentFlattenedRequestState(
    projectsDataComponentId,
    state
  ),
  loggedInUserId: state.auth.userId,
  projectsShowInClosing: state.auth.projectsShowInClosing,
  projectsShowClosed: state.auth.projectsShowClosed,
});

export const mapDispatchToProps = {
  pushWithReturnUrl,
  initDataComponent,
  openModalDialog,
  closeModalDialog,
  showSnackNotificationAction,
  performRetrieveListRequest,
  performUpdateRequest,
  openNotesModal,
  setReload,
  updateAuthenticatedUser,
};

export default withRenderOnPermissionActive(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer)),
  ["project-index-general-projects"]
);
