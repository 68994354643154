import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ShipToSiteContainer from "./ShipToSiteContainer";
import {
  initDataComponent,
  performFindRequest,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  performRetrieveListRequest,
  fetchData,
} from "../../../../actions/dataComponentActions";
import { connect } from "react-redux";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import VendorShipToSite from "../../../../models/VendorShipToSite";
import { getCurrentBluechipResourcesForRequestType } from "../../../../utils/bluechipUtils";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";

import {
  onCreateShipToSite,
  onUpdateShipToSite,
  onDeleteShipToSite,
  processRequests,
} from "./ShipToSiteFunctions";

import { vendorContactsDataComponentId } from "../VendorDetailContainer";
import VendorContact from "../../../../models/VendorContact";

export const shipToSiteDataComponentId = "ShipToSites";

const ShipToSitePage = props => {
  const {
    match: { params },
    initDataComponent,
    performRetrieveListRequest,
    performFindRequest,
    performCreateRequest,
    performDeleteRequest,
    performUpdateRequest,
    fetchData,
    showSnackNotificationAction,
    closeModalDialog,
    shipToSiteDataComponent,
    shipToSites,
    vendorContacts,
  } = props;

  const [preDataComponent, setPreDataComponent] = useState(null);

  useEffect(() => setPreDataComponent(shipToSiteDataComponent), [
    shipToSiteDataComponent,
  ]);

  useEffect(() => {
    processRequests(
      preDataComponent,
      shipToSiteDataComponent,
      shipToSiteDataComponentId,
      {
        fetchData,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );
  }, [
    shipToSiteDataComponent,
    preDataComponent,
    params.vendorId,
    performFindRequest,
    showSnackNotificationAction,
    closeModalDialog,
    fetchData,
  ]);

  useEffect(() => {
    initDataComponent(
      shipToSiteDataComponentId,
      VendorShipToSite,
      ["location", "contacts.contact"],
      "vendor-ship-to-sites"
    );
    performRetrieveListRequest(shipToSiteDataComponentId, {
      rootFilters: {
        $where: { vendorId: params.vendorId },
        sort: [{ columnName: "name", order: "asc" }],
      },
      pageSize: -1,
    });
    initDataComponent(
      vendorContactsDataComponentId,
      VendorContact,
      ["contact"],
      "vendor-contacts"
    );
    performRetrieveListRequest(vendorContactsDataComponentId, {
      rootFilters: {
        $where: { vendorId: params.vendorId },
      },
      pageSize: -1,
    });
  }, [initDataComponent, performRetrieveListRequest, params.vendorId]);

  const values = {
    locations: shipToSites,
  };

  return (
    <ShipToSiteContainer
      values={values}
      vendorContacts={vendorContacts}
      onCreateShipToSite={shipToSite =>
        onCreateShipToSite(
          shipToSite,
          params.vendorId,
          shipToSiteDataComponent,
          shipToSiteDataComponentId,
          performCreateRequest
        )
      }
      onDeleteShipToSite={shipToSiteId =>
        onDeleteShipToSite(
          shipToSiteId,
          shipToSiteDataComponent,
          shipToSiteDataComponentId,
          performDeleteRequest
        )
      }
      onUpdateShipToSite={shipToSite =>
        onUpdateShipToSite(
          shipToSite,
          shipToSiteDataComponent,
          shipToSiteDataComponentId,
          performUpdateRequest
        )
      }
    />
  );
};

ShipToSitePage.propTypes = {
  match: PropTypes.object.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  shipToSiteDataComponent: PropTypes.object.isRequired,
  shipToSites: PropTypes.array.isRequired,
  vendorContacts: PropTypes.array.isRequired,
};

export const mapStateToProps = state => {
  const shipToSiteDataComponent = getDataComponent(
    shipToSiteDataComponentId,
    state
  );
  const shipToSites =
    getCurrentBluechipResourcesForRequestType(
      shipToSiteDataComponentId,
      state
    ) || [];
  const vendorContacts =
    getCurrentBluechipResourcesForRequestType(
      vendorContactsDataComponentId,
      state
    ) || [];
  return {
    role: state.auth.role,
    shipToSiteDataComponent,
    shipToSites,
    vendorContacts,
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  fetchData,
  showSnackNotificationAction,
  closeModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipToSitePage);
