import React, { Fragment } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import EmptyState from "../../../../ui/EmptyState";

import { GridSection, GridWrapper } from "./components";
import { dataComponentId } from "../../../../../actions/specDetailActions";
import Grid from "./Grid";
import EditSubmittal from "./EditSubmittal";

const MainSection = ({
  specRequirement,
  selectedSubmittal,
  onDelete,
  onSelect,
  onClearSelection,
  onSave,
  onApprove,
  loaderActive,
  setLoaderActive,
}) => {
  if (selectedSubmittal) {
    return (
      <EditSubmittal
        submittal={selectedSubmittal}
        specRequirement={specRequirement}
        onApprove={onApprove}
        onSubmit={editedSubmittal => {
          onSave(
            editedSubmittal,
            specRequirement.submittalFiles.indexOf(selectedSubmittal)
          );
          setLoaderActive("edit");
        }}
        onCancel={onClearSelection}
        dataComponentId={dataComponentId}
        loaderActive={loaderActive}
      />
    );
  }

  if (specRequirement.files > 0) {
    return (
      <Fragment>
        <GridSection>
          <GridWrapper>
            <Grid
              specRequirement={specRequirement}
              onDelete={onDelete}
              onSelect={onSelect}
              loaderActive={loaderActive}
              setLoaderActive={setLoaderActive}
            />
          </GridWrapper>
        </GridSection>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <GridSection>
        <GridWrapper>
          <EmptyState intent="normal">No files Uploaded</EmptyState>
        </GridWrapper>
      </GridSection>
    </Fragment>
  );
};

MainSection.propTypes = {
  specRequirement: propTypes.specRequirement,
  selectedSubmittal: propTypes.submittalFile,
  loaderActive: PropTypes.string,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  onClearSelection: PropTypes.func,
  onSave: PropTypes.func,
  onApprove: PropTypes.func,
  setLoaderActive: PropTypes.func,
};

export default MainSection;
