import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import ConsultingCompany from "../../../../models/ConsultingCompany";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import {
  initDataComponent,
  performUpdateRequest,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import ConsultingCompanyForm from "../CreateConsultingCompany/ConsultingCompanyForm";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader";
import _get from "lodash/get";

export const dataComponentId = "EditConsultingCompany";

export class EditConsultingCompanyContainer extends Component {
  componentDidMount() {
    const {
      consultingCompanyId,
      initDataComponent,
      performFindRequest,
    } = this.props;

    initDataComponent(
      dataComponentId,
      ConsultingCompany,
      ["location"],
      "consulting-companies",
      false,
      "v2"
    );
    performFindRequest(dataComponentId, consultingCompanyId);
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  editConsultingCompany = (consultingCompanyToUpdate, formikActions) => {
    const { performUpdateRequest, consultingCompany } = this.props;

    performUpdateRequest(
      dataComponentId,
      consultingCompany.id,
      consultingCompanyToUpdate
    );
    this.setState({ formikActions });
  };

  render() {
    const { consultingCompany, loading } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <ConsultingCompanyForm
          sendButtonText="SAVE"
          onSubmit={this.editConsultingCompany}
          consultingCompany={consultingCompany}
          dataComponentId={dataComponentId}
          isUpdate
        />
      </LoaderContext.Provider>
    );
  }
}

EditConsultingCompanyContainer.propTypes = {
  loading: PropTypes.bool,
  consultingCompanyId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  consultingCompany: propTypes.consultingCompany,
};

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
    consultingCompany: getBluechipResourceById(
      dataComponent,
      state,
      ownProps.consultingCompanyId
    ),
  };
};

export const mapDispatchToProps = {
  push,
  closeModalDialog,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditConsultingCompanyContainer);
