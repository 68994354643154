import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { LocalGrid } from "../../../../ui/Grid/LocalGrid/LocalGrid";
import {
  StyledContentTitle,
  StyledModalContent,
  CustomAlternateQuoteCell,
} from "./components";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { getDefaultProjectCurrencyFromBGVs } from "../analysisFunctions";
import get from "lodash.get";

const columns = [
  { name: "description", title: "Description", width: "20%" },
  { name: "uom", title: "UOM", width: "10%" },
  { name: "qty", title: "Qty", fullWidth: true },
  { name: "productionPricing", title: "Production Pricing", fullWidth: true },
  { name: "notes", title: "Notes", fullWidth: true },
];

const tableComponents = {
  CellComponent: CustomAlternateQuoteCell,
  GridWrapper: ({ children }) => children,
};

const buildRelatedSpecList = vendorSpec => {
  const relatedSpecs = {};

  relatedSpecs[vendorSpec.specId] = vendorSpec.spec;
  vendorSpec.coms.forEach(com => {
    relatedSpecs[com.specId] = com.spec;
  });

  return relatedSpecs;
};

const buildRows = (bgVendorSpec, relatedSpecs, currencyFormatter) => {
  const rows = [];
  bgVendorSpec.quotes.forEach((quote, quoteIndex) => {
    quote.quoteItems.forEach((quoteItem, quoteItemIndex) => {
      const spec = relatedSpecs[quoteItem.specId];
      rows.push({
        description: spec.description,
        uom: get(spec, "unitOfMeasure.name", ""),
        qty: quoteItem.productionQuantity,
        productionPricing: currencyFormatter.format(
          quoteItem.productionPricing
        ),
        notes: quoteItemIndex === 0 ? quote.notes : "",
        displayNotes: quoteItemIndex === 0,
        quoteItemsNumber: quote.quoteItems.length,
        isLastQuote: quoteIndex == bgVendorSpec.quotes.length - 1,
      });
    });
    rows[rows.length - 1].displayBorderBottom = true;
  });
  rows[rows.length - 1].displayBorderBottom = false;
  return rows;
};

const AnalysisAlternateQuotesModal = ({ vendorSpec, bgVendor }) => {
  const [rows, setRows] = useState([]);
  const defaultProjectCurrency = getDefaultProjectCurrencyFromBGVs([bgVendor]);
  const currency = defaultProjectCurrency.currency;
  const currencyFormatter = useCurrencyFormatter(currency);

  useEffect(() => {
    const relatedSpecs = buildRelatedSpecList(vendorSpec);
    const bgVendorSpec = bgVendor.bidGroupVendorSpecs.find(
      bgVendorSpec => bgVendorSpec.specId == vendorSpec.specId
    );
    setRows(buildRows(bgVendorSpec, relatedSpecs, currencyFormatter));
    // eslint-disable-next-line
  }, [vendorSpec, bgVendor]);

  return (
    <StyledModalContent>
      <StyledContentTitle>Available Quotes</StyledContentTitle>
      <LocalGrid
        gridId="alternate-quotes-modal"
        rows={rows}
        columns={columns}
        tableComponents={tableComponents}
      />
    </StyledModalContent>
  );
};

AnalysisAlternateQuotesModal.propTypes = {
  vendorSpec: PropTypes.object,
  bgVendor: PropTypes.object,
};

export default AnalysisAlternateQuotesModal;
