import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import Tabs from "../../mui/core/Tabs";
import Tab from "../../mui/core/Tab";
import NavigationTabs from "../Navigation/NavigationTabs";
import { roleCan } from "../../../utils/roleUtils";

export const Container = styled.div`
  padding-right: 50px;
  color: rgb(255, 255, 255);
  background-color: #27587a;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.18);
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const ContainerContent = styled.div`
  padding-top: ${({ hasContent }) => (hasContent ? "35px" : "0")};
  padding-right: ${({ hasContent }) => (hasContent ? "0" : "36px")};
  padding-bottom: 0;
  padding-left: 36px;
  background-color: rgb(39, 88, 122);
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const ContentWrapper = styled.div({});

const MainContent = styled.div`
  margin-bottom: 28px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
    padding: 28px 16px 16px 16px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
`;
MainContent.displayName = "MainContent";

const filterTabsByPermission = (role, navigationTabs) => {
  return navigationTabs.filter(navigationTab => {
    if (!navigationTab.permission) return true;
    if (roleCan(role, navigationTab.permission)) {
      return true;
    }
  });
};

export const PageHeader = ({
  children,
  location,
  navigationTabs,
  isNavbarDrawerOpen,
  match,
  push,
  role,
}) => {
  const hasContent = !!children;
  const filteredNavigationtabs = filterTabsByPermission(role, navigationTabs);
  return (
    <Container>
      <ContainerContent
        hasContent={hasContent}
        isNavbarDrawerOpen={isNavbarDrawerOpen}
      >
        <ContentWrapper>
          {hasContent && <MainContent>{children}</MainContent>}

          <NavigationTabs
            location={location}
            navigationTabs={filteredNavigationtabs}
            match={match}
            push={push}
            Tabs={Tabs}
            Tab={Tab}
          />
        </ContentWrapper>
      </ContainerContent>
    </Container>
  );
};

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isNavbarDrawerOpen: PropTypes.bool.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  navigationTabs: propTypes.navigationTabs,
  push: PropTypes.func,
  role: propTypes.userRole,
};

PageHeader.defaultProps = {
  navigationTabs: [],
};

export const mapStateToProps = ({ layout, auth: { role } }) => ({
  isNavbarDrawerOpen: layout.isNavbarDrawerOpen,
  role,
});

const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageHeader)
);
