import React from "react";
import BWModels from "benjaminwest-models";

import Date from "../../../../ui/BWGrid/columns/Date";
import { DashIcon } from "../../../../ui/gridTableConfigs/detailPageProjects";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const BWInvoiceNumber = ({ bwInvoiceNumber }) => {
  if (!bwInvoiceNumber) {
    return <DashIcon />;
  }
  return bwInvoiceNumber;
};

export const columns = [
  "status",
  "sumOfAmount",
  "trackingNumber",
  "invoiceNumber",
  "invoiceDate",
  "billedDate",
  "bwInvoiceNumber",
];

export const getColumnOptions = formatter => ({
  status: {
    filter: "multiselect",
    minWidth: "150px",
    filterOptions: {
      initialOptions: ["Unbilled"],
      options: [...BWModels.loadSchema("ShippingPayment").__utils.statuses],
      operator: "multiselect",
    },
  },
  sumOfAmount: {
    title: "Amount",
    filter: "text",
    render: ({ sumOfAmount }) => {
      return formatter.format(sumOfAmount);
    },
  },
  trackingNumber: {
    filter: "text",
  },
  invoiceNumber: {
    title: "Shipping Invoice Number",
    filter: "text",
  },
  invoiceDate: {
    filter: "picker",
    render: ({ invoiceDate }) =>
      invoiceDate && <Date date={invoiceDate} format={PRIMARY_DATE_FORMAT} />,
  },
  billedDate: {
    filter: "picker",
    render: ({ billedDate }) =>
      billedDate && <Date date={billedDate} format={PRIMARY_DATE_FORMAT} />,
  },
  bwInvoiceNumber: {
    title: "BW Invoice Number",
    filter: "text",
    render: BWInvoiceNumber,
  },
});
