import { inputs } from "../../../../inputs/inputConfigs";

import { getPotentialTariffInput } from "../../../../pages/BidGroupDetail/Quotes/BidGroupTable/QuoteEdit/quoteFields.js";

const { quote: quoteFields } = inputs;

// eslint-disable-next-line max-lines-per-function
export const buildQuoteInputs = (
  projectId,
  selectedCurrency,
  potentialTariffType
) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...quoteFields.quoteEstimatedShipDate,
              label: "Quote Valid Until",
              name: "quoteValidUntil",
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: {
              ...inputs.projectCurrency,
              name: "quoteProjectCurrencyId",
              APIOptions: {
                ...inputs.projectCurrency.APIOptions,
                rootFilters: {
                  $where: { projectId },
                },
              },
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: quoteFields.quoteDate,
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: quoteFields.potentialTariffType,
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: {
              ...getPotentialTariffInput({
                potentialTariffType,
                selectedCurrency,
              }),
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: {
              ...quoteFields.quoteEstimatedShipDate,
              name: "estimatedShipDate",
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: quoteFields.dropDeadDates,
            grid: { xs: 12, sm: 6, md: 3 },
          },
        ],
      },
      {
        items: [
          {
            input: { ...quoteFields.leadTimesFrom, required: true },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: { ...quoteFields.leadTimesTo, required: true },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: { ...quoteFields.leadTimeForSubmittal, required: true },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: quoteFields.vat,
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: {
              ...quoteFields.estimatedFreightToWarehouse,
              name: "estimatedFreightToWarehouseLocal",
              inputProps: {
                currency: selectedCurrency,
              },
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: {
              ...quoteFields.miscellaneousCosts,
              name: "miscellaneousCostLocal",
              inputProps: {
                currency: selectedCurrency,
              },
            },
            grid: { xs: 12, sm: 6, md: 3 },
          },
          {
            input: { ...quoteFields.fob, required: true },
            grid: { xs: 12, sm: 6, md: 3 },
          },
        ],
      },
      {
        items: [
          {
            input: { ...quoteFields.factoryLocation, required: true },
            grid: { xs: 12, md: 6 },
          },
          {
            input: {
              ...inputs.paymentTerms,
              filter: paymentTerms => {
                return paymentTerms.filter(
                  paymentTerm => !paymentTerm.isArchived
                );
              },
              required: true,
            },
            grid: { xs: 12, md: 6 },
          },
          {
            input: quoteFields.quoteProvider,
            grid: { xs: 12, md: 6 },
          },
          {
            input: quoteFields.miscellaneous,
            grid: { xs: 12, md: 6 },
          },
        ],
      },
      {
        items: [
          {
            input: quoteFields.notes,
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};

export const submittionDetails = () => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...quoteFields.notes,
              name: "generalNotes",
              label: "Add General Notes (Optional)",
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
