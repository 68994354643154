import Program from "../Program";

describe("Program model", () => {
  test("belongsTo relations", () => {
    expect(Program.belongsTo.map(relation => relation.name)).toMatchSnapshot();
  });
  test("hasMany relations", () => {
    expect(Program.hasMany.map(relation => relation.name)).toMatchSnapshot();
  });
});
