export const FORM_IDS = {
  CREATE_SPEC: "CREATE_SPEC",
  MOVE_SPEC: "MOVE_SPEC",
  COPY_SPEC: "COPY_SPEC",
  DUPLICATE_SPEC: "DUPLICATE_SPEC",
  MAIN: "MAIN",
};

export const initialState = {
  formId: FORM_IDS.MAIN,
  purchaseOrder: null,
  filtersActive: false,
};
