import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import Rfq from "./Rfq";
import BidGroupVendor from "./BidGroupVendor";
import BidGroupSpec from "./BidGroupSpec";
import PurchaseOrder from "./PurchaseOrder";

export default class BidGroup extends BaseModel {
  static get tableName() {
    return "bid_groups";
  }

  static get hasMany() {
    return [BidGroupSpec, Spec, BidGroupVendor, PurchaseOrder];
  }

  static get belongsTo() {
    return [Rfq];
  }
}
