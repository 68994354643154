import React, { Fragment, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { AddCircle, Edit } from "@material-ui/icons";
import DragDropInputFile from "../../../inputs/DragDrop/DragDropInputFile";
import { useModalControl } from "../../../inputs/DragDrop/DragDropInputFile/useModalControl";

export const StyledDivIcon = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const StyledEmptyImageDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid #57abff;
  color: #57abff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-weight: 600;
  cursor: pointer;
`;

const EditPreview = ({ preview, editable, handleUpdatePreview }) => {
  const { open, onOpen, onClose } = useModalControl();

  const handleChange = useCallback(
    evt => {
      const files = Array.prototype.slice.call(evt.target.files);
      const file = files[0];
      handleUpdatePreview({
        filename: file.name,
        isUpdated: true,
        metadata: { size: file.size },
        file: file,
      });
    },
    [handleUpdatePreview]
  );

  if (!editable) return null;

  const dragDropModal = (
    <DragDropInputFile
      open={open}
      onClose={onClose}
      onChange={handleChange}
      accept="image/jpeg,image/png,.gif"
    />
  );

  if (!preview.preview) {
    return (
      <Fragment>
        <StyledEmptyImageDiv onClick={onOpen}>
          <IconButton
            style={{
              backgroundColor: "transparent",
              color: "#57ABFF",
              padding: "6px",
            }}
            aria-label="select image"
          >
            <AddCircle />
          </IconButton>
          <span>
            UPLOAD <br /> PREVIEW
          </span>
        </StyledEmptyImageDiv>
        {dragDropModal}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <StyledDivIcon onClick={onOpen}>
        <IconButton
          style={{
            backgroundColor: "transparent",
            color: "white",
            padding: "6px",
          }}
          aria-label="select image"
        >
          <Edit />
        </IconButton>
      </StyledDivIcon>
      {dragDropModal}
    </Fragment>
  );
};

EditPreview.propTypes = {
  editable: PropTypes.bool,
  handleUpdatePreview: PropTypes.func,
  preview: PropTypes.object,
};

export default EditPreview;
