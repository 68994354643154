import React, { Fragment, useEffect } from "react";

import { ActionButtons, FormikForm } from "../../../../forms";
import { FormGrid } from "../../../../forms/FormGrid";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import useActions from "../../../../hooks/useActions";
import {
  setReload,
  initDataComponent,
  performUpdateRequest,
  clearResources,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { dataComponentId as specShipmentsDataComponentId } from "../ShipmentsContainer";
import propTypes from "../../../../../constants/propTypes";
import { specsGridDataComponentId } from "../../../../../actions/purchaseOrderSpecsActions";
import {
  FormGridContainer,
  FormTitle,
  buildInputFields,
  getValidationSchema,
  handleUpdateRequestError,
  initialValues,
} from "./EditMultipleShipmentsModal";
import PurchaseOrder from "../../../../../models/PurchaseOrder";

const dataComponentId = "editMultipleShipmentsAllDataComponent";

const EditMultipleShipmentsAllModal = ({
  purchaseOrder,
  shipmentsDataComponentId,
}) => {
  const dataComponent = useDatacomponent(dataComponentId);

  const loading = dataComponent.requestState?.update?.loading;

  const validationSchema = getValidationSchema();

  const actions = useActions({
    setReload,
    closeModalDialog,
    initDataComponent,
    performUpdateRequest,
    showSnackNotificationAction,
    clearResources,
  });

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.clearResources(["shipments"]);
      actions.closeModalDialog();
      actions.setReload(specShipmentsDataComponentId, true);
      actions.setReload(specsGridDataComponentId, true);
      shipmentsDataComponentId &&
        actions.setReload(shipmentsDataComponentId, true);
    },
    onError: handleUpdateRequestError(actions.showSnackNotificationAction),
  });

  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      PurchaseOrder,
      [],
      "purchase-orders/shipments"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = values => {
    actions.performUpdateRequest(dataComponentId, purchaseOrder.id, values);
  };

  return (
    <FormikForm
      initialValues={initialValues(purchaseOrder)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
        return (
          <Fragment>
            <FormGridContainer>
              <FormGrid
                fields={buildInputFields(setFieldValue)}
                values={values}
                errors={errors}
                {...formikProps}
              />
            </FormGridContainer>
            <FormTitle>{`Checking above will also update previously Approved to Pay Shipments.`}</FormTitle>
            <ActionButtons
              onSend={handleSubmit}
              isModal={true}
              loading={loading}
            />
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

EditMultipleShipmentsAllModal.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
};

export default EditMultipleShipmentsAllModal;
