import React, { useContext } from "react";
import Skeleton from "react-skeleton-loader";
import PropTypes from "prop-types";

export const LoaderContext = React.createContext({ loading: false });

const Loader = ({
  loading: localLoading,
  children = null,
  height = "100%",
  width = "100%",
  widthRandomness = 0,
  heightRandomness = 0,
  borderRadius = 0,
  inverted,
  count,
}) => {
  const { loading } = useContext(LoaderContext);
  return loading || localLoading ? (
    <Skeleton
      width={width}
      height={height}
      widthRandomness={widthRandomness}
      heightRandomness={heightRandomness}
      borderRadius={borderRadius}
      color={inverted ? "#376684" : undefined}
      count={count}
    />
  ) : (
    children
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  loading: PropTypes.bool,
  widthRandomness: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  heightRandomness: PropTypes.number,
  borderRadius: PropTypes.number,
  inverted: PropTypes.bool,
  count: PropTypes.number,
};

export default Loader;
