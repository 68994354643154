import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import EmptyState, { Wrapper } from "../../../ui/EmptyState";
import Note from "./Note";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow: auto;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  ${Wrapper} {
    padding: 42px;
  }
`;

export const NoteList = ({ notes, requestInitialSearch }) => {
  if (notes.length === 0) {
    const text = requestInitialSearch
      ? "Search to Display Notes"
      : "No Notes Found";
    return <EmptyState intent="empty">{text}</EmptyState>;
  }
  return notes.map(note => <Note key={note.id} note={note} />);
};

NoteList.defaultProps = {
  notes: ["1", "2", "3", "4", "5"].map(id => ({ id })),
};

NoteList.propTypes = {
  notes: PropTypes.arrayOf(propTypes.note),
  requestInitialSearch: PropTypes.bool,
};

const arrayCompare = (a1, a2) =>
  a1.length === a2.length && a1.every((e1, index) => e1 === a2[index]);

export const areEqual = (prevProps, nextProps) =>
  arrayCompare(
    prevProps.notes.map(({ id }) => id),
    nextProps.notes.map(({ id }) => id)
  );

export default memo(NoteList, areEqual);
