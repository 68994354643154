import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import VendorHeader, {
  VendorName,
} from "../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo/VendorHeader";
import Contacts from "../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo/Contacts";
import propTypes from "../../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../../withPORevision";
import BoldText from "../../../../ui/Typography/BoldText";
import SerifTitle from "../../../../ui/Typography/SerifTitle";

const CardRight = styled(Paper)`
  width: 100%;
  margin-bottom: 24px;
  padding-bottom: 16px;
  overflow-y: auto;
  ${BoldText} {
    line-height: 24px;
  }
  ${SerifTitle} {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    line-height: 28px;
  }
`;

const VendorInfo = ({ invoice }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const contacts = useMemo(
    () =>
      _get(invoice, "purchaseOrder.poContacts", []).filter(
        ({ contactType }) => contactType === "vendor"
      ),
    [invoice]
  );

  const vendor = _get(invoice, "purchaseOrder.vendor");

  const vendorSubTitle = useMemo(
    () =>
      isCollapsed ? (
        <VendorName vendor={vendor} padding="12px 0 0 24px" />
      ) : null,
    [isCollapsed, vendor]
  );

  return (
    <CardRight>
      <LabeledTextContext.Provider value={true}>
        <VendorHeader
          title="Vendor Information"
          subtitle={vendorSubTitle}
          vendor={invoice?.purchaseOrder?.vendor}
          dataComponentId="InvoiceDetail"
          disabledOptions
          noContacts={_isEmpty(contacts)}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        {isCollapsed ? null : (
          <Contacts displayAddContact={false} vendorContacts={contacts} />
        )}
      </LabeledTextContext.Provider>
    </CardRight>
  );
};

VendorInfo.propTypes = {
  invoice: propTypes.invoice,
};

export default VendorInfo;
