import styled from "styled-components";
import shallow from "zustand/shallow";
import React from "react";

import useErrorImportStore from "../../../../hooks/useErrorImportStore";

export const InvalidText = styled.span`
  color: #ff1744;
`;

function Text({ value, name, rowId }) {
  const [actions] = useErrorImportStore(
    state => [state.actions, state.importError, state.localError], //Doing this force to the component to be re-rendered when the errors get updated changes
    shallow
  );

  if (actions.isInvalidImportField(rowId, name)) {
    return <InvalidText>Invalid</InvalidText>;
  }
  return value || "";
}

export function freeTextRender(spec, name, rowData) {
  return <Text value={spec[name]} name={name} rowId={rowData?.rowId} />;
}
