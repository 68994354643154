import styled from "styled-components";
import React from "react";
import _get from "lodash/get";

import BoldTextSmall from "../../../ui/Typography/BoldTextSmall";
import RegularTextSmall from "../../../ui/Typography/RegularTextSmall";
import propTypes from "../../../../constants/propTypes";

const AuthorWrapper = styled.div`
  display: flex;
  margin-right: 8px;
`;

const Author = ({ note }) => {
  const authorLabel = note.category === "Email" ? "Sent By" : "Created By";
  const authorName =
    note.category === "Email"
      ? note.emailSentBy
      : _get(note, "author.0.name", _get(note, "author.name", "Unknown"));

  return (
    <AuthorWrapper>
      <BoldTextSmall>{authorLabel}:</BoldTextSmall>
      <RegularTextSmall>{authorName}</RegularTextSmall>
    </AuthorWrapper>
  );
};
Author.propTypes = {
  note: propTypes.note.isRequired,
};

export default Author;
