import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Draggable } from "react-beautiful-dnd";

import { getErrorMessage } from "../../../../../utils/formValidationUtils";
import { inputs } from "../../../../inputs/inputConfigs";

const { areaRoomName, areaRoomCount } = inputs;

import {
  CustomListItem,
  NameInputContainer,
  CountInputContainer,
  CustomItemText,
} from "./components";
import ActionIcons from "./ActionIcons";
import InputWrapper from "../../../../inputs/InputWrapper";

export function hasError(name, form, submitted) {
  return submitted && !!form.errors[name];
}

const DataRow = ({ isEditing, errors, submitted, onBlur, ...input }) => (
  <Field name={input.name}>
    {({ field, form }) =>
      isEditing ? (
        <InputWrapper
          {...input}
          {...field}
          hasError={hasError(input.name, form, submitted)}
          errorMessage={getErrorMessage(input.name, form)}
          onBlur={onBlur}
        />
      ) : (
        <CustomItemText>{field.value}</CustomItemText>
      )
    }
  </Field>
);

const shouldSubmit = ({ relatedTarget }, index) => {
  if (!relatedTarget || !relatedTarget.name) return true;
  const { name } = relatedTarget;
  return (
    (!/areaRooms\[\d\]\.roomCount/.test(name) &&
      !/areaRooms\[\d\]\.name/.test(name)) ||
    !name.match(/\d+/) ||
    name.match(/\d+/)[0] != index
  );
};

export default class Item extends Component {
  static propTypes = {
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    room: PropTypes.shape({
      name: PropTypes.string.isRequired,
      roomCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      isEditing: PropTypes.bool,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  switchEditMode = () => {
    const { onEdit, index, room } = this.props;
    this.setState({
      submitted: true,
    });
    onEdit(index, { ...room, isEditing: !room.isEditing });
  };

  getDataRowProps(input) {
    const { room, index } = this.props;
    return {
      ...input,
      isEditing: room.isEditing,
      name: `areaRooms[${index}].${input.name}`,
    };
  }

  onBlur = ({ relatedTarget }) => {
    const { onEdit, index, room } = this.props;
    if (shouldSubmit({ relatedTarget }, index)) {
      this.setState({
        submitted: true,
      });
      onEdit(index, { ...room, isEditing: false });
    }
  };

  render() {
    const { onRemove, index, room, errors, touched } = this.props;
    return (
      <Draggable draggableId={index + ""} index={index}>
        {({ dragHandleProps, ...parentProps }) => (
          <CustomListItem isEditing={room.isEditing} {...parentProps}>
            <NameInputContainer>
              <DataRow
                {...this.getDataRowProps(areaRoomName)}
                errors={errors}
                touched={touched}
                submitted={this.state.submitted}
                onBlur={this.onBlur}
              />
            </NameInputContainer>
            <CountInputContainer>
              <DataRow
                {...this.getDataRowProps(areaRoomCount)}
                errors={errors}
                touched={touched}
                submitted={this.state.submitted}
                onBlur={this.onBlur}
              />
            </CountInputContainer>
            <ActionIcons
              isEditing={room.isEditing}
              onRemove={onRemove}
              onSwitchEditMode={this.switchEditMode}
              index={index}
              {...dragHandleProps}
            />
          </CustomListItem>
        )}
      </Draggable>
    );
  }
}
