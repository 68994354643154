import React from "react";
import styled from "styled-components";

import BaseText from "./BaseText";

const colorIntent = {
  light: "rgba(0, 0, 0, 0.54)",
  black: "#000000",
  white: "#FFFFFF",
  gray3: "#7A8398",
  main: "rgba(0, 0, 0, 0.87)",
  empty: "rgba(0, 0, 0, 0.38)",
};

export default styled(({ intent, ...props }) => <BaseText {...props} />)`
  color: ${({ intent }) => (intent ? colorIntent[intent] : colorIntent.main)};
  font-size: 14px;
  line-height: 20px;
`;
