import * as REQUEST_TYPES from "../constants/ActionTypes";
import StatusService from "../services/StatusService";

const statusService = new StatusService();

const statusRequestAction = service => ({
  type: REQUEST_TYPES.STATUS_REQUEST,
  payload: {
    service,
  },
});

const statusSuccessAction = (service, data) => ({
  type: REQUEST_TYPES.STATUS_SUCCESS,
  payload: {
    service,
    data,
  },
});

const statusFailureAction = (service, error) => {
  return {
    type: REQUEST_TYPES.STATUS_FAILURE,
    payload: {
      service,
      error,
    },
    error: true,
  };
};

export function herokuStatus() {
  const service = "herokuStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.herokuStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function herokuDatabaseStatus() {
  const service = "herokuDatabaseStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.herokuDatabaseStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function herokuRedisStatus() {
  const service = "herokuRedisStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.herokuRedisStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function apiVersion() {
  const service = "apiVersion";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.apiVerion();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function databaseStatus() {
  const service = "databaseStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.databaseStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function messagingStatus() {
  const service = "messagingStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.messagingStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function emailStatus() {
  const service = "emailStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.emailStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}

export function lambdaStatus() {
  const service = "lambdaStatus";
  return async dispatch => {
    try {
      dispatch(statusRequestAction(service));
      const data = await statusService.lambdaStatus();
      dispatch(statusSuccessAction(service, data));
    } catch (error) {
      dispatch(statusFailureAction(service, error));
    }
  };
}
