import React, { Component } from "react";
import { FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { Paper } from "../../../mui/core";
import SectionHeader from "../../../ui/Typography/SectionHeader";
import createVendorFields from "./createVendorFields";
import FormGrid from "../../../forms/FormGrid";
import propTypes from "../../../../constants/propTypes";
import { BrandArray } from "./BrandArray";

export class GeneralInformationSection extends Component {
  static propTypes = {
    vendorSchema: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    userRole: propTypes.userRole,
    isUpdate: PropTypes.bool,
  };

  render() {
    const { values, errors, userRole, isUpdate, ...otherProps } = this.props;
    const fields = createVendorFields(userRole, isUpdate);
    return (
      <Paper>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div>
              <SectionHeader>{"General Information"}</SectionHeader>
              <FormGrid
                fields={{ groups: [fields.groups.at(0)] }}
                values={values}
                errors={errors}
                {...otherProps}
              />
              <FieldArray name="brands">
                {formikArrayHelpers => (
                  <BrandArray formikArrayHelpers={formikArrayHelpers} />
                )}
              </FieldArray>
              <FormGrid
                fields={{ groups: fields.groups.slice(1) }}
                values={values}
                errors={errors}
                {...otherProps}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default GeneralInformationSection;
