import styled from "styled-components";
import BaseText from "./BaseText";

const BoldText = styled(BaseText)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: bold;
`;
BoldText.displayName = "BoldText";

export default BoldText;
