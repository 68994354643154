import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import {
  useUpdateProcessRequests,
  useCreateProcessRequests,
} from "../../../../hooks/useProcessRequest";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import useActions from "../../../../hooks/useActions";
import { bidGroupVendorSpecQuoteComponentId } from "../../../BidGroupDetail/Quotes/QuotesPage";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as RequestTypes from "../../../../../constants/RequestTypes";

export function useUpdateNoteSuccess(
  snackMessage,
  bidGroupVendor,
  setBidGroupVendor
) {
  const dataComponent = useDatacomponent(bidGroupVendorSpecQuoteComponentId);
  const actions = useActions({ closeModalDialog, showSnackNotificationAction });

  const updatedResource = useV2DatacomponentResources(
    bidGroupVendorSpecQuoteComponentId,
    null,
    RequestTypes.UPDATE
  );

  const onSuccess = () => {
    actions.showSnackNotificationAction(snackMessage);
    actions.closeModalDialog();

    const bgVendor = {
      ...bidGroupVendor,
      bidGroupVendorSpecs: [...bidGroupVendor.bidGroupVendorSpecs],
    };
    const bgVendorSpecIndex = bgVendor.bidGroupVendorSpecs.findIndex(
      vendorSpec => vendorSpec.id === updatedResource.bidGroupVendorSpecId
    );
    const bgVendorSpec = {
      ...bidGroupVendor.bidGroupVendorSpecs[bgVendorSpecIndex],
    };
    bgVendorSpec.quotes = [...bgVendorSpec.quotes];
    bgVendor.bidGroupVendorSpecs[bgVendorSpecIndex] = bgVendorSpec;

    const quoteIndex = bgVendorSpec.quotes.findIndex(
      quote => quote.id === updatedResource.id
    );
    bgVendorSpec.quotes[quoteIndex] = updatedResource;
    setBidGroupVendor(bgVendor);
  };

  useUpdateProcessRequests(dataComponent, { onSuccess });
}

export function useCreateAlternateQuoteSuccess(
  snackMessage,
  bidGroupVendor,
  setBidGroupVendor
) {
  const dataComponent = useDatacomponent(bidGroupVendorSpecQuoteComponentId);
  const actions = useActions({ closeModalDialog, showSnackNotificationAction });

  const createdResource = useV2DatacomponentResources(
    bidGroupVendorSpecQuoteComponentId,
    null,
    RequestTypes.CREATE
  );

  const onSuccess = () => {
    actions.showSnackNotificationAction(snackMessage);
    actions.closeModalDialog();

    const bgVendor = {
      ...bidGroupVendor,
      bidGroupVendorSpecs: [...bidGroupVendor.bidGroupVendorSpecs],
    };
    const bgVendorSpecIndex = bgVendor.bidGroupVendorSpecs.findIndex(
      vendorSpec => vendorSpec.id === createdResource.bidGroupVendorSpecId
    );
    const bgVendorSpec = {
      ...bidGroupVendor.bidGroupVendorSpecs[bgVendorSpecIndex],
    };
    bgVendorSpec.quotes = [...bgVendorSpec.quotes, createdResource];
    bgVendor.bidGroupVendorSpecs[bgVendorSpecIndex] = bgVendorSpec;

    setBidGroupVendor(bgVendor);
  };

  useCreateProcessRequests(dataComponent, { onSuccess });
}
