import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import Vendor from "./Vendor";
import BidGroup from "./BidGroup";
import BidGroupVendorSpec from "./BidGroupVendorSpec";
import VendorContact from "./VendorContact";
import ProjectCurrency from "./ProjectCurrency";
import File from "./File";

export default class BidGroupVendor extends BaseModel {
  static get belongsTo() {
    return [BidGroup, Vendor, File, ProjectCurrency];
  }

  static get hasMany() {
    return [Spec, BidGroupVendorSpec, VendorContact];
  }
}
