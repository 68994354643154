import React from "react";
import PropTypes from "prop-types";
import VendorForm from "./VendorForm";

const VendorCreatePage = ({ createVendor, goBack, dataComponentId }) => (
  <VendorForm
    goBack={goBack}
    onSubmit={createVendor}
    sendButtonText="Create Vendor"
    dataComponentId={dataComponentId}
  />
);

VendorCreatePage.propTypes = {
  goBack: PropTypes.func.isRequired,
  createVendor: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
};

export default VendorCreatePage;
