import PropTypes from "prop-types";
import useCurrencyFormatter from "../hooks/useCurrencyFormatter";

function Price({ number, decimalPlaces, currency }) {
  return useCurrencyFormatter(currency, decimalPlaces).format(number);
}

Price.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalPlaces: PropTypes.number,
  currency: PropTypes.object,
};

Price.defaultProps = {
  decimalPlaces: 2,
};

Price.displayName = "Price";

export default Price;
