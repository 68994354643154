import { inputs } from "../../../../inputs/inputConfigs";

const defaultItems = [
  {
    input: {
      ...inputs.areaName,
      required: true,
    },
    grid: { xs: 6 },
  },
  {
    input: {
      ...inputs.areaType,
      required: true,
    },
    grid: { xs: 6 },
  },
  {
    input: {
      ...inputs.description,
      required: true,
    },
    grid: { xs: 12 },
  },
];
const itemsForMultipleRoom = [
  ...defaultItems,
  {
    input: {
      ...inputs.areaBudgetCents,
    },
    grid: { xs: 6 },
  },
];
const itemsForSingleRoom = [
  ...defaultItems,
  {
    input: inputs.areaSquareFoot,
    grid: { xs: 6 },
  },
  {
    input: {
      ...inputs.areaBudgetCents,
    },
    grid: { xs: 6 },
  },
];

export default function(isMultipleRoom) {
  return {
    groups: [
      {
        items: !isMultipleRoom ? itemsForSingleRoom : itemsForMultipleRoom,
      },
    ],
  };
}
