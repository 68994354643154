import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../ActionButtons";
import RegularText from "../../ui/Typography/RegularText";

export const Message = styled(RegularText)`
  padding: 24px;
`;

const DirtyFormDialog = ({
  title,
  submitText,
  onSubmit,
  discardText,
  onDiscard,
  ...buttonsProps
}) => (
  <Fragment>
    <Message>
      Do you want to discard or save changes and leave this page?
    </Message>
    <ActionButtons
      sendButtonText={submitText}
      onSend={onSubmit}
      onDanger={onDiscard}
      dangerButtonText={discardText}
      isModal
      emphasizeCancel
      hideCancelButton
      {...buttonsProps}
    />
  </Fragment>
);

DirtyFormDialog.propTypes = {
  submitText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DirtyFormDialog;
