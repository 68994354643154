import React from "react";
import styled from "styled-components";
import find from "lodash/find";

import PercentageInput from "../../../inputs/PercentageInput";
import CurrencyInput from "../../../inputs/CurrencyInput";
import DecimalInput from "../../../inputs/DecimalInput";

import {
  InputNumber,
  InputWrapper,
  StyledEditIcon,
} from "../ImportSpecs/components";

import SelectWithError from "../../../inputs/SelectWithError";

import {
  categoryTypesIdComponent,
  areaTypesIdComponent,
  vendorsIdComponent,
} from "./DuplicateSpecContainer";
import { projectCurrenciesDataComponentId } from "../../../../actions/currencyActions";
import { unitOfMeasureDataComponentId } from "../../../../actions/specsActions";
import Vendor from "../../../../models/Vendor";
import EditableSelect from "../ImportSpecs/editableControls/EditableSelect";
import { isSpecCategoryOptionDisabled } from "../ImportSpecs/gridOptions";
import { getSpecCategoryLabel } from "../../../../models/SpecCategory";

const Select = styled(SelectWithError)`
  width: 100%;
`;

// eslint-disable-next-line max-lines-per-function
export const columnsOptions = (
  setSpecs,
  specs,
  projectCurrencies,
  specCategories
) => ({
  customNumber: {
    bold: true,
    title: "Number",
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "customNumber", setSpecs, specs),
      "customNumber"
    ),
  },
  description: {
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "description", setSpecs, specs),
      "description"
    ),
  },
  specCategoryId: {
    title: "Category",
    render: buildSelectCategoryColumn(specCategories, setSpecs, specs),
  },
  areaId: {
    title: "Area",
    render: buildSelectColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "areaId", setSpecs, specs),
      "areaId",
      areaTypesIdComponent
    ),
  },
  vendorId: {
    title: "Vendor",
    render: buildSelectColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "vendorId", setSpecs, specs),
      "vendorId",
      vendorsIdComponent
    ),
  },
  projectCurrencyId: {
    title: "Currency",
    sortingEnabled: false,
    filterOptions: {
      options: projectCurrencies.map(projectCurrency => ({
        ...projectCurrency,
        name: projectCurrency.currency.name,
      })),
    },
    render: buildSelectCurrencyColumn(projectCurrencies, setSpecs, specs),
  },
  priceCents: {
    title: "Price",
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecFields(
          specWithValue.specId,
          {
            priceCents: specWithValue.value,
          },
          setSpecs,
          specs
        ),
      "priceCents",
      CurrencyInput
    ),
  },
  baseQuantity: {
    title: "Base Qty",
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "baseQuantity", setSpecs, specs),
      "baseQuantity",
      DecimalInput
    ),
  },
  atticStock: {
    title: "Attic Stock",
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "atticStock", setSpecs, specs),
      "atticStock",
      DecimalInput
    ),
  },
  overagePercent: {
    title: "Overage",
    render: buildTextFieldColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "overagePercent", setSpecs, specs),
      "overagePercent",
      PercentageInput
    ),
  },
  unitOfMeasureId: {
    title: "UOM",
    render: buildSelectColumn(
      "",
      specWithValue =>
        updateSpecField(specWithValue, "unitOfMeasure", setSpecs, specs),
      "unitOfMeasureId",
      unitOfMeasureDataComponentId
    ),
  },
});

const buildSelectColumn = (
  defaultValue,
  onChangeValue,
  fieldName,
  dataComponentId
) => {
  return spec => {
    return (
      spec && (
        <EditableSelect
          SelectComponent={Select}
          dataComponentId={dataComponentId}
          ignoreCaption
          rowId={spec.id}
          name={fieldName}
          InputProps={{ disableUnderline: true }}
          value={spec[fieldName] || defaultValue}
          onChange={event =>
            onChangeValue({
              specId: spec.id,
              value: event.target.value,
            })
          }
          isOptionDisabled={Vendor.isVendorDisabled}
          labelProperty={Vendor.labelProperty}
        />
      )
    );
  };
};

const buildSelectCurrencyColumn = (projectCurrencies, setSpecs, specs) => {
  return (spec, name) => {
    return (
      spec && (
        <Select
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          valueProperty={"id"}
          labelProperty={"currency.name"}
          displayEmpty={false}
          dataComponentId={projectCurrenciesDataComponentId}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          value={spec.projectCurrencyId || "34"}
          onChange={({ target: { value } }) => {
            const projectCurrency = projectCurrencies.find(
              projectCurrency => projectCurrency.id == value
            );
            updateSpecFields(
              spec.id,
              {
                projectCurrencyId: value,
                projectCurrency,
                priceCents: spec.priceCents,
              },
              setSpecs,
              specs
            );
          }}
        />
      )
    );
  };
};

const buildSelectCategoryColumn = (specCategories, setSpecs, specs) => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          valueProperty={"id"}
          dataComponentId={categoryTypesIdComponent}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          labelProperty={getSpecCategoryLabel}
          isOptionDisabled={isSpecCategoryOptionDisabled}
          value={spec.specCategoryId}
          onChange={({ target: { value } }) => {
            const specCategory = find(specCategories, { id: value });
            updateSpecFields(
              spec.id,
              {
                specCategoryId: value,
                specCategory,
              },
              setSpecs,
              specs
            );
          }}
        />
      )
    );
  };
};

export const buildTextFieldColumn = (
  defaultValue,
  onChangeValue,
  fieldName,
  InputComponent
) => {
  return spec => {
    const inputProps =
      InputComponent === PercentageInput || InputComponent === DecimalInput
        ? { decimalScale: 2 }
        : {};

    inputProps.currency =
      InputComponent === CurrencyInput
        ? spec.projectCurrency?.currency
        : undefined;
    return (
      spec && (
        <InputWrapper>
          <InputNumber
            avoidDebounce
            ignoreCaption
            inputProps={inputProps}
            InputProps={{
              inputComponent: InputComponent,
              disableUnderline: true,
            }}
            value={spec[fieldName] || defaultValue}
            onChange={({ target: { value } }) => {
              return onChangeValue({ ...spec, specId: spec.id, value: value });
            }}
          />
          <StyledEditIcon />
        </InputWrapper>
      )
    );
  };
};

export const updateSpecFields = (specId, values, setSpecs, specs) => {
  const updatedSpecs = specs.map(spec => {
    if (spec.id !== specId) return spec;
    return {
      ...spec,
      ...values,
    };
  });
  setSpecs(updatedSpecs);
};

export const updateSpecField = (specWithValue, fieldName, setSpecs, specs) => {
  const updatedSpecs = specs.map(spec => {
    spec[fieldName] =
      spec.id === specWithValue.specId ? specWithValue.value : spec[fieldName];
    return spec;
  });
  setSpecs(updatedSpecs);
};
