import AuthService from "../services/AuthService";
import * as actions from "../constants/ActionTypes";
import { handleFileResponse } from "./reportsActions";

const authService = new AuthService(false);

const uploadFileRequestAction = key => ({
  type: actions.UPLOAD_FILE_REQUEST,
  payload: { key },
});

const uploadFileSuccessAction = key => ({
  type: actions.UPLOAD_FILE_SUCCESS,
  payload: { key },
});

const uploadFileFailureAction = key => ({
  type: actions.UPLOAD_FILE_FAILURE,
  payload: { key },
});

const downloadQuoteFilesRequestAction = bidGroupVendorId => ({
  type: actions.DOWNLOAD_QUOTE_FILES_REQUEST,
  payload: { bidGroupVendorId },
});

const downloadQuoteFilesSuccessAction = bidGroupVendorId => ({
  type: actions.DOWNLOAD_QUOTE_FILES_SUCCESS,
  payload: { bidGroupVendorId },
});

const downloadQuoteFilesFailureAction = bidGroupVendorId => ({
  type: actions.DOWNLOAD_QUOTE_FILES_FAILURE,
  payload: { bidGroupVendorId },
});

const callAction = (action, key, fullKey, tempKey) => {
  return async dispatch => {
    dispatch(action(key));
    dispatch(action(fullKey));
    if (tempKey) {
      dispatch(action(tempKey));
    }
  };
};

export const uploadFile = ({ key, filename }, file, tempKey) => {
  const fullKey = `${key}/${filename}`;
  return async dispatch => {
    dispatch(callAction(uploadFileRequestAction, key, fullKey, tempKey));

    try {
      const response = await authService.get("/files/upload-url", {
        key: fullKey,
      });
      const presignedUrl = response.data.attributes.url;
      await fetch(presignedUrl, {
        method: "PUT",
        body: file,
      });
      dispatch(callAction(uploadFileSuccessAction, key, fullKey, tempKey));
    } catch (error) {
      dispatch(callAction(uploadFileFailureAction, key, fullKey, tempKey));
    }
  };
};

export function uploadFiles(instance, instanceId, fileFields = [], uri) {
  return async dispatch => {
    const files = fileFields
      .filter(field => instance[field] && instance[field].isUpdated)
      .map(field => {
        const { isUpdated, ...file } = instance[field];
        return {
          field,
          file,
        };
      });
    const key = instanceId ? `${uri}/${instanceId}` : "${uri}/temp";
    return await Promise.all(
      files.map(async ({ field, file: { file, ...fileWrapper } }) => {
        const ext = file.name.split(".").pop();
        const filename = instanceId
          ? `${field}.${ext}`
          : `${Date.now()}-${field}.${ext}`;
        await dispatch(uploadFile({ key, filename }, file));
        return {
          ...fileWrapper,
          field,
          s3Key: `${key}/${filename}`,
          filename: file.name,
          metadata: {
            size: file.size,
          },
        };
      })
    );
  };
}

export function downloadQuoteFiles(bidGroupVendorId) {
  return async dispatch => {
    try {
      dispatch(callAction(downloadQuoteFilesRequestAction, bidGroupVendorId));

      const response = await authService.post(
        "/download-quote-files",
        { bidGroupVendorId },
        {},
        {
          Accept: "application/zip",
        },
        {
          returnRawResponse: true,
        }
      );

      await handleFileResponse(response);
      dispatch(callAction(downloadQuoteFilesSuccessAction, bidGroupVendorId));
    } catch (error) {
      dispatch(callAction(downloadQuoteFilesFailureAction, bidGroupVendorId));
    }
  };
}
