import React from "react";
import styled from "styled-components";
import FilterListIcon from "@material-ui/icons/FilterList";
import { StyledTitle } from "../../layout/NavigationBar/components";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalOffer from "@material-ui/icons/LocalOffer";

export const DivFlex = styled.div`
  flex: 1;
  ${DropdownMenu} {
    display: inline-block;
    padding: 0 17px;
  }
`;

export const FilterIcon = styled(FilterListIcon)`
  font-size: 22px;
  margin-left: 8px;
  color: #57abff;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Domine, serif;
  ${StyledTitle} {
    align-self: center;
  }
`;

export const TitleOptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterOption = styled(({ hasFilter, ...props }) => (
  <div {...props} />
))`
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 12px;
  margin-right: 24px;

  color: #57abff;

  > span {
    cursor: pointer;
  }

  ${({ hasFilter }) =>
    hasFilter &&
    `
    background: #57abff;
    border-radius: 31px;
    color: #ffffff;

    ${FilterIcon} {
      color: #ffffff;
      margin-right: 4px;
    }
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0px;
  align-items: center;
`;
export const AttributesWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: left;
  margin-right: 16px;
  min-height: 22px;
`;

export const ExpandActionWrapper = styled(HeaderLeft)`
  flex-grow: 1;
  margin-right: 0px;
  justify-content: flex-end;
  align-items: center;
`;

export const ManageBidGroups = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const ManageBidGroupsSpan = styled.span`
  font-weight: 600;
  color: rgb(87, 171, 255);
`;

export const StyledLocalOffer = styled(LocalOffer)`
  color: rgb(87, 171, 255);
  margin-right: 8px;
`;

export const ExpandedIcon = styled(({ isExpanded, ...props }) => (
  <ExpandMoreIcon {...props} />
))`
  ${({ isExpanded }) =>
    isExpanded
      ? `
  transform: rotate(180deg);
  marginleft: auto;
  transition: transform;
  `
      : null}
`;
