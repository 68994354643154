import { BaseModel } from "@blue-chip/core";
import BidGroupVendorSpec from "./BidGroupVendorSpec";
import BidGroupVendorSpecQuoteItem from "./BidGroupVendorSpecQuoteItem";

export default class BidGroupVendorSpecQuote extends BaseModel {
  static get belongsTo() {
    return [BidGroupVendorSpec];
  }
  static get hasMany() {
    return [BidGroupVendorSpecQuoteItem];
  }
}
