import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {
  GridSection,
  GridWrapper,
} from "../../../RequirementsSection/ReviewSubmittals/components";
import EmptyState from "../../../../../ui/EmptyState";
import propTypes from "../../../../../../constants/propTypes";
import Grid from "./Grid";

function MainSection({ files, onDelete, activeDelete, isLabeled }) {
  if (files.length > 0) {
    return (
      <Fragment>
        <GridSection>
          <GridWrapper>
            <Grid
              files={files}
              onDelete={onDelete}
              activeDelete={activeDelete}
              isLabeled={isLabeled}
            />
          </GridWrapper>
        </GridSection>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <GridSection>
        <GridWrapper showBorder>
          <EmptyState intent="normal">No files Uploaded</EmptyState>
        </GridWrapper>
      </GridSection>
    </Fragment>
  );
}

MainSection.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, file: propTypes.file })
  ),
  activeDelete: PropTypes.string,
  onDelete: PropTypes.func,
  isLabeled: PropTypes.bool,
};

export default MainSection;
