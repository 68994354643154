import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import _isNil from "lodash/isNil";
import styled from "styled-components";
import get from "lodash.get";

import EmptyState from "../../../../ui/EmptyState";
import RegularTextSmall from "../../../../ui/Typography/RegularTextSmall";
import RegularText from "../../../../ui/Typography/RegularText";
import BoldText from "../../../../ui/Typography/BoldText";
import { COM_TYPE, SPEC_TYPE } from "../../Quotes/bidGroupVendorSpecUtils";

import * as CELL_TYPES from "../cellTypes";
import SelectComControl from "../Controls/SelectComControl";
import IndicatorProvider from "./dataTypeProviders/IndicatorProvider";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { KeyboardArrowDown } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const LabelText = styled(RegularTextSmall)`
  text-align: right;
  display: inline-block;
  width: 100%;
`;

const Bold = styled(BoldText)`
  display: inline-block;
  width: 100%;
`;
const Italic = styled(RegularText)`
  font-style: italic;
  color: #9ea7ba;
`;
const HighlightedNumber = styled(RegularText)`
  color: #18cc55;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const EmptyStateFormatter = () => {
  return (
    <EmptyStateWrapper>
      <EmptyState size="small" intent="empty" intensity="strong">
        Please Select Vendors for this Bid Group
      </EmptyState>
    </EmptyStateWrapper>
  );
};

export const emptyTypeBuilder = columns => {
  const EmptyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={EmptyStateFormatter}
      {...props}
      for={columns}
    />
  );
  return EmptyTypeProvider;
};

const getProjectCurrency = value => {
  return value && value.projectCurrency ? value.projectCurrency : null;
};

const getCurrency = projectCurrency => {
  return projectCurrency && projectCurrency.currency
    ? projectCurrency.currency
    : null;
};

const getCurrencyFormatter = currency => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCurrencyFormatter(currency);
};

const getRealValue = (value, currencyFormatter, projectCurrency) => {
  let realValue = value.value;
  if (!isNaN(value.value)) {
    realValue = currencyFormatter.format(value.value);
    if (projectCurrency && projectCurrency.conversionRate) {
      realValue = currencyFormatter.format(
        value.value / projectCurrency.conversionRate
      );
    }
  }
  return realValue;
};

const CurrencyFormatter = ({ value }) => {
  const projectCurrency = getProjectCurrency(value);
  const currency = getCurrency(projectCurrency);
  const currencyFormatter = getCurrencyFormatter(currency);
  if (_isNil(value)) return "";
  if (typeof value !== "object") {
    value = { type: typeof value, value };
  }

  const { type } = value;
  const realValue = getRealValue(value, currencyFormatter, projectCurrency);
  const { isSelected, onClick, comItem, bgVendor } = value;

  const map = {
    [CELL_TYPES.SELECT_COM_FIRST]: <Italic as="span">Select COM First</Italic>,
    [CELL_TYPES.SELECT_COM_CHECKBOX]: (
      <SelectComControl
        isSelected={isSelected}
        onClick={() => onClick(bgVendor, comItem)}
      />
    ),
    [CELL_TYPES.TOTAL]: <Bold as="span">Total: {realValue}</Bold>,
    [CELL_TYPES.INCLUDED_IN_SPEC]: <Italic>{value.value}</Italic>,
    [CELL_TYPES.BOLD]: <Bold as="span">{realValue}</Bold>,
    [CELL_TYPES.HIGHLIGHTED_NUMBER]: (
      <HighlightedNumber>{realValue}</HighlightedNumber>
    ),
    [CELL_TYPES.NUMBER]: realValue,
    [CELL_TYPES.CHEVRON]: (
      <IconButton onClick={() => onClick()}>
        <KeyboardArrowDown />
      </IconButton>
    ),
    [CELL_TYPES.EMPTY_CELL]: "",
  };
  const element = map[type];
  if (!element) {
    return value.value || "";
  }
  return element;
};

export const currencyTypeBuilder = columns => {
  const CurrencyTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CurrencyFormatter}
      {...props}
      for={columns}
    />
  );
  return CurrencyTypeProvider;
};

const UnitFormatter = ({ value }) => {
  if (_isNil(value)) return "";

  const { value: realValue, unitOfMeasure: unitOfMeasureObj } = value;

  const unitOfMeasure = get(unitOfMeasureObj, "name", "");

  if (unitOfMeasure === "EA") {
    return realValue;
  }
  return `${realValue}${(unitOfMeasure || "").toLowerCase()}`;
};

const CustomFormatter = ({ value }) => {
  const projectCurrency = getProjectCurrency(value);
  const currency = getCurrency(projectCurrency);
  const currencyFormatter = getCurrencyFormatter(currency);
  if (_isNil(value)) return "";
  if (typeof value !== "object") {
    value = { type: typeof value, value };
  }

  const { type, unitOfMeasure: unitOfMeasureObj } = value;
  const unitOfMeasure = get(unitOfMeasureObj, "name", "");
  const realValue = getRealValue(value, currencyFormatter, projectCurrency);
  const { isSelected, onClick, comItem, bgVendor } = value;

  const map = {
    [CELL_TYPES.SELECT_COM_FIRST]: <Italic as="span">Select COM First</Italic>,
    [CELL_TYPES.SELECT_COM_CHECKBOX]: (
      <SelectComControl
        isSelected={isSelected}
        onClick={() => onClick(bgVendor, comItem)}
      />
    ),
    [CELL_TYPES.TOTAL]: <Bold as="span">Total: {realValue}</Bold>,
    [CELL_TYPES.INCLUDED_IN_SPEC]: <Italic>{value.value}</Italic>,
    [CELL_TYPES.BOLD]: <Bold as="span">{realValue}</Bold>,
    [CELL_TYPES.HIGHLIGHTED_NUMBER]: (
      <HighlightedNumber>{realValue}</HighlightedNumber>
    ),
    [CELL_TYPES.NUMBER]: realValue,
    [CELL_TYPES.CHEVRON]: (
      <IconButton onClick={() => onClick()}>
        <KeyboardArrowDown />
      </IconButton>
    ),
    [CELL_TYPES.QUANTITY]: `${value.value}${(
      unitOfMeasure || ""
    ).toLowerCase()}`,
  };
  const element = map[type];
  if (!element) {
    return value.value || "";
  }
  return element;
};

export const unitTypeBuilder = columns => {
  const UnitTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={UnitFormatter}
      {...props}
      for={columns}
    />
  );
  return UnitTypeProvider;
};

export const customTypeBuilder = columns => {
  const CustomTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={CustomFormatter}
      {...props}
      for={columns}
    />
  );
  return CustomTypeProvider;
};

const DescriptionFormatter = props => {
  if (!props || !props.value) return "";
  const {
    value: { value, type },
  } = props;
  switch (type) {
    case "label": {
      return (
        <LabelText intent="light" as="span">
          {value}
        </LabelText>
      );
    }
    case SPEC_TYPE: {
      return <Bold as="span">{value}</Bold>;
    }
    case COM_TYPE: {
      return (
        <RegularText intent="gray3" as="span">
          {value}
        </RegularText>
      );
    }
    default: {
      return value;
    }
  }
};

export const descriptionTypeBuilder = columns => {
  const DescriptionTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={DescriptionFormatter}
      {...props}
      for={columns}
    />
  );
  return DescriptionTypeProvider;
};

export default bgVendors => () => {
  const providerDescriptions = [
    {
      builder: emptyTypeBuilder,
      columns: ["emptyColumn"],
    },
    {
      builder: currencyTypeBuilder,
      columns: bgVendors.reduce(
        (columns, bgVendor, vendorIndex) => {
          columns.push(
            `quote_${vendorIndex}_extendedSamplePricing`,
            `quote_${vendorIndex}_extendedProductionPricing`,
            `quote_${vendorIndex}_selectCOM`,
            `quote_${vendorIndex}_chevron`
          );
          return columns;
        },
        ["designerBudget", "designerExtendedCost"]
      ),
    },
    {
      builder: unitTypeBuilder,
      columns: bgVendors.reduce(
        (columns, bgVendor, vendorIndex) => {
          columns.push(
            `quote_${vendorIndex}_extendedSampleCOMQuantity`,
            `quote_${vendorIndex}_extendedProductionCOMQuantity`
          );
          return columns;
        },
        ["designerQuantity"]
      ),
    },
    {
      builder: descriptionTypeBuilder,
      columns: [
        "description",
        "customNumber",
        "totalSampleQuantity",
        "totalProductionQuantity",
      ],
    },
    {
      builder: customTypeBuilder,
      columns: bgVendors.reduce((columns, bgVendor, vendorIndex) => {
        columns.push(
          `quote_${vendorIndex}_sampleCOMQuantity`,
          `quote_${vendorIndex}_productionCOMQuantity`,
          `quote_${vendorIndex}_samplePricing`,
          `quote_${vendorIndex}_productionPricing`
        );
        return columns;
      }, []),
    },
  ];

  return [
    IndicatorProvider,
    ...providerDescriptions.map(({ builder, columns }) => builder(columns)),
  ];
};
