import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import TagText from "../../../ui/Tags/TagText";
import Date from "../../../ui/BWGrid/columns/Date";
import OptionalColumn from "../../../ui/BWGrid/columns/OptionalColumn";
import Price from "../../../ui/Price";
import { BWGridAPI, GridHeader } from "../../../ui/BWGrid";
import Invoice from "../../../../models/Invoice";
import { GridTable } from "../../../ui/BWGrid";
import { Paper } from "../../../mui/core";
import propTypes from "../../../../constants/propTypes";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import { CollapseButton } from "../../../ui/CollapseButton";

export const OkToIssue = ({ okToIssue }) => {
  if (!okToIssue) return "";
  return <TagText intent="success">Yes</TagText>;
};

OkToIssue.propTypes = {
  okToIssue: PropTypes.bool,
};

const columns = [
  "okToIssue",
  "number",
  "invoicedDate",
  "checkRequest",
  "purchaseOrder.vendor.name",
  "amount",
  "dueDate",
  "checkNumber",
  "paymentDate",
];

export const getColumnOptions = ({ currency }) => ({
  okToIssue: {
    title: "Ok to Issue",
    bold: true,
    render: OkToIssue,
    filter: "select",
    filterOptions: {
      operator: "equal",
      options: [
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ],
    },
  },
  number: { title: "Invoice #", filter: true, bold: true },
  invoicedDate: {
    render: ({ id, invoicedDate }) =>
      id && <Date date={invoicedDate} format={PRIMARY_DATE_FORMAT} />,
  },
  checkRequest: {
    title: "CR #",
    filter: true,
    bold: true,
    render: ({ id, checkRequest }) =>
      id && <OptionalColumn>{checkRequest}</OptionalColumn>,
  },
  "purchaseOrder.vendor.name": { title: "Vendor", filter: true, fill: true },
  amount: {
    title: "Amount",
    render: ({ id, amount }) =>
      id && <Price number={amount} currency={currency} />,
  },
  dueDate: {
    title: "Due Date",
    render: ({ id, dueDate }) =>
      id && <Date date={dueDate} format={PRIMARY_DATE_FORMAT} />,
  },
  checkNumber: {
    title: "Check #",
  },
  paymentDate: {
    title: "Payment Date",
    filter: "picker",
    render: ({ id, paymentDate }) =>
      id && <Date date={paymentDate} format={PRIMARY_DATE_FORMAT} />,
  },
});

const POInvoicesList = ({
  projectCurrency,
  dataComponent,
  poId,
  invoicesCount,
  onRowClick,
  onDownloadCheckRequestLog,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    setIsCollapsed(true);
  }, [poId]);

  if (isCollapsed) {
    return (
      <Paper>
        <GridHeader headerText={pluralize("Invoice", invoicesCount, true)}>
          <CollapseButton
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </GridHeader>
      </Paper>
    );
  }

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Invoice}
        apiRoute="invoices"
        includes={["purchaseOrder.vendor"]}
        defaultSorting={[{ columnName: "checkRequest", direction: "asc" }]}
        rootFilters={{
          $where: {
            isVoid: {
              $exist: false,
            },
            purchaseOrderId: poId,
          },
        }}
      >
        <GridHeader
          headerText={pluralize("Invoice", dataComponent.totalRows, true)}
        >
          <CollapseButton
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        </GridHeader>
        <GridTable
          columns={columns}
          columnOptions={getColumnOptions(projectCurrency)}
          onClick={onRowClick}
          rowMenu={() => [
            {
              text: "Preview Check Request",
              onClick: row => onDownloadCheckRequestLog(row, true),
            },
            {
              text: "Print Check Request",
              onClick: row => onDownloadCheckRequestLog(row, false),
            },
          ]}
        />
      </BWGridAPI>
    </Paper>
  );
};
POInvoicesList.propTypes = {
  projectCurrency: propTypes.projectCurrency.isRequired,
  poId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onDownloadCheckRequestLog: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  invoicesCount: PropTypes.number,
};
export default POInvoicesList;
