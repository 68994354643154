import { connect } from "react-redux";
import _get from "lodash/get";

import { BulkUpdateTerms, queueId } from "./BulkUpdateTerms";
import {
  createQueueProcess,
  initQueueProcess,
} from "../../../../../actions/queuesActions";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import * as QUEUE_STATUSES from "../../../../../constants/queue";

export const mapStateToProps = state => {
  const { queues } = state;
  const syncUpdateTermsQueue = queues[queueId];

  return {
    syncUpdateTermsQueue,
    loading:
      _get(syncUpdateTermsQueue, "status", false) ===
      QUEUE_STATUSES.QUEUE_PROCESSING,
    progress: syncUpdateTermsQueue?.progress || 0,
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  showSnackNotificationAction,
  initQueueProcess,
  createQueueProcess,
};
export default connect(mapStateToProps, mapDispatchToProps)(BulkUpdateTerms);
