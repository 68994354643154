import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import AddNoteForm from "./AddNoteForm";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performCreateRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import Note from "../../../../../models/Note";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { generateNoteData } from "../../../Notes/NoteCreate/NoteCreateContainer";

const dataComponentId = "AddNoteDC";

const handleError = showSnackNotificationAction => ({ data }) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

const handleSuccess = (showSnackNotificationAction, closeModalDialog) => () => {
  closeModalDialog();
  showSnackNotificationAction("Note has been completed successfully");
};

export const validateCreateStatus = (
  processCreateRequestStatus,
  closeModalDialog,
  showSnackNotificationAction,
  dataComponent,
  prevDataComponent
) => () => {
  return processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(showSnackNotificationAction, closeModalDialog),
    onError: handleError(showSnackNotificationAction),
  });
};

const AddNoteContainer = ({
  dataComponent,
  userId,
  purchaseOrder,
  projectId,
  initDataComponent,
  performCreateRequest,
  showSnackNotificationAction,
  onClosePopover,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(
    validateCreateStatus(
      processCreateRequestStatus,
      onClosePopover,
      showSnackNotificationAction,
      dataComponent,
      prevDataComponent
    ),
    [dataComponent, onClosePopover, showSnackNotificationAction]
  );

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    initDataComponent(dataComponentId, Note, [], "notes");
  }, [initDataComponent]);

  const handleCreateNote = note => {
    performCreateRequest(dataComponentId, generateNoteData(note));
  };

  return (
    <AddNoteForm
      userId={userId}
      projectId={projectId}
      purchaseOrder={purchaseOrder}
      onCreateNote={handleCreateNote}
      onClosePopover={onClosePopover}
    />
  );
};

AddNoteContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  userId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  purchaseOrder: propTypes.purchaseOrder,
  initDataComponent: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { purchaseOrder }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    userId: state.auth.userId,
    projectId: _get(purchaseOrder, "projectId"),
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performCreateRequest,
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNoteContainer);
