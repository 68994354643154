import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";
import styled from "styled-components";

import propTypes from "../../../../../constants/propTypes";
import ActionButtons from "../../../../forms/ActionButtons";
import FormikForm from "../../../../forms/FormikForm";
import FormGrid from "../../../../forms/FormGrid";
import getSubmittalFields from "./getSubmittalFields";

const ButtonsWrapper = styled.div`
  padding: 24px;
`;

const Form = ({
  initialValues,
  specRequirement,
  onSubmit,
  onCancel,
  dataComponentId,
  actionButtonsProps,
  disableSelectFile,
}) => {
  const schema = useMemo(() => {
    const currentSchema = BWModels.loadSchema("SubmittalFile");
    return currentSchema.concat(
      Joi.object().keys({
        file: Joi.any().required(),
      })
    );
  }, []);

  const values = useMemo(
    () => ({ ...initialValues, specRequirementId: specRequirement.id }),
    [initialValues, specRequirement.id]
  );

  return (
    <FormikForm
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={getSubmittalFields(disableSelectFile)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ButtonsWrapper>
            <ActionButtons
              onSend={handleSubmit}
              onCancel={onCancel}
              listeners={dataComponentId ? [dataComponentId] : undefined}
              {...actionButtonsProps}
            />
          </ButtonsWrapper>
        </Fragment>
      )}
    </FormikForm>
  );
};

Form.defaultProps = {
  initialValues: {
    receivedDescription: undefined,
    approvedDescription: undefined,
    createdAt: Date.now(),
    file: undefined,
  },
  disableSelectFile: false,
};

Form.propTypes = {
  initialValues: propTypes.submittalFile,
  specRequirement: propTypes.specRequirement,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string,
  actionButtonsProps: PropTypes.shape({}),
  disableSelectFile: PropTypes.bool,
};
export default Form;
