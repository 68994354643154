import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ClientContactsPage from "./ClientContactsPage";
import PropTypes from "prop-types";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";
import { resendAccessEmail } from "../../../actions/contactActions";
import contactConstants from "../../../constants/Contact";
import propTypes from "../../../constants/propTypes";

const dataComponentId = "ClientContactsGrid";

export class ClientContactsContainer extends Component {
  state = {
    customInfo: {
      client: {},
    },
  };

  componentDidMount() {
    const { client } = this.props;

    this.setState({ customInfo: { client } });
  }

  handleAddContact = () => {
    const { client, openModalDialog } = this.props;

    openModalDialog(
      "Create Contact",
      "CreateClientContact",
      {
        clientId: client.id,
      },
      true
    );
  };

  handleEditContact = ({ id }) => {
    const { openModalDialog } = this.props;
    const { customInfo } = this.state;

    openModalDialog(
      "Edit Contact",
      "EditClientContact",
      {
        clientContactId: id,
        ...customInfo,
      },
      true
    );
  };

  handleDeleteContact = ({ id, contact }) => {
    const { openModalDialog } = this.props;
    const { firstName, lastName } = contact;
    const title = `${firstName} ${lastName}`;

    openModalDialog(
      `Delete ${title}`,
      "DeleteClientContact",
      {
        title,
        clientContactId: id,
      },
      true,
      false
    );
  };

  handleResendAccessEmail = ({ contact }) => {
    this.props.resendAccessEmail(contact, "Client");
  };

  render() {
    const { clientId, dataComponent } = this.props;

    return (
      <ClientContactsPage
        clientId={clientId}
        dataComponent={dataComponent}
        dataComponentId={dataComponentId}
        customInfo={this.state.customInfo}
        statusMap={contactConstants.statusMap}
        handleAddContact={this.handleAddContact}
        handleEditContact={this.handleEditContact}
        handleDeleteContact={this.handleDeleteContact}
        handleResendAccessEmail={this.handleResendAccessEmail}
      />
    );
  }
}

ClientContactsContainer.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  client: propTypes.clientContact,
  clientId: PropTypes.string,
  isModalOpen: PropTypes.bool,
  openModalDialog: PropTypes.func,
  resendAccessEmail: PropTypes.func,
  refreshContactsTable: PropTypes.func,
};

export const mapStateToProps = (state, ownProps) => ({
  clientId: ownProps.match.params.clientId,
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

const mapDispatchToProps = {
  openModalDialog,
  resendAccessEmail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientContactsContainer)
);
