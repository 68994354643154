import { inputs } from "../../../inputs/inputConfigs";
import { filterByStartsWith } from "../../../inputs/inputConfigs/purchaseOrderDetails";

export default () => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.vendorId,
              filterOption: filterByStartsWith,
              required: true,
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
