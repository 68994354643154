import styled from "styled-components";
import { Button } from "@material-ui/core";

export const StyledActionsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  color: rgba(0, 0, 0, 0.54);
  padding-left: 0;
`;

export const ButtonText = styled.span`
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  padding: 0 8px 0 8px;
`;

export const SubmittalsNeeded = styled.span`
  color: rgb(255, 23, 68);
`;

export const SubmittalsReceived = styled.span`
  color: rgb(114, 179, 39);
`;

export const WatchPurchaseOrderStatus = styled.div`
  margin-right: -8px;
`;
