import React from "react";
import PropTypes, { arrayOf, shape } from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";

import propTypes from "../../../../../../constants/propTypes";
import COMPreview from "./COMPreview";
import Details from "../../DetailSection/Details";
import { buildTopDetailsSpecDetails } from "../../DetailSection/TopDetails";

import {
  TopDetailWrapper,
  SubHeader,
  SerifTitle,
} from "../../DetailSection/components";
import Loader, { LoaderContext } from "../../../../../ui/Loader";

const DetailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TopDetails = ({ itemSelected, loading }) => {
  let { specDetails = [] } = { ...itemSelected };

  const specDetailsModified = buildTopDetailsSpecDetails(specDetails);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <TopDetailWrapper>
        <SubHeader>
          <Loader width="96px" inverted={true}>
            New COM
          </Loader>
        </SubHeader>
        <SerifTitle>
          <Loader width="240px" inverted={true}>
            {_get(itemSelected, "description", "")}
          </Loader>
        </SerifTitle>
        <DetailsContainer>
          <COMPreview
            specDetails={specDetailsModified}
            height="121px"
            width="121px"
          />
          <Details
            specDetails={specDetailsModified}
            categoryName={_get(itemSelected, "specCategory.name")}
            loading={loading}
          />
        </DetailsContainer>
      </TopDetailWrapper>
    </LoaderContext.Provider>
  );
};

TopDetails.propTypes = {
  loading: PropTypes.bool,
  itemSelected: shape({
    id: PropTypes.string,
    specDetails: arrayOf(propTypes.specDetail),
  }),
};

export default React.memo(TopDetails);
