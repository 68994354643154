import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { ActionButtons } from "../../../../forms";
import FormGrid from "../../../../forms/FormGrid";

export const NoteForm = ({
  onSubmit,
  sendButtonText,
  fields,
  formikProps,
  trackingType,
  onCancel,
}) => {
  const setFieldValue = formikProps.setFieldValue;
  useEffect(() => {
    setFieldValue("trackingType", trackingType);
  }, [setFieldValue, trackingType]);

  return (
    <Fragment>
      <FormGrid
        fields={fields}
        values={formikProps.values}
        errors={formikProps.errors}
        {...formikProps}
      />
      <ActionButtons
        isModal
        onSend={onSubmit}
        sendButtonText={sendButtonText}
        onCancel={onCancel}
      />
    </Fragment>
  );
};

NoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sendButtonText: PropTypes.string,
  fields: PropTypes.shape({}).isRequired,
  shipmentNumber: PropTypes.string,
  trackingType: PropTypes.string,
  onCancel: PropTypes.func,
};

export default NoteForm;
