import PropTypes from "prop-types";

import propTypes from "../../../constants/propTypes";

export const LocationCombined = ({ location }) => {
  const parts = ["address", "address2", "city", "state"];
  const addr = parts
    .reduce(
      (line, part) => (location[part] ? [...line, location[part]] : line),
      []
    )
    .join(", ");
  const addrWithZip = location.zip ? `${addr} ${location.zip}` : addr;

  return location.country ? `${addrWithZip}, ${location.country}` : addrWithZip;
};

LocationCombined.defaultProps = { location: {} };

LocationCombined.propTypes = {
  location: propTypes.location,
  ignoreZip: PropTypes.bool,
};

export default LocationCombined;
