import React, { Fragment, useEffect, useMemo } from "react";
import shallow from "zustand/shallow";

import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as DATA_COMPONENTS from "../../../../../constants/DataComponents";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import { addressBookType } from "../../../VendorContacts/VendorContactsContainer";
import useActions from "../../../../hooks/useActions";
import {
  addressBookContactsDataComponentId,
  initAddressBookDatacomponent,
  retrieveAddressVendorBookContacts,
} from "../../../../../actions/addressBookActions";
import { setModalDialogTitle } from "../../../../../actions/layoutActions";
import PropTypes from "prop-types";
import useCreateRemitAddressStore from "../../../../hooks/useCreateRemitAddressStore";
import { GroupWrapper } from "../../../VendorContacts/CreateVendorContact/SelectFromAddressBookForm";
import SelectContactRow from "../../../VendorContacts/CreateVendorContact/SelectFromAddressBookForm/SelectContactRow";
import StatusRow from "../../../VendorContacts/CreateVendorContact/SelectFromAddressBookForm/StatusRow";
import CheckboxRow from "../../../VendorContacts/CreateVendorContact/CheckboxRow";
import CommentsRow from "../../../VendorContacts/CreateVendorContact/CommentsRow";
import ActionButtons from "../../../../forms/ActionButtons";

import selectContactInputs from "./inputs";
import { inputs } from "../../../../inputs/inputConfigs";
import FormikForm from "../../../../forms/FormikForm";
import Joi from "joi-browser";

const defaultList = [];

const SelectContactForm = ({ vendorId }) => {
  const actions = useActions({
    initAddressBookDatacomponent,
    retrieveAddressVendorBookContacts,
    setModalDialogTitle,
  });

  const [
    titleModal,
    vendor,
    currentForm,
    stateActions,
  ] = useCreateRemitAddressStore(
    state => [state.titleModal, state.vendor, state.currentForm, state.actions],
    shallow
  );

  useEffect(() => {
    actions.setModalDialogTitle([titleModal, "Create New Contact"]);
  }, [actions, titleModal]);

  const addressBooks = useV2DatacomponentResources(
    DATA_COMPONENTS.ADDRESS_BOOKS_SELECT,
    defaultList,
    RequestTypes.LIST
  );

  const addressBook = addressBooks.find(
    addressBook => addressBook.type === addressBookType
  );

  useEffect(() => {
    actions.initAddressBookDatacomponent();
  }, [actions]);

  useEffect(() => {
    if (addressBook) {
      actions.retrieveAddressVendorBookContacts(addressBook.id, vendorId);
    }
  }, [actions, addressBook, vendorId]);

  const contacts = useV2DatacomponentResources(
    addressBookContactsDataComponentId,
    defaultList,
    RequestTypes.LIST
  );

  const options = useMemo(() => {
    const contactIds = currentForm.pointOfContacts.map(
      ({ contact }) => contact.id
    );
    const filteredContacts = contacts.filter(
      ({ id }) => !contactIds.includes(id)
    );

    filteredContacts.forEach(
      contact => (contact.name = `${contact.firstName} ${contact.lastName}`)
    );

    return filteredContacts;
  }, [contacts, currentForm]);

  const handleSubmit = vendorContact => {
    stateActions.setCurrentForm({
      ...currentForm,
      pointOfContacts: [...currentForm.pointOfContacts, vendorContact],
    });
    stateActions.openRemitAddressForm(true);
  };

  const handleCancel = () => {
    stateActions.openRemitAddressForm(true);
  };

  return (
    <FormikForm
      initialValues={{ vendorId, isActive: true }}
      onSubmit={handleSubmit}
      validationSchema={Joi.object().keys({
        contact: Joi.object()
          .keys({ id: Joi.string().required() })
          .required(),
      })}
      ignoreValidationFieldNames={["vendorId"]}
    >
      {({ handleSubmit, isValid }) => (
        <Fragment>
          <GroupWrapper>
            <SelectContactRow
              input={{
                ...selectContactInputs.contacts,
                options,
              }}
              handleToggleCreateNewContact={stateActions.openCreateContactForm}
            />
            <StatusRow vendor={vendor} />
            <CheckboxRow
              isFirst
              input={inputs.canAccessVendorPortal}
              label={inputs.canAccessVendorPortal.label}
            />
            <CheckboxRow
              input={inputs.contactIsActiveForVendor}
              label={inputs.contactIsActiveForVendor.label}
            />
            <CommentsRow input={selectContactInputs.comments} />
          </GroupWrapper>
          <ActionButtons
            additionalProps={{ send: { disabled: !isValid } }}
            onSend={handleSubmit}
            onCancel={handleCancel}
            isModal
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

SelectContactForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
};

export default SelectContactForm;
