export const FORM_IDS = {
  REMIT_ADDRESS: "REMIT_ADDRESS",
  SELECT_CONTACT: "SELECT_CONTACT",
  CREATE_CONTACT: "CREATE_CONTACT",
};

export const initialState = {
  formId: null,
  titleModal: "",
  vendor: "",
  currentForm: { pointOfContacts: [] },
  hasTransitioned: false,
};
