import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./components";
import SpecFiles from "./SpecFiles";
import VendorInfo from "./VendorInfo";
import propTypes from "../../../../constants/propTypes";
import { isPOChangeDisabled } from "../../PurchaseOrderDetail/utils";
import { LabeledTextContext } from "../../../../withPORevision";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";
import useVendorScopeStore from "../../../hooks/useVendorScopeStore";

const AdditionalInformationSection = ({ vendor, vendorContacts, spec }) => {
  const isProjectClosed = useIsProjectClosed();
  const selectedVendor = useVendorScopeStore(state => state.selectedVendor);
  /**
   * Spec Files should be enabled even if the PO is Issued and Revised (BW-5341)
   */
  const isLabeled =
    (useContext(LabeledTextContext) &&
      !isPOChangeDisabled(spec.purchaseOrder)) ||
    isProjectClosed ||
    (selectedVendor && selectedVendor.id !== vendor?.id);

  return (
    <Wrapper>
      <LabeledTextContext.Provider value={isLabeled}>
        <SpecFiles spec={spec} />
      </LabeledTextContext.Provider>
      <LabeledTextContext.Provider value={isLabeled || !!spec.purchaseOrderId}>
        <VendorInfo
          vendor={vendor}
          spec={spec}
          vendorContacts={vendorContacts}
        />
      </LabeledTextContext.Provider>
    </Wrapper>
  );
};

AdditionalInformationSection.propTypes = {
  vendor: propTypes.vendor,
  spec: propTypes.spec,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
};

export default AdditionalInformationSection;
