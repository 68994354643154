import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";

import Loader from "../Loader";
import DragAndDrop from "./DragAndDrop";
import { PORevisionContext } from "../../../withPORevision";
import { RevisionReferenceContext } from "../../../withPORevision";

const Container = ({ draggableProps, innerRef, ...props }) => (
  <div ref={innerRef} {...props} {...draggableProps} />
);

const Element = styled.div`
  display: flex;
  vertical-align: top;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const Content = styled(({ isDeleted, isCreated, ...props }) => (
  <div {...props} />
))`
  flex-grow: 1;
  display: flex;
  position: relative;
   ${({ isDeleted, isCreated }) =>
     (isDeleted || isCreated) &&
     `
    font-style: italic;
    font-weight: 600;
    * {
      color: #000000!important;
    }
  `}
  &:after {
    ${({ isDeleted, isCreated }) =>
      (isDeleted || isCreated) &&
      `
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    top: 2px;
    left: 16px;
    overflow: visible;
  `}
  ${({ isCreated }) => isCreated && "color: #ff9100;"}
  ${({ isDeleted }) => isDeleted && "color: #ff1744;"}
  }
  ${({ isDeleted }) =>
    isDeleted &&
    `
  > * {
    opacity: 0.4
  }
`}; 
  
`;

const getChildren = children =>
  Array.isArray(children) ? children : [children];

const Item = props => {
  const { showDragAndDrop, index, item, LeftControl } = props;
  const children = getChildren(props.children);
  const { isCreated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);

  if (item && item.__deleted__) {
    return (
      <Loader height="68px">
        <Container>
          <Element>
            {LeftControl && <LeftControl item={item} />}
            {showDragAndDrop && <DragAndDrop isDeleted />}
            {children.map((child, i) => (
              <Content key={i} isDeleted>
                {child}
              </Content>
            ))}
          </Element>
        </Container>
      </Loader>
    );
  }

  return (
    <Loader height="68px">
      <Draggable draggableId={index + ""} index={index}>
        {({ dragHandleProps, ...parentProps }) => {
          return (
            <Container {...parentProps}>
              <Element>
                {LeftControl && <LeftControl item={item} />}
                {showDragAndDrop && <DragAndDrop {...dragHandleProps} />}
                {children.map((child, i) => (
                  <Content
                    key={i}
                    isCreated={isCreated(`${reference}.${item.id}`)}
                  >
                    {child}
                  </Content>
                ))}
              </Element>
            </Container>
          );
        }}
      </Draggable>
    </Loader>
  );
};

Item.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  LeftControl: PropTypes.func,
  index: PropTypes.number.isRequired,
  showDragAndDrop: PropTypes.bool,
  menuIcons: PropTypes.array,
  item: PropTypes.shape({
    __isDeleted__: PropTypes.bool,
  }),
};

export default Item;
