import React from "react";
import styled from "styled-components";
import Chrevron from "@material-ui/icons/ChevronLeft";

export const ExpandedIcon = styled(({ isExpanded, ...props }) => (
  <Chrevron {...props} />
))`
  transition: transform 0.2s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(90deg)" : "rotate(-90deg)"};
`;

export const PanelWrapper = styled.div`
  overflow: auto;
  padding-bottom: 50px;
  flex: 1;
`;

export const GridWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
