import useCreateRemitAddressStore, {
  FORM_IDS,
} from "../../../hooks/useCreateRemitAddressStore";
import RemitAddressForm from "./RemitAddressForm";
import SelectContactForm from "./SelectContactForm";
import React from "react";
import PropTypes from "prop-types";
import CreateContactForm from "./CreateContactForm";

const Form = ({ onSubmit, vendorId, dataComponentId, isUpdate }) => {
  const formId = useCreateRemitAddressStore(state => state.formId);

  switch (formId) {
    case FORM_IDS.REMIT_ADDRESS: {
      return (
        <RemitAddressForm
          dataComponentId={dataComponentId}
          onSubmit={onSubmit}
          isUpdate={isUpdate}
        />
      );
    }
    case FORM_IDS.SELECT_CONTACT: {
      return <SelectContactForm vendorId={vendorId} />;
    }
    case FORM_IDS.CREATE_CONTACT: {
      return <CreateContactForm vendorId={vendorId} />;
    }
    default: {
      return null;
    }
  }
};

Form.propTypes = {
  vendorId: PropTypes.string,
  dataComponentId: PropTypes.string,
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool,
};

export default Form;
