import React from "react";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";

export const StyledReportList = styled.div`
  max-height: 456px;
  overflow: auto;
`;

StyledReportList.displayName = "StyledReportList";

export const Header = styled.div`
  padding: 0 24px 24px;
  background-color: rgb(39, 88, 122);
`;

export const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: rgb(255, 255, 255);
`;

export const StyledHeaderCell = styled.div`
  width: 170px;
  margin-left: 8px;
  color: rgb(255, 255, 255);
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 76px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Category = styled.div`
  font-family: "Open Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px;
`;

export const Name = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;

const ChipWrapper = React.forwardRef(({ loading, ...props }, ref) => (
  <Chip ref={ref} {...props} classes={{ icon: "icon", label: "label" }} />
));

export const StyledReportChip = styled(ChipWrapper)`
  background-color: #57abff;
  &:focus {
    background-color: #57abff;
  }
  &:hover {
    background-color: #67bbff;
  }
  opacity: ${({ loading }) => (loading ? "0.5" : "1")};
  font-size: 14px;
  line-height: 20px;
  & .icon {
    margin-left: 8px;
    font-size: 16px;
  }

  & .icon,
  .label {
    opacity: ${({ loading }) => (loading ? "0" : "1")};
  }
`;

export const ReportChip = React.forwardRef((props, ref) => (
  <StyledReportChip ref={ref} {...props} />
));
