import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import { Cell } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";
import InputWrapper from "../../../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow/DetailComponent/Input";
import AutoSaveManager from "../../../../forms/AutoSaveManager";

export const WrapperDetailRow = ({ children }) => {
  return <tr>{children}</tr>;
};
WrapperDetailRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export const DetailCellComponent = ({ children }) => (
  <React.Fragment>
    <Cell column={{}} colSpan={2} />
    <Cell column={{}} colSpan={8}>
      {children}
    </Cell>
  </React.Fragment>
);

DetailCellComponent.propTypes = {
  children: PropTypes.shape({}),
};

export const EditableDetailRow = ({ row }) => {
  const { comment: initialValue } = row;
  const autoSaveManager = useMemo(() => new AutoSaveManager(), []);
  const [comment, setComment] = useState(initialValue);

  useEffect(() => {
    setComment(initialValue);
  }, [initialValue]);

  const handleChange = (_, value) => {
    autoSaveManager.push({ comment: value });
    setComment(value);
  };

  const performSave = useCallback(
    ({ comment }) => {
      row.comment = comment;
    },
    [row]
  );

  autoSaveManager.setOptions(performSave, ["comment"]);

  const values = useMemo(() => ({ comment }), [comment]);
  return (
    <InputWrapper
      label="Comment:"
      path="comment"
      placeholder="Add Comment"
      values={values}
      onChangeValue={handleChange}
      disabled
    />
  );
};
EditableDetailRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.String,
  }),
};
