import React from "react";
import styled from "styled-components";

import { Editor } from "../Editor";
import { FormGrid } from "../../../../forms";
import { BWActionButton } from "../../../../ui/BWActionButton";
import {
  queryReportDataComponentId,
  testQueryReportDataComponentId,
} from "../../../../../actions/queryReportsActions";

const editorFields = (tables, editorRef, setSelection) => ({
  groups: [
    {
      items: [
        {
          input: {
            InputComponent: Editor,
            label: "SQL Query",
            name: "query",
            required: true,
            fullWidth: true,
            tables,
            editorRef,
            setSelection,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  border: 1px solid #ddd;
  border-right: 0px;
  border-left: 0px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

export const Button = styled(BWActionButton)`
  margin: 0 0 16px 24px;
`;

export const EditorField = ({
  tables,
  editorRef,
  setSelection,
  testQueryReport,
  ...formikProps
}) => {
  return (
    <Wrapper>
      <RightSide>
        <FormGrid
          fields={editorFields(tables, editorRef, setSelection)}
          {...formikProps}
        />
        <Button
          text="Test Query"
          onClick={() => testQueryReport(formikProps.values, formikProps)}
          buttonId={"primary"}
          listeners={[
            testQueryReportDataComponentId,
            queryReportDataComponentId,
          ]}
        />
      </RightSide>
    </Wrapper>
  );
};
