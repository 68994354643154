import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import { setNoteFilters } from "../../../../actions/notesActions";
import { connect } from "react-redux";

const Link = styled.a`
  color: #57abff;
  margin-right: 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
`;

export const handleChangeSearch = (setNoteFilters, type, id) => evt => {
  evt.stopPropagation();
  setNoteFilters({ search: `"${type}#${id}"` });
};

const LinksLine = ({
  purchaseOrders,
  shipments,
  specs,
  invoices,
  setNoteFilters,
}) => {
  return useMemo(() => {
    return [
      ...purchaseOrders.map(purchaseOrder => (
        <Link
          key={`po-${purchaseOrder.id}`}
          onClick={handleChangeSearch(
            setNoteFilters,
            "PO",
            purchaseOrder.number
          )}
        >
          {`PO #${purchaseOrder.number} - ${purchaseOrder?.vendor?.name}`}
        </Link>
      )),
      ...shipments.map(shipment => (
        <Link
          onClick={handleChangeSearch(setNoteFilters, "SHIP", shipment.number)}
          key={`ship-${shipment.id}`}
        >
          {`SHIP #${shipment.number}`}
        </Link>
      )),
      ...specs.map(spec => (
        <Link
          onClick={handleChangeSearch(
            setNoteFilters,
            "SPEC",
            spec.customNumber
          )}
          key={`spec-${spec.customNumber}`}
        >
          {`SPEC #${spec.customNumber}`}
        </Link>
      )),
      ...invoices.map(invoice => (
        <Link
          onClick={handleChangeSearch(setNoteFilters, "INV", invoice.number)}
          key={`inv-${invoice.id}`}
        >
          {`INV #${invoice.number}`}
        </Link>
      )),
    ];
  }, [purchaseOrders, invoices, specs, shipments, setNoteFilters]);
};
LinksLine.defaultProps = {
  purchaseOrders: [],
  shipments: [],
  specs: [],
  invoices: [],
};
LinksLine.propTypes = {
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  shipments: PropTypes.arrayOf(propTypes.shipment),
  specs: PropTypes.arrayOf(propTypes.spec),
  invoices: PropTypes.arrayOf(propTypes.invoice),
  setNoteFilters: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(null, mapDispatchToProps)(LinksLine);
