import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import {
  DetailAndCycle,
  NavigationArea,
  Subtitle,
} from "../../../layout/DetailAndCycle/";
import POStatusTag from "../../../ui/POStatusTag";
import ActionsSection from "./ActionsSection";
import propTypes from "../../../../constants/propTypes";
import { getTopValues } from "./headerFields";
import { pad } from "./ApprovalManager/components";
import { WarningMessage } from "./WarningMessage";
import Loader from "../../../ui/Loader";
import TitleArea from "./TitleArea";

export const getTitleId = purchaseOrder => {
  const version = _get(purchaseOrder, "currentRevision[0].version", 0);
  if (version === 0) return purchaseOrder.number;
  return `${purchaseOrder.number} - CO #${pad(version)}`;
};

const HeaderSection = ({
  purchaseOrder,
  purchaseOrderContacts,
  openSubmittalsManagerModal,
  handleDownloadPDF,
  onOpenNotesModal,
  clientId,
  onOpenApprovalManagerClick,
  openModalDialog,
  closeModalDialog,
  onRevert,
  onCancel,
  nextPOId,
  lastPOId,
  project,
  loading,
  ...formikProps
}) => {
  const location = useLocation();

  const datesToDisplay = useMemo(() => getTopValues({ purchaseOrder }), [
    purchaseOrder,
  ]);

  const baseUrl = `/clients/${clientId}/projects/${purchaseOrder.projectId}`;
  const { url, backText, lastItemRoute, nextItemRoute } = useMemo(() => {
    const { fromPurchasing } = queryString.parse(location.search);

    const navigationOpts = fromPurchasing
      ? {
          url: `${baseUrl}/quoting/po-groups`,
          backText: "BACK TO PURCHASING",
        }
      : {
          url: `${baseUrl}/expediting/purchase-orders`,
          backText: "BACK TO PURCHASE ORDERS",
        };

    if (lastPOId) {
      navigationOpts.lastItemRoute = `${baseUrl}/purchase-orders/${lastPOId}`;
    }

    if (nextPOId) {
      navigationOpts.nextItemRoute = `${baseUrl}/purchase-orders/${nextPOId}`;
    }

    return navigationOpts;
  }, [location.search, baseUrl, lastPOId, nextPOId]);

  const hasContracts = project?.contractFiles?.length > 0;

  return (
    <DetailAndCycle>
      <NavigationArea
        backUrl={url}
        backText={backText}
        onOpenNotesModal={onOpenNotesModal}
        lastItemRoute={lastItemRoute}
        nextItemRoute={nextItemRoute}
        loading={loading}
      />
      <Loader width="300px">
        {!hasContracts && (
          <WarningMessage
            message={"The project does not have contract files yet."}
          />
        )}
      </Loader>
      <TitleArea
        id={getTitleId(purchaseOrder)}
        title={_get(purchaseOrder, "vendor.name")}
        purchaseOrderId={purchaseOrder?.id}
        projectId={purchaseOrder?.projectId}
        baseUrl={baseUrl}
        backText={backText}
        backRoute={url}
      >
        <POStatusTag purchaseOrder={purchaseOrder} />
      </TitleArea>
      <Subtitle details={datesToDisplay} withMargin={false} />

      <ActionsSection
        purchaseOrder={purchaseOrder}
        purchaseOrderContacts={purchaseOrderContacts}
        openSubmittalsManagerModal={openSubmittalsManagerModal}
        handleDownloadPDF={handleDownloadPDF}
        onOpenApprovalManagerClick={onOpenApprovalManagerClick}
        openModalDialog={openModalDialog}
        closeModalDialog={closeModalDialog}
        onRevert={onRevert}
        onCancel={onCancel}
        project={project}
        {...formikProps}
      />
    </DetailAndCycle>
  );
};

HeaderSection.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  openSubmittalsManagerModal: PropTypes.func,
  onOpenApprovalManagerClick: PropTypes.func,
  onOpenNotesModal: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  handleDownloadPDF: PropTypes.func,
  onRevert: PropTypes.func,
  onCancel: PropTypes.func,
  project: propTypes.project,
};

export default HeaderSection;
