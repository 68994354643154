import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
  useState,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../../constants/propTypes";
import { LoaderContext } from "../../../../ui/Loader";
import Grid from "./Grid";
import {
  processUpdateRequestStatus,
  processCreateRequestStatus,
  haveActiveRequests,
  getCreatedId,
} from "../../../../../utils/dataComponentUtils";
import { GridWrapper } from "./components";
import { quotingBidGroupsDCId } from "../../../../../actions/quotingActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import {
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  pushNewIndex,
} from "../../../../../actions/dataComponentActions";
import { showSnackNotificationAction } from "../../../../../actions/layoutActions";
import FormikForm from "../../../../forms/FormikForm";
import withSelectedRows, {
  WithSelectedRowsContext,
} from "../../../../ui/BWGrid/withSelectedRows";
import { WithPaginationContext } from "../../../../../withPagination";

export const dataComponentId = quotingBidGroupsDCId;

export const processSaveRequest = (
  preDataComponent,
  dataComponent,
  { showSnackNotificationAction, pushToRowIndex }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(
        "The Bid Group has been created successfully"
      );
      pushToRowIndex(dataComponentId, [getCreatedId(dataComponent)]);
    },
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("The Bid Group has been saved successfully");
    },
  });
};

export const processRequests = (
  { preDataComponent, dataComponent },
  callbacks
) => {
  processSaveRequest(preDataComponent.current, dataComponent, callbacks);
  preDataComponent.current = dataComponent;
};

export const BidGroupsGridContainer = ({
  projectId,
  bidGroups,
  dataComponent,
  loading,
  performCreateRequest,
  performUpdateRequest,
  showSnackNotificationAction,
  pushToRowIndex,
}) => {
  const preDataComponent = useRef(dataComponent);
  const initialized = useRef(false);
  const [isAdding, setAdding] = useState(false);

  const { setPage } = useContext(WithPaginationContext);

  useEffect(() => {
    if (!initialized.current) {
      setPage(0, "manage-bid-groups");
      initialized.current = true;
    }
  }, [setPage]);

  useEffect(
    () =>
      processRequests(
        { preDataComponent, dataComponent },
        {
          pushToRowIndex,
          showSnackNotificationAction,
        }
      ),
    [
      dataComponent,
      preDataComponent,
      pushToRowIndex,
      showSnackNotificationAction,
    ]
  );
  const { rows } = useContext(WithSelectedRowsContext);

  const initialValues = useMemo(() => ({ bidGroups: rows }), [rows]);
  const loadingObj = useMemo(() => ({ loading }), [loading]);

  const handleSubmit = useCallback(
    ({ bidGroups: newBidGroups }, { initialValues: { bidGroups } }) => {
      const recentBidGroup = newBidGroups.find(bidGroup => {
        return !!bidGroups.find(
          srcBidGroup =>
            srcBidGroup.id === bidGroup.id && srcBidGroup.name !== bidGroup.name
        );
      });
      if (!recentBidGroup) return;
      const { __fixedTop__, ...bidGroup } = recentBidGroup;

      if (bidGroup.id) {
        return performUpdateRequest(dataComponentId, bidGroup.id, {
          name: bidGroup.name,
        });
      }

      setAdding(false);
      return performCreateRequest(dataComponentId, {
        ...bidGroup,
        projectId,
      });
    },
    [performUpdateRequest, performCreateRequest, projectId]
  );

  return (
    <LoaderContext.Provider value={loadingObj}>
      <FormikForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        autoSave
        enableReinitialize
        ignoreCache
        noDelayFields={[/^bidGroups/]}
      >
        {() => (
          <GridWrapper>
            <Grid
              bidGroups={bidGroups}
              isAdding={isAdding}
              setAdding={setAdding}
            />
          </GridWrapper>
        )}
      </FormikForm>
    </LoaderContext.Provider>
  );
};

BidGroupsGridContainer.propTypes = {
  projectId: PropTypes.string,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  dataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  performCreateRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  pushToRowIndex: PropTypes.func.isRequired,
};

BidGroupsGridContainer.defaultProps = {
  bidGroups: [],
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const dataComponent = getDataComponent(dataComponentId, state);
  const bidGroups = getBluechipResources(flattenedDataComponent, state) || [];
  const loading = haveActiveRequests(dataComponent);

  return {
    dataComponent,
    loading,
    bidGroups,
  };
};

export const mapDispatchToProps = {
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  pushToRowIndex: pushNewIndex,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSelectedRows(BidGroupsGridContainer));
