import React, { Fragment } from "react";
import shallow from "zustand/shallow";

import PropTypes from "prop-types";
import useCreateRemitAddressStore from "../../../../hooks/useCreateRemitAddressStore";
import { GroupWrapper } from "../../../VendorContacts/CreateVendorContact/SelectFromAddressBookForm";
import CheckboxRow from "../../../VendorContacts/CreateVendorContact/CheckboxRow";
import CommentsRow from "../../../VendorContacts/CreateVendorContact/CommentsRow";
import ActionButtons from "../../../../forms/ActionButtons";

import selectContactInputs from "../SelectContactForm/inputs";
import { inputs } from "../../../../inputs/inputConfigs";
import FormikForm from "../../../../forms/FormikForm";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";
import createContactFields from "../../../VendorContacts/CreateVendorContact/CreateVendorContactForm/createVendorContactFields";
import FormGrid from "../../../../forms/FormGrid";

const initialValues = {
  contact: {
    firstName: "",
    lastName: "",
    phone: "",
    fax: "",
    email: "",
    jobTitle: "",
    status: "inact",
  },
  canAccessVendorPortal: false,
  isActive: true,
  contactStatus: "",
};

const CreateContactForm = ({ vendorId }) => {
  const [vendor, currentForm, stateActions] = useCreateRemitAddressStore(
    state => [state.vendor, state.currentForm, state.actions],
    shallow
  );

  const handleSubmit = vendorContact => {
    stateActions.setCurrentForm({
      ...currentForm,
      pointOfContacts: [...currentForm.pointOfContacts, vendorContact],
    });
    stateActions.openRemitAddressForm(true);
  };

  const handleCancel = () => {
    stateActions.openRemitAddressForm(true);
  };

  const handleGoAddressBook = () => {
    stateActions.openAddressBookForm();
  };

  return (
    <FormikForm
      initialValues={{ vendorId, ...initialValues }}
      onSubmit={handleSubmit}
      validationSchema={BWModels.loadSchema("VendorContact").concat(
        Joi.object().keys({
          contact: BWModels.loadSchema("Contact"),
        })
      )}
      ignoreValidationFieldNames={["contactId"]}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <GroupWrapper>
            <FormGrid fields={createContactFields(vendor)} />
            <CheckboxRow
              isFirst
              input={inputs.canAccessVendorPortal}
              label={inputs.canAccessVendorPortal.label}
            />
            <CheckboxRow
              input={inputs.contactIsActiveForVendor}
              label={inputs.contactIsActiveForVendor.label}
            />
            <CommentsRow input={selectContactInputs.comments} />
          </GroupWrapper>
          <ActionButtons
            isModal={true}
            onSend={handleSubmit}
            sendButtonText="Create Contact"
            secondaryButtonText="Use Address Book"
            onSecondary={handleGoAddressBook}
            onCancel={handleCancel}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

CreateContactForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
};

export default CreateContactForm;
