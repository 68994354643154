import React from "react";
import styled from "styled-components";
import _get from "lodash/get";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Warning from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

import propTypes from "../../../constants/propTypes";
import * as QUEUE_STATUSES from "../../../constants/queue";

export const SavingIcon = styled(props => (
  <CircularProgress {...props} classes={{ circle: "circle" }} size={16} />
))`
  & .circle {
    color: #ffffff;
  }
`;

export const SavedIcon = styled(CheckCircle)`
  width: 16px;
  height: 16px;
  color: #72b327;
`;

export const ErrorIcon = styled(Warning)`
  width: 16px;
  height: 16px;
  color: #ff9100;
`;

const Message = styled.div`
  margin-left: 4px;
`;

const Wrapper = styled.div`
  margin-right: 24px;
  position: relative;
  left: -60px;
  top: -12px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Fade = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  transition-property: opacity;
  transition-duration: ${props => props.duration};
  transition-delay: ${props => props.delay};
  transition-timing-function: ease-in-out;
  opacity: ${props => (props.show ? "1" : "0")};
`;

const parseQueueStatus = queueStatus => ({
  isQueueLoading: [
    QUEUE_STATUSES.QUEUE_PROCESSING,
    QUEUE_STATUSES.QUEUE_REQUESTNG,
  ].includes(queueStatus),
  isQueueSuccess:
    [QUEUE_STATUSES.QUEUE_SUCCESS].includes(queueStatus) || !queueStatus,
  isQueueError: [QUEUE_STATUSES.QUEUE_ERROR].includes(queueStatus),
});

export const parseProps = (autoSaveRequestState, autoSaveQueueState) => {
  const queueStatus = _get(autoSaveQueueState, "status", false);
  const { isQueueLoading, isQueueSuccess, isQueueError } = parseQueueStatus(
    queueStatus
  );

  const {
    loading: isComponentLoading,
    success: isComponentSuccess,
    error: isComponentError,
  } = _get(autoSaveRequestState, "update", {});

  const loading = isComponentLoading || isQueueLoading;
  const success = isComponentSuccess && isQueueSuccess;
  const error = isComponentError || isQueueError;

  return {
    loading,
    success,
    error,
    duration: loading ? "0" : "0.5s",
    delay: loading ? "0" : "0.5s",
  };
};

const SavedConfirmation = ({ autoSaveRequestState, autoSaveQueueState }) => {
  const { loading, success, error, duration, delay } = parseProps(
    autoSaveRequestState,
    autoSaveQueueState
  );
  if (!loading && !success && !error) return null;
  return (
    <Wrapper>
      <Fade show={loading} duration={duration} delay={delay}>
        <SavingIcon />
        <Message>{"Saving"}</Message>
      </Fade>
      <Fade show={!loading && success} duration={duration} delay={delay}>
        <SavedIcon />
        <Message>{"Saved"}</Message>
      </Fade>
      <Fade show={!loading && error} duration={duration} delay={delay}>
        <ErrorIcon />
        <Message>{"Invalid"}</Message>
      </Fade>
    </Wrapper>
  );
};

SavedConfirmation.propTypes = {
  autoSaveRequestState: propTypes.requestState,
  autoSaveQueueState: PropTypes.shape({}),
};

export default SavedConfirmation;
