import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import useInvoicePayments from "../../../hooks/useInvoicePayments";
import { InvoicePayments } from "./InvoicePayments";
import { CancelCOIssueSummary, Title } from "./CancelCOIssueSummary";
import Loader from "../../../ui/Loader";
import { ActionButtons } from "../../../forms";

const Wrapper = styled.div`
  padding: 24px;
`;

const getCancelIssueError = (changeOrder, invoicePayments) =>
  isEmpty(invoicePayments) ||
  (changeOrder
    ? !isEmpty(invoicePayments.specPaymentsInChangeOrder)
    : !isEmpty(invoicePayments.specPayments));

const Dialog = ({ invoicePayments, changeOrder, cancelIssueError }) => {
  if (cancelIssueError) {
    return (
      <InvoicePayments
        invoicePayments={invoicePayments}
        changeOrder={changeOrder}
      />
    );
  }

  return changeOrder ? (
    <CancelCOIssueSummary invoicePayments={invoicePayments} />
  ) : (
    <Title>Are you sure you want to Cancel Purchase Order Issue?</Title>
  );
};
Dialog.propTypes = {
  invoicePayments: PropTypes.shape({}),
  changeOrder: PropTypes.bool,
  cancelIssueError: PropTypes.bool,
};

const CancelIssueDialog = ({ purchaseOrderId, changeOrder, onSubmit }) => {
  const { invoicePayments, loading } = useInvoicePayments(purchaseOrderId);
  const cancelIssueError = getCancelIssueError(changeOrder, invoicePayments);

  return (
    <React.Fragment>
      <Wrapper>
        <Loader loading={loading} count={10}>
          <Dialog
            cancelIssueError={cancelIssueError}
            invoicePayments={invoicePayments}
            changeOrder={changeOrder}
          />
        </Loader>
      </Wrapper>
      <ActionButtons
        onSend={onSubmit}
        isModal={true}
        sendButtonText="Confirm Cancellation"
        cancelButtonText="Back"
        additionalProps={{
          send: { isLoading: loading, disabled: cancelIssueError },
          cancel: { loading },
        }}
      />
    </React.Fragment>
  );
};
CancelIssueDialog.propTypes = {
  purchaseOrderId: PropTypes.string,
  changeOrder: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
export default CancelIssueDialog;
