import * as actions from "../constants/ActionTypes";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
} from "./dataComponentActions";
import { openModalDialog } from "./layoutActions";
import Project from "../models/Project";
import { dataComponentIds } from "../components/pages/Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";

export const notesHeaderDatacomponentId = "NotesHeader";

export const openNotesModal = (
  initialContext,
  initialFilters,
  onCloseModal
) => {
  return dispatch => {
    dispatch(initNotesModal(initialContext, initialFilters));
    dispatch(
      openModalDialog(
        false,
        "Notes",
        {
          onCloseModal: onCloseModal,
        },
        false,
        false,
        {
          layoutType: "wide",
        }
      )
    );
  };
};

export function fetchAssignToRelations(purchaseOrderIds) {
  return async dispatch => {
    dispatch(fetchRelationsRequest());

    const promises = [];
    promises.push(
      dispatch(
        performRetrieveListRequest(dataComponentIds.shipmentComponent, {
          pageSize: -1,
          rootFilters: {
            $where: {
              "spec.purchaseOrderId": {
                $in: purchaseOrderIds,
              },
            },
          },
          sort: [{ columnName: "number", direction: "asc" }],
        })
      )
    );
    promises.push(
      dispatch(
        performRetrieveListRequest(dataComponentIds.specComponent, {
          pageSize: -1,
          rootFilters: {
            $where: {
              purchaseOrderId: {
                $in: purchaseOrderIds,
              },
            },
          },
          sort: [{ columnName: "customNumber", direction: "asc" }],
        })
      )
    );
    promises.push(
      dispatch(
        performRetrieveListRequest(dataComponentIds.invoiceComponent, {
          pageSize: -1,
          rootFilters: {
            $where: {
              purchaseOrderId: {
                $in: purchaseOrderIds,
              },
            },
          },
          sort: [{ columnName: "number", direction: "asc" }],
          params: { omitTotals: true },
        })
      )
    );

    await Promise.all(promises);

    dispatch(fetchRelationsSuccess());
  };
}

export function fetchRelationsRequest() {
  return {
    type: actions.NOTE_FETCH_RELATIONS_REQUEST,
  };
}

export function fetchRelationsSuccess() {
  return {
    type: actions.NOTE_FETCH_RELATIONS_SUCCESS,
  };
}

export const initNotesModal = (initialContext, initialFilters) => ({
  type: actions.NOTE_INIT,
  payload: { context: initialContext, filters: initialFilters },
});

export const clearNotes = () => ({
  type: actions.NOTE_CLEAR,
});

export const setNoteFilters = filters => ({
  type: actions.NOTE_SET_FILTERS,
  payload: {
    filters,
  },
});

export const noteCreateStart = () => ({
  type: actions.NOTE_CREATE_START,
});

export const noteCreateDetailClose = () => ({
  type: actions.NOTE_CREATE_DETAIL_CLOSE,
});

export const noteDetailOpen = noteId => ({
  type: actions.NOTE_DETAIL_OPEN,
  payload: { noteId },
});

export const initHeaderDatacomponent = context => {
  return dispatch => {
    if (!context) return;
    if (context.projectId) {
      dispatch(
        initDataComponent(
          notesHeaderDatacomponentId,
          Project,
          ["property.entity.client"],
          "projects"
        )
      );
      dispatch(
        performFindRequest(notesHeaderDatacomponentId, context.projectId)
      );
    }
  };
};
