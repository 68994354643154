import React, { Fragment } from "react";
import _get from "lodash/get";
import _startCase from "lodash/startCase";

import Price from "../../../../ui/Price";
import { currencyToNumber } from "../../../../inputs/utils";

export const getInvoiceCurrency = invoice =>
  invoice.purchaseOrder?.projectCurrency?.currency;

export const roundInvoiceVal = value => {
  return Math.round(value * 100) / 100;
};

function getEditedField(row, obj) {
  const { field } = row;
  if (typeof obj[`${field}DepositsPaid`] === "undefined") {
    return `${field}AssetsPaid`;
  }
  return `${field}DepositsPaid`;
}

function genValidatorResult(isInvalid, message) {
  if (isInvalid) {
    return {
      valid: false,
      helperText: message,
    };
  }
  return {
    valid: true,
    helperText: " ",
  };
}

const getLimits = (field, prevValue, prevNotProcessed, invoice) => {
  if (prevNotProcessed < 0) {
    return { min: prevNotProcessed, max: 0 };
  }
  const min = getMinForPositiveNotProcessed(field, invoice, prevValue);
  return { min, max: prevNotProcessed };
};

/* eslint-disable complexity */
export const getMinForPositiveNotProcessed = (field, invoice, prevValue) => {
  let min = 0;
  const assetsPaid = invoice[`${field}AssetsPaid`];
  const depositsToDate = invoice[`${field}DepositsToDate`];
  if (assetsPaid !== 0 && depositsToDate !== 0) {
    min = -1 * Math.min(assetsPaid, depositsToDate) + prevValue;
  } else if (assetsPaid === 0 && depositsToDate !== 0) {
    min = -1 * depositsToDate + prevValue;
  } else if (assetsPaid !== 0 && depositsToDate === 0) {
    min = -1 * assetsPaid + prevValue;
  }
  return min;
};
/* eslint-enable complexity */

export const validatorFactory = field => (
  invoice,
  obj,
  row,
  originalInvoice
) => {
  const { notProcessed } = row;
  const editedField = getEditedField(row, obj);
  const prevValue = originalInvoice[editedField];
  const prevNotProcessed = roundInvoiceVal(notProcessed + prevValue);
  const value = currencyToNumber(obj[editedField]);
  const { min, max } = getLimits(field, prevValue, prevNotProcessed, invoice);

  return genValidatorResult(
    value !== 0 && !(value >= min && value <= max),
    <Fragment>
      {_startCase(field)} amount must be between{" "}
      <Price number={min} currency={getInvoiceCurrency(invoice)} /> and{" "}
      <Price number={max} currency={getInvoiceCurrency(invoice)} />
    </Fragment>
  );
};

export function freightWarehousingValidator(
  invoice,
  obj,
  row,
  originalInvoice
) {
  const { freightWarehousingTotal } = invoice;

  const freightAdditional = 175;

  const freightWarehousingAssetsToDate =
    invoice.freightWarehousingAssetsToDate -
    originalInvoice.freightWarehousingAssetsPaid +
    invoice.freightWarehousingAssetsPaid;
  const freightWarehousingDepositsToDate =
    invoice.freightWarehousingDepositsToDate -
    originalInvoice.freightWarehousingDepositsPaid +
    invoice.freightWarehousingDepositsPaid;

  const notProcessed =
    freightWarehousingTotal -
    freightWarehousingAssetsToDate -
    freightWarehousingDepositsToDate;

  const editedField = getEditedField(row, obj);
  const prevValue = invoice[editedField];
  const prevNotProcessed = roundInvoiceVal(
    notProcessed + freightAdditional + prevValue
  );
  return genValidatorResult(
    prevNotProcessed - obj[editedField] < 0,
    <Fragment>
      Freight/Warehousing amount can not exceed{" "}
      <Price number={prevNotProcessed} currency={getInvoiceCurrency(invoice)} />
    </Fragment>
  );
}

function isEmptyTax(invoice, obj) {
  return !invoice.purchaseOrder || invoice.taxAssetsPaid === obj.taxAssetsPaid;
}

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
function taxValidator(invoice, obj) {
  if (isEmptyTax(invoice, obj)) {
    return {
      valid: true,
      helperText: " ",
    };
  }

  const column = obj["taxDepositsPaid"] ? "Deposits" : "Assets";

  const taxableColumns = ["merchandise", "overage", "otherCost"];

  const project = _get(invoice, "purchaseOrder.project");
  const isFreightTaxable = _get(project, "isFreightTaxable");
  const isInstallTaxable = _get(project, "isInstallTaxable");

  if (isFreightTaxable) {
    taxableColumns.push("freightWarehousing");
  }
  if (isInstallTaxable) {
    taxableColumns.push("install");
  }

  // If okToIssue then the paid quantity is being accounted in the ToDate property
  const currentTotalPaid = invoice.okToIssue
    ? 0
    : taxableColumns.reduce(
        (sum, row) => sum + invoice[`${row}${column}Paid`],
        0
      );

  const total = taxableColumns.reduce(
    (sum, row) => sum + invoice[`${row}${column}ToDate`],
    currentTotalPaid
  );

  const inputValue = _get(obj, `tax${column}Paid`, invoice[`tax${column}Paid`]);

  const salesTaxTolerancePercent = _get(project, "salesTaxTolerancePercent", 0);
  const salesTaxPercent = _get(project, "salesTaxPercent", 0);

  const totalTaxPaid = invoice.okToIssue
    ? invoice[`tax${column}ToDate`] - invoice[`tax${column}Paid`]
    : invoice[`tax${column}ToDate`];

  const maxTaxPaid =
    total * (salesTaxTolerancePercent || salesTaxPercent) - totalTaxPaid;

  return genValidatorResult(
    inputValue > Number((Math.ceil(maxTaxPaid * 100) / 100).toFixed(2)),
    <Fragment>
      Tax {column} exceeds max value{" "}
      <Price number={maxTaxPaid} currency={getInvoiceCurrency(invoice)} />
    </Fragment>
  );
}

function discountValidator(invoice, obj, row) {
  const { assetsToDate, depositsToDate } = row;
  const editedField = getEditedField(row, obj);
  const prevValue = invoice[editedField];
  const prevNotProcessed = assetsToDate + depositsToDate - prevValue;
  return genValidatorResult(
    prevNotProcessed + obj[editedField] > 1,
    <Fragment>
      Discount amount can not exceed{" "}
      <Price
        number={1 - prevNotProcessed}
        currency={getInvoiceCurrency(invoice)}
      />
    </Fragment>
  );
}

function defaultValidator() {
  return {
    valid: true,
    helperText: " ",
  };
}

export const validators = {
  merchandise: validatorFactory("merchandise"),
  overage: validatorFactory("overage"),
  tax: taxValidator,
  freightWarehousing: freightWarehousingValidator,
  discount: discountValidator,
  otherCost: validatorFactory("otherCost"),
  install: validatorFactory("install"),
};

export default function(invoice, originalInvoice) {
  return (obj, row) => {
    const { field } = row;
    const validator = validators[field] || defaultValidator;
    return validator(invoice, obj, row, originalInvoice);
  };
}
