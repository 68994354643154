import React, { useContext, useMemo } from "react";
import _keyBy from "lodash/keyBy";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";

import SpecDetail from "../SpecDetail";
import DeleteButton from "../DeleteButton";
import ItemCheckbox from "./ItemCheckbox";
import Menu, { StyledIconButton } from "../../../ui/DragAndDropList/Menu";
import { Body as DnDBody } from "../../../ui/DragAndDropList";
import {
  withDeletedItems,
  withRevisionReference,
} from "../../../../withPORevision";
import { DnDItemsWrapper, DnDItemWrapper } from "./components";
import propTypes from "../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../withPORevision";

const filterDetails = (specDetails, sequence, deletedItems) => {
  const specDetailsById = _keyBy(specDetails, "id");
  deletedItems.forEach(deletedItem => delete specDetailsById[deletedItem.id]);

  return sequence
    .filter(id => !!specDetailsById[id])
    .map(id => specDetailsById[id])
    .concat(deletedItems);
};

const hideMenu = (labeledText, isDeleted, type) =>
  isDeleted || labeledText || type === "COM";

const Body = ({
  specDetails,
  idSequence,
  purchaseOrder,
  onReorder,
  handleOnClickMenu,
  rows: deletedItems,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isLabeled = useContext(LabeledTextContext);

  const items = useMemo(() => {
    return filterDetails(
      Object.values(specDetails) || [],
      idSequence || [],
      deletedItems
    );
  }, [specDetails, idSequence, deletedItems]);

  const specDetailToDisplay = items.map(specDetail => {
    const index = idSequence.indexOf(specDetail.id);
    return (
      <DnDItemWrapper key={index}>
        <SpecDetail
          specDetail={specDetail}
          purchaseOrder={purchaseOrder}
          index={index}
        />
        {!hideMenu(isLabeled, specDetail.__deleted__, specDetail.type) && (
          <Menu>
            <StyledIconButton onClick={() => handleOnClickMenu(specDetail)}>
              <Edit />
            </StyledIconButton>
            {specDetail.locked ? null : (
              <DeleteButton
                specDetail={specDetail}
                purchaseOrderId={purchaseOrder && purchaseOrder.id}
              />
            )}
          </Menu>
        )}
      </DnDItemWrapper>
    );
  });

  if (isLabeled) {
    return <DnDItemsWrapper>{specDetailToDisplay}</DnDItemsWrapper>;
  }

  return (
    <DnDBody
      showDragAndDrop
      onReorder={onReorder}
      items={items}
      LeftControl={ItemCheckbox}
    >
      {specDetailToDisplay}
    </DnDBody>
  );
};

Body.propTypes = {
  specDetails: PropTypes.arrayOf(propTypes.specDetail),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  idSequence: PropTypes.arrayOf(PropTypes.string),
  onReorder: PropTypes.func.isRequired,
  handleOnClickMenu: PropTypes.func.isRequired,
  purchaseOrder: propTypes.purchaseOrder,
};

Body.defaultProps = {
  specDetails: [],
  rows: [],
  idSequence: [],
};

export default withRevisionReference(withDeletedItems(Body));
