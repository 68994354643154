import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ArrowForward from "@material-ui/icons/ArrowForward";
import propTypes from "../../../../../constants/propTypes";
import { RelatedSpecsWrapper, Text, BoldText } from "./components";
import Subheader from "../../../../ui/Typography/Subheader";
import Loader, { LoaderContext } from "../../../../ui/Loader";
import EmptyState from "../../../../ui/EmptyState";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled.div`
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  margin: 7px 0;
  padding-top: 5px;
  text-decoration: none;
  cursor: pointer;
  svg {
    opacity: 0;
  }
  &:hover {
    ${BoldText}, ${Text} {
      color: rgba(0, 0, 0, 0.87);
    }
    svg {
      opacity: 1;
    }
  }
  ${BoldText}, ${Text} {
    color: rgba(0, 0, 0, 0.54);
    margin: 0 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${BoldText} {
    margin-left: 0px;
    width: 54px;
  }
  ${Text} {
    text-overflow: ellipsis;
    &:nth-child(2) {
      width: 169px;
    }
    &:nth-child(3) {
      width: 86px;
    }
    &:nth-child(4) {
      width: 81px;
    }
  }
`;

const RelatedSpecs = ({ specs, clientId, loading, handleClickRelatedSpec }) => {
  let specsToRender = specs;
  if (loading) {
    specsToRender = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }];
  } else if (!specsToRender || !specsToRender.length) {
    return <EmptyState />;
  }

  return (
    <LoaderContext.Provider value={{ loading }}>
      <RelatedSpecsWrapper>
        <Subheader>Related Specs</Subheader>
        <ListWrapper>
          <List>
            {specsToRender.map((spec, key) => (
              <Row
                key={key}
                onClick={() =>
                  handleClickRelatedSpec(
                    `/clients/${clientId}/projects/${spec.projectId}/specs/${spec.id}`
                  )
                }
              >
                <BoldText>
                  <Loader>{spec.customNumber}</Loader>
                </BoldText>
                <Text>
                  <Loader>{spec.description}</Loader>
                </Text>
                <Text>
                  <Loader>{spec.category}</Loader>
                </Text>
                <Text>
                  <Loader>{spec.area}</Loader>
                </Text>
                {loading ? null : <ArrowForward />}
              </Row>
            ))}
          </List>
        </ListWrapper>
      </RelatedSpecsWrapper>
    </LoaderContext.Provider>
  );
};

RelatedSpecs.propTypes = {
  clientId: PropTypes.string,
  loading: PropTypes.bool,
  handleClickRelatedSpec: PropTypes.func,
  specs: PropTypes.arrayOf(propTypes.relatedSpec),
};

export default React.memo(RelatedSpecs);
