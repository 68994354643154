import React, { useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FieldArray } from "formik";

import { openModalDialog } from "../../../../../actions/layoutActions";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { findItemInResources } from "../../../../../utils/bluechipUtils";
import propTypes from "../../../../../constants/propTypes";

import RenderedField from "./RenderedField";

const propertiesDatacomponentId = "select-properties";

const name = "addresses";

export const ProjectAddressGridContainer = ({
  values,
  openModalDialog,
  selectedProperty,
}) => {
  const originalRows = values[name];
  const rows = useMemo(() => {
    const rows = [...originalRows];
    if (selectedProperty) {
      rows.unshift({
        ...selectedProperty,
        name: `${selectedProperty.name} (Property Address)`,
        isSelectedProperty: true,
      });
    }
    return rows;
  }, [originalRows, selectedProperty]);

  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => (
        <RenderedField
          formikArrayHelpers={formikArrayHelpers}
          selectedProperty={selectedProperty}
          rows={rows}
          projectId={values.id}
          openModalDialog={openModalDialog}
        />
      )}
    />
  );
};

ProjectAddressGridContainer.propTypes = {
  values: PropTypes.shape({}),
  openModalDialog: PropTypes.func.isRequired,
  selectedProperty: propTypes.property,
};

export const mapStateToProps = (state, { values }) => {
  const datacomponent = getDataComponentFlattenedRequestState(
    propertiesDatacomponentId,
    state
  );

  const selectedProperty = findItemInResources(
    datacomponent,
    state,
    values.propertyId
  );
  return {
    selectedProperty,
  };
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAddressGridContainer);
