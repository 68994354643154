import {
  initDataComponent,
  performRetrieveListRequest,
  performFindRequest,
} from "../actions/dataComponentActions";
import { pushWithReturnUrl } from "../actions/navigationActions";
import Area from "../models/Area";

export const areaDataComponentId = "select-areas";
export const areaDetailDataComponentId = "AreaDetail";

export function fetchAreasByProject(
  projectId,
  dataComponentId = areaDataComponentId
) {
  return async dispatch => {
    dispatch(
      initDataComponent(dataComponentId, Area, [], "areas", undefined, "v2")
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
}

export function navigateToAreaDetailPage(clientId, projectId, areaId) {
  const sectionNameMap = { areas: "Areas", settings: "Project Settings" };
  const identifier = location.pathname.replace(/^.*\/(.*)$/, "$1");
  return pushWithReturnUrl(
    `/clients/${clientId}/projects/${projectId}/areas/${areaId}`,
    sectionNameMap[identifier]
  );
}

export function initAreaDetails(projectId, areaId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        areaDetailDataComponentId,
        Area,
        ["areaType", "areaRooms"],
        "areas",
        true,
        "v2"
      )
    );
    dispatch(
      performFindRequest(areaDetailDataComponentId, areaId, {
        $where: { projectId },
      })
    );
  };
}
