import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import { openModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import TeamsPage from "./TeamsPage";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

export const dataComponentId = "TeamsGrid";
export const TeamsContainer = ({ openModalDialog, dataComponent }) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create Team", "CreateTeam", {}, false);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    team => {
      openModalDialog(
        `Edit ${team.name}`,
        "EditTeam",
        {
          team,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <TeamsPage
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      dataComponent={dataComponent}
    />
  );
};

TeamsContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  performRetrieveListRequest,
  initDataComponent,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(TeamsContainer),
  ["manage-teams"]
);
