import React, { Fragment } from "react";
import { InfoRounded } from "@material-ui/icons";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";
import { isEmpty } from "lodash";

import FormGrid from "../../../forms/FormGrid";
import ActionButtons from "../../../forms/ActionButtons";
import FormikForm from "../../../forms/FormikForm";
import ProfilePictureSection from "./ProfilePictureSection";
import { inputs } from "../../../inputs/inputConfigs";
import TooltipIcon from "../../../mui/core/TooltipIcon";
import VerifyCellPhoneNumber from "../VerifyCellPhoneNumber";
import { updateUserDataComponentId } from "./AccountSettings";

const cellPhoneLabel = (
  <div>
    <span>Cell phone</span>
    <TooltipIcon
      Icon={InfoRounded}
      title={
        "Please add your cell phone number with the international code and without any spaces/brackets/dashes to receive 2FA codes and prevent losing access to the system"
      }
    />
  </div>
);

const getFields = forceVerify => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.cellNumber,
            helperText: "Include International and Area Codes",
            autoFocus: true,
            InputLabelProps: {
              shrink: true,
            },
            label: cellPhoneLabel,
            avoidDebounce: true,
          },
          grid: { xs: forceVerify ? 12 : 6 },
        },
        {
          element: <VerifyCellPhoneNumber login={forceVerify} />,
          grid: { xs: forceVerify ? 12 : 6 },
        },
      ],
    },
  ],
});

export const JoiInstance = Joi.extend(joi => ({
  base: joi
    .string()
    .regex(/^\+\d*$/)
    .optional()
    .allow(null),
  name: "cellNumber",
  coerce(value, state, options) {
    if (!isEmpty(value) && !/^\+\d*$/.test(value)) {
      return this.createError("phone.regex.base", {}, state, options);
    }
    return value;
  },
}));

const isCellNumberOutdated = (values, userData) =>
  values.cellNumber !== userData.cellNumber;

const isDisabled = (isCellNumberVerified, values, userData) =>
  (!isCellNumberVerified && !isEmpty(values.cellNumber)) ||
  (values.cellNumber !== null && isCellNumberOutdated(values, userData));

export const UserDetailsForm = ({
  userData,
  isCellNumberVerified,
  forceVerify,
  actions,
}) => {
  const userSchema = BWModels.loadSchema("User").concat(
    Joi.object().keys({
      cellNumber: JoiInstance.cellNumber(),
    })
  );

  const handleSubmit = values => {
    const isCellNumberVerified = isCellNumberOutdated(values, userData)
      ? false
      : undefined;

    actions.updateUser(values.id, {
      cellNumber: values.cellNumber,
      isCellNumberVerified,
    });
  };

  const handleOpenSettings = () => {
    actions.openModalDialog(
      ["Edit Profile", userData.name, userData.email],
      "AccountPictureSettings",
      {},
      false,
      false,
      { isShowCloseIcon: true }
    );
  };

  if (!userData.id) {
    actions.logout();
    actions.push("/");
    return null;
  }

  return (
    <FormikForm
      initialValues={userData}
      onSubmit={handleSubmit}
      validationSchema={userSchema}
      ignoreValidationFieldNames={[
        "officeId",
        "firstName",
        "lastName",
        "email",
        "userRoleId",
      ]}
      isPatch
      enableReinitialize
      initialStatus={"submitted"}
      validateOnChange
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <ProfilePictureSection
            user={userData}
            onOpenEditPicture={handleOpenSettings}
          />
          <FormGrid
            fields={getFields(forceVerify)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          {forceVerify ? null : (
            <ActionButtons
              disabled={isDisabled(isCellNumberVerified, values, userData)}
              onSend={handleSubmit}
              onDanger={null}
              dangerButtonText="DELETE USER"
              listeners={[updateUserDataComponentId]}
              isModal
            />
          )}
        </Fragment>
      )}
    </FormikForm>
  );
};
