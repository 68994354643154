import { useHasRequestActive } from "../../hocs/withRequestListener";
import React from "react";
import { ActionButton, StyledCircularProgress, Child } from "./components";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";

const PrimaryActionButton = ({
  children,
  isLoading,
  loadingIdentifier,
  disableIfProjectClosed,
  ...props
}) => {
  const loading = useHasRequestActive(loadingIdentifier, isLoading);

  const isProjectClosed = useIsProjectClosed();

  const disabled =
    props.disabled || (isProjectClosed && disableIfProjectClosed);

  return (
    <ActionButton
      variant="contained"
      color="primary"
      {...props}
      loading={loading}
      disabled={disabled}
    >
      <Child loading={loading}>{children}</Child>
      {loading && <StyledCircularProgress size={20} />}
    </ActionButton>
  );
};

PrimaryActionButton.defaultProps = {
  loadingIdentifier: "primary",
};

export default PrimaryActionButton;
