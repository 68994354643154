import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _pick from "lodash/pick";
import _get from "lodash/get";

import EditableTextDetailRow from "./EditableTextDetailRow";
import FormikForm from "../../../../forms/FormikForm";
import { createDeepEqualSelector } from "../../../../../utils/selectors";
import {
  isPendingRequest,
  isFailedRequest,
  isSuccessfulRequest,
} from "../../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import {
  performUpdateRequest,
  cloneDataComponentOverwriteProp,
} from "../../../../../actions/dataComponentActions";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getResourcesV2 } from "../../../../../utils/bluechipUtils";

const updateOriginalRow = (rows, itemId, values, idPath, valuePath) => {
  if (!rows) return;
  const row = rows.find(row => row[idPath] === itemId);
  row[valuePath] = values[valuePath];
};

export const EditableTextDetailRowContainer = ({
  initialValues,
  row,
  validationSchema,
  dataComponentId: srcDataComponentId,
  performUpdateRequest,
  cloneDataComponentOverwriteProp,
  itemId,
  textInputProps,
  isReadOnly: isReadOnlyFn,
  rows,
  dstDataComponentProps,
  ...props
}) => {
  const dataComponentId = `${srcDataComponentId}-${itemId}`;

  useEffect(() => {
    if (itemId) {
      cloneDataComponentOverwriteProp(
        srcDataComponentId,
        dataComponentId,
        true,
        false,
        dstDataComponentProps
      );
    }
  }, [
    cloneDataComponentOverwriteProp,
    srcDataComponentId,
    dataComponentId,
    itemId,
    dstDataComponentProps,
  ]);

  const handleSubmit = useCallback(
    (values, { isSubmitting }) => {
      if (isSubmitting) return;
      updateOriginalRow(rows, itemId, values, props.idPath, props.path);
      performUpdateRequest(dataComponentId, itemId, values);
    },
    [
      rows,
      itemId,
      props.idPath,
      props.path,
      performUpdateRequest,
      dataComponentId,
    ]
  );

  const isReadOnly =
    typeof isReadOnlyFn === "function"
      ? isReadOnlyFn(itemId, row, dataComponentId)
      : isReadOnlyFn;

  return (
    <FormikForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      autoSave
      debounce={3000}
      enableReinitialize
    >
      {({ values }) => (
        <EditableTextDetailRow
          {...props}
          {...textInputProps}
          isReadOnly={isReadOnly}
          value={values[props.path]}
          dataComponentId={dataComponentId}
        />
      )}
    </FormikForm>
  );
};

EditableTextDetailRowContainer.propTypes = {
  row: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  textInputProps: PropTypes.shape({ helperText: PropTypes.string }),
  colSpan: PropTypes.shape({ prev: PropTypes.number, pos: PropTypes.number }),
  performUpdateRequest: PropTypes.func.isRequired,
  cloneDataComponentOverwriteProp: PropTypes.func.isRequired,
  validationSchema: PropTypes.shape({ valid: PropTypes.func }),
  dataComponentId: PropTypes.string,
  path: PropTypes.string.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  editable: PropTypes.bool,
  dstDataComponentProps: PropTypes.object,
};

EditableTextDetailRow.defaultProps = {
  textInputProps: {},
  dstDataComponentProps: {},
};

export const mapStateToProps = () => {
  const initialValuesSelector = createDeepEqualSelector(
    (_, { row }) => row,
    (_, { path }) => path,
    (row, path) => _pick(row, [path, "id"])
  );
  return (state, { row, idPath, path, dataComponentId }) => {
    const itemId = _get(row, idPath);

    const dataComponent = getDataComponent(
      `${dataComponentId}-${itemId}`,
      state
    );

    const rows = getResourcesV2(
      getDataComponent(dataComponentId, state),
      state,
      REQUEST_TYPES.LIST
    );

    let status = null;
    if (isPendingRequest(dataComponent, REQUEST_TYPES.UPDATE)) {
      status = "pending";
    }
    if (isSuccessfulRequest(dataComponent, REQUEST_TYPES.UPDATE)) {
      status = "success";
    }
    if (isFailedRequest(dataComponent, REQUEST_TYPES.UPDATE)) {
      status = "fail";
    }

    return {
      initialValues: initialValuesSelector(null, { row, path }),
      itemId,
      status,
      rows,
    };
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  cloneDataComponentOverwriteProp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableTextDetailRowContainer);
