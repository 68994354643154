import { BaseModel } from "@blue-chip/core";
import Location from "./Location";
import VendorContact from "./VendorContact";

export default class RemitAddress extends BaseModel {
  static get belongsTo() {
    return [Location];
  }

  static get hasMany() {
    return [VendorContact];
  }
}
