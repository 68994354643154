export const columns = [
  "number",
  "projectLead.name",
  "name",
  "feesPendingCount",
  "feesBilledCount",
  "feesPaidCount",
];

export const getColumnOptions = () => {
  return {
    number: {
      title: "Project Number",
      width: "8vw",
      filter: true,
    },
    "projectLead.name": {
      title: "Project Lead",
      width: "15vw",
      filter: true,
    },
    name: {
      title: "Project Name",
      width: "24vw",
      filter: true,
    },
    feesPendingCount: {
      title: "# Fees Pending Approval",
      width: "4vw",
    },
    feesBilledCount: {
      title: "# Fees Billed",
    },
    feesPaidCount: {
      title: "# Fees Paid",
    },
  };
};
