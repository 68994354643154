import React from "react";
import styled from "styled-components";

export const SectionWrapper = styled(({ useSeparator, ...props }) => (
  <div {...props} />
))`
  padding: 24px;
  border-bottom: ${({ useSeparator }) =>
    useSeparator && "1px solid rgba(0, 0, 0, 0.12)"};
`;

export const Wrapper = styled.div`
  margin-left: 24px;
  width: 403px;
  @media (max-width: 1024px) {
    margin-left: 0px;
    width: 100%;
  }
`;
