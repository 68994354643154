import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

const Option = styled.div`
  min-width: 175px;
`;

export const BulkActions = ({
  dataComponent,
  onBulkArchive,
  onBulkDelete,
  onBulkUnarchive,
}) => {
  const disabled = (dataComponent.selectedIds || []).length === 0;

  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: <Option>Archive</Option>,
          onClick: onBulkArchive,
          disabled,
        },
        {
          content: <Option>Unarchive</Option>,
          onClick: onBulkUnarchive,
          disabled,
        },
        {
          content: <Option>Delete</Option>,
          onClick: onBulkDelete,
          disabled,
        },
      ]}
    />
  );
};

BulkActions.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  onBulkArchive: PropTypes.func.isRequired,
  onBulkUnarchive: PropTypes.func.isRequired,
  onBulkDelete: PropTypes.func.isRequired,
};

export default BulkActions;
