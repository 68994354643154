import React, { useCallback, useState } from "react";
import _debounce from "lodash/debounce";
import styled from "styled-components";
import PropTypes from "prop-types";

import { StyledMenuItem } from "../../../layout/AppLayout/DropdownMenu";
import InputWrapper from "../../../inputs/InputWrapper";
import CheckboxWithError from "../../../inputs/CheckboxWithError";
import propTypes from "../../../../constants/propTypes";
import RegularText from "../../../ui/Typography/RegularText";
import DropdownMenu from "../../../layout/AppLayout/DropdownMenuCustomHeader";
import { setNoteFilters } from "../../../../actions/notesActions";
import { connect } from "react-redux";

const MenuItem = styled(StyledMenuItem)`
  padding: 12px 22px 12px 16px;
  .label {
    line-height: 20px;
  }
`;

export const Value = styled(RegularText)`
  font-weight: bold;
  color: #57abff;
  line-height: 24px;
  margin-left: 12px;
  width: 60px;
  @media (min-width: 1450px) {
    margin-left: 8px;
    width: unset;
  }
`;

const generateOptions = (tags, selectedTags, updateTagsFilters) => {
  const isAllSelected = tags.every(tag => selectedTags.includes(tag.id));

  const actionAll = ({ target: { value: select } }) => {
    if (select) return updateTagsFilters(tags.map(tag => tag.id));
    updateTagsFilters([]);
  };

  const switchItem = ({ target: { value: select, name } }) => {
    if (select) {
      return updateTagsFilters([...selectedTags, name]);
    }
    updateTagsFilters(selectedTags.filter(tagId => name !== tagId));
  };

  return [
    {
      content: (
        <InputWrapper
          key="all"
          InputComponent={CheckboxWithError}
          name="all"
          label="All"
          value={isAllSelected}
          onChange={actionAll}
        />
      ),
      useSeparator: true,
    },
    ...tags.map(tag => ({
      content: (
        <InputWrapper
          key={tag.id}
          InputComponent={CheckboxWithError}
          name={tag.id}
          label={tag.name}
          value={selectedTags.includes(tag.id)}
          onChange={switchItem}
        />
      ),
    })),
  ];
};

const getDropdownLabel = (selectedTags, tags) => {
  const dropdownLabel =
    selectedTags.length === 0
      ? "All Tags"
      : selectedTags
          .slice(0, 3)
          .map(tagId => tags.find(tag => tag.id === tagId).name)
          .join(", ");
  const labelComplement =
    selectedTags.length > 3 ? `, (${selectedTags.length - 3})` : "";
  return `${dropdownLabel}${labelComplement}`;
};

export const TagsDropdown = ({ tags, setNoteFilters }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSetFilters = useCallback(
    _debounce(newSelectedTags => {
      if (newSelectedTags.length > 0) {
        return setNoteFilters({
          tags: newSelectedTags,
        });
      }
      return setNoteFilters({ tags: null });
    }, 800),
    [setNoteFilters]
  );

  const updateTagsFilters = newSelectedTags => {
    setSelectedTags(newSelectedTags);
    handleSetFilters(newSelectedTags);
  };

  const options = generateOptions(tags, selectedTags, updateTagsFilters);

  return (
    <DropdownMenu
      actionElem={<Value>{getDropdownLabel(selectedTags, tags)}</Value>}
      options={options}
      closeOnClick={false}
      components={{ MenuItem }}
    />
  );
};
TagsDropdown.propTypes = {
  tags: PropTypes.arrayOf(propTypes.tag),
  setNoteFilters: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(null, mapDispatchToProps)(TagsDropdown);
