import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Menu } from "@material-ui/core";

import propTypes from "../../../constants/propTypes";
import { CustomMenuItem } from "./DropdownMenu";

const StyledButton = styled.div`
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

class DropdownMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { actionElem, options = [], closeOnClick, components } = this.props;
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <StyledButton
          aria-owns={anchorEl ? "dropdownMenu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {actionElem}
        </StyledButton>

        <Menu
          id="dropdownMenu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map(({ onClick, content, ...itemProps }, i) => (
            <components.MenuItem
              key={i}
              onClick={() => {
                if (closeOnClick) {
                  this.handleClose();
                }
                onClick && onClick();
              }}
              {...itemProps}
            >
              {content}
            </components.MenuItem>
          ))}
        </Menu>
      </Fragment>
    );
  }
}

DropdownMenu.defaultProps = {
  closeOnClick: true,
  components: { MenuItem: CustomMenuItem },
};

DropdownMenu.propTypes = {
  actionElem: PropTypes.node.isRequired,
  closeOnClick: PropTypes.bool,
  components: PropTypes.shape({
    MenuItem: propTypes.component,
  }),
  onClick: PropTypes.func,
};

export default DropdownMenu;
