import React, { useEffect, useRef } from "react";
import moment from "moment";
import { goBack } from "connected-react-router";

import propTypes from "../../../../constants/propTypes";
import useActions from "../../../hooks/useActions";
import {
  fetchBidGroupVendor,
  vendorQuoteDataComponentId,
} from "../../../../actions/vendorPortalRFQActions";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as RequestTypes from "../../../../constants/RequestTypes";
import QuoteForm from "./QuoteForm";
import { LabeledTextContext } from "../../../../withPORevision";
import Loader, { LoaderContext } from "../../../ui/Loader";
import {
  useDatacomponent,
  useFlattenedDatacomponent,
} from "../../../hooks/useDatacomponent";
import ProjectHeader from "../../Projects/ProjectHeader";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import {
  setAutoSaveComponentId,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { SubmittedBanner } from "./SubmittedBanner";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
function QuoteDetail({ match }) {
  const bidGroupVendorId = match.params.bidGroupVendorId;
  const draftRef = useRef(true);
  const formikActionsRef = useRef(null);

  const actions = useActions({
    fetchBidGroupVendor,
    goBack,
    showSnackNotificationAction,
    setAutoSaveComponentId,
  });

  useEffect(() => {
    actions.fetchBidGroupVendor(bidGroupVendorId);
    actions.setAutoSaveComponentId(vendorQuoteDataComponentId);
  }, [actions, bidGroupVendorId]);

  const bidGroupVendor = useV2DatacomponentResources(
    vendorQuoteDataComponentId,
    {},
    RequestTypes.FIND
  );

  const dataComponent = useDatacomponent(vendorQuoteDataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      if (!draftRef.current) {
        actions.goBack();
      }
      actions.showSnackNotificationAction(
        "Your quote have been updated successfully"
      );
    },
    onError: error => handleRequestError(error, formikActionsRef.current),
  });

  const loading =
    useFlattenedDatacomponent(vendorQuoteDataComponentId, REQUEST_TYPES.FIND)
      .loading || (bidGroupVendor.bidGroup ? false : true);

  const responseDate = bidGroupVendor?.bidGroup?.rfq?.responseDate;
  const quoteIsOver = responseDate
    ? moment(responseDate).diff(moment(), "days") < 0
    : false;
  const projectDefaultCurrency = bidGroupVendor.bidGroup?.project?.projectCurrencies?.find(
    pcItem => pcItem.isDefault
  );
  const labeledText = quoteIsOver || bidGroupVendor.bidComplete;
  return (
    <LabeledTextContext.Provider value={labeledText}>
      <LoaderContext.Provider value={{ loading }}>
        <SubmittedBanner
          quoteStatus={bidGroupVendor.quoteStatus}
          projectLead={bidGroupVendor.bidGroup?.project.projectLead}
          loading={loading}
        />
        <ProjectHeader project={bidGroupVendor.bidGroup?.project} />
        <Loader>
          <QuoteForm
            quoteIsOver={quoteIsOver}
            bidGroupVendor={bidGroupVendor}
            projectDefaultCurrency={projectDefaultCurrency}
            draftRef={draftRef}
            formikActionsRef={formikActionsRef}
          />
        </Loader>
      </LoaderContext.Provider>
    </LabeledTextContext.Provider>
  );
}

QuoteDetail.propTypes = {
  match: propTypes.matchRouter,
};

export default QuoteDetail;
