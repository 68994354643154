import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import FilterList from "@material-ui/icons/FilterList";
import Close from "@material-ui/icons/Close";
import styled from "styled-components";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

import SearchBox, { SearchPanel } from "../../../../components/ui/SearchBox";
import { setNoteFilters } from "../../../../actions/notesActions";
import propTypes from "../../../../constants/propTypes";
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  ${SearchPanel} {
    padding: 0;
    margin-left: 16px;
    border-bottom: unset;
    background-color: #ffffff;
  }
`;

const FilterIcon = styled(FilterList)`
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
`;

export const CloseIcon = styled(Close)`
  color: #57abff;
  cursor: pointer;
`;

export const FilterPanel = ({
  displayFilters,
  setDisplayFilters,
  filters,
  setNoteFilters,
}) => {
  const [searchValue, setSearchValue] = useState(filters.search);

  // Updates the local value for search box when any external components mutates the search string
  useEffect(() => setSearchValue(filters.search), [filters.search]);

  const handleSetFilters = useCallback(
    _debounce(search => setNoteFilters({ search }), 1000),
    [setNoteFilters]
  );

  // Save the new search string to be displayed in the search box and make a delayed call to set the
  // shared filter
  const handleChangeSearch = ({ target: { value: search } }) => {
    setSearchValue(search);
    handleSetFilters(search);
  };

  const clearTagsAndTimeFilters = () => {
    if (
      filters.tags ||
      filters.timeBoundaries.from ||
      filters.timeBoundaries.to
    ) {
      setNoteFilters({ timeBoundaries: {}, tags: null });
    }
    setDisplayFilters(false);
  };

  return (
    <FilterWrapper>
      {displayFilters ? (
        <Tooltip title="Close Filters">
          <CloseIcon onClick={clearTagsAndTimeFilters} />
        </Tooltip>
      ) : (
        <Tooltip title="More Filters">
          <FilterIcon onClick={() => setDisplayFilters(true)} />
        </Tooltip>
      )}
      <SearchBox
        placeholder="Search for Note"
        onChange={handleChangeSearch}
        value={searchValue}
      />
    </FilterWrapper>
  );
};

FilterPanel.propTypes = {
  displayFilters: PropTypes.bool.isRequired,
  setDisplayFilters: PropTypes.func.isRequired,
  setNoteFilters: PropTypes.func.isRequired,
  filters: propTypes.notesFilters,
};

const mapStateToProps = ({ notes: { filters } }) => ({ filters });

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
