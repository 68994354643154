import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckCircle from "@material-ui/icons/CheckCircle";
import RemoveIcon from "@material-ui/icons/Remove";
import AddCircle from "@material-ui/icons/AddCircle";

const Content = styled.div`
  background-color: rgb(255, 255, 255);
  width: 1200px;
  overflow: auto;
  max-height: 75vh;
`;

export const ContentWrapper = props => <Content {...props} />;

export const MissingIcon = styled(RemoveIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
`;

export const columnWithEmptyState = column =>
  function Column({ [column]: value }) {
    return value ? value : <MissingIcon />;
  };

const CheckIcon = styled(CheckCircle)`
  margin-right: 8px;
`;

const Status = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Paid = styled(Status)`
  color: #78cc18;
`;
const Void = styled(Status)`
  color: #ff1744 !important;
`;
export const Pending = styled(Status)`
  color: #57abff;
`;

export const Partial = styled(Status)`
  color: #ffb74d;
`;

export const statusMap = {
  Paid,
  Void,
  Pending,
  Partial,
};

export const FundingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0px 24px;

  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const FundingIcon = styled(AddCircle)`
  margin-right: 8px;
  font-size: 24px;
`;

export const FundingType = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  color: #57abff;

  &:hover {
    color: rgba(87, 171, 255, 0.8);
  }
`;

export const StatusColumn = ({ status }) => {
  const Column = statusMap[status] || Pending;
  return (
    <Column>
      {status === "Paid" && <CheckIcon />}
      {status}
    </Column>
  );
};
StatusColumn.propTypes = {
  status: PropTypes.string,
};
