import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Assignment from "@material-ui/icons/Assignment";
import Email from "@material-ui/icons/Email";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import propTypes from "../../../constants/propTypes";

import SerifTitle from "../../ui/Typography/SerifTitle";
import SavedConfirmation from "./SavedConfirmation";

import {
  HeaderLeftContainer,
  StyledAppBar,
  HeaderContentContainer,
  iconStyle,
} from "./components";

import BWUserHeader from "./Header/BWUserHeader";
import ClientPortalHeader from "./Header/ClientPortalHeader";
import VendorPortalHeader from "./Header/VendorPortalHeader";

import { roleCan } from "../../../utils/roleUtils";
import { ProjectContext } from "../../hooks/useIsProjectClosed";

export const Title = styled(SerifTitle)`
  width: ${props => (props.isNavbarDrawerOpen ? "256px" : "56px")};
  min-width: ${props => (props.isNavbarDrawerOpen ? "256px" : "56px")};
  padding-left: 16px;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  transition: width 0.5s;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const MainHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;
export const MenuIconButton = styled(IconButton)`
  display: none;
  fill: white;
  @media (max-width: 600px) {
    display: block;
    position: absolute;
    left: 0px;
    z-index: 20000;
    padding-left: 24px;
  }
`;
export const PageNameWrapper = styled.div`
  justify-content: center;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0px 100px;
  }

  @media (max-width: 450px) {
    display: flex;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0px 40px;
  }

  @media (max-width: 400px) {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0px 40px;
  }
`;

export const PageName = styled(({ isProjectDefined, ...props }) => (
  <SerifTitle {...props} />
))`
  padding-left: 38px;
  font-size: ${({ isProjectDefined }) => (isProjectDefined ? "18px" : "20px")};
  line-height: ${({ isProjectDefined }) =>
    isProjectDefined ? "20px" : "28px"};
  color: rgb(255, 255, 255);
  @media (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
  }
`;

export const ProjectName = styled(SerifTitle)`
  padding-left: 38px;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  @media (max-width: 600px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
  }
`;

export const AssignmentIcon = styled(Assignment)(iconStyle);
export const EmailIcon = styled(Email)(iconStyle);

export class Header extends Component {
  static contextType = ProjectContext;

  state = {
    anchorElement: null,
  };

  handleClick = event => {
    this.setState({ anchorElement: event.currentTarget });
  };

  handleCloseUserMenu = () => {
    this.setState({ anchorElement: null });
  };

  handleLogout = () => {
    this.props.logout();
  };

  render() {
    const {
      text,
      authenticatedUserName,
      isDetailPage,
      isNavbarDrawerOpen,
      autoSaveRequestState,
      autoSaveQueueState,
      onOpenNotesModal,
      onGoHome,
      handleDrawerToggle,
      handleGoBack,
      useDarkBackground,
      onOpenGenerateCustomReport,
      role,
    } = this.props;
    const { anchorElement } = this.state;
    const displayCustomReports = roleCan(role, "access-custom-reports");
    const { project } = this.context;
    return (
      <Fragment>
        <StyledAppBar position="fixed" useDarkBackground={useDarkBackground}>
          <HeaderContentContainer isNavbarDrawerOpen={isNavbarDrawerOpen}>
            <Title isNavbarDrawerOpen={isNavbarDrawerOpen} onClick={onGoHome}>
              RPM
            </Title>
            {!isDetailPage && (
              <MenuIconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </MenuIconButton>
            )}
            {isDetailPage && (
              <MenuIconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={handleGoBack}
              >
                <ArrowBackIcon />
              </MenuIconButton>
            )}
            <MainHeaderContainer>
              <PageNameWrapper>
                {text && (
                  <PageName isProjectDefined={!!project}>{text}</PageName>
                )}
                {project && (
                  <ProjectName>
                    {project.number} {project.name}
                  </ProjectName>
                )}
              </PageNameWrapper>
              <HeaderLeftContainer>
                <SavedConfirmation
                  autoSaveRequestState={autoSaveRequestState}
                  autoSaveQueueState={autoSaveQueueState}
                />
                {text && (
                  <Fragment>
                    <BWUserHeader
                      handleClick={this.handleClick}
                      anchorElement={anchorElement}
                      authenticatedUserName={authenticatedUserName}
                      handleCloseUserMenu={this.handleCloseUserMenu}
                      handleLogout={this.handleLogout}
                      onOpenSettings={this.props.onOpenSettings}
                      onOpenNotesModal={onOpenNotesModal}
                      onOpenGenerateCustomReport={onOpenGenerateCustomReport}
                      displayCustomReports={displayCustomReports}
                    />
                    <ClientPortalHeader
                      authenticatedUserName={authenticatedUserName}
                      anchorElement={anchorElement}
                      onClick={this.handleClick}
                      onOpenSettings={this.props.onOpenSettings}
                      onCloseUserMenu={this.handleCloseUserMenu}
                      onLogout={this.handleLogout}
                      displayCustomReports={displayCustomReports}
                    />
                    <VendorPortalHeader
                      authenticatedUserName={authenticatedUserName}
                      anchorElement={anchorElement}
                      onClick={this.handleClick}
                      onCloseUserMenu={this.handleCloseUserMenu}
                      onLogout={this.handleLogout}
                    />
                  </Fragment>
                )}
              </HeaderLeftContainer>
            </MainHeaderContainer>
            <div />
          </HeaderContentContainer>
        </StyledAppBar>
      </Fragment>
    );
  }
}

Header.propTypes = {
  text: PropTypes.string,
  isDetailPage: PropTypes.bool,
  useDarkBackground: PropTypes.bool,
  isNavbarDrawerOpen: PropTypes.bool,
  isCellNumberVerified: PropTypes.bool,
  authenticatedUserName: PropTypes.string,
  autoSaveRequestState: propTypes.requestState,
  autoSaveQueueState: PropTypes.shape({}),
  logout: PropTypes.func.isRequired,
  onOpenNotesModal: PropTypes.func.isRequired,
  onOpenSettings: PropTypes.func.isRequired,
  onGoHome: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  onOpenGenerateCustomReport: PropTypes.func.isRequired,
  role: PropTypes.shape({}),
};
