import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { cleanCurrencyValue } from "../../../../../utils/currencyFormatter";

export const SAMPLE_PRICING = "samplePricing";
export const PRODUCTION_PRICING = "productionPricing";

export const generateGridRows = (
  bidGroupVendorSpecs,
  pricing,
  currencyFormatter
) => {
  return bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const specPrice = pricing[bidGroupVendorSpec.id];
    return {
      id: bidGroupVendorSpec.id,
      customNumber: bidGroupVendorSpec.spec.customNumber,
      description: bidGroupVendorSpec.spec.description,
      unitOfMeasure: bidGroupVendorSpec.spec.unitOfMeasure,
      baseQuantity:
        specPrice === SAMPLE_PRICING
          ? bidGroupVendorSpec.sampleQuantity
          : bidGroupVendorSpec.spec.baseQuantity,
      atticStock:
        specPrice === SAMPLE_PRICING ? 0 : bidGroupVendorSpec.spec.atticStock,
      price: specPrice,
      extendedPrice: getExtendedPrice(
        specPrice,
        bidGroupVendorSpec,
        currencyFormatter
      ),
      enableProductionPrice:
        typeof bidGroupVendorSpec.productionPricing == "number",
      enableSamplePrice: !!(
        bidGroupVendorSpec.sampleQuantity &&
        typeof bidGroupVendorSpec.samplePricing == "number"
      ),
      productionPricing: bidGroupVendorSpec.productionPricing,
      samplePricing: bidGroupVendorSpec.samplePricing,
    };
  });
};

const getExtendedPrice = (specPrice, bidGroupVendorSpec, currencyFormatter) => {
  return specPrice === SAMPLE_PRICING
    ? currencyFormatter.format(
        bidGroupVendorSpec.sampleQuantity * bidGroupVendorSpec.samplePricing
      )
    : currencyFormatter.format(
        (bidGroupVendorSpec.spec.baseQuantity +
          bidGroupVendorSpec.spec.atticStock) *
          bidGroupVendorSpec.productionPricing
      );
};

export const getPriceRow = (row, handlePricingChange, currencyFormatter) => {
  return (
    <TextField
      select
      id="select"
      onChange={event => handlePricingChange(event, row)}
      value={row.price}
    >
      {row.enableProductionPrice && (
        <MenuItem key={PRODUCTION_PRICING} value={PRODUCTION_PRICING}>
          {`Production - ${currencyFormatter.format(row.productionPricing)}`}
        </MenuItem>
      )}
      {row.enableSamplePrice && (
        <MenuItem key={SAMPLE_PRICING} value={SAMPLE_PRICING}>
          {`Sample - ${currencyFormatter.format(row.samplePricing)}`}
        </MenuItem>
      )}
    </TextField>
  );
};
export const handlePricingChange = (
  event,
  row,
  specPricing,
  setSpecPricing,
  bidGroupVendorSpecs,
  setGridRows,
  currencyFormatter
) => {
  const pricing = Object.assign(specPricing);
  pricing[row.id] = event.target.value;

  setSpecPricing(pricing);

  const rows = generateGridRows(
    bidGroupVendorSpecs,
    pricing,
    currencyFormatter
  );

  setGridRows(rows);
};

export const getOriginalVendor = bidGroupVendorSpec => {
  if (bidGroupVendorSpec.spec.awardedBidGroupVendorSpecQuoteId) {
    return bidGroupVendorSpec.spec.originalVendorId;
  }

  return parseInt(bidGroupVendorSpec.spec.vendorId);
};

export const handleAwardSpec = (
  bidGroupVendor,
  bidGroupVendorSpecs,
  specPricing,
  onAwardSpec,
  dataComponentId,
  setSnackMessage,
  rows
) => {
  const specs = bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    const specRow = rows.find(row => row.id === bidGroupVendorSpec.id);
    const spec = {
      id: parseInt(bidGroupVendorSpec.spec.id),
      projectCurrencyId: bidGroupVendor.quoteProjectCurrencyId,
      vendorId: parseInt(bidGroupVendor.vendor.id),
      originalVendorId: getOriginalVendor(bidGroupVendorSpec),
      awardedBidGroupVendorSpecQuoteId: parseInt(bidGroupVendorSpec.quote.id),
      baseQuantity: specRow.baseQuantity,
      atticStock: specRow.atticStock,
      overagePercent: bidGroupVendorSpec.overagePercent,
    };

    if (specPricing[bidGroupVendorSpec.id] === SAMPLE_PRICING) {
      spec.priceCents = cleanCurrencyValue(
        bidGroupVendorSpec.samplePricing * 100
      );
    } else {
      spec.priceCents = cleanCurrencyValue(
        bidGroupVendorSpec.productionPricing * 100
      );
    }

    return spec;
  });
  onAwardSpec(specs, dataComponentId, setSnackMessage);
};
