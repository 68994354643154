import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";
import CreateClientContactForm from "./CreateClientContactForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import Joi from "joi-browser";

class CreateClientContactContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    formikArrayHelpers: PropTypes.object,
  };

  get validationSchema() {
    return BWModels.loadSchema("ClientContact").concat(
      Joi.object().keys({
        contact: BWModels.loadSchema("Contact"),
      })
    );
  }

  createClientContact = contact => {
    this.props.formikArrayHelpers.push(contact);
    this.props.closeModalDialog();
  };

  render() {
    const { closeModalDialog } = this.props;
    return (
      <CreateClientContactForm
        validationSchema={this.validationSchema}
        createClientContact={this.createClientContact}
        closeModalDialog={closeModalDialog}
      />
    );
  }
}

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(null, mapDispatchToProps)(CreateClientContactContainer);
