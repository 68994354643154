import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import LocalGrid from "./../../../../ui/Grid/LocalGrid";
import { dateTypeBuilder } from "../../../../ui/Grid/utilComponents/dataTypeProviders";
import {
  actionIconsBuilder,
  approveTypeBuilder,
  filenameTypeBuilder,
} from "./dataTypeProviders";

const Grid = ({ gridId, specRequirement, onDelete, onSelect }) => {
  return (
    <LocalGrid
      gridId={gridId || "submittals-files"}
      rows={specRequirement.submittalFiles}
      columns={[
        { name: "file.filename", title: "Filename", bold: true },
        { name: "createdAt", title: "Date Uploaded" },
        { name: "receivedDescription", title: "Received" },
        { name: "approvedDescription", title: "Approved" },
        { name: "approved", title: " " },
        { name: "actionIcons", title: " " },
      ]}
      tableComponents={{ GridWrapper: ({ children }) => children }}
      dataTypeProviders={[
        dateTypeBuilder(["createdAt"]),
        actionIconsBuilder(onDelete, onSelect),
        approveTypeBuilder(specRequirement),
        filenameTypeBuilder(),
      ]}
    />
  );
};

Grid.propTypes = {
  specRequirement: propTypes.specRequirement,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  gridId: PropTypes.string,
};

export default Grid;
