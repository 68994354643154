import { connect } from "react-redux";

import ShipToSiteContainer from "./ShipToSiteContainer";
import { connect as formikConnect } from "formik";
import { openModalDialog } from "../../../../../actions/layoutActions";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import { poContactdataComponentId } from "../../../../../actions/purchaseOrdersContactActions";

const mapStateToProps = state => {
  return {
    purchaseOrderContacts:
      getBluechipResourcesByType(poContactdataComponentId, state) || [],
  };
};
export const mapDispatchToProps = {
  openModalDialog,
};

export default formikConnect(
  connect(mapStateToProps, mapDispatchToProps)(ShipToSiteContainer)
);
