import React from "react";
import PropTypes from "prop-types";
import DeleteConfirmation from "../../../../../ui/DeleteConfirmation";

const DeleteContract = ({ onDelete, title }) => {
  return (
    <DeleteConfirmation
      title={title}
      onDelete={onDelete}
      dangerButtonText="DELETE FILE"
    />
  );
};

DeleteContract.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
};

export default DeleteContract;
