import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BrandForm from "./ProjectNote";
import { performUpdateRequest } from "../../../../../../actions/dataComponentActions";

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        performUpdateRequest,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(BrandForm);
