import React, { memo } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import { Paper } from "../../../mui/core";
import Spec from "../../../../models/Spec";
import GridAPI from "../../../ui/Grid/GridAPI";
import { RowMenuProvider } from "../../../ui/Grid/utilComponents";
import * as dataTypeProviders from "../../../ui/Grid/utilComponents/dataTypeProviders";
import { ColumnWrapper } from "./components";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import propTypes from "../../../../constants/propTypes";
import RowComponent from "./gridComponents/RowComponent";
import { useGridProps } from "./gridProps";
import withSelectedRowsAPI from "../../../ui/BWGrid/withSelectedRowsAPI";
import { GridActions } from "./GridActions";

export const categoryTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value, row }) => {
      if (!value) return null;
      return <ColumnWrapper id={row.id} field={value} value={value} />;
    }}
    {...props}
    for={["specCategory.name", "spec.specCategory.name"]}
  />
);

export function isSpecReadOnly(id, values) {
  return values.__deleted__;
}

const includes = [
  "area",
  "specCategory",
  "purchaseOrder.vendor",
  "glCodes",
  "vendor",
  "shipments",
  "unitOfMeasure",
];

const Grid = ({
  dataComponent,
  purchaseOrderId,
  selectedProjectCurrency,
  onGoToSpecDetails,
  onOpenQuickEditModal,
  onOpenAddSpecModal,
  onRemoveSpec,
  isPOChangeDisabled,
}) => {
  const {
    rowProps,
    detailRowProps,
    gridActions,
    filteringDataTypeProviders,
    DXGrid,
    columns,
  } = useGridProps({
    purchaseOrderId,
    onOpenAddSpecModal,
    onGoToSpecDetails,
    dataComponent,
    onOpenQuickEditModal,
    onRemoveSpec,
    isPOChangeDisabled,
  });

  const showSelectionColumn = !isPOChangeDisabled;

  return (
    <Paper>
      <GridAPI
        apiRoute="specs"
        model={Spec}
        rowProps={rowProps}
        includes={includes}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        apiFilters={{
          rootFilters: {
            $where: {
              purchaseOrderId,
            },
          },
          sort: [{ columnName: "customNumber", direction: "asc" }],
          params: {
            omitDefaultModifier: true,
            modifiers: [
              "withQuantityPrice",
              "withTotalQuantityPaid",
              "withTotalQuantityShipped",
            ],
          },
        }}
        dataComponent={dataComponent}
        tableComponents={{
          GridHeader: useHeaderComponent({
            headerText: pluralize("Item", dataComponent.totalRows, true),
            headerOverride: (
              <GridActions dataComponentId={dataComponent.dataComponentId} />
            ),
            actions: gridActions,
          }),
          RowComponent,
          DXGrid,
        }}
        dxGridProps={detailRowProps.dxGridProps}
        columns={columns}
        columnExtensions={[]}
        filteringDataTypeProviders={filteringDataTypeProviders}
        dataTypeProviders={[
          RowMenuProvider,
          dataTypeProviders.numberTypeBuilder(
            ["totalQuantityWithoutOverage", "left", "shipmentTotalQty"],
            true
          ),
          dataTypeProviders.currencyTypeBuilder(
            ["price", "totalPriceWithoutOverage"],
            selectedProjectCurrency
          ),
          categoryTypeProvider,
        ]}
        showSelectAll={showSelectionColumn}
        showSelectionColumn={showSelectionColumn}
      />
    </Paper>
  );
};

Grid.propTypes = {
  dataComponent: propTypes.dataComponent.isRequired,
  purchaseOrderId: PropTypes.string,
  onGoToSpecDetails: PropTypes.func.isRequired,
  onOpenQuickEditModal: PropTypes.func.isRequired,
  onOpenAddSpecModal: PropTypes.func.isRequired,
  onRemoveSpec: PropTypes.func.isRequired,
  isPOChangeDisabled: PropTypes.bool.isRequired,
  onOpenEditAction: PropTypes.func,
  selectedProjectCurrency: PropTypes.object,
};

export default memo(withSelectedRowsAPI(Grid));
