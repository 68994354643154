import AuthService from "../../services/AuthService";
import * as REQUEST_TYPES from "../../constants/RequestTypes";
import { filtersToGetParams, pagingToGetParams, sortToGetParam } from "./utils";
import store from "../../store/store";
import * as actions from "../../constants/ActionTypes";

export function createDataComponentResourceAction(
  dataComponentId,
  requestType,
  data,
  meta
) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_CREATE,
    dataComponentId,
    requestType,
    payload: { data, meta },
  };
}

export function upsertListIfExistsDataComponentResourceAction(
  dataComponentId,
  requestType,
  data
) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_UPSERT_LIST_IF_EXISTS,
    dataComponentId,
    requestType,
    payload: { data },
  };
}

export function updateIfExistsDataComponentResourceAction(
  dataComponentId,
  requestType,
  data,
  meta
) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_UPDATE_IF_EXISTS,
    dataComponentId,
    requestType,
    payload: { data, meta },
  };
}

export function destroyDataComponentResourceAction(
  dataComponentId,
  requestType
) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_DESTROY,
    dataComponentId,
    requestType,
  };
}

export function destroyListDataComponentResourceAction(
  dataComponentId,
  requestType,
  data,
  meta
) {
  return {
    type: actions.DATA_COMPONENT_RESOURCE_DESTROY_LIST_IF_EXIST,
    dataComponentId,
    requestType,
    payload: { data, meta },
  };
}

export async function fetchList(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes } = {
    ...dataComponent,
    ...options,
  };

  const requestState = dataComponent.requestState[REQUEST_TYPES.LIST];

  const {
    pageNumber,
    pageSize,
    sort,
    rootFilters,
    fields,
    params: { modifiers, ...params } = {},
    count,
  } = {
    ...requestState,
    ...options,
  };

  // Fetch the primary records for the DataComponent's model
  const jsonApiResponse = await service.get(apiRoot + apiRoute, {
    page: pagingToGetParams(pageNumber, pageSize),
    sort: sortToGetParam(sort),
    filter: filtersToGetParams(rootFilters),
    includes: includes ? JSON.stringify(includes) : [],
    fields: fields ? JSON.stringify(fields) : undefined,
    count,
    modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
    ...params,
  });
  const data = await jsonApiResponse.deserializeData();
  const { meta } = jsonApiResponse;
  const { total_pages, total_results } = meta;

  store.dispatch(
    createDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.LIST,
      data,
      meta
    )
  );

  const rowIndex = jsonApiResponse.data.map(row => row.id);

  return {
    totalPages: total_pages,
    totalRows: total_results,
    ...meta,
    rowIndex,
  };
}

export async function fetchItem(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes, rootFilters } = {
    ...dataComponent,
    ...options,
  };
  const { id, params: { modifiers, ...params } = {} } = options;

  const jsonApiResponse = await service.get(`${apiRoot}${apiRoute}/${id}`, {
    includes: JSON.stringify(includes || []),
    filter: filtersToGetParams(rootFilters),
    modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
    ...params,
  });

  const data = await jsonApiResponse.deserializeData();
  const { meta } = jsonApiResponse;

  store.dispatch(
    createDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.FIND,
      data,
      meta
    )
  );

  return {
    rowIndex: [id],
    links: jsonApiResponse.data.links,
  };
}

export async function sendPost(dataComponent, options, url, requestType) {
  const service = new AuthService(false);
  const { includes } = dataComponent;
  const { body, params: { modifiers, ...params } = {}, items } = options;
  const endpointUrl = options.isBulk ? `${url}/bulk` : url;
  const payload = options.isBulk ? items : body;

  const jsonApiResponse = await service.post(endpointUrl, payload, {
    includes: includes ? JSON.stringify(includes) : [],
    modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
    ...params,
  });

  const data = await jsonApiResponse.deserializeData();
  const { meta } = jsonApiResponse;

  store.dispatch(
    createDataComponentResourceAction(
      dataComponent.dataComponentId,
      requestType,
      data,
      meta
    )
  );

  store.dispatch(
    upsertListIfExistsDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.LIST,
      Array.isArray(data) ? data : [data]
    )
  );

  return {
    rowIndex: [jsonApiResponse.data.id],
  };
}

export async function createItem(dataComponent, options) {
  const { apiRoute } = dataComponent;
  const apiRoot = "/";
  return await sendPost(
    dataComponent,
    options,
    `${apiRoot}${apiRoute}`,
    REQUEST_TYPES.CREATE
  );
}

export async function patchItem(dataComponent, options) {
  const { apiRoute, includes, params: { modifiers, ...params } = {} } = {
    ...dataComponent,
    ...options,
  };
  const apiRoot = "/";
  const baseUrl = `${apiRoot}${apiRoute}`;
  if (options.isBulk) {
    await sendPost(dataComponent, options, baseUrl, REQUEST_TYPES.UPDATE);
    return;
  }

  const service = new AuthService(false);

  const { id, body } = options;

  const jsonApiResponse = await service.patch(`${baseUrl}/${id}`, body, {
    includes: includes ? JSON.stringify(includes) : [],
    modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
    ...params,
  });
  const data = await jsonApiResponse.deserializeData();
  const { meta } = jsonApiResponse;

  store.dispatch(
    createDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.UPDATE,
      data,
      meta
    )
  );

  store.dispatch(
    updateIfExistsDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.FIND,
      data,
      meta
    )
  );

  store.dispatch(
    upsertListIfExistsDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.LIST,
      [data]
    )
  );

  return {
    rowIndex: [jsonApiResponse.data.id],
  };
}

export async function deleteItem(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes, params } = {
    ...dataComponent,
    ...options,
  };
  const { ids, body } = options;
  const url = `${apiRoot}${apiRoute}`;

  const isBulk = Array.isArray(ids);
  const endpointUrl = isBulk ? `${url}/bulk` : `${url}/${ids}`;
  const jsonApiResponse = await service.delete(
    endpointUrl,
    body,
    {
      includes: includes ? JSON.stringify(includes) : [],
      ...params,
    },
    ids
  );
  const data = await jsonApiResponse.deserializeData();
  const { meta } = jsonApiResponse;

  store.dispatch(
    destroyDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.FIND,
      data,
      meta
    )
  );

  store.dispatch(
    destroyListDataComponentResourceAction(
      dataComponent.dataComponentId,
      REQUEST_TYPES.LIST,
      [data]
    )
  );

  return {
    rowIndex: [jsonApiResponse.data.id],
  };
}

export async function importFromFile(dataComponent, options) {
  const { apiRoute } = dataComponent;
  const apiRoot = "/";
  return await sendPost(
    dataComponent,
    options,
    `${apiRoot}${apiRoute}/import`,
    REQUEST_TYPES.IMPORT
  );
}
