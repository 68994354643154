import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ShipmentsNeedsFollowUpGrid from "./ShipmentsNeedsFollowUpGrid";
import propTypes from "../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { goToPurchaseOrderDetail } from "../../../../actions/dashboardActions";

const dataComponentId = "DashboardShipmentsNeedsFollowUp";

export const ShipmentsNeedsFollowUpContainer = ({
  columns,
  dataComponent,
  apiFilters,
  goToPurchaseOrderDetail,
}) => {
  const handleRowClick = useCallback(
    shipment => goToPurchaseOrderDetail(shipment.spec.purchaseOrder),
    [goToPurchaseOrderDetail]
  );

  return (
    <ShipmentsNeedsFollowUpGrid
      columns={columns}
      dataComponent={dataComponent}
      onRowClick={handleRowClick}
      apiFilters={apiFilters}
    />
  );
};

ShipmentsNeedsFollowUpContainer.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataComponent: propTypes.dataComponent,
  apiFilters: PropTypes.object.isRequired,
  goToPurchaseOrderDetail: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  goToPurchaseOrderDetail,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentsNeedsFollowUpContainer);
