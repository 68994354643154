import React, { useCallback } from "react";
import PropTypes from "prop-types";
import DeleteConfirmation from "../../../../../ui/DeleteConfirmation";
import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../../../actions/dataComponentActions";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { useDeleteProcessRequests } from "../../../../../hooks/useProcessRequest";

const DeleteInvoiceContainer = ({ invoice, dataComponentId }) => {
  const actions = useActions({
    closeModalDialog,
    showSnackNotificationAction,
    setReload,
    performDeleteRequest,
  });

  const dataComponent = useDatacomponent(dataComponentId);

  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      actions.setReload(dataComponentId, true);
    },
    onError: ({ data: { errors } }) => {
      errors.forEach(error => {
        if (error.global) {
          actions.showSnackNotificationAction(error.title);
        }
      });
    },
  });

  const handleDelete = useCallback(() => {
    actions.performDeleteRequest(dataComponentId, invoice.id);
  }, [actions, dataComponentId, invoice]);

  return <DeleteConfirmation title={invoice.number} onDelete={handleDelete} />;
};

DeleteInvoiceContainer.propTypes = {
  invoice: PropTypes.object.isRequired,
  dataComponentId: PropTypes.string.isRequired,
};

export default DeleteInvoiceContainer;
