import React from "react";
import moment from "moment";

import StatusTag from "../Tags/StatusTag";
import ProjectStarContainer from "../BWGrid/ProjectStarContainer";
import styled from "styled-components";
import Remove from "@material-ui/icons/Remove";
import { propertyFormatter } from "../../pages/Projects/dataTypeProviders";
import { PRIMARY_DATE_FORMAT } from "../../../constants/formats";

export const columns = [
  "number",
  "name",
  "type",
  "status",
  "property.name",
  "startDate",
  "endDate",
];

export const DashIcon = styled(Remove)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 20px;
`;

export const makeStarColumnOptions = (loggedInUserId, dataComponent) => {
  return {
    title: " ",
    sortingEnabled: false,
    render: project =>
      project && (
        <ProjectStarContainer
          project={project}
          loggedInUserId={loggedInUserId}
          dataComponent={dataComponent}
        />
      ),
  };
};

export const columnOptions = {
  number: { title: "Number", bold: true, filter: true },
  name: { title: "Project Name", fill: true, filter: true },
  type: { bold: true },
  status: {
    // eslint-disable-next-line react/prop-types, react/display-name
    render: ({ status }) => <StatusTag>{status}</StatusTag>,
  },
  "property.name": {
    title: "Property",
    bold: true,
    width: "172px",
    render: propertyFormatter,
  },
  "projectLead.name": {
    title: "Project Lead",
  },
  startDate: {
    title: "Start",
    align: "right",
    // eslint-disable-next-line react/display-name
    render: row => {
      if (row.startDate) {
        return <span>{moment(row.startDate).format(PRIMARY_DATE_FORMAT)}</span>;
      }
      return <DashIcon />;
    },
  },
  endDate: {
    title: "End",
    align: "right",
    // eslint-disable-next-line react/display-name
    render: row => (
      <span>{moment(row.endDate).format(PRIMARY_DATE_FORMAT)}</span>
    ),
  },
};
