import React, { useCallback } from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import get from "lodash.get";
import styled from "styled-components";
import PropTypes from "prop-types";

import { InputLabel } from "../../../../../mui/core";
import BoldText from "../../../../../ui/Typography/BoldText";
import propTypes from "../../../../../../constants/propTypes";
import LocationCombined from "../../../../../ui/LocationCombined";

export const RemitAddressListItem = styled(ListItem)`
  max-width: 463px;
  padding: 8px 24px;
  white-space: normal;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ItemPrimary = ({ remitAddress }) => {
  const text = [get(remitAddress, "label"), get(remitAddress, "name")].join(
    " - "
  );
  return (
    <InputLabel style={{ lineHeight: "24px", cursor: "pointer" }}>
      <BoldText
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </BoldText>
    </InputLabel>
  );
};
ItemPrimary.propTypes = {
  remitAddress: propTypes.remitAddress,
};

export const RemitAddressItem = ({ remitAddress, updateInvoice, onClose }) => {
  const handleClick = useCallback(() => {
    updateInvoice({ remitAddressId: remitAddress.id });
    onClose();
  }, [onClose, remitAddress.id, updateInvoice]);

  return (
    <RemitAddressListItem onClick={handleClick}>
      <ListItemText
        primary={<ItemPrimary remitAddress={remitAddress} />}
        secondary={
          <LocationCombined location={get(remitAddress, "location")} />
        }
      />
    </RemitAddressListItem>
  );
};
RemitAddressItem.propTypes = {
  remitAddress: propTypes.remitAddress,
  updateInvoice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
