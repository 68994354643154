import _get from "lodash/get";
import _find from "lodash/find";
import _isArray from "lodash/isArray";

export const roleCan = (role, permissionLabel) => {
  if (!role) return false;

  const findPermissionWithArray = permission => {
    return permissionLabel.includes(permission.name) && permission.active;
  };
  const permission = _isArray(permissionLabel)
    ? _find(role.permissions, findPermissionWithArray)
    : _find(role.permissions, { name: permissionLabel, active: true });
  if (!permission) return false;
  return _get(permission, "active", false);
};

export const isSuperAdmin = userRole => userRole?.name === "Super Admin";
