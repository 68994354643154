import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import _defaultTo from "lodash/defaultTo";
import { get } from "lodash";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import EditableColumn, {
  StyledEdit,
} from "../../../ui/BWGrid/columns/EditableColumn";
import Paper from "../../../mui/core/Paper";
import editableCellCreator, {
  openNextFallback,
  openPrevFallback,
} from "./editableCellCreator";
import inputs from "../../../inputs/inputConfigs/invoice";
import withFloatInputValidator from "../../../inputs/FloatInput/withFloatInputValidator";
import DateColumn from "../../../ui/BWGrid/columns/Date";
import { DashIcon } from "../../../ui/gridTableConfigs/detailPageProjects";
import Price from "../../../ui/Price";
import propTypes from "../../../../constants/propTypes";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

const columns = [
  "spec.customNumber",
  "spec.description",
  "spec.unitOfMeasure.name",
  "baseQuantity",
  "atticStock",
  "poQty",
  "overage",
  "approved",
  "otherQuantity",
  "otherOverage",
  "quantity",
  "spec.priceCents",
  "extPrice",
  "estimatedShipDate",
  "glCodes",
];

export const EarliestEstimatedShipDate = shipments => {
  if (!shipments) return <DashIcon />;

  const earliestEstimatedShipDate = shipments.reduce(
    (previous, currentShipment) => {
      return Date.parse(currentShipment.estimatedShipDate) <
        Date.parse(previous) || !previous
        ? currentShipment.estimatedShipDate
        : previous;
    },
    null
  );

  if (!earliestEstimatedShipDate) return <DashIcon />;

  return (
    <DateColumn date={earliestEstimatedShipDate} format={PRIMARY_DATE_FORMAT} />
  );
};

const renderInvoiceQty = ({ id, quantity }) =>
  id && <EditableColumn placeholder="Add">{quantity}</EditableColumn>;

export const getColumnOptions = (invoiceSpecs, projectCurrency) => ({
  "spec.customNumber": { title: "Number", filter: true, bold: true },
  "spec.description": {
    title: "Description",
    filter: true,
    fill: true,
    minWidth: "400px",
  },
  "spec.unitOfMeasure.name": { title: "UOM" },
  baseQuantity: {
    title: "Qty",
    wordWrapEnabled: true,
    render: ({ spec }) => {
      return spec && spec.baseQuantity;
    },
  },
  atticStock: {
    title: "Attic Stock",
    wordWrapEnabled: true,
    render: ({ spec }) => {
      return spec && spec.atticStock;
    },
  },
  poQty: {
    title: "PO Qty",
    wordWrapEnabled: true,
    render: ({ spec }) => {
      return spec && spec.totalQuantityWithoutOverage;
    },
  },
  overage: {
    title: "Overage",
    wordWrapEnabled: true,
    render: ({ spec }) => {
      return spec && spec.overageValue;
    },
  },
  otherQuantity: {
    title: "Other Invoice",
    wordWrapEnabled: true,
    render: ({ otherQuantity }) => _defaultTo(otherQuantity, 0),
  },
  otherOverage: {
    title: "Other Overage",
    wordWrapEnabled: true,
    render: ({ otherOverage }) => otherOverage,
  },
  approved: {
    render: ({ shippedItems }) => `${_defaultTo(shippedItems, 0)}`,
  },
  quantity: {
    title: "Invoice Qty",
    wordWrapEnabled: true,
    editable: true,
    render: renderInvoiceQty,
    editableOptions: {
      beforeSave: (value, { name }) => {
        const parsedValue = isNaN(parseFloat(value)) ? null : parseFloat(value);
        const { id, fieldName } = getRowIdFieldNameObj(name);
        const invoiceSpec = invoiceSpecs.find(
          invoiceSpec => invoiceSpec.id == id
        );
        invoiceSpec[fieldName] = parsedValue;
        invoiceSpec.extPrice =
          (invoiceSpec.spec.priceCents / 100) * parsedValue;
        return parsedValue;
      },
    },
  },
  "spec.priceCents": {
    title: "Price",
    render: function CurrencyColumn(row) {
      const { currency } = projectCurrency;
      return <Price number={row.spec?.price} currency={currency} />;
    },
  },
  extPrice: {
    title: "Ext. Price",
    render: function CurrencyColumn(row) {
      const { currency } = projectCurrency;
      return row.id && <Price number={row.extPrice} currency={currency} />;
    },
  },
  estimatedShipDate: {
    title: "Expected Ship Date",
    render: ({ spec }) => EarliestEstimatedShipDate(spec?.shipments),
    sortingEnabled: false,
  },
  glCodes: {
    title: "GL Codes",
    render: ({ spec }) => spec?.glCodes?.map(({ code }) => code).join(", "),
    sortingEnabled: false,
  },
});

export const getRowIdFieldNameObj = value => {
  const split = value.split("-");
  return { id: split[1], fieldName: split[0] };
};

export const subtractVal = (a, b) => {
  const numberOfDecimalPlaces = x => {
    if (!x || x.toString().indexOf(".") === -1) {
      return 1;
    }
    return x.toString().length - x.toString().indexOf(".");
  };
  const factor = Math.max(numberOfDecimalPlaces(a), numberOfDecimalPlaces(b));
  return (
    (Math.round(a * Math.pow(10, factor)) -
      Math.round(b * Math.pow(10, factor))) /
    Math.pow(10, factor)
  );
};

const rowInputs = {
  quantity: {
    ...inputs.invoiceQuantity,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};

export const validateFloatInput = (obj, row) => {
  const otherQuantityWithOverage = _defaultTo(row.otherQuantityWithOverage, 0);
  const totalQuantity =
    row.shippedItems !== get(row, "spec.totalQuantityWithoutOverage")
      ? row.shippedItems
      : get(row, "spec.totalQuantity");
  const maxValue = subtractVal(totalQuantity, otherQuantityWithOverage);
  const minValue = -1 * otherQuantityWithOverage;
  const quantity = obj[Object.keys(obj)[0]];
  const valid =
    !quantity ||
    (parseFloat(quantity) <= maxValue && parseFloat(quantity) >= minValue);
  return {
    valid,
    helperText: `Must be between ${minValue} and ${maxValue}`,
  };
};

const SpecsList = ({
  specs,
  projectCurrency,
  readOnly,
  loading,
  openInvoiceDataInput,
}) => {
  const paperRef = useRef();
  const columnOptionsUpdated = { ...getColumnOptions(specs, projectCurrency) };

  if (readOnly) {
    columnOptionsUpdated.quantity.editable = false;
    columnOptionsUpdated.quantity.render = ({ quantity }) => {
      return quantity || 0;
    };
  } else {
    columnOptionsUpdated.quantity.editable = true;
    columnOptionsUpdated.quantity.render = renderInvoiceQty;
  }

  const tableComponent = useMemo(
    () => ({
      CellComponent: withFloatInputValidator(
        editableCellCreator(
          rowInputs,
          ({ row: { id } }, { name }) => `${name}-${id}`,
          openNextFallback(paperRef, openInvoiceDataInput),
          openPrevFallback(paperRef)
        ),
        validateFloatInput
      ),
    }),
    [openInvoiceDataInput]
  );

  return (
    <Paper ref={paperRef}>
      <BWGridLocal
        rows={specs}
        showSelectionColumn={false}
        gridConfig={{ pageSize: 10 }}
        tableComponents={tableComponent}
        editableNavigationDirection="horizontal"
        emptyStateProps={{
          intent: "empty",
          size: "small",
          intensity: "strong",
          padding: "24px",
        }}
        emptyStateText="No Specs Selected"
        isLoading={loading}
        omitSelector
      >
        <GridHeader subheaderText={pluralize("Item", specs.length, true)} />
        <GridTable columns={columns} columnOptions={columnOptionsUpdated} />
      </BWGridLocal>
    </Paper>
  );
};

SpecsList.defaultProps = {
  specs: [],
};

SpecsList.propTypes = {
  specs: PropTypes.array,
  projectCurrency: propTypes.projectCurrency,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  openInvoiceDataInput: PropTypes.func.isRequired,
};
export default SpecsList;
