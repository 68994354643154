import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TableRow } from "./TableRow";
import Subheader from "../../../../../ui/Typography/Subheader";
import { ColumnRow } from "./ColumnRow";
import Loader, { LoaderContext } from "../../../../../ui/Loader/Loader";
import { useLoading } from "../../../../../hooks/useDatacomponent";
import { tablesDataComponentId } from "..";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #cceeff44;
  flex: 1;
`;

export const TableList = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #c7c7c7;
  border-top: 1px solid #c7c7c7;
  height: 50%;
`;

export const ColumnList = styled(TableList)`
  flex: 1;
  border-top: 0px solid #c7c7c7;
`;

const List = styled.div`
  overflow-y: scroll;
  div:last-child {
    border-bottom: 0px;
  }
`;

export const Label = styled(Subheader)`
  font-size: 12px;
  color: unset;
  text-align: center;
  border-bottom: 2px solid #c7c7c7;
  background-color: #e2f2ff;
`;

export const EditorHelper = ({ tables, replaceText }) => {
  const [selectedTableIndex, setSelectedTableIndex] = useState(null);

  const columns =
    selectedTableIndex !== null ? tables[selectedTableIndex].columns : [];
  const selectedTable = tables[selectedTableIndex];

  const loading = useLoading(tablesDataComponentId);

  const handleTableDoubleClick = tableName => () =>
    replaceText(`"${tableName}"`);
  const handleColumnDoubleClick = columnName => () =>
    replaceText(`"${selectedTable.name}"."${columnName}"`);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Wrapper>
        <TableList>
          <Label>Tables</Label>
          <List>
            <Loader height="25px" count={25}>
              {tables.map(({ name, type }, i) => (
                <TableRow
                  key={i}
                  name={name}
                  type={type}
                  isSelected={selectedTableIndex === i}
                  onClick={() => setSelectedTableIndex(i)}
                  onDoubleClick={handleTableDoubleClick(name)}
                />
              ))}
            </Loader>
          </List>
        </TableList>
        <ColumnList>
          <Label>Columns</Label>
          <List>
            <Loader height="25px" count={25}>
              {columns.map(({ name, type }, i) => (
                <ColumnRow
                  key={i}
                  name={name}
                  type={type}
                  onDoubleClick={handleColumnDoubleClick(name)}
                />
              ))}
            </Loader>
          </List>
        </ColumnList>
      </Wrapper>
    </LoaderContext.Provider>
  );
};

EditorHelper.propTypes = {
  tables: PropTypes.array.isRequired,
  replaceText: PropTypes.func.isRequired,
};
