import styled from "styled-components";
import BaseText from "./BaseText";

const LightBoldText = styled(BaseText)`
  color: #7a8398;
  font-size: 14px;
  font-weight: bold;
`;
LightBoldText.displayName = "LightBoldText";

export default LightBoldText;
