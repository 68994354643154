import React, { Fragment } from "react";
import GeneralProjects from "./ProjectsContainer";
import ClientProjects from "./ClientProjects";

const Projects = props => {
  return (
    <Fragment>
      <GeneralProjects {...props} />
      <ClientProjects {...props} />
    </Fragment>
  );
};

export default Projects;
