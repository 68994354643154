import React from "react";
import styled from "styled-components";
import Assignment from "@material-ui/icons/Assignment";

import { BWIntent } from "../../../ui/BWIntent";
import Paper from "../../../mui/core/Paper";

export const DropdownMenuContainer = styled.div`
  padding: 3px 17px 0;
  display: flex;
`;

export const Status = styled(({ intent, opacity, ...props }) => (
  <div {...props} />
))`
  color: ${({ colors }) => BWIntent.values.tableText[colors.intent]};
  opacity: ${({ colors }) => colors.opacity || "1"};
  font-weight: ${() => "bold"};
`;

export const DateFollowUpIcon = styled(Assignment)`
  margin-bottom: -3px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.24);
`;

export const Wrapper = styled(Paper)`
  table {
    min-width: auto !important;
  }
`;
