// Set up your root reducer here...
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./authReducer";
import layoutReducer from "./layoutReducer";
import dataComponentReducer from "./dataComponentReducer";
import reduxAdapter from "@blue-chip/redux-adapter";
import notesReducer from "./notesReducer";
import reportReducer from "./reportsReducer";
import filesReducer from "./filesReducer";
import queuesReducer from "./queuesReducer";
import { bidGroupsReducer } from "./bidGroupsReducer";
import collapsibleItemReducer from "./collapsibleItemReducer";
import editableTextDetailRowReducer from "./editableTextDetailRowReducer";
import dataComponentResourceReducer from "./dataComponentResourceReducer";
import scopedCurrencyReducer from "./scopedCurrencyReducer";
import * as actionTypes from "../constants/ActionTypes";
import statusReducer from "./statusReducer";
import scopeReducer from "./scopeReducer";

export const createRootReducer = appReducer => {
  return (state, action) => {
    if (
      action.type === actionTypes.LOGOUT_SUCCESS ||
      action.type === actionTypes.CLEANUP_STATE
    ) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
};

export default history => {
  const appReducer = combineReducers({
    resources: reduxAdapter.resourcesReducer,
    router: connectRouter(history),
    auth: authReducer,
    layout: layoutReducer,
    dataComponents: dataComponentReducer,
    notes: notesReducer,
    reports: reportReducer,
    files: filesReducer,
    queues: queuesReducer,
    bidGroups: bidGroupsReducer,
    collapsibleItems: collapsibleItemReducer,
    editableTextDetailRow: editableTextDetailRowReducer,
    dataComponentResources: dataComponentResourceReducer,
    scopedCurrency: scopedCurrencyReducer,
    scope: scopeReducer,
    status: statusReducer,
  });

  return createRootReducer(appReducer);
};
