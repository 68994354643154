import React, { useState, useCallback, useMemo } from "react";
import { connect } from "formik";
import { useEffect } from "react";
import get from "lodash.get";
import isEmpty from "lodash/isEmpty";

import MUITextField from "../../mui/core/TextField";
import useAuthUser from "../../hooks/useAuthUser";
import textFieldUtils, { scopeConfig } from "./textFieldUtils";

const shouldUpdateOnBlur = (formattedValue, evt, value) =>
  (formattedValue && formattedValue != value) ||
  (isEmpty(evt.target.value) && !isEmpty(value));

export function TextField({
  onChange,
  onChangeWrapper,
  onBlur,
  value,
  formik,
  openFormat,
  onKeyDown,
  ...props
}) {
  const user = useAuthUser();
  const scopeName = get(user, "office.scope.name", "USA");
  const { parseValue, isInvalidChange, getFormattedValue } = useMemo(
    () => textFieldUtils(scopeConfig[scopeName]),
    [scopeName]
  );
  const parsedValue = parseValue(value);
  const [inlineValue, setInlineValue] = useState(parsedValue);
  const formikContext = formik || {};
  const { setFieldValue } = formikContext;

  useEffect(() => {
    setInlineValue(parseValue(value));
  }, [parseValue, value]);

  const handleChange = useCallback(
    evt => {
      if (isInvalidChange(evt, inlineValue)) {
        return;
      }
      const shortcut = evt.target.value == "t";
      const formattedValue = getFormattedValue(evt);
      setInlineValue(shortcut ? formattedValue : evt.target.value);
    },
    [getFormattedValue, inlineValue, isInvalidChange]
  );

  const handleBlur = useCallback(
    evt => {
      const formattedValue = getFormattedValue(evt);
      if (shouldUpdateOnBlur(formattedValue, evt, value)) {
        setInlineValue(formattedValue);
        const parsedValue = formattedValue || null;
        setFieldValue && setFieldValue(props.name, parsedValue);
        onChangeWrapper && onChangeWrapper(parsedValue);
      }
    },
    [getFormattedValue, onChangeWrapper, props.name, setFieldValue, value]
  );

  const handleKeyDown = useCallback(
    evt => {
      const formattedValue = getFormattedValue(evt);
      if (
        evt.key == "Enter" &&
        shouldUpdateOnBlur(formattedValue, evt, value)
      ) {
        handleBlur(evt);
        return;
      }
      onKeyDown && onKeyDown(evt);
    },
    [getFormattedValue, handleBlur, onKeyDown, value]
  );

  return (
    <MUITextField
      {...props}
      value={inlineValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
}
export default connect(TextField);
