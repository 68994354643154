import React from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import { basicContractGroup } from "./createContractFields";
import ProjectAddressGrid from "./ProjectAddressGrid";
import PageSection from "../../../layout/PageSection";
import ContractFilesGrid from "./ContractFilesGrid";
import FormGrid from "../../../forms/FormGrid";
import propTypes from "../../../../constants/propTypes";
import { roleCan } from "../../../../utils/roleUtils";
import { useSetClientPricing } from "./useSetClientPricing";
import { useModalControl } from "../../../inputs/DragDrop/DragDropInputFile/useModalControl";
import { useGetFields } from "./useGetFields";

const ContractSection = ({
  client,
  role,
  setFieldValue,
  isUpdate,
  ...props
}) => {
  const { feeBasis, contractFiles } = props.values;

  useSetClientPricing({ feeBasis, isUpdate, setFieldValue, client });

  const { open: dragDropModalOpen, onOpen, onClose } = useModalControl();

  const hasContracts = contractFiles.length > 0;

  const canUpdateContractedProjectNameScope =
    !hasContracts || roleCan(role, "update-contracted-project-name-scope");

  const fields = useGetFields({ client, role, setFieldValue, ...props });

  if (!client) {
    return null;
  }

  return (
    <PageSection
      headerText="Contract"
      actions={
        roleCan(role, "upload-contract-file")
          ? [
              {
                icon: <AddCircle />,
                text: "UpLoad File",
                handler: onOpen,
              },
            ]
          : []
      }
    >
      <ContractFilesGrid
        client={client}
        values={props.values}
        dragDropModalOpen={dragDropModalOpen}
        handleCloseDragDropModal={onClose}
      />
      <FormGrid
        fields={basicContractGroup({
          hasContracts,
          canUpdateContractedProjectNameScope,
        })}
        {...props}
      />
      <ProjectAddressGrid {...props} />
      <FormGrid fields={fields} {...props} />
    </PageSection>
  );
};

export default ContractSection;

ContractSection.propTypes = {
  values: PropTypes.shape({}),
  role: propTypes.userRole,
  isUpdate: PropTypes.bool,
  currencies: PropTypes.array,
  workScopes: PropTypes.array,
};
