import SelectWithError from "../../inputs/SelectWithError";
import DatePickerWithError from "../DatePickerWithError";
import User from "../../../models/User";

export default {
  rfqUserid: {
    InputComponent: SelectWithError,
    label: "From",
    name: "userId",
    dataComponentId: "select-users-sorted",
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: User,
      route: "users",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    fullWidth: true,
    required: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  responseDate: {
    InputComponent: DatePickerWithError,
    label: "Response Date",
    name: "responseDate",
    fullWidth: true,
  },
  deliveryDate: {
    InputComponent: DatePickerWithError,
    label: "Delivery Date",
    name: "deliveryDate",
    fullWidth: true,
  },
  rfqIssueDate: {
    InputComponent: DatePickerWithError,
    label: "Issued Date",
    name: "issuedDate",
    fullWidth: true,
  },
  rfqShipDate: {
    InputComponent: DatePickerWithError,
    label: "Ship Date",
    name: "shipDate",
    fullWidth: true,
  },
};
