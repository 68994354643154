import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IconButton, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import NavigationTabs from "../Navigation/NavigationTabs";
import {
  StyledPaper,
  NavigationTabsWrapper,
  NavigationMoreMenuWrapper,
  StyledMenu,
  StyledTitle,
  StyledTabs,
  StyledTab,
} from "./components";
import propTypes from "../../../constants/propTypes";
import { replaceKeepingSearch } from "../../../actions/navigationActions";

export const handleOpenMoreMenu = (event, setAnchorElement) => {
  setAnchorElement(event.currentTarget);
};
export const handleCloseMoreMenu = setAnchorElement => {
  setAnchorElement(null);
};
export const handleMenuItemClicked = (location, setAnchorElement, push) => {
  setAnchorElement(null);
  push(location);
};
export const NavigationBar = ({
  location,
  navigationTabs,
  match,
  replaceKeepingSearch,
  loading,
  title,
  leftChildren,
  children,
  moreTabColor,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <React.Fragment>
      <StyledPaper>
        {!leftChildren && <StyledTitle>{!loading && title}</StyledTitle>}
        {leftChildren}
        <NavigationTabsWrapper>
          <NavigationTabs
            location={location}
            navigationTabs={navigationTabs}
            match={match}
            push={replaceKeepingSearch}
            Tabs={StyledTabs}
            Tab={StyledTab}
            moreTabColor={moreTabColor}
          >
            {children}
          </NavigationTabs>
        </NavigationTabsWrapper>
        <NavigationMoreMenuWrapper>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={event => handleOpenMoreMenu(event, setAnchorElement)}
          >
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="nav-menu"
            anchorEl={anchorElement}
            keepMounted
            open={Boolean(anchorElement)}
            onClose={() => handleCloseMoreMenu(setAnchorElement)}
          >
            {navigationTabs.map((navigationTab, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  handleMenuItemClicked(
                    `${match.url}${navigationTab.path}`,
                    setAnchorElement,
                    replaceKeepingSearch
                  )
                }
              >
                {navigationTab.label}
              </MenuItem>
            ))}
          </StyledMenu>
        </NavigationMoreMenuWrapper>
      </StyledPaper>
    </React.Fragment>
  );
};

NavigationBar.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  navigationTabs: propTypes.navigationTabs,
  replaceKeepingSearch: PropTypes.func,
  title: PropTypes.string,
  leftChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
  moreTabColor: PropTypes.string,
};

NavigationBar.defaultProps = {
  navigationTabs: [],
};

const mapDispatchToProps = {
  replaceKeepingSearch,
};

export default withRouter(connect(null, mapDispatchToProps)(NavigationBar));
