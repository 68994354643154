import { BaseModel } from "@blue-chip/core";
import Location from "./Location";
import Project from "./Project";
import Contact from "./Contact";

export default class ProjectSite extends BaseModel {
  static get belongsTo() {
    return [Location, Project];
  }
  static get hasMany() {
    return [Contact];
  }
}
