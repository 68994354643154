import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import _get from "lodash/get";

import { inputs } from "../../../inputs/inputConfigs";
import InputLabel from "../../../mui/core/InputLabel";
import Subheader from "../../../ui/Typography/Subheader";
import InputLoader from "../../../ui/Loader/InputLoader";
import FreightInput from "./FreightInput";

export const Text = styled(Subheader)`
  color: rgba(0, 0, 0, 0.54);
`;

const TextWrapper = styled(Subheader)`
  display: flex;
  justify-content: space-between;
`;

const LabeledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${InputLabel} {
    width: 100%;
  }
`;

export const LabeledText = ({ label, texts }) => (
  <InputLoader>
    <LabeledTextWrapper>
      <InputLabel>{label}</InputLabel>
      <TextWrapper>
        {texts.map((text, key) => (
          <Text key={key}>{text}</Text>
        ))}
      </TextWrapper>
    </LabeledTextWrapper>
  </InputLoader>
);

LabeledText.propTypes = {
  label: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(PropTypes.string),
};

export const formatSalesTaxPercent = purchaseOrder => {
  if (!purchaseOrder) return 0;
  const salesTaxPercent = _get(purchaseOrder, "project.salesTaxPercent", 0);
  if (!salesTaxPercent) return 0;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 6,
  });
  return formatter.format(salesTaxPercent * 100);
};

export const getEstimatedShip = ({ estimatedDeliveryDate }) => {
  if (estimatedDeliveryDate && moment(estimatedDeliveryDate).isValid()) {
    return {
      ...inputs.estimatedShipDate,
    };
  }
  return inputs.estimatedShipDate;
};

export const getEstimatedDelivery = ({ estimatedShipDate }) => {
  if (estimatedShipDate && moment(estimatedShipDate).isValid()) {
    return {
      ...inputs.estimatedDeliveryDate,
    };
  }
  return inputs.estimatedDeliveryDate;
};

const estimatedShipDateHandleChange = (currentValues, setFieldValue) => ({
  target: { value },
}) => {
  const { estimatedShipDate, estimatedDeliveryDate } = currentValues;
  if (
    value &&
    [estimatedShipDate, estimatedDeliveryDate].every(date => !date)
  ) {
    setFieldValue(
      "estimatedDeliveryDate",
      moment(value)
        .add(1, "weeks")
        .toDate()
    );
  }
};

// eslint-disable-next-line max-lines-per-function
const fields = (
  shipViaInput,
  freightOptions,
  purchaseOrder,
  currentValues,
  currencyFormatter,
  projectId,
  projectCurrencies,
  isProjectClosed,
  setFieldValue
) => {
  const poTotalLocal = purchaseOrder.totalPriceWithoutOverage || 0;
  const totalWithTaxesLocal = purchaseOrder.forecastTotalWithoutOverage || 0;
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.projectCurrency,
              APIOptions: undefined,
              options: projectCurrencies,
              emphasizeRequired: true,
            },
            grid: { sm: 6, lg: 3 },
          },
          {
            element: (
              <LabeledText
                label="PO Total"
                texts={[currencyFormatter.format(poTotalLocal)]}
              />
            ),
            grid: { sm: 6, lg: 3 },
          },
          {
            element: (
              <LabeledText
                label="Current Tax Estimate"
                texts={[`${formatSalesTaxPercent(purchaseOrder)}%`]}
              />
            ),
            grid: { sm: 6, lg: 3 },
          },
          {
            element: (
              <LabeledText
                label="Total with Taxes"
                texts={[currencyFormatter.format(totalWithTaxesLocal)]}
              />
            ),
            grid: { sm: 6, lg: 3 },
          },
        ],
      },
      {
        items: [
          { input: inputs.poProjectManager, grid: { xs: 12, md: 4 } },
          {
            input: {
              ...getEstimatedShip(currentValues),
              required: true,
              emphasizeRequired: true,
              handleChange: estimatedShipDateHandleChange(
                currentValues,
                setFieldValue
              ),
            },
            grid: { xs: 6, md: 4 },
          },
          {
            input: {
              ...getEstimatedDelivery(currentValues),
              required: true,
              emphasizeRequired: true,
            },
            grid: { xs: 6, md: 4 },
          },
        ],
      },
      {
        items: [
          {
            input: {
              ...inputs.poFreight,
              InputComponent: FreightInput,
              options: freightOptions,
              required: true,
              emphasizeRequired: true,
            },
          },
          {
            input: {
              ...shipViaInput,
              required: true,
              emphasizeRequired: true,
            },
          },
          { input: inputs.poFBO },
        ],
      },
      {
        items: [
          {
            input: {
              ...inputs.statusComment,
              label: "PO Internal Notes",
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { xs: 12 },
          },
        ],
      },
      {
        items: [
          {
            input: {
              ...inputs.poToClient,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.clientApproved,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.poToDesigner,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.designerApproved,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.sentToVendor,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.vendorReceived,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
          {
            input: {
              ...inputs.followUpDate,
              ignoreLabeledText: !isProjectClosed,
            },
            grid: { sm: 12, md: 6, lg: 3 },
          },
        ],
      },
    ],
  };
};

function remapOption(option, replacers) {
  const nOption = {
    ...option,
  };
  Object.keys(replacers).forEach(term => {
    nOption.name = nOption.name.replace(`[${term}]`, replacers[term]);
  });
  return nOption;
}

const getFreightOptions = (freight, vendor) => {
  if (!freight && !vendor) return [];
  const freightOptions = [];

  if (freight) {
    freightOptions.push(freight);
  }

  if (vendor && _get(vendor, "id") !== _get(freight, "id")) {
    freightOptions.push(vendor);
  }

  return freightOptions;
};
const getShipViaInput = (freight, { shipVia }) => {
  if (!freight) {
    return {
      ...inputs.poShipVia,
      options: inputs.poShipVia.options.filter(
        ({ name }) => name !== "[ProjectFreightVendor]"
      ),
    };
  }
  const replacers = freight ? { ProjectFreightVendor: freight.name } : {};
  const options = inputs.poShipVia.options.map(option =>
    remapOption(option, replacers)
  );

  const shipViaOption = options.find(({ id }) => shipVia === id);

  if (shipVia && !shipViaOption) {
    options.unshift({ id: shipVia, name: shipVia });
  }

  return {
    ...inputs.poShipVia,
    options,
  };
};
export const hasDiscount = (discount, poDiscounts) => {
  const results = poDiscounts.filter(
    poDiscount => poDiscount.discountId === discount.id
  );
  return results.length > 0;
};

export default (
  freight,
  { vendor = {}, ...purchaseOrder },
  currentValues,
  currencyFormatter,
  projectId,
  projectCurrencies,
  isProjectClosed,
  setFieldValue
) => {
  const shipViaInput = getShipViaInput(freight, currentValues);
  const freightOptions = getFreightOptions(freight, vendor);

  return fields(
    shipViaInput,
    freightOptions,
    purchaseOrder,
    currentValues,
    currencyFormatter,
    projectId,
    projectCurrencies,
    isProjectClosed,
    setFieldValue
  );
};
