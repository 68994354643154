import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

const getHandleOpenSpecDetails = ({ push, clientId, purchaseOrder }) => ({
  id,
}) => {
  push(`/clients/${clientId}/projects/${purchaseOrder.projectId}/specs/${id}`);
};

const getOnMove = ({
  purchaseOrder,
  setSelection,
  selectedIds,
  specDataComponent,
  updateSpecs,
}) => targetPOId => {
  updateSpecs(specDataComponent, targetPOId, selectedIds, purchaseOrder);
  setSelection([]);
};

const getOnSplit = ({
  purchaseOrder,
  setSelection,
  selectedIds,
  scopeId,
  openCreatePurchaseOrderModal,
}) => () => {
  openCreatePurchaseOrderModal(
    selectedIds,
    purchaseOrder.projectId,
    purchaseOrder.id,
    undefined,
    scopeId
  );
  setSelection([]);
};

const getHandleCancel = ({
  purchaseOrder: { projectId, id, number: poNumber, vendor, status },
  clientId,
  openModalDialog,
  setSelection,
}) => () => {
  const title = `${poNumber} ${vendor.name}`;
  setSelection([]);
  openModalDialog(
    ["Cancel Purchase Order", title],
    "CancelPO",
    {
      dataComponentId: "Quoting-PurchaseOrderList",
      projectId,
      id,
      clientId,
      title,
      status,
    },
    false
  );
};

const getHandleUploadQuote = ({
  selectedIds,
  openModalDialog,
  setSelection,
}) => () => {
  openModalDialog(
    ["Upload Quote", `${pluralize("Spec", selectedIds.length, true)} Selected`],
    "BulkQuoteUpload",
    { selectedIds, resetSelection: () => setSelection([]) },
    false
  );
};

export const buildActions = ({
  purchaseOrder,
  scopeId,
  clientId,
  specDataComponent,
  updateSpecs,
  push,
  setSelection,
  openCreatePurchaseOrderModal,
  openModalDialog,
  selectedIds,
}) => ({
  handleOpenSpecDetails: getHandleOpenSpecDetails({
    push,
    clientId,
    purchaseOrder,
  }),
  onMove: getOnMove({
    purchaseOrder,
    setSelection,
    selectedIds,
    specDataComponent,
    updateSpecs,
  }),
  onSplit: getOnSplit({
    purchaseOrder,
    setSelection,
    selectedIds,
    scopeId,
    openCreatePurchaseOrderModal,
  }),
  handleCancel: getHandleCancel({
    purchaseOrder,
    clientId,
    openModalDialog,
    setSelection,
  }),
  handleUploadQuote: getHandleUploadQuote({
    selectedIds,
    openModalDialog,
    setSelection,
  }),
});

export const getPONumberLabel = ({ number }) => {
  return `PO #${number}`;
};

export const getOptions = (
  disabled,
  relatedPOs,
  purchaseOrder,
  events,
  selectedCurrency
) => {
  const defaultOptions = [
    {
      content: "Upload Quote",
      onClick: events.onUploadQuote,
      disabled,
    },
    {
      content: "Cancel Purchase Order",
      onClick: events.onCancel,
      disabled: !purchaseOrder.isCancellable,
    },
    {
      content: "Remove",
      onClick: () => events.onMove(null),
      disabled: disabled || purchaseOrder.status === "Issued",
    },
  ];

  if (purchaseOrder.revisionStatus) {
    return defaultOptions;
  }

  return [
    ...defaultOptions,
    {
      content: "Split to Another Purchase Order",
      onClick: events.onSplit,
      disabled,
      useSeparator: true,
    },
    {
      content: "Move To",
      disabled: true,
      isLabel: true,
    },
    ...relatedPOs.map(
      ({ id: otherPurchaseOrderId, number, projectCurrencyId }) => ({
        content: getPONumberLabel({ number }),
        onClick: () => events.onMove(otherPurchaseOrderId),
        disabled: disabled || projectCurrencyId != selectedCurrency,
      })
    ),
  ];
};

export const PurchaseOrderListActions = ({
  onMove,
  onSplit,
  onCancel,
  onUploadQuote,
  disabled,
  relatedPOs,
  purchaseOrder,
}) => (
  <DropdownMenu
    buttonText="Actions"
    options={getOptions(
      disabled,
      relatedPOs,
      purchaseOrder,
      {
        onMove,
        onSplit,
        onCancel,
        onUploadQuote,
      },
      purchaseOrder.projectCurrencyId
    )}
  />
);

PurchaseOrderListActions.propTypes = {
  onMove: PropTypes.func.isRequired,
  onSplit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadQuote: PropTypes.func.isRequired,
  relatedPOs: PropTypes.arrayOf(propTypes.purchaseOrder).isRequired,
  disabled: PropTypes.bool,
  purchaseOrder: propTypes.purchaseOrder.isRequired,
};

export default PurchaseOrderListActions;
