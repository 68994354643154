import React, { useMemo, Fragment, useState, useCallback } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import _get from "lodash/get";
import memoizeOne from "memoize-one";
import BWModels from "benjaminwest-models";

import { FormControlLabel, Switch } from "../../../../mui/core";
import {
  BWGridAPI,
  GridHeader,
  GridTable,
  GridRow,
} from "../../../../ui/BWGrid";
import Fee from "../../../../../models/Fee";
import Paper from "../../../../mui/core/Paper";
import FeeSummary from "./FeeSummary";
import FeeActions from "./FeeActions";
import { columns, getRowMenu, getColumnOptions } from "./tableOptions";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import { RowComponent } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/genDetailRowComponents/v2";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { useListWorkScopes } from "../../../../hooks/useListWorkScopes";
import useUserRole from "../../../../hooks/useUserRole";

const getFilterBySettings = () => {
  const filterBySettings = {};
  filterBySettings.active = {
    statuses: [
      "Pending",
      "On Hold",
      "Approved",
      "Billed",
      "Paid",
      "Partially Paid",
    ],
  };
  filterBySettings.all = {
    statuses: [...filterBySettings.active.statuses, "Void"],
  };
  return filterBySettings;
};

const getTableComponents = memoizeOne(dataComponentId => {
  return {
    ...genDetailRowComponents(
      "comment",
      "id",
      BWModels.loadSchema("Fee"),
      dataComponentId,
      6,
      2,
      { helperText: "Comment" },
      false,
      { apiRoute: "fees", model: Fee },
      true
    ),
    RowComponent,
  };
});

const FeesList = ({
  dataComponent,
  generateAndDownloadReport,
  projectId,
  onRowClick,
  onOpenCreateModal,
  onUpdateStatus,
  onOpenEditModal,
  onOpenVoidModal,
  onOpenBillFeeModal,
}) => {
  const [filterBy, setFilterBy] = useState("all");
  const filterBySettings = getFilterBySettings();
  const handleFilterByToggle = useCallback(() => {
    setFilterBy(filterBy === "all" ? "active" : "all");
  }, [filterBy]);

  const formatter = useCurrencyFormatter();

  const workScopes = useListWorkScopes();

  const columnOptions = useMemo(() => getColumnOptions(formatter, workScopes), [
    formatter,
    workScopes,
  ]);

  const userRole = useUserRole();

  return (
    <Fragment>
      <FeeSummary projectId={projectId} />
      <Paper>
        <BWGridAPI
          dataComponent={dataComponent}
          model={Fee}
          apiRoute="fees"
          includes={["feePayments", "revenueSharingProject", "workScope"]}
          defaultSorting={[{ columnName: "billingDate", direction: "asc" }]}
          rootFilters={{
            $where: {
              projectId,
              status: {
                $in: filterBySettings[filterBy].statuses,
              },
            },
          }}
          tableComponents={getTableComponents(dataComponent.dataComponentId)}
          alwaysDisplayDetailRow
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Fees`}
            headerOverride={
              <FeeActions
                projectId={projectId}
                generateAndDownloadReport={generateAndDownloadReport}
              />
            }
            actions={[
              {
                text: "ADD FEE",
                icon: <AddCircle />,
                handler: onOpenCreateModal,
              },
            ]}
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            disableRow={row => _get(row, "status") === "Void"}
            onClick={onRowClick}
            rowMenu={getRowMenu(
              onOpenEditModal,
              onUpdateStatus,
              onOpenVoidModal,
              onOpenBillFeeModal(formatter),
              userRole
            )}
          />
          <GridRow>
            <FormControlLabel
              control={
                <Switch
                  checked={filterBy === "all"}
                  onChange={handleFilterByToggle}
                  color="primary"
                />
              }
              label="Show Void Fees"
            />
          </GridRow>
        </BWGridAPI>
      </Paper>
    </Fragment>
  );
};
FeesList.propTypes = {
  onOpenEditModal: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onOpenBillFeeModal: PropTypes.func.isRequired,
  onOpenVoidModal: PropTypes.func.isRequired,
};
export default FeesList;
