import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { openModalDialog } from "../../../../actions/layoutActions";

import ContingenciesSection from "./ContingenciesSection";

const name = "contingencies";

const buildOpenAddContingencyHandler = (
  openModalDialog,
  formikArrayHelpers,
  projectId
) => () => {
  openModalDialog("Create Contingency", "CreateEditContingency", {
    formikArrayHelpers,
    contingency: {
      name: undefined,
      unit: undefined,
      value: undefined,
      contingencyType: "Merchandise",
      projectId,
    },
  });
};

const buildOpenEditContingencyHandler = (
  openModalDialog,
  formikArrayHelpers
) => (contingency, { rowId }) => {
  openModalDialog(
    `Edit Contingency ${contingency.name}`,
    "CreateEditContingency",
    {
      formikArrayHelpers,
      contingency,
      index: rowId,
    }
  );
};

const buildOpenDeleteContingencyHandler = (
  openModalDialog,
  formikArrayHelpers
) => ({ id, name }, { rowId }) => {
  openModalDialog(
    `Delete ${name}`,
    "DeleteContingency",
    {
      contingencyId: id,
      title: name,
      formikArrayHelpers,
      index: rowId,
    },
    false
  );
};

export const ContingenciesSectionContainer = ({ values, openModalDialog }) => {
  const rows = values[name];
  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => (
        <ContingenciesSection
          rows={rows}
          onOpenAddModal={buildOpenAddContingencyHandler(
            openModalDialog,
            formikArrayHelpers,
            values.id
          )}
          onOpenEditModal={buildOpenEditContingencyHandler(
            openModalDialog,
            formikArrayHelpers
          )}
          onOpenDeleteModal={buildOpenDeleteContingencyHandler(
            openModalDialog,
            formikArrayHelpers
          )}
        />
      )}
    />
  );
};

ContingenciesSectionContainer.propTypes = {
  values: PropTypes.shape({}),
  openModalDialog: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default withRouter(
  connect(null, mapDispatchToProps)(ContingenciesSectionContainer)
);
