export const handleSuccess = (
  performRetrieveListRequest,
  areaDataComponentId,
  projectId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog
) => {
  performRetrieveListRequest(areaDataComponentId, {
    rootFilters: {
      $where: { projectId },
    },
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
  });
  showSnackNotificationAction(snackMessage);
  closeModalDialog && closeModalDialog();
};
export const handleError = (data, showSnackNotificationAction) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};
