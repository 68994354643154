import React from "react";
import styled from "styled-components";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowDown";

export const Content = styled.div({});

export const DetailsContainer = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 0px 24px 0px 0px;
  padding-top: ${({ isExpaned }) => (isExpaned ? "0px" : "28px")};
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: rgb(87, 171, 255);
  z-index: 1;
  bottom: 0px;
`;

export const DetailsTitle = styled.span`
  text-transform: uppercase;
  font-weight: 600;
`;

export const ExpandableGridContainer = styled(({ isExpaned, ...props }) => (
  <div {...props} />
))`
  position: relative;
  padding: 24px;
`;

export const CollapsesContent = styled.div({});
export const ExpandedContent = styled.div`
  margin-top: 24px;
`;

export const DescriptionIcon = styled(({ isExpaned, ...props }) => (
  <KeyboardArrowUp {...props} />
))`
  transform: ${({ isExpaned }) => (isExpaned ? "rotate(180deg)" : "none")};
  transition: transform 0.5s;
`;
