import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QuotingFilter from "./QuotingFilter";

import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";

import { getBluechipResources } from "../../../../utils/bluechipUtils";

import {
  initDataComponent,
  destroyDataComponentResource,
} from "../../../../actions/dataComponentActions";
import { fetchElementIds, filterDCId } from "../../../../actions/filterActions";

export const mapStateToProps = (state, { dataComponentId }) => {
  const sourceDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const flattenedFilterDataComponent = getDataComponentFlattenedRequestState(
    filterDCId,
    state
  );

  const elements =
    getBluechipResources(flattenedFilterDataComponent, state) || [];

  return {
    loading: flattenedFilterDataComponent.loading,
    elementIds: elements.map(({ id }) => id),
    sourceDataComponent,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { initDataComponent, fetchElementIds, destroyDataComponentResource },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotingFilter);
