import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";

import TagText from "../../../../ui/Tags/TagText";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const StyledTagText = styled(TagText)`
  margin-right: 16px;
  margin-left: 16px;
`;

export const StatusChip = ({ property, emptyTitle, nonEmptyTitle }) => {
  const isSet = !!property;
  const status = isSet
    ? `${nonEmptyTitle} ${moment(property).format(PRIMARY_DATE_FORMAT)}`
    : emptyTitle;

  if (status === emptyTitle && !emptyTitle) {
    return null;
  }

  return (
    <StyledTagText
      intent={isSet ? "success" : "none"}
    >{`${status}`}</StyledTagText>
  );
};

StatusChip.propTypes = {
  property: PropTypes.string,
  emptyTitle: PropTypes.string,
  nonEmptyTitle: PropTypes.string,
};
