import React from "react";
import PropTypes from "prop-types";

import Item from "./Item";

const DraggableList = ({ values, onRemove, onEdit, errors, touched }) =>
  values.specCategoryDetails.map((detail, key) => (
    <Item
      detail={detail}
      onRemove={onRemove}
      onEdit={onEdit}
      key={key}
      index={key}
      errors={errors}
      touched={touched}
    />
  ));
DraggableList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  values: PropTypes.shape({
    specCategoryDetails: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isEditing: PropTypes.bool,
      })
    ),
  }).isRequired,
};

export default DraggableList;
