import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ConsultingCompany from "../../../../models/ConsultingCompany";
import ConsultingCompanyForm from "./ConsultingCompanyForm";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import {
  performCreateRequest,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import propTypes from "../../../../constants/propTypes";

const dataComponentId = "CreateConsultingCompany";

export class CreateConsultingCompanyContainer extends Component {
  componentDidMount() {
    this.props.initDataComponent(
      dataComponentId,
      ConsultingCompany,
      ["location"],
      "consulting-companies"
    );
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  createConsultingCompany = (consultingCompany, formikActions) => {
    const { performCreateRequest } = this.props;

    performCreateRequest(dataComponentId, consultingCompany);
    this.setState({ formikActions });
  };

  render() {
    return (
      <ConsultingCompanyForm
        sendButtonText="CREATE"
        onSubmit={this.createConsultingCompany}
        dataComponentId={dataComponentId}
      />
    );
  }
}

CreateConsultingCompanyContainer.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  role: propTypes.userRole.isRequired,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponent(dataComponentId, state),
});

export const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performCreateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateConsultingCompanyContainer);
