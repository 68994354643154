import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import FormGrid from "../../../../forms/FormGrid";
import FormikForm from "../../../../forms/FormikForm";
import ActionButtons from "../../../../forms/ActionButtons";
import createShipmentFields from "../CreateShipment/createShipmentFields";

const BulkEditShipmentForm = ({ onSubmit, loading, initialValues }) => {
  return (
    <FormikForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={BWModels.loadSchema("Shipment")}
      ignoreValidationFieldNames={[
        "specId",
        "quantity",
        "trackingNumber",
        "receivedBy",
      ]}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={createShipmentFields(null, values)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons loading={loading} isModal onSend={handleSubmit} />
        </Fragment>
      )}
    </FormikForm>
  );
};

BulkEditShipmentForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({}),
};

export default BulkEditShipmentForm;
