import React from "react";
import styled from "styled-components";

import SectionHeader from "../../../layout/SectionHeader";
import SelectionActions from "./SelectionActions";
const StyledHeader = styled(SectionHeader)`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const Header = props => {
  return <StyledHeader headerOverride={<SelectionActions />} {...props} />;
};

export default Header;
