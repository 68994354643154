import { createSelectorCreator, defaultMemoize } from "reselect";
import _isEqual from "fast-deep-equal";
import _pick from "lodash/pick";

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _isEqual
);

export const buildOverridableAttrSelector = attrs => {
  const selector = createDeepEqualSelector(
    ({ subset }) => subset,
    subset => subset
  );

  return entity => {
    const subset = _pick(entity, attrs);
    return selector({ subset });
  };
};
