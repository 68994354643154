import React, { Component } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";

import Paper from "../../mui/core/Paper";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import * as tableConfigs from "../../../components/ui/gridTableConfigs/detailPageProjects";
import { rowMenuFactory } from "../Projects/ProjectsPage";
import FavoriteControl from "../Projects/FavoriteControl";
import { columnOptions as detailPageProjectColumnOptions } from "../../ui/gridTableConfigs/detailPageProjects";

export const columns = [
  "isFavorite",
  "number",
  "name",
  "type",
  "status",
  "property.name",
  "startDate",
  "endDate",
];
export const columnOptions = {
  isFavorite: {
    title: " ",
    render: function favorite(row) {
      return <FavoriteControl projectId={row.id} />;
    },
  },
  number: { title: "Number", bold: true, filter: true },
  name: { title: "Project Name", width: "172px", filter: true },
  status: detailPageProjectColumnOptions.status,
  "property.name": detailPageProjectColumnOptions["property.name"],
  startDate: detailPageProjectColumnOptions.startDate,
  endDate: detailPageProjectColumnOptions.endDate,
};

export default class ClientProjectsPage extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
    loggedInUserId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    openNotesModal: PropTypes.func.isRequired,
  };

  handleAddProjectClick = () => {
    this.props.push(`projects/create`);
  };

  handleRowClick = ({ id }) => {
    this.props.push(`projects/${id}`);
  };

  render() {
    const { dataComponent, clientId, loggedInUserId } = this.props;

    return (
      <Paper>
        <BWGridAPI
          {...this.props}
          rootFilters={{
            $where: {
              "property.entity.clientId": clientId,
            },
          }}
          defaultSorting={[{ columnName: "number", direction: "desc" }]}
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Projects`}
            actions={[
              {
                text: "ADD PROJECT",
                icon: <AddCircle />,
                handler: this.handleAddProjectClick,
              },
            ]}
          />
          <GridTable
            columns={columns}
            columnOptions={{
              ...columnOptions,
              star: tableConfigs.makeStarColumnOptions(
                loggedInUserId,
                this.props.dataComponent
              ),
            }}
            rowMenu={rowMenuFactory(this.props)}
            onClick={this.handleRowClick}
          />
        </BWGridAPI>
      </Paper>
    );
  }
}
