import { connect } from "react-redux";

import { openQuickEditModalAction } from "../../../../actions/specsActions";
import {
  updateBidGroupVendor,
  dataComponentVendorId,
} from "../../../../actions/bidGroupActions";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import Vendors from "./Vendors";
import {
  initDataComponent,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import {
  setAutoSaveComponentId,
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { openVendorEmailPreview } from "../../../../actions/vendorActions";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { dataComponentId } from "../BidGroupContainer";

export const bidGroupDataComponentId = "BidGroupDataComponentId";
export const specsGridDataComponentId = "SpecsGrid";

export const mapStateToProps = (state, { match, history }) => {
  return {
    bidGroupId: match.params.bidGroupId,
    projectId: match.params.projectId,
    history,
    clientId: match.params.clientId,
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    specsGridDataComponent: getDataComponentFlattenedRequestState(
      specsGridDataComponentId,
      state
    ),
    isModalOpen: state.layout.modalDialog.isOpen,
    role: state.auth.role,
    updateVendorSpecDataComponent: getDataComponent(
      dataComponentVendorId,
      state,
      REQUEST_TYPES.UPDATE
    ),
  };
};

export const mapDispatchToProps = {
  openQuickEditModalAction,
  openVendorEmailPreview,
  initDataComponent,
  performFindRequest,
  setAutoSaveComponentId,
  closeModalDialog,
  showSnackNotificationAction,
  updateBidGroupVendor,
  pushWithReturnUrl,
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
