import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BaseModel } from "@blue-chip/core";

import useActions from "./useActions";
import {
  destroyDataComponent,
  initDataComponent,
  performFindRequest,
} from "../../actions/dataComponentActions";
import { getDataComponentFlattenedRequestState } from "../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../constants/RequestTypes";

export const invoicePaymentsDataComponentId = "cancel-issue-data-component-id";

export default function useInvoicePayments(purchaseOrderId) {
  const actions = useActions({
    initDataComponent,
    performFindRequest,
    destroyDataComponent,
  });

  useEffect(() => {
    actions.initDataComponent(
      invoicePaymentsDataComponentId,
      BaseModel,
      [],
      "purchase-orders/invoice-payments",
      false,
      "v2"
    );

    return () => actions.destroyDataComponent(invoicePaymentsDataComponentId);
  }, [actions]);

  useEffect(() => {
    if (purchaseOrderId) {
      actions.performFindRequest(
        invoicePaymentsDataComponentId,
        purchaseOrderId
      );
    }
  }, [actions, purchaseOrderId]);

  return useSelector(state => {
    const flattenedDataComponent = getDataComponentFlattenedRequestState(
      invoicePaymentsDataComponentId,
      state,
      REQUEST_TYPES.FIND
    );
    return {
      invoicePayments:
        getBluechipResources(flattenedDataComponent, state) || {},
      loading: flattenedDataComponent.loading,
    };
  });
}
