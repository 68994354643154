import React, { useEffect } from "react";
import isMobile from "ismobilejs";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";

import propTypes from "../../../../../constants/propTypes";
import Project from "../../../../../models/Project";
import { dataComponentId, summaryDataComponentId } from "./index";
import ProjectSummary from "../../../../../models/ProjectSummary";
import ProjectHeader from "../../ProjectHeader";
import { LoaderContext } from "../../../../ui/Loader";

const navigationTabs = [
  { path: "/overview", label: "Overview" },
  { path: "/reports", label: "Reports" },
];
// eslint-disable-next-line sonarjs/cognitive-complexity
const ProjectDetailContainer = props => {
  const {
    route,
    match,
    projectId,
    project,
    initDataComponent,
    performFindRequest,
    setHeaderTitle,
    loading,
  } = props;
  const isMobileBrowser = isMobile(window.navigator).any;

  useEffect(() => {
    isMobileBrowser && project
      ? setHeaderTitle(`${project.name}`)
      : setHeaderTitle("Project Detail");
  }, [isMobileBrowser, setHeaderTitle, project]);
  useEffect(() => {
    initDataComponent(
      dataComponentId,
      Project,
      [
        "property.[entity.[client],location]",
        "projectMemberUsers.user",
        "projectMemberContacts.contact",
        "areas.[areaType,areaRooms]",
        "remitAddresses",
        "projectLead",
        "projectManager",
        "designer",
        "generalContractor",
        "otherConsultant",
        "office",
        "notesAuthor",
        "workScopes",
      ],
      "projects"
    );
    performFindRequest(dataComponentId, projectId);
    initDataComponent(
      summaryDataComponentId,
      ProjectSummary,
      [],
      "projects/summary"
    );
    performFindRequest(summaryDataComponentId, projectId);
  }, [initDataComponent, performFindRequest, projectId]);
  return (
    <LoaderContext.Provider value={{ loading }}>
      <ProjectHeader project={project} navigationTabs={navigationTabs} />
      {renderRoutes(route.routers, { project, match })}
    </LoaderContext.Provider>
  );
};
ProjectDetailContainer.propTypes = {
  route: propTypes.route,
  match: PropTypes.object,
  role: propTypes.userRole,
  projectId: PropTypes.string.isRequired,
  project: propTypes.project,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  setHeaderTitle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ProjectDetailContainer.defaultProps = {
  projectId: "",
  project: {},
  initDataComponent: () => {},
  performFindRequest: () => {},
};
export default ProjectDetailContainer;
