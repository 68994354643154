import React from "react";
import PropTypes from "prop-types";

import CreateAreaForm from "./CreateAreaForm";
import propTypes from "../../../../../constants/propTypes";

const CreateAreaLocal = props => {
  const {
    formikArrayHelpers,
    closeModalDialog,
    areaTypes,
    area,
    index,
    onCreateArea,
    onUpdateArea,
  } = props;
  const isEditing = typeof index !== "undefined";

  const handleCreateArea = (area, areaRooms) => {
    area.areaRooms = areaRooms;

    onCreateArea(area, formikArrayHelpers);
  };

  const handleEditArea = (area, areaRooms) => {
    area.areaRooms = areaRooms;
    area.areaTypeId = area.areaType.id;

    onUpdateArea(area, index, formikArrayHelpers);
  };
  return (
    <CreateAreaForm
      isEditing={isEditing}
      area={area}
      areaTypes={areaTypes}
      createArea={isEditing ? handleEditArea : handleCreateArea}
      closeModalDialog={closeModalDialog}
    />
  );
};

function requireAtLeastOne(checkProps) {
  return function(props, propName, compName) {
    const requirePropNames = Object.keys(checkProps);
    const found = requirePropNames.find(propRequired => props[propRequired]);
    try {
      if (!found) {
        throw new Error(
          `One of ${requirePropNames.join(
            ", "
          )} is required by '${compName}' component.`
        );
      }
      PropTypes.checkPropTypes(checkProps, props, propName, compName);
    } catch (e) {
      return e;
    }
    return null;
  };
}

const requiredAreaAction = requireAtLeastOne({
  onCreateArea: PropTypes.func,
  onUpdateArea: PropTypes.func,
});

CreateAreaLocal.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  areaTypes: PropTypes.array,
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  onCreateArea: requiredAreaAction,
  onUpdateArea: requiredAreaAction,
  area: propTypes.area,
  index: PropTypes.number,
};

export default CreateAreaLocal;
