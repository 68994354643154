import React from "react";
import PropTypes from "prop-types";
const BWModels = require("benjaminwest-models");

import Paper from "../../../../../mui/core/Paper";
import Loader from "../../../../../ui/Loader";
import propTypes from "../../../../../../constants/propTypes";
import { ItemWrapper, OptionsWrapper, Title, Value } from "./components";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import { getFeeValue } from "../../../Overview/ContractStatusSection";

export const dataComponentId = "freeSummaryId";

export const ColumnItem = ({ row }) => {
  return (
    <OptionsWrapper>
      {row.map(({ title, value }, key) => (
        <ItemWrapper key={key} emptyColumn={!title}>
          {title && (
            <Title>
              <Loader width="200px">{title}</Loader>
            </Title>
          )}
          <Value noTitle={!title}>
            <Loader width="200px">{value}</Loader>
          </Value>
        </ItemWrapper>
      ))}
    </OptionsWrapper>
  );
};

ColumnItem.propTypes = {
  row: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
    })
  ),
};

const FeeSummary = ({ feeSummary }) => {
  const currencyFormatter = useCurrencyFormatter();
  const Project = BWModels.loadSchema("Project");
  const projectTier = Project.tierPricingHelpers.getProjectTier(
    feeSummary,
    feeSummary.clientPricing
  );
  const values = [
    [
      {
        title: "Contract Budget",
        value: currencyFormatter.format(feeSummary.contractBudget),
      },
      {
        title: feeSummary.shouldTotalIncludeAllSpecs
          ? "Actual PO Total"
          : "Actual Merchandise Total",
        value: currencyFormatter.format(feeSummary.actualTotal),
      },
    ],
    [
      {
        title: "Fee basis",
        value:
          feeSummary.feeBasis === "Tiered Pricing"
            ? `Tiered Pricing (Tier ${projectTier.sequenceIndex})`
            : feeSummary.feeBasis,
      },
      { title: "Fee Percentage", value: feeSummary.feePercentage || "-" },
    ],
    [
      {
        title: "Number of Fees Billed",
        value: feeSummary.numberFeesBilled || 0,
      },
      {
        title: "Number of Fees to be Billed",
        value: feeSummary.numberFeesToBilled,
      },
    ],
    [
      {
        title: "Contracted Fees",
        value: getFeeValue(currencyFormatter, feeSummary),
      },
      {
        title: "Billable Fees",
        value: currencyFormatter.format(feeSummary.billableFees),
      },
    ],
    [
      {
        title: "Fees Paid",
        value: currencyFormatter.format(feeSummary.feesPaid),
      },
      {
        title: "Fees Billed to Date",
        value: currencyFormatter.format(feeSummary.feesBilledToDate),
      },
    ],
    [
      {
        title: "True-up",
        value:
          feeSummary.trueUp === null
            ? "-"
            : currencyFormatter.format(feeSummary.trueUp),
      },

      {
        title: "Fees to be Billed",
        value: currencyFormatter.format(feeSummary.feesToBeBilled),
      },
    ],
  ];
  return (
    <Paper>
      <div>
        {values.map((row, key) => (
          <ColumnItem row={row} key={key} />
        ))}
      </div>
    </Paper>
  );
};

FeeSummary.propTypes = {
  feeSummary: propTypes.feeSummary,
};

FeeSummary.defaultProps = {
  feeSummary: {},
};

export default FeeSummary;
