import ClientPricing from "../ClientPricing";

describe("ClientPricing model", () => {
  test("belongsTo relations", () => {
    expect(
      ClientPricing.belongsTo.map(relation => relation.name)
    ).toMatchSnapshot();
  });
  test("hasMany relations", () => {
    expect(
      ClientPricing.hasMany.map(relation => relation.name)
    ).toMatchSnapshot();
  });
});
