import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";
import _get from "lodash/get";

import PARBasedQuantityCalculationForm from "./PARBasedQuantityCalculationForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import FormikForm from "../../../forms/FormikForm";
import { FieldArray } from "formik";

import {
  initDataComponent,
  performUpdateRequest,
  performCreateRequest,
  performFindRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";

import Area from "../../../../models/Area";
import SpecDetail from "../../../../models/SpecDetail";

const dataComponentId = "SpecDetail-SpecDetailsChildren";
const areaDataComponentId = dataComponentId + "-Areas";

import { handleRequestError } from "../../../../utils/formValidationUtils";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import withPORevision from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";

export class PARBasedQuantityCalculation extends Component {
  initialValues = {
    specId: this.props.spec.id,
    unitsPerCase: null,
    type: "QCParBased",
  };

  componentDidMount() {
    const {
      specDetail,
      spec: { areaId },
      initDataComponent,
      performFindRequest,
    } = this.props;

    initDataComponent(
      dataComponentId,
      SpecDetail,
      ["roomTypes"],
      "spec-details"
    );
    initDataComponent(areaDataComponentId, Area, ["areaRooms"], "areas");

    if (specDetail) {
      this.initialValues = {
        ...this.initialValues,
        id: specDetail.id,
        unitsPerCase: specDetail.unitsPerCase,
      };
    } else if (areaId) {
      performFindRequest(areaDataComponentId, areaId);
    }
  }

  mapRoomTypes() {
    const { specDetail, area } = this.props;

    if (specDetail && specDetail.roomTypes) {
      return specDetail.roomTypes.map((roomType, index) => ({
        index,
        id: roomType.id,
        name: roomType.name,
        roomCount: roomType.roomCount,
        perRoom: roomType.perRoom,
        par: roomType.par,
      }));
    } else if (area && area.areaRooms) {
      return area.areaRooms.map((areaRoom, index) => ({
        index,
        name: areaRoom.name,
        roomCount: areaRoom.roomCount,
        perRoom: null,
        par: null,
      }));
    } else {
      return [];
    }
  }

  get specDetailSchema() {
    return BWModels.loadSchema("SpecDetail").concat(
      Joi.object().keys({
        roomTypes: Joi.array().items(this.specDetailRoomTypeSchema),
      })
    );
  }

  get specDetailRoomTypeSchema() {
    return BWModels.loadSchema("SpecDetailRoomType");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateOrPatchSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateOrPatchSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleCreateOrPatchSuccess = () => {
    this.props.closeModalDialog();
  };

  saveChanges = (newSpecDetail, formikActions) => {
    const {
      specDetail,
      performCreateRequest,
      performUpdateRequest,
      revisionValue,
    } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    newSpecDetail.roomTypes.map(roomType => delete roomType.index);

    if (specDetail) {
      performUpdateRequest(
        dataComponentId,
        specDetail.id,
        newSpecDetail,
        params
      );
    } else {
      performCreateRequest(dataComponentId, newSpecDetail, params);
    }
    this.setState({ formikActions });
  };

  addRoomTypeRow = ({ roomTypes }, arrayHelpers) => {
    const lastRoomType = roomTypes[roomTypes.length - 1] || { index: -1 };
    arrayHelpers.push({
      index: lastRoomType.index + 1,
      name: null,
      roomCount: null,
      perRoom: null,
      par: null,
    });
  };

  render() {
    const { spec, specDetail } = this.props;
    const ownRef = `specs.${spec.id}.specDetails.${_get(specDetail, "id")}`;
    return (
      <FormikForm
        initialValues={{
          ...this.initialValues,
          roomTypes: this.mapRoomTypes(),
        }}
        enableReinitialize
        ignoreCache
        onSubmit={this.saveChanges}
        validationSchema={this.specDetailSchema}
        ignoreValidationFieldNames={[/roomTypes\[.*?\].specDetailId/]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <FieldArray
            name={"roomTypes"}
            render={formikArrayHelpers => (
              <PARBasedQuantityCalculationForm
                {...formikProps}
                items={values.roomTypes || []}
                values={values}
                onAddClick={() =>
                  this.addRoomTypeRow(values, formikArrayHelpers)
                }
                onRemoveClick={index => formikArrayHelpers.remove(index)}
                onUpdateRow={(newData, rowIndex) =>
                  formikArrayHelpers.replace(rowIndex, newData)
                }
                onSave={handleSubmit}
                revisionReference={ownRef}
                dataComponentId={dataComponentId}
                formikProps={{ errors, ...formikProps }}
              />
            )}
          />
        )}
      </FormikForm>
    );
  }
}

PARBasedQuantityCalculation.propTypes = {
  spec: PropTypes.shape({
    areaId: PropTypes.string.isRequired,
  }).isRequired,
  specDetail: propTypes.specDetail,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

export const mapStateToProps = (state, ownProps) => {
  const {
    spec: { areaId },
  } = ownProps;

  const dataComponent = getDataComponent(dataComponentId, state);
  const areaDataComponent = getDataComponent(areaDataComponentId, state);

  return {
    area: getBluechipResourceById(areaDataComponent, state, areaId),
    dataComponent,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  performCreateRequest,
  performRetrieveListRequest,
  closeModalDialog,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPORevision(PARBasedQuantityCalculation, "SpecDetail", "purchaseOrder"))
);
