import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import { COM_TYPE, SPEC_TYPE } from "../../Quotes/bidGroupVendorSpecUtils";

const Point = styled.div`
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  width: 5px;
  height: 5px;
  background: #9ea7ba;
  border-radius: 50%;
`;

const DottedLine = styled.div`
  position: absolute;
  top: ${({ toBorderTop }) => toBorderTop && "0"};
  bottom: ${({ toBorderBottom }) => toBorderBottom && "0"};
  left: 50%;
  width: 0;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "calc(50% - 6px)")};
  border-right: 1px dotted #9ea7ba;
`;

const Arrow = styled(KeyboardArrowUp)`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 12px);
  font-size: 17px;
  color: #9ea7ba;
`;

const Indicator = ({ isParent, isLastCOM, type }) => {
  if (type === COM_TYPE) {
    return (
      <Fragment>
        <Point />
        <DottedLine fullHeight={!isLastCOM} toBorderTop />
      </Fragment>
    );
  }
  if (type === SPEC_TYPE && isParent) {
    return (
      <Fragment>
        <Arrow />
        <DottedLine toBorderBottom />
      </Fragment>
    );
  }
  return null;
};

Indicator.propTypes = {
  isLastCOM: PropTypes.bool,
  isParent: PropTypes.bool,
  type: PropTypes.string,
};

export default Indicator;
