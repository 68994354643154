import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import createEntityFields from "./createEntityFields";
import FormGrid from "../../../../components/forms/FormGrid";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";

const FormTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  padding: 12px 24px 24px 24px;
`;

export default class CreateEntityForm extends Component {
  static propTypes = {
    countries: PropTypes.array,
    states: PropTypes.array,
    getStatesOfCountry: PropTypes.func,
    onSubmit: PropTypes.func,
    closeModalDialog: PropTypes.func,
    client: PropTypes.shape({}),
    id: PropTypes.string,
    dataComponentId: PropTypes.string.isRequired,
  };

  get validationSchema() {
    return BWModels.loadSchema("Entity");
  }

  entityInitialValues = {
    name: "",
    federalId: "",
    clientId: null,
    location: {
      address: undefined,
      address2: undefined,
      city: undefined,
      zip: undefined,
      country: undefined,
      state: undefined,
    },
  };

  get formPatchProps() {
    const { id, initialValues } = this.props;
    if (!id) return {};

    return {
      enableReinitialize: true,
      initialValues,
    };
  }

  render() {
    const { onSubmit, client, entity, dataComponentId } = this.props;

    let initialValues = this.entityInitialValues;
    if (client?.id) {
      initialValues = {
        ...initialValues,
        clientId: client.id,
      };
    }
    if (!entity && client?.scopeId === "1") {
      const defaultLocation = {
        address: "c/o Benjamin West (as Agent)",
        address2: "428 CTC Boulevard",
        city: "Louisville",
        state: "CO",
        country: "United States",
        zip: "80027",
      };
      initialValues = {
        ...initialValues,
        location: defaultLocation,
      };
    }

    return (
      <FormikForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        {...this.formPatchProps}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createEntityFields(values, client)}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <FormTitle>
              Details entered here are for Bill to Entity on Purchase Orders and
              RFQs.
            </FormTitle>
            <ActionButtons
              onSend={handleSubmit}
              isModal={true}
              listeners={[dataComponentId]}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}
