import {
  fetchDataFailed,
  fetchDataRequest,
  fetchDataSuccess,
  initDataComponent,
  performRetrieveListRequest,
  setReload,
} from "./dataComponentActions";
import { createQueueProcess, initQueueProcess } from "./queuesActions";
import ShippingPayment from "../models/ShippingPayment";
import AuthService from "../services/AuthService";
import {
  generateReportFailureAction,
  generateReportRequestAction,
  generateReportSuccessAction,
  handleFileResponse,
  mimeTypes,
} from "./reportsActions";

import { getDataComponent } from "../reducers/dataComponentReducer";

import * as REQUEST_TYPES from "../constants/RequestTypes";
import { getDataComponentId } from "../components/pages/Billing/Shipping/ShippingPayments/ShippingPaymentsContainer";
import { shippingPaymentSummaryDataComponentId } from "../components/pages/Billing/Shipping/Summary";
import { closeModalDialog, showSnackNotificationAction } from "./layoutActions";
import { dataComponentId as gridDataComponentId } from "../components/pages/Billing/Shipping/Grid";

export const queueId = "importShippingPaymentsFromXls";

export const importShippingPaymentsDataComponentId = "ProcessShippingPayments";
export const billShippingPaymentsDataComponentId = "BillShippingPayments";

const authService = new AuthService(false);

export const importShippingPaymentsFromXls = (file, reupload) => {
  return async dispatch => {
    dispatch(initQueueProcess(queueId));
    const data = new FormData();
    data.append("handler", "shippingPayments");
    data.append("file", file);
    data.append("reupload", reupload);
    data.append("filename", file.name);
    dispatch(createQueueProcess("import-from-xls", queueId, data));
  };
};

export const billShippingPayments = (projectId, ids, params) => {
  return async dispatch => {
    dispatch(
      initDataComponent(
        billShippingPaymentsDataComponentId,
        ShippingPayment,
        [],
        "projects/bill-shipping-payments",
        true,
        "v2"
      )
    );
    await dispatch(
      await updateAndDownloadReport(
        billShippingPaymentsDataComponentId,
        projectId,
        ids,
        params
      )
    );
  };
};

export const updateAndDownloadReport = (
  dataComponentId,
  projectId,
  ids,
  params = {},
  showPreview = false
) => {
  return async (dispatch, getState) => {
    const dataComponent = getDataComponent(dataComponentId, getState());
    const { apiRoute, includes } = dataComponent;
    const apiRoot = "/";
    const baseUrl = `${apiRoot}${apiRoute}`;

    const reportReference = `${apiRoute}-${projectId}-${showPreview}`;
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    dispatch(generateReportRequestAction(reportReference));

    try {
      const response = await authService.post(
        `${baseUrl}/${projectId}`,
        ids,
        {
          includes: includes ? JSON.stringify(includes) : [],
          ...params,
        },
        {
          Accept: mimeTypes.pdf,
        },
        {
          returnRawResponse: true,
        }
      );

      await handleFileResponse(response, showPreview);

      dispatch(fetchDataSuccess(dataComponentId, REQUEST_TYPES.UPDATE, {}));
      dispatch(generateReportSuccessAction(reportReference));
      dispatch(onStatusUpdateSuccess(projectId, "Billed"));
    } catch (error) {
      dispatch(fetchDataFailed(dataComponentId, REQUEST_TYPES.UPDATE, error));
      dispatch(generateReportFailureAction(reportReference));
    }
  };
};

export const onStatusUpdateSuccess = (projectId, status) => {
  return dispatch => {
    dispatch(
      showSnackNotificationAction(
        `Shipping payments were marked as ${status} successfully.`
      )
    );
    dispatch(closeModalDialog());
    dispatch(setReload(gridDataComponentId, true));
    dispatch(performRetrieveListRequest(shippingPaymentSummaryDataComponentId));

    dispatch(
      performRetrieveListRequest(getDataComponentId(projectId), {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        sort: [{ columnName: "id", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
};
