import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import _get from "lodash/get";

import { Checkbox, ListItemText } from "./components";
import { DisplayVendorContext } from "./withDisplayVendorControl";

const Item = ({ label, index }) => {
  const labelId = `checkbox-list-label-${label}`;

  const { onToggleStatus, statuses } = useContext(DisplayVendorContext);

  const handleClick = useCallback(() => onToggleStatus(index), [
    onToggleStatus,
    index,
  ]);
  return (
    <ListItem dense button onClick={handleClick}>
      <ListItemIcon>
        <Checkbox
          color="primary"
          checked={statuses[index] || false}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};

Item.propTypes = {
  label: PropTypes.string,
  index: PropTypes.number,
};

const VendorSelects = ({ anchorEl, open, onClose }) => {
  const { bgVendors, onShowAll } = useContext(DisplayVendorContext);

  return (
    <Popover
      id="bg-vendors-hide-show-select"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button onClick={onShowAll}>
          <ListItemText primary="Show All" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <Item label="Initial Budget" index={0} />
        {bgVendors.map((bgVendor, index) => (
          <Item
            key={index}
            label={_get(bgVendor, "vendor.name", index + 1)}
            index={index + 1}
          />
        ))}
      </List>
    </Popover>
  );
};

VendorSelects.propTypes = {
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default VendorSelects;
