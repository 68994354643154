import React from "react";
import PropTypes from "prop-types";

import DangerConfirmation from "./DangerConfirmation";

const BulkDeleteConfirmation = ({
  count,
  message,
  onDelete,
  ...buttonsProps
}) => {
  message = message || `delete ${count} records`;
  return (
    <DangerConfirmation
      dangerButtonText="Delete"
      actionMessage={message}
      onDanger={onDelete}
      {...buttonsProps}
    />
  );
};

BulkDeleteConfirmation.propTypes = {
  count: PropTypes.number.isRequired,
  message: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
};

export default BulkDeleteConfirmation;
