import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import styled, { keyframes } from "styled-components";
import BWModels from "benjaminwest-models";
import { Edit } from "@material-ui/icons";

import { FormikForm, FormGrid } from "../../../../forms";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";
import {
  handleAtticStockChange,
  handleBaseQuantityChange,
  handleOverageChange,
} from "../../SpecFormikForm";
import createSpecFields from "../../CreateSpec/createSpecFields";
import InputAdornment from "../../../../mui/core/InputAdornment";
import SpecDetails from "./SpecDetails";
import { FormControlLabel, Switch } from "../../../../mui/core";
import Actions from "./Actions";
import {
  createSpec,
  createSpecFromPDFDataComponentId,
  previewDataComponentId,
} from "../../../../../actions/specsActions";
import useActions from "../../../../hooks/useActions";
import useScopedCurrency from "../../../../hooks/useScopedCurrency";
import { useLoading } from "../../../../hooks/useDatacomponent";
import { LoaderContext } from "../../../../ui/Loader";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 166px);
`;

const Adornment = styled(InputAdornment)`
  padding: 0px;
`;

const blink = keyframes`
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const EditIcon = styled(Edit)`
  animation: ${blink} 1s linear infinite;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  position: fixed;
  right: 12px;
  bottom: 12px;
  margin-right: 0px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

export const parseFields = (
  { groups },
  selectedInputName,
  setSelectedInputName
) => {
  groups.forEach(({ items }) => {
    items
      .filter(
        ({ input }) =>
          !input?.disabled &&
          input?.InputComponent.name === "TextInputWithError"
      )
      .forEach(({ input }) => {
        input.InputProps = {
          ...input.InputProps,
          endAdornment:
            input?.name === selectedInputName ? (
              <Adornment position="end">
                <EditIcon />
              </Adornment>
            ) : null,
          onClick: () => setSelectedInputName(input.name),
        };
      });
  });
};

const SpecForm = ({
  match,
  selectedInputName,
  setSelectedInputName,
  selectLineOnClick,
  setSelectLineOnClick,
  formikPropsRef,
}) => {
  const [baseQuantity, setBaseQuantity] = useState(0);
  const [atticStock, setAtticStock] = useState(0);
  const [overagePercent, setOveragePercent] = useState(0);

  const actions = useActions({ createSpec });

  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );
  const scopedCurrency = useScopedCurrency();
  const defaultProjectCurrency = projectCurrencies.find(
    ({ isDefault }) => isDefault
  ) || { currency: scopedCurrency };

  const isProjectClosed = useIsProjectClosed();
  const clientId = match.params.clientId;
  const projectId = match.params.projectId;

  const loading = useLoading(
    previewDataComponentId,
    createSpecFromPDFDataComponentId
  );

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Wrapper>
        <FormikForm
          onSubmit={actions.createSpec}
          initialValues={{
            projectId,
            specDetails: [],
            projectCurrencyId: defaultProjectCurrency?.id,
          }}
          validationSchema={BWModels.loadSchema("Spec")}
          enableReinitialize
          ignoreCache
        >
          {({
            handleSubmit,
            values,
            errors,
            setFieldValue,
            ...formikProps
          }) => {
            formikPropsRef.current = {
              values,
              errors,
              setFieldValue,
              ...formikProps,
            };
            const selectedProjectCurrency = projectCurrencies.find(
              projectCurrency => projectCurrency.id == values.projectCurrencyId
            );
            const fields = createSpecFields({
              clientId,
              projectId,
              onBaseQuantityChange: handleBaseQuantityChange(
                setBaseQuantity,
                setFieldValue,
                overagePercent,
                atticStock
              ),
              handleAtticStockChange: handleAtticStockChange(
                setAtticStock,
                setFieldValue,
                baseQuantity,
                overagePercent
              ),
              handleOverageChange: handleOverageChange(
                setOveragePercent,
                setFieldValue,
                baseQuantity,
                atticStock
              ),
              selectedCurrency: selectedProjectCurrency?.currency,
              disableVendorField: false,
              spec: {},
              quantityErrorMessage: null,
              isProjectClosed,
            });
            parseFields(fields, selectedInputName, setSelectedInputName);
            return (
              <Fragment>
                <FormWrapper>
                  <FormGrid
                    fields={fields}
                    values={values}
                    errors={errors}
                    {...formikProps}
                  />
                  <SpecDetails
                    specDetails={values.specDetails}
                    setFieldValue={setFieldValue}
                    selectedInputName={selectedInputName}
                    setSelectedInputName={setSelectedInputName}
                  />
                </FormWrapper>
                <ActionsWrapper>
                  <Actions handleSubmit={handleSubmit} />
                  <FormControlLabelStyled
                    control={
                      <Switch
                        checked={selectLineOnClick}
                        onChange={() =>
                          setSelectLineOnClick(!selectLineOnClick)
                        }
                        disabled={loading}
                        color="primary"
                      />
                    }
                    label="Copy on Click"
                  />
                </ActionsWrapper>
              </Fragment>
            );
          }}
        </FormikForm>
      </Wrapper>
    </LoaderContext.Provider>
  );
};

SpecForm.propTypes = {
  match: PropTypes.shape({}),
  selectedInputName: PropTypes.string,
  setSelectedInputName: PropTypes.func.isRequired,
  selectLineOnClick: PropTypes.bool,
  setSelectLineOnClick: PropTypes.func.isRequired,
  formikPropsRef: PropTypes.shape({}),
};

export default withRouter(SpecForm);
