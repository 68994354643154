import TextInputWithError from "../../inputs/TextInputWithError";
import CheckboxWithError from "../CheckboxWithError";

export default {
  content: {
    InputComponent: TextInputWithError,
    label: "Content",
    name: "content",
    fullWidth: true,
    multiline: true,
  },
  isArchived: {
    InputComponent: CheckboxWithError,
    label: "Archived",
    name: "isArchived",
  },
};
