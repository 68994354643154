import React, { useMemo, useRef } from "react";
import { AddCircle } from "@material-ui/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

import { BWGrid, GridRow, GridTable } from "../../../../ui/BWGrid";
import editableCellCreator from "../../../InvoiceDetail/InvoiceDataSection/Grid/editableCellCreator";
import { ActionButtons, FormikForm } from "../../../../forms";
import {
  columns,
  columnsWithBorder,
  getColumnOptions,
  getFeeRangeMax,
  isCellEditable,
  isValid,
  rowInputs,
  validateFloatInput,
} from "./gridConfig";
import { SelectWithError } from "../../../../inputs/SelectWithError";
import { BWActionButton } from "../../../../ui/BWActionButton";
import Loader from "../../../../ui/Loader";
import { useLabeledText } from "../../../../inputs/TextInputWithError";
import withFloatInputValidator from "../../../../inputs/FloatInput/withFloatInputValidator";

const Wrapper = styled.div`
  padding: 24px 0 0 0;
`;

const Actions = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled(BWActionButton)`
  padding: 0;
  &:hover {
    background-color: unset;
  }
`;

const RowWrapper = styled.div`
  justify-content: space-between;
  display: flex;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const handleAddAction = (setFieldValue, pricingTiers) => () =>
  setFieldValue(`pricingTiers[${pricingTiers.length}]`, {
    sequenceIndex: pricingTiers.length,
    ffeSpendFrom: pricingTiers.at(-1).ffeSpendTo,
    ffeSpendTo: 0,
    tierPercentage: 0,
    minimumAmount: getFeeRangeMax(pricingTiers.at(-1)),
  });

const handleFfeSpendToUpdate = (setFieldValue, pricingTiers) => (
  value,
  { rowId }
) => {
  const nextRowId = rowId + 1;
  if (!pricingTiers[nextRowId]) {
    return;
  }
  setFieldValue(`pricingTiers[${nextRowId}].ffeSpendFrom`, value);
  syncMinAmount(setFieldValue, pricingTiers)("ffeSpendTo")(value, { rowId });
};
export const syncMinAmount = (setFieldValue, pricingTiers) => property => (
  value,
  { rowId }
) => {
  const nextRowId = rowId + 1;
  if (!pricingTiers[nextRowId]) {
    return;
  }
  setFieldValue(
    `pricingTiers[${nextRowId}].minimumAmount`,
    getFeeRangeMax({ ...pricingTiers[rowId], [property]: value })
  );
};

const initialPricingTier = {
  sequenceIndex: 1,
  ffeSpendFrom: 0,
  ffeSpendTo: 0,
  tierPercentage: 0,
  minimumAmount: 0,
};

const handleRemoveAction = (setFieldValue, pricingTiers) => () =>
  setFieldValue("pricingTiers", pricingTiers.slice(0, -1));

export const GridForm = ({
  clientPricings = [],
  selectedVersion,
  onVersionChange,
  isLoading,
  dataComponentId,
  onSubmit,
}) => {
  const clientPricing =
    clientPricings.find(
      ({ version: clientPricingVersion }) =>
        clientPricingVersion == selectedVersion
    ) || {};
  const { pricingTiers = [initialPricingTier] } = clientPricing;

  const pageRef = useRef();
  const rows = useMemo(() => getRows(pricingTiers), [pricingTiers]);

  const openNextFallback = useMemo(
    () => () => {
      setTimeout(() => pageRef.current.querySelector("textarea").focus(), 0);
    },
    [pageRef]
  );

  const openPrevFallback = useMemo(
    () => () => {
      setTimeout(() => pageRef.current.querySelectorAll("input")[2].focus(), 0);
    },
    [pageRef]
  );

  const isLastVersionSelected = selectedVersion == clientPricings.length;
  const isLabeled = useLabeledText();
  const isEditable = isLastVersionSelected && !isLabeled;
  const tableComponents = useMemo(
    () => pricingTiers => ({
      CellComponent: withFloatInputValidator(
        editableCellCreator(
          rowInputs,
          ({ rowId }, { name }) => `pricingTiers[${rowId}].${name}`,
          openNextFallback,
          openPrevFallback,
          ({ name }) =>
            columnsWithBorder.includes(name)
              ? "1px solid #cccccc !important"
              : "none",
          isCellEditable(isEditable)
        ),
        validateFloatInput(pricingTiers)
      ),
    }),
    [isEditable, openNextFallback, openPrevFallback]
  );

  return (
    <Wrapper ref={pageRef}>
      <FormikForm
        initialValues={{ pricingTiers: rows }}
        onSubmit={onSubmit}
        enableReinitialize
        ignoreCache
      >
        {({ values: { pricingTiers }, setFieldValue, dirty, handleSubmit }) => (
          <React.Fragment>
            <Actions>
              <SelectWithError
                label="Version"
                options={clientPricings.map(({ version }) => ({
                  id: version,
                  name: `Version ${version}`,
                }))}
                value={selectedVersion}
                onChange={onVersionChange}
                displayEmpty={false}
                disabled={dirty || isLoading}
                ignoreCaption
              />
              <ActionButtons
                onSend={handleSubmit}
                sendButtonText="UPDATE"
                disabled={!dirty || !isEditable || !isValid(pricingTiers)}
                hideCancelButton={true}
                listeners={[dataComponentId]}
                additionalProps={{
                  send: { isLoading },
                }}
              />
            </Actions>
            <Loader count={5} height={"40px"} loading={isLoading}>
              <BWGrid
                rows={pricingTiers}
                gridConfig={{ totalRows: pricingTiers.length, pageSize: 0 }}
                tableComponents={tableComponents(pricingTiers)}
              >
                <GridTable
                  columns={columns}
                  columnOptions={getColumnOptions({
                    onRemoveAction: handleRemoveAction(
                      setFieldValue,
                      pricingTiers
                    ),
                    pricingTiers,
                    isEditable,
                    onFfeSpendToUpdate: handleFfeSpendToUpdate(
                      setFieldValue,
                      pricingTiers
                    ),
                    syncMinAmount: syncMinAmount(setFieldValue, pricingTiers),
                  })}
                />
                <GridRow>
                  <RowWrapper>
                    <Button
                      icon={<AddCircle />}
                      text={"Add"}
                      onClick={handleAddAction(setFieldValue, pricingTiers)}
                      disabled={!isEditable}
                      disableRipple
                      disableFocusRipple
                    />
                    <div></div>
                    <DetailWrapper>
                      <div>
                        * Min Fee ($) for each Tier will be the Max Fee ($) from
                        the prior tier;
                      </div>
                      <div>
                        ** Values higher than the Upper Spend Limit of the last
                        tier will use the tier % of the last tier to calculate
                        the Max Fee Range.
                      </div>
                    </DetailWrapper>
                  </RowWrapper>
                </GridRow>
              </BWGrid>
            </Loader>
          </React.Fragment>
        )}
      </FormikForm>
    </Wrapper>
  );
};
GridForm.propTypes = {
  clientPricings: PropTypes.array,
  selectedVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onVersionChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  dataComponentId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

const headerRows = [
  {
    title: "",
    ffeSpendFrom: "From (>)",
    ffeSpendTo: "To (<=)",
    tierPercentage: "Tier %",
    minimumAmount: "Min *",
    feeRangeMin: "Min",
    feeRangeMax: "Max",
  },
];
const getRows = rows => [...headerRows, ...rows];
