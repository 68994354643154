import { createContext, useContext } from "react";
import { noop } from "lodash";

export const ProjectContext = createContext({
  ignoreProjectClosed: false,
  project: null,
  setProject: noop,
});

export const getIsProjectClosed = ({ ignoreProjectClosed, project }) => {
  if (ignoreProjectClosed) {
    return false;
  }
  return project?.status === "Closed";
};

export const useIsProjectClosed = () => {
  const context = useContext(ProjectContext);
  return getIsProjectClosed(context);
};

export const useLoadedProject = () => {
  const { project } = useContext(ProjectContext);
  return project;
};
