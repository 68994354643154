import { Fragment } from "react";
import FormGrid from "../../../forms/FormGrid";
import FormikForm from "../../../forms/FormikForm";
import caseBasedQuantityCalculationFields from "./caseBasedQuantityCalculationFields";
import ActionButtons from "../../../forms/ActionButtons";
import React from "react";
import BWModels from "benjaminwest-models";
import { withRevisionReference } from "../../../../withPORevision";

const Form = ({ initialValues, onSubmit, dataComponentId }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("SpecDetail")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={caseBasedQuantityCalculationFields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};
export default withRevisionReference(Form);
