import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { openModalDialog } from "../../../../../../actions/layoutActions";
import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../../utils/bluechipUtils";
import { processCreateRequestStatus } from "../../../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import propTypes from "../../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import * as DATA_COMPONENTS from "../../../../../../constants/DataComponents";
import { LoaderContext } from "../../../../../ui/Loader";
import InvoicesGrid from "./InvoicesGrid";
import {
  initCreateComponents,
  createFunding,
  updateFunding,
  performAfterCreate,
} from "../../../../../../actions/fundingActions";
import { dataComponentId } from "../../Invoices/InvoicesContainer";

const METHOD_TYPE = "Batch";
export const dataComponentIdCreate = "FundingCreate";

const processRequestResponse = (
  processRequestStatus,
  prevDataComponent,
  dataComponent,
  formikActions,
  performAfterCreate
) => {
  processRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      performAfterCreate(formikActions);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateFunding = ({
  projectId,
  initCreateComponents,
  createFunding,
  loading,
  dataComponent,
  invoicesDataComponent,
  performAfterCreate,
  openModalDialog,
  hideCancelButton,
  invoicesFilterOptions,
  purchaseOrdersFilterOptions,
  sendButtonText,
  project,
  total,
}) => {
  const [formikActions, setFormikAction] = useState(null);
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      processCreateRequestStatus,
      prevDataComponent,
      dataComponent,
      formikActions,
      performAfterCreate
    );
  }, [dataComponent, formikActions, performAfterCreate, prevDataComponent]);

  const handleCreateFunding = useCallback(
    (funding, formikActions) => {
      setFormikAction(formikActions);
      createFunding({
        ...funding,
        amountRequested: total,
        invoices: invoicesDataComponent.selectedIds.map(id => ({ id })),
      });
    },
    [createFunding, total, invoicesDataComponent]
  );

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Fund Request",
      "CreateFundingType",
      {
        projectId,
        project,
      },
      false,
      true,
      {
        subtitle: "Select Funding Type",
      }
    );
  }, [openModalDialog, project, projectId]);

  useEffect(() => {
    initCreateComponents();
  }, [initCreateComponents]);

  const defaultProjectCurrency = project?.projectCurrencies?.find(
    projectCurrency => projectCurrency.isDefault
  );

  const initialValues = useMemo(() => {
    return {
      projectId,
      projectCurrencyId: defaultProjectCurrency?.id,
      method: METHOD_TYPE,
    };
  }, [defaultProjectCurrency, projectId]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <InvoicesGrid
        projectId={projectId}
        sendButtonText={sendButtonText}
        hideCancelButton={hideCancelButton}
        onSubmit={handleCreateFunding}
        onCancel={handleOpenCreateModal}
        initialValues={initialValues}
        loading={loading}
        dataComponent={invoicesDataComponent}
        invoicesFilterOptions={invoicesFilterOptions}
        purchaseOrdersFilterOptions={purchaseOrdersFilterOptions}
        total={total}
      />
    </LoaderContext.Provider>
  );
};

CreateFunding.propTypes = {
  projectId: PropTypes.string,
  initCreateComponents: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  createFunding: PropTypes.func.isRequired,
  performAfterCreate: PropTypes.func,
  loading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
  invoicesDataComponent: propTypes.dataComponent,
  project: propTypes.project,
  invoicesFilterOptions: PropTypes.arrayOf(propTypes.invoice),
  purchaseOrdersFilterOptions: PropTypes.arrayOf(propTypes.purchaseOrder),
  hideCancelButton: PropTypes.bool,
  updateFunding: PropTypes.func,
  sendButtonText: PropTypes.string,
  total: PropTypes.number,
  funding: propTypes.funding,
};

CreateFunding.defaultProps = {
  funding: {},
};

const calculateTotal = invoices => {
  return (invoices || []).reduce(
    (
      accum,
      {
        amount,
        purchaseOrder: {
          projectCurrency: { conversionRate },
        },
      }
    ) => accum + amount * conversionRate,
    0
  );
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentIdCreate, state);

  const loading = [REQUEST_TYPES.CREATE, REQUEST_TYPES.UPDATE].some(
    action =>
      getDataComponentFlattenedRequestState(
        dataComponentIdCreate,
        state,
        action
      ).loading
  );

  const invoicesDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const total = calculateTotal(
    getBluechipResources(
      invoicesDataComponent,
      state,
      invoicesDataComponent.selectedIds
    )
  );

  return {
    dataComponent,
    invoicesDataComponent,
    loading,
    total,
    invoicesFilterOptions:
      getBluechipResources(
        getDataComponentFlattenedRequestState(
          DATA_COMPONENTS.INVOICE_SELECT,
          state
        ),
        state
      ) || [],
    purchaseOrdersFilterOptions:
      getBluechipResources(
        getDataComponentFlattenedRequestState(
          DATA_COMPONENTS.PURCHASE_ORDERS_SELECT,
          state
        ),
        state
      ) || [],
  };
};

export const mapDispatchToProps = {
  initCreateComponents,
  performAfterCreate,
  createFunding,
  openModalDialog,
  updateFunding,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFunding);
