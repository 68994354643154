import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import DuplicateConfirmation from "../../../ui/DuplicateConfirmation";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import {
  processCreateRequestStatus,
  getCreatedId,
} from "../../../../utils/dataComponentUtils";
import { cloneSpec } from "./utils";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  push,
  setReload,
  showSnackNotificationAction,
  navigateToNewPO,
  { projectId, clientId, newSpecId, title }
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      if (navigateToNewPO) {
        push(
          `/clients/${clientId}/projects/${projectId}/specs/${newSpecId}${location.search}`
        );
      } else {
        setReload(dataComponent.dataComponentId, true);
      }
      showSnackNotificationAction(`${title} has been duplicated successfully`);
    },
    onError: ({ data }) => {
      data.errors.map(error => {
        if (error.global) {
          showSnackNotificationAction(error.title);
        }
      });
    },
  });
};

const DuplicateSpecContainer = ({
  dataComponent,
  closeModalDialog,
  title,
  clientId,
  projectId,
  newSpecId,
  spec,
  push,
  setReload,
  performCreateRequest,
  showSnackNotificationAction,
  dataComponentId,
  navigateToNewPO,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      push,
      setReload,
      showSnackNotificationAction,
      navigateToNewPO,
      { projectId, clientId, newSpecId, title }
    );
  });

  const handleCreate = useCallback(() => {
    const newSpec = cloneSpec(spec);
    performCreateRequest(dataComponentId, newSpec);
  }, [performCreateRequest, dataComponentId, spec]);

  return (
    <DuplicateConfirmation
      dataComponentId={dataComponentId}
      onSubmit={handleCreate}
      title={title}
    />
  );
};

DuplicateSpecContainer.propTypes = {
  title: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  navigateToNewPO: PropTypes.bool,
  newSpecId: PropTypes.string,
  spec: propTypes.spec.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  push,
  setReload,
  showSnackNotificationAction,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    newSpecId: getCreatedId(dataComponent),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateSpecContainer);
