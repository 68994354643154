import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import File from "../../../../models/File";
import EditPreview from "./EditPreview";
import { Image } from "../../../ui/Image";
import { COM_PREVIEW } from "../../../../constants/specs";

export const COMPreviewContainer = styled.div`
  border-radius: 2px;
  margin: 12px;
  margin-left: 16px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(${({ originalSrc }) => originalSrc || COM_PREVIEW});
  ${({ editable, preview }) => {
    return editable && !preview?.preview && `background-image: none;`;
  }}
`;

export const FilePreview = props => {
  return (
    <Image
      {...props}
      file={props.preview?.preview}
      ImageComponent={COMPreviewContainer}
    >
      <EditPreview {...props} />
    </Image>
  );
};

export const mapStateToProps = (state, { preview }) => {
  return {
    preview: {
      preview: File.query(state.resources).find(preview.previewId),
    },
  };
};

export default connect(mapStateToProps)(FilePreview);
