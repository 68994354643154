import { connect } from "react-redux";

import ImportOptions from "./ImportOptions";
import { openImportSpecModal } from "../../../../../actions/specsActions";
import { openModalDialog } from "../../../../../actions/layoutActions";

export const mapDispatchToProps = {
  openImportSpecModal,
  openModalDialog,
};

export default connect(null, mapDispatchToProps)(ImportOptions);
