import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import propTypes from "../../../../../constants/propTypes";
import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import Loader from "../../../../ui/Loader";
import { ActionButtons, FormikForm } from "../../../../forms";
import {
  SAMPLE_PRICING,
  PRODUCTION_PRICING,
  generateGridRows,
  getPriceRow,
  handlePricingChange,
  handleAwardSpec,
} from "./AwardSpecFunctions";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { Message } from "./components";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCell";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import EditableColumn, {
  StyledEdit,
} from "../../../../ui/BWGrid/columns/EditableColumn";

const rowInputs = {
  baseQuantity: {
    InputComponent: TextInputWithError,
    type: "number",
    name: "baseQuantity",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
  atticStock: {
    InputComponent: TextInputWithError,
    type: "number",
    name: "atticStock",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};

export const getInputName = (tableRow, column) => {
  if (!column) return;

  return `data[${tableRow.rowId - 1}].${column.name}`;
};

export const isEditable = column => {
  const columns = ["baseQuantity", "atticStock"];
  return columns.includes(column.name);
};

export const getQuantityEditColumn = (row, prop) => {
  return <EditableColumn>{row[prop]}</EditableColumn>;
};

const columns = [
  "customNumber",
  "description",
  "unitOfMeasure.name",
  "baseQuantity",
  "atticStock",
  "price",
  "extendedPrice",
];
export const columnOptions = (
  handlePricingChange,
  gridRows,
  setGridRows,
  currencyFormatter,
  bidGroupVendorSpecs
) => ({
  customNumber: { title: "Number", bold: true, filter: false },
  description: {
    filter: false,
    fill: true,
  },
  "unitOfMeasure.name": { title: "UOM", bold: true, width: "50px" },
  baseQuantity: {
    title: "Base",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];

        row.baseQuantity = newValue;
        row.extendedPrice = (newValue + row.atticStock) * row[row.price];

        const bidGroupVendorSpec = bidGroupVendorSpecs.find(
          bidGroupVendorSpec => bidGroupVendorSpec.id === row.id
        );

        bidGroupVendorSpec.sampleQuantity = newValue;
        bidGroupVendorSpec.spec.baseQuantity = newValue;

        setGridRows(rows);
      },
    },
    render: row => {
      return getQuantityEditColumn(row, "baseQuantity");
    },
  },
  atticStock: {
    title: "Attic",
    width: "50px",
    editable: true,
    editableOptions: {
      afterSave: (newValue, data) => {
        const rows = JSON.parse(JSON.stringify(gridRows));
        const row = rows[data.rowId];
        row.atticStock = newValue;
        row.extendedPrice = (newValue + row.baseQuantity) * row[row.price];

        const bidGroupVendorSpec = bidGroupVendorSpecs.find(
          bidGroupVendorSpec => bidGroupVendorSpec.id === row.id
        );

        bidGroupVendorSpec.spec.atticStock = newValue;

        setGridRows(rows);
      },
    },
    render: row => {
      return getQuantityEditColumn(row, "atticStock");
    },
  },
  price: {
    title: "Pricing",
    wordWrapEnabled: true,
    render: row => getPriceRow(row, handlePricingChange, currencyFormatter),
  },
  extendedPrice: {
    title: "Ext. Price",
    wordWrapEnabled: true,
  },
});

const AwardSpecContainer = props => {
  const {
    bidGroupVendor,
    bidGroupVendorSpecs,
    onAwardSpec,
    dataComponentId,
    setSnackMessage,
  } = props;
  const [specPricing, setSpecPricing] = useState({});
  const [gridRows, setGridRows] = useState([]);
  const currency = bidGroupVendor.quoteProjectCurrency.currency;
  const currencyFormatter = useCurrencyFormatter(currency);
  useEffect(() => {
    const pricing = {};
    bidGroupVendorSpecs.map(bidGroupVendorSpec => {
      pricing[bidGroupVendorSpec.id] =
        typeof bidGroupVendorSpec.productionPricing == "number"
          ? PRODUCTION_PRICING
          : SAMPLE_PRICING;
    });
    setSpecPricing(pricing);
    const rows = generateGridRows(
      bidGroupVendorSpecs,
      pricing,
      currencyFormatter
    );
    setGridRows(rows);
    // eslint-disable-next-line
  }, [bidGroupVendor, bidGroupVendorSpecs]);

  const tableComponent = useMemo(
    () => ({
      CellComponent: editableCellCreator(rowInputs, getInputName, column =>
        isEditable(column)
      ),
    }),
    []
  );

  return (
    <FormikForm onSubmit={() => {}} ignoreCache>
      {() => (
        <React.Fragment>
          <BWGridLocal
            id={`create-bid-group-purchase-order-${bidGroupVendor.id}`}
            sorting={[{ columnName: "spec.customNumber", direction: "asc" }]}
            rows={gridRows}
            gridConfig={{ pageSize: 0, totalRows: bidGroupVendorSpecs.length }}
            isLoading={false}
            tableComponents={tableComponent}
            noBorder
          >
            <GridRow>
              <Loader height="30px" width="350px">
                <Message>{`Do you want to award the following ${pluralize(
                  "spec",
                  bidGroupVendorSpecs.length
                )} to ${
                  bidGroupVendor.vendor.name
                }? Please select the type of pricing in order to do so:`}</Message>
              </Loader>
            </GridRow>
            <GridTable
              columns={columns}
              columnOptions={columnOptions(
                (event, row) =>
                  handlePricingChange(
                    event,
                    row,
                    specPricing,
                    setSpecPricing,
                    bidGroupVendorSpecs,
                    setGridRows,
                    currencyFormatter
                  ),
                gridRows,
                setGridRows,
                currencyFormatter,
                bidGroupVendorSpecs
              )}
            />
            <GridRow showPagingPanel={false} />
          </BWGridLocal>
          <ActionButtons
            isModal={true}
            sendButtonText={`Award ${pluralize(
              "Spec",
              bidGroupVendorSpecs.length
            )}`}
            onSend={() =>
              handleAwardSpec(
                bidGroupVendor,
                bidGroupVendorSpecs,
                specPricing,
                onAwardSpec,
                dataComponentId,
                setSnackMessage,
                gridRows
              )
            }
            hideCancelButton
            listeners={[dataComponentId]}
          />
        </React.Fragment>
      )}
    </FormikForm>
  );
};

AwardSpecContainer.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
  bidGroupVendorSpecs: PropTypes.arrayOf(propTypes.spec),
  onAwardSpec: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
};

export default AwardSpecContainer;
