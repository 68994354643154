import TextInputWithError from "../../inputs/TextInputWithError";
import SelectWithError, {
  SelectWithError as RawSelectWithError,
} from "../../inputs/SelectWithError";
import DatePickerWithError from "../../inputs/DatePickerWithError";
import CheckboxWithError from "../../inputs/CheckboxWithError";
import CurrencyInput from "../../inputs/CurrencyInput";
import BWModels from "benjaminwest-models";
import Project from "../../../models/Project";
import WorkScope from "../../../models/WorkScope";
import FeeSubScope from "../../../models/FeeSubScope";
import { getProjectName } from "../../pages/Specs/ImportSpecs/ImportForm";

export default {
  project: {
    InputComponent: SelectWithError,
    label: "Project",
    name: "projectId",
    dataComponentId: "select-projects",
    required: true,
    fullWidth: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: Project,
      route: "projects",
      sort: [{ columnName: "number", direction: "asc" }],
      fetchOnlyOnce: true,
      fields: ["projects.id", "number"],
    },
    valueProperty: "id",
    labelProperty: getProjectName,
    apiFilterLabelProperty: "number",
  },
  feeScope: {
    InputComponent: RawSelectWithError,
    label: "Scope",
    name: "scope",
    isSingleAutocomplete: true,
    isAutocomplete: true,
    required: true,
    get options() {
      return BWModels.loadSchema("Fee").__utils.scopes;
    },
    fullWidth: true,
  },
  workScope: {
    InputComponent: SelectWithError,
    label: "Scope",
    name: "workScopeId",
    displayEmpty: true,
    nullAsEmpty: true,
    dataComponentId: "select-work-scopes",
    required: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: WorkScope,
      route: "work-scopes",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    fullWidth: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  feeSubScope: {
    InputComponent: SelectWithError,
    label: "Sub Scope",
    name: "feeSubScopeId",
    dataComponentId: "select-fee-sub-scopes",
    required: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    APIOptions: {
      model: FeeSubScope,
      route: "fee-sub-scopes",
      sort: [{ columnName: "name", direction: "asc" }],
      fetchOnlyOnce: true,
    },
    fullWidth: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  feeBillingTerm: {
    InputComponent: RawSelectWithError,
    label: "Billing Term",
    name: "billingTerm",
    required: true,
    isSingleAutocomplete: true,
    isAutocomplete: true,
    get options() {
      return BWModels.loadSchema("Fee").__utils.billingTerms;
    },
    fullWidth: true,
  },
  oseAmount: {
    InputComponent: TextInputWithError,
    label: "OS&E",
    name: "oseAmount",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { allowNegative: true },
    },
  },
  ffeAmount: {
    InputComponent: TextInputWithError,
    label: "FF&E",
    name: "ffeAmount",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { allowNegative: true },
    },
  },
  feeAmount: {
    InputComponent: TextInputWithError,
    label: "Fee Amount",
    name: "amount",
    fullWidth: true,
    required: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
    inputProps: {
      maskProps: { allowNegative: true },
    },
  },
  feeBillingDate: {
    InputComponent: DatePickerWithError,
    label: "Billing Date",
    name: "billingDate",
    required: true,
    fullWidth: true,
  },
  revenueSharing: {
    InputComponent: CheckboxWithError,
    label: "Revenue Sharing",
    name: "revenueSharing",
    useSwitch: true,
  },
  feeStatus: {
    InputComponent: CheckboxWithError,
    label: "Approved",
    name: "status",
    checkValues: {
      check: "Approved",
      uncheck: "Pending",
    },
  },
  referenceNumber: {
    name: "referenceNumber",
    InputComponent: TextInputWithError,
    label: "Reference Number",
    fullWidth: true,
    helperText: "Please provide a valid Reference Number",
  },
  feeBillDate: {
    InputComponent: DatePickerWithError,
    label: "Bill Date",
    name: "billDate",
    required: true,
    fullWidth: true,
  },
  feeInvoiceReferenceNumber: {
    name: "invoiceReferenceNumber",
    InputComponent: TextInputWithError,
    label: "Invoice #",
    fullWidth: true,
  },
  paymentDate: {
    InputComponent: DatePickerWithError,
    label: "Payment Date",
    name: "paymentDate",
    fullWidth: true,
  },
};
