export const BWIntent = {
  NONE: "none",
  PRIMARY: "primary",
  ACCENT: "accent",
  PRIMARY_INVERTED: "primaryInverted",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  DENY: "deny",
  WATCH: "watch",
  values: {
    colors: {
      none: "rgb(255,255,255)",
      primary: "rgb(255,255,255)",
      primaryInverted: "rgb(87,171,255)",
      success: "rgb(255,255,255)",
      warning: "rgb(255,255,255)",
      danger: "rgb(255,255,255)",
      deny: "rgb(255,255,255)",
      dark: "rgba(0,0,0,0.87)",
    },
    buttons: {
      primary: "rgb(87,171,255)",
      primaryInverted: "#BDBDBD",
      danger: "#FF1744",
      accent: "#2C6AFA",
    },
    icons: {
      primary: "rgb(87,171,255)",
      primaryInverted: "rgba(0, 0, 0, 0.24)",
    },
    backgroundColors: {
      none: "rgba(0,0,0, 0.24)",
      primary: "rgb(87,171,255)",
      primaryInverted: "rgb(241,241,241)",
      success: "rgb(114,179,39)",
      warning: "rgb(255,145,0)",
      danger: "rgb(255,23,68)",
      deny: "rgb(27, 61, 83)",
      watch: "#d1bd00",
    },
    tableText: {
      none: "rgba(0,0,0,0.87)",
      danger: "#FF1744",
      success: "#4CAF50",
      warning: "#FF9100",
      primary: "#57ABFF",
    },
    counters: {
      danger: "#FF1744",
      success: "#72B327",
    },
  },
};
