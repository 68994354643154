import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BWGridAPI, GridTable } from "../../../../ui/BWGrid";
import Price from "../../../../ui/Price";

import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import BWModels from "benjaminwest-models";
export const DisplayCommentsContext = React.createContext(false);

const Container = styled.div`
  margin-bottom: ${({ withMargin }) => (withMargin ? "28px" : "auto")};
`;

export const columns = displayDiscount => {
  const totalColumn = displayDiscount ? "totalPriceWithDiscount" : "totalPrice";
  return [
    "customNumber",
    "description",
    "specCategory.name",
    "vendor.name",
    "totalQuantity",
    "unitOfMeasure.name",
    "price",
    "uncommitted",
    "committed",
    "glCodes.code",
    totalColumn,
  ];
};

export const columnOptions = {
  customNumber: { title: "Spec Number", bold: true, filter: true },
  description: { filter: true, fill: true },
  "specCategory.name": {
    title: "Category",
    width: "150px",
    filter: true,
  },
  "vendor.name": {
    title: "Vendor",
    width: "150px",
    filter: true,
  },
  totalQuantity: { title: "Qty", sortingEnabled: false },
  "unitOfMeasure.name": { title: "UOM" },
  price: {
    title: "Price",
    sortingEnabled: false,
    render: ({ id, priceDefault }) => {
      return id && <Price number={priceDefault} />;
    },
  },
  uncommitted: {
    title: "Uncommitted",
    sortingEnabled: false,
    render: ({ id, totalPrice, isCommitted }) => {
      return id && <Price number={isCommitted ? 0 : totalPrice} />;
    },
  },
  committed: {
    title: "Committed",
    sortingEnabled: false,
    render: ({ id, totalPrice, isCommitted }) => {
      return id && <Price number={isCommitted ? totalPrice : 0} />;
    },
  },
  "glCodes.code": {
    title: "GL Codes",
    filter: true,
    sortingEnabled: false,
    render: ({ glCodes = [] }) => glCodes.map(({ code }) => code).join(", "),
  },
  totalPriceWithDiscount: {
    title: "Extended Price",
    sortingEnabled: false,
    render: ({ id, totalPriceWithDiscount }) => {
      return id && <Price number={totalPriceWithDiscount} />;
    },
  },
  totalPrice: {
    title: "Extended Price",
    sortingEnabled: false,
    render: ({ id, totalPrice }) => {
      return id && <Price number={totalPrice} />;
    },
  },
};

const SpecTable = ({ displayDiscount, ...props }) => {
  const { totalPages, dataComponentId } = props.dataComponent;
  const displayComments = useContext(DisplayCommentsContext);

  const tableComponents = useMemo(() => {
    if (!displayComments) return {};
    return genDetailRowComponents(
      "forecastComment",
      "id",
      BWModels.loadSchema("Spec"),
      dataComponentId,
      9,
      2,
      { helperText: "Forecast Comment" }
    );
  }, [dataComponentId, displayComments]);

  return (
    <Container withMargin={totalPages === 1}>
      <BWGridAPI
        {...props}
        tableComponents={tableComponents}
        alwaysDisplayDetailRow
        displayDiscount={displayDiscount}
        apiFilters={{
          rootFilters: props.rootFilters,
          params: {
            omitDefaultModifier: true,
            modifiers: [
              "withQuantityPrice",
              "withForecastFieldsDefaultCurrency",
            ],
          },
        }}
      >
        <GridTable
          columns={columns(displayDiscount)}
          columnOptions={columnOptions}
        />
      </BWGridAPI>
    </Container>
  );
};

SpecTable.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
};

export default SpecTable;
