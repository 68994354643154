import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Delete from "@material-ui/icons/Delete";
import _get from "lodash/get";

import { openModalDialog } from "../../../actions/layoutActions";
import propTypes from "../../../constants/propTypes";
import { StyledIconButton } from "../../ui/DragAndDropList/Menu";

const typeTitleMap = {
  QCCaseBased: "PAR Based Quantity Calculation",
  QCParBased: "Case Based Quantity Calculation",
};

export class DeleteButton extends Component {
  openDeleteModal = () => {
    const {
      specDetail,
      specDetailCom,
      openModalDialog,
      purchaseOrderId,
    } = this.props;
    const title = specDetailCom
      ? _get(specDetailCom, "spec.description")
      : typeTitleMap[specDetail.type] || specDetail.title;

    const modalDialog = specDetailCom
      ? "DeleteSpecDetailCOM"
      : "DeleteSpecDetail";

    openModalDialog(
      `Delete ${title}`,
      modalDialog,
      {
        title,
        specDetailId: specDetail.id,
        purchaseOrderId,
        specDetailComId: specDetailCom && specDetailCom.id,
      },
      true,
      false
    );
  };

  render() {
    return (
      <StyledIconButton onClick={this.openDeleteModal}>
        <Delete />
      </StyledIconButton>
    );
  }
}

DeleteButton.propTypes = {
  specDetail: propTypes.specDetail.isRequired,
  specDetailCom: propTypes.specDetailCom,
  openModalDialog: PropTypes.func.isRequired,
  purchaseOrderId: PropTypes.string,
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(null, mapDispatchToProps)(DeleteButton);
