import React from "react";
import pluralize from "pluralize";
import styled from "styled-components";

const Title = props => {
  const { count } = props;
  return pluralize("Item", count || 0, true);
};
const BidTypeText = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7a8398;
  margin-left: 12px;
  margin-right: 8px;
`;
export const getVendorName = bidGroupVendor => {
  const isoCode = bidGroupVendor?.quoteProjectCurrency?.currency?.isoCode;
  const quotedTitle = isoCode ? ` (Quoted in ${isoCode})` : "";
  const title = `${bidGroupVendor.vendor.name}${quotedTitle}`;
  if (bidGroupVendor.bidType === "COM Spec Only") {
    return (
      <React.Fragment>
        <span>{title}</span>
        <BidTypeText>COM Vendor</BidTypeText>
      </React.Fragment>
    );
  }

  return title;
};

export default (bgVendors, specsTotal) => () => {
  const staticBands = [
    {
      title: <Title count={specsTotal} />,
      children: [
        { columnName: "indicator" },
        { columnName: "description" },
        { columnName: "customNumber" },
        { columnName: "totalSampleQuantity" },
        { columnName: "totalProductionQuantity" },
      ],
    },
    {
      title: "Initial Budget",
      children: [
        { columnName: "designerBudget" },
        { columnName: "designerQuantity" },
        { columnName: "designerExtendedCost" },
      ],
    },
  ];
  return bgVendors.reduce((bands, bgVendor, vendorIndex) => {
    bands.push({
      title: getVendorName(bgVendor),
      children: [
        {
          columnName: `quote_${vendorIndex}_sampleCOMQuantity`,
        },
        {
          columnName: `quote_${vendorIndex}_extendedSampleCOMQuantity`,
        },
        {
          columnName: `quote_${vendorIndex}_samplePricing`,
        },
        {
          columnName: `quote_${vendorIndex}_extendedSamplePricing`,
        },
        {
          columnName: `quote_${vendorIndex}_productionCOMQuantity`,
        },
        {
          columnName: `quote_${vendorIndex}_extendedProductionCOMQuantity`,
        },
        {
          columnName: `quote_${vendorIndex}_productionPricing`,
        },
        {
          columnName: `quote_${vendorIndex}_extendedProductionPricing`,
        },
        {
          columnName: `quote_${vendorIndex}_selectCOM`,
        },
        {
          columnName: `quote_${vendorIndex}_chevron`,
        },
      ],
    });
    return bands;
  }, staticBands);
};
