import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

import propTypes from "../../../../constants/propTypes";
import RegularTextSmall from "../../../ui/Typography/RegularTextSmall";
import BoldTextSmall from "../../../ui/Typography/BoldTextSmall";
import AuthorLineLinks from "./AuthorLineLinks";
import Author from "./Author";
import Loader from "../../../ui/Loader/Loader";
import { dateFormat } from "./components";

const Value = styled(RegularTextSmall)`
  margin-right: 16px;
`;

const Line = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  ${BoldTextSmall} {
    margin-right: 4px;
  }
`;

const Date = ({ children }) => (
  <Value>{moment(children).format(dateFormat)}</Value>
);

Date.propTypes = {
  children: PropTypes.string,
};

const DateWithLabel = ({ label, date }) => {
  if (!date) return null;
  return (
    <Fragment>
      <BoldTextSmall>{label}: </BoldTextSmall>
      <Date>{date}</Date>
    </Fragment>
  );
};
DateWithLabel.propTypes = { label: PropTypes.string, date: PropTypes.string };

const AuthorLine = ({ note }) => {
  return (
    <Line>
      <Loader width="400px" height="24px">
        <Author note={note} />
        {note.time && <Date>{note.time}</Date>}
        {note.category === "Tracking" && note.trackingNumber && (
          <Fragment>
            <BoldTextSmall>Tracking #: </BoldTextSmall>
            <Value>{note.trackingNumber}</Value>
          </Fragment>
        )}
        <AuthorLineLinks
          purchaseOrders={note.purchaseOrders}
          shipments={note.shipments}
          specs={note.specs}
          invoices={note.invoices}
        />
      </Loader>
    </Line>
  );
};

AuthorLine.propTypes = {
  note: propTypes.note,
};

export default AuthorLine;
