import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const MainSection = styled.div`
  width: 65%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const BottomSection = styled.div`
  width: 100%;
`;

export const ModalContent = styled.div`
  background-color: rgb(255, 255, 255);
  width: 720px;
`;

export const ContentWrapper = props => <ModalContent {...props} />;
