import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "../../ui/Loader";

const StyledSubtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: ${({ withMargin }) => (withMargin ? "16px" : "0px")};
`;

export const Content = styled.span`
  padding-right: 16px;
`;

const StyledDetailTitle = styled.span`
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.38);
  font-weight: normal;
`;
StyledDetailTitle.displayName = "StyledDetailTitle";

const StyledDetailContent = styled.span`
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
`;

const Subtitle = ({ children, details = [], withMargin = true }) => {
  return (
    <StyledSubtitle withMargin={withMargin}>
      {children ? (
        <Content>
          <Loader height="24px">{children}</Loader>
        </Content>
      ) : null}
      {details.map(({ title, content }, i) => (
        <Fragment key={i}>
          <Loader>
            {title && <StyledDetailTitle>{title}</StyledDetailTitle>}
            <StyledDetailContent>{content}</StyledDetailContent>
          </Loader>
        </Fragment>
      ))}
    </StyledSubtitle>
  );
};

Subtitle.propTypes = {
  withMargin: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  details: PropTypes.array,
};

export default Subtitle;
