import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Paper from "../../mui/core/Paper";
import Property from "../../../models/Property";
import AddCircle from "@material-ui/icons/AddCircle";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import LocationCombined from "../../ui/LocationCombined";

const columnOptions = {
  name: { bold: true },
  "location.address": {
    title: "Address",
    render: LocationCombined,
    fill: true,
  },
  totalSpend: { default: "—", align: "right", sortingEnabled: false },
  totalFee: { default: "—", align: "right", sortingEnabled: false },
  projectsCount: { title: "Projects", align: "right" },
};

const getRowMenu = (onEditProperty, onDeleteProperty, onDuplicateProperty) => {
  return [
    { text: "Edit", onClick: onEditProperty },
    { text: "Duplicate", onClick: onDuplicateProperty },
    { separator: true },
    { text: "Delete", onClick: onDeleteProperty },
  ];
};

const columns = [
  "name",
  "location.address",
  "totalSpend",
  "totalFee",
  "projectsCount",
];

const PropertiesPage = ({
  clientId,
  dataComponent,
  onAddProperty,
  onEditProperty,
  onDeleteProperty,
  onDuplicateProperty,
}) => {
  const rowMenu = useMemo(
    () => getRowMenu(onEditProperty, onDeleteProperty, onDuplicateProperty),
    [onEditProperty, onDeleteProperty, onDuplicateProperty]
  );

  return (
    <Paper>
      <BWGridAPI
        model={Property}
        apiRoute="properties"
        includes={["entity", "location"]}
        dataComponent={dataComponent}
        defaultSorting={[
          {
            columnName: "name",
            direction: "asc",
          },
        ]}
        rootFilters={{
          $where: {
            "entity.clientId": clientId,
          },
        }}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Properties`}
          actions={[
            {
              text: "ADD PROPERTY",
              icon: <AddCircle />,
              handler: onAddProperty,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu}
        />
      </BWGridAPI>
    </Paper>
  );
};

PropertiesPage.propTypes = {
  onAddProperty: PropTypes.func,
  onEditProperty: PropTypes.func,
  onDeleteProperty: PropTypes.func,
  onDuplicateProperty: PropTypes.func,
  clientId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
};

export default PropertiesPage;
