import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { LockOutlined } from "@material-ui/icons";

import AlternatePortalHeader, { MenuItem } from "../AlternatePortalHeader";
import VendorPicker from "./VendorPicker";

import withRenderControlByRole, {
  Actions,
} from "../../../../hocs/withRenderControlByRole";
import { UserMenuIcon } from "../../components";

export function VendorPortalHeader({
  onClick,
  authenticatedUserName,
  anchorElement,
  onCloseUserMenu,
  onLogout,
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(push("/settings"));
  };
  const settingsMenuItem = (
    <MenuItem onClick={handleClick}>
      <UserMenuIcon>
        <LockOutlined />
      </UserMenuIcon>
      Change Password
    </MenuItem>
  );

  return (
    <Fragment>
      <VendorPicker />
      <AlternatePortalHeader
        anchorElement={anchorElement}
        authenticatedUserName={authenticatedUserName}
        onClick={onClick}
        onCloseUserMenu={onCloseUserMenu}
        onLogout={onLogout}
        settingsMenuItem={settingsMenuItem}
      />
    </Fragment>
  );
}

VendorPortalHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  authenticatedUserName: PropTypes.string,
  anchorElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onCloseUserMenu: PropTypes.func,
  onLogout: PropTypes.func,
};

export default withRenderControlByRole(
  VendorPortalHeader,
  ["Vendor Portal"],
  Actions.SHOW
);
