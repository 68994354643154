import { useEffect } from "react";

import { usePrevious } from "./usePrevious";
import * as QUEUE_STATUSES from "../../constants/queue";

const _handleStatusChange = (status, wasProcessing, queue, func) => {
  if (wasProcessing && queue.status === status) {
    func(queue);
  }
};

const processQueueRequests = ({ prevQueue, queue }, { onSuccess, onError }) => {
  if (!prevQueue || !queue) {
    return;
  }

  const wasProcessing = prevQueue.status === QUEUE_STATUSES.QUEUE_PROCESSING;

  _handleStatusChange(
    QUEUE_STATUSES.QUEUE_SUCCESS,
    wasProcessing,
    queue,
    onSuccess
  );
  _handleStatusChange(
    QUEUE_STATUSES.QUEUE_ERROR,
    wasProcessing,
    queue,
    onError
  );
};

export const useQueueProcessRequests = (queue, { onSuccess, onError }) => {
  const prevQueue = usePrevious(queue);
  useEffect(() => {
    processQueueRequests(
      { prevQueue, queue },
      {
        onSuccess,
        onError,
      }
    );
  }, [queue, onError, onSuccess, prevQueue]);
};
