import React, { Fragment } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import pluralize from "pluralize";

import { connect } from "react-redux";

import { renderRoutes } from "react-router-config";
import NavigationBar from "../../layout/NavigationBar/NavigationBar";
import { StyledTitle } from "../../layout/NavigationBar/components";
import UnassignedSpecs from "./UnassignedSpecs";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import {
  quotingPODCId,
  quotingBidGroupsDCId,
} from "../../../actions/quotingActions";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import NavigationBarTab from "../../layout/NavigationBar/NavigationBarTab";
import Mail from "@material-ui/icons/Mail";
import Send from "@material-ui/icons/Send";
import {
  FilterIcon,
  TitleWrapper,
  FilterOption,
  TitleOptionWrapper,
} from "./components";

export const OptionTab = ({ icon, label }) => {
  return (
    <NavigationBarTab>
      {icon}
      {label}
    </NavigationBarTab>
  );
};
const quotingCategoriesDCId = "Quoting-categoriesList";
OptionTab.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
};

const navigationTabs = () => {
  return [
    {
      text: "Bid Groups",
      label: <OptionTab icon={<Mail />} label="Bid Groups" />,
      path: "/bid-groups",
      permission: "project-detail-accounting-funding",
    },
    {
      text: "Direct to PO",
      label: <OptionTab icon={<Send />} label="Direct to PO" />,
      path: "/po-groups",
      permission: "project-detail-accounting-invoices",
    },
  ];
};

export const getLeftChildren = (
  loading,
  title,
  onOpenFilter,
  filterOptions
) => {
  const filterCount = Object.values(filterOptions).reduce(
    (filterCount, options) => {
      return options.length > 0 ? filterCount + 1 : filterCount;
    },
    0
  );

  return (
    <TitleWrapper onClick={onOpenFilter}>
      {!loading && (
        <Fragment>
          <StyledTitle>{title}</StyledTitle>
          <TitleOptionWrapper>
            <FilterOption hasFilter={filterCount}>
              <span>
                {filterCount
                  ? `${filterCount} ${pluralize("Filter", filterCount)}`
                  : "Show All"}
              </span>
              <FilterIcon />
            </FilterOption>
          </TitleOptionWrapper>
        </Fragment>
      )}
    </TitleWrapper>
  );
};

function QuotingPage(props) {
  const {
    loading,
    location,
    projectId,
    numberOfBidGroups,
    numberOfPurchaseOrders,
    route,
    role,
    specCategories,
    onOpenFilter,
    filterOptions,
  } = props;

  const pathName = location.pathname.substring(
    location.pathname.lastIndexOf("/")
  );

  let title = "";
  switch (pathName) {
    case "/bid-groups": {
      title = `${numberOfBidGroups} Bid Groups`;
      break;
    }
    default: {
      title = `${numberOfPurchaseOrders} Purchase Orders`;
    }
  }
  return (
    <Fragment>
      <UnassignedSpecs
        path={pathName}
        projectId={projectId}
        role={role}
        specCategories={specCategories}
        isClosedProject={props.isClosedProject}
      />
      <NavigationBar
        loading={loading}
        title={`${title}`}
        leftChildren={getLeftChildren(
          loading,
          title,
          onOpenFilter,
          filterOptions
        )}
        navigationTabs={navigationTabs()}
      />
      {renderRoutes(route.routers, props)}
    </Fragment>
  );
}
QuotingPage.propTypes = {
  projectId: PropTypes.string.isRequired,
  isClosedProject: PropTypes.bool,
  numberOfPurchaseOrders: PropTypes.number.isRequired,
  numberOfBidGroups: PropTypes.number.isRequired,
  route: propTypes.route,
  role: propTypes.userRole,
  loading: PropTypes.bool,
  location: PropTypes.object,
  specCategories: PropTypes.arrayOf(propTypes.specCategory),
  onOpenFilter: PropTypes.func,
  filterOptions: PropTypes.shape({}),
};
QuotingPage.defaultProps = {
  projectId: 0,
  numberOfPurchaseOrders: 0,
  numberOfBidGroups: 0,
  specCategories: [],
  filterOptions: {},
};
function mapStateToProps(state) {
  const flattenedPODataComponent = getDataComponentFlattenedRequestState(
    quotingPODCId,
    state
  );

  const flattenedBidGroupsDataComponent = getDataComponentFlattenedRequestState(
    quotingBidGroupsDCId,
    state
  );

  const specCategoriesDataComponent = getDataComponentFlattenedRequestState(
    quotingCategoriesDCId,
    state
  );
  const specCategories =
    getBluechipResources(specCategoriesDataComponent, state) || [];

  return {
    loading:
      flattenedBidGroupsDataComponent.loading ||
      flattenedPODataComponent.loading,
    specCategories: specCategories,
    numberOfPurchaseOrders: flattenedPODataComponent.totalRows,
    numberOfBidGroups: flattenedBidGroupsDataComponent.totalRows,
  };
}
export default connect(mapStateToProps, null)(QuotingPage);
