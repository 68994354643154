import React from "react";
import PropTypes from "prop-types";

import { FormPageSection } from "../../../../layout/FormPageSection";
import fields from "./vendorNotesFields";
import { withLabeledTextOption } from "../../../../../withPORevision";

const VendorNotesForm = ({ values, errors, ...formikProps }) => {
  return (
    <FormPageSection
      fields={fields}
      headerText="Vendor Instructions"
      values={values}
      errors={errors}
      {...formikProps}
    />
  );
};

VendorNotesForm.propTypes = {
  values: PropTypes.shape({}),
  errors: PropTypes.shape({}),
};

export default withLabeledTextOption(VendorNotesForm);
