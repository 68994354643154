import * as REQUEST_TYPES from "../../../../constants/RequestTypes";

export const handleSuccess = (
  fetchData,
  bidGroupId,
  purchaseOrderDataComponentId,
  closeModalDialog,
  showSnackNotificationAction,
  snackMessage,
  reloadBidGroupVendorSpecs
) => {
  fetchData(purchaseOrderDataComponentId, REQUEST_TYPES.FIND, {
    id: bidGroupId,
  });
  reloadBidGroupVendorSpecs && reloadBidGroupVendorSpecs();
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleSuccessForBidGroupVendorSpecs = (
  fetchData,
  dataComponentId
) => {
  fetchData(dataComponentId);
};
export const handleSuccessForPurchaseOrder = (
  fetchData,
  dataComponentId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog,
  reloadBidGroupVendorSpecs
) => {
  fetchData(dataComponentId);
  reloadBidGroupVendorSpecs && reloadBidGroupVendorSpecs();
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleSuccessForBidGroupVendorSpecQuote = (
  fetchData,
  bidGroupId,
  dataComponentId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog
) => {
  fetchData(dataComponentId);
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleError = (data, showSnackNotificationAction) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};
