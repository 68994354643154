import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import queryString from "query-string";

import { BackButtonIcon, BackButton, StyledNavigationArea } from "./components";
import { openNotesModal } from "../../../actions/notesActions";
import Actions from "./Actions";

export const buildPush = (route, push, returnUrl, sectionName) => () => {
  if (!route) return;
  const [href, prevParams = ""] = route.split("?");
  const parsedPrevParams = queryString.parse(prevParams);
  const newParams = queryString.stringify({
    ...parsedPrevParams,
    returnUrl,
    sectionName,
  });

  return () => push(`${href}?${newParams}`, { keepAutoSaveComponentId: true });
};

export const NavigationArea = ({
  push,
  backRoute,
  nextItemRoute,
  sectionName,
  lastItemRoute,
  backText,
  goBack,
  hideActions,
  onOpenNotesModal,
  loading,
}) => {
  const handleBack = useCallback(() => {
    if (backRoute) return push(backRoute, { keepAutoSaveComponentId: true });
    return goBack();
  }, [backRoute, push, goBack]);

  const handleGoLast = useMemo(
    buildPush(lastItemRoute, push, backRoute, sectionName),
    [lastItemRoute, push, backRoute, sectionName]
  );

  const handleGoNext = useMemo(
    buildPush(nextItemRoute, push, backRoute, sectionName),
    [nextItemRoute, push, backRoute, sectionName]
  );

  return (
    <StyledNavigationArea>
      <div>
        <BackButton onClick={handleBack}>
          <BackButtonIcon />
          {backText}
        </BackButton>
      </div>
      {!hideActions && (
        <Actions
          onOpenNotesModal={onOpenNotesModal}
          goLastItem={handleGoLast}
          goNextItem={handleGoNext}
          loading={loading}
        />
      )}
    </StyledNavigationArea>
  );
};

NavigationArea.propTypes = {
  push: PropTypes.func,
  goBack: PropTypes.func,
  onOpenNotesModal: PropTypes.func,
  backRoute: PropTypes.string,
  sectionName: PropTypes.string,
  nextItemRoute: PropTypes.string,
  lastItemRoute: PropTypes.string,
  backText: PropTypes.string,
  hideActions: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, { backText, backUrl }) => {
  const { returnUrl, sectionName = "" } = queryString.parse(location.search);
  return {
    sectionName,
    backText: backText || `BACK TO ${sectionName.toUpperCase()}`,
    backRoute: backUrl || returnUrl,
  };
};

const mapDispatchToProps = {
  push,
  goBack,
  openNotesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationArea);
