import React, { useEffect, useMemo } from "react";

import Paper from "../../mui/core/Paper";
import { Content } from "../../layout/Content";
import { useHeaderComponent } from "../../ui/BWGrid/GridHeader";
import pluralize from "pluralize";
import GridAPI from "../../ui/Grid/GridAPI";
import BidGroup from "../../../models/BidGroup";
import { useFlattenedDatacomponent } from "../../hooks/useDatacomponent.js";
import * as filterBuilders from "../../ui/Grid/filterDataTypeProviders";
import useVendorScopeStore from "../../hooks/useVendorScopeStore";
import useActions from "../../hooks/useActions";
import {
  clientFilterDataComponentId,
  fetchClientFilters,
} from "../../../actions/vendorPortalRFQActions";
import { useV2DatacomponentResources } from "../../hooks/useResources";
import * as RequestTypes from "../../../constants/RequestTypes";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import {
  QuoteTimeTypeProvider,
  QuoteStatusTypeProvider,
} from "./dataTypeProviders";

export const dataComponentId = "RFQVendorPortal";

const VendorRFQ = () => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);
  const selectedVendor = useVendorScopeStore(state => state.selectedVendor);

  const actions = useActions({ fetchClientFilters, pushWithReturnUrl });
  const clients = useV2DatacomponentResources(
    clientFilterDataComponentId,
    [],
    RequestTypes.LIST
  );

  useEffect(() => {
    actions.fetchClientFilters(selectedVendor);
  }, [actions, selectedVendor]);

  const GridHeader = useHeaderComponent({
    headerText: pluralize(
      "Request for Quote",
      dataComponent.totalRows || 0,
      true
    ),
  });

  const clientFilterOptions = useMemo(() => {
    return clients.map(client => ({ id: client.name, name: client.name }));
  }, [clients]);

  const rowProps = useMemo(
    () => ({
      onClick: ({ id }) => actions.pushWithReturnUrl(`/rfqs/${id}`),
      getRowTitle: ({ name }) => `Press to go to ${name} details`,
    }),
    [actions]
  );

  if (!selectedVendor) return null;

  return (
    <Content>
      <Paper>
        <GridAPI
          model={BidGroup}
          apiRoute="bid-group-vendors"
          includes={["bidGroup.[rfq, project.property.entity.client]"]}
          rowProps={rowProps}
          defaultSorting={[
            {
              columnName: "bidGroup.project.number",
              direction: "asc",
            },
          ]}
          apiFilters={{
            rootFilters: {
              $where: { vendorId: selectedVendor.id },
            },
          }}
          dataComponent={dataComponent}
          columnExtensions={[
            { columnName: "quoteTimeRemaining", sortingEnabled: false },
          ]}
          columns={[
            {
              name: "bidGroup.project.number",
              title: "Project Number",
              bold: true,
              filter: true,
              width: "5vw",
            },
            {
              name: "bidGroup.project.name",
              title: "Project",
              filter: true,
            },
            {
              name: "bidGroup.project.property.entity.client.name",
              title: "Client",
              filter: "select",
              filterOptions: {
                options: clientFilterOptions,
              },
            },
            {
              name: "bidGroup.name",
              title: "Bid Group",
            },
            {
              name: "quoteStatus",
              title: "Status",
              filter: "select",
              filterOptions: {
                options: [
                  { id: "open", name: "Open" },
                  { id: "submitted", name: "Submitted" },
                  { id: "in-review", name: "In Review" },
                  { id: "no-bid", name: "No Bid" },
                  { id: "lost", name: "Lost" },
                  { id: "awarded", name: "Awarded" },
                ],
              },
            },
            {
              name: "quoteTimeRemaining",
              title: " ",
            },
          ]}
          tableComponents={{
            GridHeader,
          }}
          dataTypeProviders={[QuoteTimeTypeProvider, QuoteStatusTypeProvider]}
          filteringDataTypeProviders={[
            filterBuilders.buildTextTypeProvider([
              "bidGroup.project.number",
              "bidGroup.project.name",
            ]),
            filterBuilders.buildStaticSelectTypeProvider([
              "bidGroup.project.property.entity.client.name",
              "quoteStatus",
            ]),
          ]}
        />
      </Paper>
    </Content>
  );
};

export default VendorRFQ;
