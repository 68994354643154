import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

const reducerMap = {
  [actionTypes.GENERATE_REPORT_REQUEST]: (state, { reportReference }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [reportReference]: true,
    },
  }),
  [actionTypes.GENERATE_REPORT_SUCCESS]: (state, { reportReference }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [reportReference]: false,
    },
  }),
  [actionTypes.GENERATE_REPORT_FAILURE]: (state, { reportReference }) => ({
    ...state,
    loaders: {
      ...state.loaders,
      [reportReference]: false,
    },
  }),
  default: state => state,
};

export default function(state = initialState.reports, action) {
  const { type, payload } = action;

  return (reducerMap[type] || reducerMap.default)(state, payload);
}
