import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@material-ui/icons";

export const StyledIconButton = styled(IconButton)`
  padding: 3px 3px;
`;

const ControlDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-right: ${props => (props.last ? "0px" : "15px")};
`;

const StyledInputLabel = styled(InputLabel)`
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.38);
  display: inline-table;
`;

const StyledSpan = styled.span`
  padding-bottom: 3px;
  color: rgba(0, 0, 0, 0.38);
`;

const buildRowsPerPageSelection = (
  withoutPageSizeSelection,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  rowsPerPage
) => {
  if (withoutPageSizeSelection) return [];

  const rowsPerPageOptionsItems = rowsPerPageOptions.map(option => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ));

  return (
    <ControlDiv>
      <StyledInputLabel>Show</StyledInputLabel>
      <Select
        value={rowsPerPage}
        onChange={e => onChangeRowsPerPage(e.target.value)}
        disableUnderline={true}
      >
        {rowsPerPageOptionsItems}
      </Select>
    </ControlDiv>
  );
};

const buildPageSelection = (count, rowsPerPage, page, onChangePage) => {
  const onlyOnePage = Math.ceil(count / rowsPerPage) < 2;

  const pageList = Array.from(
    { length: Math.ceil(count / rowsPerPage) },
    (_, i) => i + 1
  );

  return (
    <ControlDiv>
      <StyledInputLabel>Page</StyledInputLabel>
      <Select
        value={page}
        onChange={e => onChangePage(e.target.value)}
        disabled={onlyOnePage}
        disableUnderline={true}
      >
        {pageList.map(option => (
          <MenuItem key={option} value={option - 1}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </ControlDiv>
  );
};

const buildRowsIndicators = (count, page, rowsPerPage) => {
  const firstShowedRecord = count > 0 ? page * rowsPerPage + 1 : 0;
  let lastRecord = page * rowsPerPage + rowsPerPage;
  lastRecord = lastRecord > count ? count : lastRecord;

  return (
    <ControlDiv>
      <StyledSpan>
        {firstShowedRecord}-{lastRecord} of {count}
      </StyledSpan>
    </ControlDiv>
  );
};

const buildRightArrows = (totalPages, page, onChangePage, count) => {
  const isOnLastPage = page == totalPages - 1 || count == 0;

  return (
    <React.Fragment>
      <StyledIconButton
        onClick={() => !isOnLastPage && onChangePage(page + 1)}
        disabled={isOnLastPage}
      >
        <ChevronRight color={isOnLastPage ? "disabled" : "inherit"} />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => !isOnLastPage && onChangePage(totalPages - 1)}
        disabled={isOnLastPage}
      >
        <LastPage color={isOnLastPage ? "disabled" : "inherit"} />
      </StyledIconButton>
    </React.Fragment>
  );
};

const buildLeftArrows = (page, onChangePage) => {
  const isOnFirtsPage = page == 0;

  return (
    <React.Fragment>
      <StyledIconButton
        onClick={() => !isOnFirtsPage && onChangePage(0)}
        disabled={isOnFirtsPage}
      >
        <FirstPage color={isOnFirtsPage ? "disabled" : "inherit"} />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => !isOnFirtsPage && onChangePage(page - 1)}
        disabled={isOnFirtsPage}
      >
        <ChevronLeft color={isOnFirtsPage ? "disabled" : "inherit"} />
      </StyledIconButton>
    </React.Fragment>
  );
};

const buildArrowNavigation = (count, rowsPerPage, page, onChangePage) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  return (
    <ControlDiv last>
      {buildLeftArrows(page, onChangePage)}
      {buildRightArrows(totalPages, page, onChangePage, count)}
    </ControlDiv>
  );
};

const TablePagination = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  withoutPageSizeSelection,
}) => {
  const onlyOnePage = rowsPerPage <= 0;
  return onlyOnePage ? null : (
    <React.Fragment>
      {buildRowsPerPageSelection(
        withoutPageSizeSelection,
        onChangeRowsPerPage,
        rowsPerPageOptions,
        rowsPerPage
      )}
      {buildPageSelection(count, rowsPerPage, page, onChangePage)}
      {buildRowsIndicators(count, page, rowsPerPage)}
      {buildArrowNavigation(count, rowsPerPage, page, onChangePage)}
    </React.Fragment>
  );
};

TablePagination.defaultProps = {
  rowsPerPageOptions: [],
  rowsPerPage: 10,
  onChangeRowsPerPage: () => null,
  classes: {},
};

TablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  withoutPageSizeSelection: PropTypes.bool,
};

export default TablePagination;
