import React from "react";

import propTypes from "../../../constants/propTypes";
import {
  Container,
  Title,
  Page,
  AuthFormContainer,
  AuthFormWrapper,
} from "./components";
import { LoginForm } from "./LoginForm";
import DialogBottomError from "../../inputs/DialogBottomError";

const LoginPage = ({ errorPayload }) => {
  return (
    <Page>
      <Container>
        <Title />
        <AuthFormContainer>
          <DialogBottomError
            errorPayload={errorPayload}
            errorKeysToMatch={["login"]}
          />
          <AuthFormWrapper>
            <LoginForm />
          </AuthFormWrapper>
        </AuthFormContainer>
      </Container>
    </Page>
  );
};

LoginPage.propTypes = {
  errorPayload: propTypes.errorPayload,
};

export default LoginPage;
