import pluralize from "pluralize";
import React from "react";
import styled from "styled-components";

import _startCase from "lodash/startCase";
import _kebabCase from "lodash/kebabCase";

import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import DatePickerWithError from "../../../../inputs/DatePickerWithError";
import DecimalInput from "../../../../inputs/DecimalInput";
import SelectWithError, {
  SelectWithError as RawSelectWithError,
} from "../../../../inputs/SelectWithError";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import { getOptionsFromSchema } from "../../../../inputs/utils";
import { InputLabel } from "../../../../mui/core";
import {
  selectCustomReportDataComponentId,
  selectQueryReportDataComponentId,
} from "./GenerateCustomReportContainer";
import InputAdornment from "../../../../mui/core/InputAdornment";

export const loadModel = model =>
  require(`../../../../../models/${model}`).default;

const RangeInputLabel = styled(InputLabel)`
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.8);
  &:after {
    content: " *";
  }
`;

export const mapDataType = (dataType, columnName) => {
  if (columnName && /Id$/.test(columnName)) {
    return "id";
  }

  const types = [
    {
      name: "text",
      prefixes: ["character", "text"],
    },
    {
      name: "number",
      prefixes: ["decimal", "numeric", "number", "bigint"],
    },
    {
      name: "integer",
      prefixes: ["integer"],
    },
    {
      name: "date",
      prefixes: ["date", "timestamp"],
    },
    {
      name: "boolean",
      prefixes: ["boolean"],
    },
    {
      name: "enum",
      prefixes: ["USER-DEFINED"],
    },
  ];

  const type = types.find(({ prefixes }) =>
    prefixes.some(prefix => dataType.includes(prefix))
  );

  return type?.name;
};

const inputs = {
  number: {
    InputComponent: TextInputWithError,
    InputProps: {
      inputComponent: DecimalInput,
    },
    inputProps: {
      decimalScale: 2,
    },
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  integer: {
    InputComponent: TextInputWithError,
    InputProps: {
      inputComponent: DecimalInput,
    },
    inputProps: {
      decimalScale: 0,
    },
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  text: {
    InputComponent: TextInputWithError,
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  date: {
    InputComponent: DatePickerWithError,
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  boolean: {
    InputComponent: CheckboxWithError,
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  enum: {
    InputComponent: SelectWithError,
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  id: {
    InputComponent: SelectWithError,
    fullWidth: true,
    required: true,
    isAutocomplete: true,
    valueProperty: "id",
    labelProperty: row => row.number || row.name,
    apiFilterLabelProperty: "name",
    ignoreLabeledText: true,
  },
};

const getRoute = columnName => {
  const split = _startCase(columnName.replace("Id", "")).split(" ");
  const lastWord = split.pop();
  const endPoint = _kebabCase(`${split.join("-")}-${pluralize(lastWord)}`);
  return endPoint;
};

const getModelName = columnName => {
  return _startCase(columnName.replace("Id", "")).replace(" ", "");
};

const getMultiSelectLabel = label => {
  const split = _startCase(label).split(" ");
  const lastWord = split.pop();
  return `${split.join(" ")} ${pluralize(lastWord)}`;
};

const getGridSize = multiple => (multiple ? 12 : 6);

const getInput = ({
  dataType,
  columnName,
  filter,
  entityName,
  editName,
  selectName,
}) => {
  const mappedDataType = mapDataType(dataType, columnName);
  const multiple = filter === "multiSelect";
  if (mappedDataType === "enum") {
    return {
      input: {
        ...inputs[mappedDataType],
        label: editName,
        name: `['${selectName}']`,
        multiple,
        options: getOptionsFromSchema(entityName, pluralize(columnName))(),
      },
      grid: { xs: getGridSize(multiple) },
    };
  }

  if (mappedDataType === "id") {
    const label = editName.replace("Id", "");
    const modelName = getModelName(columnName);
    const route = getRoute(columnName);
    return {
      input: {
        ...inputs[mappedDataType],
        label: multiple ? getMultiSelectLabel(label) : label,
        name: `['${selectName}']`,
        isSingleAutocomplete: !multiple,
        isAutocompleteMulti: multiple,
        dataComponentId: selectName,
        APIOptions: {
          model: loadModel(modelName),
          route,
          pageSize: -1,
          sort: [{ columnName: "name", direction: "asc" }],
          fetchOnlyOnce: true,
        },
      },
      grid: { xs: getGridSize(multiple) },
    };
  }

  const adornmentTypes = {
    like: "≈",
    ilike: "≈",
    exact: "=",
    greaterThan: ">",
    lessThan: "<",
  };

  const startAdornment = adornmentTypes[filter] ? (
    <InputAdornment position="start">{adornmentTypes[filter]}</InputAdornment>
  ) : null;

  return {
    input: {
      ...inputs[mappedDataType],
      label: editName,
      name: `['${selectName}']`,
      InputProps: {
        ...inputs[mappedDataType].InputProps,
        startAdornment,
      },
    },
    grid: { xs: 6 },
  };
};

const buildFilters = columns => {
  const items = [];
  columns.forEach(
    ({ selectName, editName, entityName, columnName, filter, dataType }) => {
      if (filter === "range") {
        items.push(
          ...[
            {
              element: <RangeInputLabel>{editName}</RangeInputLabel>,
              grid: { xs: 12 },
            },
            {
              input: {
                ...inputs[mapDataType(dataType)],
                name: `['${selectName}'][0]`,
                InputProps: {
                  ...inputs[mapDataType(dataType)].InputProps,
                  startAdornment: (
                    <InputAdornment position="start">from</InputAdornment>
                  ),
                },
              },
              grid: { xs: 6 },
            },
            {
              input: {
                ...inputs[mapDataType(dataType)],
                name: `['${selectName}'][1]`,
                InputProps: {
                  ...inputs[mapDataType(dataType)].InputProps,
                  startAdornment: (
                    <InputAdornment position="start">to</InputAdornment>
                  ),
                },
              },
              grid: { xs: 6 },
            },
          ]
        );
        return;
      }

      items.push(
        getInput({
          dataType,
          columnName,
          filter,
          entityName,
          editName,
          selectName,
        })
      );
    }
  );

  return [
    {
      element: <InputLabel>Filters</InputLabel>,
      grid: { xs: 12 },
    },
    ...items,
  ];
};

const buildQueryFilters = (queryReportParams = []) => {
  const items = [];
  queryReportParams.forEach(({ name, type }) => {
    const mappedDataType = mapDataType(type);
    items.push({
      input: {
        ...inputs[mappedDataType],
        label: _startCase(name),
        name,
      },
      grid: { xs: 6 },
    });
  });

  return [
    {
      element: <InputLabel>Filters</InputLabel>,
      grid: { xs: 12 },
    },
    ...items,
  ];
};

const filterTypes = [
  "exact",
  "range",
  "greaterThan",
  "lessThan",
  "like",
  "select",
  "multiSelect",
  "toBeDefined",
];

export const getFilterDataOptions = selectedReport =>
  selectedReport?.customReportDataOptions?.filter(
    ({ filter }) => filterTypes.indexOf(filter) !== -1
  ) || [];

export const getFields = ({
  reportType,
  selectedReport,
  setReportId,
  setReportType,
  setFieldValue,
}) => {
  const filterDataOptions = getFilterDataOptions(selectedReport);
  const filters = buildFilters(filterDataOptions);
  const queryReportFilters = buildQueryFilters(
    selectedReport?.queryReportParams
  );

  const fields = {
    groups: [
      {
        items: [
          {
            input: {
              InputComponent: RawSelectWithError,
              label: "Report Type",
              name: "reportType",
              valueProperty: "id",
              labelProperty: "name",
              value: reportType,
              onChange: event => {
                setReportId(null);
                setReportType(event.target.value);
                setFieldValue("reportId", null);
              },
              options: [
                {
                  id: "customReport",
                  name: "Custom Report",
                },
                {
                  id: "queryReport",
                  name: "Query Report",
                },
              ],
              fullWidth: true,
              required: true,
            },
          },
          getSelectReportInput(reportType, setReportId),
          {
            input: {
              InputComponent: TextInputWithError,
              label: "Description",
              name: "description",
              fullWidth: true,
              disabled: true,
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };

  if (selectedReport?.queryReportParams?.length > 0) {
    fields.groups.push({
      items: queryReportFilters,
    });
  }

  if (filterDataOptions.length > 0) {
    fields.groups.push({
      items: filters,
    });
  }

  return fields;
};

export const getInitialValues = ({
  queryReportParams = [],
  ...selectedReport
} = {}) => {
  const initialValues = {
    reportId: selectedReport?.id,
    description: selectedReport?.description,
  };

  const filterDataOptions = getFilterDataOptions(selectedReport);

  filterDataOptions.forEach(({ selectName, filter }) => {
    if (filter === "range") {
      initialValues[selectName] = [null, null];
      return;
    }
    initialValues[selectName] = null;
  });

  queryReportParams.forEach(({ name, type }) => {
    initialValues[name] = type === "boolean" ? false : null;
  });

  return initialValues;
};

const getSelectReportInput = (reportType, setReportId) => ({
  input: {
    InputComponent: SelectWithError,
    label: "Select Report",
    name: "reportId",
    dataComponentId:
      reportType === "customReport"
        ? selectCustomReportDataComponentId
        : selectQueryReportDataComponentId,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    handleChange: event => setReportId(event.target.value),
    fullWidth: true,
    required: true,
    valueProperty: "id",
    labelProperty: "name",
  },
  grid: { xs: 12 },
});
