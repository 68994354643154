import React, { useMemo, useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PropTypes from "prop-types";
import moment from "moment";
import _uniqBy from "lodash/uniqBy";

import propTypes from "../../../../../../constants/propTypes";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import useScopedCurrency from "../../../../../hooks/useScopedCurrency";
import { BWGridLocal, GridRow, GridTable } from "../../../../../ui/BWGrid";
import {
  TitleWrapper,
  TitleRowWrapper,
  ExpandActionWrapper,
  ExpandButton,
  OutstandingStatus,
} from "./components";
import Loader from "../../../../../ui/Loader";
import Summary from "./Summary";
import { PRIMARY_DATE_FORMAT } from "../../../../../../constants/formats";

export const fundingRequestDataComponentId =
  "funding-request-data-component-id";

export const getStatusTitle = funding => {
  if (funding.paymentStatus === "Outstanding") {
    return <OutstandingStatus>{funding.paymentStatus}</OutstandingStatus>;
  }

  return funding.paymentStatus;
};
const columnOptions = (scopedCurrency, currencyFormatter, statusFilters) => ({
  createdAt: {
    title: "Date Requested",
    filter: false,
    render: row => {
      return moment(row.createdAt).format(PRIMARY_DATE_FORMAT);
    },
  },
  receivedAt: {
    title: "Date Received",
    filter: false,
    render: row => {
      return row.receivedAt
        ? moment(row.receivedAt).format(PRIMARY_DATE_FORMAT)
        : "-";
    },
  },
  amountRequested: {
    title: "Amount Requested",
    render: row => {
      return row.amountRequested
        ? currencyFormatter.format(row.amountRequested)
        : "-";
    },
    overflow: "visible",
  },
  totalPayment: {
    title: "Amount Received",
    render: row => {
      return row.totalPayment
        ? currencyFormatter.format(row.totalPayment)
        : "-";
    },
    fill: true,
    overflow: "visible",
  },
  paymentStatus: {
    filter: "select",
    filterOptions: {
      options: statusFilters,
    },
    render: getStatusTitle,
  },
  currency: {
    title: "Currency Type",
    render: () => {
      return scopedCurrency.name;
    },
    width: "280px",
    sortingEnabled: false,
  },
  paymentRecord: {
    title: "Check Number",
    width: "280px",
    filter: true,
  },
});
const columns = [
  "createdAt",
  "receivedAt",
  "amountRequested",
  "paymentStatus",
  "currency",
  "paymentRecord",
  "totalPayment",
];

const FundingRequests = ({ actions, projectId, projectFundings, fundings }) => {
  const currencyFormatter = useCurrencyFormatter();
  const scopedCurrency = useScopedCurrency();
  const [gridRows, setGridRows] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const tableComponent = useMemo(() => {
    if (gridRows.length === 0 || !expanded) {
      return {
        TableFilterRow: () => null,
        HeaderRowComponent: () => () => null,
        BodyComponent: () => null,
      };
    }

    return {};
  }, [expanded, gridRows]);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const statusFilters = useMemo(() => {
    if (!gridRows.length) return [];
    return _uniqBy(
      gridRows.map(({ paymentStatus }) => ({
        id: paymentStatus,
        name: paymentStatus,
      })),
      "id"
    );
  }, [gridRows]);

  useEffect(() => {
    actions.fetchFundingRequests(projectId, fundingRequestDataComponentId);
  }, [actions, projectId]);

  useEffect(() => {
    setGridRows(fundings);
  }, [fundings]);
  const showPagingPanel = gridRows.length > 0 && expanded;

  return (
    <BWGridLocal
      id={"funding-request"}
      rows={gridRows}
      showSelectionColumn={false}
      gridConfig={{ pageSize: 10, totalRows: gridRows.length }}
      isLoading={false}
      showSelectAll={false}
      sorting={[{ columnName: "receivedAt", direction: "desc" }]}
      tableComponents={tableComponent}
      emptyStateProps={{
        intent: "empty",
        size: "small",
        intensity: "strong",
        padding: "24px",
      }}
      emptyStateText="No Funds Received"
      noBottomMargin
    >
      <GridRow>
        <Loader height="30px" width="350px">
          <TitleRowWrapper>
            <TitleWrapper>Funding Schedule</TitleWrapper>
            {gridRows.length > 0 && (
              <Summary
                visible={true}
                currency={scopedCurrency}
                amountTotal={projectFundings?.totalFundsReceived}
              />
            )}
          </TitleRowWrapper>
        </Loader>
      </GridRow>
      <GridTable
        columns={columns}
        columnOptions={columnOptions(
          scopedCurrency,
          currencyFormatter,
          statusFilters
        )}
      />
      <GridRow showPagingPanel={showPagingPanel}>
        {gridRows.length > 0 && (
          <ExpandAction expanded={expanded} toggleExpanded={toggleExpanded} />
        )}
      </GridRow>
    </BWGridLocal>
  );
};

const ExpandAction = ({ expanded, toggleExpanded }) => {
  return (
    <ExpandActionWrapper>
      <ExpandButton
        aria-label="show more"
        onClick={toggleExpanded}
        disableRipple
      >
        {expanded ? "Hide Entries" : "View Entries"}
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ExpandButton>
    </ExpandActionWrapper>
  );
};

ExpandAction.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

FundingRequests.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectFundings: propTypes.project,
  fundings: PropTypes.arrayOf(propTypes.funding),
  actions: PropTypes.shape({ fetchFundingRequests: PropTypes.func }),
};

export default FundingRequests;
