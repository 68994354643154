import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PricingSectionPage from "./PricingSectionPage";
import propTypes from "../../../../constants/propTypes";
export class PricingSectionContainer extends Component {
  render() {
    const { spec, isEditable } = this.props;

    return <PricingSectionPage spec={spec} isEditable={isEditable} />;
  }
}

PricingSectionContainer.propTypes = {
  spec: propTypes.spec,
  isEditable: PropTypes.bool,
};

export const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(PricingSectionContainer);
