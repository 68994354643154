import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import RegularText from "../../../../ui/Typography/RegularText";
import Subheader from "../../../../ui/Typography/Subheader";
import styled from "styled-components";
import Loader from "../../../../ui/Loader";

const format = value => `${value.toFixed(2) || 0}%`;
const COLORS = ["#57ABFF", "#27587A"];

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ColorSwatch = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 16px;
`;
const LegendItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-right: 24px;
`;
const LegendTextItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const renderLegend = item => {
  const { payload } = item;
  return (
    <LegendWrapper>
      {payload.map((entry, index) => {
        return (
          <LegendItemWrapper key={`item-${index}`}>
            <ColorSwatch style={{ backgroundColor: COLORS[index] }} />
            <LegendTextItemWrapper>
              <RegularText style={{ marginBottom: "4px" }}>
                <Loader>{entry.value}</Loader>
              </RegularText>
              <Subheader>
                <Loader>{`${entry.payload.value?.toFixed(2)}%`}</Loader>
              </Subheader>
            </LegendTextItemWrapper>
          </LegendItemWrapper>
        );
      })}
    </LegendWrapper>
  );
};
const PercentOfForecastCommittedForMerchandisePieChart = props => {
  const { data } = props;
  return (
    <ResponsiveContainer style={{ width: "100%" }} aspect={4.0 / 3.0}>
      <PieChart>
        <Pie data={data} labelLine={false} dataKey="value">
          <Cell key={`cell-0}`} fill={COLORS[0]} />
          <Cell key={`cell-1}`} fill={COLORS[1]} />
        </Pie>
        <Tooltip
          cursor={{ fill: "transparent" }}
          formatter={value => [format(value)]}
        />
        <Legend content={renderLegend} />
      </PieChart>
    </ResponsiveContainer>
  );
};
PercentOfForecastCommittedForMerchandisePieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
export default PercentOfForecastCommittedForMerchandisePieChart;
