import { inputs } from "../../../inputs/inputConfigs";
import { shipmentStatusFilter } from "../PurchaseOrder/gridProps";

export const getFilters = data => {
  const filterOptions = {
    specIds: {
      "specs.id": {
        $in: data.specIds,
      },
    },
    vendorIds: {
      vendorId: {
        $in: data.vendorIds,
      },
    },
    poIds: {
      id: {
        $in: data.poIds,
      },
    },
    name: {
      "specs.description": {
        $ilike: `%${data.name || ""}%`,
      },
    },
    areaQuoteIds: {
      "specs.areaId": {
        $in: data.areaQuoteIds,
      },
    },
    shipmentStatus: shipmentStatusFilter({ value: data.shipmentStatus }),
  };

  return {
    $and: Object.entries(data).reduce(
      (result, [key]) => [...result, filterOptions[key]],
      []
    ),
  };
};

export const buildOnFilter = ({
  projectId,
  closeModalDialog,
  filterMethod,
  setFilterOptions,
}) => data => {
  setFilterOptions(data);
  filterMethod(projectId, getFilters(data));
  closeModalDialog();
};

export const poFields = isShipmentsTab => (projectId, poIds) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.specIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.specIds.APIOptions,
              rootFilters: {
                $where: {
                  projectId,
                  purchaseOrderId: {
                    $in: poIds,
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.vendorIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.vendorIds.APIOptions,
              rootFilters: {
                $where: {
                  "purchaseOrders.id": { $in: poIds },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.poIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.poIds.APIOptions,
              rootFilters: {
                $where: {
                  id: { $in: poIds },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.baseName,
            ignoreLabeledText: true,
            label: "Spec Description",
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.areaQuoteIds,
            ignoreLabeledText: true,
            APIOptions: {
              ...inputs.areaQuoteIds.APIOptions,
              rootFilters: {
                $where: {
                  "specs.purchaseOrderId": { $in: poIds },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
        isShipmentsTab
          ? {
              input: { ...inputs.shipmentStatus, ignoreLabeledText: true },
              grid: { xs: 12 },
            }
          : {},
      ],
    },
  ],
});
