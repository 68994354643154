import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import SpecDetailTitle from "./SpecDetailTitle";
import { COM_PREVIEW } from "../../../constants/specs";
import { Image } from "../../ui/Image";

const StyledSpecDetailPreview = styled.div`
  padding-bottom: 12px;
  display: flex;
`;

export const Description = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreviewImageWrapper = styled.div`
  border-radius: 2px;
  height: 121px;
  width: 121px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 16px;
`;

export const SpecDetailPreview = props => {
  const { title, preview } = props.specDetail;
  const description = _get(preview, "filename", "-");

  return (
    <StyledSpecDetailPreview>
      <PreviewImageWrapper>
        <Image
          file={preview}
          description={description}
          placeholder={COM_PREVIEW}
        />
      </PreviewImageWrapper>
      <InfoWrapper>
        <SpecDetailTitle>{title ? title : "-"}</SpecDetailTitle>
        <Description>{description ? description : "-"}</Description>
      </InfoWrapper>
    </StyledSpecDetailPreview>
  );
};

SpecDetailPreview.propTypes = {
  specDetail: PropTypes.object,
};

export default SpecDetailPreview;
