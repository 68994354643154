import React, { Fragment, useMemo, useEffect } from "react";
import Joi from "joi-browser";
import _isEmpty from "lodash/isEmpty";

import ActionButtons from "../../../forms/ActionButtons";
import { CancelButton } from "../../../forms/ActionButtons/components";
import FormGrid from "../../../forms/FormGrid";
import FormikForm from "../../../forms/FormikForm";
import { filterDCId } from "../../../../actions/filterActions";
import { LoaderContext } from "../../../ui/Loader";
import { inputs } from "../../../inputs/inputConfigs";

const DEFAULT_ITEMS = 4;
const QuotingFilter = ({
  dataComponentId,
  projectId,
  elementIds,
  initialValues,
  onFilter,
  fields,
  sourceDataComponent,
  actions,
  defaultFilters,
  loading,
}) => {
  useEffect(() => {
    actions.fetchElementIds(projectId, defaultFilters, {
      model: sourceDataComponent.model,
      route: sourceDataComponent.apiRoute,
    });

    return function cleanup() {
      actions.destroyDataComponentResource(filterDCId);
    };
  }, [
    actions,
    defaultFilters,
    projectId,
    sourceDataComponent.apiRoute,
    sourceDataComponent.model,
  ]);

  const handleSubmit = data => {
    const formattedData = Object.entries(data).reduce((data, [key, value]) => {
      if (_isEmpty(value)) return data;
      return {
        ...data,
        [key]: value,
      };
    }, {});
    onFilter(formattedData);
  };

  const handleClearFilter = FormikForm => () => {
    FormikForm.setValues({});
  };

  const hasAnyFilter = values => {
    return Object.values(values).some(field => field && field.length > 0);
  };

  const schema = Joi.object().keys({
    vendorIds: Joi.array(),
    awardedIds: Joi.array(),
    specIds: Joi.array(),
    poIds: Joi.array(),
  });

  const dataFields = useMemo(() => fields(projectId, elementIds), [
    elementIds,
    fields,
    projectId,
  ]);

  const emptyFields = {
    groups: [
      {
        items: [...new Array(DEFAULT_ITEMS)].map(() => ({
          input: inputs.baseName,
          grid: { xs: 12 },
        })),
      },
    ],
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FormikForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            {loading && !elementIds.length ? (
              <FormGrid fields={emptyFields} {...formikProps} />
            ) : (
              <FormGrid
                fields={dataFields}
                values={values}
                errors={errors}
                {...formikProps}
              />
            )}
            <ActionButtons
              sendButtonText="Apply"
              cancelButtonText="Close"
              onSend={handleSubmit}
              isModal={true}
              listeners={[dataComponentId, filterDCId]}
            >
              {hasAnyFilter(values) && (
                <CancelButton onClick={handleClearFilter(formikProps)}>
                  Clear Filter
                </CancelButton>
              )}
            </ActionButtons>
          </Fragment>
        )}
      </FormikForm>
    </LoaderContext.Provider>
  );
};

QuotingFilter.defaultProps = {
  initialValues: {},
  defaultFilters: {},
};

export default QuotingFilter;
