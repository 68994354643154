import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";

import BoldText from "../../../../../ui/Typography/BoldText";
import PreviewComponent from "./PreviewComponent";
import DescriptionComponent from "./DescriptionComponent";
import { Button } from "../../../../../layout/SectionHeader";
import { LoaderContext } from "../../../../../ui/Loader";

export const StyledIconButton = styled(IconButton)`
  padding-top: 24px;
  color: rgb(0, 0, 0, 0.54);
  height: 24px;
  width: 24px;
  margin: 0 8px;
  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
  }
`;

const Wrapper = styled.div`
  margin: 12px;
  border: solid 1px rgba(0, 0, 0, 0.12);
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
`;

export const SpecDetails = ({
  specDetails,
  setFieldValue,
  selectedInputName,
  setSelectedInputName,
}) => {
  const { loading } = useContext(LoaderContext);

  const handleAddDescription = () => {
    setFieldValue("specDetails", [
      ...specDetails,
      { title: "", description: null, type: "Description" },
    ]);
  };

  return (
    <Wrapper>
      <Header>
        <BoldText>Spec Details</BoldText>
        <Button
          text={"Add Description"}
          icon={<AddCircle />}
          onClick={handleAddDescription}
          disableRipple
          disableFocusRipple
          disabled={loading}
        />
      </Header>
      {specDetails.map((specDetail, i) => {
        const isPreviewSpecDetail = specDetail.type === "Preview";
        const DetailComponent = isPreviewSpecDetail
          ? PreviewComponent
          : DescriptionComponent;
        return (
          <DetailWrapper key={i}>
            <DetailComponent
              specDetail={specDetail}
              index={i}
              purchaseOrder={null}
              selectedInputName={selectedInputName}
              setSelectedInputName={setSelectedInputName}
            />
            <StyledIconButton
              onClick={() =>
                setFieldValue(
                  "specDetails",
                  specDetails.filter((_, index) => index !== i)
                )
              }
              disabled={loading}
            >
              <Delete />
            </StyledIconButton>
          </DetailWrapper>
        );
      })}
    </Wrapper>
  );
};
SpecDetails.propTypes = {
  specDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedInputName: PropTypes.string,
  setSelectedInputName: PropTypes.func.isRequired,
};
