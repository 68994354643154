import React, { createContext, memo, useMemo, useContext } from "react";
import PropTypes from "prop-types";

export const RevisionReferenceContext = createContext({ reference: "" });

export const calculateReference = (parentReference, revisionReference) => {
  if (parentReference && revisionReference) {
    return { reference: `${parentReference}.${revisionReference}` };
  }
  if (!revisionReference) {
    return { reference: parentReference };
  }
  return {
    reference: revisionReference,
  };
};

/**
 * A public higher-order component to use the PO revision state
 */
function withRevisionReference(WrappedComponent) {
  const RevisionReferenceWrapper = ({ revisionReference, ...props }) => {
    const { reference: parentReference } = useContext(RevisionReferenceContext);
    const value = useMemo(() => {
      return calculateReference(parentReference, revisionReference);
    }, [parentReference, revisionReference]);
    return (
      <RevisionReferenceContext.Provider value={value}>
        <WrappedComponent {...props} />
      </RevisionReferenceContext.Provider>
    );
  };

  RevisionReferenceWrapper.defaultProps = { revisionReference: "" };

  RevisionReferenceWrapper.propTypes = {
    revisionReference: PropTypes.string,
  };

  return memo(RevisionReferenceWrapper);
}
export default withRevisionReference;
