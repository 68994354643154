import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import LabelControl from "./LabelControl";

export const ViewByControl = ({ location: { pathname } }) => {
  if (!/quotes$/.test(pathname)) return <React.Fragment />;
  return <LabelControl />;
};

ViewByControl.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export default withRouter(ViewByControl);
