import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { ListItemIcon, Checkbox } from "@material-ui/core";

import {
  StyledTextField as TextField,
  getShrinkLabelOption,
} from "../../TextInputWithError";
import InputLoader from "../../../ui/Loader/InputLoader";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { LabeledText } from "../../LabeledText";
import { LabeledTextContext } from "../../../../withPORevision";
import { MultilineValueComponent } from "./MultilineValueComponent";
import { getLabel, MenuItemValue } from "../StandardSelect";
import {
  buildInitialOptionsMap,
  buildOptionComponents,
  buildOptions,
  SelectAllMenuItem,
} from "./components";
import { createOnChange } from "./eventHandlers";

export const getValue = value => {
  if (Array.isArray(value)) return value;
  return [];
};

export const setSelectProps = ({
  name,
  selectProps,
  options,
  valueProperty,
  labelProperty,
  onChange,
}) => {
  const optionsMap = options.reduce((map, option) => {
    map[option[valueProperty]] = option;
    return map;
  }, {});

  selectProps.SelectProps = {
    ...selectProps.SelectProps,
    multiple: true,
    classes: {
      select: "multiple-select",
    },
    renderValue: function buildRenderValue(values) {
      return (
        <MultilineValueComponent
          name={name}
          labelProperty={labelProperty}
          optionsMap={optionsMap}
          values={values}
          onChange={onChange}
        />
      );
    },
  };
};

const getSelectAllProps = (options, values) => ({
  isAllSelected: options.length > 0 && values.length === options.length,
  indeterminate: values.length > 0 && values.length < options.length,
});

export const MultipleSelect = ({
  label,
  name,
  value,
  error,
  errorMessage,
  ignoreCaption,
  options: initialOptions,
  onChange,
  submitData,
  handleChange,
  valueProperty,
  labelProperty,
  styleMenuOption,
  nullAsEmpty,
  displayEmpty,
  groupingProperty,
  isDisplaySelectAll,
  ...selectProps
}) => {
  const { width } = useWindowDimensions();
  const labeledText = useContext(LabeledTextContext);
  const options = useMemo(buildOptions(initialOptions, groupingProperty), [
    initialOptions,
    groupingProperty,
  ]);
  const initialOptionsMap = useMemo(
    () =>
      groupingProperty
        ? buildInitialOptionsMap(initialOptions, valueProperty)
        : [],
    [groupingProperty, initialOptions, valueProperty]
  );

  if (labeledText) {
    const option = options.find(({ id }) => id === value);
    return (
      <LabeledText label={label} texts={[getLabel(labelProperty, option)]} />
    );
  }
  if (styleMenuOption) {
    selectProps = {
      ...selectProps,
      SelectProps: {
        MenuProps: {
          autoFocus: false,
          getContentAnchorEl: () => null,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
      },
    };
  }

  const parsedValue = getValue(value);

  const { isAllSelected, indeterminate } = getSelectAllProps(
    options,
    parsedValue
  );

  const onChangeWrapper = createOnChange(
    name,
    submitData,
    options,
    onChange,
    handleChange,
    nullAsEmpty,
    parsedValue,
    valueProperty,
    initialOptionsMap,
    groupingProperty
  );

  setSelectProps({
    name,
    selectProps,
    options,
    valueProperty,
    labelProperty,
    onChange: onChangeWrapper,
    groupingProperty,
  });

  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <TextField
        select
        {...selectProps}
        label={label}
        value={parsedValue}
        inputProps={{ name }}
        error={error}
        onChange={onChangeWrapper}
        InputLabelProps={{
          required: false,
          ...getShrinkLabelOption(width),
          ...selectProps.InputLabelProps,
        }}
      >
        {!isDisplaySelectAll ? null : (
          <SelectAllMenuItem value="all">
            <ListItemIcon>
              <Checkbox checked={isAllSelected} indeterminate={indeterminate} />
            </ListItemIcon>
            <MenuItemValue>{"Select All"}</MenuItemValue>
          </SelectAllMenuItem>
        )}
        {buildOptionComponents({
          options,
          initialOptions,
          parsedValue,
          valueProperty,
          labelProperty,
          groupingProperty,
          values: parsedValue,
        })}
      </TextField>
    </InputLoader>
  );
};

MultipleSelect.defaultProps = {
  labelProperty: "name",
  valueProperty: "id",
  nullAsEmpty: false,
};

MultipleSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  valueProperty: PropTypes.string,
  nullAsEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.func,
  ]).isRequired,
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

export default MultipleSelect;
