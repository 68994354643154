import React, { Fragment } from "react";
import styled from "styled-components";

import ActionButtons from "../../ActionButtons";
import RegularText from "../../../ui/Typography/RegularText";
import pluralize from "pluralize";

export const Message = styled(RegularText)`
  padding: 24px 24px 0px 24px;
`;

export const ConfirmationMessage = styled(RegularText)`
  padding: 24px;
`;

const DirtyAutoSaveFormDialog = ({
  errors,
  title,
  discardText,
  onDiscard,
  ...buttonsProps
}) => (
  <Fragment>
    <Message>{`The changes made couldn't be saved due to the following ${pluralize(
      "error",
      Object.keys(errors).length
    )}:`}</Message>
    {errors.map((error, index) => (
      <Message key={index}>{error}</Message>
    ))}
    <ConfirmationMessage>
      {"Do you want to discard and leave this page?"}
    </ConfirmationMessage>
    <ActionButtons
      onDanger={onDiscard}
      dangerButtonText={discardText}
      isModal
      emphasizeCancel
      {...buttonsProps}
    />
  </Fragment>
);

export default DirtyAutoSaveFormDialog;
