import React, { useCallback, useContext, useEffect, useRef } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import _get from "lodash/get";
import _noop from "lodash/noop";
import styled from "styled-components";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import StandardSelect, {
  MenuItemValue,
} from "../../../../inputs/SelectWithError/StandardSelect";
import { LoaderContext } from "../../../Loader";

export const Select = styled(StandardSelect)`
  width: 100%;
  ${MenuItemValue} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const EditorComponent = ({ column, onValueChange, value }) => {
  const prevValue = useRef(null);

  const { loading } = useContext(LoaderContext);

  const { afterChange = _noop } = column.filterOptions;

  const handleChange = useCallback(
    ({ target: { value } }) => {
      onValueChange(value);
      afterChange(value);
    },
    [afterChange, onValueChange]
  );

  useEffect(() => {
    if (
      value === "" &&
      prevValue.current === null &&
      column?.filterOptions?.initialOption
    ) {
      handleChange({ target: { value: column.filterOptions.initialOption } });
    }

    if (value !== prevValue) {
      prevValue.current = value;
    }
  }, [column, handleChange, value]);

  return (
    <LoaderContext.Provider value={{ loading: false }}>
      <Select
        options={column.filterOptions.options}
        onChange={handleChange}
        value={value}
        SelectProps={{ displayEmpty: true }}
        ignoreLabeledText
        disabled={loading}
        displayEmpty={!column.filterOptions.omitAllOption}
        labelProperty={option => {
          return option.id === null || option.id === "" ? "All" : option.name;
        }}
      />
    </LoaderContext.Provider>
  );
};

EditorComponent.defaultProps = {
  value: "",
};

EditorComponent.propTypes = {
  column: PropTypes.shape({
    filterOptions: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  onValueChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

const StaticMultiSelectTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(column => {
    const options = columnOptions[column];
    return (
      options.filter === "select" && _get(options, "filterOptions.options")
    );
  });
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={EditorComponent} />
  ) : null;
};
StaticMultiSelectTypeProvider.propTypes = propTypes.filteringComponent;

export default StaticMultiSelectTypeProvider;
