import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

export const ExpandedIcon = styled(({ isExpanded, ...props }) => (
  <ExpandMoreIcon {...props} />
))`
  ${({ isExpanded }) =>
    isExpanded
      ? `
        transform: rotate(180deg);
        marginleft: auto;
        transition: transform;
        `
      : null}
`;

export const CollapseButton = ({ isCollapsed, setIsCollapsed, isDisabled }) => {
  return (
    <IconButton
      aria-label="show more"
      onClick={() => setIsCollapsed(!isCollapsed)}
      disabled={isDisabled}
    >
      <ExpandedIcon isExpanded={!isCollapsed} />
    </IconButton>
  );
};
CollapseButton.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
