import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import MUIRow from "../../../mui/@devexpress/dx-react-grid-material-ui/Row";
import { SortableElement } from "react-sortable-hoc";

import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";

export const StyledBWTableRow = styled(
  ({
    hasClickHandler,
    applyLastBorder,
    rowWasCreated,
    rowWasDeleted,
    isClicked,
    isDisabledRow,
    isExpanded,
    ...props
  }) => <MUIRow {...props} />
)`
  > td {
    position: relative;
    background-color: ${({ isClicked }) =>
      isClicked ? "rgb(245, 245, 245)" : "initial"} !important;

    ${({ isDisabledRow }) =>
      isDisabledRow && "color: rgba(0, 0, 0, 0.38) !important;"}
    ${({ isDisabledRow }) => isDisabledRow && "background: initial !important"}
    ${({ isDisabledRow }) => isDisabledRow && "font-weight: normal !important;"}
    &:not(:first-child) {
      opacity: ${({ rowWasDeleted }) => rowWasDeleted && "0.3"};
    }
    &:first-child > * {
      opacity: ${({ rowWasDeleted }) => rowWasDeleted && "0.3"};
    }
    @media (max-width: 768px) {
      overflow: visible;
    }
  }
  
  &.hover-active {
    &:hover > td {
      background-color: ${({ hasClickHandler }) =>
        hasClickHandler ? "rgb(245, 245, 245) !important" : null};
    }
  }

  ${({ applyLastBorder }) =>
    applyLastBorder &&
    `
    &:last-child {
      > td {
        border: none;
      }
    }
  `}

  > td:first-child:after {
    ${({ rowWasCreated, rowWasDeleted }) =>
      (rowWasCreated || rowWasDeleted) &&
      `
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    top: 2px;
    left: 16px;
    overflow: visible;
  `}
  ${({ rowWasCreated }) => rowWasCreated && "color: #ff9100;"}
  ${({ rowWasDeleted }) => rowWasDeleted && "color: #ff1744;"}
  }
`;

const SortableRow = SortableElement(StyledBWTableRow);

const BWTableRow = props => {
  const { isActive, isCreated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);
  const { __deleted__, __isClicked__, __expanded__ } = props.row;

  const row = useMemo(() => {
    const { __deleted__, __isClicked__, __expanded__, ...cleanRow } = props.row;
    return cleanRow;
  }, [props.row]);

  const additionalProps = useMemo(() => {
    if (!isActive || !row.id) {
      return {};
    }
    const ownReference = `${reference}.${row.id}`;
    return {
      rowWasCreated: isCreated(ownReference),
      rowWasDeleted: __deleted__,
    };
  }, [isCreated, __deleted__, isActive, row.id, reference]);

  return (
    <SortableRow
      {...props}
      {...additionalProps}
      isClicked={__isClicked__}
      isExpanded={__expanded__}
      index={props.tableRow.rowId}
    />
  );
};
export default BWTableRow;
