import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { openModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import Loader, { LoaderContext } from "../../../../ui/Loader";
import Subheader from "../../../../ui/Typography/Subheader";
import { Label, Value, Wrapper } from "./components";
import FileLink from "./FileLink";
import Paper from "../../../../mui/core/Paper";
import useActions from "../../../../hooks/useActions";
import {
  initSpecFiles,
  specFilesDataComponentId,
} from "../../../../../actions/specDetailActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import { haveActiveRequests } from "../../../../../utils/dataComponentUtils";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import { LabeledTextContext } from "../../../../../withPORevision";

const fileTypes = [
  { label: "Quote", key: "quote" },
  { label: "Invoice", key: "invoice" },
  { label: "Care & Warranty", key: "careWarranty" },
  { label: "Designer Specs", key: "designedSpecs" },
];

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-bottom: 16px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: rgba(0, 0, 0, 0.26);
  margin-left: 24px;
`;

export const getHandleDownloadPurchaseOrderPDF = ({
  generateAndDownloadReport,
  setGeneratingReport,
  purchaseOrder,
}) =>
  purchaseOrder
    ? async () => {
        try {
          setGeneratingReport(true);
          await generateAndDownloadReport({
            format: "pdf",
            purchaseOrderId: purchaseOrder.id,
            templateName: "purchase-order-detail",
          });
        } finally {
          setGeneratingReport(false);
        }
      }
    : undefined;

const SpecFiles = ({ spec }) => {
  const specId = spec?.id;
  const purchaseOrder = spec?.purchaseOrder;
  const isLabeled = useContext(LabeledTextContext);
  const actions = useActions({
    initSpecFiles,
    openModalDialog,
    generateAndDownloadReport,
  });
  const [isGeneratingReport, setGeneratingReport] = useState(false);
  const dataComponent = useDatacomponent(specFilesDataComponentId);
  const specFiles = useV2DatacomponentResources(
    specFilesDataComponentId,
    [],
    RequestTypes.LIST
  );

  useEffect(() => {
    if (specId) {
      actions.initSpecFiles(specId);
    }
  }, [specId, actions]);

  const loading = haveActiveRequests(dataComponent);

  const handleOpenManager = (label, fileType, acceptImage) => {
    actions.openModalDialog(
      ["Spec Files", `${label} for ${spec.customNumber} ${spec.description}`],
      "AddSpecFile",
      { fileType, acceptImage, specId, isLabeled },
      false,
      true
    );
  };

  return (
    <LoaderContext.Provider value={{ loading: loading || !specId }}>
      <Paper>
        <Header>
          <Subheader>Spec Files</Subheader>
        </Header>
        <Wrapper
          onClick={
            isGeneratingReport
              ? () => undefined
              : getHandleDownloadPurchaseOrderPDF({
                  generateAndDownloadReport: actions.generateAndDownloadReport,
                  setGeneratingReport,
                  purchaseOrder,
                })
          }
        >
          <Loader height="16px" width="150px">
            <Label>PO:</Label>
            <Value>{purchaseOrder?.number}</Value>
            {isGeneratingReport ? <StyledCircularProgress size={24} /> : null}
          </Loader>
        </Wrapper>
        {fileTypes.map(({ label, key, acceptImage }) => (
          <FileLink
            key={key}
            label={label}
            fileType={key}
            files={specFiles.filter(specFile => specFile.key === key)}
            acceptImage={acceptImage}
            onOpenManager={handleOpenManager}
            isLabeled={isLabeled}
          />
        ))}
      </Paper>
    </LoaderContext.Provider>
  );
};

SpecFiles.propTypes = {
  spec: propTypes.spec,
};

export default SpecFiles;
