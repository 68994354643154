import React, { useMemo } from "react";
import _get from "lodash/get";
import { isEmpty } from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  DetailAndCycle,
  NavigationArea,
  Subtitle,
} from "../../layout/DetailAndCycle";
import propTypes from "../../../constants/propTypes";

import TitleArea from "./TitleArea";
import { inputs } from "../../inputs/inputConfigs";
import InputWrapper from "../../inputs/InputWrapper";

export const InputContainer = styled.div`
  margin-right: 24px;
`;

const getValueOrPlaceholder = (value, placeholder) =>
  isEmpty(value?.trim()) ? placeholder : value;

const getAddress = entity => {
  const location = _get(entity, "location", {});
  const address = location.address2
    ? `${location.address || ""} ${location.address2 || ""}`
    : location.address || "";
  return [address, `${location.city} ${location.state}`]
    .filter(value => !isEmpty(value))
    .join(", ");
};

const DetailAndCycleContainer = ({
  entity,
  backUrl,
  nextItemRoute,
  lastItemRoute,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  const moreActionsInput = useMemo(() => {
    return {
      ...inputs.moreActions,
      buttonText: "Entity Actions",
      options: [
        { content: "Edit", onClick: onOpenEditModal },
        { content: "Delete", onClick: onOpenDeleteModal },
      ],
    };
  }, [onOpenEditModal, onOpenDeleteModal]);

  const zipCode = _get(entity, "location.zip", "--");
  const federalId = _get(entity, "federalId", "--");

  return (
    <DetailAndCycle>
      <NavigationArea
        backUrl={backUrl}
        backText={"BACK TO ENTITIES"}
        nextItemRoute={nextItemRoute}
        lastItemRoute={lastItemRoute}
      />
      <TitleArea title={_get(entity, "name")} />
      <Subtitle
        details={[
          { content: getAddress(entity) },
          { title: "Zip Code", content: getValueOrPlaceholder(zipCode, "--") },
          {
            title: "Federal ID",
            content: getValueOrPlaceholder(federalId, "--"),
          },
        ]}
      />
      <InputContainer>
        <InputWrapper {...moreActionsInput} />
      </InputContainer>
    </DetailAndCycle>
  );
};

DetailAndCycleContainer.propTypes = {
  entity: propTypes.entity,
  backRoute: PropTypes.string,
  nextItemRoute: PropTypes.string,
  lastItemRoute: PropTypes.string,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default DetailAndCycleContainer;
