import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import { WithPaginationContext } from "../../../../withPagination";

export const PaginationContext = createContext({
  isDefault: true,
  setPage: _noop,
  getPage: () => 0,
  setPageSize: _noop,
  getPageSize: () => 10,
  paginateResults: results => ({
    results,
  }),
});

export const PaginationStateContext = createContext(null); // Don't use a default value to make required that a parent should provide it.

const withPagination = WrappedComponent => {
  const PaginatedComponent = ({ useParentPaginationControl, ...props }) => {
    const parentPaginationControl = useContext(PaginationContext);
    const { setPage, getPage, setPageSize, getPageSize } = useContext(
      WithPaginationContext
    ); //methods to set pagination in the queryparams and sessionStore
    const { gridId } = props;
    const [pageSize, setLocalPageSize] = useState(getPageSize(gridId)); // Since page size is stored in session storage this is required to notify other components to recalculate the pageSize value

    const paginateResults = useCallback(
      results => {
        const page = getPage(gridId);
        const start = page * pageSize;
        const end = (page + 1) * pageSize;
        const paginatedResults = results.slice(start, end);
        return {
          results: paginatedResults,
          total: results.length,
          initialResult: start + 1,
          lastResult: start + paginatedResults.length,
        };
      },
      [pageSize, getPage, gridId]
    );

    const value = useMemo(() => {
      if (useParentPaginationControl) return parentPaginationControl;
      return {
        isDefault: false,
        setPage: page => setPage(page, gridId),
        getPage: () => getPage(gridId),
        setPageSize: pageSize => {
          setLocalPageSize(pageSize);
          return setPageSize(pageSize, gridId);
        },
        getPageSize: () => pageSize,
        paginateResults,
      };
    }, [
      useParentPaginationControl,
      parentPaginationControl,
      getPage,
      pageSize,
      setPage,
      setPageSize,
      paginateResults,
      gridId,
    ]);

    return (
      <PaginationContext.Provider value={value}>
        <WrappedComponent {...props} />
      </PaginationContext.Provider>
    );
  };
  PaginatedComponent.propTypes = {
    useParentPaginationControl: PropTypes.bool,
    gridId: PropTypes.string.isRequired,
  };
  PaginatedComponent.defaultProps = { useParentPaginationControl: false };
  return PaginatedComponent;
};

export default withPagination;
