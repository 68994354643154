import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import _get from "lodash/get";
import _pick from "lodash/pick";

import SpecDetailTitle from "../SpecDetailTitle";
import ItemRow from "./ItemRow";
import { Content } from "./components";
import {
  withDeletedItems,
  withRevisionReference,
} from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";
import ActionDetailLink from "../ActionDetailLink";
import { DnDItemWrapper } from "../SpecDetailDragAndDropList/components";
import { Body as DnDBody, DragAndDropList } from "../../../ui/DragAndDropList";
import { array_move } from "../../Settings/Templates/CreateTemplate/CreateTemplateForm";
import useActions from "../../../hooks/useActions";
import { updateSpecDetailComs } from "../../../../actions/specDetailActions";

const mapSpec = ({ specDetailId, specId, ...item }) => ({
  ...item,
  specId: specId.toString(),
  specDetailId: specDetailId.toString(),
});

const getCOMs = (specDetail, deletedCOMs) => {
  if (specDetail.__deleted__) {
    return Object.values(_get(specDetail, "specDetailComs", {}))
      .map(mapSpec)
      .map(specDetailCom => ({ ...specDetailCom, __parent_deleted__: true }));
  }
  return _get(specDetail, "specDetailComs", [])
    .concat(deletedCOMs)
    .filter(item => item.id !== "")
    .map(mapSpec);
};

export const handleReorder = (
  formikArrayHelpers,
  specDetail,
  updateSpecDetailComs
) => ({ destination, source }) => {
  if (!destination) return;
  formikArrayHelpers.move(source.index, destination.index);
  array_move(specDetail.specDetailComs, source.index, destination.index);

  specDetail.specDetailComs.forEach((specDetailCom, index) => {
    specDetailCom.sequenceIndex = index;
  });
  updateSpecDetailComs(_pick(specDetail, ["id", "specDetailComs"]));
};

const SpecDetailCOM = ({
  specDetail,
  rows: deletedCOMs,
  purchaseOrder,
  index,
}) => {
  const coms = useMemo(() => getCOMs(specDetail, deletedCOMs), [
    specDetail,
    deletedCOMs,
  ]);

  const actions = useActions({ updateSpecDetailComs });

  return (
    <Fragment>
      <SpecDetailTitle>COM</SpecDetailTitle>
      <Content>
        <DragAndDropList name={`specDetails[${index}].specDetailComs`}>
          {formikArrayHelpers => (
            <DnDBody
              showDragAndDrop
              onReorder={handleReorder(
                formikArrayHelpers,
                _get(formikArrayHelpers.form.values, `specDetails[${index}]`),
                actions.updateSpecDetailComs
              )}
              items={coms}
            >
              {coms.map((specDetailCom, key) => (
                <DnDItemWrapper key={key}>
                  <ItemRow
                    specDetail={specDetail}
                    purchaseOrder={purchaseOrder}
                    specDetailCom={specDetailCom}
                    key={key}
                  />
                </DnDItemWrapper>
              ))}
            </DnDBody>
          )}
        </DragAndDropList>
        <ActionDetailLink
          visible={coms.length === 0}
          specDetail={specDetail}
          type="COM"
        />
      </Content>
    </Fragment>
  );
};

const WrappedSpecDetailCOM = withRevisionReference(
  withDeletedItems(SpecDetailCOM)
);

const Wrapper = ({ specDetail, purchaseOrder, index }) => (
  <WrappedSpecDetailCOM
    specDetail={specDetail}
    purchaseOrder={purchaseOrder}
    revisionReference={`${specDetail.id}.specDetailComs`}
    index={index}
  />
);

Wrapper.propTypes = {
  specDetail: propTypes.specDetail,
};

SpecDetailCOM.propTypes = {
  ...Wrapper.propTypes,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Wrapper;
