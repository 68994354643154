import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "@material-ui/core/Card";
import BWModels from "benjaminwest-models";
import FormikForm from "../../../forms/FormikForm";
import FormGrid from "../../../forms/FormGrid";
import RfqFields from "./RfqFields";
import { Subheader } from "../components";
import styled from "styled-components";
import propTypes from "../../../../constants/propTypes";

const getRfqSchema = () => {
  return BWModels.loadSchema("Rfq");
};
const StyledCard = styled(Card)`
  overflow: visible;
  margin-bottom: 24px;
`;

const RfqForm = ({ onSubmit, rfq }) => {
  const handleSubmit = useCallback(
    values => {
      onSubmit({
        rfq: {
          id: rfq.id,
          ...values,
        },
      });
    },
    [onSubmit, rfq]
  );
  const noDelayFields = useMemo(
    () => ["userId", "deliveryDate", "responseDate"],
    []
  );

  const initialValues = useMemo(() => {
    return {
      userId: "",
      ...rfq,
      responseDate: rfq.responseDate || moment(rfq.CreatedAt).add(1, "week"),
    };
  }, [rfq]);

  return (
    <StyledCard>
      <Subheader>RFQ Details</Subheader>

      <FormikForm
        onSubmit={handleSubmit}
        validationSchema={getRfqSchema()}
        initialValues={initialValues}
        autoSave={true}
        enableReinitialize={true}
        noDelayFields={noDelayFields}
      >
        {({ values, errors }) => (
          <FormGrid fields={RfqFields} values={values} errors={errors} />
        )}
      </FormikForm>
    </StyledCard>
  );
};

RfqForm.defaultProps = {
  rfq: {},
};

RfqForm.propTypes = {
  onSubmit: PropTypes.func,
  rfq: propTypes.rfq,
};

export default RfqForm;
