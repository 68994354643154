import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Draggable } from "react-beautiful-dnd";

import {
  hasError,
  getErrorMessage,
} from "../../../../../../utils/formValidationUtils";

import inputs from "../../../../../inputs/inputConfigs/specCategory";

const { specCategoryDetailName } = inputs;

import {
  CustomListItem,
  NameInputContainer,
  CustomItemText,
} from "./components";

import ActionIcons from "./ActionIcons";

import InputWrapper from "../../../../../inputs/InputWrapper";

const DataRow = ({ isEditing, errors, touched, ...input }) => (
  <Field name={input.name}>
    {({ field, form }) =>
      isEditing ? (
        <InputWrapper
          {...input}
          {...field}
          hasError={hasError(input.name, form)}
          errorMessage={getErrorMessage(input.name, form)}
        />
      ) : (
        <CustomItemText>{field.value}</CustomItemText>
      )
    }
  </Field>
);

export default class Item extends Component {
  switchEditMode = () => {
    const { onEdit, index, detail } = this.props;
    onEdit(index, { ...detail, isEditing: !detail.isEditing });
  };

  getDataRowProps(input) {
    const { detail, index } = this.props;
    return {
      ...input,
      isEditing: detail.isEditing,
      name: `specCategoryDetails[${index}].${input.name}`,
      hideErrorMessage: true,
      ignoreCaption: true,
    };
  }

  render() {
    const { onRemove, index, detail, errors, touched } = this.props;
    return (
      <Draggable draggableId={index.toString()} index={index}>
        {({ dragHandleProps, ...parentProps }) => (
          <CustomListItem
            isEditing={detail.isEditing}
            {...parentProps}
            disableRipple
            button
          >
            <NameInputContainer>
              <DataRow
                {...this.getDataRowProps(specCategoryDetailName)}
                errors={errors}
                touched={touched}
              />
            </NameInputContainer>
            <ActionIcons
              allowEditing={detail.type === "Description"}
              isEditing={detail.isEditing}
              onRemove={onRemove}
              onSwitchEditMode={this.switchEditMode}
              index={index}
              {...dragHandleProps}
            />
          </CustomListItem>
        )}
      </Draggable>
    );
  }
}

Item.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  detail: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isEditing: PropTypes.bool,
  }).isRequired,
};
