import styled from "styled-components";
import {
  Title,
  Subtitle,
} from "../../../../layout/AppLayout/ModalDialog/ModalTitle";

export const NoteCategoriesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled(Subtitle)`
  margin-top: 0;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  color: #ffffff;
  margin-right: 9px;
  margin-left: 16px;
  line-height: 20px;
  &:first-child {
    margin-left: 0px;
  }
`;
