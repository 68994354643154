import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import FormGrid from "../../../../forms/FormGrid";
import createRemitAddressFields from "./createRemitAddressFields";
import ActionButtons from "../../../../forms/ActionButtons";
import useActions from "../../../../hooks/useActions";

import { setModalDialogTitle } from "../../../../../actions/layoutActions";
import useCreateRemitAddressStore from "../../../../hooks/useCreateRemitAddressStore";
import FormikForm from "../../../../forms/FormikForm";
import BWModels from "benjaminwest-models";
import shallow from "zustand/shallow";

const Form = ({ onSubmit, isValid, isUpdate, dataComponentId }) => {
  return (
    <Fragment>
      <FormGrid fields={createRemitAddressFields()} />
      <ActionButtons
        onSend={onSubmit}
        additionalProps={{ send: { disabled: !isValid } }}
        sendButtonText={isUpdate ? "Save" : "CREATE ADDRESS"}
        isModal
        listeners={[dataComponentId]}
      />
    </Fragment>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  setTouched: PropTypes.func,
  isUpdate: PropTypes.bool,
  isValid: PropTypes.bool,
  dataComponentId: PropTypes.string.isRequired,
};

const RemitAddressForm = ({ onSubmit, isUpdate, dataComponentId }) => {
  const actions = useActions({
    setModalDialogTitle,
  });

  const [titleModal, currentForm, hasTransitioned] = useCreateRemitAddressStore(
    state => [state.titleModal, state.currentForm, state.hasTransitioned],
    shallow
  );

  useEffect(() => {
    actions.setModalDialogTitle(titleModal);
  }, [actions, titleModal]);

  return (
    <FormikForm
      initialValues={currentForm}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("RemitAddress")}
      validateOnMount={hasTransitioned}
      ignoreValidationFieldNames={["vendorId"]}
    >
      {({ handleSubmit, isValid }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            isValid={isValid}
            isUpdate={isUpdate}
            dataComponentId={dataComponentId}
          />
        );
      }}
    </FormikForm>
  );
};

RemitAddressForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool,
  dataComponentId: PropTypes.string.isRequired,
};

export default RemitAddressForm;
