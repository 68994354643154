import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import { fundingsDataComponentId } from "../ProjectOverview";
import FundingRequests, {
  fundingRequestDataComponentId,
} from "./FundingRequests";
import { fetchFundingRequests } from "../../../../../../actions/projectsActions";

import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../../../utils/bluechipUtils";
import { getBluechipResourceById } from "../../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";

export const mapStateToProps = (state, ownProps) => {
  const fundingsDataComponent = getDataComponentFlattenedRequestState(
    fundingsDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  return {
    dataComponent: getDataComponentFlattenedRequestState(
      fundingRequestDataComponentId,
      state
    ),
    projectId: ownProps.match.params.projectId,
    projectFundings:
      getBluechipResourceById(
        fundingsDataComponent,
        state,
        ownProps.match.params.projectId
      ) || undefined,
    fundings:
      getBluechipResourcesByType(fundingRequestDataComponentId, state) || [],
  };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchFundingRequests,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FundingRequests)
);
