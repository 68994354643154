import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import { EditorComponent } from "../../BWGrid/gridInternalComponents/dataTypeProviders/StaticSelectTypeProvider";

export default columns => {
  const TextTypeProvider = () => {
    return <DataTypeProvider for={columns} editorComponent={EditorComponent} />;
  };
  TextTypeProvider.propTypes = {};
  return TextTypeProvider;
};
