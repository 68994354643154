import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { Title } from "../../ui/Typography/SectionTitle";
import {
  Content,
  DetailsContainer,
  DetailsTitle,
  ExpandableGridContainer,
  CollapsesContent,
  DescriptionIcon,
  ExpandedContent,
} from "./components";
import { Paper } from "../../mui/core";

export const ExpandableGrid = ({
  title,
  children,
  expandableTitle,
  expandleContent,
}) => {
  const [expandToggle, setExpandToggle] = useState(false);

  return (
    <Paper>
      <ExpandableGridContainer isExpaned={expandToggle}>
        <Content>
          <Title>{title}</Title>
          {children}
        </Content>
        {expandToggle ? (
          <ExpandedContent>{expandleContent}</ExpandedContent>
        ) : (
          <CollapsesContent />
        )}
        <DetailsContainer
          isExpaned={expandToggle}
          onClick={() => setExpandToggle(!expandToggle)}
        >
          <DetailsTitle>
            {expandToggle ? "Hide" : "View"} {expandableTitle}
          </DetailsTitle>
          <DescriptionIcon isExpaned={expandToggle} />
        </DetailsContainer>
      </ExpandableGridContainer>
    </Paper>
  );
};

ExpandableGrid.propTypes = {
  title: PropTypes.string,
  expandableTitle: PropTypes.string,
  expandleContent: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default memo(ExpandableGrid);
