import React, { Component } from "react";
import Paper from "../../mui/core/Paper";
import AddCircle from "@material-ui/icons/AddCircle";
import _get from "lodash.get";

import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import PropTypes from "prop-types";
import TagText from "../../ui/Tags/TagText";
import BoldText from "../../ui/Typography/BoldText";

export const getRowMenu = (
  handleEditContact,
  handleDeleteContact,
  handleResendAccessEmail
) => row => {
  const options = [
    { text: "Edit", onClick: handleEditContact },
    { text: "Delete", onClick: handleDeleteContact },
  ];
  if (row.canAccessClientPortal) {
    options.push(
      { separator: true },
      {
        text: "Resend Access Email",
        onClick: handleResendAccessEmail,
      }
    );
  }
  return options;
};

export class ContactsPage extends Component {
  columns = [
    "contact.firstName",
    "contact.jobTitle",
    "contact.status",
    "contact.phone",
    "contact.email",
  ];

  get columnOptions() {
    const { statusMap } = this.props;
    return {
      "contact.firstName": {
        title: "Name",
        bold: true,
        render: row => (
          <BoldText>{`${_get(row, "contact.firstName")} ${_get(
            row,
            "contact.lastName"
          )}`}</BoldText>
        ),
      },
      "contact.jobTitle": { title: "Job Title" },
      "contact.status": {
        title: "Status",
        fill: true,
        render: row => {
          const status = _get(row, "contact.status");
          if (status === "act") return "";
          return <TagText>{_get(statusMap, `${status}.name`)}</TagText>;
        },
      },
      "contact.phone": {
        title: "Phone Number",
        align: "right",
      },
      "contact.email": { title: "Email", align: "right" },
    };
  }

  render() {
    const {
      dataComponent,
      handleAddContact,
      handleEditContact,
      handleDeleteContact,
      handleResendAccessEmail,
    } = this.props;

    return (
      <Paper>
        <BWGridAPI
          dataComponent={dataComponent}
          model={this.model}
          includes={this.includes}
          apiRoute={this.apiRoute}
          rootFilters={this.rootFilters}
          defaultSorting={this.defaultSorting}
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Contacts`}
            actions={[
              {
                text: "ADD CONTACT",
                icon: <AddCircle />,
                handler: handleAddContact,
              },
            ]}
          />
          <GridTable
            columns={this.columns}
            columnOptions={this.columnOptions}
            rowMenu={getRowMenu(
              handleEditContact,
              handleDeleteContact,
              handleResendAccessEmail
            )}
          />
        </BWGridAPI>
      </Paper>
    );
  }
}

ContactsPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  statusMap: PropTypes.object.isRequired,
  handleAddContact: PropTypes.func,
  handleEditContact: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  handleResendAccessEmail: PropTypes.func,
  customInfo: PropTypes.object,
};

export default ContactsPage;
