import specDetails from "../../../inputs/inputConfigs/specDetails";

export default {
  groups: [
    {
      items: [
        {
          input: specDetails.unitsNeeded,
          grid: { xs: 12 },
        },
        {
          input: specDetails.unitsPerCase,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
