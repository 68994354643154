import React, { useEffect, useCallback, useState } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";
import _sortBy from "lodash/sortBy";
import BWModels from "benjaminwest-models";

import Spec from "../../../../models/Spec";
import * as QUEUE_STATUSES from "../../../../constants/queue";
import { useWithPaginationContext } from "../../../../withPagination";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import ImportForm from "./ImportForm";
import GridSelection from "./GridSelection";
import GridEdition, { gridId } from "./GridEdition";
import propTypes from "../../../../constants/propTypes";
import { Wrapper } from "./components";
import Buttons from "./Buttons";

import { withSelectedRows } from "../../../ui/BWGrid";
import useErrorImportStore from "../../../hooks/useErrorImportStore";
import shallow from "zustand/shallow";
import {
  calculateOverageQuantity,
  calculateTotalQuantity,
} from "../calculations";
import SpecCategory from "../../../../models/SpecCategory";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { FormikForm } from "../../../forms";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";
import Vendor from "../../../../models/Vendor";
import UpdateSpecs from "./UpdateSpecs";

export const queueId = "importBuildSpecs";
export const dataComponentId = "ImportSpec";
export const queueCreateSpecsId = "createSpecs";
export const specCategoryDataComponentId = "select-specCategories";

const calculateOverageAndTotal = row => {
  row.overageValue = calculateOverageQuantity(
    Number(row.overagePercent) / 100,
    Number(row.baseQuantity),
    Number(row.atticStock)
  );

  row.totalQuantity = calculateTotalQuantity(
    Number(row.baseQuantity),
    Number(row.atticStock),
    Number(row.overageValue)
  );
};

const getAreaId = ({ areaId }, defaultAreaId) =>
  areaId ? areaId : defaultAreaId || undefined;

const getProjectCurrencyId = (
  { projectCurrencyId },
  defaultProjectCurrencyId,
  poProjectCurrency
) => {
  if (poProjectCurrency) return poProjectCurrency.id;

  return projectCurrencyId
    ? projectCurrencyId
    : defaultProjectCurrencyId || undefined;
};

const getProjectCurrencies = (poProjectCurrency, projectCurrencies) =>
  poProjectCurrency ? [poProjectCurrency] : projectCurrencies;

const getProjectCurrency = (poProjectCurrency, projectCurrency) =>
  poProjectCurrency ? poProjectCurrency : projectCurrency;

export const getSpecCategory = (item, specCategories) => {
  if (specCategories && item) {
    return specCategories.find(scItem => scItem.id == item.specCategoryId);
  }
};

export const getVendor = (item, vendors) => {
  if (vendors && item) {
    const specVen = vendors.find(
      venItem =>
        venItem.id == (item.vendorId || _get(item, "purchaseOrder.vendorId"))
    );
    return Vendor.isVendorDisabled(specVen) ? undefined : specVen;
  }
};

export const getArea = (item, targetProjectAreas, defaultAreaId) => {
  let specArea = undefined;
  if (targetProjectAreas && item) {
    specArea = targetProjectAreas.find(areaItem =>
      defaultAreaId
        ? areaItem.id == defaultAreaId
        : areaItem.name == item.area.name
    );
  }
  return specArea;
};

const setRowsData = (
  queue,
  specs,
  setRows,
  errorActions,
  defaultAreaId,
  defaultProjectCurrencyId,
  isImportToPO,
  poProjectCurrency,
  categories,
  vendors
) => {
  if (queue && queue.status === QUEUE_STATUSES.QUEUE_SUCCESS) {
    const rows = _get(queue, "returnvalue.specs", []).map(
      ({ projectCurrency, ...item }, index) => ({
        ...item,
        specCategory: getSpecCategory(item, categories),
        vendor: getVendor(item, vendors),
        vendorId: getVendor(item, vendors)?.id,
        projectCurrency: getProjectCurrency(poProjectCurrency, projectCurrency),
        areaId: getAreaId(item, defaultAreaId),
        projectCurrencyId: getProjectCurrencyId(
          item,
          defaultProjectCurrencyId,
          poProjectCurrency
        ),
        area: undefined,
        priceCents: (item.priceCents || 0) / 100,
        id: `${index}`,
      })
    );

    errorActions.validateImport(_get(queue, "returnvalue.error", null), rows);
    return setRows(rows);
  }

  if (!specs) {
    return;
  }

  setRows(
    specs.map(({ ...spec }) => ({
      ...spec,
      specCategory: getSpecCategory(spec, categories),
      vendor: getVendor(spec, vendors),
      vendorId: getVendor(spec, vendors)?.id,
      areaId: getAreaId(spec, defaultAreaId),
      projectCurrencyId: getProjectCurrencyId(
        spec,
        defaultProjectCurrencyId,
        poProjectCurrency
      ),
    }))
  );
};

const fieldsToOmit = [
  "area",
  "specCategory",
  "vendor",
  "purchaseOrder",
  "purchaseOrderId",
  "id",
  "specDetailIdSequence",
  "isCommitted",
  "shipmentTotalQty",
  "totalQuantity",
  "totalPrice",
  "totalForecast",
  "totalQuantityWithoutOverage",
  "unitsNeeded",
  "unitsPerCase",
  "qcParBaseUnitsPerCase",
  "isAssignedToBidGroup",
  "projectCurrency",
  "unitOfMeasure",
];

const getSelectedSpecs = (rows, selectedIds) =>
  rows.filter(value => selectedIds.includes(value.id));

export const parseImportData = (
  isFromProjects,
  selectedIds,
  rows,
  project,
  defaultAreaId,
  defaultValues,
  defaultProjectCurrencyId,
  isImportToPO,
  poProjectCurrency
) => {
  const selectedSpecs = getSelectedSpecs(rows, selectedIds);
  return selectedSpecs.map(spec => {
    const newSpec = {
      specDetails: createSpecDetails(spec), // Keep this line here to remove room's properties first
      ..._omitBy(_omit(spec, fieldsToOmit), value => value === null),
      projectId: project.id,
      areaId: spec.areaId || defaultAreaId,
      projectCurrencyId: getProjectCurrencyId(
        spec,
        defaultProjectCurrencyId,
        poProjectCurrency
      ),
      vendorId:
        _isEmpty(spec.vendorId) || Vendor.isVendorDisabled(spec.vendor)
          ? null
          : spec.vendorId,
      specCategoryId: spec.specCategoryId,
      priceCents: cleanCurrencyValue(spec.priceCents * 100),
      customNumber: String(spec.customNumber),
      ...defaultValues,
      isTaxExempt: getIsTaxExempt(project, spec),
    };

    if (isFromProjects) {
      newSpec.sourceSpecId = spec.id;
      newSpec.cloningBetweenProjects = true;
    }

    newSpec.isImportToPO = isImportToPO;

    return newSpec;
  });
};

export const getIsTaxExempt = (project, spec) => {
  if (project.isTaxExempt) {
    return true;
  }
  const SpecCategoryUtils = BWModels.loadSchema("SpecCategory").__utils;
  if (SpecCategoryUtils.freightCategories.includes(spec.specCategory.key)) {
    return !project.isFreightTaxable;
  }
  if (SpecCategoryUtils.installCategories.includes(spec.specCategory.key)) {
    return !project.isInstallTaxable;
  }
  return false;
};

const createSpecDetails = spec => {
  const specDetails = [];

  if (spec.unitsPerCase || spec.unitsNeeded) {
    specDetails.push({
      unitsNeeded: spec.unitsNeeded,
      unitsPerCase: spec.unitsPerCase,
      type: "QCCaseBased",
    });
  }

  const roomTypes = createRoomTypes(spec);
  if (spec.qcParBaseUnitsPerCase || roomTypes.length > 0) {
    specDetails.push({
      unitsPerCase: spec.qcParBaseUnitsPerCase,
      type: "QCParBased",
      roomTypes,
    });
  }

  return specDetails;
};

const createRoomTypes = spec => {
  const roomTypes = [];
  let iteration = 0;
  do {
    iteration++;
    if (
      spec[`roomName${iteration}`] ||
      spec[`roomCount${iteration}`] ||
      spec[`roomPerRoom${iteration}`] ||
      spec[`roomPar${iteration}`]
    ) {
      roomTypes.push({
        name: spec[`roomName${iteration}`],
        roomCount: spec[`roomCount${iteration}`],
        perRoom: spec[`roomPerRoom${iteration}`],
        par: spec[`roomPar${iteration}`],
      });
    } else {
      iteration = 0;
    }

    fieldsToOmit.push(
      `roomName${iteration}`,
      `roomCount${iteration}`,
      `roomPerRoom${iteration}`,
      `roomPar${iteration}`
    );
  } while (iteration > 0);

  return roomTypes;
};

const getGrid = actualStep =>
  actualStep === "selection" ? GridSelection : GridEdition;
const buildHandleUpdate = (rows, setRows) => (specId, attributes) => {
  const parseRow = row => {
    const attrs = (row.id === specId && attributes) || {};
    const modifiedRow = {
      ...row,
      ...attrs,
    };
    calculateOverageAndTotal(modifiedRow);

    return modifiedRow;
  };
  setRows(rows.map(parseRow));
};

const retrieveSpecsData = (
  targetProjectId,
  resetSelection,
  setActualStep,
  performRetrieveListRequest
) => () => {
  if (targetProjectId === "-1") return;
  resetSelection();
  setActualStep("selection");
  performRetrieveListRequest(dataComponentId, {
    rootFilters: {
      $where: {
        projectId: targetProjectId,
      },
    },
    pageSize: -1,
    params: {
      omitDefaultModifier: true,
      modifiers: ["withQuantityPrice"],
    },
  });
};

const retrieveCategories = actions => {
  actions.initDataComponent(
    specCategoryDataComponentId,
    SpecCategory,
    [],
    "spec-categories",
    false,
    "v2"
  );
  actions.performRetrieveListRequest(specCategoryDataComponentId, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["spec_categories.id", "name", "key", "isActive"],
    params: {
      modifiers: ["withLinked"],
    },
  });
};

const parseRowEditableModel = ({
  defaultAreaId,
  defaultProjectCurrencyId,
  poProjectCurrency,
  defaultProjectCurrency,
  categories,
  vendors,
  targetProjectAreas,
  defaultValues,
}) => spec => {
  const specCategory = getSpecCategory(
    spec,
    categories.filter(({ isActive }) => isActive)
  );
  return {
    ...spec,
    areaId: getArea(spec, targetProjectAreas, defaultAreaId)?.id,
    projectCurrencyId: defaultProjectCurrencyId,
    area: getArea(spec, targetProjectAreas, defaultAreaId),
    vendor: getVendor(spec, vendors),
    vendorId: getVendor(spec, vendors)?.id,
    priceCents: spec.priceCents / 100,
    specCategory,
    specCategoryId: specCategory?.id,
    projectCurrency: getProjectCurrency(
      poProjectCurrency,
      defaultProjectCurrency
    ),
    ...defaultValues,
  };
};

const useFetch = actions => {
  useEffect(() => {
    retrieveCategories(actions);
    actions.fetchUnitOfMeasures();
    actions.fetchVendors();
  }, [actions]);
};

// eslint-disable-next-line max-lines-per-function
const ImportSpecs = ({
  actions,
  project,
  areaId,
  projectName,
  queue,
  specs,
  bulkQueue,
  clientId,
  defaultAreaId,
  dataComponent,
  isFromProjects,
  isLoading,
  step,
  vendors,
  areas,
  categories,
  unitOfMeasures,
  defaultValues,
  defaultProjectCurrencyId,
  projectCurrencies,
  isImportToPO,
  revisionIsActive,
  poProjectCurrencyId,
  userId,
}) => {
  const { setPage, getPageSize } = useWithPaginationContext();

  const {
    selectedIds,
    resetSelection,
    selectAll,
  } = useWithSelectedRowContext();

  const [targetProjectId, setTargetProjectId] = useState("-1");
  const [actualStep, setActualStep] = useState(step);
  const [rows, setRows] = useState([]);
  const [displayUpdateSpecs, setDisplayUpdateSpecs] = useState(false);
  const [displayValidRows, errorActions] = useErrorImportStore(
    state => [
      state.displayValidRows,
      state.actions,
      state.localError,
      state.importError,
    ],
    shallow
  );

  const selectedItems = _get(dataComponent, "selectedIds.length", 0);
  const invalidRows = errorActions.getInvalidRows();
  const invalidSelectedRowIds = invalidRows.filter(id =>
    selectedIds.includes(id)
  );

  useFetch(actions);

  const initSpecs = useCallback(() => {
    actions.initDataComponent(
      dataComponentId,
      Spec,
      [
        "area",
        "vendor",
        "specCategory",
        "projectCurrency.currency",
        "unitOfMeasure",
      ],
      "specs",
      false,
      "v2"
    );
    setPage(0, "spec-import-data");
  }, [actions, setPage]);

  const cleanData = useCallback(() => {
    actions.destroyDataComponent(dataComponentId);
    actions.destroyDataComponentResource(dataComponentId, REQUEST_TYPES.LIST);
    initSpecs();
    setRows([]);
    actions.initQueueProcess(queueId);
    actions.initQueueProcess(queueCreateSpecsId);
  }, [actions, initSpecs]);

  useEffect(() => {
    actions.initQueueProcess(queueId);
    actions.initQueueProcess(queueCreateSpecsId);
    return () => {
      cleanData();
      errorActions.reset();
    };
  }, [actions, cleanData, errorActions]);

  useEffect(() => {
    initSpecs();
    actions.fetchAreasByProject(project.id);
    actions.fetchProjectCurrencies(project.id);
  }, [actions, actions.initDataComponent, initSpecs, project.id]);

  const handleUploadSpecs = useCallback(
    file => {
      errorActions.reset();
      actions.uploadSpecsFromXls(file, project.id, queueId);
      actions.fetchAreasByProject(project.id);
      actions.fetchProjectCurrencies(project.id);
    },
    [actions, errorActions, project]
  );

  const poProjectCurrency = projectCurrencies?.find(
    projectCurrency => projectCurrency.id == poProjectCurrencyId
  );

  useEffect(() => {
    setRowsData(
      queue,
      specs,
      setRows,
      errorActions,
      defaultAreaId,
      defaultProjectCurrencyId,
      isImportToPO,
      poProjectCurrency,
      categories,
      vendors
    );
  }, [
    queue,
    specs,
    errorActions,
    defaultAreaId,
    defaultProjectCurrencyId,
    isImportToPO,
    poProjectCurrency,
    categories,
    vendors,
  ]);

  useEffect(() => {
    errorActions.validateLocalRows(rows);
  }, [rows, errorActions]);

  useEffect(
    retrieveSpecsData(
      targetProjectId,
      resetSelection,
      setActualStep,
      actions.performRetrieveListRequest
    ),
    [actions, project, resetSelection, targetProjectId]
  );

  useEffect(() => {
    const validResult =
      _get(bulkQueue, "status") === QUEUE_STATUSES.QUEUE_SUCCESS;
    if (validResult) {
      cleanData();
      actions.closeModalDialog();
      actions.setReload("SpecsGrid", true);
    }
  }, [actions, bulkQueue, cleanData, queue]);

  const handleImport = () => {
    if (invalidSelectedRowIds.length) {
      errorActions.setIgnoreErrors(false);
      return;
    }
    const specs = parseImportData(
      isFromProjects,
      selectedIds,
      rows,
      project,
      defaultAreaId,
      defaultValues,
      defaultProjectCurrencyId,
      isImportToPO,
      poProjectCurrency
    );
    actions.createQueueProcess("bulk-create", queueCreateSpecsId, {
      objects: specs,
      modelType: "Spec",
      updatePoRevision: revisionIsActive,
      userId,
    });
  };

  const defaultProjectCurrency = projectCurrencies?.find(
    projectCurrency => projectCurrency.id === defaultProjectCurrencyId
  );

  const onEditableModel = () => {
    const rows = _sortBy(
      specs.filter(({ id }) => {
        return selectedIds.includes(id);
      }),
      ["customNumber"]
    ).map(
      parseRowEditableModel({
        defaultAreaId,
        defaultProjectCurrencyId,
        poProjectCurrency,
        defaultProjectCurrency,
        categories,
        vendors,
        targetProjectAreas: project.areas,
        defaultValues,
      })
    );
    setRows(rows);
    setActualStep("edition");
    setPage(0, "spec-import-data");
    setTimeout(() => {
      selectAll(rows, getPageSize("spec-import-data"));
    });
  };

  const backToOptions = () => {
    cleanData();
    actions.openImportSpecOptionsModal(project, clientId, projectName, areaId);
  };

  const handleUpdateAttributes = useCallback(buildHandleUpdate(rows, setRows), [
    rows,
  ]);

  if (displayUpdateSpecs) {
    return (
      <UpdateSpecs
        rows={rows}
        setRows={setRows}
        setDisplayUpdateSpecs={setDisplayUpdateSpecs}
        categories={categories}
        areas={areas}
        vendors={vendors}
        projectCurrencies={getProjectCurrencies(
          poProjectCurrency,
          projectCurrencies
        )}
      />
    );
  }

  const Grid = getGrid(actualStep);
  return (
    <Wrapper>
      <ImportForm
        isLoading={isLoading}
        clientId={clientId}
        projectId={project.id}
        targetProjectId={targetProjectId}
        onSetTargetProjectId={setTargetProjectId}
        closeModalDialog={actions.closeModalDialog}
        onUploadSpecs={handleUploadSpecs}
        isFromProjects={isFromProjects}
        step={step}
      />
      <Grid
        specs={
          displayValidRows
            ? rows
            : rows.filter(({ id }) => invalidRows.includes(id))
        }
        vendors={vendors}
        areas={areas}
        categories={categories}
        unitOfMeasures={unitOfMeasures}
        defaultAreaId={defaultAreaId}
        isLoading={isLoading}
        onUpdateAttributes={handleUpdateAttributes}
        isFromProjects={isFromProjects}
        projectCurrencies={getProjectCurrencies(
          poProjectCurrency,
          projectCurrencies
        )}
        poProjectCurrency={poProjectCurrency}
        setDisplayUpdateSpecs={setDisplayUpdateSpecs}
        isImportToPO={isImportToPO}
      />
      <FormikForm>
        {() => (
          <Buttons
            onImport={handleImport}
            bulkQueue={bulkQueue}
            selectedItems={selectedItems}
            targetProjectId={targetProjectId}
            selectedIds={selectedIds}
            onCancel={backToOptions}
            onEditableModel={onEditableModel}
            isFromProjects={isFromProjects}
            step={actualStep}
            rows={rows}
            invalidSelectedRowIds={invalidSelectedRowIds}
          />
        )}
      </FormikForm>
    </Wrapper>
  );
};

ImportSpecs.defaultProps = {
  specs: [],
  defaultValues: {},
};

ImportSpecs.propTypes = {
  actions: PropTypes.shape({}),
  defaultValues: PropTypes.shape({}),
  project: PropTypes.shape({}),
  step: PropTypes.oneOf(["selection", "edition"]),
  projectId: PropTypes.string,
  areaId: PropTypes.string,
  projectName: PropTypes.string,
  specs: PropTypes.arrayOf(propTypes.spec),
  queue: propTypes.queue,
  clientId: PropTypes.string,
  defaultAreaId: PropTypes.string,
  bulkQueue: propTypes.queue,
  dataComponent: propTypes.dataComponent,
  isFromProjects: PropTypes.bool,
  areas: PropTypes.arrayOf(propTypes.area),
  categories: PropTypes.arrayOf(propTypes.specCategory),
  vendors: PropTypes.arrayOf(propTypes.vendor),
  unitOfMeasures: PropTypes.arrayOf(propTypes.unitOfMeasure),
  isLoading: PropTypes.bool,
  defaultProjectCurrencyId: PropTypes.string,
  projectCurrencies: PropTypes.array,
  isImportToPO: PropTypes.bool,
  revisionIsActive: PropTypes.bool,
  poProjectCurrencyId: PropTypes.string,
  userId: PropTypes.string,
};

export default withSelectedRows(ImportSpecs, gridId);
