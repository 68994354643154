import React from "react";
import fields from "./createPONotesFields";
import { FormPageSection } from "../../../layout/FormPageSection";
import PropTypes from "prop-types";

const PONotesSection = ({ values }) => {
  return (
    <FormPageSection
      headerText="Commodity Notes"
      fields={fields}
      values={values}
    />
  );
};

PONotesSection.propTypes = {
  values: PropTypes.shape({}),
};

export default PONotesSection;
