import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import Grid from "../../../ui/Grid/LocalGrid";
import { LoaderContext } from "../../../ui/Loader";
import { mapStateToProps, mapDispatchToProps } from "./connect";
import { useEffects, initializeSpecAndTotalRows } from "./analysisEffects";
import useFiltering from "./useFiltering";

import * as SPEC_TYPES from "../specTypes";

import {
  getColumns,
  getColumnBands,
  getColumnExtensions,
  fixedColumns,
  getProviders,
  getFilteringProviders,
  getTableComponents,
} from "./analysisGridSettings";
import { FilteringContext } from "../../../ui/Grid/LocalGrid/FilteringControl";
import { DisplayVendorContext } from "./ShowHideControl/withDisplayVendorControl";
import * as BID_TYPES from "../bidTypes";
import { onHandleCOMClicked } from "./analysisHandlers";
import { AlternateQuotesContext } from "../BidGroupContainer";
import { getBidGroupVendorsAlternate } from "./analysisFunctions";
import useActions from "../../../hooks/useActions";
import { openModalDialog } from "../../../../actions/layoutActions";

const gridId = "bid-group-analysis";

export const getExpandedSpecsAndCOMItems = specs => {
  const expandedSpecs = [];
  const comItems = {};
  specs.sort((row1, row2) => {
    return row1.customNumber.localeCompare(row2.customNumber, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
  specs.map(spec => {
    spec.type = SPEC_TYPES.SPEC;
    expandedSpecs.push(spec);
    const { specDetails = [] } = spec;
    specDetails.map(specDetail => {
      const { specDetailComs = [] } = specDetail;
      let lastCOM = null;
      specDetailComs.sort((row1, row2) => {
        return row1.spec.customNumber.localeCompare(
          row2.spec.customNumber,
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      });
      specDetailComs.map(specDetailCom => {
        specDetailCom.spec.type = SPEC_TYPES.COM;
        expandedSpecs.push(specDetailCom.spec);
        comItems[specDetailCom.spec.id] = Object.assign(
          {},
          { ...specDetailCom.spec },
          { parentSpec: spec }
        );
        lastCOM = specDetailCom.spec;
      });
      if (lastCOM) {
        spec.isParent = true;
        lastCOM.isLastCOM = true;
      }
    });
  });
  return { expandedSpecs, comItems };
};
export const getSortedVendors = bgVendors => {
  return bgVendors.sort((a, b) => {
    if (
      a.bidType === BID_TYPES.COM_SPEC_ONLY &&
      b.bidType !== BID_TYPES.COM_SPEC_ONLY
    ) {
      return 1;
    }
    if (
      a.bidType !== BID_TYPES.COM_SPEC_ONLY &&
      b.bidType === BID_TYPES.COM_SPEC_ONLY
    ) {
      return -1;
    }
    return a.vendor.name > b.vendor.name ? 1 : -1;
  });
};
export const onCOMClicked = (
  bgVendor,
  comItem,
  selectedCOMs,
  setSelectedCOMs
) => {
  onHandleCOMClicked(bgVendor, comItem, selectedCOMs, setSelectedCOMs);
};
export const AnalysisPage = ({
  bidGroupId,
  performRetrieveListRequest,
  performFindRequest,
  initDataComponent,
  destroyDataComponent,
  bidGroup,
  bidGroupVendors,
  paymentTerms,
  loading,
}) => {
  useEffects(
    performRetrieveListRequest,
    performFindRequest,
    initDataComponent,
    destroyDataComponent,
    bidGroupId
  );

  const [specRows, setSpecRows] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [sortedVendors, setSortedVendors] = useState([]);
  const [selectedCOMs, setSelectedCOMs] = useState({});
  const [isCOMVisible, setCOMVisible] = useState(false);

  const { showAlternateQuotes } = useContext(AlternateQuotesContext);
  const { isBudgetSectionVisible } = useContext(DisplayVendorContext);

  const isSampleColumnsVisible = !!bidGroup.enableSampleQuantity;

  const actions = useActions({ openModalDialog });

  const handleOpenAlternateQuotesModal = useCallback(
    (vendorSpec, bgVendor) => {
      actions.openModalDialog(
        bgVendor.vendor.name,
        "AnalysisAlternateQuotesModal",
        {
          vendorSpec: vendorSpec,
          bgVendor: bgVendor,
        },
        true,
        true,
        {
          subtitle: `Change Quote for #${vendorSpec.spec.customNumber} ${vendorSpec.spec.description}`,
          width: "700px",
        }
      );
    },
    [actions]
  );

  useEffect(() => {
    const modifiedBidGroupVendors = showAlternateQuotes
      ? getBidGroupVendorsAlternate(bidGroupVendors)
      : bidGroupVendors;
    const results = getSortedVendors(modifiedBidGroupVendors);
    setSortedVendors(results);
  }, [bidGroupVendors, showAlternateQuotes]);

  useEffect(() => {
    initializeSpecAndTotalRows(
      bidGroup,
      sortedVendors,
      selectedCOMs,
      setSelectedCOMs,
      (bidGroupVendor, comItem) =>
        onCOMClicked(bidGroupVendor, comItem, selectedCOMs, setSelectedCOMs),
      paymentTerms,
      setSpecRows,
      setTotalRows,
      handleOpenAlternateQuotesModal,
      setCOMVisible,
      isSampleColumnsVisible
    );
  }, [
    bidGroup,
    sortedVendors,
    paymentTerms,
    selectedCOMs,
    handleOpenAlternateQuotesModal,
    isSampleColumnsVisible,
  ]);

  const gridColumns = useMemo(
    getColumns(
      sortedVendors,
      isBudgetSectionVisible,
      isCOMVisible,
      isSampleColumnsVisible
    ),
    [sortedVendors, isBudgetSectionVisible, isCOMVisible]
  );

  const gridColumnBands = useMemo(
    getColumnBands(sortedVendors, specRows.length),
    [sortedVendors]
  );

  const gridColumnExtensions = useMemo(getColumnExtensions(), []);

  const gridDataTypeProviders = useMemo(getProviders(sortedVendors), [
    sortedVendors,
  ]);
  const gridFilteringDataTypeProviders = useMemo(
    getFilteringProviders(gridColumns),
    [gridColumns]
  );

  const filteringControlValue = useFiltering(gridId, totalRows);

  const loaderValue = useMemo(() => ({ loading }), [loading]);

  return (
    <LoaderContext.Provider value={loaderValue}>
      <FilteringContext.Provider value={filteringControlValue}>
        <Grid
          gridId={gridId}
          rows={specRows}
          columns={gridColumns}
          columnBands={gridColumnBands}
          columnExtensions={gridColumnExtensions}
          fixedColumns={fixedColumns}
          dataTypeProviders={gridDataTypeProviders}
          filteringDataTypeProviders={gridFilteringDataTypeProviders}
          tableComponents={getTableComponents(
            isCOMVisible,
            isSampleColumnsVisible
          )}
          useParentPaginationControl
          useParentFilteringControl
          pagingPanelProps={{ hidePaginationControl: true }}
        />
      </FilteringContext.Provider>
    </LoaderContext.Provider>
  );
};

AnalysisPage.propTypes = {
  performRetrieveListRequest: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  destroyDataComponent: PropTypes.func.isRequired,
  bidGroupId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  bidGroup: propTypes.bidGroup,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  paymentTerms: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  loading: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPage);
