import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Paper, Switch } from "../../../mui/core";
import { BWGridAPI, GridHeader, GridRow, GridTable } from "../../../ui/BWGrid";
import * as tableConfigs from "../../../../components/ui/gridTableConfigs/detailPageProjects";

const projectStatusFilters = {
  closed: { $and: { status: { $notEqual: "Closed" } } },
  all: {},
};

export default class Projects extends Component {
  state = {
    projectStatusFilter: "closed",
  };

  handleRowClick = ({
    id,
    property: {
      entity: { clientId },
    },
  }) => {
    this.props.push(`/clients/${clientId}/projects/${id}`);
  };

  handleSwitchClosedPOProjects = () => {
    this.setState({
      projectStatusFilter:
        this.state.projectStatusFilter === "closed" ? "all" : "closed",
    });
  };

  render() {
    const { projectStatusFilter } = this.state;
    const { dataComponent, consultingCompanyId } = this.props;
    const { columns, columnOptions } = tableConfigs;

    return (
      <Fragment>
        <Paper>
          <BWGridAPI
            {...this.props}
            rootFilters={{
              $where: {
                $or: [
                  {
                    projectManagerId: consultingCompanyId,
                  },
                  {
                    designerId: consultingCompanyId,
                  },
                  {
                    secondaryDesignerId: consultingCompanyId,
                  },
                  {
                    generalContractorId: consultingCompanyId,
                  },
                  {
                    otherConsultantId: consultingCompanyId,
                  },
                ],
                ...projectStatusFilters[projectStatusFilter],
              },
            }}
          >
            <GridHeader headerText={`${dataComponent.totalRows} Projects`} />
            <GridTable
              columns={columns}
              columnOptions={columnOptions}
              onClick={this.handleRowClick}
            />
            <GridRow>
              <FormControlLabel
                control={
                  <Switch
                    checked={projectStatusFilter !== "closed"}
                    onChange={this.handleSwitchClosedPOProjects}
                    color="primary"
                  />
                }
                label="Show Closed Projects"
              />
            </GridRow>
          </BWGridAPI>
        </Paper>
      </Fragment>
    );
  }
}

Projects.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  consultingCompanyId: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};
