import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../../actions/layoutActions";
import HiddenInput from "../../../../../ui/HiddenInput";
import RegularText from "../../../../../ui/Typography/RegularText";
import Loader from "../../../../../ui/Loader";
import QuoteFileGrid from "./QuoteFileGrid";
import propTypes from "../../../../../../constants/propTypes";

const TextWrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
`;

const RenderedField = ({
  formikArrayHelpers,
  rows,
  quoteStatus,
  addFileRef,
}) => {
  const actions = useActions({ openModalDialog, closeModalDialog });

  const quoteFilesAreEmpty = rows.length === 0;

  const handleAddQuoteFile = evt => {
    const file = evt.target.files[0];
    if (!file) return;

    formikArrayHelpers.push({
      filename: file.name,
      isUpdated: true,
      file,
    });
  };

  const deleteHandler = index => () => {
    formikArrayHelpers.remove(index);
    actions.closeModalDialog();
  };

  const handleDelete = row => {
    const index = rows.findIndex(currentRow => currentRow === row);
    actions.openModalDialog(
      ["Delete Quote File"],
      "DeleteContract",
      { index, onDelete: deleteHandler(index), title: row.filename },
      false,
      false
    );
  };

  return (
    <div>
      <HiddenInput
        ref={addFileRef}
        type="file"
        onChange={handleAddQuoteFile}
        id="add-quote-file-input"
        value=""
        accept=".doc,.pdf,.xls,.xlsx,.csv"
      />
      {quoteFilesAreEmpty ? (
        <TextWrapper intent="light">
          <RegularText intent="light">
            <Loader height="20px" width="200px">
              No quote files have been uploaded yet
            </Loader>
          </RegularText>
        </TextWrapper>
      ) : (
        <QuoteFileGrid
          rows={rows}
          onDelete={handleDelete}
          quoteStatus={quoteStatus}
        />
      )}
    </div>
  );
};
RenderedField.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  rows: PropTypes.arrayOf(propTypes.file),
  addFileRef: PropTypes.shape({}),
  quoteStatus: PropTypes.string,
};

export default RenderedField;
