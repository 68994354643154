import styled from "styled-components";

const HiddenInput = styled.input`
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
`;

export default HiddenInput;
