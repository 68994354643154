import { cleanUpBody } from "../../../../actions/dataComponentActions";

export const onCreateArea = (area, formikArrayHelpers, closeModalDialog) => {
  formikArrayHelpers.push(area);
  closeModalDialog();
};
export const onUpdateArea = (
  area,
  index,
  formikArrayHelpers,
  closeModalDialog
) => {
  formikArrayHelpers.replace(index, {
    ...cleanUpBody(area),
  });
  closeModalDialog();
};
export const onDeleteArea = (index, formikArrayHelpers, closeModalDialog) => {
  formikArrayHelpers.remove(index);
  closeModalDialog();
};
