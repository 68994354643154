import React from "react";
import PropTypes from "prop-types";

import { SubTitle } from "./SubTitle";
import ModalTitle from "../../../../../layout/AppLayout/ModalDialog/ModalTitle";

export const FundingFormTitle = ({
  isFundingPaidConfirmation,
  closeModalDialog,
}) => {
  return (
    <ModalTitle
      title={isFundingPaidConfirmation ? " " : "Create Fund Request"}
      extraTitle={
        <SubTitle isFundingPaidConfirmation={isFundingPaidConfirmation} />
      }
      isShowCloseIcon={!isFundingPaidConfirmation}
      closeModalDialog={closeModalDialog}
      width="100%"
    />
  );
};
FundingFormTitle.propTypes = {
  isFundingPaidConfirmation: PropTypes.bool,
  closeModalDialog: PropTypes.func,
};
