import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import BWModels from "benjaminwest-models";
import styled from "styled-components";
import pluralize from "pluralize";

import { BWGridLocal, GridTable } from "../../../../ui/BWGrid";
import { Wrapper } from "../../Shipments/components";
import { calculateQuantityTotals } from "../../Shipments/ManageShipments/SpecEstimatedDates";
import { HeaderOverride } from "../components";
import { columns, getColumnOptions, isEditableRow } from "./gridProps";
import SpecShipmentsActions from "./SpecShipmentsActions";
import { PurchaseOrderHeader } from "../PurchaseOrderHeader";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCellAPI";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import Spec from "../../../../../models/Spec";
import { withSelectedRows } from "../../../../ui/BWGrid";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import { getShipments } from "../EditMultipleShipments/EditMultipleShipmentsModal";
import SpecShipment from "../../../../../models/SpecShipment";
import { getDateInput } from "../CreateShipment/createShipmentFields";
import { inputs } from "../../../../inputs/inputConfigs";

export const StyledSpanInstruction = styled.span`
  color: rgba(0, 0, 0, 0.38);
  padding-right: 24px;
  padding-left: 24px;
`;

const getRowInputs = tableRow => {
  const { shipment = {} } = tableRow.row;
  return {
    estimatedShipDate: {
      ...getDateInput(
        inputs.estimatedShipDate,
        "estimatedDeliveryDate",
        shipment
      ),
      isApplyOnChange: true,
    },
    estimatedDeliveryDate: {
      ...getDateInput(
        inputs.estimatedDeliveryDate,
        "estimatedShipDate",
        shipment
      ),
      isApplyOnChange: true,
    },
    actualShipDate: {
      ...getDateInput(inputs.actualShipDate, "actualDeliveryDate", shipment),
      isApplyOnChange: true,
    },
    actualDeliveryDate: {
      ...getDateInput(inputs.actualDeliveryDate, "actualShipDate", shipment),
      isApplyOnChange: true,
    },
    approvedToPay: {
      InputComponent: CheckboxWithError,
      label: "Approved to Pay",
      name: "approvedToPay",
      isInline: true,
      fullWidth: true,
      isApplyOnChange: true,
    },
  };
};

const DescriptionComponent = () => (
  <Fragment>
    <StyledSpanInstruction>
      Click on a Spec to manage Shipments
    </StyledSpanInstruction>
  </Fragment>
);

const generateSpecShipments = (resources, specIds) => {
  const specShipments = SpecShipment.query(resources)
    .where({ id: specIds })
    .toObjects();

  return specShipments;
};

export const getSpecShipments = createSelector(
  ({ resources }) => resources,
  (_, { specIds }) => specIds,
  generateSpecShipments
);

export const hasPropTrueForAllSelectedSpecs = (
  disabled,
  selectedSpecShipments,
  prop
) => {
  return (
    !disabled &&
    selectedSpecShipments.filter(sss => sss[prop]).length ===
      selectedSpecShipments.length
  );
};

const getModifyMultipleShipmentsAction = ({
  hasSelectedRows,
  onOpenEditAction,
  onOpenEditAllAction,
  selectedIds,
}) =>
  hasSelectedRows
    ? {
        content: "Modify Multiple Shipments",
        onClick: onOpenEditAction(selectedIds),
        disableIfProjectClosed: true,
      }
    : {
        content: "Modify Multiple Shipments (All)",
        onClick: onOpenEditAllAction(selectedIds),
        disableIfProjectClosed: true,
      };

const getStatusCommentAction = ({
  hasSelectedRows,
  handleStatusComment,
  selectedSpecShipments,
  handleStatusCommentAll,
}) =>
  hasSelectedRows
    ? {
        content: "Set Status Comment",
        onClick: () => handleStatusComment(selectedSpecShipments),
        disableIfProjectClosed: true,
      }
    : {
        content: "Set Status Comment (All)",
        onClick: () => handleStatusCommentAll(),
        disableIfProjectClosed: true,
      };

export const getActions = (
  selectedIds,
  selectedShipments,
  selectedSpecShipments,
  onOpenEditAction,
  onOpenEditAllAction,
  handleApproveToPay,
  handleAddTrackingNote,
  handleStatusComment,
  handleStatusCommentAll,
  handleSpecUpdate
) => () => {
  const disabled = selectedIds.length === 0;
  const options = [
    {
      content: "Add Tracking Note",
      onClick: () => handleAddTrackingNote(selectedSpecShipments),
      disableIfProjectClosed: true,
    },
    {
      content: "Approved to Pay",
      onClick: () => handleApproveToPay(selectedShipments),
      disabled,
      disableIfProjectClosed: true,
    },
    getModifyMultipleShipmentsAction({
      hasSelectedRows: selectedIds.length,
      onOpenEditAction,
      onOpenEditAllAction,
      selectedIds,
    }),
    getStatusCommentAction({
      hasSelectedRows: selectedIds.length,
      handleStatusComment,
      handleStatusCommentAll,
      selectedSpecShipments,
    }),
  ];
  if (
    hasPropTrueForAllSelectedSpecs(disabled, selectedSpecShipments, "atRisk")
  ) {
    options.push({
      content: "Unmark At Risk",
      onClick: () => handleSpecUpdate(selectedSpecShipments, { atRisk: false }),
      disabled,
      disableIfProjectClosed: true,
    });
  } else {
    options.push({
      content: "Mark At Risk",
      onClick: () => handleSpecUpdate(selectedSpecShipments, { atRisk: true }),
      disabled,
      disableIfProjectClosed: true,
    });
  }
  if (
    hasPropTrueForAllSelectedSpecs(
      disabled,
      selectedSpecShipments,
      "inWatchList"
    )
  ) {
    options.push({
      content: "Remove From Watch List",
      onClick: () =>
        handleSpecUpdate(selectedSpecShipments, { inWatchList: false }),
      disabled,
      disableIfProjectClosed: true,
    });
  } else {
    options.push({
      content: "Add To Watch List",
      onClick: () =>
        handleSpecUpdate(selectedSpecShipments, { inWatchList: true }),
      disabled,
      disableIfProjectClosed: true,
    });
  }
  return options;
};

export const isQuantityMismatch = specShipments => {
  for (const specShipment of specShipments) {
    const { quantity, totalShipmentQuantity } = calculateQuantityTotals(
      specShipment,
      specShipment.shipments
    );
    if (quantity !== totalShipmentQuantity) {
      return true;
    }
  }
  return false;
};

const SpecShipmentsGrid = ({
  specShipments,
  onClickSpec,
  selectedIds,
  onOpenEditAction,
  onOpenEditAllAction,
  isCollapsed,
  setIsCollapsed,
  handleApproveToPay,
  handleAddTrackingNote,
  handleStatusComment,
  handleStatusCommentAll,
  handleSpecUpdate,
  purchaseOrder,
  headerText,
  dataComponentId,
  shipmentsDataComponentId,
  isPODetailPage,
  ...props
}) => {
  const { shipments: selectedShipments } = useSelector(state => ({
    shipments: getShipments(state, { specIds: selectedIds }),
  }));
  const { specShipments: selectedSpecShipments } = useSelector(state => ({
    specShipments: getSpecShipments(state, { specIds: selectedIds }),
  }));

  const actions = useMemo(
    getActions(
      selectedIds,
      selectedShipments,
      selectedSpecShipments,
      onOpenEditAction,
      onOpenEditAllAction,
      handleApproveToPay,
      handleAddTrackingNote,
      handleStatusComment,
      handleStatusCommentAll,
      handleSpecUpdate
    ),
    [
      selectedIds,
      selectedSpecShipments,
      selectedShipments,
      onOpenEditAction,
      handleApproveToPay,
    ]
  );

  const columnOptions = useMemo(() => getColumnOptions(handleApproveToPay), [
    handleApproveToPay,
  ]);

  const tableComponents = useMemo(() => {
    return {
      CellComponent: editableCellCreator(
        getRowInputs,
        shipmentsDataComponentId,
        {},
        isEditableRow
      ),
      ...genDetailRowComponents(
        "statusComment",
        "id",
        BWModels.loadSchema("Spec"),
        dataComponentId,
        6,
        2,
        { helperText: "Status Comment" },
        false,
        { apiRoute: "specs", model: Spec }
      ),
    };
  }, [dataComponentId, shipmentsDataComponentId]);

  const showQuantityMismatchWarning =
    isPODetailPage &&
    purchaseOrder.isRevised &&
    isQuantityMismatch(specShipments);

  return (
    <Wrapper>
      <PurchaseOrderHeader
        headerText={
          headerText || pluralize("Shipment", specShipments.length, true)
        }
        headerOverride={
          <HeaderOverride
            purchaseOrder={purchaseOrder}
            isCollapsed={isCollapsed}
          />
        }
        isPODetailPage={isPODetailPage}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        ActionsComponent={() => (
          <Fragment>
            <SpecShipmentsActions actions={actions} />
          </Fragment>
        )}
        DescriptionComponent={DescriptionComponent}
        showQuantityMismatchWarning={showQuantityMismatchWarning}
      />
      <BWGridLocal
        id={dataComponentId}
        rows={specShipments}
        tableComponents={tableComponents}
        showSelectionColumn
        showSelectAll
        gridConfig={{ pageSize: 10, totalRows: specShipments.length }}
        ignoreOptimizations
        {...props}
      >
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          onClick={onClickSpec}
        />
      </BWGridLocal>
    </Wrapper>
  );
};

SpecShipmentsGrid.propTypes = {
  onClickSpec: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onOpenEditAction: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
};

SpecShipmentsGrid.defaultProps = {
  selectedIds: [],
};

export default withSelectedRows(SpecShipmentsGrid);
