import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import { cloneDeep } from "lodash";
import _map from "lodash/map";

import LazyTreeView from "./LazyTreeView";
import Summary from "./Summary";
import { LoaderContext } from "../../../../ui/Loader";
import { ModalTitle } from "../../../../layout/AppLayout/ModalDialog/ModalTitle";
import FormikForm from "../../../../forms/FormikForm";
import ActionButtons from "../../../../forms/ActionButtons";
import { FormGrid } from "../../../../forms";
import { getGeneralFields } from "./fields";
import { treeProperties } from "./Summary/Summary";
import { customReportDataComponentId } from "../ReportTemplatesContainer";
import propTypes from "../../../../../constants/propTypes";
import {
  ActionsSection,
  ColumnsSection,
  ColumnWrapper,
  ContentWrapper,
  DataSection,
  Wrapper,
} from "./components";
import useUserRole from "../../../../hooks/useUserRole";
import { isSuperAdmin } from "../../../../../utils/roleUtils";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

export const onUpdate = ({
  root,
  columns: formColumns,
  setFieldValue,
}) => column => {
  const columns = treeProperties(
    root,
    formColumns.filter(({ selectName }) => selectName !== column?.selectName)
  );
  setFieldValue("columns", columns);
};

export const getValidationSchema = userRole => {
  const validationObject = {
    name: Joi.string().required(),
    columns: Joi.array()
      .items(
        Joi.object({
          filtered: Joi.boolean(),
          filter: Joi.string().when("filtered", {
            is: true,
            then: Joi.required(),
            otherwise: Joi.optional().allow(null),
          }),
        })
      )
      .min(1),
  };

  if (isSuperAdmin(userRole)) {
    validationObject.scopes = Joi.array().min(1);
  }

  return Joi.object().keys(validationObject);
};

const getModalTitle = isEditing =>
  `${isEditing ? "Edit" : "Create"} Report Template`;
const getSendButtonText = isEditing => `${isEditing ? "Update" : "Create"}`;

export const getInitialValues = (customReport, office, userRoleIds) => {
  if (!customReport) {
    return {
      name: "",
      description: "",
      columns: [],
      userRoleIds,
      scopes: [office.scope],
    };
  }

  return {
    name: customReport.name,
    description: customReport.description,
    columns: customReport.customReportDataOptions.sort(
      (a, b) => a.sequenceIndex - b.sequenceIndex
    ),
    userRoleIds: customReport.userRoleIds?.map(id => `${id}`) || [],
    scopes: customReport.scopes,
  };
};

const CreateReportTemplate = ({
  views,
  dataComponentViewId,
  onSubmit,
  closeModalDialog,
  loading,
  customReport,
  office,
}) => {
  const [root, setRoot] = useState(cloneDeep(customReport?.treeObject));

  const { entityName: rootEntity } = root || {};

  const nodeSet = new Set(rootEntity ? [rootEntity] : []);

  const onExpand = node => {
    nodeSet.add(node);
  };

  const onCollapse = node => {
    nodeSet.delete(node);
  };

  const isEditing = !!customReport;

  const userRole = useUserRole();

  const userRoles = useV2DatacomponentResources(
    "select-userRoles",
    [],
    REQUEST_TYPES.LIST
  );

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FormikForm
        initialValues={getInitialValues(
          customReport,
          office,
          _map(userRoles, "id")
        )}
        validationSchema={getValidationSchema(userRole)}
        onSubmit={onSubmit(root)}
        enableReinitialize
        ignoreCache
      >
        {({ handleSubmit, values, ...formikProps }) => {
          return (
            <Wrapper>
              <ModalTitle
                title={getModalTitle(isEditing)}
                width={"unset"}
                isShowCloseIcon
                closeModalDialog={closeModalDialog}
              />
              <ContentWrapper>
                <Fragment>
                  <DataSection>
                    <FormGrid
                      fields={getGeneralFields({
                        userRole,
                      })}
                      values={values}
                      errors={formikProps.errors}
                      {...formikProps}
                    />
                  </DataSection>
                  <ColumnsSection>
                    <ColumnWrapper>
                      <LazyTreeView
                        rows={views}
                        root={root}
                        setRoot={setRoot}
                        nodeSet={nodeSet}
                        onExpand={onExpand}
                        onCollapse={onCollapse}
                        onUpdate={onUpdate({
                          root,
                          columns: values.columns,
                          setFieldValue: formikProps.setFieldValue,
                        })}
                        parentDataComponentId={dataComponentViewId}
                      />
                    </ColumnWrapper>
                    <ColumnWrapper>
                      <Summary columns={values.columns} {...formikProps} />
                    </ColumnWrapper>
                  </ColumnsSection>
                  <ActionsSection>
                    <ActionButtons
                      onSend={handleSubmit}
                      onCancel={closeModalDialog}
                      sendButtonText={getSendButtonText(isEditing)}
                      listeners={[customReportDataComponentId]}
                    />
                  </ActionsSection>
                </Fragment>
              </ContentWrapper>
            </Wrapper>
          );
        }}
      </FormikForm>
    </LoaderContext.Provider>
  );
};

CreateReportTemplate.propTypes = {
  views: PropTypes.array.isRequired,
  dataComponentViewId: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  customReport: propTypes.customReport,
};

export default CreateReportTemplate;
