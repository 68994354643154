import React from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../../constants/propTypes";
import LocalGrid from "./../../../../../ui/Grid/LocalGrid";
import { dateTypeBuilder } from "../../../../../ui/Grid/utilComponents/dataTypeProviders";
import { actionIconsBuilder, downloadFileBuilder } from "./dataTypeProviders";

const Grid = ({ files, onDelete, activeDelete, isLabeled }) => {
  const columns = [
    { name: "file.filename", title: "Filename", bold: true },
    { name: "file.createdAt", title: "Date Added" },
    { name: "downloadFile", title: " ", width: "50px" },
  ];

  if (!isLabeled) {
    columns.push({ name: "actionIcons", title: " ", width: "64px" });
  }

  return (
    <LocalGrid
      gridId="grid-files"
      rows={files}
      columns={columns}
      tableComponents={{ GridWrapper: ({ children }) => children }}
      dataTypeProviders={[
        actionIconsBuilder(onDelete, activeDelete),
        dateTypeBuilder(["file.createdAt"]),
        downloadFileBuilder(),
      ]}
    />
  );
};

Grid.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, file: propTypes.file })
  ),
  onDelete: PropTypes.func,
  activeDelete: PropTypes.string,
  isLabeled: PropTypes.bool,
};

export default Grid;
