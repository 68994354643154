import { inputs } from "../../../inputs/inputConfigs";

export default (isRequired = false) => ({
  groups: [
    {
      items: [
        {
          input: { ...inputs.changeOrderRevisionType, required: true },
          grid: { xs: 4 },
        },
        {
          input: { ...inputs.changeOrderNotes, required: true },
          grid: { xs: 12 },
          required: isRequired,
        },
      ],
    },
  ],
});
