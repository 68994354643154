import { inputs } from "../../../inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        { input: inputs.project, grid: { xs: 12 } },
        { input: inputs.noteTime, grid: { xs: 12 } },
        { input: inputs.noteTrackingNumber, grid: { xs: 12 } },
        { input: inputs.noteCarrierName, grid: { xs: 12 } },
        { input: inputs.deliveryNotificationNumber, grid: { xs: 12 } },
        { input: inputs.noteHeadline, grid: { xs: 12 } },
        {
          input: inputs.noteDetail,
          grid: { xs: 12 },
        },
        { input: inputs.noteAssignTo, grid: { xs: 12 } },
        { input: inputs.tags, grid: { xs: 12 } },
      ],
    },
  ],
};
