import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import { GridForm } from "./GridForm/GridForm";
import ClientPricing from "../../../../models/ClientPricing";
import { Paper } from "../../../mui/core";
import {
  useCreateProcessRequests,
  useListProcessRequests,
} from "../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../hooks/useDatacomponent";
import {
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import useActions from "../../../hooks/useActions";
import { LabeledTextContext } from "../../../../withPORevision/withLabeledTextOption";
import useUserRole from "../../../hooks/useUserRole";
import { roleCan } from "../../../../utils/roleUtils";

const TieredFees = ({ clientId }) => {
  const [selectedVersion, setSelectedVersion] = useState("");
  const actions = useActions({
    performCreateRequest,
    performRetrieveListRequest,
  });

  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [{ columnName: "version", direction: "desc" }],
      rootFilters: {
        $where: {
          clientId,
        },
      },
    }),
    [clientId]
  );
  const dataComponentDef = useMemo(
    () => ({
      dataComponentId: "clientPricingsDataComponentId",
      model: ClientPricing,
      includes: ["pricingTiers"],
      apiRoute: "client-pricings",
    }),
    []
  );
  const { isLoading, data: clientPricings = [] } = usePerformRetrieveList(
    dataComponentDef,
    filters
  );

  const dataComponent = useDatacomponent(dataComponentDef.dataComponentId);
  useListProcessRequests(dataComponent, {
    onSuccess: () => {
      const [{ version } = { version: "" }] = clientPricings;
      setSelectedVersion(version);
    },
  });
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.performRetrieveListRequest(
        dataComponentDef.dataComponentId,
        filters
      );
    },
  });

  const handleVersionChange = ({ target: { value } }) =>
    setSelectedVersion(value);

  const handleSubmit = useCallback(
    ({ pricingTiers }) => {
      const filteredPricingTiers = pricingTiers
        .filter((_, index) => index != 0)
        .map(
          ({ id, clientPricingId, createdAt, updatedAt, ...pricingTier }) =>
            pricingTier
        );

      actions.performCreateRequest(dataComponentDef.dataComponentId, {
        clientId,
        version: clientPricings.length + 1,
        pricingTiers: filteredPricingTiers,
      });
    },
    [actions, clientId, clientPricings.length, dataComponentDef.dataComponentId]
  );

  const userRole = useUserRole();

  return (
    <Paper>
      <LabeledTextContext.Provider
        value={!roleCan(userRole, "update-tiered-fees")}
      >
        <GridForm
          clientPricings={clientPricings}
          selectedVersion={selectedVersion}
          onVersionChange={handleVersionChange}
          isLoading={isLoading}
          dataComponentId={dataComponentDef.dataComponentId}
          onSubmit={handleSubmit}
        />
      </LabeledTextContext.Provider>
    </Paper>
  );
};
TieredFees.propTypes = {
  clientId: PropTypes.string,
};

export const mapStateToProps = (state, { client }) => {
  return {
    clientId: client.id,
  };
};

export default connect(mapStateToProps, null)(TieredFees);
