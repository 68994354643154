import _invoke from "lodash/invoke";
import _get from "lodash/get";

import Client from "../../../../models/Client";
import Project from "../../../../models/Project";

const getBreadcrumbsForClient = (clientId, state) => {
  const client = Client.query(state.resources).find(clientId);
  if (!client) return null;
  return [{ label: client.name }];
};

const getBreadcrumbsForProject = (projectId, state) => {
  const project = Project.query(state.resources).find(projectId);
  if (!project) return [];

  const property = _invoke(project, "property");
  const entity = _invoke(property, "entity");
  const client = _invoke(entity, "client");
  return [
    {
      label: _get(client, "name"),
      clickParams: {
        context: {
          clientId: _get(client, "id"),
        },
      },
    },
    { label: project.name },
  ];
};

export const getBreadcrumbs = (context, state) => {
  if (context.clientId) {
    return getBreadcrumbsForClient(context.clientId, state);
  }
  if (context.projectId) {
    return getBreadcrumbsForProject(context.projectId, state);
  }
  return [{ label: "All Notes" }];
};
