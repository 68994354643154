import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash.get";

import { StyledTextField } from "../../../mui/core/TextField";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { shouldShrinkLabel } from "../../TextInputWithError";

function Input({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control({
  className,
  hasValue,
  isFocused,
  innerRef,
  children,
  innerProps,
  selectProps,
}) {
  const { width } = useWindowDimensions();
  const InputProps = useMemo(() => {
    const props = _get(selectProps, "textFieldProps.InputProps", {});
    return {
      inputComponent: Input,
      inputProps: {
        className: className,
        inputRef: innerRef,
        children: children,
        ...innerProps,
      },
      ...props,
    };
  }, [selectProps, className, innerRef, children, innerProps]);
  const InputLabelProps = useMemo(() => {
    const { removeOnValue, ...props } = _get(
      selectProps,
      "textFieldProps.InputLabelProps",
      {}
    );
    /*const isActive =
      const children =*/

    return {
      shrink: hasValue || isFocused || shouldShrinkLabel(width),
      ...props,
    };
  }, [width, selectProps, hasValue, isFocused]);
  return (
    <StyledTextField
      error={selectProps.error}
      fullWidth
      {...selectProps.textFieldProps}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
    />
  );
}

Control.propTypes = {
  className: PropTypes.string.isRequired,
  innerRef: PropTypes.func.isRequired,
  hasValue: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.shape({}).isRequired,
  selectProps: PropTypes.shape({
    error: PropTypes.bool,
    textFieldProps: PropTypes.shape({}),
  }).isRequired,
};

export default styled(Control)`
  display: flex;
`;
