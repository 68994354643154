import PropTypes from "prop-types";
import styled from "styled-components";
import BaseText from "../Typography/BaseText";
import { BWIntent } from "../BWIntent";

const TagText = styled(BaseText)`
  display: inline-block;
  padding: 2px 15px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: ${({ isSimple }) => (!isSimple ? "normal" : "bold")};
  color: ${({ isSimple, textIntent, intent }) =>
    !isSimple
      ? BWIntent.values.colors[textIntent || intent]
      : BWIntent.values.backgroundColors[intent]};
  background-color: ${({ isSimple, intent }) =>
    !isSimple ? BWIntent.values.backgroundColors[intent] : null};
  border-radius: 12px;
`;

TagText.propTypes = {
  intent: PropTypes.string,
  textIntent: PropTypes.string,
  isSimple: PropTypes.bool,
  width: PropTypes.string,
};

TagText.defaultProps = {
  intent: BWIntent.NONE,
};

export default TagText;
