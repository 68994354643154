import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _pickBy from "lodash/pickBy";
import _uniq from "lodash/uniq";

import propTypes from "../../../../../constants/propTypes";
import Shipment from "../../../../../models/Shipment";
import BulkEditShipmentForm from "./BulkEditShipmentForm";
import {
  initDataComponent,
  setReload,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { findItemsInResources } from "../../../../../utils/bluechipUtils";

const dataComponentId = "EditShipmentDC";

const shipmentInitialValues = {
  trackingNumber: undefined,
  receivedBy: undefined,
  estimatedShipDate: undefined,
  estimatedDeliveryDate: undefined,
  actualShipDate: undefined,
  actualDeliveryDate: undefined,
  notes: "",
  approvedToPay: false,
};

const dateFields = [
  "estimatedDeliveryDate",
  "actualDeliveryDate",
  "actualShipDate",
  "estimatedShipDate",
];

const getShipmentsValues = (shipments, attribute) => {
  return shipments.map(shipment =>
    dateFields.includes(attribute)
      ? new Date(shipment[attribute]).toDateString()
      : shipment[attribute]
  );
};

const generateInitialValues = shipments => {
  return Object.keys(shipmentInitialValues).reduce((result, attribute) => {
    const attributeValues = _uniq(getShipmentsValues(shipments, attribute));

    result[attribute] =
      attributeValues.length === 1
        ? shipments[0][attribute]
        : shipmentInitialValues[attributeValues];
    return result;
  }, {});
};

export class BulkEditShipmentContainer extends Component {
  state = { loading: false };

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, Shipment, [], "shipments");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      listDataComponent,
      setReload,
      closeModalDialog,
    } = this.props;
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload("SpecShipmentsGrid", true);
        setReload(listDataComponent.dataComponentId, true);
        this.setState({ loading: false });
      },
      onError: error => {
        handleRequestError(error, this.state.formikActions);
        this.setState({ loading: false });
      },
    });
  }

  editShipment = (newValues, formikActions) => {
    const { performUpdateRequest, selectedIds } = this.props;
    const filteredValues = _pickBy(
      newValues,
      (value, key) => shipmentInitialValues[key] !== value
    );
    const shipments = selectedIds.map(id => ({ id, ...filteredValues }));
    performUpdateRequest(dataComponentId, shipments);
    this.setState({ formikActions, loading: true });
  };

  render() {
    if (!this.props.shipments) return null;
    const initialValues = generateInitialValues(this.props.shipments);
    return (
      <BulkEditShipmentForm
        loading={this.state.loading}
        initialValues={initialValues}
        onSubmit={this.editShipment}
      />
    );
  }
}

BulkEditShipmentContainer.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  shipments: PropTypes.arrayOf(propTypes.shipment),
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  setReload: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  listDataComponent: propTypes.dataComponent,
};

export const mapStateToProps = (
  state,
  { selectedIds, dataComponent: listDataComponent }
) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const shipments = findItemsInResources(listDataComponent, state, selectedIds);
  return {
    shipments,
    dataComponent,
    listDataComponent,
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performUpdateRequest,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkEditShipmentContainer);
