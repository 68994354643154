import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import shallow from "zustand/shallow";
import styled from "styled-components";

import SpecsList from "./SpecsList";
import { ActionButtons } from "../../../../components/forms";
import { inputs } from "../../../inputs/inputConfigs";
import propTypes from "../../../../constants/propTypes";
import { dataComponentId } from "./CopyContainer";
import { getProjectName } from "../ImportSpecs/ImportForm";
import UpdateSpecs from "../ImportSpecs/UpdateSpecs";
import useErrorImportStore from "../../../hooks/useErrorImportStore";
import { useWithSelectedRowContext } from "../../../ui/BWGrid/withSelectedRows";
import { usePrevious } from "../../../hooks/usePrevious";

const { InputComponent, ...selectProps } = inputs.project;

const FormWrapper = styled.div`
  padding: 24px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 18px;
`;

const SelectWrapper = styled.div`
  width: 60%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

const isCopyDisabled = (invalidSelectedRowIds, selectedIds, targetProject) =>
  Boolean(
    invalidSelectedRowIds.length || isEmpty(selectedIds) || !targetProject?.id
  );

const CopyForm = ({
  targetProject,
  onSetTargetProject,
  onCopy,
  setSpecs,
  specs,
  projectCurrencies,
  specCategories,
  areas,
  vendors,
}) => {
  const [displayUpdateSpecs, setDisplayUpdateSpecs] = useState(false);

  const [, errorActions] = useErrorImportStore(
    state => [
      state.displayValidRows,
      state.actions,
      state.localError,
      state.importError,
    ],
    shallow
  );

  const { selectedIds, selectAll } = useWithSelectedRowContext();
  const invalidRows = errorActions.getInvalidRows();
  const invalidSelectedRowIds = invalidRows.filter(id =>
    selectedIds.includes(id)
  );

  useEffect(() => {
    errorActions.validateLocalRows(specs);
  }, [specs, errorActions]);

  const prevTargetProjectId = usePrevious(targetProject?.id);
  useEffect(() => {
    if (prevTargetProjectId != targetProject?.id) {
      selectAll(specs, specs.length);
    }
  }, [prevTargetProjectId, selectAll, specs, targetProject]);

  if (displayUpdateSpecs) {
    return (
      <UpdateSpecs
        rows={specs}
        setRows={setSpecs}
        setDisplayUpdateSpecs={setDisplayUpdateSpecs}
        categories={specCategories}
        areas={areas}
        vendors={vendors}
        projectCurrencies={projectCurrencies}
      />
    );
  }
  return (
    <Wrapper>
      <FormWrapper>
        <SelectWrapper>
          <InputComponent
            {...selectProps}
            name="targetProject"
            isAutocomplete
            isSingleAutocomplete
            onChange={evt => {
              onSetTargetProject(evt.target.value || null);
            }}
            submitData
            value={targetProject}
            helperText={"Please select a project before selecting an area"}
            labelProperty={getProjectName}
            apiFilterLabelProperty="name"
          />
        </SelectWrapper>
      </FormWrapper>
      <SpecsList
        specs={specs}
        setSpecs={setSpecs}
        setDisplayUpdateSpecs={setDisplayUpdateSpecs}
        projectCurrencies={projectCurrencies}
        specCategories={specCategories}
        showSelectionColumn={!!targetProject}
      />
      <ActionButtons
        onSend={() => onCopy(targetProject)}
        sendButtonText="COPY"
        isModal={true}
        listeners={[dataComponentId]}
        additionalProps={{
          send: {
            disabled: isCopyDisabled(
              invalidSelectedRowIds,
              selectedIds,
              targetProject
            ),
          },
        }}
      />
    </Wrapper>
  );
};
CopyForm.propTypes = {
  defaultAreaId: PropTypes.string,
  targetProject: PropTypes.shape({}),
  onSetTargetProject: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  specs: PropTypes.arrayOf(propTypes.spec),
  setSpecs: PropTypes.func.isRequired,
  projectCurrencies: PropTypes.array,
  specCategories: PropTypes.array,
  areas: PropTypes.array,
  vendors: PropTypes.array,
};

export default CopyForm;
