import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "formik";

import SelectWithError from "../../../../../inputs/SelectWithError";
import propTypes from "../../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import useActions from "../../../../../hooks/useActions";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import { destroyDataComponentResource } from "../../../../../../actions/dataComponentActions";
import {
  initPointOfContactComponent,
  POCDataComponentId,
} from "../../../../../../actions/remitAddressAction";
import Option from "./Option";
import MultiValue from "./MultiValue";
import CreateContactButton from "./CreateContactButton";
import useCreateRemitAddressStore from "../../../../../hooks/useCreateRemitAddressStore";

export const PointOfContactSelect = ({ formik, name, onChange, value }) => {
  const vendorId = formik.values.vendorId;
  const stateActions = useCreateRemitAddressStore(state => state.actions);

  const actions = useActions({
    initPointOfContactComponent,
    destroyDataComponentResource,
  });

  useEffect(() => {
    actions.initPointOfContactComponent(vendorId);
    return () => {
      actions.destroyDataComponentResource(
        POCDataComponentId,
        RequestTypes.LIST
      );
    };
  }, [actions, vendorId]);

  const vendorContacts = useV2DatacomponentResources(
    POCDataComponentId,
    [],
    RequestTypes.LIST
  );

  const handleChange = evt => {
    const data = evt.target.value;
    const addActionOption = data.findIndex(item => item.id === "action-add");
    if (addActionOption !== -1) {
      stateActions.setCurrentForm(formik.values);
      return stateActions.openAddressBookForm();
    }
    onChange(evt);
  };

  return (
    <SelectWithError
      name={name}
      label="Point of Contact"
      isAutocomplete
      isAutocompleteMulti
      submitData
      options={vendorContacts}
      onChange={handleChange}
      value={value}
      components={{ MultiValue }}
      getOptionLabel={item => {
        if (item.id === "action-add") {
          return <CreateContactButton />;
        }
        return <Option vendorContact={item} />;
      }}
      additionalOptions={[
        {
          id: "action-add",
          menuItemProps: { useSeparator: true },
        },
      ]}
    />
  );
};

PointOfContactSelect.propTypes = {
  formik: propTypes.formik,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(propTypes.vendorContact),
};

export default connect(PointOfContactSelect);
