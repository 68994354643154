import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

import { closeModalDialog } from "../../../actions/layoutActions";

const StyledButton = styled(Button)`
  padding: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  min-width: auto;
  color: rgba(255, 255, 255, 0.7);
`;

const Wrapper = styled.div`
  z-index: 1;
  height: 40px;
  background-color: #1c3d53;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
`;

export function ControlButtons({ onClose, onCloseModal }) {
  return (
    <Wrapper>
      <StyledButton onClick={onCloseModal || onClose}>
        <Close />
      </StyledButton>
    </Wrapper>
  );
}

ControlButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
};

const mapDispatchToProps = {
  onClose: closeModalDialog,
};

export default connect(null, mapDispatchToProps)(ControlButtons);
