import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import MUICheckbox from "../../../mui/core/Checkbox";
import { useCheckboxManager } from "./CheckboxManager";

export const Checkbox = styled(MUICheckbox)`
  margin-left: 12px;
  align-items: flex-start;
`;

const ItemCheckbox = ({ item }) => {
  const id = item.id;
  const {
    isChecked,
    setCheckStatus,
    registerElement,
    removeElement,
  } = useCheckboxManager();

  useEffect(() => {
    registerElement(id);
    return () => {
      removeElement(id);
    };
  }, [removeElement, registerElement, id]);

  const handleChange = useCallback(
    (_, checked) => {
      setCheckStatus(id, checked);
    },
    [setCheckStatus, id]
  );

  return (
    <Checkbox disableRipple checked={!!isChecked(id)} onChange={handleChange} />
  );
};

ItemCheckbox.propTypes = {
  item: PropTypes.shape({}),
};

export default ItemCheckbox;
