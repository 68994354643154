import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import memoizeOne from "memoize-one";

import ConsultingCompany from "../../../../../models/ConsultingCompany";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import { withLabeledTextOption } from "../../../../../withPORevision";
import propTypes from "../../../../../constants/propTypes";
import {
  genMapDispatchToProps,
  genMapStateToProps,
} from "../contactComponentsUtils";
import DesignerInfo from "./DesignerInfo";

export const dataComponentId = "ProjectDesigner";
import { poContactdataComponentId } from "../../../../../actions/purchaseOrdersContactActions";

export const filterContacts = memoizeOne((projectContacts = []) =>
  projectContacts.filter(
    projectContact => projectContact.memberType === "Designer"
  )
);

const DesignerInfoContainer = ({
  initDataComponent,
  getVendor,
  purchaseOrderContacts,
  vendor,
  vendorId,
  purchaseOrder,
}) => {
  useEffect(() => {
    initDataComponent(
      dataComponentId,
      ConsultingCompany,
      ["location"],
      "consulting-companies",
      false,
      "v2"
    );
  }, [initDataComponent]);

  useEffect(() => {
    if (vendorId) {
      getVendor();
    }
  }, [getVendor, vendorId]);

  return (
    <DesignerInfo
      dataComponentId={dataComponentId}
      disabledOptions
      designer={purchaseOrder.designer || vendor}
      purchaseOrderContacts={purchaseOrderContacts}
      projectContacts={filterContacts(
        _get(purchaseOrder, "project.projectMemberContacts")
      )}
    />
  );
};

DesignerInfoContainer.defaultProps = {
  vendor: {},
  poContactDataComponent: {},
};

DesignerInfoContainer.propTypes = {
  purchaseOrderContacts: PropTypes.arrayOf(propTypes.purchaseOrderContact),
  purchaseOrder: PropTypes.shape({
    id: PropTypes.string,
  }),
  vendor: PropTypes.shape({
    id: PropTypes.string,
  }),
  vendorId: PropTypes.string,
  loading: PropTypes.bool,
  getVendor: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string,
  initDataComponent: PropTypes.func.isRequired,
  poContactDataComponent: PropTypes.object.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { purchaseOrder } = ownProps;
  const vendorId = _get(purchaseOrder, "project.designerId", null);
  return genMapStateToProps({
    state,
    vendorDC: dataComponentId,
    contactsDC: poContactdataComponentId,
    vendorId,
    contactType: "designer",
    purchaseOrderContacts: getBluechipResourcesByType(
      poContactdataComponentId,
      state
    ),
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { purchaseOrder } = ownProps;
  const vendorId = _get(purchaseOrder, "project.designerId", null);
  return genMapDispatchToProps({
    dispatch,
    vendorId,
    vendorDC: dataComponentId,
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLabeledTextOption(DesignerInfoContainer));
