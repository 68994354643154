import { appPageBuilder, redirectTo } from "./utils";
import Billing from "../components/pages/Billing";
import {
  Available,
  Approvals,
  Received,
  Shipping,
} from "../components/pages/Billing";

const AppBilling = appPageBuilder(Billing, "Billing");

export default [
  {
    path: "/billing",
    component: AppBilling,
    exact: false,
    routers: [
      {
        path: "/billing/approvals",
        component: Approvals,
        exact: true,
      },
      {
        path: "/billing/availables",
        component: Available,
        exact: true,
      },
      {
        path: "/billing/received-payments",
        component: Received,
        exact: true,
      },
      {
        path: "/billing/shipping-payments",
        component: Shipping,
        exact: true,
      },
      //default
      {
        path: "/billing",
        component: redirectTo("approvals"),
        exact: true,
      },
    ],
  },
];
