import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import EditSpecDetailDescriptionForm from "./EditSpecDetailDescriptionForm";
import {
  performUpdateRequest,
  performCreateRequest,
} from "../../../../actions/dataComponentActions";
import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import withPORevision from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";

export const dataComponentId = "SpecDetail-SpecDetailsChildren";

export class EditSpecDetailDescriptionContainer extends Component {
  handleSuccess = () => {
    this.props.closeModalDialog();
  };

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  editSpecDetailDescription = ({ id, title, description }, formikActions) => {
    const {
      spec,
      performCreateRequest,
      performUpdateRequest,
      revisionValue,
    } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    if (!id) {
      performCreateRequest(
        dataComponentId,
        {
          specId: spec.id,
          title,
          description,
        },
        params
      );
    } else {
      performUpdateRequest(dataComponentId, id, { title, description }, params);
    }

    this.setState({ formikActions });
  };

  render() {
    const { specDetail, spec } = this.props;
    return (
      <EditSpecDetailDescriptionForm
        {...this.props}
        editSpecDetailDescription={this.editSpecDetailDescription}
        revisionReference={`specs.${spec.id}.specDetails.${_get(
          specDetail,
          "id"
        )}`}
        dataComponentId={dataComponentId}
      />
    );
  }
}

EditSpecDetailDescriptionContainer.propTypes = {
  spec: PropTypes.object,
  specDetail: PropTypes.object,
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  processCreateRequestStatus: PropTypes.func,
  processUpdateRequestStatus: PropTypes.func,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performCreateRequest,
  performUpdateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPORevision(
    EditSpecDetailDescriptionContainer,
    "SpecDetail",
    "purchaseOrder"
  )
);
