import React from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";

import { FormikForm } from "../../../../../../components/forms";
import Form from "./Form";
import propTypes from "../../../../../../constants/propTypes";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import * as RequestTypes from "../../../../../../constants/RequestTypes";

export const getValidationSchema = () => ({
  validate: (values, options) => {
    const schema = BWModels.loadSchema("PurchaseOrder").concat(
      Joi.object().keys({
        shipVendorId: BWModels.Joi.selectFk().when("shipToSite", {
          is: false,
          then: Joi.required(),
        }),
        shipVendorAddressId: BWModels.Joi.selectFk().when("shipToSite", {
          is: false,
          then: Joi.required(),
        }),
      })
    );
    return schema.validate(values, options);
  },
});

const ShipToSiteForm = ({ purchaseOrder, onSubmit }) => {
  const vendorSites = useV2DatacomponentResources(
    "select-vendors-sites",
    [],
    RequestTypes.LIST
  );

  return (
    <FormikForm
      initialValues={purchaseOrder}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      ignoreValidationFieldNames={["vendorId"]}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form
          shipToSite={values.shipToSite}
          setFieldValue={setFieldValue}
          onSubmit={handleSubmit}
          vendorSites={vendorSites.filter(
            ({ vendorId }) => vendorId == values.shipVendorId
          )}
          shipVendorId={values.shipVendorId}
        />
      )}
    </FormikForm>
  );
};

ShipToSiteForm.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  onSubmit: PropTypes.func.isRequired,
};

export default ShipToSiteForm;
