import React from "react";
import { Tooltip } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

export const ItemWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
  padding: 16px 24px;
  border-top: ${({ emptyColumn }) =>
    !emptyColumn && "1px solid rgba(0, 0, 0, 0.12)"};
`;

export const OptionsWrapper = styled.div`
  display: flex;

  ${ItemWrapper} {
    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: 14px;
`;

export const Value = styled.div`
  font-size: 20px;
  font-family: Domine, sans-serif;
  width: ${({ noTitle }) => (noTitle ? "100%" : "auto")};
`;

export const WarningIcon = styled(Warning)`
  vertical-align: middle;
  align-self: center;
  color: #ff9100;
  width: 20px;
  height: 20px;
`;

export const FeeBillingDueDate = ({
  amountOfUnbilledShipments,
  feeBillingDueDate,
}) => {
  if (amountOfUnbilledShipments > 0 && !feeBillingDueDate) {
    return (
      <Tooltip title={`Billing Due Date is missing`} placement="right-start">
        <WarningIcon />
      </Tooltip>
    );
  }

  return feeBillingDueDate;
};
FeeBillingDueDate.propTypes = {
  amountOfUnbilledShipments: PropTypes.number,
  feeBillingDueDate: PropTypes.number,
};
