import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { ListItemText } from "../../../../mui/core";
import BoldText from "../../../../ui/Typography/BoldText";
import RegularText from "../../../../ui/Typography/RegularText";
import propTypes from "../../../../../constants/propTypes";
import Loader from "../../../../ui/Loader";
import { AddressListItem } from "../components";
import { PlaceholderList } from "../VendorInfo/components";
import {
  VendorContainer,
  VendorInfo,
  VendorName,
} from "../VendorInfo/VendorHeader";
import { getAddressName } from "../../../../inputs/inputConfigs/purchaseOrderDetails";

export const getAddress = instance => {
  const address2 = _get(instance, "location.address2", "");
  if (!address2) return _get(instance, "location.address", "");
  return `${_get(instance, "location.address")}, ${address2}`;
};

export const getAddressComplement = instance => {
  const city = _get(instance, "location.city", "");
  const state = _get(instance, "location.state", "");
  const zip = _get(instance, "location.zip", "");
  return `${city}, ${state} ${zip}`;
};

const CurrentShipAddress = ({ shipAddress }) => (
  <AddressListItem>
    <ListItemText
      primary={
        <BoldText as="span">
          <Loader height="20px" width="250px">
            {getAddressName(shipAddress)}
          </Loader>
        </BoldText>
      }
      secondary={
        <Fragment>
          <RegularText as="span">
            <Loader height="20px" width="250px">
              {getAddress(shipAddress)}
            </Loader>
          </RegularText>
          <RegularText as="span">
            <Loader height="20px" width="250px">
              {getAddressComplement(shipAddress)}
            </Loader>
          </RegularText>
        </Fragment>
      }
    />
  </AddressListItem>
);

CurrentShipAddress.propTypes = {
  shipAddress: propTypes.remitAddress,
};

const CurrentAddress = ({
  shipAddressId,
  shipAddress,
  shipVendor,
  shipVendorAddressId,
  shipVendorAddress,
}) => {
  if (shipAddressId) {
    return <CurrentShipAddress shipAddress={shipAddress} />;
  }
  if (shipVendor) {
    return shipVendorAddressId ? (
      <Fragment>
        <VendorInfo vendor={shipVendor} disabledOptions />
        <CurrentShipAddress shipAddress={shipVendorAddress} />
      </Fragment>
    ) : (
      <Fragment>
        <VendorContainer>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <VendorName vendor={shipVendor} disabledOptions />
            <RegularText as="span">TBD</RegularText>
          </div>
        </VendorContainer>
      </Fragment>
    );
  }
  return <PlaceholderList title="No Site Selected" disabledOptions />;
};

CurrentAddress.propTypes = {
  shipAddress: propTypes.remitAddress,
  shipVendor: propTypes.vendor,
  shipVendorAddress: propTypes.remitAddress,
  shipAddressId: PropTypes.string,
  shipVendorAddressId: PropTypes.string,
};

export default CurrentAddress;
