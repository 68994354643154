import {
  performRetrieveListRequest,
  initDataComponent,
} from "./dataComponentActions";

export const filterDCId = "filter-datacomponentId";

export function fetchElementIds(
  projectId,
  filter = {},
  APIOptions = { route: "" }
) {
  return dispatch => {
    dispatch(
      initDataComponent(
        filterDCId,
        APIOptions.model,
        [],
        APIOptions.route,
        false,
        "v2"
      )
    );

    const tableName = APIOptions?.model?.tableName;
    const idField = tableName ? `${tableName}.id` : "id";

    return dispatch(
      performRetrieveListRequest(filterDCId, {
        rootFilters: {
          $where: filter,
        },
        pageSize: -1,
        fields: [idField],
        sort: [],
        params: {
          omitDefaultModifier: true,
        },
      })
    );
  };
}
