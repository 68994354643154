import { parseNulls } from "../../withPagination";

export function pagingToGetParams(pageNumber, pageSize) {
  const params = {};
  if (pageNumber) {
    params.page = pageNumber;
  }
  if (pageSize) {
    params.size = pageSize;
  }
  if (Object.keys(params).length > 0) {
    return JSON.stringify(params);
  }
}

export function sortToGetParam(sort) {
  const sortStr = (sort || []).reduce((s, sortValue) => {
    if (s !== "") s += ",";
    s += `${sortValue.columnName}:${sortValue.direction}${parseNulls(
      sortValue.nulls
    )}`;
    return s;
  }, "");

  if (sortStr !== "") {
    return JSON.stringify(sortStr);
  }
}

export function filtersToGetParams(rootFilters) {
  if (rootFilters) {
    return JSON.stringify(rootFilters);
  }
}
