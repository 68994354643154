import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { openModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";

import OfficesTable from "./OfficesTable";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

export const dataComponentId = "officesGrid";

const defaultLocation = {
  address: undefined,
  address2: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  state: undefined,
};

export const OfficesTableContainer = ({ openModalDialog, dataComponent }) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create Office", "CreateOffice", {}, false);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    ({ location = defaultLocation, ...office }) => {
      openModalDialog(
        `Edit ${office.name}`,
        "EditOffice",
        {
          office: { ...office, location },
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, name }) => {
      openModalDialog(
        `Delete ${name}`,
        "DeleteOffice",
        {
          officeId: id,
          title: `${name}`,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <OfficesTable
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      dataComponent={dataComponent}
    />
  );
};

OfficesTableContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  performRetrieveListRequest,
  initDataComponent,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(OfficesTableContainer),
  ["general-settings-offices"]
);
