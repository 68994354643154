import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import RootRef from "@material-ui/core/RootRef";
import ListItemText from "@material-ui/core/ListItemText";
import AddCircle from "@material-ui/icons/AddCircle";
import styled from "styled-components";

import { CustomListItem } from "./components";
import DraggableList from "./DraggableList";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const StyledAreaRoomList = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
  height: 368px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow-y: auto;
`;

const ActionButtonText = styled(ListItemText)`
  span {
    color: #57abff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
`;

export default class AreaRoomList extends Component {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired,
    ...DraggableList.propTypes,
  };

  render() {
    const { onAdd, onReorder, errors, touched, ...listProps } = this.props;
    return (
      <StyledAreaRoomList>
        <DragDropContext onDragEnd={onReorder(listProps.rooms)}>
          <Droppable droppableId="roomList">
            {provided => (
              <RootRef rootRef={provided.innerRef}>
                <List {...provided.droppableProps}>
                  <DraggableList
                    {...listProps}
                    errors={errors}
                    touched={touched}
                  />
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
        <CustomListItem onClick={() => onAdd()} button>
          <ActionButtonText>
            <AddCircle />
            ADD GUESTROOM
          </ActionButtonText>
        </CustomListItem>
      </StyledAreaRoomList>
    );
  }
}
