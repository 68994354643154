import { useEffect } from "react";
import { QUEUE_SUCCESS } from "../../../constants/queue";
export const dataComponentId = "SpecsGrid";

export const useContainerEffects = (
  { importSpecQueue, projectId },
  { setAutoSaveComponentId, setReload, initRFQListFilter, initPOListFilter }
) => {
  useEffect(() => {
    initPOListFilter(projectId);
    initRFQListFilter(projectId);
  }, [projectId, initPOListFilter, initRFQListFilter]);

  useEffect(() => {
    setAutoSaveComponentId(dataComponentId);
  }, [setAutoSaveComponentId]);

  useEffect(() => {
    if (importSpecQueue && importSpecQueue.status === QUEUE_SUCCESS) {
      setReload(dataComponentId, true);
    }
  }, [importSpecQueue, setReload]);
};

export const isAnyShipmentApprovedToPay = shipments => {
  if (!shipments) return false;

  return shipments.some(shipment => shipment.approvedToPay);
};
