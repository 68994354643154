import { useCallback } from "react";
import _noop from "lodash/noop";

import {
  debouncedFilteringChangeHandler,
  getPage,
  getSort,
  retrieveList,
} from "../../BWGrid/gridAPIFunctions";
import { createReorder, CreateSetSort } from "../../BWGrid/BWGridAPI";

export default (
  {
    setReload,
    performRetrieveListRequest,
    setSort,
    setPage,
    setPageSize,
    setSelection,
    onReorder,
  },
  { setShouldReload, setSelectedFilters },
  { context, dataComponent, pageSize, rows, pageNumber, generateSelection }
) => {
  const { dataComponentId } = dataComponent;

  const handleRetrieveList = useCallback(
    (apiFilters, selectedFilters) => {
      retrieveList(apiFilters, selectedFilters, dataComponent, {
        getSort: getSort(context, dataComponent),
        getPage: getPage(context, dataComponent),
        setReload,
        setShouldReload,
        pageSize,
        performRetrieveListRequest,
      });
    },
    [
      dataComponent,
      context,
      setReload,
      pageSize,
      performRetrieveListRequest,
      setShouldReload,
    ]
  );

  const handleSetSort = useCallback(
    CreateSetSort(context, dataComponent, setSort),
    [context, dataComponent, setSort]
  );

  const handleSetPage = useCallback(
    pageNumber => {
      context.setPage(pageNumber, dataComponentId);
      setPage(pageNumber);
      setShouldReload(true);
    },
    [setPage, context, dataComponentId, setShouldReload]
  );

  const handleSetPageSize = useCallback(
    pageSize => {
      context.setPageSize(pageSize, dataComponentId);
      setPageSize(pageSize);
      handleSetPage(0);
      setShouldReload(true);
    },
    [context, dataComponentId, setPageSize, handleSetPage, setShouldReload]
  );

  const handleFiltersChange = useCallback(
    (changes, _, avoidSetPage) => {
      debouncedFilteringChangeHandler(changes, {
        setSelectedFilters,
        onSetPage: avoidSetPage ? _noop : handleSetPage,
        setSelection: dataComponent.model ? setSelection : _noop,
      });
      return debouncedFilteringChangeHandler;
    },
    [handleSetPage, setSelection, setSelectedFilters, dataComponent]
  );

  const handleReorder = useCallback(
    createReorder(onReorder, rows, pageNumber, pageSize),
    [onReorder, rows, pageNumber, pageSize]
  );

  const handleSelection = useCallback(
    selectedIds => setSelection(generateSelection(selectedIds)),
    [generateSelection, setSelection]
  );

  return {
    onRetrieveList: handleRetrieveList,
    onSetSort: handleSetSort,
    onSetPage: handleSetPage,
    onSetPageSize: handleSetPageSize,
    onFiltersChange: handleFiltersChange,
    onReorder: handleReorder,
    onSelection: handleSelection,
  };
};
