import styled from "styled-components";

import SrcMultiValue from "../../../../../inputs/SelectWithError/AutocompleteSelect/MultiValue";

import { Column } from "./Option";

const MultiValue = styled(SrcMultiValue)`
  background-color: #d8d8d8;
  svg {
    color: rgba(0, 0, 0, 0.38);
  }
  ${Column} {
    &.main-column {
      width: auto;
      max-width: unset;
      min-width: unset;
    }
    &:not(.main-column) {
      display: none;
    }
  }
`;

export default MultiValue;
