import styled from "styled-components";
import BaseText from "./BaseText";

const InputText = styled(BaseText)`
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: hsl(0, 0%, 20%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;
InputText.displayName = "InputText";

export default InputText;
