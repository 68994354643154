import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BaseTag from "../Tags/BaseTag";

import ContactCard from "./ContactCard";
import { initials } from "./ContactCard/ContactCard";

const Person = styled(({ person, personType, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { firstName, lastName } = person;
  const label = `${firstName} ${lastName}`;
  return (
    <Fragment>
      <BaseTag
        tabIndex="0"
        role="button"
        {...props}
        title={label}
        aria-label={label}
        onClick={handleClick}
      >
        {initials(firstName, lastName)}
      </BaseTag>
      <ContactCard
        person={person}
        onClose={handleClose}
        open={!!anchorEl}
        anchorEl={anchorEl}
        personType={personType}
      />
    </Fragment>
  );
})(props => ({ ...props.theme.tag.base, ...props.theme.tag.primary }));

Person.propTypes = {
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  personType: PropTypes.string,
};

Person.defaultProps = {
  person: { firstName: "", lastName: "" },
};

export default Person;
