import React from "react";
import PropTypes from "prop-types";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import DeleteFileButton from "./DeleteFileButton";
import { GetApp } from "@material-ui/icons";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";

function ActionsColumn({ row, onDelete, activeDelete }) {
  return (
    <DeleteFileButton
      onSubmit={onDelete}
      fileWrapper={row}
      isActive={activeDelete === row.id}
      isLoading={activeDelete}
    />
  );
}

ActionsColumn.propTypes = {
  row: PropTypes.shape({}),
  onDelete: PropTypes.func,
  activeDelete: PropTypes.string,
};

export function actionIconsBuilder(onDeleteClick, activeDelete) {
  const ActionIconsFormatter = ({ row }) => {
    return (
      <ActionsColumn
        row={row}
        onDelete={onDeleteClick}
        activeDelete={activeDelete}
      />
    );
  };

  ActionIconsFormatter.propTypes = {
    row: PropTypes.shape({}),
  };

  const ActionIconsProvider = props => {
    return (
      <DataTypeProvider
        formatterComponent={ActionIconsFormatter}
        {...props}
        for={["actionIcons"]}
      />
    );
  };
  return ActionIconsProvider;
}

export const DownloadIcon = styled(GetApp)`
  cursor: pointer;
  color: black;
`;

export const Link = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export function downloadFileBuilder() {
  const DownloadFileFormatter = ({ row }) => {
    return (
      <Link href={row.file && row.file.s3SignedUrl} download target="_blank">
        <IconButton style={{ padding: "2px" }}>
          <DownloadIcon />
        </IconButton>
      </Link>
    );
  };

  DownloadFileFormatter.propTypes = {
    row: PropTypes.shape({}),
  };

  const ActionIconsProvider = props => {
    return (
      <DataTypeProvider
        formatterComponent={DownloadFileFormatter}
        {...props}
        for={["downloadFile"]}
      />
    );
  };
  return ActionIconsProvider;
}
