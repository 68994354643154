import React from "react";
import _get from "lodash/get";
import styled from "styled-components";

import LinesEllipsis from "../../../ui/LinesEllipsis";
import propTypes from "../../../../constants/propTypes";

const Text = styled.span`
  font-weight: bold;
`;

const CustomNumberColumn = ({ spec }) => (
  <LinesEllipsis width="73px">
    <Text>{_get(spec, "customNumber")}</Text>
  </LinesEllipsis>
);

CustomNumberColumn.propTypes = {
  spec: propTypes.spec,
};

export default CustomNumberColumn;
