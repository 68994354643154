import React from "react";

import { inputs } from "../../../../components/inputs/inputConfigs";
import FilePreview from "./FilePreview";

export default () => {
  return {
    groups: [
      {
        items: [
          {
            element: <FilePreview />,
            grid: { xs: 4 },
          },
          {
            input: inputs.specDetailPreview,
          },
        ],
      },
    ],
  };
};
