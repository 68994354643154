import React, { Component } from "react";
import PropTypes from "prop-types";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import Paper from "../../mui/core/Paper";
import AddCircle from "@material-ui/icons/AddCircle";
import LocationCombined from "../../ui/LocationCombined";
import PointOfContactColumn from "./PointOfContactColumn";

export default class RemitAddressesPage extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
    openModalDialog: PropTypes.func,
    isModalOpen: PropTypes.bool,
    push: PropTypes.func.isRequired,
    vendorId: PropTypes.string.isRequired,
    refreshRemitAddressTable: PropTypes.func.isRequired,
  };

  _modalHasClosed = prevProps => {
    return !this.props.isModalOpen && prevProps.isModalOpen;
  };

  _handleModalClosed = () => {
    this.props.refreshRemitAddressTable();
  };

  componentDidUpdate(prevProps) {
    if (this._modalHasClosed(prevProps)) {
      this._handleModalClosed();
    }
  }

  handleAddButtonClick = () => {
    const { vendorId, vendor } = this.props;
    this.props.openModalDialog(
      "Create Remit Address",
      "CreateRemitAddressContainer",
      { vendorId, titleModal: "Create Remit Address", vendor },
      false
    );
  };

  handleEditOptionClick = remitAddress => {
    const { vendor } = this.props;
    this.props.openModalDialog(
      "Edit Remit Address",
      "EditRemitAddressContainer",
      { remitAddress, titleModal: "Edit Remit Address", vendor },
      false
    );
  };

  handleDeleteOptionClick = ({ id, name: title }) => {
    this.props.openModalDialog(
      `Delete ${title}`,
      "DeleteRemitAddress",
      { id, title },
      true,
      false
    );
  };

  _getRootFilters() {
    const { vendorId } = this.props;
    let rootFilters = {};

    if (vendorId) {
      rootFilters = {
        $where: {
          vendorId: vendorId,
        },
      };
    }

    return rootFilters;
  }

  render() {
    return (
      <Paper>
        <BWGridAPI
          {...this.props}
          includes={["location", "pointOfContacts.contact"]}
          rootFilters={this._getRootFilters()}
        >
          <GridHeader
            headerText="Remit Addresses"
            actions={[
              {
                text: "ADD ADDRESS",
                icon: <AddCircle />,
                handler: this.handleAddButtonClick,
                disableIfProjectClosed: true,
              },
            ]}
          />
          <GridTable
            columns={["label", "name", "location.address", "pointOfContact"]}
            columnOptions={{
              label: { bold: true, filter: true },
              name: { bold: true, filter: true },
              "location.address": {
                title: "Address",
                render: LocationCombined,
              },
              pointOfContact: {
                sortingEnabled: false,
                fill: true,
                render: PointOfContactColumn,
              },
            }}
            rowMenu={[
              { text: "Edit", onClick: this.handleEditOptionClick },
              { separator: true },
              { text: "Delete", onClick: this.handleDeleteOptionClick },
            ]}
          />
        </BWGridAPI>
      </Paper>
    );
  }
}
