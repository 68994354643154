import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BWPaper } from "../../../ui/BWPaper";
import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import GridHeader from "../../../ui/BWGrid/GridHeader";
import formatter from "../../../../utils/currencyFormatter";

export const Paper = styled(BWPaper)`
  ${GridHeader} {
    padding: 24px;
  }
  table {
    min-width: 829px !important;
  }
`;

const Wrapper = styled.div`
  ${({ wasUpdatedInRevision }) =>
    wasUpdatedInRevision &&
    `
  font-style: italic;
  font-weight: bold;
  &:before {
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    color: #ff9100;
    top: 2px;
    left: 0;
    overflow: visible;
  }
  `}
`;

export const ExtendedPrice = ({ totalPrice }) =>
  totalPrice && formatter.format(totalPrice);

export const ExtendedPriceWithoutOverage = ({ totalPriceWithoutOverage }) =>
  totalPriceWithoutOverage && formatter.format(totalPriceWithoutOverage);

export const ColumnWrapper = ({ id, field, value }) => {
  const { isActive, isUpdated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);

  const wasUpdatedInRevision = useMemo(() => {
    if (!isActive || !id) return false;

    const ownReference = `${reference}.${id}`;
    return isUpdated(ownReference, field);
  }, [id, reference, field, isActive, isUpdated]);

  return <Wrapper wasUpdatedInRevision={wasUpdatedInRevision}>{value}</Wrapper>;
};

ColumnWrapper.propTypes = {
  id: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const SelectedPrice = ({ id, price }) =>
  price && (
    <ColumnWrapper id={id} field="priceCents" value={formatter.format(price)} />
  );
