// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Note model belongsTo relations 1`] = `
Array [
  "Project",
]
`;

exports[`Note model hasMany relations 1`] = `
Array [
  "PurchaseOrder",
  "Shipment",
  "Spec",
  "Tag",
  "User",
  "Invoice",
]
`;
