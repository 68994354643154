import React, { Component } from "react";
import PropTypes from "prop-types";
import { GridLocalSection } from "../../../layout/GridLocalSection";
import _get from "lodash.get";

export const renderContacts = row => {
  const contacts = _get(row, "contacts");

  if (!contacts || contacts.length === 0) {
    return "";
  }

  const firstContact = contacts[0].contact;

  if (contacts.length === 1) {
    return `${firstContact.firstName} ${firstContact.lastName}`;
  }

  return `${firstContact.firstName} ${
    firstContact.lastName
  }, ${contacts.length - 1}+`;
};
export default class ShipToSiteLayout extends Component {
  static propTypes = {
    onOpenAddModal: PropTypes.func.isRequired,
    onOpenEditModal: PropTypes.func.isRequired,
    onOpenDeleteModal: PropTypes.func.isRequired,
    rows: PropTypes.array,
  };

  addButtonText = "ADD SITE";
  gridHeaderText = "Ship to Sites";

  render() {
    const {
      rows,
      onOpenAddModal,
      onOpenEditModal,
      onOpenDeleteModal,
    } = this.props;

    const columns = [
      "name",
      "location.address",
      "location.city",
      "location.state",
      "location.zip",
      "contacts",
    ];
    const columnsOptions = {
      name: { bold: true, width: "143px", filter: true },
      "location.address": {
        title: "Address",
        width: "370px",
      },
      "location.city": {
        title: "City",
        filter: true,
      },
      "location.state": {
        title: "State",
        align: "right",
        width: "85px",
        filter: true,
      },
      "location.zip": {
        title: "ZIP Code",
        align: "right",
      },
      contacts: {
        title: "Contacts",
        align: "left",
        render: row => renderContacts(row),
      },
    };
    return (
      <GridLocalSection
        id="vendor-ship-to-sites"
        columns={columns}
        columnsOptions={columnsOptions}
        sorting={[{ columnName: "name", direction: "asc" }]}
        rowMenu={[
          { text: "Edit", onClick: onOpenEditModal },
          { separator: true },
          { text: "Delete", onClick: onOpenDeleteModal },
        ]}
        gridConfig={{
          pageSize: 10,
          totalRows: rows.length,
          sorting: [{ columnName: "name", direction: "asc" }],
        }}
        rows={rows}
        addButtonText={this.addButtonText}
        gridHeaderText={this.gridHeaderText}
        addClick={onOpenAddModal}
        hideFooter={false}
      />
    );
  }
}
