import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";
import { dataComponentId } from "../BrandsTable/FlagDetail/FlagDetailContainer";

export class DeleteFlagContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog, setReload, brandId } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(`${dataComponentId}-${brandId}`, true);
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDeleteFlag = () => {
    const { flagId, performDeleteRequest, brandId } = this.props;
    performDeleteRequest(`${dataComponentId}-${brandId}`, flagId);
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation title={title} onDelete={this.handleDeleteFlag} />
    );
  }
}

DeleteFlagContainer.propTypes = {
  title: PropTypes.string.isRequired,
  flagId: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { brandId }) => {
  const dataComponent = getDataComponent(
    `${dataComponentId}-${brandId}`,
    state
  );
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteFlagContainer);
