import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Creatable from "react-select";
import { useSelector } from "react-redux";

import {
  getHelperText,
  useLabeledText,
} from "../../../inputs/TextInputWithError";
import useActions from "../../../hooks/useActions";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import Email from "../../../../models/Email";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import {
  getComponents,
  getInputLabelProps,
} from "../../../inputs/SelectWithError/AutocompleteSelect/AutocompleteSelect";
import InputLoader from "../../../ui/Loader/InputLoader";
import { DisabledInput } from "./components";
import { getRequiredStyle } from "../../../inputs/SelectWithError/SingleAutoCompleteSelect/components";
import { buildSaveNewObj, handleKeyDown } from "./creatableHandlers";
import { removeControlCharacters } from "../../../inputs/utils";

const StyledCreatable = styled(Creatable)`
  ${props => getRequiredStyle(props)}
`;

const getFormatValue = (value, isMulti) => {
  if (isMulti) {
    return value
      ? value.split(",").map(item => {
          const email = item.trim();
          return { value: email, label: email };
        })
      : [];
  }

  return value ? { value: value, label: value } : "";
};

const getValueToBeSent = (newValue, isMulti) => {
  if (isMulti) {
    return newValue ? newValue.map(item => item.value).join(", ") : "";
  }

  return newValue ? newValue.value : "";
};

export const processSaveRequest = (
  preDataComponent,
  dataComponent,
  { showSnackNotificationAction, value }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction(`New email created.`);
    },
    onError: () => {
      value.pop();
      showSnackNotificationAction(`Error while creating new email.`);
    },
  });
};

const handleCurrentValueChange = ({
  setIsDebouncing,
  currentValue,
  actions,
  dataComponentId,
}) => () => {
  setIsDebouncing(true);
  const loadEmailsTimer = setTimeout(() => {
    actions.performRetrieveListRequest(dataComponentId, {
      sort: [{ columnName: "email", direction: "asc" }],
      pageSize: 10,
      rootFilters: {
        $where: {
          email: {
            $ilike: `${currentValue}%`,
          },
        },
      },
    });
    setIsDebouncing(false);
  }, 500);
  return () => clearTimeout(loadEmailsTimer);
};

const CreatableEmailInput = ({
  onChange,
  name,
  value,
  inputProps,
  dataComponentId,
  isMulti,
  validate,
  ...props
}) => {
  const isLabeledText = useLabeledText(false);
  const [currentValue, setCurrentValue] = useState("");
  const [preDataComponent, setPreDataComponent] = useState(null);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const actions = useActions({
    initDataComponent,
    performRetrieveListRequest,
    performCreateRequest,
    showSnackNotificationAction,
  });

  const state = useSelector(state => state);

  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      Email,
      [],
      "emails",
      false,
      "v2"
    );
    actions.performRetrieveListRequest(dataComponentId, {
      sort: [{ columnName: "email", direction: "asc" }],
      pageSize: 10,
    });
  }, [dataComponentId, actions]);

  const dataComponent = getDataComponent(dataComponentId, state);

  const emailOptions = useV2DatacomponentResources(
    dataComponentId,
    [],
    REQUEST_TYPES.LIST
  ).map(item => ({
    value: item.email,
    label: item.email,
  }));

  const isLoading = dataComponent?.requestState?.list?.loading || isDebouncing;

  useEffect(
    handleCurrentValueChange({
      setIsDebouncing,
      currentValue,
      actions,
      dataComponentId,
    }),
    [currentValue, actions, dataComponentId]
  );
  useEffect(() => setPreDataComponent(dataComponent), [dataComponent]);
  useEffect(() => {
    processSaveRequest(preDataComponent, dataComponent, {
      showSnackNotificationAction: actions.showSnackNotificationAction,
      value: value,
      isMulti,
      inputValue: currentValue,
    });
  }, [
    actions.showSnackNotificationAction,
    currentValue,
    dataComponent,
    isMulti,
    preDataComponent,
    value,
  ]);

  const components = useMemo(() => getComponents(props.label, {}), [
    props.label,
  ]);

  const handleChange = newValue => {
    const valueText = getValueToBeSent(newValue, isMulti);

    const event = {
      type: "click",
      target: { name, value: valueText || "" },
    };
    onChange(event);
  };

  const handleInputChange = value => {
    setCurrentValue(removeControlCharacters(value, true));
  };

  const helperText = getHelperText(
    props.ignoreCaption,
    props.helperText,
    props.error,
    props.errorMessage
  );

  let formatValue = getFormatValue(value, isMulti);

  if (isLabeledText) {
    return (
      <DisabledInput
        label={props.label}
        value={value}
        inputProps={inputProps}
      />
    );
  }

  const saveNewObj = buildSaveNewObj({
    dataComponentId,
    performCreateRequest: actions.performCreateRequest,
    labelProperty: "email",
  });

  return (
    <InputLoader>
      <StyledCreatable
        isClearable
        isMulti={isMulti}
        name={name}
        components={components}
        onChange={handleChange}
        options={emailOptions}
        value={formatValue}
        isLoading={isLoading}
        onInputChange={handleInputChange}
        inputValue={currentValue}
        onKeyDown={event =>
          handleKeyDown({
            name,
            event,
            value: formatValue,
            isMulti,
            inputValue: currentValue,
            options: emailOptions,
            saveNewObj,
            handleChange,
            handleInputChange,
            validate,
            showSnackNotificationAction: actions.showSnackNotificationAction,
          })
        }
        textFieldProps={getInputLabelProps(formatValue, {
          helperText,
          ...props,
        })}
        {...props}
      />
    </InputLoader>
  );
};

CreatableEmailInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  dataComponentId: PropTypes.string,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default React.memo(CreatableEmailInput);
