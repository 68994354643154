import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { DeleteButton, DeleteButtonWrapper, Button, Label } from "./components";
import {
  setDeletedBidGroup,
  undoDeletedBidGroup,
} from "../../../../../actions/bidGroupActions";
import { BWIntent } from "../../../../ui/BWIntent";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

export const DeleteActions = ({
  id,
  deletedRows,
  undoDeletedBidGroup,
  setDeletedBidGroup,
  disabled,
}) => {
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);

  const isProjectClosed = useIsProjectClosed();

  if (isConfirmationVisible) {
    return (
      <DeleteButtonWrapper>
        <Label>Are you sure?</Label>
        <Button
          text="Delete"
          intent={BWIntent.DANGER}
          onClick={e => {
            e.stopPropagation();
            setConfirmationVisible(false);
            setDeletedBidGroup(id);
          }}
        />
        <Button
          text="Cancel"
          intent={BWIntent.ACCENT}
          onClick={e => {
            e.stopPropagation();
            setConfirmationVisible(false);
          }}
        />
      </DeleteButtonWrapper>
    );
  }

  if (deletedRows.includes(id)) {
    return (
      <DeleteButtonWrapper>
        <Label>Deleted</Label>
        <Button
          text="Undo"
          onClick={e => {
            e.stopPropagation();
            undoDeletedBidGroup(id);
          }}
        />
      </DeleteButtonWrapper>
    );
  }
  return (
    <DeleteButton
      id={id}
      onClick={() => setConfirmationVisible(true)}
      disabled={disabled || isProjectClosed}
    />
  );
};

DeleteActions.propTypes = {
  id: PropTypes.string,
  deletedRows: PropTypes.arrayOf(PropTypes.string),
  undoDeletedBidGroup: PropTypes.func.isRequired,
  setDeletedBidGroup: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export const mapDispatchToProps = {
  setDeletedBidGroup,
  undoDeletedBidGroup,
};

export const mapStateToProps = state => {
  return {
    deletedRows: state.bidGroups.deletedBidGroups,
  };
};

export const ConnectedDeleteActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteActions);

const getComponent = (id, onToggleAddBidGroup, disabled) =>
  id ? (
    <ConnectedDeleteActions id={id} disabled={disabled} />
  ) : (
    <DeleteButton id={id} onClick={onToggleAddBidGroup} disabled={disabled} />
  );

export const hasAwardedSpec = specs => {
  if (!specs) return false;
  return specs.some(spec => spec.awardedBidGroupVendorSpecQuoteId);
};

export const renderActions = onToggleAddBidGroup => ({ id, specs }) =>
  getComponent(id, onToggleAddBidGroup, hasAwardedSpec(specs));
