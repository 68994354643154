import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Paper from "../../mui/core/Paper";
import { Content } from "../../layout/Content";
import ActionButtons from "../../forms/ActionButtons";
import GeneralInformationSection from "./GeneralInformationSection";
import BWModels from "benjaminwest-models";
import FormikForm from "../../forms/FormikForm";
import propTypes from "../../../constants/propTypes";
import { NavigationArea } from "../../layout/DetailAndCycle";
import useUserRole from "../../hooks/useUserRole";
import { isSuperAdmin } from "../../../utils/roleUtils";
import { dataComponentId as createDataComponentId } from "./ClientCreateContainer";

export const getClientSchema = (userRole, isUpdate) => {
  const schema = BWModels.loadSchema("Client");
  if (isSuperAdmin(userRole) && !isUpdate) {
    return schema.keys({
      scopeId: BWModels.Joi.selectFk().required(),
    });
  }
  return schema;
};

const initialValues = {
  name: "",
  federalId: "",
  notes: "",
  location: {
    address: undefined,
    address2: undefined,
    city: undefined,
    zip: undefined,
    country: undefined,
    state: undefined,
  },
};

const ClientForm = ({
  client,
  onSubmit,
  goBack,
  isUpdate,
  sendButtonText,
  isLoading,
}) => {
  const userRole = useUserRole();
  const ignoreValidationFieldNames =
    userRole?.name !== "Super Admin" ? ["scopeId"] : [];
  return (
    <Content>
      {isUpdate && <NavigationArea hideActions />}
      <FormikForm
        onSubmit={onSubmit}
        validationSchema={getClientSchema(userRole, isUpdate)}
        initialValues={client || initialValues}
        autoSave={isUpdate}
        enableReinitialize={isUpdate}
        ignoreCache={isUpdate}
        ignoreValidationFieldNames={ignoreValidationFieldNames}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <Paper>
              <GeneralInformationSection
                values={values}
                clientSchema={getClientSchema(userRole, isUpdate)}
                errors={errors}
                isUpdate={isUpdate}
                userRole={userRole}
                {...formikProps}
              />
            </Paper>

            {!isUpdate && (
              <ActionButtons
                className={"CancelButton"}
                goBack={goBack}
                onSend={handleSubmit}
                sendButtonText={sendButtonText}
                listeners={[createDataComponentId]}
                additionalProps={{
                  send: {
                    isLoading,
                  },
                  cancel: {
                    disabled: isLoading,
                  },
                }}
              />
            )}
          </Fragment>
        )}
      </FormikForm>
    </Content>
  );
};

ClientForm.propTypes = {
  goBack: PropTypes.func,
  client: propTypes.client,
  isUpdate: PropTypes.bool,
  sendButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ClientForm;
