import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import noteGeneralField from "./noteFields";
import noteEmailFields from "./noteEmailFields";
import notePhoneCallFields from "./notePhoneCallFields";
import noteMeetingFields from "./noteMeetingFields";
import noteFollowUpFields from "./noteFollowUpFields";
import noteDeliveryFields from "./noteDeliveryFields";
import noteShippingFields from "./noteShippingFields";

import { TitleContainer } from "../../../layout/AppLayout/ModalDialog/ModalTitle";
import { NoteCategoriesContainer, StyledTitle, Label } from "./components";
import Select from "../../../mui/core/Select";
import { FormikForm } from "../../../forms";
import NoteForm from "./NoteForm";
import { customValidator } from "./EmailInput";

export const Container = styled.div`
  height: calc(100% - 101px);
  overflow: auto;
`;

const TrackingTypeLabel = styled(Label)`
  margin-left: 24px;
`;

export const TrackingTypeDropdown = ({ value, onChange }) => (
  <React.Fragment>
    <TrackingTypeLabel>Type:</TrackingTypeLabel>
    <Select value={value} onChange={({ target: { value } }) => onChange(value)}>
      {getTrackingTypes().map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </React.Fragment>
);

TrackingTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const getCategories = () => {
  const noteSchema = BWModels.loadSchema("Note");
  return noteSchema.__utils.categories;
};

const getInitialCategory = initialCategory => {
  if (initialCategory) return initialCategory;
  return getCategories()[1].id;
};

export const CategoryDropdown = ({ value, onChange }) => (
  <Select value={value} onChange={({ target: { value } }) => onChange(value)}>
    {getCategories().map(({ id, name }) => (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    ))}
  </Select>
);

CategoryDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const getTrackingTypes = () => {
  const noteSchema = BWModels.loadSchema("Note");
  return noteSchema.__utils.types;
};

const fields = {
  General: noteGeneralField,
  Email: noteEmailFields,
  "Phone Call": notePhoneCallFields,
  Meeting: noteMeetingFields,
  Tracking: {
    Shipping: noteShippingFields,
    "Follow Up": noteFollowUpFields,
    Delivery: noteDeliveryFields,
  },
};

export const getFields = (
  projectId,
  category,
  trackingType,
  fieldsMap = fields
) => {
  const selectedFields =
    category === "Tracking"
      ? fieldsMap[category][trackingType]
      : fieldsMap[category];
  if (!projectId) return selectedFields;

  const filteredFields = {
    ...selectedFields,
    groups: selectedFields.groups.map(group => ({
      ...group,
      items: group.items.map(item => ({ ...item })),
    })),
  };

  filteredFields.groups[0].items[0].input = {
    ...filteredFields.groups[0].items[0].input,
    disabled: true,
  };

  return filteredFields;
};

const getInitialValues = (
  projectId,
  userId,
  category,
  trackingType,
  assignTo = []
) => ({
  projectId,
  category: category,
  trackingType: trackingType,
  time: undefined,
  subject: undefined,
  detail: undefined,
  emailSentBy: undefined,
  emailSentTo: undefined,
  callWith: undefined,
  trackingNumber: undefined,
  carrierName: undefined,
  authorId: userId,
  tags: [],
  assignTo,
  mentions: [],
});

const NoteCreatePage = ({
  userId,
  category: initialCategory,
  projectId,
  createNote,
  onCancel,
  assignTo,
}) => {
  const [category, setCategory] = useState(getInitialCategory(initialCategory));
  const [trackingType, setTrackingType] = useState(getTrackingTypes()[0].id);
  const initialValues = getInitialValues(
    projectId,
    userId,
    category,
    trackingType,
    assignTo
  );

  const fields = getFields(projectId, category, trackingType);
  return (
    <Fragment>
      <TitleContainer width="100%">
        <StyledTitle>Create Note</StyledTitle>

        <NoteCategoriesContainer>
          <Label>Category:</Label>
          <CategoryDropdown value={category} onChange={setCategory} />
          {category === "Tracking" && (
            <TrackingTypeDropdown
              value={trackingType}
              onChange={setTrackingType}
            />
          )}
        </NoteCategoriesContainer>
      </TitleContainer>
      <Container>
        <FormikForm
          initialValues={initialValues}
          valueOverrides={{ category, trackingType }}
          onSubmit={createNote}
          validationSchema={customValidator}
          enableReinitialize={true}
        >
          {({ handleSubmit, values, errors, setValues, ...formikProps }) => (
            <NoteForm
              onSubmit={handleSubmit}
              onCancel={onCancel}
              fields={fields}
              errors={errors}
              values={values}
              formikProps={formikProps}
            />
          )}
        </FormikForm>
      </Container>
    </Fragment>
  );
};

NoteCreatePage.propTypes = {
  onCancel: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  category: PropTypes.string,
  projectId: PropTypes.string,
  createNote: PropTypes.func.isRequired,
  assignTo: PropTypes.array,
};

export default NoteCreatePage;
