import { appPageBuilder, buildSubPage, redirectTo } from "./utils";
import Projects from "../components/pages/Projects";
import ProjectCreate from "../components/pages/ProjectCreate";
import ProjectDetail from "../components/pages/ProjectDetail";
import SpecDetail from "../components/pages/SpecDetail";
import AreaDetail from "../components/pages/AreaDetail";
import ProjectEdit from "../components/pages/ProjectEdit";
import ProjectOverviewPage from "../components/pages/ProjectDetail/Overview/ProjectOverviewPage";
import Specs from "../components/pages/Specs";
import Areas from "../components/pages/ProjectDetail/Areas";
import PurchaseOrder from "../components/pages/ProjectDetail/PurchaseOrder";
import Shipments from "../components/pages/ProjectDetail/Shipments";
import Quoting from "../components/pages/Quoting";
import Forecast from "../components/pages/ProjectDetail/Forecast";
import Categories from "../components/pages/ProjectDetail/Forecast/Categories";
import ForecastAreas from "../components/pages/ProjectDetail/Forecast/Areas";
import Expediting from "../components/pages/ProjectDetail/Expediting";
import Accounting from "../components/pages/ProjectDetail/Accounting";
import Terms from "../components/pages/ProjectDetail/Terms";
import AccountingSummary from "../components/pages/ProjectDetail/Accounting/Summary";
import AccountingFees from "../components/pages/ProjectDetail/Accounting/Fees";
import AccountingFunding from "../components/pages/ProjectDetail/Accounting/Funding";
import AccountingInvoices from "../components/pages/ProjectDetail/Accounting/Invoices";
import InvoiceDetail from "../components/pages/InvoiceDetail";
import BidGroupDetail from "../components/pages/BidGroupDetail";
import Vendors from "../components/pages/BidGroupDetail/Vendors";
import PurchaseOrderDetail from "../components/pages/PurchaseOrderDetail";
import PurchaseOrderList from "../components/pages/Quoting/PurchaseOrderList";
import BidGroupList from "../components/pages/Quoting/BidGroupList";
import QuotesPage from "../components/pages/BidGroupDetail/Quotes/QuotesPage";
import AnalysisPage from "../components/pages/BidGroupDetail/Analysis/AnalysisPage";

import ClientProjectDetail from "../components/pages/Projects/ClientProjects/ProjectDetail";
import ClientProjectOverview from "../components/pages/Projects/ClientProjects/ProjectOverview";
import ClientProjectReports from "../components/pages/Projects/ClientProjects/Reports";

const AppProjects = appPageBuilder(Projects, "Projects");
const AppProjectCreate = appPageBuilder(
  ProjectCreate,
  "Create New Project for "
);
const AppProjectDetail = appPageBuilder(ProjectDetail, "Project Detail", true);
const AppSpecDetail = appPageBuilder(SpecDetail, "Spec Detail", true);
const AppAreaDetail = appPageBuilder(AreaDetail, "Area Detail", true);
const AppBidGroupDetail = appPageBuilder(
  BidGroupDetail,
  "Bid Group Detail",
  true
);
const AppInvoiceDetail = appPageBuilder(InvoiceDetail, "Invoice Detail", true);

const ClientProjectDetailPage = appPageBuilder(ClientProjectDetail, "", true);

export const quotingRoutes = [
  {
    path: "/clients/:clientId/projects/:projectId/quoting/bid-groups",
    component: BidGroupList,
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/:projectId/quoting/po-groups",
    component: PurchaseOrderList,
    exact: true,
  },
  //default
  {
    path: "/clients/:clientId/projects/:projectId/quoting",
    component: redirectTo("bid-groups"),
    exact: true,
  },
];

export default [
  {
    path: "/projects",
    component: AppProjects,
    exact: true,
  },
  {
    path: "/projects/:projectId",
    component: ClientProjectDetailPage,
    exact: false,
    routers: [
      {
        path: "/projects/:projectId/overview",
        component: ClientProjectOverview,
        exact: true,
      },
      {
        path: "/projects/:projectId/reports",
        component: ClientProjectReports,
        exact: true,
      },
    ],
  },
  {
    path: "/clients/:clientId/projects/:projectId/settings",
    component: appPageBuilder(ProjectEdit, "Edit Project", true),
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/:projectId/purchase-orders/:poId",
    component: appPageBuilder(
      PurchaseOrderDetail,
      "Purchase Order Detail",
      true
    ),
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/create",
    component: AppProjectCreate,
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/:projectId/specs/:specId",
    component: AppSpecDetail,
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/:projectId/areas/:areaId",
    component: AppAreaDetail,
    exact: true,
  },
  {
    path:
      "/clients/:clientId/projects/:projectId/quoting/bid-groups/:bidGroupId",
    component: AppBidGroupDetail,
    exact: false,
    routers: [
      {
        path:
          "/clients/:clientId/projects/:projectId/quoting/bid-groups/:bidGroupId/vendors",
        component: Vendors,
        exact: true,
      },
      {
        path:
          "/clients/:clientId/projects/:projectId/quoting/bid-groups/:bidGroupId/quotes",
        component: QuotesPage,
        exact: true,
      },
      {
        path:
          "/clients/:clientId/projects/:projectId/quoting/bid-groups/:bidGroupId/analysis",
        component: AnalysisPage,
        exact: true,
      },
      //default
      {
        path:
          "/clients/:clientId/projects/:projectId/quoting/bid-groups/:bidGroupId",
        component: redirectTo("vendors"),
        exact: true,
      },
    ],
  },
  {
    path: "/clients/:clientId/projects/:projectId/invoices/:invoiceId",
    component: AppInvoiceDetail,
    exact: true,
  },
  {
    path: "/clients/:clientId/projects/:projectId",
    component: AppProjectDetail,
    routers: [
      {
        path: "/clients/:clientId/projects/:projectId/overview",
        component: buildSubPage(ProjectOverviewPage),
        exact: true,
      },
      {
        path: "/clients/:clientId/projects/:projectId/specs",
        component: buildSubPage(Specs),
        exact: true,
      },
      {
        path: "/clients/:clientId/projects/:projectId/areas",
        component: buildSubPage(Areas),
        exact: true,
      },
      {
        path: "/clients/:clientId/projects/:projectId/forecast",
        component: buildSubPage(Forecast),
        exact: false,
        routers: [
          {
            path: "/clients/:clientId/projects/:projectId/forecast/categories",
            component: Categories,
            exact: true,
          },
          {
            path: "/clients/:clientId/projects/:projectId/forecast/areas",
            component: ForecastAreas,
            exact: true,
          },
          //default
          {
            path: "/clients/:clientId/projects/:projectId/forecast",
            component: redirectTo("areas"),
            exact: true,
          },
        ],
      },
      {
        path: "/clients/:clientId/projects/:projectId/quoting",
        component: buildSubPage(Quoting),
        exact: false,
        routers: quotingRoutes,
      },
      {
        path: "/clients/:clientId/projects/:projectId/expediting",
        component: buildSubPage(Expediting),
        exact: false,
        routers: [
          {
            path: "/clients/:clientId/projects/:projectId/expediting/shipments",
            component: Shipments,
            exact: true,
          },
          {
            path:
              "/clients/:clientId/projects/:projectId/expediting/purchase-orders",
            component: PurchaseOrder,
            exact: true,
          },
          {
            path: "/clients/:clientId/projects/:projectId/expediting/shipments",
            component: Shipments,
            exact: true,
          },
          //default
          {
            path: "/clients/:clientId/projects/:projectId/expediting",
            component: redirectTo("purchase-orders"),
            exact: true,
          },
        ],
      },
      {
        path: "/clients/:clientId/projects/:projectId/accounting",
        component: buildSubPage(Accounting),
        exact: false,
        routers: [
          {
            path: "/clients/:clientId/projects/:projectId/accounting/summary",
            component: AccountingSummary,
            exact: true,
          },
          {
            path: "/clients/:clientId/projects/:projectId/accounting/fees",
            component: AccountingFees,
            exact: true,
          },
          {
            path: "/clients/:clientId/projects/:projectId/accounting/funding",
            component: AccountingFunding,
            exact: true,
          },
          {
            path: "/clients/:clientId/projects/:projectId/accounting/invoices",
            component: AccountingInvoices,
            exact: true,
          },
          //default
          {
            path: "/clients/:clientId/projects/:projectId/accounting",
            component: redirectTo("summary"),
            exact: true,
          },
        ],
      },
      {
        path: "/clients/:clientId/projects/:projectId/terms",
        component: buildSubPage(Terms),
        exact: false,
      },
      //default
      {
        path: "/clients/:clientId/projects/:projectId",
        component: redirectTo("overview"),
        exact: true,
      },
    ],
  },
];
