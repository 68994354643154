import React, { useContext, useState } from "react";

import RegularText from "../../../../ui/Typography/RegularText";
import { Button, DropDownIcon, StyledTitle } from "./components";
import { QuoteViewByContext } from "./withQuoteViewByControl";
import CurrentViewSelect from "./CurrentViewSelect";

const LabelControl = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentView } = useContext(QuoteViewByContext);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTitle>
      <RegularText as="span">View As</RegularText>
      <Button onClick={handleClick}>
        <RegularText as="span" intent="light">
          {currentView}
        </RegularText>
        <DropDownIcon />
      </Button>
      <CurrentViewSelect
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      />
    </StyledTitle>
  );
};

export default LabelControl;
