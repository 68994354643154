import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { connect } from "react-redux";
import moment from "moment";

import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import propTypes from "../../../../constants/propTypes";
import { dataComponentId } from "./Grid";
import { getDataComponentRequestState } from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import { getDefaultProjectCurrency } from "../../../helpers";

export const openBillFeeModal = (props, formattedAmount) => {
  const { fee, openModalDialog } = props;
  openModalDialog(
    `Bill Fee`,
    "BillFee",
    {
      fee,
      feeId: fee.id,
      dataComponentId,
    },
    true,
    true,
    {
      subtitle: `Project: ${fee.project.number}`,
      secondSubtitle: `Billing Date: ${moment(fee.billingDate).format(
        PRIMARY_DATE_FORMAT
      )} | Amount: ${formattedAmount}`,
    }
  );
};

const ActionButton = props => {
  const { fee, isUpdating, updateFee } = props;
  const actionDisabled = Boolean(fee.sharedFromFeeId || isUpdating);
  const Wrapper = actionDisabled ? Fragment : Tooltip;
  const buttonProps = actionDisabled
    ? {}
    : { title: fee.revenueSharing ? "Mark As Paid" : "Bill Fee" };
  const defaultCurrency = getDefaultProjectCurrency(
    fee.project.projectCurrencies
  );
  const formatter = useCurrencyFormatter(defaultCurrency.currency);
  if (fee.revenueSharing) {
    return (
      <Wrapper {...buttonProps}>
        <IconButton
          onClick={() => updateFee(fee.id, { status: "Paid" }, dataComponentId)}
          disabled={actionDisabled}
        >
          <MonetizationOnIcon />
        </IconButton>
      </Wrapper>
    );
  }
  return (
    <Wrapper {...buttonProps}>
      <IconButton
        onClick={() => openBillFeeModal(props, formatter.format(fee.amount))}
        disabled={actionDisabled}
      >
        <ReceiptIcon />
      </IconButton>
    </Wrapper>
  );
};
ActionButton.propTypes = {
  updateFee: PropTypes.func.isRequired,
  fee: propTypes.fee,
  isUpdating: PropTypes.bool,
};

export const mapDispatchToProps = {
  openModalDialog,
};

const mapStateToProps = state => {
  const updateRequestState =
    getDataComponentRequestState(dataComponentId, state)[
      REQUEST_TYPES.UPDATE
    ] || {};

  return {
    isUpdating: updateRequestState.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
