import React from "react";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";

import { PACheckCircle } from "../Projects/Components/components";
import PersonList, { getUserInfo } from "../../../ui/Tags/PersonList";
import Person from "../../../ui/Person";
import { genDetailRowComponents } from "../../../ui/BWGrid/helperComponents/SpecAdditionalInfoDetailRow";
import { projectAccountingType } from "../../ProjectCreate/TeamSection/createTeamFields";
import { RowComponent } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/genDetailRowComponents/v2";
import { buildMemberUserFilter, ProjectDate } from "../Projects/gridProps";
import { editableCellCreator } from "../../../ui/BWGrid/EditableCellAPI";
import DatePickerWithError from "../../../inputs/DatePickerWithError";

export const rowMenu = ({ onOpenProjectSettings, onOpenProjectDetails }) => [
  { text: "Project Settings", onClick: onOpenProjectSettings },
  { separator: true },
  { text: "Go To Project", onClick: onOpenProjectDetails },
];

const renderPasFirstName = row => {
  if (
    row.projectMemberUsers &&
    row.projectMemberUsers.length > 0 &&
    row.projectMemberUsers.filter(
      projectMemberUser => projectMemberUser.memberType === "Accounting Support"
    ).length > 0
  ) {
    return (
      <PersonList
        members={row.projectMemberUsers.filter(
          projectMemberUser =>
            projectMemberUser.memberType === "Accounting Support"
        )}
        type="user"
        maxTags={3}
      />
    );
  }
  return "";
};

export const columns = [
  "number",
  "name",
  "property.entity.client.name",
  "hasAccounting",
  "pasFirstName",
  "projectLead.firstName",
  "purchasingCloseDate",
  "softCloseDate",
  "accountingCloseDate",
  "useTaxBalance",
  "openPoBalance",
  "deposits",
  "cash",
];

export const getColumnOptions = (currencyFormatter, users) => ({
  number: {
    title: "Number",
    filter: true,
    filterOptions: { operator: "ilike" },
  },
  name: {
    title: "Project Name",
    filter: true,
    fill: true,
  },
  "property.entity.client.name": {
    title: "Client",
    filter: true,
    render: row => {
      if (row.property && row.property.entity && row.property.entity.client) {
        return row.property.entity.client.name;
      }
      return "";
    },
  },
  hasAccounting: {
    title: "PA",
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "True" },
        { id: false, name: "False" },
      ],
    },
    render: function PA(row) {
      return <PACheckCircle isActive={row.hasAccounting} />;
    },
  },
  pasFirstName: {
    title: "Acct. Support",
    filter: "multiselect",
    minWidth: "160px",
    filterOptions: {
      operator: buildMemberUserFilter(projectAccountingType),
      options: users.map(user => {
        return { id: user.id, name: `${user.firstName} ${user.lastName}` };
      }),
    },
    parseSort: sort => ({ ...sort, nulls: "first" }),
    render: renderPasFirstName,
  },
  "projectLead.firstName": {
    title: "Project Lead",
    filter: "select",
    filterOptions: {
      operator: ({ value }) => ({
        ["projectLead.id"]: { $integerContains: `%${value}%` },
      }),
      options: users.map(user => {
        return { id: user.id, name: `${user.firstName} ${user.lastName}` };
      }),
    },
    wordWrapEnabled: true,
    render: row => {
      if (row.projectLead) {
        return (
          <Person person={getUserInfo(row.projectLead)} personType="Lead" />
        );
      }
      return "";
    },
  },
  purchasingCloseDate: {
    title: "Purchasing Close",
    filter: "picker",
    editable: true,
    render: project => ProjectDate(project, "purchasingCloseDate"),
  },
  softCloseDate: {
    title: "Soft Close Complete",
    filter: "picker",
    editable: true,
    render: project => ProjectDate(project, "softCloseDate"),
  },
  accountingCloseDate: {
    title: "Accounting Close Date",
    filter: "picker",
    editable: true,
    render: project => ProjectDate(project, "accountingCloseDate"),
  },
  useTaxBalance: {
    title: "Use Tax Balance",
    sortingEnabled: false,
    render: row => {
      return currencyFormatter.format(row.useTaxBalance);
    },
  },
  openPoBalance: {
    title: "Open PO Balance",
    sortingEnabled: false,
    render: row => {
      return currencyFormatter.format(row.openPoBalance);
    },
  },
  deposits: {
    title: "Deposits",
    sortingEnabled: false,
    wordWrapEnabled: true,
    render: row => {
      return currencyFormatter.format(row.deposits);
    },
  },
  cash: {
    title: "Cash Balance",
    sortingEnabled: false,
    render: row => {
      return currencyFormatter.format(
        row.totalFundsReceived - row.expenditures
      );
    },
  },
});

export const rowInputs = {
  purchasingCloseDate: {
    InputComponent: DatePickerWithError,
    name: "purchasingCloseDate",
    fullWidth: true,
    isApplyOnChange: true,
  },
  softCloseDate: {
    InputComponent: DatePickerWithError,
    name: "softCloseDate",
    fullWidth: true,
    isApplyOnChange: true,
  },
  accountingCloseDate: {
    InputComponent: DatePickerWithError,
    name: "accountingCloseDate",
    fullWidth: true,
    isApplyOnChange: true,
  },
};

export const isEditableRow = editableColumns => column => {
  return editableColumns.indexOf(column.name) !== -1;
};

export const getTableComponents = memoizeOne(
  (dataComponentId, displayComments, editableColumns) => {
    if (!displayComments) return {};
    return {
      CellComponent: editableCellCreator(
        rowInputs,
        dataComponentId,
        {},
        isEditableRow(editableColumns)
      ),
      ...genDetailRowComponents(
        "id",
        [
          {
            path: "closingComments",
            label: "",
            placeholder: "Add Comment",
            isEmptyFn: Boolean,
            showLabel: false,
          },
        ],
        BWModels.loadSchema("Project"),
        dataComponentId,
        { prevSpan: 1, colSpan: 6, posSpan: 8 }
      ),
      RowComponent,
    };
  }
);
