import React from "react";
import { Link } from "@material-ui/core";
import styled from "styled-components";

export const BWLink = styled(props => (
  <Link
    href="#"
    type="button"
    component="button"
    variant={"body2"}
    {...props}
  />
))`
  margin-left: 4px;
  color: #57abff;
  cursor: pointer;
  font-size: inherit;
  &:hover {
    text-decoration: none;
  }
`;
