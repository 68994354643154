import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  Checkbox as RawCheckbox,
  FormControlLabel,
} from "../../../../../mui/core";
import Loader from "../../../../../ui/Loader";

const Wrapper = styled.div`
  display: flex;
  margin-left: 24px;
  align-items: center;
`;

const Checkbox = styled(RawCheckbox)`
  padding: 0px;
`;

export const Item = ({ name, onToggleProp, checked, ...props }) => {
  const handleChange = ({ target: { checked } }) => {
    onToggleProp(checked, { name, ...props });
  };

  return (
    <Wrapper>
      <Loader count={10}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label={name}
        />
      </Loader>
    </Wrapper>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  onToggleProp: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

const SelectableItems = ({ id, items, onToggleProp }) => {
  return items.map(({ name, columnName, ...props }) => (
    <Item
      key={`${id}-${columnName}`}
      name={name}
      columnName={columnName}
      onToggleProp={onToggleProp}
      {...props}
    />
  ));
};

export default SelectableItems;
