import React from "react";
import styled from "styled-components";
import MUIRow from "../../../../mui/@devexpress/dx-react-grid-material-ui/Row";

import { buildPressEventHandlers } from "../../../../../utils/eventUtils";

export const StyledRow = styled(MUIRow)`
  ${({ onClick }) =>
    onClick &&
    `
  cursor: pointer;
  &:focus, &.hover-active:hover {
    background: #f5f5f5;
    > {
      td,
      th {
        background: #f5f5f5 !important;
      }
    }
  }
  `}
`;

const Row = ({
  onClick: pressHandler,
  getRowTitle,
  title: rowTitle,
  ...props
}) => {
  const clickArguments = [props.row, { rowId: props.tableRow.rowId }];

  const handler = () => pressHandler(...clickArguments);

  const { onClick, onKeyUp } = buildPressEventHandlers(handler, true, false);

  if (pressHandler) {
    const title = rowTitle || getRowTitle(...clickArguments);
    return (
      <StyledRow
        tabIndex="0"
        {...props}
        onClick={onClick}
        onKeyUp={onKeyUp}
        title={title}
      />
    );
  }
  return <StyledRow {...props} />;
};

Row.defaultProps = {
  getRowTitle: () => "Press",
};

/**
 *
 * @param rowProps
 * @param rowProps.onClick
 * @param rowProps.getRowTitle
 * @param rowProps.title
 * @param RowComponent
 * @return {ClickableRow}
 */
export const withClickableRow = (rowProps = {}, RowComponent) => {
  /**
   * @type ClickableRow
   */
  const ClickableRow = props => {
    return <RowComponent {...rowProps} {...props} />;
  };
  return ClickableRow;
};

export default Row;
