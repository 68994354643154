import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import ClientDetailPage from "./ClientDetailPage";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import { openNotesModal } from "../../../actions/notesActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import Client from "../../../models/Client";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";

const dataComponentId = "ClientDetail";

export class ClientDetailContainer extends Component {
  componentDidMount() {
    const { initDataComponent, performFindRequest, clientId } = this.props;
    initDataComponent(
      dataComponentId,
      Client,
      ["location"],
      "clients",
      true,
      "v2"
    );
    performFindRequest(dataComponentId, clientId);
  }

  handleOpenMenu = () => {
    const { pushWithReturnUrl, clientId } = this.props;
    pushWithReturnUrl(`/clients/edit/${clientId}`, "CLIENT DETAIL");
  };

  handleOpenNotesModal = () => {
    const { clientId } = this.props;
    this.props.openNotesModal({ clientId });
  };

  render() {
    const { client } = this.props;
    if (!client) {
      return null;
    }
    return (
      <ClientDetailPage
        client={client}
        onOpenMenu={this.handleOpenMenu}
        onOpenNotesModal={this.handleOpenNotesModal}
      />
    );
  }
}

ClientDetailContainer.propTypes = {
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  dataComponent: PropTypes.object,
  clientId: PropTypes.string,
  client: PropTypes.object,
  pushWithReturnUrl: PropTypes.func,
  openNotesModal: PropTypes.func,
};

export const mapDispatchToProps = {
  pushWithReturnUrl,
  initDataComponent,
  performFindRequest,
  openNotesModal,
};

const mapStateToProps = (state, ownProps) => {
  const clientId = ownProps.match.params.clientId;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    clientId,
    dataComponent,
    client: getBluechipResourceById(dataComponent, state, clientId),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientDetailContainer)
);
