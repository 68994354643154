import styled from "styled-components";

const Content = styled.div`
  padding: 24px 24px 36px 36px;
  @media (max-width: 768px) {
    padding: 12px 12px 36px 12px;
  }
`;

export default Content;
