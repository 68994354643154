import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import styled from "styled-components";
import _get from "lodash/get";

import ProjectForm from "../ProjectCreate/ProjectForm";
import { NavigationArea } from "../../layout/DetailAndCycle";
import { Content } from "../../layout/Content";
import {
  FlexWrapper,
  FlexItem,
  FlexItemGrow,
} from "../../layout/SectionHeader";
import Loader from "../../ui/Loader";
import propTypes from "../../../constants/propTypes";
import HeaderButtons from "./HeaderButtons";
import { dataComponentId } from "./ProjectEditContainer";

const FlexWrapperRow = styled(FlexWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  margin-top: -8px;
  @media (max-width: 768px) {
    align-items: start;
  }
`;
const ProjectNumberText = styled.span`
  font-family: Domine, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-right: 16px;
  color: rgba(0, 0, 0, 0.87);
`;
const ForClientText = styled.span`
  font-family: Domine, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
`;
const ProjectNameText = styled.span`
  font-family: Domine, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
`;

export const getSortedCurrencies = projectCurrencies => {
  return [
    ...projectCurrencies.filter(pcItem => pcItem.isDefault),
    ...projectCurrencies
      .filter(pcItem => !pcItem.isDefault)
      .sort((a, b) => Number(a.id) - Number(b.id)),
  ];
};
const ProjectEditPage = props => {
  const {
    onSubmit,
    onCreateArea,
    onDeleteArea,
    onUpdateArea,
    project,
    areaTypes,
    clientId,
    client,
    role,
    workScopes,
    currencies,
  } = props;

  const clientName = _get(client, "name", "");
  const projectNumber = project.number ? project.number : "";
  const sortedProject = {
    ...project,
    projectCurrencies: getSortedCurrencies(project.projectCurrencies),
  };
  return (
    <Content>
      <NavigationArea hideActions />
      <FlexWrapperRow>
        <FlexItemGrow>
          <Loader width="400px" height="30px">
            <ProjectNumberText>{`${projectNumber}`}</ProjectNumberText>
            <ForClientText>{`For Client: `}</ForClientText>
            <ProjectNameText>{`${clientName}`}</ProjectNameText>
          </Loader>
        </FlexItemGrow>
        <FlexItem>
          <HeaderButtons project={project} client={client} />
        </FlexItem>
      </FlexWrapperRow>
      <ProjectForm
        client={client}
        onSubmit={onSubmit}
        onCreateArea={onCreateArea}
        onDeleteArea={onDeleteArea}
        onUpdateArea={onUpdateArea}
        sendButtonText="Update Project"
        initialValues={sortedProject}
        clientId={clientId}
        project={sortedProject}
        areaTypes={areaTypes}
        isUpdate
        role={role}
        workScopes={workScopes}
        currencies={currencies}
        dataComponentId={dataComponentId}
      />
    </Content>
  );
};
ProjectEditPage.defaultProps = {
  onSubmit: () => {},
  clientId: "",
  project: {},
  location: {},
  role: {},
};
ProjectEditPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  location: PropTypes.object,
  onCreateArea: PropTypes.func.isRequired,
  onDeleteArea: PropTypes.func.isRequired,
  onUpdateArea: PropTypes.func.isRequired,
  areaTypes: PropTypes.array.isRequired,
  client: propTypes.client,
  role: propTypes.userRole,
  workScopes: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
};
export default withRouter(ProjectEditPage);
