import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { cloneDeep } from "lodash";

import CreateReportTemplate from "./CreateReportTemplate";
import View from "../../../../../models/View";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import {
  useCreateProcessRequests,
  useUpdateProcessRequests,
} from "../../../../hooks/useProcessRequest";
import { customReportDataComponentId } from "../ReportTemplatesContainer";
import propTypes from "../../../../../constants/propTypes";

export const dataComponentViewId = "dataComponentView";

const updateTreeObject = ({
  treeObject: originalTreeObject,
  customReport: { customReportDataOptions },
}) => {
  const treeObject = cloneDeep(originalTreeObject);
  customReportDataOptions.forEach(customReportDataOption => {
    const selectPath = customReportDataOption.selectName.split(".");
    if (selectPath.length === 1) {
      treeObject.properties[selectPath] = customReportDataOption;
      return;
    }

    let currentObject = treeObject;
    selectPath.forEach((path, index) => {
      // If it's the column/property
      if (index === selectPath.length - 1) {
        currentObject.properties[path] = customReportDataOption;
        return;
      }
      currentObject = Object.values(currentObject.children).find(
        ({ relationName }) => relationName === path
      );
    });
  });

  return treeObject;
};

const onSubmit = (
  customReport,
  performCreateRequest,
  performUpdateRequest
) => root => ({ name, description, columns, userRoleIds, scopes }) => {
  const newCustomReport = {
    name,
    description,
    baseModel: root.entityName,
    customReportDataOptions: columns,
    userRoleIds,
    treeObject: JSON.stringify(root),
    scopes,
  };

  if (!customReport) {
    performCreateRequest(customReportDataComponentId, newCustomReport);
    return;
  }

  newCustomReport.treeObject = updateTreeObject({
    treeObject: root,
    customReport: newCustomReport,
  });
  performUpdateRequest(
    customReportDataComponentId,
    customReport.id,
    newCustomReport
  );
};

const CreateReportTemplateContainer = ({
  views,
  loading,
  initDataComponent,
  closeModalDialog,
  performRetrieveListRequest,
  showSnackNotificationAction,
  performCreateRequest,
  performUpdateRequest,
  setReload,
  customReport,
  office,
}) => {
  useEffect(() => {
    initDataComponent(dataComponentViewId, View, [], "views", true, "v2");

    performRetrieveListRequest(dataComponentViewId, {
      rootFilters: {},
      pageSize: -1,
    });
  }, [initDataComponent, performRetrieveListRequest]);

  const customReportDataComponent = useDatacomponent(
    customReportDataComponentId
  );

  const onSuccess = () => {
    closeModalDialog();
    setReload(customReportDataComponentId, true);
  };

  useCreateProcessRequests(customReportDataComponent, {
    onSuccess,
    onError: () =>
      showSnackNotificationAction(
        "There was a problem while creating the new template"
      ),
  });

  useUpdateProcessRequests(customReportDataComponent, {
    onSuccess,
    onError: () =>
      showSnackNotificationAction(
        "There was a problem while updating the custom report"
      ),
  });

  return (
    <CreateReportTemplate
      views={views}
      dataComponentViewId={dataComponentViewId}
      onSubmit={onSubmit(
        customReport,
        performCreateRequest,
        performUpdateRequest
      )}
      closeModalDialog={closeModalDialog}
      loading={loading}
      customReport={customReport}
      office={office}
    />
  );
};

CreateReportTemplateContainer.propTypes = {
  views: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  customReport: propTypes.customReport,
  office: propTypes.office,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentViewId,
    state
  );

  const views = getBluechipResourcesByType(dataComponentViewId, state) || [];
  return {
    views,
    viewDataComponent: dataComponent,
    loading: _get(dataComponent, "loading") || !views,
    office: state.auth.office,
  };
};

export const mapDispatchToProps = {
  performRetrieveListRequest,
  showSnackNotificationAction,
  performCreateRequest,
  performUpdateRequest,
  initDataComponent,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReportTemplateContainer);
