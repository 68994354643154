import React from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import propTypes from "../../../../constants/propTypes";
import CustomReport from "../../../../models/CustomReport";
import useUserRole from "../../../hooks/useUserRole";
import { isSuperAdmin } from "../../../../utils/roleUtils";
import { getScopeIdColumnOption } from "../QueryReports/gridConfig";

export const getColumns = userRole => {
  const columns = ["name"];
  if (isSuperAdmin(userRole)) {
    columns.push("scopeId");
  }
  return columns;
};

export const getColumnOptions = scopes => ({
  name: { title: "Name", filter: true },
  scopeId: getScopeIdColumnOption(scopes),
});

const rowMenu = ({ handleDeleteTemplate, onOpenEditModal }) => [
  {
    text: "Edit",
    onClick: onOpenEditModal,
  },
  {
    text: "Delete",
    onClick: ({ id }) => handleDeleteTemplate(id),
  },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Custom Report",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const ReportTemplates = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  handleDeleteTemplate,
  scopes = [],
}) => {
  const userRole = useUserRole();
  return (
    <BWGridAPI
      dataComponent={dataComponent}
      model={CustomReport}
      defaultSorting={[
        {
          columnName: "name",
          direction: "asc",
        },
      ]}
      apiRoute="custom-reports"
      includes={["customReportDataOptions", "scopes"]}
    >
      <GridHeader
        headerText={`${dataComponent.totalRows} Custom Reports`}
        actions={actions(onOpenCreateModal)}
      />
      <GridTable
        columns={getColumns(userRole)}
        columnOptions={getColumnOptions(scopes)}
        rowMenu={rowMenu({ handleDeleteTemplate, onOpenEditModal })}
      />
    </BWGridAPI>
  );
};

ReportTemplates.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  scopes: PropTypes.array,
};

export default ReportTemplates;
