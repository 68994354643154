import React, { useCallback } from "react";
import PropTypes from "prop-types";

import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

export const GridActions = ({
  selectedRows,
  setShowBulkUploadSubmittals,
  setTitle,
}) => {
  const handleClick = useCallback(() => {
    setShowBulkUploadSubmittals(true);
    setTitle(selectedRows.map(({ requirement: { name } }) => name).join(", "));
  }, [selectedRows, setShowBulkUploadSubmittals, setTitle]);

  return (
    <DropdownMenu
      buttonText="Actions"
      options={[
        {
          content: "Upload Files",
          onClick: handleClick,
          disabled: !selectedRows.length,
        },
      ]}
    />
  );
};

GridActions.propTypes = {
  selectedRows: PropTypes.array,
  setShowBulkUploadSubmittals: PropTypes.func,
  setTitle: PropTypes.func.isRequired,
};
