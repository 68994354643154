import { inputs } from "../../../inputs/inputConfigs";

export default () => {
  return {
    groups: [
      {
        items: [
          {
            input: { ...inputs.locationName, label: "Payee Remit To" },
            grid: { xs: 12 },
          },
          { input: inputs.address, grid: { xs: 12 } },
          { input: inputs.address2, grid: { xs: 12 } },
          { input: inputs.country, grid: { xs: 6 } },
          { input: inputs.state, grid: { xs: 6 } },
          { input: inputs.city, grid: { xs: 6 } },
          { input: inputs.postalCode, grid: { xs: 6 } },
        ],
      },
    ],
  };
};
