import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { FormikForm, FormGrid, ActionButtons } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";
import { LoaderContext } from "../../../../ui/Loader";
import inputs from "../../../../inputs/inputConfigs/login";
import { useCreateProcessRequests } from "../../../../hooks/useProcessRequest";
import { codeToErrorInfoMap } from "../../../../../utils/formValidationUtils";
import { PasswordTitle } from "../components";
import gridSchema from "../../../../pages/Settings/UpdatePassword/gridSchema";

const createForgotPasswordFields = () => ({
  groups: [
    {
      items: [
        {
          input: inputs.oldPassword,
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.password, label: "New Password" },
          grid: { xs: 12 },
        },
        {
          input: inputs.repeatPassword,
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

const UpdatePassword = ({ actions, dataComponent }) => {
  const [formik, setFormik] = useState();

  const handleSubmit = ({ passwordConfirmation, ...values }, formik) => {
    setFormik(formik);
    actions.resetPassword(values);
  };

  const loading = useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      formik.resetForm();
      actions.showSnackNotificationAction("Password was updated successfully");
    },
    onError: payload => {
      const errorCode = _get(payload, "data.errors.0.code", "noInfo");
      actions.showSnackNotificationAction(codeToErrorInfoMap[errorCode].title);
    },
  });

  return (
    <LoaderContext.Provider value={{ loading }}>
      <PasswordTitle>Change Password</PasswordTitle>
      <FormikForm onSubmit={handleSubmit} validationSchema={gridSchema}>
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createForgotPasswordFields()}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              hideCancelButton
              isModal
              onSend={handleSubmit}
              sendButtonText={"Update Password"}
            />
          </Fragment>
        )}
      </FormikForm>
    </LoaderContext.Provider>
  );
};

UpdatePassword.propTypes = {
  dataComponent: propTypes.dataComponent,
  actions: PropTypes.shape({
    resetForm: PropTypes.func,
    showSnackNotificationAction: PropTypes.func,
  }),
};

export default UpdatePassword;
