export const handleSuccess = (
  fetchData,
  addressBookDataComponentId,
  addressBookId,
  showSnackNotificationAction,
  snackMessage,
  closeModalDialog
) => {
  fetchData(addressBookDataComponentId);
  showSnackNotificationAction(snackMessage);
  closeModalDialog();
};
export const handleError = (data, showSnackNotificationAction) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};
