import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import {
  DetailAndCycle,
  NavigationArea,
  Subtitle,
  TitleArea,
} from "../../layout/DetailAndCycle";
import ActionsArea from "../../layout/DetailAndCycle/ActionsArea";
import createSpecDetailActionsAreaFields from "./createSpecDetailActionsAreaFields";
import propTypes from "../../../constants/propTypes";
import SerifTitle from "../../ui/Typography/SerifTitle";
import { roleCan } from "../../../utils/roleUtils";
import POStatusTag from "../../ui/POStatusTag";
import { LabeledTextContext } from "../../../withPORevision";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";

const StyledTitleParent = styled(SerifTitle)`
  margin-left: 8px;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
`;

const getMoreActionOptions = (
  { purchaseOrder, projectId },
  role,
  { onViewPO, onOpenDeleteSpec, onOpenDuplicateSpec }
) => {
  const options = [
    purchaseOrder
      ? {
          content: `View PO#${projectId}-${purchaseOrder.sequenceIndex}`,
          onClick: onViewPO,
        }
      : null,
    {
      content: "Duplicate",
      useSeparator: purchaseOrder && purchaseOrder.status !== "Issued",
      onClick: onOpenDuplicateSpec,
      disableIfProjectClosed: true,
    },
    {
      content: "Delete",
      onClick: onOpenDeleteSpec,
      hidden: purchaseOrder && purchaseOrder.status === "Issued",
      disableIfProjectClosed: true,
    },
  ];

  return options.filter(option => {
    return (
      option !== null &&
      !option.hidden &&
      (!option.permission || roleCan(role, option.permission))
    );
  });
};

const getBackProps = (spec, clientId, backTo) => {
  if (typeof backTo === "object") return backTo;
  if (backTo === "po") {
    return {
      backRoute: `/clients/${clientId}/projects/${spec.projectId}/purchase-orders/${spec.purchaseOrderId}`,
      backText: `Back to PO #${_get(spec, "purchaseOrder.number")}`,
    };
  }
  return {};
};

export const getParams = ({ navigateBetweenPO, navigateBetweenBG }) => {
  const array = [];
  if (navigateBetweenPO) {
    array.push("navigateBetweenPO=true");
  }

  if (navigateBetweenBG) {
    array.push(`navigateBetweenBG=${navigateBetweenBG}`);
  }

  return array.join("&");
};

const getNavigationProps = (
  isLabeled,
  nextSpecId,
  lastSpecId,
  clientId,
  spec,
  onOpenNotesModal,
  history
) => {
  const params = getParams(parse(history.location.search));
  return {
    nextItemRoute:
      nextSpecId &&
      `/clients/${clientId}/projects/${spec.projectId}/specs/${nextSpecId}?${params}`,
    lastItemRoute:
      lastSpecId &&
      `/clients/${clientId}/projects/${spec.projectId}/specs/${lastSpecId}?${params}`,
    onOpenNotesModal: onOpenNotesModal,
  };
};

const DetailAndCycleContainer = ({
  spec,
  clientId,
  onOpenNotesModal,
  onOpenDeleteSpec,
  onOpenDuplicateSpec,
  onViewPO,
  nextSpecId,
  lastSpecId,
  role,
  backTo,
}) => {
  const isLabeled = useContext(LabeledTextContext);
  const history = useHistory();

  const inputs = useMemo(
    () =>
      createSpecDetailActionsAreaFields(
        getMoreActionOptions(spec, role, {
          onViewPO,
          onOpenDeleteSpec,
          onOpenDuplicateSpec,
        }),
        isLabeled,
        spec.specCategory?.key
      ),
    [onOpenDeleteSpec, onOpenDuplicateSpec, onViewPO, spec, role, isLabeled]
  );

  return (
    <DetailAndCycle>
      <NavigationArea
        {...getNavigationProps(
          isLabeled,
          nextSpecId,
          lastSpecId,
          clientId,
          spec,
          onOpenNotesModal,
          history
        )}
        {...getBackProps(spec, clientId, backTo)}
      />
      <TitleArea id={spec.customNumber} title={spec.description}>
        {spec.parent && (
          <StyledTitleParent>@{spec.parent.description}</StyledTitleParent>
        )}
        {spec.purchaseOrder && (
          <POStatusTag purchaseOrder={spec.purchaseOrder} displayNumber />
        )}
      </TitleArea>

      <Subtitle>{spec.specCategory && spec.specCategory.name}</Subtitle>

      <ActionsArea inputs={inputs} />
    </DetailAndCycle>
  );
};

DetailAndCycleContainer.propTypes = {
  spec: propTypes.spec,
  clientId: PropTypes.string,
  onOpenNotesModal: PropTypes.func.isRequired,
  onViewPO: PropTypes.func.isRequired,
  onOpenDeleteSpec: PropTypes.func.isRequired,
  onOpenDuplicateSpec: PropTypes.func.isRequired,
  nextSpecId: PropTypes.string,
  lastSpecId: PropTypes.string,
  backToPO: PropTypes.bool,
  role: propTypes.userRole,
};

export default DetailAndCycleContainer;
