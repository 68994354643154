import React, { Fragment } from "react";
import PropTypes from "prop-types";

import SectionHeader from "../../../ui/Typography/SectionHeader";
import createClientFields from "./createClientFields";
import FormGrid from "../../../forms/FormGrid";
import propTypes from "../../../../constants/propTypes";

const GeneralInformationSection = ({
  values,
  errors,
  isUpdate,
  userRole,
  ...otherProps
}) => (
  <Fragment>
    <SectionHeader>{"General Information"}</SectionHeader>
    <FormGrid
      fields={createClientFields(userRole, isUpdate)}
      values={values}
      errors={errors}
      {...otherProps}
    />
  </Fragment>
);

GeneralInformationSection.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  userRole: propTypes.userRole.isRequired,
};

export default GeneralInformationSection;
