import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

export default function collapsibleItemReducer(
  state = initialState.collapsibleItems,
  action
) {
  switch (action.type) {
    case actionTypes.SET_COLLAPSIBLE_ITEM_STATUS: {
      return {
        ...state,
        [action.payload.itemId]: {
          isCollapsed: action.payload.isCollapsed,
        },
      };
    }
    default:
      return state;
  }
}
