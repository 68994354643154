import PropTypes from "prop-types";
import React from "react";
import Item from "./Item";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export const Body = ({ items, ...props }) => {
  let children = [];
  if (props.children) {
    children = Array.isArray(props.children)
      ? props.children
      : [props.children];
  }

  return (
    <DragDropContext onDragEnd={props.onReorder}>
      <Droppable droppableId="mainList">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children.map((child, i) => (
              <Item key={child.key} index={i} {...props} item={items[i]}>
                {child}
              </Item>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onReorder: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      __isDeleted__: PropTypes.bool,
    })
  ),
};

export default Body;
