import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect as formikConnect } from "formik";

import TextInputWithError from "./TextInputWithError";
import propTypes from "../../constants/propTypes";

const getAdditionalProps = (canSet, isVisible, setVisible) => () => {
  if (canSet) {
    return {
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle visibility"
              onClick={() => canSet && setVisible(!isVisible)}
            >
              {isVisible ? (
                <Tooltip title="Disable">
                  <VisibilityOff />
                </Tooltip>
              ) : (
                <Tooltip title="Enable">
                  <Visibility />
                </Tooltip>
              )}
            </IconButton>
          </InputAdornment>
        ),
      },
      inputProps: { readOnly: !isVisible },
    };
  }
  return { value: "xxxxxx", disabled: true };
};

export const SecretInput = ({
  type,
  canSet,
  dispatch,
  formik,
  defaultVisible,
  ...props
}) => {
  const initialValue = formik.initialValues[props.name];
  const [isVisible, setVisible] = useState(defaultVisible);

  const entityIsLoaded = !!formik.initialValues.id;

  useEffect(() => {
    if (
      !isVisible &&
      entityIsLoaded &&
      initialValue === props.value &&
      !initialValue
    ) {
      setVisible(true);
    }
  }, [entityIsLoaded, initialValue, isVisible, props.value]);

  const additionalProps = useMemo(
    getAdditionalProps(canSet, isVisible, setVisible),
    [canSet, isVisible]
  );

  return (
    <TextInputWithError
      {...props}
      type={isVisible ? type : "password"}
      {...additionalProps}
    />
  );
};
SecretInput.propTypes = {
  type: PropTypes.string,
  permission: PropTypes.string,
  canSet: PropTypes.bool,
  defaultVisible: PropTypes.bool,
  formik: propTypes.formik,
};

SecretInput.defaultProps = {
  canSet: true,
};

export const mapStateToProps = ({ auth }, { permission }) => {
  if (!permission) return {};
  return {
    canSet: _get(auth, "role.permissions", []).some(
      ({ name, active }) => name === permission && active
    ),
  };
};

export default connect(mapStateToProps)(formikConnect(SecretInput));
