import React from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import styles from "styled-components";
import _get from "lodash/get";

import FormGrid from "../../../../components/forms/FormGrid";
import propTypes from "../../../../constants/propTypes";
import editSpecDetailDescriptionFields from "./editPreviewFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import { withRevisionReference } from "../../../../withPORevision";

const Wrapper = styles.div`
  textarea {
    resize: vertical;
  }
`;

const EditPreviewForm = ({ specDetail, onSubmit }) => {
  return (
    <FormikForm
      initialValues={specDetail}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("SpecDetail")}
    >
      {({ handleSubmit, values }) => (
        <Wrapper>
          <FormGrid fields={editSpecDetailDescriptionFields()} />
          <ActionButtons
            listeners={["SpecDetail-SpecDetailsChildren"]}
            onSend={handleSubmit}
            additionalProps={{
              send: { disabled: !_get(values, "preview.isUpdated") },
            }}
            sendButtonText="UPLOAD"
            isModal={true}
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};

EditPreviewForm.propTypes = {
  specDetail: propTypes.specDetail,
  onSubmit: PropTypes.func,
};

export default withRevisionReference(EditPreviewForm);
