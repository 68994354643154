import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const IconTooltip = ({ Icon, title, onChange }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const setTooltipIsOpenWrapper = value => evt => {
    setTooltipIsOpen(value);
    if (onChange) {
      onChange(evt);
    }
  };

  return (
    <Tooltip
      open={tooltipIsOpen}
      onOpen={setTooltipIsOpenWrapper(true)}
      onClose={setTooltipIsOpenWrapper(false)}
      title={title}
    >
      <Icon onClick={setTooltipIsOpenWrapper(true)} />
    </Tooltip>
  );
};

IconTooltip.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default IconTooltip;
