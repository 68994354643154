import React, { useContext } from "react";

import FormikForm from "../../forms/FormikForm";
import VerifyCode from "./VerifyCode";
import { LoginContext } from "./LoginContainer";
import { CredentialsCard } from "./CredentialsCard";

export const LoginForm = () => {
  const { onSubmit, defaultEmail, verifyTwoFactor, sentToEmail } = useContext(
    LoginContext
  );

  if (verifyTwoFactor) {
    return <VerifyCode isEmail={sentToEmail} />;
  }

  return (
    <FormikForm
      initialValues={{
        email: defaultEmail,
        password: "",
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => {
        return (
          <CredentialsCard
            formikProps={{ ...formikProps, handleSubmit, values, errors }}
          />
        );
      }}
    </FormikForm>
  );
};

LoginForm.propTypes = {};
