import React from "react";
import PropTypes from "prop-types";

import AddCircle from "@material-ui/icons/AddCircle";
import styled from "styled-components";

import RegularTextSmall from "../../../../../ui/Typography/RegularTextSmall";
import { BWActionButton } from "../../../../../ui/BWActionButton";
import List from "./List";

const Wrapper = styled.div`
  padding: 8px 24px 16px;
`;

const DetailsList = ({ onAdd, onReorder, ...listProps }) => {
  return (
    <Wrapper>
      <RegularTextSmall intent="light">Details</RegularTextSmall>
      <List onReorder={onReorder} {...listProps} />
      <BWActionButton icon={<AddCircle />} text="ADD DETAILS" onClick={onAdd} />
    </Wrapper>
  );
};

DetailsList.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  ...List.propTypes,
};

export default DetailsList;
