import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash.get";
import BWModels from "benjaminwest-models";
import { Link } from "@material-ui/core";

import { inputs } from "../../../../inputs/inputConfigs";
import { FormikForm, ActionButtons } from "../../../../forms";
import CommentsRow from "../CommentsRow";
import commentsInput from "../CommentsRowInput";
import SelectWithError from "../../../../inputs/SelectWithError";
import SelectContactRow from "./SelectContactRow";
import StatusRow from "./StatusRow";
import CheckboxRow from "../CheckboxRow";

import { vendorContactsDataComponentId } from "../../VendorContactsContainer";
import AddressBookContact from "../../../../../models/AddressBookContact";

const selectContactInput = vendorId => {
  return {
    InputComponent: SelectWithError,
    name: "contactId",
    label: "Select Contact",
    dataComponentId: "select-address-book-contact",
    APIOptions: {
      model: AddressBookContact,
      route: "address-book-contacts",
      includes: ["contact"],
      sort: [{ columnName: "contact.name", direction: "asc" }],
      rootFilters: {
        $where: {
          "addressBook.type": "Vendor",
          "contact.status": {
            $notEqual: "inact",
          },
        },
      },
      params: { omitInVendor: vendorId },
      fetchOnlyOnce: true,
      pageSize: -1,
    },
    apiFilterLabelProperty: "contact.name",
    labelProperty: addressBookContact =>
      get(addressBookContact, "contact.name", ""),
    valueProperty: "contactId",
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: false,
    required: true,
  };
};

export const GroupWrapper = styled.div`
  padding: 24px 0px;
`;
export const LinkWrapper = styled.div`
  display: flex;
  color: #9ea7ba;
  font-size: 12px;
  flex-direction: row;
  margin-top: 4px;
`;
export const StyledLink = styled(props => (
  <Link
    href="#"
    type="button"
    component="button"
    variant={"body2"}
    {...props}
  />
))`
  margin-left: 4px;
  color: #57abff;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export default class SelectFromAddressBookForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    closeModalDialog: PropTypes.func,
    vendor: PropTypes.object.isRequired,
    toggleCreateNewContact: PropTypes.func.isRequired,
  };

  render() {
    const { onSubmit, vendor, toggleCreateNewContact } = this.props;
    const { canAccessVendorPortal, contactIsActiveForVendor } = inputs;

    return (
      <FormikForm
        initialValues={{ vendorId: vendor.id, isActive: true }}
        onSubmit={onSubmit}
        validationSchema={BWModels.loadSchema("VendorContact")}
      >
        {({ handleSubmit }) => {
          return (
            <React.Fragment>
              <GroupWrapper>
                <SelectContactRow
                  input={selectContactInput(vendor.id)}
                  handleToggleCreateNewContact={toggleCreateNewContact}
                />
                <StatusRow vendor={vendor} />
                <CheckboxRow
                  isFirst
                  input={canAccessVendorPortal}
                  label={canAccessVendorPortal.label}
                />
                <CheckboxRow
                  input={contactIsActiveForVendor}
                  label={contactIsActiveForVendor.label}
                />
                <CommentsRow input={commentsInput} />
              </GroupWrapper>
              <ActionButtons
                isModal={true}
                onSend={handleSubmit}
                listeners={[vendorContactsDataComponentId]}
              />
            </React.Fragment>
          );
        }}
      </FormikForm>
    );
  }
}
