import styled from "styled-components";
import { InputLabel } from "@material-ui/core";

export default styled(InputLabel)`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);

  & .root {
    & .focused {
      color: #ff0000;
    }
  }
`;
