import React, { useState, useMemo, useCallback, memo } from "react";
import { connect } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import InputLoader from "../ui/Loader/InputLoader";
import { processChange } from "./FilePickerWithError";
import { useIsProjectClosed } from "../hooks/useIsProjectClosed";
import DragDropInputFile from "./DragDrop/DragDropInputFile";
import { useModalControl } from "./DragDrop/DragDropInputFile/useModalControl";

export const ErrorMessage = styled.div`
  font-size: 12px;
  color: #ff1744;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 53%;
  font-family: Open Sans, sans-serif;
  color: rgba(0, 0, 0, 0.54);
`;

export const Link = styled.a`
  text-decoration: none;
  color: #57abff;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.54);
`;

export const FileName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.87);
`;

const FilePickerAndDownloadWithError = ({
  label,
  value: initValue,
  ignoreCaption,
  onChange,
  name,
  inputFileProps = {},
  useFileWrapper,
}) => {
  const [value, setValue] = useState(initValue);

  const { open, onOpen, onClose } = useModalControl();

  const isProjectClosed = useIsProjectClosed();

  const handleClick = useCallback(
    evt => {
      evt.stopPropagation();
      onOpen();
    },
    [onOpen]
  );

  const handleChange = useCallback(
    evt => {
      const files = Array.prototype.slice.call(evt.target.files);
      const newValue = processChange(
        files,
        null,
        useFileWrapper,
        inputFileProps.multiple,
        value
      );
      setValue(newValue);
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
    },
    [useFileWrapper, inputFileProps.multiple, value, onChange, name]
  );

  const size = useMemo(() => {
    return (_get(value, "metadata.size", 0) / (1024 * 1024)).toFixed(2);
  }, [value]);

  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <DragDropInputFile
        open={open}
        onClose={onClose}
        onChange={handleChange}
        {...inputFileProps}
      />
      <Label>{label}</Label>
      <FileName>{value && value.filename}</FileName>
      <ActionWrapper>
        <div>{size} MB</div>-
        <div>
          Click to{" "}
          <Link href={value && value.s3SignedUrl} download>
            Download
          </Link>
        </div>
        |
        <div>
          <Link onClick={!isProjectClosed ? handleClick : undefined}>
            Replace File
          </Link>
        </div>
      </ActionWrapper>
    </InputLoader>
  );
};

FilePickerAndDownloadWithError.propTypes = {
  useFileWrapper: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default connect(memo(FilePickerAndDownloadWithError));
