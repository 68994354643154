import React, { useCallback, useContext, useEffect, useRef } from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import _get from "lodash/get";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../../../../constants/propTypes";
import AutocompleteSelect from "../../../../inputs/SelectWithError/AutocompleteSelect/AutocompleteSelect";
import { LoaderContext } from "../../../Loader";

export const Select = styled(AutocompleteSelect)`
  width: 100%;
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : "")}
`;

export const EditorComponent = ({ column, onValueChange, value }) => {
  const { loading } = useContext(LoaderContext);

  const prevValue = useRef(null);
  const handleChange = useCallback(
    ({ target: { value } }) => {
      onValueChange(value);
    },
    [onValueChange]
  );

  useEffect(() => {
    if (
      value.length === 0 &&
      prevValue.current === null &&
      column?.filterOptions?.initialOptions
    ) {
      handleChange({ target: { value: column.filterOptions.initialOptions } });
    }

    if (value !== prevValue) {
      prevValue.current = value;
    }
  }, [column, handleChange, value]);

  return (
    <Select
      options={column.filterOptions.options}
      onChange={handleChange}
      value={value}
      isDisabled={loading}
      isAutocompleteMulti
      minWidth={column.minWidth}
    />
  );
};

EditorComponent.defaultProps = {
  value: [],
};

EditorComponent.propTypes = {
  column: PropTypes.shape({
    filterOptions: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  onValueChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

const StaticMultiSelectTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(column => {
    const options = columnOptions[column];
    return (
      options.filter === "multiselect" && _get(options, "filterOptions.options")
    );
  });
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={EditorComponent} />
  ) : null;
};
StaticMultiSelectTypeProvider.propTypes = {
  ...propTypes.filteringComponent,
  initialFilters: PropTypes.array,
};

export default StaticMultiSelectTypeProvider;
