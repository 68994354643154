import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { noop, isEmpty } from "lodash";

import { CollapseButton } from "./components";
import EntityRowContent from "./EntityRowContent";
import Loader from "../../../../../ui/Loader";
import { FormControlLabel as RawFormControlLabel } from "../../../../../mui/core";

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormControlLabel = styled(RawFormControlLabel)`
  margin-left: 0px;
`;

const handleCollapseChange = ({
  entityName,
  isCollapsed,
  isCollapsedRef,
  node,
  root,
  setRoot = noop,
  onExpand,
  onCollapse,
  onUpdate,
  expandedChildren,
}) => () => {
  if (isCollapsedRef.current === isCollapsed) {
    return;
  }

  isCollapsedRef.current = isCollapsed;

  if (!isCollapsed) {
    onExpand(entityName, node);
    setRoot(node);
    onUpdate();
    if (root) {
      root.children[entityName] = node;
    }
    return;
  }

  onCollapse(entityName, node);
  Object.keys(expandedChildren).forEach(entityName =>
    onCollapse(entityName, expandedChildren[entityName])
  );
  setRoot(null);
  onUpdate();
  if (root) {
    delete root.children[entityName];
  }
};

const buildOnToggleProp = (node, onUpdate) => (
  checked,
  { columnName, ...prop }
) => {
  if (checked) {
    node.current.properties[columnName] = { columnName, ...prop };
    onUpdate();
    return;
  }
  const column = node.current.properties[columnName];
  delete node.current.properties[columnName];
  onUpdate(column);
};

const buildOnExpand = ({ expandedChildren, nodeSet, onExpand, onUpdate }) => (
  entityName,
  node
) => {
  expandedChildren.current[entityName] = node;
  nodeSet.add(entityName);
  onExpand(entityName, node);
  onUpdate();
};

const buildOnCollapse = ({
  expandedChildren,
  nodeSet,
  onCollapse,
  onUpdate,
}) => (entityName, node) => {
  delete expandedChildren.current[entityName];
  nodeSet.delete(entityName);
  onCollapse(entityName, node);
  if (node?.properties) {
    node.properties = {};
  }
  onUpdate();
};

const EntityRow = ({
  id,
  root,
  setRoot,
  onUpdate,
  name,
  entityName,
  relationName,
  endpoint,
  onExpand,
  onCollapse,
  nodeSet,
  properties = {},
  childNodes = {},
  parentDataComponentId,
}) => {
  const preLoadCollapsed = isEmpty(properties);
  const [isCollapsed, setIsCollapsed] = useState(preLoadCollapsed);
  const expandedChildren = useRef(childNodes);
  const isCollapsedRef = useRef(preLoadCollapsed);
  const node = useRef({
    entityName,
    relationName,
    properties,
    children: childNodes,
  });

  useEffect(() => {
    node.current = {
      entityName,
      relationName,
      properties,
      children: childNodes,
    };
  }, [childNodes, entityName, properties, relationName]);

  useEffect(
    handleCollapseChange({
      id,
      entityName,
      isCollapsed,
      isCollapsedRef,
      root,
      node: node.current,
      setRoot,
      onUpdate,
      onExpand,
      onCollapse,
      expandedChildren: expandedChildren.current,
    }),
    [isCollapsed, entityName, setRoot, onExpand, onCollapse]
  );

  const setIsCollapsedWrapper = isCollapsed => {
    if (!isCollapsed && nodeSet.has(entityName)) return;
    setIsCollapsed(isCollapsed);
  };

  return (
    <Loader count={10} height={"14px"}>
      <Wrapper>
        <Header>
          <FormControlLabel
            control={
              <CollapseButton
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsedWrapper}
              />
            }
            label={name}
          />
        </Header>
        {!isCollapsed && (
          <EntityRowContent
            id={id}
            node={node.current}
            endpoint={endpoint}
            entityName={entityName}
            name={name}
            onExpand={buildOnExpand({
              expandedChildren,
              nodeSet,
              onExpand,
              onUpdate,
            })}
            onCollapse={buildOnCollapse({
              expandedChildren,
              nodeSet,
              onCollapse,
              onUpdate,
            })}
            onUpdate={onUpdate}
            nodeSet={nodeSet}
            expandedChildren={expandedChildren.current}
            onToggleProp={buildOnToggleProp(node, onUpdate)}
            parentDataComponentId={parentDataComponentId}
          />
        )}
      </Wrapper>
    </Loader>
  );
};

EntityRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  relationName: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  root: PropTypes.object,
  setRoot: PropTypes.func,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  onUpdate: PropTypes.func,
  nodeSet: PropTypes.object,
  properties: PropTypes.object,
  childNodes: PropTypes.object,
  parentDataComponentId: PropTypes.string.isRequired,
};

export default EntityRow;
