import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import CreateIcon from "@material-ui/icons/Create";
import RawCard from "@material-ui/core/Card";
import RawSubheader from "../../ui/Typography/Subheader";
import CircularProgress from "@material-ui/core/CircularProgress";
export const Progress = styled(CircularProgress)`
  margin-left: 15px;
  margin-top: 2px;
`;

export const Subheader = styled(RawSubheader)`
  padding: 24px 24px 0px 24px;
`;

export const Card = styled(RawCard)`
  padding: 28px 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.38);
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const EditIcon = styled(CreateIcon)`
  && {
    cursor: pointer;
    margin-left: 10px;
    color: #979797;
    font-size: 12px;
    width: 12px;
    height: 12px;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const RemoveIcon = styled(CancelIcon)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.24);
`;

export const AddIcon = styled(AddCircleOutlineIcon)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.38);
`;

export const DownloadIcon = styled(GetAppIcon)`
  cursor: pointer;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.54);
`;

export const RemoveIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ItemRowWrapper = styled.div`
  font-size: 14px;
  color: ${({ isEmpty }) => (isEmpty ? "#57abff" : "rgba(0, 0, 0, 0.87)")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:hover {
    color: ${({ isEmpty }) => (isEmpty ? "#57abff" : "rgba(0, 0, 0, 0.50)")};
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`;

export const AdditionalSection = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  width: 40%;
`;
