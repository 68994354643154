import React, { useCallback } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import ProjectAddressGrid from "./ProjectAddressGrid";
import { dataComponentIdDeleteAddress } from "../../../../../actions/projectsActions";

const buildOpenAddAddressHandler = (
  openModalDialog,
  formikArrayHelpers,
  projectId
) => () => {
  openModalDialog("Create Address", "CreateEditAddress", {
    formikArrayHelpers,
    hideContacts: true,
    address: {
      name: undefined,
      projectId,
      location: {
        address: undefined,
        address2: undefined,
        city: undefined,
        zip: undefined,
        country: undefined,
        state: undefined,
      },
      contacts: [],
    },
  });
};

const buildOpenEditAddressHandler = (
  openModalDialog,
  formikArrayHelpers,
  selectedProperty
) => (address, { rowId }) => {
  openModalDialog(`Edit Address ${address.name}`, "CreateEditAddress", {
    formikArrayHelpers,
    hideContacts: true,
    address,
    index: selectedProperty ? rowId - 1 : rowId,
  });
};

const buildOpenDeleteAddressHandler = (
  openModalDialog,
  formikArrayHelpers,
  selectedProperty
) => ({ id, name }, { rowId }) => {
  openModalDialog(
    `Delete ${name}`,
    "DeleteAddress",
    {
      addressId: id,
      dataComponentId: dataComponentIdDeleteAddress,
      title: name,
      formikArrayHelpers,
      index: selectedProperty ? rowId - 1 : rowId,
    },
    false
  );
};

const RenderedField = ({
  formikArrayHelpers,
  selectedProperty,
  rows,
  projectId,
  openModalDialog,
}) => {
  const handleOpenAddModal = useCallback(
    buildOpenAddAddressHandler(openModalDialog, formikArrayHelpers, projectId),
    [openModalDialog, formikArrayHelpers, projectId]
  );

  const handleOpenEditModal = useCallback(
    buildOpenEditAddressHandler(
      openModalDialog,
      formikArrayHelpers,
      selectedProperty
    ),
    [openModalDialog, formikArrayHelpers, selectedProperty]
  );

  const handleOpenDeleteModal = useCallback(
    buildOpenDeleteAddressHandler(
      openModalDialog,
      formikArrayHelpers,
      selectedProperty
    ),
    [openModalDialog, formikArrayHelpers, selectedProperty]
  );

  return (
    <ProjectAddressGrid
      rows={rows}
      onOpenAddModal={handleOpenAddModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
    />
  );
};
RenderedField.propTypes = {
  projectId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  rows: PropTypes.arrayOf(propTypes.remitAddress),
  openModalDialog: PropTypes.func.isRequired,
  selectedProperty: propTypes.property,
};

export default RenderedField;
