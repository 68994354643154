import React, { useMemo } from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import styled from "styled-components";
import pluralize from "pluralize";
import _get from "lodash/get";

import propTypes from "../../../../constants/propTypes";
import BWGridLocal from "../../../ui/BWGrid/BWGridLocal";
import GridHeader from "../../../ui/BWGrid/GridHeader";
import GridTable from "../../../ui/BWGrid/GridTable";
import LocationCombined from "../../../ui/LocationCombined";

const Wrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

const columns = [
  "name",
  "location.address",
  "location.city",
  "location.state",
  "location.zip",
];
export const getColumnsOptions = (cities, states) => ({
  name: {
    bold: true,
    filter: true,
  },
  "location.address": {
    title: "Address",
    render: row =>
      row && (
        <LocationCombined
          location={row.location}
          parts={["address", "address2"]}
          ignoreZip
        />
      ),
  },
  "location.city": {
    title: "City",
    filter: "select",
    filterOptions: {
      operator: "equal",
      options: cities,
    },
  },
  "location.state": {
    title: "State",
    filter: "select",
    filterOptions: {
      operator: "equal",
      options: states,
    },
  },
  "location.zip": {
    title: "ZIP Code",
    fill: true,
  },
});
export const getRowMenu = (onEdit, onDelete) => () => [
  { text: "Edit", onClick: onEdit, disableIfProjectClosed: true },
  { separator: true },
  { text: "Delete", onClick: onDelete, disableIfProjectClosed: true },
];

const addToFilterOptions = (value, options) => {
  if (typeof value !== "undefined" && !options.find(({ id }) => id === value)) {
    options.push({ id: value, name: value });
  }
};

const ProjectAddressGrid = ({
  rows,
  onOpenAddModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  const headerActions = useMemo(
    () => [
      {
        text: "ADD SITE",
        icon: <AddCircle />,
        handler: onOpenAddModal,
        disableIfProjectClosed: true,
      },
    ],
    [onOpenAddModal]
  );

  const rowMenu = useMemo(
    () => getRowMenu(onOpenEditModal, onOpenDeleteModal),
    [onOpenEditModal, onOpenDeleteModal]
  );

  const { gridConfig, columnOptions } = useMemo(() => {
    const cities = [];
    const states = [];
    rows.forEach(site => {
      const location = _get(site, "location", {});
      addToFilterOptions(location.state, states);
      addToFilterOptions(location.city, cities);
    });
    return {
      gridConfig: { pageSize: 0, totalRows: rows.length },
      columnOptions: getColumnsOptions(cities, states),
    };
  }, [rows]);

  return (
    <Wrapper>
      <BWGridLocal gridConfig={gridConfig} rows={rows}>
        <GridHeader
          subheaderText={pluralize("Ship to Site", rows.length, true)}
          actions={headerActions}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu}
        />
      </BWGridLocal>
    </Wrapper>
  );
};
ProjectAddressGrid.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contingency),
  onOpenAddModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default ProjectAddressGrid;
