import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";

import { ActionButton } from "../../../../ui/BWActionButton";
import DeleteRequirementButton from "./DeleteRequirementButton";
import propTypes from "../../../../../constants/propTypes";

const Row = styled.div`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  align-items: center;
`;

const ActionRow = ({
  onDisplayUploadFormClick,
  onDeleteRequirement,
  specRequirement,
}) => {
  return (
    <Row>
      <ActionButton
        icon={<AddCircle />}
        onClick={onDisplayUploadFormClick}
        text="UpLoad File"
        disableIfProjectClosed
      />
      <DeleteRequirementButton
        onSubmit={onDeleteRequirement}
        specRequirement={specRequirement}
        disableIfProjectClosed
      />
    </Row>
  );
};

ActionRow.propTypes = {
  specRequirement: propTypes.specRequirement,
  onDisplayUploadFormClick: PropTypes.func.isRequired,
  onDeleteRequirement: PropTypes.func.isRequired,
};

export default ActionRow;
