// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Program model belongsTo relations 1`] = `
Array [
  "Flag",
]
`;

exports[`Program model hasMany relations 1`] = `
Array [
  "Discount",
]
`;
