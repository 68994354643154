import React, { useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import {
  deleteFile,
  specFilesDataComponentId,
  uploadFiles,
} from "../../../../../../actions/specDetailActions";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import MainSection from "./MainSection";
import { Wrapper, Header } from "./components";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import {
  useDeleteProcessRequests,
  useCreateProcessRequests,
} from "../../../../../hooks/useProcessRequest";
import useActions from "../../../../../hooks/useActions";
import Subheader from "../../../../../ui/Typography/Subheader";
import { DragDropDashboard } from "../../../../../inputs/DragDrop/DragDropDashboard";

const getAcceptOption = acceptImage =>
  acceptImage ? ".pdf,.jpg,.png,.gif" : ".pdf";

const AddSpecFile = ({ fileType, acceptImage, specId, isLabeled }) => {
  const [isUploadActive, setUploadActive] = useState();
  const [activeDelete, setActiveDelete] = useState(null);

  const dataComponent = useDatacomponent(specFilesDataComponentId);
  const actions = useActions({ deleteFile, uploadFiles });

  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      setActiveDelete(null);
    },
  });

  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      setUploadActive(false);
    },
  });

  const handleUpload = evt => {
    setUploadActive(true);
    actions.uploadFiles(evt.target.files, specId, fileType);
  };

  const handleDelete = specFile => {
    setActiveDelete(specFile.id);
    actions.deleteFile(specFile.id);
  };

  const specFiles = useV2DatacomponentResources(
    specFilesDataComponentId,
    [],
    RequestTypes.LIST
  );

  const files = specFiles.filter(specFile => specFile.key === fileType);

  return (
    <Wrapper>
      <Header>
        <Subheader>{pluralize("File", files.length, true)}</Subheader>
      </Header>
      <MainSection
        files={files}
        isUploadActive={isUploadActive}
        activeDelete={activeDelete}
        onDelete={handleDelete}
        inputId="spec-file"
        isLabeled={isLabeled}
      />
      {!isLabeled && (
        <DragDropDashboard
          onChange={handleUpload}
          accept={getAcceptOption(acceptImage)}
          height="100%"
          multiple
        />
      )}
    </Wrapper>
  );
};

AddSpecFile.propTypes = {
  fileType: PropTypes.string,
  acceptImage: PropTypes.bool,
  specId: PropTypes.string,
  isLabeled: PropTypes.bool,
};

export default AddSpecFile;
