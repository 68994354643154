import React from "react";
import { Field as FormikField } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";

import Grid from "../mui/core/Grid";
import InputWrapper from "../inputs/InputWrapper";
import { hasError, getErrorMessage } from "../../utils/formValidationUtils";
import { withRevisionReference } from "../../withPORevision";

export const GroupWrapper = styled.div`
  padding: 24px;
  &:not(:last-child) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }
`;

export const Field = ({ value, name, children: render }) => {
  return value ? (
    render({ field: { value }, form: { errors: {} } })
  ) : (
    <FormikField name={name}>{render}</FormikField>
  );
};

Field.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  name: PropTypes.string,
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export const Input = ({ input }) => {
  return (
    <Field value={input.value} name={input.name}>
      {({ field, form }) => (
        <InputWrapper
          {...input}
          {...field}
          hasError={hasError(
            input.name.replace(`['`, "").replace(`']`, ""),
            form
          )}
          errorMessage={getErrorMessage(
            input.name.replace(`['`, "").replace(`']`, ""),
            form
          )}
        />
      )}
    </Field>
  );
};

const Group = ({ items }) => (
  <GroupWrapper>
    <Grid container>
      {items.map(({ input, element, grid, disableFormik }, index) => (
        <Grid item {...grid} key={index}>
          {element ? (
            element
          ) : input ? (
            <Input input={input} disableFormik={disableFormik} />
          ) : null}
        </Grid>
      ))}
    </Grid>
  </GroupWrapper>
);

Group.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ input: PropTypes.object, grid: PropTypes.object })
  ),
};

export const FormGrid = ({ fields: { groups } }) => {
  return (
    <div>
      {groups.map(({ items }, index) => (
        <Group key={index} items={items} />
      ))}
    </div>
  );
};

FormGrid.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default withRevisionReference(FormGrid);
