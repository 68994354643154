import Note from "../Note";

describe("Note model", () => {
  test("belongsTo relations", () => {
    expect(Note.belongsTo.map(relation => relation.name)).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(Note.hasMany.map(relation => relation.name)).toMatchSnapshot();
  });
});
