import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Remove from "@material-ui/icons/Remove";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import { inputs } from "../../../../components/inputs/inputConfigs";
import { GridLocalSection } from "../../../layout/GridLocalSection";
import { calcQtyNeeded } from "./calcFunctions";
import { editableCellCreator } from "../../../../components/ui/BWGrid/EditableCell";
import { StyledBWTableRow } from "../../../../components/ui/BWGrid/gridInternalComponents/BWTableRow";
import { StyledBWCell } from "../../../../components/ui/BWGrid/gridInternalComponents/BWCell";
import { Grid } from "@devexpress/dx-react-grid-material-ui";
import propTypes from "../../../../constants/propTypes";

export const tablePropTypes = {
  addClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onUpdateRow: PropTypes.func.isRequired,
};

const rowInputs = {
  name: inputs.baseName,
  roomCount: inputs.roomCount,
  perRoom: inputs.perRoom,
  par: inputs.par,
};

const TableWrapper = styled.div`
  max-height: 454px;
  ${StyledBWTableRow} {
    &:last-child > ${StyledBWCell} {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
`;

const StyledGridRoot = styled(Grid.Root)`
  overflow: visible;
  > div {
    overflow: visible;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || "flex-start"};
  > svg {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const EmptyIconPlaceholder = styled.div`
  width: 24px;
`;

const DeleteCell = ({ row, onRemoveClick }) => (
  <IconWrapper justify="flex-end">
    {!row.areaRoomId ? (
      <IconButton onClick={() => onRemoveClick(row.index)}>
        <Delete />
      </IconButton>
    ) : (
      <EmptyIconPlaceholder />
    )}
  </IconWrapper>
);

DeleteCell.propTypes = {
  row: propTypes.areaRoom,
  onRemoveClick: PropTypes.func,
};

function renderColumnFactory(column) {
  return row =>
    row[column] || (
      <IconWrapper>
        <Remove />
      </IconWrapper>
    );
}

const columns = [
  "name",
  "roomCount",
  "perRoom",
  "par",
  "quantityNeeded",
  "delete",
];

const columnsOptions = onRemoveClick => {
  const RowDeleteCell = row => (
    <DeleteCell row={row} onRemoveClick={onRemoveClick} />
  );

  return {
    name: { title: "Room", width: "140px", editable: true },
    roomCount: {
      title: "Room Qty",
      width: "50px",
      render: renderColumnFactory("roomCount"),
      editable: true,
    },
    perRoom: {
      width: "50px",
      render: renderColumnFactory("perRoom"),
      editable: true,
    },
    par: {
      title: "PAR",
      fill: true,
      render: renderColumnFactory("par"),
      editable: true,
    },
    quantityNeeded: {
      align: "right",
      width: "100px",
      render: row =>
        calcQtyNeeded(row) || (
          <IconWrapper justify="flex-end">
            <Remove />
          </IconWrapper>
        ),
    },
    delete: {
      title: " ",
      width: "48px",
      render: RowDeleteCell,
    },
  };
};

const addButtonText = "ADD ROOM TYPE";

const RoomTypeTable = ({
  onRemoveClick,
  onUpdateRow,
  addClick,
  formikProps,
  ...gridSectionProps
}) => {
  const getFieldError = useCallback(
    (tableRow, column) => {
      if (formikProps.status == "submitted") {
        return formikProps.errors[
          `roomTypes[${tableRow.rowId}].${column.name}`
        ];
      }
      return null;
    },
    [formikProps.errors, formikProps.status]
  );

  const tableComponent = useMemo(() => {
    return {
      CellComponent: editableCellCreator(
        rowInputs,
        (tableRow, column) => `roomTypes[${tableRow.rowId}].${column.name}`,
        false,
        (tableRow, column) => getFieldError(tableRow, column)
      ),
    };
  }, [getFieldError]);

  return (
    <TableWrapper>
      <GridLocalSection
        {...gridSectionProps}
        columns={columns}
        columnsOptions={columnsOptions(onRemoveClick)}
        addClick={addClick}
        addButtonText={addButtonText}
        tableComponents={tableComponent}
        rootComponent={StyledGridRoot}
        revisionReference="roomTypes"
        editableNavigationDirection="horizontal"
        noBorder
      />
    </TableWrapper>
  );
};

RoomTypeTable.propTypes = {
  ...tablePropTypes,
  formikProps: PropTypes.object,
  gridHeaderText: PropTypes.string.isRequired,
};

export default RoomTypeTable;
