import React from "react";

import fields from "./fields";
import propTypes from "../../../../constants/propTypes";
import { FormPageSection } from "../../../layout/FormPageSection";
import withLabeledTextOption from "../../../../withPORevision/withLabeledTextOption";

const QuoteSection = ({ purchaseOrder, freightVendor, ...formikProps }) => {
  return (
    <FormPageSection
      fields={fields(purchaseOrder)}
      headerText="Quote Details"
      {...formikProps}
    />
  );
};

QuoteSection.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  freightVendor: propTypes.vendor,
};

export default withLabeledTextOption(QuoteSection);
