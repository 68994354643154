import React from "react";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";

export const EditIcon = styled(CreateIcon)`
  && {
    cursor: pointer;
    color: #979797;
    font-size: 12px;
    top: calc(50% - 8px);
    right: 10px;
    width: 12px;
    height: 12px;
  }
`;
export const StyledSelect = styled(Select)`
  color: "rgba(0, 0, 0, 0.87)";
  background-color: "transparent";
  font-family: "Open Sans", sans-serif;
  svg {
    color: "rgba(0, 0, 0, 0.87)";
  }

  &::before {
    border-bottom: none;
  }

  &::after {
    border-bottom: none;
  }
  &:hover {
    border-bottom: none;
    &::before {
      border-bottom: none;
    }

    &::after {
      border-bottom: none;
    }
  }
  &:focus {
    border-bottom: none;
  }
`;

export const renderSelectCell = (
  row,
  options = [],
  { isProjectClosed, ...additionalProps } = {}
) => {
  const { name } = additionalProps;

  const IconComponent = isProjectClosed ? undefined : EditIcon;

  return (
    <StyledSelect
      {...additionalProps}
      displayEmpty
      value={row[name]}
      IconComponent={IconComponent}
      disabled={isProjectClosed}
    >
      {options.map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default StyledSelect;
