import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../../../../constants/propTypes";

const StyledPopper = styled(Popper)`
  z-index: 1;
  background: "#fff";
  width: 200px;
  position: "absolute";
  top: ${props => (props.top ? props.top + "px" : "0px")};
  left: ${props => (props.left ? props.left + "px" : "0px")};
`;

const MentionMenu = ({
  openMenu,
  handleClose,
  onSelectOption,
  top,
  left,
  users,
  anchorEl,
}) => {
  const handlerMenuAction = option => event => {
    event.preventDefault();
    onSelectOption(option);
  };
  // Review Notes: autoFocusItem in MenuList element is not available in MUI v3.
  return (
    <StyledPopper
      open={openMenu}
      onClose={handleClose}
      anchorEl={anchorEl}
      top={top}
      left={left}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                {users.map(user => (
                  <MenuItem onClick={handlerMenuAction(user)} key={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </StyledPopper>
  );
};

MentionMenu.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
  users: PropTypes.arrayOf(propTypes.user),
  anchorEl: PropTypes.shape({}),
};

MentionMenu.defaultProps = {
  users: [],
};

export default MentionMenu;
