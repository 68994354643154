import React, { Fragment } from "react";

import FormGrid from "../../../../forms/FormGrid";
import FormikForm from "../../../../forms/FormikForm";
import SectionHeader from "../../../../ui/Typography/SectionHeader";
import ActionButtons from "../../../../forms/ActionButtons";
import createShipmentFields from "./createShipmentFields";

const shipmentInitialValues = {
  quantity: 0,
  trackingNumber: undefined,
  receivedBy: undefined,
  estimatedShipDate: undefined,
  estimatedDeliveryDate: undefined,
  actualShipDate: undefined,
  actualDeliveryDate: undefined,
  notes: "",
  specId: "-1",
};

const CreateShipmentForm = ({
  schema,
  remainingQty,
  onSubmit,
  estimatedDeliveryDate,
  estimatedShipDate,
  loading,
  onCancel,
  totalQuantityApprovedToPay,
}) => {
  return (
    <Fragment>
      <SectionHeader>Shipment Notes</SectionHeader>
      <FormikForm
        initialValues={{
          ...shipmentInitialValues,
          estimatedDeliveryDate,
          estimatedShipDate,
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
          return (
            <Fragment>
              <FormGrid
                fields={createShipmentFields(
                  remainingQty,
                  values,
                  setFieldValue,
                  {},
                  totalQuantityApprovedToPay
                )}
                values={values}
                errors={errors}
                ignoreValidationFieldNames={["specId"]}
                {...formikProps}
              />
              <ActionButtons
                onSend={handleSubmit}
                loading={loading}
                isModal
                onCancel={onCancel}
              />
            </Fragment>
          );
        }}
      </FormikForm>
    </Fragment>
  );
};

export default CreateShipmentForm;
