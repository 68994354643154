import { isSuperAdmin } from "../../../../utils/roleUtils";
import { inputs } from "../../../inputs/inputConfigs";

export default (role, isUpdate) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.name,
            required: true,
            label: "Name",
          },
          grid: { xs: 12 },
        },
        {
          input: inputs.consultingCompanyType,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.location,
            required: true,
          },
          grid: { xs: 12 },
        },
        {
          input: inputs.locationAddress2,
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.locationCity,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.locationState,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.locationCountry,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.locationPostalCode,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: isSuperAdmin(role)
            ? { ...inputs.scopeId, isDisabled: isUpdate }
            : null,
          grid: { xs: 6 },
        },
      ],
    },
  ],
});
