import React, { useEffect, useMemo, memo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";
import omitBy from "lodash/omitBy";
import NoteEditPage from "./NoteEditPage";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
  performDeleteRequest,
  setReload,
  fetchData,
} from "../../../../actions/dataComponentActions";

import { noteDetailOpen } from "../../../../actions/notesActions";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import {
  processUpdateRequestStatus,
  getActiveRequest,
  processDeleteRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { noteCreateDetailClose } from "../../../../actions/notesActions";
import { dataComponentIds } from "../NoteCreate/AssignToSelect/AssignToSelectContainer";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader";
import {
  fetchNoteDependencies,
  handleError,
  generateNoteData,
} from "../NoteCreate/NoteCreateContainer";
import { dataComponentId } from "../NoteList/NoteListContainer";

export const handleSuccess = (
  showSnackNotificationAction,
  fetchData,
  setReload,
  noteDetailOpen
) => () => {
  fetchData(dataComponentId);
  setReload(dataComponentId, true);
  if (noteDetailOpen) {
    noteDetailOpen(null);
    showSnackNotificationAction("Note has been deleted successfully");
  } else {
    showSnackNotificationAction("Note has been saved successfully");
  }
};

const processSaveRequest = (
  noteCreateDetailClose,
  fetchData,
  setReload,
  showSnackNotificationAction,
  dataComponent,
  prevDataComponent,
  noteDetailOpen
) => () => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(showSnackNotificationAction, fetchData, setReload),
    onError: handleError(showSnackNotificationAction),
  });

  processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(
      showSnackNotificationAction,
      fetchData,
      setReload,
      noteDetailOpen
    ),
    onError: handleError(showSnackNotificationAction),
  });
};

export const NoteEditContainer = ({
  fetchData,
  setReload,
  initDataComponent,
  performRetrieveListRequest,
  noteCreateDetailClose,
  performUpdateRequest,
  performDeleteRequest,
  noteDetailOpen,
  dataComponent,
  showSnackNotificationAction,
  userId,
  loading,
  note,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(
    fetchNoteDependencies(initDataComponent, performRetrieveListRequest, {}),
    [initDataComponent]
  );

  useEffect(
    processSaveRequest(
      noteCreateDetailClose,
      fetchData,
      setReload,
      showSnackNotificationAction,
      dataComponent,
      prevDataComponent,
      noteDetailOpen
    )
  );

  const handleUpdateNote = useCallback(
    ({ mentions = [], ...note }) => {
      note.mentions = mentions.map(mention => {
        if (typeof mention === "object") return mention;
        return note.users.find(user => user.id === mention.toString());
      });
      performUpdateRequest(dataComponentId, note.id, generateNoteData(note));
    },
    [performUpdateRequest]
  );

  const handleDeleteNote = useCallback(() => {
    performDeleteRequest(dataComponentId, note.id);
  }, [note.id, performDeleteRequest]);

  const parsedNote = useMemo(() => {
    const cleanNote = omitBy(note, value => value === null);
    const { detail, users } = cleanNote;
    if (!detail || !users || users.length === 0) return cleanNote;
    const parsedDescription = users.reduce((detail, user) => {
      return detail.replace(RegExp(`\\[\\~${user.id}\\]`, "g"), user.name);
    }, detail);
    return { ...cleanNote, detail: parsedDescription };
  }, [note]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <NoteEditPage
        userId={userId}
        category={_get(note, "category")}
        onDeleteNote={handleDeleteNote}
        onUpdateNote={handleUpdateNote}
        onCancel={noteCreateDetailClose}
        note={parsedNote}
      />
    </LoaderContext.Provider>
  );
};

NoteEditContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  noteCreateDetailClose: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  dataComponent: PropTypes.shape({}).isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  note: propTypes.note,
  performDeleteRequest: PropTypes.func.isRequired,
  noteDetailOpen: PropTypes.func.isRequired,
};

NoteEditContainer.defaultProps = {
  loading: false,
};

export const mapStateToProps = ({ auth: { userId }, ...state }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const projectsDataComponent = getDataComponent("select-projects", state);
  const dataComponents = Object.keys(dataComponentIds).reduce(
    (sources, key) => {
      sources[key] = getDataComponentFlattenedRequestState(
        dataComponentIds[key],
        state
      );
      return sources;
    },
    {}
  );
  return {
    userId,
    dataComponent,
    loading:
      _get(
        dataComponent,
        `requestState.${getActiveRequest(dataComponent)}.loading`
      ) ||
      _get(projectsDataComponent, "requestState.list.loading") ||
      Object.values(dataComponents).some(
        dataComponent => dataComponent.loading
      ),
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  noteCreateDetailClose,
  performUpdateRequest,
  performDeleteRequest,
  showSnackNotificationAction,
  noteDetailOpen,
  fetchData,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(NoteEditContainer));
