import React, { forwardRef } from "react";
import { TableRow } from "./TableRow";
import styled from "styled-components";
import {
  Table,
  TableHeaderRow,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import { StyledBWTableRow } from "./gridInternalComponents/BWTableRow";
import { useRowReference } from "../../mui/@devexpress/dx-react-grid-material-ui/Row";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";

export const StyledBWTableContainer = styled(Table.Container)`
  overflow-y: visible;
  position: relative;
  z-index: 0;
`;

export const StyledBWHeadRow = styled(TableRow)`
  vertical-align: unset;
  height: unset;
`;
export const StyledBWHeadCell = styled(TableHeaderRow.Cell)`
  padding-bottom: 16px;
  padding-top: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ column: { bold } }) =>
    bold ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.54)"};
  svg {
    display: ${({ column }) =>
      column.sortingEnabled === false ? "none" : null};
  }
  width: ${({ column }) => column.width};
`;

export const StyledBWSelectHeader = styled(TableSelection.HeaderCell)`
  padding-left: 24px;
  padding-right: 6px;

  > span {
    width: 24px;
    height: 24px;
  }
`;

export const StyledBWSelectCell = styled(TableSelection.Cell)`
  padding-left: 24px;
  padding-right: 6px;
  border-bottom: none;

  cursor: ${({ disabled }) => (disabled ? "unset" : null)};
  > span {
    width: 24px;
    height: 24px;
    cursor: ${({ disabled }) => (disabled ? "unset" : null)};
  }

  ${({ disabled }) =>
    disabled
      ? `
      svg {
    color: rgba(0, 0, 0, 0.14);
    }`
      : null}
`;

export const StyledBWToggleCell = styled(Table.Cell)`
  padding-left: 16px !important;
  padding-right: 0px;
`;

export const StyledBWDetailCell = styled(Table.Cell)`
  padding: 0 !important;
  cursor: unset;
`;

export const StyledBWDetailRow = styled(StyledBWTableRow)({});

export const LoadingContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: absolute;
  height: 100%;
  background: #ffffff4d;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEditableWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  background: #f1f1f1;
  border-radius: 3px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 4px 8px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  width: max-content;
  margin-top: 2px;
  margin-bottom: 2px;
  span {
    margin-right: 8px;
  }
  > * {
    display: inline-block;
  }
`;

export const EditableWrapper = forwardRef(
  ({ ignoreProjectClosed, ...props }, ref) => {
    const { toggleRowHover } = useRowReference();
    const isProjectClosed = useIsProjectClosed();

    const isDisabled = isProjectClosed && !ignoreProjectClosed;

    const onClick = isDisabled
      ? evt => {
          evt.preventDefault();
          evt.stopPropagation();
        }
      : props.onClick;

    return (
      <StyledEditableWrapper
        ref={ref}
        tabIndex="0"
        onMouseOver={() => toggleRowHover(false)}
        onMouseLeave={() => toggleRowHover(true)}
        {...props}
        onClick={onClick}
        disabled={isDisabled}
      />
    );
  }
);

export const EditableCellError = styled.div`
  font-size: 12px;
  color: red;
  width: ${({ width }) => width};
`;
