import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _size from "lodash/size";

import propTypes from "../../../../constants/propTypes";
import Loader from "../../../ui/Loader";
import ApprovalManagerButton from "./ApprovalManager/TriggerButton";
import { StyledActionsSection, StyledButton, ButtonText } from "./components";
import DownloadPDFButton from "./DownloadPDFButton";
import InputWrapper from "../../../inputs/InputWrapper";
import { getMoreActions } from "./headerFields";
import { checkHasContactsByType } from "../utils";
import { ReviewSubmittalsIcon } from "./ReviewSubmittalsIcon";

const reviewSubmittalsAvailableStatus = ["Issued"];

const LeftActions = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-right: 24px;
`;

export const ActionsSection = ({
  openSubmittalsManagerModal,
  onOpenApprovalManagerClick,
  openModalDialog,
  closeModalDialog,
  handleDownloadPDF,
  purchaseOrder,
  purchaseOrderContacts,
  onRevert,
  onCancel,
  setFieldValue,
  project,
}) => {
  const inputs = useMemo(() => {
    const hasFreightContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "freight"
    );
    const hasVendorContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "vendor"
    );
    const hasDesignerContacts = checkHasContactsByType(
      purchaseOrderContacts,
      "designer"
    );

    return getMoreActions({
      purchaseOrder,
      setFieldValue,
      onOpenApprovalManagerClick,
      onRevert,
      onCancel,
      hasFreightContacts,
      hasVendorContacts,
      hasDesignerContacts,
      hasContractFiles: _size(project?.contractFiles) > 0,
      openModalDialog,
      closeModalDialog,
    });
  }, [
    purchaseOrderContacts,
    purchaseOrder,
    setFieldValue,
    onOpenApprovalManagerClick,
    onRevert,
    onCancel,
    project,
    openModalDialog,
    closeModalDialog,
  ]);

  return (
    <StyledActionsSection>
      <LeftActions>
        <Loader width="300px">
          <InputContainer>
            <InputWrapper {...inputs.moreActions} />
          </InputContainer>
          <DownloadPDFButton handleDownloadPDF={handleDownloadPDF} />
          <ApprovalManagerButton
            purchaseOrder={purchaseOrder}
            onOpenApprovalManagerClick={onOpenApprovalManagerClick}
          />
          {reviewSubmittalsAvailableStatus.includes(purchaseOrder.status) && (
            <StyledButton onClick={openSubmittalsManagerModal}>
              <ReviewSubmittalsIcon {...purchaseOrder} />
              <ButtonText>Review Submittals</ButtonText>
            </StyledButton>
          )}
        </Loader>
      </LeftActions>
    </StyledActionsSection>
  );
};

ActionsSection.propTypes = {
  openSubmittalsManagerModal: PropTypes.func,
  handleDownloadPDF: PropTypes.func,
  onOpenApprovalManagerClick: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  purchaseOrder: propTypes.purchaseOrder,
  project: propTypes.project,
  invoices: PropTypes.array,
};

export default ActionsSection;
