import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import FormikForm from "../../../../forms/FormikForm";
import { FormPageSection } from "../../../../layout/FormPageSection";

import { headerFields, comDetailsFields, bottomFields } from "./ComFormFields";

import ComDetailSection from "./DetailsSection/ComDetailSection";
import GlCode from "../../../../../models/GLCode";
import ControlButtons from "../ControlButtons";
import Spec from "../../../../../models/Spec";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import * as RequestTypes from "../../../../../constants/RequestTypes";

const DetailPanel = styled.div`
  display: flex;
  flex: 0 0 560px;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

const ListPanel = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
`;

const StyledModal = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledHeaderText = styled.span`
  line-height: 32px;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const initialValues = props => ({
  projectId: props?.projectId || -1,
  projectCurrencyId: props?.projectCurrencyId || null,
  description: "",
  customNumber: undefined,
  areaId: "",
  specCategoryId: undefined,
  totalQuantity: "--",
  overageValue: "--",
  specDetails: [
    {
      locked: true,
      type: "Description",
      title: "Color",
      description: "",
    },
    {
      locked: true,
      type: "Description",
      title: "Pattern",
      description: "",
    },
    {
      locked: true,
      type: "Description",
      title: "Certificate",
      description: "",
    },
    {
      locked: true,
      type: "Description",
      title: "Flame & Care",
      description: "",
    },
    {
      locked: true,
      type: "Preview",
      title: "Preview",
    },
    {
      locked: true,
      type: "Description",
      title: "Type",
      description: "",
    },
  ],
});

const CreateComItemContainer = ({
  handleCancel,
  projectId,
  clientId,
  specSelected,
  initDataComponent,
  performRetrieveListRequest,
  closeModalDialog,
  createComForSpec,
}) => {
  useEffect(() => {
    initDataComponent("ComSpec", Spec, [], "specs", false, "v2");

    initDataComponent("select-glCodes", GlCode, [], "gl-codes", false, "v2");
    performRetrieveListRequest("select-glCodes", {
      sort: [{ columnName: "description", direction: "asc" }],
      rootFilters: {
        $where: {
          clientId,
        },
      },
      pageSize: -1,
    });
  }, [initDataComponent, performRetrieveListRequest, clientId]);

  const handleSubmit = specForm => {
    createComForSpec("ComSpec", specSelected, specForm);
  };

  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );

  return (
    <FormikForm
      initialValues={{
        ...initialValues({
          projectId,
          projectCurrencyId: specSelected.projectCurrencyId,
        }),
      }}
      onSubmit={handleSubmit}
      validationSchema={BWModels.loadSchema("Spec")}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <StyledModal>
          <ControlButtons onClose={closeModalDialog} />
          <ListPanel>
            <StyledHeaderText>
              {`New COM spec in #${specSelected.customNumber} ${specSelected.description}`}
            </StyledHeaderText>
            <FormPageSection fields={headerFields(projectId)} />
            <FormPageSection
              fields={comDetailsFields()}
              headerText={"Com Details"}
            />
            <FormPageSection fields={bottomFields()} />
          </ListPanel>
          <DetailPanel>
            <ComDetailSection
              itemSelected={values}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              setFieldValue={setFieldValue}
              projectCurrencies={projectCurrencies}
              values={values}
              projectId={projectId}
            />
          </DetailPanel>
        </StyledModal>
      )}
    </FormikForm>
  );
};

CreateComItemContainer.propTypes = {
  specSelected: PropTypes.object,
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  handleCancel: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  closeModalDialog: PropTypes.func,
  createComForSpec: PropTypes.func,
};

export default CreateComItemContainer;
