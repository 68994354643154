import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import Assignment from "@material-ui/icons/Assignment";
import Assessment from "@material-ui/icons/Assessment";
import Weekend from "@material-ui/icons/Weekend";
import Settings from "@material-ui/icons/Settings";

import Grid from "./Grid";
import propTypes from "../../../constants/propTypes";
import { projectsDataComponentId } from "./ProjectsContainer";
import { SwitchContext } from "../../ui/BWGrid/PagingPaneWithStatusSwitch";

const handleOpenCOMLibrary = ({ openModalDialog }, project) => () => {
  openModalDialog(
    false,
    "COMLibrary",
    {
      projectId: project.id,
    },
    false,
    false,
    {
      layoutType: "wide",
      freezeBackground: true,
    }
  );
};
export const getFormattedCityState = (city = "", state = "") => {
  if (city.length > 0 && state.length > 0) {
    return `${city}, ${state}`;
  }
  if (city.length > 0) {
    return `${city}`;
  }
  return state;
};
export const handleReassignProjectClick = (props, project) => () => {
  const {
    openModalDialog,
    initDataComponent,
    performRetrieveListRequest,
    transferProject,
  } = props;
  const { property = {} } = project;
  const {
    location = {},
    entity: {
      client: { scopeId },
    },
  } = property;
  const { city = "", state = "" } = location;
  const formattedCityState = getFormattedCityState(city || "", state || "");
  openModalDialog(
    "Switch Client",
    "TransferProjectContainer",
    {
      project: project,
      initDataComponent,
      performRetrieveListRequest,
      onTransferProject: transferProject,
      scopeId,
    },
    false,
    false,
    {
      layoutType: "default",
      scrollDisabled: true,
      subtitle: `${property.name} ${formattedCityState}`,
    }
  );
};

const goToProjectSettings = (
  { pushWithReturnUrl },
  project,
  backTo = "PROJECT INDEX"
) => () => {
  const clientId = _get(project, "property.entity.clientId");
  pushWithReturnUrl(
    `/clients/${clientId}/projects/${project.id}/settings`,
    backTo
  );
};

export const openDuplicateProjectModal = (
  { openModalDialog },
  project
) => () => {
  const clientId = _get(project, "property.entity.clientId");
  return openModalDialog(
    ["Duplicate Project", `${project.number} - ${project.name}`],
    "DuplicateProject",
    {
      title: `${project.number} - ${project.name}`,
      clientId,
      project,
      dataComponentId: projectsDataComponentId,
    }
  );
};

export const rowMenuFactory = (
  props,
  backTo,
  disableSwitchClient = false
) => row => {
  const rowActions = [
    {
      icon: <Assignment />,
      text: "Notes",
      onClick: () =>
        props.openNotesModal({
          projectId: row.id,
        }),
    },
    {
      icon: <Assessment />,
      text: "Reports",
      onClick: () => {
        props.openModalDialog(
          "Generate Report",
          "ReportsContainer",
          { projectId: row.id },
          false,
          true,
          {
            width: "629px",
          }
        );
      },
    },
    {
      icon: <Weekend />,
      text: "COM Library",
      onClick: handleOpenCOMLibrary(props, row),
    },
    {
      icon: <Settings />,
      text: "Settings",
      onClick: goToProjectSettings(props, row, backTo),
    },
    { separator: true },
    {
      text: "Duplicate Project",
      onClick: openDuplicateProjectModal(props, row),
    },
  ];
  if (!disableSwitchClient) {
    rowActions.push({
      text: "Switch Client",
      onClick: handleReassignProjectClick(props, row),
    });
  }
  return rowActions;
};

export const getStatuses = (showInClosing, showClosed) => {
  const inClosing = showInClosing ? ["In Closing"] : [];
  const closed = showClosed ? ["Closed"] : [];
  return [
    "Created",
    "Contracted",
    "On Hold",
    "Root - Master",
    ...inClosing,
    ...closed,
  ];
};

class ProjectsPage extends Component {
  handleRowClick = ({ id, property: { entity } }) => {
    this.props.pushWithReturnUrl(`/clients/${entity.clientId}/projects/${id}`);
  };

  handleInClosingToggle = () => {
    const { updateAuthenticatedUser, projectsShowInClosing } = this.props;
    updateAuthenticatedUser({ projectsShowInClosing: !projectsShowInClosing });
  };

  handleClosedToggle = () => {
    const { updateAuthenticatedUser, projectsShowClosed } = this.props;
    updateAuthenticatedUser({ projectsShowClosed: !projectsShowClosed });
  };

  render() {
    const {
      dataComponent,
      projectsShowInClosing,
      projectsShowClosed,
    } = this.props;
    const title =
      !projectsShowInClosing && !projectsShowClosed
        ? "Active Projects"
        : "Projects";
    const statuses = getStatuses(projectsShowInClosing, projectsShowClosed);
    return (
      <SwitchContext.Provider
        value={{
          onToggle: [this.handleInClosingToggle, this.handleClosedToggle],
          checked: [projectsShowInClosing, projectsShowClosed],
          label: ["Show In Closing Projects", "Show Closed Projects"],
        }}
      >
        <Grid
          dataComponent={dataComponent}
          selectedStatuses={statuses}
          title={title}
          getRowMenu={rowMenuFactory(this.props)}
          onRowClick={this.handleRowClick}
        />
      </SwitchContext.Provider>
    );
  }
}

ProjectsPage.propTypes = {
  loggedInUserId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  pushWithReturnUrl: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func,
  openNotesModal: PropTypes.func.isRequired,
  updateAuthenticatedUser: PropTypes.func.isRequired,
  filterBy: PropTypes.string,
  projectsShowInClosing: PropTypes.bool,
  projectsShowClosed: PropTypes.bool,
};

export default ProjectsPage;
