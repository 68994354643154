import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import propTypes from "../../../../constants/propTypes";
import {
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../../actions/layoutActions";

import Projects from "./Projects";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";

export const dataComponentId = "AccountingProjectsGrid";
export const usersDataComponentId = "UsersDataComponentId";
export const officesDataComponentId = "OfficesProjectsDataComponentId";

export const showAllProjectsSettings = {
  false: {
    statuses: [
      "Created",
      "Contracted",
      "On Hold",
      "In Closing",
      "Root - Master",
    ],
  },
};
showAllProjectsSettings.true = {
  statuses: [...showAllProjectsSettings.false.statuses, "Closed"],
};

export const ProjectsContainer = ({
  dataComponent,
  generateAndDownloadReport,
  openModalDialog,
  setAutoSaveComponentId,
  users,
  offices,
  initDataComponent,
  performRetrieveListRequest,
  push,
  pushWithReturnUrl,
}) => {
  const handleOpenProjectDetails = useCallback(
    project => {
      push(
        `/clients/${project.property.entity.clientId}/projects/${project.id}`
      );
    },
    [push]
  );

  const handleOpenProjectSettings = useCallback(
    project => {
      pushWithReturnUrl(
        `/clients/${project.property.entity.clientId}/projects/${project.id}/settings`,
        "Accounting Matrix"
      );
    },
    [pushWithReturnUrl]
  );

  const handleUpdateProjectDate = useCallback(
    (title, column, project) => () => {
      openModalDialog(
        `${title} Date`,
        "EditProjectDateColumn",
        {
          project,
          column,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleExport = () => {
    generateAndDownloadReport({
      format: "xls",
      templateName: "accounting-matrix",
    });
  };

  return (
    <Projects
      dataComponent={dataComponent}
      onUpdateProjectDate={handleUpdateProjectDate}
      onOpenProjectDetails={handleOpenProjectDetails}
      onOpenProjectSettings={handleOpenProjectSettings}
      handleExport={handleExport}
      setAutoSaveComponentId={setAutoSaveComponentId}
      users={users}
      offices={offices}
      initDataComponent={initDataComponent}
      performRetrieveListRequest={performRetrieveListRequest}
    />
  );
};

ProjectsContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  generateAndDownloadReport: PropTypes.func,
  openModalDialog: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(propTypes.user),
  offices: PropTypes.arrayOf(propTypes.office),
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  const userDataComponent = getDataComponentFlattenedRequestState(
    usersDataComponentId,
    state
  );
  const officeDataComponent = getDataComponentFlattenedRequestState(
    officesDataComponentId,
    state
  );

  const users = getBluechipResources(userDataComponent, state);
  const offices = getBluechipResources(officeDataComponent, state);

  return {
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    userDataComponent: userDataComponent,
    users: users ? users : [],
    offices: offices ? offices : [],
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  generateAndDownloadReport,
  setAutoSaveComponentId,
  initDataComponent,
  performRetrieveListRequest,
  push,
  pushWithReturnUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
