import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import Button from "../mui/core/Button";
import { BWIntent } from "./BWIntent";
import { useHasRequestActive } from "../hocs/withRequestListener";
import withRequestListener from "../hocs/withRequestListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useIsProjectClosed } from "../hooks/useIsProjectClosed";
import { useWindowWidth } from "../hooks/useWindowWidth";

export const StyledListItemText = styled(ListItemText)`
  padding: 0;
  span {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: ${({ intent = BWIntent.PRIMARY }) =>
      BWIntent.values.buttons[intent]};
  }
`;

export const StyledCircularProgress = styled(CircularProgress)({});

export const StyledListItemIcon = styled(ListItemIcon)`
  margin: 0 8px 0 0;
  color: ${({ intent = BWIntent.PRIMARY }) => BWIntent.values.icons[intent]};
  ${StyledCircularProgress} {
    color: ${({ intent = BWIntent.PRIMARY }) => BWIntent.values.icons[intent]};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Icon = ({ loading, icon }) => {
  if (loading) return <StyledCircularProgress size={24} />;
  return icon;
};

const isDisabled = (loading, isProjectClosed, disableIfProjectClosed) =>
  loading || (isProjectClosed && disableIfProjectClosed);

const shouldCollapseText = (keepTextExpanded, width) =>
  !keepTextExpanded && width <= 700;

const getTooltipTitle = (collapseText, text) => (collapseText ? text : "");

export const ActionButton = ({
  icon,
  text,
  edge,
  intent,
  buttonId,
  dispatch,
  disableIfProjectClosed,
  keepTextExpanded = true,
  ...buttonProps
}) => {
  const loading = useHasRequestActive(buttonId);
  const isProjectClosed = useIsProjectClosed();
  const width = useWindowWidth();

  buttonProps.disabled =
    buttonProps.disabled ||
    isDisabled(loading, isProjectClosed, disableIfProjectClosed);

  if (buttonProps.disabled && !loading) {
    return null;
  }

  const collapseText = shouldCollapseText(keepTextExpanded, width);

  return (
    <Tooltip title={getTooltipTitle(collapseText, text)}>
      <span>
        <Button {...buttonProps}>
          {icon && (
            <StyledListItemIcon intent={intent}>
              <Icon loading={loading} icon={icon} />
            </StyledListItemIcon>
          )}
          {!collapseText && (
            <StyledListItemText primary={text} intent={intent} />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

ActionButton.defaultProps = {
  buttonId: "primary",
};

export const BWActionButton = styled(withRequestListener(ActionButton))({});
BWActionButton.displayName = "ActionButton";
BWActionButton.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  loading: PropTypes.bool,
  edge: PropTypes.oneOf(["left", "right"]),
};
