import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PurchaseOrderListLayout from "./PurchaseOrderListLayout";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import propTypes from "../../../../constants/propTypes";
import {
  quotingPODCId,
  refreshPurchaseOrderList,
  refreshCategoriesList,
  initQuotingPurchaseOrderDC,
  initQuotingCategoriesDC,
  initSpecDetail,
  fetchPurchaseOrders,
  allPurchaseOrdersDataComponentId,
} from "../../../../actions/quotingActions";
import { setReload } from "../../../../actions/dataComponentActions";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import BWInfiniteScroll from "../../../ui/BWInfiniteScroll";
import { unassignedSecUpdateId } from "../QuotingActions";
import { dataComponentId } from "../UnassignedSpecs/UnassignedOnPO/UnassignedSpecsContainer";

const getRelatedPOs = (purchaseOrder, purchaseOrders) =>
  purchaseOrders.filter(po => po.id !== purchaseOrder.id);

export class PurchaseOrderListContainer extends Component {
  componentDidMount() {
    this.props.initQuotingPurchaseOrderDC();
    this.props.initQuotingCategoriesDC();
    this.props.initSpecDetail();
    this.props.refreshPurchaseOrderList(this.props.projectId)(0);
    this.props.refreshCategoriesList(this.props.projectId);
    this.props.fetchPurchaseOrders(this.props.projectId);
    this.props.onClearFilter();
  }

  componentDidUpdate({
    dataComponent: prevDataComponent,
    unassignedSpecDataComponent: prevUnassignedSpecDataComponent,
  }) {
    const {
      dataComponent,
      unassignedSpecDataComponent,
      setReload,
      refreshCategoriesList,
      projectId,
    } = this.props;

    const updateResources = () => {
      setReload(dataComponentId, true);
      this.refreshPurchaseOrders();
      refreshCategoriesList(projectId);
    };

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: updateResources,
    });

    processUpdateRequestStatus(
      prevUnassignedSpecDataComponent,
      unassignedSpecDataComponent,
      {
        onSuccess: updateResources,
      }
    );
  }

  refreshPurchaseOrders = () => {
    const { refreshPurchaseOrderList, projectId } = this.props;
    refreshPurchaseOrderList(projectId)(0);
  };

  render() {
    const { purchaseOrders, isClosedProject } = this.props;
    return (
      <BWInfiniteScroll
        dataComponentId={quotingPODCId}
        loadNext={this.props.refreshPurchaseOrderList(
          this.props.projectId,
          this.props.filters
        )}
        scrollThreshold={0.5}
        mapRowToComponent={purchaseOrder => (
          <PurchaseOrderListLayout
            isClosedProject={isClosedProject}
            key={purchaseOrder.id}
            purchaseOrderId={purchaseOrder.id}
            relatedPOs={getRelatedPOs(purchaseOrder, purchaseOrders)}
            purchaseOrder={purchaseOrder}
            refreshPurchaseOrderList={this.refreshPurchaseOrders}
          />
        )}
      />
    );
  }
}

PurchaseOrderListContainer.propTypes = {
  onClearFilter: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  unassignedSpecDataComponent: propTypes.dataComponent,
  isClosedProject: PropTypes.bool,
  refreshPurchaseOrderList: PropTypes.func.isRequired,
  refreshCategoriesList: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  initQuotingPurchaseOrderDC: PropTypes.func.isRequired,
  initQuotingCategoriesDC: PropTypes.func.isRequired,
  fetchPurchaseOrders: PropTypes.func.isRequired,
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  initSpecDetail: PropTypes.func.isRequired,
  filters: PropTypes.object,
  loading: PropTypes.bool,
};

PurchaseOrderListContainer.defaultProps = {
  purchaseOrders: [],
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(quotingPODCId, state);
  const unassignedSpecDataComponent = getDataComponent(
    unassignedSecUpdateId,
    state
  );

  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    allPurchaseOrdersDataComponentId,
    state
  );
  const purchaseOrders =
    getBluechipResources(flattenedDataComponent, state) || [];
  return {
    dataComponent,
    unassignedSpecDataComponent,
    purchaseOrders: purchaseOrders,
  };
};

const mapDispatchToProps = dispatch => ({
  refreshPurchaseOrderList: (...props) => pageNumber =>
    dispatch(refreshPurchaseOrderList(...props)(pageNumber)),
  ...bindActionCreators(
    {
      refreshCategoriesList,
      initQuotingPurchaseOrderDC,
      initQuotingCategoriesDC,
      setReload,
      initSpecDetail,
      fetchPurchaseOrders,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderListContainer);
