import React from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { IconButton } from "@material-ui/core";

const SelectComControl = props => {
  const { onClick, isSelected } = props;
  return (
    <IconButton
      style={{
        backgroundColor: "transparent",
        color: isSelected ? "#18CC55" : "#D8D8D8",
      }}
      onClick={onClick}
      aria-label="select COM"
    >
      <CheckCircleIcon />
    </IconButton>
  );
};

SelectComControl.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};
SelectComControl.defaultProps = {
  onClick: () => {},
  isSelected: false,
};
export default SelectComControl;
