import React, { useCallback, useMemo } from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _sortBy from "lodash/sortBy";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { withRouter } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import { PlaylistAddCheck } from "@material-ui/icons";

import BidGroupStatus from "./BidGroupStatus";
import { GridHeader } from "../../../../ui/BWGrid";
import { Button } from "../../../../layout/SectionHeader";
import { CollapseButton } from "../../../../ui/CollapseButton";
import { pushWithReturnUrl } from "../../../../../actions/navigationActions";
import propTypes from "../../../../../constants/propTypes";
import { PlaylistAddCheckIconGreen } from "../../../BidGroupDetail/Quotes/SpecTable/components";

const StyledVendorHeader = styled.span`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.87);
`;

const ToolBarWrapper = styled(Paper)``;
export const HeaderWrapper = styled(({ isCollapsed, ...props }) => (
  <ToolBarWrapper {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  ${({ isCollapsed }) => (isCollapsed ? "" : "box-shadow: none;")}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  ${({ isCollapsed }) => (isCollapsed ? "margin-bottom: 24px" : "")}
  ${({ isCollapsed }) => (isCollapsed ? "" : "box-shadow: none;")}
`;

export const GridHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: baseline;
  padding: 9px 24px 9px;
`;

export const AwardedIcon = styled(PlaylistAddCheck)`
  vertical-align: bottom;
  color: #83bd44;
  font-size: medium;
`;

PlaylistAddCheckIconGreen;

const hasAwardedSpecs = (bidGroupVendor, bidGroup) =>
  bidGroup.bidGroupSpecs.some(
    ({ spec: { awardedVendorId } }) =>
      awardedVendorId == bidGroupVendor.vendorId
  );

const isCollapseButtonDisabled = (isDisabled, loading) => isDisabled || loading;

const buildVendorsList = (truncationList, bidGroup) => (
  <span>
    {truncationList.map((bidGroupVendor, i) => {
      return (
        <span key={i}>
          {hasAwardedSpecs(bidGroupVendor, bidGroup) ? <AwardedIcon /> : null}
          {bidGroupVendor.vendor.name}
          {i < truncationList.length - 1 ? ", " : ""}
        </span>
      );
    })}
  </span>
);

export const buildVendorSubtitle = bidGroup => {
  if (!bidGroup.bidGroupVendors || bidGroup.bidGroupVendors.length === 0) {
    return null;
  }
  const sortedList = _sortBy(
    bidGroup.bidGroupVendors,
    bidGroupVendor => bidGroupVendor.vendor.name
  );
  const numberOfVendors = bidGroup.bidGroupVendors.length;

  const truncationList =
    numberOfVendors > 5 ? sortedList.slice(0, 5) : sortedList;
  const vendorList = buildVendorsList(truncationList, bidGroup);

  return numberOfVendors > 5 ? (
    <span>
      {vendorList}, +{numberOfVendors - 5} more
    </span>
  ) : (
    vendorList
  );
};

export const BidGroupHeader = ({
  bidGroup,
  bidGroups,
  isCollapsed,
  setIsCollapsed,
  isDisabled,
  loading,
  history,
  resetSelection,
  selectedIds = [],
  selectedProjectCurrencyId,
  onOpenCreateBidModal,
  pushWithReturnUrl,
}) => {
  const { specsCount } = bidGroup;
  const itemsDisplay = pluralize("Item", specsCount, true);
  const headerText = `BG ${bidGroup.nameWithSequenceIdentifier} (${itemsDisplay})`;

  const vendorsSubtitle = useMemo(() => {
    return buildVendorSubtitle(bidGroup);
  }, [bidGroup]);

  const handleOpenDetails = useCallback(() => {
    pushWithReturnUrl(
      `${history.location.pathname}/${bidGroup.id}/vendors`,
      "Bid Groups"
    );
  }, [bidGroup.id, history, pushWithReturnUrl]);

  return (
    <Wrapper isCollapsed={isCollapsed}>
      <HeaderWrapper isCollapsed={isCollapsed}>
        <GridHeaderWrapper>
          <GridHeader
            headerText={headerText}
            headerOverride={
              <BidGroupStatus
                bidGroup={bidGroup}
                bidGroups={bidGroups}
                onOpenCreateBidModal={onOpenCreateBidModal}
                resetSelection={resetSelection}
                selectedIds={selectedIds}
                selectedProjectCurrencyId={selectedProjectCurrencyId}
              />
            }
            secondaryText={
              vendorsSubtitle && (
                <div>
                  <StyledVendorHeader>Vendors</StyledVendorHeader>{" "}
                  {vendorsSubtitle}
                </div>
              )
            }
          />
        </GridHeaderWrapper>
        <DescriptionWrapper>
          <Button
            text="View Details"
            icon={<EditIcon />}
            onClick={handleOpenDetails}
          />
          <CollapseButton
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            isDisabled={isCollapseButtonDisabled(isDisabled, loading)}
          />
        </DescriptionWrapper>
      </HeaderWrapper>
    </Wrapper>
  );
};

BidGroupHeader.propTypes = {
  bidGroup: propTypes.bidGroup,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  resetSelection: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  selectedProjectCurrencyId: PropTypes.string,
  onOpenCreateBidModal: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  pushWithReturnUrl,
};

export default connect(null, mapDispatchToProps)(withRouter(BidGroupHeader));
