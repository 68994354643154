import { inputs } from "../../../inputs/inputConfigs";

export default isUpdate => {
  return {
    groups: [
      {
        items: [
          {
            input: inputs.hasProjectAccounting,
            grid: { xs: 4, noPaddingLeft: true },
          },
          { input: inputs.projectIsPositivePay, grid: { xs: 4 } },
          { input: inputs.isTaxExempt, grid: { xs: 4 } },
          {
            input: { ...inputs.bankAccount, defaultVisible: !isUpdate },
            grid: { md: 3, sm: 6, xs: 12 },
          },
          {
            input: inputs.saleTaxPercent,
            grid: { md: 3, sm: 6, xs: 12 },
          },
          {
            input: inputs.useTaxPercent,
            grid: { md: 3, sm: 6, xs: 12 },
          },
          {
            input: inputs.salesTaxTolerancePercent,
            grid: { md: 3, sm: 6, xs: 12 },
          },
          {
            input: inputs.isFreightTaxable,
            grid: { xs: 12, noPaddingLeft: true },
          },
          {
            input: inputs.isInstallTaxable,
            grid: { xs: 12, noPaddingLeft: true },
          },
          { input: inputs.accountingDueWeekday, grid: { xs: 4 } },
        ],
      },
    ],
  };
};
