import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Edit from "@material-ui/icons/Edit";

import Price from "../../../../ui/Price";

const clearColumns = [];
const doubleAsteriskColumns = ["ffeSpendTo", "feeRangeMax"];

export const StyledValue = styled.span`
  display: flex;
  align-items: center;
  color: ${({ clear }) => (clear ? "#979797" : "rgba(0, 0, 0, 0.87)")};
`;

const EditIcon = styled(Edit)`
  color: #979797;
  font-size: 12px;
  margin-left: 10px;
  width: 12px;
  height: 12px;
`;

// eslint-disable-next-line complexity
const Column = ({
  row,
  rowData,
  path,
  isEditable,
  renderValue,
  isPercentage,
  pricingTiers = [],
}) => {
  const value = renderValue ? renderValue(row, rowData) : row[path];

  if (typeof value === "string") {
    return <StyledValue>{value}</StyledValue>;
  }

  return (
    <StyledValue clear={clearColumns.includes(path)}>
      {isPercentage ? `${value || 0}%` : <Price number={value} />}
      {doubleAsteriskColumns.includes(path) &&
        rowData.rowId === pricingTiers.length - 1 &&
        " **"}
      {isEditable && <EditIcon />}
    </StyledValue>
  );
};

Column.propTypes = {
  row: PropTypes.shape({}),
  rowData: PropTypes.shape({}),
  path: PropTypes.string,
  isEditable: PropTypes.bool,
  renderValue: PropTypes.func,
  isPercentage: PropTypes.bool,
  pricingTiers: PropTypes.array,
};

export default Column;
