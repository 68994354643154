import React, { useContext, memo } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";

import CardRight from "../CardRight";
import ShortPaidControl from "./ShortPaidControl";
import Contacts from "./Contacts";
import VendorHeader from "./VendorHeader";
import propTypes from "../../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../../withPORevision";

const showShortPaidControl = (
  vendorSpec,
  purchaseOrder,
  displayShortPaidControl
) => {
  return !vendorSpec && !_isEmpty(purchaseOrder) && displayShortPaidControl;
};

export const getVendor = (purchaseOrder, vendor) => {
  if (vendor) return vendor;
  return purchaseOrder.vendor;
};

const VendorInfo = ({
  vendor,
  purchaseOrder,
  purchaseOrderContacts,
  displayShortPaidControl,
  dataComponentId,
  displayAddContact,
  vendorSpec,
  disabledOptions,
  spec,
}) => {
  return (
    <CardRight>
      <VendorHeader
        dataComponentId={dataComponentId}
        vendor={getVendor(purchaseOrder, vendor)}
        vendorSpec={vendorSpec}
        disabledOptions={useContext(LabeledTextContext) || disabledOptions}
      />
      {showShortPaidControl(
        vendorSpec,
        purchaseOrder,
        displayShortPaidControl
      ) && <ShortPaidControl purchaseOrder={purchaseOrder} />}
      {!vendorSpec ? (
        <Contacts
          vendor={vendor}
          purchaseOrder={purchaseOrder}
          spec={spec}
          vendorContacts={purchaseOrderContacts}
          purchaseOrderContacts={purchaseOrderContacts}
          displayAddContact={displayAddContact}
        />
      ) : null}
    </CardRight>
  );
};

VendorInfo.defaultProps = {
  displayShortPaidControl: false,
  displayAddContact: true,
  purchaseOrderContacts: [],
};

VendorInfo.propTypes = {
  vendor: propTypes.vendor,
  disabledOptions: PropTypes.bool,
  dataComponentId: PropTypes.string,
  vendorSpec: PropTypes.bool,
  loading: PropTypes.bool,
  purchaseOrder: propTypes.purchaseOrder,
  purchaseOrderContacts: PropTypes.arrayOf(propTypes.purchaseOrderContact),
  displayShortPaidControl: PropTypes.bool,
  displayAddContact: PropTypes.bool,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  spec: propTypes.spec,
};

export default memo(VendorInfo);
