import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { closeModalDialog } from "../../../actions/layoutActions";
import DirtyFormDialog from "./DirtyFormDialog";

export class DirtyFormDialogContainer extends Component {
  handleSubmit = () => this.props.onSubmit();

  render() {
    const { title, submitText, ...props } = this.props;
    return (
      <DirtyFormDialog
        title={title}
        submitText={submitText}
        onSubmit={this.handleSubmit}
        {...props}
      />
    );
  }
}

DirtyFormDialogContainer.propTypes = {
  submitText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(null, mapDispatchToProps)(DirtyFormDialogContainer);
