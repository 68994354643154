import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
  processUpdateRequestStatus,
} from "../../../utils/dataComponentUtils";
import { handleSuccess, handleError } from "./AreaRequestHandlers";

export const onCreateArea = (
  area,
  projectId,
  areaDataComponent,
  areasDataComponentId,
  performCreateRequest
) => {
  area.projectId = projectId;

  delete area.numberOfSpecs;

  performCreateRequest(areasDataComponentId, area);
};
export const onUpdateArea = (
  area,
  areaDataComponent,
  areasDataComponentId,
  performUpdateRequest
) => {
  delete area.numberOfSpecs;

  performUpdateRequest(areasDataComponentId, area.id, area);
};
export const onDeleteArea = (
  areaId,
  areaDataComponent,
  areasDataComponentId,
  performDeleteRequest
) => {
  performDeleteRequest(areasDataComponentId, areaId);
};
const processSaveRequest = (
  preDataComponent,
  dataComponent,
  projectId,
  projectDataComponentId,
  entity,
  {
    performFindRequest,
    showSnackNotificationAction,
    closeModalDialog,
    onUpdateSuccess,
  }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performFindRequest,
        projectDataComponentId,
        projectId,
        showSnackNotificationAction,
        `The ${entity} has been saved successfully`,
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performFindRequest,
        projectDataComponentId,
        projectId,
        showSnackNotificationAction,
        `The ${entity} has been updated successfully`,
        closeModalDialog,
        onUpdateSuccess
      );
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performFindRequest,
        projectDataComponentId,
        projectId,
        showSnackNotificationAction,
        `The ${entity} has been deleted successfully`,
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processRequests = (
  preDataComponent,
  dataComponent,
  projectId,
  projectDataComponentId,
  entity,
  callbacks
) => {
  processSaveRequest(
    preDataComponent,
    dataComponent,
    projectId,
    projectDataComponentId,
    entity,
    callbacks
  );
};
