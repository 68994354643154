import styled from "styled-components";

export const Aside = styled.aside`
  flex: 1;
  height: 100%;
`;

export const Footer = styled.footer`
  padding: 24px;
  height: 96px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  @media (max-width: 1280px) {
    height: auto !important;
  }
`;

export const Main = styled.main`
  padding: 28px;
  flex: 2;
  background: rgba(0, 0, 0, 0.05);
`;

export const VendorsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const SelectedVendorsWrapper = styled.div`
  height: calc(100% - 196px);
  overflow-y: auto;
`;
