import React from "react";
import propTypes from "../../../../../constants/propTypes";
import { ColumnItem } from "../Fees/FeeSummary/FeeSummary";
import { MissingIcon } from "../Funding/components";
import ProjectEditableAttribute from "./ProjectEditableAttribute";

const SummarySection = ({
  project: { accountingDueWeekday },
  currency = {},
}) => {
  const values = [
    [
      {
        title: "Project Currency:",
        value: currency.name,
      },
      {
        title: "Cash Flow Complete",
        value: (
          <ProjectEditableAttribute
            attribute="cashFlowComplete"
            title="Cash Flow Complete"
          />
        ),
      },
    ],
    [
      {
        title: "Processing Day",
        value: accountingDueWeekday || <MissingIcon />,
      },
      {
        title: "Use Tax Complete",
        value: (
          <ProjectEditableAttribute
            attribute="useTaxComplete"
            title="Use Tax Complete"
          />
        ),
      },
    ],
    [
      {
        title: "Last Reviewed Date",
        value: (
          <ProjectEditableAttribute
            attribute="lastReviewed"
            title="Last Reviewed Date"
          />
        ),
      },
      {
        title: "Monthly Reports Complete",
        value: (
          <ProjectEditableAttribute
            attribute="monthlyReportsComplete"
            title="Monthly Reports Complete"
          />
        ),
      },
    ],
  ];
  return (
    <div>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} />
      ))}
    </div>
  );
};

SummarySection.defaultProps = {
  project: {
    currency: {},
  },
};

SummarySection.propTypes = {
  project: propTypes.project,
  currency: propTypes.currency,
};

export default SummarySection;
