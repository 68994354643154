import React, { useMemo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import RevisionIcon from "@material-ui/icons/ReportProblem";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _get from "lodash/get";

import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import { LoaderContext } from "../../../ui/Loader";
import propTypes from "../../../../constants/propTypes";
import {
  Button,
  LeftWrapper,
  Text,
  Banner,
  Buttons,
  RevertIcon,
  RightButton,
} from "./components";
import { revertFromRevision } from "../../../../actions/specDetailActions";

const RevisedBanner = ({ spec, clientId, push, revertFromRevision }) => {
  const { hasModifiedChildrens } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);
  const { loading } = useContext(LoaderContext);

  const shouldDisplay = useMemo(() => hasModifiedChildrens(reference), [
    hasModifiedChildrens,
    reference,
  ]);

  const goToPO = useCallback(() => {
    push(
      `/clients/${clientId}/projects/${spec.projectId}/purchase-orders/${spec.purchaseOrderId}`
    );
  }, [clientId, spec, push]);

  const handleRevertFromRevision = useCallback(() => {
    revertFromRevision(spec.id, spec.purchaseOrder.currentRevisionId);
  }, [spec, revertFromRevision]);

  if (!shouldDisplay) return null;

  return (
    <Banner>
      <Text>
        <LeftWrapper>
          <RevisionIcon />
        </LeftWrapper>
        Revised
      </Text>
      <Buttons>
        <Button disabled={loading} onClick={handleRevertFromRevision}>
          <LeftWrapper>Revert Changes</LeftWrapper>
          <RevertIcon />
        </Button>
        <RightButton disabled={loading} onClick={goToPO}>
          <LeftWrapper>
            Go to PO #{_get(spec, "purchaseOrder.number")}
          </LeftWrapper>
          <ChevronRight />
        </RightButton>
      </Buttons>
    </Banner>
  );
};

RevisedBanner.propTypes = {
  push: PropTypes.func.isRequired,
  revertFromRevision: PropTypes.func.isRequired,
  spec: propTypes.spec,
  clientId: PropTypes.string,
};

const mapDispatchToProps = {
  push,
  revertFromRevision,
};

export default connect(null, mapDispatchToProps)(RevisedBanner);
