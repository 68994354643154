import React from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";

const StyledPaper = styled(
  ({ gutterTop, noBorder, noBottomMargin, selectRef, ...props }) => (
    <Paper ref={selectRef} {...props} />
  )
)`
  border-radius: 0;
  margin-top: ${({ gutterTop }) => (gutterTop ? "24px" : "0")};
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? "0px" : "24px")};
  position: relative;
  box-shadow: ${({ noBorder }) => (noBorder ? "none" : "auto")};
`;

export default React.forwardRef((props, ref) => (
  <StyledPaper selectRef={ref} {...props} />
));
