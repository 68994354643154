import React from "react";
import PropTypes from "prop-types";
import { MissingIcon } from "../components";
import _get from "lodash/get";
import styled from "styled-components";
import RegularText from "../../../../../ui/Typography/RegularText";
import RegularTextSmall from "../../../../../ui/Typography/RegularTextSmall";

const Label = styled(RegularTextSmall)`
  color: rgba(0, 0, 0, 0.54);
  margin-top: 24px;
`;
const FileName = styled(RegularText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: start;
  font-family: Open Sans, sans-serif;
  color: rgba(0, 0, 0, 0.54);
`;
export const Link = styled.a`
  text-decoration: none;
  color: #57abff;
  align-items: center;
  cursor: pointer;
`;
const getSize = file => {
  return (_get(file, "metadata.size", 0) / (1024 * 1024)).toFixed(2);
};
const FundingRequestDocument = props => {
  const { file, headerLabel } = props;
  return (
    <React.Fragment>
      <Label>{headerLabel}</Label>
      {file && (
        <React.Fragment>
          <FileName>{file.filename}</FileName>
          <ActionWrapper>
            <div>{getSize(file)} MB</div>-
            <div>
              Click to{" "}
              <Link href={file && file.s3SignedUrl} download>
                Download
              </Link>
            </div>
          </ActionWrapper>
        </React.Fragment>
      )}
      {!file && (
        <FileName>
          <MissingIcon />
        </FileName>
      )}
    </React.Fragment>
  );
};
FundingRequestDocument.propTypes = {
  file: PropTypes.object,
  headerLabel: PropTypes.string.isRequired,
};

export default FundingRequestDocument;
