import React, { Fragment } from "react";
import { renderRoutes } from "react-router-config";
import { PageHeader } from "../../layout/PageHeader";
import propTypes from "../../../constants/propTypes";

const navigationTabs = [
  { path: "/projects", label: "Matrix" },
  { path: "/closingSchedule", label: "Closing Schedule" },
];

const AccountingPage = ({ match, route }) => (
  <Fragment>
    <PageHeader navigationTabs={navigationTabs} />
    {renderRoutes(route.routers, { match })}
  </Fragment>
);

AccountingPage.propTypes = {
  route: propTypes.route,
  match: propTypes.matchRouter,
};
export default AccountingPage;
