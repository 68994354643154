import { Grid } from "@material-ui/core";
import { Field } from "formik";
import InputWrapper from "../../../inputs/InputWrapper";
import {
  getErrorMessage,
  hasError,
} from "../../../../utils/formValidationUtils";
import React from "react";
import PropTypes from "prop-types";
import { FieldContainer, Row } from "./StyledRow";

const CheckboxRow = ({ input, label, isFirst }) => {
  return (
    <Row isFirst={isFirst}>
      <Grid item xs={12}>
        <FieldContainer>
          <Field name={input.name}>
            {({ field, form }) => (
              <InputWrapper
                {...input}
                {...field}
                label={label}
                hasError={hasError(input.name, form)}
                errorMessage={getErrorMessage(input.name, form)}
              />
            )}
          </Field>
        </FieldContainer>
      </Grid>
    </Row>
  );
};
CheckboxRow.propTypes = {
  input: PropTypes.object.isRequired,
};

export default CheckboxRow;
