import { isSuperAdmin } from "../../../utils/roleUtils";
import { inputs } from "../../inputs/inputConfigs";

const fields = (role, isUpdate) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.firstName,
            name: inputs.firstName.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.lastName,
            name: inputs.lastName.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.phone,
            label: "Phone Number",
            name: inputs.phone.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Fax",
            name: inputs.fax.name,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Cell Number",
            name: `cellphone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.fax,
            label: "Work Number",
            name: `workPhone`,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.contactRole,
            name: inputs.contactRole.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.email,
            name: inputs.email.name,
            required: true,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.contactStatus,
          grid: { xs: 6 },
        },
        {
          input: isSuperAdmin(role)
            ? { ...inputs.scopeId, isDisabled: isUpdate }
            : null,
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export default (role, isUpdate) => {
  return fields(role, isUpdate);
};
