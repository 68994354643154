import styled from "styled-components";
import { BWPaper } from "../../../ui/BWPaper";
import ContactActions from "./VendorInfo/ContactActions";
import BoldText from "../../../ui/Typography/BoldText";
import Subheader from "../../../ui/Typography/Subheader";
import SerifTitle from "../../../ui/Typography/SerifTitle";
import RegularText from "../../../ui/Typography/RegularText";
import { BWActionButton } from "../../../ui/BWActionButton";
import { List, ListItem, MenuItem } from "@material-ui/core";

export const PaperRightColumn = styled(BWPaper)`
  width: 100%;
  margin: 0 0 24px;

  ${BoldText} {
    line-height: 24px;
  }

  ${SerifTitle} {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    line-height: 28px;
  }
`;
PaperRightColumn.displayName = "PaperRightColumn";

export const ColumnSectionHeader = styled.header`
  padding: 24px;

  ${Subheader} {
    margin-bottom: 12px;
  }
  ${SerifTitle} {
    margin-bottom: 8px;
  }
`;

export const SectionHeaderWithLink = styled.header`
  padding: 26px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${BWActionButton} {
    padding: 8px;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 8px 24px;

  ${RegularText} {
    display: block;
  }
`;

export const AddressListItem = styled(ListItem)`
  padding: 24px;
  ${RegularText} {
    display: block;
  }
`;

export const ContactList = styled(List)`
  padding: 8px 0;

  &:hover ${ContactActions} {
    opacity: 1;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 14px 16px 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
`;

export const AddressName = styled.div`
  font-family: "Open Sans Bold", sans-serif;
  line-height: 24px;
`;

export const Address1 = styled.div`
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

export const LinkMenu = styled.div`
  display: flex;
  align-items: center;
  color: rgb(87, 171, 255);
`;

export const LinkMenuText = styled.div`
  padding-left: 8px;
  font-size: 14px;
  line-height: 16px;
`;
