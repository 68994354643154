import { createSelector } from "reselect";
import { isEqual, isEmpty } from "lodash";

export const setRelationsLoadedEffect = ({
  prevRelationsLoading,
  isRelationsLoading,
  setRelationsLoaded,
  purchaseOrderIds,
  isLoading,
}) => () => {
  if (
    prevRelationsLoading &&
    !isRelationsLoading &&
    !isLoading &&
    !isEmpty(purchaseOrderIds)
  ) {
    setRelationsLoaded(true);
  }
};

// Reset to initial state if note id changes
export const noteChangeEffect = ({
  setRelationsLoaded,
  setInitialValues,
  id,
  prevId,
}) => () => {
  if (id === prevId) {
    return;
  }
  setRelationsLoaded(false);
  setInitialValues();
};

// Clear PO relations if a PO is removed
export const clearPORelationsEffect = ({
  formik,
  options,
  setFieldValue,
  name,
  isRelationsLoaded,
}) => () => {
  if (!formik.values.assignTo || !isRelationsLoaded) {
    return;
  }
  const set = new Set(options.map(({ name }) => name));
  const filtered = formik.values.assignTo.filter(({ name }) => set.has(name));
  if (!isEqual(filtered, formik.values.assignTo)) {
    setFieldValue(name, filtered);
  }
};

// Sets PO and relations as initial values
export const initialValuesEffect = ({
  initialValues,
  itemsRelated,
  options,
  setInitialValues,
  isLoading,
  isRelationsLoaded,
}) => () => {
  if (
    isLoading ||
    isEmpty(options) ||
    isRelationsLoaded ||
    isEmpty(initialValues.id)
  ) {
    return;
  }

  const values = getInitialValues({
    relations: getRelations({
      note: initialValues,
      relations: itemsRelated,
    }),
    options,
    initialValues: initialValues.assignTo,
  });
  setInitialValues(values);
};

const getInitialValues = createSelector(
  ({ relations }) => relations,
  ({ options }) => options,
  ({ initialValues }) => initialValues,
  (relations, options, initialValues) => {
    const keys = ["po", "ship", "spec", "inv"];

    const set = new Set(initialValues);
    keys.forEach(key => {
      relations[key].forEach(identifier => {
        set.add(
          options.find(({ id, type }) =>
            type == "PO"
              ? id == identifier
              : id === `${key.toUpperCase()} #${identifier}`
          )
        );
      });
    });
    return [...set].filter(option => option !== undefined);
  }
);

export const getRelations = createSelector(
  ({ note }) => note,
  ({ relations }) => relations,
  (note, relations) => {
    if (!note.id) return relations;
    const relationMap = {
      po: { relation: "purchaseOrders", identifier: "name" },
      ship: { relation: "shipments", identifier: "number" },
      spec: { relation: "specs", identifier: "customNumber" },
      inv: { relation: "invoices", identifier: "number" },
    };
    return Object.keys(relationMap).reduce((accum, key) => {
      const relationDescriptor = relationMap[key];
      const items = note[relationDescriptor.relation] || [];
      accum[key] = items.map(item => item[relationDescriptor.identifier]);
      return accum;
    }, {});
  }
);
