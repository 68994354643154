import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

const PhoneInput = ({ inputRef, onChange, ...props }) => {
  return (
    <IMaskInput
      mask={/^\+?\d*$/}
      unmask
      inputRef={inputRef}
      onAccept={value => {
        onChange({
          target: {
            name: props.name,
            value,
          },
        });
      }}
      {...props}
    />
  );
};

PhoneInput.propTypes = {
  inputRef: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default PhoneInput;
