import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DeleteConfirmation from "../../../ui/DeleteConfirmation";
import propTypes from "../../../../constants/propTypes";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  performDeleteRequest,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import SpecDetailCom from "../../../../models/SpecDetailCom";
import withPORevision from "../../../../withPORevision";

const dataComponentId = "COMLibrary-specDetailCom";
const dataComponentSpecDetailChildrenId = "SpecDetail-SpecDetailsChildren";

export class DeleteSpecDetailCOMContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    specDetailId: PropTypes.string.isRequired,
    specDetailComId: PropTypes.string.isRequired,
    remainingRelatedCOMs: PropTypes.number.isRequired,
    dataComponent: propTypes.dataComponent.isRequired,
    dataComponentSpecDetailChildren: propTypes.dataComponent.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    performDeleteRequest: PropTypes.func.isRequired,
    showSnackNotificationAction: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    revisionValue: PropTypes.shape({
      isActive: PropTypes.bool,
      activities: PropTypes.arrayOf(propTypes.revisionActivities),
    }),
  };

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, SpecDetailCom, [], "spec-detail-coms");
  }

  componentDidUpdate({
    dataComponent: prevDataComponent,
    dataComponentSpecDetailChildren: prevDataComponentSpecDetailChildren,
  }) {
    const { dataComponent, dataComponentSpecDetailChildren } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleDeleteSpecDetailCOMSuccess,
      onError: this.handleDeleteRequestError,
    });

    processDeleteRequestStatus(
      prevDataComponentSpecDetailChildren,
      dataComponentSpecDetailChildren,
      {
        onSuccess: this.handleDeleteSpecDetailSuccess,
        onError: this.handleDeleteRequestError,
      }
    );
  }

  handleDeleteSpecDetailSuccess = () => this.props.closeModalDialog();

  handleDeleteSpecDetailCOMSuccess = () => {
    const { closeModalDialog, remainingRelatedCOMs } = this.props;
    if (remainingRelatedCOMs > 0) return closeModalDialog();

    const { specDetailId, performDeleteRequest } = this.props;
    performDeleteRequest(
      dataComponentSpecDetailChildrenId,
      specDetailId,
      this.getDeleteParams()
    );
  };

  getDeleteParams = () => {
    const { revisionValue } = this.props;
    const revisionIsActive = revisionValue.isActive;
    return revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;
  };

  handleDeleteSpecDetailCOM = () => {
    const { specDetailComId, performDeleteRequest } = this.props;
    performDeleteRequest(
      dataComponentId,
      specDetailComId,
      this.getDeleteParams()
    );
  };

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation
        title={title}
        onDelete={this.handleDeleteSpecDetailCOM}
      />
    );
  }
}

const mapStateToProps = (state, { specDetailId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const dataComponentSpecDetailChildren = getDataComponent(
    dataComponentSpecDetailChildrenId,
    state
  );

  let remainingRelatedCOMs = SpecDetailCom.query(state.resources)
    .where({ specDetailId })
    .toObjects().length;
  return {
    dataComponent,
    dataComponentSpecDetailChildren,
    remainingRelatedCOMs,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  initDataComponent,
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(DeleteSpecDetailCOMContainer, "SpecDetail", "purchaseOrder"));
