import React from "react";

import Paper from "../../../mui/core/Paper";
import propTypes from "../../../../constants/propTypes";
import PurchaseOrder from "../../../../models/PurchaseOrder";
import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import PropTypes from "prop-types";
import {
  Column,
  EstimatedDeliveryDateColumn,
  EstimatedShipDateColumn,
  StatusColumn,
} from "../../ProjectDetail/PurchaseOrder/gridProps";
import ScheduleColumn from "../../ProjectDetail/PurchaseOrder/ScheduleColumn";
import _get from "lodash/get";

export const columnOptions = {
  "project.number": { title: "Project Number", bold: true },
  number: {
    title: "PO Number",
    bold: true,
    render: ({ status, number }) =>
      number && (
        <Column status={status} bold>
          {number}
        </Column>
      ),
  },
  status: {
    render: StatusColumn,
  },
  "vendor.name": {
    title: "Vendor",
    render: ({ status, vendor }) =>
      vendor && <Column status={status}>{_get(vendor, "name")}</Column>,
  },
  needsFollowUp: {
    title: "Schedule",
    render: ScheduleColumn,
  },
  estimatedShipDate: {
    title: "Estimated Ship",
    align: "right",
    render: row => EstimatedShipDateColumn(row),
  },
  estimatedDeliveryDate: {
    title: "Estimated Delivery",
    align: "right",
    render: row => EstimatedDeliveryDateColumn(row),
  },
};

const PurchaseOrdersNeedsFollowUpGrid = ({
  columns,
  dataComponent,
  apiFilters,
  onRowClick,
}) => (
  <Paper>
    <BWGridAPI
      apiRoute="purchase-orders"
      model={PurchaseOrder}
      dataComponent={dataComponent}
      includes={["vendor", "project", "project.property.entity"]}
      apiFilters={apiFilters}
    >
      <GridHeader headerText="POs Needing Follow Up" />
      <GridTable
        columns={columns}
        columnOptions={columnOptions}
        onClick={onRowClick}
      />
    </BWGridAPI>
  </Paper>
);

PurchaseOrdersNeedsFollowUpGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataComponent: propTypes.dataComponent,
  onRowClick: PropTypes.func.isRequired,
  apiFilters: PropTypes.object.isRequired,
};

export default PurchaseOrdersNeedsFollowUpGrid;
