import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { PaginationContext } from "./PaginationControl";
import { operations } from "../../BWGrid/gridAPIFunctions";

export const FilteringContext = createContext({
  isDefault: true,
  onFiltersChange: _noop,
  filterResults: results => ({ results, count: results.length }),
});

export const FilteringStateContext = createContext(null); // Don't use a default value to make required that a parent should provide it.

export const getFilteredItems = (filters, items) => {
  if (_isEmpty(items)) return items;
  return items.filter(item =>
    Object.keys(filters).every(columnName => {
      const { operator, value } = filters[columnName];
      const operationFunc = operations[operator];
      const columnValue = _get(item, columnName) || "";
      return operationFunc(columnValue, value);
    })
  );
};

const withFiltering = WrappedComponent => {
  const FilteredComponent = ({
    useParentFilteringControl,
    defaultFilters,
    ...props
  }) => {
    const parentPaginationControl = useContext(FilteringContext);
    const { setPage } = useContext(PaginationContext);

    const [filters, setFilters] = useState(defaultFilters);

    const filterResults = useCallback(
      items => {
        const results = getFilteredItems(filters, items);
        return { results, count: results.lenght };
      },
      [filters]
    );

    const handleFiltersChange = useCallback(
      _debounce(filters => {
        setFilters(filters);
        setPage(0);
      }, 800),
      [setPage]
    );

    const value = useMemo(() => {
      if (useParentFilteringControl) return parentPaginationControl;
      return {
        isDefault: false,
        filterResults,
        onFiltersChange: handleFiltersChange,
      };
    }, [
      useParentFilteringControl,
      handleFiltersChange,
      filterResults,
      parentPaginationControl,
    ]);

    return (
      <FilteringContext.Provider value={value}>
        <WrappedComponent {...props} />
      </FilteringContext.Provider>
    );
  };
  FilteredComponent.propTypes = {
    useParentFilteringControl: PropTypes.bool,
    gridId: PropTypes.string.isRequired,
    defaultFilters: PropTypes.shape({}),
  };
  FilteredComponent.defaultProps = {
    useParentFilteringControl: false,
    defaultFilters: {},
  };
  return FilteredComponent;
};

export default withFiltering;
