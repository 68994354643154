import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FundingsList from "./FundingsList";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import {
  initializeFilters,
  openBatchFundingReport,
} from "../../../../../actions/fundingActions";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import propTypes from "../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import Funding from "../../../../../models/Funding";

const VOID_STATUS = "Void";
const dataComponentId = "FundingsGrid";
export const dataComponentCountId = "FundingsGridCount";
const projectDataComponentId = "ProjectDetail";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  showSnackNotificationAction,
  setReload
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(dataComponentId, true);
      showSnackNotificationAction("Funding has been updated successfully");
    },
  });
};

const FundingsContainer = ({
  projectId,
  dataComponent,
  dataComponentCount,
  flattenDataComponent,
  openModalDialog,
  performUpdateRequest,
  showSnackNotificationAction,
  initDataComponent,
  performRetrieveListRequest,
  initializeFilters,
  setReload,
  project,
  openBatchFundingReport,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [shouldCounterReload, setCounterReload] = useState(true);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    initDataComponent(dataComponentCountId, Funding, [], "fundings");
    initializeFilters(projectId);
  }, [
    initDataComponent,
    performRetrieveListRequest,
    projectId,
    initializeFilters,
  ]);

  useEffect(() => {
    if (shouldCounterReload) {
      performRetrieveListRequest(dataComponentCountId, {
        count: true,
        rootFilters: {
          $where: {
            projectId,
            status: {
              $notEqual: "Void",
            },
          },
        },
      });
      setCounterReload(false);
      setReload(dataComponentCountId, false);
    }
  }, [shouldCounterReload, setReload, performRetrieveListRequest, projectId]);

  useEffect(() => {
    if (dataComponentCount.reload) {
      setCounterReload(true);
    }
  }, [dataComponentCount.reload]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      showSnackNotificationAction,
      setReload
    );
  }, [
    dataComponent,
    showSnackNotificationAction,
    setReload,
    prevDataComponent,
  ]);

  const handleUpdateStatus = status => ({ id }) => {
    performUpdateRequest(dataComponentId, id, { status });
  };

  const handleOpenPaidConfirmationModal = useCallback(
    funding => {
      if (funding.status === VOID_STATUS) return;
      openModalDialog(
        "Confirm Funding Received",
        "FundingPaidConfirmation",
        {
          funding,
          projectId,
          project,
          fundingId: funding.id,
          dataComponentId,
          sendButtonText: "Save Changes",
          hideCancelButton: true,
          isUpdate: true,
          isFundingPaidConfirmation: true,
        },
        false,
        true,
        {
          width: "790px",
          subtitle: `FR - #${funding.number}`,
          tabs: [
            { content: "Funding", elementTypeID: "FundingPaidConfirmation" },
            { content: "Details", elementTypeID: "CreateFunding" },
          ],
        }
      );
    },
    [openModalDialog, project, projectId]
  );

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create Fund Request",
      "CreateFundingType",
      {
        projectId,
        project,
      },
      false,
      true,
      {
        subtitle: "Select Funding Type",
      }
    );
  }, [openModalDialog, project, projectId]);

  return (
    <FundingsList
      fundingCount={dataComponentCount.totalRows}
      projectId={projectId}
      dataComponent={flattenDataComponent}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenPaidConfirmationModal={handleOpenPaidConfirmationModal}
      onOpenBatchFundingReport={openBatchFundingReport}
      onApprove={handleUpdateStatus("Paid")}
      onVoid={handleUpdateStatus("Void")}
    />
  );
};

FundingsContainer.propTypes = {
  projectId: PropTypes.string,
  project: propTypes.project,
  dataComponent: propTypes.dataComponent,
  dataComponentCount: propTypes.dataComponent,
  flattenDataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  initializeFilters: PropTypes.func.isRequired,
  openBatchFundingReport: PropTypes.func.isRequired,
};

export const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    projectId,
    project: getBluechipResourceById(projectDataComponent, state, projectId),
    dataComponent: getDataComponent(dataComponentId, state),
    dataComponentCount: getDataComponentFlattenedRequestState(
      dataComponentCountId,
      state
    ),
    flattenDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  initDataComponent,
  performRetrieveListRequest,
  initializeFilters,
  openBatchFundingReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingsContainer);
