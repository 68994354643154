import React from "react";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import PropTypes from "prop-types";
import { Paper } from "../../../mui/core";
import UserRole from "../../../../models/UserRole";
import propTypes from "../../../../constants/propTypes";

const columns = ["name", "currentUsers"];
const columnOptions = {
  name: { bold: true },
  currentUsers: { title: "Current Users", fill: true },
};
const rowMenu = ({ onOpenEditModal, onOpenDeleteModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
  { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
];

const actions = onOpenCreateModal => [
  {
    text: "Create User Role",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const UserRolesTable = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={UserRole}
        includes={["userRolePermissions"]}
        apiRoute="user-roles"
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} User Roles`}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu({ onOpenEditModal, onOpenDeleteModal })}
          showSelectionColumn={true}
          showSelectAll={true}
        />
      </BWGridAPI>
    </Paper>
  );
};

UserRolesTable.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default UserRolesTable;
