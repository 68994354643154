import { inputs } from "../../../../../components/inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.code,
            required: true,
          },
          grid: { xs: 12 },
        },
        {
          input: {
            ...inputs.glDescription,
            required: true,
          },
          grid: { xs: 12 },
        },
        {
          input: inputs.isArchived,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
