import React, { createContext, useContext, useMemo } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";
import _get from "lodash/get";

import propTypes from "../../constants/propTypes";
import { createDeepEqualSelector } from "../../utils/selectors";
import * as RequestTypes from "../../constants/RequestTypes";
import { getDataComponent } from "../../reducers/dataComponentReducer";
import { getActiveRequest } from "../../utils/dataComponentUtils";

export const listenerSetting = PropTypes.oneOfType([
  PropTypes.shape({
    dataComponentId: PropTypes.string,
    requestTypes: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(RequestTypes))
    ),
  }),
  PropTypes.string,
]);

export const listenersPropType = PropTypes.oneOfType([
  PropTypes.objectOf(PropTypes.arrayOf(listenerSetting)),
  PropTypes.arrayOf(listenerSetting),
]);

const defaultContextValue = {
  hasRequestActive: () => false,
};

export const RequestListenerContext = createContext(defaultContextValue);

const mapSettings = settings => {
  return settings.map(setting => {
    if (typeof setting === "string") {
      return {
        dataComponentId: setting,
        requestTypes: Object.values(RequestTypes),
      };
    }
    return setting;
  });
};

export const parseListeners = listeners => {
  if (!listeners) return [];
  if (Array.isArray(listeners)) {
    return { primary: mapSettings(listeners) };
  }
  return Object.keys(listeners).reduce((obj, identifier) => {
    obj[identifier] = mapSettings(listeners[identifier]);
    return obj;
  }, {});
};

export function useHasRequestActive(identifier, isLoading) {
  const { hasRequestActive } = useContext(RequestListenerContext);
  const loading = useMemo(() => hasRequestActive(identifier), [
    hasRequestActive,
    identifier,
  ]);
  return isLoading || loading;
}

function withRequestListener(WrappedComponent) {
  const Wrapper = ({ listeners, loading, dataComponents, ...props }) => {
    const value = useMemo(() => {
      return {
        hasRequestActive: identifier => {
          if (loading) return true;
          const settings = _get(listeners, identifier, []);
          return settings.some(({ dataComponentId, requestTypes }) => {
            const dataComponent = dataComponents[dataComponentId];
            return !!getActiveRequest(dataComponent, requestTypes);
          });
        },
      };
    }, [loading, listeners, dataComponents]);
    return (
      <RequestListenerContext.Provider value={value}>
        <WrappedComponent {...props} />
      </RequestListenerContext.Provider>
    );
  };

  Wrapper.propTypes = {
    listeners: listenersPropType,
    dataComponents: PropTypes.objectOf(propTypes.dataComponent),
  };

  const makeMapStateToProps = () => {
    const listenersSelector = createDeepEqualSelector(
      (_, { listeners }) => listeners,
      listeners => parseListeners(listeners)
    );
    return createSelector(
      state => state.dataComponents,
      listenersSelector,
      (_, { loading }) => loading,
      (dataComponents, listeners, loading) => {
        const dataComponentIds = Object.keys(listeners).reduce(
          (ids, identifier) => {
            const newIds = listeners[identifier]
              .map(setting => setting.dataComponentId)
              .filter(id => !ids.includes(id));
            return [...ids, ...newIds];
          },
          []
        );
        const state = { dataComponents };
        const filteredDataComponents = dataComponentIds
          .map(dcId => getDataComponent(dcId, state))
          .reduce((accum, dataComponent) => {
            accum[dataComponent.dataComponentId] = dataComponent;
            return accum;
          }, {});
        return { listeners, loading, dataComponents: filteredDataComponents };
      }
    );
  };

  return connect(makeMapStateToProps)(Wrapper);
}
export default withRequestListener;
