import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Star from "@material-ui/icons/Star";
import IconButton from "@material-ui/core/IconButton";
import StarBorder from "@material-ui/icons/StarBorder";

export const Starred = styled(Star)`
  color: #ff9100;
`;

export const NotStarred = styled(StarBorder)`
  color: rgba(0, 0, 0, 0.54);
`;

export const Button = styled(IconButton)`
  padding: 12px;
  cursor: ${({ disabled }) => (disabled ? "progress" : "pointer")};
`;

const FavoriteStar = ({ onClick, isStarred: initialStarred, loading }) => {
  const [isStarred, setIsStarred] = useState(initialStarred);

  useEffect(() => setIsStarred(initialStarred), [initialStarred]);

  const handleClick = event => {
    event.stopPropagation();
    if (loading) return;
    const currentState = !isStarred;
    setIsStarred(currentState);
    onClick(currentState);
  };

  const StarCmp = isStarred ? Starred : NotStarred;
  return (
    <Button disabled={loading} onClick={handleClick}>
      <StarCmp />
    </Button>
  );
};

FavoriteStar.propTypes = {
  isStarred: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoriteStar;
