import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import CreateShipmentForm from "./CreateShipmentForm";
import {
  initDataComponent,
  performCreateRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import Shipment from "../../../../../models/Shipment";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { LoaderContext } from "../../../../ui/Loader";

export const dataComponentId = "CreateShipmentDC";

const getSchema = remainingQty =>
  BWModels.loadSchema("Shipment").concat(
    Joi.object().keys({
      quantity: Joi.number().max(remainingQty),
    })
  );

export class CreateShipmentContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    remainingQty: PropTypes.number.isRequired,
    estimatedDeliveryDate: PropTypes.string,
    estimatedShipDate: PropTypes.string,
    initShipmentDataComponent: PropTypes.func,
    createShipment: PropTypes.func,
    dataComponent: propTypes.dataComponent,
    loading: PropTypes.bool,
    shipmentActions: PropTypes.object,
    openModalDialog: PropTypes.func,
    shipmentsDataComponentId: PropTypes.string,
    totalQuantityApprovedToPay: PropTypes.number,
  };

  componentDidMount() {
    this.props.initShipmentDataComponent();
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;
    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  createShipment = (shipment, formikActions) => {
    const {
      createShipment,
      shipmentActions,
      openModalDialog,
      shipmentsDataComponentId,
    } = this.props;

    if (shipmentActions && shipmentActions.isFromManageShipments()) {
      shipmentActions.addShipment(shipment);
      shipmentActions.openManageShipmentsModal(
        openModalDialog,
        shipmentsDataComponentId
      );
      return;
    }

    createShipment(shipment);
    this.setState({ formikActions });
  };

  handleCancel = () => {
    const {
      shipmentActions,
      closeModalDialog,
      openModalDialog,
      shipmentsDataComponentId,
    } = this.props;
    if (shipmentActions && shipmentActions.isFromManageShipments()) {
      shipmentActions.openManageShipmentsModal(
        openModalDialog,
        shipmentsDataComponentId
      );
      return;
    }

    closeModalDialog();
  };

  render() {
    const {
      remainingQty,
      estimatedDeliveryDate,
      estimatedShipDate,
      loading,
    } = this.props;
    return (
      <LoaderContext.Provider value={{ loading }}>
        <CreateShipmentForm
          schema={getSchema(remainingQty)}
          remainingQty={remainingQty}
          onSubmit={this.createShipment}
          estimatedDeliveryDate={estimatedDeliveryDate}
          estimatedShipDate={estimatedShipDate}
          loading={loading}
          onCancel={this.handleCancel}
          totalQuantityApprovedToPay={this.props.totalQuantityApprovedToPay}
        />
      </LoaderContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: getDataComponentFlattenedRequestState(
      dataComponentId,
      state,
      REQUEST_TYPES.CREATE
    ).loading,
  };
};

const mapDispatchToProps = (dispatch, { specId }) => {
  return {
    initShipmentDataComponent: () =>
      dispatch(
        initDataComponent(
          dataComponentId,
          Shipment,
          ["specShipment"],
          "shipments"
        )
      ),
    createShipment: shipment =>
      dispatch(performCreateRequest(dataComponentId, { ...shipment, specId })),
    ...bindActionCreators({ closeModalDialog, openModalDialog }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateShipmentContainer);
