import React, { Fragment } from "react";
import propTypes from "../../../../../../constants/propTypes";

import styled from "styled-components";
import RegularText from "../../../../../ui/Typography/RegularText";
import RegularTextSmall from "../../../../../ui/Typography/RegularTextSmall";
import SubHeader from "../../../../../ui/Typography/Subheader";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";
import FundingRequestDocument from "./FundingRequestDocument";
const ContentWrapper = styled.div`
  padding: 0px 24px 24px 24px;
`;
const Label = styled(RegularTextSmall)`
  color: rgba(0, 0, 0, 0.54);
  margin-top: 24px;
`;
const SectionHeader = styled(RegularText)`
  color: rgba(0, 0, 0, 0.87);
  margin-top: 50px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: start;
  font-family: Open Sans, sans-serif;
  color: rgba(0, 0, 0, 0.54);
`;
export const Link = styled.a`
  text-decoration: none;
  color: #57abff;
  align-items: center;
  cursor: pointer;
`;

const FundingDetails = props => {
  const { funding } = props;
  const { projectCurrency } = funding;

  const currencyFormatter = useCurrencyFormatter(projectCurrency?.currency);

  return (
    <ContentWrapper>
      <Label>Amount Requested</Label>
      <SubHeader>{currencyFormatter.format(funding.amountRequested)}</SubHeader>
      {funding.method == "Cashflow" && (
        <Fragment>
          <SectionHeader>Cashflow Documents</SectionHeader>
          <FundingRequestDocument
            headerLabel={"Cashflow File"}
            file={funding.cashflowFile}
          />
          <FundingRequestDocument
            headerLabel={"Project Budget File"}
            file={funding.projectBudgetFile}
          />
        </Fragment>
      )}
    </ContentWrapper>
  );
};
FundingDetails.propTypes = {
  funding: propTypes.funding,
};
export default FundingDetails;
