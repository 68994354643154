export const dataComponentInitialData = {
  includes: [],
  model: null,
  requestState: {},
};

export const requestInitialStates = {
  list: {
    totalRows: 0,
    totalPages: 0,
    pageNumber: 0,
    pageSize: 10,
    sort: [],
    selection: {},
    selectedIds: [],
    selectedIdsByPage: [],
    rootFilters: null,
    filters: {},
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
  find: {
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
  create: {
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
  delete: {
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
  update: {
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
  import: {
    loading: false,
    success: false,
    error: null,
    rowIndex: [],
  },
};

export default {
  dataComponentResources: {},
  scopedCurrency: {
    name: "US Dollar - $",
    isoCode: "USD",
    symbol: "$",
  },
  scope: {},
  layout: {
    isNavbarDrawerOpen: true,
    snackNotification: {
      isOpen: false,
      message: null,
      hideDuration: null,
    },
    showLoader: false,
    modalDialog: {
      isOpen: false,
      renderBackground: true,
      title: null,
      elementTypeID: null,
      elementProperties: null,
      isShowCancelButton: false,
    },
    headerTitle: null,
  },
  errors: {
    mapSourceToErrors: {},
    validationErrors: {},
  },
  auth: {
    token: null,
    name: null,
    email: null,
    phoneNumber: null,
    cellNumber: null,
    userId: null,
    role: {
      permissions: [],
    },
    office: null,
    updatingPassword: false,
    loadingAuthentication: false,
    sendingPasswordResetEmail: false,
  },
  specDetail: {},
  reports: {
    loaders: {},
  },
  fileUploads: {
    loaders: {},
  },
  notes: {
    isRelationsLoading: false,
    isCreateActive: false,
    filters: {
      category: null,
      search: "",
      checks: {
        myNotes: false,
        myMentions: false,
      },
      timeBoundaries: {},
    },
    context: {},
  },
  queues: {},
  bidGroups: {
    deletedBidGroups: [],
  },
  collapsibleItems: {},
  editableTextDetailRow: {},
  status: {
    herokuStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    herokuDatabaseStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    herokuRedisStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    apiVersion: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    databaseStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    messagingStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    emailStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
    lambdaStatus: {
      loading: false,
      success: false,
      error: false,
      data: {},
    },
  },
};
