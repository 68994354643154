import { useHasRequestActive } from "../../hocs/withRequestListener";
import React from "react";
import { ActionButton, StyledCircularProgress, Child } from "./components";
import styled from "styled-components";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";

export const StyledActionButton = styled(props => (
  <ActionButton color="secondary" {...props} />
))`
  color: #ff1744;
  background-color: #ffffff;
  &:hover {
    background-color: rgba(127, 11, 34, 0.08);
  }
`;

const CircularProgress = styled(StyledCircularProgress)`
  color: #ff1744;
`;

const DangerButton = ({
  children,
  isLoading,
  disableIfProjectClosed,
  ...props
}) => {
  const loading = useHasRequestActive("danger", isLoading);

  const isProjectClosed = useIsProjectClosed();

  const disabled =
    props.disabled || (isProjectClosed && disableIfProjectClosed);

  return (
    <StyledActionButton {...props} loading={loading} disabled={disabled}>
      <Child loading={loading}>{children}</Child>
      {loading && <CircularProgress size={20} />}
    </StyledActionButton>
  );
};

export default DangerButton;
