import React from "react";
import styled from "styled-components";

import { DefaultContent } from "../../../../layout/AppLayout/ModalDialog/components";
import UISubheader from "../../../../ui/Typography/Subheader";
import RegularTextSmall from "../../../../ui/Typography/RegularTextSmall";
import { Wrapper as EmptyStateWrapper } from "../../../../ui/EmptyState";

const StyledContent = styled(DefaultContent)`
  min-height: 70vh;
  width: auto;
  min-width: 560px;
  display: flex;
`;

export const Content = props => <StyledContent {...props} />;

export const FormWrapper = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: inherit;
  max-width: 750px;
`;

export const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Subheader = styled(UISubheader)`
  margin-bottom: 24px;
`;

export const StatusLabel = styled(RegularTextSmall)`
  margin-bottom: 8px;
`;

export const GridSection = styled.div`
  padding-left: 24px;
`;

export const GridWrapper = styled.div`
  border-bottom: ${({ showBorder }) =>
    showBorder && "1px solid rgba(0, 0, 0, 0.12)"};
  ${EmptyStateWrapper} {
    margin-left: -24px;
  }
  th,
  td {
    &:first-child {
      padding-left: 0;
    }
  }
`;

export const ActionWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const ApproveButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StatusLine = styled.div`
  display: flex;
  align-items: center;
  > * {
    line-height: 20px;
    margin-right: 16px;
  }
`;

export const ApprovalBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #72b327;
  padding: 22px 0;
  > * {
    color: #ffffff;
    font-weight: bold;
    &:first-child {
      margin-right: 8px;
    }
  }
`;
