import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { BWGridLocal, GridTable } from "../../../../ui/BWGrid";

import UploadDashboard from "./UploadDashboard";
import {
  VersionColumn,
  DateColumn,
  StatusColumn,
  UserColumn,
  FileLinkColumn,
  GridWrapper,
  DeleteButton,
  UploadPanel,
} from "./components";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

const columns = [
  "revision.version",
  "createdAt",
  "status",
  "user.name",
  "file.filename",
  "delete",
];

const defaultColumnsOptions = {
  "revision.version": {
    bold: true,
    title: "#",
    sortingEnabled: false,
    render: VersionColumn,
  },
  createdAt: {
    bold: true,
    title: "Date",
    sortingEnabled: false,
    render: DateColumn,
  },
  status: {
    bold: true,
    sortingEnabled: false,
    render: StatusColumn,
  },
  "user.name": {
    bold: true,
    title: "By",
    sortingEnabled: false,
    render: UserColumn,
  },
  "file.filename": {
    bold: true,
    title: " ",
    sortingEnabled: false,
    fill: true,
    render: FileLinkColumn,
  },
  delete: {
    bold: true,
    title: " ",
    sortingEnabled: false,
  },
};

const ApprovalManager = ({
  onUploadApproval,
  onDeleteApproval,
  isDeleting,
  files,
  deletableVersions,
}) => {
  const isProjectClosed = useIsProjectClosed();

  const columnOptions = useMemo(() => {
    return {
      ...defaultColumnsOptions,
      delete: {
        ...defaultColumnsOptions.delete,
        render: ({ id, revision }) => {
          return (
            id && (
              <DeleteButton
                id={id}
                revision={revision}
                onDeleteApproval={onDeleteApproval}
                isDeleting={isDeleting}
                deletableVersions={deletableVersions}
                disabled={isDeleting || isProjectClosed}
              />
            )
          );
        },
      },
    };
  }, [onDeleteApproval, isDeleting, deletableVersions, isProjectClosed]);

  return (
    <Fragment>
      <UploadPanel>
        <UploadDashboard
          onSaveFile={onUploadApproval}
          disabled={isProjectClosed}
        />
      </UploadPanel>
      <GridWrapper>
        <BWGridLocal
          id="approval-manager-grid"
          rows={files}
          gridConfig={{ pageSize: 10, totalRows: files.length }}
          noBottomMargin={true}
        >
          <GridTable columns={columns} columnOptions={columnOptions} />
        </BWGridLocal>
      </GridWrapper>
    </Fragment>
  );
};

ApprovalManager.propTypes = {
  onUploadApproval: PropTypes.func.isRequired,
  onDeleteApproval: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  deletableVersions: PropTypes.arrayOf(PropTypes.string),
};

export default ApprovalManager;
