import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import propTypes from "../../../constants/propTypes";
import SpecDetailDescription from "./SpecDetailDescription";
import SpecDetailCaseBased from "./SpecDetailCaseBased";
import SpecDetailParBased from "./SpecDetailParBased";
import SpecDetailCOM from "./SpecDetailCOM";
import SpecDetailPreview from "./SpecDetailPreview";

const specTypes = {
  COM: SpecDetailCOM,
  QCParBased: SpecDetailParBased,
  QCCaseBased: SpecDetailCaseBased,
  Preview: SpecDetailPreview,
};

const DetailWrapper = styled.div`
  flex-grow: 1;
  margin: 12px 0 0 23px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;

const SpecDetail = ({ specDetail, purchaseOrder, index }) => {
  const DetailComponent = specTypes[specDetail.type] || SpecDetailDescription;
  return (
    <DetailWrapper>
      <DetailComponent
        specDetail={specDetail}
        purchaseOrder={purchaseOrder}
        index={index}
      />
    </DetailWrapper>
  );
};

SpecDetail.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  specDetail: PropTypes.object,
  index: PropTypes.number,
};

export default SpecDetail;
