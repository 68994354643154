import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Vendor from "../../../../models/Vendor";
import BidGroupVendor from "../../../../models/BidGroupVendor";
import { blueChipActions } from "../../../../store/blueChipConfig";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { getCreatedIds } from "../../../../utils/dataComponentUtils";
import {
  initDataComponent,
  fetchData,
  performCreateRequest,
} from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import AddVendor from "./AddVendor";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import useScope from "../../../hooks/useScope";
export const dataComponentId = "VendorsGrid";
export const dataComponentBGVendorId = "BidGroupVendor";
export const BGDataComponentId = "BidGroupDetailId";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  bidGroup
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      const createdIds = getCreatedIds(dataComponent);
      closeModalDialog();
      const bidGroupVendors = bidGroup.bidGroupVendors || [];
      const data = {
        type: "bidGroups",
        id: bidGroup.id,
        relationships: {
          bidGroupVendors: {
            data: [
              ...bidGroupVendors.map(({ id }) => ({
                type: "bidGroupVendors",
                id,
              })),
              ...createdIds.map(id => ({ type: "bidGroupVendors", id })),
            ],
          },
        },
      };
      blueChipActions.updateResource(data);
    },
    onError: () => {},
  });
};

export const AddVendorContainer = ({
  bidGroup,
  initDataComponent,
  fetchData,
  vendors,
  vendorsLoading,
  closeModalDialog,
  performCreateRequest,
  dataComponent,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  const scope = useScope();

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      bidGroup
    );
  }, [bidGroup, closeModalDialog, dataComponent, prevDataComponent]);

  const selectedVendorIds = useMemo(() => {
    const bidGroupVendors = bidGroup.bidGroupVendors || [];
    return bidGroupVendors.map(({ vendorId }) => vendorId);
  }, [bidGroup]);

  const handleCreateBidGroupVendors = useCallback(
    items => {
      performCreateRequest(dataComponentBGVendorId, items);
    },
    [performCreateRequest]
  );

  useEffect(() => {
    initDataComponent(dataComponentId, Vendor, ["category"], "vendors");
    initDataComponent(
      dataComponentBGVendorId,
      BidGroupVendor,
      ["bidGroup", "vendor", "contacts.contact"],
      "bid-group-vendors"
    );
    fetchData(dataComponentId, REQUEST_TYPES.LIST, {
      pageSize: -1,
      rootFilters: {
        $where: {
          id: {
            $notIn: selectedVendorIds,
          },
          "scope.id": scope.id,
        },
      },
    });
  }, [fetchData, initDataComponent, scope, selectedVendorIds]);

  return (
    <AddVendor
      bidGroup={bidGroup}
      onCreateBidGroupVendors={handleCreateBidGroupVendors}
      vendors={vendors}
      vendorsLoading={vendorsLoading}
      closeModalDialog={closeModalDialog}
    />
  );
};

AddVendorContainer.propTypes = {
  initDataComponent: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  bidGroup: propTypes.bidGroup,
  vendors: PropTypes.arrayOf(propTypes.vendor),
  vendorsLoading: PropTypes.bool,
  dataComponent: propTypes.dataComponent,
};

AddVendorContainer.defaultProps = {};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const vendors = getBluechipResources(dataComponent, state);

  return {
    vendors: vendors || [],
    vendorsLoading: dataComponent.loading,
    dataComponent: getDataComponent(
      dataComponentBGVendorId,
      state,
      REQUEST_TYPES.CREATE
    ),
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  closeModalDialog,
  fetchData,
  performCreateRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVendorContainer);
