import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import { inputs } from "../../../../../components/inputs/inputConfigs";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const FormGridContainer = styled.div`
  padding-bottom: 20px;
`;

const projectFields = column => ({
  groups: [
    {
      items: [
        {
          input: { ...inputs.customDate(column), disablePast: false },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

const ProjectDateColumnForm = ({
  onSubmit,
  initialValues,
  column,
  dataComponentId,
}) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      allowPartialValidation
      validationSchema={BWModels.loadSchema("Project")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGridContainer>
            <FormGrid
              fields={projectFields(column)}
              values={values}
              errors={errors}
              {...formikProps}
            />
          </FormGridContainer>

          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

ProjectDateColumnForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.flag,
  column: PropTypes.string,
};

export default ProjectDateColumnForm;
