import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/AddCircleOutline";

import InputLoader from "../../../../ui/Loader/InputLoader";
import Subheader from "../../../../ui/Typography/Subheader";
import { BWActionButton } from "../../../../ui/BWActionButton";
import { PlaceholderList } from "./components";
import { LabeledTextContext } from "../../../../../withPORevision";
import AddContactMenu from "./AddContactMenu";
import List, { commonPropTypes } from "./List";
import { ContactsHeader, ContactsContainer } from "./components";
import propTypes from "../../../../../constants/propTypes";
import Loader from "../../../../ui/Loader";

const titleMap = {
  vendor: "Contacts",
  designer: "Contacts",
  freight: "Freight Contact",
};

export const thereAreContacts = (projectContacts, vendorContacts) =>
  projectContacts.length > 0 || vendorContacts.length > 0;

export class Contacts extends Component {
  static contextType = LabeledTextContext;
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  get title() {
    return titleMap[this.props.type] || titleMap.freight;
  }

  render() {
    const {
      onClickItem,
      displayAddContact,
      vendorSpec,
      vendorContacts,
      projectContacts,
      AddContactMenuComponent,
      required,
      ...commonProps
    } = this.props;
    const { anchorEl } = this.state;
    const disabledOptions = this.context;
    return (
      <ContactsContainer>
        <ContactsHeader>
          <Subheader required={required} emphasizeRequired>
            {this.title}
          </Subheader>
          {displayAddContact && (
            <Fragment>
              {!disabledOptions && commonProps.vendor.id && (
                <Loader width="100px" height="24px">
                  <BWActionButton
                    icon={<AddIcon />}
                    text="ADD CONTACT"
                    onClick={this.handleClick}
                    disableIfProjectClosed
                  />
                </Loader>
              )}
              <AddContactMenuComponent
                anchorEl={anchorEl}
                disabledOptions={disabledOptions}
                handleClose={this.handleClose}
                projectContacts={projectContacts}
                {...commonProps}
              />
            </Fragment>
          )}
        </ContactsHeader>
        <InputLoader>
          {thereAreContacts(projectContacts, vendorContacts) ? (
            <List
              vendorSpec={vendorSpec}
              onClickItem={onClickItem}
              vendorContacts={vendorContacts}
              projectContacts={projectContacts}
              PurchaseOrderDetail
              {...commonProps}
            />
          ) : (
            <PlaceholderList title="No Contacts Selected" />
          )}
        </InputLoader>
      </ContactsContainer>
    );
  }
}

Contacts.propTypes = {
  ...commonPropTypes,
  displayAddContact: PropTypes.bool,
  projectContacts: PropTypes.arrayOf(propTypes.projectContact),
};

Contacts.defaultProps = {
  type: "vendor",
  displayAddContact: true,
  vendorContacts: [],
  projectContacts: [],
  AddContactMenuComponent: AddContactMenu,
  required: true,
};

export default Contacts;
