import React, { useMemo } from "react";
import fields from "./createFundingFields";
import styled from "styled-components";

import {
  FormPageSection,
  formSectionPropTypes,
} from "../../../layout/FormPageSection";
import PageSection from "../../../layout/PageSection";

const StyledWrapper = styled.div`
  ${PageSection} {
    margin-top: 24px;
  }
`;

const FundingSection = props => {
  const fundingDue = props.values.fundingDue;
  const memoFields = useMemo(() => fields({ fundingDue }), [fundingDue]);

  return (
    <StyledWrapper>
      <FormPageSection {...props} fields={memoFields} headerText="Funding" />
    </StyledWrapper>
  );
};

FundingSection.propTypes = formSectionPropTypes;

export default FundingSection;
