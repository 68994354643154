import React from "react";
import PropTypes from "prop-types";

import { ContactList } from "../../VendorInfo/components";
import ContactItem, { ContactItemWrapper } from "../../VendorInfo/ContactItem";
import Loader from "../../../../../ui/Loader";
import { ListItemText } from "@material-ui/core";
import EmptyState from "../../../../../ui/EmptyState";

export const List = ({ projectSiteContacts, onItemClick }) => {
  return (
    <Loader count={10}>
      <ContactList>
        {projectSiteContacts.map((projectSiteContact, i) => (
          <ContactItem
            key={i}
            vendorContact={{ contact: projectSiteContact.contact }}
            onClick={() => onItemClick(projectSiteContact.id)}
          />
        ))}
        {projectSiteContacts.length === 0 && (
          <ContactItemWrapper>
            <ListItemText>
              <EmptyState>No results</EmptyState>
            </ListItemText>
          </ContactItemWrapper>
        )}
      </ContactList>
    </Loader>
  );
};

List.propTypes = {
  projectSiteContacts: PropTypes.array,
  onItemClick: PropTypes.func.isRequired,
};
