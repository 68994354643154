import moment from "moment";

import { inputs } from "../../../../inputs/inputConfigs";
import { ApprovedToPay } from "../SpecShipments/ApprovedToPay";
import { isPaidShipment } from "../ManageShipments/gridProps";

export const handleDateChange = (targetField, values, clearTargetField) => ({
  target: { value },
}) => {
  const targetValue = values[targetField];

  const shouldClearTarget = moment(value).isAfter(targetValue, "day");

  if (shouldClearTarget) {
    clearTargetField();
  }
};

export const getDateInput = (input, targetField, values, clearTargetField) => {
  const targetValue = values[targetField];
  if (targetValue && moment(targetValue).isValid()) {
    return {
      ...input,
      handleChange: clearTargetField
        ? handleDateChange(targetField, values, clearTargetField)
        : null,
    };
  }
  return input;
};

export const getApprovedToPay = (
  spec,
  setFieldValue,
  values,
  totalQuantityApprovedToPay
) => {
  const handleApproveToPay = (_, value) =>
    setFieldValue("approvedToPay", value);

  return {
    ...inputs.approvedToPay,
    InputComponent: ApprovedToPay,
    shipment: values,
    handleApproveToPay,
    totalQuantityApprovedToPay: totalQuantityApprovedToPay,
    totalQuantityPaid: spec?.totalQuantityPaid,
  };
};

export default function(
  shipmentQuantityLimit,
  values,
  setFieldValue,
  spec,
  totalQuantityApprovedToPay
) {
  const paidShipment =
    values.approvedToPay &&
    isPaidShipment(spec, totalQuantityApprovedToPay, values.quantity);
  const approvedToPay = getApprovedToPay(
    spec,
    setFieldValue,
    values,
    totalQuantityApprovedToPay
  );
  const quantityInputs =
    shipmentQuantityLimit !== null
      ? [
          {
            input: {
              ...inputs.shipmentQty,
              avoidDebounce: true,
              limit: shipmentQuantityLimit,
              disabled: paidShipment,
              limitPrecision: 3,
            },
            grid: { xs: 6 },
          },
          {
            input: approvedToPay,
            grid: { xs: 6 },
          },
        ]
      : [];

  const approvedToPayField =
    shipmentQuantityLimit === null
      ? [
          {
            input: approvedToPay,
            grid: { xs: 6 },
          },
        ]
      : [];

  return {
    groups: [
      {
        items: [
          ...quantityInputs,
          {
            input: getDateInput(
              inputs.estimatedShipDate,
              "estimatedDeliveryDate",
              values,
              () => setFieldValue("estimatedDeliveryDate", null)
            ),
            grid: { xs: 6 },
          },
          {
            input: getDateInput(
              inputs.estimatedDeliveryDate,
              "estimatedShipDate",
              values
            ),
            grid: { xs: 6 },
          },
          {
            input: inputs.shipmentTrackingNumber,
            grid: { xs: 6 },
          },
          {
            input: inputs.shipmentReceivedBy,
            grid: { xs: 6 },
          },
          {
            input: getDateInput(
              inputs.actualShipDate,
              "actualDeliveryDate",
              values,
              () => setFieldValue("actualDeliveryDate", null)
            ),
            grid: { xs: 6 },
          },
          {
            input: {
              ...getDateInput(
                inputs.actualDeliveryDate,
                "actualShipDate",
                values
              ),
              handleChange: ({ target: { value } }) =>
                setFieldValue &&
                value &&
                setFieldValue(inputs.approvedToPay.name, true),
            },
            grid: { xs: 6 },
          },
          {
            input: inputs.notes,
            grid: { xs: 12 },
          },
          ...approvedToPayField,
        ],
      },
    ],
  };
}
