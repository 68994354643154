import React, { Fragment, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../constants/propTypes";
import { ConsultingComanyDetailHeader } from "./ConsultingCompanyDetailHeader";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import ConsultingCompany from "../../../models/ConsultingCompany";
import Content from "../../layout/Content/Content";
import { Paper } from "../../mui/core";
import { openModalDialog } from "../../../actions/layoutActions";
import { useUpdateProcessRequests } from "../../hooks/useProcessRequest";
import { useDatacomponent } from "../../hooks/useDatacomponent";
import { dataComponentId as editDataComponentId } from "../ConsultingCompanies/EditConsultingCompany/EditConsultingCompanyContainer";

const navigationTabs = [
  { path: "/projects", label: "Projects" },
  { path: "/contacts", label: "Contacts" },
];

export const dataComponentId = "ConsultingCompanies";
export const ConsultingCompanyDetail = ({
  consultingCompanyId,
  consultingCompany,
  initDataComponent,
  performFindRequest,
  openModalDialog,
  match,
  route,
}) => {
  useEffect(() => {
    initDataComponent(
      dataComponentId,
      ConsultingCompany,
      ["location"],
      "consulting-companies",
      false,
      "v2"
    );
    performFindRequest(dataComponentId, consultingCompanyId);
  }, [consultingCompanyId, initDataComponent, performFindRequest]);

  useUpdateProcessRequests(useDatacomponent(editDataComponentId), {
    onSuccess: () => {
      performFindRequest(dataComponentId, consultingCompanyId);
    },
  });

  const handleEditConsultingCompany = () => {
    openModalDialog(
      `Edit ${consultingCompany.name}`,
      "EditConsultingCompany",
      {
        consultingCompanyId: consultingCompany.id,
      },
      false
    );
  };

  return (
    <Fragment>
      <ConsultingComanyDetailHeader
        consultingCompany={consultingCompany}
        handleEditConsultingCompany={handleEditConsultingCompany}
        navigationTabs={navigationTabs}
      />
      <Content>
        <Paper>{renderRoutes(route.routers, { match })}</Paper>
      </Content>
    </Fragment>
  );
};

ConsultingCompanyDetail.propTypes = {
  consultingCompanyId: PropTypes.string,
  consultingCompany: propTypes.consultingCompany,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  route: propTypes.route,
  match: propTypes.matchRouter,
};

const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  openModalDialog,
};

export const mapStateToProps = (state, ownProps) => {
  const consultingCompanyId = ownProps.match.params.consultingCompanyId;
  const dataComponent = getDataComponent(dataComponentId, state);

  return {
    dataComponent,
    consultingCompanyId,
    consultingCompany:
      getBluechipResourceById(dataComponent, state, consultingCompanyId) || {},
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsultingCompanyDetail)
);
