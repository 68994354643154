import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";

import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import AutocompleteSelect from "./AutocompleteSelect";
import SingleAutoCompleteSelect from "./SingleAutoCompleteSelect";
import StandardSelect from "./StandardSelect";
import withAPIFilter from "./withAPIFilter";
import { getHelperText, useLabeledText } from "../TextInputWithError";
import MultipleSelect from "./MultipleSelect";
import Scope from "../../../models/Scope";

const AutocompleteSelectWithAPIFilter = withAPIFilter(AutocompleteSelect);
const SingleAutocompleteSelectWithAPIFilter = withAPIFilter(
  SingleAutoCompleteSelect,
  -1
);
const defaultFilter = arr => arr || [];
const getAutocompleteComponent = (isAutocomplete, isSimple) => {
  if (!isAutocomplete) return;
  if (isSimple) return SingleAutocompleteSelectWithAPIFilter;
  return AutocompleteSelectWithAPIFilter;
};

const runNextChunk = (options, batchState, filter) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const { lastPosition, batchSize } = batchState;
        const chunk = options.slice(lastPosition, lastPosition + batchSize);
        batchState.lastPosition = lastPosition + batchSize;
        batchState.options = batchState.options.concat(filter(chunk));
        resolve();
      } catch (e) {
        reject(e);
      }
    }, 0);
  });
};

export async function runFilterBatch(
  initialOptions,
  additionalOptions,
  filter,
  setFilteredOptions,
  operationStatus
) {
  const options =
    typeof initialOptions === "function" ? initialOptions() : initialOptions;
  const batchState = { options: [], lastPosition: 0, batchSize: 25 };

  while (!operationStatus.isEnded && batchState.lastPosition < options.length) {
    await runNextChunk(options, batchState, filter);
  }
  if (!operationStatus.isEnded) {
    setFilteredOptions([...batchState.options, ...additionalOptions]);
  }
}

const useFilteredOptions = (
  initialOptions,
  additionalOptions,
  filter,
  isOptionDisabled
) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const prevOperationStatus = useRef({
    isEnded: false,
  });

  useEffect(() => {
    prevOperationStatus.current.isEnded = true;
    prevOperationStatus.current = {
      isEnded: false,
    };
    runFilterBatch(
      initialOptions,
      additionalOptions,
      filter,
      setFilteredOptions,
      prevOperationStatus.current
    );
    return () => {
      prevOperationStatus.current.isEnded = true;
    };
  }, [initialOptions, additionalOptions, filter]);

  if (!isOptionDisabled) {
    return filteredOptions;
  }

  return filteredOptions.map(option => ({
    ...option,
    menuItemProps: {
      ..._get(option, "menuItemProps", {}),
      disabled: isOptionDisabled(option),
    },
  }));
};

export const SelectWithError = ({
  dataComponentId,
  dispatch,
  isAutocomplete,
  isAutocompleteMulti,
  valueProperty,
  label,
  errorMessage,
  isDisabled,
  filter,
  additionalOptions,
  options: initialOptions,
  isSingleAutocomplete,
  castFunction,
  multiple,
  isOptionDisabled,
  ...props
}) => {
  const labeledText = useLabeledText(props.ignoreLabeledText);
  const helperText = getHelperText(
    props.ignoreCaption,
    props.helperText,
    props.error,
    errorMessage
  );
  const AutoCompleteComponent = getAutocompleteComponent(
    isAutocomplete,
    isSingleAutocomplete
  );

  const options = useFilteredOptions(
    initialOptions,
    additionalOptions,
    filter,
    isOptionDisabled
  );
  const cachedAPIOptions = useRef(props.APIOptions);

  const value = castFunction(props.value);
  const standardSelectValue = options.length > 0 ? value : undefined;

  if (AutoCompleteComponent) {
    return (
      <AutoCompleteComponent
        {...props}
        value={value}
        APIOptions={cachedAPIOptions.current}
        dataComponentId={dataComponentId}
        options={options}
        isDisabled={labeledText || isDisabled}
        helperText={helperText}
        isAutocompleteMulti={isAutocompleteMulti}
        valueProperty={valueProperty}
        label={label}
      />
    );
  }
  return (
    <Fragment>
      {multiple ? (
        <MultipleSelect
          {...props}
          value={standardSelectValue}
          options={options}
          helperText={helperText}
          label={label}
        />
      ) : (
        <StandardSelect
          {...props}
          value={standardSelectValue}
          options={options}
          helperText={helperText}
          label={label}
        />
      )}
    </Fragment>
  );
};

SelectWithError.defaultProps = {
  additionalOptions: [],
  filter: defaultFilter,
  castFunction: value => value,
};

SelectWithError.propTypes = {
  ...StandardSelect.propTypes,
  castFunction: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  valueProperty: PropTypes.string,
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  nullAsEmpty: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  dataComponentId: PropTypes.string,
  isAutocomplete: PropTypes.bool,
  isAutocompleteMulti: PropTypes.bool,
  submitData: PropTypes.bool,
  ignoreLabeledText: PropTypes.bool,
  filter: PropTypes.func,
  dispatch: PropTypes.func,
};

export const mapStateToProps = () => {
  return (state, { dataComponentId, options }) => {
    if (options) return {};

    const dataComponent = getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    );
    const { scope } = state;
    const filterByScope =
      scope?.id && Scope.filterScopeModels.includes(dataComponent.model?.name);
    return {
      options: (getBluechipResources(dataComponent, state) || []).filter(
        ({ scopeId }) => !filterByScope || scopeId === scope.id
      ),
    };
  };
};

export default connect(mapStateToProps)(SelectWithError);
