import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";
import Edit from "@material-ui/icons/Edit";

import { DragAndDropList } from "../../../ui/DragAndDropList";
import Body from "./Body";
import Header from "./Header";
import propTypes from "../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../withPORevision";
import Paper from "../../../mui/core/Paper";
import CheckboxManager from "./CheckboxManager";

export const emptySection = (labeledText, specDetails = []) =>
  labeledText && specDetails.length === 0;

export const handleReorder = formikArrayHelpers => ({
  destination,
  source,
}) => {
  if (!destination) return;
  formikArrayHelpers.move(source.index, destination.index);
};

const SpecDetailDragAndDropContainer = ({
  spec,
  openAddDetailModal,
  handleOnClickMenu,
  onEditDetailModal,
  values,
  purchaseOrder,
  ...formikProps
}) => {
  const isLabeled = useContext(LabeledTextContext);

  const headerActions = useMemo(
    () => [
      {
        text: "EDIT DESCRIPTIONS",
        icon: <Edit />,
        handler: onEditDetailModal,
        disableIfProjectClosed: true,
      },
      {
        text: "ADD DETAIL",
        icon: <AddCircle />,
        handler: openAddDetailModal,
        disableIfProjectClosed: true,
      },
    ],
    [openAddDetailModal, onEditDetailModal]
  );

  if (emptySection(isLabeled, spec.specDetails)) return null;

  return (
    <Paper>
      <DragAndDropList name="specDetailIdSequence">
        {formikArrayHelpers => (
          <CheckboxManager>
            {!isLabeled && <Header actions={headerActions} />}
            <Body
              purchaseOrder={purchaseOrder}
              handleOnClickMenu={handleOnClickMenu}
              onReorder={handleReorder(formikArrayHelpers)}
              specDetails={spec.specDetails}
              idSequence={spec.specDetailIdSequence}
              revisionReference="specDetails"
              {...formikProps}
            />
          </CheckboxManager>
        )}
      </DragAndDropList>
    </Paper>
  );
};

SpecDetailDragAndDropContainer.propTypes = {
  spec: propTypes.spec,
  openAddDetailModal: PropTypes.func.isRequired,
  onEditDetailModal: PropTypes.func.isRequired,
  handleOnClickMenu: PropTypes.func,
};

export default SpecDetailDragAndDropContainer;
