import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import SpecCategoryDetail from "./SpecCategoryDetail";

export default class SpecCategory extends BaseModel {
  static get belongsTo() {
    return [Spec];
  }

  static get hasMany() {
    return [SpecCategoryDetail];
  }
}

export const getSpecCategoryLabel = ({ name, isActive }) =>
  isActive ? name : `${name} (Inactive)`;
