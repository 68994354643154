import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../forms";
import { ActionButtons } from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import { unitOfMeasureDataComponentId } from "../UnitOfMeasureContainer";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.description, required: true },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const EditUnitOfMeasure = ({
  unitOfMeasure,
  handleEditUnitOfMeasure,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{
        name: unitOfMeasure.name,
        description: unitOfMeasure.description,
      }}
      onSubmit={handleEditUnitOfMeasure}
      validationSchema={BWModels.loadSchema("UnitOfMeasure")}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Save"
            listeners={[unitOfMeasureDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
