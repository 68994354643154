import React, { useEffect, useRef, useState } from "react";
import shallow from "zustand/shallow";
import _get from "lodash/get";

import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  setModalDialogTitle,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import CreateSpecForm from "../../../../Specs/CreateSpec/CreateSpecForm";
import BWModels from "benjaminwest-models";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { useCreateProcessRequests } from "../../../../../hooks/useProcessRequest";
import { handleRequestError } from "../../../../../../utils/formValidationUtils";
import {
  createSpecToPO,
  specsGridDataComponentId,
  specShipmentsDataComponentId,
} from "../../../../../../actions/purchaseOrderSpecsActions";
import { fetchPurchaseOrder } from "../../../../../../actions/purchaseOrdersActions";
import { setReload } from "../../../../../../actions/dataComponentActions";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { FormWrapper } from "../components";
import { cleanCurrencyValue } from "../../../../../../utils/currencyFormatter";

const specToPOStoreSelector = state => [state.purchaseOrder, state.actions];

const CreateSpec = () => {
  const [isSaveAndCreateAnother, setIsSaveAndCreateAnother] = useState(false);
  const [purchaseOrder, stateActions] = useAddSpecToPOStore(
    specToPOStoreSelector,
    shallow
  );

  const formInfo = useRef({
    formikActions: null,
    initialValues: {
      purchaseOrderId: purchaseOrder.id,
      projectId: purchaseOrder.projectId,
      projectCurrencyId: purchaseOrder.projectCurrency.id,
      vendorId: purchaseOrder.vendorId,
      description: "",
      customNumber: undefined,
      areaId: undefined,
      specCategoryId: undefined,
      totalQuantity: "--",
      overageValue: "--",
    },
  });

  const actions = useActions({
    setModalDialogTitle,
    createSpecToPO,
    closeModalDialog,
    setReload,
    showSnackNotificationAction,
    fetchPurchaseOrder,
  });

  const dataComponent = useDatacomponent(specsGridDataComponentId);

  useEffect(() => {
    actions.setModalDialogTitle([
      "Create a New Spec",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ]);
  }, [actions, purchaseOrder]);

  const createdItem = useV2DatacomponentResources(
    specsGridDataComponentId,
    null,
    REQUEST_TYPES.CREATE
  );

  const handleCreateSuccess = () => {
    const { formikActions } = formInfo.current;

    formikActions.setSubmitting(false);
    formikActions.resetForm();
    formikActions.setValues({
      ...formInfo.current.initialValues,
    });

    actions.setReload(specsGridDataComponentId, true);
    actions.setReload(specShipmentsDataComponentId, true);
    actions.fetchPurchaseOrder(
      _get(purchaseOrder, "id"),
      _get(purchaseOrder, "project.id")
    );

    if (!isSaveAndCreateAnother) {
      actions.closeModalDialog();
    }

    actions.showSnackNotificationAction(
      `Spec ${createdItem.customNumber} ${createdItem.description} has been created`
    );
    setIsSaveAndCreateAnother(false);
  };

  useCreateProcessRequests(dataComponent, {
    onSuccess: handleCreateSuccess,
    onError: error => handleRequestError(error, formInfo.current.formikActions),
  });

  const handleCreate = (spec, formikActions) => {
    formInfo.current.formikActions = formikActions;
    const updatedSpec = { ...spec };

    if (updatedSpec.priceCents) {
      updatedSpec.priceCents = cleanCurrencyValue(spec.priceCents * 100);
    }

    actions.createSpecToPO(updatedSpec, purchaseOrder);
  };

  return (
    <FormWrapper>
      <CreateSpecForm
        initialValues={formInfo.current.initialValues}
        specSchema={BWModels.loadSchema("Spec")}
        closeModalDialog={stateActions.openMainOptions}
        setSaveAndCreateAnother={setIsSaveAndCreateAnother}
        createSpec={handleCreate}
        listeners={[specsGridDataComponentId]}
        projectCurrency={purchaseOrder.projectCurrency}
        spec={{
          purchaseOrderId: purchaseOrder.id,
          projectId: purchaseOrder.projectId,
        }}
      />
    </FormWrapper>
  );
};

export default CreateSpec;
