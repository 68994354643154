import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FlagForm from "./FlagForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../ui/Loader";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { dataComponentId } from "../BrandsTable/FlagDetail/FlagDetailContainer";
import { reloadDataComponent } from "../../../../actions/initializeStoreActions";
import {
  FLAGS_SELECT,
  PROGRAMS_SELECT,
} from "../../../../constants/DataComponents";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions,
  brandId
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload("brandsGrid", true);
      setReload(`${dataComponentId}-${brandId}`, true);
      reloadDataComponent(PROGRAMS_SELECT);
      reloadDataComponent(FLAGS_SELECT);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const CreateFlagContainer = ({
  dataComponent,
  closeModalDialog,
  performCreateRequest,
  reloadDataComponent,
  loading,
  setReload,
  brandId,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions,
      brandId
    );
  }, [
    prevDataComponent,
    dataComponent,
    closeModalDialog,
    setReload,
    reloadDataComponent,
    formikActions,
    brandId,
  ]);

  const handleCreateFlag = useCallback(
    (flag, formikActions) => {
      performCreateRequest(`${dataComponentId}-${brandId}`, flag);
      setFormikAction(formikActions);
    },
    [brandId, performCreateRequest]
  );

  const initialValues = useMemo(() => ({ brandId }), [brandId]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FlagForm
        onSubmit={handleCreateFlag}
        initialValues={initialValues}
        dataComponentId={`${dataComponentId}-${brandId}`}
      />
    </LoaderContext.Provider>
  );
};

CreateFlagContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  brandId: PropTypes.string,
  performCreateRequest: PropTypes.func,
  setReload: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  loading: PropTypes.bool,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = (state, { brandId }) => {
  const dataComponent = getDataComponent(
    `${dataComponentId}-${brandId}`,
    state
  );
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CreateFlagContainer));
