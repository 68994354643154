import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import glCodeFields from "./glCodeFields";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const GLCodeForm = ({ onSubmit, initialValues, dataComponentId }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("GLCode")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={glCodeFields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

GLCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.glCode,
  dataComponentId: PropTypes.string.isRequired,
};

export default GLCodeForm;
