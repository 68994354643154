export const calculateSubTotalMerchandiseCost = (
  bidGroupVendorSpecs,
  isSampleCost
) => {
  return bidGroupVendorSpecs.reduce((accum, bidGroupVendorSpec) => {
    const { quotes = [] } = bidGroupVendorSpec;
    if (quotes.length === 0) {
      return accum;
    }
    const { quoteItems = [] } = quotes[0];
    return (
      accum +
      quoteItems.reduce((accum, quoteItem) => {
        const pricing = isSampleCost
          ? quoteItem.extendedSamplePricing
          : quoteItem.extendedProductionPricing;
        return accum + pricing / 100;
      }, 0)
    );
  }, 0);
};
export const calculateSubTotalMerchandiseCostForCOMS = (
  bidGroupVendorSpecs,
  selectedCOMs,
  isSampleCost = true
) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return bidGroupVendorSpecs.reduce((accum, bidGroupVendorSpec) => {
    const {
      spec: { specDetails = [] },
      quotes = [],
    } = bidGroupVendorSpec;

    if (!quotes || quotes.length === 0) return 0;

    const { quoteItems = [] } = quotes[0];
    return (
      accum +
      specDetails.reduce((accum, specDetail) => {
        const { specDetailComs = [] } = specDetail;
        return (
          accum +
          specDetailComs.reduce((accum, specDetailCom) => {
            const quoteItem = quoteItems.find(
              quoteItem => quoteItem.specId == specDetailCom.specId
            );

            if (!quoteItem) return accum;

            const extendedSampleQuantity =
              quoteItem.sampleQuantity * bidGroupVendorSpec.spec.sampleQuantity;
            const extendedProductionQuantity =
              quoteItem.productionQuantity *
              bidGroupVendorSpec.spec.totalQuantityWithoutOverage;

            const awardedSamplePricing =
              specDetailCom.spec.awardedSamplePricing || 0;
            const awardedProductionPricing =
              specDetailCom.spec.awardedProductionPricing || 0;

            const cost = isSampleCost
              ? awardedSamplePricing * extendedSampleQuantity
              : awardedProductionPricing * extendedProductionQuantity;
            return accum + cost / 100;
          }, 0)
        );
      }, 0)
    );
  }, 0);
};
export const calculateExtendedSubTotalMerchandiseCostForSpecs = bidGroupVendorSpecs => {
  return bidGroupVendorSpecs.reduce((accum, bidGroupVendorSpec) => {
    const { quotes = [] } = bidGroupVendorSpec;
    if (quotes.length === 0) {
      return accum;
    }
    const { quoteItems = [] } = quotes[0];
    return (
      accum +
      quoteItems.reduce((accum, quoteItem) => {
        return (
          accum +
          (quoteItem.productionPricing / 100) *
            bidGroupVendorSpec.spec.totalQuantityWithoutOverage
        );
      }, 0)
    );
  }, 0);
};
export const calculateExtendedSubTotalMerchandiseCostForCOMS = (
  bidGroupVendorSpecs,
  selectedCOMs
) => {
  return bidGroupVendorSpecs.reduce((accum, bidGroupVendorSpec) => {
    const { quotes = [] } = bidGroupVendorSpec;
    if (quotes.length === 0) {
      return accum;
    }
    const { quoteItems = [] } = quotes[0];
    const { specDetails = [] } = bidGroupVendorSpec.spec;
    return (
      accum +
      specDetails.reduce((accum, specDetail) => {
        const { specDetailComs = [] } = specDetail;
        return (
          accum +
          specDetailComs.reduce((accum, specDetailCom) => {
            const quote = quoteItems.find(
              quoteItem => quoteItem.specId === parseInt(specDetailCom.spec.id)
            );
            const selectedCOMPricing = selectedCOMs[specDetailCom.spec.id];
            if (selectedCOMPricing && quote) {
              return (
                accum +
                selectedCOMPricing.productionPricing * quote.productionQuantity
              );
            }
            return accum;
          }, 0)
        );
      }, 0)
    );
  }, 0);
};
