import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BulkDeleteConfirmation from "../../../../ui/BulkDeleteConfirmation";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import {
  performDeleteRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { processDeleteRequestStatus } from "../../../../../utils/dataComponentUtils";

export const dataComponentId = "GLCodesGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload
) => {
  processDeleteRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
  });
};

const BulkDeleteConfirmationContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performDeleteRequest,
  selectedIds,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload
    );
  });

  const handleArchiveList = useCallback(
    () => performDeleteRequest(dataComponentId, selectedIds),
    [performDeleteRequest, selectedIds]
  );

  return (
    <BulkDeleteConfirmation
      count={selectedIds.length}
      onDelete={handleArchiveList}
    />
  );
};

BulkDeleteConfirmationContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReload: PropTypes.func.isRequired,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    selectedIds: dataComponent.requestState[RequestTypes.LIST].selectedIds,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkDeleteConfirmationContainer);
