import React, { Fragment, useState, useCallback } from "react";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import AddNotePopover from "./AddNotePopover";

export const Button = styled(Fab)`
  position: fixed;
  background-color: #57abff;
  right: 24px;
  bottom: 50px;
  z-index: 1;
`;

export const PopoverContainer = styled(props => (
  <Popover {...props} classes={{ paper: "paper add-note-paper" }} />
))`
  .paper {
    top: unset !important;
    left: unset !important;
    position: fixed;
    right: 52px;
    bottom: 78px;
  }
`;

const AddNoteButton = ({ purchaseOrder, loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const handleClick = useCallback(
    event => {
      if (!anchorEl) {
        setTooltipOpen(false);
        return setAnchorEl(event.currentTarget);
      }
      setAnchorEl(null);
    },
    [anchorEl]
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleOpenTooltip = useCallback(() => {
    if (!anchorEl) return setTooltipOpen(true);
  }, [anchorEl]);

  if (loading) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip
        title="Add Note"
        open={isTooltipOpen}
        onOpen={handleOpenTooltip}
        onClose={() => setTooltipOpen(false)}
      >
        <Button color="primary" onClick={handleClick}>
          {anchorEl ? <Close /> : <Assignment />}
        </Button>
      </Tooltip>
      <PopoverContainer
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <AddNotePopover
          purchaseOrder={purchaseOrder}
          onClosePopover={handleClose}
        />
      </PopoverContainer>
    </Fragment>
  );
};
AddNoteButton.propTypes = {
  purchaseOrder: propTypes.purchaseOrder,
  loading: PropTypes.bool,
};

export default AddNoteButton;
