import React, { memo } from "react";
import PropTypes from "prop-types";
import { Template, Plugin, TemplateConnector } from "@devexpress/dx-react-core";

import TablePagination from "../../mui/core/TablePagination";
const pluginDependencies = [{ name: "PagingState" }];
const PagingPanel = props => {
  const { rowsPerPageOptions, display } = props;
  if (!display) return null;
  return (
    <Plugin name="PagingPanel" dependencies={pluginDependencies}>
      <Template name="pagingPanel">
        <TemplateConnector>
          {(
            { currentPage, pageSize, totalCount },
            { setCurrentPage, setPageSize }
          ) => (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              labelRowsPerPage="Show"
              count={totalCount}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangePage={pageNumber => {
                setCurrentPage(pageNumber);
              }}
              onChangeRowsPerPage={rowsPerPage => setPageSize(rowsPerPage)}
            />
          )}
        </TemplateConnector>
      </Template>
    </Plugin>
  );
};

PagingPanel.propTypes = {
  rowsPerPageOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
  display: PropTypes.bool.isRequired,
};

PagingPanel.defaultProps = {
  rowsPerPageOptions: [],
};

export default memo(PagingPanel);
