import React, { useEffect } from "react";
import { connect } from "react-redux";
import _get from "lodash.get";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  cloneDataComponent,
  performFindRequest,
} from "../../../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { getBluechipResourcesByType } from "../../../../../../utils/bluechipUtils";
import Loader, { LoaderContext } from "../../../../../ui/Loader";
import SelectableItems from "./SelectableItems";
import ChildrenItems from "./ChildrenItems";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled(Loader)`
  margin-left: 24px;
`;

const EntityRowContent = ({
  id,
  node,
  entity,
  endpoint,
  loading,
  onExpand,
  onCollapse,
  onUpdate,
  onToggleProp,
  nodeSet,
  dataComponentId,
  parentDataComponentId,
  cloneDataComponent,
  performFindRequest,
}) => {
  useEffect(() => {
    cloneDataComponent(parentDataComponentId, dataComponentId, true, false);
    performFindRequest(dataComponentId, endpoint);
  }, [
    cloneDataComponent,
    dataComponentId,
    endpoint,
    parentDataComponentId,
    performFindRequest,
  ]);

  const { properties = [], relations = [] } = entity || {};

  if (loading) {
    return (
      <Wrapper>
        <LoaderWrapper count={10} loading={true} />
      </Wrapper>
    );
  }

  const mappedProperties = properties.map(property => ({
    ...property,
    checked: Object.keys(node.properties).indexOf(property.columnName) !== -1,
  }));

  return (
    <LoaderContext.Provider value={loading}>
      <Wrapper>
        <SelectableItems
          id={id}
          items={mappedProperties}
          onToggleProp={onToggleProp}
        />
        <ChildrenItems
          id={id}
          items={relations}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onUpdate={onUpdate}
          nodeSet={nodeSet}
          node={node}
          parentDataComponentId={dataComponentId}
        />
      </Wrapper>
    </LoaderContext.Provider>
  );
};

EntityRowContent.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
  entity: PropTypes.object,
  endpoint: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onToggleProp: PropTypes.func.isRequired,
  nodeSet: PropTypes.object,
  dataComponentId: PropTypes.string.isRequired,
  parentDataComponentId: PropTypes.string.isRequired,
  cloneDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  cloneDataComponent,
  performFindRequest,
};

const mapStateToProps = (state, { endpoint, parentDataComponentId }) => {
  const dataComponentId = `${parentDataComponentId}-${endpoint}`;
  const dataComponent = getDataComponent(dataComponentId, state);

  const entity = getBluechipResourcesByType(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    dataComponentId,
    loading:
      _get(dataComponent, `requestState.${REQUEST_TYPES.FIND}.loading`) ||
      !entity,
    entity,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityRowContent);
