import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";

import BidGroupTable from "./BidGroupTable";
import { setItemStatus } from "../../../../../actions/collapsibleItemsActions";
import {
  updateSpecOnBidGroupVendor,
  updateBidGroupVendor,
} from "../../../../../actions/bidGroupActions";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { setReload } from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";

const projectDetailComponentId = "ProjectDetail";
export const mapStateToProps = (state, { bidGroupVendor, projectId }) => {
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDetailComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const project = getBluechipResourceById(
    projectDataComponent,
    state,
    projectId
  );
  const isClosedProject =
    project && ["In Closing", "Closed"].includes(project.status);
  return {
    isClosedProject,
    isCollapsed: _get(
      state.collapsibleItems,
      `bidGroupVendor_${bidGroupVendor.id}.isCollapsed`
    ),
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setItemStatus,
        updateSpecOnBidGroupVendor,
        updateBidGroupVendor,
        setReload,
        openModalDialog,
        closeModalDialog,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BidGroupTable);
