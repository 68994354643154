import React, {
  memo,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import _noop from "lodash/noop";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import _flatten from "lodash/flatten";

import { WithPaginationContext } from "../../../withPagination";

import BWGrid from "./BWGrid";
import { makeMapDispatchToProps, makeMapStateToProps } from "./connect";
import {
  checkBackPageEffect,
  debouncedFilteringChangeHandler,
  getSort,
  getPage,
  retrieveList,
  checkSorting,
} from "./gridAPIFunctions";
import { LoaderContext } from "../Loader";
import { useSelectedRowAPIContext } from "./withSelectedRowsAPI";

export const CreateSetSort = (context, dataComponent, setSort) => (
  sort = [],
  columnOptions
) => {
  const actualSort = getSort(context, dataComponent)();
  const actualColumnName = _get(actualSort, "0.columnName", "");
  const sortFiltered =
    sort.length > 1
      ? sort.filter(({ columnName }) => actualColumnName !== columnName)
      : sort;

  const sortParsed = _flatten(
    sortFiltered.map(sort =>
      columnOptions &&
      columnOptions[sort.columnName] &&
      columnOptions[sort.columnName].parseSort
        ? columnOptions[sort.columnName].parseSort(sort)
        : sort
    )
  );

  context.setSort(sortParsed, dataComponent.dataComponentId);
  setSort(sortParsed);
};

export const createReorder = (onReorder, pageNumber, pageSize) => ({
  newIndex,
  oldIndex,
  nodes,
  ...reorderInfo
}) => {
  const id = nodes[oldIndex].node.getAttribute("data-id");
  onReorder(
    {
      newIndex: pageSize * pageNumber + newIndex,
      oldIndex: pageSize * pageNumber + oldIndex,
      ...reorderInfo,
    },
    { id }
  );
};

const useEffects = ({
  dataComponent,
  rows,
  pageNumber,
  setPrevDefaultSorting,
  defaultSorting,
  setPrevAPIFilters,
  apiFilters,
  setShouldReload,
  selectedFilters,
  reload,
  setSelection,
}) => {
  useEffect(() => {
    setPrevDefaultSorting(defaultSorting);
  }, [defaultSorting, setPrevDefaultSorting]);

  useEffect(() => {
    setPrevAPIFilters(apiFilters);
  }, [apiFilters, setPrevAPIFilters]);

  useEffect(() => {
    setShouldReload(true);
  }, [selectedFilters, setShouldReload]);

  useEffect(() => {
    if (reload) {
      setSelection({});
      setShouldReload(true);
    }
  }, [reload, setSelection, setShouldReload]);

  const { updateSelectedRows } = useSelectedRowAPIContext(
    dataComponent.dataComponentId
  );
  useEffect(() => {
    updateSelectedRows(rows, dataComponent.selectedIds, pageNumber);
  }, [dataComponent.selectedIds, pageNumber, rows, updateSelectedRows]);
};

export const BWGridAPI = ({
  requestState,
  dataComponent,
  setPage,
  setSort,
  setReload,
  setSelection,
  initDataComponent,
  performRetrieveListRequest,
  apiFilters,
  reload,
  isLoading,
  defaultSorting,
  setPageSize,
  additionalSorting,
  ...props
}) => {
  const [shouldReload, setShouldReload] = useState(false);
  const [prevDefaultSorting, setPrevDefaultSorting] = useState(null);
  const [prevAPIFilters, setPrevAPIFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const context = useContext(WithPaginationContext);

  const rows = useMemo(() => props.rows, [props.rows]);

  useEffect(() => {
    initDataComponent();
  }, [initDataComponent]);

  useEffects({
    dataComponent,
    rows,
    pageNumber: props.pageNumber,
    setPrevDefaultSorting,
    defaultSorting,
    setPrevAPIFilters,
    apiFilters,
    setShouldReload,
    selectedFilters,
    reload,
    setSelection,
  });

  const pageSize = context.getPageSize(dataComponent.dataComponentId);

  const handleRetrieveList = useCallback(
    (apiFilters, selectedFilters) => {
      retrieveList(apiFilters, selectedFilters, dataComponent, {
        getSort: getSort(context, dataComponent, additionalSorting),
        getPage: getPage(context, dataComponent),
        setReload,
        setShouldReload,
        pageSize,
        performRetrieveListRequest,
      });
    },
    [
      dataComponent,
      context,
      setReload,
      pageSize,
      performRetrieveListRequest,
      additionalSorting,
    ]
  );

  useEffect(() => {
    if (shouldReload) {
      handleRetrieveList(apiFilters, selectedFilters);
    }
  }, [apiFilters, selectedFilters, shouldReload, handleRetrieveList]);

  const handleSetSort = useCallback(
    CreateSetSort(context, dataComponent, setSort),
    [context, dataComponent, setSort]
  );

  /**
   * omitShouldReload is set true in handleFiltersChange to avoid fetching twice
   */
  const handleSetPage = useCallback(
    (pageNumber, omitShouldReload) => {
      context.setPage(pageNumber, dataComponent.dataComponentId);
      setPage(pageNumber);
      if (!omitShouldReload) setShouldReload(true);
    },
    [context, dataComponent.dataComponentId, setPage]
  );

  const handleSetPageSize = useCallback(
    pageSize => {
      context.setPageSize(pageSize, dataComponent.dataComponentId);
      setPageSize(pageSize);
      handleSetPage(0);
      setShouldReload(true);
    },
    [context, dataComponent.dataComponentId, setPageSize, handleSetPage]
  );

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  const handleFiltersChange = useCallback(
    (changes, avoidSetPage) =>
      debouncedFilteringChangeHandler(changes, {
        setSelectedFilters,
        onSetPage: avoidSetPage ? _noop : handleSetPage,
        setSelection,
      }),
    [handleSetPage, setSelection]
  );

  const handleReorder = useCallback(
    createReorder(props.onReorder, props.pageNumber, pageSize),
    [props.onReorder, props.pageNumber, pageSize]
  );

  useEffect(
    checkBackPageEffect(isLoading, rows, props.pageNumber, handleSetPage),
    [isLoading, rows, props.pageNumber, handleSetPage]
  );

  useEffect(() => {
    if (apiFilters !== prevAPIFilters && prevAPIFilters) {
      handleSetPage(0);
      setShouldReload(true);
      setSelection({});
    }
  }, [apiFilters, prevAPIFilters, handleSetPage, setSelection]);

  useEffect(
    checkSorting(
      context,
      dataComponent,
      prevDefaultSorting,
      defaultSorting,
      handleSetSort
    ),
    [defaultSorting, prevDefaultSorting, handleSetSort, context, dataComponent]
  );

  const loadingValue = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  return (
    <LoaderContext.Provider value={loadingValue}>
      {dataComponent.model && (
        <BWGrid
          {...props}
          setSort={handleSetSort}
          pageSize={pageSize}
          setSelection={setSelection}
          setPage={handleSetPage}
          setPageSize={handleSetPageSize}
          isLoading={isLoading}
          gridConfig={dataComponent}
          onFiltersChange={handleFiltersChange}
          onReorder={handleReorder}
          rows={rows}
        />
      )}
    </LoaderContext.Provider>
  );
};

BWGridAPI.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  showSelectionColumn: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  hideFooter: PropTypes.bool,
  apiFilters: PropTypes.object,
  requestState: PropTypes.object,
  reload: PropTypes.bool,
  isLoading: PropTypes.bool,
  ignoreOptimizations: PropTypes.bool,
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    })
  ),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  pageNumber: PropTypes.number,
  setReload: PropTypes.func.isRequired,
  setSelection: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
  apiRoute: PropTypes.string,
  isCollapsed: PropTypes.bool,
  additionalSorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    })
  ),
};

BWGridAPI.defaultProps = {
  onReorder: _noop,
  additionalSorting: [],
};

function areEqual(prevProps, { children, ignoreOptimizations, ...nextProps }) {
  if (ignoreOptimizations) {
    nextProps.children = children;
  }
  return Object.keys(nextProps).every(propName =>
    _isEqual(nextProps[propName], prevProps[propName])
  );
}

export default connect(
  makeMapStateToProps,
  makeMapDispatchToProps
)(memo(BWGridAPI, areEqual));
