import React from "react";
import moment from "moment";
const BWModels = require("benjaminwest-models");

import propTypes from "../../../../constants/propTypes";
import { ColumnItem } from "../Accounting/Fees/FeeSummary/FeeSummary";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";

export const getMonthsRemaining = endDate => {
  if (!endDate) {
    return "--";
  }
  const currentDate = new Date();
  const parseEndDate = moment(endDate);
  if (currentDate > parseEndDate) {
    return "0";
  }
  return parseEndDate
    .startOf("day")
    .diff(moment(currentDate).startOf("day"), "months");
};
export const getMerchOverageSpend = (
  currencyFormatter,
  contractBudget,
  projectForecast
) => {
  if (!projectForecast) {
    return "--";
  }

  const overageSpend = projectForecast.total - contractBudget;

  if (overageSpend < 0) {
    return "--";
  }

  return currencyFormatter.format(overageSpend);
};
export const getFeeTitle = () => {
  return "Contracted Fee";
};

// eslint-disable-next-line complexity
export const getFeeValue = (
  currencyFormatter,
  {
    contractBudget,
    feeBasis,
    feeAmount,
    feePercent: rawFeePercent,
    baseAmountLimit,
    numberOfPOs,
    feesPerPurchaseOrder,
    clientPricing,
  }
) => {
  const Project = BWModels.loadSchema("Project");
  const feePercent = rawFeePercent || 0;
  const feePercentValue = feePercent / 100;
  switch (feeBasis) {
    case "Percentage":
      return `${currencyFormatter.format(contractBudget * feePercentValue)}`;
    case "Limit":
      return `${currencyFormatter.format(baseAmountLimit)}`;
    case "Fixed":
      return `${currencyFormatter.format(feeAmount * 100)}`;
    case "PO":
      return `${currencyFormatter.format(numberOfPOs * feesPerPurchaseOrder)}`;
    case "Tiered Pricing":
      return `${currencyFormatter.format(
        Project.tierPricingHelpers.getTierFeeAmount(
          { contractBudget },
          clientPricing
        )
      )}`;
    default:
      return currencyFormatter.format(0);
  }
};

// eslint-disable-next-line complexity
export const getFee = (currencyFormatter, project) => {
  const Project = BWModels.loadSchema("Project");
  const feeValue = getFeeValue(currencyFormatter, project);
  const feePercent = project.feePercent || 0;
  const projectTier = Project.tierPricingHelpers.getProjectTier(
    project,
    project.clientPricing
  );
  switch (project.feeBasis) {
    case "Percentage":
      return `${feeValue} (%) ${feePercent}%`;
    case "Limit":
      return `${feeValue} (Limit) ${Math.round(project.overLimitFee * 10000) /
        100}%`;
    case "Fixed":
      return `${feeValue} (Fixed)`;
    case "PO":
      return `${feeValue} (PO)`;
    case "Tiered Pricing":
      return `${feeValue} (Tier ${projectTier?.sequenceIndex})`;
    default:
      return feeValue;
  }
};
const ContractStatusSection = props => {
  const { project, projectForecast } = props;
  const currencyFormatter = useCurrencyFormatter();

  const values = [
    [
      {
        title: "Contract Amount",
        value: currencyFormatter.format(project.contractBudget),
      },
    ],
    [
      {
        title: getFeeTitle(project.feeBasis, project.feeAmount),
        value: getFee(currencyFormatter, project),
      },
    ],
    [
      {
        title: "MerchOvg Spend",
        value: getMerchOverageSpend(
          currencyFormatter,
          project.contractBudget,
          projectForecast
        ),
      },
    ],
    [
      {
        title: "Months Remaining",
        value: getMonthsRemaining(project.endDate),
      },
    ],
  ];

  return (
    <div>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} id={key} />
      ))}
    </div>
  );
};

ContractStatusSection.defaultProps = {
  project: {},
  projectForecast: {
    contingencies: [],
  },
};

ContractStatusSection.propTypes = {
  project: propTypes.project.isRequired,
  projectForecast: propTypes.projectForecast,
};

export default ContractStatusSection;
