import React, { Fragment, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ActionButtons from "../../../../forms/ActionButtons";
import propTypes from "../../../../../constants/propTypes";
import Loader from "../../../../ui/Loader";
import { FormControlLabel, Checkbox } from "../../../../mui/core";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { useRetrieveSpecs } from "./useRetrieveSpecs";

const Wrapper = styled.div`
  padding: 24px;
`;

const Message = styled.p``;

const checkboxLabel = `* Please confirm by
checking the box that you understand bringing those specs will be
manual process of removing them from their POs and adding them
back to this one.`;

const getSpecsList = (specs, style, suffix = "") =>
  specs.map(({ id, customNumber }) => (
    <li key={id} style={style}>
      {customNumber}
      {suffix}
    </li>
  ));

const CancelChangeOrder = ({ purchaseOrder, onSubmit }) => {
  const [confirmed, setConfirmed] = useState(false);

  const {
    currentRevision: { revisionActivities },
  } = purchaseOrder;

  const { isLoading, specs, permanentDeletedSpecs } = useRetrieveSpecs({
    purchaseOrder,
    revisionActivities,
  });

  const handleChange = ({ target: { checked } }) => {
    setConfirmed(checked);
  };

  const currencyFormatter = useCurrencyFormatter(
    purchaseOrder.projectCurrency.currency
  );

  return (
    <Fragment>
      <Wrapper>
        <Loader loading={isLoading} count={10}>
          <Message>
            The purchase order will have the following specs after cancelling
            the change order:
          </Message>
          <table style={{ width: "100%", paddingTop: "14px" }}>
            <thead>
              <tr>
                <td style={{ paddingRight: "24px", width: "65%" }}>
                  <b>Spec</b>
                </td>
                <td>
                  <b>Quantity</b>
                </td>
                <td>
                  <b>Price</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {specs.map(
                ({
                  id,
                  purchaseOrderId,
                  customNumber,
                  totalQuantity,
                  priceCents,
                }) => (
                  <tr
                    key={id}
                    style={{ fontStyle: purchaseOrderId ? "unset" : "italic" }}
                  >
                    <td>{customNumber}</td>
                    <td>{totalQuantity}</td>
                    <td>{currencyFormatter.format(priceCents / 100.0)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {!!permanentDeletedSpecs.length && (
            <Fragment>
              <Message>
                <b>
                  The following specs will not be reassigned back to this PO as
                  they are already assigned to other POs as of now.
                </b>
              </Message>
              <ul>
                {getSpecsList(
                  permanentDeletedSpecs,
                  { color: "red", fontStyle: "italic" },
                  " *"
                )}
              </ul>
              <FormControlLabel
                control={
                  <Checkbox checked={confirmed} onChange={handleChange} />
                }
                label={checkboxLabel}
              />
            </Fragment>
          )}
        </Loader>
      </Wrapper>
      <ActionButtons
        onSend={onSubmit}
        sendButtonText="CONFIRM"
        additionalProps={{
          send: {
            isLoading,
            disabled: permanentDeletedSpecs.length && !confirmed,
          },
          cancel: { loading: isLoading },
        }}
        isModal
      />
    </Fragment>
  );
};
CancelChangeOrder.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CancelChangeOrder;
