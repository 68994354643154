import { connect } from "react-redux";

import {
  updateFunding,
  dataComponentIdUpdate,
  performAfterCreate,
  createFundingPayment,
  performAfterCreatePayment,
} from "../../../../../../actions/fundingActions";
import { showSnackNotificationAction } from "../../../../../../actions/layoutActions";
import { getBluechipResourcesByType } from "../../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import FundingPaidConfirmation from "./FundingPaidConfirmation";

export const dataComponentIdCreate = "FundingCreate";
const dataComponentFundingPaymentId = "FundingPayment";

export const mapStateToProps = (state, { dataComponentId, fundingId }) => {
  const dataComponent = getDataComponent(dataComponentIdUpdate, state);
  const fundings = getBluechipResourcesByType(dataComponentId, state) || [];

  return {
    dataComponent,
    funding: fundings.find(({ id }) => id === fundingId),
    fundingPaymentComponent: getDataComponent(
      dataComponentFundingPaymentId,
      state,
      REQUEST_TYPES.CREATE
    ),
  };
};

export const mapDispatchToProps = {
  updateFunding,
  performAfterCreate,
  performAfterCreatePayment,
  showSnackNotificationAction,
  createFundingPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundingPaidConfirmation);
