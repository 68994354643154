import React from "react";
import BWModels from "benjaminwest-models";
import AddCircle from "@material-ui/icons/AddCircle";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import SpecCategory from "../../../../models/SpecCategory";
import Paper from "../../../mui/core/Paper";
import { getKeyColumn } from "./KeyColumn";
import TagText from "../../../ui/Tags/TagText";

export const StyledChip = styled(({ invert, ...props }) => <Chip {...props} />)`
  background: ${({ invert }) => (invert ? "#F1F1F1" : "#57ABFF")};
  color: ${({ invert }) => (invert ? "#57ABFF" : "#FFFFFF")};
`;

const TypeColumn = ({ type }) =>
  type && <StyledChip invert={type === "FF&E"} label={type} />;

const columns = ["name", "type", "key", "isActive"];
export const columnOptions = () => {
  const specCategorySchema = BWModels.loadSchema("SpecCategory");
  const types = specCategorySchema.__utils.types;
  return {
    name: { bold: true, filter: true },
    type: {
      fill: true,
      render: TypeColumn,

      filter: "select",
      filterOptions: {
        options: types,
      },
    },
    key: {
      title: "Template Type",
      render: getKeyColumn,
      filter: "select",
      filterOptions: {
        options: [
          { id: "COM_ITEM", name: "COM" },
          { id: "FREIGHT_ITEM", name: "Freight" },
          { id: "INSTALL_ITEM", name: "Install" },
          { id: "OTHER_COST", name: "Other Costs" },
          { id: "VENDOR_FREIGHT_ITEM", name: "Vendor Freight" },
          { id: "WAREHOUSING_ITEM", name: "Warehousing" },
        ],
      },
    },
    isActive: {
      title: "Status",
      filter: "select",
      filterOptions: {
        initialOption: true,
        options: [
          { id: true, name: "Active" },
          { id: false, name: "Inactive" },
        ],
      },
      render: row => {
        const isActive = _get(row, "isActive", false);
        const text = isActive ? "Active" : "Inactive";
        return (
          row.id && (
            <TagText intent={isActive ? "success" : undefined}>{text}</TagText>
          )
        );
      },
    },
  };
};

const getRowMenu = (onOpenEditModal, onOpenDeleteModal) => {
  return [
    {
      icon: <Edit />,
      text: "Edit",
      onClick: onOpenEditModal,
    },
    { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
  ];
};

const TemplatesPage = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={SpecCategory}
        includes={["specCategoryDetails"]}
        apiRoute="spec-categories"
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Spec Details Templates`}
          actions={[
            {
              text: "CREATE TEMPLATE",
              icon: <AddCircle />,
              handler: onOpenCreateModal,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions()}
          rowMenu={getRowMenu(onOpenEditModal, onOpenDeleteModal)}
        />
      </BWGridAPI>
    </Paper>
  );
};
TemplatesPage.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};
export default TemplatesPage;
