import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton,
  StyledCircularProgress,
} from "../../../../forms/ActionButtons/components";
import { DragDropDashboard } from "../../../../inputs/DragDrop/DragDropDashboard";

export const Button = styled(ActionButton)`
  color: #57abff;
  padding-left: 24px;
  padding-right: 24px;
  cursor: ${({ loading }) => loading && "not-allowed"};
  ${StyledCircularProgress} {
    color: #57abff;
    left: 0;
    top: calc(50% - 14px);
  }
`;

const UploadDashboard = ({ onSaveFile, disabled }) => {
  const handleChange = useCallback(
    evt => {
      const [fileToUpload] = evt.target.files;
      if (fileToUpload) {
        onSaveFile(fileToUpload);
      }
    },
    [onSaveFile]
  );

  if (disabled) {
    return null;
  }

  return <DragDropDashboard onChange={handleChange} height={335} width={720} />;
};

UploadDashboard.propTypes = {
  onSaveFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default UploadDashboard;
