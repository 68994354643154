import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Header from "./Header";

import propTypes from "../../../../constants/propTypes";
import * as RequestTypes from "../../../../constants/RequestTypes";
import { LoaderContext } from "../../../ui/Loader";
import {
  initHeaderDatacomponent,
  initNotesModal,
  notesHeaderDatacomponentId,
} from "../../../../actions/notesActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBreadcrumbs } from "./breadcrumsFunctions";

export const HeaderContainer = ({
  breadcrumbs,
  initHeaderDatacomponent,
  context,
  loading,
  initNotesModal,
}) => {
  useEffect(() => {
    initHeaderDatacomponent(context);
  }, [initHeaderDatacomponent, context]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Header breadcrumbs={breadcrumbs} initNotesModal={initNotesModal} />
    </LoaderContext.Provider>
  );
};

HeaderContainer.propTypes = {
  context: propTypes.notesContext,
  breadcrumbs: propTypes.breadcrumbs,
  initHeaderDatacomponent: PropTypes.func.isRequired,
  initNotesModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { context } = state.notes;

  const datacomponent = getDataComponentFlattenedRequestState(
    notesHeaderDatacomponentId,
    state,
    RequestTypes.FIND
  );

  return {
    context,
    breadcrumbs: getBreadcrumbs(state.notes.context, state),
    loading: datacomponent.loading,
  };
};

const mapDispatchToProps = {
  initHeaderDatacomponent,
  initNotesModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
