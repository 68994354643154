import UserRolePermission from "../UserRolePermission";

describe("UserRolePermission Model", () => {
  test("belongsTo relations", () => {
    expect(
      UserRolePermission.belongsTo.map(relation => relation.name)
    ).toMatchSnapshot();
  });

  test("pluralName", () => {
    expect(UserRolePermission.pluralName()).toMatchSnapshot();
  });
});
