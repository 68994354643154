import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import {
  uploadUserProfile,
  initUserComponent,
} from "../../../../actions/usersActions";

import {
  validateAuthentication,
  openAccountSettingsModal,
} from "../../../../actions/authActions";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import AccountPictureSettings, {
  dataComponentId,
} from "./AccountPictureSettings";

export const mapStateToProps = ({
  auth: { phoneNumber, userId, profilePicture },
  ...state
}) => {
  const dataComponent = getDataComponent(
    dataComponentId,
    state,
    REQUEST_TYPES.UPDATE
  );

  return {
    userData: {
      phoneNumber,
      profilePicture,
      id: userId,
    },
    dataComponent,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        uploadUserProfile,
        initUserComponent,
        closeModalDialog,
        showSnackNotificationAction,
        openAccountSettingsModal,
        validateAuthentication,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPictureSettings);
