import React, {
  createContext,
  memo,
  useContext,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { parse, stringify } from "query-string";
import _snakeCase from "lodash/snakeCase";
import _get from "lodash/get";
import _noop from "lodash/noop";

export const WithPaginationContext = createContext({
  isDefault: true,
  setSort: _noop,
  getSort: _noop,
  setPage: _noop,
  getPage: () => 0,
  setPageSize: _noop,
  getPageSize: () => 10,
});

export const useWithPaginationContext = () => useContext(WithPaginationContext);

export const parseNulls = nulls => (nulls ? `-${nulls}` : "");

const handleSetSort = setQueryParams => (
  sortOptions,
  dataComponentId = "t"
) => {
  const labelDirection = `${_snakeCase(dataComponentId)}_sort`;

  const sort = sortOptions.map(
    sorting =>
      `${sorting.columnName}:${sorting.direction || "desc"}${parseNulls(
        sorting.nulls
      )}`
  );

  setQueryParams(labelDirection, {
    [labelDirection]: sort,
  });
};

const handleGetSort = location => (dataComponentId = "t") => {
  const search = parse(location.search) || {};
  const labelDirection = `${_snakeCase(dataComponentId)}_sort`;
  const rawSort = _get(search, labelDirection);
  if (!rawSort) return;

  const rawSortArray = Array.isArray(rawSort) ? rawSort : [rawSort];

  return rawSortArray.map(sort => {
    const [columnName, ordering] = sort.split(":");
    const [direction, nulls] = ordering.split("-");

    return {
      direction,
      columnName,
      nulls,
    };
  });
};
/**
 * A public higher-order component to include pagination feature
 */
function withPagination(WrappedComponent) {
  const Pagination = props => {
    const { location, history } = props;
    const searchRef = useRef(location.search);

    useEffect(() => {
      searchRef.current = history.location.search;
    }, [history.location.search]);

    const setQueryParams = useCallback(
      (label, value) => {
        const currentSearch = parse(searchRef.current);
        delete currentSearch[label];

        searchRef.current = stringify(Object.assign({}, currentSearch, value));
        history.replace({ search: searchRef.current });
      },
      [history]
    );

    const setPage = useCallback(
      (newPage, dataComponentId = "t") => {
        const label = `${_snakeCase(dataComponentId)}_page`;

        const newParams = newPage === 0 ? {} : { [label]: newPage };
        setQueryParams(label, newParams);
      },
      [setQueryParams]
    );

    const setSort = useCallback(handleSetSort(setQueryParams), [
      setQueryParams,
    ]);

    const getSort = useCallback(handleGetSort(location), [location]);

    const getPage = useCallback(
      (dataComponentId = "t") => {
        const search = parse(location.search) || {};
        const label = `${_snakeCase(dataComponentId)}_page`;
        return Number(search[label]) || 0;
      },
      [location]
    );

    const setPageSize = useCallback((pageSize, dataComponentId = "t") => {
      const label = `${_snakeCase(dataComponentId)}_pageSize`;
      sessionStorage.setItem(label, pageSize);
    }, []);

    const getPageSize = useCallback((dataComponentId = "t") => {
      const label = `${_snakeCase(dataComponentId)}_pageSize`;
      const pageSize = sessionStorage.getItem(label);
      return Number(pageSize) || 10;
    }, []);

    const value = useMemo(() => {
      return {
        setSort,
        getSort,
        setPage,
        getPage,
        setPageSize,
        getPageSize,
      };
    }, [setSort, getSort, setPage, getPage, setPageSize, getPageSize]);

    return (
      <WithPaginationContext.Provider value={value}>
        <WrappedComponent {...props} />
      </WithPaginationContext.Provider>
    );
  };

  Pagination.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  return memo(Pagination);
}
export default withPagination;
