import React, { useMemo } from "react";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";
import pluralize from "pluralize";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import PropTypes from "prop-types";
import { Paper } from "../../../mui/core";
import Brand from "../../../../models/Brand";
import FlagDetail from "./FlagDetail";
import propTypes from "../../../../constants/propTypes";

const columns = ["name", "flagsTotal"];
const columnOptions = {
  name: { bold: true },
  flagsTotal: {
    title: "Flags",
    fill: true,
  },
};
const buildRowMenu = ({ onOpenEditModal, onOpenDeleteModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
  { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Brand",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

export function genDetailRowComponent() {
  const FlagDetails = ({ row }) => <FlagDetail brandId={row.id} />;
  FlagDetails.propTypes = {
    row: PropTypes.shape({}),
  };
  return FlagDetails;
}

const BrandsTable = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  const tableComponents = useMemo(
    () => ({
      DetailRowComponent: genDetailRowComponent(),
    }),
    []
  );

  const rowMenu = useMemo(
    () => buildRowMenu({ onOpenEditModal, onOpenDeleteModal }),
    [onOpenEditModal, onOpenDeleteModal]
  );
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Brand}
        apiRoute="brands"
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
        tableComponents={tableComponents}
      >
        <GridHeader
          headerText={pluralize("Brand", dataComponent.totalRows, true)}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu}
        />
      </BWGridAPI>
    </Paper>
  );
};

BrandsTable.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default BrandsTable;
