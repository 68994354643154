import _get from "lodash/get";
import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import TagScope from "../../ui/Tags/TagScope";
import { PageHeader, TitleArea } from "../../layout/PageHeader";
import { Currency, Dates, Scopes } from "../ProjectDetail/components";
import { LocationAndTeam } from "../ProjectDetail/ProjectDetailPage";
import Loader from "../../ui/Loader";
import { PRIMARY_DATE_FORMAT } from "../../../constants/formats";

const ProjectHeader = ({ project, navigationTabs }) => {
  const startDate = project.startDate
    ? moment(new Date(project.startDate)).format(PRIMARY_DATE_FORMAT)
    : "--";
  const endDate = new Date(project.endDate);
  const property = _get(project, "property", {});
  const currency = _get(project, "currency", {
    symbol: "",
    isoCode: "",
  });
  const client = _get(project, "property.entity.client");
  return (
    <Fragment>
      <PageHeader navigationTabs={navigationTabs}>
        <TitleArea
          id={
            <Loader width="165px" inverted>
              {project.number}
            </Loader>
          }
          title={
            <Loader width="200px" inverted>
              {project.name}
            </Loader>
          }
          dates={
            <Dates>
              <Loader width="80px" inverted>{`${startDate}`}</Loader>
              <ArrowForwardIcon
                style={{
                  height: "16px",
                  color: "#FFFFFF",
                }}
              />
              <Loader width="80px" inverted>{`${moment(endDate).format(
                PRIMARY_DATE_FORMAT
              )}`}</Loader>
            </Dates>
          }
        >
          <Scopes>
            <TagScope
              workScopes={project.workScopes}
              projectSubWorkScopes={project.projectSubWorkScopes}
              isPublicSubScopeNotes={project.isPublicSubScopeNotes}
            />
          </Scopes>
          <Currency>
            {currency && `${currency.symbol} ${currency.isoCode}`}
          </Currency>
        </TitleArea>
        <LocationAndTeam
          property={property}
          project={project}
          client={client}
        />
      </PageHeader>
    </Fragment>
  );
};

ProjectHeader.propTypes = {
  project: PropTypes.object,
  navigationTabs: PropTypes.array,
};

ProjectHeader.defaultProps = {
  project: {},
  navigationTabs: [],
};

export default ProjectHeader;
