import React from "react";
import styled from "styled-components";
import RegularText from "../../../ui/Typography/RegularText";
import WarningIcon from "@material-ui/icons/ReportProblem";
import PropTypes from "prop-types";

export const ChangesLabel = styled.div`
  display: flex;
  align-items: center;
  background: #ff9100;
  border-radius: 2px;
  padding: 5px 12px 5px 8px;
  width: fit-content;
`;

const Icon = styled(WarningIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
`;

export const Text = styled(RegularText)`
  margin-left: 8px;
  line-height: 16px;
  font-weight: 600;
  font-size: 12px;
`;

export const WarningMessage = ({ message }) => {
  return (
    <ChangesLabel>
      <Icon />
      <Text>{message}</Text>
    </ChangesLabel>
  );
};

WarningMessage.propTypes = {
  message: PropTypes.string,
};
