import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../components/forms/FormGrid";
import contactFields from "./contactFields";
import { FormikForm, ActionButtons } from "../../../components/forms";
import propTypes from "../../../constants/propTypes";
import { isSuperAdmin } from "../../../utils/roleUtils";

export const getContactSchema = (userRole, isUpdate) => {
  const schema = BWModels.loadSchema("Contact");
  if (isSuperAdmin(userRole) && !isUpdate) {
    return schema.keys({
      scopeId: BWModels.Joi.selectFk().required(),
    });
  }
  return schema;
};

export default class ContactForm extends Component {
  static propTypes = {
    contact: PropTypes.object,
    onSubmit: PropTypes.func,
    isEditing: PropTypes.bool,
    userRole: propTypes.userRole,
  };

  render() {
    const { onSubmit, userRole, isEditing } = this.props;

    return (
      <FormikForm
        initialValues={{ ...this.props.contact }}
        onSubmit={onSubmit}
        validationSchema={getContactSchema(userRole, isEditing)}
        ignoreValidationFieldNames={["name", "status"]}
        {...this.formPatchProps}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => {
          return (
            <Fragment>
              <FormGrid
                fields={contactFields(userRole, isEditing)}
                values={values}
                {...formikProps}
              />
              <ActionButtons isModal={true} onSend={handleSubmit} />
            </Fragment>
          );
        }}
      </FormikForm>
    );
  }
}
