import React from "react";
import { Status } from "./components";
import { POPropTypes } from "./gridProps";

const ScheduleColumn = purchaseOrder => {
  if (purchaseOrder.status === "Canceled") return "";
  const needsFollowUp = purchaseOrder.needsFollowUp;
  const text = needsFollowUp ? "Needs Follow Up" : "On Schedule";
  const intent = needsFollowUp ? "danger" : "none";
  return <Status colors={{ intent }}>{text}</Status>;
};

ScheduleColumn.propTypes = POPropTypes;

export default ScheduleColumn;
