import { inputs } from "../../../inputs/inputConfigs";
import { glCodesDataComponentId } from "../../../../actions/specDetailActions";

export default clientId => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.glCodes,
            dataComponentId: glCodesDataComponentId,
            menuPlacement: "top",
            APIOptions: {
              ...inputs.glCodes.APIOptions,
              rootFilters: {
                $where: { clientId },
              },
            },
          },
        },
      ],
    },
  ],
});
