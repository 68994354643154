import { useHasRequestActive } from "../../hocs/withRequestListener";
import React from "react";
import { ActionButton, StyledCircularProgress, Child } from "./components";
import styled from "styled-components";

const StyledActionButton = styled(ActionButton)`
  color: #57abff;
`;

const CircularProgress = styled(StyledCircularProgress)`
  color: #57abff;
`;

const SecondaryActionButton = ({ children, isLoading, ...props }) => {
  const loading = useHasRequestActive("secondary", isLoading);

  return (
    <StyledActionButton {...props} loading={loading}>
      <Child loading={loading}>{children}</Child>
      {loading && <CircularProgress size={20} />}
    </StyledActionButton>
  );
};

export default SecondaryActionButton;
