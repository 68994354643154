import React from "react";
import PropTypes from "prop-types";
import propTypes from "../../../../../../constants/propTypes";
import styled from "styled-components";

import Loader from "../../../../../ui/Loader";
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter";

export const SummaryOuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;
export const SummaryInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;
export const SummaryLabelWrapper = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;
export const SummaryValueWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;

const Summary = ({ currency, amountTotal }) => {
  const currencyFormatter = useCurrencyFormatter(currency);
  return (
    <SummaryOuterWrapper>
      <Loader height="28px" width="600px">
        <SummaryInnerWrapper>
          <SummaryLabelWrapper>Currency</SummaryLabelWrapper>
          <SummaryValueWrapper>{currency.name}</SummaryValueWrapper>
        </SummaryInnerWrapper>
        <SummaryInnerWrapper>
          <SummaryLabelWrapper>Total</SummaryLabelWrapper>
          <SummaryValueWrapper>
            {currencyFormatter.format(amountTotal)}
          </SummaryValueWrapper>
        </SummaryInnerWrapper>
      </Loader>
    </SummaryOuterWrapper>
  );
};

Summary.propTypes = {
  currency: propTypes.currency,
  amountTotal: PropTypes.number,
};

export default Summary;
