import AuthService from "../services/AuthService";
import { showSnackNotificationAction } from "./layoutActions";

const authService = new AuthService();

export const resendAccessEmail = (contact, portal, contactType) => {
  return async dispatch => {
    try {
      await authService.post("/resend-access-email", { contact, portal });
      dispatch(
        showSnackNotificationAction(
          `${contactType || portal} Contact Access Email Sent.`
        )
      );
    } catch (error) {
      dispatch(showSnackNotificationAction("Email Failed."));
    }
  };
};
