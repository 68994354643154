import AuthService from "../../services/AuthService";
import * as REQUEST_TYPES from "../../constants/RequestTypes";
import { blueChipActions } from "../../store/blueChipConfig";
import { sortToGetParam, pagingToGetParams, filtersToGetParams } from "./utils";

export async function fetchList(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes } = {
    ...dataComponent,
    ...options,
  };

  const requestState = dataComponent.requestState[REQUEST_TYPES.LIST];

  const {
    pageNumber,
    pageSize,
    sort,
    rootFilters,
    fields,
    params: { modifiers, ...params } = {},
    count,
  } = {
    ...requestState,
    ...options,
  };

  // Fetch the primary records for the DataComponent's model
  const jsonApiResponse = await service.get(apiRoot + apiRoute, {
    page: pagingToGetParams(pageNumber, pageSize),
    sort: sortToGetParam(sort),
    filter: filtersToGetParams(rootFilters),
    includes: includes ? JSON.stringify(includes) : [],
    fields: fields ? JSON.stringify(fields) : undefined,
    count,
    modifiers: modifiers ? JSON.stringify(modifiers) : [],
    ...params,
  });

  const {
    meta: { total_pages, total_results, ...meta },
  } = jsonApiResponse;

  const rowIndex = jsonApiResponse.data.map(row => row.id);
  blueChipActions.updateResources(jsonApiResponse);

  return {
    totalPages: total_pages,
    totalRows: total_results,
    ...meta,
    rowIndex,
  };
}

export async function fetchItem(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes, rootFilters } = {
    ...dataComponent,
    ...options,
  };
  const { id, params: { modifiers, ...params } = {} } = options;

  const jsonApiResponse = await service.get(`${apiRoot}${apiRoute}/${id}`, {
    includes: JSON.stringify(includes || []),
    filter: filtersToGetParams(rootFilters),
    modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
    ...params,
  });
  blueChipActions.updateResources(jsonApiResponse);

  return {
    rowIndex: [id],
    links: jsonApiResponse.data.links,
  };
}

async function bulkUpsert(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes, params } = {
    ...dataComponent,
    ...options,
  };
  const { items } = options;
  const jsonApiResponse = await service.post(
    `${apiRoot}${apiRoute}/bulk`,
    items,
    {
      includes: includes ? JSON.stringify(includes) : [],
      ...params,
    }
  );
  blueChipActions.updateResources(jsonApiResponse);
  return {
    rowIndex: jsonApiResponse.data.map(item => item.id),
  };
}

async function sendPost(dataComponent, options, url) {
  const service = new AuthService(false);
  const { includes } = dataComponent;
  const { body, params } = options;

  const jsonApiResponse = await service.post(url, body, {
    includes: includes ? JSON.stringify(includes) : [],
    ...params,
  });
  blueChipActions.updateResources(jsonApiResponse);

  return {
    rowIndex: [jsonApiResponse.data.id],
  };
}

export async function createItem(dataComponent, options) {
  if (options.isBulk) return bulkUpsert(dataComponent, options);
  const { apiRoute } = dataComponent;
  const apiRoot = "/";
  return await sendPost(dataComponent, options, `${apiRoot}${apiRoute}`);
}

export async function patchItem(dataComponent, options) {
  if (options.isBulk) return bulkUpsert(dataComponent, options);

  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes, params: { modifiers, ...params } = {} } = {
    ...dataComponent,
    ...options,
  };
  const { id, body } = options;

  const jsonApiResponse = await service.patch(
    `${apiRoot}${apiRoute}/${id}`,
    body,
    {
      includes: includes ? JSON.stringify(includes) : [],
      modifiers: modifiers ? JSON.stringify(modifiers) : undefined,
      ...params,
    }
  );
  blueChipActions.updateResources(jsonApiResponse);

  return {
    rowIndex: [jsonApiResponse.data.id],
  };
}

export async function deleteItem(dataComponent, options) {
  const service = new AuthService(false);
  const apiRoot = "/";

  const { apiRoute, includes } = dataComponent;
  const { ids, params } = options;

  const parsedIncludes = includes ? JSON.stringify(includes) : [];
  if (Array.isArray(ids)) {
    const jsonApiResponse = await service.delete(
      `${apiRoot}${apiRoute}/bulk`,
      {
        includes: parsedIncludes,
        ...params,
      },
      {},
      ids
    );
    jsonApiResponse.data.forEach(item => blueChipActions.removeResource(item));
    return {
      rowIndex: ids,
    };
  } else {
    const jsonApiResponse = await service.delete(
      `${apiRoot}${apiRoute}/${ids}`,
      {
        includes: parsedIncludes,
        ...params,
      }
    );
    try {
      blueChipActions.removeResource(jsonApiResponse.data);
    } catch (e) {
      // Bluechip rises an error when tries to delete a resource but any element
      //of this type exists in the bluechip resources
    }
    return {
      rowIndex: [ids],
    };
  }
}

export async function importFromFile(dataComponent, options) {
  const { apiRoute } = dataComponent;
  const apiRoot = "/";
  return await sendPost(dataComponent, options, `${apiRoot}${apiRoute}/import`);
}
