import { connect } from "react-redux";
import _noop from "lodash/noop";
import { withRouter } from "react-router";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../../reducers/dataComponentReducer";
import {
  updateProject,
  initEditProject,
} from "../../../../../../actions/projectsActions";
import { dataComponentId } from "../../../../Accounting/Projects/ProjectsContainer";
import {
  showSnackNotificationAction,
  openModalDialog,
} from "../../../../../../actions/layoutActions";
import { getBluechipResourceById } from "../../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import ProjectEditableAttribute from "./ProjectEditableAttribute";
const projectDataComponentId = "ProjectDetailWithFunding";
const dataComponentIdUpdate = dataComponentId;

export const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  const projectDataComponent = getDataComponentFlattenedRequestState(
    projectDataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const project = getBluechipResourceById(
    projectDataComponent,
    state,
    projectId
  );

  return {
    dataComponentUpdate: getDataComponent(
      dataComponentIdUpdate,
      state,
      REQUEST_TYPES.UPDATE
    ),
    dataComponentIdUpdate,
    project: project || _noop(),
    projectId,
  };
};

export const mapDispatchToProps = {
  updateProject,
  initEditProject,
  openModalDialog,
  showSnackNotificationAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectEditableAttribute)
);
