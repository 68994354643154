import React from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";

import UnassignedOnPO from "./UnassignedOnPO";
import UnassignedOnBidGroups from "./UnassignedOnBidGroups";

export const UnassignedSpecs = ({ path, history, match, ...props }) => {
  const handleOpenSpecDetail = specId => {
    const {
      params: { projectId, clientId },
    } = match;
    history.push(`/clients/${clientId}/projects/${projectId}/specs/${specId}`);
  };

  if (path === "/bid-groups")
    return (
      <UnassignedOnBidGroups
        {...props}
        onOpenSpecDetail={handleOpenSpecDetail}
      />
    );
  return <UnassignedOnPO {...props} onOpenSpecDetail={handleOpenSpecDetail} />;
};

UnassignedSpecs.propTypes = { path: propTypes.string };

export default withRouter(UnassignedSpecs);
