import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";

import { PORevisionContext } from "./wrapPORevision";
import { RevisionReferenceContext } from "./withRevisionReference";

/**
 * A public higher-order component to use the PO revision state
 */
function withDeletedItems(WrappedComponent) {
  const Wrapper = ({ rows, ...props }) => {
    const { isActive, getDeletedItems } = useContext(PORevisionContext);
    const { reference } = useContext(RevisionReferenceContext);

    const finalRows = useMemo(() => {
      const existingRows = rows || [];
      if (!isActive || !reference) {
        return existingRows;
      }
      return [...existingRows, ...getDeletedItems(reference)];
    }, [rows, isActive, getDeletedItems, reference]);

    return <WrappedComponent {...props} rows={finalRows} />;
  };

  Wrapper.defaultProps = { rows: [] };

  Wrapper.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  };

  return Wrapper;
}
export default withDeletedItems;
