import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListItem, ListItemIcon, ListItemText, Menu } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffRounded from "@material-ui/icons/HighlightOffRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Loader from "../../../ui/Loader";
import SearchOption from "../SearchOption";
import NoteIcon from "../NoteIcon";
import {
  HeaderIconsContainer,
  iconStyle,
  UserMenuIcon,
  UserMenuItem,
} from "../components";
import withRenderControlByRole, {
  Actions,
} from "../../../hocs/withRenderControlByRole";

import { CustomReportsIcon } from "./CustomReportsIcon";
import SwitchPortalMenuItem from "./SwitchPortalMenuItem";

const UserIcon = styled(ListItemIcon)({
  ...iconStyle,
  margin: "0",
  color: "rgb(255,255,255)",
});

export const AccountCircleIcon = styled(AccountCircle)({
  ...iconStyle,
  margin: "0",
});

const HidableWrapper = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: none;
  }
`;

const UserName = styled(props => (
  <ListItemText classes={{ primary: "primary" }} {...props} />
))`
  padding: 0 0 0 8px;
  @media (max-width: 600px) {
    display: none;
  }
  & .primary {
    padding: 0;
    color: rgb(255, 255, 255);
    line-height: 26px;
    display: inline;
  }
`;

export const UserMenuButton = styled(ListItem)`
  width: auto;
  padding-left: 12px;
  padding-right: 24px;
  white-space: nowrap;
`;

export const UserNoteIcon = styled(NoteIcon)`
  fill: white;
`;

export function HeaderMenu({
  handleLogout,
  onOpenSettings,
  onClose,
  anchorElement,
  isStaffPortal = true,
}) {
  const handleOpenSettings = () => {
    onClose();
    onOpenSettings();
  };
  return (
    <Menu
      id="user_menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={onClose}
    >
      <UserMenuItem onClick={handleOpenSettings}>
        <UserMenuIcon>
          <SettingsIcon />
        </UserMenuIcon>
        Account Settings
      </UserMenuItem>
      <UserMenuItem onClick={handleLogout}>
        <UserMenuIcon>
          <HighlightOffRounded />
        </UserMenuIcon>
        Log Out
      </UserMenuItem>
      <SwitchPortalMenuItem onClose={onClose} isStaffPortal={isStaffPortal} />
    </Menu>
  );
}

HeaderMenu.propTypes = {
  handleCloseUserMenu: PropTypes.func,
  handleLogout: PropTypes.func,
  onClose: PropTypes.func,
  onOpenSettings: PropTypes.func,
  anchorElement: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isStaffPortal: PropTypes.bool,
};

function BWUserHeader({
  handleClick,
  onOpenNotesModal,
  authenticatedUserName,
  onOpenGenerateCustomReport,
  displayCustomReports,
  ...menuProps
}) {
  return (
    <HeaderIconsContainer>
      <SearchOption />
      <HidableWrapper>
        <UserNoteIcon onClick={onOpenNotesModal} />
      </HidableWrapper>
      {displayCustomReports ? (
        <HidableWrapper>
          <CustomReportsIcon onClick={onOpenGenerateCustomReport} />
        </HidableWrapper>
      ) : null}
      <Tooltip title="User Options">
        <UserMenuButton
          button={true}
          aria-owns="user_menu"
          onClick={handleClick}
        >
          <Loader
            loading={!authenticatedUserName}
            width="150px"
            height="26px"
            inverted
          >
            <UserIcon>
              <AccountCircleIcon />
            </UserIcon>
            <UserName
              id="user_name"
              primary={authenticatedUserName && `PD: ${authenticatedUserName}`}
            />
          </Loader>
        </UserMenuButton>
      </Tooltip>
      <HeaderMenu {...menuProps} onClose={menuProps.handleCloseUserMenu} />
    </HeaderIconsContainer>
  );
}

BWUserHeader.propTypes = {
  handleClick: PropTypes.func.isRequired,
  authenticatedUserName: PropTypes.string,
  anchorElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  handleCloseUserMenu: PropTypes.func,
  handleLogout: PropTypes.func,
  onOpenNotesModal: PropTypes.func.isRequired,
  onOpenGenerateCustomReport: PropTypes.func.isRequired,
  displayCustomReports: PropTypes.bool,
};

export default withRenderControlByRole(
  BWUserHeader,
  ["Client Portal", "Vendor Portal"],
  Actions.HIDE
);
