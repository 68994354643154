import React from "react";

import {
  Grid as DXGrid,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import { RowDetailState } from "@devexpress/dx-react-grid";
import { BWToggleCell } from "../../BWGrid";
import PropTypes from "prop-types";

const RowDetailGrid = ({ children, tableComponents, ...props }) => {
  const {
    WrapperDetailRow,
    DetailRowComponent,
    DetailCellComponent,
  } = tableComponents;

  const { rows } = props;

  return (
    <DXGrid {...props}>
      {children}
      <RowDetailState
        key="rowDetailState"
        expandedRowIds={rows.map((_, index) => index)}
      />
      <TableRowDetail
        key="tableRowDetail"
        contentComponent={DetailRowComponent}
        cellComponent={DetailCellComponent}
        toggleCellComponent={BWToggleCell}
        toggleColumnWidth={24}
        rowComponent={WrapperDetailRow}
      />
    </DXGrid>
  );
};

RowDetailGrid.propTypes = {
  tableComponents: PropTypes.shape({
    DetailRowComponent: PropTypes.func,
    DetailCellComponent: PropTypes.func,
    WrapperDetailRow: PropTypes.func,
  }),
};

export default RowDetailGrid;
