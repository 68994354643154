import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import _pick from "lodash/pick";

import { inputs } from "../../../../inputs/inputConfigs";
import { ActionButtons, FormikForm } from "../../../../forms";
import { FormGrid } from "../../../../forms/FormGrid";
import Shipment from "../../../../../models/Shipment";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import useActions from "../../../../hooks/useActions";
import {
  setReload,
  initDataComponent,
  performUpdateRequest,
  clearResources,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { dataComponentId as specShipmentsDataComponentId } from "../ShipmentsContainer";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import { specsGridDataComponentId } from "../../../../../actions/purchaseOrderSpecsActions";

export const FormGridContainer = styled.div`
  padding-bottom: 0px;
`;

export const FormTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 24px 14px 24px;
`;

export const buildInputFields = setFieldValue => ({
  groups: [
    {
      items: [
        {
          input: inputs.estimatedShipDate,
          grid: { xs: 6 },
        },
        {
          input: inputs.estimatedDeliveryDate,
          grid: { xs: 6 },
        },
        {
          input: inputs.actualShipDate,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.actualDeliveryDate,
            handleChange: () =>
              setFieldValue && setFieldValue(inputs.approvedToPay.name, true),
          },
          grid: { xs: 6 },
        },
        {
          input: { ...inputs.approvedToPay },
          grid: { xs: 12 },
        },
        {
          input: {
            name: "forceUpdate",
            label: "Force Update",
            InputComponent: CheckboxWithError,
            useSwitch: true,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export const generateShipments = (resources, specIds) => {
  const shipments = Shipment.query(resources)
    .where({ specId: specIds })
    .toObjects();

  return shipments;
};

export const getShipments = createSelector(
  ({ resources }) => resources,
  (_, { specIds }) => specIds,
  generateShipments
);

export const buildNewShipments = (shipments, { forceUpdate, ...values }) => {
  return shipments.map(shipment => {
    shipment = _pick(shipment, fieldsToPick);
    for (const field in values) {
      if (
        initialValues[field] != values[field] &&
        (forceUpdate || !shipment.approvedToPay)
      ) {
        shipment[field] = values[field];
      }
    }
    return shipment;
  });
};

const dataComponentId = "editMultipleShipmentsDataComponent";

export const handleUpdateRequestError = showSnackNotificationAction => ({
  data,
}) => {
  if (!data.errors) return;
  if (data.errors[0].source.includes("estimatedDeliveryDate")) {
    showSnackNotificationAction(
      "There are some shipments with Estimated Ship date greater than the Estimated Delivery date"
    );
  }

  if (data.errors[0].source.includes("actualDeliveryDate")) {
    showSnackNotificationAction(
      "There are some shipments with Actual Ship date greater than the Actual Delivery date"
    );
  }
};

export const initialValues = () => ({
  estimatedShipDate: null,
  actualShipDate: null,
  estimatedDeliveryDate: null,
  actualDeliveryDate: null,
  approvedToPay: null,
});

export const fieldsToPick = [
  "id",
  "estimatedShipDate",
  "actualShipDate",
  "estimatedDeliveryDate",
  "actualDeliveryDate",
  "approvedToPay",
];

export const updateApprovedToPay = (
  actualDeliveryDate,
  lastActualDeliveryDate,
  setFieldValue,
  setLastActualDeliveryDate
) => {
  if (
    (lastActualDeliveryDate === undefined || lastActualDeliveryDate === null) &&
    actualDeliveryDate != lastActualDeliveryDate
  ) {
    setFieldValue(`approvedToPay`, true);
  }

  if (actualDeliveryDate != lastActualDeliveryDate) {
    setLastActualDeliveryDate(actualDeliveryDate);
  }
};

export const getValidationSchema = () =>
  BWModels.Joi.object({
    estimatedShipDate: BWModels.Joi.date().empty(null),
    actualShipDate: BWModels.Joi.date().empty(null),
    estimatedDeliveryDate: BWModels.Joi.date()
      .empty(null)
      .when("estimatedShipDate", {
        is: BWModels.Joi.exist(),
        then: BWModels.Joi.date().min(BWModels.Joi.ref("estimatedShipDate")),
      }),
    actualDeliveryDate: BWModels.Joi.date()
      .empty(null)
      .when("actualShipDate", {
        is: BWModels.Joi.exist(),
        then: BWModels.Joi.date().min(BWModels.Joi.ref("actualShipDate")),
      }),
  });

const EditMultipleShipmentsModal = ({
  selectedSpecIds,
  shipmentsDataComponentId,
}) => {
  const { shipments } = useSelector(state => ({
    shipments: getShipments(state, { specIds: selectedSpecIds }),
  }));

  const dataComponent = useDatacomponent(dataComponentId);

  const loading = dataComponent.requestState?.update?.loading;

  const validationSchema = getValidationSchema();

  const actions = useActions({
    setReload,
    closeModalDialog,
    initDataComponent,
    performUpdateRequest,
    showSnackNotificationAction,
    clearResources,
  });

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.clearResources(["shipments"]);
      actions.closeModalDialog();
      actions.setReload(specShipmentsDataComponentId, true);
      actions.setReload(specsGridDataComponentId, true);
      shipmentsDataComponentId &&
        actions.setReload(shipmentsDataComponentId, true);
    },
    onError: handleUpdateRequestError(actions.showSnackNotificationAction),
  });

  useEffect(() => {
    actions.initDataComponent(dataComponentId, Shipment, [], "shipments");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = values => {
    const newShipments = buildNewShipments(shipments, values);
    actions.performUpdateRequest(dataComponentId, newShipments);
  };

  return (
    <FormikForm
      initialValues={initialValues()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
        return (
          <Fragment>
            <FormGridContainer>
              <FormGrid
                fields={buildInputFields(setFieldValue)}
                values={values}
                errors={errors}
                {...formikProps}
              />
            </FormGridContainer>
            <FormTitle>{`Checking above will also update previously Approved to Pay Shipments.`}</FormTitle>
            <ActionButtons
              onSend={handleSubmit}
              isModal={true}
              loading={loading}
            />
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

EditMultipleShipmentsModal.propTypes = {
  selectedSpecIds: PropTypes.arrayOf(PropTypes.string),
};

EditMultipleShipmentsModal.defaultProps = {
  selectedSpecIds: [],
};

export default EditMultipleShipmentsModal;
