import { BaseModel } from "@blue-chip/core";

import Contact from "./Contact";
import ProjectSite from "./ProjectSite";

export default class ProjectSiteContact extends BaseModel {
  static get belongsTo() {
    return [ProjectSite, Contact];
  }
}
