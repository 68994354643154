import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import memoizeOne from "memoize-one";
import _pick from "lodash/pick";
import _get from "lodash/get";

import FormikForm from "../../../../../forms/FormikForm";
import ActionButtons from "../../../../../forms/ActionButtons";
import FormGrid from "../../../../../forms/FormGrid";

import { buildQuoteFormFields } from "./quoteFields";
import propTypes from "../../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import { useV2DatacomponentResources } from "../../../../../hooks/useResources";

const hasAwardedSpecs = ({ bidGroupVendorSpecs }) =>
  bidGroupVendorSpecs.some(
    ({ quotes, spec: { awardedBidGroupVendorSpecQuoteId } }) =>
      awardedBidGroupVendorSpecQuoteId == _get(quotes, "0.id")
  );

export const getInitValues = (bidGroupVendor, projectDefaultCurrency) => {
  const attributes = [
    "vat",
    "quoteProjectCurrencyId",
    "leadTimesTo",
    "leadTimesFrom",
    "leadTimeForSubmittal",
    "dropDeadDates",
    "quoteDate",
    "quoteValidUntil",
    "factoryLocation",
    "quoteProvider",
    "fob",
    "potentialTariffType",
    "potentialTariff",
    "potentialTariffLocal",
    "estimatedFreightToWarehouse",
    "estimatedFreightToWarehouseLocal",
    "paymentTermId",
    "miscellaneousCost",
    "miscellaneousCostLocal",
    "miscellaneous",
    "notes",
    "bidGroupId",
    "generalNotes",
    "quoteFile",
    "vendorId",
    "bidComplete",
    "isDraft",
  ];
  return {
    ..._pick(bidGroupVendor, attributes),
    quoteProjectCurrencyId:
      bidGroupVendor.quoteProjectCurrencyId ||
      _get(projectDefaultCurrency, "id", "1"),
    quoteDate: bidGroupVendor.quoteDate || undefined,
    estimatedShipDate: bidGroupVendor.estimatedShipDate || undefined,
    potentialTariffType: bidGroupVendor.potentialTariffType || "$",
    potentialTariffLocal:
      bidGroupVendor.potentialTariffType === "$"
        ? bidGroupVendor.potentialTariffLocal
        : bidGroupVendor.potentialTariff,
  };
};

export const bidGroupVendorValidationSchema = memoizeOne(() => {
  const additionalProperties = {
    leadTimesTo: BWModels.Joi.number().required(),
    leadTimesFrom: BWModels.Joi.number().required(),
    leadTimeForSubmittal: BWModels.Joi.number().required(),
    factoryLocation: BWModels.Joi.string()
      .trim()
      .required(),
    fob: BWModels.Joi.string()
      .trim()
      .required(),
    paymentTermId: BWModels.Joi.selectFk().required(),
  };
  const validationSchema = BWModels.loadSchema("BidGroupVendor").append(
    additionalProperties
  );
  validationSchema.forPATCH = BWModels.loadSchema("BidGroupVendor").forPATCH;
  return validationSchema;
});

const QuoteForm = ({
  bidGroupVendor,
  onSubmit,
  onCancel,
  sendButtonText,
  projectDefaultCurrency,
  dataComponentId,
  projectId,
}) => {
  const projectCurrencies = useV2DatacomponentResources(
    "select-project-currencies",
    [],
    RequestTypes.LIST
  );

  const isCurrencyFieldDisabled = hasAwardedSpecs(bidGroupVendor);

  return (
    <FormikForm
      onSubmit={onSubmit}
      initialValues={getInitValues(bidGroupVendor, projectDefaultCurrency)}
      validationSchema={bidGroupVendorValidationSchema()}
    >
      {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
        const selectedProjectCurrency = projectCurrencies.find(
          projectCurrency => projectCurrency.id == values.quoteProjectCurrencyId
        );
        return (
          <Fragment>
            <FormGrid
              fields={buildQuoteFormFields(
                projectId,
                selectedProjectCurrency?.currency,
                values.potentialTariffType,
                isCurrencyFieldDisabled
              )}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              onCancel={onCancel}
              onSend={handleSubmit}
              isModal={true}
              sendButtonText={sendButtonText}
              listeners={[dataComponentId]}
            />
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

QuoteForm.propTypes = {
  goBack: PropTypes.func,
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool,
  sendButtonText: PropTypes.string,
  projectId: PropTypes.string,
  projectDefaultCurrency: propTypes.projectCurrency,
  dataComponentId: PropTypes.string.isRequired,
};

export default QuoteForm;
