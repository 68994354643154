import React, { Fragment, useCallback } from "react";
import _get from "lodash/get";
import { renderRoutes } from "react-router-config";
import moment from "moment";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import Settings from "@material-ui/icons/Settings";
import Weekend from "@material-ui/icons/Weekend";
import InsertChart from "@material-ui/icons/InsertChart";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { push } from "connected-react-router";
import { Link as MuiLink } from "@material-ui/core";

import theme from "../../ui/Tags/theme";
import { PageHeader, TitleArea } from "../../layout/PageHeader";
import TagText from "../../ui/Tags/TagText";
import TagScope from "../../ui/Tags/TagScope";
import Person from "../../ui/Person/Person";
import PersonList, { getMembers, getUserInfo } from "../../ui/Tags/PersonList";
import propTypes from "../../../constants/propTypes";
import { roleCan } from "../../../utils/roleUtils";
import { RawNoteIcon } from "../../layout/AppLayout/NoteIcon";
import {
  ActionIcon,
  styledIcon,
  Buttons,
  Dates,
  TitleWrapper,
  Title,
  PropertyName,
  LocationAndTeamContainer,
  LocationContainer,
  PropertyAddress,
  Name,
  Scopes,
  Currency,
  TagListLabel,
  TeamContainer,
  PersonTagListContainer,
} from "./components";
import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import { Button } from "../../layout/PageHeader/TitleArea";
import LocationCombined from "./LocationCombined";
import { getDefaultProjectCurrency } from "../../helpers";
import Loader from "../../ui/Loader";
import { PRIMARY_DATE_FORMAT } from "../../../constants/formats";
import useActions from "../../hooks/useActions";
import useUserRole from "../../hooks/useUserRole";

const Tbd = () => null;

export const Link = styled(MuiLink)`
  cursor: pointer;
  font-size: inherit;
  text-decoration: underline;
`;

const rootMasterHiddenTabLabels = [
  "Forecast",
  "Purchasing",
  "Expediting",
  "Accounting",
  "Terms",
];
const getNavigationTabs = ({ status }) =>
  [
    {
      path: "/overview",
      label: "Overview",
      permission: "project-detail-specs",
    },
    {
      path: "/areas",
      label: "Areas",
      permission: "project-detail-areas",
    },
    { path: "/specs", label: "Specs", permission: "project-detail-specs" },
    {
      path: "/forecast",
      label: "Forecast",
      permission: "project-detail-forecast",
    },
    {
      path: "/quoting",
      label: "Purchasing",
      permission: "project-detail-purchasing",
    },
    {
      path: "/expediting",
      label: "Expediting",
      permission: "project-detail-expediting",
    },
    {
      path: "/accounting",
      label: "Accounting",
      component: Tbd,
      permission: "project-detail-accounting",
    },
    {
      path: "/terms",
      label: "Terms",
      component: Tbd,
      permission: "project-detail-accounting",
    },
  ].filter(
    navigationTab =>
      status !== "Root - Master" ||
      !rootMasterHiddenTabLabels.includes(navigationTab.label)
  );

export const PersonTagList = ({
  members,
  type,
  memberType,
  label,
  personTypeLabel,
}) => {
  const hasMembersFor = useCallback(
    (type, memberType) => {
      if (members.length === 0) return;
      return getMembers(members, type, memberType).length > 0;
    },
    [members]
  );

  if (!hasMembersFor(type, memberType)) {
    return <div />;
  }
  return (
    <TeamContainer aria-label={`Project Members: ${label}`}>
      <PersonList
        members={members}
        type={type}
        memberType={memberType}
        personTypeLabel={personTypeLabel}
      />
      <TagListLabel>{label}</TagListLabel>
    </TeamContainer>
  );
};

PersonTagList.propTypes = {
  members: PropTypes.arrayOf(propTypes.user),
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  memberType: PropTypes.string.isRequired,
  personTypeLabel: PropTypes.string.isRequired,
};

PersonTagList.defaultProps = {
  members: [],
};

export const LocationAndTeam = ({ property, project, client }) => {
  const { entity = {} } = property;
  const location = property.location || {};

  const clientName = _get(client, "name", "");

  const actions = useActions({ push });
  const userRole = useUserRole();

  const handleClientNameClick = () => actions.push(`/clients/${client.id}`);

  const isClientLinkAllowed = userRole.name == "Admin";
  const clientNameNode = isClientLinkAllowed ? (
    <Link onClick={handleClientNameClick} color="secondary">
      {clientName}
    </Link>
  ) : (
    clientName
  );

  return (
    <ThemeProvider theme={{ tag: theme.medium }}>
      <LocationAndTeamContainer>
        <LocationContainer>
          <TitleWrapper>
            <Title>Client:</Title>
            <Name>
              <Loader width="165px" inverted>
                {clientNameNode}
              </Loader>
            </Name>
          </TitleWrapper>
          <TitleWrapper>
            <Title>Entity:</Title>
            <Name>
              <Loader width="165px" inverted>
                {entity.name}
              </Loader>
            </Name>
          </TitleWrapper>
          <PropertyName>
            <Loader width="165px" inverted>
              {property.name}
            </Loader>
          </PropertyName>
          <PropertyAddress>
            <Loader width="165px" inverted>
              <LocationCombined location={location} />
            </Loader>
          </PropertyAddress>
        </LocationContainer>
        <PersonTagListContainer>
          {project.projectLead && (
            <TeamContainer>
              <Person
                person={getUserInfo(project.projectLead)}
                personType="Lead"
              />
              <TagListLabel>Lead</TagListLabel>
            </TeamContainer>
          )}
          <PersonTagList
            members={project.projectMemberUsers}
            type="user"
            memberType="Project Support"
            label="Support"
            personTypeLabel="Support Assistant"
          />
          <PersonTagList
            members={project.projectMemberUsers}
            type="user"
            memberType="Accounting Support"
            label="Accounting"
            personTypeLabel="Accounting Support"
          />
          <PersonTagList
            members={project.projectMemberContacts}
            type="contact"
            memberType="Client Team"
            label="Client"
            personTypeLabel="Client Contacts"
          />
          <PersonTagList
            members={project.projectMemberContacts}
            type="contact"
            memberType="Project Manager"
            label="Manager"
            personTypeLabel="Project Manager Contacts"
          />
          <PersonTagList
            members={project.projectMemberContacts}
            type="contact"
            memberType="Interior Designer"
            label="Designer"
            personTypeLabel="Interior Designer Contacts"
          />
          <PersonTagList
            members={project.projectMemberContacts}
            type="contact"
            memberType="General Contractor"
            label="Contractor"
            personTypeLabel="General Contractor Contacts"
          />
          <PersonTagList
            members={project.projectMemberContacts}
            type="contact"
            memberType="Other"
            label="Others"
            personTypeLabel="Other Contacts"
          />
        </PersonTagListContainer>
      </LocationAndTeamContainer>
    </ThemeProvider>
  );
};

LocationAndTeam.propTypes = {
  property: propTypes.property,
  project: propTypes.project,
  client: PropTypes.object,
};

const titleAreaButtons = (
  { openCOMModal, openReportsModal, goToProjectSettings, onOpenNotesModal },
  role
) => {
  const projectSetting = roleCan(role, "project-detail-settings")
    ? [
        {
          Icon: Settings,
          tooltip: "Project Settings",
          onClick: goToProjectSettings,
        },
      ]
    : [];
  return [
    {
      Icon: RawNoteIcon,
      title: "Project Notes",
      tooltip: "Project Notes",
      onClick: onOpenNotesModal,
    },
    {
      Icon: InsertChart,
      tooltip: "Generate Reports",
      onClick: openReportsModal,
    },
    { Icon: Weekend, tooltip: "COM Library", onClick: openCOMModal },
    ...projectSetting,
  ];
};
const buildTitleAreaButton = buttons => {
  return buttons.map(({ Icon, onClick, options, tooltip }, i) =>
    options ? (
      <DropdownMenu
        Button={Button}
        Icon={styledIcon(Icon)}
        options={options}
        key={i}
        tooltip={tooltip}
      />
    ) : tooltip ? (
      <Tooltip key={i} title={tooltip}>
        <Button index={i} onClick={onClick}>
          <ActionIcon Icon={Icon} />
        </Button>
      </Tooltip>
    ) : (
      <Button key={i} index={i} onClick={onClick}>
        <ActionIcon Icon={Icon} />
      </Button>
    )
  );
};

const ProjectDetailPage = ({
  project,
  openCOMModal,
  openReportsModal,
  goToProjectSettings,
  onOpenNotesModal,
  route,
  match,
  role,
  client,
}) => {
  if (!project) return null;
  const startDate = project.startDate
    ? moment(new Date(project.startDate)).format(PRIMARY_DATE_FORMAT)
    : "--";
  const endDate = new Date(project.endDate);
  const property = project.property ? project.property : {};
  const defaultCurrency = getDefaultProjectCurrency(project?.projectCurrencies);
  const currency = _get(defaultCurrency, "currency", {
    symbol: "",
    isoCode: "",
  });

  return (
    <Fragment>
      <PageHeader navigationTabs={getNavigationTabs(project)}>
        <TitleArea
          id={project.number}
          title={`${project.name}`}
          dates={
            <Dates>
              {`${startDate}`}
              <ArrowForwardIcon
                style={{
                  height: "16px",
                  color: "#FFFFFF",
                }}
              />
              {`${moment(endDate).format(PRIMARY_DATE_FORMAT)}`}
            </Dates>
          }
        >
          <TagText intent={"none"}>{project.status}</TagText>
          <Scopes>
            <TagScope
              workScopes={project.workScopes}
              projectSubWorkScopes={project.projectSubWorkScopes}
              isPublicSubScopeNotes={project.isPublicSubScopeNotes}
            />
          </Scopes>
          <Currency>
            {currency && `${currency.symbol} ${currency.isoCode}`}
          </Currency>
          <Buttons>
            {buildTitleAreaButton(
              titleAreaButtons(
                {
                  openCOMModal,
                  openReportsModal,
                  goToProjectSettings,
                  onOpenNotesModal,
                },
                role
              )
            )}
          </Buttons>
        </TitleArea>

        <LocationAndTeam
          property={property}
          project={project}
          client={client}
        />
      </PageHeader>
      {renderRoutes(route.routers, { project, match })}
    </Fragment>
  );
};

ProjectDetailPage.propTypes = {
  project: PropTypes.object,
  openCOMModal: PropTypes.func.isRequired,
  openReportsModal: PropTypes.func.isRequired,
  goToProjectSettings: PropTypes.func.isRequired,
  onOpenNotesModal: PropTypes.func.isRequired,
  route: propTypes.route,
  match: propTypes.matchRouter,
  role: propTypes.userRole,
  client: PropTypes.object,
};

ProjectDetailPage.defaultProps = {
  project: {},
  client: {},
};

export default ProjectDetailPage;
