import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";

export const Banner = styled.div`
  display: flex;
  align-items: center;
  background: #ff9100;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px;
  color: white;
`;

export const SubmittedBanner = ({ quoteStatus, projectLead, loading }) => {
  if (quoteStatus !== "submitted" || loading) {
    return null;
  }

  return (
    <Banner>
      <Text>
        Thank you for submitting a quote. If you need to make changes, please
        contact {projectLead?.name ?? ""} to request a resubmission.
      </Text>
    </Banner>
  );
};

SubmittedBanner.propTypes = {
  quoteStatus: PropTypes.string,
  projectLead: propTypes.user,
  loading: PropTypes.bool,
};
