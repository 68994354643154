import React from "react";
import PropTypes from "prop-types";

import AutocompleteSelect from "./AutocompleteSelect";
import InputLoader from "../../../ui/Loader/InputLoader";

const AutocompleteSelectContainer = ({ ignoreCaption, ...props }) => {
  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <AutocompleteSelect {...props} />
    </InputLoader>
  );
};

AutocompleteSelectContainer.propTypes = {
  ignoreCaption: PropTypes.bool,
};

export default AutocompleteSelectContainer;
