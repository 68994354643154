import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ActionButtons } from "../../../../forms";
import RegularText from "../../../../ui/Typography/RegularText";
import propTypes from "../../../../../constants/propTypes";
import LinearProgressWithLabel from "../../../../ui/LinearProgressWithLabel";
import { useQueueProcessRequests } from "../../../../hooks/useQueueProcess";
import useUserRole from "../../../../hooks/useUserRole";

import SelectWithErrorContainer from "../../../../inputs/SelectWithError/SelectWithErrorContainer";
import { inputs } from "../../../../inputs/inputConfigs";
import { isSuperAdmin } from "../../../../../utils/roleUtils";

export const updateTermsDataComponentId = "bulk-update-terms";

export const Message = styled(RegularText)`
  padding: 24px;
`;

const LinearProgressWrapper = styled.div`
  margin: 0px 24px 24px 24px;
`;

export const queueId = "syncEntityTerms";

export const Select = styled(SelectWithErrorContainer)`
  width: 50%;
  padding: 0px 24px 24px 24px;
`;

export const SelectScope = ({ scopeRequired, value, onChange, disabled }) => {
  if (!scopeRequired) {
    return null;
  }

  return (
    <Select
      label="Scope"
      onChange={onChange}
      value={value}
      dataComponentId={inputs.scopeId.dataComponentId}
      APIOptions={inputs.scopeId.APIOptions}
      isSingleAutocomplete
      isAutocomplete
      required
      valueProperty="id"
      labelProperty="name"
      isDisabled={disabled}
    />
  );
};
SelectScope.propTypes = {
  scopeRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const isScopeRequired = (userRole, isGlobal) =>
  isSuperAdmin(userRole) && isGlobal;

export const BulkUpdateTerms = ({
  entity,
  clientId,
  isGlobal,
  initQueueProcess,
  createQueueProcess,
  syncUpdateTermsQueue,
  loading,
  progress,
  closeModalDialog,
  showSnackNotificationAction,
}) => {
  const [scopeId, setScopeId] = useState(null);
  const entityLabel = entity ? entity.name : "All entity";

  useEffect(() => {
    initQueueProcess(queueId);
  }, [initQueueProcess]);

  useQueueProcessRequests(syncUpdateTermsQueue, {
    onSuccess: () => {
      closeModalDialog();
      showSnackNotificationAction(
        `${entityLabel} terms were updated successfully`
      );
    },
    onError: () => {
      showSnackNotificationAction(
        "There was a problem while updating entity terms."
      );
    },
  });

  const handleUpdateTerms = () => {
    const ids = entity ? [entity.id] : [];
    createQueueProcess("sync-entity-terms", queueId, {
      ids,
      isGlobal,
      clientId,
      scopeId,
    });
  };

  const handleScopeChange = ({ target: { value } }) => {
    setScopeId(value);
  };

  const userRole = useUserRole();
  const scopeRequired = isScopeRequired(userRole, isGlobal);

  return (
    <Fragment>
      <Message>
        Are you sure you want to update {entity ? entity.name : "all entity"}{" "}
        terms?
      </Message>
      <SelectScope
        scopeRequired={scopeRequired}
        value={scopeId}
        onChange={handleScopeChange}
        disabled={loading}
      />
      {loading && isGlobal && (
        <LinearProgressWrapper>
          <LinearProgressWithLabel value={progress} />
        </LinearProgressWrapper>
      )}
      <ActionButtons
        onDanger={handleUpdateTerms}
        dangerButtonText={`Update ${entityLabel} terms`}
        cancelButtonText="Dismiss"
        isModal
        emphasizeCancel
        additionalProps={{
          cancel: { disabled: loading },
          danger: { disabled: scopeRequired && !scopeId },
        }}
        loading={loading}
      />
    </Fragment>
  );
};
BulkUpdateTerms.propTypes = {
  entity: propTypes.entity,
  clientId: PropTypes.string,
  isGlobal: PropTypes.bool,
  initQueueProcess: PropTypes.func.isRequired,
  createQueueProcess: PropTypes.func.isRequired,
  syncUpdateTermsQueue: PropTypes.object,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  progress: PropTypes.number,
};
