import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";

import CreateRemitAddressForm from "./CreateRemitAddressForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  populateCountryResources,
  populateStateResources,
} from "../../../../actions/countryStateActions";

class CreateRemitAddressContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    populateCountryResources: PropTypes.func,
    populateStateResources: PropTypes.func,
    formikArrayHelpers: PropTypes.object,
  };

  get addressSchema() {
    return BWModels.loadSchema("RemitAddress");
  }

  componentDidMount() {
    this.props.populateCountryResources();
    this.props.populateStateResources();
  }

  _createAddress = address => {
    this.props.formikArrayHelpers.push(address);
    this.props.closeModalDialog();
  };

  render() {
    const { closeModalDialog } = this.props;
    return (
      <CreateRemitAddressForm
        addressSchema={this.addressSchema}
        createAddress={this._createAddress}
        closeModalDialog={closeModalDialog}
      />
    );
  }
}

const mapDispatchToProps = {
  closeModalDialog,
  populateCountryResources,
  populateStateResources,
};

export default connect(null, mapDispatchToProps)(CreateRemitAddressContainer);
