import React from "react";
import FolderOpen from "@material-ui/icons/FolderOpen";
import { CheckCircle } from "@material-ui/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

export const Check = styled(CheckCircle)`
  position: absolute;
  left: 10px;
  width: 20px;
  color: #72b328;
  bottom: 24px;
`;

export const ReviewSubmittalsIcon = ({ requirementsApproved }) => {
  if (!requirementsApproved) {
    return <FolderOpen />;
  }

  return (
    <div>
      <FolderOpen />
      <Check />
    </div>
  );
};

ReviewSubmittalsIcon.propTypes = {
  requirementsApproved: PropTypes.bool,
};
