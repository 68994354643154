import React from "react";
import styled from "styled-components";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { SortableContainer } from "react-sortable-hoc";

// min-width: unset !important; - hack to override devex grid min-width calc
export const StyledBWTable = styled(
  ({ useDragHandle, onSortEnd, ...props }) => <Table.Table {...props} />
)`
  width: 100%;
  min-width: unset !important;
  table-layout: auto;
  border-collapse: collapse;
`;

const SortableTable = SortableContainer(StyledBWTable);

const BWTable = ({ styles, onReorder, ...props }) => (
  <SortableTable {...props} onSortEnd={onReorder} useDragHandle />
);

export default BWTable;
