import TextInputWithError from "../TextInputWithError";
import CheckboxWithError from "../CheckboxWithError";
import PercentageInput from "../PercentageInput";

export default {
  discountApplyToAllItems: {
    name: "applyToAllItems",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Applies to all items",
  },
  discountPercent: {
    name: "discountPercent",
    InputComponent: TextInputWithError,
    label: "Discount",
    fullWidth: true,
    required: true,
    InputProps: {
      inputComponent: PercentageInput,
      inputProps: {
        suffix: "%",
      },
    },
  },
  comments: {
    InputComponent: TextInputWithError,
    label: "Comments",
    name: "comments",
    fullWidth: true,
    multiline: true,
    rows: 2,
  },
};
