import React from "react";
import PropTypes from "prop-types";

import { DashIcon } from "../../../../ui/gridTableConfigs/detailPageProjects";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import propTypes from "../../../../../constants/propTypes";
import { LabeledTextContext } from "../../../../../withPORevision/withLabeledTextOption";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";
import Tooltip from "../../../../mui/core/Tooltip";
import { isPaidShipment } from "../ManageShipments/gridProps";

export const getPaidShipmentMessage = paidShipment =>
  paidShipment
    ? "Approved To Pay cannot be unchecked as this shipment has already been paid for or is staged to be paid."
    : "";

export const ApprovedToPay = ({
  shipment,
  handleApproveToPay,
  totalQuantityApprovedToPay,
  totalQuantityPaid,
  label,
}) => {
  const isProjectClosed = useIsProjectClosed();
  if (!shipment) return <DashIcon />;

  const { approvedToPay, quantity } = shipment;

  const paidShipment =
    approvedToPay &&
    isPaidShipment({ totalQuantityPaid }, totalQuantityApprovedToPay, quantity);

  const disabled = isProjectClosed || paidShipment;

  const onChange = evt => {
    const {
      target: { value },
    } = evt;
    handleApproveToPay([shipment], value);
  };

  return (
    <LabeledTextContext.Provider value={disabled}>
      <Tooltip title={getPaidShipmentMessage(paidShipment)}>
        <span>
          <CheckboxWithError
            label={label}
            value={approvedToPay}
            onChange={onChange}
            disabled={disabled}
            onClick={evt => {
              evt.stopPropagation();
              evt.preventDefault();
            }}
          />
        </span>
      </Tooltip>
    </LabeledTextContext.Provider>
  );
};

ApprovedToPay.propTypes = {
  label: PropTypes.string,
  shipment: propTypes.shipment,
  handleApproveToPay: PropTypes.func.isRequired,
  totalQuantityApprovedToPay: PropTypes.number,
  totalQuantityPaid: PropTypes.number,
};
