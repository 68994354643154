import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import EmptyState from "./EmptyState";
import propTypes from "../../../constants/propTypes";
import RequirementsGrid from "./RequirementsGrid";
import ModalTitle from "../../layout/AppLayout/ModalDialog/ModalTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  background-color: #ffffff;
`;

const getDefaultSpecTitle = selectedSpec =>
  selectedSpec
    ? `${selectedSpec.customNumber} ${selectedSpec.description}`
    : "No Spec Selected";

const CreateForm = ({ selectedSpec }) => {
  const [specTitle, setTitle] = useState(getDefaultSpecTitle(selectedSpec));

  useEffect(() => {
    setTitle(getDefaultSpecTitle(selectedSpec));
  }, [selectedSpec]);

  const handleTitleChange = useCallback(
    specRequirement => {
      const defaultSpecTitle = getDefaultSpecTitle(selectedSpec);
      setTitle(
        specRequirement
          ? `${defaultSpecTitle} - ${specRequirement.requirement.name}`
          : defaultSpecTitle
      );
    },
    [selectedSpec]
  );

  return (
    <Wrapper>
      <ModalTitle title={["Review Submittals", specTitle]} />
      {selectedSpec ? (
        <RequirementsGrid
          specId={selectedSpec.id}
          onTitleChange={handleTitleChange}
          setTitle={setTitle}
        />
      ) : (
        <EmptyState />
      )}
    </Wrapper>
  );
};

CreateForm.propTypes = {
  selectedSpec: propTypes.spec,
};

export default CreateForm;
