import React from "react";
import styled from "styled-components";
import { Remove as MUIRemoveIcon } from "@material-ui/icons";
import _get from "lodash/get";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import Date from "../../../../ui/BWGrid/columns/Date";
import {
  PODatesContainer,
  PODatesTitle,
  StyledArrowForward,
} from "../components";
import { parseQuantity } from "./ManageShipmentsModal";
import { addVal } from "../../../Specs/SpecFormikForm";
import { subtractVal } from "../../../InvoiceDetail/Specs/SpecsList";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const StyledDivHeader = styled.div`
  padding: 25px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
`;

const StyledDivTitle = styled.div`
  display: flex;
`;

const StyledSpanNumber = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const StyledSpanName = styled.span`
  font-size: 18px;
  margin-left: 10px;
`;

const StyledColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShipmentQuantityHeader = styled.th`
  font-size: 14px;
  font-weight: initial;
  padding-left: 10px;
  padding-right: 10px;
`;

export const QuantityValue = styled.td`
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
`;

const RemoveIcon = styled(MUIRemoveIcon)`
  width: 14px;
  height: 14px;
`;

const ShipmentQuantityValue = ({ value }) => {
  return <QuantityValue>{value ? value : <RemoveIcon />}</QuantityValue>;
};

ShipmentQuantityValue.propTypes = {
  value: PropTypes.number,
};

export const calculateQuantityTotals = (spec, shipments) => {
  const quantity = spec.totalQuantityWithoutOverage;
  const { shipped, totalShipmentQuantity } = shipments.reduce(
    (
      { shipped, totalShipmentQuantity },
      { quantity, actualShipDate, actualDeliveryDate }
    ) => {
      const isShipped = actualShipDate && actualDeliveryDate;
      return {
        shipped: addVal(shipped, isShipped ? Number(quantity) : 0),
        totalShipmentQuantity: addVal(totalShipmentQuantity, Number(quantity)),
      };
    },
    { shipped: 0, totalShipmentQuantity: 0 }
  );
  return {
    quantity,
    left: parseQuantity(subtractVal(quantity, shipped)),
    shipped: parseQuantity(shipped),
    totalShipmentQuantity,
  };
};

const SpecEstimatedDates = ({
  quantity,
  left,
  shipped,
  spec: { purchaseOrder, totalQuantityPaid },
}) => {
  return (
    <StyledDivHeader>
      <StyledColumnDiv>
        <StyledDivTitle>
          <StyledSpanNumber>PO #{purchaseOrder.number}</StyledSpanNumber>
          <StyledSpanName>
            {_get(purchaseOrder, "vendor.name", "")}
          </StyledSpanName>
        </StyledDivTitle>
        <PODatesContainer>
          <PODatesTitle> Original PO Est. Shipment</PODatesTitle>
          {
            <Date
              date={purchaseOrder.estimatedShipDate}
              format={PRIMARY_DATE_FORMAT}
              customMissingFormat="00-00-0000"
            />
          }
          <StyledArrowForward />
          <PODatesTitle>Original PO Est. Delivery</PODatesTitle>
          {
            <Date
              date={purchaseOrder.estimatedDeliveryDate}
              format={PRIMARY_DATE_FORMAT}
              customMissingFormat="00-00-0000"
            />
          }
        </PODatesContainer>
      </StyledColumnDiv>
      <StyledColumnDiv>
        <table>
          <thead>
            <tr>
              <ShipmentQuantityHeader>Qty.</ShipmentQuantityHeader>
              <ShipmentQuantityHeader>Paid</ShipmentQuantityHeader>
              <ShipmentQuantityHeader>Left</ShipmentQuantityHeader>
              <ShipmentQuantityHeader>Shipped</ShipmentQuantityHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ShipmentQuantityValue value={quantity} />
              <ShipmentQuantityValue value={totalQuantityPaid} />
              <ShipmentQuantityValue value={left} />
              <ShipmentQuantityValue value={shipped} />
            </tr>
          </tbody>
        </table>
      </StyledColumnDiv>
    </StyledDivHeader>
  );
};

SpecEstimatedDates.propTypes = {
  spec: propTypes.spec,
  quantity: PropTypes.number,
  left: PropTypes.number,
  shipped: PropTypes.number,
};

export default SpecEstimatedDates;
