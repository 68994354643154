import {
  processSaveRequest,
  processSaveRequestForBidGroupVendorSpecQuotes,
  processSaveRequestForBidGroupVendorSpecs,
  processUpdateRequestForPurchaseOrder,
  processUpdateRequestForSpec,
} from "./QuoteRequestFunctions";
import {
  bidGroupDataComponentId,
  bidGroupVendorDataComponentId,
} from "./QuotesPage";
import BidGroupVendor from "../../../../models/BidGroupVendor";
import Spec from "../../../../models/Spec";
import BidGroup from "../../../../models/BidGroup";
import { specDataComponentId } from "../../../../actions/bidGroupActions";

export const onCreateBidGroupPurchaseOrder = (
  bidGroupId,
  purchaseOrder,
  dataComponentId,
  performCreateRequest
) => {
  const bidGroupPurchaseOrder = {
    bidGroupId,
    purchaseOrder,
  };

  performCreateRequest(dataComponentId, bidGroupPurchaseOrder);
};
export const onCreateAlternateQuote = (
  bidGroupVendorSpecId,
  notes,
  quoteItems,
  dataComponentId,
  performCreateRequest
) => {
  const bidGroupVendorSpecQuote = {
    bidGroupVendorSpecId,
    notes,
    quoteItems,
  };

  performCreateRequest(dataComponentId, bidGroupVendorSpecQuote);
};
export const onUpdateNote = (
  bidGroupVendorSpecQuote,
  dataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(dataComponentId, bidGroupVendorSpecQuote.id, {
    notes: bidGroupVendorSpecQuote.notes,
  });
};
export const onUpdatePurchaseOrderStatus = (
  purchaseOrder,
  status,
  dataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(dataComponentId, purchaseOrder.id, { status: status });
};
export const onAwardSpec = (
  specs,
  dataComponentId,
  awardSpecAction,
  bidGroupVendorRef
) => {
  awardSpecAction(dataComponentId, bidGroupVendorRef, specs);
};
export const onUnAwardSpec = (
  specs,
  dataComponentId,
  unawardSpecAction,
  bidGroupVendorRef
) => {
  unawardSpecAction(dataComponentId, bidGroupVendorRef, specs);
};
export const initBidGroupDataComponent = initDataComponent => {
  initDataComponent(
    bidGroupDataComponentId,
    BidGroup,
    [
      "bidGroupVendors.[bidGroupVendorSpecs.[quotes.[quoteItems]],quoteProjectCurrency.[currency]]",
      "specs(withQuantityPrice).[unitOfMeasure,projectCurrency.[currency],specCategory,specDetails.[specDetailComs.spec.[projectCurrency.[currency],specCategory]]]",
    ],
    "bid-groups"
  );
};
export const initBidGroupVendorDataComponent = (
  initDataComponent,
  destroyDataComponent,
  analysis
) => {
  destroyDataComponent(bidGroupVendorDataComponentId);
  initDataComponent(
    bidGroupVendorDataComponentId,
    BidGroupVendor,
    [
      "bidGroup.project.projectCurrencies.currency",
      "quoteProjectCurrency.[currency]",
      "vendor.location",
      "bidGroupVendorSpecs.[quotes.[quoteItems], spec.[unitOfMeasure,specCategory,specDetails.[specDetailComs.spec.purchaseOrder], purchaseOrder]]",
      "quoteFiles",
    ],
    `bid-group-vendors${analysis ? "/analysis" : ""}`,
    true,
    "v2"
  );
};
export const initSpecDataComponent = initDataComponent => {
  initDataComponent(specDataComponentId, Spec, [], "specs", true, "v2");
};
export const processRequests = (
  prevDataComponent,
  bidGroupPurchaseOrderDataComponent,
  bidGroupDataComponentId,
  bidGroupId,
  callbacks
) => {
  processSaveRequest(
    prevDataComponent,
    bidGroupPurchaseOrderDataComponent,
    bidGroupDataComponentId,
    bidGroupId,
    callbacks
  );
};
export const processRequestsForPurchaseOrder = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  callbacks
) => {
  processUpdateRequestForPurchaseOrder(
    prevDataComponent,
    dataComponent,
    dataComponentId,
    callbacks
  );
};
export const processRequestsForSpecs = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  callbacks
) => {
  processUpdateRequestForSpec(
    prevDataComponent,
    dataComponent,
    dataComponentId,
    callbacks
  );
};
export const processRequestsForBidGroupVendorSpecs = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  callbacks
) => {
  processSaveRequestForBidGroupVendorSpecs(
    prevDataComponent,
    dataComponent,
    dataComponentId,
    callbacks
  );
};
export const processRequestForBidGroupVendorSpecQuotes = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  bidGroupId,
  callbacks
) => {
  processSaveRequestForBidGroupVendorSpecQuotes(
    prevDataComponent,
    dataComponent,
    dataComponentId,
    bidGroupId,
    callbacks
  );
};
