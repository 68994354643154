import PropTypes from "prop-types";
import React from "react";

import EditableTextDetailRowContainer from "../EditableTextDetailRowContainer";
import { Cell } from "../components";
import BWTableRow from "../BWTableRow";

const column = {};

export const genDetailRowComponents = (
  path,
  idPath,
  validationSchema,
  dataComponentId,
  colSpan,
  prevColSpan,
  textInputProps,
  isReadOnly,
  dstDataComponentProps,
  omitDisableOnProjectClosed
) => {
  const DetailRowComponent = props => (
    <EditableTextDetailRowContainer
      path={path}
      validationSchema={validationSchema}
      dataComponentId={dataComponentId}
      idPath={idPath}
      textInputProps={textInputProps}
      isReadOnly={isReadOnly}
      dstDataComponentProps={dstDataComponentProps || {}}
      omitDisableOnProjectClosed={omitDisableOnProjectClosed}
      {...props}
    />
  );

  const WrapperDetailRow = ({ children }) => {
    if (!colSpan) return <tr>{children}</tr>;
    const prevSpan = prevColSpan ? prevColSpan : children.length - colSpan;
    const posSpan = prevSpan ? children.length - colSpan - prevSpan : 0;
    return (
      <tr>
        <Cell column={column} colSpan={prevSpan} />
        {children}
        {posSpan > 0 && <Cell column={column} colSpan={posSpan} />}
      </tr>
    );
  };

  WrapperDetailRow.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
  };

  const DetailCellComponent = ({ children }) => (
    <Cell column={column} colSpan={colSpan}>
      {children}
    </Cell>
  );

  DetailCellComponent.propTypes = {
    children: PropTypes.shape({}),
  };

  return {
    DetailRowComponent,
    RowComponent: BWTableRow,
    WrapperDetailRow,
    DetailCellComponent,
  };
};
