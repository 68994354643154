import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TemplatePlaceholder } from "@devexpress/dx-react-core";
import ToolBarMUI from "@material-ui/core/Toolbar/Toolbar";
import SectionTitle from "../../ui/Typography/SectionTitle";

export const StyledGridRow = styled(ToolBarMUI)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: unset;
  padding-top: 18px;
  padding-bottom: 18px;
`;

export const EmptyDiv = styled.div`
  display: none;
`;

export const PagingGridRow = styled(StyledGridRow)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

export const Content = styled.div`
  flex: 1;
`;

export default class GridRow extends Component {
  static propTypes = {
    children: PropTypes.node,
    showPagingPanel: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
  };

  static isGridRow = true;

  render() {
    const { children, showPagingPanel, className, isLoading } = this.props;

    const content = isLoading ? (
      <SectionTitle>Loading...</SectionTitle>
    ) : (
      children
    );

    if (showPagingPanel) {
      return (
        <PagingGridRow className={className}>
          <Content>{content}</Content>
          <TemplatePlaceholder name="pagingPanel" />
        </PagingGridRow>
      );
    }

    return children ? (
      <StyledGridRow className={className}>{content}</StyledGridRow>
    ) : (
      <EmptyDiv className={className} /> //To avoid styled components issues
    );
  }
}
