import React from "react";

import EmptyState from "../../EmptyState";

export const buildNoDataCell = ({
  text,
  isLoading,
  isEmpty,
  isCollapsed,
  ...emptyStateProps
}) => {
  const renderCell = !isLoading && isEmpty && !isCollapsed;
  function NoDataCell({ tableRow, tableColumn, getMessage, ...props }) {
    return renderCell ? (
      <td {...props}>
        <EmptyState {...emptyStateProps}>{text}</EmptyState>
      </td>
    ) : null;
  }
  return NoDataCell;
};

export const NoDataRow = ({ tableRow, ...props }) => {
  return <tr {...props} />;
};
