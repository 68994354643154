import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { openModalDialog } from "../../../../actions/layoutActions";
import ActionDetailLink from "./ActionDetailLink";

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openModalDialog }, dispatch),
});

export default connect(null, mapDispatchToProps)(ActionDetailLink);
