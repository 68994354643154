import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import FavoriteStar from "./FavoriteStar";
import AuthService from "../../../services/AuthService";
import ProjectUserStar from "../../../models/ProjectUserStar";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import propTypes from "../../../constants/propTypes";
import { initDataComponent } from "../../../actions/dataComponentActions";
import { getActiveRequest } from "../../../utils/dataComponentUtils";
import {
  unstarProject,
  starProject,
  updateProjectResource,
} from "../../../actions/projectStarActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import { dataComponentProjectUserStarsId } from "../../pages/Dashboard/MyProjects/MyProjectsContainer";

const getDataComponentId = projectId => `ProjectUserStars-${projectId}`;

const typesOfRequests = [REQUEST_TYPES.CREATE, REQUEST_TYPES.DELETE];

export class ProjectStarContainer extends Component {
  static defaultProps = {
    service: new AuthService(true),
  };

  componentDidMount() {
    const { project } = this.props;
    this.props.initDataComponent(
      getDataComponentId(project.id),
      ProjectUserStar,
      [],
      "project-user-stars",
      true
    );
  }

  handleToggle = isStarred => {
    const { project, projectUserStars, loggedInUserId } = this.props;

    if (isStarred) {
      this.props.starProject();
    } else {
      const projectUserStar = projectUserStars.find(
        projectUserStar =>
          projectUserStar.userId === loggedInUserId &&
          project.id === projectUserStar.projectId
      );

      projectUserStar && this.props.unstarProject(projectUserStar);
    }
  };

  render() {
    const { project, activeRequest } = this.props;

    return (
      <FavoriteStar
        isStarred={!!project.isFavorited}
        loading={!!activeRequest}
        onClick={this.handleToggle}
      />
    );
  }
}

ProjectStarContainer.propTypes = {
  project: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  initDataComponent: PropTypes.func.isRequired,
  unstarProject: PropTypes.func.isRequired,
  starProject: PropTypes.func.isRequired,
  updateProjectResource: PropTypes.func.isRequired,
  service: PropTypes.object,
  activeRequest: PropTypes.oneOf(typesOfRequests),
  projectUserStars: PropTypes.arrayOf(propTypes.projectUserStar),
};

const mapStateToProps = (state, { project }) => {
  const dataComponent = getDataComponent(getDataComponentId(project.id), state);
  const dataComponentProjectUserStars = getDataComponentFlattenedRequestState(
    dataComponentProjectUserStarsId,
    state
  );
  const projectUserStars = getBluechipResources(
    dataComponentProjectUserStars,
    state
  );
  return {
    projectUserStars,
    activeRequest: getActiveRequest(dataComponent, typesOfRequests),
    dataComponent,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { loggedInUserId, project } = ownProps;
  const userId = loggedInUserId;
  const dataComponentId = getDataComponentId(project.id);
  return {
    starProject: () => dispatch(starProject(dataComponentId, project, userId)),
    unstarProject: projectUserStar => {
      return dispatch(unstarProject(dataComponentId, projectUserStar, project));
    },
    ...bindActionCreators(
      { initDataComponent, updateProjectResource },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectStarContainer);
