import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ModalDialog from "./ModalDialog";
import {
  closeModalDialog,
  changeModalDialogElementType,
} from "../../../../actions/layoutActions";

export class ModalDialogContainer extends Component {
  static propTypes = {
    modalDialog: PropTypes.object.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    changeModalDialogElementType: PropTypes.func.isRequired,
  };

  render() {
    const {
      modalDialog,
      closeModalDialog,
      changeModalDialogElementType,
    } = this.props;

    // Send this property to handle the X icon click.
    const { onCloseModal } = modalDialog;

    return (
      <ModalDialog
        {...modalDialog}
        onTabChange={changeModalDialogElementType}
        closeModalDialog={onCloseModal || closeModalDialog}
      />
    );
  }
}

const mapStateToProps = ({ layout }) => ({
  modalDialog: layout.modalDialog,
});

const mapDispatchToProps = {
  closeModalDialog,
  changeModalDialogElementType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDialogContainer);
