import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _get from "lodash/get";
import moment from "moment";

import FeesList from "./FeesList";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../../reducers/dataComponentReducer";
import {
  fetchFeeSummaries,
  openPaymentsDialog,
} from "../../../../../actions/feeSummaryActions";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import {
  openModalDialog,
  showSnackNotificationAction,
  setAutoSaveComponentId,
} from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

export const dataComponentId = "FeesGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  showSnackNotificationAction,
  setReload,
  fetchFeeSummaries
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(dataComponentId, true);
      fetchFeeSummaries();
      showSnackNotificationAction("Fee has been updated successfully");
    },
    onError: ({ data }) => {
      setReload(dataComponentId, true);
      showSnackNotificationAction(_get(data, "errors.0.title"));
    },
  });
};

export const getHandleOpenVoidModal = ({
  openModalDialog,
  handleUpdateStatus,
  dataComponentId,
}) => ({ id }) => {
  openModalDialog(
    `Void Fee`,
    "VoidFee",
    {
      title: ``,
      onVoidFee: handleUpdateStatus("Void"),
      feeId: id,
      dataComponentId,
    },
    false,
    false,
    {
      subtitle: `Fee`,
    }
  );
};

const FeesContainer = ({
  projectId,
  dataComponent,
  flattenDataComponent,
  openPaymentsDialog,
  openModalDialog,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  fetchFeeSummaries,
  setAutoSaveComponentId,
  generateAndDownloadReport,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    setAutoSaveComponentId(dataComponent.dataComponentId);
  }, [dataComponent.dataComponentId, setAutoSaveComponentId]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      showSnackNotificationAction,
      setReload,
      fetchFeeSummaries
    );
  }, [
    dataComponent,
    showSnackNotificationAction,
    setReload,
    prevDataComponent,
    fetchFeeSummaries,
  ]);

  const handleUpdateStatus = status => ({ id }) => {
    performUpdateRequest(dataComponentId, id, { status });
  };

  const handleOpenCreateModal = () => {
    openModalDialog(
      "Add Fee",
      "CreateFee",
      {
        projectId,
      },
      false,
      false,
      {
        scrollDisabled: true,
      }
    );
  };

  const handleOpenEditModal = fee => {
    openModalDialog(
      `Edit Fee`,
      "CreateFee",
      {
        projectId,
        fee,
        feeId: fee.id,
      },
      false,
      false,
      {
        scrollDisabled: true,
      }
    );
  };

  const handleOpenBillFeeModal = formatter => fee => {
    openModalDialog(
      `Bill Fee`,
      "BillFee",
      {
        projectId,
        fee,
        feeId: fee.id,
        dataComponentId,
      },
      true,
      true,
      {
        subtitle: `Billing Date: ${moment(fee.billingDate).format(
          PRIMARY_DATE_FORMAT
        )} | Amount: ${formatter.format(fee.amount)}`,
      }
    );
  };

  const handleOpenVoidModal = getHandleOpenVoidModal({
    openModalDialog,
    handleUpdateStatus,
    dataComponentId,
  });

  const handleAfterCreatePayment = fee => {
    setReload(dataComponentId, true);
    handleRowClick(fee);
  };

  const handleRowClick = fee => {
    openPaymentsDialog({
      fee,
      dataComponentId,
      performAfterCreatePayment: handleAfterCreatePayment,
    });
  };

  return (
    <FeesList
      projectId={projectId}
      dataComponent={flattenDataComponent}
      onRowClick={handleRowClick}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onUpdateStatus={handleUpdateStatus}
      onOpenVoidModal={handleOpenVoidModal}
      onOpenBillFeeModal={handleOpenBillFeeModal}
      generateAndDownloadReport={generateAndDownloadReport}
    />
  );
};

FeesContainer.propTypes = {
  fetchFeeSummaries: PropTypes.func,
  projectId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  flattenDataComponent: propTypes.dataComponent,
  generateAndDownloadReport: PropTypes.func.isRequired,
  openPaymentsDialog: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
};

export const mapStateToProps = (
  state,
  {
    match: {
      params: { projectId },
    },
  }
) => {
  return {
    projectId,
    dataComponent: getDataComponent(dataComponentId, state),
    flattenDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  fetchFeeSummaries,
  setAutoSaveComponentId,
  openPaymentsDialog,
  generateAndDownloadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeesContainer);
