import styled from "styled-components";
import { useContext, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";

import InputWrapper from "../InputWrapper";
import { WithFloatInputValidatorContext } from "./withFloatInputValidator";
import { WithEditableCellContext } from "../../ui/BWGrid/withEditableCellController";
import { isFunction, isNull } from "lodash";

export const InputContainer = styled.div`
  display: flex;
  min-width: 225px;
  max-width: 225px;
  align-items: center;
  padding: ${({ topMargin }) => topMargin || "22px"} 16px 0px 16px;
  height: ${({ height }) => height};
`;

const stopEvtPropagation = evt => {
  evt.preventDefault();
  evt.stopPropagation();
};

const keyWasPressed = (evt, key, setSubmitted, hasError) => {
  if (evt.key === key) {
    stopEvtPropagation(evt);
    document.activeElement.blur();
    setSubmitted(true);
    return !hasError;
  }
};

const buildHandleKeyDown = (
  setCurrentCell,
  openNextCell,
  openPrevCell,
  setSubmitted,
  hasError,
  handleChange,
  openNextFallback,
  openPrevFallback
) => evt => {
  if (keyWasPressed(evt, "Enter", setSubmitted, hasError)) {
    handleChange(evt);
  }

  if (keyWasPressed(evt, "Tab", setSubmitted, hasError)) {
    return evt.shiftKey
      ? openPrevCell(openPrevFallback)
      : openNextCell(openNextFallback);
  }
};

export const getErrorStatus = (
  hasParentError,
  parentErrorMessage,
  isValid,
  name,
  localValue,
  wasSumitted
) => () => {
  const { valid, errorMessage, helperText } = isValid({ [name]: localValue });
  return {
    hasError: hasParentError || !valid,
    helperText: wasSumitted
      ? parentErrorMessage || errorMessage || helperText
      : helperText,
  };
};

const isInvalidAnchorEl = anchorEl =>
  isNull(anchorEl) ||
  anchorEl === undefined ||
  (isFunction(anchorEl) && (isNull(anchorEl()) || anchorEl() === undefined));

const buildEventHandlers = ({
  hasError,
  setCurrentCell,
  setSubmitted,
  setLocalValue,
  isApplyOnChange,
  localValue,
  onChange,
  openNextCell,
  openPrevCell,
  inputContainerRef,
  openNextFallback,
  openPrevFallback,
}) => {
  const submitChanges = () => {
    setSubmitted(true);
    if (!hasError) {
      setCurrentCell({});
    }
  };

  const handleChange = ({ target }) => {
    const value =
      target.type === "number" ? target.valueAsNumber : target.value;
    setLocalValue(value);
    if (isApplyOnChange && !hasError) {
      submitChanges();
      return onChange(value);
    }
  };

  const handleBlur = () => {
    if (isApplyOnChange) return;
    setSubmitted(true);
    if (!hasError) {
      setCurrentCell({});
      return onChange(localValue);
    }

    return { hasError };
  };

  const handleKeyDown = buildHandleKeyDown(
    setCurrentCell,
    openNextCell,
    openPrevCell,
    setSubmitted,
    hasError,
    handleChange,
    openNextFallback,
    openPrevFallback
  );

  const handleClose = evt => {
    if (!hasError) {
      setCurrentCell({});
    }
    stopEvtPropagation(evt);
    setSubmitted(true);
  };

  const handleEntered = () => {
    const input = inputContainerRef.current.getElementsByTagName("input")[0];
    input?.select();
    input?.focus();
  };

  return {
    handleChange,
    handleBlur,
    handleKeyDown,
    handleClose,
    handleEntered,
  };
};

export const FloatInput = ({
  input: {
    anchorOrigin = {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin = {
      vertical: "center",
      horizontal: "left",
    },
    ...input
  },
  name,
  hasError: hasParentError,
  errorMessage: parentErrorMessage,
  anchorEl,
  visible,
  value,
  onChange,
  openNextFallback,
  openPrevFallback,
}) => {
  const { FloatInputProps = {}, isApplyOnChange } = input;
  const [localValue, setLocalValue] = useState(value);
  const [wasSumitted, setSubmitted] = useState(false);
  const prevValue = useRef(value);
  const inputContainerRef = useRef(null);
  const { setCurrentCell, openNextCell, openPrevCell } = useContext(
    WithEditableCellContext
  );

  const { isValid } = useContext(WithFloatInputValidatorContext);

  useEffect(() => {
    if (value !== prevValue.current && !visible) {
      setLocalValue(value);
    }
    prevValue.current = value;
  }, [value, visible]);

  const { hasError, helperText } = useMemo(
    getErrorStatus(
      hasParentError,
      parentErrorMessage,
      isValid,
      name,
      localValue
    ),
    [hasParentError, parentErrorMessage, isValid, name, localValue]
  );

  const {
    handleChange,
    handleBlur,
    handleKeyDown,
    handleClose,
    handleEntered,
  } = buildEventHandlers({
    hasError,
    setCurrentCell,
    setSubmitted,
    setLocalValue,
    isApplyOnChange,
    localValue,
    onChange,
    openNextCell,
    openPrevCell,
    inputContainerRef,
    hasParentError,
    parentErrorMessage,
    isValid,
    name,
    wasSumitted,
    openNextFallback,
    openPrevFallback,
  });

  if (isInvalidAnchorEl(anchorEl)) {
    return null;
  }

  return (
    <Popover
      open={visible}
      TransitionProps={{
        onEntered: handleEntered,
        onClose: handleClose,
      }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={handleClose}
    >
      <InputContainer ref={inputContainerRef} {...FloatInputProps}>
        <InputWrapper
          {...input}
          onKeyDown={handleKeyDown}
          onClick={stopEvtPropagation}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onClose={handleBlur}
          value={localValue}
          setLocalValue={setLocalValue}
          hasError={wasSumitted && hasError}
          helperText={helperText}
          avoidDebounce
        />
      </InputContainer>
    </Popover>
  );
};

FloatInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

export default FloatInput;
