import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import propTypes from "../../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import UnassignedSpecsGrid from "./UnassignedSpecsGrid";
import {
  quotingBidGroupsDCId,
  vendorsDataComponentId,
  initQuotingFiltersByProject,
  areasDataComponentId,
  allBidGroupsDataComponentId,
} from "../../../../../actions/quotingActions";
import { resetDeletedBidGroups } from "../../../../../actions/bidGroupActions";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import { useDeleteProcessRequests } from "../../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";

export const dataComponentId = "Quoting-BidGroups-Unassigned";

export const UnassignedSpecsContainer = ({
  projectId,
  dataComponent,
  role,
  openModalDialog,
  isModalOpen,
  resetDeletedBidGroups,
  deletedBidGroups,
  performDeleteRequest,
  specCategories,
  onOpenSpecDetail,
  initQuotingFiltersByProject,
  setReload,
  specs,
  bidGroups,
}) => {
  const prev = useRef({});

  useEffect(() => {
    initQuotingFiltersByProject(projectId);
  }, [initQuotingFiltersByProject, projectId]);

  useEffect(() => {
    if (prev.current.dataComponent !== dataComponent) {
      prev.current.dataComponent = dataComponent;
    }
    if (prev.current.isModalOpen) {
      prev.current.isModalOpen = isModalOpen;
    }
  }, [dataComponent, isModalOpen]);
  const handleOpenManageBidsModal = useCallback(() => {
    resetDeletedBidGroups();
    openModalDialog(
      "Manage Bid Groups",
      "ManageBidGroups",
      { projectId },
      false,
      true
    );
  }, [resetDeletedBidGroups, openModalDialog, projectId]);

  const handleOpenCreateBidModal = useCallback(
    () =>
      openModalDialog(
        [
          "Create Bid Group",
          `${pluralize(
            "Spec",
            dataComponent.selectedIds.length,
            true
          )} Selected`,
        ],
        "CreateBidGroup",
        { projectId }
      ),
    [openModalDialog, dataComponent, projectId]
  );
  useEffect(() => {
    if (prev.current.isModalOpen === isModalOpen || isModalOpen) return;
    if (deletedBidGroups.length > 0) {
      performDeleteRequest(quotingBidGroupsDCId, deletedBidGroups);
      resetDeletedBidGroups();
    }
  }, [
    performDeleteRequest,
    isModalOpen,
    deletedBidGroups,
    resetDeletedBidGroups,
  ]);

  const quotingBidGroupDC = useDatacomponent(quotingBidGroupsDCId);
  useDeleteProcessRequests(quotingBidGroupDC, {
    onSuccess: () => {
      setReload(dataComponentId, true);
    },
  });

  const vendors = useV2DatacomponentResources(
    vendorsDataComponentId,
    [],
    REQUEST_TYPES.LIST
  );

  const areas = useV2DatacomponentResources(
    areasDataComponentId,
    [],
    REQUEST_TYPES.LIST
  );

  return (
    <UnassignedSpecsGrid
      onOpenManageBidsModal={handleOpenManageBidsModal}
      onOpenCreateBidModal={handleOpenCreateBidModal}
      projectId={projectId}
      role={role}
      dataComponent={dataComponent}
      specCategories={specCategories}
      onOpenSpecDetail={onOpenSpecDetail}
      vendors={vendors}
      areas={areas}
      specs={specs}
      bidGroups={bidGroups}
    />
  );
};

UnassignedSpecsContainer.propTypes = {
  role: propTypes.userRole,
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  deletedBidGroups: PropTypes.arrayOf(PropTypes.string),
  isModalOpen: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  resetDeletedBidGroups: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  specCategories: PropTypes.array,
  onOpenSpecDetail: PropTypes.func,
  initQuotingFiltersByProject: PropTypes.func,
  specs: PropTypes.array,
  bidGroups: PropTypes.array,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const specs = getBluechipResources(dataComponent, state);
  const isModalOpen = state.layout.modalDialog.isOpen;
  const deletedBidGroups = state.bidGroups.deletedBidGroups;
  const bidGroups =
    getBluechipResources(
      getDataComponentFlattenedRequestState(allBidGroupsDataComponentId, state),
      state
    ) || [];

  return {
    dataComponent,
    isModalOpen,
    deletedBidGroups,
    specs,
    bidGroups,
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  resetDeletedBidGroups,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  initQuotingFiltersByProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedSpecsContainer);
