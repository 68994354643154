import React from "react";
import styled from "styled-components";
import ListIcon from "@material-ui/icons/List";

import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import Subheader from "../../../../ui/Typography/Subheader";
import BoldText from "../../../../ui/Typography/BoldText";
import { Grid, Paper } from "@material-ui/core";

export const ContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 8px;
  }
`;

export const TopPaperWrapper = styled(Paper)`
  flex-basis: 50%;
  padding: 24px 24px;
`;
export const ItemWrapper = styled.div`
  margin-top: 24px;
  height: 100%;
  @media (max-width: 1280px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;
export const ItemPaperWrapper = styled(Paper)`
  padding: 24px 24px;
  height: 100%;
`;
export const NegativeWrapper = styled.div`
  margin: 0 -24px -24px -24px;
`;
export const FlexWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;
export const GridContainer = styled(Grid)`
  margin-bottom: 24px;
`;
export const GridItemLeft = styled(Grid)`
  padding-right: 12px;
  @media (max-width: 1280px) {
    padding-right: 0px;
    margin-bottom: 24px;
  }
`;
export const GridItemCenter = styled(Grid)`
  padding-left: 12px;
  padding-right: 12px;
  @media (max-width: 1280px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 24px;
  }
`;
export const GridItemRight = styled(Grid)`
  padding-left: 12px;
  @media (max-width: 1280px) {
    padding-left: 0px;
  }
`;
export const ContactNameText = styled(BoldText)`
  margin-top: 16px;
  line-height: 24px;
`;
export const SubheaderText = styled(Subheader)`
  margin-top: 24px;
`;
export const Label = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 24px;
`;
export const Value = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 4px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionWrapper = styled.div`
  display: flex;
`;

export const ViewOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  margin-left: 24px;

  span {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const StyledListIcon = styled(({ active, ...props }) => (
  <ListIcon {...props} />
))`
  font-size: 24px;
  margin-right: 12px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#57abff" : "#dadada")};
`;

export const StyledSignalIcon = styled(({ active, ...props }) => (
  <SignalCellularAltIcon {...props} />
))`
  font-size: 24px;
  cursor: pointer;
  color: ${({ active }) => (active ? "#57abff" : "#dadada")};
`;

export const StyledGrid = styled(({ isActive, ...props }) => (
  <Grid {...props} />
))`
  display: ${({ isActive = true }) => (isActive ? "auto" : "none")};
`;
