import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import _get from "lodash/get";
import _noop from "lodash/noop";
import _debounce from "lodash/debounce";

import {
  getComponents,
  getInputLabelProps,
  isOptionDisabled,
} from "../AutocompleteSelect/AutocompleteSelect";
import InputLoader from "../../../ui/Loader/InputLoader";
import { DropdownIndicator, StyleSelected } from "./components";
import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import {
  optionsWithEmptyValue,
  getLabel,
  getNullAsEmptyValue,
} from "../StandardSelect";
import { useLabeledText } from "../../TextInputWithError";

const getValue = ({ menuItemProps, ...data }, submitData, valueProperty) => {
  return (submitData ? data : data[valueProperty]) || null;
};

export const buildChangeHandler = (
  submitData,
  name,
  onChange,
  valueProperty = "id",
  onSelectedItem,
  selectRef,
  handleChange,
  nullAsEmpty
) => data => {
  const value = getValue(data, submitData, valueProperty);
  const event = {
    type: "click",
    target: { name, value: getNullAsEmptyValue({ value, nullAsEmpty }) },
  };
  onChange(event);
  handleChange && handleChange(event);
  onSelectedItem && onSelectedItem(data);
  setTimeout(() => {
    if (selectRef.current?.select) {
      selectRef.current.select.focus();
    }
  });
};

const checkWasModified = (
  isActive,
  isUpdated,
  hasModifiedChildrens,
  reference,
  name
) => () => {
  if (!isActive) return false;
  return isUpdated(reference, name);
};

const getSelected = (valueProperty, value, submitData) => option => {
  const optionValue = _get(option, valueProperty);
  return submitData
    ? optionValue === _get(value, valueProperty)
    : optionValue === value;
};

const SingleAutoCompleteSelect = ({
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  onInputChange,
  onChange,
  options: initialOptions,
  valueProperty,
  labelProperty,
  label,
  value,
  error,
  helperText,
  textFieldProps,
  onSelectedItem,
  onMenuOpen,
  nullAsEmpty,
  displayEmpty,
  handleChange,
  ignoreLabeledText,
  defaultOption,
  ...props
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { isActive, isUpdated, hasModifiedChildrens } = useContext(
    PORevisionContext
  );
  const labeledText = useLabeledText(ignoreLabeledText);
  const selectRef = useRef();
  const { reference } = useContext(RevisionReferenceContext);
  const [isOpen, setIsOpen] = useState(false);

  const [prevValue, setPrevValue] = useState(value);
  const [hide, setHide] = useState(false);

  const onChangeDebounced = _debounce(onChange, 300);

  const options = useMemo(
    optionsWithEmptyValue(
      valueProperty,
      labelProperty,
      initialOptions,
      displayEmpty,
      nullAsEmpty,
      defaultOption
    ),
    [valueProperty, labelProperty, initialOptions, displayEmpty, nullAsEmpty]
  );

  const components = useMemo(() => {
    return {
      ...getComponents(label),
      DropdownIndicator,
    };
  }, [label]);

  const selected = options.find(
    getSelected(valueProperty, value, props.submitData)
  );

  useEffect(() => {
    setPrevValue(value);
  }, [value]);

  useEffect(() => {
    if (value !== prevValue) {
      setHide(true);
    }
  }, [value, prevValue]);

  useEffect(() => {
    hide && setHide(false);
  }, [hide]);

  const wasModified = useMemo(
    checkWasModified(
      isActive,
      isUpdated,
      hasModifiedChildrens,
      reference,
      props.name
    ),
    [isActive, isUpdated, hasModifiedChildrens, reference, props.name]
  );

  const handleMenuOpen = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      onMenuOpen("");
    }
  }, [isOpen, onMenuOpen]);

  const handleMenuClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const inputProps = {
    textFieldProps,
    helperText,
    error,
    label,
  };
  return (
    <InputLoader>
      <StyleSelected
        {...props}
        hide={hide}
        selectRef={selectRef}
        value={selected || ""}
        textFieldProps={getInputLabelProps(value, inputProps, wasModified)}
        getOptionValue={data => {
          return data[valueProperty];
        }}
        getOptionLabel={data => {
          return getLabel(labelProperty, data);
        }}
        onInputChange={onInputChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        components={components}
        onChange={buildChangeHandler(
          props.submitData,
          props.name,
          onChangeDebounced,
          valueProperty,
          onSelectedItem,
          selectRef,
          handleChange,
          nullAsEmpty
        )}
        isDisabled={isDisabled || labeledText}
        isLoading={isLoading}
        options={options}
        isOptionDisabled={isOptionDisabled}
      />
    </InputLoader>
  );
};

SingleAutoCompleteSelect.defaultProps = {
  valueProperty: "id",
  labelProperty: "name",
  onMenuOpen: _noop,
};

export default SingleAutoCompleteSelect;
