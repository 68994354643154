import React from "react";
import PropTypes from "prop-types";

import DeleteConfirmation from "../../../../ui/DeleteConfirmation";

const DeleteAreaContainer = props => {
  const { title, areaId, formikArrayHelpers, index, onDeleteArea } = props;

  const handleDelete = () => {
    onDeleteArea(areaId, index, formikArrayHelpers);
  };

  return <DeleteConfirmation title={title} onDelete={handleDelete} />;
};

DeleteAreaContainer.propTypes = {
  title: PropTypes.string.isRequired,
  areaId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.number,
  onDeleteArea: PropTypes.func.isRequired,
};

export default DeleteAreaContainer;
