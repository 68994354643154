import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import VendorsPage from "./VendorsPage";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import {
  fetchData,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
} from "../../../actions/dataComponentActions";
import {
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import { fetchVendorCategories } from "../../../actions/vendorActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import { getBluechipResources } from "../../../utils/bluechipUtils";
import propTypes from "../../../constants/propTypes";

import * as DATA_COMPONENTS from "../../../constants/DataComponents";

const dataComponentId = "VendorsGrid";
const addressBookType = "Vendor";

export class VendorsContainer extends Component {
  componentDidUpdate(prevProps) {
    if (this.modalHasClosed(prevProps)) {
      this.handleModalClosed();
    }
  }

  componentDidMount() {
    this.props.fetchVendorCategories();
    this.props.setAutoSaveComponentId(dataComponentId);
  }

  handleModalClosed = () => {
    this.props.refreshEntitiesDataComponent();
  };

  modalHasClosed = prevProps => {
    return !this.props.isModalOpen && prevProps.isModalOpen;
  };

  handleAddVendorClick = () => {
    this.props.pushWithReturnUrl("/vendors/create");
  };

  handleRowClick = ({ id }) => {
    this.props.pushWithReturnUrl(`/vendors/${id}`);
  };

  handleEditVendor = id => {
    this.props.pushWithReturnUrl(`/vendors/edit/${id}`, "VENDOR INDEX");
  };

  handleDeleteVendorClick = ({ id, name: title }) => {
    this.props.openModalDialog(
      `Delete ${title}`,
      "DeleteVendor",
      { id, title },
      true,
      false
    );
  };

  generateVendorCategoriesOptions = () => {
    const { vendorCategories } = this.props;
    return vendorCategories.map(({ name }) => ({
      id: name,
      name,
    }));
  };

  render() {
    const { addressBook, dataComponent, openModalDialog } = this.props;

    return (
      <VendorsPage
        vendorCategories={this.generateVendorCategoriesOptions()}
        dataComponent={dataComponent}
        handleRowClick={this.handleRowClick}
        handleEditVendor={this.handleEditVendor}
        handleAddVendorClick={this.handleAddVendorClick}
        handleDeleteVendorClick={this.handleDeleteVendorClick}
        addressBook={addressBook}
        openModalDialog={openModalDialog}
      />
    );
  }
}

VendorsContainer.propTypes = {
  vendorCategories: PropTypes.arrayOf(propTypes.vendorCategory),
  fetchVendorCategories: PropTypes.func,
  pushWithReturnUrl: PropTypes.func.isRequired,
  dataComponent: PropTypes.object.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  refreshEntitiesDataComponent: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  addressBook: PropTypes.object.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
};
VendorsPage.defaultProps = {
  vendorCategories: [],
  fetchVendorCategories: () => {},
  pushWithReturnUrl: () => {},
  dataComponent: {},
  openModalDialog: () => {},
  refreshEntitiesDataComponent: () => {},
  addressBook: {},
};

export const mapStateToProps = state => {
  const dataComponentCategories = getDataComponentFlattenedRequestState(
    "select-vendorCategories",
    state
  );
  const dataComponentAddressBooks = getDataComponentFlattenedRequestState(
    DATA_COMPONENTS.ADDRESS_BOOKS_SELECT,
    state
  );
  const addressBooks =
    getBluechipResources(dataComponentAddressBooks, state) || [];

  const addressBook =
    addressBooks.find(addressBook => addressBook.type === addressBookType) ||
    {};

  return {
    addressBook,
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    vendorCategories:
      getBluechipResources(dataComponentCategories, state) || [],
    isModalOpen: state.layout.modalDialog.isOpen,
  };
};

export const mapDispatchToProps = {
  pushWithReturnUrl,
  openModalDialog,
  setAutoSaveComponentId,
  fetchVendorCategories,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  performUpdateRequest,
  performDeleteRequest,
  refreshEntitiesDataComponent: () => fetchData(dataComponentId),
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsContainer);
