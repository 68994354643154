import React from "react";

import inputs from "../../../inputs/inputConfigs/invoice";
import OkToIssueButton from "./OkToIssueButton";
import {
  withOverwrittedLabeledContext,
  DatePickerWrapper,
} from "../../../inputs/inputConfigs/invoice";
import TextInputWithError from "../../../inputs/TextInputWithError";

export const getCheckRequestFields = (
  values,
  canIssue,
  okToIssueError,
  updateInvoice
) => {
  if (values.checkRequest) {
    return [
      {
        input: inputs.checkRequest,
        grid: {
          xs: 4,
        },
      },
      {
        input: inputs.crIssueDate,
        grid: {
          xs: 4,
        },
      },
    ];
  }
  return [
    {
      element: (
        <OkToIssueButton
          disabled={!canIssue}
          okToIssueError={okToIssueError}
          updateInvoice={updateInvoice}
        />
      ),
      grid: {
        xs: 12,
      },
    },
  ];
};

export const getDetailFields = readOnly => {
  const invoiceNumber = { ...inputs.invoiceNumber };
  const invoicedDate = { ...inputs.invoicedDate };
  const dueDate = {
    ...inputs.dueDate,
  };

  if (readOnly) {
    invoiceNumber.InputComponent = withOverwrittedLabeledContext(
      TextInputWithError
    );
    invoicedDate.InputComponent = withOverwrittedLabeledContext(
      DatePickerWrapper
    );
    dueDate.InputComponent = withOverwrittedLabeledContext(DatePickerWrapper);
  }

  return [
    {
      input: invoiceNumber,
      grid: {
        xs: 4,
      },
    },
    {
      input: invoicedDate,
      grid: {
        xs: 4,
      },
    },
    {
      input: dueDate,
      grid: {
        xs: 4,
      },
    },
  ];
};

export default (
  values,
  canIssue,
  okToIssue,
  updateInvoice,
  allChangesLocked,
  okToIssueError
) => {
  const checkNumberInput = {
    ...inputs.checkNumber,
    ignoreLabeledText: !allChangesLocked,
    disabled: !okToIssue,
  };
  return {
    groups: [
      {
        items: getCheckRequestFields(
          values,
          canIssue,
          okToIssueError,
          updateInvoice
        ),
      },
      {
        items: getDetailFields(allChangesLocked),
      },
      {
        items: [
          {
            input: checkNumberInput,
            grid: {
              xs: 4,
            },
          },
          {
            input: inputs.paymentDate,
            grid: {
              xs: 4,
            },
          },
        ],
      },
    ],
  };
};
