import React from "react";
import { useFormikContext } from "formik";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { InputLabel } from "../../../../mui/core";
import { SelectWithError } from "../../../../inputs/SelectWithError";
import { FormGrid } from "../../../../forms";
import { inputs } from "../../../../inputs/inputConfigs";
import { AddQueryReportParamButton } from "./AddQueryReportParamButton";

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  padding-bottom: 24px;
`;

const FormGridWrapper = styled.div`
  flex: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75%;
`;
const Helper = styled(InputLabel)`
  font-style: italic;
  white-space: inherit;
`;

export const QueryReportParamFields = ({ ...formikProps }) => (
  <Wrapper>
    <FormGridWrapper>
      <FormGrid
        fields={queryReportParamFields(formikProps.values.queryReportParams)}
        {...formikProps}
      />
    </FormGridWrapper>
  </Wrapper>
);

const queryReportParamFields = queryReportParams => ({
  groups: [
    {
      items: [
        {
          element: (
            <Header>
              <LabelWrapper>
                <InputLabel>Query Params</InputLabel>
                <Helper>
                  {`Query Params should be used in the query preceding with a ":".
                  Eg. Param with name "isActive" should be used in query as
                  ":isActive"`}
                </Helper>
              </LabelWrapper>
              <AddQueryReportParamButton />
            </Header>
          ),
          grid: { xs: 12 },
        },
        ...queryReportParams.reduce(
          (arr, _, i) => [
            ...arr,
            ...[
              {
                input: {
                  ...inputs.name,
                  name: `queryReportParams[${i}].name`,
                  avoidDebounce: true,
                },
                grid: { xs: 3 },
              },
              {
                input: {
                  InputComponent: SelectWithError,
                  name: `queryReportParams[${i}].type`,
                  label: `Type`,
                  required: true,
                  fullWidth: true,
                  options: BWModels.loadSchema(
                    "QueryReportParam"
                  ).__utils.types.map(({ id, name }) => ({
                    id,
                    name: startCase(name),
                  })),
                },
                grid: { xs: 2 },
              },
              {
                element: <DeleteIcon index={i} />,
                grid: { xs: 1 },
              },
            ],
          ],
          []
        ),
      ],
    },
  ],
});

export const DeleteIcon = ({ index }) => {
  const {
    values: { queryReportParams },
    setFieldValue,
  } = useFormikContext();

  const handleDelete = () => {
    setFieldValue(
      "queryReportParams",
      queryReportParams.filter((_, i) => i !== index)
    );
  };

  return (
    <IconButton onClick={handleDelete}>
      <Delete />
    </IconButton>
  );
};

DeleteIcon.propTypes = {
  index: PropTypes.number.isRequired,
};
