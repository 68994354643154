import React, { useMemo } from "react";
import _uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";

import { getOptions } from "./gridOptions";
import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import { getOptionsFrom, columns } from "./gridOptions";
import { GridWrapper } from "./components";
import { genDetailRowComponents } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import propTypes from "../../../../constants/propTypes";

const sorting = [{ columnName: "customNumber", direction: "asc" }];

const SpecsList = ({
  specs,
  vendors,
  categories,
  defaultAreaId,
  isLoading,
}) => {
  const tableComponents = useMemo(
    () => ({
      ...genDetailRowComponents(
        "forecastComment",
        "id",
        null,
        null,
        8,
        2,
        { helperText: "Forecast Comment", disabled: true },
        true
      ),
    }),
    []
  );

  const columnOptions = useMemo(
    () =>
      getOptions(defaultAreaId, true, {
        vendors: getOptionsFrom(specs, "vendorId", vendors),
        categories: getOptionsFrom(specs, "specCategoryId", categories),
        areas: getOptionsFrom(
          specs,
          "areaId",
          _uniqBy(
            specs.map(({ area }) => area),
            "id"
          )
        ),
        projectCurrencies: getOptionsFrom(
          specs,
          "projectCurrency.id",
          _uniqBy(
            specs.map(({ projectCurrency }) => projectCurrency),
            "id"
          )
        ),
        unitOfMeasures: getOptionsFrom(
          specs,
          "unitOfMeasureId",
          _uniqBy(
            specs.map(({ unitOfMeasure }) => unitOfMeasure),
            "id"
          )
        ),
      }),
    [categories, defaultAreaId, specs, vendors]
  );

  return (
    <GridWrapper>
      <BWGridLocal
        noBorder
        className="grid-root"
        id="spec-import-data"
        rows={specs}
        tableComponents={tableComponents}
        gridConfig={{ pageSize: 10, totalRows: specs.length }}
        emptyStateText="Please select a source first"
        isLoading={isLoading}
        sorting={sorting}
        showSelectionColumn
        alwaysDisplayDetailRow
        showSelectAll
      >
        <GridHeader headerText="Spec" withCounter />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
    </GridWrapper>
  );
};

SpecsList.defaultProps = {
  vendors: [],
  areas: [],
  categories: [],
};

SpecsList.propTypes = {
  defaultAreaId: PropTypes.string,
  specs: PropTypes.arrayOf(propTypes.spec),
  areas: PropTypes.arrayOf(propTypes.area),
  categories: PropTypes.arrayOf(propTypes.specCategory),
  vendors: PropTypes.arrayOf(propTypes.vendor),
  unitOfMeasures: PropTypes.arrayOf(propTypes.unitOfMeasure),
  isLoading: PropTypes.bool,
};

export default SpecsList;
