import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../../../../forms/ActionButtons";
import RegularText from "../../../../ui/Typography/RegularText";

export const Message = styled(RegularText)`
  padding: 24px;
`;

const CancelPOConfirmation = ({ onSubmit, title, isSimple }) => (
  <Fragment>
    <Message>
      Are you sure you want to cancel this PO# {title}?
      {isSimple && " All specs will return to unassigned state."}
    </Message>
    <ActionButtons
      cancelButtonText="DISMISS"
      onDanger={onSubmit}
      dangerButtonText="Cancel Purchase Order"
      isModal
      emphasizeCancel
    />
  </Fragment>
);

CancelPOConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isSimple: PropTypes.bool,
};

export default CancelPOConfirmation;
