import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import propTypes from "../../../../constants/propTypes";
import {
  Label,
  PictureWrapper,
  Picture,
  EditLink,
  ImageContainer,
  ProfilePictureWrapper,
  EditIcon,
  EmptyState,
} from "./components";
import { Image } from "../../../ui/Image";

const MAX_ERROR_MARGIN = 0.15;
const MAX_SCALE = 2;

const ProfilePictureSection = ({ user, onOpenEditPicture }) => {
  const metadata = _get(user, "profilePicture.metadata", {
    zoom: 1,
    x: 0,
    y: 0,
  });

  const zoom = metadata.zoom + (MAX_ERROR_MARGIN * metadata.zoom) / MAX_SCALE;
  const pan = `${((metadata.x || 0) * 100).toFixed(2)}% ${(
    (metadata.y || 0) * 100
  ).toFixed(2)}%`;

  return (
    <ProfilePictureWrapper>
      <Label>Profile Picture</Label>
      <ImageContainer>
        <PictureWrapper>
          {user.profilePicture ? (
            <Image
              file={user.profilePicture}
              ImageComponent={Picture}
              zoom={zoom}
              pan={pan}
            />
          ) : (
            <EmptyState />
          )}
        </PictureWrapper>
        <EditLink onClick={onOpenEditPicture}>
          <EditIcon />
          Change Profile Picture
        </EditLink>
      </ImageContainer>
    </ProfilePictureWrapper>
  );
};

ProfilePictureSection.defaultProps = {
  user: {},
};

ProfilePictureSection.propTypes = {
  user: propTypes.user,
  onOpenEditPicture: PropTypes.func,
};

export default ProfilePictureSection;
