import { inputs } from "../../../../../components/inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        {
          input: inputs.notes,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
