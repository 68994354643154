import _get from "lodash/get";

import * as actions from "../constants/ActionTypes";
import * as RequestTypes from "../constants/RequestTypes";
import BidGroupVendor from "../models/BidGroupVendor";

import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
} from "./dataComponentActions";
import { getResourcesV2 } from "../utils/bluechipUtils";
import { getDataComponent } from "../reducers/dataComponentReducer";
import { cleanCurrencyValue } from "../utils/currencyFormatter";
import { setAutoSaveComponentId } from "./layoutActions";

export const dataComponentVendorId = "dataComponentVendorId";
export const bidGroupVendorSpecDataComponentId =
  "BidGroupVendorSpecsDataDataComponent";

export const bidGroupVendorDataComponentId = "BidGroupVendorDataComponent";

export const specDataComponentId = "SpecDataComponent";

export const setDeletedBidGroup = bidGroupId => ({
  type: actions.SET_DELETED_BID_GROUP,
  payload: {
    bidGroupId,
  },
});
export const undoDeletedBidGroup = bidGroupId => ({
  type: actions.UNDO_DELETED_BID_GROUP,
  payload: {
    bidGroupId,
  },
});
export const resetDeletedBidGroups = () => ({
  type: actions.RESET_DELETED_BID_GROUPS,
});

export const updateBidGroupVendor = (
  id,
  data,
  dataComponentId = dataComponentVendorId
) => {
  return dispatch => {
    dispatch(setAutoSaveComponentId(dataComponentId));

    dispatch(
      initDataComponent(
        dataComponentId,
        BidGroupVendor,
        ["bidGroupVendorSpecs"],
        "bid-group-vendors"
      )
    );
    return dispatch(performUpdateRequest(dataComponentId, id, data));
  };
};

export function quoteRowsAreEqual(comparator, currentRow) {
  return (
    currentRow.specId === comparator.specId &&
    currentRow.bidGroupVendorSpecId === comparator.bidGroupVendorSpecId &&
    currentRow.bidGroupVendorSpecQuoteId ===
      comparator.bidGroupVendorSpecQuoteId
  );
}

export function getPricingValue(value) {
  return typeof value === "number" ? cleanCurrencyValue(value * 100) : value;
}

export function findQuoteIdInBidGroupVendor(bidGroupVendor, row) {
  const { bidGroupVendorSpecId, bidGroupVendorSpecQuoteId, specId } = row;
  const bgVendorSpec = bidGroupVendor.bidGroupVendorSpecs.find(
    bgVendorSpec => bgVendorSpec.id === bidGroupVendorSpecId
  );
  const quote = bgVendorSpec.quotes.find(
    quote => quote.id === bidGroupVendorSpecQuoteId
  );

  const quoteItems = _get(quote, "quoteItems", []);

  const quoteItem = quoteItems.find(
    item =>
      item.bidGroupVendorSpecQuoteId === bidGroupVendorSpecQuoteId &&
      item.specId.toString() === specId.toString()
  );
  return _get(quoteItem, "id");
}

export function updateBidGroupVendorSpecQuotes(
  dataComponentId,
  row,
  params,
  columnName,
  value,
  bidGroupVendorRef
) {
  const { bidGroupVendorSpecId, bidGroupVendorSpecQuoteId, specId } = row;
  return async (dispatch, getState) => {
    const bgVendor = JSON.parse(JSON.stringify(bidGroupVendorRef.current));
    bidGroupVendorRef.current = bgVendor;

    const id = findQuoteIdInBidGroupVendor(bgVendor, row);
    await dispatch(
      performUpdateRequest(
        dataComponentId,
        bidGroupVendorSpecId,
        {
          quotes: [
            {
              id: bidGroupVendorSpecQuoteId,
              bidGroupVendorSpecId,
              quoteItems: [
                {
                  id,
                  bidGroupVendorSpecQuoteId,
                  specId,
                  sampleQuantity: row.sampleQuantity,
                  samplePricing: getPricingValue(row.samplePricing),
                  productionQuantity: row.productionQuantity,
                  productionPricing: getPricingValue(row.productionPricing),
                  [columnName]: value,
                },
              ],
            },
          ],
        },
        params
      )
    );

    const state = getState();
    const dataComponent = getDataComponent(
      bidGroupVendorSpecDataComponentId,
      state
    );

    const bgVendorSpec = getResourcesV2(
      dataComponent,
      state,
      RequestTypes.UPDATE
    );

    const bigGroupVendorSpecIndex = bgVendor.bidGroupVendorSpecs.findIndex(
      bgVendorSpec => bgVendorSpec.id === row.bidGroupVendorSpecId
    );

    bgVendor.bidGroupVendorSpecs[bigGroupVendorSpecIndex] = bgVendorSpec;
  };
}

export function updateSpecOnBidGroupVendor(
  dataComponentId,
  bidGroupVendorRef,
  specs
) {
  return async (dispatch, getState) => {
    await dispatch(performUpdateRequest(dataComponentId, specs));

    const state = getState();

    const bgVendors = getResourcesV2(
      getDataComponent(bidGroupVendorDataComponentId, state),
      state,
      RequestTypes.LIST
    );

    await dispatch(
      performRetrieveListRequest(bidGroupVendorDataComponentId, {
        rootFilters: {
          $where: { bidGroupId: bgVendors[0].bidGroupId },
        },
        pageSize: -1,
      })
    );
  };
}
