import TextInputWithError from "../TextInputWithError";
import CheckboxWithError from "../CheckboxWithError";
import CurrencyInput from "../CurrencyInput";
import PercentageInput from "../PercentageInput";
import DatePickerWithError from "../DatePickerWithError";
import FilePickerWithError from "../FilePickerWithError";
import { SelectWithError } from "../SelectWithError";
import { getOptionsFromSchema } from "../utils";

export default {
  quoteEstimatedShipDate: {
    InputComponent: DatePickerWithError,
    label: "Estimated Ship Date",
    name: "quoteEstimatedShipDate",
    fullWidth: true,
  },
  vat: {
    InputComponent: TextInputWithError,
    name: "vat",
    label: "VAT %",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
    inputProps: {
      decimalScale: 2,
    },
  },
  leadTimesTo: {
    InputComponent: TextInputWithError,
    name: "leadTimesTo",
    label: "To",
    fullWidth: true,
  },
  leadTimesFrom: {
    InputComponent: TextInputWithError,
    name: "leadTimesFrom",
    label: "Lead Times (Weeks)",
    fullWidth: true,
  },
  leadTimeForSubmittal: {
    InputComponent: TextInputWithError,
    name: "leadTimeForSubmittal",
    label: "Lead Times for Submittal (Weeks)",
    fullWidth: true,
  },
  dropDeadDates: {
    InputComponent: TextInputWithError,
    name: "dropDeadDates",
    label: "Drop Dead Dates",
    fullWidth: true,
  },
  factoryLocation: {
    InputComponent: TextInputWithError,
    name: "factoryLocation",
    label: "Factory Location",
    fullWidth: true,
  },
  quoteProvider: {
    InputComponent: TextInputWithError,
    name: "quoteProvider",
    label: "Quote Provider",
    fullWidth: true,
  },
  quoteDate: {
    InputComponent: DatePickerWithError,
    name: "quoteDate",
    label: "Quote Date",
    fullWidth: true,
  },
  fob: {
    InputComponent: TextInputWithError,
    label: "FOB Customs Cleared DDP",
    name: "fob",
    fullWidth: true,
  },
  potentialTariffType: {
    InputComponent: SelectWithError,
    name: "potentialTariffType",
    label: "Potential Tariff Type",
    options: getOptionsFromSchema("BidGroupVendor", "potentialTariffTypes"),
    isSingleAutocomplete: true,
    isAutocomplete: true,
    fullWidth: true,
    required: true,
  },
  potentialTariffPercent: {
    InputComponent: TextInputWithError,
    name: "potentialTariff",
    label: "Potential Tariff (%)",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
    inputProps: {
      decimalScale: 2,
    },
  },
  potentialTariffAmount: {
    InputComponent: TextInputWithError,
    name: "potentialTariff",
    label: "Potential Tariff ($)",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  miscellaneousCosts: {
    InputComponent: TextInputWithError,
    name: "miscellaneousCost",
    label: "Miscellaneous Costs",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  estimatedFreightToWarehouse: {
    InputComponent: TextInputWithError,
    name: "estimatedFreightToWarehouse",
    label: "Estimated Freight to Warehouse",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  miscellaneous: {
    InputComponent: TextInputWithError,
    name: "miscellaneous",
    label: "Miscellaneous",
    fullWidth: true,
    multiline: true,
    resize: "vertical",
    avoidOnChange: true,
  },
  notes: {
    InputComponent: TextInputWithError,
    name: "notes",
    label: "Notes",
    fullWidth: true,
    multiline: true,
    resize: "vertical",
    avoidOnChange: true,
  },
  bidComplete: {
    label: "Bid Complete",
    name: "bidComplete",
    InputComponent: CheckboxWithError,
    ignoreCaption: true,
    useSwitch: true,
    formControlLabelProps: { labelPlacement: "start" },
  },
  quoteFile: {
    InputComponent: FilePickerWithError,
    name: "quoteFile",
    label: "Select File (Optional)",
    helperText: "Provide a valid .pdf or .doc file",
    fullWidth: true,
    useFileWrapper: true,
  },
};
