import React from "react";

import EmptyState from "../../../EmptyState";

export const NoDataCell = ({ tableRow, tableColumn, getMessage, ...props }) => {
  return (
    <td {...props}>
      <EmptyState />
    </td>
  );
};

export const NoDataRow = ({ tableRow, ...props }) => {
  return <tr {...props} />;
};
