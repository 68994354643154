import React, { Fragment } from "react";

import { inputs } from "../../../../inputs/inputConfigs";
import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import { getFeeBasisFieldsWithRules } from "../createContractFields";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";
import projects from "../../../../inputs/inputConfigs/projects";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

const getFields = values => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.contractFeeBasis,
          },
          grid: { xs: 4 },
        },
        ...getFeeBasisFieldsWithRules(values, {}),
        { grid: { xs: 12 } },
        {
          input: {
            ...inputs.contractBillingTerm,
          },
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.projectFeeBillingDueDate,
          },
          grid: {
            xs: 4,
          },
        },
      ],
    },
  ],
});

const EditFeeSchedule = ({ onSubmit, values: initialValues, clientId }) => {
  const clientPricings = useV2DatacomponentResources(
    projects.clientPricing.dataComponentId,
    [],
    REQUEST_TYPES.LIST
  );

  return (
    <FormikForm initialValues={initialValues} onSubmit={onSubmit}>
      {formikProps => (
        <Fragment>
          <FormGrid
            fields={getFields({
              ...formikProps.values,
              clientId,
              clientPricing: clientPricings.find(
                ({ id }) => id == formikProps.values.clientPricingId
              ),
            })}
            {...formikProps}
          />
          <ActionButtons onSend={formikProps.handleSubmit} isModal />
        </Fragment>
      )}
    </FormikForm>
  );
};

export default EditFeeSchedule;
