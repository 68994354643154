import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getIsProjectClosed,
  ProjectContext,
} from "../../hooks/useIsProjectClosed";

export class InputWrapper extends Component {
  static propTypes = {
    InputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
      .isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    hideErrorMessage: PropTypes.bool,
    ignoreCaption: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static contextType = ProjectContext;

  get inputProps() {
    const {
      errors,
      InputComponent,
      dispatch,
      hasError,
      errorMessage,
      ignoreCaption,
      hideErrorMessage,
      FloatInputProps,
      ...inputProps
    } = this.props;
    return inputProps;
  }

  render() {
    const {
      hasError,
      errorMessage,
      ignoreCaption,
      InputComponent,
      hideErrorMessage,
    } = this.props;
    const inputProps = this.inputProps;
    const projectContext = this.context;
    const isProjectClosed = getIsProjectClosed(projectContext);
    return (
      <InputComponent
        {...inputProps}
        error={hasError}
        errorMessage={!hideErrorMessage ? errorMessage : ""}
        ignoreCaption={ignoreCaption}
        disabled={
          inputProps.disabled ||
          (isProjectClosed && !inputProps.ignoreLabeledText)
        }
      />
    );
  }
}

export default InputWrapper;
