import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { getLabel } from "../StandardSelect";
import MultiValue from "../AutocompleteSelect/MultiValue";

export const MultilineValueComponent = ({
  name,
  values,
  optionsMap,
  labelProperty,
  onChange,
}) => {
  const buildOnClick = (value, values) => evt => {
    const event = {
      ...evt,
      target: {
        name,
        value: values.filter(val => val !== value),
      },
    };
    onChange(event);
    evt.stopPropagation();
  };

  return (
    <Fragment>
      {values.map((value, i) => (
        <MultiValue
          key={i}
          onMouseDown={e => e.stopPropagation()}
          removeProps={{ onClick: buildOnClick(value, values) }}
        >
          {getLabel(labelProperty, optionsMap[value])}
        </MultiValue>
      ))}
    </Fragment>
  );
};

MultilineValueComponent.propTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  optionsMap: PropTypes.shape({}),
  labelProperty: PropTypes.string,
  onChange: PropTypes.func,
};
