import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import { performDeleteRequest } from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const dataComponentId = "RemitAddressesGrid";

export class DeleteRemitAddressContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataComponent: propTypes.dataComponent.isRequired,
    performDeleteRequest: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    showSnackNotificationAction: PropTypes.func.isRequired,
  };

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteRemitAddress = () => {
    const { id, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, id);
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation title={title} onDelete={this.deleteRemitAddress} />
    );
  }
}

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRemitAddressContainer);
