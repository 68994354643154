import React from "react";
import { useFormikContext } from "formik";
import _size from "lodash/size";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const BWModels = require("benjaminwest-models");
import PropTypes from "prop-types";

import useUserRole from "../../../../hooks/useUserRole";
import { roleCan } from "../../../../../utils/roleUtils";
import { ActionButtonWrapper, Button } from "../createContractFields";
import useActions from "../../../../hooks/useActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import { getDirtyFields } from "../../../../../utils/formValidationUtils";

const requiredFeeBasisFields = {
  Fixed: ["feeAmount"],
  Percentage: ["feePercent"],
  Limit: ["baseAmountLimit", "upperSpendLimit", "overLimitFee"],
  PO: ["numberOfPOs", "feesPerPurchaseOrder"],
  "Tiered Pricing": [],
};

const feeBasisFieldsFilled = (feeBasis, props) =>
  requiredFeeBasisFields[feeBasis].every(field => !!props[field]);

export const feeRevenueSharingFieldsFilled = props =>
  !props.revenueSharing ||
  (props.revenueSharing && !!props.revenueSharingProjectId);

// eslint-disable-next-line complexity
export const canGenerateFeeSchedule = props => {
  const Project = BWModels.loadSchema("Project");
  const {
    feeBasis,
    billingTerm,
    canChangeFeeInformation,
    feeScheduleGenerated,
    contractFiles,
    expectedFeeCount,
    feeBillingDueDate,
    feeBillingStartMonth,
    workScopes,
  } = props;

  const hasContractFiles = _size(contractFiles) > 0;
  return (
    workScopes.length &&
    canChangeFeeInformation &&
    !feeScheduleGenerated &&
    !!feeBasis &&
    feeBasisFieldsFilled(feeBasis, props) &&
    feeRevenueSharingFieldsFilled(props) &&
    hasContractFiles &&
    !!billingTerm &&
    billingTerm === "Monthly" &&
    !!expectedFeeCount &&
    !!feeBillingDueDate &&
    !!feeBillingStartMonth &&
    (feeBasis !== "Tiered Pricing" ||
      Project.tierPricingHelpers.getProjectTier(props, props.clientPricing))
  );
};

export const showEditFee = (
  canReenterFeeBasis,
  isGenerateFeeScheduleDisabled,
  values
) => {
  return (
    canReenterFeeBasis &&
    isGenerateFeeScheduleDisabled &&
    values.feeScheduleGenerated
  );
};

export const FeeScheduleAction = ({ clientId }) => {
  const role = useUserRole();
  const { values, setFieldValue } = useFormikContext();
  const actions = useActions({
    openModalDialog,
    closeModalDialog,
  });

  const canChangeFeeInformation = roleCan(
    role,
    "project-change-fee-information"
  );
  const canReenterFeeBasis = roleCan(role, "reenter-fee-basis");
  const isGenerateFeeScheduleDisabled = !canGenerateFeeSchedule({
    canChangeFeeInformation,
    ...values,
  });

  if (!values.id || (!canChangeFeeInformation && !canReenterFeeBasis)) {
    return null;
  }

  const handleEditSubmit = updatedValues => {
    actions.closeModalDialog();
    const dirtyFields = getDirtyFields({
      dirty: true,
      values: updatedValues,
      initialValues: values,
    });
    Object.keys(dirtyFields).forEach(field =>
      setFieldValue(field, dirtyFields[field])
    );
  };

  const handleEdit = () => {
    actions.openModalDialog(
      "Edit Fee Basis",
      "EditFeeSchedule",
      { values, onSubmit: handleEditSubmit, clientId },
      true,
      true,
      {
        width: "1200px",
      }
    );
  };

  const handleGenerateFeeSchedule = () =>
    setFieldValue("feeScheduleGenerated", true);
  return (
    <ActionButtonWrapper>
      {showEditFee(
        canReenterFeeBasis,
        isGenerateFeeScheduleDisabled,
        values
      ) ? (
        <Button onClick={handleEdit} text={"Edit"} disableIfProjectClosed />
      ) : (
        <Button
          onClick={handleGenerateFeeSchedule}
          icon={<AddCircleIcon />}
          text={"Generate Fees Schedule"}
          disabled={isGenerateFeeScheduleDisabled}
          disableIfProjectClosed
        />
      )}
    </ActionButtonWrapper>
  );
};
FeeScheduleAction.propTypes = {
  clientId: PropTypes.string,
};
