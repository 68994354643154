import React from "react";
import _map from "lodash/map";
import PropTypes from "prop-types";

import { Input } from "./components";

const ArrayText = ({
  placeholder,
  id,
  value,
  disabled,
  inputComponentProps: { itemLabelPath },
}) => {
  const labels = _map(value, itemLabelPath).join(", ");
  return (
    <Input
      placeholder={placeholder}
      id={id}
      value={labels}
      disabled={disabled}
      title={labels}
    />
  );
};

ArrayText.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
  inputComponentProps: PropTypes.shape({ itemLabelPath: PropTypes.string }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

ArrayText.defaultProps = {
  inputComponentProps: { itemLabelPath: "description" },
};

export default ArrayText;
