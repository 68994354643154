import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";

import AddressForm from "./AddressForm";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { cleanUpBody } from "../../../../../../actions/dataComponentActions";
import propTypes from "../../../../../../constants/propTypes";
import { useProcessSavingStatus } from "../../../../../hooks/useProcessSavingStatus";
import { useSavingStatus } from "../../../../../hooks/useSavingStatus";
import { LabeledTextContext } from "../../../../../../withPORevision/withLabeledTextOption";

const invalidAddress = ({
  name,
  values,
  address,
  setFieldError,
  showSnackNotificationAction,
}) => {
  if (values[name].find(({ name }) => name.trim() === address.name.trim())) {
    setFieldError("name", "Invalid Name");
    showSnackNotificationAction("Site names cannot be duplicated");
    return true;
  }
};

const cleanAddress = (hideContacts, address) => {
  if (hideContacts) {
    delete address.contacts;
  }
};

const closeDialogWhenEqual = (original, current, closeModalDialog) => {
  if (_isEqual(original, current)) {
    closeModalDialog();
  }
};

const closeDialogWhenCreating = ({ id }, closeModalDialog) =>
  !id && closeModalDialog();

const CreateEditAddressContainer = ({
  formikArrayHelpers,
  hideContacts,
  closeModalDialog,
  showSnackNotificationAction,
  address,
  index,
  submitText,
  descriptionField,
}) => {
  useProcessSavingStatus({ onSuccess: closeModalDialog });
  const { loading } = useSavingStatus();

  const handleCreateAddress = (address, { setFieldError }) => {
    const {
      name,
      form: { values },
    } = formikArrayHelpers;

    if (
      invalidAddress({
        name,
        values,
        address,
        setFieldError,
        showSnackNotificationAction,
      })
    ) {
      return;
    }

    cleanAddress(hideContacts, address);
    formikArrayHelpers.push(address);
    closeDialogWhenCreating(values, closeModalDialog);
  };

  const handleEditAddress = (address, { setFieldError }) => {
    const {
      name,
      form: { values },
    } = formikArrayHelpers;

    const addressIndex = values[name].findIndex(
      ({ name }) => name.trim() === address.name.trim()
    );
    if (addressIndex !== -1 && addressIndex !== index) {
      setFieldError("name", "Invalid Name");
      showSnackNotificationAction("Site names cannot be duplicated");
      return true;
    }

    cleanAddress(hideContacts, address);
    const cleanedBody = cleanUpBody(address);
    formikArrayHelpers.replace(index, cleanUpBody(address));
    closeDialogWhenEqual(
      values[name][addressIndex],
      cleanedBody,
      closeModalDialog
    );
    closeDialogWhenCreating(values, closeModalDialog);
  };

  const isEditing = typeof index !== "undefined";
  return (
    <LabeledTextContext.Provider value={loading}>
      <AddressForm
        onSubmit={isEditing ? handleEditAddress : handleCreateAddress}
        isEditing={isEditing}
        address={address}
        submitText={submitText}
        descriptionField={descriptionField}
        showSnackNotificationAction={showSnackNotificationAction}
        hideContacts={hideContacts}
      />
    </LabeledTextContext.Provider>
  );
};
CreateEditAddressContainer.propTypes = {
  closeModalDialog: PropTypes.func,
  showSnackNotificationAction: PropTypes.func,
  formikArrayHelpers: PropTypes.shape({}),
  address: propTypes.projectAddress,
  index: PropTypes.number,
  submitText: PropTypes.string,
  descriptionField: PropTypes.bool,
  hideContacts: PropTypes.bool,
};

const mapDispatchToProps = {
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(null, mapDispatchToProps)(CreateEditAddressContainer);
