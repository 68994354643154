import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const StyledMenu = styled.div`
  padding-top: 22px;
  padding-right: 16px;
`;

const MenuIcons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledIconButton = styled(({ iconButton, ...props }) => (
  <IconButton {...props} />
))`
  color: rgb(0, 0, 0, 0.54);
  height: 24px;
  width: 24px;
  margin: 0 8px;
  padding: 0;
  &:hover {
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0);
  }
`;
StyledIconButton.displayName = "StyledIconButton";

const Menu = props => {
  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <StyledMenu>
      <MenuIcons>{children}</MenuIcons>
    </StyledMenu>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Menu;
