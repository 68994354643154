import styled from "styled-components";
import React from "react";
import RegularText from "../../../../ui/Typography/RegularText";

export const Value = styled(({ value, ...props }) => (
  <RegularText {...props} as="div" />
))`
  font-weight: normal;
  cursor: initial;
`;

export const Wrapper = styled(({ withHoverState, canDelete, ...props }) => (
  <div {...props} />
))`
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 14px 24px;
  ${({ onClick }) =>
    onClick &&
    `
      &:hover {
        background: #f5f5f5;
        cursor: pointer;
      }
  `}
  ${Value} {
    ${({ onClick }) =>
      onClick &&
      `
        font-weight: 600;
        line-height: 16px;
        color: #57abff;
        cursor: pointer;
  `}
  }
`;

export const Label = styled(RegularText)`
  color: #979797;
  margin-right: 4px;
`;

export const Anchor = styled.a`
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
`;
