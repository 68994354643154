import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import pluralize from "pluralize";
import styled from "styled-components";
import _templateSettings from "lodash/templateSettings";
import _template from "lodash/template";

import {
  BWGridLocal,
  GridHeader,
  GridTable,
  withSelectedRows,
} from "../../../ui/BWGrid";
import { WithSelectedRowsContext } from "../../../ui/BWGrid/withSelectedRows";
import propTypes from "../../../../constants/propTypes";
import Paper from "../../../mui/core/Paper";
import { getOptionsFromSchema } from "../../../inputs/utils";
import LinesEllipsis from "../../../ui/LinesEllipsis";
import { RelationColumn } from "../../Settings/Terms/TermPage";

const columns = ["content", "relation"];

const VerticalCenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const getColumnsOptions = () => ({
  content: {
    title: "Description",
    fill: true,
    filter: true,
    render: ({ content }) => {
      return (
        content && (
          <VerticalCenterWrapper>
            <LinesEllipsis>{content}</LinesEllipsis>
          </VerticalCenterWrapper>
        )
      );
    },
  },
  relation: {
    title: "Relation",
    filter: "select",
    sortingEnabled: false,
    filterOptions: {
      options: getOptionsFromSchema("Term", "relation")(),
      operator: "ilike",
    },
    render: RelationColumn,
  },
});

const getTitle = count => {
  return `${count} Project ${pluralize("Term", count)}`;
};

const getRelationColumn = ({ forRfq, forPo }) => {
  const options = [];
  if (forPo) {
    options.push("PO");
  }
  if (forRfq) {
    options.push("RFQ");
  }
  return options.join();
};

const TermPage = ({ terms, loading, project }) => {
  const columnOptions = useMemo(getColumnsOptions, []);
  _templateSettings.interpolate = /{{([\s\S]+?)}}/g;

  const rows = useMemo(
    () =>
      terms.map(term => ({
        ...term,
        content: _template(term.content)({
          local_office: _get(project, "office.name"),
        }),
        relation: getRelationColumn(term),
      })),
    [project, terms]
  );

  const { totalRows } = useContext(WithSelectedRowsContext);
  return (
    <Paper>
      <BWGridLocal
        id="project-terms"
        rows={rows}
        gridConfig={{ pageSize: 10, totalRows: totalRows }}
        emptyStateText="No Terms"
        revisionReference="terms"
        isLoading={loading}
      >
        <GridHeader headerText={getTitle(totalRows)} />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
    </Paper>
  );
};

TermPage.defaultProps = {
  terms: [],
};
TermPage.propTypes = {
  terms: PropTypes.arrayOf(propTypes.term),
  loading: PropTypes.bool,
  project: propTypes.project,
};
export default withSelectedRows(TermPage);
