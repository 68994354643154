import React, { useContext, useMemo } from "react";

import { Grid as DXGrid } from "@devexpress/dx-react-grid-material-ui";
import { RowDetailState } from "@devexpress/dx-react-grid";

import PropTypes from "prop-types";
import { PORevisionContext } from "../../../../../withPORevision";

function buildGrid(fixedRows) {
  const DXGridWithRevisedItems = ({
    children,
    tableComponents,
    rows,
    ...props
  }) => {
    const allRows = useMemo(() => [...rows, ...fixedRows], [rows]);

    return (
      <DXGrid {...props} rows={allRows}>
        {children}
        <RowDetailState
          key="rowDetailState"
          expandedRowIds={allRows.map((_, index) => index)}
        />
      </DXGrid>
    );
  };

  DXGridWithRevisedItems.propTypes = {
    tableComponents: PropTypes.shape({
      DetailRowComponent: PropTypes.func,
      DetailCellComponent: PropTypes.func,
      WrapperDetailRow: PropTypes.func,
    }),
  };

  return DXGridWithRevisedItems;
}

function useDXGridWithRevisedItems() {
  const { getDeletedItems } = useContext(PORevisionContext);
  const deletedItems = useMemo(() => getDeletedItems("specs"), [
    getDeletedItems,
  ]);
  return useMemo(() => buildGrid(deletedItems), [deletedItems]);
}

export default useDXGridWithRevisedItems;
