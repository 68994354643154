import React from "react";
import styled from "styled-components";
import CheckCircle from "@material-ui/icons/CheckCircle";

export const StyledSelectorCheckbox = styled.div`
  padding-top: 5px;
`;

const Selector = () => {
  return (
    <StyledSelectorCheckbox>
      <CheckCircle />
    </StyledSelectorCheckbox>
  );
};

export default Selector;
