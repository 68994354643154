import {
  fetchDataFailed,
  fetchDataRequest,
  fetchDataSuccess,
  initDataComponent,
  performCreateRequest,
  performUpdateRequest,
} from "./dataComponentActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { BaseModel } from "@blue-chip/core";
import { getResourcesV2 } from "../utils/bluechipUtils";

export const testQueryReportDataComponentId = "dataComponentTestQueryReport";
export const queryReportDataComponentId = "dataComponentQueryReport";

const requestConfigs = {
  UPDATE: {
    requestType: REQUEST_TYPES.UPDATE,
    requestAction: performUpdateRequest,
    getParams: queryReport => [queryReport.id, queryReport],
  },
  CREATE: {
    requestType: REQUEST_TYPES.CREATE,
    requestAction: performCreateRequest,
    getParams: queryReport => [queryReport],
  },
};

export const testQueryReport = (queryReport, formikProps) => {
  return async (dispatch, getState) => {
    try {
      formikProps.setSubmitting(false);
      formikProps.setStatus();

      await dispatch(
        initDataComponent(
          testQueryReportDataComponentId,
          BaseModel,
          [],
          "test-query-report",
          false,
          "v2"
        )
      );
      await dispatch(
        performCreateRequest(testQueryReportDataComponentId, queryReport)
      );

      const { rowCount, query } = getResourcesV2(
        {
          dataComponentId: testQueryReportDataComponentId,
          requestType: "create",
        },
        getState()
      );
      formikProps.setFieldError(
        "query",
        `${query} - execution completed successfully returning ${rowCount} row(s)`
      );
    } catch (error) {
      formikProps.setSubmitting(false);
      formikProps.setStatus("submitted");
      const [{ detail }] = error.data.errors;
      formikProps.setFieldError("query", detail);
      throw error;
    }
  };
};

export const testAndSaveQueryReport = (queryReport, formikProps) => {
  return async dispatch => {
    const { requestType, requestAction, getParams } = queryReport.id
      ? requestConfigs.UPDATE
      : requestConfigs.CREATE;

    dispatch(fetchDataRequest(queryReportDataComponentId, requestType));
    try {
      await dispatch(testQueryReport(queryReport, formikProps));

      const response = await dispatch(
        requestAction(
          ...[queryReportDataComponentId, ...getParams(queryReport)]
        )
      );

      dispatch(
        fetchDataSuccess(queryReportDataComponentId, requestType, response)
      );
    } catch (error) {
      dispatch(fetchDataFailed(queryReportDataComponentId, requestType, error));
      throw error;
    }
  };
};
