import TextInputWithError from "../TextInputWithError";
import FilePickerWithError from "../FilePickerWithError";
import DecimalInput from "../DecimalInput";

export default {
  unitsNeeded: {
    InputComponent: TextInputWithError,
    label: "Units Needed",
    name: "unitsNeeded",
    fullWidth: true,
    type: "number",
  },
  unitsPerCase: {
    InputComponent: TextInputWithError,
    label: "Units Per Case",
    name: "unitsPerCase",
    fullWidth: true,
    type: "number",
  },
  roomCount: {
    InputComponent: TextInputWithError,
    name: "roomCount",
    label: "Room Quantity",
    fullWidth: true,
    autoFocus: true,
    type: "number",
  },
  perRoom: {
    InputComponent: TextInputWithError,
    name: "perRoom",
    label: "Per Room",
    fullWidth: true,
    autoFocus: true,
    hideErrorMessage: true,
    type: "number",
  },
  par: {
    InputComponent: TextInputWithError,
    name: "par",
    label: "PAR",
    fullWidth: true,
    autoFocus: true,
    hideErrorMessage: true,
    InputProps: {
      inputComponent: DecimalInput,
    },
    inputProps: {
      decimalScale: 2,
    },
  },
  specFile: {
    InputComponent: FilePickerWithError,
    name: "file",
    label: "Select Preview File",
    fullWidth: true,
  },
  specDetailPreview: {
    InputComponent: FilePickerWithError,
    name: "preview",
    label: "Select Preview File",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {
      accept: "image/jpeg,image/png,.gif",
      isForm: true,
    },
  },
  specDetailColor: {
    InputComponent: TextInputWithError,
    name: "color",
    label: "Color",
    fullWidth: true,
  },
  specDetailPattern: {
    InputComponent: TextInputWithError,
    name: "pattern",
    label: "Pattern",
    fullWidth: true,
  },
  specDetailType: {
    InputComponent: TextInputWithError,
    name: "type",
    label: "Type",
    fullWidth: true,
  },
  specDetailCertificate: {
    InputComponent: TextInputWithError,
    name: "certificate",
    label: "Certificate",
    fullWidth: true,
  },
  specDetailFlameCare: {
    InputComponent: TextInputWithError,
    name: "flameCare",
    label: "Flame & Care",
    fullWidth: true,
  },
};
