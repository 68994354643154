import _get from "lodash/get";
import _startCase from "lodash/startCase";

import { Grid as DXGrid } from "@devexpress/dx-react-grid-material-ui";

import buildEmptyTypeProvider from "./filterDataTypeProviders/EmptyTypeProvider";
import {
  BWGridHeaderSortLabel,
  BWTable,
} from "../BWGrid/gridInternalComponents";
import {
  Cell,
  FilterCell,
  HeadCell,
  HeadRow,
  HeadBandCell,
  Row,
  NoDataCell,
  NoDataRow,
} from "./utilComponents/tableComponents";
import { withClickableRow } from "./utilComponents/tableComponents/Row";
import { BWTableContainer, BWSelectHeader } from "../BWGrid/BWGrid";
import DefaultPagingPanelTemplate from "./utilComponents/internalComponents/DefaultPagingPanelTemplate";
import Paper from "@material-ui/core/Paper";

const defaultTableComponents = {
  DXGrid,
  TableComponent: BWTable,
  RowComponent: Row,
  CellComponent: Cell,
  FilterCellComponent: FilterCell,
  HeaderRowComponent: HeadRow,
  HeaderCellComponent: HeadCell,
  HeaderBandCellComponent: HeadBandCell,
  TableContainerComponent: BWTableContainer,
  SelectHeader: BWSelectHeader,
  PagingPanelTemplate: DefaultPagingPanelTemplate,
  HeaderSortLabelComponent: BWGridHeaderSortLabel,
  GridHeader: () => null,
  GridWrapper: Paper,
  NoDataRowComponent: NoDataRow,
  NoDataCellComponent: NoDataCell,
};

export const buildTableComponents = (customTableComponents, rowProps) => () => {
  const tableComponents = {
    ...defaultTableComponents,
    ...customTableComponents,
  };

  tableComponents.RowComponent = withClickableRow(
    rowProps,
    tableComponents.RowComponent
  );

  return tableComponents;
};

export const normalizeColumns = columns => () => {
  return columns.map(({ type, ...srcColumn }) => {
    switch (type) {
      case "rowMenu": {
        return {
          name: "rowMenu",
          title: " ",
          width: "80px",
          ...srcColumn,
        };
      }
      default: {
        return {
          getCellValue: row =>
            _get(row, srcColumn.name, srcColumn.defaultValue),
          title: _startCase(srcColumn.name),
          ...srcColumn,
        };
      }
    }
  });
};

export const normalizeFilteringDataTypeProviders = (
  columns,
  filteringDataTypeProviders
) => () => {
  const columnsNoFilter = columns.reduce((columns, currentColumn) => {
    if (!currentColumn.filter) columns.push(currentColumn.name);
    return columns;
  }, []);
  return [
    ...filteringDataTypeProviders,
    buildEmptyTypeProvider(columnsNoFilter),
  ];
};
