import BaseText from "./BaseText";
import styled from "styled-components";

export default styled(BaseText)`
  margin: 0;
  padding: 0;
  font-family: Domine, serif;
  line-height: 40px;
  color: rgb(28, 61, 83);
  font-size: 34px;
  font-weight: normal;
`;
