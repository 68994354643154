import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Select from "react-select";
import _noop from "lodash/noop";

import { removeControlCharacters } from "../../utils";

//Review Note: Since the internal component wasn't receiving the "className" prop
//The styled component wrapper was doing nothing.
export const DropdownIndicator = () => <ArrowDropDown />;

export const getRequiredStyle = ({ required, emphasizeRequired }) => {
  if (!required) return "";
  const emphisizeRequiredStyle = `
    color: red;
    font-weight: bold;
    font-size: large;
  `;
  return `
    label:after {
      content: " *";
      ${emphasizeRequired ? emphisizeRequiredStyle : ""}
    }
  `;
};

export const onInputChangeWrapper = (
  onInputChange = _noop,
  setInputValue
) => rawValue => {
  const value = removeControlCharacters(rawValue, true);
  onInputChange(value);
  setInputValue(value);
};

//Review Note: Since the wrapped component is not a build-in one (like styled.div)
//The function doesn't take the ref prop propertly, so you I have to rename the prop
//passed by the parent component and provide it as selectRef
export const StyleSelected = styled(
  ({ hide, selectRef, emphasizeRequired, ...props }) => {
    const [inputValue, setInputValue] = useState();
    return (
      <Select
        ref={selectRef}
        {...props}
        inputValue={inputValue}
        onInputChange={onInputChangeWrapper(props.onInputChange, setInputValue)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: styles => ({ ...styles, zIndex: 99999999 }),
          input: styles => ({ ...styles, position: "absolute" }),
          menu: styles => ({
            ...styles,
            width: "fit-content",
          }),
        }}
      />
    );
  }
)`
  display: ${({ hide }) => (hide ? "none" : "auto")};
  ${props => getRequiredStyle(props)}
`;
