import Reports from "./ReportsContainer";
import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import { connect } from "react-redux";

export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  return {
    projectId,
    reportsLoader: state.reports.loaders,
  };
};
const mapDispatchToProps = {
  generateAndDownloadReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
