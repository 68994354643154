import { mapJoiErrorToCompatibleError } from "../../../../utils/formValidationUtils";

export const buildSaveNewObj = ({
  dataComponentId,
  performCreateRequest,
  labelProperty,
}) => value => {
  performCreateRequest(dataComponentId, { [labelProperty]: value });
};

const submit = ({
  isMulti,
  value,
  inputValue,
  saveNewObj,
  handleChange,
  handleInputChange,
  validate,
  showSnackNotificationAction,
}) => {
  const newObj = { value: inputValue, label: inputValue };
  const error = validate ? validate(inputValue) : undefined;
  if (error) {
    const mapError = mapJoiErrorToCompatibleError(error);
    showSnackNotificationAction(
      Object.entries(mapError)
        .map(([, value]) => value)
        .join(", ")
    );
    return;
  }
  saveNewObj(inputValue);
  if (isMulti) {
    value.push(newObj);
  }
  handleChange(isMulti ? value : newObj);
  handleInputChange("");
};

export const handleKeyDown = ({
  event,
  value,
  isMulti,
  inputValue,
  options,
  saveNewObj,
  handleChange,
  handleInputChange,
  validate,
  showSnackNotificationAction,
}) => {
  if (event.key === "Enter" && options.length === 0) {
    submit({
      isMulti,
      value,
      inputValue,
      saveNewObj,
      handleChange,
      handleInputChange,
      validate,
      showSnackNotificationAction,
    });
  }
};
