import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";

import ClosingSchedule from "./ClosingSchedule";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { pushWithReturnUrl } from "../../../../actions/navigationActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";

export const dataComponentId = "AccountingClosingScheduleGrid";
export const editDateDataComponentId = "EditDateDataComponentId";
export const usersDataComponentId = "UsersDataComponentId";

export const mapStateToProps = state => {
  const userDataComponent = getDataComponentFlattenedRequestState(
    usersDataComponentId,
    state
  );
  const users = getBluechipResources(userDataComponent, state);
  return {
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    editDateDataComponentId,
    usersDataComponentId,
    users: users ? users : [],
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  generateAndDownloadReport,
  performRetrieveListRequest,
  push,
  openModalDialog,
  setAutoSaveComponentId,
  pushWithReturnUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosingSchedule);
