import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";

const InputLoader = ({ ignoreCaption, ...props }) => (
  <Loader {...props} height={ignoreCaption ? "48px" : "66px"} />
);

InputLoader.propTypes = {
  ...Loader.propTypes,
  ignoreCaption: PropTypes.bool,
};

export default InputLoader;
