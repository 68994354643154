import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateAuthenticatedUser } from "../../../../actions/authActions";

import FavoriteControl from "./FavoriteControl";

export const mapStateToProps = ({ auth }, { clientId }) => {
  const { favoriteClientIds = [], loadingAuthentication } = auth;
  return {
    favoriteClientIds,
    isStarred: favoriteClientIds.includes(clientId),
    loading: loadingAuthentication,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ updateAuthenticatedUser }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteControl);
