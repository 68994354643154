import currencyFormatter from "../../../../utils/currencyFormatter";

export const format = value => currencyFormatter.format(value || 0);
export const friendlyFormat = value => {
  if (Math.abs(value) > 999999) {
    return `$${Math.sign(value) * (Math.abs(value) / 1000000).toFixed(1)}M`;
  }
  return Math.abs(value) > 999
    ? `$${Math.sign(value) * (Math.abs(value) / 1000).toFixed(1)}k`
    : `$${Math.sign(value) * Math.abs(value)}`;
};
