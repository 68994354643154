import React from "react";
import { FormControl } from "@material-ui/core";

import { inputs } from "../../../inputs/inputConfigs";
import InputText from "../../../ui/Typography/InputText";
import RegularTextSmall from "../../../ui/Typography/RegularTextSmall";
import { CancelledCheckbox } from "./CancelledCheckbox";

export const getFilteredBy = (attribute, id) => arr => {
  if (!id || !arr) return [];
  return arr.filter(item => item[attribute] === id);
};

export const isStatusOptionDisabled = canCloseProjects => ({ name }) =>
  name == "Closed" && !canCloseProjects;

export default ({
  brandId,
  flagId,
  clientId,
  selectedProperty,
  isCancelled,
  cancelledBy,
  setFieldValue,
  handleStatusChange,
  canCloseProjects,
}) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.propertyId,
              APIOptions: {
                ...inputs.propertyId.APIOptions,
                rootFilters: {
                  $where: { "entity.clientId": clientId },
                },
              },
            },
            grid: { xs: 4 },
          },
          {
            element: selectedProperty && (
              <FormControl>
                <RegularTextSmall>Entity</RegularTextSmall>
                <InputText>{selectedProperty?.entity.name}</InputText>
              </FormControl>
            ),
            grid: { xs: 4 },
          },
          {
            element: (
              <CancelledCheckbox
                setFieldValue={setFieldValue}
                isCancelled={isCancelled}
                cancelledBy={cancelledBy}
              />
            ),
            grid: { xs: 4 },
          },
        ],
      },
      {
        items: [
          {
            input: {
              ...inputs.projectStatus,
              isOptionDisabled: isStatusOptionDisabled(canCloseProjects),
              handleChange: handleStatusChange,
            },
            grid: { xs: 4 },
          },
          { input: inputs.brandId, grid: { xs: 4 } },
          { grid: { xs: 4 } },
          {
            input: {
              ...inputs.flagId,
              filter: getFilteredBy("brandId", brandId),
            },
            grid: { xs: 4 },
          },
          {
            input: {
              ...inputs.programId,
              filter: getFilteredBy("flagId", flagId),
            },
            grid: { xs: 4 },
          },
        ],
      },
    ],
  };
};
