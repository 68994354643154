import inputs from "../../../inputs/inputConfigs/invoice";
import { withOverwrittedLabeledContext } from "../../../inputs/inputConfigs/invoice";
import TextInputWithError from "../../../inputs/TextInputWithError";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.invoiceNumber,
            InputComponent: withOverwrittedLabeledContext(TextInputWithError),
          },
          grid: {
            xs: 4,
          },
        },
      ],
    },
  ],
};
