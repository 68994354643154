import React from "react";
import PropTypes from "prop-types";
import { useRef, useCallback, useContext, useEffect } from "react";
import FloatInput, {
  FloatInput as RawFloatInput,
} from "../../inputs/FloatInput";
import BWCell from "./gridInternalComponents/BWCell";
import { WithEditableCellContext } from "./withEditableCellController";
import { EditableWrapper } from "./components";

const buildHandleChange = (
  beforeSave,
  afterSave = () => {},
  columnName,
  initialValue,
  row,
  onChange = () => {}
) => value => {
  const parsedValue = beforeSave ? beforeSave(value, columnName, row) : value;
  if (value === initialValue) return;
  onChange(parsedValue, { columnName, row });
  afterSave(parsedValue, { columnName, row });
};

export const EditableLocalCell = ({
  input,
  children,
  dataComponentId,
  performCreateRequest,
  performUpdateRequest,
  setAutoSaveComponentId,
  ...props
}) => {
  const { column, row, tableRow } = props;
  const { editableOptions = {} } = column;
  const columnId = column.columnId;
  const rowId = tableRow.rowId;

  const { addCell, removeCell, setCurrentCell, currentCell } = useContext(
    WithEditableCellContext
  );

  const { beforeSave, afterSave, formatValue, onChange } = editableOptions;
  const anchorEl = useRef(null);
  const isEditable =
    currentCell.columnId === columnId && currentCell.rowId === rowId;

  useEffect(() => {
    addCell(rowId, columnId);
    return () => removeCell(rowId, columnId);
  }, [rowId, columnId, addCell, removeCell]);

  const startEditMode = useCallback(() => {
    setCurrentCell({ rowId, columnId });
  }, [setCurrentCell, rowId, columnId]);

  const initialValue = formatValue ? formatValue(row) : row[column.name];
  const handleChange = useCallback(
    buildHandleChange(
      beforeSave,
      afterSave,
      column.name,
      initialValue,
      row,
      onChange
    ),
    []
  );

  return (
    <BWCell {...props}>
      <EditableWrapper ref={anchorEl} onClick={startEditMode}>
        {children}
      </EditableWrapper>
      <FloatInput
        input={input}
        onChange={handleChange}
        onClose={handleChange}
        autoFocus
        name={column.name}
        value={initialValue}
        visible={isEditable}
        hasError={false}
        anchorEl={() => anchorEl.current}
      />
    </BWCell>
  );
};

EditableLocalCell.propTypes = {
  input: RawFloatInput.propTypes.input,
  row: PropTypes.shape({}).isRequired,
};

export default EditableLocalCell;

export function editableCellCreator(rowInputs, isEditable) {
  return function CellWrapper({ children, ...props }) {
    const { column, tableRow } = props;
    if (!isEditable(column, tableRow))
      return <BWCell {...props}>{children}</BWCell>;

    const input = rowInputs[column.name];
    return (
      <EditableLocalCell {...props} input={input}>
        {children}
      </EditableLocalCell>
    );
  };
}
