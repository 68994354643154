import { inputs } from "../../../../inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.noteTrackingNumber,
            label: "Tracking Number 🚧",
          },
          grid: { xs: 12 },
        },
        { input: inputs.noteCarrierName, grid: { xs: 12 } },
        { input: inputs.noteHeadline, grid: { xs: 12 } },
        {
          input: { ...inputs.noteDetail, label: "Shipment Notes" },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
