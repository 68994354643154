import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BaseModel } from "@blue-chip/core";
import _get from "lodash/get";

import { Paper } from "../../../mui/core";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { ColumnItem } from "../../ProjectDetail/Accounting/Fees/FeeSummary/FeeSummary";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";

export const shippingPaymentSummaryDataComponentId =
  "billing-shipping-payment-summary";

const Summary = ({
  shippingPaymentSummary,
  initDataComponent,
  performRetrieveListRequest,
}) => {
  useEffect(() => {
    initDataComponent(
      shippingPaymentSummaryDataComponentId,
      BaseModel,
      [],
      "shipping-payments-summary",
      false,
      "v2"
    );
    performRetrieveListRequest(shippingPaymentSummaryDataComponentId);
  }, [initDataComponent, performRetrieveListRequest]);

  const currencyFormatter = useCurrencyFormatter();
  const values = [
    [
      {
        title: "Shipments Unbilled",
        value: shippingPaymentSummary.unbilledShippingPayments,
      },
      {
        title: "Total Shipments Unbilled",
        value: currencyFormatter.format(
          shippingPaymentSummary.unbilledShippingPaymentsAmount
        ),
      },
    ],
  ];

  return (
    <Paper>
      <div>
        {values.map((row, key) => (
          <ColumnItem row={row} key={key} />
        ))}
      </div>
    </Paper>
  );
};

Summary.propTypes = {
  shippingPaymentSummary: PropTypes.shape({
    unbilledShippingPayments: PropTypes.number,
    unbilledShippingPaymentsAmount: PropTypes.number,
  }),
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const summaryDataComponent = getDataComponent(
    shippingPaymentSummaryDataComponentId,
    state
  );
  const [shippingPaymentSummary = {}] =
    getResourcesV2(summaryDataComponent, state, REQUEST_TYPES.LIST) || [];

  return {
    shippingPaymentSummary,
    loading: _get(
      summaryDataComponent,
      `requestState.${REQUEST_TYPES.LIST}.loading`
    ),
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
