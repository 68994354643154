import React, { Component } from "react";
import PropTypes from "prop-types";
import AddCircle from "@material-ui/icons/AddCircle";

import Paper from "../../mui/core/Paper";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";

import BulkActions from "./BulkActions";
import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";

const columns = [
  "name",
  "numProjects",
  "location.address",
  "location.city",
  "location.state",
  "location.zip",
  "federalId",
  "termsCount",
];
const columnOptions = {
  name: { fill: true },
  numProjects: { title: "Projects", sortingEnabled: false },
  "location.address": { title: "Address" },
  "location.city": { title: "City" },
  "location.state": { title: "State" },
  "location.zip": { title: "Zip Code" },
  federalId: { title: "Federal ID" },
  termsCount: { title: "Terms" },
};

export default class EntitiesPage extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    dataComponent: PropTypes.object.isRequired,
    openModalDialog: PropTypes.func,
    refreshEntitiesDataComponent: PropTypes.func,
    onRowClick: PropTypes.func,
    isModalOpen: PropTypes.bool,
    initDataComponent: PropTypes.func,
    performUpdateRequest: PropTypes.func,
    closeModalDialog: PropTypes.func,
  };

  _handleModalClosed = () => {
    this.props.refreshEntitiesDataComponent();
  };

  _modalHasClosed = prevProps => {
    return !this.props.isModalOpen && prevProps.isModalOpen;
  };

  componentDidUpdate(prevProps) {
    if (this._modalHasClosed(prevProps)) {
      this._handleModalClosed();
    }

    const {
      updateTermsDataComponent: prevUpdateTermsDataComponent,
    } = prevProps;
    const { updateTermsDataComponent, closeModalDialog } = this.props;

    processUpdateRequestStatus(
      prevUpdateTermsDataComponent,
      updateTermsDataComponent,
      {
        onSuccess: () => {
          closeModalDialog();
          this._handleModalClosed();
        },
      }
    );
  }

  handleAddButtonClick = () => {
    const { client } = this.props;
    this.props.openModalDialog(
      "Create Entity",
      "CreateEntityContainer",
      { client },
      false
    );
  };

  handleEditOptionClick = ({ id }) => {
    const { client } = this.props;
    this.props.openModalDialog(
      "Edit Entity",
      "EditEntityContainer",
      { client, id },
      false
    );
  };

  handleDeleteOptionClick = ({ id: entityId, name: title }) => {
    const { clientId } = this.props;
    this.props.openModalDialog(
      `Delete ${title}`,
      "DeleteEntity",
      { clientId, entityId, title },
      true,
      false
    );
  };

  handleOpenUpdateTermsModal = () => {
    const { clientId } = this.props;
    this.props.openModalDialog(
      `Update Entity Terms`,
      "BulkUpdateTerms",
      { clientId, isGlobal: false },
      false,
      false
    );
  };

  handleUpdateEntityTerms = entity => {
    this.props.openModalDialog(
      `Update ${entity.name} Terms`,
      "BulkUpdateTerms",
      { entity },
      true,
      true
    );
  };

  get rowMenu() {
    return [
      {
        text: "Edit",
        onClick: this.handleEditOptionClick,
      },
      { text: "Sync All Terms", onClick: this.handleUpdateEntityTerms },
      { separator: true },
      { text: "Delete", onClick: this.handleDeleteOptionClick },
    ];
  }
  render() {
    const { dataComponent, clientId, onRowClick } = this.props;
    return (
      <Paper>
        <BWGridAPI
          {...this.props}
          defaultSorting={[
            {
              columnName: "name",
              direction: "asc",
            },
          ]}
          apiFilters={{
            rootFilters: {
              $where: {
                clientId,
              },
            },
          }}
        >
          <GridHeader
            headerText={`${dataComponent.totalRows} Entities`}
            actions={[
              {
                text: "ADD ENTITY",
                icon: <AddCircle />,
                handler: this.handleAddButtonClick,
              },
            ]}
            headerOverride={
              <BulkActions
                onOpenUpdateTermsModal={this.handleOpenUpdateTermsModal}
              />
            }
          />
          <GridTable
            columns={columns}
            columnOptions={columnOptions}
            rowMenu={this.rowMenu}
            onClick={onRowClick}
          />
        </BWGridAPI>
      </Paper>
    );
  }
}
