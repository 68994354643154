import { handleSubmitEvent } from "../StandardSelect";
import { getParentItemSelectAllProps } from "./components";

const parseParentEventValue = ({
  event,
  value,
  groupingProperty,
  parentItem,
  valueProperty,
}) => {
  const mappedParentItemOptions = parentItem[groupingProperty].map(
    option => option[valueProperty]
  );
  event.target.value = value
    .slice(0, value.length - 1)
    .filter(option => mappedParentItemOptions.indexOf(option) === -1);
  const { isAllSelected, indeterminate } = getParentItemSelectAllProps(
    value,
    parentItem,
    groupingProperty,
    valueProperty,
    new Set(value)
  );
  const appendArray =
    isAllSelected || indeterminate
      ? []
      : parentItem[groupingProperty].map(option => option[valueProperty]);
  event.target.value.push(...appendArray);
};

export const parseEventValue = ({
  event,
  valueProperty,
  options,
  values,
  initialOptionsMap,
  groupingProperty,
}) => {
  const value = event.target.value;
  const selectedValue = value[value.length - 1];
  if (selectedValue === "all") {
    const empty = options.length === values.length || values.length !== 0;
    event.target.value = empty
      ? []
      : options.map(option => option[valueProperty]);
  }

  if (selectedValue && selectedValue.startsWith("parent_")) {
    const parentItem = initialOptionsMap[selectedValue.slice("parent_".length)];
    parseParentEventValue({
      event,
      value,
      groupingProperty,
      parentItem,
      valueProperty,
      selectedValue,
    });
  }
};

export const createOnChange = (
  name,
  submitData,
  options,
  onChange,
  handleChange,
  nullAsEmpty,
  values,
  valueProperty,
  initialOptionsMap,
  groupingProperty
) => event => {
  if (submitData) {
    handleSubmitEvent(event, name, options, onChange, nullAsEmpty);
    return;
  }
  parseEventValue({
    event,
    valueProperty,
    options,
    values,
    initialOptionsMap,
    groupingProperty,
  });
  onChange(event);
  handleChange && handleChange(event);
};
