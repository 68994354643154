import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import ImportFromFile from "./ImportFromFile";
import propTypes from "../../../../../constants/propTypes";
import {
  performImportRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { processImportRequestStatus } from "../../../../../utils/dataComponentUtils";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import FormikForm from "../../../../forms/FormikForm";
import {
  codeToErrorInfoMap,
  handleRequestError,
} from "../../../../../utils/formValidationUtils";

function mapError(errors, errorMeta, message) {
  const [, line, field] = errorMeta.source.match(/\[(\d+)\]\.(\w*)/);
  errors.file = `Item ${line} (${field}): ${message}`;
}

export const processRequestStatus = (
  formikActions,
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  showSnackNotificationAction
) => {
  processImportRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponent.dataComponentId, true);
    },
    onError: ({ response, data }) => {
      if (response.status === -1) {
        return showSnackNotificationAction("Failed request");
      }
      const errors = data.errors.reduce(
        (errors, error) => {
          const source =
            error.status === 500 || error.global || error.code === "unique"
              ? errors.global
              : errors.form;
          source.push(error);
          return errors;
        },
        {
          global: [],
          form: [],
        }
      );
      if (errors.global.length > 0) {
        return errors.global.forEach(error => {
          showSnackNotificationAction(
            _get(
              codeToErrorInfoMap,
              `${error.code}.title`,
              error.title || error.detail || "Unknown field error"
            )
          );
        });
      }
      handleRequestError({ data: { errors: errors.form } }, formikActions, {
        errorMapper: mapError,
      });
    },
  });
};

const ImportFromFileContainer = ({
  closeModalDialog,
  showSnackNotificationAction,
  performImportRequest,
  setReload,
  dataComponent,
  dataComponentId,
  loading,
  pluralEntity,
  additionalValues,
}) => {
  const [prevDataComponent, setDataComponent] = useState(null);
  const [formikActions, setFormikAction] = useState(null);
  useEffect(() => {
    setDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestStatus(
      formikActions,
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction
    );
  }, [
    formikActions,
    prevDataComponent,
    dataComponent,
    setReload,
    closeModalDialog,
    showSnackNotificationAction,
  ]);

  const handleUploadFile = useCallback(
    ({ file, ignoreDuplicates }, formikActions) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("additionalValues", JSON.stringify(additionalValues));
      formData.append("ignoreDuplicates", JSON.stringify(ignoreDuplicates));
      performImportRequest(dataComponentId, formData);
      setFormikAction(formikActions);
    },
    [dataComponentId, performImportRequest, additionalValues]
  );

  return (
    <FormikForm
      initialValues={{
        file: undefined,
      }}
      onSubmit={handleUploadFile}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <ImportFromFile
            onSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            loading={loading}
            pluralEntity={pluralEntity}
            file={values.file}
          />
        );
      }}
    </FormikForm>
  );
};
ImportFromFileContainer.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  performImportRequest: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
  additionalValues: PropTypes.shape({}),
  loading: PropTypes.bool,
  pluralEntity: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { dataComponentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(
      dataComponent,
      `requestState.${REQUEST_TYPES.IMPORT}.loading`
    ),
  };
};

const mapDispatchToProps = {
  performImportRequest,
  closeModalDialog,
  setReload,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportFromFileContainer);
