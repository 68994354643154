import { inputs } from "../../../../components/inputs/inputConfigs";

export default disabledTitle => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.title,
              disabled: disabledTitle,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.description,
              rows: 3,
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
