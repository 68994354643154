import React from "react";
import PropTypes from "prop-types";

import PagingPanel from "../PagingPanel";

import { getGridTableProps } from "../utils";
import propTypes from "../../../../constants/propTypes";

const BWGridPagingPanel = ({ gridConfig, hidePaginationControl, children }) => {
  const { rowsPerPageOptions = [10, 25, 50, 100] } = getGridTableProps(
    children
  );
  const hasEnoughRowsForPagination = gridConfig.totalRows > 10;
  return (
    <PagingPanel
      display={hasEnoughRowsForPagination}
      rowsPerPageOptions={!hidePaginationControl && rowsPerPageOptions}
    />
  );
};

BWGridPagingPanel.propTypes = {
  gridConfig: propTypes.gridConfig,
  hidePaginationControl: PropTypes.bool,
  children: PropTypes.node,
};

export default BWGridPagingPanel;
