import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RegularText from "../../../ui/Typography/RegularText";
import propTypes from "../../../../constants/propTypes";
import Grid from "../../../mui/core/Grid";

import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import TagsDropdown from "./TagsDropdown";
import TimeDropdown from "./TimeDropdown";

const Label = styled(RegularText)`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 22px;
  &:first-child {
    margin-left: 0;
  }
`;

const LineWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0;
  width: 100%;
`;

export const FiltersLine = ({ tags }) => {
  return (
    <LineWrapper container>
      <Label>View</Label>
      <TagsDropdown tags={tags} />
      <Label>Time</Label>
      <TimeDropdown />
    </LineWrapper>
  );
};

FiltersLine.propTypes = {
  tags: PropTypes.arrayOf(propTypes.tag),
};

const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    "notes-tags-list",
    state
  );
  return {
    tags: getBluechipResources(dataComponent, state) || [],
  };
};

export default connect(mapStateToProps)(FiltersLine);
