import PropTypes from "prop-types";
import { connect } from "react-redux";

import Vendor from "../../../../models/Vendor";

const VendorColumn = ({ name }) => name;
VendorColumn.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = (state, { vendorId }) => {
  const vendor = Vendor.query(state.resources).find(vendorId);
  return {
    name: vendor.name,
  };
};

export default connect(mapStateToProps)(VendorColumn);
