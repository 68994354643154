import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import DeleteSubmittalButton from "./DeleteSubmittalButton";
import EditSubmittalButton from "./EditSubmittalButton";
import {
  Check,
  VerticalAlignWrapper,
} from "../../../SubmittalsManager/RequirementsGrid/SubmittalsTable/components";
import { renderFilename } from "../../../SubmittalsManager/RequirementsGrid/SubmittalsTable/Table";

function ActionsColumn({ row, onDelete, onEdit }) {
  return (
    <Fragment>
      <EditSubmittalButton onClick={onEdit} submittalFile={row} />
      <DeleteSubmittalButton onSubmit={onDelete} submittalFile={row} />
    </Fragment>
  );
}

ActionsColumn.propTypes = {
  row: PropTypes.shape({}),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export function actionIconsBuilder(onDeleteClick, onEditClick) {
  const ActionIconsFormatter = ({ row }) => {
    return (
      <ActionsColumn row={row} onDelete={onDeleteClick} onEdit={onEditClick} />
    );
  };

  ActionIconsFormatter.propTypes = {
    row: PropTypes.shape({}),
  };

  const ActionIconsProvider = props => {
    return (
      <DataTypeProvider
        formatterComponent={ActionIconsFormatter}
        {...props}
        for={["actionIcons"]}
      />
    );
  };
  return ActionIconsProvider;
}

export const approveTypeBuilder = specRequirement => {
  const ApproveFormatter = ({ row }) => {
    const isApproved = row.id === specRequirement.submittalApprovedId;
    return (
      <VerticalAlignWrapper>
        <Check isApproved={isApproved} />
      </VerticalAlignWrapper>
    );
  };

  ApproveFormatter.propTypes = {
    row: PropTypes.shape({}),
  };

  const ApproveTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={ApproveFormatter}
      {...props}
      for={["approved"]}
    />
  );
  return ApproveTypeProvider;
};

export const filenameTypeBuilder = () => {
  const FilenameFormatter = ({ row }) => renderFilename(row);
  const ApproveTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={FilenameFormatter}
      {...props}
      for={["file.filename"]}
    />
  );
  return ApproveTypeProvider;
};
