import React, { useState } from "react";

import { PurchaseOrderHeader } from "./PurchaseOrderHeader";
import SpecShipmentsContainer from "./SpecShipments/SpecShipmentsContainer";
import { HeaderOverride } from "./components";
import propTypes from "../../../../constants/propTypes";

const PurchaseOrderRow = ({ purchaseOrder }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const headerText = purchaseOrder.name;

  if (isCollapsed) {
    return (
      <PurchaseOrderHeader
        headerText={headerText}
        headerOverride={
          <HeaderOverride
            purchaseOrder={purchaseOrder}
            isCollapsed={isCollapsed}
          />
        }
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
    );
  }

  return (
    <SpecShipmentsContainer
      purchaseOrder={purchaseOrder}
      headerText={headerText}
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
    />
  );
};

PurchaseOrderRow.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
};

export default PurchaseOrderRow;
