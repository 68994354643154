import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";

const MultiValue = styled(props => {
  const onDelete = props.isDisabled ? null : props.removeProps.onClick;
  return (
    <Chip
      className={props.className}
      tabIndex={-1}
      label={props.children}
      onDelete={onDelete}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      onMouseDown={props.onMouseDown}
    />
  );
})`
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0 8px 4px 0;
  height: 29px;
  background-color: #f1f1f1;
`;

MultiValue.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    .isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

export default MultiValue;
