import React from "react";
import PropTypes from "prop-types";

import { DashIcon } from "./components";

export const VendorColumn = ({ vendor, purchaseOrder }) => {
  return vendor?.name || purchaseOrder?.vendor?.name || <DashIcon />;
};
VendorColumn.propTypes = {
  purchaseOrder: PropTypes.shape({
    id: PropTypes.string,
    vendor: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default VendorColumn;
