import _get from "lodash/get";
import moment from "moment";
import _cloneDeep from "lodash/cloneDeep";
import _omit from "lodash/omit";

import AuthService from "../services/AuthService";
import {
  performCreateRequest,
  performUpdateRequest,
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
  fetchDataRequest,
  performDeleteRequest,
  cloneDataComponentOverwriteProp,
} from "./dataComponentActions";
import { openModalDialog, setAutoSaveComponentId } from "./layoutActions";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../reducers/dataComponentReducer";
import Spec from "../models/Spec";
import SpecDetail from "../models/SpecDetail";
import SpecFile from "../models/SpecFile";
import { uploadFile } from "./filesActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";

export const dataComponentId = "SpecDetail";
export const specFilesDataComponentId = "SpecFilesDC";
const dataComponentSettings = "specDetailsSettings";
const specDetailsChildrenDataComponentId = "SpecDetail-SpecDetailsChildren";
export const glCodesDataComponentId = "AssignedGLCodes";
export const addSpecDetailsComponentId = "AddSpecDetailsComponentId";

import { getCreatedId } from "../utils/dataComponentUtils";
import { cleanCurrencyValue } from "../utils/currencyFormatter";
import { FILE_DATE_FORMAT } from "../constants/formats";

const authService = new AuthService(true);

const isLastIndex = (rowIndex = [], specId) => {
  return rowIndex.indexOf(specId) === rowIndex.length - 1;
};

export const initQuickEditSpec = (
  specId,
  dataComponentId,
  cloneDataComponentId
) => {
  return async dispatch => {
    dispatch(
      cloneDataComponentOverwriteProp(
        dataComponentId,
        cloneDataComponentId,
        true,
        true,
        {
          includes: ["glCodes"],
        }
      )
    );
    dispatch(
      performFindRequest(
        cloneDataComponentId,
        specId,
        {},
        {
          omitDefaultModifier: true,
          modifiers: [
            "withQuantityPrice",
            "withTotalQuantityPaid",
            "withTotalQuantityShipped",
            "withAssignedToBidGroup",
          ],
        }
      )
    );
  };
};

export const initQuickEditSpecForm = (
  specId,
  projectId,
  clientId,
  dataComponentId
) => {
  return async (dispatch, getState) => {
    const cloneDataComponentId = `${dataComponentId}Clone`;
    dispatch(
      cloneDataComponentOverwriteProp(
        dataComponentId,
        cloneDataComponentId,
        true,
        true,
        {
          includes: [],
        }
      )
    );

    const { pageNumber, rowIndex } = getDataComponentFlattenedRequestState(
      cloneDataComponentId,
      getState()
    );

    if (isLastIndex(rowIndex, specId)) {
      dispatch(
        performRetrieveListRequest(cloneDataComponentId, {
          pageNumber: pageNumber + 1,
        })
      );
    }
  };
};

export const initSpecDetail = (
  specId,
  projectId,
  clientId,
  includeSpecDetails,
  params
) => {
  return async dispatch => {
    const includes = [
      "vendorContacts.contact",
      "vendor.location",
      "specCategory",
      "purchaseOrder(withNeedsFollowUp).[currentRevision.revisionActivities, vendor.location, poContacts.vendorContact.contact]",
      "glCodes",
      "specRequirements.[requirement,submittalFiles.file,submittalApproved.file]",
      "shipments",
      "projectCurrency.currency",
      "unitOfMeasure",
    ];

    if (includeSpecDetails) {
      includes.push(
        "specDetails.[specDetailComs.spec.[specCategory, specDetails.preview], roomTypes, preview]"
      );
    }

    dispatch(
      initDataComponent(dataComponentId, Spec, includes, "specs", false, "v2")
    );

    dispatch(
      initDataComponent(
        dataComponentSettings,
        Spec,
        ["specRequirements.requirement"],
        "specs"
      )
    );

    dispatch(
      initDataComponent(
        specDetailsChildrenDataComponentId,
        SpecDetail,
        ["spec", "preview"],
        "spec-details",
        false,
        "v2"
      )
    );

    dispatch(
      initDataComponent(addSpecDetailsComponentId, Spec, [], "spec-details-add")
    );

    dispatch(setAutoSaveComponentId(dataComponentId));
    dispatch(
      performFindRequest(
        dataComponentId,
        specId,
        {
          $where: { projectId },
        },
        params
      )
    );
  };
};

export function initSpecFileDC() {
  return initDataComponent(
    specFilesDataComponentId,
    SpecFile,
    ["file"],
    "spec-files",
    true,
    "v2"
  );
}

export const initSpecFiles = specId => {
  return async dispatch => {
    dispatch(initSpecFileDC());

    dispatch(
      performRetrieveListRequest(specFilesDataComponentId, {
        rootFilters: { $where: { specId } },
        sort: [{ columnName: "key", direction: "asc" }],
        pageSize: -1,
      })
    );
  };
};

export function deleteFile(specFileId) {
  return performDeleteRequest(specFilesDataComponentId, specFileId);
}

export function uploadFiles(files, specId, fileType) {
  return async dispatch => {
    const key = `/specs/${specId}/${fileType}`;
    const specFiles = await Promise.all(
      Array.from(files).map(async file => {
        const ext = file.name.split(".").pop();
        const filename = `${moment().format(FILE_DATE_FORMAT)}-${
          file.name
        }.${ext}`;
        const s3Key = `${key}/${filename}`;
        await dispatch(uploadFile({ key, filename }, file, s3Key));
        return {
          key: fileType,
          specId,
          file: {
            s3Key,
            filename: file.name,
            metadata: { size: file.size, type: file.type },
          },
        };
      })
    );
    dispatch(performCreateRequest(specFilesDataComponentId, specFiles));
  };
}

export function uploadFileToMultipleSpecs(file, specIds, fileType) {
  return async dispatch => {
    const key = `/specs/${specIds.join("-")}/${fileType}`;
    const ext = file.name.split(".").pop();
    const filename = `${moment().format(FILE_DATE_FORMAT)}-${file.name}.${ext}`;
    const s3Key = `${key}/${filename}`;
    await dispatch(uploadFile({ key, filename }, file, s3Key));
    dispatch(
      performCreateRequest(
        specFilesDataComponentId,
        specIds.map(specId => ({
          key: fileType,
          specId,
          file: {
            s3Key,
            filename: file.name,
            metadata: { size: file.size, type: file.type },
          },
        }))
      )
    );
  };
}

export const editSpecDetailCom = (
  dataComponentId,
  specDetailCom,
  notes,
  params
) => {
  return async dispatch => {
    const newSpecDetailCom = {
      ...specDetailCom,
      notes,
    };

    dispatch(
      performUpdateRequest(
        dataComponentId,
        specDetailCom.id,
        newSpecDetailCom,
        params
      )
    );
  };
};

export const openEditSpecDetailComNotes = (
  specDetailCom,
  specDetail,
  purchaseOrder
) => {
  const [customNumber, description, purchaseOrderId] = [
    _get(specDetailCom, "spec.customNumber"),
    _get(specDetailCom, "spec.description"),
    _get(purchaseOrder, "id"),
  ];

  return openModalDialog(
    `Edit ${customNumber} ${description} Notes`,
    "EditSpecDetailCOM",
    { specDetailCom, specDetail, purchaseOrderId }
  );
};

export const addCOMToSpec = (
  dataComponentId,
  spec,
  currentSelected,
  notes,
  params
) => {
  return async dispatch => {
    const newSpecDetailCom = {
      specId: currentSelected.id,
      notes,
    };

    const specDetailFilter = {
      filter: JSON.stringify({
        $where: { specId: spec.id, type: "COM" },
      }),
    };
    let specDetail = await authService.get(`/spec-details/`, specDetailFilter);

    if (!specDetail || specDetail.length === 0) {
      specDetail = {
        specId: spec.id,
        title: "",
        description: "",
        type: "COM",
      };
      newSpecDetailCom.specDetail = specDetail;
    } else {
      specDetail = specDetail[0];
      newSpecDetailCom.specDetailId = specDetail.id;
    }

    dispatch(performCreateRequest(dataComponentId, newSpecDetailCom, params));
  };
};

export function quickUpdateSpec(
  dataComponentId,
  specId,
  projectId,
  { glCodes, specRequirements, shipmentTotalQty, ...spec },
  params
) {
  return async dispatch => {
    dispatch(setAutoSaveComponentId(dataComponentId));
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    const attributes = {};

    if (glCodes) {
      attributes.glCodes = glCodes.map(({ id }) => ({ id }));
    }

    if (specRequirements) {
      attributes.specRequirements = specRequirements.map(
        ({ __expanded__, __isClicked__, ...item }) => item
      );
    }

    dispatch(
      performUpdateRequest(
        dataComponentId,
        specId,
        { ...spec, ...attributes },
        {
          ...params,
          filter: JSON.stringify({
            $where: { projectId },
          }),
        }
      )
    );
  };
}

export function updateSpec(
  specId,
  projectId,
  spec,
  params,
  customDataComponentId
) {
  return async dispatch => {
    dispatch(
      quickUpdateSpec(
        customDataComponentId || dataComponentId,
        specId,
        projectId,
        spec,
        params
      )
    );
  };
}

export function revertFromRevision(specId, revisionId) {
  return async dispatch => {
    dispatch(
      performUpdateRequest(
        dataComponentId,
        specId,
        {},
        { revert_from_revision: revisionId, update_po_revision: true }
      )
    );
  };
}

export function updatePreview(
  dataComponentId,
  specId,
  projectId,
  specDetailId,
  previewFile,
  params
) {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    const key = `projects/${projectId}/specs/${specId}/details/${specDetailId}`;
    const file = previewFile.file;
    const ext = file.name.split(".").pop();
    const filename = `preview.${ext}`;
    await dispatch(uploadFile({ key, filename }, file));
    dispatch(
      performUpdateRequest(
        dataComponentId,
        specDetailId,
        {
          preview: {
            s3Key: `${key}/${filename}`,
            filename: file.name,
            metadata: previewFile.metadata,
          },
        },
        params
      )
    );
  };
}

export function openBulkDeleteSpecDetails(spec, ids) {
  const params = spec?.purchaseOrder?.revisionStatus
    ? { update_po_revision: true }
    : {};
  return openModalDialog(
    ["Delete Spec Details", `${spec.customNumber} ${spec.description}`],
    "StandardBulkDeleteConfirmation",
    {
      selectedIds: ids,
      dataComponentId: specDetailsChildrenDataComponentId,
      reloadDataComponentId: dataComponentId,
      message: `delete the ${ids.length} Spec Details`,
      params: { ...params },
    },
    false
  );
}

export const createComForSpec = (dataComponentId, spec, newCom) => {
  return async (dispatch, getState) => {
    const clonedNewCom = _cloneDeep(newCom);

    const preview = clonedNewCom.specDetails.find(
      detail => detail.type == "Preview"
    );

    if (preview.file) {
      dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.CREATE));
      const key = `projects/${spec.projectId}/specs/${spec.id}/details`;
      const file = preview.file.file;
      const ext = file.name.split(".").pop();
      const filename = `preview.${ext}`;
      await dispatch(uploadFile({ key, filename }, file));
      preview.preview = {
        s3Key: `${key}/${filename}`,
        filename: file.name,
      };
      delete preview.file;
    }

    const notes = clonedNewCom.notes;
    delete clonedNewCom.notes;

    if (clonedNewCom.priceCents) {
      clonedNewCom.priceCents = cleanCurrencyValue(
        clonedNewCom.priceCents * 100
      );
    }

    await dispatch(performCreateRequest(dataComponentId, clonedNewCom, {}));
    const comSpecId = getCreatedId(
      getDataComponent(dataComponentId, getState())
    );
    await dispatch(
      addCOMToSpec(
        "COMLibrary-specDetailCom",
        spec,
        { id: comSpecId },
        notes,
        {}
      )
    );
  };
};

export const updateSpecDetailComs = ({ id, specDetailComs }) => {
  return async dispatch => {
    const dataComponentId = "specDetailDataComponentId";
    dispatch(setAutoSaveComponentId(dataComponentId));
    dispatch(
      initDataComponent(dataComponentId, SpecDetail, [], "spec-details", true)
    );
    dispatch(
      performUpdateRequest(dataComponentId, id, {
        specDetailComs: specDetailComs.map(specDetailCom =>
          _omit(specDetailCom, "spec")
        ),
      })
    );
  };
};
