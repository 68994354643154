import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import {
  handleSuccessForPurchaseOrder,
  handleError,
} from "./PurchaseOrderRequestHandlers";

export const onUpdatePurchaseOrderStatus = (
  purchaseOrder,
  status,
  dataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(dataComponentId, purchaseOrder.id, { status: status });
};
export const processRequestsForPurchaseOrder = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  callbacks
) => {
  processUpdateRequestForPurchaseOrder(
    prevDataComponent,
    dataComponent,
    dataComponentId,
    callbacks
  );
};
const processUpdateRequestForPurchaseOrder = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  { setReload, showSnackNotificationAction, snackMessage }
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForPurchaseOrder(
        setReload,
        dataComponentId,
        showSnackNotificationAction,
        snackMessage
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
