import React from "react";

import propTypes from "../../../../../constants/propTypes";
import { Title } from "../../../../ui/Typography/SectionTitle";
import { ItemPaperWrapper, NegativeWrapper } from "./components";
import Loader from "../../../../ui/Loader";
import ProjectStatusSection from "../../../ProjectDetail/Overview/ProjectStatusSection/ProjectStatusSection";

export const ProjectStatusCard = ({ projectSummary }) => {
  return (
    <ItemPaperWrapper>
      <Loader width="200px" height="20px">
        <Title>Project Status</Title>
      </Loader>
      <NegativeWrapper>
        {projectSummary && projectSummary.projectStatus && (
          <ProjectStatusSection projectSummary={projectSummary} />
        )}
      </NegativeWrapper>
    </ItemPaperWrapper>
  );
};

ProjectStatusCard.propTypes = {
  projectSummary: propTypes.projectSummary.isRequired,
};
