import { BaseModel } from "@blue-chip/core";
import PurchaseOrder from "./PurchaseOrder";
import RevisionActivity from "./RevisionActivity";

export default class Revision extends BaseModel {
  static get belongsTo() {
    return [PurchaseOrder];
  }

  static get hasMany() {
    return [RevisionActivity];
  }
}
