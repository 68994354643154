import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import styled from "styled-components";
import _get from "lodash/get";

import noteFollowUpFields from "./noteFollowUpFields";
import noteDeliveryFields from "./noteDeliveryFields";
import noteShippingFields from "./noteShippingFields";
import noteEmailFields from "./noteEmailFields";
import noteGeneralField from "./noteFields";
import notePhoneCallFields from "./notePhoneCallFields";
import noteMeetingFields from "./noteMeetingFields";
import { TitleContainer } from "../../../../layout/AppLayout/ModalDialog/ModalTitle";
import {
  NoteCategoriesContainer,
  StyledTitle,
} from "../../../ProjectDetail/Shipments/AddTrackingNote/components";
import {
  TrackingTypeDropdown,
  getTrackingTypes,
  CategoryDropdown,
  getCategories,
} from "../../../ProjectDetail/Shipments/AddTrackingNote/AddTrackingNoteForm";
import { ActionButtons, FormikForm } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";
import { getFields } from "../../../Notes/NoteCreate/NoteCreatePage";
import FormGrid from "../../../../forms/FormGrid";

const Container = styled.div`
  height: calc(100% - 101px);
  overflow: auto;
`;

const Wrapper = styled.div`
  width: 560px;
`;

const fieldsMap = {
  General: noteGeneralField,
  Email: noteEmailFields,
  "Phone Call": notePhoneCallFields,
  Meeting: noteMeetingFields,
  Tracking: {
    Shipping: noteShippingFields,
    "Follow Up": noteFollowUpFields,
    Delivery: noteDeliveryFields,
  },
};

const initialValues = {
  subject: undefined,
  detail: undefined,
  emailSentBy: undefined,
  emailSentTo: undefined,
  callWith: undefined,
  trackingNumber: undefined,
  carrierName: undefined,
  tags: [],
  assignTo: [],
  mentions: [],
};

export const AddNoteForm = ({
  projectId,
  userId,
  purchaseOrder,
  onCreateNote,
  onClosePopover,
}) => {
  const purchaseOrderId = purchaseOrder.id;
  const [trackingType, setTrackingType] = useState(getTrackingTypes()[0].id);
  const [category, setCategory] = useState(getCategories()[0].id);

  const fields = getFields(null, category, trackingType, fieldsMap);
  return (
    <Wrapper>
      <TitleContainer>
        <StyledTitle>
          Create Note for PO# {_get(purchaseOrder, "number")}
        </StyledTitle>
        <NoteCategoriesContainer>
          <CategoryDropdown value={category} onChange={setCategory} />
          {category === "Tracking" && (
            <TrackingTypeDropdown
              value={trackingType}
              onChange={setTrackingType}
            />
          )}
        </NoteCategoriesContainer>
      </TitleContainer>
      <Container>
        <FormikForm
          initialValues={initialValues}
          onSubmit={values =>
            onCreateNote({
              ...values,
              category,
              trackingType,
              projectId,
              authorId: userId,
              assignTo: [
                {
                  rawId: purchaseOrderId,
                  name: `PO #${purchaseOrderId}`,
                  id: `PO #${purchaseOrderId}`,
                },
              ],
            })
          }
          ignoreValidationFieldNames={[
            "assignTo",
            "authorId",
            "projectId",
            "trackingType",
            "category",
          ]}
          validationSchema={BWModels.loadSchema("Note")}
        >
          {({ handleSubmit, ...formikProps }) => (
            <Fragment>
              <FormGrid
                fields={fields}
                values={formikProps.values}
                errors={formikProps.errors}
                {...formikProps}
              />
              <ActionButtons
                isModal
                onSend={handleSubmit}
                onCancel={onClosePopover}
              />
            </Fragment>
          )}
        </FormikForm>
      </Container>
    </Wrapper>
  );
};

AddNoteForm.propTypes = {
  projectId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  purchaseOrder: propTypes.purchaseOrder,
  onCreateNote: PropTypes.func.isRequired,
  onClosePopover: PropTypes.func.isRequired,
};

export default AddNoteForm;
