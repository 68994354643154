import { push } from "connected-react-router";
import { connect } from "react-redux";
import {
  logout,
  validateVerificationCode,
} from "../../../../actions/authActions";

import { VerifyCellPhoneNumber } from "./VerifyCellPhoneNumber";

export const mapStateToProps = state => {
  const {
    auth: {
      isCellNumberVerified,
      sendingVerificationCode,
      verificationCodeSent,
      cellNumber,
      verificationCodeValidated,
      validatingVerificationCode,
    },
  } = state;

  return {
    isCellNumberVerified,
    sendingVerificationCode,
    verificationCodeSent,
    cellNumber,
    verificationCodeValidated,
    validatingVerificationCode,
  };
};

const mapDispatchToProps = {
  validateVerificationCode,
  logout,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyCellPhoneNumber);
