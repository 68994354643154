import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import CapacityPage from "./CapacityPage";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import TeamsExtraCount from "../../../models/TeamsExtraCount";
import Project from "../../../models/Project";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";

const capacityTeamGridDataComponentId = "CapacityTeamsGrid";
const usersDataComponentId = "Capacity-users";
const activeProjectsDataComponentId = "Capacity-activeProjects";
const closingProjectsDataComponentId = "Capacity-closingProjects";
const completedProjectsDataComponentId = "Capacity-completedProjects";

export class CapacityContainer extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { initDataComponent, performRetrieveListRequest } = this.props;

    initDataComponent(usersDataComponentId, Project, [], "users");
    initDataComponent(activeProjectsDataComponentId, Project, [], "projects");
    initDataComponent(
      completedProjectsDataComponentId,
      Project,
      [],
      "projects"
    );
    initDataComponent(closingProjectsDataComponentId, Project, [], "projects");

    performRetrieveListRequest(activeProjectsDataComponentId, {
      rootFilters: { $where: { status: { $in: ["Contracted", "On Hold"] } } },
    });
    performRetrieveListRequest(closingProjectsDataComponentId, {
      rootFilters: { $where: { status: { $in: ["In Closing"] } } },
    });
    performRetrieveListRequest(completedProjectsDataComponentId, {
      rootFilters: { $where: { status: { $in: ["Closed"] } } },
    });
  }

  render() {
    return (
      <CapacityPage
        {...this.props}
        model={TeamsExtraCount}
        apiRoute="teams/extra/counts/"
      />
    );
  }
}

export const mapStateToProps = state => {
  const usersDataComponent = getDataComponentFlattenedRequestState(
    usersDataComponentId,
    state
  );
  const activeProjectsDataComponent = getDataComponentFlattenedRequestState(
    activeProjectsDataComponentId,
    state
  );
  const completedProjectsDataComponent = getDataComponentFlattenedRequestState(
    completedProjectsDataComponentId,
    state
  );
  const closingProjectsDataComponent = getDataComponentFlattenedRequestState(
    closingProjectsDataComponentId,
    state
  );

  return {
    dataComponent: getDataComponentFlattenedRequestState(
      capacityTeamGridDataComponentId,
      state
    ),
    numEmployees: usersDataComponent.totalRows,
    numActiveProjects: activeProjectsDataComponent.totalRows,
    numClosingProjects: completedProjectsDataComponent.totalRows,
    numCompletedProjects: closingProjectsDataComponent.totalRows,
  };
};

export const mapDispatchToProps = {
  push,
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CapacityContainer);
