import {
  buildConsultingCompanyFilter,
  buildContactFilter,
  buildUserFilter,
  buildUserFilterWithKeyWords,
  buildVendorContactFilter,
  clientContactDCId,
  consultingDCId,
  freightDCId,
  usersDCId,
  vendorContactsDCId,
} from "../../../inputs/inputConfigs/projects";
import SelectWithError from "../../../inputs/SelectWithError";
import UserSelect from "./UserSelect";
import ConsultingContactSelect from "./ConsultingContactSelect";
import { warehouseFields } from "./warehouseTeamFields";
import Vendor from "../../../../models/Vendor";

const clientType = "Client Team";
const projectLeadType = "Project Lead";
const projectSupportType = "Project Support";
export const projectAccountingType = "Accounting Support";
const freightVendorType = "Freight Vendor";
const projectManagerType = "Project Manager";
const designerType = "Interior Designer";
const secondaryDesignerType = "Secondary Interior Designer";
const generalContactorType = "General Contractor";
const otherConsultantType = "Other";

const createMemberContactInput = (consultingCompanyId, memberType) => {
  return {
    InputComponent: ConsultingContactSelect,
    name: "projectMemberContacts",
    label: "Select Contacts",
    dataComponentId: consultingDCId,
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "contactId",
    consultingCompanyId,
    memberType: memberType,
  };
};
// eslint-disable-next-line max-lines-per-function
const createInputs = (project, syncProjectMemberContacts) => {
  return {
    projectLead: {
      InputComponent: SelectWithError,
      name: "projectLeadId",
      label: projectLeadType,
      dataComponentId: usersDCId,
      fullWidth: true,
      isAutocomplete: true,
      isSimple: true,
      submitData: false,
      valueProperty: "userId",
      filter: buildUserFilter(projectLeadType, [
        "Project Director",
        "Senior Project Manager",
        "Project Manager",
        "Admin",
        "Super Admin",
      ]),
      memberType: projectLeadType,
      required: true,
    },
    projectSupport: {
      InputComponent: UserSelect,
      name: "projectMemberUsers",
      label: projectSupportType,
      dataComponentId: usersDCId,
      fullWidth: true,
      isAutocomplete: true,
      isAutocompleteMulti: true,
      submitData: true,
      valueProperty: "userId",
      filter: buildUserFilter(projectSupportType, [
        "Project Director",
        "Senior Project Manager",
        "Project Manager",
        "Project Coordinator",
        "Admin",
        "Super Admin",
      ]),
      memberType: projectSupportType,
    },
    projectAccounting: {
      InputComponent: UserSelect,
      name: "projectMemberUsers",
      label: projectAccountingType,
      dataComponentId: usersDCId,
      fullWidth: true,
      isAutocomplete: true,
      isAutocompleteMulti: true,
      submitData: true,
      valueProperty: "userId",
      filter: buildUserFilterWithKeyWords(projectAccountingType, [
        "admin",
        "account",
      ]),
      memberType: projectAccountingType,
    },
    freight: {
      InputComponent: SelectWithError,
      name: "freightVendorId",
      label: "Freight",
      dataComponentId: freightDCId,
      fullWidth: true,
      isAutocomplete: true,
      isOptionDisabled: Vendor.isVendorDisabled,
      handleChange: syncProjectMemberContacts,
      apiFilterLabelProperty: "name",
      labelProperty: Vendor.labelProperty,
    },
    freightVendorContacts: {
      InputComponent: UserSelect,
      name: "projectMemberContacts",
      label: "Select Contacts",
      dataComponentId: vendorContactsDCId,
      fullWidth: true,
      isAutocomplete: true,
      isAutocompleteMulti: true,
      submitData: true,
      valueProperty: "contactId",
      filter: buildVendorContactFilter(
        project.freightVendorId,
        freightVendorType
      ),
      memberType: freightVendorType,
    },
    ...warehouseFields(project, syncProjectMemberContacts),
    projectManager: {
      InputComponent: SelectWithError,
      name: "projectManagerId",
      label: "Project Manager",
      placeholder: "Select Project Manager",
      dataComponentId: consultingDCId,
      fullWidth: true,
      isAutocomplete: true,
      filter: buildConsultingCompanyFilter(projectManagerType),
      handleChange: syncProjectMemberContacts,
    },
    projectManagerContacts: createMemberContactInput(
      project.projectManagerId,
      projectManagerType
    ),
    projectClientTeam: {
      InputComponent: UserSelect,
      name: "projectMemberContacts",
      label: "Client Team",
      dataComponentId: clientContactDCId,
      fullWidth: true,
      isAutocomplete: true,
      isAutocompleteMulti: true,
      submitData: true,
      valueProperty: "contactId",
      filter: buildContactFilter(clientType),
      memberType: clientType,
    },
    projectDesigner: {
      InputComponent: SelectWithError,
      name: "designerId",
      label: "Designer",
      dataComponentId: consultingDCId,
      fullWidth: true,
      isAutocomplete: true,
      filter: buildConsultingCompanyFilter(
        designerType,
        project.secondaryDesignerId
      ),
      handleChange: syncProjectMemberContacts,
    },
    projectDesignerContacts: createMemberContactInput(
      project.designerId,
      designerType
    ),
    projectSecondaryDesigner: {
      InputComponent: SelectWithError,
      name: "secondaryDesignerId",
      label: "Secondary Designer",
      dataComponentId: consultingDCId,
      fullWidth: true,
      isAutocomplete: true,
      filter: buildConsultingCompanyFilter(designerType, project.designerId),
      handleChange: syncProjectMemberContacts,
    },
    projectSecondaryDesignerContacts: createMemberContactInput(
      project.secondaryDesignerId,
      secondaryDesignerType
    ),
    projectGeneralContractor: {
      InputComponent: SelectWithError,
      name: "generalContractorId",
      label: "General Contractor",
      dataComponentId: consultingDCId,
      fullWidth: true,
      isAutocomplete: true,
      filter: buildConsultingCompanyFilter(generalContactorType),
      handleChange: syncProjectMemberContacts,
    },
    projectGeneralContractorContacts: createMemberContactInput(
      project.generalContractorId,
      generalContactorType
    ),
    otherConsultingCompany: {
      InputComponent: SelectWithError,
      name: "otherConsultantId",
      label: "Other",
      placeholder: "Select Other Consulting Company",
      dataComponentId: consultingDCId,
      fullWidth: true,
      isAutocomplete: true,
      filter: buildConsultingCompanyFilter(otherConsultantType),
      handleChange: syncProjectMemberContacts,
    },
    otherConsultingCompanyContacts: createMemberContactInput(
      project.otherConsultantId,
      otherConsultantType
    ),
  };
};

export const createTeamFields = (project, syncProjectMemberContacts) => {
  const inputs = createInputs(project, syncProjectMemberContacts);

  return {
    groups: [
      {
        items: [{ input: inputs.projectLead }],
      },
      {
        items: [{ input: inputs.projectSupport }],
      },
      {
        items: [{ input: inputs.projectAccounting }],
      },
      {
        items: [{ input: inputs.projectClientTeam }],
      },
      {
        items: [
          { input: inputs.freight, grid: { xs: 6 } },
          { input: inputs.freightVendorContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.warehouse, grid: { xs: 6 } },
          { input: inputs.warehouseVendorContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.projectManager, grid: { xs: 6 } },
          { input: inputs.projectManagerContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.projectDesigner, grid: { xs: 6 } },
          { input: inputs.projectDesignerContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.projectSecondaryDesigner, grid: { xs: 6 } },
          { input: inputs.projectSecondaryDesignerContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.projectGeneralContractor, grid: { xs: 6 } },
          { input: inputs.projectGeneralContractorContacts, grid: { xs: 6 } },
        ],
      },
      {
        items: [
          { input: inputs.otherConsultingCompany, grid: { xs: 6 } },
          { input: inputs.otherConsultingCompanyContacts, grid: { xs: 6 } },
        ],
      },
    ],
  };
};
