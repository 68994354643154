import React, { useMemo, useRef } from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import styled from "styled-components";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Term from "../../../../models/Term";
import Paper from "../../../mui/core/Paper";
import BulkActions from "./BulkActions";
import { getOptionsFromSchema } from "../../../inputs/utils";
import { StyledChip } from "../Templates/TemplatesPage";
import LinesEllipsis from "../../../ui/LinesEllipsis";
import withSelectedRowsAPI from "../../../ui/BWGrid/withSelectedRowsAPI";
import useUserRole from "../../../hooks/useUserRole";
import { isSuperAdmin } from "../../../../utils/roleUtils";

const columns = ["displayOrder", "content", "relation", "scope.id"];

const menuConditions = {
  entityId: (defaultOptions, row) => {
    if (row.isGlobal) {
      return defaultOptions.filter(({ text }) => text !== "Edit");
    }
    return defaultOptions;
  },
};

const getRowMenu = (onOpenEditModal, onOpenDeleteModal, termContext) => row => {
  const defaultOptions = [
    {
      icon: <Edit />,
      text: "Edit",
      onClick: onOpenEditModal,
    },
    { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
  ];

  if (menuConditions[termContext]) {
    return menuConditions[termContext](defaultOptions, row);
  }

  return defaultOptions;
};

const VerticalCenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RelationColumn = ({ forRfq, forPo }) => {
  const chips = [];
  if (forPo) {
    chips.push(<StyledChip key="PO" label="PO" />);
  }
  if (forRfq) {
    chips.push(<StyledChip key="RFQ" invert label="RFQ" />);
  }
  return chips;
};

const contexts = ["clientId", "entityId"];

export const relationFilter = ({ value }) => {
  switch (value) {
    case "PO":
      return { forPo: true };
    case "RFQ":
      return { forRfq: true };
    default:
      return {};
  }
};

const getColumnsOptions = (scopes, onScopeFilterChange) => ({
  displayOrder: {
    title: "Order",
    bold: true,
    filter: true,
    filterOptions: {
      operator: "equal",
    },
    dragHandle: true,
  },
  content: {
    title: "Description",
    fill: true,
    filter: true,
    render: ({ content }) => {
      return (
        content && (
          <VerticalCenterWrapper>
            <LinesEllipsis>{content}</LinesEllipsis>
          </VerticalCenterWrapper>
        )
      );
    },
  },
  relation: {
    title: "Relation",
    filter: "select",
    sortingEnabled: false,
    filterOptions: {
      options: getOptionsFromSchema("Term", "relation")(),
      operator: relationFilter,
    },
    render: RelationColumn,
  },
  "scope.id": {
    title: "Scope",
    filter: "select",
    render: ({ scope }) => scope?.name,
    filterOptions: {
      options: scopes,
      initialOption: "1",
      omitAllOption: true,
      afterChange: onScopeFilterChange,
    },
  },
});

const getTitle = (count, isGlobal) => {
  if (isGlobal) {
    return `${count} Global ${pluralize("Term", count)}`;
  }
  return `${count} ${pluralize("Term", count)}`;
};

const isDeleteDisabled = selectedRows =>
  new Set(selectedRows.map(({ scopeId }) => scopeId)).size > 1;

const getFilteredColumns = (userRole, contextValue) =>
  columns.filter(
    name => (isSuperAdmin(userRole) && !contextValue) || name !== "scope.id"
  );

const TermPage = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
  onUpdateEntityTerms,
  onOpenBulkDeleteModal,
  onReorder,
  additionalFilters,
  termContext,
  contextValue,
  disableSyncGlobalTerms,
  disableAddTerm,
  scopes,
  selectedRows,
}) => {
  const userRole = useUserRole();
  const selectedScopeRef = useRef(isSuperAdmin(userRole) ? "1" : null);

  const onScopeFilterChange = scopeId => (selectedScopeRef.current = scopeId);

  const columnOptions = getColumnsOptions(scopes, onScopeFilterChange);

  const filters = useMemo(() => {
    if (!termContext) {
      return {
        rootFilters: {
          $where: contexts.reduce(
            (where, context) => ({ ...where, [context]: { $exist: false } }),
            {}
          ),
        },
      };
    }

    if (termContext === "projectId") {
      return {
        rootFilters: {
          $where: {
            [termContext]: contextValue,
            ...additionalFilters,
          },
        },
      };
    }

    return {
      rootFilters: {
        $where: {
          [termContext]: contextValue,
          ...additionalFilters,
        },
      },
    };
  }, [termContext, contextValue, additionalFilters]);

  const actions = disableAddTerm
    ? []
    : [
        {
          text: "ADD TERM",
          icon: <AddCircle />,
          handler: () => onOpenCreateModal(selectedScopeRef.current),
        },
      ];
  const filteredColumns = getFilteredColumns(userRole, contextValue);

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Term}
        apiRoute="terms"
        showSelectAll
        showSelectionColumn
        apiFilters={filters}
        includes={["scope"]}
        defaultSorting={[
          { columnName: "scopeId,displayOrder", direction: "asc" },
        ]}
        onReorder={onReorder}
        ignoreOptimizations
      >
        <GridHeader
          headerText={getTitle(dataComponent.totalRows, !termContext)}
          headerOverride={
            <BulkActions
              dataComponent={dataComponent}
              onUpdateEntityTerms={onUpdateEntityTerms}
              onBulkDelete={onOpenBulkDeleteModal}
              disableSyncGlobalTerms={disableSyncGlobalTerms}
              isDeleteDisabled={isDeleteDisabled(selectedRows)}
            />
          }
          actions={actions}
        />
        <GridTable
          columns={filteredColumns}
          columnOptions={columnOptions}
          rowMenu={getRowMenu(onOpenEditModal, onOpenDeleteModal, termContext)}
        />
      </BWGridAPI>
    </Paper>
  );
};

TermPage.defaultProps = {
  additionalFilters: {},
};
TermPage.propTypes = {
  additionalFilters: PropTypes.shape({}),
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  onUpdateEntityTerms: PropTypes.func.isRequired,
  onOpenBulkDeleteModal: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  termContext: PropTypes.string,
  contextValue: PropTypes.string,
  disableSyncGlobalTerms: PropTypes.bool,
  disableAddTerm: PropTypes.bool,
  scopes: PropTypes.array,
  selectedRows: PropTypes.array,
};
export default withSelectedRowsAPI(TermPage);
