import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { BaseModel } from "@blue-chip/core";

import { Paper } from "../../../../mui/core";
import { BWGridLocal, GridHeader, GridTable } from "../../../../ui/BWGrid";
import { columnOptions, columns } from "./gridProps";
import { ActionButtons } from "../../../../forms";
import { RowComponent } from "./RowComponent";
import propTypes from "../../../../../constants/propTypes";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { EmailTemplate } from "./EmailTemplate";
import { Summary } from "./Summary/Summary";

export const sendRfqDataComponentId = "sendRfq-dataComponentId";

export const hasContacts = ({ selectedContacts }) =>
  selectedContacts.length > 0;
export const isSendDisabled = vendors =>
  vendors.reduce(
    (res, vendor) => res || !hasContacts(vendor) || !vendor.specs.length,
    false
  );

export const SendRFQ = ({
  vendors,
  initDataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  dataComponent,
  sendRfqSummary: summary,
  updateBidGroupVendor,
}) => {
  const [sendRfqSummary, setSendRfqSummary] = useState(null);

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Request for Quotes sent successfully.");
      setSendRfqSummary(summary);
      updateBidGroupVendor(vendors.map(({ id }) => ({ id })));
    },
    onError: () => {
      showSnackNotificationAction("There was a problem while sending RFQs.");
    },
  });

  useEffect(() => {
    initDataComponent(
      sendRfqDataComponentId,
      BaseModel,
      [],
      "send-rfq",
      true,
      "v2"
    );
  }, [initDataComponent]);

  const handleSend = () => {
    performUpdateRequest(
      sendRfqDataComponentId,
      vendors.map(({ id }) => id),
      {}
    );
  };

  if (sendRfqSummary) {
    return <Summary sendRfqSummary={sendRfqSummary} />;
  }

  return (
    <Paper noBottomMargin>
      <BWGridLocal
        id="bidgroup-vendors-send-rfq"
        rows={vendors}
        gridConfig={{ pageSize: 10, totalRows: vendors.length }}
        tableComponents={{
          RowComponent,
        }}
      >
        <GridHeader headerText={pluralize("Vendors", vendors.length, true)} />
        <GridTable columns={columns} columnOptions={columnOptions} />
      </BWGridLocal>
      <EmailTemplate />
      <ActionButtons
        sendButtonText="Send"
        disabled={isSendDisabled(vendors)}
        onSend={handleSend}
        listeners={[sendRfqDataComponentId]}
        isModal
      />
    </Paper>
  );
};

SendRFQ.propTypes = {
  vendors: PropTypes.array,
  initDataComponent: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  updateBidGroupVendor: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  sendRfqSummary: PropTypes.array,
};
