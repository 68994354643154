import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
