import PropTypes from "prop-types";
import React from "react";

import BWGridAPI from "../../../../ui/BWGrid/BWGridAPI";
import GridHeader from "../../../../ui/BWGrid/GridHeader";
import GridTable from "../../../../ui/BWGrid/GridTable";
import SpecShipment from "../../../../../models/SpecShipment";
import Counter from "../../../../ui/Counter";
import { BWIntent } from "../../../../ui/BWIntent";
import CardRight from "../CardRight";
import {
  PlaceholderCounter,
  ShipmentColumn,
  ShipmentsTotalSpan,
} from "./components";
import {
  FutureDeliveryWarningIcon,
  shouldDisplayFutureWarning,
} from "../../../ProjectDetail/Shipments/SpecShipments/components";

const intents = BWIntent.values.counters;

const CounterOrPlaceholder = ({ value, intent }) =>
  value ? <Counter value={value} intent={intent} /> : <PlaceholderCounter />;

CounterOrPlaceholder.propTypes = {
  value: PropTypes.number,
  intent: PropTypes.string,
};

export const Shipments = row => {
  const {
    shipmentsCount,
    shipmentsNeedsFollowUp,
    successShipments,
    shipments = [],
  } = row;

  const futureDeliveryWarning = shipments.some(shipment =>
    shouldDisplayFutureWarning(shipment, "actualDeliveryDate")
  );

  return (
    <ShipmentColumn>
      {futureDeliveryWarning && (
        <FutureDeliveryWarningIcon fieldName="actualDeliveryDate" />
      )}
      <CounterOrPlaceholder value={successShipments} intent={intents.success} />
      <CounterOrPlaceholder value={shipmentsNeedsFollowUp} />
      <ShipmentsTotalSpan>Total {shipmentsCount || 0}</ShipmentsTotalSpan>
    </ShipmentColumn>
  );
};

Shipments.propTypes = {
  shipmentsCount: PropTypes.number,
  shipmentsNeedsFollowUp: PropTypes.number,
  successShipments: PropTypes.number,
};

const columns = ["id", "shipmentsCount"];
const columnsOptions = {
  id: { title: "Spec ID", width: "66px" },
  shipmentsCount: { fill: true, align: "left", render: Shipments },
};

const Table = ({ dataComponent, rootFilters, gridHeaderText }) => {
  return (
    <CardRight>
      <BWGridAPI
        apiRoute="spec-shipments"
        dataComponent={dataComponent}
        model={SpecShipment}
        rootFilters={rootFilters}
      >
        <GridHeader subheaderText={gridHeaderText} />
        <GridTable columns={columns} columnOptions={columnsOptions} />
      </BWGridAPI>
    </CardRight>
  );
};

Table.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  rootFilters: PropTypes.object.isRequired,
  gridHeaderText: PropTypes.string.isRequired,
};

export default Table;
