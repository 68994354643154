import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import FormikForm from "../../../forms/FormikForm";
import FormGrid from "../../../forms/FormGrid";
import ActionButtons from "../../../forms/ActionButtons";
import FilePickerWithError from "../../../inputs/FilePickerWithError";
import useActions from "../../../hooks/useActions";
import {
  initSpecFileDC,
  specFilesDataComponentId,
  uploadFileToMultipleSpecs,
} from "../../../../actions/specDetailActions";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import { useCreateProcessRequests } from "../../../hooks/useProcessRequest";
import { useDatacomponent } from "../../../hooks/useDatacomponent";

const inputs = {
  groups: [
    {
      items: [
        {
          input: {
            InputComponent: FilePickerWithError,
            name: "quoteFile",
            label: "Select File",
            fullWidth: true,
            useFileWrapper: true,
            helperText: "Select a valid Quote File",
            inputFileProps: {
              accept: ".pdf",
              isForm: true,
            },
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

function BulkQuoteUpload({ selectedIds, resetSelection }) {
  const Joi = BWModels.Joi;
  const modifiedSchema = Joi.object().keys({
    quoteFile: BWModels.loadSchema("File").required(),
  });

  const dataComponent = useDatacomponent(specFilesDataComponentId);
  const actions = useActions({
    initSpecFileDC,
    uploadFileToMultipleSpecs,
    showSnackNotificationAction,
    closeModalDialog,
  });

  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      resetSelection?.();
      actions.showSnackNotificationAction(
        "Spec file has been attached successfully"
      );
      actions.closeModalDialog();
    },
  });

  useEffect(() => {
    actions.initSpecFileDC();
  }, [actions]);

  const handleSubmit = ({ quoteFile }) =>
    actions.uploadFileToMultipleSpecs(quoteFile.file, selectedIds, "quote");

  return (
    <FormikForm
      initialValues={{}}
      onSubmit={handleSubmit}
      validationSchema={modifiedSchema}
    >
      {({ handleSubmit }) => (
        <Fragment>
          <FormGrid fields={inputs} />
          <ActionButtons
            onSend={handleSubmit}
            isModal
            sendButtonText="Attach Quote"
            listeners={[specFilesDataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
}

BulkQuoteUpload.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  resetSelection: PropTypes.func,
};

export default BulkQuoteUpload;
