import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import {
  CustomPaging,
  PagingState,
  SortingState,
  IntegratedSelection,
  SelectionState,
} from "@devexpress/dx-react-grid";
import { Plugin, Template } from "@devexpress/dx-react-core";
import {
  Table,
  TableBandHeader,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";

import {
  BWGridFilterRow,
  BWGridFilterState,
} from "../BWGrid/gridInternalComponents";

import GridPagingPanel from "./utilComponents/internalComponents/GridPagingPanel";
import { operationBuildersLocal } from "../BWGrid/filterFunctions";
import {
  normalizeColumns,
  normalizeFilteringDataTypeProviders,
  buildTableComponents,
} from "./gridFunctions";
import PickerTypeProvider from "../BWGrid/gridInternalComponents/dataTypeProviders/PickerTypeProvider";

const Grid = ({
  rows,
  columns: customColumns,
  columnBands,
  columnExtensions: customColumnExtensions,
  dataTypeProviders,
  fixedColumns,
  filteringDataTypeProviders: customFilteringDataTypeProviders,
  tableComponents: customTableComponents,
  pagingPanelProps,
  pagingStateProps,
  selectionStateProps,
  sortingStateProps,
  showSelectAll,
  showSelectionColumn,
  filterProps,
  rowProps,
  dxGridProps,
}) => {
  const tableComponents = useMemo(
    buildTableComponents(customTableComponents, rowProps),
    [customTableComponents, rowProps]
  );
  const handleSelectionChange = selected => {
    selectionStateProps.setSelection(selected, pagingStateProps.currentPage);
  };

  const {
    DXGrid,
    PagingPanelTemplate,
    GridHeader,
    GridWrapper,
  } = tableComponents;
  const columns = useMemo(normalizeColumns(customColumns), [customColumns]);
  const filteringDataTypeProviders = useMemo(
    normalizeFilteringDataTypeProviders(
      columns,
      customFilteringDataTypeProviders
    ),
    [customColumns, customFilteringDataTypeProviders]
  );
  const columnExtensions = useMemo(
    () => [
      ...customColumnExtensions,
      {
        columnName: "rowMenu",
        sortingEnabled: false,
        width: "80px",
        align: "center",
      },
    ],
    [customColumnExtensions]
  );
  return (
    <GridWrapper>
      <DXGrid rows={rows} columns={columns} {...dxGridProps}>
        <PagingState
          currentPage={pagingStateProps.currentPage}
          pageSize={pagingStateProps.pageSize}
          onCurrentPageChange={pagingStateProps.onCurrentPageChange}
          onPageSizeChange={pagingStateProps.onPageSizeChange}
        />
        <CustomPaging totalCount={pagingStateProps.total} />
        <SelectionState
          selection={selectionStateProps.selection}
          onSelectionChange={handleSelectionChange}
        />
        <IntegratedSelection />
        <SortingState
          sorting={sortingStateProps.sorting}
          onSortingChange={sortingStateProps.onSortingChange}
          columnExtensions={columnExtensions}
        />
        {dataTypeProviders.map((Provider, index) => (
          <Provider key={index} />
        ))}
        <Table
          columnExtensions={columnExtensions}
          tableComponent={tableComponents.TableComponent}
          rowComponent={tableComponents.RowComponent}
          cellComponent={tableComponents.CellComponent}
          containerComponent={tableComponents.TableContainerComponent}
          noDataCellComponent={tableComponents.NoDataCellComponent}
          noDataRowComponent={tableComponents.NoDataRowComponent}
        />
        <TableHeaderRow
          rowComponent={tableComponents.HeaderRowComponent}
          cellComponent={tableComponents.HeaderCellComponent}
          sortLabelComponent={tableComponents.HeaderSortLabelComponent}
          showSortingControls={!!sortingStateProps.onSortingChange}
        />
        <TableSelection
          showSelectAll={showSelectAll}
          showSelectionColumn={showSelectionColumn}
          headerCellComponent={tableComponents.SelectHeader}
        />
        <TableBandHeader
          columnBands={columnBands}
          cellComponent={tableComponents.HeaderBandCellComponent}
        />
        {filteringDataTypeProviders.map((Provider, index) => (
          <Provider key={index} />
        ))}
        <BWGridFilterState {...filterProps} />
        <BWGridFilterRow
          displayFilters={filterProps.displayFilters}
          cellComponent={tableComponents.FilterCellComponent}
        />

        <Plugin name="TableRowDetail" />
        <TableFixedColumns
          leftColumns={fixedColumns.left}
          rightColumns={fixedColumns.right}
        />

        <Plugin name="GridRowPlugin">
          <Template name="header">
            <GridHeader />
          </Template>
        </Plugin>

        <PagingPanelTemplate totalRows={pagingStateProps.total} />

        <GridPagingPanel
          totalRows={pagingStateProps.total}
          hidePaginationControl={pagingPanelProps.hidePaginationControl}
          rowsPerPageOptions={pagingPanelProps.rowsPerPageOptions}
        />

        <PickerTypeProvider {...filterProps} />
      </DXGrid>
    </GridWrapper>
  );
};

Grid.defaultProps = {
  rows: [],
  columns: [],
  columnBands: [],
  columnExtensions: [],
  dataTypeProviders: [],
  filteringDataTypeProviders: [],
  fixedColumns: {},
  filteringBuilders: operationBuildersLocal,
  onFiltersChange: _noop,
  tableComponents: {},
  pagingPanelProps: {},
  filterProps: {},
  pagingStateProps: {},
  showSelectionColumn: false,
  sortingStateProps: {},
  selectionStateProps: {},
  dxGridProps: {},
};

Grid.propTypes = {
  gridId: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })
  ),
  columnBands: PropTypes.arrayOf(PropTypes.shape({})), // examples at: https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table-band-header/
  columnExtensions: PropTypes.arrayOf(
    PropTypes.shape({ columnName: PropTypes.string, width: PropTypes.string }) // https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table/#tablecolumnextension
  ),
  dataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  filteringDataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  tableComponents: PropTypes.objectOf(PropTypes.func),
  fixedColumns: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.string),
    right: PropTypes.arrayOf(PropTypes.string),
  }),
  pagingPanelProps: PropTypes.shape({
    hidePaginationControl: PropTypes.bool,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  pagingStateProps: PropTypes.shape({
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    onCurrentPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
  }),
  sortingStateProps: PropTypes.shape({
    sorting: PropTypes.arrayOf(
      PropTypes.shape({
        columnName: PropTypes.string,
        direction: PropTypes.string,
      })
    ),
    onSortingChange: PropTypes.func,
  }),
  rowProps: PropTypes.shape({
    onClick: PropTypes.func,
    getRowTitle: PropTypes.func,
    title: PropTypes.string,
  }),
  dxGridProps: PropTypes.shape({}),
};

export default Grid;
