import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Clear from "@material-ui/icons/Clear";

import SerifTitle from "../../../ui/Typography/SerifTitle";
import useCloseOnEscape from "../../../hooks/useCloseOnEscape";

export const TitleContainer = styled.div`
  display: flex;
  min-height: 56px;
  padding: 16px 24px;
  background-color: rgb(39, 88, 122);
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ width }) => (width ? width : "560px")};
  @media (max-width: 768px) {
    width: calc(100vw - 32px) !important;
  }
  @media (max-width: 1024px) {
    width: 100% !important;
  }
  ${({ isDraggable }) => (isDraggable ? "cursor: move;" : "")}
`;

export const Title = styled(SerifTitle)`
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
`;

export const Subtitle = styled(SerifTitle)`
  max-width: 500px;
  margin: 8px 0;
  display: flex;
  font-size: 20px;
  line-height: 28px;
  color: rgb(255, 255, 255);
`;

export const SecondSubtitle = styled(Subtitle)`
  margin: 0px 0px 8px 0px;
  color: rgba(255, 255, 255, 0.7);
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #1c3c53;
  cursor: pointer;
  > svg {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const wrapContent = (content, Component) =>
  content && <Component>{content}</Component>;

export const ModalTitle = ({
  title,
  subtitle,
  secondSubtitle,
  extraTitle,
  isShowCloseIcon,
  closeModalDialog,
  width,
  isDraggable,
}) => {
  useCloseOnEscape(isShowCloseIcon, closeModalDialog);
  if (!title) return null;
  if (Array.isArray(title)) {
    [title, subtitle, secondSubtitle] = title;
  }
  return (
    <TitleContainer
      width={width}
      className={"draggableHandle"}
      isDraggable={isDraggable}
    >
      <Title as="div">{title}</Title>
      {wrapContent(subtitle, Subtitle)}
      {wrapContent(secondSubtitle, SecondSubtitle)}
      {wrapContent(extraTitle, React.Fragment)}
      {isShowCloseIcon && (
        <CloseButton onClick={closeModalDialog}>
          <Clear />
        </CloseButton>
      )}
    </TitleContainer>
  );
};

ModalTitle.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  subtitle: PropTypes.string,
  secondSubtitle: PropTypes.string,
  extraTitle: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  isShowCloseIcon: PropTypes.bool,
  closeModalDialog: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDraggable: PropTypes.bool,
};

export default ModalTitle;
