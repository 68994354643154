import styled from "styled-components";
import RegularText from "../../../../ui/Typography/RegularText";

export const Message = styled(RegularText)`
  padding: 24px;
`;

export const Select = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
`;

export const ButtonsWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 24px;
  margin-top: 16px;
`;

export const GridWrapper = styled.div`
  max-height: 250px;
  overflow: auto;
`;

export const ModalWrapper = styled.div`
  overflow: hidden;
`;

export const VendorWrapper = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;
