import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { openCreatePurchaseOrderModal } from "../../../../../actions/quotingActions";
import Spec from "../../../../../models/Spec";
import UnassignedSpecsGrid from "./UnassignedSpecsGrid";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import Vendor from "../../../../../models/Vendor";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import Area from "../../../../../models/Area";

export const dataComponentId = "Quoting-PurchaseOrders-Unassigned";
const vendorsByProjectComponentId = "PO-Vendors";
const areasByProjectComponentId = "PO-Areas";

export class UnassignedSpecsContainer extends Component {
  componentDidMount() {
    const {
      initDataComponent,
      performRetrieveListRequest,
      projectId,
    } = this.props;

    initDataComponent(
      vendorsByProjectComponentId,
      Vendor,
      ["purchaseOrders", "bidGroupVendors.bidGroup"],
      "vendors",
      false,
      "v2"
    );

    performRetrieveListRequest(vendorsByProjectComponentId, {
      rootFilters: {
        $where: {
          "specs.projectId": projectId,
        },
      },
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
      fields: ["vendors.id", "name", "scopeId"],
    });

    initDataComponent(
      areasByProjectComponentId,
      Area,
      [],
      "areas",
      false,
      "v2"
    );

    performRetrieveListRequest(areasByProjectComponentId, {
      rootFilters: {
        $where: {
          projectId,
        },
      },
      sort: [{ columnName: "name", direction: "asc" }],
      pageSize: -1,
      fields: ["areas.id", "name"],
    });
  }

  openCreatePOModal = () => {
    const { projectId, dataComponent } = this.props;
    this.props.openCreatePurchaseOrderModal(
      dataComponent.selectedIds,
      projectId,
      null,
      dataComponent.dataComponentId,
      this.props.scopeId
    );
  };

  render() {
    const {
      projectId,
      dataComponent,
      role,
      specCategories,
      onOpenSpecDetail,
      isClosedProject,
      vendors,
      areas,
      specs,
    } = this.props;
    return (
      <UnassignedSpecsGrid
        isClosedProject={isClosedProject}
        handleCreatePurchaseOrderClick={this.openCreatePOModal}
        onOpenSpecDetail={onOpenSpecDetail}
        projectId={projectId}
        role={role}
        dataComponent={dataComponent}
        apiRoute="specs"
        model={Spec}
        includes={[
          "area",
          "project",
          "specCategory",
          "projectCurrency.currency",
          "unitOfMeasure",
          "vendor",
        ]}
        specCategories={specCategories}
        vendors={vendors}
        specs={specs}
        areas={areas}
      />
    );
  }
}

UnassignedSpecsContainer.propTypes = {
  role: propTypes.userRole,
  project: propTypes.project,
  scopeId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  openCreatePurchaseOrderModal: PropTypes.func.isRequired,
  specCategories: PropTypes.array,
  onOpenSpecDetail: PropTypes.func,
  isClosedProject: PropTypes.bool,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  vendors: PropTypes.array,
  areas: PropTypes.array,
  specs: PropTypes.array,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const specs = getBluechipResources(dataComponent, state);

  const vendorsDataComponent = getDataComponentFlattenedRequestState(
    vendorsByProjectComponentId,
    state
  );

  const areasDataComponent = getDataComponentFlattenedRequestState(
    areasByProjectComponentId,
    state
  );

  return {
    dataComponent,
    vendors: getBluechipResources(vendorsDataComponent, state),
    areas: getBluechipResources(areasDataComponent, state),
    specs,
    scopeId: state.scope.id,
  };
};

export const mapDispatchToProps = {
  openCreatePurchaseOrderModal,
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedSpecsContainer);
