import React, { useContext } from "react";
import styled from "styled-components";
import _get from "lodash/get";

import { DetailAndCycle, NavigationArea } from "../../../layout/DetailAndCycle";
import propTypes from "../../../../constants/propTypes";
import PropTypes from "prop-types";
import TitleArea from "./TitleArea";
import TagText from "../../../ui/Tags/TagText";
import ActionsSection from "./ActionsSection";
import { ActionButtons } from "../../../forms";
import { LoaderContext } from "../../../ui/Loader";

const StatusTag = styled(TagText)`
  display: inline-block;
  margin-left: 16px;
  align-self: center;
  vertical-align: middle;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  flex: 2;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DetailAndCycleContainer = ({
  invoice,
  nextItemRoute,
  lastItemRoute,
  onOpenNotesModal,
  projectId,
  generateAndDownloadReport,
  updateInvoice,
  dataComponentId,
  allChangesLocked,
}) => {
  const { loading: isLoading } = useContext(LoaderContext);

  return (
    <DetailAndCycle>
      <NavigationArea
        nextItemRoute={nextItemRoute}
        lastItemRoute={lastItemRoute}
        onOpenNotesModal={onOpenNotesModal}
        hideActions
      />
      <HeaderWrapper>
        <TitleWrapper>
          <TitleArea title={`Invoice #${_get(invoice, "number") || ""}`}>
            {_get(invoice, "paymentConfirmed") && (
              <StatusTag intent="success">Payment Confirmed</StatusTag>
            )}
          </TitleArea>
          <ActionsSection
            invoiceId={invoice?.id}
            projectId={projectId}
            generateAndDownloadReport={generateAndDownloadReport}
          />
        </TitleWrapper>
        <ButtonsWrapper>
          <ActionButtons
            sendButtonText={"Update"}
            onSend={(_, values) => updateInvoice(values)}
            disabled={allChangesLocked}
            listeners={[dataComponentId]}
            hideCancelButton={true}
            additionalProps={{
              send: { disableIfProjectClosed: true, isLoading },
            }}
          />
        </ButtonsWrapper>
      </HeaderWrapper>
    </DetailAndCycle>
  );
};

DetailAndCycleContainer.propTypes = {
  invoice: propTypes.invoice,
  nextItemRoute: PropTypes.string,
  lastItemRoute: PropTypes.string,
  onOpenNotesModal: PropTypes.func.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  dataComponentId: PropTypes.string.isRequired,
  allChangesLocked: PropTypes.bool,
};

export default DetailAndCycleContainer;
