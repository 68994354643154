import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import { GridLocalSection } from "../../../layout/GridLocalSection";

import { columnsOptions } from "../DuplicateSpecs/columnOptions";
import tableComponents from "./tableComponents";
import GridMenu from "../ImportSpecs/GridMenu";

const GridLocal = styled(GridLocalSection)`
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  max-height: 300px;
`;

const columns = [
  "customNumber",
  "description",
  "specCategoryId",
  "areaId",
  "vendorId",
  "projectCurrencyId",
  "priceCents",
  "baseQuantity",
  "atticStock",
  "overagePercent",
  "unitOfMeasureId",
];

export const gridId = "copy-specs-grid-id";

const SpecsList = ({
  specs,
  setSpecs,
  setDisplayUpdateSpecs,
  projectCurrencies,
  specCategories,
  showSelectionColumn,
}) => {
  const headerOverride = showSelectionColumn ? (
    <GridMenu setDisplayUpdateSpecs={setDisplayUpdateSpecs} />
  ) : null;

  return (
    <GridLocal
      id={gridId}
      columnsOptions={columnsOptions(
        setSpecs,
        specs,
        projectCurrencies,
        specCategories
      )}
      rows={specs}
      columns={columns}
      gridConfig={{ pageSize: 0, totalRows: specs.length }}
      tableComponents={tableComponents(setSpecs, specs, true)}
      headerOverride={headerOverride}
      defaultSelection={{}}
      alwaysDisplayDetailRow
      showSelectionColumn={showSelectionColumn}
      showSelectAll={showSelectionColumn}
    />
  );
};
SpecsList.propTypes = {
  selectedAreas: PropTypes.shape({}),
  setSpecs: PropTypes.func.isRequired,
  setDisplayUpdateSpecs: PropTypes.func.isRequired,
  specs: PropTypes.arrayOf(propTypes.spec),
  projectCurrencies: PropTypes.array,
  specCategories: PropTypes.array,
  showSelectionColumn: PropTypes.bool,
};
export default SpecsList;
