import { useEffect, useState, useRef, useCallback } from "react";
import _debounce from "lodash/debounce";
import isMobile from "ismobilejs";

export const createHandleScroll = (element, setSticky, top) => () => {
  const isMobileBrowser = isMobile(window.navigator).any;
  if (!element.current) return;
  if (!top) return;

  if (isMobileBrowser) {
    const isCloseToStick = window.scrollY > top;
    if (isCloseToStick) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }
};

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const [top, setTop] = useState();
  const element = useRef(null);

  useEffect(() => {
    if (element.current && element.current.getBoundingClientRect && !top) {
      setTop(element.current.getBoundingClientRect().top);
    }
  }, [top, element]);

  const handleScroll = useCallback(
    createHandleScroll(element, setSticky, top),
    [top]
  );

  useEffect(() => {
    window.addEventListener("scroll", _debounce(handleScroll, 10));
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, element };
}

export default useSticky;
