import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BoldText, Text } from "./components";
import { sortById } from "../../../../../utils/sortUtils";
import Loader from "../../../../ui/Loader";
import { Checkbox as MuiCheckbox } from "@material-ui/core";

const Wrapper = styled.div`
  margin-left: 29px;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 9px;
`;

const DetailWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;

export const Checkbox = styled(MuiCheckbox)`
  color: white !important;
`;

const DetailRow = ({ specDetail }) => (
  <Row key={specDetail.id}>
    <BoldText>
      <Loader width="100px" height="16px" inverted={true}>
        {specDetail.title}
      </Loader>
    </BoldText>
    <Text>
      <Loader width="100px" height="16px" inverted={true}>
        {specDetail.description}
      </Loader>
    </Text>
  </Row>
);

DetailRow.propTypes = {
  specDetail: PropTypes.object,
};

export const getSpecDetailsToRender = specDetails =>
  sortById(specDetails).filter(specDetail => specDetail.type === "Description");

const Details = ({ specDetails, onSpecDetailsUpdate, loading }) => {
  let specDetailsToRender = [];

  if (loading) {
    specDetailsToRender = [
      { id: "1" },
      { id: "2" },
      { id: "3" },
      { id: "4" },
      { id: "5" },
    ];
  } else {
    specDetailsToRender = getSpecDetailsToRender(specDetails);
  }

  const handleCheckboxChange = specDetail => () => {
    specDetail.isSelected = !specDetail.isSelected;
    onSpecDetailsUpdate(specDetails);
  };

  return (
    <Wrapper>
      {specDetailsToRender.map(specDetail => (
        <DetailWrapper key={specDetail.id}>
          <Checkbox
            checked={specDetail.isSelected}
            onChange={handleCheckboxChange(specDetail)}
          />
          <DetailRow specDetail={specDetail} />
        </DetailWrapper>
      ))}
    </Wrapper>
  );
};

Details.propTypes = {
  specDetails: PropTypes.array,
  onSpecDetailsUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default Details;
