import * as v1Actions from "./v1";
import * as v2Actions from "./v2";

function invokeAction(action, dataComponent, args) {
  switch (dataComponent.dataComponentVersion) {
    case "v2": {
      return v2Actions[action](...args);
    }
    default: {
      return v1Actions[action](...args);
    }
  }
}

export function fetchList(dataComponent, options) {
  return invokeAction("fetchList", dataComponent, [dataComponent, options]);
}

export function fetchItem(dataComponent, options) {
  return invokeAction("fetchItem", dataComponent, [dataComponent, options]);
}

export function createItem(dataComponent, options) {
  return invokeAction("createItem", dataComponent, [dataComponent, options]);
}

export function patchItem(dataComponent, options) {
  return invokeAction("patchItem", dataComponent, [dataComponent, options]);
}

export function deleteItem(dataComponent, options) {
  return invokeAction("deleteItem", dataComponent, [dataComponent, options]);
}

export function importFromFile(dataComponent, options) {
  return invokeAction("importFromFile", dataComponent, [
    dataComponent,
    options,
  ]);
}
