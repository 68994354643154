import { blueChipActions } from "../store/blueChipConfig";
import { initDataComponent, fetchDataSuccess } from "./dataComponentActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";

import countryStateCitiesDb from "country-state-city";
import { UsaStates } from "usa-states";

import Country from "../models/Country";
import State from "../models/State";
import { createDataComponentResourceAction } from "./dataComponents/v2";

export const populateCountryResources = () => {
  return async dispatch => {
    const unsortedCountries = countryStateCitiesDb.getAllCountries();
    const us = unsortedCountries.find(country => country.sortname === "US");
    let countries = [
      us,
      ...unsortedCountries.filter(country => country.sortname !== "US"),
    ].map(country => ({
      id: country.name,
      type: "countries",
      attributes: {
        ...country,
        id: country.name,
      },
    }));

    const rowIndex = countries.map(country => country.id);

    dispatch(
      initDataComponent("select-countries", Country, [], null, true, "v2")
    );

    dispatch(
      createDataComponentResourceAction(
        "select-countries",
        REQUEST_TYPES.LIST,
        countries,
        {}
      )
    );

    dispatch(
      fetchDataSuccess("select-countries", REQUEST_TYPES.LIST, { rowIndex })
    );
  };
};

export const populateStateResources = () => {
  return dispatch => {
    let states = new UsaStates({ includeTerritories: true })
      .arrayOf("abbreviations")
      .map(state => ({
        id: state,
        type: "states",
        attributes: { name: state },
      }));
    const rowIndex = states.map(state => state.id);

    dispatch(initDataComponent("select-states", State, [], null, true));

    states = { data: states };
    blueChipActions.updateResources(states);

    dispatch(
      fetchDataSuccess("select-states", REQUEST_TYPES.LIST, { rowIndex })
    );
  };
};
