import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";
import Shipment from "../../../../../models/Shipment";
import EditShipmentForm from "./EditShipmentForm";
import {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { getBluechipResourceById } from "../../../../../utils/bluechipUtils";
import _get from "lodash/get";
import { LoaderContext } from "../../../../ui/Loader";

export const dataComponentId = "EditShipmentDC";

export class EditShipmentContainer extends Component {
  componentDidMount() {
    const { initDataComponent, performFindRequest, shipmentId } = this.props;
    initDataComponent(dataComponentId, Shipment, [], "shipments");

    if (shipmentId) {
      performFindRequest(dataComponentId, shipmentId);
    }
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  editShipment = ({ needsFollowUp, number, ...shipment }, formikActions) => {
    const {
      performUpdateRequest,
      shipmentId,
      shipmentActions,
      shipmentIndex,
      openModalDialog,
      shipmentsDataComponentId,
    } = this.props;

    if (shipmentActions && shipmentActions.isFromManageShipments()) {
      shipmentActions.editShipment(shipmentIndex, shipment);
      shipmentActions.openManageShipmentsModal(
        openModalDialog,
        shipmentsDataComponentId
      );
      return;
    }

    performUpdateRequest(dataComponentId, shipmentId, shipment);

    this.setState({ formikActions });
  };

  handleCancel = () => {
    const {
      shipmentActions,
      closeModalDialog,
      openModalDialog,
      shipmentsDataComponentId,
    } = this.props;
    if (shipmentActions && shipmentActions.isFromManageShipments()) {
      shipmentActions.openManageShipmentsModal(
        openModalDialog,
        shipmentsDataComponentId
      );
      return;
    }

    closeModalDialog();
  };

  render() {
    const { remainingQty, loading, shipment, localShipment, spec } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <EditShipmentForm
          shipment={localShipment || shipment}
          remainingQty={remainingQty}
          onSubmit={this.editShipment}
          loading={loading}
          onCancel={this.handleCancel}
          spec={spec}
          totalQuantityApprovedToPay={this.props.totalQuantityApprovedToPay}
        />
      </LoaderContext.Provider>
    );
  }
}

EditShipmentContainer.propTypes = {
  loading: PropTypes.bool,
  shipment: propTypes.shipment,
  shipmentId: PropTypes.string,
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  remainingQty: PropTypes.number.isRequired,
  localShipment: propTypes.shipment,
  shipmentActions: PropTypes.object,
  shipmentIndex: PropTypes.number,
  openModalDialog: PropTypes.func,
  shipmentsDataComponentId: PropTypes.string,
  spec: propTypes.spec.isRequired,
  totalQuantityApprovedToPay: PropTypes.number,
};

export const mapStateToProps = (state, { shipmentId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const loading = [
    _get(dataComponent, "requestState.find.loading"),
    _get(dataComponent, "requestState.update.loading"),
  ].some(value => value);
  return {
    dataComponent,
    loading,
    shipment: getBluechipResourceById(dataComponent, state, shipmentId),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  openModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditShipmentContainer);
