import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ResetPasswordPage from "./ResetPasswordPage";
import { updatePassword } from "../../../actions/authActions";
import SnackNotificationContainer from "../../ui/SnackNotifications/SnackNotificationContainer";
import { getAttr } from "../ForgotPassword/ForgotPasswordContainer";

export class ResetPasswordContainer extends Component {
  state = {
    formikActions: {},
  };

  componentDidUpdate({ updatingPassword: prevUpdatingPassword }) {
    const { errors, updatingPassword } = this.props;
    const { formikActions } = this.state;

    if (!errors && prevUpdatingPassword && !updatingPassword) {
      this.props.push("/");
    } else if (errors && formikActions.setSubmitting) {
      this.state.formikActions.setSubmitting(false);
    }
  }

  handleUpdatePassword = ({ verificationCode, password }, formikActions) => {
    const { updatePassword, email, callbackUrl } = this.props;
    updatePassword(
      verificationCode.replaceAll("-", ""),
      password,
      email,
      callbackUrl
    );
    this.setState({ formikActions });
  };

  render() {
    const { errors, loading } = this.props;
    return (
      <Fragment>
        <ResetPasswordPage
          errors={errors}
          loading={loading}
          handleUpdatePassword={this.handleUpdatePassword}
        />
        <SnackNotificationContainer />
      </Fragment>
    );
  }
}

ResetPasswordContainer.propTypes = {
  email: PropTypes.string,
  callbackUrl: PropTypes.string,
  errors: propTypes.errorPayload,
  push: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updatingPassword: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ auth, router }) => ({
  email: getAttr(router.location.query, "email"),
  callbackUrl: getAttr(router.location.query, "callback"),
  errors: auth.error,
  loading: auth.loading,
  updatingPassword: auth.updatingPassword,
});

const mapDispatchToProps = { updatePassword, push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
