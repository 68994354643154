import styled from "styled-components";
import Menu from "../../mui/core/Menu";
import Paper from "../../mui/core/Paper";
import Tabs from "../../mui/core/Tabs";
import Tab from "../../mui/core/Tab";
import { Title } from "../../ui/Typography/SectionTitle";

export const StyledPaper = styled(Paper)`
  min-height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const NavigationTabsWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const NavigationMoreMenuWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
export const StyledMenu = styled(Menu)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledTitle = styled(Title)`
  padding-left: 24px;
`;

export const StyledTabs = styled(Tabs)`
  background-color: rgb(255, 255, 255);
  & span.indicator {
    background-color: rgb(87, 171, 255) !important;
  }
`;

export const StyledTab = styled(Tab)`
  height: 76px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.38);
  text-transform: uppercase;

  & > span > span {
    width: auto;
    padding: 0 0 0 0px;
    margin-left: 0px;
  }

  & > span:first-child {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
  }

  &[aria-selected="true"] span {
    color: rgb(87, 171, 255);
  }
  & > span > svg {
    margin-right: 8px;
  }
`;
