import { useCallback, useEffect } from "react";

const omitTagNames = ["INPUT", "TEXTAREA"];

export default function useCloseOnEscape(isCloseOnEscapeEnabled, closeFn) {
  const handleKeyDown = useCallback(
    evt => {
      const { key } = evt;
      if (
        key === "Escape" &&
        isCloseOnEscapeEnabled &&
        !omitTagNames.includes(evt.target.tagName) &&
        !evt.target.cmView
      ) {
        closeFn();
      }
    },
    [closeFn, isCloseOnEscapeEnabled]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);
}
