import { connect } from "react-redux";

import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import { showSnackNotificationAction } from "../../../../../actions/layoutActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { SendRFQ, sendRfqDataComponentId } from "./SendRFQ";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { getResourcesV2 } from "../../../../../utils/bluechipUtils";

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(sendRfqDataComponentId, state);
  const sendRfqSummary = getResourcesV2(
    dataComponent,
    state,
    REQUEST_TYPES.UPDATE
  );
  return {
    dataComponent,
    sendRfqSummary,
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendRFQ);
