import * as actions from "../constants/ActionTypes";

export const setHeaderTitle = headerTitle => ({
  type: actions.LAYOUT_SET_HEADER_TITLE,
  payload: { headerTitle },
});

export const unsetHeaderTitle = () => ({
  type: actions.LAYOUT_UNSET_HEADER_TITLE,
});

export const openNavbarDrawer = () => ({
  type: actions.LAYOUT_OPEN_NAVBAR_DRAWER,
});

export const closeNavbarDrawer = () => ({
  type: actions.LAYOUT_CLOSE_NAVBAR_DRAWER,
});

export const openModalDialog = (
  title,
  elementTypeID,
  elementProperties,
  isShowCancelButton,
  isShowCloseIcon,
  additionalProps
) => ({
  type: actions.LAYOUT_OPEN_MODAL_DIALOG,
  payload: {
    title,
    elementTypeID,
    elementProperties,
    isShowCancelButton,
    isShowCloseIcon,
    ...additionalProps,
  },
});

export const changeModalDialogElementType = elementTypeID => ({
  type: actions.LAYOUT_CHANGE_MODAL_DIALOG_ELEMENT,
  payload: {
    elementTypeID,
  },
});
export const setModalDialogTitle = title => ({
  type: actions.SET_MODAL_DIALOG_TITLE,
  payload: {
    title,
  },
});

export function showSnackNotificationAction(message, hideDuration = 6000) {
  return {
    type: actions.LAYOUT_SHOW_SNACK_NOTIFICATION,
    payload: {
      message,
      hideDuration,
    },
  };
}

export function dismissActionRequired() {
  return {
    type: actions.LAYOUT_ACTION_REQUIRED_NOTIFICATION,
    payload: {
      actionRequiredDimissed: true,
    },
  };
}

export function showActionRequired() {
  return {
    type: actions.LAYOUT_ACTION_REQUIRED_NOTIFICATION,
    payload: {
      actionRequiredDimissed: false,
    },
  };
}

export function setAutoSaveComponentId(autoSaveComponentId) {
  return {
    type: actions.LAYOUT_SET_AUTOSAVE_COMPONENT,
    payload: autoSaveComponentId,
  };
}

export function setAutoSaveQueueId(autoSaveQueueId) {
  return {
    type: actions.LAYOUT_SET_AUTOSAVE_QUEUE,
    payload: autoSaveQueueId,
  };
}

export function clearAutoSaveId() {
  return dispatch => {
    dispatch(setAutoSaveComponentId(null));
    dispatch(setAutoSaveQueueId(null));
  };
}

export const closeModalDialog = () => ({
  type: actions.LAYOUT_CLOSE_MODAL_DIALOG,
});

export function dismissSnackNotificationAction() {
  return {
    type: actions.LAYOUT_DISMISS_SNACK_NOTIFICATION,
  };
}
