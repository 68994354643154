import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import Loader from "../../../../ui/Loader";
import propTypes from "../../../../../constants/propTypes";
import { Wrapper, Label, Value } from "./components";

const getFilename = files => {
  if (files.length === 0) return "";

  const filename = _get(files, "0.file.filename", "");
  const ext = filename.split(".").pop();
  const firstFilename =
    filename.length > 18
      ? `${filename.substr(0, 12)}...${filename.substr(
          filename.length - ext.length - 3
        )}`
      : filename;

  return files.length === 1
    ? firstFilename
    : `${firstFilename}, (${files.length})`;
};

export const FileLink = ({
  label,
  fileType,
  files,
  acceptImage,
  onOpenManager,
  isLabeled,
}) => {
  const filename = getFilename(files);

  const handleClick =
    filename || !isLabeled
      ? () => onOpenManager(label, fileType, acceptImage)
      : undefined;

  return (
    <Wrapper onClick={handleClick}>
      <Loader height="16px" width="250px">
        <Label>{label}:</Label>
        <Value>{filename || (!isLabeled && "Upload")}</Value>
      </Loader>
    </Wrapper>
  );
};

FileLink.propTypes = {
  label: PropTypes.string,
  fileType: PropTypes.string,
  onOpenManager: PropTypes.func,
  files: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, file: propTypes.file })
  ),
  acceptImage: PropTypes.bool,
  isLabeled: PropTypes.bool,
};

export default FileLink;
