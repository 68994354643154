import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { inputs } from "../../../../../inputs/inputConfigs";
import { FormGrid } from "../../../../../forms";
import Subheader from "../../../../../ui/Typography/Subheader";
import { parseFields } from "../SpecForm";

const DescriptionWrapper = styled.div`
  overflow: auto;
  margin-bottom: 24px;
`;

const PlaceHolder = styled(Subheader)`
  color: #979797;
`;

const DescriptionComponent = ({
  index,
  selectedInputName,
  setSelectedInputName,
}) => {
  const fields = useMemo(() => {
    const fields = {
      groups: [
        {
          items: [
            {
              element: <PlaceHolder>{index + 1}</PlaceHolder>,
              grid: { xs: 1 },
            },
            {
              input: {
                ...inputs.title,
                name: `specDetails[${index}].title`,
              },
              grid: { xs: 11 },
            },
            {
              element: <PlaceHolder />,
              grid: { xs: 1 },
            },
            {
              input: {
                ...inputs.description,
                name: `specDetails[${index}].description`,
                rows: 2,
              },
              grid: { xs: 11 },
            },
          ],
        },
      ],
    };
    parseFields(fields, selectedInputName, setSelectedInputName);
    return fields;
  }, [index, selectedInputName, setSelectedInputName]);

  return (
    <React.Fragment>
      <DescriptionWrapper>
        <FormGrid fields={fields} />
      </DescriptionWrapper>
    </React.Fragment>
  );
};
DescriptionComponent.propTypes = {
  index: PropTypes.number,
  selectedInputName: PropTypes.string,
  setSelectedInputName: PropTypes.func.isRequired,
};

export default DescriptionComponent;
