import { BaseModel } from "@blue-chip/core";
import { useEffect } from "react";

import {
  billShippingPaymentsDataComponentId,
  importShippingPaymentsDataComponentId,
} from "../../../../actions/shippingPaymentsActions";
import ShippingPayment from "../../../../models/ShippingPayment";

export const loadPayments = (
  performRetrieveListRequest,
  dataComponentId,
  projectId
) => {
  performRetrieveListRequest(dataComponentId, {
    rootFilters: {
      $where: {
        projectId,
      },
    },
    sort: [{ columnName: "id", direction: "asc" }],
    pageSize: -1,
  });
};

export const useInitComponents = initDataComponent => {
  useEffect(() => {
    initDataComponent(
      importShippingPaymentsDataComponentId,
      BaseModel,
      [],
      "shipping-payments",
      false,
      "v2"
    );
    initDataComponent(
      billShippingPaymentsDataComponentId,
      ShippingPayment,
      [],
      "projects/bill-shipping-payments",
      true,
      "v2"
    );
  }, [initDataComponent]);
};
