import { BaseModel } from "@blue-chip/core";
import User from "./User";
import Permission from "./Permission";
import UserRolePermission from "./UserRolePermission";

export default class UserRole extends BaseModel {
  static get belongsTo() {
    return [User];
  }

  static get hasMany() {
    return [Permission, UserRolePermission];
  }
}
