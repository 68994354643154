import React from "react";
import PropTypes from "prop-types";
import Settings from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import { TitleArea, PageHeader } from "../../layout/PageHeader";
import propTypes from "../../../constants/propTypes";
import LocationCombined from "../../ui/LocationCombined";
import { Button } from "../../layout/PageHeader/TitleArea";
import { ActionIcon } from "../ProjectDetail/components";

export const ConsultingComanyDetailHeader = ({
  consultingCompany,
  handleEditConsultingCompany,
  navigationTabs,
}) => {
  const { name, type, location } = consultingCompany;

  return (
    <PageHeader navigationTabs={navigationTabs}>
      <TitleArea title={name}>
        <Tooltip title="Edit">
          <Button onClick={handleEditConsultingCompany}>
            <ActionIcon Icon={Settings} />
          </Button>
        </Tooltip>
      </TitleArea>
      <div>
        <p>{type}</p>
      </div>
      <div>
        <p>
          <LocationCombined location={location} />
        </p>
      </div>
    </PageHeader>
  );
};

ConsultingComanyDetailHeader.propTypes = {
  handleEditConsultingCompany: PropTypes.func,
  handleDeleteConsultingCompany: PropTypes.func,
  consultingCompany: propTypes.consultingCompany,
  navigationTabs: propTypes.navigationTabs,
};
