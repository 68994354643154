import UserRole from "../UserRole";

describe("UserRole Model", () => {
  test("belongsTo relations", () => {
    expect(UserRole.belongsTo.map(relation => relation.name)).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(UserRole.hasMany.map(relation => relation.name)).toMatchSnapshot();
  });
});
