import React from "react";
import styled from "styled-components";
import BaseTab from "../../ProjectDetail/Accounting/BaseTab";

export const BarControls = styled.div`
  display: flex;
  align-items: center;
`;

export const BarLabel = styled.div`
  margin: 0 24px 0 48px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;

export const TableRow = styled(({ small, applyLastBorder, ...props }) => (
  <div {...props} />
))`
  justify-content: space-between;
  display: flex;
  flex: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const Value = styled.div`
  color: ${({ small }) => (small ? "#303030" : "rgba(0, 0, 0, 0.87);")};
  font-size: ${({ small }) => (small ? "auto" : "20px")};
`;

export const Title = styled.div`
  color: ${({ small }) =>
    small ? "rgba(0, 0, 0, 0.54)" : "rgba(0, 0, 0, 0.87)"};
`;

export const PriceCalculations = styled.div`
  @media (min-width: 768px) {
    display: flex;
    margin-top: 24px;
  }
`;

export const PriceCalculation = styled.div`
  @media (max-width: 768px) {
    margin-top: 12px;
  }
  @media (min-width: 768px) {
    padding-right: 63px;
  }
`;

export const Content = styled.div`
  font-family: "Open Sans Bold", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;

export const ColumnCard = styled(BaseTab)``;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .paper-root {
    flex: 0.49;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
