import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import Vendor from "../../../../../models/Vendor";
import { withLabeledTextOption } from "../../../../../withPORevision";
import propTypes from "../../../../../constants/propTypes";
import { poContactdataComponentId } from "../../../../../actions/purchaseOrdersContactActions";
import {
  genMapDispatchToProps,
  genMapStateToProps,
} from "../contactComponentsUtils";
import { getBluechipResourcesByType } from "../../../../../utils/bluechipUtils";
import VendorInfo from "./VendorInfo";

export const VendorInfoContainer = ({
  purchaseOrderContacts,
  purchaseOrder,
  vendorSpec,
  vendorContacts,
  spec,
  dataComponentId,
  disabledOptions,
  getVendor,
  fetchPurchaseOrderContacts,
  initDataComponent,
  ...props
}) => {
  useEffect(() => {
    initDataComponent(
      "PODetailVendor",
      Vendor,
      ["location"],
      "vendors",
      undefined,
      "v2"
    );
  }, [initDataComponent]);

  useEffect(() => {
    if (purchaseOrder.id) {
      if (!vendorSpec) {
        getVendor();
        fetchPurchaseOrderContacts(purchaseOrder.id);
      }
    }
  }, [
    fetchPurchaseOrderContacts,
    getVendor,
    purchaseOrder.id,
    purchaseOrder.vendorId,
    vendorSpec,
  ]);

  const vendor = props.vendor || {};
  return (
    <VendorInfo
      dataComponentId={dataComponentId}
      disabledOptions={disabledOptions}
      spec={spec}
      vendorSpec={vendorSpec}
      vendor={vendor}
      vendorContacts={vendorContacts}
      purchaseOrder={purchaseOrder}
      purchaseOrderContacts={purchaseOrderContacts}
    />
  );
};

VendorInfoContainer.defaultProps = {
  contacts: [],
  vendor: {},
  poContactDataComponent: {},
};

VendorInfoContainer.propTypes = {
  purchaseOrderContacts: PropTypes.arrayOf(propTypes.purchaseOrderContact),
  purchaseOrder: PropTypes.shape({
    id: PropTypes.string,
  }),
  vendor: PropTypes.shape({
    id: PropTypes.string,
  }),
  vendorId: PropTypes.string,
  loading: PropTypes.bool,
  getVendor: PropTypes.func.isRequired,
  disabledOptions: PropTypes.bool,
  dataComponentId: PropTypes.string,
  initDataComponent: PropTypes.func.isRequired,
  poContactDataComponent: PropTypes.object.isRequired,
  vendorContacts: PropTypes.arrayOf(propTypes.vendorContact),
  fetchPurchaseOrderContacts: PropTypes.func,
  spec: propTypes.spec,
  vendorSpec: PropTypes.bool,
};
const mapStateToProps = (state, ownProps) => {
  const { purchaseOrder, vendor, vendorSpec } = ownProps;
  return genMapStateToProps({
    state,
    vendorDC: "PODetailVendor",
    contactsDC: poContactdataComponentId,
    vendorId: _get(purchaseOrder, "vendorId"),
    vendor,
    vendorSpec,
    purchaseOrderContacts: getBluechipResourcesByType(
      poContactdataComponentId,
      state
    ),
    contactType: "vendor",
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { purchaseOrder } = ownProps;
  return genMapDispatchToProps({
    dispatch,
    vendorId: purchaseOrder && purchaseOrder.vendorId,
    vendorDC: "PODetailVendor",
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLabeledTextOption(VendorInfoContainer));
