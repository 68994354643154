import React, { useRef } from "react";

import PageSection from "../../../layout/PageSection";
import FormGrid from "../../../forms/FormGrid";
import fields from "./invoiceDataSectionFields";
import Grid from "./Grid";

const InvoiceDataSection = props => {
  const pageSectionRef = useRef();
  const { readOnly, setCurrentCellFunc } = props;

  return (
    <PageSection pageSectionRef={pageSectionRef} headerText="Invoice Data">
      <FormGrid {...props} fields={fields} />
      <Grid
        invoice={props.values}
        readOnly={readOnly}
        formikProps={props}
        setCurrentCellFunc={setCurrentCellFunc}
        pageSectionRef={pageSectionRef}
      />
    </PageSection>
  );
};

InvoiceDataSection.propTypes = {};

export default InvoiceDataSection;
