import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import _get from "lodash/get";

import RegularTextSmall from "../../../ui/Typography/RegularTextSmall";
import propTypes from "../../../../constants/propTypes";

import {
  getExcludedCOMIds,
  openCOM,
  openDescription,
} from "../addSpecDetailMenuItems";
import { PageContext } from "../selectors";

export const CompleteButtom = styled(Button)`
  padding: 0;
  text-transform: none;
`;

const ActionDetailLink = ({ visible, type, actions, specDetail }) => {
  const pageContext = useContext(PageContext);

  const handleClick = useCallback(() => {
    const spec = pageContext.spec;
    if (type === "COM") {
      const excludeComIds = getExcludedCOMIds(spec);
      openCOM({
        spec,
        specDetail,
        excludeComIds,
        openModalDialog: actions.openModalDialog,
        projectId: pageContext.projectId,
        clientId: pageContext.clientId,
        purchaseOrderId: _get(spec, "purchaseOrderId"),
      });
    }
    if (type === "Description") {
      openDescription({
        spec,
        specDetail,
        openModalDialog: actions.openModalDialog,
        purchaseOrderId: _get(spec, "purchaseOrderId"),
      });
    }
  }, [type, actions, pageContext, specDetail]);

  const isUpdated = specDetail.createdAt !== specDetail.updatedAt;
  if (!visible || isUpdated) return null;
  return (
    <CompleteButtom onClick={handleClick} disableRipple disableFocusRipple>
      <RegularTextSmall intent="light">Click to Complete</RegularTextSmall>
    </CompleteButtom>
  );
};

ActionDetailLink.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf(["COM", "Description"]),
  actions: PropTypes.shape({ openModalDialog: PropTypes.func }),
  specDetail: propTypes.specDetail,
};

export default ActionDetailLink;
