import React from "react";
import styled from "styled-components";

import EditableCell from "../../../ui/BWGrid/EditableCell";
import BWCell from "../../../ui/BWGrid/gridInternalComponents/BWCell";

export const StyledBWCell = styled(BWCell)``;

const StyledEditableCell = styled(EditableCell)``;

export const openNextFallback = (paperRef, openInvoiceDataInput) => () => {
  setTimeout(() => {
    const specInput = paperRef.current.nextElementSibling.querySelector(
      "input"
    );
    if (specInput) {
      return specInput.focus();
    }
    openInvoiceDataInput();
  }, 0);
};

export const openPrevFallback = paperRef => () => {
  setTimeout(
    () =>
      paperRef.current.previousElementSibling.querySelector("input").focus(),
    0
  );
};

export default (
  rowInputs,
  getInputName,
  openNextFallback,
  openPrevFallback
) => {
  return function CellWrapper({ children, ...props }) {
    const { column } = props;
    if (column.name !== "quantity") {
      return <StyledBWCell {...props}>{children}</StyledBWCell>;
    }
    const input = rowInputs[column.name];
    return (
      <StyledEditableCell
        {...props}
        input={input}
        getInputName={getInputName}
        openNextFallback={openNextFallback}
        openPrevFallback={openPrevFallback}
      >
        {children}
      </StyledEditableCell>
    );
  };
};
