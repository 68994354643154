import React, { useEffect } from "react";

export default function withScrollOnLeave(Component) {
  const WithScrollOnLeaveWrapper = props => {
    useEffect(
      () => () => {
        window.scrollTo(0, 0);
      },
      []
    );

    return <Component {...props} />;
  };

  WithScrollOnLeaveWrapper.propTypes = {};

  return WithScrollOnLeaveWrapper;
}
