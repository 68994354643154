import { inputs } from "../../../../inputs/inputConfigs";

const quoteNoteFields = disabled => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.statusComment,
            label: "Note",
            name: "notes",
            required: true,
            rows: 5,
            disabled: disabled,
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

export default quoteNoteFields;
