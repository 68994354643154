import React from "react";
import PropTypes from "prop-types";

import { DataTypeProvider } from "@devexpress/dx-react-grid";
import RowMenu from "../../../../menus/RowMenu";

const RowMenuFormatter = ({ value: menuItems, row }) => {
  return <RowMenu row={row} menuItems={menuItems} />;
};

RowMenuFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  row: PropTypes.shape({}),
};

const RowMenuProvider = props => (
  <DataTypeProvider
    formatterComponent={RowMenuFormatter}
    {...props}
    for={["rowMenu"]}
  />
);

export default RowMenuProvider;
