import { BaseModel } from "@blue-chip/core";
import VendorContact from "./VendorContact";
import ConsultingCompanyContact from "./ConsultingCompanyContact";
import PurchaseOrder from "./PurchaseOrder";

export default class PurchaseOrderContact extends BaseModel {
  static plural = "purchaseOrdersContacts";

  static get belongsTo() {
    return [VendorContact, ConsultingCompanyContact, PurchaseOrder];
  }
}
