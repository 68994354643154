import React from "react";
import PropTypes from "prop-types";
import _camelCase from "lodash/camelCase";
import _get from "lodash/get";

import Mention from "./Mention";
import propTypes from "../../../../constants/propTypes";

const ParsedMentions = ({ mentions, users }) => {
  return mentions.map((mention, key) => {
    if (!/^\[(~\d+)\]$/.test(mention)) return mention;
    const user = users.find(user => `[~${user.id}]` === mention);
    return <Mention key={key} value={`@${_camelCase(user.name)}`} />;
  });
};

const DetailContainer = ({ detail, users }) => {
  if (!detail) return null;
  if (users.length <= 0) return detail;

  const mentionMatchers = users.map(({ id }) => `\\[\\~${id}\\]`).join("|");
  const regExp = RegExp(`(${mentionMatchers})`, "g");
  return <ParsedMentions mentions={detail.split(regExp)} users={users} />;
};

DetailContainer.propTypes = {
  detail: PropTypes.string,
  users: PropTypes.arrayOf(propTypes.user),
};

DetailContainer.defaultProps = {
  users: [],
};

const NoteDetail = ({ note }) => {
  return (
    <DetailContainer
      detail={_get(note, "detail")}
      users={_get(note, "users")}
    />
  );
};

NoteDetail.defaultProps = {
  note: {
    detail: "",
  },
};

NoteDetail.propTypes = {
  note: propTypes.note,
};

export default NoteDetail;
