import React from "react";
import PropTypes from "prop-types";
import _groupBy from "lodash/groupBy";
import _startCase from "lodash/startCase";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import _reduce from "lodash/reduce";
import styled, { ThemeProvider } from "styled-components";

import Loader, { LoaderContext } from "../../../../ui/Loader/Loader";
import propTypes from "../../../../../constants/propTypes";
import Formatter from "./Formatter";
import {
  ActivitiesWrapper,
  RevisionContainer,
  RevisionNotes,
  ActivityContainer,
  Attribute,
  Previous,
  Current,
  StyledArrow,
  InstanceTitle,
} from "./components";
import Person from "../../../../ui/Person/Person";
import theme from "../../../../ui/Tags/theme";

const PersonWrapper = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

export const User = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <ThemeProvider theme={{ tag: theme.small }}>
      <PersonWrapper>
        <Loader height="28px" width="28px" borderRadius={12}>
          <Person person={user} />
        </Loader>
      </PersonWrapper>
    </ThemeProvider>
  );
};
User.propTypes = {
  user: propTypes.user,
};

const operationTitles = {
  create: "Created",
  delete: "Deleted",
};

const cleanColumnName = columnName => {
  return _startCase(columnName.replace(/(Id|Cents)$/, ""));
};

const loadingMock = n =>
  Array(n)
    .fill()
    .reduce(
      (val, _, i) => ({
        ...val,
        [i]: [
          {
            reference: "",
            operation: "",
            previous: null,
            current: null,
            title: "",
            user: {},
          },
        ],
      }),
      {}
    );

const parseValue = (name, value) => name || value;

const buildActivities = (purchaseOrder, groupByReference) =>
  _reduce(
    groupByReference,
    (result, activities, title) => {
      const firstOperation = activities[0].operation;
      if (firstOperation !== "update") {
        const { operation, user } = activities[0];
        result.push(
          <ActivityContainer key={`${title} ${operation}`}>
            <Attribute>
              <Loader height="20px" width="75px">
                {title}
              </Loader>
            </Attribute>
            <Loader height="20px" width="75px"></Loader>
            <StyledArrow />
            <Current>
              <Loader height="20px" width="150px">
                {operationTitles[operation]}
              </Loader>
            </Current>
            <User user={user} />
          </ActivityContainer>
        );
      } else {
        result.push(
          <InstanceTitle key={title}>
            <Loader height="20px" width="145px">
              {title}
            </Loader>
          </InstanceTitle>
        );
        activities.map(
          ({
            columnName,
            previous,
            previousName,
            current,
            currentName,
            operation,
            reference,
            user,
          }) => {
            if (operation === "update") {
              result.push(
                <ActivityContainer key={`${reference} ${columnName}`}>
                  <Attribute>{cleanColumnName(columnName)}</Attribute>
                  <Previous>
                    <Formatter
                      attribute={columnName}
                      value={parseValue(previousName, previous)}
                      purchaseOrder={purchaseOrder}
                    />
                  </Previous>
                  <StyledArrow />
                  <Current>
                    <Formatter
                      attribute={columnName}
                      value={parseValue(currentName, current)}
                      purchaseOrder={purchaseOrder}
                    />
                  </Current>
                  <User user={user} />
                </ActivityContainer>
              );
            }
          }
        );
      }

      return result;
    },
    []
  );

const RevisionActivities = ({
  revision,
  loading,
  changesMade = 5,
  purchaseOrder,
}) => {
  const groupByReference = loading
    ? loadingMock(changesMade)
    : _groupBy(
        _sortBy(_get(revision, "revisionActivities", []), "reference"),
        "title"
      );

  const items = buildActivities(purchaseOrder, groupByReference);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <RevisionContainer>
        <ActivitiesWrapper>{items || []}</ActivitiesWrapper>
        <RevisionNotes>
          <InstanceTitle>Revision Notes</InstanceTitle>
          <Loader height="80px" width="500px">
            {revision && revision.notes}
          </Loader>
        </RevisionNotes>
      </RevisionContainer>
    </LoaderContext.Provider>
  );
};

RevisionActivities.propTypes = {
  revision: propTypes.revision,
  purchaseOrder: propTypes.purchaseOrder,
  loading: PropTypes.bool,
  changesMade: PropTypes.number,
};

RevisionActivities.defaultProps = {
  revision: {
    revisionActivities: [],
  },
};

export default RevisionActivities;
