import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Content } from "../../../layout/Content";
import { BWGridAPI, GridHeader, GridRow, GridTable } from "../../../ui/BWGrid";
import { FormControlLabel, Paper, Switch } from "../../../mui/core";
import Project from "../../../../models/Project";
import propTypes from "../../../../constants/propTypes";
import {
  usersDataComponentId,
  officesDataComponentId,
} from "./ProjectsContainer";
import User from "../../../../models/User";
import Office from "../../../../models/Office";
import BulkActions from "../BulkActions";

import theme from "../../../ui/Tags/theme";
import { ThemeProvider } from "styled-components";
import { getColumnOptions, columns, getTableComponents } from "./gridProps";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import { useUserRoleCan } from "../../../hooks/useUserRole";

const rowMenu = ({ onOpenProjectSettings, onOpenProjectDetails }) => [
  { text: "Project Settings", onClick: onOpenProjectSettings },
  { separator: true },
  { text: "Go To Project", onClick: onOpenProjectDetails },
];

export const showAllProjectsSettings = {
  false: {
    statuses: [
      "Created",
      "Contracted",
      "On Hold",
      "In Closing",
      "Root - Master",
    ],
  },
};
showAllProjectsSettings.true = {
  statuses: [...showAllProjectsSettings.false.statuses, "Closed"],
};

const Projects = ({
  dataComponent,
  onOpenProjectSettings,
  onOpenProjectDetails,
  handleExport,
  setAutoSaveComponentId,
  users,
  offices,
  initDataComponent,
  performRetrieveListRequest,
}) => {
  const [showAllProjects, setShowAllProjects] = useState(false);

  const currencyFormatter = useCurrencyFormatter();

  useEffect(() => {
    initDataComponent(usersDataComponentId, User, [], "users");
    performRetrieveListRequest(usersDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fields: ["id", "firstName", "lastName", "name"],
    });
  }, [initDataComponent, performRetrieveListRequest]);

  useEffect(() => {
    initDataComponent(officesDataComponentId, Office, [], "offices");
    performRetrieveListRequest(officesDataComponentId, {
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fields: ["id", "name", "abbreviation"],
    });
  }, [initDataComponent, performRetrieveListRequest]);

  useEffect(() => {
    setAutoSaveComponentId(dataComponent.dataComponentId);
  }, [dataComponent.dataComponentId, setAutoSaveComponentId]);

  const handleSwitchStatuses = () => setShowAllProjects(!showAllProjects);

  const projectStatuses = showAllProjectsSettings[showAllProjects].statuses;
  const canEditSoftCloseDate = useUserRoleCan("edit-soft-close-date");

  return (
    <ThemeProvider theme={{ tag: theme.small }}>
      <Content>
        <Paper>
          <BWGridAPI
            dataComponent={dataComponent}
            model={Project}
            includes={["office", "projectMemberUsers.user", "property.entity"]}
            apiRoute="projects"
            apiFilters={{
              rootFilters: {
                $where: {
                  status: {
                    $in: projectStatuses,
                  },
                },
              },
              params: {
                modifiers: ["withAccountingTotals"],
              },
            }}
            defaultSorting={[{ columnName: "number", direction: "asc" }]}
            tableComponents={getTableComponents(
              dataComponent.dataComponentId,
              true,
              canEditSoftCloseDate
            )}
            alwaysDisplayDetailRow
          >
            <GridHeader
              headerText={`${dataComponent.totalRows} Projects`}
              headerOverride={<BulkActions handleExport={handleExport} />}
            />
            <GridTable
              columns={columns}
              columnOptions={getColumnOptions(
                users,
                offices,
                currencyFormatter
              )}
              rowMenu={rowMenu({
                onOpenProjectSettings,
                onOpenProjectDetails,
              })}
              showSelectionColumn={true}
              showSelectAll={true}
            />
            <GridRow>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAllProjects}
                    onChange={handleSwitchStatuses}
                    color="primary"
                  />
                }
                label="Show Closed Projects"
              />
            </GridRow>
          </BWGridAPI>
        </Paper>
      </Content>
    </ThemeProvider>
  );
};

Projects.propTypes = {
  dataComponent: propTypes.dataComponent,
  handleExport: PropTypes.func.isRequired,
  onOpenProjectSettings: PropTypes.func.isRequired,
  onOpenProjectDetails: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(propTypes.user),
  offices: PropTypes.arrayOf(propTypes.office),
  projectStatutes: PropTypes.array,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
};

export default Projects;
