// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BidGroupVendorSpecQuote Model belongsTo relations 1`] = `
Array [
  "BidGroupVendorSpec",
]
`;

exports[`BidGroupVendorSpecQuote Model hasMany relations 1`] = `
Array [
  "BidGroupVendorSpecQuoteItem",
]
`;
