import styled from "styled-components";
import {
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import PropTypes from "prop-types";
import React from "react";
import MomentUtils from "@date-io/moment";

const StyledInlineDatePicker = styled(props => (
  <MaterialDatePicker
    variant={"inline"}
    {...props}
    InputLabelProps={{ classes: { root: "label" }, shrink: false }}
    InputProps={{
      classes: {
        root: "input",
      },
    }}
  />
))`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
  &:first-child {
    margin-left: 0;
  }
  & .label {
    position: static;
    font-family: Open Sans, sans-serif;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    transform: unset;
  }
  & .input {
    font-family: Open Sans, sans-serif;
    color: #57abff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: right;
    width: 64px;
    margin-top: 0;
    margin-left: 8px;
    cursor: pointer;
    > input {
      padding: 4px 0;
    }
    &:after,
    &:before {
      content: unset;
    }
  }
  &:last-child {
    .input {
      margin-left: 25px;
    }
  }
  @media (min-width: 1450px) {
    &:first-child {
      margin-left: 24px;
    }
    &:last-child {
      .input {
        margin-left: 8px;
      }
    }
  }
`;

const DatePicker = ({ label, onChange, name, value }) => {
  const onChangeWrapper = value => {
    onChange({
      target: {
        value: value && value.toDate ? value.toDate() : value,
        name: name,
      },
    });
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StyledInlineDatePicker
        label={label}
        onChange={onChangeWrapper}
        format="MM-DD-YY"
        value={value}
        emptyLabel="00-00-00"
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
};

export default DatePicker;
