import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TermPage from "./TermPage";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../../reducers/dataComponentReducer";
import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { reorderTerms } from "../../../../actions/termsActions";
import { setReload } from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import {
  processListRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";
import { SCOPES_SELECT } from "../../../../constants/DataComponents";

export const dataComponentId = "TermsGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  setReload,
  setTotalRows
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      setReload(dataComponentId, true);
    },
  });
  processListRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: setTotalRows,
  });
};

const getAttrs = (maxPosition, termContext, contextValue) => ({
  maxPosition,
  params: { termContext, contextValue },
});

const updateTermsMsg =
  "Please update the Entity Terms to view the recent Terms changes on the latest Project Documents";

const getOpenCreateModal = ({
  totalRows,
  termContext,
  contextValue,
  scopeId,
  openModalDialog,
  showSnackNotificationAction,
}) => filterScopeId => {
  openModalDialog(
    "Create Term",
    "CreateTerm",
    {
      ...getAttrs(totalRows.current + 1, termContext, contextValue),
      scopeId: scopeId || filterScopeId,
      onSuccess: () => {
        totalRows.current = totalRows.current + 1;
        showSnackNotificationAction(updateTermsMsg);
      },
    },
    false
  );
};

const TermContainer = ({
  dataComponent,
  flattenedDataComponent,
  openModalDialog,
  termContext,
  contextValue,
  scopeId,
  setReload,
  reorderTerms,
  additionalFilters,
  disableSyncGlobalTerms,
  disableAddTerm,
  showSnackNotificationAction,
  scopes,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const totalRows = useRef(flattenedDataComponent.totalRows);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);
  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      setReload,
      () => (totalRows.current = flattenedDataComponent.totalRows)
    );
  });

  const handleOpenCreateModal = useCallback(
    getOpenCreateModal({
      totalRows,
      contextValue,
      openModalDialog,
      scopeId,
      showSnackNotificationAction,
      termContext,
    }),
    [
      openModalDialog,
      termContext,
      contextValue,
      scopeId,
      showSnackNotificationAction,
    ]
  );

  const handleOpenEditModal = useCallback(
    term => {
      openModalDialog(
        `Edit Term`,
        "EditTerm",
        {
          term,
          ...getAttrs(totalRows.current, termContext, contextValue, totalRows),
          onSuccess: () => {
            showSnackNotificationAction(updateTermsMsg);
          },
        },
        false
      );
    },
    [openModalDialog, termContext, contextValue, showSnackNotificationAction]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, content }) => {
      const title =
        content.length > 30 ? `${content.substr(0, 30)}...` : content;
      openModalDialog(
        `Delete ${title}`,
        "StandardDelete",
        {
          id,
          title,
          dataComponentId,
          params: { termContext, contextValue },
          onSuccess: () => {
            totalRows.current = totalRows.current - 1;
            showSnackNotificationAction(updateTermsMsg);
          },
        },
        false
      );
    },
    [openModalDialog, termContext, contextValue, showSnackNotificationAction]
  );

  const handleOpenBulkDeleteModal = useCallback(() => {
    openModalDialog(
      "Delete Terms",
      "StandardBulkDeleteConfirmation",
      {
        dataComponentId,
        selectedIds: flattenedDataComponent.selectedIds,
        params: { termContext, contextValue },
        onSuccess: () => {
          totalRows.current =
            totalRows.current - flattenedDataComponent.selectedIds.length;
          showSnackNotificationAction(updateTermsMsg);
        },
      },
      false
    );
  }, [
    openModalDialog,
    flattenedDataComponent.selectedIds,
    termContext,
    contextValue,
    showSnackNotificationAction,
  ]);

  const handleUpdateEntityTerms = useCallback(() => {
    openModalDialog(
      `Update All Entity Terms`,
      "BulkUpdateTerms",
      { isGlobal: true },
      false,
      false
    );
  }, [openModalDialog]);

  const handleReorder = useCallback(
    ({ newIndex }, item) => {
      showSnackNotificationAction(updateTermsMsg);
      reorderTerms(dataComponentId, item.id, newIndex, {
        termContext,
        contextValue,
      });
    },
    [showSnackNotificationAction, reorderTerms, termContext, contextValue]
  );

  return (
    <TermPage
      dataComponent={flattenedDataComponent}
      additionalFilters={additionalFilters}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      onOpenBulkDeleteModal={handleOpenBulkDeleteModal}
      onUpdateEntityTerms={handleUpdateEntityTerms}
      onReorder={handleReorder}
      termContext={termContext}
      contextValue={contextValue}
      disableSyncGlobalTerms={disableSyncGlobalTerms}
      disableAddTerm={disableAddTerm}
      scopes={scopes}
    />
  );
};

TermContainer.propTypes = {
  additionalFilters: PropTypes.shape({}),
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  reorderTerms: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  termContext: PropTypes.string,
  contextValue: PropTypes.string,
  disableSyncGlobalTerms: PropTypes.bool,
  disableAddTerm: PropTypes.bool,
  scopeId: PropTypes.string,
  scopes: PropTypes.array,
};

export const mapStateToProps = state => {
  const scopesDataComponent = getDataComponentFlattenedRequestState(
    SCOPES_SELECT,
    state
  );
  return {
    flattenedDataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    dataComponent: getDataComponent(dataComponentId, state),
    scopes: getResourcesV2(scopesDataComponent, state) || [],
  };
};

export const mapDispatchToProps = {
  openModalDialog,
  setReload,
  reorderTerms,
  showSnackNotificationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermContainer);
