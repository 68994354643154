import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Send from "@material-ui/icons/Send";

import CurrentAddresses from "./CurrentAddresses";
import { PaperRightColumn, SectionHeaderWithLink } from "../components";
import Subheader from "../../../../ui/Typography/Subheader";
import { BWActionButton } from "../../../../ui/BWActionButton";
import propTypes from "../../../../../constants/propTypes";
import { useCallbacks } from "./ShipToSiteFunctions";
import withLabeledTextOption from "../../../../../withPORevision/withLabeledTextOption";
import { useLabeledText } from "../../../../inputs/TextInputWithError";
import Loader from "../../../../ui/Loader";
import Contacts from "../VendorInfo/Contacts";
import AddContactMenu from "./AddContactMenu/AddContactMenu";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";

const StyledPaper = styled(PaperRightColumn)`
  min-height: 172px;
`;

const CheckboxWrapper = styled.div`
  padding: 12px 16px 16px 12px;
`;

export const ShipToSiteContainer = ({
  openModalDialog,
  shipAddress,
  shipVendor,
  shipVendorAddressId,
  shipVendorAddress,
  shipAddressId,
  formik,
  purchaseOrderContacts,
}) => {
  const { values, setFieldValue } = formik;
  const [onSelectSite, onShowManagerRoutingInstructionsChange] = useCallbacks(
    openModalDialog,
    setFieldValue,
    values,
    shipVendorAddress
  );

  const labelexText = useLabeledText();

  return (
    <StyledPaper>
      <SectionHeaderWithLink>
        <Subheader required emphasizeRequired>
          Shipping
        </Subheader>
        <Loader width="100px" height="24px">
          <BWActionButton
            icon={<Send />}
            text="SELECT LOCATION"
            onClick={onSelectSite}
            disableIfProjectClosed
            disabled={labelexText}
          />
        </Loader>
      </SectionHeaderWithLink>
      <CurrentAddresses
        shipAddress={shipAddress}
        shipAddressId={shipAddressId}
        shipVendor={shipVendor}
        shipVendorAddressId={shipVendorAddressId}
        shipVendorAddress={shipVendorAddress}
      />
      {shipAddressId && (
        <Contacts
          vendor={{ id: "1" }}
          projectContacts={purchaseOrderContacts
            .filter(({ contactType }) => contactType === "projectSite")
            .map(({ projectSiteContact }) => projectSiteContact)}
          purchaseOrderContacts={purchaseOrderContacts}
          vendorContacts={[]}
          displayAddContact
          AddContactMenuComponent={AddContactMenu}
          shipAddressId={shipAddressId}
          required={false}
        />
      )}
      <CheckboxWrapper>
        <CheckboxWithError
          label={
            'Show "Contact Freight Manager for Routing Instructions" on PDF'
          }
          value={values.showManagerRoutingInstructions}
          onChange={onShowManagerRoutingInstructionsChange}
          disabled={labelexText}
        />
      </CheckboxWrapper>
    </StyledPaper>
  );
};

ShipToSiteContainer.propTypes = {
  openModalDialog: PropTypes.func.isRequired,
  formik: propTypes.formik,
  shipAddressId: PropTypes.string,
  shipAddress: propTypes.shipToSite,
  shipVendorAddressId: PropTypes.string,
  shipVendor: propTypes.vendor,
  shipVendorAddress: propTypes.shipToSite,
  purchaseOrderContacts: PropTypes.array,
};

ShipToSiteContainer.defaultProps = {};

export default withLabeledTextOption(ShipToSiteContainer);
