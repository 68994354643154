import { createStore, compose, applyMiddleware } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";
import { batchDispatchMiddleware } from "redux-batched-actions";

export const history = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);
const middlewares = [thunk, reactRouterMiddleware, batchDispatchMiddleware];
const devMiddlewares = [reduxImmutableStateInvariant(), ...middlewares];

function configureStoreProd(initialState) {
  return createStore(
    createRootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...devMiddlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextReducer = require("../reducers").default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

// Remove development from the array if you need Redux dev tools.
const productionStoreEnvs = ["production", "test", "development"];

const configureStore = productionStoreEnvs.includes(process.env.NODE_ENV)
  ? configureStoreProd
  : configureStoreDev;

export default configureStore();
