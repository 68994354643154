import React from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import { createSelector } from "reselect";

import UserSelect from "./UserSelect";
import { consultingDCId } from "../../../inputs/inputConfigs/projects";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

const ConsultingContactSelect = ({ consultingCompanyId, ...props }) => {
  return <UserSelect {...props} />;
};

const mapStateToProps = () => {
  const contactSelector = createSelector(
    ({ consultingCompanyId }) => consultingCompanyId,
    ({ consultingCompanies }) => consultingCompanies,
    ({ memberType }) => memberType,
    (consultingCompanyId, consultingCompanies, memberType) => {
      const consultingCompany = consultingCompanies.find(
        ({ id }) => id === consultingCompanyId
      );
      return _get(consultingCompany, "consultingCompanyContacts", []).map(
        consultingCompanyContact => {
          return {
            memberType,
            contactId: consultingCompanyContact.contact.id,
            name: `${consultingCompanyContact.contact.firstName} ${consultingCompanyContact.contact.lastName}`,
          };
        }
      );
    }
  );
  return (state, { consultingCompanyId, memberType }) => {
    if (!consultingCompanyId) return { options: [] };
    const dataComponent = getDataComponentFlattenedRequestState(
      consultingDCId,
      state
    );

    const consultingCompanies =
      getBluechipResources(dataComponent, state) || [];

    return {
      options: contactSelector({
        consultingCompanies,
        consultingCompanyId,
        memberType,
      }),
    };
  };
};

export default connect(mapStateToProps)(ConsultingContactSelect);
