import React from "react";

import { inputs } from "../../../../inputs/inputConfigs";
import { AddCell, StyleDelete, ActionWrapper } from "./components";
import { isValidEmail } from "../../../Notes/NoteCreate/EmailInput";

export const rowInputs = {
  firstName: { ...inputs.firstName, required: true },
  lastName: { ...inputs.lastName, required: true },
  jobTitle: { ...inputs.contactRole, required: true },
  email: inputs.email,
  phone: { ...inputs.phone, required: true },
};

export const columns = [
  "firstName",
  "lastName",
  "jobTitle",
  "email",
  "phone",
  "actions",
];

export const newVendorContact = {
  contact: {
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    phone: "",
  },
};

export const renderColumnFactory = title => {
  return (row, column, data) => {
    if (!data) return;
    const { rowId } = data;
    if (rowId > 0) return row?.contact[column];
    return row.contact[column] || <AddCell>{`Add ${title}`}</AddCell>;
  };
};

export const getActionRender = (isAdding, handleDelete, labeledText) => (
  row,
  _,
  rowOption
) => {
  if (!rowOption || labeledText) return;
  const { rowId } = rowOption;
  const isCreateRow = rowId === 0 && isAdding;
  const showDeleteButton = rowId !== 0;

  return (
    row &&
    showDeleteButton && (
      <ActionWrapper>
        <StyleDelete onClick={() => handleDelete(isCreateRow, rowId)} />
      </ActionWrapper>
    )
  );
};

export const getHandleDelete = (
  setIsAdding,
  formik,
  values,
  isAdding,
  handleRemoveContact,
  handleSubmit
) => (isCreateRow, rowId) => {
  if (isCreateRow) return setIsAdding(false);
  const currentIndex = isAdding ? rowId - 1 : rowId;
  handleRemoveContact(currentIndex, formik, values);
  handleSubmit();
};

const beforeSave = ({ columnName, showSnackNotificationAction }) => value => {
  if (columnName === "email" && isValidEmail(value || "")) {
    showSnackNotificationAction(`"email" must be a valid email`);
  }
  return value;
};

export const getEditableOptions = ({
  columnName,
  rows,
  handleSubmit,
  hasEmptyValues,
  showSnackNotificationAction,
}) => ({
  beforeSave: beforeSave({ columnName, showSnackNotificationAction }),
  afterSave: (value, { rowId }) => {
    const { contact, ...row } = rows[rowId];
    const updatedRow = {
      ...row,
      contact: { ...contact, [columnName]: value },
    };
    if (updatedRow.id) {
      handleSubmit();
      return;
    }
    if (columnName === "phone" || !hasEmptyValues(updatedRow.contact)) {
      handleSubmit();
    }
  },
});

export const columnsOptions = (
  handleSubmit,
  handleRemoveContact,
  hasEmptyValues,
  {
    isAdding,
    setIsAdding,
    formik,
    values,
    rows,
    showSnackNotificationAction,
    labeledText,
  }
) => {
  const handleDelete = getHandleDelete(
    setIsAdding,
    formik,
    values,
    isAdding,
    handleRemoveContact,
    handleSubmit
  );

  return {
    firstName: {
      sortingEnabled: false,
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "firstName",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      render: renderColumnFactory("First Name"),
      width: "150px",
      title: "First Name *",
    },
    lastName: {
      sortingEnabled: false,
      editableOptions: getEditableOptions({
        columnName: "lastName",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      title: "Last Name *",
      render: renderColumnFactory("Last Name"),
      width: "100px",
      editable: true,
    },
    jobTitle: {
      sortingEnabled: false,
      title: "Job Title *",
      render: renderColumnFactory("Job Title"),
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "jobTitle",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "100px",
    },
    email: {
      sortingEnabled: false,
      render: renderColumnFactory("Email"),
      title: "Email *",
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "email",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "100px",
    },
    phone: {
      sortingEnabled: false,
      title: "Phone Number *",
      render: renderColumnFactory("phone"),
      editable: true,
      editableOptions: getEditableOptions({
        columnName: "phone",
        rows,
        handleSubmit,
        hasEmptyValues,
        showSnackNotificationAction,
      }),
      width: "150px",
    },
    actions: {
      width: "100px",
      title: " ",
      sortingEnabled: false,
      render: getActionRender(isAdding, handleDelete, labeledText),
    },
  };
};

export const getInputName = isAdding => (tableRow, column) => {
  if (!column) return;
  const defaultAttribute = `data[${tableRow.rowId}].contact.${column.name}`;
  if (!isAdding) return defaultAttribute;

  return tableRow.rowId === 0
    ? `newVendorContact.contact.${column.name}`
    : `data[${tableRow.rowId - 1}].contact.${column.name}`;
};
