import { appPageBuilder } from "./utils";
import Settings from "../components/pages/Settings";

const AppSettings = appPageBuilder(Settings, "Settings");

export default [
  {
    path: "/settings",
    component: AppSettings,
    exact: true,
  },
];
