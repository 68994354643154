import { inputs } from "../../../../../../components/inputs/inputConfigs";

export default descriptionField => {
  const mainFields = [
    {
      input: {
        ...inputs.baseName,
        label: "Name",
        required: true,
      },
      grid: { xs: 12 },
    },
  ];
  if (descriptionField) {
    mainFields.push({
      input: {
        ...inputs.description,
        label: "Site Description",
        limitType: false,
      },
      grid: { xs: 12 },
    });
  }
  return {
    groups: [
      {
        items: [
          ...mainFields,
          {
            input: {
              ...inputs.location,
              required: true,
            },
            grid: { xs: 12 },
          },
          { input: inputs.locationAddress2, grid: { xs: 12 } },
          {
            input: {
              ...inputs.locationCity,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationState,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationCountry,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationPostalCode,
              required: true,
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
