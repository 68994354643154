import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import styled from "styled-components";

import InputWrapper from "../../inputs/InputWrapper";
import propTypes from "../../../constants/propTypes";
import { hasError, getErrorMessage } from "../../../utils/formValidationUtils";
import Loader from "../../ui/Loader";

const Inputs = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  ${({ direction }) =>
    direction === "right" ? "margin-right" : "margin-left"}: 24px;
`;

const InputComponent = ({
  component: Component,
  input,
  preventSubmit,
  style,
  width,
  direction,
  ...props
}) => {
  if (Component) return <Component {...props} />;
  if (preventSubmit)
    return (
      <InputContainer style={style} width={width} direction={direction}>
        <InputWrapper {...input} />
      </InputContainer>
    );

  return (
    <InputContainer style={style} width={width} direction={direction}>
      <Field name={input.name}>
        {({ field, form }) => (
          <InputWrapper
            {...input}
            {...field}
            hasError={hasError(input.name, form)}
            errorMessage={getErrorMessage(input.name, form)}
          />
        )}
      </Field>
    </InputContainer>
  );
};

InputComponent.defaultProps = {
  width: 250,
  direction: "right",
};

InputComponent.propTypes = {
  component: propTypes.component,
  preventSubmit: PropTypes.bool,
  input: PropTypes.shape({}),
};

const ActionsArea = ({ inputs }) => {
  if (inputs.length === 0) {
    return null;
  } else {
    return (
      <Loader height="32px">
        <Inputs>
          {inputs.map((inputProps, i) => (
            <InputComponent key={i} {...inputProps} />
          ))}
        </Inputs>
      </Loader>
    );
  }
};

ActionsArea.propTypes = {
  inputs: PropTypes.array,
};

export default ActionsArea;
