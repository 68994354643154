import React, { useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import { push } from "connected-react-router";

import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import * as DATA_COMPONENTS from "../../../constants/DataComponents";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import {
  getBluechipResources,
  getBluechipResourceById,
} from "../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import { generateAndDownloadReport } from "../../../actions/reportsActions";

import { LoaderContext } from "../../ui/Loader";
import { Content } from "../../layout/Content";
import BidGroup from "../../../models/BidGroup";
import Project from "../../../models/Project";
import User from "../../../models/User";
import propTypes from "../../../constants/propTypes";
import {
  openModalDialog,
  setAutoSaveComponentId,
} from "../../../actions/layoutActions";
import BidGroupHeader from "./BidGroupHeader";
import VendorActionCard from "./VendorActionCard";
import withDisplayVendorControl from "./Analysis/ShowHideControl/withDisplayVendorControl";
import { bidGroupVendorDataComponentId } from "./Quotes/QuotesPage";
import withQuoteViewByControl from "./Quotes/ViewByControl/withQuoteViewByControl";

export const dataComponentId = "BidGroupDetailId";
export const userDataComponentId = "select-users";
export const dataComponentProjectId = "ProjectDetailBidGroup";

const defaultArray = [];

export const AlternateQuotesContext = React.createContext();

export const BidGroupContainer = ({
  route,
  performFindRequest,
  initDataComponent,
  setAutoSaveComponentId,
  lastBidGroupId,
  nextBidGroupId,
  openModalDialog,
  loading,
  bidGroup,
  performUpdateRequest,
  performRetrieveListRequest,
  generateAndDownloadReport,
  loaders,
  bidGroupVendors,
  paymentTerms,
  project,
  match: {
    params: { clientId, projectId, bidGroupId },
  },
}) => {
  const [showAlternateQuotes, setShowAlternateQuotes] = useState(false);

  useEffect(() => {
    initDataComponent(
      dataComponentId,
      BidGroup,
      [
        "bidGroupVendors.[contacts.contact,specs.[unitOfMeasure],bidGroupVendorSpecs.[quotes],vendor.[category,contacts.contact]]",
        "specs(withQuantityPrice).[unitOfMeasure,vendor,specCategory,specDetails.[specDetailComs.spec.[unitOfMeasure,specCategory,vendor]]]",
        "rfq.user",
      ],
      "bid-groups"
    );

    initDataComponent(
      dataComponentProjectId,
      Project,
      ["projectMemberUsers"],
      "projects"
    );
    initDataComponent(userDataComponentId, User, [], "users");
    performFindRequest(dataComponentId, bidGroupId, {
      $where: { projectId }, //projectId is necessary for retrieveing nextId/prevId within project
    });
    performFindRequest(dataComponentProjectId, projectId);
    performRetrieveListRequest(userDataComponentId, {
      pageSize: -1,
    });
  }, [
    bidGroupId,
    initDataComponent,
    performFindRequest,
    performRetrieveListRequest,
    projectId,
    setAutoSaveComponentId,
  ]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <AlternateQuotesContext.Provider
        value={{ showAlternateQuotes, setShowAlternateQuotes }}
      >
        <Content>
          <BidGroupHeader
            nextBidGroupId={nextBidGroupId}
            lastBidGroupId={lastBidGroupId}
            bidGroup={bidGroup}
            clientId={clientId}
            projectId={projectId}
          />
          <VendorActionCard
            bidGroup={bidGroup}
            generateAndDownloadReport={generateAndDownloadReport}
            bidGroupVendors={bidGroupVendors}
            paymentTerms={paymentTerms}
          />
          {renderRoutes(route.routers, {
            openModalDialog,
            loading,
            loaders,
            bidGroup,
            project,
            generateAndDownloadReport,
            performUpdateRequest,
            match: {
              params: { clientId, projectId, bidGroupId },
            },
          })}
        </Content>
      </AlternateQuotesContext.Provider>
    </LoaderContext.Provider>
  );
};

BidGroupContainer.propTypes = {
  lastBidGroupId: PropTypes.string,
  nextBidGroupId: PropTypes.string,
  performFindRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func,
  bidGroup: propTypes.bidGroup,
  route: propTypes.route,
  project: propTypes.project,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  paymentTerms: PropTypes.arrayOf(propTypes.paymentTerm),
  loading: PropTypes.bool,
  loaders: PropTypes.shape({}),
  match: PropTypes.shape({}).isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  generateAndDownloadReport: PropTypes.func,
  openModalDialog: PropTypes.func,
};

BidGroupContainer.defaultProps = {
  bidGroup: {
    bidGroupVendors: [],
  },
};

export const mapStateToProps = (
  state,
  {
    match: {
      params: { bidGroupId, projectId },
    },
  }
) => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const paymentTermsDC = getDataComponentFlattenedRequestState(
    DATA_COMPONENTS.PAYMENT_TERMS_SELECT,
    state
  );

  const bidGroupVendorsDataComponent = getDataComponentFlattenedRequestState(
    bidGroupVendorDataComponentId,
    state
  );
  const dataComponentProject = getDataComponentFlattenedRequestState(
    dataComponentProjectId,
    state,
    REQUEST_TYPES.FIND
  );
  const bidGroup = getBluechipResourceById(dataComponent, state, bidGroupId);
  const project = getBluechipResourceById(
    dataComponentProject,
    state,
    projectId
  );

  const { loaders } = state.reports || {};

  return {
    bidGroup: bidGroup || _noop(),
    loaders,
    project,
    loading: dataComponent.loading,
    nextBidGroupId: _get(dataComponent, "links.next.meta.id"),
    lastBidGroupId: _get(dataComponent, "links.last.meta.id"),
    paymentTerms: getBluechipResources(paymentTermsDC, state) || defaultArray,
    bidGroupVendors:
      getBluechipResources(bidGroupVendorsDataComponent, state) || defaultArray,
  };
};

export const mapDispatchToProps = {
  push,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  openModalDialog,
  setAutoSaveComponentId,
  performRetrieveListRequest,
  generateAndDownloadReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDisplayVendorControl(withQuoteViewByControl(BidGroupContainer)));
