import { appPageBuilder } from "../utils";
import VendorRFQs from "../../components/pages/VendorRFQs";
import QuoteDetail from "../../components/pages/VendorRFQs/QuoteDetail";
import SpecDetail from "../../components/pages/SpecDetail";

const AppRFQs = appPageBuilder(VendorRFQs, "Request For Quotes");
const AppQuoteDetail = appPageBuilder(QuoteDetail, "Request For Quotes");
const AppSpecDetail = appPageBuilder(SpecDetail, "Spec Detail", true);

export default [
  {
    path: "/rfqs",
    component: AppRFQs,
    exact: true,
  },
  { path: "/rfqs/:bidGroupVendorId", component: AppQuoteDetail, exact: true },
  {
    path: "/rfqs/:bidGroupVendorId/specs/:specId",
    component: AppSpecDetail,
    exact: true,
  },
];
