import { inputs } from "../../../inputs/inputConfigs";

export default {
  groups: [
    {
      items: [
        {
          input: inputs.rfqIssueDate,
          grid: { sx: 12, sm: 6, md: 3 },
        },
        {
          input: inputs.responseDate,
          grid: { sx: 12, sm: 6, md: 3 },
        },
        {
          input: inputs.rfqUserid,
          grid: { sx: 12, sm: 12, md: 3 },
        },
      ],
    },
  ],
};
