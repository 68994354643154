import styled from "styled-components";
import ChevronRight from "@material-ui/icons/ChevronRight";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  margin-bottom: 6px;
`;

export const LabelWrapper = styled.label`
  display: flex;
  align-content: center;
  cursor: ${({ disabled }) => !disabled && "pointer"};
`;

export const LabelArrow = styled(ChevronRight)`
  font-size: 16px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 10px;
  width: 16px;
  height: 16px;
`;
