import React, { useEffect } from "react";
import _get from "lodash/get";

import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  setModalDialogTitle,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { useCreateProcessRequests } from "../../../../../hooks/useProcessRequest";
import {
  performDuplicateSpecAction,
  specsGridDataComponentId,
  specShipmentsDataComponentId,
} from "../../../../../../actions/purchaseOrderSpecsActions";
import { fetchPurchaseOrder } from "../../../../../../actions/purchaseOrdersActions";
import { setReload } from "../../../../../../actions/dataComponentActions";
import SpecsGrid from "../SpecsGrid";

import pluralize from "pluralize";

const poSelector = state => state.purchaseOrder;

const DuplicateSpec = () => {
  const purchaseOrder = useAddSpecToPOStore(poSelector);
  const dataComponent = useDatacomponent(specsGridDataComponentId);

  const actions = useActions({
    setModalDialogTitle,
    closeModalDialog,
    setReload,
    showSnackNotificationAction,
    performDuplicateSpecAction,
    fetchPurchaseOrder,
  });

  useEffect(() => {
    actions.setModalDialogTitle([
      "Duplicate a spec",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ]);
  }, [actions, purchaseOrder]);

  const handleCreateSuccess = () => {
    actions.setReload(specsGridDataComponentId, true);
    actions.setReload(specShipmentsDataComponentId, true);
    actions.fetchPurchaseOrder(
      _get(purchaseOrder, "id"),
      _get(purchaseOrder, "project.id")
    );
    actions.showSnackNotificationAction(
      "The selected Specs has been created successfully"
    );
    actions.closeModalDialog();
  };

  useCreateProcessRequests(dataComponent, {
    onSuccess: handleCreateSuccess,
  });

  const handleDuplicate = ids => {
    actions.performDuplicateSpecAction(
      specsGridDataComponentId,
      ids,
      purchaseOrder
    );
  };

  return (
    <SpecsGrid
      filters={{ projectId: purchaseOrder.projectId }}
      submitTextFn={selected =>
        selected
          ? `Duplicate ${pluralize("Spec", selected, true)}`
          : "Duplicate Specs within Projects"
      }
      onSubmit={handleDuplicate}
      projectCurrency={purchaseOrder.projectCurrency}
    />
  );
};

export default DuplicateSpec;
