import { appPageBuilder, redirectTo } from "./utils";
import Accounting from "../components/pages/Accounting";
import Projects from "../components/pages/Accounting/Projects";
import ClosingSchedule from "../components/pages/Accounting/ClosingSchedule";

const AppAccounting = appPageBuilder(Accounting, "Accounting");

export default [
  {
    path: "/accounting",
    component: AppAccounting,
    exact: false,
    routers: [
      {
        path: "/accounting/projects",
        component: Projects,
        exact: true,
      },
      {
        path: "/accounting/closingSchedule",
        component: ClosingSchedule,
        exact: true,
      },
      //default
      {
        path: "/accounting",
        component: redirectTo("projects"),
        exact: true,
      },
    ],
  },
];
