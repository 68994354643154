import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ShipToSiteForm from "./ShipToSiteForm";
import { mapStateToProps, mapDispatchToProps } from "./connect";
import { useCallbacks, useEffects } from "./SelectShipToSiteFunctions";
import propTypes from "../../../../../../constants/propTypes";

export const SelectShipToSiteContainer = ({
  initDataComponent,
  dataComponent,
  performRetrieveListRequest,
  closeModalDialog,
  setFieldValue,
  purchaseOrder,
}) => {
  const prevDataComponent = useRef(dataComponent);
  useEffects(
    purchaseOrder.projectId,
    dataComponent,
    prevDataComponent,
    initDataComponent,
    performRetrieveListRequest,
    closeModalDialog
  );

  const [onSubmit] = useCallbacks(
    setFieldValue,
    closeModalDialog,
    purchaseOrder
  );

  return <ShipToSiteForm onSubmit={onSubmit} purchaseOrder={purchaseOrder} />;
};

SelectShipToSiteContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  purchaseOrder: propTypes.purchaseOrder,
  setFieldValue: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectShipToSiteContainer);
