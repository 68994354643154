import { Grid, Typography } from "@material-ui/core";
import { Field } from "formik";
import InputWrapper from "../../../../inputs/InputWrapper";
import {
  getErrorMessage,
  hasError,
} from "../../../../../utils/formValidationUtils";
import React from "react";
import { LinkWrapper, StyledLink } from "./index";
import PropTypes from "prop-types";
import { FieldContainer, Row } from "../StyledRow";

const SelectContactRow = ({ input, handleToggleCreateNewContact }) => {
  return (
    <Row>
      <Grid item xs={12}>
        <FieldContainer>
          <Field name={input.name}>
            {({ field, form }) => (
              <InputWrapper
                {...input}
                {...field}
                label={input.label}
                hasError={hasError(input.name, form)}
                errorMessage={getErrorMessage(input.name, form)}
              />
            )}
          </Field>
          <LinkWrapper>
            <Typography variant={"inherit"}>{"Can't find it?"}</Typography>
            <StyledLink onClick={handleToggleCreateNewContact}>
              Create a new Contact
            </StyledLink>
          </LinkWrapper>
        </FieldContainer>
      </Grid>
    </Row>
  );
};
SelectContactRow.propTypes = {
  input: PropTypes.object.isRequired,
  handleToggleCreateNewContact: PropTypes.func.isRequired,
};

export default SelectContactRow;
