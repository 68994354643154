import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../../../actions/layoutActions";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { dataComponentId } from "../../../connect";

export const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(dataComponentId, state),
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  closeModalDialog,
};
