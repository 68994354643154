import React from "react";
import PropTypes from "prop-types";

import CardRight from "../CardRight";
import Contacts from "../VendorInfo/Contacts";
import VendorHeader from "../VendorInfo/VendorHeader";
import propTypes from "../../../../../constants/propTypes";
import { SwitchDesigner } from "./SwitchDesigner";

const DesignerInfo = ({
  designer,
  purchaseOrderContacts,
  projectContacts,
  dataComponentId,
}) => {
  return (
    <CardRight>
      <VendorHeader
        dataComponentId={dataComponentId}
        vendor={designer || {}}
        disabledOptions
        title="Designer Information"
        emptyStateText=""
        required={false}
      />
      <SwitchDesigner designerId={designer?.id} />
      {designer ? (
        <Contacts
          vendor={designer}
          vendorContacts={purchaseOrderContacts}
          purchaseOrderContacts={purchaseOrderContacts}
          projectContacts={projectContacts}
          displayAddContact
          type="designer"
          required={false}
        />
      ) : null}
    </CardRight>
  );
};

DesignerInfo.propTypes = {
  designer: propTypes.vendor,
  dataComponentId: PropTypes.string,
  purchaseOrderContacts: PropTypes.arrayOf(propTypes.purchaseOrderContact),
  projectContacts: PropTypes.arrayOf(propTypes.projectContact),
};

export default DesignerInfo;
