import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Navbar } from "./Navbar";
import propTypes from "../../../constants/propTypes";
import {
  closeNavbarDrawer,
  openModalDialog,
} from "../../../actions/layoutActions";
import { openNotesModal } from "../../../actions/notesActions";

export const NavbarContainer = ({ pathname, actions, role }) => (
  <Navbar
    pathname={pathname}
    push={actions.push}
    openModalDialog={actions.openModalDialog}
    closeNavbarDrawer={actions.closeNavbarDrawer}
    openNotesModal={actions.openNotesModal}
    role={role}
  />
);

NavbarContainer.propTypes = {
  pathname: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    push: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeNavbarDrawer: PropTypes.func.isRequired,
    openNotesModal: PropTypes.func.isRequired,
  }),
  role: propTypes.userRole,
};

const mapStateToProps = ({ router: { location }, auth: { role } }) => {
  return {
    pathname: location.pathname,
    role,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { push, openModalDialog, closeNavbarDrawer, openNotesModal },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);
