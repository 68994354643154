// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BidGroupVendorSpec Model belongsTo relations 1`] = `
Array [
  "BidGroupVendor",
  "Spec",
]
`;

exports[`BidGroupVendorSpec Model hasMany relations 1`] = `
Array [
  "BidGroupVendorSpecQuote",
]
`;
