import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import propTypes from "../../../../../constants/propTypes";
import DeleteConfirmation from "../../../../ui/DeleteConfirmation";

const dataComponentId = "specDetailsSettings";

export class DeleteRequirementContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const {
      showSnackNotificationAction,
      formikArrayHelpers,
      requirement,
      index,
    } = this.props;

    data.errors.map(error => {
      formikArrayHelpers.insert(index, requirement);
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDelete = () => {
    const { index, formikArrayHelpers, closeModalDialog } = this.props;
    formikArrayHelpers.remove(index);
    closeModalDialog();
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.handleDelete} />;
  }
}

DeleteRequirementContainer.propTypes = {
  title: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  requirement: propTypes.requirement,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.number,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponent(dataComponentId, state),
});

export const mapDispatchToProps = {
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteRequirementContainer);
