import React from "react";
import SelectWithError from "../../../inputs/SelectWithError";

function getArrayOfValues(selectedValues, isMulti) {
  return !selectedValues ? [] : isMulti ? selectedValues : [selectedValues];
}

const UserSelect = ({ value: prevValue, memberType, onChange, ...props }) => {
  const filteredValue = prevValue.filter(
    user => user.memberType === memberType
  );
  const customOnChange = event => {
    const selectedValues = event.target.value;
    const arr = getArrayOfValues(selectedValues, props.isAutocompleteMulti);
    const diffVals = prevValue.filter(user => user.memberType !== memberType);

    return onChange({
      ...event,
      target: { ...event.target, value: [...diffVals, ...arr] },
    });
  };

  return (
    <SelectWithError
      {...props}
      value={props.isAutocompleteMulti ? filteredValue : filteredValue[0]}
      onChange={customOnChange}
    />
  );
};

export default UserSelect;
