import React, { Fragment, useRef } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

import {
  DetailLineWrapper,
  Button,
  TextArea,
  TextAction,
  Line,
  ChildrenSpan,
} from "./components";

const BasicLine = ({ children, value }) => {
  const spanRef = useRef(null);

  const handleCopy = () => {
    spanRef.current.select();
    document.execCommand("copy");
  };

  return (
    <Fragment>
      <TextArea role="presentation" ref={spanRef} value={value} readOnly />
      <ChildrenSpan>{children}</ChildrenSpan>
      <Button
        title="Copy to Clipboard"
        aria-label="Copy to Clipboard"
        onClick={handleCopy}
      >
        <TextAction>Copy</TextAction>
      </Button>
    </Fragment>
  );
};

BasicLine.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export const DetailLine = ({ label, value }) => {
  return (
    <DetailLineWrapper>
      <BasicLine label={label} value={value}>
        <NumberFormat
          value={value}
          displayType={"text"}
          format="(###) ###-####"
          renderText={formattedValue => formattedValue}
        />
      </BasicLine>
    </DetailLineWrapper>
  );
};

DetailLine.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export const DetailLineEmail = ({ value }) => {
  const handleClick = () => {
    window.open(`mailto:${value}`, "_blank");
  };

  return (
    <DetailLineWrapper>
      <BasicLine label="Email" value={value}>
        {value}
      </BasicLine>
      <Line>|</Line>
      <Button title="Send Email" aria-label="Send Email" onClick={handleClick}>
        <TextAction>Send Email</TextAction>
      </Button>
    </DetailLineWrapper>
  );
};

DetailLineEmail.propTypes = {
  value: PropTypes.string,
};
