import React, { useState, useEffect, useCallback } from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";

import Checkbox from "../mui/core/Checkbox";

export const StyledTextField = styled(TextField)`
  padding: 8px 0px;
  color: rgba(33, 33, 33, 0.6);
  font-size: 14px;
`;

const StyledInputAdornment = styled(InputAdornment)`
  padding: 17px 5px 17px 19px;
  color: rgba(33, 33, 33, 0.6);
`;

const StyledSufixAdornment = styled(InputAdornment)`
  padding: 17px 5px 17px 5px;
  color: rgba(33, 33, 33, 0.6);
  display: ${({ show }) => (show ? "auto" : "none")};
`;

const StyledListItemText = styled(ListItemText)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding: 0px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  margin-right: 4px;
`;

export const StyledListItem = styled(ListItem)`
  padding: 8px 4px;
`;

const DownIcon = styled(ArrowDropDownIcon)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
`;

export const StyledClearIcon = styled(ClearIcon)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
  cursor: pointer;
`;
export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 136px;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.54);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MenuWithSearch = ({ itemName, items, onChangeSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearInput = () => {
    setInputValue("");
    handleChangeField("");
  };

  const handleChangeField = useCallback(
    search => {
      const match = new RegExp(`${search}`, "i");
      const filtered = items.filter(({ label }) => match.test(label));
      setInputValue(search);
      setFilteredItems(filtered);
    },
    [items]
  );

  useEffect(() => {
    handleChangeField(inputValue);
  }, [handleChangeField, inputValue, items]);

  const checkedItems = filteredItems.filter(({ isChecked }) => isChecked);
  const title =
    checkedItems.length === filteredItems.length
      ? `Show All ${pluralize(itemName, filteredItems.length)}`
      : checkedItems.map(({ label }) => label).join(",");
  const showClear = inputValue.length;

  return (
    <div>
      <SelectContainer onClick={handleClick}>
        <span>{title}</span>
        <DownIcon />
      </SelectContainer>
      <Popover
        id="simple-popper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <List>
          <StyledTextField
            id="input-with-icon-textfield"
            placeholder="Search"
            onChange={event => handleChangeField(event.target.value)}
            value={inputValue}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <StyledInputAdornment position="start">
                  <SearchIcon />
                </StyledInputAdornment>
              ),
              endAdornment: (
                <StyledSufixAdornment position="start" show={showClear}>
                  <StyledClearIcon onClick={handleClearInput} />
                </StyledSufixAdornment>
              ),
            }}
            autoFocus
          />
          <Divider />
          {filteredItems.map(({ label, value, isChecked }) => (
            <StyledListItem
              dense
              button
              onClick={() =>
                onChangeSelected({ label, value, isChecked: !isChecked })
              }
              key={value}
            >
              <StyledListItemIcon>
                <Checkbox
                  color="primary"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": label }}
                  checked={isChecked}
                  value={value}
                />
              </StyledListItemIcon>
              <StyledListItemText id={label} primary={label} />
            </StyledListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

MenuWithSearch.defaultProps = {
  items: [],
};

MenuWithSearch.propTypes = {
  itemName: PropTypes.string,
  items: PropTypes.array,
  checkItem: PropTypes.func,
  onChangeItem: PropTypes.func,
  onChangeSelected: PropTypes.func,
};

export default MenuWithSearch;
