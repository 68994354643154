import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import {
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { connectInput } from "../../../withPORevision";
import { LabeledText } from "../LabeledText";
import InputLoader from "../../ui/Loader/InputLoader";
import { getShrinkLabelOption, useLabeledText } from "../TextInputWithError";
import TextField from "./TextField";
import { getRequiredStyle } from "../SelectWithError/SingleAutoCompleteSelect/components";
import {
  ALL_USED_DATE_FORMAT,
  PRIMARY_DATE_FORMAT,
} from "../../../constants/formats";

const components = {
  date: KeyboardDatePicker,
  clearableDate: KeyboardDatePicker,
  dateTime: KeyboardDateTimePicker,
  monthYear: KeyboardDatePicker,
};

const formats = {
  date: {
    mask: value =>
      value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [],
    format: PRIMARY_DATE_FORMAT,
    variant: "inline",
  },
  clearableDate: {
    mask: value =>
      value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [],
    format: PRIMARY_DATE_FORMAT,
  },
  dateTime: {
    variant: "inline",
    mask: [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      ":",
      /\d/,
      /\d/,
      " ",
      /[AaPp]/,
      /[Mm]/,
    ],
    format: `${PRIMARY_DATE_FORMAT} hh:mm A`,
  },
  time: {
    variant: "inline",
    mask: [/\d/, /\d/, ":", /\d/, /\d/, " ", /[AaPp]/, /[Mm]/],
    format: "hh:mm A",
  },
  monthYear: {
    variant: "inline",
    mask: [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    format: "MM/YYYY",
  },
};

const Wrapper = styled.div`
  ${({ splitDateTime }) =>
    splitDateTime &&
    `
    display: flex;
    justify-content: space-between;
  `}
`;

const onTimeChange = ({ setTime, value, onChange }) => newValue => {
  if (isValid(newValue)) {
    value.setHours(newValue.toDate().getHours());
    value.setMinutes(newValue.toDate().getMinutes());
    onChange(moment(value), true);
  }
  setTime(newValue);
};

const getViews = pickerType => {
  switch (pickerType) {
    case "dateTime":
      return ["year", "month", "date", "hour", "minute"];
    case "monthYear":
      return ["month", "year"];
    default:
      return ["year", "month", "date"];
  }
};

const getTextFieldComponent = pickerType => {
  if (pickerType !== "monthYear") {
    return TextField;
  }
};

const getFilteredProps = (
  pickerType,
  { keyboard, clearable, onChangeWrapper }
) => {
  if (pickerType === "monthYear") {
    return {};
  }

  return { keyboard, clearable, onChangeWrapper };
};

const Picker = ({
  pickerType: rawPickerType,
  pickerRef,
  wasUpdatedInRevision,
  isApplyOnChange,
  InputProps,
  InputAdornmentProps,
  splitDateTimePicker,
  label,
  value,
  keyboard,
  clearable,
  onChangeWrapper,
  isDisabled,
  ...props
}) => {
  const [time, setTime] = useState(value);
  const splitDateTime = rawPickerType === "dateTime" && splitDateTimePicker;
  const pickerType = splitDateTime ? "date" : rawPickerType;
  const Component = components[pickerType];
  const inputFormat = formats[pickerType];
  const timeFormat = formats.time;
  const { width } = useWindowDimensions();

  return (
    <Wrapper splitDateTime={splitDateTime}>
      <Component
        autoOk={true}
        variant={inputFormat.variant}
        ref={pickerRef}
        format={inputFormat.format}
        TextFieldComponent={getTextFieldComponent(pickerType)}
        {...props}
        {...getFilteredProps(pickerType, {
          keyboard,
          clearable,
          onChangeWrapper: props.onChange,
        })}
        label={splitDateTime ? "Date" : label}
        value={value}
        views={getViews(pickerType)}
        PopoverProps={{
          onClick: evt => {
            evt.stopPropagation();
            evt.preventDefault();
          },
        }}
        InputProps={{
          ...InputProps,
          classes: {
            root: "root",
            focused: "input-focused",
          },
        }}
        InputLabelProps={{
          ...getShrinkLabelOption(width),
          classes: {
            focused: "label-focused",
          },
          required: false,
        }}
        FormHelperTextProps={{
          classes: {
            root: "form-helper-text-root",
          },
        }}
        InputAdornmentProps={{
          ...InputAdornmentProps,
        }}
      />
      {splitDateTime && (
        <KeyboardTimePicker
          autoOk={true}
          variant={timeFormat.variant}
          ref={pickerRef}
          format={timeFormat.format}
          {...props}
          onChange={onTimeChange({ setTime, value, onChange: props.onChange })}
          label={splitDateTime ? "Time" : label}
          value={time}
          disabled={!value}
          PopoverProps={{
            onClick: evt => {
              evt.stopPropagation();
              evt.preventDefault();
            },
          }}
          InputProps={{
            classes: {
              root: "root",
              focused: "input-focused",
            },
          }}
          InputLabelProps={{
            ...getShrinkLabelOption(width),
            classes: {
              focused: "label-focused",
            },
            required: false,
          }}
          FormHelperTextProps={{
            classes: {
              root: "form-helper-text-root",
            },
          }}
        />
      )}
    </Wrapper>
  );
};

Picker.propTypes = { pickerType: PropTypes.string };
Picker.defaultProps = { pickerType: "date" };

export const StyledPicker = styled(Picker)`
  & .root {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    min-width: 135px;
    font-style: ${({ wasUpdatedInRevision }) =>
      wasUpdatedInRevision && "italic"};
  }

  & .input-focused:after {
    border-bottom-color: #57abff;
  }

  & .label-focused {
    color: rgba(0, 0, 0, 0.8);
  }

  & .form-helper-text-root {
    font-family: Open Sans, sans-serif;
  }

  ${({ wasUpdatedInRevision }) =>
    wasUpdatedInRevision &&
    `
  &:before {
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    color: #ff9100;
    top: 14px;
    left: -5px;
    overflow: visible;
  }
  `}

  ${props => getRequiredStyle(props)}
`;

const isValid = value => value && value.isValid && value.isValid();

const prepValue = value => {
  if (!value || !value.isValid()) return null;
  if (value.toDate) {
    const date = value.toDate();
    return isNaN(date.getTime()) ? date : value.toDate();
  }
  return value;
};

const ConnectedPicker = connectInput(StyledPicker);

const getDateFormatted = value =>
  value ? moment(value).format(PRIMARY_DATE_FORMAT) : "-";

/* Transforms the date when the user types in the input.
   For now it's only be applying for DateTime input.
*/
const transformDateIfNecessary = (value, onChange, datePickerProps) => {
  if (
    value &&
    typeof value === "string" &&
    datePickerProps.pickerType == "dateTime" &&
    !value.includes("_")
  ) {
    onChange({
      target: {
        value: prepValue(moment(value).utc(false)),
        name: datePickerProps.name,
      },
    });
  }
};

// eslint-disable-next-line complexity
const onChangeWrapper = ({ name, onChange, handleChange }) => (
  newValue,
  isTimePicker
) => {
  if (isTimePicker && typeof isTimePicker === "boolean" && !isValid(newValue)) {
    return;
  }
  const parsedNewValue = prepValue(
    newValue?.isMoment && newValue.isMoment()
      ? newValue
      : moment(newValue, ALL_USED_DATE_FORMAT).utc(false)
  );
  const event = {
    target: {
      value: parsedNewValue,
      name: name,
    },
  };
  onChange(event);
  handleChange && handleChange(event);
};

// eslint-disable-next-line complexity
const DatePickerWithError = ({
  label,
  onChange,
  value,
  error,
  errorMessage,
  ignoreCaption,
  ignoreLabeledText,
  handleChange,
  ...datePickerProps
}) => {
  useEffect(() => {
    transformDateIfNecessary(value, onChange, datePickerProps);
  }, [value, onChange, datePickerProps]);

  const isLabeledText = useLabeledText(ignoreLabeledText);

  if (isLabeledText) {
    const date = getDateFormatted(value);
    return <LabeledText label={label} texts={[date]} />;
  }

  return (
    <InputLoader>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConnectedPicker
          label={label}
          value={value ? value : null}
          onChange={onChangeWrapper({
            name: datePickerProps.name,
            onChange,
            handleChange,
          })}
          className="date-picker-with-error"
          error={error}
          helperText={ignoreCaption ? null : error ? errorMessage : " "}
          keyboard
          clearable
          {...datePickerProps}
        />
      </MuiPickersUtilsProvider>
    </InputLoader>
  );
};

DatePickerWithError.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DatePickerWithError;
