import React from "react";
import PropTypes from "prop-types";

import LocalGrid from "../../../../../ui/Grid/LocalGrid";
import { dateTypeBuilder } from "../../../../../ui/Grid/utilComponents";
import RowMenuProvider from "../../../../../ui/Grid/utilComponents/dataTypeProviders/RowMenuProvider";
import propTypes from "../../../../../../constants/propTypes";
import { PRIMARY_DATE_FORMAT } from "../../../../../../constants/formats";

export const downloadFile = row => {
  const downloadLink = document.createElement("a");
  downloadLink.href = row.s3SignedUrl;
  downloadLink.download = row.filename;
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  return downloadLink;
};

const getRowMenu = (onDelete, quoteStatus) => ({ isUpdated } = {}) => {
  const options = [];

  if (!isUpdated) {
    options.push({ text: "Download File", onClick: downloadFile });
  }

  const quoteIsSubmitted = quoteStatus === "submitted";
  if (!quoteIsSubmitted) {
    options.push({
      text: "Delete File",
      onClick: onDelete,
      disableIfProjectClosed: true,
    });
  }

  return options;
};

const QuoteFileGrid = ({ rows, onDelete, quoteStatus }) => {
  return (
    <LocalGrid
      gridId="quote-files"
      rows={rows}
      columns={[
        { name: "filename", title: "Filename", bold: true },
        { name: "createdAt", title: "Date Uploaded" },
        {
          type: "rowMenu",
          getCellValue: getRowMenu(onDelete, quoteStatus),
        },
      ]}
      tableComponents={{ GridWrapper: ({ children }) => children }}
      dataTypeProviders={[
        dateTypeBuilder(["createdAt"], PRIMARY_DATE_FORMAT),
        RowMenuProvider,
      ]}
    />
  );
};
QuoteFileGrid.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contractFile),
  onDelete: PropTypes.func,
  quoteStatus: PropTypes.string,
};

export default QuoteFileGrid;
