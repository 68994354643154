import { BaseModel } from "@blue-chip/core";
import Flag from "./Flag";
import Discount from "./Discount";

export default class Program extends BaseModel {
  static get belongsTo() {
    return [Flag];
  }
  static get hasMany() {
    return [Discount];
  }
}
