import TextInputWithError from "../../../inputs/TextInputWithError";

const commentsInput = {
  InputComponent: TextInputWithError,
  label: "Comments",
  name: "comments",
  fullWidth: true,
};

export default commentsInput;
