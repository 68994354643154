import React from "react";
import _get from "lodash/get";
import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const BWGridHeaderSortLabel = props => {
  const updatedProps = Object.assign({}, props);
  updatedProps.disabled =
    props.disabled || _get(props.column, "name") === "gridTableRowMenu";

  return <TableHeaderRow.SortLabel {...updatedProps} />;
};

export default BWGridHeaderSortLabel;
