import TextInputWithError from "../../inputs/TextInputWithError";
import CheckboxWithError from "../../inputs/CheckboxWithError";

export default {
  code: {
    InputComponent: TextInputWithError,
    label: "GL Code",
    name: "code",
    fullWidth: true,
  },
  glDescription: {
    InputComponent: TextInputWithError,
    label: "Description",
    name: "description",
    fullWidth: true,
  },
  isArchived: {
    InputComponent: CheckboxWithError,
    label: "Archived",
    name: "isArchived",
  },
};
