import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import pluralize from "pluralize";
import { connect } from "react-redux";

import Subheader from "../../../ui/Typography/Subheader";
import Loader from "../../../ui/Loader/Loader";
import FilterPanel from "./FilterPanel";
import FiltersLine from "./FiltersLine";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    padding-left: 24px;
    margin-top: 24px;
  }
`;

const MainLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const ListHeader = ({
  totalRows,
  search,
  displayFilters,
  setDisplayFilters,
}) => {
  return (
    <StyledHeader>
      <MainLine>
        <div>
          <Loader width="250px" height="30px">
            <Subheader>
              {pluralize("Note", totalRows, true)}
              {search && (
                <span>
                  {" "}
                  with <i>{search}</i>
                </span>
              )}
            </Subheader>
          </Loader>
        </div>
        <FilterPanel
          displayFilters={displayFilters}
          setDisplayFilters={setDisplayFilters}
        />
      </MainLine>
      {displayFilters && <FiltersLine />}
    </StyledHeader>
  );
};
ListHeader.defaultProps = {
  totalRows: 0,
};
ListHeader.propTypes = {
  totalRows: PropTypes.number.isRequired,
  search: PropTypes.string,
  setDisplayFilters: PropTypes.func,
  displayFilters: PropTypes.bool,
};

const mapStateToProps = ({
  notes: {
    filters: { search },
  },
}) => ({ search });

export default connect(mapStateToProps)(ListHeader);
