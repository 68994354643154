import PropTypes from "prop-types";
import styled from "styled-components";

const BaseTag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  padding: 2px 0;
  border-radius: 50%;
  border-width: 2px;
  cursor: ${({ onClick, onFocus }) =>
    onClick || onFocus ? "pointer" : "default"};
  border-style: solid;
  margin-left: ${({ isOverlap }) => (isOverlap ? "-6px" : "0")};
  color: #ffffff;
`;

BaseTag.propTypes = {
  isOverlap: PropTypes.bool,
};

BaseTag.defaultProps = {
  isOverlap: false,
};

export default BaseTag;
