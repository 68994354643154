import { inputs } from "../../../../components/inputs/inputConfigs";

export default (values, client) => {
  const address2Required =
    values?.location?.address?.toLowerCase().trim() ==
    "c/o benjamin west (as agent)";
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.name,
              required: true,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.location,
              required: true,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.locationAddress2,
              required: address2Required,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...inputs.locationCity,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationState,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationCountry,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.locationPostalCode,
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.federalId,
              label: client.scopeId === "2" ? "VAT" : inputs.federalId.label,
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
