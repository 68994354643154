import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ActionButtons from "../forms/ActionButtons";
import { Message } from "./DangerConfirmation";

const DuplicateConfirmation = ({ onSubmit, dataComponentId, title }) => {
  return (
    <Fragment>
      <Message>Are you sure you want to duplicate {title}?</Message>
      <ActionButtons
        listeners={[dataComponentId]}
        onSend={onSubmit}
        sendButtonText="DUPLICATE"
        isModal={true}
      />
    </Fragment>
  );
};

DuplicateConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  dataComponentId: PropTypes.string,
};

export default DuplicateConfirmation;
