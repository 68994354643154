import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";
import ConsultingCompaniesPage from "./ConsultingCompaniesPage";
import { fetchData } from "../../../actions/dataComponentActions";

const dataComponentId = "ConsultingCompanies";

export class ConsultingCompaniesContainer extends Component {
  componentDidUpdate({ isModalDialogOpen: prevIsModalDialogOpen }) {
    const { isModalDialogOpen, refreshDataComponent } = this.props;
    if (prevIsModalDialogOpen && !isModalDialogOpen) {
      refreshDataComponent();
    }
  }

  handleRowClick = ({ id }) => {
    this.props.push(`/consultingCompany/${id}`);
  };

  handleAddConsultingCompany = () => {
    this.props.openModalDialog(
      "Create Consulting Company",
      "CreateConsultingCompany",
      {},
      false
    );
  };

  handleEditConsultingCompany = ({ id, name }) => {
    this.props.openModalDialog(
      `Edit ${name}`,
      "EditConsultingCompany",
      {
        consultingCompanyId: id,
      },
      false
    );
  };

  handleDeleteConsultingCompany = ({ id, name }) => {
    this.props.openModalDialog(
      `Delete ${name}`,
      "DeleteConsultingCompany",
      {
        consultingCompanyId: id,
        title: name,
      },
      false
    );
  };

  handleRowClick = ({ id }) => {
    this.props.push(`consultingCompanies/${id}`);
  };

  render() {
    const { dataComponent } = this.props;

    return (
      <ConsultingCompaniesPage
        dataComponent={dataComponent}
        handleRowClick={this.handleRowClick}
        handleAddConsultingCompany={this.handleAddConsultingCompany}
        handleEditConsultingCompany={this.handleEditConsultingCompany}
        handleDeleteConsultingCompany={this.handleDeleteConsultingCompany}
      />
    );
  }
}

ConsultingCompaniesContainer.propTypes = {
  push: PropTypes.func.isRequired,
  isModalDialogOpen: PropTypes.bool,
  dataComponent: PropTypes.object.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  refreshDataComponent: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  isModalDialogOpen: state.layout.modalDialog.isOpen,
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  refreshDataComponent: () => fetchData(dataComponentId),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultingCompaniesContainer);
