import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BrandForm from "./BrandForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { reloadDataComponent } from "../../../../actions/initializeStoreActions";

import { LoaderContext } from "../../../ui/Loader";
import { BRANDS_SELECT } from "../../../../constants/DataComponents";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { dataComponentId } from "../BrandsTable/BrandsTableContainer";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      reloadDataComponent(BRANDS_SELECT);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const CreateBrandContainer = ({
  dataComponent,
  closeModalDialog,
  performCreateRequest,
  reloadDataComponent,
  loading,
  setReload,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions
    );
  }, [
    prevDataComponent,
    dataComponent,
    closeModalDialog,
    setReload,
    reloadDataComponent,
    formikActions,
  ]);

  const handleCreateBrand = useCallback(
    (brand, formikActions) => {
      performCreateRequest(dataComponentId, brand);
      setFormikAction(formikActions);
    },
    [performCreateRequest]
  );

  return (
    <LoaderContext.Provider value={{ loading }}>
      <BrandForm
        onSubmit={handleCreateBrand}
        dataComponentId={dataComponentId}
      />
    </LoaderContext.Provider>
  );
};

CreateBrandContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  performCreateRequest: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  setReload: PropTypes.func,
  loading: PropTypes.bool,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CreateBrandContainer));
