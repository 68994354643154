import { useSelector } from "react-redux";
import { roleCan } from "../../utils/roleUtils";

export default function useUserRole() {
  return useSelector(state => state.auth.role);
}

export const useUserRoleCan = permission => {
  const role = useUserRole();
  return roleCan(role, permission);
};
