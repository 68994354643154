import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import {
  ExpandActionWrapper,
  ExpandedIcon,
  StyledPaper,
} from "../BidGroupTable/components";
import React, { useState, useEffect, useMemo } from "react";
import Loader from "../../../../ui/Loader";
import {
  HeaderLeft,
  HeaderWrapper,
  TitleWrapper,
  Wrapper,
} from "../BidGroupTable/components";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";
import {
  SubHeadingWrapper,
  vendorNameWithAwardedIcon,
  categoryNameAwarded,
} from "./components";
import { PlaylistAddCheck } from "@material-ui/icons";
import { Chip, IconButton } from "@material-ui/core";

const columns = ["name", "category.name"];

export const getColumnsOptions = (categories, awardedVendorId) => ({
  name: {
    title: "Vendor Name",
    width: "280px",
    filter: true,
    render: vendorNameWithAwardedIcon(awardedVendorId),
  },
  "category.name": {
    title: "Category",
    filter: "select",
    filterOptions: {
      options: categories.map(category => ({
        id: category.name,
        name: category.name,
      })),
    },
    render: categoryNameAwarded(awardedVendorId),
  },
});
export const buildRows = (specVendors, vendorCategories) => {
  const { vendors } = specVendors;
  const rows = [];
  vendors.map(bidGroupVendor => {
    const row = bidGroupVendor.vendor;
    row.category =
      vendorCategories.find(
        vendorCategory => vendorCategory.id === bidGroupVendor.vendor.categoryId
      ) || {};
    rows.push(row);
  });
  return rows;
};
const SpecTable = props => {
  const { loading, specVendors, vendorCategories } = props;
  const [gridRows, setGridRows] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { spec = {} } = specVendors;
  const { specCategory = {} } = spec;

  useEffect(() => {
    const rows = buildRows(specVendors, vendorCategories);
    setGridRows(rows);
  }, [specVendors, vendorCategories]);

  const awardedSpecBidGroup = useMemo(
    () =>
      specVendors.vendors
        ? specVendors.vendors.find(vendor =>
            vendor.bidGroupVendorSpecs?.some(bidGroupVendorSpec =>
              bidGroupVendorSpec.quotes?.some(
                quote => quote.id == spec.awardedBidGroupVendorSpecQuoteId
              )
            )
          )
        : null,
    [spec.awardedBidGroupVendorSpecQuoteId, specVendors.vendors]
  );

  const tableComponent = useMemo(() => {
    if (isCollapsed) {
      return {
        TableFilterRow: () => null,
        HeaderRowComponent: () => () => null,
        BodyComponent: () => null,
      };
    }
  }, [isCollapsed]);

  const awardedVendorChip = awardedSpecBidGroup ? (
    <Chip
      color="success"
      icon={<PlaylistAddCheck style={{ color: "white" }}></PlaylistAddCheck>}
      label={awardedSpecBidGroup.vendor.name}
      style={{
        backgroundColor: "#72b327",
        color: "white",
        marginLeft: "10px",
      }}
    />
  ) : null;

  return (
    <StyledPaper>
      <BWGridLocal
        id={`specs-bid-group-${spec.id}`}
        rows={gridRows}
        sorting={[{ columnName: "name", direction: "asc" }]}
        gridConfig={{ pageSize: 10, totalRows: gridRows.length }}
        isLoading={loading}
        showSelectAll
        editableNavigationDirection="horizontal"
        tableComponents={tableComponent}
      >
        <GridRow>
          <HeaderWrapper>
            <Wrapper>
              <HeaderLeft>
                <Loader height="30px" width="350px">
                  <TitleWrapper>{`${spec.customNumber} - ${spec.description}`}</TitleWrapper>
                  {awardedVendorChip}
                </Loader>
              </HeaderLeft>
            </Wrapper>
            <Wrapper>
              <HeaderLeft>
                <Loader height="30px" width="350px">
                  <SubHeadingWrapper>
                    {`${specCategory.name || ""}`}
                  </SubHeadingWrapper>
                </Loader>
              </HeaderLeft>
            </Wrapper>
            <ExpandActionWrapper>
              <IconButton
                aria-label="show more"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <ExpandedIcon isExpanded={!isCollapsed} />
              </IconButton>
            </ExpandActionWrapper>
          </HeaderWrapper>
        </GridRow>
        <GridTable
          columns={columns}
          columnOptions={getColumnsOptions(
            vendorCategories,
            awardedSpecBidGroup?.vendorId
          )}
        />
        <GridRow showPagingPanel={!isCollapsed} />
      </BWGridLocal>
    </StyledPaper>
  );
};
SpecTable.propTypes = {
  loading: PropTypes.bool,
  specVendors: PropTypes.object.isRequired,
  vendorCategories: PropTypes.arrayOf(propTypes.vendorCategory),
};

export default SpecTable;
