import React from "react";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import { BoldText } from "./components";
import Loader from "../../../ui/Loader/Loader";
import AuthorLine from "./AuthorLine";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
`;

function subjectString(strings, value) {
  if (!value) return "";
  return `${strings[0]}${value}`;
}

const Subject = ({ note }) => {
  const subjects = {
    Email: subjectString`To: ${note.emailSentTo}`,
    "Phone Call": subjectString`With: ${note.callWith}`,
    Meeting: subjectString`With: ${note.callWith}`,
    default: note.subject,
  };

  return <BoldText>{subjects[note.category] || subjects.default}</BoldText>;
};

Subject.propTypes = {
  note: propTypes.note,
};

const NoteHeader = ({ note }) => (
  <StyledHeader>
    <Loader width="150px" height="24px">
      <Subject note={note} />
    </Loader>
    <AuthorLine note={note} />
  </StyledHeader>
);

NoteHeader.propTypes = {
  note: propTypes.note,
};

export default NoteHeader;
