import termInputs from "../../../../../components/inputs/inputConfigs/term";
import { isSuperAdmin } from "../../../../../utils/roleUtils";
import { inputs } from "../../../../inputs/inputConfigs";

export default (role, isUpdate, maxPosition) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...termInputs.termContent,
              required: true,
            },
            grid: { xs: 12 },
          },
          {
            input: {
              ...termInputs.termDisplayOrder,
              options: Array.from(new Array(maxPosition), (_, index) => ({
                name: index.toString(),
                id: index.toString(),
              })),
              required: true,
            },
            grid: { xs: 6 },
          },
          {
            input: termInputs.termRelation,
            grid: { xs: 6 },
          },
          {
            input: isSuperAdmin(role)
              ? { ...inputs.scopeId, isDisabled: isUpdate }
              : null,
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
