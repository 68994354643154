import React from "react";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";

import ContactCard from "./ContactCard";

const ContactCardContainer = ({
  person,
  personType,
  anchorEl,
  onClose,
  open,
}) => {
  return (
    <Popover
      id="contact-card"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ContactCard {...person} personType={personType} />
    </Popover>
  );
};

ContactCard.propTypes = {
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  person: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    fax: PropTypes.string,
  }),
};

export default ContactCardContainer;
