import { connect as connectFormik } from "formik";
import InputWithMention from "./InputWithMention";
import { connect } from "react-redux";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";

import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
const usersDataComponentId = "user-notes-list";
export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    usersDataComponentId,
    state
  );
  const users = getBluechipResources(dataComponent, state);
  return {
    usersDataComponentId,
    users: users ? users : [],
  };
};
export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectFormik(InputWithMention));
