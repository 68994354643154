import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import EmptyState from "../../../../ui/EmptyState";

export const StyledPaper = styled(Paper)`
  margin-bottom: 24px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const messages = {
  vendors: "Please Select Vendors for Bid Group",
  po: "Please select specs to create a Purchase Order",
};

const QuoteEmptyState = ({ visible, messageType }) => {
  if (!visible) return null;
  return (
    <StyledPaper elevation={2}>
      <EmptyState intent="empty" size="small" intensity="strong" padding="24px">
        {messages[messageType]}
      </EmptyState>
    </StyledPaper>
  );
};

QuoteEmptyState.propTypes = {
  visible: PropTypes.bool,
  messageType: PropTypes.oneOf(["vendors", "po"]),
};

export default QuoteEmptyState;
