import React from "react";
import propTypes from "../../../../../constants/propTypes";
import { ColumnItem } from "../Fees/FeeSummary/FeeSummary";
import DateColumn from "../../../../ui/BWGrid/columns/Date";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const format = (value, currencyFormatter) =>
  currencyFormatter.format(value || 0);

const FundingSection = ({
  project: {
    fundingType,
    lastFundingRequest,
    lastFundingRequestWithFunds,
    fundingDue,
    fundingDueDate,
  },
  projectSummary: { totalRequested },
}) => {
  const currencyFormatter = useCurrencyFormatter();

  const values = [
    [
      {
        title: "Funding Type:",
        value: fundingType,
      },
      {
        title: "Total Requested",
        value: format(totalRequested, currencyFormatter),
      },
    ],
    [
      {
        title: "Last Requested Date:",
        value: (
          <DateColumn date={lastFundingRequest} format={PRIMARY_DATE_FORMAT} />
        ),
      },
      {
        title: "Funding Day",
        value: fundingDue || "",
      },
    ],
    [
      {
        title: "Last Funded Date:",
        value: (
          <DateColumn
            date={lastFundingRequestWithFunds}
            format={PRIMARY_DATE_FORMAT}
          />
        ),
      },
      {
        title: "Due Date",
        value: fundingDueDate || "",
      },
    ],
  ];
  return (
    <div>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} />
      ))}
    </div>
  );
};

FundingSection.propTypes = {
  project: propTypes.project,
  projectSummary: propTypes.projectSummary,
};

FundingSection.defaultProps = {
  summary: {},
  project: {
    fundingType: "",
  },
};

export default FundingSection;
