import Loader from "../../../../ui/Loader";
import React from "react";
import PropTypes from "prop-types";

import {
  OptionsWrapper,
  ItemWrapper,
  Title,
  RowItemWrapper,
  Value,
  Bar,
  Fill,
} from "./components";

export const ColumnItem = props => {
  const { row } = props;

  return (
    <OptionsWrapper>
      {row.map(({ title, value, total, needsFollowUp }, key) => (
        <ItemWrapper key={key}>
          {title && (
            <Title>
              <Loader width="200px">
                <RowItemWrapper>
                  <Title>{title}</Title>
                  <Title>
                    {value} of {total}
                  </Title>
                </RowItemWrapper>
              </Loader>
            </Title>
          )}
          <Value noTitle={!title}>
            <Loader width="200px">
              <Bar>
                <Fill
                  needsFollowUp={!!needsFollowUp}
                  width={total > 0 ? (value / total) * 100 : 0}
                />
              </Bar>
            </Loader>
          </Value>
        </ItemWrapper>
      ))}
    </OptionsWrapper>
  );
};
ColumnItem.defaultProps = {
  row: [],
};

ColumnItem.propTypes = {
  row: PropTypes.array.isRequired,
};

export default ColumnItem;
