import React, { Component } from "react";
import PropTypes from "prop-types";
import { BWGridAPI, GridHeader, GridTable } from "../../ui/BWGrid";
import { Content } from "../../layout/Content";
import { Paper } from "../../mui/core";
import AddCircle from "@material-ui/icons/AddCircle";

const columns = [
  "name",
  "numTeamMembers",
  "numActiveProjects",
  "numClosingProjects",
  "numCompletedProjects",
];

const columnOptions = ({
  numActiveProjects = 0,
  numClosingProjects = 0,
  numCompletedProjects = 0,
}) => ({
  name: { fill: true },
  numTeamMembers: { title: "Members", fill: true },
  numActiveProjects: {
    title: `Active Projects (${numActiveProjects})`,
    align: "right",
  },
  numClosingProjects: {
    title: `Closing Projects (${numClosingProjects})`,
    align: "right",
  },
  numCompletedProjects: {
    title: `Completed Projects (${numCompletedProjects})`,
    align: "right",
  },
});

export default class CapacityPage extends Component {
  static propTypes = {
    dataComponent: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    numEmployees: PropTypes.number,
    numActiveProjects: PropTypes.number,
    numClosingProjects: PropTypes.number,
    numCompletedProjects: PropTypes.number,
  };

  handleRowClick = ({ id }) => {
    this.props.push(`/capacity/team/${id}`);
  };

  render() {
    const { dataComponent, numEmployees = 0, ...projectCounts } = this.props;
    return (
      <Content>
        <Paper>
          <BWGridAPI {...this.props}>
            <GridHeader
              headerText={`${dataComponent.totalRows} Teams`}
              subheaderText={`${numEmployees} Employees 🚧`}
              actions={[
                {
                  text: "MANAGE TEAMS 🚧",
                  icon: <AddCircle />,
                  handler: () => {},
                },
              ]}
            />
            <GridTable
              columns={columns}
              columnOptions={columnOptions(projectCounts)}
              onClick={this.handleRowClick}
            />
          </BWGridAPI>
        </Paper>
      </Content>
    );
  }
}
