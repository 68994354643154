import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";
import isMobile from "ismobilejs";

const reducerMap = {
  [actionTypes.LAYOUT_SHOW_SNACK_NOTIFICATION]: (state, payload) => {
    const { message, hideDuration } = payload;
    return {
      ...state,
      snackNotification: {
        isOpen: true,
        message,
        hideDuration,
      },
    };
  },
  [actionTypes.LAYOUT_ACTION_REQUIRED_NOTIFICATION]: (state, payload) => {
    const { actionRequiredDimissed } = payload;
    return {
      ...state,
      actionRequiredDimissed,
    };
  },
  [actionTypes.LAYOUT_OPEN_NAVBAR_DRAWER]: state => ({
    ...state,
    isNavbarDrawerOpen: true,
  }),
  [actionTypes.LAYOUT_SET_HEADER_TITLE]: (state, { headerTitle }) => ({
    ...state,
    headerTitle,
  }),
  [actionTypes.LAYOUT_UNSET_HEADER_TITLE]: state => ({
    ...state,
    headerTitle: null,
  }),
  [actionTypes.LAYOUT_CLOSE_NAVBAR_DRAWER]: state => ({
    ...state,
    isNavbarDrawerOpen: false,
  }),
  [actionTypes.LAYOUT_OPEN_MODAL_DIALOG]: (state, payload) => ({
    ...state,
    modalDialog: {
      isOpen: true,
      ...payload,
    },
  }),
  [actionTypes.SET_MODAL_DIALOG_TITLE]: (state, payload) => {
    return {
      ...state,
      modalDialog: {
        ...state.modalDialog,
        title: payload.title,
      },
    };
  },
  [actionTypes.LAYOUT_CHANGE_MODAL_DIALOG_ELEMENT]: (state, payload) => ({
    ...state,
    modalDialog: {
      ...state.modalDialog,
      elementTypeID: payload.elementTypeID,
    },
  }),
  [actionTypes.LAYOUT_CLOSE_MODAL_DIALOG]: state => ({
    ...state,
    modalDialog: initialState.layout.modalDialog,
  }),
  [actionTypes.LAYOUT_DISMISS_SNACK_NOTIFICATION]: state => ({
    ...state,
    snackNotification: initialState.layout.snackNotification,
  }),
  [actionTypes.LAYOUT_SET_AUTOSAVE_COMPONENT]: (state, payload) => ({
    ...state,
    autoSaveComponentId: payload,
  }),
  [actionTypes.LAYOUT_SET_AUTOSAVE_QUEUE]: (state, payload) => ({
    ...state,
    autoSaveQueueId: payload,
  }),
  default: state => state,
};

export default function(state = initialState.layout, action) {
  const { type, payload } = action;
  const isMobileBrowser = isMobile(window.navigator).any;
  if (isMobileBrowser) {
    initialState.layout.isNavbarDrawerOpen = false;
  }
  return (reducerMap[type] || reducerMap.default)(state, payload);
}
