import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { goBack } from "connected-react-router";
import { isEmpty } from "lodash";

import { FormikForm, PromptIfDirty } from "../../../../forms";
import SectionHeader from "../../../../layout/SectionHeader";
import SubmissionDetails from "./SubmitionDetails";
import { Content } from "../../../../layout/Content";
import { Title } from "../../../../ui/Typography/SectionTitle";
import FormGrid from "../../../../forms/FormGrid";
import Paper from "../../../../mui/core/Paper";
import { updateBidGroupVendor } from "../../../../../actions/vendorPortalRFQActions";
import { bidGroupVendorValidationSchema } from "../../../BidGroupDetail/Quotes/BidGroupTable/QuoteEdit/QuoteForm";
import useActions from "../../../../hooks/useActions";
import QuoteTable from "../QuoteTable/QuoteTable";
import { buildQuoteInputs } from "./quoteFields";
import { cleanCurrencyValue } from "../../../../../utils/currencyFormatter";
import propTypes from "../../../../../constants/propTypes";
import { mapJoiErrorToCompatibleError } from "../../../../../utils/formValidationUtils";

const Container = styled(Content)`
  ${Title} {
    font-weight: bold;
  }
`;

export const handleBidGroupVendorSpecsUpdate = (
  bidGroupVendor,
  updatedRows,
  setFieldValue
) => {
  const { bidGroupVendorSpecs } = bidGroupVendor;
  const updatedBGSpecs = updateAllBidGroupSpecValues(
    bidGroupVendorSpecs,
    updatedRows
  );
  setFieldValue("bidGroupVendorSpecs", updatedBGSpecs);
};

const getUninsertedQuotes = (updatedRows, qItem) =>
  updatedRows.filter(
    ({ id, quoteId, specId }) =>
      !id &&
      quoteId === qItem.id &&
      !qItem.quoteItems.find(quoteItem => quoteItem.specId === specId)
  );

const getUpdatedQuoteItem = (updatedRows, qiItem) => {
  const updatedQI = updatedRows.find(
    rItem => rItem.id === qiItem.id && rItem.specId == qiItem.specId
  );
  if (!updatedQI) {
    return qiItem;
  }

  return {
    ...qiItem,
    sampleQuantity: updatedQI.sampleQuantity,
    productionQuantity: updatedQI.productionQuantity,
    samplePricing: cleanCurrencyValue(updatedQI.samplePricing * 100),
    productionPricing: cleanCurrencyValue(updatedQI.productionPricing * 100),
    overagePercent: updatedQI.overagePercent,
  };
};

const updateAllBidGroupSpecValues = (bidGroupVendorSpecs, updatedRows) => {
  const updatedBidGroupVendorSpecs = bidGroupVendorSpecs.map(bgvsItem => ({
    ...bgvsItem,
    quotes: bgvsItem.quotes.map(qItem => {
      const uninsertedQuotes = getUninsertedQuotes(updatedRows, qItem);
      if (uninsertedQuotes.length > 0) {
        qItem.quoteItems.push(
          ...uninsertedQuotes.map(
            ({
              bidGroupVendorSpecQuoteId,
              overagePercent,
              productionPricing,
              productionQuantity,
              samplePricing,
              sampleQuantity,
              specId,
            }) => ({
              bidGroupVendorSpecQuoteId,
              overagePercent,
              productionPricing,
              productionQuantity,
              samplePricing,
              sampleQuantity,
              specId,
            })
          )
        );
      }
      return {
        ...qItem,
        quoteItems: qItem.quoteItems.map(qiItem =>
          getUpdatedQuoteItem(updatedRows, qiItem)
        ),
      };
    }),
  }));
  return updatedBidGroupVendorSpecs;
};

export const getInitValues = (bidGroupVendor, projectDefaultCurrency) => {
  const initialValues = {
    ...bidGroupVendor,
    isDraft: undefined,
    quoteProjectCurrencyId:
      bidGroupVendor.quoteProjectCurrencyId || projectDefaultCurrency.id,
    quoteDate: bidGroupVendor.quoteDate || undefined,
    estimatedShipDate: bidGroupVendor.estimatedShipDate || undefined,
    potentialTariffType: bidGroupVendor.potentialTariffType || "$",
  };
  initialValues.potentialTariffLocal = bidGroupVendor.potentialTariff;
  initialValues.estimatedFreightToWarehouseLocal =
    bidGroupVendor.estimatedFreightToWarehouse;
  initialValues.miscellaneousCostLocal = bidGroupVendor.miscellaneousCost;
  return initialValues;
};

function validateForm(values) {
  const schema = bidGroupVendorValidationSchema();
  const joiError = schema.validate(
    { ...this.initialValues, ...values },
    {
      abortEarly: false,
      skipFunctions: true,
      convert: true,
      allowUnknown: true,
    }
  ).error;

  if (!joiError || values.bidGroupVendorSpecs) {
    return {};
  }
  return mapJoiErrorToCompatibleError(joiError);
}

export const submitHandler = (
  isDraft,
  handleSubmit,
  draftRef,
  { values, setErrors, setStatus }
) => () => {
  const errors = validateForm.bind({ initialValues: {} })({
    ...values,
    bidGroupVendorSpecs: null,
  });

  if (!isEmpty(errors)) {
    setErrors(errors);
    setStatus("submitted");
    return;
  }

  draftRef.current = isDraft;
  setTimeout(() => {
    handleSubmit();
  });
};

function QuoteForm({
  bidGroupVendor,
  quoteIsOver,
  projectDefaultCurrency,
  draftRef,
  formikActionsRef,
}) {
  const actions = useActions({
    updateBidGroupVendor,
    goBack,
  });

  const handleSubmit = (values, formikActions) => {
    formikActionsRef.current = formikActions;
    const parsedValues = values.bidGroupVendorSpecs
      ? { bidGroupVendorSpecs: values.bidGroupVendorSpecs }
      : values;
    actions.updateBidGroupVendor(bidGroupVendor.bidGroupId, bidGroupVendor.id, {
      ...parsedValues,
      bidComplete: !draftRef.current,
      isDraft: draftRef.current,
    });
  };

  const initialValues = useMemo(
    () => getInitValues(bidGroupVendor, projectDefaultCurrency),
    [bidGroupVendor, projectDefaultCurrency]
  );

  const projectId = bidGroupVendor?.bidGroup?.project?.id;
  const projectCurrencies =
    bidGroupVendor?.bidGroup?.project?.projectCurrencies;
  const quoteIsOpen = bidGroupVendor.quoteStatus === "open";
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      ignoreCache
      autoSave
      validate={validateForm}
      noDelayFields={["quoteFiles"]}
    >
      {({ handleSubmit, values, setFieldValue, ...formikProps }) => {
        const selectedProjectCurrency = projectCurrencies.find(
          projectCurrency => projectCurrency.id == values.quoteProjectCurrencyId
        );
        return (
          <Container>
            {projectId && (
              <Paper>
                <SectionHeader headerText="Quote Details" />
                <FormGrid
                  fields={buildQuoteInputs(
                    projectId,
                    selectedProjectCurrency?.currency,
                    values.potentialTariffType
                  )}
                />
              </Paper>
            )}
            <QuoteTable
              bidGroupVendor={values}
              handleBidGroupVendorSpecsUpdate={updatedRows =>
                handleBidGroupVendorSpecsUpdate(
                  values,
                  updatedRows,
                  setFieldValue
                )
              }
            />
            <SubmissionDetails
              values={values}
              quoteIsOpen={quoteIsOpen}
              quoteIsOver={quoteIsOver}
              onSubmit={submitHandler(false, handleSubmit, draftRef, {
                ...formikProps,
                values,
              })}
              onReturn={actions.goBack}
              uploadFile={actions.uploadFile}
            />
            <PromptIfDirty />
          </Container>
        );
      }}
    </FormikForm>
  );
}

QuoteForm.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor,
  quoteIsOver: PropTypes.bool,
  projectDefaultCurrency: propTypes.projectCurrency,
  draftRef: PropTypes.shape({}),
  formikActionsRef: PropTypes.shape({}),
};

export default QuoteForm;
