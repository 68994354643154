import { connect } from "react-redux";

import {
  updateFee,
  dataComponentFeePaymentId,
  createFeePayment,
} from "../../../../../../actions/feeSummaryActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { getBluechipResourcesByType } from "../../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import FeePayments from "./FeePayments";

export const mapStateToProps = (state, { dataComponentId, feeId }) => {
  const fees = getBluechipResourcesByType(dataComponentId, state) || [];

  return {
    fee: fees.find(({ id }) => id === feeId),
    feePaymentComponent: getDataComponent(
      dataComponentFeePaymentId,
      state,
      REQUEST_TYPES.CREATE
    ),
  };
};

export const mapDispatchToProps = {
  updateFee,
  closeModalDialog,
  showSnackNotificationAction,
  createFeePayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeePayments);
