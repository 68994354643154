import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Creatable from "react-select";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {
  getHelperText,
  useLabeledText,
} from "../../../inputs/TextInputWithError";
import useActions from "../../../hooks/useActions";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import {
  getComponents,
  getInputLabelProps,
} from "../../../inputs/SelectWithError/AutocompleteSelect/AutocompleteSelect";
import InputLoader from "../../../ui/Loader/InputLoader";
import { DisabledInput } from "./components";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { showSnackNotificationAction } from "../../../../actions/layoutActions";
import { getRequiredStyle } from "../../../inputs/SelectWithError/SingleAutoCompleteSelect/components";
import { buildSaveNewObj, handleKeyDown } from "./creatableHandlers";
import { removeControlCharacters } from "../../../inputs/utils";

const StyledCreatable = styled(Creatable)`
  ${props => getRequiredStyle(props)}
`;
const formatMultiValue = ({ value, valueProperty, labelProperty }) => {
  return value
    ? value.map(item => {
        const value = item[valueProperty]
          ? item[valueProperty]
          : item[labelProperty];
        return { value: value, label: item[labelProperty] };
      })
    : [];
};

const getFormatValue = ({ value, valueProperty, labelProperty }) => {
  return formatMultiValue({
    value,
    valueProperty,
    labelProperty,
  });
};

const getValueToBeSent = ({
  newValue,
  isMulti,
  valueProperty,
  labelProperty,
}) => {
  if (!isMulti) {
    return newValue ? newValue.label : "";
  }

  return newValue.map(item => {
    const newItem = {
      [labelProperty]: item.label,
    };
    if (!item.__isNew__) {
      newItem[valueProperty] = item.value;
    }
    return newItem;
  });
};

export const processSaveRequest = (
  preDataComponent,
  dataComponent,
  { showSnackNotificationAction, value, modelName, isMulti, inputValue }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      const id = dataComponent.requestState[REQUEST_TYPES.CREATE].rowIndex[0];
      if (isMulti) {
        value[value.length - 1].id = id;
      } else {
        value = inputValue;
      }
      showSnackNotificationAction(`New ${modelName.toLowerCase()} created.`);
    },
    onError: () => {
      value.pop();
      showSnackNotificationAction(
        `Error while creating new ${modelName.toLowerCase()}.`
      );
    },
  });
};

// eslint-disable-next-line max-lines-per-function
const CreatableSelectInput = ({
  onChange,
  name,
  value,
  inputProps,
  dataComponentId,
  isMulti,
  model,
  url,
  columnNameToSortAndFilter,
  valueProperty = "id",
  labelProperty = "name",
  validate,
  ...props
}) => {
  const isLabeledText = useLabeledText(false);
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [preDataComponent, setPreDataComponent] = useState(null);

  const actions = useActions({
    initDataComponent,
    performRetrieveListRequest,
    performCreateRequest,
    showSnackNotificationAction,
  });

  const state = useSelector(state => state);

  useEffect(() => {
    actions.initDataComponent(dataComponentId, model, [], url, false, "v2");

    actions.performRetrieveListRequest(dataComponentId, {
      sort: [{ columnName: columnNameToSortAndFilter, direction: "asc" }],
      pageSize: 20,
    });
  }, [dataComponentId, actions, model, url, columnNameToSortAndFilter]);

  const dataComponent = getDataComponent(dataComponentId, state);

  const options = useV2DatacomponentResources(
    dataComponentId,
    [],
    REQUEST_TYPES.LIST
  ).map(item => ({
    value: item[valueProperty],
    label: item[labelProperty],
  }));

  const isLoading = dataComponent?.requestState?.list?.loading;

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      actions.performRetrieveListRequest(dataComponentId, {
        sort: [{ columnName: columnNameToSortAndFilter, direction: "asc" }],
        pageSize: 20,
        rootFilters: {
          $where: {
            [columnNameToSortAndFilter]: {
              $ilike: `${currentInputValue}%`,
            },
          },
        },
      });
    }, 500);

    return () => clearTimeout(loadTimer);
  }, [currentInputValue, actions, dataComponentId, columnNameToSortAndFilter]);

  useEffect(() => setPreDataComponent(dataComponent), [dataComponent]);
  useEffect(() => {
    processSaveRequest(preDataComponent, dataComponent, {
      showSnackNotificationAction: actions.showSnackNotificationAction,
      modelName: model.name,
      value: value,
      isMulti,
      options,
      inputValue: currentInputValue,
    });
  }, [
    actions.showSnackNotificationAction,
    currentInputValue,
    dataComponent,
    isMulti,
    model,
    options,
    preDataComponent,
    value,
  ]);

  const components = useMemo(() => getComponents(props.label, {}), [
    props.label,
  ]);

  const handleChange = newValue => {
    const newValueToBeSent = getValueToBeSent({
      newValue,
      isMulti,
      valueProperty,
      labelProperty,
    });

    const event = {
      type: "click",
      target: { name, value: newValueToBeSent || "" },
    };

    onChange(event);
  };

  const handleInputChange = value => {
    setCurrentInputValue(removeControlCharacters(value, true));
  };

  const saveNewObj = buildSaveNewObj({
    dataComponentId,
    performCreateRequest: actions.performCreateRequest,
    labelProperty,
  });

  const helperText = getHelperText(
    props.ignoreCaption,
    props.helperText,
    props.error,
    props.errorMessage
  );

  const formatValue = getFormatValue({
    value,
    valueProperty,
    labelProperty,
  });

  if (isLabeledText) {
    return (
      <DisabledInput
        label={props.label}
        value={formatValue.map(({ label }) => label).join(", ")}
        inputProps={inputProps}
      />
    );
  }

  return (
    <InputLoader>
      <StyledCreatable
        isClearable
        isMulti={isMulti}
        name={name}
        components={components}
        onChange={handleChange}
        options={options}
        value={formatValue}
        isLoading={isLoading}
        onInputChange={handleInputChange}
        inputValue={currentInputValue}
        onKeyDown={event =>
          handleKeyDown({
            name,
            event,
            value: formatValue,
            isMulti,
            inputValue: currentInputValue,
            options,
            saveNewObj,
            handleChange,
            handleInputChange,
            validate,
            showSnackNotificationAction: actions.showSnackNotificationAction,
          })
        }
        textFieldProps={getInputLabelProps(formatValue, {
          helperText,
          ...props,
        })}
        {...props}
      />
    </InputLoader>
  );
};

CreatableSelectInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  dataComponentId: PropTypes.string,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  url: PropTypes.string.isRequired,
  model: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  columnNameToSortAndFilter: PropTypes.string.isRequired,
};

export default React.memo(CreatableSelectInput);
