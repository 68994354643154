import React from "react";
import PropTypes from "prop-types";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { Content, PagingGridRow } from "../../../BWGrid/GridRow";

const DefaultPagingPanelTemplate = ({ totalRows }) => {
  if (totalRows <= 10) return null;
  return (
    <Plugin name="GridRowPlugin">
      <Template name="footer">
        <PagingGridRow>
          <Content />
          <TemplatePlaceholder name="pagingPanel" />
        </PagingGridRow>
      </Template>
    </Plugin>
  );
};

DefaultPagingPanelTemplate.propTypes = {
  totalRows: PropTypes.number,
};

export default DefaultPagingPanelTemplate;
