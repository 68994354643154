import React from "react";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import PropTypes from "prop-types";

import { UserMenuIcon, UserMenuItem } from "../components";
import useActions from "../../../hooks/useActions";
import {
  switchToClientPortal,
  switchBackToOriginalPortal,
} from "../../../../actions/authActions";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

const SwitchPortalMenuItem = ({ onClose, isStaffPortal }) => {
  const actions = useActions({
    switchToClientPortal,
    switchBackToOriginalPortal,
  });

  const handleClick = () => {
    if (isStaffPortal) {
      actions.switchToClientPortal();
    } else {
      actions.switchBackToOriginalPortal();
    }
    onClose();
  };

  if (isStaffPortal) {
    return (
      <UserMenuItem onClick={handleClick}>
        <UserMenuIcon>
          <SwapHoriz />
        </UserMenuIcon>
        Switch to Client Portal
      </UserMenuItem>
    );
  }

  return (
    <UserMenuItem onClick={handleClick}>
      <UserMenuIcon>
        <SwapHoriz />
      </UserMenuIcon>
      Switch to Staff Portal
    </UserMenuItem>
  );
};
SwitchPortalMenuItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  isStaffPortal: PropTypes.bool,
};

export default withRenderOnPermissionActive(SwitchPortalMenuItem, [
  "switch-to-client-portal",
]);
