import React from "react";
import styled from "styled-components";
import List from "@material-ui/core/List";
import { BWActionButton } from "../../../../ui/BWActionButton";
import { SearchPanel } from "../../../../ui/SearchBox";
import EmptyState, { Wrapper } from "../../../../ui/EmptyState";
import { Input, Menu, MenuItem } from "../../../../mui/core";
import RegularText from "../../../../ui/Typography/RegularText";
import { ContactItemWrapper } from "./ContactItem";

export const ContactsContainer = styled.div`
  &:first-child {
    padding-top: 24px;
  }
`;

export const ContactsHeader = styled(({ type, ...props }) => (
  <header {...props} />
))`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 8px;
  }
`;

export const ContactList = styled(List)`
  padding: 0 0 8px;
  min-height: 75px;
`;

export const MenuContainer = styled(Menu)`
  ul {
    width: 100%;
    padding: 0;
  }
  ${BWActionButton} {
    background: none;
  }
`;

export const SearchItem = styled(MenuItem)`
  padding: 16px;
  padding-left: 8px;
  ${SearchPanel} {
    padding: 0;
    border: none;
    ${Input} {
      border: none;
      color: rgba(0, 0, 0, 0.54);
      font-weight: normal;
      input {
        padding: 0;
        padding-right: 16px;
      }
    }
  }
`;

export const ListItem = styled(MenuItem)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0;
  height: auto;
  overflow: auto;
  ${ContactList} {
    max-height: 275px;
    width: 100%;
    ${ContactItemWrapper} {
      padding: 14px 16px;
    }
  }
`;

export const ActionItem = styled(MenuItem)`
  color: #57abff;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  svg {
    margin-right: 8px;
  }
`;

export const CreateVendorLink = styled.a`
  ${({ onClick }) =>
    onClick &&
    `
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.5);
  }
  `}
`;

const getTitle = (title, disabledOptions) => {
  if (title) return title;
  if (disabledOptions) return "No Vendor Selected";
  return "No Vendor Selected, Click here to select Vendor";
};

export const PlaceholderList = styled(
  ({ disabledOptions, title, onClick, ...props }) => (
    <div {...props}>
      <EmptyState>
        {disabledOptions ? (
          getTitle(title, disabledOptions)
        ) : (
          <CreateVendorLink onClick={onClick}>
            {getTitle(title, disabledOptions)}
          </CreateVendorLink>
        )}
      </EmptyState>
    </div>
  )
)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90px;

  ${Wrapper} {
    padding: 0px;

    ${RegularText} {
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.38);
    }
  }
`;
