import { BaseModel } from "@blue-chip/core";
import PurchaseOrder from "./PurchaseOrder";
import File from "./File";
import User from "./User";
import Revision from "./Revision";

export default class ApprovalFile extends BaseModel {
  static get belongsTo() {
    return [PurchaseOrder, File, User, Revision];
  }
}
