import styled from "styled-components";
import { Button as MaterialButton } from "@material-ui/core";

import { Title as SerifText } from "../../Typography/SectionTitle";
import BaseTag from "../../Tags/BaseTag";

export const Wrapper = styled.article`
  min-width: 487px;
  @media (max-width: 600px) {
    min-width: calc(100vw - 32px);
  }
`;

export const ContactInformation = styled.main`
  margin: 24px;
  display: flex;
  flex-direction: column;
`;

export const DetailLineWrapper = styled(SerifText)`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

export const Button = styled(MaterialButton)`
  padding: 2px 6px;
  text-transform: none;
  min-width: 0px;
`;

export const TextArea = styled.textarea`
  position: absolute;
  opacity: 0;
  margin-right: 5px;
`;

export const Avatar = styled(BaseTag)`
  height: 120px;
  width: 120px;
  position: absolute;
  background-color: rgb(39, 88, 122);
  right: 24px;
  top: 24px;
`;

export const ChildrenSpan = styled.span`
  margin-right: 10px;
`;

export const TextAction = styled.span`
  color: #57abff;
  font-size: 12px;
`;

export const Line = styled.span`
  font-size: 12px;
`;
