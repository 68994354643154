import Invoice from "../Invoice";

describe("Invoice Model", () => {
  test("belongsTo relations", () => {
    expect(Invoice.belongsTo.map(relation => relation.name)).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(Invoice.hasMany.map(relation => relation.name)).toMatchSnapshot();
  });
});
