import React, { useCallback } from "react";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import EventNote from "@material-ui/icons/EventNote";
import LocalShipping from "@material-ui/icons/LocalShipping";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Assignment from "@material-ui/icons/Assignment";
import PropTypes from "prop-types";

const styleIcon = Icon => {
  return styled(Icon)`
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.24);
  `;
};

export const Item = styled(MenuItem)`
  @media (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const ICONS = {
  project: styleIcon(EventNote),
  purchaseOrder: styleIcon(InsertDriveFile),
  shipment: styleIcon(LocalShipping),
  spec: styleIcon(LibraryBooks),
  invoice: styleIcon(Assignment),
  bidGroup: styleIcon(InsertDriveFile),
};

const SearchResult = ({ item, onClick }) => {
  const Icon = ICONS[item.type];
  const handleClick = useCallback(() => {
    onClick(item.link, item.sectionName, item.returnUrl);
  }, [item.link, item.returnUrl, item.sectionName, onClick]);
  return (
    <Item onClick={handleClick}>
      <Icon />
      {item.title}
    </Item>
  );
};

SearchResult.propTypes = {
  item: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default SearchResult;
