import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import _defaultTo from "lodash/defaultTo";

import propTypes from "../../../constants/propTypes";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContactCircle = styled.div`
  position: relative;
  text-align: center;
  background: ${({ isPlaceholder }) => (isPlaceholder ? "#BDBDBD" : "#57abff")};
  max-width: 24px;
  min-width: 24px;
  border-radius: 50%;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 0;
  left: -${({ position }) => position + 1}px;
  &:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 28px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    top: -2px;
    left: -2px;
  }
`;

const Contact = ({ vendorContact, position }) => {
  const contact = vendorContact.contact;
  const firstName = _get(contact, "firstName", "").toUpperCase();
  const lastName = _get(contact, "lastName", "").toUpperCase();

  return (
    <ContactCircle
      position={position - 1}
      title={`${firstName} ${lastName}`}
    >{`${firstName[0]}${lastName[0]}`}</ContactCircle>
  );
};

Contact.propTypes = {
  vendorContact: propTypes.vendorContact,
  position: PropTypes.number,
};

const PointOfContactColumn = props => {
  const pointOfContacts = _defaultTo(props.pointOfContacts, []);
  const hidden = pointOfContacts.length - 3;
  return (
    <Wrapper>
      {pointOfContacts.slice(0, 3).map((vendorContact, index) => (
        <Contact
          key={vendorContact.id}
          vendorContact={vendorContact}
          position={index}
        />
      ))}
      {hidden > 0 && (
        <ContactCircle position={2} isPlaceholder>
          +{hidden}
        </ContactCircle>
      )}
    </Wrapper>
  );
};

PointOfContactColumn.propTypes = {
  pointOfContacts: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PointOfContactColumn;
