import React from "react";
import NavigationRoutes from "../Navigation/NavigationRoutes";
import { Content } from "../Content";

const WrapperNavigationRoutes = props => (
  <Content>
    <NavigationRoutes {...props} />
  </Content>
);
WrapperNavigationRoutes.propTypes = NavigationRoutes.propTypes;

export default WrapperNavigationRoutes;
