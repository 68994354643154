import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { closeModalDialog } from "../../actions/layoutActions";
import { connect } from "react-redux";

const StyledList = styled(List)`
  padding: 0;
`;

const StyledItem = styled(ListItem)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px;
`;

const StyledItemText = styled(ListItemText)`
  span {
    color: #57abff;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
`;

function RawItem({ children, onClick, closeModalDialog }) {
  const handleClick = () => {
    closeModalDialog();
    onClick();
  };
  return (
    <StyledItem onClick={handleClick} button>
      <StyledItemText>{children}</StyledItemText>
    </StyledItem>
  );
}

const Item = connect(null, { closeModalDialog })(RawItem);

const itemPropTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

Item.propTypes = itemPropTypes;

RawItem.propTypes = {
  ...itemPropTypes,
  closeModalDialog: PropTypes.func.isRequired,
};

MenuModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)).isRequired,
};

export default function MenuModal(props) {
  const { items } = props;
  return (
    <StyledList>
      {items.map((itemProps, index) => (
        <Item {...itemProps} key={index} />
      ))}
    </StyledList>
  );
}
