import BidGroupVendor from "../BidGroupVendor";

describe("BidGroupVendor Model", () => {
  test("belongsTo relations", () => {
    expect(
      BidGroupVendor.belongsTo.map(relation => relation.name)
    ).toMatchSnapshot();
  });

  test("hasMany relations", () => {
    expect(
      BidGroupVendor.hasMany.map(relation => relation.name)
    ).toMatchSnapshot();
  });
});
