import React, { memo, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import InsertChart from "@material-ui/icons/InsertChart";
import Inbox from "@material-ui/icons/Inbox";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Assignment from "@material-ui/icons/Assignment";
import { renderRoutes } from "react-router-config";
import styled from "styled-components";

import propTypes from "../../../../constants/propTypes";
import { roleCan } from "../../../../utils/roleUtils";
import NavigationBar from "../../../layout/NavigationBar/NavigationBar";
import NavigationBarTab from "../../../layout/NavigationBar/NavigationBarTab";
import { useLoadedProject } from "../../../hooks/useIsProjectClosed";
import TagText from "../../../ui/Tags/TagText";

const Tag = styled(TagText)`
  margin-left: 24px;
`;

const OptionTab = ({ icon, label }) => {
  return (
    <NavigationBarTab>
      {icon}
      {label}
    </NavigationBarTab>
  );
};

OptionTab.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
};

const navigationTabs = role => {
  return [
    {
      text: "Summary",
      label: <OptionTab icon={<InsertChart />} label="Summary" />,
      path: "/summary",
      permission: "project-detail-accounting-summary",
    },
    {
      text: "Fees",
      label: <OptionTab icon={<Inbox />} label="Fees" />,
      path: "/fees",
      permission: "project-detail-accounting-fees",
    },
    {
      text: "Funding",
      label: <OptionTab icon={<AttachMoney />} label="Funding" />,
      path: "/funding",
      permission: "project-detail-accounting-funding",
    },
    {
      text: "Invoices",
      label: <OptionTab icon={<Assignment />} label="Invoices" />,
      path: "/invoices",
      permission: "project-detail-accounting-invoices",
    },
  ].filter(tab => roleCan(role, tab.permission));
};

const AccountingPage = ({ route, role }) => {
  const { isWaiverRequired } = useLoadedProject();

  const leftChildren = useMemo(
    () =>
      isWaiverRequired ? <Tag intent="watch">Lien Waiver Required</Tag> : null,
    [isWaiverRequired]
  );

  return (
    <Fragment>
      <NavigationBar
        navigationTabs={navigationTabs(role)}
        leftChildren={leftChildren}
      />
      {renderRoutes(route.routers)}
    </Fragment>
  );
};

AccountingPage.propTypes = {
  route: propTypes.route.isRequired,
  role: propTypes.userRole,
};

export default memo(AccountingPage);
