import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import { inputs } from "../../../../../components/inputs/inputConfigs";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const projectFields = {
  groups: [
    {
      items: [
        {
          input: inputs.bankAccount,
          grid: { xs: 6 },
        },
      ],
    },
  ],
};

const BankAccountForm = ({ onSubmit, initialValues }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      allowPartialValidation
      validationSchema={BWModels.loadSchema("Project")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={projectFields}
            values={values}
            errors={errors}
            {...formikProps}
          />

          <ActionButtons onSend={handleSubmit} isModal={true} />
        </Fragment>
      )}
    </FormikForm>
  );
};

BankAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.flag,
  column: PropTypes.string,
  title: PropTypes.string,
};

export default BankAccountForm;
