import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../forms";
import { ActionButtons } from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import { requirementTypeDataComponentId } from "../RequirementTypeContainer";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const CreateRequirementType = ({
  handleCreateRequirementType,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{ name: "" }}
      onSubmit={handleCreateRequirementType}
      validationSchema={BWModels.loadSchema("Requirement")}
    >
      {({ handleSubmit, values, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Create"
            listeners={[requirementTypeDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
