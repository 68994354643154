import React, { useRef } from "react";
import PropTypes from "prop-types";

import {
  Wrapper,
  StyledCircularProgress,
  IconWrapper,
  StyledCheck,
  StyledWarning,
  ChatIcon,
  ReferenceSpan,
} from "./components";
import TextField from "./TextField";
import Loader from "../../../Loader";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

const EditableTextDetailRow = ({
  path,
  value,
  status,
  helperText,
  dataComponentId,
  isReadOnly,
  omitDisableOnProjectClosed,
}) => {
  const spanRef = useRef(null);
  const isProjectClosed = useIsProjectClosed();
  return (
    <Loader>
      <Wrapper>
        <ChatIcon />
        <TextField
          path={path}
          value={value}
          helperText={helperText}
          dataComponentId={dataComponentId}
          isReadOnly={
            isReadOnly || (isProjectClosed && !omitDisableOnProjectClosed)
          }
          spanRef={spanRef}
        />
        <IconWrapper>
          <StyledCircularProgress size="22px" status={status} />
          <StyledCheck status={status} />
          <StyledWarning status={status} />
        </IconWrapper>
      </Wrapper>
      <ReferenceSpan ref={spanRef}>{!value ? "" : `${value}`}</ReferenceSpan>
    </Loader>
  );
};

EditableTextDetailRow.defaultProps = { helperText: "Add Comment" };

EditableTextDetailRow.propTypes = {
  path: PropTypes.string,
  value: PropTypes.string,
  status: PropTypes.string,
  dataComponentId: PropTypes.string,
  editable: PropTypes.bool,
  helperText: PropTypes.string,
  isReadOnly: PropTypes.bool,
  omitDisableOnProjectClosed: PropTypes.bool,
};

export default EditableTextDetailRow;
