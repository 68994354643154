import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RemoveIcon from "@material-ui/icons/Remove";
import moment from "moment";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const MissingDateIcon = styled(RemoveIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
`;

const Date = ({ date, format = PRIMARY_DATE_FORMAT, customMissingFormat }) =>
  date
    ? moment(date).format(format)
    : customMissingFormat || <MissingDateIcon />;

Date.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ getTime: PropTypes.func }),
  ]),
  format: PropTypes.string,
  customMissingFormat: PropTypes.string,
};

export default Date;
