import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";

import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import MoveForm from "./MoveForm";
import propTypes from "../../../../constants/propTypes";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

export const dataComponentId = "SpecsGrid";
export const areaTypesIdComponent = "select-area-types";

export const handleMoveSuccess = (
  closeModalDialog,
  showSnackNotificationAction,
  setReload
) => () => {
  setReload("SpecsGrid", true);
  showSnackNotificationAction("Specs have been moved successfully");
  closeModalDialog();
};

export const processDatacomponentChange = (
  prevDataComponent,
  dataComponent,
  handleMoveSuccess
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleMoveSuccess,
    onError: error => handleRequestError(error),
  });
};

const MoveContainer = props => {
  const {
    projectId,
    performUpdateRequest,
    selectedSpecs,
    dataComponent,
    flattenedDataComponent,
    closeModalDialog,
    showSnackNotificationAction,
    setReload,
  } = props;

  const [prevDatacomponent, setPrevDatacomponent] = useState(dataComponent);
  const [targetAreaId, setTargetAreaId] = useState("-1");

  useEffect(() => {
    processDatacomponentChange(
      prevDatacomponent,
      dataComponent,
      handleMoveSuccess(
        closeModalDialog,
        showSnackNotificationAction,
        setReload
      )
    );
  }, [
    prevDatacomponent,
    dataComponent,
    closeModalDialog,
    showSnackNotificationAction,
    setReload,
  ]);

  useEffect(() => {
    setPrevDatacomponent(dataComponent);
  }, [dataComponent]);

  const handleMove = () => {
    const updatedSpecs = selectedSpecs.map(spec => ({
      id: spec.id,
      areaId: targetAreaId,
    }));
    performUpdateRequest(dataComponentId, updatedSpecs);
  };

  return (
    <MoveForm
      dataComponent={flattenedDataComponent}
      projectId={projectId}
      targetAreaId={targetAreaId}
      onSetTargetAreaId={setTargetAreaId}
      onMove={handleMove}
    />
  );
};

MoveContainer.propTypes = {
  projectId: PropTypes.string,
  clientId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  selectedSpecs: PropTypes.arrayOf(propTypes.spec),
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  return {
    dataComponent,
    flattenedDataComponent,
    selectedSpecs: getBluechipResources(
      flattenedDataComponent,
      state,
      flattenedDataComponent.selectedIds
    ),
  };
};

export const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveContainer);
