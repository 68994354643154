import buildTextTypeProvider from "../../../../ui/Grid/filterDataTypeProviders/TextTypeProvider";

const textFilterColumns = ["description", "customNumber"];

export default () => () => {
  const providerDescriptions = [
    {
      builder: buildTextTypeProvider,
      columns: textFilterColumns,
    },
  ];
  return providerDescriptions.map(({ builder, columns }) => builder(columns));
};
