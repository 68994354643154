import React from "react";
import PropTypes from "prop-types";
import { useRef, useCallback, useContext, useEffect } from "react";
import { connect } from "react-redux";

import FloatInput, {
  FloatInput as RawFloatInput,
} from "../../../../inputs/FloatInput";
import BWCell from "../../../../ui/BWGrid/gridInternalComponents/BWCell";
import { WithEditableCellContext } from "../../../../ui/BWGrid/withEditableCellController";
import { EditableWrapper } from "../../../../ui/BWGrid/components";
import { updateBidGroupVendorSpecQuotes } from "../../../../../actions/bidGroupActions";
import { buildPressEventHandlers } from "../../../../../utils/eventUtils";

export const buildHandleChange = (
  beforeSave,
  updateBidGroupVendorSpecQuotes,
  columnName,
  dataComponentId,
  initialValue,
  params,
  row,
  bidGroupVendorRef
) => async value => {
  const parsedValue = beforeSave ? beforeSave(value, columnName, row) : value;
  if (value === initialValue) return;

  updateBidGroupVendorSpecQuotes(
    dataComponentId,
    row,
    params,
    columnName,
    parsedValue,
    bidGroupVendorRef
  );
};

export const EditableCellAPI = ({
  input,
  children,
  dataComponentId,
  updateBidGroupVendorSpecQuotes,
  setAutoSaveComponentId,
  bidGroupVendorRef,
  params,
  ...props
}) => {
  const { column, row, tableRow } = props;
  const { editableOptions = {} } = column;
  const columnId = column.columnId;
  const rowId = tableRow.rowId;

  const { addCell, removeCell, setCurrentCell, currentCell } = useContext(
    WithEditableCellContext
  );

  const { beforeSave, formatValue } = editableOptions;
  const anchorEl = useRef(null);
  const isEditable =
    currentCell.columnId === columnId && currentCell.rowId === rowId;

  useEffect(() => {
    addCell(rowId, columnId);
    return () => removeCell(rowId, columnId);
  }, [rowId, columnId, addCell, removeCell]);

  const startEditMode = useCallback(() => {
    setCurrentCell({ rowId, columnId });
  }, [setCurrentCell, rowId, columnId]);

  const initialValue = formatValue ? formatValue(row) : row[column.name];

  const handleChange = useCallback(
    buildHandleChange(
      beforeSave,
      updateBidGroupVendorSpecQuotes,
      column.name,
      dataComponentId,
      initialValue,
      params,
      row,
      bidGroupVendorRef
    ),
    [
      beforeSave,
      updateBidGroupVendorSpecQuotes,
      column.name,
      dataComponentId,
      initialValue,
      params,
      row,
      bidGroupVendorRef,
    ]
  );

  const { onClick, onKeyUp } = buildPressEventHandlers(
    startEditMode,
    false,
    false,
    []
  );

  return (
    <BWCell {...props}>
      <EditableWrapper ref={anchorEl} onClick={onClick} onKeyUp={onKeyUp}>
        {children}
      </EditableWrapper>
      <FloatInput
        input={input}
        onChange={handleChange}
        autoFocus
        name={column.name}
        value={initialValue}
        visible={isEditable}
        hasError={false}
        anchorEl={() => anchorEl.current}
      />
    </BWCell>
  );
};

EditableCellAPI.propTypes = {
  input: RawFloatInput.propTypes.input,
  row: PropTypes.shape({}).isRequired,
  params: PropTypes.shape({}),
  bidGroupVendorRef: PropTypes.shape({}),
};

export const mapStateToProps = () => {
  return {};
};

export const mapDispatchToProps = {
  updateBidGroupVendorSpecQuotes,
};

const ConnectedEditableCellAPI = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableCellAPI);

export default ConnectedEditableCellAPI;

export function editableCellCreator(
  rowInputs,
  dataComponentId,
  params,
  isEditable,
  bidGroupVendorRef
) {
  return function CellWrapper({ children, ...props }) {
    const { column, tableRow } = props;
    if (!isEditable(column, tableRow))
      return <BWCell {...props}>{children}</BWCell>;

    const input = rowInputs[column.name];
    return (
      <ConnectedEditableCellAPI
        {...props}
        input={input}
        dataComponentId={dataComponentId}
        params={params}
        bidGroupVendorRef={bidGroupVendorRef}
      >
        {children}
      </ConnectedEditableCellAPI>
    );
  };
}
