import React from "react";
import _uniq from "lodash/uniq";
import _get from "lodash/get";
import find from "lodash/find";

import VendorColumn from "../VendorColumn";
import Price from "../../../ui/Price";
import { Select } from "./components";
import PercentageInput from "../../../inputs/PercentageInput";
import CurrencyInput from "../../../inputs/CurrencyInput";
import EditableSelect from "./editableControls/EditableSelect";
import InputNumber from "./editableControls/InputNumber";
import { freeTextRender } from "./editableControls/components";
import InputTextArea from "./editableControls/InputTextArea";
import { projectCurrenciesDataComponentId } from "../../../../actions/currencyActions";
import { unitOfMeasureDataComponentId } from "../../../../actions/specsActions";
import Vendor from "../../../../models/Vendor";
import { getSpecCategoryLabel } from "../../../../models/SpecCategory";

export const columns = [
  "customNumber",
  "description",
  "specCategoryId",
  "areaId",
  "vendorId",
  "projectCurrency.id",
  "priceCents",
  "baseQuantity",
  "atticStock",
  "overagePercent",
  "overageValue",
  "totalQuantity",
  "unitOfMeasureId",
];

export const columnOptions = {
  customNumber: { title: "Spec Number", bold: true, filter: true },
  description: { filter: true },
  unitOfMeasureId: {
    title: "UOM",
    filter: "select",
    render: ({ unitOfMeasure }) => _get(unitOfMeasure, "name", ""),
  },
  designerBudget: {
    title: "Designer Budget",
    filter: false,
    width: "180px",
    render: ({ designerBudget, projectCurrency = {} }) =>
      designerBudget && (
        <Price number={designerBudget} currency={projectCurrency.currency} />
      ),
  },
  designerQuantity: { title: "Designer Qty", filter: false },
  overagePercent: {
    title: "Overage %",
    filter: false,
    render: ({ overagePercent }) => overagePercent && `${overagePercent}%`,
  },
  overageValue: {
    title: "Overage Value",
    filter: false,
  },
  totalQuantity: {
    filter: false,
    title: "Qty",
  },
  baseQuantity: {
    title: "Base Qty",
    filter: false,
  },
  atticStock: { filter: false, width: "100px" },
  projectCurrencyId: {
    title: "Currency",
    filter: false,
    render: ({ projectCurrency = {} }) => {
      return projectCurrency.currency?.name;
    },
  },
  priceCents: {
    title: "Price",
    filter: false,
    width: "180px",
    render: ({ priceCents, projectCurrency = {} }) =>
      priceCents && (
        <Price number={priceCents / 100} currency={projectCurrency.currency} />
      ),
  },
  qty: {
    filter: false,
    render: ({ atticStock, overagePercent, baseQuantity }) => {
      atticStock = atticStock || 0;
      baseQuantity = baseQuantity || 0;
      const overageValue = Math.ceil(baseQuantity * (overagePercent / 100));
      return baseQuantity + atticStock + overageValue;
    },
  },
  areaId: {
    title: "Area",
    width: "150px",
    filter: true,
    sortingEnabled: true,
    render: ({ area }) => area && area.name,
  },
  vendorId: {
    title: "Vendor",
    render: VendorColumn,
    width: "200px",
    filter: true,
    sortingEnabled: false,
  },
  specCategoryId: {
    title: "Category",
    width: "200px",
    filter: true,
    sortingEnabled: false,
    render: ({ specCategory }) =>
      specCategory && getSpecCategoryLabel(specCategory),
  },
};

export const isSpecCategoryOptionDisabled = ({ isActive }) => !isActive;
const buildSelectSpecCategoryIdColumn = (
  onUpdateAttributes,
  specCategories
) => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          dataComponentId="select-specCategories"
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          value={spec.specCategoryId}
          isOptionDisabled={isSpecCategoryOptionDisabled}
          labelProperty={getSpecCategoryLabel}
          onChange={({ target: { value } }) => {
            const specCategory = find(specCategories, { id: value });
            return onUpdateAttributes(spec.id, {
              specCategoryId: value,
              specCategory,
            });
          }}
        />
      )
    );
  };
};

const buildSelectVendorIdColumn = (
  onUpdateAttributes,
  vendors,
  isImportToPO
) => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          dataComponentId="select-vendors"
          disabled={isImportToPO}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          value={spec.vendorId}
          onChange={({ target: { value } }) => {
            const vendor = vendors.find(vendor => vendor.id == value);
            return onUpdateAttributes(spec.id, {
              vendorId: value,
              vendor,
            });
          }}
          isOptionDisabled={Vendor.isVendorDisabled}
          apiFilterLabelProperty="name"
          labelProperty={Vendor.labelProperty}
        />
      )
    );
  };
};

const buildUnitSelect = onUpdateAttributes => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          dataComponentId={unitOfMeasureDataComponentId}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          value={_get(spec, "unitOfMeasureId", null)}
          onChange={({ target: { value } }) =>
            onUpdateAttributes(spec.id, {
              unitOfMeasureId: value == "" ? null : value,
            })
          }
        />
      )
    );
  };
};

const buildSelectColumn = onUpdateAttributes => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          dataComponentId={"select-areas"}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          value={spec.areaId}
          onChange={({ target: { value } }) =>
            onUpdateAttributes(spec.id, {
              areaId: value,
            })
          }
        />
      )
    );
  };
};

const buildSelectCurrencyColumn = (
  onUpdateAttributes,
  projectCurrencies,
  poProjectCurrency
) => {
  return (spec, name) => {
    return (
      spec && (
        <EditableSelect
          name={name}
          rowId={spec.id}
          SelectComponent={Select}
          valueProperty={"id"}
          labelProperty={"currency.name"}
          displayEmpty={false}
          dataComponentId={projectCurrenciesDataComponentId}
          ignoreCaption
          InputProps={{ disableUnderline: true }}
          options={poProjectCurrency ? [poProjectCurrency] : undefined}
          value={
            poProjectCurrency ? poProjectCurrency.id : spec.projectCurrencyId
          }
          disabled={poProjectCurrency !== undefined}
          onChange={({ target: { value } }) => {
            const projectCurrency = projectCurrencies.find(
              projectCurrency => projectCurrency.id == value
            );
            return onUpdateAttributes(spec.id, {
              projectCurrencyId: value,
              projectCurrency: projectCurrency,
            });
          }}
        />
      )
    );
  };
};

export const buildTextFieldColumn = (
  onUpdateAttributes,
  defaultId,
  key,
  InputComponent
) => {
  return (spec, name) => {
    const inputProps =
      InputComponent === PercentageInput ? { decimalScale: 2 } : {};

    inputProps.currency =
      InputComponent === CurrencyInput
        ? spec.projectCurrency?.currency
        : undefined;

    return (
      spec && (
        <InputNumber
          name={name}
          rowId={spec.id}
          ignoreCaption
          inputProps={inputProps}
          InputProps={{
            inputComponent: InputComponent,
            disableUnderline: true,
          }}
          value={spec[key] === undefined ? defaultId : spec[key]}
          onChange={({ target: { value } }) => {
            return onUpdateAttributes(spec.id, {
              [key]: value,
            });
          }}
        />
      )
    );
  };
};

export const buildTextAreaColumn = (
  onUpdateAttributes,
  defaultId,
  key,
  InputComponent
) => {
  return (spec, name) => {
    const inputProps =
      InputComponent === PercentageInput ? { decimalScale: 2 } : {};
    return (
      spec && (
        <InputTextArea
          name={name || key}
          rowId={spec.id}
          ignoreCaption
          inputProps={inputProps}
          InputProps={{
            inputComponent: InputComponent,
            disableUnderline: true,
          }}
          value={spec[key] === undefined ? defaultId : spec[key]}
          handleBlur={value => {
            return onUpdateAttributes(spec.id, {
              [key]: value,
            });
          }}
        />
      )
    );
  };
};

const getTextInputRow = (
  key,
  onUpdateAttributes,
  defaultValue,
  InputComponent
) => {
  return {
    ...columnOptions[key],
    sortingEnabled: false,
    render: buildTextFieldColumn(
      onUpdateAttributes,
      defaultValue,
      key,
      InputComponent
    ),
  };
};

export const getAdditionalColumnsFromFile = (specs, onUpdateAttributes) => {
  const iterableColumns = {};
  let iteration = 0;
  do {
    iteration++;
    if (specs.length > 0 && oneSpecWithRoomIteration(specs, iteration)) {
      iterableColumns[`roomName${iteration}`] = {
        title: `Room Name ${iteration}`,
        filter: false,
        render: freeTextRender,
      };
      iterableColumns[`roomCount${iteration}`] = {
        title: `Room Count ${iteration}`,
        filter: false,
        render: freeTextRender,
      };
      iterableColumns[`roomPerRoom${iteration}`] = {
        title: `Per Room ${iteration}`,
        filter: false,
        render: freeTextRender,
      };
      iterableColumns[`roomPar${iteration}`] = {
        title: `Par ${iteration}`,
        filter: false,
        ...getTextInputRow(`roomPar${iteration}`, onUpdateAttributes),
      };
    } else {
      iteration = 0;
    }
  } while (iteration > 0);

  return {
    designerBudget: getTextInputRow(
      "designerBudget",
      onUpdateAttributes,
      undefined,
      CurrencyInput
    ),
    designerQuantity: getTextInputRow("designerQuantity", onUpdateAttributes),
    unitsNeeded: {
      title: "Units Needed",
      filter: false,
      render: freeTextRender,
    },
    unitsPerCase: {
      title: "Units per Case",
      filter: false,
      render: freeTextRender,
    },
    qcParBaseUnitsPerCase: {
      title: "QC Par Base Units per Case",
      filter: false,
      render: freeTextRender,
    },
    ...iterableColumns,
  };
};

export function getRoomColumns(specs) {
  const roomColumns = [];
  let iteration = 0;
  do {
    iteration++;
    if (specs.length > 0 && oneSpecWithRoomIteration(specs, iteration)) {
      roomColumns.push(
        `roomName${iteration}`,
        `roomCount${iteration}`,
        `roomPerRoom${iteration}`,
        `roomPar${iteration}`
      );
    } else {
      iteration = 0;
    }
  } while (iteration > 0);
  return roomColumns;
}

export const getColumns = (isFromProjects, specs) => {
  let finalColumns = [...columns];

  if (isFromProjects) return [...finalColumns];

  finalColumns.push();

  return [
    ...finalColumns,
    "designerBudget",
    "designerQuantity",
    "unitsNeeded",
    "unitsPerCase",
    "qcParBaseUnitsPerCase",
    ...getRoomColumns(specs),
  ];
};

const oneSpecWithRoomIteration = (specs, iteration) => {
  return specs.some(
    spec =>
      spec[`roomName${iteration}`] ||
      spec[`roomCount${iteration}`] ||
      spec[`roomPerRoom${iteration}`] ||
      spec[`roomPar${iteration}`]
  );
};

export const getEditableOptions = (
  {
    vendors,
    areas,
    categories,
    projectCurrencies,
    fullProjectCurrencies,
    unitOfMeasures,
    specCategories,
  },
  onUpdateAttributes,
  isFromProjects,
  specs,
  poProjectCurrency,
  isImportToPO
) => {
  const additionalColumnsFromFile = !isFromProjects
    ? getAdditionalColumnsFromFile(specs, onUpdateAttributes)
    : {};
  return {
    ...columnOptions,
    customNumber: getTextInputRow("customNumber", onUpdateAttributes),
    description: getTextInputRow("description", onUpdateAttributes),
    baseQuantity: getTextInputRow("baseQuantity", onUpdateAttributes),
    priceCents: getTextInputRow(
      "priceCents",
      onUpdateAttributes,
      undefined,
      CurrencyInput
    ),
    unitOfMeasureId: {
      ...columnOptions.unitOfMeasureId,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: unitOfMeasures,
      },
      render: buildUnitSelect(onUpdateAttributes),
    },
    atticStock: getTextInputRow("atticStock", onUpdateAttributes),
    overagePercent: getTextInputRow(
      "overagePercent",
      onUpdateAttributes,
      undefined,
      PercentageInput
    ),
    "projectCurrency.id": {
      ...columnOptions.projectCurrencyId,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: projectCurrencies.map(projectCurrency => ({
          ...projectCurrency,
          name: projectCurrency.currency.name,
        })),
      },
      render: buildSelectCurrencyColumn(
        onUpdateAttributes,
        fullProjectCurrencies,
        poProjectCurrency
      ),
    },
    areaId: {
      ...columnOptions.areaId,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: areas,
      },
      render: buildSelectColumn(onUpdateAttributes),
    },
    vendorId: {
      ...columnOptions.vendorId,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: vendors,
      },
      render: buildSelectVendorIdColumn(
        onUpdateAttributes,
        vendors,
        isImportToPO
      ),
    },
    specCategoryId: {
      ...columnOptions.specCategoryId,
      sortingEnabled: false,
      filter: "select",
      filterOptions: {
        options: categories.filter(({ isActive }) => isActive),
      },
      render: buildSelectSpecCategoryIdColumn(
        onUpdateAttributes,
        specCategories
      ),
    },
    ...additionalColumnsFromFile,
  };
};

export const getOptions = (
  defaultAreaId,
  isReadOnly,
  { vendors, areas, categories, projectCurrencies, unitOfMeasures }
) => {
  return {
    ...columnOptions,
    areaId: {
      ...columnOptions.areaId,
      filter: "select",
      filterOptions: {
        options: areas,
      },
      render: columnOptions.areaId.render,
    },
    "projectCurrency.id": {
      ...columnOptions.projectCurrencyId,
      filter: "select",
      filterOptions: {
        options: projectCurrencies.map(projectCurrency => ({
          ...projectCurrency,
          name: projectCurrency.currency.name,
        })),
      },
      render: columnOptions.projectCurrencyId.render,
    },
    vendorId: {
      ...columnOptions.vendorId,
      filter: "select",
      filterOptions: {
        options: vendors,
      },
      render: columnOptions.vendorId.render,
    },
    specCategoryId: {
      ...columnOptions.specCategoryId,
      filter: "select",
      filterOptions: {
        options: categories,
      },
      render: columnOptions.specCategoryId.render,
    },
    unitOfMeasureId: {
      ...columnOptions.unitOfMeasureId,
      filter: "select",
      filterOptions: {
        options: unitOfMeasures,
      },
      render: columnOptions.unitOfMeasureId.render,
    },
  };
};

export const getOptionsFrom = (specs, key, collection, defaultId) => {
  const ids = _uniq(
    specs.map(item => _get(item, key) || defaultId).filter(id => id)
  );
  return (collection || [])
    .filter(props => props)
    .filter(({ id }) => ids.includes(id));
};
