import React, { Fragment, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import Divider from "@material-ui/core/Divider";

import feeAmountFields from "./feeAmountFields";
import feeFields from "./feeFields";
import currencyFormatter from "../../../../../../utils/currencyFormatter";
import FormGrid from "../../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../../components/forms/FormikForm";
import propTypes from "../../../../../../constants/propTypes";
import { ProjectContext } from "../../../../../hooks/useIsProjectClosed";
import { useLabeledText } from "../../../../../inputs/TextInputWithError";
import { LabeledTextContext } from "../../../../../../withPORevision/withLabeledTextOption";

export const getFeeAmount = ({ ffeAmount, oseAmount }) => {
  if (!ffeAmount || !oseAmount) return ["Add FF&E and OS&E Amounts"];
  return [currencyFormatter.format(ffeAmount + oseAmount)];
};

const FeeForm = ({ dataComponentId, onSubmit, isUpdate, initialValues }) => {
  const { project } = useContext(ProjectContext);

  const validationSchema = useMemo(
    () =>
      isUpdate && initialValues.amountPaid != 0
        ? BWModels.loadSchema("Fee").append({
            amount: BWModels.Joi.number()
              .precision(2)
              .when("revenueSharing", {
                is: false,
                then: BWModels.Joi.number()
                  .min(initialValues.amountPaid)
                  .required(),
              }),
            oseAmount: BWModels.Joi.number()
              .precision(2)
              .when("revenueSharing", {
                is: true,
                then: BWModels.Joi.number()
                  .min(initialValues.amountPaid)
                  .required(),
              }),
          })
        : BWModels.loadSchema("Fee"),
    [initialValues.amountPaid, isUpdate]
  );

  const isLabeledText = useLabeledText();
  const isDisabled = isLabeledText || initialValues.status == "Paid";

  return (
    <LabeledTextContext.Provider value={isDisabled}>
      <FormikForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={feeAmountFields(
                project,
                values,
                formikProps.setFieldValue
              )}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <Divider />
            <FormGrid
              fields={feeFields}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              sendButtonText={isUpdate ? "SAVE CHANGES" : "SAVE FEE"}
              onSend={handleSubmit}
              listeners={[dataComponentId]}
              isModal={true}
            />
          </Fragment>
        )}
      </FormikForm>
    </LabeledTextContext.Provider>
  );
};

FeeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  initialValues: propTypes.fee,
};

export default FeeForm;
