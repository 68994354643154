import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import GLCodePage from "./GLCodePage";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";

const dataComponentId = "GLCodesGrid";

const GLCodeContainer = ({ clientId, dataComponent, openModalDialog }) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(
      "Create GL Code",
      "CreateGLCode",
      {
        clientId,
      },
      false
    );
  }, [openModalDialog, clientId]);

  const handleOpenEditModal = useCallback(
    glCode => {
      openModalDialog(
        `Edit ${glCode.code}`,
        "EditGLCode",
        {
          glCode,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDuplicateModal = useCallback(
    glCode => {
      openModalDialog(
        "Duplicate GL Code",
        "CreateGLCode",
        {
          glCode,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, code }) => {
      openModalDialog(
        `Delete ${code}`,
        "DeleteGLCode",
        {
          glCodeId: id,
          title: code,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenImportModal = useCallback(() => {
    openModalDialog(
      "Upload GL Codes",
      "ImportFromFile",
      {
        dataComponentId,
        additionalValues: { clientId },
        pluralEntity: "GL CODES",
      },
      false
    );
  }, [openModalDialog, clientId]);

  const handleOpenBulkArchiveModal = useCallback(() => {
    openModalDialog("Archive GL Codes", "ArchiveGLCode", {}, true, true);
  }, [openModalDialog]);

  const handleOpenBulkUnarchiveModal = useCallback(() => {
    openModalDialog(
      "Unarchive GL Codes",
      "ArchiveGLCode",
      { option: "unarchive" },
      true,
      true
    );
  }, [openModalDialog]);

  const handleOpenBulkDeleteModal = useCallback(() => {
    openModalDialog("Delete GL Codes", "BulkDeleteGLCode");
  }, [openModalDialog]);

  return (
    <GLCodePage
      clientId={clientId}
      dataComponent={dataComponent}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDuplicateModal={handleOpenDuplicateModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      onOpenImportModal={handleOpenImportModal}
      onOpenBulkArchiveModal={handleOpenBulkArchiveModal}
      onOpenBulkUnarchiveModal={handleOpenBulkUnarchiveModal}
      onOpenBulkDeleteModal={handleOpenBulkDeleteModal}
    />
  );
};

GLCodeContainer.propTypes = {
  clientId: PropTypes.string,
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, { client }) => {
  return {
    clientId: client.id,
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(GLCodeContainer);
