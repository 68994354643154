import { BaseModel } from "@blue-chip/core";
import PurchaseOrder from "./PurchaseOrder";
import RemitAddress from "./RemitAddress";
import VendorContact from "./VendorContact";
import GLCode from "./GLCode";
import InvoiceSpec from "./InvoiceSpec";

export default class Invoice extends BaseModel {
  static get belongsTo() {
    return [PurchaseOrder, RemitAddress, VendorContact];
  }

  static get hasMany() {
    return [GLCode, InvoiceSpec];
  }
}
