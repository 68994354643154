import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import TeamForm from "../CreateTeam/TeamForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { reloadDataComponent } from "../../../../../actions/initializeStoreActions";
import { TEAMS_SELECT } from "../../../../../constants/DataComponents";
import { dataComponentId } from "../TeamsContainer";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      reloadDataComponent(TEAMS_SELECT);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const EditTeamContainer = ({
  dataComponent,
  closeModalDialog,
  team,
  setReload,
  reloadDataComponent,
  performUpdateRequest,
  loading,
  params,
}) => {
  team.users = team.users.map(user => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
  }));
  delete team.category;
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions
    );
  });

  const handleEdit = useCallback(
    (instance, formikActions) => {
      const updatedTeam = {
        id: instance.id,
        name: instance.name,
        users: instance.users.map(user => ({ id: user.id })),
      };
      performUpdateRequest(
        dataComponentId,
        updatedTeam.id,
        updatedTeam,
        params
      );
      setFormikAction(formikActions);
    },
    [performUpdateRequest, params]
  );

  return (
    <TeamForm
      initialValues={team}
      loading={loading}
      onSubmit={handleEdit}
      isUpdate
    />
  );
};

EditTeamContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  team: propTypes.team,
  performUpdateRequest: PropTypes.func,
  setReload: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  params: PropTypes.shape({
    termContext: PropTypes.string,
    contextValue: PropTypes.string,
  }),
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.update.loading"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamContainer);
