import React, { Component } from "react";
import PropTypes from "prop-types";

export default class BaseError extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  render() {
    const { children } = this.props;

    let className = "";
    if (!children || children.length == 0) {
      className = "hidden";
    }

    return <div className={className}>{children}</div>;
  }
}
