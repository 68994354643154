import TextInputWithError from "../TextInputWithError";
import FilePickerWithError from "../FilePickerWithError";
import DatePickerWithError from "../DatePickerWithError";

export default {
  submittalDescription: {
    InputComponent: TextInputWithError,
    label: "Description",
    name: "description",
    fullWidth: true,
    multiline: true,
    rows: 3,
  },
  submittalFile: {
    InputComponent: FilePickerWithError,
    name: "file",
    label: "Select File",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {},
  },
  submittalCreatedAt: {
    InputComponent: DatePickerWithError,
    label: "Date Uploaded",
    name: "createdAt",
    disabled: true,
    fullWidth: true,
  },
};
