import { useEffect, useRef } from "react";
import {
  processUpdateRequestStatus,
  processCreateRequestStatus,
  processDeleteRequestStatus,
  haveActiveRequests,
  processImportRequestStatus,
  processListRequestStatus,
} from "../../utils/dataComponentUtils";

const processRequests = (
  { preDataComponent, dataComponent },
  callbacks,
  processRequestStatus = processUpdateRequestStatus
) => {
  processRequestStatus(preDataComponent.current, dataComponent, callbacks);
  preDataComponent.current = dataComponent;
};

export const useListProcessRequests = (
  dataComponent,
  { onSuccess, onError }
) => {
  const preDataComponent = useRef(dataComponent);
  useEffect(() => {
    processRequests(
      { preDataComponent, dataComponent },
      {
        onSuccess,
        onError,
      },
      processListRequestStatus
    );
  }, [dataComponent, onError, onSuccess, preDataComponent]);

  return haveActiveRequests(dataComponent);
};

export const useCreateProcessRequests = (
  dataComponent,
  { onSuccess, onError }
) => {
  const preDataComponent = useRef(dataComponent);
  useEffect(() => {
    processRequests(
      { preDataComponent, dataComponent },
      {
        onSuccess,
        onError,
      },
      processCreateRequestStatus
    );
  }, [dataComponent, onError, onSuccess, preDataComponent]);

  return haveActiveRequests(dataComponent);
};

export const useUpdateProcessRequests = (
  dataComponent,
  { onSuccess, onError }
) => {
  const preDataComponent = useRef(dataComponent);
  useEffect(() => {
    processRequests(
      { preDataComponent, dataComponent },
      {
        onSuccess,
        onError,
      }
    );
  }, [dataComponent, onError, onSuccess, preDataComponent]);

  return haveActiveRequests(dataComponent);
};

export const useDeleteProcessRequests = (
  dataComponent,
  { onSuccess, onError }
) => {
  const preDataComponent = useRef(dataComponent);
  useEffect(() => {
    processRequests(
      { preDataComponent, dataComponent },
      {
        onSuccess,
        onError,
      },
      processDeleteRequestStatus
    );
  }, [dataComponent, onError, onSuccess, preDataComponent]);

  return haveActiveRequests(dataComponent);
};

export const useImportProcessRequests = (
  dataComponent,
  { onSuccess, onError }
) => {
  const preDataComponent = useRef(dataComponent);
  useEffect(() => {
    processRequests(
      { preDataComponent, dataComponent },
      {
        onSuccess,
        onError,
      },
      processImportRequestStatus
    );
  }, [dataComponent, onError, onSuccess, preDataComponent]);

  return haveActiveRequests(dataComponent);
};
