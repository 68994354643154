import { createSelector } from "reselect";
import arrayMove from "array-move";

import * as actionTypes from "../constants/ActionTypes";
import { dataComponentInitialData, requestInitialStates } from "./initialState";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { createDeepEqualSelector } from "../utils/selectors";

// ---------------------------------------------------------------------------
// Selectors
// ---------------------------------------------------------------------------

const selectorsByDatacomponentId = {};
const flattenedSelectorsByDatacomponentId = {};

const getDataComponentSelector = (state, { dataComponentId }) => {
  if (!selectorsByDatacomponentId[dataComponentId]) {
    selectorsByDatacomponentId[dataComponentId] = createDeepEqualSelector(
      state => state.dataComponents[dataComponentId],
      dataComponent => {
        if (!dataComponent) {
          return { ...dataComponentInitialData, dataComponentId };
        }
        return dataComponent;
      }
    );
  }
  return selectorsByDatacomponentId[dataComponentId](state);
};

const getDataComponentFlattenedRequestStateSelector = (state, props) => {
  const { dataComponentId } = props;
  if (!flattenedSelectorsByDatacomponentId[dataComponentId]) {
    flattenedSelectorsByDatacomponentId[
      dataComponentId
    ] = createDeepEqualSelector(
      getDataComponentSelector,
      (_, { requestType }) => requestType,
      (dataComponent, requestType) => {
        if (!dataComponent) {
          return {
            ...dataComponentInitialData,
            dataComponentId,
            requestType,
            ...requestInitialStates[requestType],
          };
        }

        const flattenedState = {
          ...dataComponent,
          requestType,
          ...dataComponent.requestState[requestType],
        };
        delete flattenedState.requestState;

        return flattenedState;
      }
    );
  }
  return flattenedSelectorsByDatacomponentId[dataComponentId](state, props);
};

const getDataComponentRequestStateSelector = createSelector(
  getDataComponentSelector,
  dataComponent => {
    if (!dataComponent) {
      return {};
    }
    return dataComponent.requestState;
  }
);

export const getDataComponent = (dataComponentId, state) => {
  if (dataComponentId === undefined) {
    throw new Error(`dataComponentId undefined`);
  }
  return getDataComponentSelector(state, { dataComponentId });
};

export const getDataComponentRequestState = (dataComponentId, state) => {
  if (dataComponentId === undefined) {
    throw new Error(`dataComponentId undefined`);
  }
  return getDataComponentRequestStateSelector(state, { dataComponentId });
};

export const getDataComponentFlattenedRequestState = (
  dataComponentId,
  state,
  requestType = REQUEST_TYPES.LIST
) => {
  if (dataComponentId === undefined) {
    throw new Error(`dataComponentId undefined`);
  }
  return getDataComponentFlattenedRequestStateSelector(state, {
    dataComponentId,
    requestType,
  });
};

// ---------------------------------------------------------------------------
// Reducer (factory function)
// ---------------------------------------------------------------------------

const reducerFuncs = {
  [actionTypes.DATA_COMPONENT_INIT]: reduceDataComponentInit,
  [actionTypes.DATA_COMPONENT_DESTROY]: reduceDataComponentDestroy,
  [actionTypes.DATA_COMPONENT_CLONE]: reduceDataComponentClone,
  [actionTypes.DATA_COMPONENT_CLONE_SET_REQUEST_STATE]: reduceDataComponentCloneSetRequestState,
  [actionTypes.DATA_COMPONENT_CLONE_OVERWRITE_PROP]: reduceDataComponentCloneOverwriteProp,
  [actionTypes.DATA_COMPONENT_SET_PAGE]: updateDataComponentRequestState,
  [actionTypes.DATA_COMPONENT_SET_RELOAD]: updateDataComponentRequestState,
  [actionTypes.DATA_COMPONENT_SET_PAGE_SIZE]: updateDataComponentRequestState,
  [actionTypes.DATA_COMPONENT_SET_SORT]: updateDataComponentRequestState,
  [actionTypes.DATA_COMPONENT_SET_SELECTION]: reduceDataComponentSetSelection,
  [actionTypes.DATA_COMPONENT_SET_SELECTION_V2]: reduceDataComponentSetSelectionV2,
  [actionTypes.DATA_COMPONENT_REORDER]: reduceDataComponentReorder,
  [actionTypes.DATA_COMPONENT_SET_API_FILTERS]: updateDataComponentRequestState,
  [actionTypes.DATA_COMPONENT_REQUEST]: reduceDataComponentRequest,
  [actionTypes.DATA_COMPONENT_SUCCESS]: reduceDataComponentSuccess,
  [actionTypes.DATA_COMPONENT_FAILURE]: reduceDataComponentFailure,
};

export default function(state = {}, action) {
  const {
    type,
    dataComponentId,
    payload = {},
    requestType = REQUEST_TYPES.LIST,
  } = action;

  switch (type) {
    case actionTypes.DATA_COMPONENT_INIT:
      return (reducerFuncs[type] || (() => state))(
        state,
        dataComponentId,
        requestType,
        payload,
        action.skipInitIfExists
      );
    default:
      return (reducerFuncs[type] || (() => state))(
        state,
        dataComponentId,
        requestType,
        payload
      );
  }
}

function updateDataComponent(state, dataComponentId, dataComponentUpdate) {
  const dataComponentState = state[dataComponentId] || {
    ...dataComponentInitialData,
    dataComponentId,
  };
  return {
    ...state,
    [dataComponentId]: {
      dataComponentId,
      ...dataComponentState,
      ...dataComponentUpdate,
    },
  };
}

function updateDataComponentRequestState(
  state,
  dataComponentId,
  requestType,
  update
) {
  let newState = state;
  if (!state[dataComponentId]) {
    newState = updateDataComponent(state, dataComponentId, {});
  }

  const dataComponentRequestState =
    newState[dataComponentId].requestState[requestType] ||
    requestInitialStates[requestType];

  return {
    ...newState,
    [dataComponentId]: {
      ...newState[dataComponentId],
      requestState: {
        ...newState[dataComponentId].requestState,
        [requestType]: {
          ...dataComponentRequestState,
          ...update,
        },
      },
    },
  };
}

function reduceDataComponentInit(
  state,
  dataComponentId,
  requestType,
  payload,
  skipInitIfExists
) {
  if (state[dataComponentId] && skipInitIfExists) {
    return state;
  }

  const newState = updateDataComponent(state, dataComponentId, {
    ...dataComponentInitialData,
    dataComponentId,
    ...payload,
  });

  return updateDataComponentRequestState(
    newState,
    dataComponentId,
    requestType,
    {}
  );
}

function reduceDataComponentDestroy(state, dataComponentId) {
  return { ...state, [dataComponentId]: undefined };
}

function reduceDataComponentClone(
  state,
  srcDataComponentId,
  requestType,
  { dstDataComponentId, skipInitIfExists, cloneRequestState }
) {
  if (state[dstDataComponentId] && skipInitIfExists) {
    return state;
  }
  const dataComponentState = state[srcDataComponentId] || {
    ...dataComponentInitialData,
  };
  const requestState = cloneRequestState
    ? dataComponentState.requestState
    : dataComponentInitialData.requestState;
  return {
    ...state,
    [dstDataComponentId]: {
      ...dataComponentState,
      dataComponentId: dstDataComponentId,
      requestState,
    },
  };
}

function reduceDataComponentCloneSetRequestState(
  state,
  srcDataComponentId,
  requestType,
  { dstDataComponentId, requestState, skipInitIfExists }
) {
  if (state[dstDataComponentId] && skipInitIfExists) {
    return state;
  }
  const dataComponentState = state[srcDataComponentId] || {
    ...dataComponentInitialData,
  };
  return {
    ...state,
    [dstDataComponentId]: {
      ...dataComponentState,
      dataComponentId: dstDataComponentId,
      requestState,
    },
  };
}

function reduceDataComponentCloneOverwriteProp(
  state,
  srcDataComponentId,
  requestType,
  {
    dstDataComponentId,
    skipInitIfExists,
    cloneRequestState,
    dstDataComponentProps,
  }
) {
  if (state[dstDataComponentId] && skipInitIfExists) {
    return state;
  }
  const dataComponentState = state[srcDataComponentId] || {
    ...dataComponentInitialData,
  };
  const requestState = cloneRequestState
    ? dataComponentState.requestState
    : dataComponentInitialData.requestState;

  return {
    ...state,
    [dstDataComponentId]: {
      ...dataComponentState,
      dataComponentId: dstDataComponentId,
      requestState,
      ...dstDataComponentProps,
    },
  };
}

function reduceDataComponentSetSelectionV2(
  state,
  dataComponentId,
  requestType,
  payload
) {
  const selectedIds = payload.selectedIds;
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    selectedIds: selectedIds,
  });
}

function reduceDataComponentSetSelection(
  state,
  dataComponentId,
  requestType,
  payload
) {
  const dataComponent = state[dataComponentId];
  const request = dataComponent.requestState[requestType];
  const selection = payload.selection;
  const currentPage = request.pageNumber;

  const selectedIdsByPage = [...request.selectedIdsByPage].map(
    (idsInPage, page) => {
      if (!selection[page] || selection[page].length === 0) {
        return [];
      }
      return idsInPage;
    }
  );
  selectedIdsByPage[currentPage] = (selection[currentPage] || []).map(
    i => request.rowIndex[i]
  );
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    selection,
    selectedIdsByPage,
    selectedIds: selectedIdsByPage.reduce(
      (ids, idsInPage) => [...ids, ...idsInPage],
      []
    ),
  });
}

function reduceDataComponentReorder(
  state,
  dataComponentId,
  requestType,
  { reorderInfo: { newIndex, oldIndex } }
) {
  const dataComponent = state[dataComponentId];
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    selection: {},
    selectedIds: [],
    selectedIdsByPage: [],
    rowIndex: arrayMove(
      dataComponent.requestState[requestType].rowIndex,
      oldIndex,
      newIndex
    ),
  });
}

function reduceDataComponentRequest(state, dataComponentId, requestType) {
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    loading: true,
    success: false,
    error: null,
  });
}

function reduceDataComponentSuccess(
  state,
  dataComponentId,
  requestType,
  payload
) {
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    loading: false,
    error: null,
    success: true,
    ...payload,
  });
}

function reduceDataComponentFailure(
  state,
  dataComponentId,
  requestType,
  payload
) {
  return updateDataComponentRequestState(state, dataComponentId, requestType, {
    loading: false,
    success: false,
    error: payload,
  });
}
