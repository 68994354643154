import { inputs } from "../../../../../inputs/inputConfigs";
const { quote: quoteFields } = inputs;

const addRequiredToInput = input => {
  return {
    ...input,
    required: true,
  };
};

export const getPotentialTariffInput = ({
  potentialTariffType,
  selectedCurrency,
}) => {
  const input = {
    "%": {
      ...quoteFields.potentialTariffPercent,
    },
    $: {
      ...quoteFields.potentialTariffAmount,
      inputProps: {
        currency: selectedCurrency,
      },
    },
  };

  return input[potentialTariffType];
};

export const buildQuoteFormFields = (
  projectId,
  selectedCurrency,
  potentialTariffType,
  isCurrencyFieldDisabled
) => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...quoteFields.quoteEstimatedShipDate,
              name: "estimatedShipDate",
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.projectCurrency,
              name: "quoteProjectCurrencyId",
              isDisabled: isCurrencyFieldDisabled,
              APIOptions: {
                ...inputs.projectCurrency.APIOptions,
                rootFilters: {
                  $where: { projectId },
                },
              },
            },
            grid: { xs: 6 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimesFrom),
            grid: { xs: 6 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimesTo),
            grid: { xs: 6 },
          },
          {
            input: addRequiredToInput(quoteFields.leadTimeForSubmittal),
            grid: { xs: 6 },
          },
          {
            input: quoteFields.dropDeadDates,
            grid: { xs: 6 },
          },
          {
            input: quoteFields.vat,
            grid: { xs: 6 },
          },
          {
            input: {
              ...quoteFields.estimatedFreightToWarehouse,
              inputProps: {
                currency: selectedCurrency,
              },
            },
            grid: { xs: 6 },
          },
          {
            input: addRequiredToInput(quoteFields.factoryLocation),
            grid: { xs: 12 },
          },
          {
            input: quoteFields.quoteDate,
            grid: { xs: 6 },
          },
          {
            input: quoteFields.quoteProvider,
            grid: { xs: 6 },
          },
          {
            input: {
              ...quoteFields.potentialTariffType,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...getPotentialTariffInput({
                potentialTariffType,
                selectedCurrency,
              }),
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...quoteFields.miscellaneousCosts,
              inputProps: {
                currency: selectedCurrency,
              },
            },
            grid: { xs: 6 },
          },
          {
            input: addRequiredToInput(quoteFields.fob),
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.paymentTerms,
              isAutocomplete: true,
              filter: paymentTerms => {
                return paymentTerms.filter(
                  paymentTerm => !paymentTerm.isArchived
                );
              },
              required: true,
              isSingleAutocomplete: true,
              menuPlacement: "top",
            },
            grid: { xs: 12 },
          },
          {
            input: quoteFields.miscellaneous,
            grid: { xs: 12 },
          },
          {
            input: quoteFields.notes,
            grid: { xs: 12 },
          },
          {
            input: quoteFields.bidComplete,
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
