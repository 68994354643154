import styled from "styled-components";
import MUIButton from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MUIListItemText from "@material-ui/core/ListItemText";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import MUICheckbox from "../../../../mui/core/Checkbox";

export const StyledTitle = styled.div`
  padding-left: 24px;
  display: flex;
  align-items: center;
`;

export const Button = styled(MUIButton)`
  text-transform: none;
`;

export const DropDownIcon = styled(ArrowDropDown)`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 8px;
`;

export const Checkbox = styled(MUICheckbox)`
  padding: 4px 0;
`;

export const ListItemText = styled(MUIListItemText)`
  padding: 0;
`;

export const ExportIcon = styled(FolderOpenIcon)`
  color: #57abff;
  margin-right: 8px;
`;

export const ExportContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #57abff;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    &,
    ${ExportIcon} {
      color: #57abffc4;
    }
  }
`;

export const Separator = styled.div`
  background: rgba(0, 0, 0, 0.12);
  width: 1px;
  height: 26px;
  margin: 0px 24px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const SwitchLabel = styled.span``;
