import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import PropTypes from "prop-types";

import propTypes from "../../../../../constants/propTypes";
import Shipment from "../../../../../models/Shipment";
import PurchaseOrder from "../../../../../models/PurchaseOrder";
import AddTrackingNoteForm from "./AddTrackingNoteForm";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import Tag from "../../../../../models/Tag";
import Note from "../../../../../models/Note";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { generateNoteData } from "../../../Notes/NoteCreate/NoteCreateContainer";
import SpecShipment from "../../../../../models/SpecShipment";
import { dataComponentIds } from "../../../Notes/NoteCreate/AssignToSelect/AssignToSelectContainer";
import { LoaderContext } from "../../../../ui/Loader";

const dataComponentId = "AddTrackingNoteDC";

const handleError = showSnackNotificationAction => ({ data }) => {
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

export const handleSuccess = (
  showSnackNotificationAction,
  closeModalDialog,
  shipmentActions,
  openModalDialog,
  shipmentsDataComponentId
) => () => {
  showSnackNotificationAction("Note has been completed successfully");

  if (shipmentActions && shipmentActions.isFromManageShipments()) {
    shipmentActions.openManageShipmentsModal(
      openModalDialog,
      shipmentsDataComponentId
    );
    return;
  }

  closeModalDialog();
};

export const validateCreateStatus = (
  processCreateRequestStatus,
  closeModalDialog,
  showSnackNotificationAction,
  dataComponent,
  prevDataComponent,
  shipmentActions,
  openModalDialog,
  shipmentsDataComponentId
) => () => {
  return processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: handleSuccess(
      showSnackNotificationAction,
      closeModalDialog,
      shipmentActions,
      openModalDialog,
      shipmentsDataComponentId
    ),
    onError: handleError(showSnackNotificationAction),
  });
};

const AddTrackingNoteContainer = ({
  dataComponent,
  userId,
  shipmentId,
  specShipment,
  assignTo,
  projectId,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  showSnackNotificationAction,
  closeModalDialog,
  shipment,
  shipmentActions,
  openModalDialog,
  shipmentsDataComponentId,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(
    validateCreateStatus(
      processCreateRequestStatus,
      closeModalDialog,
      showSnackNotificationAction,
      dataComponent,
      prevDataComponent,
      shipmentActions,
      openModalDialog,
      shipmentsDataComponentId
    ),
    [dataComponent, closeModalDialog, showSnackNotificationAction]
  );

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    initDataComponent(dataComponentId, Note, [], "notes");

    initDataComponent("select-tags", Tag, [], "tags");
    performRetrieveListRequest("select-tags");
  }, [initDataComponent, performRetrieveListRequest]);

  const handleCreateNote = note => {
    performCreateRequest(dataComponentId, generateNoteData(note));
  };

  const handleCancel = () => {
    if (shipmentActions && shipmentActions.isFromManageShipments()) {
      shipmentActions.openManageShipmentsModal(
        openModalDialog,
        shipmentsDataComponentId
      );
      return;
    }

    closeModalDialog();
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <AddTrackingNoteForm
        userId={userId}
        shipment={shipment}
        projectId={projectId}
        shipmentId={shipmentId}
        spec={specShipment}
        assignTo={assignTo}
        onCreateNote={handleCreateNote}
        shipmentActions={shipmentActions}
        onCancel={handleCancel}
      />
    </LoaderContext.Provider>
  );
};

AddTrackingNoteContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  userId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  shipmentId: PropTypes.string,
  specShipment: propTypes.spec,
  assignTo: PropTypes.array,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  shipment: propTypes.shipment,
  shipmentActions: PropTypes.object,
  openModalDialog: PropTypes.func,
  shipmentsDataComponentId: PropTypes.string,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, { shipmentId, projectId }) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const dataComponents = Object.keys(dataComponentIds).reduce(
    (sources, key) => {
      sources[key] = getDataComponentFlattenedRequestState(
        dataComponentIds[key],
        state
      );
      return sources;
    },
    {}
  );
  const shipment = Shipment.query(state.resources).find(shipmentId);
  const specShipment = shipment
    ? SpecShipment.query(state.resources).find(shipment.specId)
    : null;
  const purchaseOrder = specShipment
    ? PurchaseOrder.query(state.resources).find(specShipment.purchaseOrderId)
    : null;

  return {
    dataComponent,
    userId: state.auth.userId,
    projectId: _get(purchaseOrder, "projectId") || projectId,
    specShipment,
    loading: Object.values(dataComponents).some(
      dataComponent => dataComponent.loading
    ),
  };
};

const mapDispatchToProps = {
  openModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  closeModalDialog,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTrackingNoteContainer);
