import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { openModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";

import PaymentTermsPage from "./PaymentTermsPage";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

export const dataComponentId = "paymentTermsGrid";

export const getTitle = content => {
  return content.length > 30 ? `${content.substr(0, 30)}...` : content;
};
export const PaymentTermsContainer = ({ openModalDialog, dataComponent }) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create Payment Term", "CreatePaymentTerm", {}, false);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    paymentTerm => {
      openModalDialog(
        `Edit ${paymentTerm.content}`,
        "EditPaymentTerm",
        {
          paymentTerm,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, content }) => {
      const title = getTitle(content);

      openModalDialog(
        `Delete ${title}`,
        "DeletePaymentTerm",
        {
          id,
          title,
          dataComponentId,
          params: { content },
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <PaymentTermsPage
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      dataComponent={dataComponent}
    />
  );
};

PaymentTermsContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  performRetrieveListRequest,
  initDataComponent,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(PaymentTermsContainer),
  ["manage-payment-terms"]
);
