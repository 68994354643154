import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Reorder from "@material-ui/icons/Reorder";
import { Draggable } from "react-beautiful-dnd";
import { startCase } from "lodash";

import { ListWrapper } from "../../../../ProjectCreate/AreasSection/CreateAreaLocal/components";
import CheckboxWithError from "../../../../../inputs/CheckboxWithError";
import { SelectWithError as SelectWithErrorRaw } from "../../../../../inputs/SelectWithError";
import { mapDataType } from "../../GenerateCustomReport/fields";
import {
  getErrorMessage,
  hasError,
} from "../../../../../../utils/formValidationUtils";
import TextInputWithError from "../../../../../inputs/TextInputWithError";

const Wrapper = styled.div`
  display: flex;
  padding-left: 5px;
  flex: 1;
  justify-content: space-between;
`;

const ColumnName = styled.div`
  display: flex;
  flex: 2;
  align-self: center;
`;

export const CustomListItem = styled(ListWrapper)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  &:last-child {
    border-bottom: none;
  }
`;

const FilterOptions = styled.div`
  flex: 1;
  align-self: center;
`;

export const ReorderIcon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px;
  color: #000000;
  align-self: center;
`;

export const SelectWithError = styled(SelectWithErrorRaw)`
  width: 100%;
`;

const TextInput = styled(TextInputWithError)`
  flex: 2;
`;

const queryOptions = {
  exact: { id: "exact", name: "Exact" },
  range: { id: "range", name: "Range" },
  null: { id: "null", name: "Null" },
  notNull: { id: "notNull", name: "Not Null" },
  toBeDefined: { id: "toBeDefined", name: "TBD" },
  like: { id: "like", name: "Like" },
  true: { id: "true", name: "Yes" },
  false: { id: "false", name: "No" },
  select: { id: "select", name: "Select" },
  multiSelect: { id: "multiSelect", name: "Multi Select" },
  greaterThan: { id: "greaterThan", name: "Greater Than" },
  lessThan: { id: "lessThan", name: "Less Than" },
};

const queryTypeOptions = {
  text: [
    queryOptions.exact,
    queryOptions.like,
    queryOptions.null,
    queryOptions.notNull,
  ],
  number: [
    queryOptions.exact,
    queryOptions.range,
    queryOptions.greaterThan,
    queryOptions.lessThan,
    queryOptions.null,
    queryOptions.notNull,
  ],
  date: [
    queryOptions.exact,
    queryOptions.range,
    queryOptions.greaterThan,
    queryOptions.lessThan,
    queryOptions.null,
    queryOptions.notNull,
  ],
  boolean: [queryOptions.toBeDefined, queryOptions.true, queryOptions.false],
  enum: [queryOptions.select, queryOptions.multiSelect],
  id: [queryOptions.select, queryOptions.multiSelect],
};

const getFilterOptions = (dataType, columnName) => {
  const mappedDataType = mapDataType(dataType, columnName);
  const filterOptions = queryTypeOptions[mappedDataType];
  return filterOptions || [];
};

const ColumnEdit = ({ column, setFieldValue, ...formikProps }) => {
  const {
    editName,
    sequenceIndex,
    dataType,
    filtered: rawFiltered,
    filter,
    selectName,
    columnName,
    entityName,
    name,
  } = column;

  const fieldName = `columns[${sequenceIndex}]`;

  const onToggle = ({ target: { value } }) => {
    column.filtered = value;
    setFieldValue(`${fieldName}.filtered`, value);
    if (!column.filtered) {
      setFieldValue(`${fieldName}.filter`, null);
    }
  };

  const onFilterTypeChange = ({ target: { value } }) => {
    column.filter = value;
    setFieldValue(`${fieldName}.filter`, value);
  };

  const onEditNameChange = ({ target: { value } }) => {
    setFieldValue(`${fieldName}.editName`, value);
  };

  const filtered = rawFiltered || !!filter;

  return (
    <Draggable draggableId={selectName} index={sequenceIndex}>
      {({ dragHandleProps, ...parentProps }) => (
        <CustomListItem {...parentProps}>
          <Wrapper>
            <ColumnName>{`${startCase(entityName)} - ${name}`}</ColumnName>
            <TextInput
              value={editName}
              label={"Column Name"}
              onChange={onEditNameChange}
            />
            <CheckboxWithError
              useSwitch={true}
              onChange={onToggle}
              value={filtered}
              label={"Filter"}
              formControlLabelProps={{
                labelPlacement: "top",
                alignSelf: "start",
              }}
            />
            <FilterOptions>
              {filtered && (
                <SelectWithError
                  options={getFilterOptions(dataType, columnName)}
                  value={filter}
                  onChange={onFilterTypeChange}
                  ignoreCaption
                  error={hasError(
                    `${fieldName}.filter`.replace(`['`, "").replace(`']`, ""),
                    formikProps
                  )}
                  errorMessage={getErrorMessage(
                    `${fieldName}.filter`.replace(`['`, "").replace(`']`, ""),
                    formikProps
                  )}
                />
              )}
            </FilterOptions>
            <ReorderIcon {...dragHandleProps}>
              <Reorder />
            </ReorderIcon>
          </Wrapper>
        </CustomListItem>
      )}
    </Draggable>
  );
};

ColumnEdit.propTypes = {
  column: PropTypes.object.isRequired,
};

export default ColumnEdit;
