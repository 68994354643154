import React, { useEffect } from "react";
import _get from "lodash/get";

import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  setModalDialogTitle,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import {
  moveSpecsToPO,
  specsGridDataComponentId,
  specShipmentsDataComponentId,
} from "../../../../../../actions/purchaseOrderSpecsActions";
import { setReload } from "../../../../../../actions/dataComponentActions";
import SpecsGrid from "../SpecsGrid";

import pluralize from "pluralize";
import { fetchPurchaseOrder } from "../../../../../../actions/purchaseOrdersActions";

const poSelector = state => state.purchaseOrder;

export const getSubmitButtonText = numberSelected => {
  return numberSelected
    ? `Add ${pluralize("Spec", numberSelected, true)}`
    : "Add Specs";
};
export const MoveSpec = () => {
  const purchaseOrder = useAddSpecToPOStore(poSelector);
  const dataComponent = useDatacomponent(specsGridDataComponentId);

  const actions = useActions({
    setModalDialogTitle,
    closeModalDialog,
    setReload,
    showSnackNotificationAction,
    moveSpecsToPO,
    fetchPurchaseOrder,
  });

  useEffect(() => {
    actions.setModalDialogTitle([
      "Add a spec",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ]);
  }, [actions, purchaseOrder]);

  const handleUpdateSuccess = () => {
    actions.setReload(specsGridDataComponentId, true);
    actions.setReload(specShipmentsDataComponentId, true);
    actions.fetchPurchaseOrder(
      _get(purchaseOrder, "id"),
      _get(purchaseOrder, "project.id")
    );
    actions.showSnackNotificationAction(
      "The selected Specs has been moved successfully"
    );
    actions.closeModalDialog();
  };

  useUpdateProcessRequests(dataComponent, {
    onSuccess: handleUpdateSuccess,
  });

  const handleMove = ids => actions.moveSpecsToPO(ids, purchaseOrder);
  return (
    <SpecsGrid
      filters={{
        projectId: purchaseOrder.projectId,
        isAssignedToBidGroup: false,
        $or: [
          {
            purchaseOrderId: {
              $exists: false,
            },
          },
          {
            purchaseOrderId: { $notEqual: purchaseOrder.id },
            "purchaseOrder.status": { $notEqual: "Issued" },
          },
        ],
      }}
      submitTextFn={selected => getSubmitButtonText(selected)}
      onSubmit={handleMove}
    />
  );
};

export default MoveSpec;
