import React from "react";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";

export default styled(({ isSticky, width, ...props }) => (
  <Tabs classes={{ indicator: "indicator" }} {...props} />
))`
  background-color: rgb(39, 88, 122);

  & .indicator {
    background-color: rgb(255, 255, 255);
  }

  ${({ isSticky }) =>
    isSticky &&
    `
    position: fixed;
    top: 55px;
    z-index: 10;
    right: 0px;
    width: 100%;

    `}
`;
