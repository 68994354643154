import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";

import RenderedField from "./RenderedField";
import propTypes from "../../../../../constants/propTypes";

const name = "contractFiles";

export const ContractGridWrapper = ({
  values,
  client,
  dragDropModalOpen,
  handleCloseDragDropModal,
}) => {
  const rows = values[name];

  return (
    <FieldArray
      name={name}
      render={formikArrayHelpers => (
        <RenderedField
          client={client}
          formikArrayHelpers={formikArrayHelpers}
          rows={rows}
          projectId={values.id}
          dragDropModalOpen={dragDropModalOpen}
          handleCloseDragDropModal={handleCloseDragDropModal}
        />
      )}
    />
  );
};

ContractGridWrapper.propTypes = {
  values: PropTypes.shape({}),
  addFileRef: PropTypes.shape({}),
  client: propTypes.client,
  dragDropModalOpen: PropTypes.bool,
  handleCloseDragDropModal: PropTypes.func.isRequired,
};

export default ContractGridWrapper;
