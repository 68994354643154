import React, { memo, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  performRetrieveListRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import {
  BWGridLocal,
  GridHeader,
  GridTable,
  withSelectedRows,
} from "../../../../ui/BWGrid";
import { areEqual } from "../../../../ui/Grid/GridAPI";
import { columns } from "./gridProps";
import { getColumnOptions } from "./gridProps";
import propTypes from "../../../../../constants/propTypes";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import { StyledEdit } from "../../../../ui/BWGrid/columns/EditableColumn";
import { editableCellCreator } from "../../../../ui/BWGrid/EditableCellAPI";
import {
  ShippingPaymentsActions,
  unbillShippingPaymentsDataComponentId,
} from "./ShippingPaymentsActions";
import { useLoading } from "../../../../hooks/useDatacomponent";
import { billShippingPaymentsDataComponentId } from "../../../../../actions/shippingPaymentsActions";
import { useProcessRequest } from "./useProcessRequest";

export const getDataComponentId = projectId => `ShippingPayments-${projectId}`;

const rowInputs = {
  bwInvoiceNumber: {
    InputComponent: TextInputWithError,
    name: "bwInvoiceNumber",
    fullWidth: true,
    InputProps: {
      endAdornment: <StyledEdit />,
    },
  },
};

const ShippingPayments = ({ projectId, shippingPayments, dataComponent }) => {
  const dataComponentId = dataComponent.dataComponentId;

  const formatter = useCurrencyFormatter();

  const columnOptions = useMemo(() => getColumnOptions(formatter), [formatter]);

  const tableComponents = useMemo(
    () => ({
      CellComponent: editableCellCreator(
        rowInputs,
        dataComponentId,
        {},
        column => columnOptions[column.name].editable
      ),
    }),
    [columnOptions, dataComponentId]
  );

  useProcessRequest({ projectId });

  const isLoading = useLoading([
    billShippingPaymentsDataComponentId,
    unbillShippingPaymentsDataComponentId,
  ]);

  return (
    <BWGridLocal
      id={dataComponentId}
      sorting={[{ columnName: "id", direction: "asc" }]}
      rows={shippingPayments}
      gridConfig={{ pageSize: -1, totalRows: shippingPayments.length }}
      tableComponents={tableComponents}
      isLoading={isLoading}
      emptyStateText="No Shipping Payments"
      emptyStateProps={{ intent: "empty" }}
      noBorder
      showSelectionColumn
      showSelectAll
    >
      <GridHeader
        headerText={`${shippingPayments.length} Shipping Payments`}
        headerOverride={<ShippingPaymentsActions projectId={projectId} />}
      />
      <GridTable columns={columns} columnOptions={columnOptions} />
    </BWGridLocal>
  );
};

ShippingPayments.propTypes = {
  shippingPayments: PropTypes.arrayOf(propTypes.shippingPayment),
  row: propTypes.project,
  dataComponent: propTypes.dataComponent,
  importDataComponent: propTypes.dataComponent,
  setReload: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

const mapStateToProps = (state, { row: { id: projectId } }) => {
  const dataComponentId = getDataComponentId(projectId);
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const shippingPayments = getBluechipResources(dataComponent, state) || [];
  return {
    projectId,
    dataComponent,
    shippingPayments,
  };
};

const mapDispatchToProps = {
  performRetrieveListRequest,
  setReload,
};

export default withSelectedRows(
  connect(mapStateToProps, mapDispatchToProps)(memo(ShippingPayments, areEqual))
);
