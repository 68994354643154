import styled from "styled-components";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const BoldText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 24px;
`;

export const dateFormat = `${PRIMARY_DATE_FORMAT} hh:mm A`;
