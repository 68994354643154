import React, { Fragment } from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";

import { Cell } from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";
import RowComponent from "../ImportSpecs/tableComponents/RowComponent";
import WrapperDetailRow from "../ImportSpecs/tableComponents/WrapperDetailRow";
import { detailRowComponent } from "../DuplicateSpecs/tableComponents";

const DetailCellComponent = ({ children }) => (
  <Fragment>
    <Cell column={{}} colSpan={8}>
      {children}
    </Cell>
  </Fragment>
);

DetailCellComponent.propTypes = {
  children: PropTypes.object,
};

const tableComponents = memoizeOne((setSpecs, specsFields) => {
  return {
    DetailRowComponent: detailRowComponent(setSpecs, specsFields),
    RowComponent,
    DetailCellComponent,
    WrapperDetailRow,
  };
});

export default tableComponents;
