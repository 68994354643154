import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { handleSuccess, handleError } from "./ShipToSiteRequestHandlers";

export const updateContacts = (shipToSite, vendorId) => {
  if (!shipToSite.contacts) {
    return shipToSite;
  }

  return {
    ...shipToSite,
    contacts: shipToSite.contacts.map(vendorContact => {
      return {
        ...vendorContact,
        vendorId: vendorId,
      };
    }),
  };
};
export const onCreateShipToSite = (
  shipToSite,
  vendorId,
  shipToSiteDataComponent,
  shipToSitesDataComponentId,
  performCreateRequest
) => {
  shipToSite.vendorId = vendorId;
  performCreateRequest(
    shipToSitesDataComponentId,
    updateContacts(shipToSite, vendorId)
  );
};
export const onUpdateShipToSite = (
  shipToSite,
  shipToSiteDataComponent,
  shipToSitesDataComponentId,
  performUpdateRequest
) => {
  performUpdateRequest(shipToSitesDataComponentId, shipToSite.id, shipToSite);
};
export const onDeleteShipToSite = (
  shipToSiteId,
  shipToSiteDataComponent,
  shipToSitesDataComponentId,
  performDeleteRequest
) => {
  performDeleteRequest(shipToSitesDataComponentId, shipToSiteId);
};
const processSaveRequest = (
  preDataComponent,
  dataComponent,
  vendorShipToSiteDataComponentId,
  { fetchData, showSnackNotificationAction, closeModalDialog }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        vendorShipToSiteDataComponentId,
        showSnackNotificationAction,
        "Ship to site has been saved successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        vendorShipToSiteDataComponentId,
        showSnackNotificationAction,
        "Ship to site has been updated successfully",
        closeModalDialog
      );
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        vendorShipToSiteDataComponentId,
        showSnackNotificationAction,
        "Ship to site has been deleted successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processRequests = (
  preDataComponent,
  dataComponent,
  vendorDataComponentId,
  callbacks
) => {
  processSaveRequest(
    preDataComponent,
    dataComponent,
    vendorDataComponentId,
    callbacks
  );
};
