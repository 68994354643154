import React from "react";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import styled from "styled-components";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import Input from "../../../mui/core/Input";
import InputAdornment from "../../../mui/core/InputAdornment";

export const StyledInput = styled(({ hidden, onAdornmentClick, ...props }) => (
  <Input
    {...props}
    classes={{ input: "input" }}
    endAdornment={
      <InputAdornment position="end" classes={{ root: "adornment" }}>
        <Close onClick={onAdornmentClick} />
      </InputAdornment>
    }
  />
))`
  margin-top: 0;
  border-radius: 3px;
  background: #335064;
  width: ${({ hidden }) => (hidden ? "0px" : "330px")};
  transition: all 0.3s ease-in;
  &:before,
  &:after {
    border: none !important;
  }
  & .input {
    width: ${({ hidden }) => (hidden ? "0px" : "100%")};
    color: #ffffff;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "auto")};
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    padding: ${({ hidden }) => (hidden ? "0px" : "8px 16px")};
    transition: all 0s 0.3s;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  & .adornment {
    transform: ${({ hidden }) => (hidden ? "scale(0)" : "none")};
    color: #ffffff;
    cursor: pointer;
    ${({ hidden }) => (hidden ? "padding: 0px;" : null)}
    transition: all 0.3s ease-in;
    &:hover {
      color: rgba(250, 250, 250, 0.54);
    }
  }
  @media (max-width: 600px) {
    width: ${({ hidden }) => (hidden ? "0px" : "calc(100vw - 200px)")};
  }
  @media (max-width: 500px) {
    width: ${({ hidden }) => (hidden ? "0px" : "calc(100vw - 100px)")};
  }
`;

export const SearchIcon = styled(Search)`
  font-size: 24px;
  cursor: pointer;
  fill: white;
  &:hover {
    color: rgba(250, 250, 250, 0.54);
  }
`;

export const StyledPopper = styled(Popper)`
  width: 330px;
  @media (max-width: 600px) {
    width: calc(100vw - 200px);
  }
  @media (max-width: 500px) {
    width: calc(100vw - 100px);
  }
`;

export const StyledMenuList = styled(MenuList)`
  max-height: 200px;
  overflow-y: auto;
`;

export const StyledTooltip = styled(Tooltip)`
  width: ${({ hidden }) => (hidden ? "0px" : "48px")};
  transition: all 0.1s 0.1s;
`;
