import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import SubmittalFile from "./SubmittalFile";
import Requirement from "./Requirement";

export default class SpecRequirement extends BaseModel {
  static get belongsTo() {
    return [Spec, Requirement];
  }

  static get hasMany() {
    return [SubmittalFile];
  }
}
