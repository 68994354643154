import PropTypes from "prop-types";

import propTypes from "../../constants/propTypes";

export const LocationCombined = ({
  location = {},
  parts = ["address", "address2", "city", "state"],
  ignoreZip,
}) => {
  const displayZip = !ignoreZip && location.zip;
  const addr = parts
    .reduce(
      (line, part) => (location[part] ? [...line, location[part]] : line),
      []
    )
    .join(", ");
  return displayZip ? `${addr} ${location.zip}` : addr;
};

LocationCombined.propTypes = {
  location: propTypes.location,
  parts: PropTypes.arrayOf(PropTypes.string),
  ignoreZip: PropTypes.bool,
};

export default LocationCombined;
