import styled from "styled-components";
import Tab from "@material-ui/core/Tab";

const StyledTab = styled(Tab)`
  min-width: 166px;
  font-family: "Open Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  color: rgba(255, 255, 255, 0.7);
  @media (max-width: 600px) {
    width: 50%;
  }
`;

export default StyledTab;
