import React from "react";
import styled from "styled-components";

import { BWIntent } from "./BWIntent";

const intents = BWIntent.values.counters;

const CounterCore = ({ value, intent, ...props }) => (
  <div {...props}>{value < 10 ? value : "9+"}</div>
);

export const Counter = styled(CounterCore)`
  height: 17px;
  min-width: 17px;
  max-width: 33px;
  color: #ffffff;
  background: ${({ intent = intents.danger }) => intent};
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
  border-radius: 8px;
  padding: 0 5px;
  margin-left: 8px;
  display: ${({ value }) => (value ? null : "none")};
`;

export default Counter;
