import { connect } from "react-redux";
import _get from "lodash/get";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { getResourcesV2 } from "../../../../../utils/bluechipUtils";

import { generateAndDownloadReport } from "../../../../../actions/reportsActions";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import GenerateCustomReport from "./GenerateCustomReport";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";

export const selectCustomReportDataComponentId = "select-custom-reports";
export const selectQueryReportDataComponentId = "select-query-reports";

export const mapStateToProps = state => {
  const reportsDataComponent = getDataComponent(
    selectCustomReportDataComponentId,
    state
  );

  const queryReportsDataComponent = getDataComponent(
    selectQueryReportDataComponentId,
    state
  );

  const loading =
    _get(reportsDataComponent, "requestState.list.loading") ||
    _get(queryReportsDataComponent, "requestState.list.loading");

  const customReports = getResourcesV2(
    reportsDataComponent,
    state,
    RequestTypes.LIST,
    []
  );

  const queryReports = getResourcesV2(
    queryReportsDataComponent,
    state,
    RequestTypes.LIST,
    []
  );

  return {
    customReports,
    queryReports,
    loading,
    reportsLoader: state.reports.loaders,
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  closeModalDialog,
  generateAndDownloadReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateCustomReport);
