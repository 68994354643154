import React from "react";
import { PropTypes } from "prop-types";

import packageJson from "../package.json";
global.appVersion = packageJson.version;

export const semverGreaterThan = (serverVersion, clientVersion) => {
  const serverVersionArray = serverVersion.split(/\./g);
  const clientVersionArray = clientVersion.split(/\./g);
  while (serverVersionArray.length || clientVersionArray.length) {
    const serverVersionItem = Number(serverVersionArray.shift());
    const clientVersionItem = Number(clientVersionArray.shift());
    if (serverVersionItem === clientVersionItem) {
      continue;
    }
    return serverVersionItem > clientVersionItem || isNaN(clientVersionItem);
  }
  return false;
};

export const getVersion = data => {
  return data ? data.version : "";
};

export const clearCache = cacheItems => {
  if (cacheItems) {
    cacheItems = {};
  }
};

export const getStateVals = shouldForceRefresh => {
  if (shouldForceRefresh) {
    return { loading: false, isLatestVersion: false };
  } else {
    return { loading: false, isLatestVersion: true };
  }
};

export const refreshCacheAndReload = cacheItems => {
  clearCache(cacheItems);
  // delete browser cache and hard reload
  window.location.reload(true);
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => refreshCacheAndReload(caches),
    };
  }

  componentDidMount() {
    fetch(`${process.env.API_URL}/version`)
      .then(response => response.json())
      .then(data => {
        this.setState(
          getStateVals(semverGreaterThan(getVersion(data), packageJson.version))
        );
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log("Version Load error");
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.any,
};

export default CacheBuster;
