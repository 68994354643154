import { BaseModel } from "@blue-chip/core";
import UserRole from "./UserRole";
import Permission from "./Permission";

export default class UserRolePermission extends BaseModel {
  static pluralName() {
    return "userRolesPermissions";
  }

  static get belongsTo() {
    return [UserRole, Permission];
  }
}
