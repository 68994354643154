import SelectWithError from "../../../../inputs/SelectWithError";
import Requirement from "../../../../../models/Requirement";

export default (value, selectedRequirements, specRequirement, onChange) => {
  const isEdit = !!specRequirement;
  const selectedList = isEdit
    ? selectedRequirements.filter(
        selected => selected.id !== specRequirement.requirementId
      )
    : selectedRequirements;

  const selectedIds = selectedList.map(requirement => requirement.id);

  return {
    groups: [
      {
        items: [
          {
            input: {
              InputComponent: SelectWithError,
              isAutocomplete: true,
              isAutocompleteMulti: !isEdit,
              isSingleAutocomplete: isEdit,
              dataComponentId: "select-requirements",
              fullWidth: true,
              ignoreCaption: true,
              APIOptions: {
                fetchOnlyOnce: true,
                model: Requirement,
                route: "requirements",
                pageSize: -1,
                sort: [{ columnName: "name", direction: "asc" }],
              },
              valueProperty: "id",
              labelProperty: "name",
              submitData: true,
              name: "requirements",
              label: "Requirement Description",
              value,
              onChange,
              filter: options => {
                return options.filter(
                  option => !selectedIds.includes(option.id)
                );
              },
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
