import React, { useState, memo, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import _get from "lodash/get";

import noteFollowUpFields from "./noteFollowUpFields";
import noteDeliveryFields from "./noteDeliveryFields";
import noteShippingFields from "./noteShippingFields";
import { TitleContainer } from "../../../../layout/AppLayout/ModalDialog/ModalTitle";
import {
  NoteCategoriesContainer,
  StyledTitle,
  StyledSubtitle,
  Label,
} from "./components";
import NoteForm from "./NoteForm";
import Select from "../../../../mui/core/Select";
import { FormikForm } from "../../../../forms";
import propTypes from "../../../../../constants/propTypes";

const Container = styled.div`
  height: calc(100% - 101px);
  overflow: auto;
`;

export const TrackingTypeDropdown = ({ value, onChange }) => (
  <React.Fragment>
    <Label>Type:</Label>
    <Select value={value} onChange={({ target: { value } }) => onChange(value)}>
      {getTrackingTypes().map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </React.Fragment>
);

TrackingTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const getTrackingTypes = () => {
  const noteSchema = BWModels.loadSchema("Note");
  return noteSchema.__utils.types;
};

export const CategoryDropdown = ({ value, onChange }) => (
  <React.Fragment>
    <Label>Category:</Label>
    <Select value={value} onChange={({ target: { value } }) => onChange(value)}>
      {getCategories().map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </React.Fragment>
);

CategoryDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const getCategories = () => {
  const noteSchema = BWModels.loadSchema("Note");
  return noteSchema.__utils.categories;
};

const fields = {
  Shipping: noteShippingFields,
  "Follow Up": noteFollowUpFields,
  Delivery: noteDeliveryFields,
};

const getInitialValues = (
  projectId,
  authorId,
  shipment = {},
  assignTo = []
) => ({
  projectId,
  authorId,
  category: "Tracking",
  trackingType: undefined,
  subject: undefined,
  detail: undefined,
  emailSentBy: undefined,
  emailSentTo: undefined,
  callWith: undefined,
  trackingNumber: shipment.trackingNumber,
  carrierName: undefined,
  tags: [],
  assignTo: [...assignTo],
  mentions: [],
});

export const AddTrackingNoteForm = ({
  projectId,
  userId,
  spec,
  onCreateNote,
  shipment = {},
  onCancel,
  assignTo,
  ...formProps
}) => {
  const [trackingType, setTrackingType] = useState(getTrackingTypes()[0].id);
  return (
    <Fragment>
      <TitleContainer>
        <StyledTitle>Add Tracking Note</StyledTitle>
        {spec && (
          <StyledSubtitle>
            {spec.customNumber} - {spec.description}
          </StyledSubtitle>
        )}
        <NoteCategoriesContainer>
          <TrackingTypeDropdown
            value={trackingType}
            onChange={setTrackingType}
          />
        </NoteCategoriesContainer>
      </TitleContainer>
      <Container>
        <FormikForm
          initialValues={getInitialValues(
            projectId,
            userId,
            shipment,
            assignTo
          )}
          valueOverrides={{ trackingType }}
          onSubmit={onCreateNote}
          validationSchema={BWModels.loadSchema("Note")}
        >
          {({ handleSubmit, ...formikProps }) => (
            <NoteForm
              onSubmit={handleSubmit}
              fields={fields[trackingType]}
              formikProps={formikProps}
              trackingType={trackingType}
              onCancel={onCancel}
              {...formProps}
            />
          )}
        </FormikForm>
      </Container>
    </Fragment>
  );
};

AddTrackingNoteForm.propTypes = {
  projectId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  shipmentId: PropTypes.string,
  spec: propTypes.spec,
  onCreateNote: PropTypes.func.isRequired,
  shipment: propTypes.shipment,
  onCancel: PropTypes.func,
};

export const areEqual = (prevProps, nextProps) => {
  const {
    shipmentId: prevShipmentId,
    spec: prevSpec,
    projectId: prevProjectId,
  } = prevProps;
  const { shipmentId, spec, projectId } = nextProps;
  return (
    prevShipmentId === shipmentId &&
    _get(prevSpec, "id") === _get(spec, "id") &&
    prevProjectId === projectId
  );
};

export default memo(AddTrackingNoteForm, areEqual);
