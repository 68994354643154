import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEqual, debounce, omitBy } from "lodash";
import { parse, stringify } from "query-string";

import propTypes from "../../../constants/propTypes";
import LatestNews from "./LatestNews";
import Content from "../../layout/Content/Content";
import PurchaseOrdersNeedsFollowUp from "./PurchaseOrdersNeedsFollowUp";
import PurchaseOrdersRequiringRefunds from "./PurchaseOrdersRequiringRefunds";
import MyProjects from "./MyProjects";
import { posNeedingAttentionColumns } from "./columns";
import { updateAuthenticatedUser } from "../../../actions/authActions";

const getPurchaseOrdersNeedsFollowUpAPIFilters = (
  loggedInUserId,
  favoriteProjectIds
) => ({
  rootFilters: {
    $where: {
      $and: {
        needsFollowUp: true,
        $or: [
          { "project.membersUsers.id": loggedInUserId },
          { "project.projectLeadId": loggedInUserId },
          { projectId: { $in: favoriteProjectIds } },
        ],
      },
    },
  },
  sort: [{ columnName: "number", order: "desc" }],
  params: {
    omitDefaultModifier: true,
    modifiers: ["withBasicFields"],
  },
});
export const DashboardPage = props => {
  const {
    userId,
    favoriteProjectIds,
    updateAuthenticatedUser,
    dashboardGridPreferences,
    history,
  } = props;
  const dashboardGridPreferencesRef = useRef(null);
  const loadedDashboardGridPreferences = useRef(false);
  const purchaseOrdersNeedsFollowUpAPIFilters = getPurchaseOrdersNeedsFollowUpAPIFilters(
    userId,
    favoriteProjectIds
  );

  const updateDashboardGridPreference = useCallback(
    debounce(
      dashboardGridPreferences =>
        updateAuthenticatedUser({
          dashboardGridPreferences,
        }),
      1500
    ),
    [updateAuthenticatedUser]
  );

  useEffect(() => {
    loadedDashboardGridPreferences.current =
      loadedDashboardGridPreferences.current ||
      dashboardGridPreferences !== null;
  }, [dashboardGridPreferences, history.location.search]);

  useEffect(() => {
    if (dashboardGridPreferences !== null) {
      const currentSearch = parse(history.location.search);
      const dashboardSearch = omitBy(
        parse(dashboardGridPreferences),
        (val, key) => key.endsWith("_filters")
      );
      const search = stringify(
        Object.assign({}, currentSearch, dashboardSearch)
      );
      history.replace({
        search,
      });
    }
  }, [dashboardGridPreferences, history]);

  useEffect(() => {
    if (
      !isEqual(dashboardGridPreferencesRef.current, history.location.search) &&
      !isEqual(dashboardGridPreferences, history.location.search) &&
      loadedDashboardGridPreferences.current
    ) {
      updateDashboardGridPreference(history.location.search);
    }
    dashboardGridPreferencesRef.current = history.location.search;
  }, [
    dashboardGridPreferences,
    history.location.search,
    updateDashboardGridPreference,
  ]);

  return (
    <Content>
      <LatestNews />
      <MyProjects />
      <PurchaseOrdersRequiringRefunds
        projectIds={favoriteProjectIds}
        loggedInUserId={userId}
      />
      <PurchaseOrdersNeedsFollowUp
        apiFilters={purchaseOrdersNeedsFollowUpAPIFilters}
        columns={posNeedingAttentionColumns}
      />
    </Content>
  );
};

DashboardPage.propTypes = {
  userId: PropTypes.string,
  favoriteProjectIds: PropTypes.arrayOf(PropTypes.string),
  handleRowClick: PropTypes.func,
  dataComponent: propTypes.dataComponent,
  updateAuthenticatedUser: PropTypes.func.isRequired,
  dashboardGridPreferences: PropTypes.string,
  history: PropTypes.shape({}),
};

export const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    favoriteProjectIds: state.auth.favoriteProjectIds || [],
    dashboardGridPreferences: state.auth.dashboardGridPreferences,
  };
};

const mapDispatchToProps = {
  updateAuthenticatedUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
);
