import inputs from "../../../../components/inputs/inputConfigs/paymentTerms";

export default () => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.content,
              label: "Payment Term",
              required: true,
            },
            grid: { xs: 12 },
          },
          { input: inputs.isArchived },
        ],
      },
    ],
  };
};
