import React from "react";
import PropTypes from "prop-types";

import { ActionButtons } from "../../../../components/forms";
import { inputs } from "../../../inputs/inputConfigs";
import propTypes from "../../../../constants/propTypes";
import styled from "styled-components";
import { dataComponentId } from "./MoveContainer";

const { InputComponent, ...selectProps } = inputs.area;

const FormWrapper = styled.div`
  padding: 24px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 18px;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

const MoveForm = props => {
  const { targetAreaId, onSetTargetAreaId, onMove, projectId } = props;
  const updatedSelectProps = {
    ...selectProps,
    APIOptions: {
      ...inputs.area.APIOptions,
      rootFilters: {
        $where: { projectId },
      },
    },
  };
  return (
    <Wrapper>
      <FormWrapper>
        <SelectWrapper>
          <InputComponent
            {...updatedSelectProps}
            isAutocomplete
            isSingleAutocomplete
            onChange={evt => onSetTargetAreaId(evt.target.value || "-1")}
            value={targetAreaId}
            helperText={
              "Please select a area where the selected specs should move"
            }
          />
        </SelectWrapper>
      </FormWrapper>
      <ActionButtons
        onSend={onMove}
        sendButtonText="MOVE"
        isModal={true}
        listeners={[dataComponentId]}
        additionalProps={{
          send: {
            disabled: !targetAreaId,
          },
        }}
      />
    </Wrapper>
  );
};

MoveForm.propTypes = {
  dataComponent: propTypes.dataComponent,
  projectId: PropTypes.string.isRequired,
  targetAreaId: PropTypes.string.isRequired,
  onSetTargetAreaId: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
};

export default MoveForm;
