import React from "react";
import PropTypes from "prop-types";
import { renderRoutes } from "react-router-config";
import Tabs from "../../mui/core/Tabs";
import Tab from "../../mui/core/Tab";
import propTypes from "../../../constants/propTypes";

import {
  Container,
  ContainerContent,
} from "../../layout/PageHeader/PageHeader";
import { Content } from "../../layout/Content";
import NavigationTabs from "../../layout/Navigation/NavigationTabs";

const navigationTabs = [
  { path: "/approvals", label: "Approvals" },
  { path: "/availables", label: "Available for Billing" },
  { path: "/received-payments", label: "Payments Received" },
  { path: "/shipping-payments", label: "Shipping Payments" },
];

const BillingPage = ({ route, history: { push }, match }) => {
  return (
    <div>
      <Container>
        <ContainerContent>
          <NavigationTabs
            location={location}
            navigationTabs={navigationTabs}
            match={match}
            push={push}
            Tabs={Tabs}
            Tab={Tab}
          />
        </ContainerContent>
      </Container>
      <Content>{renderRoutes(route.routers, { match })}</Content>
    </div>
  );
};

BillingPage.propTypes = {
  route: propTypes.route,
  match: propTypes.matchRouter,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default BillingPage;
