import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import styled from "styled-components";

import { closeModalDialog } from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { saveSpecStatusComment } from "../../../../../actions/expeditingActions";
import { ActionButtons, FormGrid, FormikForm } from "../../../../forms";
import { inputs } from "../../../../inputs/inputConfigs";
import useActions from "../../../../hooks/useActions";
import Spec from "../../../../../models/Spec";
import { FormTitle } from "../EditMultipleShipments/EditMultipleShipmentsModal";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";

const FormGridContainer = styled.div`
  padding-bottom: 20px;
`;

const fields = {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.statusComment,
            label: "Status Comment",
          },
          grid: { xs: 12 },
        },
        {
          input: {
            name: "overwriteComments",
            InputComponent: CheckboxWithError,
            label: "Overwrite Existing Comments",
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const specDataComponentId = "Spec-Status-Comment";

const SetStatusCommentContainer = ({
  selectedSpecShipments,
  parentDataComponentId,
}) => {
  const actions = useActions({
    initDataComponent,
    closeModalDialog,
    performUpdateRequest,
    saveSpecStatusComment,
    setReload,
  });

  useEffect(() => {
    actions.initDataComponent(specDataComponentId, Spec, [], "specs", true);
  }, [actions]);

  const submit = ({ statusComment, overwriteComments }) => {
    actions.saveSpecStatusComment(
      selectedSpecShipments,
      statusComment,
      specDataComponentId,
      parentDataComponentId,
      overwriteComments
    );
  };

  return (
    <React.Fragment>
      <FormikForm
        initialValues={{ statusComment: "", overwriteComments: false }}
        onSubmit={submit}
      >
        {({ handleSubmit, values, errors, setFieldValue, ...formikProps }) => {
          return (
            <React.Fragment>
              <FormGridContainer>
                <FieldArray name={"shipments"}>
                  {() => {
                    return (
                      <React.Fragment>
                        <FormGrid
                          fields={fields}
                          values={values}
                          errors={errors}
                          {...formikProps}
                        />
                        {!values.overwriteComments && (
                          <FormTitle>
                            All Selected Specs which do not have comments will
                            be updated
                          </FormTitle>
                        )}
                        <ActionButtons
                          onSend={handleSubmit}
                          isModal={true}
                          listeners={[specDataComponentId]}
                        />
                      </React.Fragment>
                    );
                  }}
                </FieldArray>
              </FormGridContainer>
            </React.Fragment>
          );
        }}
      </FormikForm>
    </React.Fragment>
  );
};

SetStatusCommentContainer.propTypes = {
  selectedSpecShipments: PropTypes.array.isRequired,
};

export default SetStatusCommentContainer;
