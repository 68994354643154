export const columns = [
  "vendor.name",
  "vendor.category.name",
  "vendor.website",
  "specs",
  "contacts",
];

export const columnOptions = {
  "vendor.name": {
    title: "Name",
  },
  "vendor.category.name": {
    title: "Category",
  },
  "vendor.website": {
    title: "Website",
  },
  specs: {
    title: "Items",
    render: bidGroupVendor => bidGroupVendor.specs.length,
  },
  contacts: {
    title: "Contacts",
    render: ({ contacts, selectedContacts }) => {
      if (!selectedContacts.length) {
        return "Missing Contacts";
      }
      const set = new Set(selectedContacts);
      return contacts
        .filter(contact => set.has(contact.contactId))
        .map(({ contact }) => contact.name)
        .join(", ");
    },
  },
};
