import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircle from "@material-ui/icons/CheckCircle";
import moment from "moment";
import propTypes from "../../../../../constants/propTypes";
import { Tooltip } from "@material-ui/core";

const MissingValueIcon = styled(RemoveIcon)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  width: 14px;
  height: 14px;
`;

const CheckIcon = styled(CheckCircle)`
  margin-right: 8px;
  color: #72b327;
`;

export const RevenueSharing = ({ revenueSharing, revenueSharingProject }) => {
  const projectLabel =
    revenueSharingProject?.number || revenueSharingProject?.name || "none";
  const label = `Revenue Shared with ${projectLabel}`;

  return revenueSharing ? (
    <Tooltip title={label}>
      <span>{label}</span>
    </Tooltip>
  ) : (
    <MissingValueIcon />
  );
};
RevenueSharing.propTypes = {
  revenueSharing: PropTypes.bool,
  revenueSharingProject: propTypes.project,
};

const Status = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Approved = styled(Status)`
  color: #78cc18;
`;
const Void = styled(Status)`
  color: #000000;
`;
const Pending = styled(Status)`
  color: ${({ needsFollowUp }) => (needsFollowUp ? "#ff1744" : "#57abff")};
`;
const Billed = styled(Status)`
  color: #78cc18;
`;
const OnHold = styled(Status)`
  color: #ff9100;
`;
export const PartiallyPaid = styled(Status)`
  color: #ffb74d;
  padding-left: 14px;
`;
const statusMap = {
  Approved,
  Void,
  Pending,
  Billed,
  "On Hold": OnHold,
  "Partially Paid": PartiallyPaid,
};

export const GroupWrapper = styled.div`
  padding: 0px 24px 24px 24px;
`;

export const StatusColumn = ({ status, ...fee }) => {
  const currentDate = new Date();
  const billingDate = moment(fee.billingDate);

  const Column = statusMap[status] || Pending;
  const needsFollowUp =
    status === "Pending" && currentDate >= billingDate.add(3, "days");
  return (
    <Column needsFollowUp={needsFollowUp}>
      {["Billed"].includes(status) && <CheckIcon />}
      {status}
    </Column>
  );
};
StatusColumn.propTypes = {
  status: PropTypes.string,
};
