import React, { useState } from "react";
import PropTypes from "prop-types";
import _isUndefined from "lodash/isUndefined";
import { Hidden, Grid } from "@material-ui/core";

import { Title } from "../../../../../ui/Typography/SectionTitle";
import MenuWithSearch from "../../../../../ui/MenuWithSearch";
import {
  StyledGrid,
  ItemPaperWrapper,
  Label,
  Header,
  Value,
  OptionWrapper,
  ViewOptionWrapper,
  StyledSignalIcon,
  StyledListIcon,
} from "../components";
import styled from "styled-components";
import Loader from "../../../../../ui/Loader";
const ViewAsLabel = styled.span`
  white-space: nowrap;
`;
const CardWithGraphic = ({
  items,
  title,
  disableFilter,
  searchModelName,
  onChangeFilters,
  searchItems,
  valueFormatter,
  children,
}) => {
  const [showData, setShowData] = useState(true);
  const MenuFilter = !disableFilter ? (
    <MenuWithSearch
      itemName={searchModelName}
      items={searchItems}
      onChangeSelected={onChangeFilters}
    />
  ) : null;

  return (
    <ItemPaperWrapper>
      <Header>
        <Title>{title}</Title>
        <OptionWrapper>
          <Hidden smDown>{MenuFilter}</Hidden>
          <ViewOptionWrapper>
            <ViewAsLabel>View As</ViewAsLabel>
            <Loader>
              <StyledListIcon
                active={showData}
                onClick={() => setShowData(true)}
              />
              <StyledSignalIcon
                active={!showData}
                onClick={() => setShowData(false)}
              />
            </Loader>
          </ViewOptionWrapper>
        </OptionWrapper>
      </Header>
      <Hidden mdUp>{MenuFilter}</Hidden>
      <StyledGrid container isActive={showData}>
        {items.map(({ value, label }, key) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={key}>
            <Label>{label}</Label>
            <Value>
              <Loader width="80%">
                {_isUndefined(value) ? "--" : valueFormatter(value)}
              </Loader>
            </Value>
          </Grid>
        ))}
      </StyledGrid>
      <StyledGrid container isActive={!showData}>
        <Loader>{children}</Loader>
      </StyledGrid>
    </ItemPaperWrapper>
  );
};

CardWithGraphic.defaultProps = {
  tickFormatter: value => value,
  valueFormatter: value => value,
  graphItems: [],
};

CardWithGraphic.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  searchItems: PropTypes.array,
  searchModelName: PropTypes.string,
  disableFilter: PropTypes.bool,
  onChangeFilters: PropTypes.func,
  valueFormatter: PropTypes.func,
  children: PropTypes.element,
};

export default CardWithGraphic;
