import React, { useState, useEffect, useCallback } from "react";
import _get from "lodash/get";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GridLocalSection } from "../../../layout/GridLocalSection";
import { closeModalDialog } from "../../../../actions/layoutActions";
import Area from "../../../../models/Area";
import SpecCategory from "../../../../models/SpecCategory";
import Paper from "../../../mui/core/Paper";
import { ActionButtons } from "../../../../components/forms";
import propTypes from "../../../../constants/propTypes";
import * as RequestTypes from "../../../../constants/RequestTypes";
import {
  performCreateRequest,
  setReload,
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";

import { dataComponentId } from "../SpecsContainer";
import Vendor from "../../../../models/Vendor";

export const areaTypesIdComponent = "select-area-types";
export const categoryTypesIdComponent = "select-category-types";
export const vendorsIdComponent = "select-vendors";

import { columnsOptions } from "./columnOptions";
import { cloneSpec } from "../../SpecDetail/DuplicateSpec/utils";
import tableComponents from "./tableComponents";
import {
  fetchProjectCurrencies,
  projectCurrenciesDataComponentId,
} from "../../../../actions/currencyActions";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";
import UnitOfMeasure from "../../../../models/UnitOfMeasure";
import { unitOfMeasureDataComponentId } from "../../../../actions/specsActions";

const StyledPaper = styled(Paper)`
  margin-bottom: 0px;

  ${Paper} {
    box-shadow: none;
    overflow-y: auto;
    max-height: 300px;
  }
`;

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
  });
};

const columns = [
  "customNumber",
  "description",
  "specCategory.name",
  "area.name",
  "vendorId",
  "projectCurrencyId",
  "baseQuantity",
  "atticStock",
  "overagePercent",
  "unitOfMeasure.name",
];

const retrieveDataListInformation = (
  initDataComponent,
  performRetrieveListRequest,
  projectId,
  fetchProjectCurrencies
) => () => {
  initDataComponent(areaTypesIdComponent, Area, [], "areas", false, "v2");
  performRetrieveListRequest(areaTypesIdComponent, {
    rootFilters: {
      $where: {
        projectId,
      },
    },
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["areas.id", "name"],
  });

  initDataComponent(
    categoryTypesIdComponent,
    SpecCategory,
    [],
    "spec-categories",
    false,
    "v2"
  );
  performRetrieveListRequest(categoryTypesIdComponent, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["spec_categories.id", "name", "isActive"],
    params: {
      modifiers: ["withLinked"],
    },
  });

  initDataComponent(
    unitOfMeasureDataComponentId,
    UnitOfMeasure,
    [],
    "unit-of-measures",
    false,
    "v2"
  );
  performRetrieveListRequest(unitOfMeasureDataComponentId, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["unit_of_measures.id", "name"],
  });

  initDataComponent(vendorsIdComponent, Vendor, [], "vendors", false, "v2");
  performRetrieveListRequest(vendorsIdComponent, {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["vendors.id", "name", "status", "scopeId"],
  });
  fetchProjectCurrencies(projectId);
};

export const DuplicateSpecContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performCreateRequest,
  specs,
  initDataComponent,
  performRetrieveListRequest,
  projectId,
  defaultAreaId,
  fetchProjectCurrencies,
  projectCurrencies,
}) => {
  const [specsFields, setSpecsFields] = useState(
    specs.map(spec => ({
      ...spec,
      priceCents: spec.priceCents / 100,
    }))
  );

  useEffect(
    retrieveDataListInformation(
      initDataComponent,
      performRetrieveListRequest,
      projectId,
      fetchProjectCurrencies
    ),
    [initDataComponent, performRetrieveListRequest, projectId]
  );

  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload
    );
  }, [closeModalDialog, dataComponent, prevDataComponent, setReload]);

  const handleDuplicateList = useCallback(() => {
    const specsData = specsFields.map(spec => {
      spec.priceCents = cleanCurrencyValue(spec.priceCents * 100);
      spec.areaId = spec.areaId || defaultAreaId;
      return cloneSpec(spec);
    });
    performCreateRequest(dataComponentId, specsData);
  }, [performCreateRequest, specsFields, defaultAreaId]);

  return (
    <StyledPaper>
      <GridLocalSection
        id="duplicate-specs"
        columnsOptions={columnsOptions(
          setSpecsFields,
          specsFields,
          projectCurrencies
        )}
        rows={specsFields}
        columns={columns}
        gridConfig={{ pageSize: 0, totalRows: specs.length }}
        tableComponents={tableComponents(setSpecsFields, specsFields)}
        alwaysDisplayDetailRow
      />
      <ActionButtons
        onSend={handleDuplicateList}
        sendButtonText="Duplicate"
        isModal={true}
        listeners={[dataComponentId]}
      />
    </StyledPaper>
  );
};

DuplicateSpecContainer.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  defaultAreaId: PropTypes.string,
  fetchProjectCurrencies: PropTypes.func,
  projectCurrencies: PropTypes.array,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  performRetrieveListRequest,
  setReload,
  initDataComponent,
  fetchProjectCurrencies,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const requestState = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const selectedIds = dataComponent.requestState[RequestTypes.LIST].selectedIds;
  const specs = getBluechipResources(requestState, state, selectedIds) || [];

  const projectCurrencies = getBluechipResources(
    getDataComponentFlattenedRequestState(
      projectCurrenciesDataComponentId,
      state
    ),
    state
  );

  return {
    dataComponent,
    selectedIds,
    specs,
    defaultAreaId: _get(
      getDataComponentFlattenedRequestState(areaTypesIdComponent, state),
      "rowIndex[0]"
    ),
    projectCurrencies: projectCurrencies || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateSpecContainer);
