import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import { openModalDialog } from "../../../actions/layoutActions";
import { LoaderContext } from "../../ui/Loader";
import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import propTypes from "../../../constants/propTypes";
import {
  getDataComponentFlattenedRequestState,
  getDataComponent,
} from "../../../reducers/dataComponentReducer";
import { getActiveRequest } from "../../../utils/dataComponentUtils";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import Entity from "../../../models/Entity";
import EntityDetailPage from "./EntityDetailPage";

export const dataComponentId = "EntitiesGrid";

const EntityDetailContainer = ({
  initDataComponent,
  performFindRequest,
  openModalDialog,
  entityId,
  nextEntityId,
  lastEntityId,
  clientId,
  loading,
  entity,
}) => {
  useEffect(() => {
    initDataComponent(dataComponentId, Entity, ["location"], "entities");
  }, [initDataComponent]);
  useEffect(() => {
    performFindRequest(dataComponentId, entityId, {
      $where: { clientId },
    });
  }, [performFindRequest, entityId, clientId]);

  const handleOpenEditModal = useCallback(() => {
    openModalDialog(
      "Edit Entity",
      "EditEntityContainer",
      { clientId, id: entityId },
      false
    );
  }, [openModalDialog, clientId, entityId]);

  const handleOpenDeleteModal = useCallback(() => {
    openModalDialog(
      `Delete ${entity.name}`,
      "DeleteEntity",
      { clientId, entityId, title: entity.name, backToEntities: true },
      true,
      false
    );
  }, [openModalDialog, clientId, entityId, entity]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <EntityDetailPage
        entity={entity}
        backUrl={`/clients/${clientId}/entities`}
        nextItemRoute={
          nextEntityId && `/clients/${clientId}/entities/${nextEntityId}`
        }
        lastItemRoute={
          lastEntityId && `/clients/${clientId}/entities/${lastEntityId}`
        }
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
      />
    </LoaderContext.Provider>
  );
};

EntityDetailContainer.propTypes = {
  initDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  performFindRequest: PropTypes.func,
  openModalDialog: PropTypes.func,
  nextEntityId: PropTypes.string,
  lastEntityId: PropTypes.string,
  clientId: PropTypes.string,
  entityId: PropTypes.string,
  entity: propTypes.entity,
};

export const mapStateToProps = (state, ownProps) => {
  const { clientId, entityId } = ownProps.match.params;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    clientId,
    entityId,
    entity: getBluechipResourceById(dataComponent, state, entityId),
    nextEntityId: _get(dataComponent, "links.next.meta.id"),
    lastEntityId: _get(dataComponent, "links.last.meta.id"),
    loading: !!getActiveRequest(getDataComponent(dataComponentId, state)),
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  openModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityDetailContainer);
