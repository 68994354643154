import React, { useMemo, useState } from "react";
import propTypes from "../../../../../constants/propTypes";
import PropTypes from "prop-types";

import Paper from "../../../../mui/core/Paper";
import { BWGridAPI, GridRow, GridTable } from "../../../../ui/BWGrid";

import QuotingActions from "../../QuotingActions";
import { genDetailRowComponents } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow";
import BWModels from "benjaminwest-models";
import VendorColumn from "../../../Specs/VendorColumn.js";
import {
  ExpandActionWrapper,
  ExpandedIcon,
  HeaderWrapper,
  Wrapper,
  TitleWrapper,
  HeaderLeft,
} from "../../components";
import { IconButton } from "@material-ui/core";
import withSelectedRowsAPI from "../../../../ui/BWGrid/withSelectedRowsAPI";

export const getVendorName = (
  { name, bidGroupVendors = [], purchaseOrders = [] },
  currentProjectId
) => {
  const details = [
    ...bidGroupVendors
      .filter(({ bidGroup: { projectId } }) => projectId == currentProjectId)
      .map(
        ({ bidGroup: { sequenceIndex } }) =>
          `BG ${String(sequenceIndex).padStart(3, "0")}`
      ),
    ...purchaseOrders
      .filter(({ projectId }) => projectId == currentProjectId)
      .map(
        ({ sequenceIndex }) => `PO ${String(sequenceIndex).padStart(3, "0")}`
      ),
  ];

  if (!details.length) {
    return name;
  }

  return `${name} (${details.join(", ")})`;
};

const getColumnOptions = (specCategories, vendors, areas, projectId) => {
  return {
    customNumber: {
      title: "Spec Number",
      bold: true,
      filter: true,
      width: "400px",
    },
    description: { fill: true, filter: true },
    "area.name": {
      title: "Area",
      width: "300px",
      filter: "select",
      filterOptions: {
        options: areas?.map(({ name }) => ({
          id: name,
          name,
        })),
      },
    },
    "specCategory.name": {
      title: "Category",
      filter: "select",
      filterOptions: {
        options: specCategories.map(({ name }) => ({
          id: name,
          name,
        })),
      },
    },
    "vendor.name": {
      title: "Vendor",
      width: "300px",
      filter: "select",
      filterOptions: {
        options: vendors?.map(vendor => ({
          id: vendor.name,
          name: getVendorName(vendor, projectId),
        })),
      },
      render: VendorColumn,
    },
    "projectCurrency.currency.name": {
      title: "Currency",
      fill: true,
      filter: true,
    },
    "unitOfMeasure.name": { title: "UOM", align: "right" },
    totalQuantityWithoutOverage: {
      title: "Qty",
      align: "right",
    },
  };
};

const columns = [
  "customNumber",
  "description",
  "area.name",
  "specCategory.name",
  "vendor.name",
  "projectCurrency.currency.name",
  "unitOfMeasure.name",
  "totalQuantityWithoutOverage",
];

const getRootFilters = projectId => ({
  $where: {
    projectId: projectId,
    purchaseOrderId: {
      $exists: false,
    },
    isAssignedToBidGroup: false,
  },
});

const UnassignedSpecsGrid = ({
  dataComponent,
  projectId,
  handleCreatePurchaseOrderClick,
  apiRoute,
  model,
  includes,
  specCategories,
  onOpenSpecDetail,
  isClosedProject,
  vendors,
  areas,
  selectedRows,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleOpenSpecDetail = ({ id }) => {
    onOpenSpecDetail(id);
  };

  const tableComponents = useMemo(() => {
    if (isCollapsed) {
      return {
        TableFilterRow: () => null,
        HeaderRowComponent: () => () => null,
        BodyComponent: () => null,
      };
    }

    return genDetailRowComponents(
      "forecastComment",
      "id",
      BWModels.loadSchema("Spec"),
      dataComponent.dataComponentId,
      3,
      2,
      { helperText: "Forecast Comment" }
    );
  }, [dataComponent.dataComponentId, isCollapsed]);

  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        apiRoute={apiRoute}
        hideFooter={isCollapsed}
        model={model}
        includes={includes}
        showSelectionColumn={!isClosedProject}
        showSelectAll={!isClosedProject}
        apiFilters={{
          rootFilters: getRootFilters(projectId),
          params: {
            omitDefaultModifier: true,
            modifiers: ["withAssignedToBidGroup"],
          },
        }}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        tableComponents={tableComponents}
        alwaysDisplayDetailRow
        isCollapsed={isCollapsed}
        ignoreOptimizations
      >
        <GridRow>
          <HeaderWrapper>
            <Wrapper>
              <HeaderLeft>
                <TitleWrapper>{`${dataComponent.totalRows} Unassigned Specs`}</TitleWrapper>
              </HeaderLeft>
              {isClosedProject || isCollapsed ? null : (
                <QuotingActions
                  handleCreatePurchaseOrderClick={
                    handleCreatePurchaseOrderClick
                  }
                  selectedSpecs={selectedRows}
                />
              )}
            </Wrapper>
            <ExpandActionWrapper>
              <IconButton
                aria-label="show more"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <ExpandedIcon isExpanded={!isCollapsed} />
              </IconButton>
            </ExpandActionWrapper>
          </HeaderWrapper>
        </GridRow>
        <GridTable
          columns={columns}
          columnOptions={getColumnOptions(
            specCategories,
            vendors,
            areas,
            projectId
          )}
          onClick={handleOpenSpecDetail}
        />
      </BWGridAPI>
    </Paper>
  );
};

UnassignedSpecsGrid.propTypes = {
  purchaseOrders: PropTypes.arrayOf(propTypes.purchaseOrder),
  handleCreatePurchaseOrderClick: PropTypes.func.isRequired,
  selectedSpecs: PropTypes.array,
  dataComponent: PropTypes.object.isRequired,
  isClosedProject: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  apiRoute: PropTypes.string,
  model: PropTypes.func,
  includes: PropTypes.array,
  role: propTypes.userRole,
  onOpenSpecDetail: PropTypes.func,
  specCategories: PropTypes.arrayOf(propTypes.specCategory),
  vendors: PropTypes.array,
  areas: PropTypes.array,
  selectedRows: PropTypes.array,
};
UnassignedSpecsGrid.defaultProps = {
  dataComponent: {},
  projectId: null,
  handleCreatePurchaseOrderClick: () => {},
  apiRoute: "",
  includes: [],
  role: null,
  specCategories: [],
  selectedRows: [],
};

export default withSelectedRowsAPI(UnassignedSpecsGrid);
