import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { openModalDialog } from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";

import UserRolesTable from "./UserRolesTable";
import Permission from "../../../../models/Permission";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

export const dataComponentPermissionsId = "dataComponentPermissions";
export const dataComponentId = "UserRolesGrid";
export const UserRolesTableContainer = ({
  openModalDialog,
  dataComponent,
  initDataComponent,
  performRetrieveListRequest,
}) => {
  useEffect(() => {
    initDataComponent(
      dataComponentPermissionsId,
      Permission,
      ["permissionGroup"],
      "permissions",
      true
    );

    performRetrieveListRequest(dataComponentPermissionsId, {
      rootFilters: {},
      pageSize: -1,
    });
  }, [initDataComponent, performRetrieveListRequest]);

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Create User Role", "CreateUserRole", {}, false);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    userRole => {
      openModalDialog(
        `Edit ${userRole.name}`,
        "EditUserRole",
        {
          userRole,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, name }) => {
      openModalDialog(
        `Delete ${name}`,
        "DeleteUserRole",
        {
          userRoleId: id,
          title: `${name}`,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <UserRolesTable
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
      dataComponent={dataComponent}
    />
  );
};

UserRolesTableContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  performRetrieveListRequest,
  initDataComponent,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(UserRolesTableContainer),
  ["general-settings-user-roles"]
);
