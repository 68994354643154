import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { findItemInResources } from "../../../../../utils/bluechipUtils";
import { getActiveRequest } from "../../../../../utils/dataComponentUtils";
import {
  initQuickEditSpec,
  initQuickEditSpecForm,
  quickUpdateSpec,
} from "../../../../../actions/specDetailActions";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performFindRequest,
  setReload,
  destroyDataComponent,
} from "../../../../../actions/dataComponentActions";
import { openQuickEditModalAction } from "../../../../../actions/specsActions";
import { fetchPurchaseOrder } from "../../../../../actions/purchaseOrdersActions";

export const PODataComponentId = "QuickEditPO";

const isLoading = (dataComponentId, state) => {
  const dataComponent =
    typeof dataComponentId === "string"
      ? getDataComponentFlattenedRequestState(dataComponentId, state)
      : dataComponentId;
  return dataComponent.loading;
};

export const mapStateToProps = (
  state,
  { dataComponentId, specId, getNextSpecId }
) => {
  const cloneDataComponentId = `${dataComponentId}SpecClone`;
  const cloneDataComponent = getDataComponent(cloneDataComponentId, state);
  const clonedSpecDataComponent = getDataComponentFlattenedRequestState(
    `${dataComponentId}Clone`,
    state
  );
  const specDataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const poDataComponent = getDataComponentFlattenedRequestState(
    PODataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const nextSpecId = getNextSpecId(clonedSpecDataComponent.rowIndex, specId);

  const findSpec = specId =>
    findItemInResources(clonedSpecDataComponent, state, specId) ||
    findItemInResources(specDataComponent, state, specId);

  const spec = findSpec(specId);
  const nextSpec = findSpec(nextSpecId);

  const purchaseOrder = spec.purchaseOrderId
    ? findItemInResources(poDataComponent, state, spec.purchaseOrderId)
    : null;

  return {
    cloneDataComponentId,
    cloneDataComponent,
    purchaseOrder,
    spec,
    nextSpecId,
    nextSpec,
    getNextSpecId,
    loading: isLoading(poDataComponent, state),
    loadingSave: !!getActiveRequest(cloneDataComponent, [
      REQUEST_TYPES.CREATE,
      REQUEST_TYPES.UPDATE,
    ]),
  };
};

export const mapDispatchToProps = {
  closeModalDialog,
  quickUpdateSpec,
  initQuickEditSpecForm,
  setReload,
  performFindRequest,
  initDataComponent,
  openQuickEditModalAction,
  destroyDataComponent,
  fetchPurchaseOrder,
  initQuickEditSpec,
};
