import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";

import propTypes from "../../../../constants/propTypes";
import FormikForm from "../../../../components/forms/FormikForm";
import SpecDetailsForm from "./SpecDetailsForm";
import { performUpdateRequest } from "../../../../actions/dataComponentActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import { dataComponentId } from "../EditSpecDetailDescription/EditSpecDetailDescriptionContainer";
import withPORevision from "../../../../withPORevision";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import _keyBy from "lodash/keyBy";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog
) => () => {
  processUpdateRequestStatus(prevDataComponent.current, dataComponent, {
    onSuccess: () => closeModalDialog(),
  });
  prevDataComponent.current = dataComponent;
};

const getOrderedDescriptions = spec => {
  const detailsById = _keyBy(spec.specDetails, "id");
  const specDetailIdSequence = spec.specDetailIdSequence || [];
  return specDetailIdSequence
    .map(id => detailsById[id])
    .filter(item => item?.type === "Description");
};

export const BulkEditSpecDetailsContainer = ({
  performUpdateRequest,
  closeModalDialog,
  revisionValue,
  dataComponent,
  spec,
}) => {
  const formikActionsRef = useRef({});
  const prevDataComponent = useRef(dataComponent);

  const initialValues = {
    details: getOrderedDescriptions(spec),
  };

  useEffect(
    processRequestResponse(prevDataComponent, dataComponent, closeModalDialog),
    [dataComponent, closeModalDialog]
  );

  const handleSubmit = useCallback(
    ({ details }, formikActions) => {
      formikActionsRef.current = formikActions;
      const revisionIsActive = revisionValue.isActive;
      const params = revisionIsActive
        ? {
            update_po_revision: true,
          }
        : undefined;

      performUpdateRequest(dataComponentId, details, params);
    },
    [revisionValue, performUpdateRequest]
  );

  const schema = useMemo(
    () =>
      Joi.object().keys({
        details: Joi.array().items(BWModels.loadSchema("SpecDetail")),
      }),
    []
  );

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit }) => (
        <SpecDetailsForm
          details={initialValues.details}
          onSubmit={handleSubmit}
        />
      )}
    </FormikForm>
  );
};

BulkEditSpecDetailsContainer.propTypes = {
  spec: propTypes.spec,
  specId: PropTypes.string.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

export const mapStateToProps = () => {
  return state => {
    return {
      dataComponent: getDataComponent(dataComponentId, state),
    };
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(BulkEditSpecDetailsContainer, "SpecDetail", "purchaseOrder"));
