import React, { useMemo } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import _get from "lodash/get";

import { BWGridLocal, GridHeader, GridTable } from "../../../ui/BWGrid";
import propTypes from "../../../../constants/propTypes";
import { AddIcon } from "../components";
import { getUniqRelationFromVendors } from "../Vendors/BidGroupVendors";
import Vendor from "../../../../models/Vendor";
import VendorStatusTag from "../../Vendors/VendorStatusTag";

const columns = ["name", "categoryId", "website", "status", "actions"];

const columnOptions = ({ addSelectedItem, categories }) => ({
  name: {
    title: "Vendor Name",
    filter: true,
    bold: true,
  },
  categoryId: {
    render: row => row && _get(row, "category.name"),
    title: "Category",
    filter: "select",
    filterOptions: {
      options: categories,
    },
  },
  website: {
    title: "Website",
    fullWidth: true,
  },
  status: {
    title: " ",
    filter: false,
    render: ({ status }) =>
      status &&
      status.map(status => (
        <VendorStatusTag key={status}>{status}</VendorStatusTag>
      )),
  },
  actions: {
    title: " ",
    align: "right",
    render: row =>
      row && (
        <div>
          {Vendor.isVendorDisabled(row) ? null : (
            <AddIcon onClick={() => addSelectedItem(row)} />
          )}
        </div>
      ),
  },
});

const sorting = [{ columnName: "name", direction: "asc" }];
const UnselectedVendors = ({ vendors, loading, addSelectedItem }) => {
  const categories = getUniqRelationFromVendors(
    "categoryId",
    "category"
  )(vendors);
  const totalRows = useMemo(() => vendors.length, [vendors.length]);
  const headerText = useMemo(() => pluralize("Vendor", totalRows, true), [
    totalRows,
  ]);

  return (
    <BWGridLocal
      id="bidgroup-vendor-unassigned"
      sorting={sorting}
      rows={vendors}
      gridConfig={{ pageSize: 10, totalRows }}
      emptyStateText="No Vendors Selected"
      isLoading={loading}
    >
      <GridHeader headerText={headerText} />
      <GridTable
        columns={columns}
        columnOptions={columnOptions({ addSelectedItem, categories })}
      />
    </BWGridLocal>
  );
};

UnselectedVendors.propTypes = {
  vendors: PropTypes.arrayOf(propTypes.vendor),
  loading: PropTypes.bool,
  addSelectedItem: PropTypes.func,
};

export default UnselectedVendors;
