export const sortById = items => {
  return (
    items &&
    items.sort((a, b) => {
      const aVal = _convertId(a.id);
      const bVal = _convertId(b.id);
      if (aVal < bVal) {
        return -1;
      } else if (aVal > bVal) {
        return 1;
      } else {
        return 0;
      }
    })
  );
};

const _convertId = id => {
  if (isNaN(id)) {
    return id;
  }

  return parseInt(id);
};
