import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import { inputs } from "../../../../inputs/inputConfigs";
import { ActionButtons, FormikForm } from "../../../../forms";
import { FormGrid } from "../../../../forms/FormGrid";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import useActions from "../../../../hooks/useActions";
import {
  setReload,
  initDataComponent,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { dataComponentId as specsTableDataComponentId } from "../SpecsContainer";
import Spec from "../../../../../models/Spec";

const FormGridContainer = styled.div`
  padding-bottom: 20px;
`;

const inputFields = {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.statusComment,
            label: "Status Comment",
          },
        },
      ],
    },
  ],
};

const editMultipleSpecsDataComponentId = "EditMultipleSpecs";

export const handleUpdateRequestError = showSnackNotificationAction => ({
  data,
}) => {
  if (!data.errors) return;
  data.errors.map(error => {
    if (error.global) {
      showSnackNotificationAction(error.title);
    }
  });
};

const initialValues = {
  statusComment: "",
};

const EditMultipleSpecsModal = ({ selectedSpecIds }) => {
  const dataComponent = useDatacomponent(editMultipleSpecsDataComponentId);

  const loading = dataComponent.requestState?.update?.loading;

  const actions = useActions({
    setReload,
    closeModalDialog,
    initDataComponent,
    performUpdateRequest,
    showSnackNotificationAction,
  });

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      actions.setReload(specsTableDataComponentId, true);
    },
    onError: handleUpdateRequestError(actions.showSnackNotificationAction),
  });

  useEffect(() => {
    actions.initDataComponent(
      editMultipleSpecsDataComponentId,
      Spec,
      [],
      "specs"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = values => {
    const specs = selectedSpecIds.map(id => ({
      id,
      statusComment: values.statusComment,
    }));

    actions.performUpdateRequest(editMultipleSpecsDataComponentId, specs);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Joi.object().keys({
        statusComment: Joi.string()
          .allow("")
          .allow(null),
      })}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGridContainer>
            <FormGrid
              fields={inputFields}
              values={values}
              errors={errors}
              {...formikProps}
            />
          </FormGridContainer>

          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            loading={loading}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

EditMultipleSpecsModal.propTypes = {
  selectedSpecIds: PropTypes.arrayOf(PropTypes.string),
};

EditMultipleSpecsModal.defaultProps = {
  selectedSpecIds: [],
};

export default EditMultipleSpecsModal;
