import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { StyledBWCell } from "./gridInternalComponents/BWCell";
import { BWTableRow } from "./gridInternalComponents";

export const Cell = styled(StyledBWCell)`
  overflow: visible;
`;

const column = {};

const TableRow = styled(BWTableRow)`
  border-bottom: ${({ row: { __expanded__ } }) =>
    __expanded__ ? "hidden" : "initial"};
  & > td:first-child {
    padding: 0;
  }
`;

export const genDetailTableComponents = ({ colSpan, prevColSpan }) => {
  const WrapperDetailRow = ({ children }) => {
    if (!colSpan) return <tr>{children}</tr>;
    const prevSpan = prevColSpan ? prevColSpan : children.length - colSpan;
    const posSpan = prevSpan ? children.length - colSpan - prevSpan : 0;
    return (
      <tr>
        {children}
        {posSpan > 0 && <Cell column={column} colSpan={posSpan} />}
      </tr>
    );
  };

  WrapperDetailRow.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({})),
  };

  const DetailCellComponent = ({ children }) => (
    <Cell column={column} colSpan={colSpan}>
      {children}
    </Cell>
  );

  DetailCellComponent.propTypes = {
    children: PropTypes.shape({}),
  };

  return {
    RowComponent: TableRow,
    WrapperDetailRow,
    DetailCellComponent,
  };
};
