import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { IconButton as MuiIconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const ExpandedIcon = styled(({ isExpanded, ...props }) => (
  <ExpandMoreIcon {...props} />
))`
  ${({ isExpanded }) =>
    isExpanded
      ? `
      transform: rotate(180deg);
      marginleft: auto;
      transition: transform;
      `
      : `
      transform: rotate(270deg);
      marginleft: auto;
      transition: transform;
      `}
`;

const IconButton = styled(MuiIconButton)`
  padding: 0px;
`;

export const CollapseButton = ({
  isCollapsed,
  setIsCollapsed,
  disabled,
  loading,
}) => {
  return (
    <IconButton
      aria-label="show more"
      onClick={() => setIsCollapsed(!isCollapsed)}
      disabled={disabled || loading}
    >
      <ExpandedIcon isExpanded={!isCollapsed} />
    </IconButton>
  );
};
CollapseButton.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
