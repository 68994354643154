import { BaseModel } from "@blue-chip/core";
import Spec from "./Spec";
import Project from "./Project";
import AreaRoom from "./AreaRoom";
import AreaType from "./AreaType";

export default class Area extends BaseModel {
  static get belongsTo() {
    return [Spec, AreaType, Project];
  }

  static get hasMany() {
    return [AreaRoom];
  }
}
