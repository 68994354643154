import _get from "lodash/get";
import _noop from "lodash/noop";

import { openModalDialog } from "./layoutActions";
import {
  fetchDataFailed,
  fetchDataRequest,
  fetchDataSuccess,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "./dataComponentActions";
import {
  ContentWrapper,
  Overlay,
} from "../components/pages/PurchaseOrderDetail/Specs/AddSpecToPO/components";
import * as DATA_COMPONENTS from "../constants/DataComponents";
import {
  destroyDataComponentResourceAction,
  sendPost,
} from "./dataComponents/v2";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { getDataComponent } from "../reducers/dataComponentReducer";

import SpecCategory from "../models/SpecCategory";
import Area from "../models/Area";
import Vendor from "../models/Vendor";
import UnitOfMeasure from "../models/UnitOfMeasure";

export const specsGridDataComponentId = "PurchaseOrderDetail-PurchaseOrderList";
export const specShipmentsDataComponentId = "SpecShipmentsGrid";

export const specsSelectionGridId = "SpecsSelectionGrid";

export function openAddSpecOptionsModalAction(purchaseOrder) {
  return openModalDialog(
    [
      "Add Spec",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ],
    "AddSpecToPO",
    { purchaseOrder },
    false,
    true,
    { components: { Content: ContentWrapper, Overlay }, width: "auto" }
  );
}

export function createSpecToPO(spec, purchaseOrder) {
  const revisionIsActive = !!purchaseOrder.revisionStatus;
  return dispatch => {
    dispatch(
      performCreateRequest(
        specsGridDataComponentId,
        spec,
        revisionIsActive && {
          update_po_revision: true,
        }
      )
    );
  };
}

export function moveSpecsToPO(specIds, purchaseOrder) {
  const revisionIsActive = !!purchaseOrder.revisionStatus;
  return dispatch => {
    dispatch(
      performUpdateRequest(
        specsGridDataComponentId,
        specIds.map(id => ({
          id,
          purchaseOrderId: purchaseOrder.id,
          projectCurrencyId: purchaseOrder.projectCurrencyId,
        })),
        revisionIsActive && {
          update_po_revision: true,
        }
      )
    );
  };
}

const defaultFilters = {
  sort: [{ columnName: "name", direction: "asc" }],
  rootFilters: {},
  pageSize: -1,
};

export const filters = [
  {
    dataComponentId: DATA_COMPONENTS.SPEC_CATEGORIES_SELECT,
    model: SpecCategory,
    includes: [],
    apiRoute: "spec-categories",
    params: {
      modifiers: ["withLinked"],
    },
    rootFilters: ({ projectId }) => ({
      $where: { "specs.projectId": projectId },
    }),
  },
  {
    dataComponentId: DATA_COMPONENTS.AREAS_SELECT,
    model: Area,
    includes: [],
    apiRoute: "areas",
    rootFilters: ({ projectId }) => ({ $where: { projectId } }),
  },
  {
    dataComponentId: DATA_COMPONENTS.VENDORS_SELECT,
    model: Vendor,
    includes: [],
    apiRoute: "vendors",
    rootFilters: ({ projectId }) => ({
      $where: { "specs.projectId": projectId },
    }),
  },
  {
    dataComponentId: DATA_COMPONENTS.UNIT_OF_MEASURE_SELECT,
    model: UnitOfMeasure,
    includes: [],
    apiRoute: "unit-of-measures",
  },
];

export function initFilters(purchaseOrder) {
  return dispatch => {
    filters.forEach(
      ({
        dataComponentId,
        model,
        includes,
        apiRoute,
        params = {},
        rootFilters = _noop,
      }) => {
        dispatch(
          initDataComponent(
            dataComponentId,
            model,
            includes,
            apiRoute,
            false,
            "v2"
          )
        );
        dispatch(
          performRetrieveListRequest(dataComponentId, {
            ...defaultFilters,
            params,
            rootFilters: rootFilters(purchaseOrder),
          })
        );
      }
    );
  };
}

export function destroyFilters() {
  return dispatch => {
    filters.forEach(({ dataComponentId }) => {
      dispatch(
        destroyDataComponentResourceAction(dataComponentId, REQUEST_TYPES.LIST)
      );
    });
  };
}

export function performDuplicateSpecAction(
  dataComponentId,
  ids,
  purchaseOrder
) {
  return async (dispatch, getState) => {
    const options = {
      body: { ids, additionalAttrs: { purchaseOrderId: purchaseOrder.id } },
    };
    const dataComponent = getDataComponent(dataComponentId, getState());
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.CREATE));

    try {
      const requestStatePayload = await sendPost(
        dataComponent,
        options,
        "/specs/duplicate",
        REQUEST_TYPES.CREATE
      );

      dispatch(
        fetchDataSuccess(
          dataComponentId,
          REQUEST_TYPES.CREATE,
          requestStatePayload
        )
      );

      return requestStatePayload;
    } catch (error) {
      dispatch(fetchDataFailed(dataComponentId, REQUEST_TYPES.CREATE, error));
      throw error;
    }
  };
}
