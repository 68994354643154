import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import billFeeFields from "./billFeeFields";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const BillFeeForm = ({ dataComponentId, onSubmit, initialValues }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("Fee")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={billFeeFields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            sendButtonText={"Confirm"}
            onSend={handleSubmit}
            listeners={[dataComponentId]}
            isModal={true}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

BillFeeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: propTypes.fee,
};

export default BillFeeForm;
