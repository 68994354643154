import poll from "poll";
import queryString from "query-string";
import _omitBy from "lodash/omitBy";

import AuthService from "../services/AuthService";
import * as REQUEST_TYPES from "../constants/ActionTypes";
import * as QUEUE_STATUSES from "../constants/queue";
import { showSnackNotificationAction } from "./layoutActions";
const POLL_DELAY = 1500;
const service = new AuthService();

/*
   # Queue Process

   This queue endpoint process all the action types in background job .

                                             +---------+
                                  +--------->+ SUCCESS |
                          +------------+     +---------+
               +--------->+ PROCESSING |
        +------------+    +------------+      +--------+
        | REQUESTING |            +---------> | ERROR  |
        +------------+                        +--------+

   Response is store in a queues object in redux with all the referenceId
   as key. Value is described below:

   {
      status: String,
      type: String,
      lastSeenTime: Date,
      count: Number,
      data: Object,
      queueId: String,
      error: Object,
      returnvalue: Any,

   }
 */
function requestCreateQueue(referenceId) {
  return {
    type: REQUEST_TYPES.CREATE_QUEUE_REQUEST,
    payload: {
      [referenceId]: {
        status: QUEUE_STATUSES.QUEUE_REQUESTNG,
        lastSeenTime: new Date(),
        count: 0,
      },
    },
  };
}

function sendSuccessQueue(referenceId, response) {
  return {
    type: REQUEST_TYPES.CREATE_QUEUE_SUCCESS,
    payload: {
      [referenceId]: {
        status: QUEUE_STATUSES.QUEUE_PROCESSING,
        lastSeenTime: new Date(),
        ...response,
      },
    },
  };
}

function sendFailureQueue(referenceId, response) {
  return {
    type: REQUEST_TYPES.CREATE_QUEUE_FAILURE,
    payload: {
      [referenceId]: {
        status: QUEUE_STATUSES.QUEUE_ERROR,
        ...response,
      },
    },
  };
}

function sendSuccessJob(referenceId, response) {
  return {
    type: REQUEST_TYPES.JOB_QUEUE_COMPLETED,
    payload: {
      [referenceId]: {
        status: QUEUE_STATUSES.QUEUE_SUCCESS,
        ...response,
      },
    },
  };
}

const dispatchPollResponse = (job, referenceId) => {
  if (!job.stacktrace.length) {
    return sendSuccessJob(referenceId, job);
  } else {
    return sendFailureQueue(referenceId, job);
  }
};

export const initQueueProcess = referenceId => {
  return {
    type: REQUEST_TYPES.INIT_QUEUE_JOB,
    payload: {
      referenceId,
    },
  };
};

export const createQueueProcess = (type, referenceId, payload) => {
  return async dispatch => {
    try {
      let stopPolling = false;
      const shouldStopPolling = () => {
        return stopPolling;
      };

      dispatch(requestCreateQueue(referenceId));
      const response = await service.post(
        `/queues/${type}/jobs?${queryString.stringify(
          parseQueryParams(payload)
        )}`,
        payload
      );

      const pollFunction = async () => {
        const job = await service.get(`/queues/${type}/jobs/${response.id}`);
        dispatch(sendSuccessQueue(referenceId, job));
        if (!job.finishedOn) return;
        if (job.failedReason) {
          dispatch(showSnackNotificationAction(job.failedReason));
        }

        dispatch(dispatchPollResponse(job, referenceId));
        stopPolling = true;
      };

      dispatch(sendSuccessQueue(referenceId, response));
      await poll(pollFunction, POLL_DELAY, shouldStopPolling);
    } catch (error) {
      dispatch(sendFailureQueue(referenceId, error));
      dispatch(showSnackNotificationAction(error.message));
    }
  };
};

export const parseQueryParams = payload =>
  _omitBy(payload, prop => typeof prop === "object");
