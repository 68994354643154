import PropTypes from "prop-types";
import React from "react";

import { Title, Value, TableRow } from "./components";
import Loader from "../../../ui/Loader/Loader";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";

export const PriceRow = ({ name, value, small, applyLastBorder }) => {
  const currencyFormatter = useCurrencyFormatter();
  const formattedValue = currencyFormatter.format(value);
  return (
    <TableRow small={small} applyLastBorder={applyLastBorder}>
      <Title small={small}>
        <Loader>{name}</Loader>
      </Title>
      <Value small={small}>
        <Loader>{formattedValue}</Loader>
      </Value>
    </TableRow>
  );
};

PriceRow.defaultProps = {
  small: false,
  applyLastBorder: false,
};

PriceRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  small: PropTypes.bool,
  applyLastBorder: PropTypes.bool,
};

export default PriceRow;
