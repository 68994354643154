import React, { useState } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import { useV2DatacomponentResources } from "../../../../../hooks/useResources";
import {
  deletePOFile,
  poFilesDataComponentId,
  uploadPOFiles,
} from "../../../../../../actions/purchaseOrdersActions";
import * as RequestTypes from "../../../../../../constants/RequestTypes";
import { useDatacomponent } from "../../../../../hooks/useDatacomponent";
import {
  useDeleteProcessRequests,
  useCreateProcessRequests,
} from "../../../../../hooks/useProcessRequest";
import useActions from "../../../../../hooks/useActions";
import Subheader from "../../../../../ui/Typography/Subheader";
import {
  Header,
  Wrapper,
} from "../../../../SpecDetail/AdditionalInformation/SpecFiles/AddSpecFile/components";
import MainSection from "../../../../SpecDetail/AdditionalInformation/SpecFiles/AddSpecFile/MainSection";
import { useIsProjectClosed } from "../../../../../hooks/useIsProjectClosed";
import { DragDropDashboard } from "../../../../../inputs/DragDrop/DragDropDashboard";

const getAcceptOption = acceptImage =>
  acceptImage ? ".pdf,.jpg,.png,.gif" : ".pdf";

const AddPOFile = ({ fileType, acceptImage, purchaseOrderId }) => {
  const [isUploadActive, setUploadActive] = useState();
  const [activeDelete, setActiveDelete] = useState(null);

  const dataComponent = useDatacomponent(poFilesDataComponentId);
  const actions = useActions({ deletePOFile, uploadPOFiles });

  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      setActiveDelete(null);
    },
  });

  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      setUploadActive(false);
    },
  });

  const handleUpload = evt => {
    setUploadActive(true);
    actions.uploadPOFiles(evt.target.files, purchaseOrderId, fileType);
  };

  const handleDelete = poFile => {
    setActiveDelete(poFile.id);
    actions.deletePOFile(poFile.id);
  };

  const poFiles = useV2DatacomponentResources(
    poFilesDataComponentId,
    [],
    RequestTypes.LIST
  );

  const files = poFiles.filter(poFile => poFile.key === fileType);

  const isProjectClosed = useIsProjectClosed();

  return (
    <Wrapper>
      <Header>
        <Subheader>{pluralize("File", files.length, true)}</Subheader>
      </Header>
      <MainSection
        inputId="po-file"
        files={files}
        isUploadActive={isUploadActive}
        activeDelete={activeDelete}
        onDelete={handleDelete}
        isLabeled={isProjectClosed}
      />
      {!isProjectClosed && (
        <DragDropDashboard
          onChange={handleUpload}
          accept={getAcceptOption(acceptImage)}
          height="100%"
          multiple
        />
      )}
    </Wrapper>
  );
};

AddPOFile.propTypes = {
  fileType: PropTypes.string,
  acceptImage: PropTypes.bool,
  purchaseOrderId: PropTypes.string,
};

export default AddPOFile;
