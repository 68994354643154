import React from "react";
import propTypes from "../../../../constants/propTypes";
import { ColumnItem } from "../Accounting/Fees/FeeSummary/FeeSummary";
import DateColumn from "../../../ui/BWGrid/columns/Date";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

const useFormat = value => useCurrencyFormatter().format(value);

const FundingStatusSection = props => {
  const { project } = props;

  const values = [
    [
      {
        title: "Total Funding Received",
        value: useFormat(project.totalFundsReceived),
      },
    ],
    [
      {
        title: "Funds Requested",
        value: useFormat(project.totalFundings),
      },
    ],
    [
      {
        title: "Cash Balance",
        value: useFormat(project.totalFundsReceived - project.expenditures),
      },
    ],
    [
      {
        title: "Last Funded Date",
        value: (
          <DateColumn
            date={project.lastFundingRequestWithFunds}
            format={PRIMARY_DATE_FORMAT}
          />
        ),
      },
    ],
    [
      {
        title: "Last Funded Amount",
        value: useFormat(project.lastFundingWithFundsAmount),
      },
    ],
  ];

  return (
    <div>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} id={key} />
      ))}
    </div>
  );
};

FundingStatusSection.defaultProps = {
  project: {},
};

FundingStatusSection.propTypes = {
  project: propTypes.project.isRequired,
};

export default FundingStatusSection;
