import React, { useEffect } from "react";
import BWModels from "benjaminwest-models";
import PropTypes from "prop-types";
import _omitBy from "lodash/omitBy";
import _isNil from "lodash/isNil";

import { FormGrid, FormikForm } from "../../../../../forms";
import DatePickerWithError from "../../../../../inputs/DatePickerWithError";
import TextInputWithError from "../../../../../inputs/TextInputWithError";
import ActionButtons from "../../../../../forms/ActionButtons";
import useActions from "../../../../../hooks/useActions";
import {
  billShippingPayments,
  billShippingPaymentsDataComponentId,
} from "../../../../../../actions/shippingPaymentsActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performUpdateRequest,
} from "../../../../../../actions/dataComponentActions";
import ShippingPayment from "../../../../../../models/ShippingPayment";
import { useUpdateProcessRequests } from "../../../../../hooks/useProcessRequest";
import {
  useDatacomponent,
  useLoading,
} from "../../../../../hooks/useDatacomponent";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import { LabeledTextContext } from "../../../../../../withPORevision/withLabeledTextOption";
import { getDataComponentId } from "../ShippingPaymentsContainer";
import { loadPayments } from "../../gridFunctions";

const getFields = edit => ({
  groups: [
    {
      items: [
        {
          input: {
            InputComponent: DatePickerWithError,
            label: "Billed Date",
            name: "billedDate",
            fullWidth: true,
            required: !edit,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            InputComponent: TextInputWithError,
            name: "bwInvoiceNumber",
            label: "BW Invoice Number",
            fullWidth: true,
            required: !edit,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

const getValidationSchema = edit =>
  edit
    ? BWModels.Joi.any()
    : BWModels.Joi.object({
        billedDate: BWModels.Joi.date()
          .empty(null)
          .required(),
        bwInvoiceNumber: BWModels.Joi.string(),
      });

export const editShippingPaymentsDataComponentId =
  "editShippingPaymentsDataComponentId";

export const MarkBilledModal = ({ projectId, selectedIds, edit }) => {
  const actions = useActions({
    initDataComponent,
    billShippingPayments,
    performUpdateRequest,
    closeModalDialog,
    showSnackNotificationAction,
    performRetrieveListRequest,
  });

  useEffect(() => {
    actions.initDataComponent(
      editShippingPaymentsDataComponentId,
      ShippingPayment,
      [],
      "shipping-payments",
      true,
      "v2"
    );
  }, [actions]);

  const dataComponent = useDatacomponent(editShippingPaymentsDataComponentId);
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      loadPayments(
        actions.performRetrieveListRequest,
        getDataComponentId(projectId),
        projectId
      );
      actions.showSnackNotificationAction(
        "Shipping payments updated successfully."
      );
    },
  });

  const handleMarkAsBilled = values =>
    actions.billShippingPayments(projectId, selectedIds, values);
  const handleEdit = values =>
    actions.performUpdateRequest(
      editShippingPaymentsDataComponentId,
      selectedIds.map(id => ({
        id,
        ..._omitBy(values, _isNil),
      }))
    );
  const handleSubmit = edit ? handleEdit : handleMarkAsBilled;

  const isLoading = useLoading([
    billShippingPaymentsDataComponentId,
    editShippingPaymentsDataComponentId,
  ]);

  return (
    <LabeledTextContext.Provider value={isLoading}>
      <FormikForm
        onSubmit={handleSubmit}
        initialValues={{
          billedDate: edit ? null : new Date(),
          bwInvoiceNumber: null,
        }}
        validationSchema={getValidationSchema(edit)}
      >
        {formikProps => (
          <React.Fragment>
            <FormGrid fields={getFields(edit)} {...formikProps} />
            <ActionButtons
              onSend={formikProps.handleSubmit}
              listeners={[
                billShippingPaymentsDataComponentId,
                editShippingPaymentsDataComponentId,
              ]}
              isModal
            />
          </React.Fragment>
        )}
      </FormikForm>
    </LabeledTextContext.Provider>
  );
};

MarkBilledModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  selectedIds: PropTypes.array.isRequired,
  edit: PropTypes.bool,
};
