import React from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";
import {
  Title,
  Page,
  Instructions,
  AuthFormWrapper,
  Container,
  AuthFormContainer,
} from "../Login/components";
import inputs from "../../inputs/inputConfigs/login";
import { FormikForm, FormGrid } from "../../forms";
import ActionButtons from "../../forms/ActionButtons";
import { Joi } from "benjaminwest-models";
import TextInputWithError from "../../inputs/TextInputWithError";
import { CodeMask } from "../../layout/AppLayout/VerifyCellPhoneNumber/VerifyCode";
import { withRouter } from "react-router-dom";

const createResetPasswordFields = () => ({
  groups: [
    {
      items: [
        {
          input: {
            InputComponent: TextInputWithError,
            label: "Verification Code",
            name: "verificationCode",
            fullWidth: true,
            avoidDebounce: true,
            InputProps: {
              inputComponent: CodeMask,
            },
            inputProps: { autoFocus: true },
          },
          grid: { xs: 12 },
        },
        {
          input: { ...inputs.password, label: "New Password" },
          grid: { xs: 12 },
        },
      ],
    },
  ],
});

const ResetPasswordPage = ({ handleUpdatePassword, loading, history }) => (
  <Page>
    <Container>
      <Title />
      <FormikForm
        initialValues={{
          verificationCode: "",
          password: "",
        }}
        onSubmit={handleUpdatePassword}
        validationSchema={Joi.object({
          verificationCode: Joi.string(),
          password: Joi.password(),
        })}
        ignoreValidationFieldNames={[
          "name",
          "officeId",
          "userRoleId",
          "email",
          "phoneNumber",
        ]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <AuthFormContainer>
            <AuthFormWrapper>
              <Instructions>
                Please provide the 6 digit code you received in your email. Then
                provide a 8 to 32 characters password with at least 1 number.
              </Instructions>

              <FormGrid
                fields={createResetPasswordFields()}
                values={values}
                errors={errors}
                {...formikProps}
              />

              <ActionButtons
                onSend={handleSubmit}
                sendButtonText="SET PASSWORD"
                cancelButtonText="BACK"
                onCancel={history.goBack}
                isStacked={true}
                isModal={true}
                loading={loading}
              />
            </AuthFormWrapper>
          </AuthFormContainer>
        )}
      </FormikForm>
    </Container>
  </Page>
);

ResetPasswordPage.propTypes = {
  errors: propTypes.errorPayload,
  handleUpdatePassword: PropTypes.func.isRequired,
};

export default withRouter(ResetPasswordPage);
