import React, { useMemo, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import currencyFormatter from "../../../../../utils/currencyFormatter";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import startCase from "lodash/startCase";

import { getBluechipResources } from "../../../../../utils/bluechipUtils";
import {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
} from "../../../../../actions/dataComponentActions";
import propTypes from "../../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../../reducers/dataComponentReducer";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

const dataCurrencyId = "select-currencies";
const dataUsersId = "select-users";

function isDate(str) {
  return !!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str);
}

const getFormattedValue = (customFormats, attribute, value) => {
  const formater = Object.values(customFormats).find(format => {
    return format.fields.includes(attribute);
  });
  if (typeof value == "boolean") {
    return startCase(`${value}`);
  }
  const parsedValue = attribute.includes("Cents") ? value / 100 : value;
  let formattedValue = formater ? formater.format(parsedValue) : parsedValue;
  return (formattedValue = isDate(value)
    ? customFormats.date.format(value)
    : formattedValue);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const getCustomFormats = (currencies, purchaseOrder, users) => ({
  shipVia: {
    fields: ["shipVia"],
    format: value =>
      value === "[ProjectFreightVendor]"
        ? purchaseOrder.project.freightVendor.name
        : value,
  },
  currency: {
    fields: [
      "priceCents",
      "clientBudgetCents",
      "totalPriceWithDiscount",
      "totalPriceWithoutOverage",
      "designerBudget",
    ],
    format: value => currencyFormatter.format(value),
  },
  user: {
    fields: ["projectManagerId"],
    format: value => {
      const user = users.find(({ id }) => id == value);
      return user && user.name;
    },
  },
  currencyType: {
    fields: ["currencyId"],
    format: value => {
      const currency = currencies.find(({ id }) => id == value);
      return currency && currency.name;
    },
  },
  date: {
    fields: ["estimatedShipDate"],
    format: value => (value ? moment(value).format(PRIMARY_DATE_FORMAT) : ""),
  },
  percentage: {
    fields: ["overagePercent"],
    format: value => value && `${value}%`,
  },
});

export const Formatter = ({
  attribute,
  value,
  currencies = [],
  users = [],
  purchaseOrder,
}) => {
  const customFormats = useMemo(
    () => getCustomFormats(currencies, purchaseOrder, users),
    [currencies, purchaseOrder, users]
  );

  return (
    <Fragment>
      {getFormattedValue(customFormats, attribute, value) || "None"}
    </Fragment>
  );
};

Formatter.propTypes = {
  attribute: PropTypes.string,
  users: PropTypes.arrayOf(propTypes.user),
  currencies: PropTypes.arrayOf(propTypes.currency),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  purchaseOrder: propTypes.purchaseOrder,
};

export const mapStateToProps = state => {
  const dataCurrencyComponent = getDataComponentFlattenedRequestState(
    dataCurrencyId,
    state
  );
  const dataUsersComponent = getDataComponentFlattenedRequestState(
    dataUsersId,
    state
  );
  return {
    currencies: getBluechipResources(dataCurrencyComponent, state) || [],
    users: getBluechipResources(dataUsersComponent, state) || [],
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Formatter)
);
