import React, { Component } from "react";
import { connect } from "react-redux";
import ClientsPage from "./ClientsPage";
import PropTypes from "prop-types";

import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";

export class ClientsContainer extends Component {
  handleRowClick = ({ id }) => {
    this.props.pushWithReturnUrl(`/clients/${id}`);
  };

  handleAddClient = () => {
    this.props.pushWithReturnUrl("/clients/create");
  };

  handleEditClient = id => {
    this.props.pushWithReturnUrl(`clients/edit/${id}`, "CLIENT INDEX");
  };

  handleDeleteClient = ({ id, name: title }) => {
    this.props.openModalDialog(
      `Delete ${title}`,
      "DeleteClient",
      { id, title },
      true,
      false
    );
  };

  render() {
    const { dataComponent } = this.props;
    return (
      <ClientsPage
        dataComponent={dataComponent}
        handleRowClick={this.handleRowClick}
        handleAddClient={this.handleAddClient}
        handleEditClient={this.handleEditClient}
        handleDeleteClient={this.handleDeleteClient}
      />
    );
  }
}

ClientsContainer.propTypes = {
  pushWithReturnUrl: PropTypes.func.isRequired,
  dataComponent: PropTypes.object.isRequired,
  openModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponentFlattenedRequestState("ClientsGrid", state),
});

export const mapDispatchToProps = { pushWithReturnUrl, openModalDialog };

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);
