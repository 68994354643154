import React, { Fragment } from "react";
import _get from "lodash/get";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import Paper from "../../../mui/core/Paper";
import { Content } from "../../../layout/Content";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import pluralize from "pluralize";
import GridAPI from "../../../ui/Grid/GridAPI";
import Discount from "../../../../models/Discount";
import { useFlattenedDatacomponent } from "../../../hooks/useDatacomponent.js";
import { buildTextTypeProvider } from "../../../ui/Grid/filterDataTypeProviders";
import useVendorScopeStore from "../../../hooks/useVendorScopeStore";
import { unitTypeBuilder } from "../../../ui/Grid/utilComponents/dataTypeProviders";

export const dataComponentId = "DiscountVendorPortal";

export const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) => <Fragment>{value}</Fragment>}
    {...props}
    for={["programNameWithDiscountType"]}
  />
);

const VendorPortalEntrypoint = () => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);
  const selectedVendor = useVendorScopeStore(state => state.selectedVendor);

  const GridHeader = useHeaderComponent({
    headerText: pluralize("Discount", dataComponent.totalRows, true),
  });

  if (!selectedVendor) return null;

  return (
    <Content>
      <Paper>
        <GridAPI
          model={Discount}
          apiRoute="discounts"
          includes={["program"]}
          defaultSorting={[
            {
              columnName: "programNameWithDiscountType",
              direction: "asc",
            },
          ]}
          apiFilters={{
            rootFilters: {
              $where: {
                vendorId: _get(selectedVendor, "id"),
              },
            },
          }}
          dataComponent={dataComponent}
          columns={[
            {
              name: "programNameWithDiscountType",
              title: "Name",
              bold: true,
              filter: true,
              width: "5vw",
            },
            {
              name: "discountPercent",
              title: "Amount",
              width: "20vw",
              getCellValue: ({ discountPercent }) => ({
                value: discountPercent,
                unitOfMeasure: "%",
              }),
            },
          ]}
          tableComponents={{
            GridHeader,
          }}
          dataTypeProviders={[
            NameTypeProvider,
            unitTypeBuilder(["discountPercent"]),
          ]}
          filteringDataTypeProviders={[
            buildTextTypeProvider(["programNameWithDiscountType"]),
          ]}
        />
      </Paper>
    </Content>
  );
};

export default VendorPortalEntrypoint;
