import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../../../constants/propTypes";
import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import { ActionButtons } from "../../../../forms";
import { Message } from "./components";
import { handleUnawardSpec, generateGridRows } from "./UnawardSpecFunctions";
import pluralize from "pluralize";

const columns = [
  "customNumber",
  "description",
  "unitOfMeasure.name",
  "quantity",
];
const columnOptions = () => ({
  customNumber: { title: "Number", bold: true, filter: false },
  description: {
    filter: false,
    fill: true,
  },
  "unitOfMeasure.name": { title: "UOM", bold: true, width: "50px" },
  quantity: {
    title: "QTY",
    width: "50px",
  },
});

const UnawardSpecContainer = props => {
  const {
    bidGroupVendor,
    bidGroupVendorSpecs,
    onUnAwardSpec,
    dataComponentId,
    setSnackMessage,
  } = props;
  const [gridRows, setGridRows] = useState([]);

  useEffect(() => {
    const rows = generateGridRows(bidGroupVendorSpecs);

    setGridRows(rows);
  }, [bidGroupVendorSpecs]);
  return (
    <React.Fragment>
      <BWGridLocal
        id={`create-bid-group-purchase-order-${bidGroupVendor.id}`}
        sorting={[{ columnName: "spec.customNumber", direction: "asc" }]}
        rows={gridRows}
        gridConfig={{ pageSize: 0, totalRows: bidGroupVendorSpecs.length }}
        isLoading={false}
        noBorder
      >
        <GridRow>
          <Message>{`Do you want to un-award the following ${pluralize(
            "spec",
            bidGroupVendorSpecs.length
          )} from ${bidGroupVendor.vendor.name}?`}</Message>
        </GridRow>
        <GridTable columns={columns} columnOptions={columnOptions()} />
        <GridRow showPagingPanel={false} />
      </BWGridLocal>
      <ActionButtons
        isModal={true}
        sendButtonText={`Unaward ${pluralize(
          "Spec",
          bidGroupVendorSpecs.length
        )}`}
        onSend={() =>
          handleUnawardSpec(
            bidGroupVendorSpecs,
            onUnAwardSpec,
            dataComponentId,
            setSnackMessage
          )
        }
        hideCancelButton
        listeners={[dataComponentId]}
      />
    </React.Fragment>
  );
};

UnawardSpecContainer.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
  bidGroupVendorSpecs: PropTypes.arrayOf(propTypes.spec),
  onUnAwardSpec: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  setSnackMessage: PropTypes.func.isRequired,
};

export default UnawardSpecContainer;
