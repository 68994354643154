import rfqRoutes from "./rfq";
import { appPageBuilder } from "../utils";
import ManageUsers from "../../components/pages/VendorContacts/VendorPortalEntrypoint";
import Discounts from "../../components/pages/VendorDetail/Discounts/VendorPortalEntrypoint";

const AppManageUsers = appPageBuilder(ManageUsers, "Manage Contacts");
const AppDiscounts = appPageBuilder(Discounts, "Discounts");

const routes = [
  {
    path: "/manage-vendor-users",
    component: AppManageUsers,
    exact: true,
  },
  {
    path: "/vendor-discounts",
    component: AppDiscounts,
    exact: true,
  },
];

export default [...rfqRoutes, ...routes];
