import React from "react";
import styled from "styled-components";
import Paper from "../../../mui/core/Paper";
import GridHeader from "../../../ui/BWGrid/GridHeader";
import PropTypes from "prop-types";
import StatusTag from "../../../ui/Tags/StatusTag";

export const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${GridHeader} {
    padding: 24px;
  }
`;
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  ${GridHeader} {
    flex-grow: 1;
  }
  ${StatusTag} {
    margin-right: 24px;
  }
`;

const BaseTab = props => {
  const { text, statusText, showStatusTag, children } = props;

  return (
    <StyledPaper classes={{ root: "paper-root" }}>
      <HeaderWrapper>
        <GridHeader headerText={text} />
        {showStatusTag && <StatusTag>{statusText}</StatusTag>}
      </HeaderWrapper>
      {children}
    </StyledPaper>
  );
};

BaseTab.propTypes = {
  text: PropTypes.string,
  statusText: PropTypes.string,
  showStatusTag: PropTypes.bool,
  children: PropTypes.node,
};
BaseTab.defaults = {
  showStatusTag: false,
};
export default BaseTab;
