import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withRouter } from "react-router";
import { connect as formikConnect } from "formik";

import { MenuContainer, SearchItem } from "../../VendorInfo/components";
import SearchBox from "../../../../../ui/SearchBox";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../../actions/dataComponentActions";
import { connect } from "react-redux";
import ProjectSiteContact from "../../../../../../models/ProjectSiteContact";
import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../../../utils/bluechipUtils";
import { filterContacts } from "../../VendorInfo/AddContactMenu/AddContactMenu";
import { List } from "./List";
import { LoaderContext } from "../../../../../ui/Loader";
import {
  createPOContact,
  poContactdataComponentId,
} from "../../../../../../actions/purchaseOrdersContactActions";
import { bindActionCreators } from "redux";

export const projectSiteContactsDataComponentId =
  "projectSiteContactsDataComponentId";

class AddContactMenu extends Component {
  static propTypes = {
    anchorEl: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    createPOContact: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  state = {
    searchVal: "",
  };

  constructor(props) {
    super(props);
    this.formikPropsRef = React.createRef(null);
  }

  componentDidUpdate({ anchorEl: prevAnchorEl }) {
    const {
      initDataComponent,
      performRetrieveListRequest,
      shipAddressId,
      anchorEl,
    } = this.props;
    if (anchorEl && !prevAnchorEl) {
      initDataComponent(
        projectSiteContactsDataComponentId,
        ProjectSiteContact,
        ["contact"],
        "project-site-contacts"
      );
      performRetrieveListRequest(projectSiteContactsDataComponentId, {
        rootFilters: { $where: { projectSiteId: shipAddressId } },
        sort: [{ columnName: "contact.firstName", direction: "asc" }],
        pageSize: -1,
      });
    }
  }

  get menuProps() {
    const { anchorEl } = this.props;
    return {
      anchorEl: anchorEl,
      open: Boolean(anchorEl),
      onClose: this.props.handleClose,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    };
  }

  setSearchText = ({ target: { value } }) => {
    this.setState({ searchVal: value });
  };

  get projectSiteContacts() {
    const { projectSiteContacts, purchaseOrderContacts } = this.props;
    const { searchVal } = this.state;
    return filterContacts(
      projectSiteContacts,
      purchaseOrderContacts
        .filter(({ projectSiteContact }) => projectSiteContact)
        .map(({ projectSiteContact }) => projectSiteContact),
      searchVal
    );
  }

  createPOContact = projectSiteContactId => {
    this.props.createPOContact(projectSiteContactId);
    this.props.handleClose();
  };

  render() {
    const { loading } = this.props;
    const { searchVal } = this.state;
    return (
      <LoaderContext.Provider value={{ loading: loading }}>
        <MenuContainer {...this.menuProps}>
          <SearchItem button={false}>
            <SearchBox
              name="searchVal"
              value={searchVal}
              onChange={this.setSearchText}
              autoFocus
            />
          </SearchItem>
          <List
            projectSiteContacts={this.projectSiteContacts}
            projectContacts={[]}
            onItemClick={this.createPOContact}
          />
        </MenuContainer>
      </LoaderContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    projectSiteContactsDataComponentId,
    state
  );
  return {
    loading: flattenedDataComponent.loading,
    projectSiteContacts:
      getBluechipResources(flattenedDataComponent, state) || [],
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { match, formik } = ownProps;
  const purchaseOrderId =
    match.params.poId || _get(ownProps, "purchaseOrder.id");

  const { setFieldValue, values: po } = formik;

  return {
    ...bindActionCreators(
      {
        initDataComponent,
        performRetrieveListRequest,
      },
      dispatch
    ),
    createPOContact: vendorContactId =>
      dispatch(
        createPOContact(
          poContactdataComponentId,
          purchaseOrderId,
          vendorContactId,
          "projectSite",
          setFieldValue,
          po.poContacts
        )
      ),
  };
};

export default withRouter(
  formikConnect(connect(mapStateToProps, mapDispatchToProps)(AddContactMenu))
);
