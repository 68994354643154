import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Joi from "joi-browser";

import propTypes from "../../../../constants/propTypes";
import { ActionButtons } from "../../../forms";
import FormikForm from "../../../forms/FormikForm";
import FormGrid from "../../../forms/FormGrid";
import SelectWithError from "../../../inputs/SelectWithError";
import Client from "../../../../models/Client";
import Entity from "../../../../models/Entity";
import Property from "../../../../models/Property";
import { dataComponentId } from "../../ProjectEdit/ProjectEditContainer";
import Project from "../../../../models/Project";

const clientsDataComponentId = "select-clients";

export const InstructionsCopy = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 24px 24px 0px 24px;
`;
export const FormWrapper = styled.div`
  margin-bottom: 24px;
`;

export const getFields = ({ clientId, setClientId, entityId, setEntityId }) => {
  const fields = {
    groups: [
      {
        items: [
          {
            input: {
              InputComponent: SelectWithError,
              name: "clientId",
              label: "Select a Client",
              dataComponentId: "select-clients",
              isAutocomplete: true,
              isSingleAutocomplete: true,
              fullWidth: true,
              onSelectedItem: client => setClientId(client.id),
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
  if (clientId) {
    fields.groups[0].items.push({
      input: {
        InputComponent: SelectWithError,
        name: "entityId",
        label: "Select an Entity",
        dataComponentId: "select-entities-transfer",
        isAutocomplete: true,
        isSingleAutocomplete: true,
        fullWidth: true,
        onSelectedItem: entity => setEntityId(entity.id),
      },
      grid: { xs: 12 },
    });
  }
  if (entityId) {
    fields.groups[0].items.push({
      input: {
        InputComponent: SelectWithError,
        name: "propertyId",
        label: "Select a Property",
        dataComponentId: "select-properties-transfer",
        isAutocomplete: true,
        isSingleAutocomplete: true,
        fullWidth: true,
      },
      grid: { xs: 12 },
    });
  }
  return fields;
};
export const getActionButtonText = values => {
  if (values.clientId && values.entityId && values.propertyId) {
    return "Switch Client";
  }
  return "";
};

const TransferProjectContainer = props => {
  const {
    project,
    initDataComponent,
    performRetrieveListRequest,
    onTransferProject,
    scopeId,
  } = props;
  const [clientId, setClientId] = useState(0);
  const [entityId, setEntityId] = useState(0);

  const validationSchema = useMemo(
    () =>
      Joi.object().keys({
        clientId: Joi.number()
          .integer()
          .required(),
        entityId: Joi.number()
          .integer()
          .required(),
        propertyId: Joi.number()
          .integer()
          .required(),
      }),
    []
  );

  useEffect(() => {
    initDataComponent(clientsDataComponentId, Client, [], "clients");
    initDataComponent("select-entities-transfer", Entity, [], "entities");
    initDataComponent("select-properties-transfer", Property, [], "properties");
    initDataComponent(dataComponentId, Project, [], "projects", true);
  }, [initDataComponent]);

  useEffect(() => {
    performRetrieveListRequest("select-clients", {
      rootFilters: { $where: { scopeId } },
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
    });
  }, [performRetrieveListRequest, scopeId]);
  useEffect(() => {
    if (!clientId) {
      return;
    }

    performRetrieveListRequest("select-entities-transfer", {
      rootFilters: { $where: { clientId } },
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
    });
  }, [performRetrieveListRequest, clientId]);
  useEffect(() => {
    if (!entityId) {
      return;
    }

    performRetrieveListRequest("select-properties-transfer", {
      rootFilters: {
        $where: {
          entityId,
          id: {
            $notEqual: project.propertyId,
          },
        },
      },
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
    });
  }, [performRetrieveListRequest, entityId, project]);

  const handleTransferProject = data => {
    onTransferProject(project, data.propertyId);
  };

  return (
    <React.Fragment>
      <InstructionsCopy>Select new client:</InstructionsCopy>
      <FormikForm
        initialValues={{
          clientId: undefined,
          entityId: undefined,
          propertyId: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={handleTransferProject}
        ignoreCache
      >
        {({ handleSubmit, values }) => {
          return (
            <React.Fragment>
              <FormWrapper>
                <FormGrid
                  fields={getFields({
                    clientId,
                    setClientId,
                    entityId,
                    setEntityId,
                  })}
                />
              </FormWrapper>
              <ActionButtons
                isModal
                onDanger={handleSubmit}
                dangerButtonText={getActionButtonText(values)}
                hideDangerButton={!getActionButtonText(values)}
                emphasizeCancel
                listeners
              />
            </React.Fragment>
          );
        }}
      </FormikForm>
    </React.Fragment>
  );
};

TransferProjectContainer.propTypes = {
  project: propTypes.project,
  onTransferProject: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  scopeId: PropTypes.string.isRequired,
};

export default TransferProjectContainer;
