import { connect } from "react-redux";

import { closeModalDialog } from "../../../../../actions/layoutActions";
import {
  testQueryReport,
  testAndSaveQueryReport,
} from "../../../../../actions/queryReportsActions";
import { SaveQueryReport } from "./SaveQueryReport";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../../actions/dataComponentActions";
import { getResourcesV2 } from "../../../../../utils/bluechipUtils";

import * as RequestTypes from "../../../../../constants/RequestTypes";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";

export const tablesDataComponentId = "tablesDataComponentId";

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(tablesDataComponentId, state);
  const tables = getResourcesV2(dataComponent, state, RequestTypes.LIST, []);

  return {
    tables,
    office: state.auth.office,
  };
};

const mapDispatchToProps = {
  testQueryReport,
  testAndSaveQueryReport,
  closeModalDialog,
  initDataComponent,
  performRetrieveListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveQueryReport);
