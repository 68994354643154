import memoize from "memoize-one";

import SelectWithError, {
  SelectWithError as RawSelectWithError,
} from "../SelectWithError";
import TextInputWithError from "../TextInputWithError";
import CheckboxWithError from "../CheckboxWithError";
import PercentageInput from "../PercentageInput";
import CurrencyInput from "../CurrencyInput";
import SecretInput from "../SecretInput.js";
import FilePickerWithError from "../FilePickerWithError";
import { getOptionsFromSchema } from "../utils";
import DatePickerWithError from "../DatePickerWithError";
import ClientPricing from "../../../models/ClientPricing";

export const usersDCId = "teamRow-users";
export const freightDCId = "teamRow-freight";
export const warehouseDCID = "teamRow-warehouse";
export const consultingDCId = "teamRow-consulting";
export const clientContactDCId = "teamRow-clientContact";
export const vendorContactsDCId = "teamRow-vendorContacts";
export const warehouseContactsDCId = "teamRow-warehouseContacts";

export function buildUserFilter(memberType, expectedRoles) {
  const roles = ["Admin", "MVP", ...expectedRoles];
  const filter = memoize((users, memberType) => {
    if (!users) return [];
    return users
      .filter(user => roles.includes(user.userRole.name))
      .map(user => ({ memberType, userId: user.id, name: user.name }));
  });
  return users => filter(users, memberType);
}

export function buildUserFilterWithKeyWords(memberType, keyWords) {
  const regex = new RegExp(keyWords.join("|"), "i");
  const filter = memoize((users, memberType) => {
    if (!users) return [];
    return users
      .filter(user => regex.test(user.userRole.name))
      .map(user => ({ memberType, userId: user.id, name: user.name }));
  });
  return users => filter(users, memberType);
}

export function buildContactFilter(memberType) {
  const filter = memoize(contacts => {
    if (!contacts) return [];
    return contacts.map(({ contact: { firstName, lastName = "", id } }) => ({
      memberType,
      contactId: id,
      name: `${firstName} ${lastName}`,
    }));
  });
  return users => filter(users);
}
export function buildConsultingCompanyFilter(consultingCompanyType, ...ids) {
  const filter = memoize(consultingCompanies => {
    if (!consultingCompanies) return [];
    return consultingCompanies.filter(
      consultingCompany =>
        consultingCompany.type === consultingCompanyType &&
        !ids.includes(consultingCompany.id)
    );
  });
  return consultingCompanies =>
    filter(consultingCompanies, consultingCompanyType);
}
export function buildVendorContactFilter(vendorId, memberType) {
  const filter = memoize(vendorContacts => {
    if (!vendorContacts) return [];

    return vendorContacts
      .filter(vendorContact => vendorContact.vendorId === vendorId)
      .map(vendorContact => {
        return {
          memberType,
          contactId: vendorContact.contact.id,
          name: `${vendorContact.contact.firstName} ${vendorContact.contact.lastName}`,
        };
      });
  });
  return vendorContacts => filter(vendorContacts);
}

export default {
  projectStatus: {
    InputComponent: RawSelectWithError,
    label: "Status",
    name: "status",
    options: getOptionsFromSchema("Project", "statuses"),
    fullWidth: true,
    required: true,
    ignoreLabeledText: true,
  },
  projectName: {
    InputComponent: TextInputWithError,
    label: "Project Name",
    name: "name",
    fullWidth: true,
    required: true,
  },
  projectNickname: {
    InputComponent: TextInputWithError,
    label: "Project Nickname",
    name: "nickname",
    fullWidth: true,
  },
  projectNumber: {
    InputComponent: TextInputWithError,
    label: "Project Number",
    name: "numberPrefix",
    fullWidth: true,
  },
  projectNumberExt: {
    InputComponent: TextInputWithError,
    label: "Project Extension Number",
    name: "numberExt",
    fullWidth: true,
    isValid: value => !value || !!value.match(/^[a-z0-9]+$/i),
  },
  projectFundingType: {
    InputComponent: RawSelectWithError,
    label: "Funding Type",
    name: "fundingType",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "fundingTypes"),
    nullAsEmpty: true,
    displayEmpty: true,
    fullWidth: true,
  },
  projectFundingDue: {
    InputComponent: RawSelectWithError,
    label: "Funding Due",
    name: "fundingDue",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "fundingDues"),
    displayEmpty: true,
    nullAsEmpty: true,
    fullWidth: true,
  },
  projectFundingDueDate: {
    InputComponent: TextInputWithError,
    label: "Due Date",
    name: "fundingDueDate",
    type: "number",
    min: 1,
    max: 31,
    fullWidth: true,
  },
  fundingDueWeekday: {
    InputComponent: RawSelectWithError,
    label: "Due Date",
    name: "fundingDueWeekday",
    options: getOptionsFromSchema("Project", "dueWeekdays"),
    nullAsEmpty: true,
    fullWidth: true,
  },
  hasProjectAccounting: {
    name: "hasAccounting",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Project Accounting",
  },
  isTaxExempt: {
    name: "isTaxExempt",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Tax Exempt",
  },
  bankAccount: {
    name: "accountingBankAccount",
    InputComponent: SecretInput,
    label: "Bank Account",
    fullWidth: true,
    permission: "set-bank-account",
    autoComplete: "new-password",
  },
  saleTaxPercent: {
    name: "salesTaxPercent",
    InputComponent: TextInputWithError,
    label: "Sale Tax",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
    inputProps: {
      decimalScale: 6,
      fixedDecimalScale: true,
    },
  },
  projectContractBudget: {
    name: "contractBudget",
    InputComponent: TextInputWithError,
    label: "Contract Budget",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  useTaxPercent: {
    name: "useTaxPercent",
    InputComponent: TextInputWithError,
    label: "Use Tax",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
    inputProps: {
      decimalScale: 6,
      fixedDecimalScale: true,
    },
  },
  salesTaxTolerancePercent: {
    name: "salesTaxTolerancePercent",
    InputComponent: TextInputWithError,
    label: "Sales Tax Tolerance",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
    inputProps: {
      decimalScale: 6,
      fixedDecimalScale: true,
    },
  },
  projectFeeAmount: {
    name: "feeAmount",
    InputComponent: TextInputWithError,
    label: "Fee Amount",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  projectFeePercent: {
    name: "feePercent",
    InputComponent: TextInputWithError,
    label: "Fee %",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
  },
  projectBaseAmountLimit: {
    name: "baseAmountLimit",
    InputComponent: TextInputWithError,
    label: "Base Amount",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  projectUpperSpendLimit: {
    name: "upperSpendLimit",
    InputComponent: TextInputWithError,
    label: "Upper Spend Limit",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  projectOverLimitFee: {
    name: "overLimitFee",
    InputComponent: TextInputWithError,
    label: "Over-Limit Fee %",
    fullWidth: true,
    InputProps: {
      inputComponent: PercentageInput,
    },
  },
  projectFees: {
    name: "fees",
    InputComponent: TextInputWithError,
    label: "Fees",
    fullWidth: true,
    disabled: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  projectExpectedFeeCount: {
    name: "expectedFeeCount",
    InputComponent: TextInputWithError,
    label: "Expected Fee Count",
    fullWidth: true,
  },
  projectNumberOfPOs: {
    name: "numberOfPOs",
    InputComponent: TextInputWithError,
    label: "# of POs",
    fullWidth: true,
  },
  projectFeesPerPO: {
    name: "feesPerPurchaseOrder",
    InputComponent: TextInputWithError,
    label: "Fee/PO",
    fullWidth: true,
    InputProps: {
      inputComponent: CurrencyInput,
    },
  },
  projectFeeBillingStartMonth: {
    name: "feeBillingStartMonth",
    InputComponent: DatePickerWithError,
    label: "Fee Billing Start Month",
    fullWidth: true,
    pickerType: "monthYear",
  },
  projectFeeBillingDueDate: {
    InputComponent: RawSelectWithError,
    name: "feeBillingDueDate",
    label: "Fee Billing Due Date",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "feeBillingDueDates"),
    displayEmpty: true,
    nullAsEmpty: true,
    fullWidth: true,
  },
  contractFeeBasis: {
    InputComponent: RawSelectWithError,
    name: "feeBasis",
    label: "Fee Basis",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "feeBasis"),
    fullWidth: true,
    displayEmpty: true,
    nullAsEmpty: true,
  },
  contractBillingTerm: {
    InputComponent: RawSelectWithError,
    name: "billingTerm",
    label: "Billing Term",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "billingTerms"),
    displayEmpty: true,
    nullAsEmpty: true,
    fullWidth: true,
  },
  isFreightTaxable: {
    name: "isFreightTaxable",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Vendor Freight Taxable",
  },
  isInstallTaxable: {
    name: "isInstallTaxable",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Vendor Install Taxable",
  },
  accountingProcessingDay: {
    InputComponent: RawSelectWithError,
    name: "processingDay",
    label: "Processing Day",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "processingDays"),
    nullAsEmpty: true,
    displayEmpty: true,
    fullWidth: true,
  },
  accountingDueDate: {
    InputComponent: TextInputWithError,
    name: "accountingDueDate",
    type: "number",
    min: 1,
    max: 31,
    label: "Due Date",
    fullWidth: true,
  },
  accountingDueWeekday: {
    InputComponent: RawSelectWithError,
    label: "Processing Day",
    name: "accountingDueWeekday",
    isAutocomplete: true,
    isSingleAutocomplete: true,
    options: getOptionsFromSchema("Project", "dueWeekdays"),
    nullAsEmpty: true,
    displayEmpty: true,
    fullWidth: true,
  },
  projectVariance: {
    InputComponent: RawSelectWithError,
    name: "varianceType",
    label: "Project Variance",
    options: getOptionsFromSchema("Project", "varianceTypes"),
    fullWidth: true,
  },
  publicSubScopeNotes: {
    name: "isPublicSubScopeNotes",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Publish Scope Notes",
  },
  projectIsPositivePay: {
    name: "isPositivePay",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Positive Pay",
  },
  projectIsWaiverRequired: {
    name: "isWaiverRequired",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Lien Waiver Required",
  },
  projectWaiverFile: {
    InputComponent: FilePickerWithError,
    name: "waiverFile",
    label: "Waiver Upload",
    fullWidth: true,
    useFileWrapper: true,
    displayMenu: true,
    inputFileProps: {
      accept: ".pdf,.doc,.docx",
    },
  },
  projectIncludeInMarketingMaterials: {
    name: "includeInMarketingMaterials",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "Include in Marketing Materials",
  },
  projectShouldTotalIncludeAllSpecs: {
    name: "shouldTotalIncludeAllSpecs",
    InputComponent: CheckboxWithError,
    narrow: true,
    label: "PO Value",
  },
  projectContractFile: {
    InputComponent: FilePickerWithError,
    name: "contractFile",
    id: "contrat-file-input",
    label: "Contract Upload",
    fullWidth: true,
    useFileWrapper: true,
    inputFileProps: {
      accept: ".pdf",
    },
  },
  clientPricing: {
    InputComponent: SelectWithError,
    name: "clientPricingId",
    label: "Tier Setting",
    fullWidth: true,
    nullAsEmpty: true,
    isAutocomplete: true,
    isSingleAutocomplete: true,
    dataComponentId: "select-client-pricings",
    APIOptions: {
      model: ClientPricing,
      route: "client-pricings",
      includes: ["pricingTiers"],
      sort: [{ columnName: "version", direction: "asc" }],
      fetchOnlyOnce: true,
      omitDestroyDataComponent: true,
    },
    valueProperty: "id",
    apiFilterLabelProperty: "version",
  },
};
