import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

// eslint-disable-next-line max-lines-per-function
export default function authReducer(state = initialState.auth, action) {
  const { type, payload, error } = action;
  const reducerFuncs = {
    [actionTypes.AUTHENTICATION_REQUEST]: () => ({
      ...state,
      loadingAuthentication: true,
    }),
    [actionTypes.AUTHENTICATION_SUCCESS]: reduceAuthenticationSuccess,
    [actionTypes.AUTHENTICATION_UPDATE]: reduceAuthenticationUpdate,
    [actionTypes.AUTHENTICATION_FAILURE]: reduceAuthenticationFailure,
    [actionTypes.VERIFY_EMAIL_REQUEST]: () => ({
      ...state,
      verifyingEmail: true,
      isPasswordDefined: false,
      emailVerified: false,
    }),
    [actionTypes.VERIFY_EMAIL_SUCCESS]: (state, payload) => ({
      ...state,
      verifyingEmail: false,
      emailVerified: true,
      error: null,
      isPasswordDefined: payload.isPasswordDefined,
    }),
    [actionTypes.VERIFY_EMAIL_FAILURE]: (state, payload) => ({
      ...state,
      verifyingEmail: false,
      isPasswordDefined: false,
      error: payload,
    }),
    [actionTypes.SEND_PASSWORD_RESET_EMAIL_REQUEST]: () => ({
      ...state,
      loading: true,
      sendingPasswordResetEmail: true,
    }),
    [actionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS]: () => ({
      ...state,
      loading: false,
      sendingPasswordResetEmail: false,
    }),
    [actionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE]: (state, payload) => ({
      ...state,
      sendingPasswordResetEmail: false,
      loading: false,
      error: payload,
    }),
    [actionTypes.UPDATE_PASSWORD_REQUEST]: () => ({
      ...state,
      loading: true,
      updatingPassword: true,
    }),
    [actionTypes.UPDATE_PASSWORD_SUCCESS]: () => ({
      ...state,
      loading: false,
      updatingPassword: false,
    }),
    [actionTypes.UPDATE_PASSWORD_FAILURE]: (state, payload) => ({
      ...state,
      updatingPassword: false,
      loading: false,
      error: payload,
    }),
    [actionTypes.SEND_VERIFICATION_CODE]: (
      state,
      { verificationCodeSent, sendingVerificationCode }
    ) => ({
      ...state,
      verificationCodeSent,
      sendingVerificationCode,
    }),
    [actionTypes.SEND_VERIFICATION_CODE_FAILURE]: (state, payload) => ({
      ...state,
      verificationCodeSent: false,
      sendingVerificationCode: false,
      error: payload,
    }),
    [actionTypes.VALIDATE_VERIFICATION_CODE]: (
      state,
      { verificationCodeValidated, validatingVerificationCode }
    ) => ({
      ...state,
      verificationCodeValidated,
      validatingVerificationCode,
    }),
    [actionTypes.VALIDATE_VERIFICATION_CODE_FAILURE]: (state, payload) => ({
      ...state,
      verificationCodeValidated: false,
      validatingVerificationCode: false,
      error: payload,
    }),
    [actionTypes.CLOSE_ERROR_DIALOG]: () => ({
      ...state,
      authenticationError: false,
      error: null,
    }),
    [actionTypes.CLOSE_ERROR_DIALOG]: () => ({
      ...state,
      authenticationError: false,
      error: null,
    }),
    [actionTypes.LOGOUT_REQUEST]: () => ({
      ...state,
      loadingAuthentication: true,
    }),
    [actionTypes.LOGOUT_SUCCESS]: () => ({
      ...state,
      token: null,
      userId: null,
      name: null,
      email: null,
      loadingAuthentication: false,
    }),
    [actionTypes.UPDATE_NOTE_LAST_VISITED]: () => ({
      ...state,
      ...payload,
    }),
    [actionTypes.LOGOUT_FAILURE]: () => ({
      ...state,
      loadingAuthentication: false,
      error,
    }),
  };
  return (reducerFuncs[type] || (() => state))(state, payload);
}

function reduceAuthenticationUpdate(state, payload) {
  const {
    token,
    userId,
    name,
    email,
    cellNumber,
    phoneNumber,
    profilePicture,
    role,
    office,
    noteLastVisited,
    hasUnreadNotes,
    favoriteClientIds,
    favoriteProjectIds,
    loadingAuthentication,
    dashboardShowInClosing,
    dashboardShowClosed,
    projectsShowInClosing,
    projectsShowClosed,
    dashboardGridPreferences,
    isCellNumberVerified,
  } = { ...state, ...payload };
  return {
    ...state,
    token,
    userId,
    name,
    email,
    profilePicture,
    cellNumber,
    phoneNumber,
    role,
    office,
    noteLastVisited,
    hasUnreadNotes,
    favoriteClientIds,
    favoriteProjectIds,
    loadingAuthentication,
    dashboardShowInClosing,
    dashboardShowClosed,
    projectsShowInClosing,
    projectsShowClosed,
    dashboardGridPreferences,
    isCellNumberVerified,
  };
}

function reduceAuthenticationSuccess(state, payload) {
  const {
    token,
    userId,
    name,
    email,
    cellNumber,
    phoneNumber,
    profilePicture,
    role,
    office,
    noteLastVisited,
    hasUnreadNotes,
    favoriteClientIds,
    favoriteProjectIds,
    dashboardShowInClosing,
    dashboardShowClosed,
    projectsShowInClosing,
    projectsShowClosed,
    dashboardGridPreferences,
    isCellNumberVerified,
  } = payload;
  return {
    ...state,
    token,
    userId,
    name,
    email,
    profilePicture,
    cellNumber,
    phoneNumber,
    role,
    office,
    loadingAuthentication: false,
    noteLastVisited,
    hasUnreadNotes,
    error: null,
    favoriteClientIds,
    favoriteProjectIds,
    dashboardShowInClosing,
    dashboardShowClosed,
    projectsShowInClosing,
    projectsShowClosed,
    dashboardGridPreferences,
    isCellNumberVerified,
  };
}

function reduceAuthenticationFailure(state, payload) {
  return {
    ...state,
    token: null,
    userId: null,
    name: null,
    email: null,
    cellNumber: null,
    phoneNumber: null,
    noteLastVisited: null,
    profilePicture: null,
    hasUnreadNotes: false,
    loadingAuthentication: false,
    authenticationError: true,
    dashboardShowInClosing: false,
    dashboardShowClosed: false,
    projectsShowInClosing: false,
    projectsShowClosed: false,
    dashboardGridPreferences: null,
    isCellNumberVerified: false,
    error: payload,
  };
}
