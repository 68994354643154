import React from "react";
import PropTypes from "prop-types";
import { LabeledTextContext } from "../../../../../withPORevision";
import { FormPageSection } from "../../../../layout/FormPageSection";

import DiscountCheckboxGroup from "./DiscountCheckboxGroup";

export const fields = discountOptions => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              InputComponent: DiscountCheckboxGroup,
              label: "Discounts",
              name: "purchaseOrderDiscounts",
              options: discountOptions,
              emptyValue: "true",
            },
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
const DiscountContainer = props => {
  const { discounts = [], ...formikProps } = props;
  if (discounts.length === 0) {
    return null;
  }
  return (
    <LabeledTextContext.Provider value={false}>
      <FormPageSection
        fields={fields(discounts)}
        headerText="Discounts"
        {...formikProps}
      />
    </LabeledTextContext.Provider>
  );
};
DiscountContainer.propTypes = {
  discounts: PropTypes.array.isRequired,
  formikProps: PropTypes.object.isRequired,
};
DiscountContainer.defaultProps = {
  discounts: [],
  formikProps: {},
};

export default DiscountContainer;
