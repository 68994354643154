/* eslint-disable import/default */
import "regenerator-runtime/runtime";
import React from "react";

import Joi from "joi-browser";
import BWModels from "benjaminwest-models";

BWModels.init(Joi);

import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";

import store, { history } from "./store/store";
import Root from "./Root";

import "../node_modules/flexboxgrid/css/flexboxgrid.min.css";

require("./favicon.ico"); // Tell webpack to load favicon.ico

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./Root", () => {
    const NewRoot = require("./Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
