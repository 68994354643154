import { BaseModel } from "@blue-chip/core";
import Project from "./Project";
import User from "./User";
import PurchaseOrder from "./PurchaseOrder";
import Shipment from "./Shipment";
import Spec from "./Spec";
import Tag from "./Tag";
import Invoice from "./Invoice";

export default class Note extends BaseModel {
  static get belongsTo() {
    return [Project];
  }

  static get hasMany() {
    return [PurchaseOrder, Shipment, Spec, Tag, User, Invoice];
  }
}
