import React from "react";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import AddCircle from "@material-ui/icons/AddCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";

import { Paper } from "../../../mui/core";
import Team from "../../../../models/Team";
import propTypes from "../../../../constants/propTypes";

const columns = ["name", "numberOfUsers"];
export const columnOptions = {
  name: { title: "Name", bold: true, filter: true },
  numberOfUsers: { title: "Number Of Users", bold: true },
};
const rowMenu = ({ onOpenEditModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Team",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const TeamsPage = ({ dataComponent, onOpenCreateModal, onOpenEditModal }) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Team}
        apiRoute="teams"
        apiFilters={{
          sort: [{ columnName: "name" }],
        }}
        includes={["users"]}
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Teams`}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu({ onOpenEditModal })}
        />
      </BWGridAPI>
    </Paper>
  );
};

TeamsPage.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
};

export default TeamsPage;
