import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Popover, List, ListItem, ListItemText } from "@material-ui/core";
import { QuoteViewByContext } from "./withQuoteViewByControl";
import styled from "styled-components";

export const StyledListItem = styled(ListItem)`
  padding-right: 92px;
  cursor: pointer;
`;
const CurrentViewSelect = ({ anchorEl, open, onClose }) => {
  const { handleCurrentViewChanged } = useContext(QuoteViewByContext);

  const handleListItemClicked = view => {
    onClose();
    handleCurrentViewChanged(view);
  };
  return (
    <Popover
      id="bg-vendors-quote-view-by-select"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List width={200}>
        <StyledListItem button onClick={() => handleListItemClicked("Vendors")}>
          <ListItemText primary="Vendors" />
        </StyledListItem>
        <StyledListItem button onClick={() => handleListItemClicked("Specs")}>
          <ListItemText primary="Specs" />
        </StyledListItem>
      </List>
    </Popover>
  );
};

CurrentViewSelect.propTypes = {
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default CurrentViewSelect;
