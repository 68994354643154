import { useDatacomponent } from "../../../../hooks/useDatacomponent";
import { onStatusUpdateSuccess } from "../../../../../actions/shippingPaymentsActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";

import useActions from "../../../../hooks/useActions";
import { unbillShippingPaymentsDataComponentId } from "./ShippingPaymentsActions";

export const useProcessRequest = ({ projectId }) => {
  const unbillShippingPaymentsDataComponent = useDatacomponent(
    unbillShippingPaymentsDataComponentId
  );
  const actions = useActions({
    onStatusUpdateSuccess,
  });
  useUpdateProcessRequests(unbillShippingPaymentsDataComponent, {
    onSuccess: () => actions.onStatusUpdateSuccess(projectId, "Unbilled"),
  });
};
