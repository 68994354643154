import _debounce from "lodash/debounce";
import { useState, useEffect } from "react";

function useDebounce(value, delay, options = {}) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handleChange = _debounce(
      () => {
        setDebouncedValue(value);
      },
      delay,
      options
    );
    handleChange();
  }, [delay, options, value]);

  return debouncedValue;
}

export default useDebounce;
