import React from "react";
import PropTypes from "prop-types";

import Item from "./Item";

const DraggableList = ({ rooms, onRemove, onEdit, errors, touched }) =>
  rooms.map((room, key) => (
    <Item
      room={room}
      onRemove={onRemove}
      onEdit={onEdit}
      key={key}
      index={key}
      errors={errors}
      touched={touched}
    />
  ));
DraggableList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      roomCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      isEditing: PropTypes.bool,
    })
  ).isRequired,
};

export default DraggableList;
