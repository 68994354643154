import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import RegularText from "../../../../../ui/Typography/RegularText";
import ContactActions from "../ContactActions";
import BoldText from "../../../../../ui/Typography/BoldText";
import { BWActionButton } from "../../../../../ui/BWActionButton";
import InputLabel from "../../../../../mui/core/InputLabel";
import LocationCombined from "../../../../../ui/LocationCombined";
import propTypes from "../../../../../../constants/propTypes";
import Vendor from "../../../../../../models/Vendor";

export const VendorItemContainer = styled(({ isDisabled, ...props }) => (
  <ListItem {...props} />
))`
  padding: 8px 24px;
  white-space: normal;
  &:hover {
    background-color: #f5f5f5;
  }
  ${RegularText} {
    display: block;
  }
  &:hover ${ContactActions} {
    opacity: 1;
  }
  ${BWActionButton} {
    background: none !important;
    padding-left: 0;
    padding-bottom: 0;
  }
  ${InputLabel} {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
  ${BoldText} {
    display: inline-block;
    ${({ isDisabled }) =>
      isDisabled ? "font-weight: normal; color: grey;" : ""}
  }
`;

export const ItemBody = ({ vendor }) => (
  <LocationCombined location={vendor.location} />
);
ItemBody.propTypes = {
  vendor: propTypes.vendor,
};

export const ItemPrimary = ({ vendor }) => (
  <InputLabel>
    <BoldText>{Vendor.labelProperty(vendor)}</BoldText>
  </InputLabel>
);
ItemPrimary.propTypes = {
  vendor: propTypes.vendor,
};

export const VendorItem = ({ onClick, vendor, primProps }) => {
  const isDisabled = Vendor.isVendorDisabled(vendor);
  return (
    <VendorItemContainer
      onClick={isDisabled ? null : onClick}
      isDisabled={isDisabled}
    >
      <ListItemText
        primary={<ItemPrimary {...primProps} vendor={vendor} />}
        secondary={<ItemBody vendor={vendor} />}
      />
    </VendorItemContainer>
  );
};

VendorItem.propTypes = {
  vendor: propTypes.vendor,
  onClick: PropTypes.func.isRequired,
};

export default VendorItem;
