import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import LocalOffer from "@material-ui/icons/LocalOffer";

import NavigationBarTab from "../../../layout/NavigationBar/NavigationBarTab";
import Counter from "../../../ui/Counter";
import withRequestListener, {
  useHasRequestActive,
} from "../../../hocs/withRequestListener";

export const PurchaseOrderTab = ({ counter, onUpdate }) => {
  const hasActiveRequest = useHasRequestActive("pos");
  const prevActiveRequest = useRef(false);

  useEffect(() => {
    if (prevActiveRequest.current && !hasActiveRequest) {
      onUpdate();
    }
    prevActiveRequest.current = hasActiveRequest;
  }, [hasActiveRequest, onUpdate]);

  return (
    <NavigationBarTab>
      <LocalOffer />
      Purchase Orders
      <Counter value={counter} />
    </NavigationBarTab>
  );
};

PurchaseOrderTab.propTypes = {
  project: PropTypes.object,
  counter: PropTypes.number,
  onUpdate: PropTypes.func,
};

export default withRequestListener(PurchaseOrderTab);
