import BWModels from "benjaminwest-models";

export const getColumns = (workScopes, billingTermFilter, feeSubScopes) => [
  {
    name: "project.projectLead.name",
    title: "Project Lead",
    width: "10vw",
    filter: true,
  },
  {
    name: "project.number",
    title: "Project Number",
    width: "8vw",
    filter: true,
  },
  {
    name: "project.name",
    title: "Project Name",
    width: "17vw",
    filter: true,
  },
  {
    name: "workScope.id",
    title: "Scope",
    width: "14vw",
    minWidth: "120px",
    filter: "multiselect",
    filterOptions: { options: workScopes, operator: "in" },
  },
  {
    name: "feeSubScope.name",
    title: "Sub Scope",
    minWidth: "150px",
    filter: "select",
    filterOptions: {
      options: feeSubScopes.map(({ name }) => ({ id: name, name })),
    },
  },
  {
    name: "billingTerm",
    filter: true,
    filterOptions: {
      options: BWModels.loadSchema("Fee").__utils.billingTerms,
      operator: billingTermFilter,
    },
  },
  { name: "billingDate", filter: "picker" },
  {
    name: "amount",
    title: "Fee Amount",
    width: "5vw",
    filter: true,
    filterOptions: { operator: "equal" },
  },
];
