/* eslint-disable import/no-named-as-default */
import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";

import Routes from "./Routes";
import CacheBuster from "./CacheBuster";

const generateClassName = createGenerateClassName();

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

export const validateLoadingAndRender = (
  loading,
  isLatestVersion,
  refreshCacheAndReload
) => {
  if (loading) {
    return null;
  }
  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
  }
  return (
    <StylesProvider injectFirst generateClassName={generateClassName}>
      <Routes />
    </StylesProvider>
  );
};

export default class App extends Component {
  static propTypes = {
    children: PropTypes.element,
  };
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          return validateLoadingAndRender(
            loading,
            isLatestVersion,
            refreshCacheAndReload
          );
        }}
      </CacheBuster>
    );
  }
}
