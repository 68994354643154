import React, { useContext, useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _keyBy from "lodash/keyBy";

import Grid from "../Grid";
import { operationBuildersAPI } from "../../BWGrid/filterFunctions";
import { LoaderContext } from "../../Loader";
import { WithPaginationContext } from "../../../../withPagination";
import { useInitGridStatus, useReloadEffects } from "./gridAPIEffects";
import useGridAPICallbacks from "./useGridAPICallbacks";
import useSelection from "./useSelection";
import { useSelectedRowAPIContext } from "../../BWGrid/withSelectedRowsAPI";

const GridAPI = ({
  dataComponent,
  actions,
  rows,
  columns,
  columnBands,
  columnExtensions,
  dataTypeProviders,
  fixedColumns,
  filteringBuilders,
  filteringDataTypeProviders,
  tableComponents,
  pagingPanelProps,
  defaultSorting,
  apiFilters,
  reload,
  pageNumber,
  rowProps,
  showSelectAll,
  showSelectionColumn,
  dxGridProps,
}) => {
  const prevReferences = useRef({
    defaultSorting: [],
    apiFilters: {},
    selectedFilters: {},
  });
  const [shouldReload, setShouldReload] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selection, generateSelection] = useSelection(
    dataComponent.selectedIds,
    rows
  );
  const context = useContext(WithPaginationContext);
  const pageSize = context.getPageSize(dataComponent.dataComponentId);

  useInitGridStatus(
    actions,
    { setShouldReload },
    {
      selectedFilters,
      reload,
      prevReferences,
      defaultSorting,
      apiFilters,
      pageSize,
    }
  );

  const {
    onRetrieveList,
    onSetSort,
    onSetPage,
    onSetPageSize,
    onFiltersChange,
    onSelection,
  } = useGridAPICallbacks(
    actions,
    { setShouldReload, setSelectedFilters },
    { context, dataComponent, pageSize, rows, pageNumber, generateSelection }
  );

  useReloadEffects(
    actions,
    { setShouldReload, onRetrieveList, onSetPage, onSetSort },
    {
      shouldReload,
      rows,
      selectedFilters,
      defaultSorting,
      apiFilters,
      pageNumber,
      prevReferences,
      context,
      dataComponent,
    }
  );

  const { updateSelectedRows } = useSelectedRowAPIContext(
    dataComponent.dataComponentId
  );
  useEffect(() => {
    updateSelectedRows(rows, dataComponent.selectedIds, pageNumber);
  }, [dataComponent.selectedIds, pageNumber, rows, updateSelectedRows]);

  const filterProps = useMemo(() => {
    const displayFilters = columns.some(column => column.filter);
    return {
      id: dataComponent.dataComponentId,
      displayFilters,
      columnOptions: _keyBy(columns, "name"),
      onFiltersChange,
      operationBuilders: filteringBuilders,
    };
  }, [
    columns,
    dataComponent.dataComponentId,
    onFiltersChange,
    filteringBuilders,
  ]);

  const pagingStateProps = {
    currentPage: pageNumber,
    pageSize,
    onCurrentPageChange: onSetPage,
    onPageSizeChange: onSetPageSize,
    total: dataComponent.totalRows,
  };

  const sortingStateProps = {
    sorting: dataComponent.sort,
    onSortingChange: onSetSort,
  };

  const selectionStateProps = {
    setSelection: onSelection,
    selection: selection,
  };

  return (
    <LoaderContext.Provider value={{ loading: dataComponent.loading }}>
      <Grid
        dxGridProps={dxGridProps}
        gridId={dataComponent.dataComponentId}
        rows={rows}
        columns={columns}
        columnBands={columnBands}
        columnExtensions={columnExtensions}
        dataTypeProviders={dataTypeProviders}
        fixedColumns={fixedColumns}
        filteringBuilders={filteringBuilders}
        filteringDataTypeProviders={filteringDataTypeProviders}
        tableComponents={tableComponents}
        pagingPanelProps={pagingPanelProps}
        filterProps={filterProps}
        pagingStateProps={pagingStateProps}
        rowProps={rowProps}
        sortingStateProps={sortingStateProps}
        selectionStateProps={selectionStateProps}
        showSelectAll={showSelectAll}
        showSelectionColumn={showSelectionColumn}
      />
    </LoaderContext.Provider>
  );
};

GridAPI.defaultProps = {
  rows: [],
  columns: [],
  filteringBuilders: operationBuildersAPI,
};

GridAPI.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  showSelectionColumn: PropTypes.bool,
  hidePaginationControl: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  hideFooter: PropTypes.bool,
  apiFilters: PropTypes.object,
  requestState: PropTypes.object,
  reload: PropTypes.bool,
  defaultLoading: PropTypes.bool,
  rowProps: PropTypes.shape({
    onClick: PropTypes.func,
    getRowTitle: PropTypes.func,
    title: PropTypes.string,
  }),
  defaultSorting: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    })
  ),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  pageNumber: PropTypes.number,
  actions: PropTypes.shape({
    fetchData: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    setSelection: PropTypes.func.isRequired,
    performRetrieveListRequest: PropTypes.func.isRequired,
    setReload: PropTypes.func.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired,
  }),
  apiRoute: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })
  ),
  columnBands: PropTypes.arrayOf(PropTypes.shape({})), // examples at: https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table-band-header/
  columnExtensions: PropTypes.arrayOf(
    PropTypes.shape({ columnName: PropTypes.string, width: PropTypes.string }) // https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table/#tablecolumnextension
  ),
  dataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  filteringBuilders: PropTypes.objectOf(PropTypes.func),
  filteringDataTypeProviders: PropTypes.arrayOf(PropTypes.func),
  tableComponents: PropTypes.objectOf(PropTypes.func),
  fixedColumns: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.string),
    right: PropTypes.arrayOf(PropTypes.string),
  }),
  pagingPanelProps: PropTypes.shape({
    hidePaginationControl: PropTypes.bool,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  dxGridProps: PropTypes.shape({}),
};

export default GridAPI;
