import { useMemo } from "react";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../constants/RequestTypes";

export const useDatacomponent = dataComponentId => {
  const selector = useMemo(() => {
    return createSelector(
      state => state,
      state => getDataComponent(dataComponentId, state)
    );
  }, [dataComponentId]);

  return useSelector(selector);
};

export const useFlattenedDatacomponent = (dataComponentId, requestType) => {
  const selector = useMemo(() => {
    return createSelector(
      state => state,
      state =>
        getDataComponentFlattenedRequestState(
          dataComponentId,
          state,
          requestType
        )
    );
  }, [dataComponentId, requestType]);

  return useSelector(selector);
};

export const useLoading = (ids = [], requestTypeObj = REQUEST_TYPES) => {
  const dataComponentIds = Array.isArray(ids) ? ids : [ids];
  const selector = useMemo(() => {
    return createSelector(
      state => state,
      state => {
        return dataComponentIds.some(dataComponentId => {
          const dataComponent = getDataComponent(dataComponentId, state);
          return Object.values(requestTypeObj).some(
            requestType => dataComponent.requestState[requestType]?.loading
          );
        });
      }
    );
  }, [dataComponentIds, requestTypeObj]);
  return useSelector(selector);
};
