import { connect } from "react-redux";
import { importShippingPaymentsFromXls } from "../../../../../actions/shippingPaymentsActions";
import { ImportShippingPayments } from "./ImportShippingPayments";
import {
  closeModalDialog,
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import {
  performRetrieveListRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

const mapDispatchToProps = {
  importShippingPaymentsFromXls,
  closeModalDialog,
  openModalDialog,
  setReload,
  showSnackNotificationAction,
  performRetrieveListRequest,
};

export default connect(null, mapDispatchToProps)(ImportShippingPayments);
