import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import propTypes from "../../../../constants/propTypes";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import {
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const dataComponentId = "PropertiesGrid";

export class DeletePropertyContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleDeleteRequestSuccess,
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestSuccess = () => {
    const { closeModalDialog, setReload } = this.props;

    setReload(dataComponentId, true);
    closeModalDialog();
  };

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteProperty = () => {
    const { propertyId, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, propertyId);
  };

  render() {
    const { title } = this.props;

    return <DeleteConfirmation title={title} onDelete={this.deleteProperty} />;
  }
}

DeletePropertyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  setReload: PropTypes.func.isRequired,
  propertyId: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  showSnackNotificationAction,
  performDeleteRequest,
  closeModalDialog,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePropertyContainer);
