// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ClientPricing model belongsTo relations 1`] = `
Array [
  "Client",
]
`;

exports[`ClientPricing model hasMany relations 1`] = `
Array [
  "PricingTier",
]
`;
