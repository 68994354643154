import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CheckCircle from "@material-ui/icons/CheckCircle";

import {
  FormWrapper,
  ApproveButtonWrapper,
  ApprovalBanner,
} from "./components";
import UploadSubmittal from "../../../SubmittalsManager/RequirementsGrid/UploadSubmittal";
import RegularText from "../../../../ui/Typography/RegularText";
import propTypes from "../../../../../constants/propTypes";
import ApproveButton from "./ApproveButton";

const EditSubmittal = ({
  submittal,
  specRequirement,
  onSubmit,
  onCancel,
  onApprove,
  loaderActive,
  disableSelectFile,
}) => {
  const isApproval = specRequirement.submittalApprovedId === submittal.id;
  return (
    <Fragment>
      {isApproval && (
        <ApprovalBanner>
          <CheckCircle /> <RegularText as="span">Approval File</RegularText>
        </ApprovalBanner>
      )}
      <FormWrapper>
        <UploadSubmittal
          initialValues={submittal}
          specRequirement={specRequirement}
          onSubmit={onSubmit}
          onCancel={onCancel}
          actionButtonsProps={{
            additionalProps: { send: { isLoading: loaderActive === "edit" } },
            children: (
              <ApproveButtonWrapper>
                <ApproveButton
                  onClick={() => onApprove(isApproval ? null : submittal)}
                  loading={loaderActive === "approve"}
                >
                  {isApproval ? "Remove Approval" : "Approve requirement"}
                </ApproveButton>
              </ApproveButtonWrapper>
            ),
          }}
          disableSelectFile={disableSelectFile}
        />
      </FormWrapper>
    </Fragment>
  );
};

EditSubmittal.propTypes = {
  specRequirement: propTypes.specRequirement,
  submittal: propTypes.submittalFile,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onApprove: PropTypes.func,
  loaderActive: PropTypes.string,
  disableSelectFile: PropTypes.bool,
};

export default EditSubmittal;
