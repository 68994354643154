import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";

export const CheckboxManagerContext = createContext(null);

export const useCheckboxManager = () => useContext(CheckboxManagerContext);

const CheckboxManager = ({ children }) => {
  const ids = useRef([]);
  const [checkedIds, setCheckedIds] = useState({});

  const registerElement = useCallback(itemId => {
    ids.current.push(itemId);
  }, []);

  const removeElement = useCallback(itemId => {
    ids.current.splice(ids.current.indexOf(itemId), 1);
  }, []);

  const setCheckStatus = useCallback(
    (itemId, checked) => {
      setCheckedIds({ ...checkedIds, [itemId]: checked });
    },
    [checkedIds]
  );

  const setAllStatus = useCallback(checked => {
    setCheckedIds(
      ids.current.reduce((idsMap, id) => {
        idsMap[id] = checked;
        return idsMap;
      }, {})
    );
  }, []);

  const isChecked = useCallback(itemId => !!checkedIds[itemId], [checkedIds]);

  const isAllChecked = useCallback(() => {
    const registeredIds = ids.current;
    return (
      registeredIds.length > 0 && registeredIds.every(id => !!checkedIds[id])
    );
  }, [checkedIds]);

  const getSelectedIds = useCallback(() => {
    return ids.current.filter(id => !!checkedIds[id]);
  }, [checkedIds]);

  return (
    <CheckboxManagerContext.Provider
      value={{
        checkedIds,
        registerElement,
        removeElement,
        isChecked,
        isAllChecked,
        setCheckStatus,
        setAllStatus,
        getSelectedIds,
      }}
    >
      {children}
    </CheckboxManagerContext.Provider>
  );
};

CheckboxManager.propTypes = {
  children: PropTypes.any,
};

export default CheckboxManager;
