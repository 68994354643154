import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
  openModalDialog,
  dismissSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { navigateToAreaDetailPage } from "../../../../actions/areasActions";
import AreasSectionLayout from "./AreasSectionLayout";
import { FieldArray } from "formik";

const buildOpenDeleteAreaHandler = (
  openModalDialog,
  formikArrayHelpers,
  onDeleteArea
) => ({ id, name }, { rowId }) => {
  openModalDialog(
    `Delete ${name}`,
    "DeleteArea",
    {
      areaId: id,
      title: name,
      formikArrayHelpers,
      index: rowId,
      onDeleteArea,
    },
    false
  );
};

const buildOpenEditAreaHandler = (
  openModalDialog,
  formikArrayHelpers,
  onUpdateArea
) => (area, { rowId }) => {
  openModalDialog(`Edit Area ${area.name}`, "CreateAreaLocal", {
    formikArrayHelpers,
    area,
    index: rowId,
    onUpdateArea,
  });
};

export class AreasSectionContainer extends Component {
  static propTypes = {
    openModalDialog: PropTypes.func.isRequired,
    values: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string,
        clientId: PropTypes.string,
      }),
    }),
    areaTypes: PropTypes.array.isRequired,
    onCreateArea: PropTypes.func.isRequired,
    onDeleteArea: PropTypes.func.isRequired,
    onUpdateArea: PropTypes.func.isRequired,
    navigateToAreaDetailPage: PropTypes.func.isRequired,
    dismissSnackNotificationAction: PropTypes.func,
  };

  createComponent = "CreateAreaLocal";

  handleAddAreaClick = (formikArrayHelpers, onCreateArea) => {
    this.props.openModalDialog(
      "Create Area",
      this.createComponent,
      {
        formikArrayHelpers,
        onCreateArea,
      },
      true,
      false
    );
  };

  handleGoAreaDetailPage = () => {
    const { clientId, projectId } = this.props.match.params;
    if (!projectId) return;
    return ({ id }) => {
      this.props.navigateToAreaDetailPage(clientId, projectId, id);
    };
  };

  name = "areas";

  render() {
    const {
      values,
      areaTypes,
      openModalDialog,
      onCreateArea,
      onUpdateArea,
      onDeleteArea,
      dismissSnackNotificationAction,
    } = this.props;

    return (
      <FieldArray
        name={this.name}
        render={formikArrayHelpers => (
          <AreasSectionLayout
            dismissSnackNotificationAction={dismissSnackNotificationAction}
            rows={values[this.name]}
            areaTypes={areaTypes}
            onRowClick={this.handleGoAreaDetailPage()}
            onOpenAddModal={() =>
              this.handleAddAreaClick(formikArrayHelpers, onCreateArea)
            }
            onOpenEditModal={buildOpenEditAreaHandler(
              openModalDialog,
              formikArrayHelpers,
              onUpdateArea
            )}
            onOpenDeleteModal={buildOpenDeleteAreaHandler(
              openModalDialog,
              formikArrayHelpers,
              onDeleteArea
            )}
          />
        )}
      />
    );
  }
}

export const mapDispatchToProps = {
  openModalDialog,
  navigateToAreaDetailPage,
  dismissSnackNotificationAction,
};

export default withRouter(
  connect(null, mapDispatchToProps)(AreasSectionContainer)
);
