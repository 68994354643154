import PurchaseOrder from "../../../../../models/PurchaseOrder";
import { inputs } from "../../../../inputs/inputConfigs";

export const getFields = (projectId, quoteProjectCurrencyId) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.purchaseOrderId,
            dataComponentId: "select-draft-purchase-orders",
            required: true,
            isSingleAutocomplete: true,
            isAutocomplete: true,
            APIOptions: {
              model: PurchaseOrder,
              route: "purchase-orders",
              sort: [{ columnName: "number", direction: "asc" }],
              fetchOnlyOnce: true,
              rootFilters: {
                $where: {
                  projectId,
                  projectCurrencyId: quoteProjectCurrencyId,
                  $or: [
                    {
                      status: "Draft",
                    },
                    {
                      revisionStatus: {
                        $exists: true,
                      },
                      status: "Issued",
                    },
                  ],
                },
              },
            },
            defaultOption: {
              id: "0",
              number: "New Purchase Order",
            },
            valueProperty: "id",
            labelProperty: purchaseOrderLabelProperty,
            apiFilterLabelProperty: "number",
            fullWidth: true,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

export const purchaseOrderLabelProperty = ({
  number,
  status,
  revisionStatus,
}) => {
  if (!status) {
    return number;
  }

  const changeOrderStatus = revisionStatus ? ` - CO ${revisionStatus}` : "";
  return `${number} (${status}${changeOrderStatus})`;
};
