import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../../ui/DeleteConfirmation";

const dataComponentId = "DiscountsGrid";

export class DeleteDiscountContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog, setReload } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(dataComponentId, true);
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDeleteGlCode = () => {
    const { discountId, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, discountId);
  };

  render() {
    const { title } = this.props;
    return (
      <DeleteConfirmation title={title} onDelete={this.handleDeleteGlCode} />
    );
  }
}

DeleteDiscountContainer.propTypes = {
  title: PropTypes.string.isRequired,
  discountId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteDiscountContainer);
