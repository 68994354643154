import React from "react";
import PropTypes from "prop-types";

import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";

export const SpecShipmentsActions = ({ actions }) => (
  <DropdownMenu buttonText="Actions" options={actions} />
);

SpecShipmentsActions.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default SpecShipmentsActions;
