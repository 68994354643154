import React from "react";

import FilePickerWithError from "../../../inputs/FilePickerWithError";
import { useFormikContext } from "formik";

export const MSAContractFileInput = ({ ...props }) => {
  const { values } = useFormikContext();

  if (!values.msaClient) {
    return null;
  }

  return <FilePickerWithError {...props} hideDeleteOption />;
};
