import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { processDeleteRequestStatus } from "../../../../../../utils/dataComponentUtils";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../../actions/layoutActions";
import {
  performDeleteRequest,
  initDataComponent,
} from "../../../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../../../ui/DeleteConfirmation";

export class DeleteAddressContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      formikArrayHelpers,
      index,
    } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        formikArrayHelpers.remove(index);
        closeModalDialog();
      },
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDelete = () => {
    const {
      addressId,
      performDeleteRequest,
      index,
      formikArrayHelpers,
      closeModalDialog,
      dataComponentId,
    } = this.props;
    if (addressId) {
      return performDeleteRequest(dataComponentId, addressId);
    }
    formikArrayHelpers.remove(index);
    closeModalDialog();
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.handleDelete} />;
  }
}

DeleteAddressContainer.propTypes = {
  title: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  addressId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.number,
};

export const mapStateToProps = (state, { dataComponentId }) => ({
  dataComponent: getDataComponent(dataComponentId, state),
});

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  showSnackNotificationAction,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAddressContainer);
