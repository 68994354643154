import styled from "styled-components";

import OriginalSubHeader from "../../../../ui/Typography/Subheader";
import OriginalSerifTitle from "../../../../ui/Typography/SerifTitle";
import BaseText from "../../../../ui/Typography/BaseText";
import { COMPreviewContainer } from "../FilePreview";

export const TopDetailWrapper = styled.div`
  background-color: #27587a;
  color: #ffffff;
  padding: 12px 24px 24px;
  width: 100%;
  ${COMPreviewContainer} {
    margin: 0;
    width: 121px;
    height: 121px;
  }
`;

export const RelatedSpecsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.54);
  padding: 24px 24px 0;
  width: 100%;
`;

export const SubHeader = styled(OriginalSubHeader)`
  color: rgba(255, 255, 255, 0.7);
  margin: 4px 0;
`;

export const SerifTitle = styled(OriginalSerifTitle)`
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  margin: 4px 0 16px;
`;

export const Text = styled(BaseText)`
  color: #ffffff;
  line-height: 24px;
  font-size: 14px;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
  width: 120px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-height: 160px;
  overflow-y: auto;
`;
