import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import RootRef from "@material-ui/core/RootRef";
import MUIList from "@material-ui/core/List";

import DraggableList from "./DraggableList";
import RegularText from "../../../../../ui/Typography/RegularText";
import PropTypes from "prop-types";

const ListWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 8px;
`;

const EmptyState = styled(RegularText)`
  text-align: center;
  padding: 48px 0;
`;

const StyledList = styled(MUIList)`
  height: 344px;
  max-height: 344px;
  overflow: auto;
  padding: 0;
`;

const List = ({ onReorder, ...listProps }) => {
  const {
    values: { specCategoryDetails = [] },
  } = listProps;
  return (
    <ListWrapper>
      {specCategoryDetails.length > 0 ? (
        <DragDropContext onDragEnd={onReorder}>
          <Droppable droppableId="details">
            {provided => (
              <RootRef rootRef={provided.innerRef}>
                <StyledList {...provided.droppableProps}>
                  <DraggableList {...listProps} />
                  {provided.placeholder}
                </StyledList>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <EmptyState intent="light">No Sections Created</EmptyState>
      )}
    </ListWrapper>
  );
};

List.propTypes = {
  ...DraggableList.propTypes,
  onReorder: PropTypes.func.isRequired,
};

export default List;
