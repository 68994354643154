import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import { performDeleteRequest } from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const dataComponentId = "ConsultingCompanies";

export class DeleteConsultingCompanyContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteConsultingCompany = () => {
    const { consultingCompanyId, performDeleteRequest } = this.props;

    performDeleteRequest(dataComponentId, consultingCompanyId);
  };

  render() {
    const { title } = this.props;

    return (
      <DeleteConfirmation
        title={title}
        onDelete={this.deleteConsultingCompany}
      />
    );
  }
}

DeleteConsultingCompanyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  consultingCompanyId: PropTypes.string.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponent(dataComponentId, state),
});

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConsultingCompanyContainer);
