import React, { Fragment, useCallback, useState } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { ActionButtons } from "../../../forms";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import ModalTitle from "../../../layout/AppLayout/ModalDialog/ModalTitle";
import { DefaultContent } from "../../../layout/AppLayout/ModalDialog/components";
import RegularText from "../../../ui/Typography/RegularText";

const Message = styled(RegularText)`
  padding: 24px;
`;

export const RequiresApprovalActionButton = ({
  handleCancel,
  dataComponentId,
}) => {
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    values: { vendorId },
  } = useFormikContext();

  const vendors = useV2DatacomponentResources(
    "select-vendors",
    [],
    REQUEST_TYPES.LIST
  );

  const handleSend = useCallback(() => {
    const selectedVendor = vendors.find(({ id }) => id == vendorId);
    if (selectedVendor.status?.includes("requires approval")) {
      setOpen(true);
      return;
    }

    handleSubmit();
  }, [handleSubmit, vendorId, vendors]);

  const handleClose = useCallback(() => setOpen(false), []);

  const width = "325px";
  return (
    <Fragment>
      <Dialog open={open}>
        <ModalTitle width={width} title={"Selected Vendor Requires Approval"} />
        <DefaultContent width={width}>
          <Message>Are you sure?</Message>
        </DefaultContent>
        <ActionButtons
          onSend={handleSubmit}
          sendButtonText="CONFIRM"
          onCancel={handleClose}
          listeners={[dataComponentId]}
          isModal
        />
      </Dialog>
      <ActionButtons
        onSend={handleSend}
        sendButtonText="CREATE"
        onCancel={handleCancel}
        listeners={[dataComponentId]}
        isModal
      />
    </Fragment>
  );
};
RequiresApprovalActionButton.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
};
