import { connect } from "react-redux";
import _get from "lodash/get";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { importShippingPaymentsDataComponentId } from "../../../../actions/shippingPaymentsActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { openModalDialog } from "../../../../actions/layoutActions";
import { Grid } from "./Grid";
import { shippingPaymentsFileKey } from "./ImportShippingPayments/ImportShippingPayments";

const mapStateToProps = state => {
  const importDataComponent = getDataComponent(
    importShippingPaymentsDataComponentId,
    state
  );

  return {
    loading:
      state.files.loaders[shippingPaymentsFileKey] ||
      _get(importDataComponent, `requestState.${REQUEST_TYPES.IMPORT}.loading`),
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  openModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
