import { useCallback, useEffect } from "react";
import _isEqual from "lodash/isEqual";

import Vendor from "../../../../../../models/Vendor";
import VendorShipToSite from "../../../../../../models/VendorShipToSite";
import ProjectSite from "../../../../../../models/ProjectSite";
import { processUpdateRequestStatus } from "../../../../../../utils/dataComponentUtils";
import useScope from "../../../../../hooks/useScope";

const initDataComponentSelects = initDataComponent => () => {
  initDataComponent("select-vendors", Vendor, [], "vendors");
  initDataComponent(
    "select-vendors-sites",
    VendorShipToSite,
    ["location"],
    "vendor-ship-to-sites",
    true,
    "v2"
  );
  initDataComponent(
    "select-project-sites",
    ProjectSite,
    ["location"],
    "project-sites"
  );
};

const fetchSelects = (projectId, performRetrieveListRequest, scopeId) => () => {
  performRetrieveListRequest("select-vendors", {
    rootFilters: {
      $where: {
        "scope.id": scopeId,
      },
    },
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
    fields: ["vendors.id", "name", "status", "scopeId"],
  });
  performRetrieveListRequest("select-vendors-sites", {
    sort: [{ columnName: "name", direction: "asc" }],
    pageSize: -1,
  });
  performRetrieveListRequest("select-project-sites", {
    sort: [{ columnName: "name", direction: "asc" }],
    rootFilters: {
      $where: {
        projectId,
      },
    },
    pageSize: -1,
  });
};

const processRequestStatus = (
  dataComponent,
  closeModalDialog,
  prevDataComponent
) => () => {
  processUpdateRequestStatus(prevDataComponent.current, dataComponent, {
    onSuccess: closeModalDialog,
  });
  prevDataComponent.current = dataComponent;
};

export const useEffects = (
  projectId,
  dataComponent,
  prevDataComponent,
  initDataComponent,
  performRetrieveListRequest,
  closeModalDialog
) => {
  useEffect(initDataComponentSelects(initDataComponent), [initDataComponent]);
  const scope = useScope();
  useEffect(fetchSelects(projectId, performRetrieveListRequest, scope?.id), [
    projectId,
    performRetrieveListRequest,
  ]);

  useEffect(
    processRequestStatus(dataComponent, closeModalDialog, prevDataComponent),
    [dataComponent, closeModalDialog, prevDataComponent]
  );
};

export const buildHandleSubmit = (
  closeModalDialog,
  purchaseOrder,
  setFieldValue
) => values => {
  if (_isEqual(purchaseOrder, values)) {
    return closeModalDialog();
  }
  const {
    shipToSite,
    shipAddressId,
    shipVendorId,
    shipVendorAddressId,
  } = values;
  setFieldValue("shipToSite", shipToSite);
  if (shipToSite) {
    setFieldValue("shipAddressId", shipAddressId);
    setFieldValue("shipVendorId", null);
    setFieldValue("shipVendorAddressId", null);
  } else {
    setFieldValue("shipAddressId", null);
    setFieldValue("shipVendorId", shipVendorId);
    setFieldValue(
      "shipVendorAddressId",
      shipVendorAddressId != -1 ? shipVendorAddressId : null
    );
  }
};

export const useCallbacks = (
  setFieldValue,
  closeModalDialog,
  purchaseOrder
) => {
  const handleSubmit = useCallback(
    buildHandleSubmit(closeModalDialog, purchaseOrder, setFieldValue),
    [closeModalDialog, purchaseOrder, setFieldValue]
  );

  return [handleSubmit];
};
