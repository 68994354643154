import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";

import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import Project from "../../../../models/Project";
import Projects from "./Projects";

export const dataComponentId = "ConsultingCompanyProjectsGrid";
export class ProjectsContainer extends Component {
  static propTypes = {
    consultingCompanyId: PropTypes.string,
    dataComponent: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Projects
        {...this.props}
        model={Project}
        includes={["property.[entity, location]", "membersContacts"]}
        apiRoute="projects"
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  consultingCompanyId: ownProps.match.params.consultingCompanyId,
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
});

export default connect(mapStateToProps, { push })(ProjectsContainer);
