import React, { Fragment } from "react";
import { Mail } from "@material-ui/icons";
import pluralize from "pluralize";
import { push } from "connected-react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "../../../layout/SectionHeader";
import { DisabledPaper } from "./components";
import { Paper } from "../../../mui/core";
import POStatusTag from "../../../ui/POStatusTag";
import { PurchaseOrderHeader } from "../../ProjectDetail/Shipments/PurchaseOrderHeader";
import propTypes from "../../../../constants/propTypes";

export const RowHeader = ({
  purchaseOrder,
  isCollapsed,
  setIsCollapsed,
  actionsComponent,
  push,
  clientId,
}) => {
  const onViewPO = () => {
    push(
      `/clients/${clientId}/projects/${purchaseOrder.projectId}/purchase-orders/${purchaseOrder.id}?fromPurchasing=true&purchase_order_detail_purchase_order_list_sort=customNumber:asc`
    );
  };

  function ViewPurchaseOrderButton() {
    return (
      <Button text="VIEW PURCHASE ORDER" icon={<Mail />} onClick={onViewPO} />
    );
  }
  const isDisabled =
    purchaseOrder.status === "Issued" && !purchaseOrder.revisionStatus;
  const Wrapper = isDisabled ? DisabledPaper : Paper;
  const { name, specsCount } = purchaseOrder;
  const itemsDisplay = pluralize("Item", specsCount || 0, true);
  const headerText = `${name} (${itemsDisplay})`;

  return (
    <Wrapper noBorder noBottomMargin>
      <PurchaseOrderHeader
        headerText={headerText}
        DescriptionComponent={ViewPurchaseOrderButton}
        headerOverride={
          <Fragment>
            <POStatusTag
              purchaseOrder={purchaseOrder}
              spaceBetween={false}
              marginRight={true}
            />
            {actionsComponent ? actionsComponent : null}
          </Fragment>
        }
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDisabled={isDisabled}
      />
    </Wrapper>
  );
};

RowHeader.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func.isRequired,
  actionsComponent: PropTypes.node,
  push: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, { match }) => {
  return {
    clientId: match.params.clientId,
    scopeId: state.scope.id,
  };
};

export const mapDispatchToProps = {
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RowHeader)
);
