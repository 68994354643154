import React from "react";
import _get from "lodash";
import ReactInputTrigger from "react-input-trigger";

// CUSTOM function override of react-input-trigger,https://github.com/abinavseelan/react-input-trigger
/* eslint-disable */
class InputTrigger extends ReactInputTrigger {
  /*
   * Those changes have been made by the repository owner in branch bugfix/broken-ref. this file should be REMOVE once this PR will be merged with master.
   * */

  componentDidUpdate(prevProps) {
    const { inputRef: prevInputRef } = prevProps;
    const { inputRef: inputRef } = this.props;
    if (_get(prevInputRef, "current") !== _get(inputRef, "current")) {
      this.element = inputRef.current;
    }
  }

  render() {
    const {
      children,
      trigger,
      onStart,
      onCancel,
      onType,
      endTrigger,
      inputRef,
      elementRef,
      ...rest
    } = this.props;

    return (
      <div
        role="textbox"
        tabIndex={-1}
        onKeyDown={super.handleTrigger.bind(this)}
        {...rest}
      >
        {children}
      </div>
    );
  }
}

InputTrigger.propTypes = InputTrigger.propTypes;

InputTrigger.defaultProps = {
  trigger: {
    keyCode: null,
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  },
  onStart: () => {},
  onCancel: () => {},
  onType: () => {},
  endTrigger: () => {},
};

export default InputTrigger;
