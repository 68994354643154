import PropTypes from "prop-types";
import React, { Fragment, useContext, useMemo } from "react";
import styled from "styled-components";
import _get from "lodash/get";

import SpecDetailTitle from "./SpecDetailTitle";
import {
  calcQtyNeeded,
  calcRecommendedCaseQtyOrder,
  calcTotalNeeded,
} from "./PARBasedQuantityCalculation/calcFunctions";
import {
  PORevisionContext,
  RevisionReferenceContext,
  withRevisionReference,
} from "../../../withPORevision";
import propTypes from "../../../constants/propTypes";

const Description = styled.div`
  padding: 18px 0 24px;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 24px 12px 0;
  ${({ isDeleted, isCreated }) =>
    (isDeleted || isCreated) &&
    `
  font-style: italic;
  font-weight: 600;
  color: #000000!important;
  `}
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  & > * {
    ${({ isDeleted }) => isDeleted && "opacity: 0.3"}
  }  
  &:after {
    ${({ isDeleted, isCreated }) =>
      (isDeleted || isCreated) &&
      `
      position: absolute;
      content: "\\25CF";
      font-size: 10px;
      top: -12px;
      left: -6px;
      overflow: visible;
    `}
    ${({ isCreated }) => isCreated && "color: #ff9100;"}
    ${({ isDeleted }) => isDeleted && "color: #ff1744;"}
  }
`;
export const ItemColumn = styled.div`
  text-align: ${({ align = "left" }) => align};
`;
export const ColumRow = styled.p`
  color: ${({ isTitle }) =>
    isTitle ? "rgba(0,0,0,0.87)" : "rgba(0,0,0,0.54)"};
  margin: 4px 0;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${({ isUpdated, isValueUpdated }) =>
    (isUpdated || isValueUpdated) &&
    `
  font-style: italic;
  font-weight: 600;
  color: #000000;
  `}
  &:after {
    position: absolute;
    font-size: 10px;
    top: -6px;
    overflow: visible;
    color: #ff9100;
    ${({ direction = "left" }) => direction}: -8px;
    content: ${({ isUpdated }) => (isUpdated ? '"\\25CF"' : null)};
  }
`;

const ItemRow = ({ roomType: { __deleted__, ...roomType }, reference }) => {
  const { isUpdated, isCreated } = useContext(PORevisionContext);
  return (
    <ItemWrapper isDeleted={__deleted__} isCreated={isCreated(reference)}>
      <ItemColumn>
        <ColumRow isUpdated={isUpdated(reference, "name")} isTitle>
          {roomType.name}
        </ColumRow>
        <ColumRow isUpdated={isUpdated(reference, "perRoom")}>
          Per Room
        </ColumRow>
        <ColumRow isUpdated={isUpdated(reference, "par")}>PAR</ColumRow>
        <ColumRow>Quantity Needed</ColumRow>
      </ItemColumn>
      <ItemColumn align="right">
        <ColumRow
          isUpdated={isUpdated(reference, "roomCount")}
          direction="right"
        >
          {roomType.roomCount}
        </ColumRow>
        <ColumRow isValueUpdated={isUpdated(reference, "perRoom")}>
          {roomType.perRoom}
        </ColumRow>
        <ColumRow isValueUpdated={isUpdated(reference, "par")}>
          {roomType.par}
        </ColumRow>
        <ColumRow>{calcQtyNeeded(roomType)}</ColumRow>
      </ItemColumn>
    </ItemWrapper>
  );
};
const roomTypeProp = PropTypes.shape({
  roomCount: PropTypes.number,
  perRoom: PropTypes.number,
  par: PropTypes.number,
});
ItemRow.propTypes = {
  reference: PropTypes.string,
  roomType: roomTypeProp,
};

const LastRow = ({ roomTypes, unitsPerCase, reference }) => {
  const { isUpdated } = useContext(PORevisionContext);
  return (
    <ItemWrapper>
      <ItemColumn>
        <ColumRow>Total Needed</ColumRow>
        <ColumRow isUpdated={isUpdated(reference, "unitsPerCase")}>
          Units per Case
        </ColumRow>
        <ColumRow>Recommended Case Quantity Order</ColumRow>
      </ItemColumn>
      <ItemColumn align="right">
        <ColumRow>{calcTotalNeeded(roomTypes)}</ColumRow>
        <ColumRow isValueUpdated={isUpdated(reference, "unitsPerCase")}>
          {unitsPerCase || 0}
        </ColumRow>
        <ColumRow>
          {calcRecommendedCaseQtyOrder(unitsPerCase, roomTypes) || 0}
        </ColumRow>
      </ItemColumn>
    </ItemWrapper>
  );
};
LastRow.propTypes = {
  reference: PropTypes.string,
  roomTypes: PropTypes.arrayOf(roomTypeProp),
  unitsPerCase: PropTypes.number,
};

const SpecDetailParBased = ({ specDetail }) => {
  const { unitsPerCase } = specDetail;
  const { reference } = useContext(RevisionReferenceContext);
  const { getDeletedItems } = useContext(PORevisionContext);

  const { fullList, basicList } = useMemo(() => {
    if (specDetail.__deleted__) {
      const basicList = Object.values(_get(specDetail, "roomTypes", {}));
      return {
        fullList: basicList,
        basicList,
      };
    }
    const basicList = _get(specDetail, "roomTypes", []);
    return {
      fullList: basicList.concat(getDeletedItems(`${reference}.roomTypes`)),
      basicList,
    };
  }, [specDetail, getDeletedItems, reference]);
  return (
    <Fragment>
      <SpecDetailTitle>PAR Based Quantity Calculation</SpecDetailTitle>
      <Description>
        {fullList.map((roomType, key) => (
          <ItemRow
            roomType={roomType}
            key={key}
            reference={`${reference}.roomTypes.${roomType.id}`}
          />
        ))}
        <LastRow
          roomTypes={basicList}
          unitsPerCase={unitsPerCase}
          reference={reference}
        />
      </Description>
    </Fragment>
  );
};

const WrappedSpecDetailParBased = withRevisionReference(SpecDetailParBased);

const Wrapper = ({ specDetail }) => (
  <WrappedSpecDetailParBased
    specDetail={specDetail}
    revisionReference={specDetail.id}
  />
);

Wrapper.propTypes = {
  specDetail: propTypes.specDetail,
};

SpecDetailParBased.propTypes = Wrapper.propTypes;

export default Wrapper;
