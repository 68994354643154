import {
  getEmptyStringWhenFalsy,
  getEmptyStringWhenNull,
  getZeroWhenFalsy,
} from "./analysisSpecRowFunctions";
import * as CELL_TYPES from "./cellTypes";
import * as SPEC_TYPES from "../specTypes";

export const setChevronColumn = (
  vendorSpec,
  specRow,
  openAlternateQuotesModal,
  bgVendor,
  vendorIndex
) => {
  specRow[`quote_${vendorIndex}_chevron`] = "";
  if (vendorSpec.hasAlternate) {
    specRow[`quote_${vendorIndex}_chevron`] = {
      type: CELL_TYPES.CHEVRON,
      onClick: () => openAlternateQuotesModal(vendorSpec, bgVendor),
    };
  }
};

const getProductionPricingExtraProps = ({ awardedProductionPricing }) =>
  awardedProductionPricing
    ? {}
    : {
        fontStyle: "italic",
        note: "*",
      };

export const setCOMColumns = (
  vendorSpec,
  specRow,
  openAlternateQuotesModal,
  bgVendor,
  vendorIndex,
  defaultProjectCurrency
) => {
  const { quoteProjectCurrency } = bgVendor;
  /**
   * Sample columns
   */
  const extSampleCOMQuantity =
    vendorSpec.sampleQuantity *
    (vendorSpec.type === SPEC_TYPES.COM && vendorSpec.parentSpec
      ? vendorSpec.parentSpec.sampleQuantity
      : 1);
  specRow[`quote_${vendorIndex}_sampleCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${vendorSpec.sampleQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => vendorSpec.sampleQuantity
    ),
    type: CELL_TYPES.QUANTITY,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${extSampleCOMQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => extSampleCOMQuantity
    ),
    type: CELL_TYPES.QUANTITY,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_samplePricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.awardedSamplePricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_extendedSamplePricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.awardedSamplePricing *
        extSampleCOMQuantity *
        quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };

  /**
   * Production columns
   */
  const extProductionCOMQuantity =
    vendorSpec.productionQuantity *
    (vendorSpec.parentSpec
      ? vendorSpec.parentSpec.totalQuantityWithoutOverage
      : 1);
  specRow[`quote_${vendorIndex}_productionCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${vendorSpec.productionQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => vendorSpec.productionQuantity
    ),
    type: CELL_TYPES.QUANTITY,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${extProductionCOMQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => extProductionCOMQuantity
    ),
    type: CELL_TYPES.QUANTITY,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_productionPricing`] = {
    value: getZeroWhenFalsy(
      (vendorSpec.awardedProductionPricing ||
        vendorSpec.spec.priceCents / 100) * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
    ...getProductionPricingExtraProps(vendorSpec),
  };
  specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.awardedProductionPricing *
        extProductionCOMQuantity *
        quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };
};

export const getQuoteItemAtIndex = (quote, index) => {
  const { quoteItems = [] } = quote;
  const quoteItem = {};
  if (quoteItems.length > index) {
    quoteItem.productionPricing = quoteItems[index].productionPricing / 100;
  }
  return quoteItem;
};
