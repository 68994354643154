import styled from "styled-components";
export const Footer = styled.footer`
  padding: 24px;
  height: 96px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 600px;
`;
