import styled from "styled-components";
import { TextField } from "@material-ui/core";
import React from "react";

import { connectInput } from "../../../withPORevision";

export const StyledTextField = styled(
  ({
    wasUpdatedInRevision,
    labeledTextProps,
    InputProps,
    InputLabelProps,
    resize,
    rowId,
    SelectComponent,
    keyboard,
    clearable,
    isApplyOnChange,
    setLocalValue,
    APIOptions,
    emphasizeRequired,
    isInline,
    avoidDebounce,
    PopoverProps,
    editableOptions,
    ...props
  }) => {
    return (
      <TextField
        {...props}
        InputProps={{
          ...InputProps,
          classes: {
            root: "root",
            focused: "input-focused",
            input: "input",
          },
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            root: "label",
            focused: "label-focused",
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: "form-helper-text-root",
          },
        }}
      />
    );
  }
)`
  & .root {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: ${({ InputProps = {} }) =>
      InputProps.readOnly ? "pointer" : null};
  }

  & .input {
    cursor: ${({ InputProps = {} }) =>
      InputProps.readOnly ? "pointer" : null};
    font-style: ${({ wasUpdatedInRevision }) =>
      wasUpdatedInRevision && "italic"};
    resize: ${({ resize }) => resize};
    ${({ height }) => (height ? `height: ${height}px` : "")}
  }

  & .input-focused:after {
    border-bottom-color: #57abff;
  }

  & .label-focused {
    color: rgba(0, 0, 0, 0.8);
  }

  & .form-helper-text-root {
    margin-top: 3px;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    line-height: 16px;
  }

  ${({ wasUpdatedInRevision }) =>
    wasUpdatedInRevision &&
    `
  &:before {
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    color: #ff9100;
    top: 14px;
    left: -5px;
    overflow: visible;
  }
  `}
`;

export default connectInput(StyledTextField);
