import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import _isArray from "lodash/isArray";
import BWModels from "benjaminwest-models";

import DateColumn from "../../../../ui/BWGrid/columns/Date";
import { RevenueSharing, StatusColumn } from "./components";
import Price from "../../../../ui/Price";
import { formatWithNegatives } from "../../../Billing/Received/ProjectFees/FeePayments/PaymentsList";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";
import { isSuperAdmin } from "../../../../../utils/roleUtils";

const RenderAmount = ({ amount }) => <Price number={amount || 0} />;
RenderAmount.propTypes = {
  amount: PropTypes.number,
};

export const columns = [
  "serviceCategory",
  "billingTerm",
  "workScope.id",
  "amount",
  "billingDate",
  "revenueSharing",
  "status",
  "billDate",
  "invoiceReferenceNumber",
  "amountPaid",
  "paidReference",
];
export const getColumnOptions = (formatter, workScopes) => ({
  serviceCategory: {
    filter: "select",
    filterOptions: {
      options: BWModels.loadSchema("Fee").__utils.serviceCategories,
    },
  },
  billingTerm: {
    filter: "select",
    filterOptions: {
      options: BWModels.loadSchema("Fee").__utils.billingTerms,
    },
  },
  amount: {
    title: "Fee",
    render: RenderAmount,
  },
  "workScope.id": {
    title: "Scope",
    render: ({ workScope }) => {
      return workScope?.name;
    },
    filter: "multiselect",
    filterOptions: {
      options: workScopes,
      operator: "in",
    },
  },
  billingDate: {
    render: ({ billingDate }) =>
      billingDate && (
        <DateColumn date={billingDate} format={PRIMARY_DATE_FORMAT} />
      ),
  },
  revenueSharing: {
    fill: true,
    render: RevenueSharing,
    filter: "select",
    filterOptions: {
      options: [
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
      ],
    },
  },
  status: {
    render: StatusColumn,
    filter: "select",
    filterOptions: {
      options: BWModels.loadSchema("Fee").__utils.statuses,
    },
  },
  billDate: {
    filter: "picker",
    render: ({ billDate }) =>
      billDate && <DateColumn date={billDate} format={PRIMARY_DATE_FORMAT} />,
  },
  invoiceReferenceNumber: {
    title: "Invoice",
    filter: true,
  },
  amountPaid: {
    filter: "number",
    filterOptions: {
      operator: "equal",
    },
    render: ({ amountPaid }) => {
      return formatWithNegatives(amountPaid, formatter);
    },
  },
  paidReference: {
    title: "Ref",
    filter: true,
  },
});

export const showAction = ({ actionName, ...action }, fee) => {
  const { status } = fee;
  if (action.show) return true;
  const transition = BWModels.loadSchema(
    "Fee"
  ).__utils.stateMachineTransitions.find(({ name }) => name === actionName);
  if (!transition) return false;
  const { from } = transition;
  const showPermitted = _isArray(from)
    ? from.includes(status)
    : status === from;
  return showPermitted && !action.forceHide;
};

export const getResumeOption = (userRole, status, onUpdateStatus) => {
  if (status == "On Hold") {
    return {
      text: "Resume Fee",
      onClick: onUpdateStatus("Pending"),
      actionName: "resume",
    };
  }

  if (status == "Approved" && isSuperAdmin(userRole)) {
    return {
      text: "Revert to Pending",
      onClick: onUpdateStatus("Pending"),
      actionName: "resume",
    };
  }

  return {};
};

export const getRowMenu = (
  onOpenEditModal,
  onUpdateStatus,
  onOpenVoidModal,
  onOpenBillFeeModal,
  userRole
) => fee => {
  const { status, billingDate, sharedFromFeeId, revenueSharing } = fee;
  if (["Void"].includes(status) || sharedFromFeeId) return false;
  return [
    {
      text: "Edit",
      onClick: onOpenEditModal,
      show: true,
    },
    {
      text: "Approve Fee",
      onClick: onUpdateStatus("Approved"),
      actionName: "approve",
      forceHide: new Date() < moment(billingDate) || status == "On Hold",
    },
    {
      text: "Bill Fee",
      onClick: onOpenBillFeeModal,
      actionName: "bill",
      forceHide: !!revenueSharing,
    },
    {
      text: "Mark as Paid",
      onClick: onUpdateStatus("Paid"),
      actionName: "pay",
      forceHide: !revenueSharing,
    },
    getResumeOption(userRole, status, onUpdateStatus),
    {
      text: "Put On Hold",
      onClick: onUpdateStatus("On Hold"),
      actionName: "putOnHold",
    },
    { separator: true, show: status !== "Billed" },
    {
      text: "Void Fee",
      onClick: onOpenVoidModal,
      actionName: "void",
      forceHide: status === "Paid",
    },
  ]
    .filter(action => showAction(action, fee))
    .map(({ actionName, show, forceHide, ...action }) => action);
};
