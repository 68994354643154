import React from "react";
import shallow from "zustand/shallow";

import useErrorImportStore from "../../../../hooks/useErrorImportStore";
import { InvalidText } from "./components";
import { getLabel } from "../../../../inputs/SelectWithError/StandardSelect";

export const getLabelProperty = (labelProperty = "name") =>
  function label(option) {
    if (option.id) {
      return getLabel(labelProperty, option);
    }
    return <InvalidText>Missing</InvalidText>;
  };

function EditableSelect({ SelectComponent, name, rowId, ...props }) {
  const [actions] = useErrorImportStore(
    state => [
      state.actions,
      state.importError,
      state.localError,
      state.ignoreErrors,
    ], //Doing this force to the component to be re-rendered when the errors get updated changes
    shallow
  );

  if (actions.isInvalidLocalField(rowId, name)) {
    return (
      <SelectComponent
        {...props}
        SelectProps={{ displayEmpty: true }}
        labelProperty={getLabelProperty(props.labelProperty)}
      />
    );
  }

  return <SelectComponent {...props} />;
}

export default EditableSelect;
