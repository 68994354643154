import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BaseError from "./BaseError";
import ErrorText from "./ErrorText";
import propTypes from "../../constants/propTypes";

function filterErrors(errorPayload, errorKeysToMatch) {
  let errors = [];
  if (
    errorPayload &&
    errorPayload.data &&
    errorPayload.data.errors &&
    Array.isArray(errorPayload.data.errors)
  ) {
    errorPayload.data.errors.map(error => {
      const { source } = error;
      errorKeysToMatch.forEach(keyToMatch => {
        if (keyToMatch === source || RegExp(keyToMatch).test(source)) {
          errors.push(error);
        }
      });
    });
  }
  return errors;
}

const Container = styled.div`
  padding: 16px;
  font-family: Open Sans, sans-serif;
  background-color: rgb(254, 232, 236);
`;

const Title = styled.div({});
const Detail = styled.p({});
const Error = styled.div`
  padding-top: "24px";
  ${Title} {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
  ${Detail} {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

class DialogBottomError extends Component {
  static propTypes = {
    errorPayload: propTypes.errorPayload,
    errorKeysToMatch: PropTypes.array.isRequired,
  };

  render() {
    const { errorPayload, errorKeysToMatch } = this.props;
    const validationErrors = filterErrors(errorPayload, errorKeysToMatch);

    let validationErrorElements;
    if (validationErrors && validationErrors.length) {
      validationErrorElements = validationErrors.map((error, i) => (
        <Error key={i}>
          <Title>
            <ErrorText code={error.code} />
          </Title>
          <Detail>
            <ErrorText code={error.code} detail />
          </Detail>
        </Error>
      ));

      validationErrorElements = (
        <Container>{validationErrorElements}</Container>
      );
    }

    return <BaseError>{validationErrorElements}</BaseError>;
  }
}

export default DialogBottomError;
