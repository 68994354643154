import React from "react";
import PropTypes from "prop-types";

import withRenderControlByRole, {
  Actions,
} from "../../../hocs/withRenderControlByRole";
import { HeaderMenu } from "./BWUserHeader";
import { HeaderIconsContainer } from "../components";
import { UserOptions } from "./AlternatePortalHeader";

export function ClientPortalHeader({
  onClick,
  authenticatedUserName,
  anchorElement,
  onCloseUserMenu,
  onOpenSettings,
  onLogout,
}) {
  return (
    <HeaderIconsContainer>
      <UserOptions
        onClick={onClick}
        authenticatedUserName={authenticatedUserName}
      />
      <HeaderMenu
        handleLogout={onLogout}
        onClose={onCloseUserMenu}
        anchorElement={anchorElement}
        onOpenSettings={onOpenSettings}
        isStaffPortal={false}
      />
    </HeaderIconsContainer>
  );
}

ClientPortalHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  authenticatedUserName: PropTypes.string,
  anchorElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  onCloseUserMenu: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onLogout: PropTypes.func,
};

export default withRenderControlByRole(
  ClientPortalHeader,
  ["Client Portal"],
  Actions.SHOW
);
