import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

export function bidGroupsReducer(state = initialState.bidGroups, action) {
  switch (action.type) {
    case actionTypes.SET_DELETED_BID_GROUP: {
      return {
        ...state,
        deletedBidGroups: [
          ...state.deletedBidGroups,
          action.payload.bidGroupId,
        ],
      };
    }
    case actionTypes.UNDO_DELETED_BID_GROUP: {
      return {
        ...state,
        deletedBidGroups: state.deletedBidGroups.filter(
          bidGroupId => bidGroupId !== action.payload.bidGroupId
        ),
      };
    }
    case actionTypes.RESET_DELETED_BID_GROUPS: {
      return {
        ...state,
        deletedBidGroups: [],
      };
    }
    default:
      return state;
  }
}
