import styled from "styled-components";
import Refresh from "@material-ui/icons/Refresh";
import MUIButton from "@material-ui/core/Button";

export const Banner = styled.div`
  display: flex;
  align-items: center;
  background: #ff9100;
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
`;

export const LeftWrapper = styled.span`
  display: flex;
  margin-right: 8px;
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px;
`;

export const Buttons = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
`;

export const Button = styled(MUIButton)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  padding: 24px;
  text-transform: none;
`;

export const RightButton = styled(Button)`
  font-weight: 600;
  padding: 20px 16px;
  background-color: rgba(0, 0, 0, 0.08);
`;

export const RevertIcon = styled(Refresh)`
  font-size: 16px;
  width: 16px;
  height: 16px;
`;
