import React, { Component } from "react";
import { connect } from "react-redux";
import RemitAddressPage from "./RemitAddressPage";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { fetchData } from "../../../actions/dataComponentActions";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";
import RemitAddress from "../../../models/RemitAddress";

export class RemitAddressesContainer extends Component {
  static propTypes = {
    vendorId: PropTypes.string.isRequired,
    dataComponent: PropTypes.object.isRequired,
    push: PropTypes.func,
    isModalOpen: PropTypes.bool,
    openModalDialog: PropTypes.func,
    refreshRemitAddressTable: PropTypes.func,
  };

  render() {
    return (
      <RemitAddressPage
        {...this.props}
        model={RemitAddress}
        apiRoute="remit-addresses"
      />
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  vendorId: ownProps.match.params.vendorId,
  dataComponent: getDataComponentFlattenedRequestState(
    "RemitAddressesGrid",
    state
  ),
  isModalOpen: state.layout.modalDialog.isOpen,
});

export const mapDispatchToProps = {
  push,
  openModalDialog,
  refreshRemitAddressTable: () => fetchData("RemitAddressesGrid"),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemitAddressesContainer);
