import React from "react";
import PropTypes from "prop-types";
import createSpecFields from "./createSpecFields";
import SpecFormikForm from "../SpecFormikForm";
import { listenerSetting } from "../../../hocs/withRequestListener";
import { shouldDisableVendorField } from "../../PurchaseOrderDetail/Specs/QuickEditSpec/QuickEditSpecForm";

function CreateSpecForm(props) {
  const {
    spec,
    specSchema,
    createSpec,
    closeModalDialog,
    initialValues,
    setSaveAndCreateAnother,
    listeners,
    projectCurrency,
    setRef,
  } = props;

  const buildSubmit = (onSubmit, goToNext, ref, customErrorMessage) => (
    ...args
  ) => {
    if (customErrorMessage) return;
    setSaveAndCreateAnother(goToNext);
    setRef(ref);
    onSubmit(...args);
  };

  return (
    <SpecFormikForm
      spec={spec}
      initialValues={initialValues}
      onSubmit={createSpec}
      buildSubmit={buildSubmit}
      validationSchema={specSchema}
      createFields={createSpecFields}
      disableVendorField={shouldDisableVendorField(spec)}
      sendButtonText={"Create Spec"}
      saveAndCreateAnotherText={"Save & Create Another"}
      onCancel={closeModalDialog}
      listeners={listeners}
      projectCurrency={projectCurrency}
      setRef={setRef}
    />
  );
}

CreateSpecForm.defaultProps = {
  spec: {},
  createSpec: () => {},
  specSchema: {},
  closeModalDialog: () => {},
  initialValues: {},
  setSaveAndCreateAnother: () => {},
  setRef: () => {},
};
CreateSpecForm.propTypes = {
  spec: PropTypes.shape({}),
  createSpec: PropTypes.func.isRequired,
  specSchema: PropTypes.object,
  closeModalDialog: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  setSaveAndCreateAnother: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  listeners: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(listenerSetting)),
    PropTypes.arrayOf(listenerSetting),
  ]),
  projectCurrency: PropTypes.shape({}),
};

export default CreateSpecForm;
