import React, { useState } from "react";
import PropTypes from "prop-types";

import TextInputWithError from "../../../inputs/TextInputWithError";
import { IMaskInput } from "react-imask";

export const CodeMask = ({ inputRef, onChange, ...props }) => {
  return (
    <IMaskInput
      mask={"00-00-00"}
      unmask
      inputRef={inputRef}
      onAccept={value => {
        onChange({
          target: {
            name: props.name,
            value,
          },
        });
      }}
      {...props}
      placeholder="00-00-00"
    />
  );
};

export const VerifyCode = ({
  verificationCodeSent,
  validateVerificationCode,
  validatingVerificationCode,
  verificationCodeValidated,
  login,
  callbackUrl,
}) => {
  const [code, setCode] = useState("");

  const handleChange = ({ target: { value } }) => {
    setCode(value);
    if (value?.length === 6) {
      validateVerificationCode(value, login, callbackUrl);
    }
  };

  const hideInput =
    !verificationCodeSent ||
    validatingVerificationCode ||
    verificationCodeValidated;

  if (hideInput) {
    return null;
  }

  return (
    <TextInputWithError
      avoidDebounce
      ignoreCaption
      label="Code"
      InputProps={{
        inputComponent: CodeMask,
      }}
      inputProps={{ autoFocus: true }}
      value={code}
      onChange={handleChange}
    />
  );
};

VerifyCode.propTypes = {
  verificationCodeSent: PropTypes.bool,
  validateVerificationCode: PropTypes.func.isRequired,
  validatingVerificationCode: PropTypes.bool,
  verificationCodeValidated: PropTypes.bool,
  login: PropTypes.bool,
  callbackUrl: PropTypes.string,
};
