import React, { useCallback } from "react";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";

import { useCreateProcessRequests } from "../../../hooks/useProcessRequest";
import DuplicateConfirmation from "./DuplicateConfirmation";

export const useProcessRequestResponse = (
  dataComponent,
  { clientId, newProjectId, title },
  actions
) => {
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.closeModalDialog();
      actions.push(`/clients/${clientId}/projects/${newProjectId}/settings`);
      actions.showSnackNotificationAction(
        `${title} has been duplicated successfully`
      );
    },
    onError: ({ data }) => {
      data.errors.map(error =>
        actions.showSnackNotificationAction(error.title)
      );
    },
  });
};

const DuplicateProject = ({
  dataComponent,
  title,
  clientId,
  newProjectId,
  project,
  dataComponentId,
  actions,
}) => {
  useProcessRequestResponse(
    dataComponent,
    { newProjectId, clientId, title },
    actions
  );

  const handleCreate = useCallback(() => {
    actions.duplicateProject(project.id, dataComponentId);
  }, [actions, dataComponentId, project]);

  return (
    <DuplicateConfirmation
      dataComponentId={dataComponentId}
      onSubmit={handleCreate}
      title={title}
    />
  );
};

DuplicateProject.propTypes = {
  title: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  newProjectId: PropTypes.string,
  project: propTypes.project.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  actions: PropTypes.shape({
    closeModalDialog: PropTypes.func,
    push: PropTypes.func,
    duplicateProject: PropTypes.func,
    showSnackNotificationAction: PropTypes.func,
  }),
};

export default DuplicateProject;
