import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import FormGrid from "../../../../forms/FormGrid";
import createAreaFields from "./createAreaFields";
import AreaRoomList from "./AreaRoomList";
import { FormikForm, ActionButtons } from "../../../../forms";
import { FieldArray } from "formik";
import BWModels from "benjaminwest-models";
import Joi from "joi-browser";
import propTypes from "../../../../../constants/propTypes";

const dataComponentId = "Areas";

const getValidationSchema = () =>
  BWModels.loadSchema("Area").concat(
    Joi.object().keys({
      areaRooms: Joi.array().items(BWModels.loadSchema("AreaRoom")),
    })
  );

export default class CreateAreaForm extends Component {
  _handleCancel = () => {
    this.props.closeModalDialog();
  };

  roomHasError = (index, errors) => {
    for (var key in errors) {
      if (key.startsWith(`areaRooms[${index}]`)) {
        return true;
      }
    }
    return false;
  };

  removeEditingState = (rooms, formikArrayHelpers, errors) =>
    rooms.forEach((room, index) => {
      if (this.roomHasError(index, errors)) return;
      const { isEditing, ...roomWithoutEdit } = room;
      formikArrayHelpers.replace(index, roomWithoutEdit);
    });

  addRoom = ({ rooms, formikArrayHelpers, errors }) => {
    const room = {
      name: "",
      roomCount: "",
      sequenceIndex: rooms.length,
      isEditing: true,
    };
    this.removeEditingState(rooms, formikArrayHelpers, errors);
    formikArrayHelpers.push(room);
  };

  editRoom = (index, room, { formikArrayHelpers, errors, setFieldTouched }) => {
    let replacementRoomState = room;
    if (!room.isEditing && this.roomHasError(index, errors)) {
      setFieldTouched(`areaRooms[${index}].name`);
      setFieldTouched(`areaRooms[${index}].roomCount`);
      // Don't release edit if still has error
      replacementRoomState = { ...replacementRoomState, isEditing: true };
    }
    formikArrayHelpers.replace(index, replacementRoomState);
  };

  removeRoom = (index, { formikArrayHelpers }) => {
    formikArrayHelpers.remove(index);
  };

  reorderRooms = (rooms, destination, source, { formikArrayHelpers }) => {
    const sourceSequenceIndex = rooms[source.index].sequenceIndex;
    const destinationSequenceIndex = rooms[destination.index].sequenceIndex;
    rooms[source.index].sequenceIndex = destinationSequenceIndex;
    rooms[destination.index].sequenceIndex = sourceSequenceIndex;
    formikArrayHelpers.move(source.index, destination.index);
  };

  isMultipleRoomSelected(area) {
    if (!area.areaType) return false;
    return area.areaType.isMultiple;
  }

  roomListProps = formikProps => {
    return {
      onAdd: () => this.addRoom(formikProps),
      onEdit: (index, room) => this.editRoom(index, room, formikProps),
      onRemove: index => this.removeRoom(index, formikProps),
      onReorder: roooms => ({ destination, source }) =>
        this.reorderRooms(roooms, destination, source, formikProps),
    };
  };

  onSend = (values, actions) => {
    const { createArea } = this.props;
    let { areaRooms, ...area } = values;

    if (!this.isMultipleRoomSelected(values)) {
      return createArea(area, [], actions);
    }
    const { squareFoot, ...parsedArea } = area;

    if (areaRooms) {
      areaRooms = areaRooms.map((room, index) => {
        if (this.roomHasError(index, {})) return;
        const { isEditing, ...roomWithoutEdit } = room;
        return roomWithoutEdit;
      });
    }

    areaRooms.forEach((room, index) => (room.sequenceIndex = index));

    createArea(parsedArea, areaRooms, actions);
  };

  render() {
    return (
      <FormikForm
        initialValues={this.props.area}
        onSubmit={this.onSend}
        validationSchema={getValidationSchema()}
        ignoreValidationFieldNames={[
          "projectId",
          "propertyId",
          /areaRooms\[.*?\].areaId/,
        ]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createAreaFields(this.isMultipleRoomSelected(values))}
              values={values}
              errors={errors}
              {...formikProps}
            />
            {this.isMultipleRoomSelected(values) && (
              <FieldArray name={"areaRooms"}>
                {formikArrayHelpers => (
                  <AreaRoomList
                    rooms={values.areaRooms || []}
                    errors={errors}
                    {...formikProps}
                    {...this.roomListProps({
                      rooms: values.areaRooms || [],
                      errors,
                      formikArrayHelpers,
                      ...formikProps,
                    })}
                  />
                )}
              </FieldArray>
            )}
            <ActionButtons
              sendButtonText={this.props.isEditing ? "Save" : "Create Area"}
              onSend={handleSubmit}
              onCancel={this._handleCancel}
              isModal={true}
              listeners={[dataComponentId]}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}

CreateAreaForm.propTypes = {
  area: propTypes.area,
  createArea: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  areaTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

CreateAreaForm.defaultProps = {
  area: {
    name: undefined,
    areaType: undefined,
    description: undefined,
    squareFoot: undefined,
    areaRooms: [],
  },
};
