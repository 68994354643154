import React from "react";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";
import CheckCircle from "@material-ui/icons/CheckCircle";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";

import { Paper } from "../../../mui/core";
import Office from "../../../../models/Office";
import propTypes from "../../../../constants/propTypes";
import LocationCombined from "../../../ui/LocationCombined";

export const StatusColumn = ({ isArchived }) =>
  isArchived ? (
    <CheckCircle style={{ display: "flex", color: "#72B327" }} />
  ) : null;
StatusColumn.propTypes = {
  isArchived: PropTypes.bool,
};

const columns = ["name", "location.address", "abbreviation", "isArchived"];
export const columnOptions = {
  name: { bold: true, filter: true },
  "location.address": {
    title: "Address",
    render: LocationCombined,
    fill: true,
  },
  abbreviation: { filter: true },
  isArchived: {
    title: "Archived",
    render: StatusColumn,
    filter: "select",
    filterOptions: {
      operator: ({ value: displayArchived }) => {
        if (displayArchived) {
          return { isArchived: true };
        }
        return {
          $or: [{ isArchived: false }, { isArchived: { $exists: false } }],
        };
      },
      options: [
        { id: false, name: "Active" },
        { id: true, name: "Archived" },
      ],
    },
  },
};
const rowMenu = ({ onOpenEditModal, onOpenDeleteModal }) => [
  { icon: <Edit />, text: "Edit", onClick: onOpenEditModal },
  { icon: <Cancel />, text: "Delete", onClick: onOpenDeleteModal },
];

const actions = onOpenCreateModal => [
  {
    text: "Create Office",
    icon: <AddCircle />,
    handler: onOpenCreateModal,
  },
];

const OfficesTable = ({
  dataComponent,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Office}
        includes={["location"]}
        apiRoute="offices"
        apiFilters={{
          sort: [{ columnName: "name" }],
          rootFilters: {
            displayArchived: true,
          },
        }}
        defaultSorting={[{ columnName: "name", direction: "asc" }]}
      >
        <GridHeader
          headerText={`${dataComponent.totalRows} Offices`}
          actions={actions(onOpenCreateModal)}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={rowMenu({ onOpenEditModal, onOpenDeleteModal })}
          showSelectionColumn={true}
          showSelectAll={true}
        />
      </BWGridAPI>
    </Paper>
  );
};

OfficesTable.propTypes = {
  dataComponent: propTypes.dataComponent,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default OfficesTable;
