import { createSelector } from "reselect";
import _get from "lodash/get";
import { deserialize } from "deserialize-json-api/src";

import { getDataComponentFlattenedRequestState } from "../reducers/dataComponentReducer";
import { createDeepEqualSelector } from "./selectors";
import * as REQUEST_TYPES from "../constants/RequestTypes";

const selectorsByDatacomponentId = {};

export const getElementsById = (Model, resources, ids) => {
  if (resources) {
    const { currentResources } = Model.query(resources).where({ id: ids });

    if (!currentResources) return [];

    const flattenResources = Object.values(resources).map(resource => resource);

    let included = [];
    flattenResources.forEach(flattenResource => {
      included = [...included, ...Object.values(flattenResource)];
    });

    const serializedData = Object.values(currentResources).map(
      currentResource => ({
        data: { ...currentResource },
        included,
      })
    );

    const deserializedData = serializedData.map(data => deserialize(data).data);
    return deserializedData;
  }

  return [];
};

const createResourcesSelector = () => {
  const dataComponentSelector = createDeepEqualSelector(
    (_, { dataComponent }) => dataComponent,
    dataComponent => dataComponent
  );
  const idsSelector = createDeepEqualSelector(
    (_, { whereIds }) => whereIds,
    whereIds => whereIds
  );
  const resourcesSelector = createSelector(
    state => state.resources,
    dataComponentSelector,
    idsSelector,
    (resources, dataComponent, whereIds) => {
      if (!whereIds) {
        return null;
      }

      // Ids default to all (which is rowIndex array if not supplied explicitly)
      return getElementsById(
        dataComponent.model,
        resources,
        whereIds,
        dataComponent.includes ? dataComponent.includes : []
      ).sort(
        (item1, item2) =>
          whereIds.indexOf(`${item1.id}`) - whereIds.indexOf(`${item2.id}`)
      );
    }
  );

  const deepResourcesSelector = createDeepEqualSelector(
    resourcesSelector,
    resources => resources
  );

  let previousResources = null;

  return (state, props) => {
    const isLoading = _get(props, "dataComponent.loading");
    if (isLoading) return previousResources;

    const newResources = props.useDeep
      ? deepResourcesSelector(state, props)
      : resourcesSelector(state, props);

    return (previousResources = newResources);
  };
};

function _performBluechipQuery(dataComponent, state, whereIds, useDeep) {
  const { dataComponentId } = dataComponent;
  if (!selectorsByDatacomponentId[dataComponentId]) {
    selectorsByDatacomponentId[dataComponentId] = createResourcesSelector();
  }
  const getResourcesSelector = selectorsByDatacomponentId[dataComponentId];

  return getResourcesSelector(state, { dataComponent, whereIds, useDeep });
}

function _getWhereIds(dataComponent, ids) {
  if (!dataComponent || !dataComponent.model || dataComponent.error) {
    return null;
  }

  return ids ? ids : dataComponent.rowIndex;
}

export function getResourcesV1(dataComponent, state, ids, useDeep = true) {
  const whereIds = _getWhereIds(dataComponent, ids);
  const resources = _performBluechipQuery(
    dataComponent,
    state,
    whereIds,
    useDeep
  );
  if (!resources || !resources.length) return null;

  return resources;
}

export function getResourcesV2(
  dataComponent,
  state,
  requestType = dataComponent.requestType,
  defaultValue
) {
  return _get(
    state.dataComponentResources,
    `${dataComponent.dataComponentId}_${requestType}`,
    { data: defaultValue }
  ).data;
}

export function getResourceLinks(resource) {
  const links = _get(resource, "__json_api__.links", {});
  return Object.keys(links).reduce((resourceLinks, linkKey) => {
    resourceLinks[linkKey] = _get(links[linkKey], "meta.id");
    return resourceLinks;
  }, {});
}

export function getBluechipResources(
  dataComponent,
  state,
  ids,
  useDeep = true
) {
  if (dataComponent.dataComponentVersion === "v2") {
    return getResourcesV2(dataComponent, state);
  }
  return getResourcesV1(dataComponent, state, ids, useDeep);
}

export function getCurrentBluechipResourcesForRequestType(
  dataComponentId,
  state,
  requestType = REQUEST_TYPES.LIST
) {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    requestType
  );
  const resources = getBluechipResources(dataComponent, state);
  if (!resources) {
    return null;
  }

  if (
    requestType === REQUEST_TYPES.LIST ||
    requestType === REQUEST_TYPES.CREATE
  ) {
    return resources;
  }

  return resources[0];
}

//Create an alias for method
export const getBluechipResourcesByType = getCurrentBluechipResourcesForRequestType;

export function getCurrentResources(state, dataComponentIdOperationMap) {
  const resourceMap = {};
  Object.entries(dataComponentIdOperationMap).forEach(
    ([dataComponentId, operation]) => {
      resourceMap[dataComponentId] = getCurrentBluechipResourcesForRequestType(
        dataComponentId,
        state,
        operation
      );
    }
  );

  return resourceMap;
}

const isValidRequest = (dataComponent, id) => {
  return dataComponent && dataComponent.model && !dataComponent.error && id;
};

export function getBluechipResourceById(dataComponent, state, id) {
  if (dataComponent.dataComponentVersion === "v2") {
    return getResourcesV2(dataComponent, state, REQUEST_TYPES.FIND);
  }
  if (!isValidRequest(dataComponent, id)) {
    return null;
  }

  const resources = getBluechipResources(dataComponent, state, [`${id}`]);

  return resources ? resources[0] : null;
}

export function findItemInResources(dataComponent, state, id) {
  if (dataComponent.dataComponentVersion === "v2") {
    const resources =
      getResourcesV2(dataComponent, state, REQUEST_TYPES.LIST) || [];
    return resources.find(resource => resource.id === id);
  }
  return getBluechipResourceById(dataComponent, state, id);
}

export function findItemsInResources(dataComponent, state, ids) {
  const resources =
    getResourcesV2(dataComponent, state, REQUEST_TYPES.LIST) || [];

  return resources.filter(resource => ids.includes(resource.id));
}

export function getNextId(dataComponent, state, id) {
  if (!dataComponent || !dataComponent.model || dataComponent.error) {
    return null;
  }
  const currentId = id || dataComponent.rowIndex[0];
  return _get(
    state,
    `resources.${dataComponent.model.pluralName()}.${currentId}.links.next.meta.id`
  );
}
