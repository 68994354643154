import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { push } from "connected-react-router";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";

import PurchaseOrder from "../../../../models/PurchaseOrder";
import useActions from "../../../hooks/useActions";
import { ShipmentStatusColumn } from "../../ProjectDetail/PurchaseOrder/gridProps";
import { buildPush } from "../../../layout/DetailAndCycle/NavigationArea";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { useLoading } from "../../../hooks/useDatacomponent";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as RequestTypes from "../../../../constants/RequestTypes";
import DropdownMenu from "../../../layout/AppLayout/DropdownMenuCustomHeader";

const labelProperty = purchaseOrder => {
  const color = purchaseOrder.needsFollowUp ? "red" : "unset";
  return (
    <span style={{ color }}>
      {purchaseOrder.id != -1
        ? `${purchaseOrder.number} ${purchaseOrder.vendor.name}${
            purchaseOrder.shipmentsCount
              ? ` (${ShipmentStatusColumn(purchaseOrder)})`
              : ""
          }`
        : ""}
    </span>
  );
};

const dataComponentId = "select-purchase-orders-title-menu";

const PurchaseOrderSelectMenu = ({
  purchaseOrderId,
  projectId,
  baseUrl,
  backText,
  backRoute,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const { dirty } = useFormikContext();
  const actions = useActions({
    push,
    initDataComponent,
    performRetrieveListRequest,
  });

  const filterPurchaseOrders = useCallback(
    purchaseOrders => purchaseOrders.filter(({ id }) => id != purchaseOrderId),
    [purchaseOrderId]
  );

  useEffect(() => {
    actions.initDataComponent(
      dataComponentId,
      PurchaseOrder,
      ["vendor"],
      "purchase-orders",
      true,
      "v2"
    );

    if (isClicked) {
      actions.performRetrieveListRequest(dataComponentId, {
        rootFilters: {
          $where: {
            projectId,
            status: { $notEqual: "Canceled" },
          },
        },
        params: {
          omitDefaultModifier: true,
          modifiers: ["withShipmentsData", "withNeedsFollowUp"],
        },
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
        pageSize: -1,
      });
    }
  }, [actions, isClicked, projectId]);

  const isLoading = useLoading(dataComponentId, { LIST: RequestTypes.LIST });

  const purchaseOrders = useV2DatacomponentResources(
    dataComponentId,
    [],
    RequestTypes.LIST
  );

  const handleChange = useCallback(
    ({ id }) => () => {
      const push = buildPush(
        `${baseUrl}/purchase-orders/${id}`,
        actions.push,
        backRoute,
        backText
      )();
      push();
    },
    [actions, backRoute, backText, baseUrl]
  );

  const options = useMemo(
    () =>
      isLoading
        ? [
            {
              content: "Loading...",
              disabled: true,
            },
          ]
        : filterPurchaseOrders(purchaseOrders).map(purchaseOrder => ({
            content: labelProperty(purchaseOrder),
            onClick: handleChange(purchaseOrder),
            disabled: dirty,
          })),
    [dirty, filterPurchaseOrders, handleChange, isLoading, purchaseOrders]
  );

  if (!projectId || !purchaseOrderId) {
    return null;
  }

  const handleClick = () => setIsClicked(true);
  return (
    <DropdownMenu
      actionElem={<ArrowDropDownIcon />}
      onClick={handleClick}
      options={options}
    />
  );
};
PurchaseOrderSelectMenu.propTypes = {
  purchaseOrderId: PropTypes.string,
  projectId: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  backText: PropTypes.string.isRequired,
  backRoute: PropTypes.string.isRequired,
};

export default memo(PurchaseOrderSelectMenu);
