import { inputs } from "../../../../../components/inputs/inputConfigs";

export const getFilteredBy = arr => {
  return arr.sort((a, b) => a.flag.brand.name.localeCompare(b.flag.brand.name));
};
export const labelProperty = option => {
  if (option.flag) {
    return `${option.flag.brand.name} - ${option.name}`;
  }
  return "None";
};

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.programId,
            labelProperty,
            required: true,
            filter: getFilteredBy,
            displayEmpty: false,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.discountPercent,
          grid: { xs: 6 },
        },
        {
          input: inputs.comments,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};
