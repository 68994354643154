import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import FormGrid from "../../../../components/forms/FormGrid";
import shipToSiteFields from "./shipToSiteFields";
import { FormikForm } from "../../../../components/forms";

export default class ContactForm extends Component {
  static propTypes = {
    shipToSite: PropTypes.object,
    vendorContacts: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    dataComponentId: PropTypes.string.isRequired,
  };
  shipToSitesValues = {
    name: "",
    vendorId: null,
    location: {
      address: undefined,
      address2: undefined,
      city: undefined,
      zip: undefined,
      country: undefined,
      state: undefined,
    },
  };
  render() {
    const {
      isEditing,
      vendorContacts,
      shipToSite = {},
      shipToSiteValidRef,
      onSubmit,
    } = this.props;

    const initialValues = {
      ...this.shipToSitesValues,
      ...shipToSite,
    };

    return (
      <Fragment>
        <FormikForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={BWModels.loadSchema("VendorShipToSite")}
          ignoreValidationFieldNames={["locationId", "vendorId"]}
          enableReinitialize
          autoSave
          delayAutoSave={0}
        >
          {({ handleSubmit, values, errors, ...formikProps }) => {
            shipToSiteValidRef.current = formikProps.isValid;
            return (
              <Fragment>
                <FormGrid
                  fields={shipToSiteFields(vendorContacts, isEditing)}
                  values={values}
                  {...formikProps}
                />
              </Fragment>
            );
          }}
        </FormikForm>
      </Fragment>
    );
  }
}
