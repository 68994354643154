import React from "react";
import PropTypes from "prop-types";
import ClientForm from "./ClientForm";

const ClientCreatePage = ({ createClient, goBack, isLoading }) => (
  <ClientForm
    goBack={goBack}
    onSubmit={createClient}
    sendButtonText="Create Client"
    isLoading={isLoading}
  />
);

ClientCreatePage.propTypes = {
  goBack: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ClientCreatePage;
