import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import PropertiesPage from "./PropertiesPage";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../actions/layoutActions";

const dataComponentId = "PropertiesGrid";

export class PropertiesContainer extends Component {
  handleAddProperty = () => {
    this.props.push(`/clients/${this.props.clientId}/properties/create`);
  };

  handleEditProperty = ({ id, entity: { clientId } }) => {
    this.props.push(`/clients/${clientId}/properties/${id}/settings`);
  };

  handleDuplicateProperty = ({ id, entity: { clientId } }) => {
    this.props.push(`/clients/${clientId}/properties/${id}/duplicate`);
  };

  handleDeleteProperty = ({ id, name }) => {
    const { openModalDialog } = this.props;

    openModalDialog(
      `Delete ${name}`,
      "DeleteProperty",
      {
        title: name,
        propertyId: id,
      },
      true,
      false
    );
  };

  render() {
    const { dataComponent, clientId } = this.props;

    return (
      <PropertiesPage
        clientId={clientId}
        dataComponent={dataComponent}
        onAddProperty={this.handleAddProperty}
        onEditProperty={this.handleEditProperty}
        onDeleteProperty={this.handleDeleteProperty}
        onDuplicateProperty={this.handleDuplicateProperty}
      />
    );
  }
}

PropertiesContainer.propTypes = {
  push: PropTypes.func,
  clientId: PropTypes.string,
  openModalDialog: PropTypes.func,
  dataComponent: PropTypes.object.isRequired,
};

export const mapStateToProps = (state, ownProps) => ({
  dataComponent: getDataComponentFlattenedRequestState(dataComponentId, state),
  clientId: ownProps.match.params.clientId,
});

const mapDispatchToProps = {
  push,
  openModalDialog,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PropertiesContainer)
);
