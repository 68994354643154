import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Input } from "../../../../forms/FormGrid";
import ActionButtons from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import styled from "styled-components";
import { downloadFile } from "../../../../../actions/reportsActions";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";

const FormWrapper = styled.div`
  padding: 24px 24px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Anchor = styled.a`
  color: #57abff;
  cursor: pointer;
  text-decoration: none;
`;

export const Checkbox = styled(CheckboxWithError)`
  padding: 16px 0px 16px 0px;
`;

export const HelperText = () => {
  const handleClick = evt => {
    const blob = new Blob(
      [
        [
          "code,description,is_archived",
          "C1,This GLCode is active,0",
          "C2,This GLCode is archived,1",
          "C3,This is another archived GLCode,1",
        ].join("\n"),
      ],
      { type: "text/csv" }
    );
    downloadFile(blob, "Bulk GLCode Template.csv");
    evt.stopPropagation();
  };
  return (
    <span>
      Provide a valid .csv file -{" "}
      <Anchor onClick={handleClick}>Download GL Codes Template</Anchor>
    </span>
  );
};

const ImportFromFile = ({
  onSubmit,
  setFieldValue,
  loading,
  pluralEntity,
  file,
}) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [ignoreDuplicates, setIgnoreDuplicates] = useState(true);

  useEffect(() => {
    setFieldValue("ignoreDuplicates", ignoreDuplicates);
  }, [ignoreDuplicates, setFieldValue]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        setItemsCount(result.split("\n").length - 1);
      };
      reader.readAsText(file.file);
    }
  }, [file]);

  return (
    <Fragment>
      <FormWrapper>
        <Input
          input={{
            ...inputs.importFile,
            helperText: <HelperText />,
            useFileWrapper: true,
          }}
        />
        <Checkbox
          label={"Ignore Duplicates"}
          value={ignoreDuplicates}
          onChange={() =>
            loading ? null : setIgnoreDuplicates(!ignoreDuplicates)
          }
        />
      </FormWrapper>

      <ActionButtons
        onSend={onSubmit}
        sendButtonText={`Save ${itemsCount} ${pluralEntity}`}
        isModal
        disabled={itemsCount === 0}
        loading={loading}
      />
    </Fragment>
  );
};

ImportFromFile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  pluralEntity: PropTypes.string.isRequired,
  file: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export default ImportFromFile;
