import React from "react";
import styled from "styled-components";
import AddCircle from "@material-ui/icons/AddCircle";

import RegularText from "../../../../../ui/Typography/RegularText";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(AddCircle)`
  color: #57abff;
  font-size: 24px;
  margin-right: 8px;
`;

const Text = styled(RegularText)`
  color: #57abff;
  font-weight: bold;
`;

const CreateRemitAddressButton = () => {
  return (
    <Wrapper>
      <Icon />
      <Text>Create New Remit Address</Text>
    </Wrapper>
  );
};

export default CreateRemitAddressButton;
