import React, { Fragment } from "react";
import PropTypes from "prop-types";

import ActionButtons from "../forms/ActionButtons";
import { Message } from "./DangerConfirmation";

const StandardConfirmation = ({
  onSubmit,
  dataComponentId,
  title,
  ...buttonProps
}) => {
  return (
    <Fragment>
      <Message>Are you sure you want to {title}?</Message>
      <ActionButtons onSend={onSubmit} isModal={true} {...buttonProps} />
    </Fragment>
  );
};

StandardConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  dataComponentId: PropTypes.string,
};

export default StandardConfirmation;
