export const SELECT_COM_FIRST = "SELECT_COM_FIRST";
export const SELECT_COM_CHECKBOX = "SELECT_COM_CHECKBOX";
export const TOTAL = "TOTAL";
export const INCLUDED_IN_SPEC = "INCLUDED_IN_SPEC";
export const BOLD = "BOLD";
export const NUMBER = "NUMBER";
export const HIGHLIGHTED_NUMBER = "HIGHLIGHTED_NUMBER";
export const EMPTY_CELL = "EMPTY_CELL";
export const CHEVRON = "CHEVRON";
export const QUANTITY = "QUANTITY";
