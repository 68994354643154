import React, { Component } from "react";
import PropTypes from "prop-types";
import { codeToErrorInfoMap } from "../../utils/formValidationUtils";

export default class ErrorText extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    detail: PropTypes.any,
  };

  render() {
    const { code, detail } = this.props;
    const errorInfo = codeToErrorInfoMap[code];

    let content = "No information associated to error.";
    if (errorInfo) {
      content = detail ? errorInfo.detail : errorInfo.title;
    }
    return <span>{content}</span>;
  }
}
