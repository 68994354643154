import React from "react";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RootRef from "@material-ui/core/RootRef";

const ListWrapper = ({ isEditing, draggableProps, innerRef, ...props }) => (
  <RootRef rootRef={innerRef}>
    <ListItem {...props} {...draggableProps} />
  </RootRef>
);

export const Icon = styled(IconButton)`
  color: #000000;
`;

export const ListActions = styled(ListItemSecondaryAction)`
  display: flex;
  opacity: 0;
  align-items: center;
  margin-right: 8px;
`;

export const ReorderIcon = styled.div`
  display: flex;
  color: #000000;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
`;

export const CustomItemText = styled(props => (
  <ListItemText {...props} classes={{ primary: "primary" }} />
))`
  .primary {
    color: rgba(0, 0, 0, 0.87);
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const NameInputContainer = styled.div`
  width: calc(100% - 150px);
`;

export const CustomListItem = styled(ListWrapper)`
  min-height: 56px;
  padding: ${({ isEditing }) => (isEditing ? "0" : "16px")} 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:hover ${ListActions} {
    opacity: 1;
  }
  ${({ isEditing }) =>
    isEditing &&
    `
  ${NameInputContainer} {
    margin-bottom: -14px;
  }
  `}
`;
