import React, { Component } from "react";
import memoize from "memoize-one";
import _get from "lodash/get";

import SearchBox from "../../../../../ui/SearchBox";
import { escapeStringRegexp } from "../../../../../../utils/stringUtils";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import { MenuContainer, SearchItem, ActionItem } from "../components";
import List from "./List";
import {
  getCreatedId,
  processCreateRequestStatus,
} from "../../../../../../utils/dataComponentUtils";
import { handleCreateContactError } from "../../../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";

export const filterContacts = memoize((items, selectedItems, searchVal) => {
  const selectedIds = selectedItems
    ? selectedItems.filter(item => !!item.contact).map(item => item.contact.id)
    : [];
  const searchReg = new RegExp(escapeStringRegexp(searchVal), "i");
  return items
    .filter(item => !selectedIds.includes(item.contact.id))
    .filter(item =>
      searchReg.test(`${item.contact.firstName} ${item.contact.lastName}`)
    );
});

class AddContactMenu extends Component {
  static propTypes = {
    anchorEl: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    addressBook: PropTypes.shape({}).isRequired,
    vendorContacts: List.propTypes.vendorContacts,
    selectedContacts: List.propTypes.vendorContacts,
    loadVendorContacts: PropTypes.func.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    createPOContact: PropTypes.func.isRequired,
    openCreateContactDialog: PropTypes.func.isRequired,
    contactCreateState: PropTypes.object,
    isCreating: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string,
  };

  state = {
    searchVal: "",
  };

  constructor(props) {
    super(props);
    this.formikPropsRef = React.createRef(null);
  }

  componentDidUpdate({
    vendorContactDataComponent: prevVendorContactDataComponent,
    anchorEl: prevAnchorEl,
  }) {
    const {
      vendorContactDataComponent,
      initDataComponent,
      loadVendorContacts,
      anchorEl,
      showSnackNotificationAction,
    } = this.props;

    processCreateRequestStatus(
      prevVendorContactDataComponent,
      vendorContactDataComponent,
      {
        onSuccess: () => {
          const vendorContactId = getCreatedId(vendorContactDataComponent);
          this.createPOContact(vendorContactId);
          this.props.closeModalDialog();
        },
        onError: handleCreateContactError(
          this.formikPropsRef.current,
          showSnackNotificationAction
        ),
      }
    );

    if (anchorEl && !prevAnchorEl) {
      initDataComponent();
      loadVendorContacts();
    }
  }

  get menuProps() {
    const { anchorEl } = this.props;
    return {
      anchorEl: anchorEl,
      open: Boolean(anchorEl),
      onClose: this.props.handleClose,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    };
  }

  setSearchText = ({ target: { value } }) => {
    this.setState({ searchVal: value });
  };

  get vendorContacts() {
    const { vendorContacts, selectedContacts } = this.props;
    const { searchVal } = this.state;
    return filterContacts(vendorContacts, selectedContacts, searchVal);
  }

  createPOContact = vendorContactId => {
    const {
      createPOContact,
      vendorSpec,
      updateSpecVendorContacts,
      spec,
    } = this.props;
    vendorSpec
      ? updateSpecVendorContacts([
          ...(spec.vendorContacts || []),
          { id: vendorContactId },
        ])
      : createPOContact(vendorContactId);
    this.props.handleClose();
  };

  handleCreateContactClick = () => {
    const { addressBook } = this.props;
    this.props.openCreateContactDialog(addressBook, this.formikPropsRef);
    this.props.handleClose();
  };

  render() {
    const { searchVal } = this.state;
    const { isCreating, type, purchaseOrder, loading } = this.props;
    return (
      <MenuContainer {...this.menuProps}>
        <SearchItem button={false}>
          <SearchBox
            name="searchVal"
            value={searchVal}
            onChange={this.setSearchText}
            autoFocus
          />
        </SearchItem>
        <List
          displayPlaceholder={true}
          isCreating={isCreating}
          vendor={_get(purchaseOrder, "vendor")}
          vendorContacts={this.vendorContacts}
          onClick={this.createPOContact}
          loading={loading}
        />
        {type === "vendor" && (
          <ActionItem onClick={this.handleCreateContactClick}>
            <AddCircleIcon /> CREATE CONTACT
          </ActionItem>
        )}
      </MenuContainer>
    );
  }
}

export default AddContactMenu;
