import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GetApp from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import propTypes from "../../../../constants/propTypes";

import styled from "styled-components";

export const StyledCircularProgress = styled(CircularProgress)`
  color: rgba(0, 0, 0, 0.26);
`;

export const DownloadIcon = styled(GetApp)`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`;

export const RevisionDownloadIcon = ({ onClick, loading, revision }) => {
  const handleClick = useCallback(() => {
    onClick({ revisionId: revision.id });
  }, [onClick, revision.id]);
  return loading ? (
    <StyledCircularProgress size={24} />
  ) : (
    <DownloadIcon onClick={handleClick} />
  );
};

RevisionDownloadIcon.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  revision: propTypes.revision,
};

const mapStateToProps = (state, { revision }) => {
  return {
    loading:
      state.reports.loaders[
        `purchase-order-detail-pdf-false-revision-${revision.id}`
      ],
  };
};

export default connect(mapStateToProps)(RevisionDownloadIcon);
