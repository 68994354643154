import React, { useCallback, useContext } from "react";
import _get from "lodash/get";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { StyledPicker } from "../../../../inputs/DatePickerWithError";
import propTypes from "../../../../../constants/propTypes";
import { LoaderContext } from "../../../Loader";

export const FilterTextField = styled(StyledPicker)`
  & .root {
    font-weight: normal;
    font-size: 14px;
  }
`;

export const EditorComponent = ({ onValueChange, value, ...props }) => {
  const { loading } = useContext(LoaderContext);

  const placeholder = _get(props, "column.placeholder", "All");
  const handleChange = useCallback(date => onValueChange(date), [
    onValueChange,
  ]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FilterTextField
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        pickerType="clearableDate"
        clearable
        InputProps={{ readOnly: loading }}
      />
    </MuiPickersUtilsProvider>
  );
};
EditorComponent.defaultProps = {
  value: null,
};

EditorComponent.propTypes = {
  onValueChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  placeholder: PropTypes.string,
};

const PickerTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(column => {
    const options = columnOptions[column];
    return options.filter === "picker";
  });
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={EditorComponent} />
  ) : null;
};
PickerTypeProvider.propTypes = propTypes.filteringComponent;

export default PickerTypeProvider;
