import { inputs } from "../../../../../../components/inputs/inputConfigs";

export default currency => {
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.amount,
              inputProps: {
                maskProps: { allowNegative: true },
                currency,
              },
            },
            grid: { xs: 4 },
          },
          {
            input: inputs.fundingTypes,
            grid: { xs: 4 },
          },
          {
            input: inputs.paymentReceivedAt,
            grid: { xs: 4 },
          },
          {
            input: inputs.paymentRecord,
            grid: { xs: 8 },
          },
        ],
      },
    ],
  };
};
