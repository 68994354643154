import { useEffect } from "react";
import { isEmpty } from "lodash";

import {
  bidGroupDataComponentId,
  bidGroupVendorDataComponentId,
} from "../Quotes/QuotesPage";
import {
  initBidGroupDataComponent,
  initBidGroupVendorDataComponent,
} from "../Quotes/QuoteFunctions";
import { formatVendorsToSpecRows } from "./analysisFunctions";
import { getExpandedSpecsAndCOMItems } from "./AnalysisPage";

export const useEffects = (
  performRetrieveListRequest,
  performFindRequest,
  initDataComponent,
  destroyDataComponent,
  bidGroupId
) => {
  useEffect(() => {
    initBidGroupDataComponent(initDataComponent);
  }, [initDataComponent]);
  useEffect(() => {
    initBidGroupVendorDataComponent(
      initDataComponent,
      destroyDataComponent,
      true
    );
  }, [destroyDataComponent, initDataComponent]);

  useEffect(() => {
    performFindRequest(bidGroupDataComponentId, bidGroupId);
  }, [performFindRequest, bidGroupId]);
  useEffect(() => {
    performRetrieveListRequest(bidGroupVendorDataComponentId, {
      rootFilters: {
        $where: {
          bidGroupId,
        },
      },
      pageSize: -1,
    });
  }, [performRetrieveListRequest, bidGroupId]);
};
export const initializeSpecAndTotalRows = (
  bidGroup,
  bgVendors,
  selectedCOMs,
  setSelectedCOMs,
  onCOMClicked,
  paymentTerms,
  setSpecRows,
  setTotalRows,
  openAlternateQuotesModal,
  setCOMVisible,
  isSampleColumnsVisible
) => {
  if (!bidGroup || !bgVendors) {
    return;
  }
  const { specs = [] } = bidGroup;

  const { expandedSpecs, comItems } = getExpandedSpecsAndCOMItems(specs);

  const isCOMVisible = !isEmpty(comItems);

  setCOMVisible(isCOMVisible);

  const { specRows, totalRows } = formatVendorsToSpecRows(
    expandedSpecs,
    bgVendors,
    paymentTerms,
    comItems,
    selectedCOMs,
    onCOMClicked,
    openAlternateQuotesModal,
    isCOMVisible,
    isSampleColumnsVisible
  );

  setSpecRows(specRows);
  setTotalRows(totalRows);
};
