import _get from "lodash/get";
import moment from "moment";

import { inputs } from "../../../inputs/inputConfigs";
import {
  getMissingRequiredFieldsForTransition,
  getErrorMessage,
} from "../utils";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const actions = {
  requestPOApproval: ({ setFieldValue, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(purchaseOrder);
    return {
      content: "Request Purchase Order Approval",
      onClick: () => {
        setFieldValue("status", "Pending Approval");
      },
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  approvePO: ({ onOpenApprovalManagerClick, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(purchaseOrder);
    return {
      content: "Approve Purchase Order",
      onClick: onOpenApprovalManagerClick,
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  cancelPO: ({ onCancel, purchaseOrder }) => ({
    content: "Cancel Purchase Order",
    onClick: onCancel,
    disabled: !purchaseOrder.isCancellable,
    disableIfProjectClosed: true,
  }),
  issuePO: ({ setFieldValue, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(purchaseOrder);
    return {
      content: "Issue Purchase Order",
      onClick: () => {
        setFieldValue("status", "Issued");
      },
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  startChangeOrder: ({ setFieldValue }) => ({
    content: "Start Change Order",
    onClick: () => {
      setFieldValue("revisionStatus", "In Progress");
    },
    disableIfProjectClosed: true,
  }),
  requestChangeOrderApproval: ({ setFieldValue, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(
      purchaseOrder,
      [],
      "Request Change Order Approval"
    );
    return {
      content: "Request Change Order Approval",
      onClick: () => {
        setFieldValue("revisionStatus", "Pending Approval");
      },
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  approveChangeOrder: ({ onOpenApprovalManagerClick, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(purchaseOrder);
    return {
      content: "Approve Change Order",
      onClick: onOpenApprovalManagerClick,
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  cancelChangeOrder: ({
    openModalDialog,
    closeModalDialog,
    purchaseOrder,
    onRevert,
  }) => {
    return {
      content: "Cancel Change Order",
      onClick: () => {
        openModalDialog(
          "Cancel Change Order",
          "CancelChangeOrder",
          {
            purchaseOrder: purchaseOrder,
            onSubmit: () => {
              onRevert({ omitUpdateIssueDate: true });
              closeModalDialog();
            },
          },
          false,
          true
        );
      },
      disableIfProjectClosed: true,
    };
  },
  issueChangeOrder: ({ setFieldValue, purchaseOrder }) => {
    const missingFields = getMissingRequiredFieldsForTransition(purchaseOrder);
    return {
      content: "Issue Change Order",
      onClick: () => {
        setFieldValue("revisionStatus", null);
      },
      disabled: Object.keys(missingFields).length > 0,
      title: getErrorMessage(missingFields),
      disableIfProjectClosed: true,
    };
  },
  cancelIssue: ({
    openModalDialog,
    closeModalDialog,
    onRevert,
    purchaseOrder,
  }) => ({
    content: "Cancel Purchase Order Issue",
    disableIfProjectClosed: true,
    onClick: () => {
      openModalDialog(
        "Cancel Purchase Order Issue",
        "CancelIssueDialog",
        {
          purchaseOrderId: purchaseOrder.id,
          onSubmit: () => {
            onRevert({
              revisionId: purchaseOrder.firstRevisionId,
              delete_last_revisions: true,
              revert_last_status: "Pending Approval",
            });
            closeModalDialog();
          },
        },
        false,
        true
      );
    },
  }),
  cancelIssueForApprovedChangeOrder: ({
    setFieldValue,
    purchaseOrder,
    openModalDialog,
    closeModalDialog,
  }) => ({
    content: "Cancel Change Order Issue",
    onClick: () => {
      openModalDialog(
        "Cancel Change Order Issue",
        "CancelIssueDialog",
        {
          purchaseOrderId: purchaseOrder.id,
          changeOrder: true,
          onSubmit: () => {
            setFieldValue("revisionStatus", "Pending Approval");
            closeModalDialog();
          },
        },
        false,
        true
      );
    },
    disabled: !purchaseOrder.isRevised,
    disableIfProjectClosed: true,
  }),
};

const actionsByStatus = {
  Draft: [actions.requestPOApproval, actions.cancelPO],
  "Pending Approval": [actions.approvePO, actions.cancelPO],
  Approved: [actions.issuePO, actions.cancelPO],
  Issued: [
    actions.startChangeOrder,
    actions.cancelPO,
    actions.cancelIssue,
    actions.cancelIssueForApprovedChangeOrder,
  ],
};

const actionsByRevisionStatus = {
  "In Progress": [
    actions.requestChangeOrderApproval,
    actions.cancelChangeOrder,
    actions.cancelPO,
    actions.cancelIssue,
  ],
  "Pending Approval": [
    actions.approveChangeOrder,
    actions.cancelChangeOrder,
    actions.cancelPO,
    actions.cancelIssue,
  ],
  Approved: [
    actions.issueChangeOrder,
    actions.cancelChangeOrder,
    actions.cancelPO,
    actions.cancelIssue,
  ],
};

export function getActions(po, hasContractFiles) {
  if (!hasContractFiles) {
    return [actions.cancelPO];
  }

  if (po.revisionStatus) {
    return actionsByRevisionStatus[po.revisionStatus];
  }

  return actionsByStatus[po.status] || [];
}

export const getMoreActions = ({
  purchaseOrder,
  setFieldValue,
  onOpenApprovalManagerClick,
  onRevert,
  onCancel,
  hasFreightContacts,
  hasVendorContacts,
  hasDesignerContacts,
  hasContractFiles,
  openModalDialog,
  closeModalDialog,
}) => {
  const actions = getActions(purchaseOrder, hasContractFiles);
  const options = actions.map(action =>
    action({
      purchaseOrder: {
        ...purchaseOrder,
        hasFreightContacts,
        hasVendorContacts,
        hasDesignerContacts,
      },
      setFieldValue,
      onOpenApprovalManagerClick,
      onRevert,
      onCancel,
      hasContractFiles,
      openModalDialog,
      closeModalDialog,
    })
  );

  return {
    moreActions: {
      ...inputs.moreActions,
      buttonText: "PO Actions",
      options,
    },
  };
};

function parseDate(purchaseOrder, path) {
  const date = _get(purchaseOrder, path);
  if (!date) return "--";
  return moment(date).format(PRIMARY_DATE_FORMAT);
}

const valueGenerators = {
  createdAt: ({ purchaseOrder }) => ({
    title: "Created Date",
    content: parseDate(purchaseOrder, "createdAt"),
  }),
  approvedAt: ({ purchaseOrder }) => ({
    title: "Approved",
    content: parseDate(purchaseOrder, "approvedAt"),
  }),
  issuedAt: ({ purchaseOrder }) => ({
    title: "Issued",
    content: parseDate(purchaseOrder, "currentRevision.issueDate"),
  }),
};

const datesByStatus = {
  Approved: [valueGenerators.approvedAt],
  Issued: [valueGenerators.approvedAt, valueGenerators.issuedAt],
  Revised: [valueGenerators.approvedAt, valueGenerators.issuedAt],
};

export const getTopValues = ({ purchaseOrder }) => {
  const valuesForState = datesByStatus[purchaseOrder.status] || [];
  return [valueGenerators.createdAt, ...valuesForState].map(action =>
    action({ purchaseOrder })
  );
};
