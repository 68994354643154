import {
  performRetrieveListRequest,
  performFindRequest,
  initDataComponent,
  destroyDataComponent,
} from "../../../../actions/dataComponentActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import {
  getBluechipResourceById,
  getBluechipResources,
} from "../../../../utils/bluechipUtils";
import {
  bidGroupDataComponentId,
  bidGroupVendorDataComponentId,
} from "../Quotes/QuotesPage";
import * as DATA_COMPONENTS from "../../../../constants/DataComponents";

const defaultArray = [];

export const mapStateToProps = (
  state,
  {
    match: {
      params: { bidGroupId },
    },
  }
) => {
  const bidGroupDataComponent = getDataComponentFlattenedRequestState(
    bidGroupDataComponentId,
    state
  );
  const dataComponent = getDataComponentFlattenedRequestState(
    bidGroupVendorDataComponentId,
    state
  );
  const paymentTermsDC = getDataComponentFlattenedRequestState(
    DATA_COMPONENTS.PAYMENT_TERMS_SELECT,
    state
  );

  return {
    bidGroupId,
    dataComponent,
    bidGroup:
      getBluechipResourceById(bidGroupDataComponent, state, bidGroupId) || {},
    bidGroupVendors: getBluechipResources(dataComponent, state) || defaultArray,
    paymentTerms: getBluechipResources(paymentTermsDC, state) || defaultArray,
    loading: dataComponent.loading,
  };
};

export const mapDispatchToProps = {
  performRetrieveListRequest,
  performFindRequest,
  initDataComponent,
  destroyDataComponent,
};
