import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const SpanError = styled.span`
  color: red;
`;

export const QuantityError = ({ errorMessage }) => {
  if (!errorMessage) return null;
  return <SpanError>{errorMessage}</SpanError>;
};
QuantityError.propTypes = {
  errorMessage: PropTypes.string,
};
