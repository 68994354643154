import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox, Switch } from "../mui/core";
import styled from "styled-components";
import { useLabeledText } from "./TextInputWithError";

export const StyledFormControlLabel = styled(({ alignSelf, ...props }) => (
  <FormControlLabel {...props} />
))`
  margin-left: 0px;
  ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf};` : "")}
  > .label {
    margin-left: 0px;
  }
`;

function isChecked(value, checkValues) {
  return checkValues.check === value;
}

function onChangeWrapper(event, checkValues, fn, handleChange) {
  const isChecked = event.target.checked;
  const checkValue = isChecked ? checkValues.check : checkValues.uncheck;
  const parsedEvent = {
    ...event,
    target: { name: event.target.name, value: checkValue },
  };
  fn(parsedEvent);
  handleChange && handleChange(parsedEvent);
}

const CheckboxWithError = ({
  label,
  value,
  checkValues = {
    check: true,
    uncheck: false,
  },
  onChange,
  handleChange,
  error,
  errorMessage,
  ignoreCaption,
  InputProps,
  isNotesFilter,
  useSwitch,
  formControlLabelProps,
  ignoreLabeledText,
  disabled,
  ...checkboxProps
}) => {
  const Control = useSwitch ? Switch : Checkbox;

  const labeledText = useLabeledText(ignoreLabeledText) || disabled;

  return (
    <StyledFormControlLabel
      control={
        <Control
          {...checkboxProps}
          checked={isChecked(value, checkValues)}
          color="primary"
          onChange={event =>
            onChangeWrapper(event, checkValues, onChange, handleChange)
          }
          disabled={labeledText}
        />
      }
      label={label}
      isNotesFilter
      {...formControlLabelProps}
    />
  );
};

CheckboxWithError.defaultProps = {
  formControlLabelProps: {},
};

CheckboxWithError.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.bool,
  ignoreLabeledText: PropTypes.bool,
};

export default CheckboxWithError;
