import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Container, Wrapper } from "./components";
import VerifyButton from "./VerifyButton";
import { VerifyCode } from "./VerifyCode";
import { usePrevious } from "../../../hooks/usePrevious";

const shouldDisplayNull = ({
  cellNumber,
  originalCellNumber,
  isCellNumberVerified,
  verificationCodeValidated,
}) =>
  !cellNumber ||
  (cellNumber === originalCellNumber &&
    (isCellNumberVerified || verificationCodeValidated));

const shouldUpdate = (prev, current) => prev !== undefined && current !== prev;

export const VerifyCellPhoneNumber = ({
  isCellNumberVerified,
  verificationCodeSent: codeSent,
  cellNumber: originalCellNumber,
  validateVerificationCode,
  verificationCodeValidated: codeValidated,
  validatingVerificationCode,
  login,
}) => {
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCodeValidated, setVerificationCodeValidated] = useState(
    false
  );

  const prevCodeSent = usePrevious(codeSent);
  const prevCodeValidated = usePrevious(codeValidated);

  useEffect(() => {
    if (shouldUpdate(prevCodeSent, codeSent)) {
      setVerificationCodeSent(codeSent);
    }
  }, [codeSent, prevCodeSent]);

  useEffect(() => {
    if (shouldUpdate(prevCodeValidated, codeValidated)) {
      setVerificationCodeValidated(codeValidated);
    }
  }, [codeValidated, prevCodeValidated]);

  const {
    values: { cellNumber },
  } = useFormikContext();

  if (
    shouldDisplayNull({
      cellNumber,
      originalCellNumber,
      isCellNumberVerified,
      verificationCodeValidated,
    })
  ) {
    return null;
  }

  return (
    <Container>
      <VerifyButton
        cellNumber={cellNumber}
        validatingVerificationCode={validatingVerificationCode}
      />
      <Wrapper>
        <VerifyCode
          verificationCodeSent={verificationCodeSent}
          validateVerificationCode={validateVerificationCode}
          validatingVerificationCode={validatingVerificationCode}
          verificationCodeValidated={verificationCodeValidated}
          login={login}
        />
      </Wrapper>
    </Container>
  );
};

VerifyCellPhoneNumber.propTypes = {
  isCellNumberVerified: PropTypes.bool,
  verificationCodeSent: PropTypes.bool,
  cellNumber: PropTypes.string,
  validateVerificationCode: PropTypes.func,
  verificationCodeValidated: PropTypes.bool,
  validatingVerificationCode: PropTypes.bool,
  login: PropTypes.bool,
};
