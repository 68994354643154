import Vendor from "../../../../models/Vendor";
import {
  buildVendorContactFilter,
  warehouseDCID,
  warehouseContactsDCId,
} from "../../../inputs/inputConfigs/projects";
import SelectWithError from "../../../inputs/SelectWithError";
import UserSelect from "./UserSelect";

const warehouseVendorType = "Warehouse Vendor";

export const warehouseFields = (project, syncProjectMemberContacts) => ({
  warehouse: {
    InputComponent: SelectWithError,
    name: "warehouseVendorId",
    label: "Warehouse",
    dataComponentId: warehouseDCID,
    fullWidth: true,
    isAutocomplete: true,
    isOptionDisabled: Vendor.isVendorDisabled,
    handleChange: syncProjectMemberContacts,
    apiFilterLabelProperty: "name",
    labelProperty: Vendor.labelProperty,
  },
  warehouseVendorContacts: {
    InputComponent: UserSelect,
    name: "projectMemberContacts",
    label: "Select Contacts",
    dataComponentId: warehouseContactsDCId,
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    submitData: true,
    valueProperty: "contactId",
    filter: buildVendorContactFilter(
      project.warehouseVendorId,
      warehouseVendorType
    ),
    memberType: warehouseVendorType,
  },
});
