import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Shipment from "../../../../../models/Shipment";
import propTypes from "../../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../../actions/layoutActions";
import {
  initDataComponent,
  performDeleteRequest,
} from "../../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../../ui/DeleteConfirmation";

export const dataComponentId = "DeleteShipmentDC";

export class DeleteShipmentContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    shipmentId: PropTypes.string.isRequired,
    dataComponent: propTypes.dataComponent.isRequired,
    performDeleteRequest: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    showSnackNotificationAction: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, Shipment, [], "shipments");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteShipment = () => {
    const { shipmentId, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, shipmentId);
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.deleteShipment} />;
  }
}

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteShipmentContainer);
