import {
  processCreateRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import {
  handleError,
  handleSuccess,
  handleSuccessForBidGroupVendorSpecQuote,
  handleSuccessForBidGroupVendorSpecs,
  handleSuccessForPurchaseOrder,
} from "./QuotingRequestHandlers";

export const processSaveRequest = (
  prevDataComponent,
  dataComponent,
  bidGroupDataComponentId,
  bidGroupId,
  {
    fetchData,
    closeModalDialog,
    showSnackNotificationAction,
    snackMessage,
    reloadBidGroupVendorSpecs,
  }
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        bidGroupId,
        bidGroupDataComponentId,
        closeModalDialog,
        showSnackNotificationAction,
        snackMessage,
        reloadBidGroupVendorSpecs
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        fetchData,
        bidGroupId,
        bidGroupDataComponentId,
        closeModalDialog,
        showSnackNotificationAction,
        snackMessage
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processSaveRequestForBidGroupVendorSpecs = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  { fetchData, showSnackNotificationAction }
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForBidGroupVendorSpecs(fetchData, dataComponentId);
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processSaveRequestForBidGroupVendorSpecQuotes = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  bidGroupId,
  { fetchData, closeModalDialog, showSnackNotificationAction, snackMessage }
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForBidGroupVendorSpecQuote(
        fetchData,
        bidGroupId,
        dataComponentId,
        showSnackNotificationAction,
        snackMessage,
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForBidGroupVendorSpecQuote(
        fetchData,
        bidGroupId,
        dataComponentId,
        showSnackNotificationAction,
        snackMessage,
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processUpdateRequestForPurchaseOrder = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  {
    fetchData,
    showSnackNotificationAction,
    snackMessage,
    closeModalDialog,
    reloadBidGroupVendorSpecs,
  }
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForPurchaseOrder(
        fetchData,
        dataComponentId,
        showSnackNotificationAction,
        snackMessage,
        closeModalDialog,
        reloadBidGroupVendorSpecs
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processUpdateRequestForSpec = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  { fetchData, showSnackNotificationAction, snackMessage, closeModalDialog }
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccessForPurchaseOrder(
        fetchData,
        dataComponentId,
        showSnackNotificationAction,
        snackMessage,
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
