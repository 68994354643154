import TextInputWithError from "../../inputs/TextInputWithError";
import SelectWithError from "../SelectWithError";
import User from "../../../models/User";

export default {
  teamName: {
    InputComponent: TextInputWithError,
    label: "Name",
    name: "name",
    fullWidth: true,
    autoFocus: true,
    required: true,
  },
  teamUsers: {
    InputComponent: SelectWithError,
    label: "Users",
    name: "users",
    dataComponentId: "select-users-team",
    multiple: true,
    fullWidth: true,
    isAutocomplete: true,
    isAutocompleteMulti: true,
    valueProperty: "id",
    labelProperty: "name",
    submitData: true,
    APIOptions: {
      model: User,
      route: "users",
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
      fields: ["users.id", "name"],
      rootFilters: {
        $where: {
          isInternalUser: true,
        },
      },
    },
  },
};
