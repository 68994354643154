import styled from "styled-components";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Button, IconButton } from "@material-ui/core";

const Icon = styled.svg`
  margin-left: 0px;
`;

export const StyledNavigationArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px 0;
`;

export const BackButtonIcon = styled(ArrowBack)`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;

export const ActionButton = styled(IconButton)`
  color: rgb(87, 171, 255);
  padding: 8px;
  margin-left: 8px;

  & ${Icon} {
    margin-left: 0px;
  }
`;

export const CycleButton = styled(IconButton)`
  color: rgb(0, 0, 0);
  padding: 8px;
  margin-left: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const BackButton = styled(Button)`
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgb(87, 171, 255);
  padding: 8px 8px 8px 0;
`;
