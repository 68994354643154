import React, { useMemo, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";
import propTypes from "../../../../../constants/propTypes";
import { bidGroupSpecDetailId } from "../../BidGroupList/BidGroupListContainer";
import {
  performUpdateRequest,
  performDeleteRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import pluralize from "pluralize";
import {
  closeModalDialog,
  openModalDialog,
} from "../../../../../actions/layoutActions";
import { quotingBidGroupsDCId } from "../../../../../actions/quotingActions";

const render = (triggerLabel, options) => {
  return <DropdownMenu buttonText={triggerLabel} options={options} />;
};

const buildOptions = (
  bidGroups,
  selectedIds,
  onRemoveFromBidGroup,
  onOpenDeleteBidGroupModal,
  onOpenCreateBidModal,
  onOpenEditBidModal,
  onMoveToBidGroup,
  onUploadQuote,
  isBidGroupOption,
  { create, edit, remove, add }
) => {
  const disabled = selectedIds.length === 0;

  const selectedIdSet = new Set(selectedIds);
  const filteredBidGroups = bidGroups.filter(
    ({ bidGroupSpecs }) =>
      !bidGroupSpecs.some(({ specId }) => selectedIdSet.has(specId))
  );

  const options = [];

  if (isBidGroupOption) {
    options.push(
      {
        content: edit,
        onClick: onOpenEditBidModal,
        disableIfProjectClosed: true,
      },
      {
        content: "Delete Bid Group",
        onClick: onOpenDeleteBidGroupModal,
        useSeparator: true,
        disableIfProjectClosed: true,
      },
      {
        content: "Upload Quote",
        onClick: onUploadQuote,
        disabled,
        disableIfProjectClosed: true,
      },
      {
        disabled: selectedIds.length === 0,
        content: remove,
        onClick: onRemoveFromBidGroup(),
        disableIfProjectClosed: true,
      }
    );
  }
  options.push({
    disabled,
    content: create,
    onClick: onOpenCreateBidModal,
    useSeparator: true,
    disableIfProjectClosed: true,
  });
  options.push({
    content: add,
    isLabel: true,
    disabled: true,
    disableIfProjectClosed: true,
  });
  filteredBidGroups.map(bidGroup => {
    options.push({
      disabled,
      content: `Bid Group ${bidGroup.name} (${bidGroup.specsCount})`,
      onClick: onMoveToBidGroup(bidGroup.id),
      disableIfProjectClosed: true,
    });
  });

  return options;
};

export const QuotingActions = ({
  bidGroups,
  onOpenCreateBidModal,
  onOpenEditBidModal,
  performUpdateRequest,
  performDeleteRequest,
  openModalDialog,
  closeModalDialog,
  setReload,
  triggerLabel,
  labelOptions,
  isBidGroupOption,
  currentBidGroup,
  selectedIds,
  resetSelection,
}) => {
  const handleMoveToBidGroup = useCallback(
    bidGroupId => () => {
      if (currentBidGroup) {
        const filteredBidGroupSpecs = currentBidGroup.bidGroupSpecs.filter(
          ({ specId }) => selectedIds.includes(specId)
        );

        performDeleteRequest(
          bidGroupSpecDetailId,
          filteredBidGroupSpecs.map(({ id }) => id)
        );
        resetSelection();
      }

      performUpdateRequest(
        bidGroupSpecDetailId,
        selectedIds.map(specId => ({
          bidGroupId,
          specId,
        }))
      );
    },
    [
      currentBidGroup,
      performDeleteRequest,
      performUpdateRequest,
      resetSelection,
      selectedIds,
    ]
  );
  const handleRemoveFromBidGroup = useCallback(
    () => () => {
      if (currentBidGroup) {
        const filteredBidGroupSpecs = currentBidGroup.bidGroupSpecs.filter(
          ({ specId }) => selectedIds.includes(specId)
        );
        performDeleteRequest(
          bidGroupSpecDetailId,
          filteredBidGroupSpecs.map(({ id }) => id)
        );
        resetSelection();
      }
    },
    [currentBidGroup, performDeleteRequest, resetSelection, selectedIds]
  );

  const handleUploadQuote = useCallback(() => {
    openModalDialog(
      [
        "Upload Quote",
        `${pluralize("Spec", selectedIds.length, true)} Selected`,
      ],
      "BulkQuoteUpload",
      { selectedIds: selectedIds },
      false
    );
  }, [selectedIds, openModalDialog]);

  const handleOpenDeleteBidGroupModal = useCallback(() => {
    openModalDialog(
      "Delete Bid Group",
      "GeneralConfirmation",
      {
        title: `Are you sure you want to delete BG ${currentBidGroup.nameWithSequenceIdentifier}?`,
        sendButtonText: "Cancel",
        dangerButtonText: "Delete",
        onSubmit: closeModalDialog,
        onDanger: () => {
          performDeleteRequest(quotingBidGroupsDCId, currentBidGroup.id);
          setReload(quotingBidGroupsDCId, true);
          closeModalDialog();
        },
        hideCancelButton: true,
      },
      false,
      true
    );
  }, [
    closeModalDialog,
    currentBidGroup,
    openModalDialog,
    performDeleteRequest,
    setReload,
  ]);

  const options = useMemo(
    () =>
      buildOptions(
        bidGroups,
        selectedIds,
        handleRemoveFromBidGroup,
        handleOpenDeleteBidGroupModal,
        onOpenCreateBidModal,
        onOpenEditBidModal,
        handleMoveToBidGroup,
        handleUploadQuote,
        isBidGroupOption,
        labelOptions
      ),
    [
      bidGroups,
      selectedIds,
      handleRemoveFromBidGroup,
      handleOpenDeleteBidGroupModal,
      onOpenCreateBidModal,
      onOpenEditBidModal,
      handleMoveToBidGroup,
      handleUploadQuote,
      isBidGroupOption,
      labelOptions,
    ]
  );

  return render(triggerLabel, options);
};

QuotingActions.propTypes = {
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  onOpenCreateBidModal: PropTypes.func.isRequired,
  onOpenEditBidModal: PropTypes.func,
  performUpdateRequest: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  triggerLabel: PropTypes.string,
  labelOptions: PropTypes.shape({}),
  isBidGroupOption: PropTypes.bool,
  currentBidGroup: propTypes.bidGroup,
};

QuotingActions.defaultProps = {
  triggerLabel: "Actions",
  labelOptions: {
    create: "Create Bid Group",
    add: "Add To",
  },
  isBidGroupOption: false,
};

const mapDispatchToProps = {
  performUpdateRequest,
  performDeleteRequest,
  openModalDialog,
  closeModalDialog,
  setReload,
};

export default connect(null, mapDispatchToProps)(QuotingActions);
