import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";

import {
  isPendingRequest,
  isFailedRequest,
  isSuccessfulRequest,
} from "../../../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import {
  initDetailSpecRow,
  saveDetailSpecRowChanges,
} from "../../../../../../actions/specsActions";
import * as REQUEST_TYPES from "../../../../../../constants/RequestTypes";
import DetailComponent from "./DetailComponent";

const statusChecker = [
  { status: "pending", checkFn: isPendingRequest },
  { status: "success", checkFn: isSuccessfulRequest },
  { status: "fail", checkFn: isFailedRequest },
];

export const mapStateToProps = (state, { row, idPath, dataComponentId }) => {
  const itemId = _get(row, idPath);

  const dataComponent = getDataComponent(dataComponentId, state);
  let currentStatus = statusChecker.find(({ checkFn }) =>
    checkFn(dataComponent, REQUEST_TYPES.UPDATE)
  );

  return {
    itemId,
    status: _get(currentStatus, "status"),
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        initRow: initDetailSpecRow,
        updateRow: saveDetailSpecRowChanges,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailComponent);
