import React, { Component } from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BWModels from "benjaminwest-models";

import Entity from "../../../models/Entity";
import PropertyForm from "../PropertyCreate/PropertyForm";
import { mapStateToProps } from "../PropertyEdit/PropertyEditContainer";
import {
  initDataComponent,
  performCreateRequest,
  performFindRequest,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";
import { setHeaderTitle } from "../../../actions/layoutActions";
import Property from "../../../models/Property";
import propTypes from "../../../constants/propTypes";
import { handleRequestError } from "../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../utils/dataComponentUtils";

const dataComponentId = "PropertyCreate";

const cleanProperty = memoizeOne(srcProperty => {
  if (!srcProperty || !srcProperty.id) return srcProperty;
  const {
    id,
    name,
    locationId,
    location: { id: _locationId, ...location } = {},
    ...property
  } = srcProperty;
  return {
    name: `DUPLICATED: ${name}`,
    entityId: property.entityId,
    location,
  };
});

export class PropertyDuplicateContainer extends Component {
  componentDidMount() {
    const {
      initDataComponent,
      performFindRequest,
      performRetrieveListRequest,
      propertyId,
      clientId,
      property,
      setHeaderTitle,
    } = this.props;
    initDataComponent(dataComponentId, Property, ["location"], "properties");
    initDataComponent("select-entities", Entity, [], "entities");
    performRetrieveListRequest("select-entities", {
      rootFilters: { $where: { clientId } },
      pageSize: -1,
      sort: [{ columnName: "name", direction: "asc" }],
    });

    performFindRequest(dataComponentId, propertyId);
    if (property.id) {
      setHeaderTitle(`Duplicate Property - #${property.id} ${property.name}`);
    }
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
  }

  handleCreateSuccess = () => {
    const { clientId } = this.props;
    this.props.push(`/clients/${clientId}/properties`);
  };

  componentDidUpdate({
    dataComponent: prevDataComponent,
    property: prevProperty,
  }) {
    const { dataComponent, property, setHeaderTitle } = this.props;
    if (prevProperty.id !== property.id) {
      setHeaderTitle(`Duplicate Property - #${property.id} ${property.name}`);
    }

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  createProperty = (property, formikActions) => {
    const { performCreateRequest } = this.props;
    performCreateRequest(dataComponentId, property);
    this.setState({ formikActions });
  };

  render() {
    const { property, clientId, loading } = this.props;
    const propertySchema = BWModels.loadSchema("Property");
    return (
      <PropertyForm
        clientId={clientId}
        initialValues={cleanProperty(property)}
        propertySchema={propertySchema}
        onSave={this.createProperty}
        loading={loading}
        ignoreCache={true}
        dataComponentId={dataComponentId}
      />
    );
  }
}

PropertyDuplicateContainer.propTypes = {
  property: propTypes.property,
  push: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent,
  propertyId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  setHeaderTitle: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performCreateRequest: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func,
  loading: PropTypes.bool,
};

const mapDispatchToProps = {
  initDataComponent,
  performCreateRequest,
  performFindRequest,
  performRetrieveListRequest,
  setHeaderTitle,
  push,
};

export default withRouter(
  connect(
    mapStateToProps(dataComponentId),
    mapDispatchToProps
  )(PropertyDuplicateContainer)
);
