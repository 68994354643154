import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";

import ProjectDetailPage from "./ProjectDetailPage";
import { openModalDialog } from "../../../actions/layoutActions";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";

import {
  initDataComponent,
  performFindRequest,
} from "../../../actions/dataComponentActions";
import { dataComponentId } from "../../../actions/projectsActions";
import { getDataComponentFlattenedRequestState } from "../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../utils/bluechipUtils";
import { openNotesModal } from "../../../actions/notesActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";

import { fetchClientById } from "../../../actions/projectsActions";

export { dataComponentId } from "../../../actions/projectsActions";

const dataClientComponentId = "ClientDetail";

export class ProjectDetailContainer extends Component {
  goToProjectSettings = () => {
    const { project } = this.props;
    this.props.pushWithReturnUrl(
      `/clients/${project.property.entity.clientId}/projects/${project.id}/settings`,
      "PROJECT DETAIL"
    );
  };

  openCOMModal = () => {
    this.props.openModalDialog(
      false,
      "COMLibrary",
      {
        projectId: this.props.project.id,
        clientId: this.props.project.property.entity.clientId,
      },
      false,
      false,
      {
        layoutType: "wide",
        freezeBackground: true,
      }
    );
  };

  openReportsModal = () => {
    this.props.openModalDialog(
      "Generate Report",
      "ReportsContainer",
      {
        projectId: this.props.project.id,
      },
      false,
      true,
      {
        width: "629px",
      }
    );
  };

  handleOpenNotesModal = () => {
    const { projectId, openNotesModal } = this.props;
    openNotesModal({ projectId });
  };

  componentDidMount() {
    const { clientId, fetchClientById } = this.props;

    fetchClientById(dataClientComponentId, clientId);
  }

  render() {
    const { project, route, match, role, client } = this.props;
    return (
      <ProjectDetailPage
        match={match}
        client={client}
        route={route}
        role={role}
        project={project}
        openCOMModal={this.openCOMModal}
        openReportsModal={this.openReportsModal}
        goToProjectSettings={this.goToProjectSettings}
        onOpenNotesModal={this.handleOpenNotesModal}
      />
    );
  }
}

ProjectDetailContainer.propTypes = {
  openModalDialog: PropTypes.func,
  match: PropTypes.object.isRequired,
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  projectId: PropTypes.string,
  project: propTypes.project,
  pushWithReturnUrl: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  route: propTypes.route,
  role: propTypes.userRole,
  client: PropTypes.object,
  clientId: PropTypes.string,
  fetchClientById: PropTypes.func,
};

export const mapDispatchToProps = {
  openModalDialog,
  initDataComponent,
  fetchClientById,
  performFindRequest,
  openNotesModal,
  pushWithReturnUrl,
};

export const mapStateToProps = (state, ownProps) => {
  const clientDataComponent = getDataComponentFlattenedRequestState(
    dataClientComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const { projectId, clientId } = ownProps.match.params;
  const client = getBluechipResourceById(clientDataComponent, state, clientId);

  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  return {
    role: state.auth.role,
    projectId,
    client,
    clientId,
    project: getBluechipResourceById(dataComponent, state, projectId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailContainer);
