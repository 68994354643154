import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import ForecastByAreaSection, {
  forecastAreaComponentId,
} from "./ForecastByAreaSection";
import { fetchForecastByArea } from "../../../../../../actions/projectsActions";
import {
  fetchAreasByProject,
  areaDataComponentId,
} from "../../../../../../actions/areasActions";

import { getDataComponent } from "../../../../../../reducers/dataComponentReducer";
import { getBluechipResourcesByType } from "../../../../../../utils/bluechipUtils";

export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const dataComponent = getDataComponent(forecastAreaComponentId, state);
  return {
    dataComponent,
    projectId,
    areas: getBluechipResourcesByType(areaDataComponentId, state) || [],
    forecastByAreas:
      getBluechipResourcesByType(forecastAreaComponentId, state) || [],
  };
};

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchForecastByArea,
      fetchAreasByProject,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForecastByAreaSection)
);
