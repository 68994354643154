import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import FormGrid from "../../../../components/forms/FormGrid";
import createRemitAddressFields from "./createRemitAddressFields";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";

export default class CreateRemitAddressForm extends Component {
  static propTypes = {
    createAddress: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    addressSchema: PropTypes.object.isRequired,
  };

  remitAddressInitialValues = {
    name: "",
    address: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  };

  _handleCancel = () => {
    this.props.closeModalDialog();
  };

  render() {
    const { createAddress, addressSchema } = this.props;

    return (
      <FormikForm
        initialValues={this.remitAddressInitialValues}
        onSubmit={createAddress}
        validationSchema={addressSchema}
        ignoreValidationFieldNames={["categoryId", "vendorId"]}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <FormGrid
              fields={createRemitAddressFields()}
              values={values}
              errors={errors}
              {...formikProps}
            />
            <ActionButtons
              onSend={handleSubmit}
              sendButtonText="CREATE ADDRESS"
              onCancel={this._handleCancel}
              isModal={true}
            />
          </Fragment>
        )}
      </FormikForm>
    );
  }
}
