import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Ellipsis = styled.div`
  display: inline-block;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

const LinesEllipsis = ({ children, width, maxWidth }) => (
  <Ellipsis width={width} title={children} maxWidth={maxWidth}>
    {children}
  </Ellipsis>
);

LinesEllipsis.propTypes = {
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};

export default LinesEllipsis;
