import { appPageBuilder, redirectTo } from "./utils";
import ConsultingCompanies from "../components/pages/ConsultingCompanies";
import ConsultingCompanyDetail from "../components/pages/ConsultingCompanyDetail";
import Projects from "../components/pages/ConsultingCompanyDetail/Projects";
import Contacts from "../components/pages/ConsultingCompanyDetail/Contacts";

const AppConsultingCompanies = appPageBuilder(
  ConsultingCompanies,
  "Consulting Companies"
);

const AppConsultingCompanyDetail = appPageBuilder(
  ConsultingCompanyDetail,
  "Consulting Companies",
  true
);

export default [
  {
    path: "/consultingCompanies",
    component: AppConsultingCompanies,
    exact: true,
  },
  {
    path: "/consultingCompanies/:consultingCompanyId",
    component: AppConsultingCompanyDetail,
    routers: [
      {
        path: "/consultingCompanies/:consultingCompanyId/projects",
        component: Projects,
      },
      {
        path: "/consultingCompanies/:consultingCompanyId/contacts",
        component: Contacts,
        exact: true,
      },
      //default
      {
        path: "/consultingCompanies/:consultingCompanyId",
        component: redirectTo("projects"),
        exact: true,
      },
    ],
  },
];
