import React, { useContext } from "react";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import _get from "lodash/get";

import {
  ComMain,
  ComIcon,
  ComInfo,
  ComType,
  Id,
  ItemWrapper,
  Notes,
  Title,
} from "./components";
import DeleteButton from "../DeleteButton";
import {
  LabeledTextContext,
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";
import Menu, { StyledIconButton } from "../../../ui/DragAndDropList/Menu";
import { connect } from "react-redux";
import { openEditSpecDetailComNotes } from "../../../../actions/specDetailActions";
import { Image } from "../../../ui/Image";
import { COM_PREVIEW } from "../../../../constants/specs";

const shouldDisplayMenu = (labeledText, isDeleted) =>
  !labeledText && !isDeleted;

const ItemRow = ({
  specDetailCom: { __deleted__, __parent_deleted__, ...specDetailCom },
  specDetail,
  openEditSpecDetailComNotes,
  purchaseOrder,
}) => {
  const labeledText = useContext(LabeledTextContext);
  const { isCreated, isUpdated } = useContext(PORevisionContext);
  const { reference } = useContext(RevisionReferenceContext);
  const comReference = `${reference}.${_get(specDetailCom, "id")}`;

  const specDetails = Object.values(specDetailCom.spec.specDetails) || [];
  const preview = specDetails.find(specDetail => specDetail.type == "Preview")
    ?.preview;

  return (
    <ItemWrapper isDeleted={__deleted__} isCreated={isCreated(comReference)}>
      <ComMain>
        <ComInfo>
          <Image
            file={preview}
            ImageComponent={ComIcon}
            placeholder={COM_PREVIEW}
          />
          <Title>
            <Id>{_get(specDetailCom, "spec.customNumber")}</Id>
            <span>{_get(specDetailCom, "spec.description")}</span>
            <ComType>{_get(specDetailCom, "spec.specCategory.name")}</ComType>
          </Title>
        </ComInfo>
        <Notes isUpdated={isUpdated(comReference, "notes")}>
          {specDetailCom.notes}
        </Notes>
      </ComMain>
      {shouldDisplayMenu(labeledText, __deleted__ || __parent_deleted__) && (
        <Menu>
          <StyledIconButton
            onClick={() =>
              openEditSpecDetailComNotes(
                specDetailCom,
                specDetail,
                purchaseOrder
              )
            }
          >
            <Edit />
          </StyledIconButton>
          <DeleteButton
            specDetail={specDetail}
            specDetailCom={specDetailCom}
            purchaseOrderId={purchaseOrder && purchaseOrder.id}
          />
        </Menu>
      )}
    </ItemWrapper>
  );
};

ItemRow.propTypes = {
  specDetailCom: propTypes.specDetailCom,
  specDetail: propTypes.specDetail,
  openEditSpecDetailComNotes: PropTypes.func.isRequired,
  purchaseOrder: propTypes.purchaseOrder,
};

export const mapDispatchToProps = { openEditSpecDetailComNotes };

export default connect(null, mapDispatchToProps)(ItemRow);
