import React, { Fragment } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";

import Date from "../../../../ui/BWGrid/columns/Date";
import { inputs } from "../../../../inputs/inputConfigs";
import propTypes from "../../../../../constants/propTypes";
import { getDateInput } from "../CreateShipment/createShipmentFields";
import {
  ShipmentFutureDeliveryWarning,
  WarningIcon,
} from "../SpecShipments/components";
import { getPaidShipmentMessage } from "../SpecShipments/ApprovedToPay";
import Tooltip from "../../../../mui/core/Tooltip";
import { Error } from "../../../../inputs/LabeledPONumber";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

export const isPaidShipment = (spec, totalQuantityApprovedToPay, quantity) => {
  if (!spec || !spec.totalQuantityPaid) {
    return false;
  }
  return spec.totalQuantityPaid > totalQuantityApprovedToPay - quantity;
};

export const isEditable = (spec, totalQuantityApprovedToPay) => (
  { name },
  { row: { quantity, approvedToPay } }
) => {
  if (name !== "quantity") {
    return true;
  }
  return (
    !approvedToPay ||
    !isPaidShipment(spec, totalQuantityApprovedToPay, quantity)
  );
};

export const columns = [
  "quantity",
  "estimatedShipDate",
  "estimatedDeliveryDate",
  "actualShipDate",
  "actualDeliveryDate",
  "approvedToPay",
];

const renderDate = date => <Date date={date} format={PRIMARY_DATE_FORMAT} />;

export const ApprovedToPay = ({
  approvedToPay,
  spec,
  quantity,
  totalQuantityApprovedToPay,
}) => {
  const paidShipment =
    approvedToPay && isPaidShipment(spec, totalQuantityApprovedToPay, quantity);
  if (!approvedToPay) {
    return "No";
  }

  return (
    <Fragment>
      Yes
      {paidShipment && (
        <Tooltip title={getPaidShipmentMessage(paidShipment)}>
          <Error />
        </Tooltip>
      )}
    </Fragment>
  );
};
ApprovedToPay.propTypes = {
  approvedToPay: PropTypes.bool,
  spec: propTypes.spec,
  quantity: PropTypes.number,
  totalQuantityApprovedToPay: PropTypes.number,
};

export const getColumnOptions = (spec, totalQuantityApprovedToPay) => ({
  quantity: {
    editable: true,
    sortingEnabled: false,
    render: shipment => shipment.quantity,
  },
  estimatedShipDate: {
    title: "Estimated Ship",
    editable: true,
    sortingEnabled: false,
    render: shipment => renderDate(shipment.estimatedShipDate),
  },
  estimatedDeliveryDate: {
    title: "Estimated Delivery",
    editable: true,
    sortingEnabled: false,
    render: shipment => renderDate(shipment.estimatedDeliveryDate),
    OuterComponent: function getOuterComponent(props) {
      return (
        <WarningIcon
          {...props}
          row={{ shipment: props.row }}
          fieldName={"estimatedDeliveryDate"}
          targetField={"estimatedShipDate"}
        />
      );
    },
  },
  actualShipDate: {
    title: "Actual Ship",
    editable: true,
    sortingEnabled: false,
    render: shipment => renderDate(shipment.actualShipDate),
  },
  actualDeliveryDate: {
    title: "Actual Delivery",
    editable: true,
    sortingEnabled: false,
    render: shipment => renderDate(shipment.actualDeliveryDate),
    OuterComponent: function getOuterComponent(props) {
      return (
        <Fragment>
          <WarningIcon
            {...props}
            row={{ shipment: props.row }}
            fieldName={"actualDeliveryDate"}
            targetField={"actualShipDate"}
          />
          <ShipmentFutureDeliveryWarning
            {...props}
            row={{ shipment: props.row }}
            fieldName={"actualDeliveryDate"}
          />
        </Fragment>
      );
    },
  },
  approvedToPay: {
    title: "Approved To Pay",
    sortingEnabled: false,
    render: function getApprovedToPay(props) {
      return (
        <ApprovedToPay
          {...props}
          spec={spec}
          totalQuantityApprovedToPay={totalQuantityApprovedToPay}
        />
      );
    },
  },
});

export const getRowMenu = (
  onEditShipment,
  onDeleteShipment,
  onOpenNotesModal,
  onAddTrackingNote,
  spec,
  totalQuantityApprovedToPay
) => row => [
  {
    text: "Edit Shipment",
    onClick: onEditShipment,
    disableIfProjectClosed: true,
  },
  {
    text: "View All Notes",
    onClick: onOpenNotesModal,
    disabled: !row.id,
    disableIfProjectClosed: true,
  },
  {
    text: "Add Tracking Note",
    onClick: onAddTrackingNote,
    disabled: !row.id,
    disableIfProjectClosed: true,
  },
  { separator: true },
  {
    text: "Delete",
    onClick: onDeleteShipment,
    disableIfProjectClosed: true,
    disabled:
      row.approvedToPay &&
      isPaidShipment(spec, totalQuantityApprovedToPay, row.quantity),
  },
];

export const getHeaderText = rows => {
  return pluralize("Shipment", rows.length, true);
};

export const buildRowInputs = (setFieldValue, tableRow) => ({
  quantity: { ...inputs.shipmentQty, limitType: null },
  estimatedShipDate: {
    ...getDateInput(
      inputs.estimatedShipDate,
      "estimatedDeliveryDate",
      tableRow.row,
      () =>
        setFieldValue(
          `shipments[${tableRow.rowId}].estimatedDeliveryDate`,
          null
        )
    ),
    isApplyOnChange: true,
  },
  estimatedDeliveryDate: {
    ...getDateInput(
      inputs.estimatedDeliveryDate,
      "estimatedShipDate",
      tableRow.row
    ),
    isApplyOnChange: true,
  },
  actualShipDate: {
    ...getDateInput(
      inputs.actualShipDate,
      "actualDeliveryDate",
      tableRow.row,
      () =>
        setFieldValue(`shipments[${tableRow.rowId}].actualDeliveryDate`, null)
    ),
    isApplyOnChange: true,
  },
  actualDeliveryDate: {
    ...getDateInput(inputs.actualDeliveryDate, "actualShipDate", tableRow.row),
    isApplyOnChange: true,
    handleChange: ({ target: { value } }) => {
      setFieldValue &&
        value &&
        setFieldValue(
          `shipments[${tableRow.rowId}].${inputs.approvedToPay.name}`,
          true
        );
    },
  },
});
