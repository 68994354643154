import { uploadFiles } from "./filesActions";
import Funding from "../models/Funding";
import FundingPayment from "../models/FundingPayment";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import {
  performCreateRequest,
  performUpdateRequest,
  fetchDataRequest,
  initDataComponent,
  setReload,
  performRetrieveListRequest,
} from "./dataComponentActions";
import { closeModalDialog } from "./layoutActions";
import * as DATA_COMPONENTS from "../constants/DataComponents";
import Invoice from "../models/Invoice";
import PurchaseOrder from "../models/PurchaseOrder";
import { generateAndDownloadReport } from "./reportsActions";
const dataComponentIdCreate = "FundingCreate";
export const dataComponentIdUpdate = "FundingUpdate";
const dataComponentId = "FundingsGrid";
const dataComponentFundingPaymentId = "FundingPayment";
const dataComponentCountId = "FundingsGridCount";

export function initializeFilters(projectId) {
  return async dispatch => {
    dispatch(
      initDataComponent(DATA_COMPONENTS.INVOICE_SELECT, Invoice, [], "invoices")
    );
    dispatch(
      performRetrieveListRequest(DATA_COMPONENTS.INVOICE_SELECT, {
        sort: [{ columnName: "number", direction: "asc" }],
        rootFilters: {
          $where: {
            "purchaseOrder.projectId": projectId,
            okToIssue: true,
          },
        },
        params: { omitTotals: true },
        fields: ["number", "invoices.id"],
        pageSize: -1,
      })
    );
    dispatch(
      initDataComponent(
        DATA_COMPONENTS.PURCHASE_ORDERS_SELECT,
        PurchaseOrder,
        [],
        "purchase-orders"
      )
    );
    dispatch(
      performRetrieveListRequest(DATA_COMPONENTS.PURCHASE_ORDERS_SELECT, {
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
        rootFilters: {
          $where: {
            projectId: projectId,
          },
        },
        fields: ["number", "purchase_orders.id"],
        pageSize: -1,
        params: {
          omitDefaultModifier: true,
          modifiers: ["withBasicFields"],
        },
      })
    );
  };
}

export const initCreateComponents = () => {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentIdCreate,
        Funding,
        ["fundingPayments"],
        "fundings"
      )
    );
  };
};

export const initUpdateComponents = () => {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentIdUpdate,
        Funding,
        ["projectBudgetFile", "cashflowFile", "fundingPayments"],
        "fundings"
      )
    );
  };
};

export const performAfterCreate = () => {
  return dispatch => {
    dispatch(setReload(dataComponentId, true));
    dispatch(setReload(dataComponentCountId, true));

    dispatch(closeModalDialog());
  };
};

export const performAfterCreatePayment = () => {
  return dispatch => {
    dispatch(setReload(dataComponentId, true));
  };
};

export const updateFunding = (
  fundingId,
  funding,
  dataComponentId = dataComponentIdUpdate
) => {
  return async dispatch => {
    initUpdateComponents()(dispatch);
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    await performUploadFiles(funding, dispatch);
    dispatch(performUpdateRequest(dataComponentId, fundingId, funding));
  };
};

export const createFunding = funding => {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentIdCreate, REQUEST_TYPES.CREATE));
    await performUploadFiles(funding, dispatch);
    dispatch(performCreateRequest(dataComponentIdCreate, funding));
  };
};

export const createFundingPayment = fundingPayment => {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentFundingPaymentId,
        FundingPayment,
        ["funding"],
        "funding-payments",
        undefined,
        "v2"
      )
    );
    dispatch(
      performCreateRequest(dataComponentFundingPaymentId, fundingPayment)
    );
  };
};

const performUploadFiles = async (funding, dispatch) => {
  const files = await dispatch(
    uploadFiles(
      funding,
      null,
      ["cashflowFile", "projectBudgetFile"],
      "fundings"
    )
  );
  files.reduce((project, { field, ...file }) => {
    funding[field] = file;
    return project;
  }, funding);
};

export function openBatchFundingReport(funding, format) {
  return generateAndDownloadReport({
    format,
    templateName: "batch-funding-report",
    fundingId: funding.id,
  });
}
