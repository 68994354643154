import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import styled from "styled-components";

import SpecDetailTitle from "./SpecDetailTitle";
import { ColumRow, ItemColumn } from "./SpecDetailParBased";
import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../withPORevision";

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 18px 24px 24px 0;
`;

export default function SpecDetailCaseBased({ specDetail }) {
  const { unitsNeeded, unitsPerCase, id } = specDetail;
  const { reference } = useContext(RevisionReferenceContext);
  const { isUpdated } = useContext(PORevisionContext);
  const ownReference = `${reference}.${id}`;

  return (
    <Fragment>
      <SpecDetailTitle>Case Based Quantity Calculation</SpecDetailTitle>
      <ItemWrapper>
        <ItemColumn>
          <ColumRow isUpdated={isUpdated(ownReference, "unitsNeeded")}>
            Total Needed
          </ColumRow>
          <ColumRow isUpdated={isUpdated(ownReference, "unitsPerCase")}>
            Units per Case
          </ColumRow>
          <ColumRow>Recommended Case Quantity Order</ColumRow>
        </ItemColumn>
        <ItemColumn align="right">
          <ColumRow isValueUpdated={isUpdated(ownReference, "unitsNeeded")}>
            {unitsNeeded || 0}
          </ColumRow>
          <ColumRow isValueUpdated={isUpdated(ownReference, "unitsPerCase")}>
            {unitsPerCase || 0}
          </ColumRow>
          <ColumRow>
            {unitsPerCase > 0 ? Math.ceil(unitsNeeded / unitsPerCase) : 0}
          </ColumRow>
        </ItemColumn>
      </ItemWrapper>
    </Fragment>
  );
}

SpecDetailCaseBased.propTypes = {
  specDetail: PropTypes.object,
};
