import { appPageBuilder, buildSubPage, redirectTo } from "./utils";
import Vendors from "../components/pages/Vendors";
import VendorDetail from "../components/pages/VendorDetail";
import VendorEdit from "../components/pages/VendorEdit";
import VendorCreate from "../components/pages/VendorCreate";
import VendorProjects from "../components/pages/VendorProjects";
import VendorContacts from "../components/pages/VendorContacts";
import Discounts from "../components/pages/VendorDetail/Discounts";
import VendorRemitAddresses from "../components/pages/RemitAddresses";
import ShipToSites from "../components/pages/VendorDetail/ShipToSites";

const AppVendors = appPageBuilder(Vendors, "Vendors");
const AppVendorDetail = appPageBuilder(VendorDetail, "Vendor Detail", true);
const AppVendorEdit = appPageBuilder(VendorEdit, "Edit Vendor", true);
const AppCreateVendor = appPageBuilder(VendorCreate, "Create Vendor");

export default [
  {
    path: "/vendors",
    component: AppVendors,
    exact: true,
  },
  {
    path: "/vendors/create",
    component: AppCreateVendor,
    exact: true,
  },
  {
    path: "/vendors/edit/:vendorId",
    component: AppVendorEdit,
    exact: true,
  },
  {
    path: "/vendors/:vendorId",
    component: AppVendorDetail,
    routers: [
      {
        path: "/vendors/:vendorId/projects",
        component: buildSubPage(VendorProjects),
      },
      {
        path: "/vendors/:vendorId/contacts",
        component: buildSubPage(VendorContacts),
      },
      {
        path: "/vendors/:vendorId/discounts",
        component: buildSubPage(Discounts),
      },
      {
        path: "/vendors/:vendorId/remit-addresses",
        component: buildSubPage(VendorRemitAddresses),
      },
      {
        path: "/vendors/:vendorId/ship-to-sites",
        component: buildSubPage(ShipToSites),
      },
      //default
      {
        path: "/vendors/:vendorId",
        component: redirectTo("projects"),
        exact: true,
      },
    ],
  },
];
