import { inputs } from "../../../../inputs/inputConfigs";
import { Grid } from "@material-ui/core";
import { Field } from "formik";
import InputWrapper from "../../../../inputs/InputWrapper";
import {
  getErrorMessage,
  hasError,
} from "../../../../../utils/formValidationUtils";
import React from "react";
import PropTypes from "prop-types";
import { FieldContainer, Row } from "../StyledRow";

import { UsaStates } from "usa-states";

const states = new UsaStates({ includeTerritories: true }).states;

function remapOption(option, replacers) {
  const nOption = {
    ...option,
  };
  Object.keys(replacers).forEach(term => {
    nOption.name = nOption.name.replace(`[${term}]`, replacers[term]);
  });
  return nOption;
}

const StatusRow = props => {
  const { vendor } = props;

  const vendorState = states.find(
    state => state.abbreviation === vendor.location.stateCode
  );
  const replacers = { State: vendorState ? vendorState.name : "Local" };
  const input = {
    ...inputs.vendorContactStatus,
    options: inputs.vendorContactStatus.options.map(option =>
      remapOption(option, replacers)
    ),
  };
  return (
    <Row>
      <Grid item xs={12}>
        <FieldContainer>
          <Field name={input.name}>
            {({ field, form }) => (
              <InputWrapper
                {...input}
                {...field}
                label={input.label}
                hasError={hasError(input.name, form)}
                errorMessage={getErrorMessage(input.name, form)}
              />
            )}
          </Field>
        </FieldContainer>
      </Grid>
    </Row>
  );
};
StatusRow.propTypes = {
  vendor: PropTypes.object.isRequired,
};

export default StatusRow;
