import { useSelector } from "react-redux";

import { parseProps } from "../layout/AppLayout/SavedConfirmation";
import { getDataComponentRequestState } from "../../reducers/dataComponentReducer";

export const useSavingStatus = () => {
  const { autoSaveRequestState, autoSaveQueueState } = useSelector(state => {
    const {
      layout: { autoSaveComponentId, autoSaveQueueId },
      queues,
    } = state;
    return {
      autoSaveRequestState: autoSaveComponentId
        ? getDataComponentRequestState(autoSaveComponentId, state)
        : null,
      autoSaveQueueState: queues[autoSaveQueueId] || null,
    };
  });

  return parseProps(autoSaveRequestState, autoSaveQueueState);
};
