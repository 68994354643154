import React from "react";
import _get from "lodash/get";
import styled from "styled-components";

import CheckCircle from "@material-ui/icons/CheckCircle";
import BoldText from "../../../ui/Typography/BoldText";
import TagText from "../../../ui/Tags/TagText";
import PhoneNumber from "../../../ui/PhoneNumber";

export const FullName = row => (
  <BoldText>{`${_get(row, "contact.firstName")} ${_get(
    row,
    "contact.lastName"
  )}`}</BoldText>
);

export const FormattedPhoneNumber = (row, path) => {
  return <PhoneNumber number={_get(row, path)} />;
};
FullName.displayName = "FullName";

export const Status = row => {
  const status = _get(row, "contact.status");
  if (status === "act") return "";
  return <TagText>Inactive</TagText>;
};

const Check = styled(CheckCircle)`
  display: flex;
  color: #72b327;
`;

export const ClientPortalAccess = row =>
  row.canAccessClientPortal ? <Check /> : "";
