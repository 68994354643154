import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import ExpandableGrid from "../../../layout/ExpandableGrid/ExpandableGrid";
import propTypes from "../../../../constants/propTypes";
import {
  PriceCalculation,
  Title,
  Content,
  PriceCalculations,
} from "./components";
import SpecTable from "./SpecTable";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";

const Areas = ({ projectAreaPrices, project, displayDiscount }) => {
  const formatter = useCurrencyFormatter();
  return projectAreaPrices.map(
    (
      {
        totalSpec,
        area,
        uncommittedPrice,
        committedPrice,
        total,
        totalPriceWithDiscount,
        salesTax = 0,
        forecastTotal,
        forecastTotalWithDiscount,
        additionalCostsTotal,
      },
      i
    ) => (
      <ExpandableGrid
        key={i}
        title={`${area.name} (${totalSpec} ${pluralize("Spec", totalSpec)})`}
        expandableTitle="Specs"
        expandleContent={
          project ? (
            <SpecTable
              projectId={project.id}
              displayDiscount={displayDiscount}
              rootFilters={{
                areaId: area.id,
              }}
              dataComponentId={`specs-by-area-${area.id}`}
            />
          ) : null
        }
      >
        <PriceCalculations>
          <PriceCalculation>
            <Title>Uncommitted</Title>
            <Content>{formatter.format(uncommittedPrice)}</Content>
          </PriceCalculation>
          <PriceCalculation>
            <Title>Committed</Title>
            <Content>{formatter.format(committedPrice)}</Content>
          </PriceCalculation>
          <PriceCalculation>
            <Title>Merchandise Cost</Title>
            <Content>
              {formatter.format(
                displayDiscount ? totalPriceWithDiscount : total
              )}
            </Content>
          </PriceCalculation>
          <PriceCalculation>
            <Title>Non-Merchandise Cost</Title>
            <Content>{formatter.format(additionalCostsTotal)}</Content>
          </PriceCalculation>
          <PriceCalculation>
            <Title>Sales Tax</Title>
            <Content>{formatter.format(salesTax)}</Content>
          </PriceCalculation>
          <PriceCalculation>
            <Title>Forecast</Title>
            <Content>
              {formatter.format(
                displayDiscount ? forecastTotalWithDiscount : forecastTotal
              )}
            </Content>
          </PriceCalculation>
        </PriceCalculations>
      </ExpandableGrid>
    )
  );
};

Areas.propTypes = {
  specs: PropTypes.arrayOf(propTypes.spec),
  projectAreaPrices: PropTypes.arrayOf(PropTypes.object),
  project: propTypes.project,
};

export default Areas;
