import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { useSelector } from "react-redux";

import Fee from "../../../../models/Fee";
import FeeSubScope from "../../../../models/FeeSubScope";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import GridAPI from "../../../ui/Grid/GridAPI";
import {
  dateTypeBuilder,
  currencyTypeBuilder,
  editableTextTypeBuilder,
  projectLinkTypeBuilder,
} from "../../../ui/Grid/utilComponents/dataTypeProviders";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
} from "../../../ui/Grid/filterDataTypeProviders";
import { RowMenuProvider } from "../../../ui/Grid/utilComponents";
import RowDetailGrid from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/RowDetailGrid";
import BWTableRow from "../../../ui/BWGrid/helperComponents/EditableTextDetailRow/BWTableRow";
import { updateFee } from "../../../../actions/feeSummaryActions";
import { setReload } from "../../../../actions/dataComponentActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";
import {
  showSnackNotificationAction,
  setAutoSaveComponentId,
} from "../../../../actions/layoutActions";
import useActions from "../../../hooks/useActions";
import {
  useFlattenedDatacomponent,
  useDatacomponent,
} from "../../../hooks/useDatacomponent";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import {
  WrapperDetailRow,
  DetailCellComponent,
  EditableDetailRow,
} from "./DetailRow";
import PagingPaneWithStatusSwitch from "../../../ui/BWGrid/PagingPaneWithStatusSwitch";
import { useV2DatacomponentResources } from "../../../hooks/useResources";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import { columns } from "./gridProps";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";
import { push } from "connected-react-router";
import { usePerformRetrieveList } from "../../../hooks/usePerformRetrieveList";
import { getSelectedProjectCurrency } from "../Approvals/Grid";

export const dataComponentId = "billing-available-table";

export const feeSubscopeDataComponent = {
  dataComponentId: "fee-sub-scopes",
  model: FeeSubScope,
  includes: [],
  apiRoute: "fee-sub-scopes",
};
export const subScopeFilters = {
  pageSize: -1,
  sort: [{ columnName: "name", direction: "asc" }],
};

const getUpdateHandlers = ({ actions, updatedFee }) => {
  return {
    onSuccess: () => {
      const billed = updatedFee.status === "Billed";
      actions.showSnackNotificationAction(
        `Fee has been updated successfully.${
          billed ? " Generating Fee Sheet PDF." : ""
        }`
      );
      actions.setReload(dataComponentId, true);

      if (billed) {
        actions.generateAndDownloadReport({
          format: "pdf",
          templateName: "fee-sheet",
          projectId: updatedFee.projectId,
        });
      }
    },
    onError: () => {
      actions.showSnackNotificationAction("Fee couldn't be updated");
    },
  };
};

export const handleProjectNumberClick = push => ({ project }) =>
  push(
    `/clients/${project.property.entity.clientId}/projects/${project.id}/accounting/fees`
  );

const Grid = ({ showAllProject }) => {
  const dataComponent = useFlattenedDatacomponent(dataComponentId);
  const actions = useActions({
    updateFee,
    showSnackNotificationAction,
    setAutoSaveComponentId,
    setReload,
    generateAndDownloadReport,
    push,
  });
  const updatedFee = useV2DatacomponentResources(
    dataComponentId,
    [],
    REQUEST_TYPES.UPDATE
  );

  useEffect(() => {
    actions.setAutoSaveComponentId(dataComponentId);
  }, [actions]);

  useUpdateProcessRequests(
    useDatacomponent(dataComponentId),
    getUpdateHandlers({ actions, updatedFee })
  );

  const userId = useSelector(state => state.auth.userId);
  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider([
        "project.projectLead.name",
        "project.name",
        "project.number",
      ]),
      buildStaticSelectTypeProvider(["billingTerm", "feeSubScope.name"]),
      buildStaticSelectTypeProvider(["project.isWaiverRequired"]),
    ],
    []
  );

  const { data: feeSubScopes } = usePerformRetrieveList(
    feeSubscopeDataComponent,
    subScopeFilters
  );

  const additionalFilters = showAllProject
    ? {}
    : {
        "project.projectLead.id": userId,
      };

  return (
    <GridAPI
      model={Fee}
      apiRoute="fees"
      includes={[
        "project.[projectLead, office, projectCurrencies.currency, property.entity]",
        "feeSubScope",
      ]}
      defaultSorting={[
        { columnName: "billingDateTrunc", direction: "asc" },
        { columnName: "project.number", direction: "asc" },
      ]}
      apiFilters={{
        rootFilters: {
          $where: { status: "Approved", ...additionalFilters },
        },
      }}
      dxGridProps={{
        tableComponents: {
          WrapperDetailRow: WrapperDetailRow,
          DetailRowComponent: EditableDetailRow,
          DetailCellComponent,
        },
      }}
      dataComponent={dataComponent}
      columns={columns({ updateFee: actions.updateFee, feeSubScopes })}
      columnExtensions={[]}
      tableComponents={{
        PagingPanelTemplate: PagingPaneWithStatusSwitch,
        GridHeader: useHeaderComponent({
          headerText: `${pluralize(
            "Fee",
            dataComponent.totalRows,
            true
          )} Ready for Billing`,
        }),
        DXGrid: RowDetailGrid,
        RowComponent: BWTableRow,
      }}
      dataTypeProviders={[
        dateTypeBuilder(["billingDateTrunc"], PRIMARY_DATE_FORMAT),
        currencyTypeBuilder(["amount"], getSelectedProjectCurrency),
        editableTextTypeBuilder(["invoiceReferenceNumber"]),
        projectLinkTypeBuilder(
          "project.number",
          "project.number",
          handleProjectNumberClick(actions.push)
        ),
        RowMenuProvider,
      ]}
      filteringDataTypeProviders={filteringDataTypeProviders}
    />
  );
};

Grid.propTypes = {
  showAllProject: PropTypes.bool,
};

export default Grid;
