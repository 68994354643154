import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { MenuItem as MUIMenuItem } from "@material-ui/core";
import _get from "lodash/get";

import {
  StyledTextField as TextField,
  getShrinkLabelOption,
  useLabeledText,
} from "../TextInputWithError";
import InputLoader from "../../ui/Loader/InputLoader";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LabeledText } from "../LabeledText";

export const MenuItemValue = styled.span`
  font-family: Open Sans, sans-serif;
  font-weight: 600;
`;

export const MenuItemWrapper = React.forwardRef(
  ({ useSeparator, ...props }, ref) => <MUIMenuItem ref={ref} {...props} />
);

export const StyledMenuItem = styled(MenuItemWrapper)`
  border-top: ${({ useSeparator }) =>
    useSeparator && "1px solid rgba(0, 0, 0, 0.12)"};
`;

export const MenuItem = React.forwardRef((props, ref) => (
  <StyledMenuItem ref={ref} {...props} />
));

export const nullAsEmptyValue = "-1";
export const getInputEmptyValue = nullAsEmpty =>
  nullAsEmpty ? nullAsEmptyValue : "";

export const getNullAsEmptyValue = ({ value, nullAsEmpty }) => {
  const isNullAsEmptyValue = nullAsEmpty && value === nullAsEmptyValue;
  return isNullAsEmptyValue ? null : value;
};

export const handleSubmitEvent = (
  event,
  name,
  options,
  onChange,
  nullAsEmpty
) => {
  const selectedObject = options.find(
    option => option.id === event.target.value
  );
  const inputValue = selectedObject
    ? selectedObject
    : getInputEmptyValue(nullAsEmpty);
  const eventWithFullObject = {
    ...event,
    target: {
      name,
      value: getNullAsEmptyValue({ value: inputValue, nullAsEmpty }),
    },
  };
  onChange(eventWithFullObject);
};

export const createOnChange = (
  name,
  submitData,
  options,
  onChange,
  handleChange,
  nullAsEmpty
) => event => {
  if (submitData) {
    handleSubmitEvent(event, name, options, onChange, nullAsEmpty);
    return;
  }
  onChange(event);
  handleChange && handleChange(event);
};

export const getValue = (value, submitData) => {
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value;
  if (value) return (submitData ? value.id : value) + "";

  return "";
};

export const getLabel = (labelProperty, option) => {
  if (typeof labelProperty === "function") return labelProperty(option);
  return _get(option, labelProperty) || "N/A";
};

export const optionsWithEmptyValue = (
  valueProperty,
  labelProperty,
  options,
  displayEmptyValue,
  nullAsEmpty,
  defaultOption
) => () => {
  const defaultOptions = defaultOption ? [defaultOption] : [];
  if (options.length === 0 && !defaultOption) {
    defaultOptions.push({
      [valueProperty]: getInputEmptyValue(nullAsEmpty),
      [labelProperty]: "No Options",
      menuItemProps: { disabled: true },
    });
  } else if (displayEmptyValue) {
    defaultOptions.push({
      [valueProperty]: getInputEmptyValue(nullAsEmpty),
      [labelProperty]: " ",
    });
  }
  return options ? [...defaultOptions, ...options] : defaultOptions;
};

export const StandardSelect = ({
  label,
  name,
  value,
  error,
  errorMessage,
  ignoreCaption,
  options: initialOptions,
  onChange,
  submitData,
  handleChange,
  valueProperty,
  labelProperty,
  styleMenuOption,
  nullAsEmpty,
  displayEmpty,
  ignoreLabeledText,
  ...selectProps
}) => {
  const { width } = useWindowDimensions();
  const labeledText = useLabeledText(ignoreLabeledText);
  const options = useMemo(
    optionsWithEmptyValue(
      valueProperty,
      labelProperty,
      initialOptions,
      typeof displayEmpty === "undefined"
        ? !selectProps.required
        : displayEmpty,
      nullAsEmpty
    ),
    [
      valueProperty,
      labelProperty,
      initialOptions,
      selectProps.required,
      nullAsEmpty,
    ]
  );

  if (labeledText) {
    const option = options.find(({ id }) => id === value);
    return (
      <LabeledText label={label} texts={[getLabel(labelProperty, option)]} />
    );
  }
  if (styleMenuOption) {
    selectProps = {
      ...selectProps,
      SelectProps: {
        MenuProps: {
          autoFocus: false,
          getContentAnchorEl: () => null,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
      },
    };
  }

  const parsedValue = getValue(value, submitData);

  const onChangeWrapper = createOnChange(
    name,
    submitData,
    options,
    onChange,
    handleChange,
    nullAsEmpty,
    parsedValue,
    valueProperty
  );

  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <TextField
        select
        {...selectProps}
        label={label}
        value={parsedValue}
        inputProps={{ name }}
        error={error}
        onChange={onChangeWrapper}
        InputLabelProps={{
          ...getShrinkLabelOption(width),
          ...selectProps.InputLabelProps,
          required: false,
        }}
      >
        {options.map(option => (
          <MenuItem
            value={option[valueProperty]}
            key={option[valueProperty]}
            {...option.menuItemProps}
          >
            <MenuItemValue>{getLabel(labelProperty, option)}</MenuItemValue>
          </MenuItem>
        ))}
      </TextField>
    </InputLoader>
  );
};

StandardSelect.defaultProps = {
  labelProperty: "name",
  valueProperty: "id",
  nullAsEmpty: false,
};

StandardSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  valueProperty: PropTypes.string,
  nullAsEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.func,
  ]).isRequired,
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  ignoreLabeledText: PropTypes.bool,
  error: PropTypes.bool,
};

export default StandardSelect;
