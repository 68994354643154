import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import EditPreviewForm from "./EditPreviewForm";
import { updatePreview } from "../../../../actions/specDetailActions";
import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  getUpdatedId,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import withPORevision from "../../../../withPORevision";
import propTypes from "../../../../constants/propTypes";
import {
  initDataComponent,
  performUpdateRequest,
} from "../../../../actions/dataComponentActions";
import Spec from "../../../../models/Spec";

const dataComponentId = "SpecDetail-SpecDetailsChildren";
export const addPreviewSpecDetailComponentId = "SpecDetail-Preview";

export class EditPreviewContainer extends Component {
  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(
      addPreviewSpecDetailComponentId,
      Spec,
      [],
      "spec-details-add"
    );
  }

  handleSuccess = () => {
    this.props.closeModalDialog();
  };

  handleAddSpecDetailSuccess = specDetailDataComponent => () => {
    const id = getUpdatedId(specDetailDataComponent);
    this.handleSubmit(
      { id, preview: this.state.preview },
      this.state.formikActions
    );
  };

  componentDidUpdate({
    dataComponent: prevDataComponent,
    specDetailDataComponent: prevSpecDetailDataComponent,
  }) {
    const { dataComponent, specDetailDataComponent } = this.props;
    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
    processUpdateRequestStatus(
      prevSpecDetailDataComponent,
      specDetailDataComponent,
      {
        onSuccess: this.handleAddSpecDetailSuccess(specDetailDataComponent),
        onError: error => handleRequestError(error, this.state.formikActions),
      }
    );
  }

  handleSubmit = ({ id, preview }, formikActions) => {
    const {
      updatePreview,
      performUpdateRequest,
      revisionValue,
      spec,
    } = this.props;
    const revisionIsActive = revisionValue.isActive;
    const params = revisionIsActive
      ? {
          update_po_revision: true,
        }
      : undefined;

    this.setState({ formikActions, preview });

    if (id) {
      updatePreview(
        dataComponentId,
        spec.id,
        spec.projectId,
        id,
        preview,
        params
      );
      return;
    }

    performUpdateRequest(
      addPreviewSpecDetailComponentId,
      spec.id,
      {},
      { previewOnly: true }
    );
  };

  render() {
    const { specDetail, spec } = this.props;
    return (
      <EditPreviewForm
        specDetail={specDetail || { specId: spec.id }}
        onSubmit={this.handleSubmit}
        revisionReference={`specs.${spec.id}.specDetails.${_get(
          specDetail,
          "id"
        )}`}
      />
    );
  }
}

EditPreviewContainer.propTypes = {
  spec: propTypes.spec,
  specDetail: propTypes.specDetail,
  dataComponent: propTypes.dataComponent,
  specDetailDataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  updatePreview: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  initDataComponent: PropTypes.func,
  revisionValue: PropTypes.shape({
    isActive: PropTypes.bool,
    activities: PropTypes.arrayOf(propTypes.revisionActivities),
  }),
};

export const mapStateToProps = () => {
  return state => {
    const dataComponent = getDataComponent(dataComponentId, state);
    const specDetailDataComponent = getDataComponent(
      addPreviewSpecDetailComponentId,
      state
    );
    return {
      dataComponent,
      specDetailDataComponent,
    };
  };
};

const mapDispatchToProps = {
  updatePreview,
  performUpdateRequest,
  initDataComponent,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPORevision(EditPreviewContainer, "SpecDetail", "purchaseOrder"));
