import { inputs } from "../../../../components/inputs/inputConfigs";

const getNameItems = contactPath => [
  {
    input: {
      ...inputs.firstName,
      required: true,
      name: contactPath + "." + inputs.firstName.name,
    },
    grid: { xs: 6 },
  },
  {
    input: {
      ...inputs.lastName,
      required: true,
      name: contactPath + "." + inputs.lastName.name,
    },
    grid: { xs: 6 },
  },
];
export default (props, contactPath = "contact") => {
  return {
    groups: [
      {
        items: [
          ...getNameItems(contactPath),
          {
            input: {
              ...inputs.phone,
              label: "Phone Number",
              name: contactPath + "." + inputs.phone.name,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.fax,
              name: contactPath + "." + inputs.fax.name,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.contactRole,
              required: true,
              name: contactPath + "." + inputs.contactRole.name,
            },
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.email,
              name: contactPath + "." + inputs.email.name,
            },
            grid: { xs: 6 },
          },
          {
            input: inputs.canAccessClientPortal,
            grid: { xs: 6 },
          },
          {
            input: {
              ...inputs.status,
              name: contactPath + "." + inputs.status.name,
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};
