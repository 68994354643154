import React from "react";
import PropTypes from "prop-types";

import { Status } from "../../PurchaseOrder/components";

const SpecNumberColumn = spec => {
  let intent = "none";
  if (spec.atRisk) {
    intent = "danger";
  } else if (spec.inWatchList) {
    intent = "primary";
  }

  return <Status colors={{ intent }}>{spec.customNumber}</Status>;
};

SpecNumberColumn.propTypes = {
  atRisk: PropTypes.bool,
  inWatchList: PropTypes.bool,
};

export default SpecNumberColumn;
