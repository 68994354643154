import React from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import propTypes from "../../../../constants/propTypes";
import DropdownMenu from "../../../layout/AppLayout/DropdownMenu";

const onApproveForBilling = ({
  openModalDialog,
  closeModalDialog,
  selectedIds,
  handleUpdateStatus,
}) => () => {
  openModalDialog(
    "Approve for Billing",
    "GeneralConfirmation",
    {
      title: `Are you sure you want to approve ${pluralize(
        "fee",
        selectedIds.length,
        true
      )} for billing?`,
      sendButtonText: "Confirm",
      cancelButtonText: "Cancel",
      onSubmit: () => {
        handleUpdateStatus({
          ids: selectedIds,
        });
        closeModalDialog();
      },
    },
    false,
    true
  );
};

export const shouldDisableApproveAction = selectedIds => {
  return selectedIds.length === 0;
};

export const Actions = props => {
  const {
    dataComponent: { selectedIds = [] },
    openModalDialog,
    closeModalDialog,
    handleUpdateStatus,
  } = props;

  const options = [
    {
      content: "Approve for Billing",
      onClick: onApproveForBilling({
        openModalDialog,
        closeModalDialog,
        selectedIds,
        handleUpdateStatus,
      }),
      disabled: shouldDisableApproveAction(selectedIds),
    },
  ];
  return <DropdownMenu buttonText="Actions" options={options} />;
};
Actions.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
};
