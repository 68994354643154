import { BaseModel } from "@blue-chip/core";
import Project from "./Project";
import SpecCategory from "./SpecCategory";
import Area from "./Area";
import Vendor from "./Vendor";
import VendorContact from "./VendorContact";
import SpecDetail from "./SpecDetail";
import PurchaseOrder from "./PurchaseOrder";
import Shipment from "./Shipment";
import SpecDetailCom from "./SpecDetailCom";
import GLCode from "./GLCode";
import Requirement from "./Requirement";
import File from "./File";
import SpecRequirement from "./SpecRequirement";
import BidGroupVendorSpecQuote from "./BidGroupVendorSpecQuote";
import ProjectCurrency from "./ProjectCurrency";
export default class Spec extends BaseModel {
  static get belongsTo() {
    return [
      Project,
      SpecCategory,
      Area,
      PurchaseOrder,
      Spec,
      File,
      Vendor,
      BidGroupVendorSpecQuote,
      ProjectCurrency,
    ];
  }

  static get hasMany() {
    return [
      SpecDetail,
      Shipment,
      SpecDetailCom,
      GLCode,
      Requirement,
      VendorContact,
      SpecRequirement,
    ];
  }
}
