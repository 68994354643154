import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SectionTitle from "../ui/Typography/SectionTitle";
import { LabeledTextContext } from "../../withPORevision";
import Loader from "../ui/Loader";
import Subheader from "../ui/Typography/Subheader";
import { BWActionButton } from "../ui/BWActionButton";

export const FlexWrapper = styled.div`
  flex: 0 0 100%;
  flex-direction: column;
`;
export const FlexItem = styled.div`
  display: flex;
`;
export const FlexItemGrow = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: unset;
  padding: 24px 24px 0;
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: ${({ isMultiline }) => (isMultiline ? "flex-start" : "center")};
`;
const ActionsWrapper = styled.div`
  display: flex;
  align-items: ${({ isMultiline }) => (isMultiline ? "flex-start" : "center")};
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;
const SecondaryTextWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  width: 90%;
`;

export const Button = styled(props => (
  <BWActionButton disableRipple disableFocusRipple {...props} />
))`
  padding: 0;
  margin-left: 24px;
  &:hover {
    background-color: unset;
  }
  &:last-child {
    margin-right: 8px;
  }
`;

const ActionButtons = ({ labeledText, actions }) => {
  if (labeledText) return [];

  return actions.map(({ handler, actionOverride, ...props }) =>
    actionOverride ? (
      actionOverride
    ) : (
      <Button onClick={handler} key={props.text} {...props} />
    )
  );
};

export const RawHeader = ({
  headerOverride,
  headerText,
  subheaderText,
  secondaryText,
  actions = [],
  children,
  isMultiline,
}) => {
  const labeledText = useContext(LabeledTextContext);

  const titleContent = headerText ? (
    <SectionTitle secondary={subheaderText}>{headerText}</SectionTitle>
  ) : subheaderText ? (
    <Subheader>{subheaderText}</Subheader>
  ) : null;
  return (
    <Fragment>
      <FlexWrapper>
        <FlexItem>
          <FlexItemGrow>
            <HeaderLeft>
              <Loader height="30px" width="350px">
                {titleContent && <TitleWrapper>{titleContent}</TitleWrapper>}
                {headerOverride}
              </Loader>
            </HeaderLeft>
          </FlexItemGrow>
          <ActionsWrapper isMultiline={isMultiline}>
            <Loader height="30px" width="350px">
              {children}
              <ActionButtons labeledText={labeledText} actions={actions} />
            </Loader>
          </ActionsWrapper>
        </FlexItem>
        {secondaryText && (
          <FlexItem>
            <SecondaryTextWrapper>{secondaryText}</SecondaryTextWrapper>
          </FlexItem>
        )}
      </FlexWrapper>
    </Fragment>
  );
};

const SectionHeader = ({ className, ...props }) => (
  <Wrapper className={className}>
    <RawHeader {...props} />
  </Wrapper>
);

SectionHeader.propTypes = RawHeader.propTypes = {
  headerOverride: PropTypes.element,
  headerText: PropTypes.string,
  subheaderText: PropTypes.string,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.node,
      handler: PropTypes.func,
    })
  ),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isMultiline: PropTypes.bool,
};

export default SectionHeader;
