// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BidGroupVendor Model belongsTo relations 1`] = `
Array [
  "BidGroup",
  "Vendor",
  "File",
  "ProjectCurrency",
]
`;

exports[`BidGroupVendor Model hasMany relations 1`] = `
Array [
  "Spec",
  "BidGroupVendorSpec",
  "VendorContact",
]
`;
