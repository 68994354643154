import React from "react";
import { inputs } from "../../../inputs/inputConfigs";
import { GroupLabel } from "./components";

export default {
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.name,
            label: "Name",
          },
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const permissionFields = permissionsByGroup => ({
  groups: Object.keys(permissionsByGroup)
    .sort()
    .map(label => {
      const permissions = permissionsByGroup[label];
      return {
        items: [
          {
            element: <GroupLabel>{label}</GroupLabel>,
            grid: { xs: 12 },
          },
          ...permissions.map(({ id, label }) => ({
            input: {
              ...inputs.permission,
              label,
              name: `permissions[${id}]`,
            },
            grid: { xs: 12 },
          })),
        ],
      };
    }),
});
