import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";

import { GridHeader } from "../../../ui/BWGrid";
import { CollapseButton } from "../../../ui/CollapseButton";

const ToolBarWrapper = styled(Paper)``;
export const HeaderWrapper = styled(({ isCollapsed, ...props }) => (
  <ToolBarWrapper {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  ${({ isCollapsed }) => (isCollapsed ? "" : "box-shadow: none;")}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  ${({ isCollapsed }) => (isCollapsed ? "margin-bottom: 24px" : "")}
  ${({ isCollapsed }) => (isCollapsed ? "" : "box-shadow: none;")}
`;

export const GridHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
`;

export const QuantityMismatchWarning = styled.div`
  color: red;
  margin-left: 24px;
  margin-bottom: 24px;
`;

const isCollapseButtonDisabled = (isDisabled, loading) => isDisabled || loading;

export const PurchaseOrderHeader = ({
  headerText,
  headerOverride,
  isCollapsed,
  setIsCollapsed,
  ActionsComponent,
  DescriptionComponent,
  isDisabled,
  loading,
  isPODetailPage,
  showQuantityMismatchWarning,
}) => (
  <Wrapper isCollapsed={isCollapsed}>
    <HeaderWrapper isCollapsed={isCollapsed}>
      <GridHeaderWrapper>
        <GridHeader
          headerText={headerText}
          headerOverride={isPODetailPage ? null : headerOverride}
        />
        {ActionsComponent && <ActionsComponent />}
      </GridHeaderWrapper>
      <DescriptionWrapper>
        {DescriptionComponent && <DescriptionComponent />}
        {!isPODetailPage && (
          <CollapseButton
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            isDisabled={isCollapseButtonDisabled(isDisabled, loading)}
          />
        )}
      </DescriptionWrapper>
    </HeaderWrapper>
    {showQuantityMismatchWarning && (
      <QuantityMismatchWarning>
        {
          "Please Update the Shipment Quantities to match the updated Spec Quantities"
        }
      </QuantityMismatchWarning>
    )}
  </Wrapper>
);

PurchaseOrderHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  headerOverride: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  ActionsComponent: PropTypes.func,
  DescriptionComponent: PropTypes.func,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  isPODetailPage: PropTypes.bool,
  showQuantityMismatchWarning: PropTypes.bool,
};
