import { isSuperAdmin } from "../../../../../utils/roleUtils";
import { inputs } from "../../../../inputs/inputConfigs";

export const getGeneralFields = ({ userRole }) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.name,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.description,
            multiline: false,
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.userRole,
            label: "User Roles",
            name: "userRoleIds",
            multiple: true,
            isSingleAutocomplete: false,
            isAutocompleteMulti: true,
            valueProperty: "id",
            labelProperty: "name",
          },
          grid: { xs: 12 },
        },
        {
          input: isSuperAdmin(userRole)
            ? {
                ...inputs.scopeId,
                name: "scopes",
                isSingleAutocomplete: false,
                isAutocompleteMulti: true,
                submitData: true,
              }
            : null,
          grid: { xs: 3 },
        },
      ],
    },
  ],
});
