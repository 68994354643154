import React from "react";
import styled from "styled-components";

import { BWIntent } from "../../../../ui/BWIntent";
import Paper from "../../../../mui/core/Paper";
import GridHeader from "../../../../ui/BWGrid/GridHeader";

export const StyledPaper = styled(Paper)`
  ${GridHeader} {
    padding: 24px;
  }
`;
export const Status = styled(({ intent, opacity, ...props }) => (
  <div {...props} />
))`
  color: ${({ colors }) => BWIntent.values.tableText[colors.intent]};
  opacity: ${({ colors }) => colors.opacity || "1"};
  font-weight: ${({ colors }) =>
    colors.intent === "none" ? "normal" : "bold"};
`;

export const Wrapper = styled(Paper)`
  table {
    min-width: auto !important;
  }
`;
