import { memo } from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import _get from "lodash/get";

import PurchaseOrder from "../models/PurchaseOrder";
import Revision from "../models/Revision";
import wrapPORevision from "./wrapPORevision";
import { getDataComponent } from "../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { getResourcesV2 } from "../utils/bluechipUtils";

const legacySelector = () => {
  const poSelector = createSelector(
    state => state.resources,
    (_, { purchaseOrderId }) => purchaseOrderId,
    (resources, purchaseOrderId) => {
      if (!purchaseOrderId) return {};

      const po = PurchaseOrder.query(resources)
        .where({ id: [purchaseOrderId] })
        .toObjects()[0];
      if (!po) return {};

      const revision = Revision.query(resources)
        .where({ id: po.currentRevisionId })
        .includes(["revisionActivities"])
        .toObjects()[0];

      return {
        status: po.revisionStatus,
        activities: _get(revision, "revisionActivities", []),
      };
    }
  );

  return poSelector;
};

const legacyMapStateToProps = () => {
  const poSelector = legacySelector();

  return (state, { purchaseOrderId }) => {
    const { status, activities } = poSelector(state, { purchaseOrderId });
    return {
      isRevisionActive: Boolean(status),
      revisionActivities: activities,
    };
  };
};

const v2MapStateToProps = (dataComponentId, poPath, v1AndV2) => () => {
  const defaultPO = {};
  const poSelector = createSelector(
    (_, { po }) => po,
    po => {
      const revision = po.currentRevision;
      return {
        status: po.revisionStatus,
        activities: _get(revision, "revisionActivities", []),
      };
    }
  );

  return (state, { purchaseOrderId }) => {
    const dataComponent = getDataComponent(dataComponentId, state);
    const resource = getResourcesV2(
      dataComponent,
      state,
      REQUEST_TYPES.FIND,
      defaultPO
    );

    const po = poPath ? _get(resource, poPath, defaultPO) : resource;

    let status, activities;

    // In case nothing is found in v2 and v1AndV2 = true find the po on V1
    if (v1AndV2 && po == defaultPO) {
      const legacyPOSelector = legacySelector();
      ({ status, activities } = legacyPOSelector(state, {
        purchaseOrderId,
      }));
    } else {
      ({ status, activities } = poSelector(null, { po }));
    }

    return {
      isRevisionActive: Boolean(status),
      revisionActivities: activities,
    };
  };
};

/**
 * A public higher-order component to use the PO revision state
 * If v1AndV2 its true that means the component can be used from v1 and v2 parent components.
 */
export default function(WrappedComponent, dataComponentId, poPath, v1AndV2) {
  const PORevisionWrapper = wrapPORevision(WrappedComponent);

  const mapStateToProps = dataComponentId
    ? v2MapStateToProps(dataComponentId, poPath, v1AndV2)
    : legacyMapStateToProps;
  return memo(connect(mapStateToProps)(PORevisionWrapper));
}
