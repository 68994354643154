import { Checkbox, ListItemIcon } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import {
  getLabel,
  MenuItem,
  MenuItemValue,
  MenuItemWrapper,
} from "../StandardSelect";

export const StyledSelectAllItem = styled(MenuItem)`
  border-bottom: groove;
  color: black;
`;

export const SelectAllMenuItem = React.forwardRef((props, ref) => (
  <StyledSelectAllItem ref={ref} {...props} />
));

export const StyledGroupedMenuItem = styled(MenuItemWrapper)`
  padding-left: 45px;
`;

export const GroupedMenuItem = React.forwardRef((props, ref) => (
  <StyledGroupedMenuItem ref={ref} {...props} />
));

export const buildInitialOptionsMap = (initialOptions, valueProperty) => {
  return initialOptions.reduce((previous, current) => {
    previous[current[valueProperty]] = current;
    return previous;
  }, {});
};

export const buildOptions = (options, groupingProperty) => () => {
  if (groupingProperty) {
    return options.reduce((previous, current) => {
      current[groupingProperty].forEach(option => previous.push(option));
      return previous;
    }, []);
  }

  return options;
};

export const getParentItemSelectAllProps = (
  values,
  parentItem,
  groupingProperty,
  valueProperty,
  valuesSet
) => {
  const parentSelectedItems = parentItem[groupingProperty].reduce(
    (previous, current) =>
      previous + (valuesSet.has(current[valueProperty]) ? 1 : 0),
    0
  );

  return {
    isAllSelected: parentSelectedItems === parentItem[groupingProperty].length,
    indeterminate:
      parentSelectedItems !== parentItem[groupingProperty].length &&
      parentSelectedItems !== 0,
  };
};

export const buildOptionComponents = ({
  options,
  initialOptions,
  parsedValue,
  valueProperty,
  labelProperty,
  groupingProperty,
  values,
}) => {
  if (groupingProperty) {
    const optionComponents = [];
    const valuesSet = new Set(values);
    initialOptions
      .filter(initialOption => initialOption[groupingProperty]?.length > 0)
      .forEach((initialOption, index) => {
        const { isAllSelected, indeterminate } = getParentItemSelectAllProps(
          values,
          initialOption,
          groupingProperty,
          valueProperty,
          valuesSet
        );
        optionComponents.push(
          <MenuItem
            value={`parent_${initialOption[valueProperty]}`}
            key={initialOption[valueProperty]}
            {...initialOption.menuItemProps}
          >
            <ListItemIcon>
              <Checkbox checked={isAllSelected} indeterminate={indeterminate} />
            </ListItemIcon>
            <MenuItemValue>
              {getLabel(labelProperty, initialOption)}
            </MenuItemValue>
          </MenuItem>
        );

        initialOption[groupingProperty]?.forEach(option => {
          optionComponents.push(
            <GroupedMenuItem
              value={option[valueProperty]}
              key={`${index}_${option[valueProperty]}`}
            >
              <ListItemIcon>
                <Checkbox checked={valuesSet.has(option[valueProperty])} />
              </ListItemIcon>
              <MenuItemValue>{getLabel(labelProperty, option)}</MenuItemValue>
            </GroupedMenuItem>
          );
        });
      });

    return optionComponents;
  } else {
    return options.map(option => (
      <MenuItem
        value={option[valueProperty]}
        key={option[valueProperty]}
        {...option.menuItemProps}
      >
        <ListItemIcon>
          <Checkbox checked={parsedValue.indexOf(option[valueProperty]) > -1} />
        </ListItemIcon>
        <MenuItemValue>{getLabel(labelProperty, option)}</MenuItemValue>
      </MenuItem>
    ));
  }
};
