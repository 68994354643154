import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _get from "lodash/get";
import { LabeledText } from "../../../inputs/LabeledText";
import { Title } from "../../../layout/AppLayout/ModalDialog/ModalTitle";

export const NoteCategoriesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const Label = styled.span`
  color: #ffffff;
  margin-right: 9px;
`;

export const DisabledInput = ({ label, value, labeledTextProps }) => {
  return (
    <LabeledText
      label={label}
      texts={[`${value || ""}${_get(labeledTextProps, "endAdornment", "")}`]}
    />
  );
};

DisabledInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeledTextProps: PropTypes.shape({}),
};
