import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TemplatesPage from "./TemplatesPage";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { openModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import withRenderOnPermissionActive from "../../../hocs/withRenderOnPermissionActive";

const dataComponentId = "SpecCategoriesGrid";

const TemplatesContainer = ({ dataComponent, openModalDialog }) => {
  const handleOpenCreateModal = useCallback(() => {
    openModalDialog("Spec Template", "CreateTemplate", {}, false);
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    specCategory => {
      openModalDialog(
        `Edit ${specCategory.name}`,
        "CreateTemplate",
        {
          instance: specCategory,
        },
        false
      );
    },
    [openModalDialog]
  );

  const handleOpenDeleteModal = useCallback(
    ({ id, name }) => {
      openModalDialog(
        `Delete ${name}`,
        "StandardDelete",
        {
          id,
          title: name,
          dataComponentId,
        },
        false
      );
    },
    [openModalDialog]
  );

  return (
    <TemplatesPage
      dataComponent={dataComponent}
      onOpenCreateModal={handleOpenCreateModal}
      onOpenEditModal={handleOpenEditModal}
      onOpenDeleteModal={handleOpenDeleteModal}
    />
  );
};

TemplatesContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  openModalDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = state => {
  return {
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
  };
};

export const mapDispatchToProps = {
  openModalDialog,
};

export default withRenderOnPermissionActive(
  connect(mapStateToProps, mapDispatchToProps)(TemplatesContainer),
  ["general-settings-spec-templates"]
);
