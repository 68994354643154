import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Weekend from "@material-ui/icons/Weekend";

import GridHeader from "../../../../ui/BWGrid/GridHeader";
import { StyledBWHeadCell } from "../../../../ui/BWGrid/components";
import { StyledBWCell } from "../../../../ui/BWGrid/gridInternalComponents/BWCell";

import { Grid } from "@devexpress/dx-react-grid-material-ui";

export const ListSectionWrapper = styled(Paper)`
  border-radius: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex: 1;
  max-height: 100%;
  box-shadow: none;
  ${StyledBWHeadCell} {
    color: rgba(0, 0, 0, 0.54);
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
  ${StyledBWCell} {
    &:first-child {
      padding: 0;
    }
  }
  ${GridHeader} {
    padding: 0;
    margin: 36px 8px 24px 8px;
  }
`;

export const StyledGridRoot = styled(Grid.Root)`
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0 0 0 24px;
`;

export const HeaderText = styled.span`
  line-height: 32px;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 24px;
  > * {
    vertical-align: middle;
  }
`;

export const StyledIcon = styled(Weekend)`
  display: inline-block;
  margin-right: 18px;
`;
