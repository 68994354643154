import React from "react";
import PropTypes from "prop-types";
import Price from "../../../ui/Price";
import styled from "styled-components";

import Edit from "@material-ui/icons/Edit";
import TagText from "../../../ui/Tags/TagText";
import Paper from "../../../mui/core/Paper";
import GridHeader from "../../../ui/BWGrid/GridHeader";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";
import { Title } from "../../../ui/Typography/SectionTitle";

export const DisabledPaper = styled(Paper)`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  ${Title} {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const StyledEdit = styled(Edit)`
  color: #979797;
  margin-left: 10px;
  font-size: 12px;
  width: 12px;
  height: 12px;
`;

export const StyledSelectedPrice = styled.div`
  color: ${({ active }) => (active ? "#000000" : "#bdbdbd")};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 8px;
`;

export const SelectedPrice = ({ price, currency }) => {
  const isProjectClosed = useIsProjectClosed();

  return (
    <StyledSelectedPrice active={price}>
      {price ? (
        <Price number={price} currency={currency} />
      ) : (
        "Add Selected Price"
      )}{" "}
      {!isProjectClosed && <StyledEdit />}
    </StyledSelectedPrice>
  );
};

SelectedPrice.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.object,
};

export const StyledPaper = styled(Paper)`
  ${GridHeader} {
    padding: 24px;
  }
`;

export const TitleLine = styled.span`
  display: flex;
  align-items: center;
  ${TagText} {
    margin-left: 8px;
  }
`;
