import BWModels from "benjaminwest-models";
import memoize from "memoize-one";

function createLoaderAsMap(modelName, utilField) {
  return memoize(() => {
    const { __utils } = BWModels.loadSchema(modelName);
    const list = __utils[utilField];
    return list.reduce(
      (loaderMap, element) => ({ ...loaderMap, [element.id]: element }),
      {}
    );
  });
}

const vendorContactStatusAdditionalInfo = {
  "Accounting Contact": { intent: "warning" },
  "Primary RFQ Contact": { intent: "primary" },
  "Secondary RFQ Contact": { intent: "primary" },
  "Boulder Rep": { intent: "success" },
  "Chicago Rep": { intent: "success" },
  "Dallas Rep": { intent: "success" },
  "London Rep": { intent: "success" },
  "International Rep": { intent: "success" },
  "All Company Rep": { intent: "success" },
  "Florida Rep": { intent: "success" },
  "Maryland Rep": { intent: "success" },
  "Arizona Rep": { intent: "success" },
};

const loadStatusMap = createLoaderAsMap("Contact", "statuses");
const loadContactStatusMap = createLoaderAsMap(
  "VendorContact",
  "contactStatus"
);

const loadContactStatusMapWithAddInfo = memoize(() => {
  let statusMap = loadContactStatusMap();
  return Object.keys(statusMap).reduce((map, key) => {
    map[key] = {
      ...statusMap[key],
      ...vendorContactStatusAdditionalInfo[key],
    };
    return map;
  }, {});
});

export default {
  get statusMap() {
    return loadStatusMap();
  },
  get vendorContactStatusMap() {
    return loadContactStatusMapWithAddInfo();
  },
};
