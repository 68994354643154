import React from "react";
import { useCallback } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import SpecsPage from "./SpecsPage";
import { connect } from "react-redux";
import {
  initDataComponent,
  setReload,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";
import { pushWithReturnUrl } from "../../../actions/navigationActions";
import { setAutoSaveComponentId } from "../../../actions/layoutActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";
import {
  openBulkDeleteModalAction,
  openCopySpecModalAction,
  openDuplicateSpecsAction,
  openAddSpecAction,
  openDuplicateModalAction,
  openDeleteModalAction,
  openQuickEditModalAction,
  openImportSpecOptionsModal,
  openIncompleteSpecModalAction,
  openIncompleteSpecsModalAction,
  openCompleteSpecModalAction,
  openCompleteSpecsModalAction,
  openMoveSpecsModalAction,
} from "../../../actions/specsActions";
import Spec from "../../../models/Spec";
import propTypes from "../../../constants/propTypes";
import { queueCreateSpecsId } from "./ImportSpecs/ImportSpecs";
import { useContainerEffects } from "./specContainerFunctions";

import { dataComponentId } from "./specContainerFunctions";
import {
  rfqFilterDataComponent,
  poFilterDataComponent,
  initRFQListFilter,
  initPOListFilter,
} from "../../../actions/projectsActions";
import { getResourcesV2 } from "../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import { getDefaultProjectCurrency } from "../../helpers";
export { dataComponentId } from "./specContainerFunctions";

export const getNextSpecId = (rowIndex, specId) => {
  if (!rowIndex) return null;

  const currentIndex = rowIndex.indexOf(specId);
  if (currentIndex === -1) {
    return rowIndex[0];
  }
  return rowIndex[currentIndex + 1];
};
// eslint-disable-next-line max-lines-per-function
export const SpecIndexContainer = ({
  importSpecQueue,
  initDataComponent,
  performRetrieveListRequest,
  openBulkDeleteModalAction,
  openCopySpecModalAction,
  openDuplicateSpecsAction,
  openAddSpecAction,
  openDuplicateModalAction,
  openDeleteModalAction,
  openQuickEditModalAction,
  openImportSpecOptionsModal,
  openIncompleteSpecModalAction,
  openIncompleteSpecsModalAction,
  openCompleteSpecModalAction,
  openCompleteSpecsModalAction,
  openMoveSpecsModalAction,
  initRFQListFilter,
  initPOListFilter,
  setAutoSaveComponentId,
  pushWithReturnUrl,
  project,
  setReload,
  ...props
}) => {
  const { dataComponent, projectId, clientId, areaId } = props;
  const projectNumber = _get(project, "number") || "--";
  const projectName = _get(project, "name");
  const propertyId = _get(project, "propertyId");
  const defaultProjectCurrency = getDefaultProjectCurrency(
    _get(project, "projectCurrencies")
  );

  useContainerEffects(
    { importSpecQueue, projectId },
    {
      setAutoSaveComponentId,
      setReload,
      initRFQListFilter,
      initPOListFilter,
    }
  );

  const handleOpenAddSpec = useCallback(
    () =>
      openAddSpecAction(
        projectId,
        projectNumber,
        projectName,
        propertyId,
        clientId,
        areaId,
        defaultProjectCurrency.id
      ),
    [
      openAddSpecAction,
      projectId,
      projectNumber,
      projectName,
      propertyId,
      clientId,
      areaId,
      defaultProjectCurrency,
    ]
  );

  const handleOpenImportSpecModal = useCallback(
    () =>
      openImportSpecOptionsModal(
        project,
        clientId,
        `${projectNumber} ${projectName}`,
        areaId
      ),
    [
      openImportSpecOptionsModal,
      project,
      clientId,
      projectNumber,
      projectName,
      areaId,
    ]
  );

  const handleOpenBulkDeleteModal = useCallback(
    selectedIds => openBulkDeleteModalAction(selectedIds, projectId),
    [openBulkDeleteModalAction, projectId]
  );

  const handleOpenDuplicateSpecs = useCallback(
    selectedIds => openDuplicateSpecsAction(selectedIds, projectId),
    [openDuplicateSpecsAction, projectId]
  );
  const handleOpenIncompleteSpecsModal = useCallback(
    selectedIds => openIncompleteSpecsModalAction(selectedIds, projectId),
    [openIncompleteSpecsModalAction, projectId]
  );
  const handleOpenCompleteSpecsModal = useCallback(
    selectedIds => openCompleteSpecsModalAction(selectedIds, projectId),
    [openCompleteSpecsModalAction, projectId]
  );
  const handleOpenMoveSpecsModal = useCallback(
    selectedIds => openMoveSpecsModalAction(selectedIds, projectId),
    [openMoveSpecsModalAction, projectId]
  );

  const handleOpenCopySpecModal = useCallback(
    () => openCopySpecModalAction(dataComponent, projectId),
    [openCopySpecModalAction, dataComponent, projectId]
  );

  const handleOpenDuplicateSpecModal = useCallback(
    spec =>
      openDuplicateModalAction(dataComponentId, spec, clientId, projectId),
    [openDuplicateModalAction, clientId, projectId]
  );

  const handleOpenDeleteSpecModal = useCallback(
    spec => openDeleteModalAction(dataComponentId, spec, clientId, projectId),
    [openDeleteModalAction, clientId, projectId]
  );
  const handleOpenIncompleteSpecModal = useCallback(
    spec =>
      openIncompleteSpecModalAction(dataComponentId, spec, clientId, projectId),
    [clientId, openIncompleteSpecModalAction, projectId]
  );
  const handleOpenCompleteSpecModal = useCallback(
    spec =>
      openCompleteSpecModalAction(dataComponentId, spec, clientId, projectId),
    [clientId, openCompleteSpecModalAction, projectId]
  );
  const handleOpenQuickEditModal = useCallback(
    spec =>
      openQuickEditModalAction(dataComponentId, spec, clientId, getNextSpecId),
    [openQuickEditModalAction, clientId]
  );

  const handleGoToPO = useCallback(
    purchaseOrderId =>
      pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/purchase-orders/${purchaseOrderId}`,
        "SPECS"
      ),
    [pushWithReturnUrl, clientId, projectId]
  );

  const handleEditSpec = useCallback(
    ({ id }) =>
      pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/specs/${id}`,
        "SPECS"
      ),
    [pushWithReturnUrl, clientId, projectId]
  );

  const handleGoToBidGroup = useCallback(
    bidGroupId =>
      pushWithReturnUrl(
        `/clients/${clientId}/projects/${projectId}/quoting/bid-groups/${bidGroupId}/vendors`,
        "SPECS"
      ),
    [pushWithReturnUrl, clientId, projectId]
  );

  return (
    <SpecsPage
      {...props}
      onOpenBulkDeleteModal={handleOpenBulkDeleteModal}
      onOpenDuplicateSpecs={handleOpenDuplicateSpecs}
      onOpenImportSpecModal={handleOpenImportSpecModal}
      onOpenCopySpecModal={handleOpenCopySpecModal}
      onOpenAddSpec={handleOpenAddSpec}
      onOpenDuplicateSpecModal={handleOpenDuplicateSpecModal}
      onOpenDeleteSpecModal={handleOpenDeleteSpecModal}
      onEditSpec={handleEditSpec}
      onOpenIncompleteSpecModal={handleOpenIncompleteSpecModal}
      onOpenCompleteSpecModal={handleOpenCompleteSpecModal}
      onOpenIncompleteSpecsModal={handleOpenIncompleteSpecsModal}
      onOpenCompleteSpecsModal={handleOpenCompleteSpecsModal}
      onOpenMoveSpecsModal={handleOpenMoveSpecsModal}
      onGoToPO={handleGoToPO}
      onGoToBidGroup={handleGoToBidGroup}
      onRowClick={handleOpenQuickEditModal}
      apiRoute="specs"
      model={Spec}
      includes={[
        "area",
        "specCategory",
        "specDetails.[specDetailComs.spec.specCategory, roomTypes]",
        "purchaseOrder.[vendor, bidGroup]",
        "glCodes",
        "vendor",
        "bidGroups",
        "specRequirements.[requirement, submittalFiles.file]",
        "shipments",
        "projectCurrency.currency",
        "unitOfMeasure",
      ]}
    />
  );
};

SpecIndexContainer.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool,
  openBulkDeleteModalAction: PropTypes.func.isRequired,
  openCopySpecModalAction: PropTypes.func.isRequired,
  setAutoSaveComponentId: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  openDuplicateSpecsAction: PropTypes.func.isRequired,
  openAddSpecAction: PropTypes.func.isRequired,
  openDuplicateModalAction: PropTypes.func.isRequired,
  openDeleteModalAction: PropTypes.func.isRequired,
  openQuickEditModalAction: PropTypes.func.isRequired,
  openIncompleteSpecModalAction: PropTypes.func.isRequired,
  openCompleteSpecModalAction: PropTypes.func.isRequired,
  initRFQListFilter: PropTypes.func.isRequired,
  initPOListFilter: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func,
  project: propTypes.project.isRequired,
  role: propTypes.userRole,
  projectId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  areaId: PropTypes.string,
};

export const mapStateToProps = (state, { match }) => {
  const { queues } = state;
  const importSpecQueue = queues[queueCreateSpecsId];
  const rfqsDataComponent = getDataComponent(rfqFilterDataComponent, state);
  const posDataComponent = getDataComponent(poFilterDataComponent, state);

  return {
    projectId: match.params.projectId,
    importSpecQueue,
    clientId: match.params.clientId,
    areaId: match.params.areaId,
    dataComponent: getDataComponentFlattenedRequestState(
      dataComponentId,
      state
    ),
    isModalOpen: state.layout.modalDialog.isOpen,
    role: state.auth.role,
    rfqs: getResourcesV2(rfqsDataComponent, state, REQUEST_TYPES.LIST, []),
    pos: getResourcesV2(posDataComponent, state, REQUEST_TYPES.LIST, []),
  };
};

export const mapDispatchToProps = {
  pushWithReturnUrl,
  initDataComponent,
  setReload,
  performRetrieveListRequest,
  openBulkDeleteModalAction,
  openCopySpecModalAction,
  openDuplicateSpecsAction,
  openAddSpecAction,
  openDuplicateModalAction,
  openDeleteModalAction,
  openQuickEditModalAction,
  openImportSpecOptionsModal,
  openIncompleteSpecModalAction,
  openIncompleteSpecsModalAction,
  openCompleteSpecsModalAction,
  openMoveSpecsModalAction,
  openCompleteSpecModalAction,
  setAutoSaveComponentId,
  initRFQListFilter,
  initPOListFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecIndexContainer);
