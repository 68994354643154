import React, { useState, useEffect, useCallback, memo } from "react";
import _groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UserRoleForm from "./UserRoleForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import propTypes from "../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../ui/Loader";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import {
  dataComponentPermissionsId,
  dataComponentId,
} from "../UserRolesTable/UserRolesTableContainer";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const CreateUserRoleContainer = ({
  dataComponent,
  closeModalDialog,
  performCreateRequest,
  permissionsByGroup,
  loading,
  setReload,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  });

  const handleCreateUserRole = useCallback(
    ({ permissions, ...userRole }, formikActions) => {
      performCreateRequest(dataComponentId, {
        ...userRole,
        userRolePermissions: permissions.reduce(
          (result, isActive, permissionId) => {
            return isActive
              ? [
                  ...result,
                  {
                    permissionId,
                    active: true,
                  },
                ]
              : result;
          },
          []
        ),
      });
      setFormikAction(formikActions);
    },
    [performCreateRequest]
  );

  return (
    <LoaderContext.Provider value={{ loading }}>
      <UserRoleForm
        permissionsByGroup={permissionsByGroup}
        onSubmit={handleCreateUserRole}
        dataComponentId={dataComponentId}
      />
    </LoaderContext.Provider>
  );
};

CreateUserRoleContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  clientId: PropTypes.string,
  performCreateRequest: PropTypes.func,
  setReload: PropTypes.func,
  permissionsByGroup: PropTypes.shape({}),
  loading: PropTypes.bool,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const permissionsDataComponent = getDataComponentFlattenedRequestState(
    dataComponentPermissionsId,
    state
  );
  const permissions =
    getBluechipResources(permissionsDataComponent, state) || [];
  return {
    dataComponent,
    permissionsByGroup: _groupBy(permissions, "permissionGroup.label"),
    loading: permissionsDataComponent.loading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(CreateUserRoleContainer));
