import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Menu,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import MoreVert from "@material-ui/icons/MoreVert";
import styled from "styled-components";

import { buildPressEventHandlers } from "../../utils/eventUtils";
import {
  getIsProjectClosed,
  ProjectContext,
} from "../hooks/useIsProjectClosed";

export const StyledMenu = styled(
  ({ fullWidth, textColor, selectionFollowsFocus, indicator, ...props }) => (
    <Menu classes={{ paper: "paper" }} {...props} />
  )
)`
  & .paper {
    border-radius: 0;
  }
`;

export const StyledListItem = styled(
  ({ hasBorderTop, actionName, ...props }) => <ListItem button {...props} />
)`
  padding-left: 16px;
  min-width: 176px;
  pointer-events: auto !important;
  ${({ hasBorderTop }) =>
    hasBorderTop ? "border-top: 1px solid rgba(0,0,0,0.12);" : ""};
`;

const StyledListItemIcon = styled(ListItemIcon)`
  color: rgba(0, 0, 0, 0.24);
`;

export const StyledListItemText = styled(props => (
  <ListItemText classes={{ primary: "primary" }} {...props} />
))`
  margin: 0;
  padding: 0;
  & .primary {
    font-family: "Open Sans", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const StyledMoreVert = styled(MoreVert)`
  width: 24px;
  height: 24px;
`;

const getMenuItemsConfigured = (menuItems, row, tableRow) => {
  let menuItemsReturn = menuItems;
  let lastConfigWasASeparator = false;

  if (typeof menuItemsReturn === "function") {
    menuItemsReturn = menuItems(row, tableRow);
  }

  if (!menuItemsReturn) {
    return [];
  }

  return menuItemsReturn
    .map(({ separator, text: textPrimary, ...menuItem }) => {
      if (separator) {
        lastConfigWasASeparator = true;
        return null;
      }
      const menuItemConfig = {
        textPrimary,
        isRenderBorderTop: lastConfigWasASeparator,
        ...menuItem,
      };
      lastConfigWasASeparator = false;
      return menuItemConfig;
    })
    .filter(menuItem => menuItem !== null);
};

class RowMenu extends Component {
  static propTypes = {
    row: PropTypes.object.isRequired,
    tableRow: PropTypes.shape({
      rowId: PropTypes.number,
    }),
    menuItems: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    classes: PropTypes.object,
  };

  state = {
    anchorElement: null,
  };

  static contextType = ProjectContext;

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorElement: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorElement: null });
  };

  handleMenuItemClick = (menuItemAction, row, tableRow, disabled) => event => {
    event.stopPropagation();
    if (disabled) return;

    this.handleClose(event);
    if (menuItemAction) {
      menuItemAction(row, tableRow);
    }
  };

  render() {
    const {
      row,
      tableRow,
      menuItems,
      dismissSnackNotificationAction,
    } = this.props;

    const { anchorElement } = this.state;

    const menuItemsConfigured = getMenuItemsConfigured(
      menuItems,
      row,
      tableRow
    );

    if (menuItemsConfigured.length === 0) {
      return <Fragment />;
    }

    const { onClick, onKeyUp } = buildPressEventHandlers(
      this.handleClick,
      false,
      false,
      undefined,
      dismissSnackNotificationAction
    );

    const projectContext = this.context;
    const isProjectClosed = getIsProjectClosed(projectContext);

    return (
      <Fragment>
        <IconButton
          aria-owns={anchorElement ? "rowMenu_" + row.id : null}
          aria-haspopup="true"
          onClick={onClick}
          onKeyUp={onKeyUp}
          variant="flat"
        >
          <StyledMoreVert />
        </IconButton>
        <StyledMenu
          id={"rowMenu_" + row.id}
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={this.handleClose}
        >
          <List component="nav">
            {menuItemsConfigured.map(
              (
                {
                  isRenderBorderTop,
                  onClick,
                  icon,
                  textPrimary,
                  disableIfProjectClosed,
                  ...menuItem
                },
                i
              ) => {
                const disabled =
                  menuItem.disabled ||
                  (isProjectClosed && disableIfProjectClosed);
                return (
                  <StyledListItem
                    hasBorderTop={isRenderBorderTop}
                    key={i}
                    onClick={this.handleMenuItemClick(
                      onClick,
                      row,
                      tableRow,
                      disabled
                    )}
                    {...menuItem}
                    disabled={disabled}
                  >
                    {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
                    <StyledListItemText primary={textPrimary} />
                  </StyledListItem>
                );
              }
            )}
          </List>
        </StyledMenu>
      </Fragment>
    );
  }
}

export default RowMenu;
