import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormikContext } from "formik";
import _invoke from "lodash/invoke";
import { goBack } from "connected-react-router";

import { closeModalDialog } from "../../../actions/layoutActions";
import propTypes from "../../../constants/propTypes";
import withRequestListener from "../../hocs/withRequestListener";
import PrimaryActionButton from "./PrimaryActionButton";
import SecondaryActionButton from "./SecondaryActionButton";
import DangerButton from "./DangerButton";
import {
  PanelButtons,
  CancelButton,
  CancelButtonWithEmphasis,
} from "./components";
import useCloseOnEscape from "../../hooks/useCloseOnEscape";

const buildDefaultCancel = ({
  formik,
  isModal,
  closeModalDialog,
  goBack,
}) => () => {
  if (formik) {
    _invoke(formik, "resetForm", formik);
  }
  if (isModal) return closeModalDialog();
  setTimeout(() => {
    goBack();
  });
};

const isCloseOnEscapeEnabled = (isModal, { disabled } = {}) =>
  isModal && !disabled;

export const ActionButtons = ({
  onSend,
  sendButtonText = "SAVE",
  additionalProps = {},
  onSecondary,
  secondaryButtonText,
  cancelButtonText = "CANCEL",
  hideCancelButton,
  onDanger,
  dangerButtonText,
  isStacked,
  isModal,
  emphasizeCancel,
  disabled,
  hasThreeOption,
  alignRight,
  children,
  closeModalDialog,
  goBack,
  ...props
}) => {
  const formik = useContext(FormikContext);

  const {
    onCancel = buildDefaultCancel({
      formik,
      isModal: !!isModal,
      closeModalDialog,
      goBack,
    }),
  } = props;

  const isDangerButtonVisible = () => {
    const { hideDangerButton } = props;
    return onDanger && !hideDangerButton;
  };

  useCloseOnEscape(
    isCloseOnEscapeEnabled(isModal, additionalProps.cancel),
    onCancel
  );

  return (
    <PanelButtons
      isStacked={isStacked}
      isModal={isModal}
      alignRight={alignRight}
      hasThreeOption={hasThreeOption}
    >
      {onSend && (
        <PrimaryActionButton
          onClick={onSend}
          disabled={disabled}
          {...additionalProps.send}
        >
          {sendButtonText}
        </PrimaryActionButton>
      )}
      {onSecondary && (
        <SecondaryActionButton
          onClick={onSecondary}
          {...additionalProps.secondary}
        >
          {secondaryButtonText}
        </SecondaryActionButton>
      )}
      {!hideCancelButton &&
        (emphasizeCancel ? (
          <CancelButtonWithEmphasis
            onClick={onCancel}
            {...additionalProps.cancel}
          >
            {cancelButtonText}
          </CancelButtonWithEmphasis>
        ) : (
          <CancelButton onClick={onCancel} {...additionalProps.cancel}>
            {cancelButtonText}
          </CancelButton>
        ))}
      {isDangerButtonVisible() && (
        <DangerButton onClick={onDanger} {...additionalProps.danger}>
          {dangerButtonText}
        </DangerButton>
      )}
      {children}
    </PanelButtons>
  );
};

ActionButtons.propTypes = {
  onSend: PropTypes.func,
  sendButtonText: PropTypes.string,
  additionalProps: propTypes.buttonAdditionalProps,
  onSecondary: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  onDanger: PropTypes.func,
  hideDangerButton: PropTypes.bool,
  dangerButtonText: PropTypes.string,
  closeModalDialog: PropTypes.func,
  goBack: PropTypes.func,
  isModal: PropTypes.bool,
  alignRight: PropTypes.bool,
  isStacked: PropTypes.bool,
  emphasizeCancel: PropTypes.bool,
  disabled: PropTypes.bool,
  hasThreeOption: PropTypes.bool,
};

export default connect(null, { goBack, closeModalDialog })(
  withRequestListener(ActionButtons)
);
