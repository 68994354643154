import React, {
  Fragment,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ActionButtons } from "../../../forms";
import FormGrid from "../../../forms/FormGrid";
import propTypes from "../../../../constants/propTypes";
import PopoverDeleteConfirmation from "../NoteEdit/PopoverDeleteConfirmation";
import { dataComponentId } from "../NoteList/NoteListContainer";
import { ProjectContext } from "../../../hooks/useIsProjectClosed";
import { LabeledTextContext } from "../../../../withPORevision/withLabeledTextOption";

const ButtonContainer = styled.div`
  padding: 0 24px 24px;
`;

export const NoteForm = ({
  onSubmit,
  sendButtonText,
  fields,
  onCancel,
  values,
  errors,
  formikProps,
  onDeleteNote,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDeleteConfirmation = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    []
  );
  const handleCloseDeleteConfirmation = useCallback(
    () => setAnchorEl(null),
    []
  );

  const deleteProps = useMemo(
    () =>
      onDeleteNote
        ? {
            onDanger: handleOpenDeleteConfirmation,
            dangerButtonText: "Delete Note",
            hasThreeOption: true,
            additionalProps: { danger: { disableIfProjectClosed: true } },
          }
        : {},
    [handleOpenDeleteConfirmation, onDeleteNote]
  );

  const projectContext = useContext(ProjectContext);
  const isProjectClosed = values?.project?.status === "Closed";
  return (
    <ProjectContext.Provider
      value={{
        ...projectContext,
        ignoreProjectClosed: !isProjectClosed,
      }}
    >
      <LabeledTextContext.Provider value={isProjectClosed}>
        <Fragment>
          <FormGrid
            fields={fields}
            values={values}
            errors={errors}
            {...formikProps}
          />

          <ButtonContainer>
            <ActionButtons
              onSend={onSubmit}
              onCancel={onCancel}
              sendButtonText={sendButtonText}
              listeners={[dataComponentId]}
              {...deleteProps}
            />
          </ButtonContainer>
          <PopoverDeleteConfirmation
            anchorEl={anchorEl}
            onDelete={onDeleteNote}
            onClose={handleCloseDeleteConfirmation}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          />
        </Fragment>
      </LabeledTextContext.Provider>
    </ProjectContext.Provider>
  );
};

NoteForm.defaultProps = {
  isUpdate: false,
};

NoteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sendButtonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  values: propTypes.note.isRequired,
};

export default NoteForm;
