import React from "react";
import styled from "styled-components";
import { Paper } from "@material-ui/core";
import cloudinary from "cloudinary-core";
import { Image } from "cloudinary-react";
const cloudinaryCore = new cloudinary.Cloudinary({
  cloud_name: "full-stack-labs",
});

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url(${cloudinaryCore.url(
    "benjaminwest/login-background.png"
  )});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #28587a;
`;

export const Container = styled.div`
  margin-bottom: 160px;
`;

export const TitleWrapper = styled.div`
  width: 253px;
  margin: 0 auto;
  min-height: 39px;
`;

export const Title = () => (
  <TitleWrapper>
    <Image
      cloudName="full-stack-labs"
      publicId="benjaminwest/icn_bwlogo_n8piqt.svg"
      alt="Benjamin West"
    />
  </TitleWrapper>
);

export const AuthFormContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 393px;
  min-height: 307px;
  margin-top: 48px;
`;

export const AuthFormWrapper = styled.div`
  padding: 24px;
  place-self: center;
  text-align: center;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Instructions = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 24px;
`;
