import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/Remove";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import Discount from "../../../../models/Discount";
import Paper from "../../../mui/core/Paper";

const NotArchivedIcon = styled(RemoveIcon)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
`;

const Status = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: right;
`;

const StatusColumn = ({ applyToAllItems }) =>
  applyToAllItems ? <Status>Applies to All Items</Status> : <NotArchivedIcon />;
StatusColumn.propTypes = {
  applyToAllItems: PropTypes.bool,
};

const columns = ["programNameWithDiscountType", "discountPercent", "comments"];
const columnOptions = {
  programNameWithDiscountType: {
    title: "Name",
    bold: true,
    filter: true,
    width: "200px",
  },
  discountPercent: {
    title: "Amount",
    render: ({ discountPercent }) => `${discountPercent}%`,
  },
  comments: {
    title: "Comments",
    fill: true,
    filter: false,
  },
};

const getRowMenu = (onOpenEditModal, onOpenDeleteModal) => {
  return [
    {
      text: "Edit",
      onClick: onOpenEditModal,
    },
    { text: "Delete", onClick: onOpenDeleteModal },
  ];
};

const DiscountsPage = ({
  count,
  dataComponent,
  vendorId,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={Discount}
        apiRoute="discounts"
        includes={["program"]}
        rootFilters={{
          $where: {
            vendorId,
          },
        }}
        defaultSorting={[
          {
            columnName: "programNameWithDiscountType",
            direction: "asc",
          },
        ]}
      >
        <GridHeader
          headerText={pluralize("Discount", count || 0, true)}
          actions={[
            {
              text: "ADD DISCOUNT",
              icon: <AddCircle />,
              handler: onOpenCreateModal,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={getRowMenu(onOpenEditModal, onOpenDeleteModal)}
        />
      </BWGridAPI>
    </Paper>
  );
};
DiscountsPage.propTypes = {
  count: PropTypes.number,
  vendorId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};
export default DiscountsPage;
