import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import styled from "styled-components";

export const RevisionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActivitiesWrapper = styled.div`
  max-height: 438px;
  overflow-y: auto;
`;

export const ActivityContainer = styled.div`
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  padding: 12px 24px;
  align-items: center;
`;

export const Attribute = styled.div`
  width: 30%;
  font-weight: bold;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.87);
`;
export const Previous = styled.div`
  margin-right: 30px;
  color: #979797;
`;
export const Current = styled.div`
  margin-right: 30px;
  color: rgba(0, 0, 0, 0.87);
`;
export const StyledArrow = styled(ArrowRightAlt)`
  margin-right: 30px;
`;
export const InstanceTitle = styled.div`
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  min-height: 56px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  padding: 24px 24px 16px 24px;
`;

export const RevisionNotes = styled(ActivityContainer)`
  display: flex;
  font-weight: 600;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  align-items: flex-start;
  max-height: 250px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.54);

  & ${InstanceTitle} {
    font-size: 12px;
    font-weight: normal;
    padding: 12px 0px 0px 0px;
    height: auto;
    border-top: none;
  }
`;
