import styled from "styled-components";
import { InputAdornment } from "@material-ui/core";

export default styled(InputAdornment)`
  padding: 8px;
  margin: 0;
  height: 24px;
  width: 24px;
  box-sizing: content-box;
`;
