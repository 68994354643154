import React, { useCallback } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import pluralize from "pluralize";

import { EditIcon, ItemRowWrapper } from "../components";
import propTypes from "../../../../constants/propTypes";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const hasAllContacts = (contactsLength, bidGroupContactsLength) =>
  contactsLength === bidGroupContactsLength;

export const ItemRow = ({ row, contacts, onOpenSelectVendorContact }) => {
  const bidGroupContactsLength = _get(row, "selectedContacts.length", 0);
  const contactsLength = _get(contacts, "length", 0);
  const allContacts = hasAllContacts(contactsLength, bidGroupContactsLength);
  const isProjectClosed = useIsProjectClosed();

  const handleClick = useCallback(() => {
    onOpenSelectVendorContact(row);
  }, [onOpenSelectVendorContact, row]);

  const getText = useCallback(() => {
    if (!bidGroupContactsLength) return "Select Contacts";
    if (allContacts) return `All Contacts (${bidGroupContactsLength})`;

    return pluralize("Contact", bidGroupContactsLength, true);
  }, [allContacts, bidGroupContactsLength]);

  return (
    <ItemRowWrapper isEmpty={!bidGroupContactsLength} onClick={handleClick}>
      {getText()}
      {bidGroupContactsLength && !isProjectClosed ? <EditIcon /> : null}
    </ItemRowWrapper>
  );
};

ItemRow.propTypes = {
  row: propTypes.bidGroupVendor,
  contacts: PropTypes.arrayOf(propTypes.vendorContact),
  onOpenSelectVendorContact: PropTypes.func,
};

export default ItemRow;
