import React, { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import DropdownMenu from "../../layout/AppLayout/DropdownMenu";
import { useBluechipResources } from "../../hooks/useResources";
import { isPOChangeDisabled } from "../PurchaseOrderDetail/utils";

export const SpecActions = ({
  dataComponent,
  onOpenDuplicateSpecs,
  onOpenBulkDeleteModal,
  onOpenCopySpecModal,
  onOpenIncompleteSpecModal,
  onOpenCompleteSpecModal,
  onOpenMoveSpecsModal,
}) => {
  const { selectedIds = [] } = dataComponent;
  const onOpenBulkDeleteModalWithSelectedIds = useCallback(() => {
    onOpenBulkDeleteModal(selectedIds);
  }, [onOpenBulkDeleteModal, selectedIds]);
  const onOpenDuplicateSpecsModalWithSelectedIds = useCallback(() => {
    onOpenDuplicateSpecs(selectedIds);
  }, [onOpenDuplicateSpecs, selectedIds]);
  const onOpenIncompletesModalWithSelectedIds = useCallback(() => {
    onOpenIncompleteSpecModal(selectedIds);
  }, [onOpenIncompleteSpecModal, selectedIds]);
  const onOpenCompletesModalWithSelectedIds = useCallback(() => {
    onOpenCompleteSpecModal(selectedIds);
  }, [onOpenCompleteSpecModal, selectedIds]);
  const onOpenMoveSpecsModalWithSelectedIds = useCallback(() => {
    onOpenMoveSpecsModal(selectedIds);
  }, [onOpenMoveSpecsModal, selectedIds]);

  const selectedSpecs = useBluechipResources(
    dataComponent.model,
    ["purchaseOrder"],
    selectedIds
  );

  const options = useMemo(() => {
    const disabled = selectedIds.length === 0;

    const isBulkChangeSpecDisabled =
      disabled ||
      selectedSpecs.reduce(
        (result, spec) => result || isPOChangeDisabled(spec.purchaseOrder),
        false
      );

    return [
      {
        content: "Duplicate Specs within Project",
        onClick: onOpenDuplicateSpecsModalWithSelectedIds,
        disabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Copy Specs into Another Project",
        onClick: onOpenCopySpecModal,
        disabled,
      },
      {
        content: "Incomplete Specs",
        onClick: onOpenIncompletesModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Complete Specs",
        onClick: onOpenCompletesModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
      {
        content: "Move to Area",
        onClick: onOpenMoveSpecsModalWithSelectedIds,
        disabled: disabled,
        disableIfProjectClosed: true,
        useSeparator: true,
      },
      {
        content: disabled
          ? "Delete Specs"
          : `Delete ${selectedIds.length} Specs`,
        onClick: onOpenBulkDeleteModalWithSelectedIds,
        disabled: isBulkChangeSpecDisabled,
        disableIfProjectClosed: true,
      },
    ];
  }, [
    selectedIds.length,
    selectedSpecs,
    onOpenDuplicateSpecsModalWithSelectedIds,
    onOpenCopySpecModal,
    onOpenIncompletesModalWithSelectedIds,
    onOpenCompletesModalWithSelectedIds,
    onOpenMoveSpecsModalWithSelectedIds,
    onOpenBulkDeleteModalWithSelectedIds,
  ]);

  return <DropdownMenu buttonText="Actions" options={options} />;
};

SpecActions.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  onOpenBulkDeleteModal: PropTypes.func.isRequired,
  onOpenDuplicateSpecs: PropTypes.func.isRequired,
  onOpenCopySpecModal: PropTypes.func.isRequired,
  onOpenIncompleteSpecModal: PropTypes.func.isRequired,
  onOpenCompleteSpecModal: PropTypes.func.isRequired,
  onOpenMoveSpecsModal: PropTypes.func.isRequired,
};

export default memo(SpecActions);
