import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import TeamForm from "./TeamForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { reloadDataComponent } from "../../../../../actions/initializeStoreActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { TEAMS_SELECT } from "../../../../../constants/DataComponents";
import { dataComponentId } from "../TeamsContainer";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
      reloadDataComponent(TEAMS_SELECT);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateTeamContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  reloadDataComponent,
  team: { id: sourceId, ...team },
  performCreateRequest,
  loading,
  params,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      reloadDataComponent,
      formikActions
    );
  });

  const handleCreate = useCallback(
    (instance, formikActions) => {
      const team = {
        name: instance.name,
        users: instance.users.map(user => ({ id: user.id })),
      };
      performCreateRequest(dataComponentId, team, params);
      setFormikAction(formikActions);
    },
    [performCreateRequest, params]
  );

  return (
    <TeamForm initialValues={team} onSubmit={handleCreate} loading={loading} />
  );
};

CreateTeamContainer.defaultProps = {
  team: {
    name: "",
    users: [],
  },
};

CreateTeamContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  team: propTypes.team,
  performCreateRequest: PropTypes.func,
  setReload: PropTypes.func,
  reloadDataComponent: PropTypes.func,
  loading: PropTypes.bool,
  params: PropTypes.shape({
    termContext: PropTypes.string,
    contextValue: PropTypes.string,
  }),
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
  reloadDataComponent,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.create.loading"),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTeamContainer);
