import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _get from "lodash/get";
import moment from "moment";

import {
  openModalDialog,
  closeModalDialog,
} from "../../../../../actions/layoutActions";
import useActions from "../../../../hooks/useActions";
import propTypes from "../../../../../constants/propTypes";
import ContractGrid from "./ContractGrid";
import Loader from "../../../../ui/Loader";
import RegularText from "../../../../ui/Typography/RegularText";
import DragDropInputFile from "../../../../inputs/DragDrop/DragDropInputFile";
import { useModalControl } from "../../../../inputs/DragDrop/DragDropInputFile/useModalControl";

const TextWrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
`;

const RenderedField = ({
  formikArrayHelpers,
  client,
  rows,
  dragDropModalOpen,
  handleCloseDragDropModal,
  projectId,
}) => {
  const toReplaceRow = useRef();
  const actions = useActions({ openModalDialog, closeModalDialog });
  const { open: replaceFileOpen, onOpen, onClose } = useModalControl();

  const contractsAreEmpty = rows.length === 0;

  const handleAddContract = evt => {
    const file = evt.target.files[0];
    if (!file) return;

    formikArrayHelpers.push({
      isActive: contractsAreEmpty,
      uploadedAt: moment()
        .toDate()
        .toISOString(),
      file: { filename: file.name, file },
    });
  };

  const deleteHandler = index => () => {
    formikArrayHelpers.remove(index);
    actions.closeModalDialog();
  };

  const handleDelete = row => {
    const index = rows.findIndex(currentRow => currentRow === row);
    actions.openModalDialog(
      ["Delete Contract File", _get(client, "name")],
      "DeleteContract",
      { index, onDelete: deleteHandler(index), title: row.file.filename },
      false,
      false
    );
  };

  const updateFlagHandler = (index, contractFile, isActive) => () => {
    formikArrayHelpers.replace(index, { ...contractFile, isActive });
    actions.closeModalDialog();
  };

  const handleFlagContract = row => {
    const index = rows.findIndex(currentRow => currentRow === row);

    actions.openModalDialog(
      ["Flag as Active Contract file", _get(client, "name")],
      "FlagUnflagContract",
      {
        onSubmit: updateFlagHandler(index, row, true),
        title: `flag as active contract ${row.file.filename}`,
        buttonText: "Flag as Active contract",
      },
      false,
      false
    );
  };

  const handleUnflagContract = row => {
    const index = rows.findIndex(currentRow => currentRow === row);

    actions.openModalDialog(
      ["Unflag as Active Contract file", _get(client, "name")],
      "FlagUnflagContract",
      {
        onSubmit: updateFlagHandler(index, row, false),
        title: `unflag as active contract ${row.file.filename}`,
        buttonText: "Unflag as Active contract",
      },
      false,
      false
    );
  };

  const handleReplaceClick = row => {
    onOpen();
    toReplaceRow.current = row;
  };

  const handleReplaceContract = evt => {
    const file = evt.target.files[0];
    if (!file) return;

    const row = toReplaceRow.current;
    const index = rows.findIndex(currentRow => currentRow === row);
    formikArrayHelpers.replace(index, {
      ...row,
      uploadedAt: moment()
        .toDate()
        .toISOString(),
      file: { filename: file.name, file },
    });
  };

  return (
    <div>
      <DragDropInputFile
        open={dragDropModalOpen}
        onClose={handleCloseDragDropModal}
        onChange={handleAddContract}
        isForm={!projectId}
      />
      <DragDropInputFile
        open={replaceFileOpen}
        onClose={onClose}
        onChange={handleReplaceContract}
        isForm={!projectId}
      />
      {contractsAreEmpty ? (
        <TextWrapper intent="light">
          <RegularText intent="light">
            <Loader height="20px" width="200px">
              No contracts have been uploaded yet
            </Loader>
          </RegularText>
        </TextWrapper>
      ) : (
        <ContractGrid
          rows={rows}
          onDelete={handleDelete}
          onFlag={handleFlagContract}
          onUnflag={handleUnflagContract}
          onReplace={handleReplaceClick}
        />
      )}
    </div>
  );
};
RenderedField.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  addFileRef: PropTypes.shape({}),
  rows: PropTypes.arrayOf(propTypes.contractFile),
  client: propTypes.client,
  dragDropModalOpen: PropTypes.bool,
  handleCloseDragDropModal: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

export default RenderedField;
