import pluralize from "pluralize";
import _omitBy from "lodash/omitBy";
import _omit from "lodash/omit";
import _get from "lodash/get";

import { openModalDialog, showSnackNotificationAction } from "./layoutActions";
import {
  ContentWrapper,
  SpecsDeleteWrapper,
} from "../components/pages/Specs/components";

import {
  ContentWrapper as SpecsContentWrapper,
  Overlay,
} from "../components/pages/Specs/DuplicateSpecs/components";

import {
  fetchDataRequest,
  fetchDataSuccess,
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  performUpdateRequest,
} from "./dataComponentActions";
import { createQueueProcess } from "./queuesActions";
import { getDataComponent } from "../reducers/dataComponentReducer";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import UnitOfMeasure from "../models/UnitOfMeasure";
import * as DATA_COMPONENTS from "../constants/DataComponents";
import Vendor from "../models/Vendor";
import Spec from "../models/Spec";
import { getBluechipResourceById } from "../utils/bluechipUtils";
import { updatePreview } from "./specDetailActions";
import SpecDetail from "../models/SpecDetail";
import { cleanCurrencyValue } from "../utils/currencyFormatter";

export const unitOfMeasureDataComponentId =
  DATA_COMPONENTS.UNIT_OF_MEASURE_SELECT;

export const createSpecFromPDFDataComponentId =
  "createSpecFromPDFDataComponentId";
export const previewDataComponentId = "previewDataComponentId";

export function openBulkDeleteModalAction(selectedIds, projectId) {
  return openModalDialog(
    "Delete Specs",
    "BulkDeleteSpecs",
    { projectId },
    false,
    false,
    {
      components: { Content: SpecsDeleteWrapper },
      subtitle: `${selectedIds.length} Spec Selected`,
      width: "700px",
    }
  );
}

export function openDuplicateSpecsAction(selectedIds, projectId) {
  return openModalDialog(
    "Duplicate Specs within Project",
    "DuplicateSpecs",
    {
      projectId,
    },
    false,
    false,
    {
      subtitle: `${pluralize("Spec", selectedIds.length, true)} Selected`,
      components: { Content: SpecsContentWrapper, Overlay },
      width: "auto",
    }
  );
}

export function openCopySpecModalAction(dataComponent, projectId) {
  return openModalDialog(
    [
      "Copy Specs to Another Project",
      `${pluralize("Spec", dataComponent.selectedIds.length, true)} Selected`,
    ],
    "CopySpecs",
    {
      projectId,
    },
    true,
    false,
    {
      components: { Content: SpecsContentWrapper, Overlay },
      width: "auto",
    }
  );
}

export function openAddSpecAction(
  projectId,
  projectNumber,
  projectName,
  propertyId,
  clientId,
  areaId,
  projectCurrencyId
) {
  return openModalDialog(
    ["Create Spec", `New Spec in ${projectNumber} ${projectName}`],
    "CreateSpec",
    { projectId, propertyId, clientId, areaId, projectCurrencyId },
    true,
    false,
    {
      width: "828px",
    }
  );
}

export function openDuplicateModalAction(
  dataComponentId,
  spec,
  clientId,
  projectId,
  navigateToNewPO = false
) {
  return openModalDialog("Duplicate Spec", "DuplicateSpec", {
    title: spec.customNumber,
    spec,
    clientId,
    projectId,
    dataComponentId,
    navigateToNewPO,
  });
}

export function openDeleteModalAction(
  dataComponentId,
  spec,
  clientId,
  projectId,
  navigations
) {
  return openModalDialog("Delete Spec", "DeleteSpec", {
    dataComponentId,
    title: spec.customNumber,
    specId: spec.id,
    clientId,
    projectId,
    purchaseOrderId: spec.purchaseOrderId,
    navigate: !!navigations,
    backToPO: _get(navigations, "backToPO", false),
  });
}
export function openIncompleteSpecModalAction(dataComponentId, spec) {
  return openModalDialog(
    "Flag as Incomplete",
    "IncompleteSpec",
    {
      dataComponentId,
      title: spec.customNumber,
      spec: spec,
      specId: spec.id,
    },
    false,
    false,
    {
      layoutType: "default",
      subtitle: `${spec.customNumber} ${spec.description}`,
    }
  );
}
export function openIncompleteSpecsModalAction(selectedIds, projectId) {
  return openModalDialog(
    "Flag as Incomplete",
    "IncompleteSpecs",
    {
      projectId,
    },
    false,
    false,
    {
      layoutType: "default",
      subtitle: `${pluralize("Spec", selectedIds.length, true)}`,
    }
  );
}
export function openCompleteSpecModalAction(dataComponentId, spec) {
  return openModalDialog(
    "Remove Incomplete Flag",
    "CompleteSpec",
    {
      dataComponentId,
      title: spec.customNumber,
      spec: spec,
      specId: spec.id,
    },
    false,
    false,
    {
      layoutType: "default",
      subtitle: `${spec.customNumber} ${spec.description}`,
    }
  );
}
export function openCompleteSpecsModalAction(selectedIds, projectId) {
  return openModalDialog(
    "Remove Incomplete Flag",
    "CompleteSpecs",
    {
      projectId,
    },
    false,
    false,
    {
      layoutType: "default",
      subtitle: `${pluralize("Spec", selectedIds.length, true)}`,
    }
  );
}

export function openMoveSpecsModalAction(selectedIds, projectId) {
  return openModalDialog(
    [
      "Move Specs to Another Area",
      `${pluralize("Spec", selectedIds.length, true)} Selected`,
    ],
    "MoveSpecs",
    {
      projectId,
    },
    false,
    false,
    {
      layoutType: "default",
    }
  );
}

export function openQuickEditModalAction(
  dataComponentId,
  spec,
  clientId,
  getNextSpecId
) {
  return openModalDialog(
    ["Edit Spec", `${spec.customNumber} ${spec.description}`],
    "QuickEditSpec",
    {
      dataComponentId,
      spec: spec,
      specId: spec.id,
      projectId: spec.projectId,
      clientId,
      getNextSpecId,
      purchaseOrderId: spec.purchaseOrderId,
    },
    false,
    false,
    {
      width: "828px",
    }
  );
}

export function openImportSpecModal({
  project,
  clientId,
  projectName,
  step,
  isFromProjects,
  areaId,
}) {
  return openModalDialog(
    ["Import Specs to", projectName],
    "ImportSpecs",
    { project, clientId, areaId, projectName, step, isFromProjects },
    true,
    true,
    {
      components: { Content: ContentWrapper },
      scrollDisabled: true,
      width: "90vw",
    }
  );
}

export function openImportSpecOptionsModal(
  project,
  clientId,
  projectName,
  areaId
) {
  return openModalDialog(
    ["Import Specs to", projectName],
    "ImportOptions",
    { project, clientId, areaId, projectName },
    true,
    true
  );
}

export const uploadSpecsFromXls = (file, projectId, queueId) => {
  return dispatch => {
    const data = new FormData();
    data.append("handler", "specs");
    data.append("file", file);
    data.append("projectId", projectId);
    dispatch(createQueueProcess("import-from-xls", queueId, data));
  };
};

export function importSpecs(
  data,
  { project, defaultAreaId, queueCreateSpecsId }
) {
  return dispatch => {
    const fieldsToOmit = [
      "area",
      "createdAt",
      "specCategory",
      "purchaseOrder",
      "purchaseOrderId",
      "id",
      "specDetailIdSequence",
      "parentId",
      "isCommitted",
      "shipmentTotalQty",
      "totalQuantity",
      "totalPrice",
      "totalForecast",
      "totalQuantityWithoutOverage",
    ];
    const specs = data.map(spec => ({
      ..._omitBy(_omit(spec, fieldsToOmit), value => value === null),
      projectId: Number(project.id),
      customNumber: spec.customNumber.toString(),
      overagePercent: Number(spec.overagePercent),
      baseQuantity: Number(spec.baseQuantity),
      atticStock: Number(spec.atticStock),
      priceCents: Number(spec.priceCents),
      areaId: Number(spec.areaId || defaultAreaId),
      vendorId: spec.vendorId ? Number(spec.vendorId) : null,
      specCategoryId: spec.specCategoryId ? Number(spec.specCategoryId) : null,
    }));
    dispatch(
      createQueueProcess("bulk-create", queueCreateSpecsId, {
        objects: specs,
        modelType: "Spec",
      })
    );
  };
}

export function initDetailSpecRow(srcDataComponentId, itemId) {
  return function(dispatch, getState) {
    const dataComponent = getDataComponent(srcDataComponentId, getState());
    dispatch(
      initDataComponent(
        `${srcDataComponentId}_${itemId}`,
        dataComponent.model,
        dataComponent.includes,
        dataComponent.apiRoute,
        false,
        "v2"
      )
    );
  };
}

export function saveDetailSpecRowChanges(srcDataComponentId, itemId, body) {
  return async dispatch => {
    dispatch(fetchDataRequest(srcDataComponentId, REQUEST_TYPES.UPDATE));
    await dispatch(
      performUpdateRequest(`${srcDataComponentId}_${itemId}`, itemId, body)
    );
    dispatch(fetchDataSuccess(srcDataComponentId, REQUEST_TYPES.UPDATE, {}));
  };
}

export function fetchUnitOfMeasures(
  dataComponentId = unitOfMeasureDataComponentId
) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        UnitOfMeasure,
        [],
        "unit-of-measures",
        undefined,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        sort: [{ columnName: "name", direction: "asc" }],
        pageSize: -1,
        fields: ["unit_of_measures.id", "name"],
      })
    );
  };
}

export function fetchVendors(projectId) {
  const $where = projectId ? { "specs.projectId": projectId } : {};
  return async (dispatch, getState) => {
    const {
      scope: { id: scopeId },
    } = getState();
    dispatch(
      initDataComponent(DATA_COMPONENTS.VENDORS_SELECT, Vendor, [], "vendors")
    );
    dispatch(
      performRetrieveListRequest(DATA_COMPONENTS.VENDORS_SELECT, {
        sort: [{ columnName: "name", direction: "asc" }],
        fields: ["vendors.id", "name", "status", "scopeId"],
        pageSize: -1,
        rootFilters: {
          $where: {
            "scope.id": scopeId,
            ...$where,
          },
        },
      })
    );
  };
}

export function createSpec(spec, { resetForm }) {
  return async (dispatch, getState) => {
    await dispatch(
      initDataComponent(
        createSpecFromPDFDataComponentId,
        Spec,
        ["specDetails"],
        "specs"
      )
    );
    await dispatch(
      initDataComponent(previewDataComponentId, SpecDetail, [], "spec-details")
    );
    await dispatch(
      fetchDataRequest(previewDataComponentId, REQUEST_TYPES.IMPORT)
    );

    if (spec.priceCents) {
      spec.priceCents = cleanCurrencyValue(spec.priceCents * 100);
    }

    const previews = spec.specDetails
      .filter(({ type }) => type === "Preview")
      .map(specDetail => {
        const preview = specDetail.preview;
        delete specDetail.preview;
        return preview;
      });

    const {
      rowIndex: [id],
    } = await dispatch(
      performCreateRequest(createSpecFromPDFDataComponentId, spec)
    );

    const state = getState();
    const createdSpec = getBluechipResourceById(
      getDataComponent(createSpecFromPDFDataComponentId, state),
      state,
      id
    );

    let index = 0;
    /**
     * Promise.all sometimes jams S3 and some PUT requests fail
     */
    for await (const previewUrl of previews) {
      const specDetail = createdSpec.specDetails.filter(
        ({ type }) => type === "Preview"
      )[index];
      const response = await fetch(previewUrl);
      const image = await response.blob();
      image.name = `imported-from-pdf-${index}.${image.type.split("/")[1]}`;
      await dispatch(
        updatePreview(
          previewDataComponentId,
          createdSpec.id,
          createdSpec.projectId,
          specDetail.id,
          { file: image }
        )
      );
      index++;
    }

    await dispatch(
      fetchDataSuccess(previewDataComponentId, REQUEST_TYPES.IMPORT)
    );
    resetForm();
    dispatch(
      showSnackNotificationAction(
        `Spec ${spec.customNumber} was created successfully.`
      )
    );
  };
}
