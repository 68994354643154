import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import pluralize from "pluralize";

import SerifTitle from "../../../ui/Typography/SerifTitle";
import RegularText from "../../../ui/Typography/RegularText";
import ActionButtons from "../../../forms/ActionButtons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Title = styled(SerifTitle)`
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
`;

export const Counter = styled(SerifTitle)`
  font-size: 16px;
  line-height: 24px;
  color: #57abff;
  margin: 16px 0 24px 0;
`;

const Dialog = ({ onDismiss, seconds }) => {
  return (
    <Wrapper>
      <Title>Hey, still there?</Title>
      <RegularText>
        Your account is going to log out due to inactivity in:
      </RegularText>
      <Counter>{pluralize("Second", seconds, true)}</Counter>
      <ActionButtons
        onSend={onDismiss}
        sendButtonText="DISMISS"
        hideCancelButton
      />
    </Wrapper>
  );
};

Dialog.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  seconds: PropTypes.number,
};

export default Dialog;
