import inputs from "../../../inputs/inputConfigs/invoice";
import { withOverwrittedLabeledContext } from "../../../inputs/inputConfigs/invoice";
import TextInputWithError from "../../../inputs/TextInputWithError";

export default (checkNumber, setCurrentCell) => {
  const crRemarks = {
    ...inputs.crRemarks,
    onKeyDown: evt => {
      if (evt.code === "Tab" && evt.shiftKey) {
        evt.preventDefault();
        evt.stopPropagation();
        setCurrentCell({ rowId: 8, columnId: 3 });
      }
    },
  };
  const internalRemarks = { ...inputs.internalRemarks };

  if (checkNumber) {
    crRemarks.InputComponent = withOverwrittedLabeledContext(
      TextInputWithError
    );
    internalRemarks.InputComponent = withOverwrittedLabeledContext(
      TextInputWithError
    );
  }

  return {
    groups: [
      {
        items: [
          {
            input: crRemarks,
            grid: { xs: 12 },
          },
          {
            input: internalRemarks,
            grid: { xs: 12 },
          },
        ],
      },
    ],
  };
};
