import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Indicator from "../../../Vendors/tableComponents/Indicator";
import PropTypes from "prop-types";

const IndicatorWrapper = ({ row }) => {
  return (
    <Indicator
      isLastCOM={row.isLastCOM}
      isParent={row.isParent}
      type={row.type}
    />
  );
};

IndicatorWrapper.propTypes = {
  row: PropTypes.shape({
    isLastCOM: PropTypes.bool,
    isParent: PropTypes.bool,
    type: PropTypes.string,
  }),
};

const IndicatorProvider = props => (
  <DataTypeProvider
    formatterComponent={IndicatorWrapper}
    {...props}
    for={["indicator"]}
  />
);

export default IndicatorProvider;
