import { BaseModel } from "@blue-chip/core";
import Vendor from "./Vendor";
import Project from "./Project";
import ProjectCurrency from "./ProjectCurrency";
import RemitAddress from "./RemitAddress";
import ApprovalFile from "./ApprovalFile";
import PurchaseOrderContact from "./PurchaseOrderContact";
import PurchaseOrderDiscount from "./PurchaseOrderDiscount";
import ConsultingCompany from "./ConsultingCompany";
import Commodity from "./Commodity";
import Revision from "./Revision";
import ProjectSite from "./ProjectSite";
import VendorShipToSite from "./VendorShipToSite";
import Spec from "./Spec";
import BidGroup from "./BidGroup";
import Invoice from "./Invoice";
import SpecShipment from "./SpecShipment";

export default class PurchaseOrder extends BaseModel {
  static get tableName() {
    return "purchase_orders";
  }

  static get belongsTo() {
    return [
      Vendor,
      Project,
      RemitAddress,
      ProjectCurrency,
      ProjectSite,
      VendorShipToSite,
      BidGroup,
      ConsultingCompany,
    ];
  }

  static get hasMany() {
    return [
      PurchaseOrderContact,
      PurchaseOrderDiscount,
      ApprovalFile,
      Commodity,
      Revision,
      Spec,
      Invoice,
      SpecShipment,
    ];
  }
}
