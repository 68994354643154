import styled from "styled-components";
import { FormLabel } from "@material-ui/core";
import React from "react";

export default styled(props => (
  <FormLabel classes={{ root: "label", focused: "label-focused" }} {...props} />
))`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8) !important;
`;
