import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import propTypes from "../../../../constants/propTypes";
import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import BWModels from "benjaminwest-models";
import FormGrid from "../../../forms/FormGrid";
import { Container } from "./components";
import ActionButtons from "../../../forms/ActionButtons";
import FormikForm from "../../../forms/FormikForm";
import PictureEditableSection from "./PictureEditableSection";
import { inputs } from "../../../inputs/inputConfigs";
export const dataComponentId = "userDataComponent";

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.profilePicture,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

const ignoredFileds = [
  "officeId",
  "firstName",
  "lastName",
  "email",
  "userRoleId",
];

const AccountSettings = ({ userData, dataComponent, actions }) => {
  const [metadata, setMetada] = useState({});
  const userSchema = BWModels.loadSchema("User");

  useEffect(() => {
    actions.initUserComponent();
  }, [actions, actions.initUserComponent]);

  const handleSubmit = value => {
    const initMetadata = _get(userData, "profilePicture.file.metadata", {});
    actions.uploadUserProfile(
      userData,
      userData.profilePicture ? userData.profilePicture.id : undefined,
      value.profilePicture.file,
      { ...initMetadata, ...metadata }
    );
  };

  const backToSettings = () => {
    actions.openAccountSettingsModal(userData.name, userData.email);
  };

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.validateAuthentication();
      actions.showSnackNotificationAction(
        "The account has been saved successfully"
      );
      backToSettings();
    },
  });

  const handleUpdateMetadata = newMetadata => {
    setMetada({
      ...metadata,
      ...newMetadata,
    });
  };

  return (
    <FormikForm
      initialValues={userData}
      onSubmit={handleSubmit}
      validationSchema={userSchema}
      ignoreValidationFieldNames={ignoredFileds}
      isPatch
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Container>
          <FormGrid
            fields={fields}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <PictureEditableSection
            file={values.profilePicture && values.profilePicture.file}
            user={values}
            onUpdateZoomOrPan={handleUpdateMetadata}
            initialMetadata={
              values.profilePicture && values.profilePicture.metadata
            }
          />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={backToSettings}
            isModal={true}
            isDisable={!values.profilePicture}
            sendButtonText="Save Profile Picture"
            listeners={[dataComponentId]}
          />
        </Container>
      )}
    </FormikForm>
  );
};

AccountSettings.propTypes = {
  userData: PropTypes.shape({ id: PropTypes.text }),
  dataComponent: propTypes.dataComponent,
  actions: PropTypes.shape({
    openAccountSettingsModal: PropTypes.func,
  }),
};

export default AccountSettings;
