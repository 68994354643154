import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateUserForm from "./CreateUserForm";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  setReload,
} from "../../../../actions/dataComponentActions";

import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";

import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";
import { getResourcesV2 } from "../../../../utils/bluechipUtils";

export const dataComponentId = "UsersGrid";

export class CreateUserContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      setReload,
      showSnackNotificationAction,
    } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => {
        closeModalDialog();
        setReload(dataComponentId, true);
      },
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  createUser = ({ name, ...user }, formikActions) => {
    const { performCreateRequest } = this.props;
    performCreateRequest(dataComponentId, { ...user, isInternalUser: true });
    this.setState({ formikActions });
  };

  render() {
    const { closeModalDialog, userRoles } = this.props;

    return (
      <CreateUserForm
        createUser={this.createUser}
        closeModalDialog={closeModalDialog}
        dataComponentId={dataComponentId}
        userRoles={userRoles}
      />
    );
  }
}

CreateUserContainer.propTypes = {
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performCreateRequest: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  userRoles: PropTypes.array,
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const userRoleDataComponent = getDataComponentFlattenedRequestState(
    "select-userRoles",
    state
  );

  return {
    dataComponent,
    userRoles: getResourcesV2(userRoleDataComponent, state),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  setReload,
  showSnackNotificationAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserContainer);
