import React from "react";
import get from "lodash.get";
import BoldText from "../Typography/BoldText";
import RowMenu from "../../menus/RowMenu";
import getCellValueWrapper from "./gridInternalComponents/BWDraggableCell";

export function isGridRow(node) {
  return node !== undefined && node.type !== undefined && node.type.isGridRow;
}

export function isGridTable(node) {
  return node !== undefined && node.type !== undefined && node.type.isGridTable;
}

export function getGridTableProps(children) {
  let gridTableProps = undefined;
  React.Children.forEach(children, child => {
    if (isGridTable(child)) {
      gridTableProps = child.props;
    }
  });
  if (gridTableProps === undefined) {
    throw Error("No GridTable found");
  }
  if (gridTableProps.rowMenu && gridTableProps.columnOptions) {
    gridTableProps.columnOptions.gridTableRowMenu = {
      name: "gridTableRowMenu",
      align: "center",
      width: "80px",
    };
  }
  return gridTableProps;
}

export function generateGridColumns({
  columns,
  columnOptions,
  menuItems,
  dismissSnackNotificationAction,
}) {
  const gridColumns = generateGridColumnConfigs(columns, columnOptions);
  if (menuItems) {
    const renderRowMenu = (row, columnName, tableRow) => (
      <RowMenu
        row={row}
        tableRow={tableRow}
        menuItems={menuItems}
        dismissSnackNotificationAction={dismissSnackNotificationAction}
      />
    );
    gridColumns.unshift({
      name: "gridTableRowMenu",
      title: " ",
      firstColumn: true,
      width: "80px",
      getCellValue: renderRowMenu,
    });
  }

  return gridColumns;
}

export function generateGridColumnConfigs(columns, columnOptions) {
  return columns.map((fieldName, columnId) => {
    const options = columnOptions[fieldName] || {};
    const columnConfig = {
      ...options,
      name: fieldName,
      title: options.title || fieldNameToTitle(fieldName),
      fill: options.fill || false,
      columnId,
    };
    if (options.render) {
      columnConfig.getCellValue = getCellValueWrapper(options, options.render);
    } else if (options.bold) {
      // eslint-disable-next-line react/display-name, react/prop-types
      columnConfig.getCellValue = getCellValueWrapper(options, row => (
        <BoldText as="span">{get(row, fieldName) || row[fieldName]}</BoldText>
      ));
    }
    return columnConfig;
  });
}

export function fieldNameToTitle(fieldName) {
  // based on: https://stackoverflow.com/a/7225450/52509
  // Handle camelCase (e.g. theQuickBrownFox -> The Quick Brown Fox)
  let result = fieldName.replace(/([A-Z])/g, " $1");
  // Handle relations (e.g. office.name -> Office Name)
  result = result.replace(/\.([a-zA-Z])/g, v => ` ${v.slice(1).toUpperCase()}`);
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function generateTableColumnExtensions(columnOptions) {
  const columnExtensionFields = ["align", "wordWrapEnabled", "sortingEnabled"];
  return Object.entries(columnOptions).map(([fieldName, options]) => {
    const colExtOptions = Object.keys(options).filter(k =>
      columnExtensionFields.includes(k)
    );
    if (colExtOptions.length > 0) {
      return colExtOptions.reduce(
        (columnExtension, optionName) => {
          columnExtension[optionName] = options[optionName];
          return columnExtension;
        },
        { columnName: fieldName }
      );
    } else {
      return { columnName: fieldName, align: "left" };
    }
  });
}

export const _coalesce = (...args) => {
  for (const value of args) {
    if (value) {
      return value;
    }
  }
};
