import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  resetPassword,
  resetPaswordDataComponentId,
} from "../../../../actions/usersActions";

import { showSnackNotificationAction } from "../../../../actions/layoutActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import UpdatePassword from "./UpdatePassword";

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        resetPassword,
        showSnackNotificationAction,
      },
      dispatch
    ),
  };
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponent(resetPaswordDataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
