import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import {
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const dataComponentId = "ConsultingCompanyContacts";

export class DeleteConsultingCompanyContactContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleDeleteRequestSuccess,
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestSuccess = () => {
    const { closeModalDialog, setReload } = this.props;

    setReload(dataComponentId, true);
    closeModalDialog();
  };

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteConsultingCompanyContact = () => {
    const { consultingCompanyContactId, performDeleteRequest } = this.props;

    performDeleteRequest(dataComponentId, consultingCompanyContactId);
  };

  render() {
    const { title } = this.props;

    return (
      <DeleteConfirmation
        title={title}
        onDelete={this.deleteConsultingCompanyContact}
      />
    );
  }
}

DeleteConsultingCompanyContactContainer.propTypes = {
  title: PropTypes.string.isRequired,
  setReload: PropTypes.func.isRequired,
  consultingCompanyContactId: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  showSnackNotificationAction,
  performDeleteRequest,
  closeModalDialog,
  setReload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConsultingCompanyContactContainer);
