import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SerifTitle from "../../ui/Typography/SerifTitle";
import Loader from "../../ui/Loader";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BaseTitle = styled(SerifTitle)`
  display: inline-block;
  font-size: 24px;
  line-height: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
`;

const Id = styled(BaseTitle)`
  padding-right: 16px;
  font-weight: bold;
`;

export const Title = styled(BaseTitle)`
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

const TitleArea = ({ id, title, children }) => {
  return (
    <Loader height="32px">
      <Wrapper>
        {id ? <Id>{id}</Id> : null}
        <Title>{title}</Title>
        <Content>{children}</Content>
      </Wrapper>
    </Loader>
  );
};

TitleArea.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.func.isRequired,
      onClick: PropTypes.func,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TitleArea;
