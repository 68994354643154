import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import teamFields from "../teamFields";
import FormGrid from "../../../../../components/forms/FormGrid";
import ActionButtons from "../../../../../components/forms/ActionButtons";
import FormikForm from "../../../../../components/forms/FormikForm";
import propTypes from "../../../../../constants/propTypes";

const TeamForm = ({ onSubmit, initialValues, isUpdate, loading }) => {
  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BWModels.loadSchema("Team")}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={teamFields()}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            loading={loading}
            sendButtonText={isUpdate ? "Save" : "Add Team"}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

TeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: propTypes.term,
};

export default TeamForm;
