import { BaseModel } from "@blue-chip/core";
import Client from "./Client";
import PricingTier from "./PricingTier";

export default class ClientPricing extends BaseModel {
  static get belongsTo() {
    return [Client];
  }

  static get hasMany() {
    return [PricingTier];
  }
}
