import _noop from "lodash/noop";

import inputs from "../../../../../../components/inputs/inputConfigs/fee";

export const handleRevenueSharingChange = (setFieldValue, values) => ({
  target: { value },
}) => {
  if (value) {
    setFieldValue("oseAmount", values.amount);
    setFieldValue("ffeAmount", 0);
    return;
  }
  setFieldValue("amount", values.oseAmount);
  setFieldValue("oseAmount", 0);
  setFieldValue("ffeAmount", 0);
};

export default (project, values, setFieldValue) => {
  const { revenueSharing, oseAmount } = values;
  const fields = revenueSharing
    ? [
        {
          input: {
            ...inputs.ffeAmount,
            InputProps: {
              ...inputs.ffeAmount.InputProps,
              onChange: _noop,
            },
            value: -oseAmount,
            disabled: true,
          },
          grid: { xs: 6 },
        },
        {
          input: inputs.oseAmount,
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.project,
            name: "revenueSharingProjectId",
            APIOptions: {
              ...inputs.project.APIOptions,
              rootFilters: {
                $where: {
                  id: {
                    $notEqual: project.id,
                  },
                },
              },
            },
          },
          grid: { xs: 12 },
        },
      ]
    : [
        {
          input: { ...inputs.workScope, ignoreLabeledText: true },
          grid: { xs: 6 },
        },
        {
          input: { ...inputs.feeSubScope, ignoreLabeledText: true },
          grid: { xs: 6 },
        },
        {
          input: inputs.feeAmount,
          grid: { xs: 6 },
        },
      ];
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.revenueSharing,
              disabled: !project.workScopes.find(({ name }) => name === "OS&E"),
              handleChange: handleRevenueSharingChange(setFieldValue, values),
            },
            grid: { xs: 12 },
          },
          ...fields,
        ],
      },
    ],
  };
};
