import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import CodeMirror, { getStatistics } from "@uiw/react-codemirror";
import { FormHelperText } from "@material-ui/core";
import { sql, PostgreSQL, SQLDialect } from "@codemirror/lang-sql";
import { useFormikContext } from "formik";
import { Prec } from "@codemirror/state";
import { keymap } from "@codemirror/view";
import {
  startCompletion,
  closeCompletion,
  moveCompletionSelection,
  autocompletion,
  acceptCompletion,
} from "@codemirror/autocomplete";

import Subheader from "../../../ui/Typography/Subheader";
import {
  getErrorMessage,
  hasError,
} from "../../../../utils/formValidationUtils";

const ErrorMessage = styled(FormHelperText)`
  margin-top: 3px;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  line-height: 16px;
  ${({ error }) => (error ? "color: #f44336;" : "")}
`;

export const Label = styled(Subheader)`
  font-size: 12px;
  color: unset;
  font-weight: unset;
  ${({ error }) => (error ? "color: #f44336;" : "")}
`;

const completionKeymap = [
  { key: "Ctrl-Space", run: startCompletion },
  { key: "Escape", run: closeCompletion },
  { key: "ArrowDown", run: moveCompletionSelection(true) },
  { key: "ArrowUp", run: moveCompletionSelection(false) },
  { key: "PageDown", run: moveCompletionSelection(true, "page") },
  { key: "PageUp", run: moveCompletionSelection(false, "page") },
  { key: "Tab", run: acceptCompletion },
  { key: "Enter", run: acceptCompletion },
];

const [autocompletionConfig] = autocompletion();

export const Editor = ({
  editorRef,
  name,
  label,
  required,
  setSelection,
  tables,
}) => {
  const {
    setFieldValue,
    initialValues: { query },
    ...formikProps
  } = useFormikContext();

  const handleChange = value => setFieldValue(name, value);
  const handleBlur = () =>
    setSelection(getStatistics(editorRef.current.view).selection);

  const error = hasError(name, formikProps);
  const errorMessage = getErrorMessage(name, formikProps);

  const columnsAsTables = tables.reduce(
    (arr, { name: tableName, columns }, index) => [
      ...arr,
      ...columns.reduce(
        (columnsArray, { name }) => [
          ...columnsArray,
          {
            label: name,
            apply: `"${tableName}"."${name}"`,
            displayLabel: `"${tableName}"."${name}"`,
            boost: -index,
          },
        ],
        []
      ),
    ],
    []
  );

  const schema = tables.reduce(
    (map, { name: tableName, columns }) => ({
      ...map,
      [tableName]: columns.map(({ name, type }) => ({
        label: name,
        info: type,
        apply: `"${name}"`,
        displayLabel: `"${tableName}"."${name}"`,
      })),
    }),
    {}
  );

  return (
    <React.Fragment>
      <Label required={required} error={errorMessage}>
        {label}
      </Label>
      <CodeMirror
        ref={editorRef}
        value={query}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        basicSetup={{
          defaultKeymap: false,
        }}
        extensions={[
          Prec.highest(
            keymap.computeN([autocompletionConfig], () => [completionKeymap])
          ),
          sql({
            dialect: {
              language: PostgreSQL.language.configure({}),
              spec: SQLDialect.define({}),
              dialect: { words: "" },
            },
            upperCaseKeywords: true,
            defaultSchema: "PUBLIC",
            schema,
            tables: [
              ...tables.map(({ name }) => ({
                label: name,
                apply: `"${name}"`,
                displayLabel: `"${name}"`,
              })),
              ...columnsAsTables,
            ],
          }),
        ]}
        autoFocus
        height="100%"
      />
      <ErrorMessage error={error}>{errorMessage}</ErrorMessage>
    </React.Fragment>
  );
};

Editor.propTypes = {
  editorRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setSelection: PropTypes.func.isRequired,
  tables: PropTypes.array,
};

const placeholder = `SELECT * 
  FROM specs 
WHERE specs."customNumber" = :customNumber;
`;
