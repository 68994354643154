import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EntityForm from "./EntityForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import {
  populateCountryResources,
  populateStateResources,
} from "../../../../actions/countryStateActions";

import {
  initDataComponent,
  performCreateRequest,
} from "../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../utils/dataComponentUtils";

const dataComponentId = "EntitiesGrid";

export class CreateEntityContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    populateCountryResources: PropTypes.func,
    populateStateResources: PropTypes.func,
    client: PropTypes.shape({}),
    performCreateRequest: PropTypes.func,
  };

  componentDidMount() {
    this.props.populateCountryResources();
    this.props.populateStateResources();
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  createEntity = (entity, formikActions) => {
    const { performCreateRequest } = this.props;
    delete entity.country_id;
    delete entity.state_id;

    performCreateRequest(dataComponentId, entity);
    this.setState({ formikActions });
  };

  render() {
    return (
      <EntityForm
        {...this.props}
        onSubmit={this.createEntity}
        dataComponentId={dataComponentId}
      />
    );
  }
}

export const mapDispatchToProps = {
  closeModalDialog,
  populateCountryResources,
  populateStateResources,
  initDataComponent,
  performCreateRequest,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);

  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEntityContainer);
