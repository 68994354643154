import PropTypes from "prop-types";
import React from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Tooltip from "@material-ui/core/Tooltip";

import { ActionButton, CycleButton } from "./components";

//Review Note: span wrapping a disabled button will avoid warning from MUI
const Actions = ({ onOpenNotesModal, goLastItem, goNextItem, loading }) => (
  <div>
    {onOpenNotesModal && (
      <Tooltip title="Related Notes">
        <span>
          <ActionButton onClick={onOpenNotesModal} disabled={loading}>
            <AssignmentIcon />
          </ActionButton>
        </span>
      </Tooltip>
    )}
    <Tooltip title="Prev">
      <span>
        <CycleButton onClick={goLastItem} disabled={!goLastItem}>
          <ArrowBack />
        </CycleButton>
      </span>
    </Tooltip>
    <Tooltip title="Next">
      <span>
        <CycleButton onClick={goNextItem} disabled={!goNextItem}>
          <ArrowForward />
        </CycleButton>
      </span>
    </Tooltip>
  </div>
);

Actions.propTypes = {
  onOpenNotesModal: PropTypes.func,
  goLastItem: PropTypes.func,
  goNextItem: PropTypes.func,
  loading: PropTypes.bool,
};

export default Actions;
