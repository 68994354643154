import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import ForecastPage from "./ForecastPage";
import {
  getBluechipResources,
  getBluechipResourceById,
} from "../../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import Spec from "../../../../models/Spec";
import ProjectForecast from "../../../../models/ProjectForecast";
import ProjectCategoryPrice from "../../../../models/ProjectCategoryPrice";
import ProjectAreaPrice from "../../../../models/ProjectAreaPrice";
import { LoaderContext } from "../../../ui/Loader";
import _get from "lodash/get";
import Project from "../../../../models/Project";

const dataComponentId = "specs";
const dataComponentPriceId = "projectPrice";
const projectDataComponentId = "ProjectDetailWithFunding";
const dataComponentCategoryPriceId = "projectCategoryPrice";
const dataComponentAreaPriceId = "projectAreaPrice";
const filters = projectId => ({
  rootFilters: { $where: { projectId } },
  count: true,
  params: {
    omitDefaultModifier: true,
  },
});

export class ForecastContainer extends Component {
  componentDidMount() {
    const {
      initDataComponent,
      performFindRequest,
      performRetrieveListRequest,
      project,
    } = this.props;

    initDataComponent(dataComponentId, Spec, ["specCategory"], "specs");
    performRetrieveListRequest(dataComponentId, filters(project.id));

    initDataComponent(
      dataComponentCategoryPriceId,
      ProjectCategoryPrice,
      [],
      `projects/${project.id}/spec-category-prices`
    );

    initDataComponent(
      dataComponentAreaPriceId,
      ProjectAreaPrice,
      [],
      `projects/${project.id}/area-prices`
    );
    performRetrieveListRequest(dataComponentAreaPriceId);
    performRetrieveListRequest(dataComponentCategoryPriceId);

    initDataComponent(
      dataComponentPriceId,
      ProjectForecast,
      [],
      "projects/forecast",
      false,
      "v2"
    );
    initDataComponent(
      projectDataComponentId,
      Project,
      [],
      "project-with-fundings"
    );
    performFindRequest(projectDataComponentId, project.id);
    performFindRequest(dataComponentPriceId, project.id);
  }

  render() {
    const {
      route,
      totalSpecs,
      loading,
      projectForecast,
      projectCategoryPrices,
      projectAreaPrices,
      project,
    } = this.props;
    return (
      <LoaderContext.Provider value={{ loading }}>
        <ForecastPage
          route={route}
          totalSpecs={totalSpecs}
          projectForecast={projectForecast}
          projectCategoryPrices={projectCategoryPrices}
          projectAreaPrices={projectAreaPrices}
          project={project}
        />
      </LoaderContext.Provider>
    );
  }
}

ForecastContainer.propTypes = {
  loading: PropTypes.bool,
  project: propTypes.project,
  projectForecast: propTypes.projectForecast,
  totalSpecs: PropTypes.number,
  projectCategoryPrices: PropTypes.arrayOf(PropTypes.object),
  projectAreaPrices: PropTypes.arrayOf(PropTypes.object),
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  route: propTypes.route,
};

ForecastContainer.defaultProps = {
  projectCategoryPrices: [],
  projectAreaPrices: [],
};

export const mapDispatchToProps = {
  initDataComponent,
  performFindRequest,
  performRetrieveListRequest,
};

export const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.project.id;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const dataComponentPrice = getDataComponentFlattenedRequestState(
    dataComponentPriceId,
    state
  );
  const dataComponentCategoryPrice = getDataComponentFlattenedRequestState(
    dataComponentCategoryPriceId,
    state
  );
  const dataComponentAreaPrice = getDataComponentFlattenedRequestState(
    dataComponentAreaPriceId,
    state
  );
  const projectForecast = getBluechipResourceById(
    dataComponentPrice,
    state,
    projectId
  );

  return {
    loading:
      [
        dataComponent,
        dataComponentCategoryPrice,
        dataComponentAreaPrice,
      ].some(dataComponent => _get(dataComponent, "loading")) ||
      !projectForecast,
    totalSpecs: _get(dataComponent, "totalRows"),
    projectCategoryPrices:
      getBluechipResources(dataComponentCategoryPrice, state) || [],
    projectAreaPrices:
      getBluechipResources(dataComponentAreaPrice, state) || [],
    projectForecast: projectForecast || {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastContainer);
