import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import RegularText from "../../../../ui/Typography/RegularText";
import DeleteIcon from "@material-ui/icons/Delete";
import ContactActions from "./ContactActions";
import IconButton from "@material-ui/core/IconButton";
import BoldText from "../../../../ui/Typography/BoldText";
import { BWActionButton } from "../../../../ui/BWActionButton";
import InputLabel from "../../../../mui/core/InputLabel";
import { LabeledTextContext } from "../../../../../withPORevision";

import { connect } from "react-redux";
import { getContactStatus } from "../../../VendorContacts/utils";
import { useIsProjectClosed } from "../../../../hooks/useIsProjectClosed";

export const ContactItemWrapper = styled(ListItem)`
  padding: 8px 24px;
  &:hover {
    background-color: #f5f5f5;
  }
  ${RegularText} {
    display: block;
  }
  &:hover ${ContactActions} {
    opacity: 1;
  }
  ${BWActionButton} {
    background: none !important;
    padding-left: 0;
    padding-bottom: 0;
  }
  ${InputLabel} {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
  ${BoldText} {
    display: inline-block;
  }
`;

const ActionWrapper = ({ onRemove, disabled }) =>
  onRemove ? (
    <ContactActions>
      <IconButton onClick={onRemove} disabled={disabled} aria-label="Delete">
        <DeleteIcon />
      </IconButton>
    </ContactActions>
  ) : null;

ActionWrapper.propTypes = {
  onRemove: PropTypes.func,
};

export const ItemBody = ({ contact, secondaryClick, type }) => (
  <Fragment>
    <RegularText as="span"> {contact.phone} </RegularText>
    <RegularText as="span"> {contact.email} </RegularText>
    {type === "freight" && (
      <BWActionButton
        disableRipple
        text="VIEW VENDOR PROFILE"
        onClick={secondaryClick}
      />
    )}
  </Fragment>
);
ItemBody.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  type: PropTypes.string,
  secondaryClick: PropTypes.func,
};

const ItemPrimary = ({ contact, vendor, vendorContact, type }) => (
  <InputLabel>
    <BoldText>
      {contact.firstName} {contact.lastName}
    </BoldText>
    {type === "vendor" &&
      vendor &&
      ` - ${getContactStatus(vendor, vendorContact.contactStatus)}`}
    {type === "freight" &&
      vendor &&
      ` - ${getContactStatus(vendor, vendorContact.contactStatus)}`}
  </InputLabel>
);
ItemPrimary.propTypes = {
  contact: ItemBody.propTypes.contact,
  vendorContact: PropTypes.shape({
    contactStatus: PropTypes.string,
  }),
  vendor: PropTypes.shape({
    state: PropTypes.string,
  }),
  type: PropTypes.string,
};

export const ContactItem = props => {
  const { type, onRemove, button, onClick, disabled, ...primProps } = props;
  const labeledText = useContext(LabeledTextContext);
  const isButton = button === false ? false : !onRemove;
  const isProjectClosed = useIsProjectClosed();
  return (
    <ContactItemWrapper button={isButton} onClick={onClick} disabled={disabled}>
      <ListItemText
        primary={<ItemPrimary {...primProps} type={type} />}
        secondary={
          <ItemBody
            contact={props.contact}
            type={type}
            secondaryClick={props.secondaryClick}
          />
        }
      />
      {!labeledText && (
        <ActionWrapper onRemove={onRemove} disabled={isProjectClosed} />
      )}
    </ContactItemWrapper>
  );
};

ContactItem.propTypes = {
  ...ItemPrimary.propTypes,
  ...ItemBody.propTypes,
  ...ActionWrapper.propTypes,
  button: PropTypes.bool,
};

const mapStateToProps = (_, { vendorContact }) => {
  return {
    contact: vendorContact.contact,
  };
};

export default connect(mapStateToProps)(ContactItem);
