import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import { isSuperAdmin } from "../../../../utils/roleUtils";

export const getColumns = userRole =>
  isSuperAdmin(userRole) ? ["name", "scopeId"] : ["name"];

export const getScopeIdColumnOption = scopes => ({
  title: "Scopes",
  filter: "select",
  render: ({ scopes }) => scopes?.map(({ name }) => name).join(", "),
  filterOptions: {
    options: scopes,
    operator: ({ value }) => ({ "scopes.id": { $in: [value] } }),
  },
});
export const getColumnOptions = scopes => ({
  name: { title: "Name", filter: true },
  scopeId: getScopeIdColumnOption(scopes),
});

export const rowMenu = ({
  handleUpdateQueryReport,
  handleDeleteQueryReport,
}) => [
  {
    text: "Edit",
    onClick: handleUpdateQueryReport,
  },
  {
    text: "Delete",
    onClick: handleDeleteQueryReport,
  },
];

export const gridActions = handleCreateQueryReport => [
  {
    text: "Create Query Report",
    icon: <AddCircle />,
    handler: handleCreateQueryReport,
  },
];
