import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import ContactForm from "./ShipToSiteForm";
import { shipToSiteDataComponentId } from "./ShipToSitePage";
import { useModalStateContext } from "../../../layout/AppLayout/ModalDialog/withModalState";
import CreateContacts from "./CreateContacts";

const getNewVendorContact = (vendorContact, contactData, shipToSite) => {
  return vendorContact
    ? {
        ...vendorContact,
        ...contactData,
      }
    : {
        vendorId: shipToSite.vendorId,
        ...contactData,
      };
};

const getHandleUpdateSiteToShip = (
  modalState,
  shipToSite,
  onUpdateShipToSite,
  isValidRef
) => () => {
  if (!isValidRef.current) return;
  const contacts = _get(modalState, "contacts", []).reduce(
    (result, contactData) => {
      const vendorContact = _get(shipToSite, "contacts", []).find(
        ({ contact }) => contact.id === contactData.id
      );

      return [
        ...result,
        getNewVendorContact(vendorContact, contactData, shipToSite),
      ];
    },
    []
  );

  onUpdateShipToSite({
    ...modalState.shipToSite,
    contacts: contacts || [],
  });
};

const getHandleCreateSiteToShip = (
  onCreateShipToSite,
  modalState,
  isValidRef
) => () => {
  if (!isValidRef.current) return;
  onCreateShipToSite({
    ...modalState.shipToSite,
    contacts: modalState.contacts || [],
  });
};

const handleShipToSiteSubmit = ({
  setModalState,
  modalState,
  displayContactsForm,
  setDisplayContactsForm,
  isEditing,
}) => (_, { values: shipToSite, isValid }) => {
  setModalState({ ...modalState, shipToSite });
  if (displayContactsForm !== isValid && !isEditing) {
    setDisplayContactsForm(isValid);
  }
};

const CreateShipToSiteContainer = props => {
  const {
    shipToSite,
    vendorContacts,
    onUpdateShipToSite,
    isEditing,
    showSnackNotificationAction,
    onCreateShipToSite,
  } = props;

  const shipToSiteValidRef = useRef(false);
  const [displayContactsForm, setDisplayContactsForm] = useState(isEditing);
  const { setModalState, modalState = {} } = useModalStateContext();
  const { shipToSite: shipToSiteData } = modalState;

  useEffect(() => {
    if (_get(shipToSite, "id")) {
      const contacts = _get(shipToSite, "contacts", []);
      setModalState({
        shipToSite,
        contacts,
      });
    }
  }, [setModalState, shipToSite]);

  const handleSend = isEditing
    ? getHandleUpdateSiteToShip(
        modalState,
        shipToSite,
        onUpdateShipToSite,
        shipToSiteValidRef
      )
    : getHandleCreateSiteToShip(
        onCreateShipToSite,
        modalState,
        shipToSiteValidRef
      );

  return (
    <Fragment>
      <ContactForm
        shipToSite={shipToSiteData}
        vendorContacts={vendorContacts}
        dataComponentId={shipToSiteDataComponentId}
        shipToSiteValidRef={shipToSiteValidRef}
        onSubmit={handleShipToSiteSubmit({
          setModalState,
          modalState,
          displayContactsForm,
          setDisplayContactsForm,
          isEditing,
        })}
      />
      {displayContactsForm && (
        <CreateContacts
          vendorContacts={vendorContacts}
          isEditing={isEditing}
          showSnackNotificationAction={showSnackNotificationAction}
          handleSend={handleSend}
          submitText={"Site"}
          nonEmptyContacts
        />
      )}
    </Fragment>
  );
};

CreateShipToSiteContainer.defaultProps = {
  isEditing: false,
};

CreateShipToSiteContainer.propTypes = {
  formikArrayHelpers: PropTypes.shape({}),
  closeModalDialog: PropTypes.func,
  initDataComponent: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  onCreateShipToSite: PropTypes.func.isRequired,
  onUpdateShipToSite: PropTypes.func.isRequired,
  shipToSite: PropTypes.object,
  vendorContacts: PropTypes.array.isRequired,
  index: PropTypes.string,
  isEditing: PropTypes.bool,
  showSnackNotificationAction: PropTypes.func.isRequired,
};

export default CreateShipToSiteContainer;
