import React, { Component } from "react";
import PropTypes from "prop-types";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Apps from "@material-ui/icons/Apps";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Explore from "@material-ui/icons/Explore";
import AttachMoney from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import Share from "@material-ui/icons/Share";
import Settings from "@material-ui/icons/Settings";
import Ballot from "@material-ui/icons/Ballot";
import Assignment from "@material-ui/icons/Assignment";
import Divider from "@material-ui/core/Divider";
import {
  ListItemIcon as MuiListItemIcon,
  MenuList as MuiMenuList,
  ListItemText as MuiListItemText,
} from "@material-ui/core";
import styled from "styled-components";
import isMobile from "ismobilejs";

import { roleCan } from "../../../utils/roleUtils";
import propTypes from "../../../constants/propTypes";
import { handleOpenGenerateCustomReports } from "./HeaderContainer";

const HidableWrapper = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
`;

const MenuItemContainer = styled.div`
  padding: 6px;
`;

export const MenuItem = styled(MuiMenuItem)`
  height: 12px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 2px;

  & span {
    height: 36px;
  }
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  height: 24px;
  width: 24px;
  margin-right: 16px;
  ${props => (props.selected ? "color: rgb(30,61,82);" : "")}

  svg {
    min-height: 24px;
    min-width: 24px;
  }
`;

export const ListItemText = styled(({ selected, ...props }) => (
  <MuiListItemText classes={{ primary: "primary" }} {...props} />
))`
  padding-left: 8px;

  span {
    height: unset;
  }

  & .primary {
    padding-left: 0;
    color: rgba(0, 0, 0, ${({ selected }) => (selected ? "0.87" : "0.54")});
    line-height: 20px;
  }
`;

const MenuList = styled(MuiMenuList)`
  padding: 0;
`;

export const HidableItems = ({
  role,
  openNotesModal,
  openModalDialog,
  closeNavbarDrawer,
}) => {
  const displayCustomReports = roleCan(role, "access-custom-reports");
  return (
    <div>
      <HidableWrapper>
        <MenuItemContainer>
          <MenuItem
            onClick={() => {
              closeNavbarDrawer();
              openNotesModal();
            }}
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText inset primary={"Notes"} />
          </MenuItem>
        </MenuItemContainer>
      </HidableWrapper>
      {displayCustomReports ? (
        <HidableWrapper>
          <MenuItemContainer>
            <MenuItem
              onClick={() => {
                closeNavbarDrawer();
                handleOpenGenerateCustomReports(openModalDialog)();
              }}
            >
              <ListItemIcon>
                <Ballot />
              </ListItemIcon>
              <ListItemText inset primary={"Custom/Query Reports"} />
            </MenuItem>
          </MenuItemContainer>
        </HidableWrapper>
      ) : null}
    </div>
  );
};
HidableItems.propTypes = {
  role: propTypes.userRole,
  openNotesModal: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeNavbarDrawer: PropTypes.func.isRequired,
};

export class Navbar extends Component {
  handleClick = route => {
    this.props.push("/" + route);

    const isMobileBrowser = isMobile(window.navigator).any;
    if (isMobileBrowser) {
      this.props.closeNavbarDrawer();
    }
  };

  renderMenuItem = (route, label, Icon, permission, exclusions = []) => {
    if (!roleCan(this.props.role, permission)) return;
    const { pathname } = this.props;
    const isExclusion = exclusions.find(exclusion =>
      pathname.match(new RegExp(exclusion))
    );
    const selected = isExclusion ? false : pathname.includes(`/${route}`);
    return (
      <MenuItemContainer>
        <MenuItem onClick={() => this.handleClick(route)} selected={selected}>
          <ListItemIcon selected={selected}>
            <Icon />
          </ListItemIcon>
          <ListItemText inset primary={label} selected={selected} />
        </MenuItem>
      </MenuItemContainer>
    );
  };

  renderSettings = () => {
    return (
      <div>
        <Divider />
        {this.renderMenuItem(
          "settings",
          "Settings",
          Settings,
          ["navigation-settings", "access-to-settings-section"],
          ["/projects/\\d+/settings", "/properties/\\d+/settings"]
        )}
        <Divider />
      </div>
    );
  };

  renderHidableItems = () => {
    const {
      role,
      openNotesModal,
      openModalDialog,
      closeNavbarDrawer,
    } = this.props;

    return (
      <HidableItems
        role={role}
        openNotesModal={openNotesModal}
        openModalDialog={openModalDialog}
        closeNavbarDrawer={closeNavbarDrawer}
      />
    );
  };

  render() {
    return (
      <MenuList>
        {this.renderMenuItem(
          "dashboard",
          "Dashboard",
          Apps,
          "navigation-dashboard"
        )}
        {this.renderMenuItem(
          "rfqs",
          "Request For Quotes",
          LocalOffer,
          "navigation-rfq"
        )}
        {this.renderMenuItem(
          "projects",
          "Projects",
          FileCopyIcon,
          "navigation-projects",
          [
            "/vendors/\\d+/projects",
            "/accounting/projects",
            "/clients/\\d+/projects$",
            "/consultingCompanies/\\d+/projects",
          ]
        )}
        {this.renderMenuItem(
          "clients",
          "Clients",
          SupervisedUserCircleIcon,
          "navigation-clients",
          ["/clients/\\d+/projects/\\d+", "/clients/\\d+/projects/create"]
        )}
        {this.renderMenuItem(
          "vendors",
          "Vendors",
          LocalOffer,
          "navigation-vendors",
          ["/quoting/bid-groups/\\d+/vendors"]
        )}
        {this.renderMenuItem(
          "manage-vendor-users",
          "Manage Contacts",
          PersonIcon,
          "navigation-vendor-contacts"
        )}
        {this.renderMenuItem(
          "capacity",
          "Capacity",
          Explore,
          "navigation-capacity"
        )}
        {this.renderMenuItem("users", "Users", PersonIcon, "navigation-users")}
        {this.renderMenuItem(
          "vendor-discounts",
          "Discounts",
          AttachMoney,
          "navigation-vendor-discounts"
        )}
        {this.renderMenuItem(
          "billing",
          "Billing",
          AttachMoney,
          "navigation-billing"
        )}
        {this.renderMenuItem(
          "accounting",
          "Accounting",
          InsertChartIcon,
          "navigation-accounting",
          ["projects/\\d+/accounting"]
        )}
        {this.renderMenuItem(
          "consultingCompanies",
          "Consultants",
          Share,
          "navigation-consultants"
        )}
        {this.renderSettings("navigation-settings")}
        {this.renderHidableItems()}
      </MenuList>
    );
  }
}

Navbar.propTypes = {
  pathname: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  closeNavbarDrawer: PropTypes.func.isRequired,
  openNotesModal: PropTypes.func.isRequired,
  role: propTypes.userRole,
};
