import { inputs } from "../../../../components/inputs/inputConfigs";
import { isSuperAdmin } from "../../../../utils/roleUtils";

export default role => {
  const items = [
    {
      input: {
        ...inputs.baseName,
        label: "Name",
        required: true,
      },
      grid: { xs: 12 },
    },
    {
      input: {
        ...inputs.abbreviation,
      },
      grid: { xs: 12 },
    },
    {
      input: {
        ...inputs.location,
        required: true,
      },
      grid: { xs: 12 },
    },
    {
      input: inputs.locationAddress2,
      grid: { xs: 12 },
    },
    {
      input: {
        ...inputs.locationCity,
        required: true,
      },
      grid: { xs: 6 },
    },
    {
      input: {
        ...inputs.locationState,
        required: true,
      },
      grid: { xs: 6 },
    },
    {
      input: {
        ...inputs.locationCountry,
        required: true,
      },
      grid: { xs: 6 },
    },
    {
      input: {
        ...inputs.locationPostalCode,
        required: true,
      },
      grid: { xs: 6 },
    },
    {
      input: {
        ...inputs.phoneNumber,
        label: "Phone Number",
      },
      grid: { xs: 6 },
    },
    {
      input: {
        ...inputs.phoneNumber,
        label: "Fax Number",
        name: "faxNumber",
      },
      grid: { xs: 6 },
    },
  ];

  if (isSuperAdmin(role)) {
    items.push({
      input: {
        ...inputs.scopeId,
        label: "Scope",
        name: "scopeId",
      },
      grid: { xs: 6 },
    });
  }

  items.push({ input: inputs.isArchived });

  return {
    groups: [
      {
        items,
      },
    ],
  };
};
