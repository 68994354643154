import React, { Fragment } from "react";
import SectionHeader from "../../../ui/Typography/SectionHeader";
import { FormGrid } from "../../../forms";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";
import { inputs } from "../../../inputs/inputConfigs";

const createFields = (spec, projectId) => {
  const disabledCurrency = !!(
    spec?.purchaseOrderId || spec?.isAssignedToBidGroup
  );
  return {
    groups: [
      {
        items: [
          {
            input: {
              ...inputs.projectCurrency,
              isDisabled: disabledCurrency,
              APIOptions: {
                ...inputs.projectCurrency.APIOptions,
                rootFilters: {
                  $where: { projectId },
                },
              },
            },
            grid: { xs: 6 },
          },
        ],
      },
    ],
  };
};

export const CurrencySection = ({ spec, projectId, ...formikProps }) => {
  return (
    <Fragment>
      <SectionHeader>Currency</SectionHeader>
      <FormGrid
        fields={createFields(spec, projectId)}
        purchaseOrder={spec && spec.purchaseOrder}
        {...formikProps}
      />
    </Fragment>
  );
};

CurrencySection.propTypes = {
  spec: propTypes.spec,
  formikProps: PropTypes.object,
};

export default CurrencySection;
