import React from "react";
import styled from "styled-components";
import { PlaylistAddCheck } from "@material-ui/icons";

export const SubHeadingWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 16px;
`;

export const PlaylistAddCheckIconGreen = (
  <PlaylistAddCheck
    style={{ color: "#83bd44", marginRight: "5px", fontSize: "medium" }}
  ></PlaylistAddCheck>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledGraySpan = styled.span`
  color: #9e9e9e;
`;

// eslint-disable-next-line react/display-name
export const vendorNameWithAwardedIcon = awardedVendorId => row => {
  if (!awardedVendorId) return row.name;

  if (awardedVendorId != row.id) {
    return <StyledGraySpan>{row.name}</StyledGraySpan>;
  }

  return (
    <Wrapper>
      {PlaylistAddCheckIconGreen} {row.name}
    </Wrapper>
  );
};

// eslint-disable-next-line react/display-name
export const categoryNameAwarded = awardedVendorId => row => {
  if (awardedVendorId && awardedVendorId != row.id) {
    return <StyledGraySpan>{row.category.name}</StyledGraySpan>;
  }
  return row.category.name;
};
