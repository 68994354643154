import React, { useMemo } from "react";
import _get from "lodash/get";
import styled from "styled-components";

import { Cell, FilterCell, HeadCell } from "../../../../ui/Grid/utilComponents";
import { CellContentWrapper } from "../../../../ui/Grid/utilComponents/tableComponents/Cell";
import { StyledBWTable } from "../../../../ui/BWGrid/gridInternalComponents/BWTable";

const StyledCell = styled(
  ({ isEmpty, backGroundColor, borderLeft, expanded, ...props }) => (
    <Cell {...props} />
  )
)`
  padding: ${({ column }) => column.name === "indicator" && "0!important"};
  padding-right: ${({ isEmpty }) => isEmpty && "8px !important"};
  padding-left: ${({ column }) => column.name === "customNumber" && "0"};
  ${CellContentWrapper} {
    margin-top: ${({ isEmpty }) => isEmpty && "-168px"};
  }
  background-color: ${({ backGroundColor }) =>
    backGroundColor ? backGroundColor : "#FFFFFF"} !important;
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : "none")};
  font-style: ${({ fontStyle }) => fontStyle};
  span {
    &::after {
      content: " ${({ note }) => note}";
    }
  };
  ${({ expanded }) =>
    expanded &&
    `
    span {
      white-space: break-spaces;
    }
    `};
`;

export const getExpandedColSpan = (isCOMVisible, isSampleColumnsVisible) => {
  if (isCOMVisible) {
    if (isSampleColumnsVisible) return "10";
    return "6";
  }
  if (!isCOMVisible) {
    if (isSampleColumnsVisible) return "6";
    return "4";
  }
};

export const CustomCell = ({
  column: srcColumn,
  isCOMVisible,
  isSampleColumnsVisible,
  ...props
}) => {
  const { value } = props;

  const expanded = _get(value, "expanded", false);
  const noRender = _get(value, "noRender", false);
  let backGroundColor = _get(value, "backGroundColor", "#FFFFFF");
  if (backGroundColor == "#FFFFFF") {
    backGroundColor = _get(srcColumn, "backGroundColor", "#FFFFFF");
  }
  const borderLeft = _get(value, "borderLeft", "none");
  const fontStyle = _get(value, "fontStyle", "initial");
  const note = _get(value, "note");
  const customProps = useMemo(() => {
    if (expanded) {
      return {
        column: {
          ...srcColumn,
          displayBorderRight: true,
        },
        colSpan: getExpandedColSpan(isCOMVisible, isSampleColumnsVisible),
      };
    }
    return { column: srcColumn };
  }, [expanded, srcColumn, isCOMVisible, isSampleColumnsVisible]);
  customProps.backGroundColor = backGroundColor;
  customProps.borderLeft = borderLeft;
  customProps.expanded = expanded;
  customProps.fontStyle = fontStyle;
  customProps.note = note;

  if (noRender) return null;

  return <StyledCell {...props} {...customProps} />;
};

const StyledTable = styled(StyledBWTable)`
  border-collapse: separate;
`;

const StyledFilterCell = styled(FilterCell)`
  padding: ${({ column }) => column.name === "indicator" && "0!important"};
`;

export const CustomFilterCell = props => {
  const { column } = props;
  if (column.name === "emptyColumn") return null;
  return <StyledFilterCell {...props} />;
};

const StyledHeadCell = styled(HeadCell)`
  padding: ${({ column }) => column.name === "indicator" && "0!important"};
`;

export const CustomHeadCell = props => {
  const { column } = props;
  if (column.name === "emptyColumn") return null;

  return <StyledHeadCell {...props} />;
};

export const Table = props => <StyledTable {...props} />;
