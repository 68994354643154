import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  useNextVariants: true,
  overrides: {
    MuiToolbar: {
      root: {
        "&.noBorderBottom": {
          border: "0",
        },
      },
    },
    MuiInput: {
      input: {
        "&$disabled": {
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
      underline: {
        "&$disabled:before": {
          "border-bottom-style": "solid",
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: "inline",
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: "0",
      },
    },
    MuiInputBase: {
      root: {
        font: "inherit",
        color: "currentColor",
        width: "100%",
        border: "0",
        margin: "0",
        display: "flex",
        "min-width": "0",
        "box-sizing": "content-box",
        background: "none",
        "-webkit-tap-highlight-color": "transparent",
      },
      input: {
        minHeight: "32px",
        height: "unset",
        padding: "0px",
      },
      multiline: {
        padding: "0px",
        minHeight: "unset",
      },
      inputMultiline: {
        padding: "6px 0 7px",
        minHeight: "unset",
      },
    },
    MuiMenuItem: {
      root: {
        boxSizing: "content-box",
        "&$selected": {
          backgroundColor: "rgba(87,171,255, 0.24)",
          fontWeight: "normal",
        },
      },
      gutters: {
        paddingTop: "11px",
        paddingBottom: "11px",
        height: "24px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "24px",
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: "8px",
        paddingRight: "8px",
        "&:last-child": {
          paddingRight: "24px",
        },
      },
      body: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        cursor: "pointer",
      },
    },
    TableFilterCell: {
      cell: {
        padding: "8px",
      },
    },
    MuiTableSortLabel: {
      icon: {
        width: "16px",
        height: "16px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "#3F51B5",
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: "#3F51B5",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "24px",
        height: "24px",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "1.2rem",
        color: "rgba(0, 0, 0, 0.8)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: 1.2,
        "&$disabled": {
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
    },
    MuiTableRow: {
      root: {
        height: "48px",
        "&:hover td": {
          backgroundColor: "rgb(245,245,245)",
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        height: "32px",
        display: "flex",
        flex: "1",
        alignItems: "inherit",
      },
    },
    Pagination: {
      button: {
        display: "none !important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#FFFFFF",
        fontWeight: "bold",
      },
    },
    MuiChip: {
      root: {
        height: "24px",
      },
      label: {
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
  shape: {
    borderRadius: "2px",
  },
  palette: {
    primary: {
      main: "#27587A",
      dark: "#234d6b",
    },
    secondary: {
      main: "#57abff",
      contrastText: "#ffffff",
    },
  },
});

export default theme;
