import { useEffect } from "react";
import { useSavingStatus } from "./useSavingStatus";
import { usePrevious } from "./usePrevious";

const processStatus = (status, statusHandler) => {
  if (!status || !statusHandler) {
    return;
  }
  statusHandler(status);
};

export const useProcessSavingStatus = ({ onSuccess, onError }) => {
  const { loading, success, error } = useSavingStatus();
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (prevLoading && !loading) {
      processStatus(success, onSuccess);
      processStatus(error, onError);
    }
  }, [error, loading, onError, onSuccess, prevLoading, success]);

  return null;
};
