import { isFirefox } from "react-device-detect";
import _get from "lodash/get";

const normalizeCursorValue = value => (isFirefox ? value : value - 1);

const MentioHandler = {
  isValidTriggerEntry: (cursor, element) => {
    const selectionStart = normalizeCursorValue(cursor.selectionStart);
    const lastChar = _get(element, `value.${selectionStart - 1}`, "");
    return selectionStart === 0 || !!lastChar.match(/\s/);
  },

  parseTextWithMention: (cursor, element, user) => {
    const textareaValue = element.value;
    const startPoint = normalizeCursorValue(cursor.startPoint);
    const endPoint = normalizeCursorValue(cursor.endPoint);
    return `${textareaValue.slice(0, startPoint)}${
      user.name
    } ${textareaValue.slice(endPoint + 1, textareaValue.length)}`;
  },
};

export default MentioHandler;
