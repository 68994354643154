import React from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import styled from "styled-components";

import {
  HeaderLeft,
  HeaderWrapper,
  StyledPaper,
  Wrapper,
} from "../../../BidGroupDetail/Quotes/BidGroupTable/components";
import { BWGridLocal, GridRow, GridTable } from "../../../../ui/BWGrid";
import {
  canCreateAlternateQuote,
  columnOptions,
  HeaderInnerWrapper,
} from "../../../BidGroupDetail/Quotes/BidGroupTable/BidGroupTable";
import Loader from "../../../../ui/Loader";
import Summary from "../../../BidGroupDetail/Quotes/BidGroupTable/Summary";
import propTypes from "../../../../../constants/propTypes";
import { COM_TYPE } from "../../../BidGroupDetail/Quotes/bidGroupVendorSpecUtils";
import SerifTitle from "../../../../ui/Typography/SerifTitle";

export const Title = styled(SerifTitle)`
  display: inline-block;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
`;

export const getRowMenu = (
  bidGroupVendor,
  row,
  onCreateAlternateQuote,
  onGoToSpecDetail,
  onOpenQuoteNotesModal,
  readOnly
) => {
  if (row.type === COM_TYPE) {
    return;
  }
  return [
    {
      text: "Create Alternate Quote",
      onClick: onCreateAlternateQuote,
      disabled: !canCreateAlternateQuote(row) || readOnly,
    },
    {
      text: "Review Spec Detail",
      onClick: onGoToSpecDetail,
    },
  ];
};

function QuoteGrid({
  bidGroupVendor,
  gridRows,
  tableComponents,
  totalRows,
  columns,
  setGridRows,
  onOpenNotesModal,
  onNotesIconClick,
  openCreateAlternateQuoteModal,
  productionTotal,
  extProductionTotal,
  selectedProjectCurrency,
  isLabeled,
  onGoToSpecDetail,
}) {
  const columnList = [...columns];

  const columnOptionList = columnOptions(
    bidGroupVendor,
    onNotesIconClick,
    gridRows,
    setGridRows,
    () => {},
    isLabeled
  );

  columnList.pop();
  delete columnOptionList["spec.purchaseOrder.id"];

  return (
    <StyledPaper>
      <BWGridLocal
        id={`sbg-${bidGroupVendor.id}`}
        rows={gridRows}
        gridConfig={{ pageSize: 10, totalRows: gridRows.length }}
        isLoading={!bidGroupVendor.id}
        tableComponents={tableComponents}
        editableNavigationDirection="horizontal"
        emptyStateProps={{
          intent: "empty",
          size: "small",
          intensity: "strong",
          padding: "24px",
        }}
        emptyStateText="No Specs Selected"
      >
        <GridRow>
          <HeaderWrapper>
            <HeaderInnerWrapper>
              <Wrapper>
                <HeaderLeft>
                  <Loader height="30px" width="350px">
                    <Title>{pluralize("Spec", totalRows, true)}</Title>
                  </Loader>
                </HeaderLeft>
              </Wrapper>
            </HeaderInnerWrapper>
          </HeaderWrapper>
        </GridRow>
        <GridTable
          columns={columnList}
          columnOptions={columnOptionList}
          rowMenu={row =>
            getRowMenu(
              bidGroupVendor,
              row,
              openCreateAlternateQuoteModal,
              onGoToSpecDetail,
              onOpenNotesModal,
              isLabeled
            )
          }
        />
        <GridRow>
          <Summary
            visible={gridRows.length > 0}
            bidGroupVendor={bidGroupVendor}
            extProductionTotal={extProductionTotal}
            productionTotal={productionTotal}
            currency={selectedProjectCurrency.currency}
          />
        </GridRow>
      </BWGridLocal>
    </StyledPaper>
  );
}

QuoteGrid.propTypes = {
  bidGroupVendor: propTypes.bidGroupVendor,
  gridRows: PropTypes.arrayOf(PropTypes.shape({})),
  tableComponents: PropTypes.shape({}),
  selectedProjectCurrency: PropTypes.shape({}),
  totalRows: PropTypes.number,
  productionTotal: PropTypes.number,
  extProductionTotal: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.string),
  setGridRows: PropTypes.func,
  onOpenNotesModal: PropTypes.func,
  openCreateAlternateQuoteModal: PropTypes.func,
  onNotesIconClick: PropTypes.func,
  isLabeled: PropTypes.bool,
  onGoToSpecDetail: PropTypes.func,
};

export default QuoteGrid;
