import inputs from "../../../inputs/inputConfigs/invoice";

export const createPOFields = (discount, currency) => {
  const groups = [
    {
      items: [
        {
          input: inputs.poNumber,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: inputs.poIssueDate,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: {
            ...inputs.poTotal,
            InputProps: {
              currency,
            },
          },
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: inputs.projectSalesTaxPercent,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: inputs.projectSalesTaxTolerancePercent,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: inputs.projectUseTaxRate,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 4,
          },
        },
        {
          input: inputs.isFreightTaxable,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 3,
          },
        },
        {
          input: inputs.isInstallTaxable,
          grid: {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 3,
          },
        },
      ],
    },
    {
      items: [
        {
          input: inputs.projectVendorPaymentTerms,
        },
      ],
    },
  ];
  if (discount > 0) {
    groups.push({
      items: [
        {
          input: inputs.projectDiscount,
          grid: {
            xs: 3,
          },
        },
      ],
    });
  }
  return {
    groups,
  };
};
