import React from "react";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";

import DangerConfirmation from "../../../../../ui/DangerConfirmation";

const PopoverVoidConfirmation = ({
  anchorEl,
  onVoid,
  onClose,
  title,
  ...popoverProps
}) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "center",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "left",
    }}
    {...popoverProps}
  >
    <DangerConfirmation
      onDanger={onVoid}
      actionMessage={`void Funding Request #${title}`}
      dangerButtonText="VOID FUNDING REQUEST"
      sendButtonText="DISMISS"
      onCancel={onClose}
    />
  </Popover>
);

PopoverVoidConfirmation.propTypes = {
  anchorEl: PropTypes.shape({}),
  title: PropTypes.string,
  onVoid: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopoverVoidConfirmation;
