import publicIp from "public-ip";
import ipLocation from "iplocation";

/**
 * Takes an object of get parameters and returns a string ready to append
 * to the end of a url.
 *
 * examples:
 * "/path/to/api/" + serialize({page: 3, size: 10, title: "Jane & I", notused: undefined})
 * /path/to/api?page=3&size=10&title=Jane%20%26%20I
 *
 * "/path/to/api/" + serialize({})
 * /path/to/api
 */
export function serializeGetParams(obj) {
  const params = Object.entries(obj).reduce((p, [key, value]) => {
    if (value !== undefined) p[key] = value;
    return p;
  }, {});
  let keys = Object.keys(params);
  keys.sort();
  if (keys.length > 0) {
    return (
      "?" +
      keys
        .reduce(function(a, k) {
          a.push(k + "=" + encodeURIComponent(obj[k]));
          return a;
        }, [])
        .join("&")
    );
  }
  return "";
}

export async function getMyIp() {
  try {
    return publicIp.v4();
  } catch (error) {
    return null;
  }
}

export async function getMyLocation(ip) {
  try {
    const location = await ipLocation(ip);
    return `${location.city}, ${location.region.code} ${location.country.name}`;
  } catch (error) {
    return null;
  }
}
