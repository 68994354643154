import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _omit from "lodash/omit";
import _get from "lodash/get";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
} from "../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../utils/dataComponentUtils";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import propTypes from "../../../../constants/propTypes";
import { LoaderContext } from "../../../ui/Loader/Loader";
import ConsultingCompanyContactForm from "../CreateConsultingCompanyContact/ConsultingCompanyContactForm";
import { handleCreateContactError } from "../../VendorContacts/CreateVendorContact/CreateVendorContactFunctions";

const dataComponentId = "ConsultingCompanyContacts";

export class EditConsultingCompanyContactContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const {
      dataComponent,
      closeModalDialog,
      showSnackNotificationAction,
    } = this.props;

    processUpdateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: response =>
        handleCreateContactError(
          this.state.formikActions,
          showSnackNotificationAction
        )(response),
    });
  }

  handleEditContact = (consultingCompanyContact, formikActions) => {
    const { performUpdateRequest } = this.props;
    performUpdateRequest(
      dataComponentId,
      consultingCompanyContact.id,
      _omit(consultingCompanyContact, "contact.memberType")
    );
    this.setState({ formikActions });
  };

  render() {
    const { consultingCompanyContact, loading } = this.props;

    return (
      <LoaderContext.Provider value={{ loading }}>
        <ConsultingCompanyContactForm
          consultingCompanyContact={consultingCompanyContact}
          onSubmit={this.handleEditContact}
          dataComponentId={dataComponentId}
        />
      </LoaderContext.Provider>
    );
  }
}

EditConsultingCompanyContactContainer.propTypes = {
  closeModalDialog: PropTypes.func.isRequired,
  consultingCompanyContactId: PropTypes.string.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  performFindRequest: PropTypes.func.isRequired,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dataComponent: PropTypes.object,
  consultingCompanyContact: propTypes.consultingCompanyContact,
};

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    loading: _get(dataComponent, "requestState.find.loading"),
    consultingCompanyContact: getBluechipResourceById(
      dataComponent,
      state,
      ownProps.consultingCompanyContactId
    ),
  };
};

const mapDispatchToProps = {
  closeModalDialog,
  initDataComponent,
  performFindRequest,
  performUpdateRequest,
  showSnackNotificationAction,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditConsultingCompanyContactContainer)
);
