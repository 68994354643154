import * as CELL_TYPES from "./cellTypes";
import * as SPEC_TYPES from "../specTypes";
import { comVendorBackground } from "./analysisSpecCellFunctions";
import {
  getQuoteItemAtIndex,
  setChevronColumn,
  setCOMColumns,
} from "./analysisSpecRowColumn";

export const getZeroWhenFalsy = value => value || 0;
export const getPlaceholderWhenFalsy = value => value || "--";
export const getEmptyStringWhenFalsy = (value, compareFunc) =>
  compareFunc() ? value : "";
export const getEmptyStringWhenNull = value => value || "";

const highlightGreen = "#18CC55";

export const buildSpecRowForSpecOnly = (
  specRow,
  vendorIndex,
  defaultProjectCurrency,
  vendorSpec,
  selectedCOMs,
  quoteIndex,
  bgVendor,
  openAlternateQuotesModal
) => {
  const { quoteProjectCurrency } = bgVendor;
  if (vendorSpec.type === SPEC_TYPES.COM) {
    setCOMColumns(
      vendorSpec,
      specRow,
      openAlternateQuotesModal,
      bgVendor,
      vendorIndex,
      defaultProjectCurrency
    );
    specRow[`quote_${vendorIndex}_selectCOM`] = "";
    specRow[`quote_${vendorIndex}_chevron`] = "";
    return;
  }

  /** Sample columns */
  const extSampleQuantity = vendorSpec.spec.sampleQuantity;
  specRow[`quote_${vendorIndex}_samplePricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.samplePricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_extendedSamplePricing`] = {
    value: getZeroWhenFalsy(
      extSampleQuantity *
        vendorSpec.samplePricing *
        quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };

  /**
   * Production columns
   */
  const extProductionQuantity = vendorSpec.spec.totalQuantityWithoutOverage;
  specRow[`quote_${vendorIndex}_productionPricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.productionPricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };
  specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
    value: getZeroWhenFalsy(
      extProductionQuantity *
        vendorSpec.productionPricing *
        quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
  };
  setChevronColumn(
    vendorSpec,
    specRow,
    openAlternateQuotesModal,
    bgVendor,
    vendorIndex
  );
};

const getBackGround = (comIsSelected, bgVendor) => {
  if (bgVendor.isAlternateQuote) {
    return "#EDF0F7";
  }
  return comIsSelected ? "#FFFFFF" : comVendorBackground;
};

export const buildSpecRowForCOM = (
  bgVendor,
  specRow,
  vendorIndex,
  defaultProjectCurrency,
  vendorSpec,
  allCOMItems,
  selectedCOMs,
  onCOMClicked,
  quoteProjectCurrency
) => {
  const selectedCOMPricing = selectedCOMs[vendorSpec.spec.id];
  const comIsSelected =
    selectedCOMPricing && selectedCOMPricing.quote.id === vendorSpec.quoteId;

  specRow[`quote_${vendorIndex}_sampleCOMQuantity`] = {
    value: `1${getEmptyStringWhenNull(specRow.unitOfMeasure?.name)}`,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${vendorSpec.sampleQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => vendorSpec.sampleQuantity
    ),
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };
  specRow[`quote_${vendorIndex}_samplePricing`] = {
    value: getPlaceholderWhenFalsy(
      vendorSpec.samplePricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
    projectCurrency: defaultProjectCurrency,
    borderLeft: comIsSelected
      ? `solid 4px ${highlightGreen} !important`
      : "none",
  };
  specRow[`quote_${vendorIndex}_extendedSamplePricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.extendedSamplePricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };

  specRow[`quote_${vendorIndex}_productionCOMQuantity`] = {
    value: `1${getEmptyStringWhenNull(specRow.unitOfMeasure?.name)}`,
    type: CELL_TYPES.QUANTITY,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
    value: getEmptyStringWhenFalsy(
      `${vendorSpec.productionQuantity}${getEmptyStringWhenNull(
        specRow.unitOfMeasure?.name
      )}`,
      () => vendorSpec.productionQuantity
    ),
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };
  specRow[`quote_${vendorIndex}_productionPricing`] = {
    value: getPlaceholderWhenFalsy(
      vendorSpec.productionPricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
    projectCurrency: defaultProjectCurrency,
    borderLeft: comIsSelected
      ? `solid 4px ${highlightGreen} !important`
      : "none",
  };
  specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
    value: getZeroWhenFalsy(
      vendorSpec.extendedProductionPricing * quoteProjectCurrency.conversionRate
    ),
    type: CELL_TYPES.NUMBER,
    projectCurrency: defaultProjectCurrency,
    backGroundColor: getBackGround(comIsSelected, bgVendor),
  };
  if (allCOMItems[parseInt(vendorSpec.spec.id)]) {
    specRow[`quote_${vendorIndex}_selectCOM`] = {
      type: CELL_TYPES.SELECT_COM_CHECKBOX,
      onClick: onCOMClicked,
      isSelected: comIsSelected,
      bgVendor: bgVendor,
      comItem: vendorSpec,
      backGroundColor: getBackGround(comIsSelected, bgVendor),
    };
  } else {
    specRow[`quote_${vendorIndex}_selectCOM`] = {
      type: CELL_TYPES.SELECT_COM_CHECKBOX,
      onClick: onCOMClicked,
      isSelected: true,
      bgVendor: bgVendor,
      comItem: vendorSpec,
      backGroundColor: comVendorBackground,
    };
  }

  specRow[`quote_${vendorIndex}_chevron`] = " ";
};

export const buildSpecRowForAllInclusive = (
  specRow,
  vendorIndex,
  defaultProjectCurrency,
  vendorSpec,
  allCOMItems,
  quoteIndex,
  bgVendor,
  openAlternateQuotesModal
) => {
  const { quoteProjectCurrency } = bgVendor;
  switch (vendorSpec.type) {
    case SPEC_TYPES.COM: {
      const parentSpec = allCOMItems[vendorSpec.spec.id].parentSpec;
      specRow[`quote_${vendorIndex}_productionPricing`] = {
        value: `Included on #${parentSpec.customNumber} ${parentSpec.description}`,
        type: CELL_TYPES.INCLUDED_IN_SPEC,
        expanded: true,
      };
      specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
        noRender: true,
      };
      specRow[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
        noRender: true,
      };
      specRow[`quote_${vendorIndex}_productionCOMQuantity`] = {
        noRender: true,
      };
      specRow[`quote_${vendorIndex}_selectCOM`] = {
        noRender: true,
      };
      specRow[`quote_${vendorIndex}_chevron`] = {
        noRender: true,
      };
      break;
    }
    default: {
      const { quote: vendorSpecQuote = {} } = vendorSpec;
      const quote = getQuoteItemAtIndex(vendorSpecQuote, quoteIndex);
      if (quote) {
        specRow[`quote_${vendorIndex}_productionPricing`] = {
          value: getPlaceholderWhenFalsy(
            vendorSpec.productionPricing * quoteProjectCurrency.conversionRate
          ),
          type: CELL_TYPES.NUMBER,
          projectCurrency: defaultProjectCurrency,
          expanded: false,
        };

        specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
          value: getZeroWhenFalsy(
            vendorSpec.extendedProductionPricing *
              quoteProjectCurrency.conversionRate
          ),
          projectCurrency: defaultProjectCurrency,
          type: CELL_TYPES.NUMBER,
        };
      }
      specRow[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
        value: vendorSpec.productionQuantity
          ? `${vendorSpec.productionQuantity}${getEmptyStringWhenNull(
              specRow.unitOfMeasure?.name
            )}`
          : "",
      };

      specRow[`quote_${vendorIndex}_productionCOMQuantity`] = {
        value: `1${getEmptyStringWhenNull(specRow.unitOfMeasure?.name)}`,
      };
      specRow[`quote_${vendorIndex}_selectCOM`] = "";

      setChevronColumn(
        vendorSpec,
        specRow,
        openAlternateQuotesModal,
        bgVendor,
        vendorIndex
      );
    }
  }
};

const getSpecRowEmptyBackgroung = bgVendor => {
  return bgVendor.isAlternateQuote ? "#EDF0F7" : comVendorBackground;
};

export const buildSpecRowEmpty = (specRow, vendorIndex, bgVendor) => {
  specRow[`quote_${vendorIndex}_sampleCOMQuantity`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_samplePricing`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedSamplePricing`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedSampleCOMQuantity`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_productionPricing`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedProductionPricing`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_productionCOMQuantity`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_extendedProductionCOMQuantity`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_selectCOM`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
  specRow[`quote_${vendorIndex}_chevron`] = {
    value: " ",
    backGroundColor: getSpecRowEmptyBackgroung(bgVendor),
  };
};
