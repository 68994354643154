import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import Paper from "../../../../mui/core/Paper";

import GridHeader from "../../../../ui/BWGrid/GridHeader";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const StyledPaper = styled(Paper)`
  ${GridHeader} {
    padding: 24px;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
  margin-right: 10px;
`;

export const EditLink = styled.div`
  cursor: pointer;
  color: #57abff;
  display: flex;
  align-items: center;

  &:hover {
    color: #57abffcc;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0px;
  align-items: center;
`;
export const AttributesWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: left;
  margin-right: 16px;
  min-height: 22px;
`;

export const ExpandActionWrapper = styled(HeaderLeft)`
  flex-grow: 1;
  margin-right: 0px;
  justify-content: flex-end;
`;

export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`;
export const StyledIconButton = styled(props => <IconButton {...props} />)`
  height: 16px;
  width: 16px;
  padding: 0;
  color: #57abff;
  flex-grow: 0;
  margin-left: 8px;
`;
StyledIconButton.displayName = "StyledIconButton";
export const SubHeadingWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 16px;
`;
export const ComItemText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: #7a8398;
  font-size: 14px;
  line-height: 24px;
`;
export const BoldComItemText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #7a8398;
  font-size: 14px;
  line-height: 24px;
`;

export const ExpandedIcon = styled(({ isExpanded, ...props }) => (
  <ExpandMoreIcon {...props} />
))`
  ${({ isExpanded }) =>
    isExpanded
      ? `
  transform: rotate(180deg);
  marginleft: auto;
  transition: transform;
  `
      : null}
`;

export const PreviousPOWarning = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ff871e;
  margin-left: 10px;
`;

export const WarningSymbol = styled.span`
  color: #ff871e;
`;

export const StyledBidGroupTableActionsDiv = styled.div`
  margin-top: 2px;
  margin-left: 10px;
`;
