import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Snackbar, SnackbarContent, Button, Fade } from "@material-ui/core";

const StyledSnackBar = styled(Snackbar)`
  margin-bottom: 60px;
  width: 80%;
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
  max-width: 100%;
`;

const DismissButton = styled(Button)`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: rgb(255, 255, 255);
`;

class SnackNotification extends Component {
  static propTypes = {
    snackNotification: PropTypes.object,
    onClose: PropTypes.func,
  };

  render() {
    const { onClose } = this.props;
    const { isOpen, message, hideDuration } = this.props.snackNotification;

    return (
      <StyledSnackBar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Fade}
        autoHideDuration={hideDuration}
      >
        <StyledSnackbarContent
          aria-describedby={"snackMessage"}
          message={<span id="snackMessage">{message}</span>}
          action={<DismissButton onClick={onClose}>DISMISS</DismissButton>}
        />
      </StyledSnackBar>
    );
  }
}

export default SnackNotification;
