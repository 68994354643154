import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BWModels from "benjaminwest-models";

import PropertyForm from "./PropertyForm";

import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
} from "../../../actions/dataComponentActions";

import Property from "../../../models/Property";

import { getDataComponent } from "../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../utils/dataComponentUtils";

const dataComponentId = "PropertyCreate";

export class PropertyCreateContainer extends Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    push: PropTypes.func,
    initDataComponent: PropTypes.func,
    performCreateRequest: PropTypes.func,
    performRetrieveListRequest: PropTypes.func.isRequired,
    dataComponent: PropTypes.object,
  };

  propertyInitialValues = {
    name: "",
    location: {
      address: undefined,
      address2: undefined,
      city: undefined,
      zip: undefined,
      country: undefined,
      state: undefined,
    },
  };

  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, Property, ["location"], "properties");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processCreateRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleCreateSuccess,
      onError: error => handleRequestError(error, this.state.formikActions),
    });
  }

  handleCreateSuccess = () => {
    const { clientId } = this.props;
    this.props.push(`/clients/${clientId}/properties`);
  };

  createProperty = (property, formikActions) => {
    const { performCreateRequest } = this.props;
    performCreateRequest(dataComponentId, property);
    this.setState({ formikActions });
  };

  render() {
    const { dataComponent } = this.props;
    const propertySchema = BWModels.loadSchema("Property");

    return (
      <PropertyForm
        initialValues={this.propertyInitialValues}
        propertySchema={propertySchema}
        onSave={this.createProperty}
        loading={false}
        clientId={this.props.clientId}
        dataComponentId={dataComponent.dataComponentId}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    clientId: ownProps.match.params.clientId,
    dataComponent,
  };
};

const mapDispatchToProps = {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PropertyCreateContainer)
);
