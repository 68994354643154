import React from "react";
import { connect } from "formik";
import _get from "lodash/get";
import { SelectWithError as RawSelectWithError } from "../../../inputs/SelectWithError";

export const FreightInput = ({ onChange, formik, ...props }) => {
  const handleChange = (...args) => {
    const { values, setFieldValue } = formik;
    setFieldValue(
      "poContacts",
      _get(values, "poContacts", []).filter(
        ({ contactType }) => contactType !== "freight"
      )
    );
    onChange(...args);
  };

  return <RawSelectWithError onChange={handleChange} {...props} />;
};

export default connect(FreightInput);
