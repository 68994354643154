import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Loader from "../../../ui/Loader";
import PurchaseOrderSelectMenu from "./PurchaseOrderSelectMenu";
import { BaseTitle, Title } from "../../../layout/DetailAndCycle/TitleArea";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PONumberNav = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

const Id = styled(BaseTitle)`
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const TitleArea = ({
  id,
  title,
  purchaseOrderId,
  projectId,
  baseUrl,
  backText,
  backRoute,
  children,
}) => {
  return (
    <Loader height="32px">
      <Wrapper>
        <PONumberNav>
          {id ? <Id>{id}</Id> : null}
          <PurchaseOrderSelectMenu
            purchaseOrderId={purchaseOrderId}
            projectId={projectId}
            baseUrl={baseUrl}
            backText={backText}
            backRoute={backRoute}
          />
        </PONumberNav>
        <Title>{title}</Title>
        <Content>{children}</Content>
      </Wrapper>
    </Loader>
  );
};

TitleArea.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  purchaseOrderId: PropTypes.string,
  projectId: PropTypes.string,
  baseUrl: PropTypes.string,
  backText: PropTypes.string,
  backRoute: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TitleArea;
