import React from "react";
import PropTypes from "prop-types";

import RemitAddressInfo from "./RemitAddressInfo";
import propTypes from "../../../../../constants/propTypes";

const RemitAddressInfoContainer = ({ invoice, updateInvoice }) =>
  invoice ? (
    <RemitAddressInfo invoice={invoice} updateInvoice={updateInvoice} />
  ) : null;

RemitAddressInfoContainer.propTypes = {
  invoice: propTypes.invoice,
  updateInvoice: PropTypes.func.isRequired,
};

export default RemitAddressInfoContainer;
