import React from "react";
import PropTypes from "prop-types";
import DoneAll from "@material-ui/icons/DoneAll";

import propTypes from "../../../../../constants/propTypes";
import { StyledButton, ButtonText } from "../components";

const hiddenStatus = ["Draft", "Pending Approval"];

const TriggerButton = ({ purchaseOrder, onOpenApprovalManagerClick }) => {
  if (hiddenStatus.includes(purchaseOrder.status)) return null;
  return (
    <StyledButton onClick={onOpenApprovalManagerClick}>
      <DoneAll />
      <ButtonText>
        Approval Manager({purchaseOrder?.approvalFiles?.length || 0})
      </ButtonText>
    </StyledButton>
  );
};
TriggerButton.propTypes = {
  onOpenApprovalManagerClick: PropTypes.func.isRequired,
  purchaseOrder: propTypes.purchaseOrder,
};

export default TriggerButton;
