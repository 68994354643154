import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import TagText from "../../ui/Tags/TagText";
import FavoriteControl from "./FavoriteControl";
import { BWIntent } from "../../ui/BWIntent";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

const NameWrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-overflow: inherit;
  white-space: inherit;
  overflow: inherit;
  ${TagText} {
    margin-left: 16px;
  }
`;

const NameFormatter = ({ row }) => {
  const { name, msaClient } = row;
  return (
    <NameWrapper>
      {name}
      {msaClient && <TagText intent={BWIntent.PRIMARY}>MSA</TagText>}
    </NameWrapper>
  );
};

NameFormatter.propTypes = {
  row: PropTypes.shape({ name: PropTypes.string, msaClient: PropTypes.bool }),
};

export const NameTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NameFormatter}
    {...props}
    for={["name"]}
  />
);

const FavoriteFormatter = ({ row }) => {
  return <FavoriteControl clientId={row.id} />;
};

FavoriteFormatter.propTypes = {
  row: PropTypes.shape({ name: PropTypes.string, msaClient: PropTypes.bool }),
};

export const FavoriteTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={FavoriteFormatter}
    {...props}
    for={["isFavorite"]}
  />
);
