import moment from "moment";
import { PRIMARY_DATE_FORMAT } from "../../../constants/formats";

const mmmToMM = {
  jan: "01",
  feb: "02",
  mar: "03",
  apr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  aug: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dec: "12",
};

export const scopeConfig = {
  USA: {
    masks: [
      "MMDDYYYY",
      "MMDDYY",
      "MM-DD-YYYY",
      "MM/DD/YYYY",
      "MM-DD-YY",
      "MM/DD/YY",
      "DDMMYYYY",
      "DDMMYY",
      "DDMMYYYY",
    ],
    regexList: [
      /^(\d{2})(\d{2})(\d{4})$/, //08141991
      /^(\d{2})(\d{2})(\d{2})$/, //081493
      /^(\d{2})-(\d{2})-(\d{4})$/, //08-14-1995
      /^(\d{2})\/(\d{2})\/(\d{4})$/, //08/14/1996
      /^(\d{2})-(\d{2})-(\d{2})$/, //08-14-98
      /^(\d{2})\/(\d{2})\/(\d{2})$/, //08/14/99
      /^(\d{2})([a-zA-Z]{3})(\d{4})$/, //14aug2000
      /^(\d{2})([a-zA-Z]{3})(\d{2})$/, //14aug01
      /^(\d{2})-([a-zA-Z]{3})-(\d{4})$/, //14-aug-1994
    ],
    partialRegexList: [
      /^((\d{2})?)((\d{1,2})?)((\d{1,4})?)$/, //08141991
      /^((\d{2})?)((\d{1,2})?)((\d{1,2})?)$/, //081493
      /^((\d{2})?)-?((\d{1,2})?)-?((\d{1,4})?)$/, //08-14-1995
      /^((\d{2})?)\/?((\d{1,2})?)\/?((\d{1,4})?)$/, //08/14/1996
      /^((\d{2})?)-((\d{1,2})?)-((\d{1,2})?)$/, //08-14-98
      /^((\d{2})?)\/((\d{1,2})?)\/((\d{1,2})?)$/, //08/14/99
      /^((\d{2})?)(([a-zA-Z]{1,3})?)((\d{1,4})?)$/, //14aug2000
      /^((\d{2})?)(([a-zA-Z]{1,3})?)((\d{1,2})?)$/, //14aug01
      /^((\d{2})?)-+(([a-zA-Z]{1,3})?)-?((\d{1,4})?)$/, //14-aug-1994
    ],
  },
  UK: {
    masks: [
      "DDMMYYYY",
      "DDMMYYYY",
      "DDMMYY",
      "DDMMYY",
      "DD-MM-YYYY",
      "DD-MM-YYYY",
      "DD/MM/YYYY",
      "DD-MM-YY",
      "DD-MM-YY",
      "DD/MM/YY",
    ],
    regexList: [
      /^(\d{2})([a-zA-Z]{3})(\d{4})$/, //14aug1990
      /^(\d{2})(\d{2})(\d{4})$/, //14081991
      /^(\d{2})([a-zA-Z]{3})(\d{2})$/, //14aug92
      /^(\d{2})(\d{2})(\d{2})$/, //140893
      /^(\d{2})-([a-zA-Z]{3})-(\d{4})$/, //14-aug-1994
      /^(\d{2})-(\d{2})-(\d{4})$/, //14-08-1995
      /^(\d{2})\/(\d{2})\/(\d{4})$/, //14/08/1996
      /^(\d{2})-([a-zA-Z]{3})-(\d{2})$/, //14-aug-97
      /^(\d{2})-(\d{2})-(\d{2})$/, //14-08-98
      /^(\d{2})\/(\d{2})\/(\d{2})$/, //14/08/99
    ],
    partialRegexList: [
      /^((\d{2})?)(([a-zA-Z]{1,3})?)((\d{1,4})?)$/, //14aug1990
      /^((\d{2})?)((\d{1,2})?)((\d{1,4})?)$/, //14081991
      /^((\d{2})?)(([a-zA-Z]{1,3})?)((\d{1,2})?)$/, //14aug92
      /^((\d{2})?)((\d{1,2})?)((\d{1,2})?)$/, //140893
      /^((\d{2})?)-+(([a-zA-Z]{1,3})?)-?((\d{1,4})?)$/, //14-aug-1994
      /^((\d{2})?)-?((\d{1,2})?)-?((\d{1,4})?)$/, //14-08-1995
      /^((\d{2})?)\/?((\d{1,2})?)\/?((\d{1,4})?)$/, //14/08/1996
      /^((\d{2})?)-(([a-zA-Z]{1,3})?)-((\d{1,2})?)$/, //14-aug-97
      /^((\d{2})?)-((\d{1,2})?)-((\d{1,2})?)$/, //14-08-98
      /^((\d{2})?)\/((\d{1,2})?)\/((\d{1,2})?)$/, //14/08/99
    ],
  },
};

export default ({ masks, regexList, partialRegexList }) => {
  const canMatch = value =>
    !!partialRegexList.find(partialRegex => partialRegex.test(value));

  const getDateValue = value => {
    const regexIndex = regexList.findIndex(regex => regex.test(value));

    if (regexIndex != -1) {
      const parsedValue = Object.keys(mmmToMM).reduce(
        (val, mmmm) => val.replace(mmmm, mmmToMM[mmmm]),
        value.toLowerCase()
      );
      return moment(parsedValue, masks[regexIndex]);
    }
  };

  const getDateFormatted = date => date?.format(PRIMARY_DATE_FORMAT);

  const parseValue = value => (value == "Unknown" ? undefined : value);

  const isInvalidChange = (evt, inlineValue) =>
    !evt.target.value != "t" &&
    evt.target.value.length > inlineValue.length &&
    !canMatch(evt.target.value);

  const getFormattedValue = evt => {
    const shortcut = evt.target.value == "t";
    const shortCutValue = moment();
    const value = shortcut ? shortCutValue : getDateValue(evt.target.value);
    return getDateFormatted(value) || "";
  };

  return {
    parseValue,
    isInvalidChange,
    getFormattedValue,
  };
};
