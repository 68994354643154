import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrid from "../../../forms/FormGrid";
import FormikForm from "../../../forms/FormikForm";
import ActionButtons from "../../../forms/ActionButtons";
import BWModels from "benjaminwest-models";
import createConsultingCompanyFields from "./createConsultingCompanyFields";
import propTypes from "../../../../constants/propTypes";
import useUserRole from "../../../hooks/useUserRole";
import { isSuperAdmin } from "../../../../utils/roleUtils";

export const getValidationSchema = (userRole, isUpdate) => {
  const schema = BWModels.loadSchema("ConsultingCompany");
  if (isSuperAdmin(userRole) && !isUpdate) {
    return schema.keys({
      scopeId: BWModels.Joi.selectFk().required(),
    });
  }
  return schema;
};

const initialValues = {
  name: "",
  location: {
    address: undefined,
    address2: undefined,
    city: undefined,
    zip: undefined,
    country: undefined,
    state: undefined,
  },
};

const ConsultingCompanyForm = ({
  onSubmit,
  consultingCompany,
  sendButtonText,
  dataComponentId,
  isUpdate,
}) => {
  const userRole = useUserRole();
  return (
    <FormikForm
      initialValues={consultingCompany || initialValues}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(userRole, isUpdate)}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGrid
            fields={createConsultingCompanyFields(userRole, isUpdate)}
            values={values}
            errors={errors}
            {...formikProps}
          />
          <ActionButtons
            onSend={handleSubmit}
            sendButtonText={sendButtonText}
            isModal={true}
            listeners={[dataComponentId]}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

ConsultingCompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sendButtonText: PropTypes.string.isRequired,
  consultingCompany: propTypes.consultingCompany,
  dataComponentId: PropTypes.string.isRequired,
  isUpdate: PropTypes.bool,
};

export default ConsultingCompanyForm;
