import TextInputWithError from "../../inputs/TextInputWithError";

export default {
  email: {
    InputComponent: TextInputWithError,
    label: "Email",
    name: "email",
    fullWidth: true,
    avoidDebounce: true,
    inputProps: {
      autoComplete: "username",
    },
  },
  password: {
    InputComponent: TextInputWithError,
    label: "Password",
    type: "password",
    name: "password",
    fullWidth: true,
    avoidDebounce: true,
    inputProps: {
      autoComplete: "current-password",
    },
  },
  oldPassword: {
    InputComponent: TextInputWithError,
    label: "Old Password",
    type: "password",
    name: "oldPassword",
    fullWidth: true,
  },
  repeatPassword: {
    InputComponent: TextInputWithError,
    label: "Repeat Password",
    type: "password",
    name: "passwordConfirmation",
    fullWidth: true,
  },
};
