import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BWModels from "benjaminwest-models";
import CreateVendorContactForm from "./CreateVendorContactForm";
import { closeModalDialog } from "../../../../actions/layoutActions";
import Joi from "joi-browser";

class CreateVendorContactContainer extends Component {
  static propTypes = {
    closeModalDialog: PropTypes.func,
    formikArrayHelpers: PropTypes.object,
  };

  get validationSchema() {
    return BWModels.loadSchema("VendorContact").concat(
      Joi.object().keys({
        contact: BWModels.loadSchema("Contact"),
      })
    );
  }

  createVendorContact = contact => {
    this.props.formikArrayHelpers.push(contact);
    this.props.closeModalDialog();
  };

  render() {
    const vendor = {};
    const { closeModalDialog } = this.props;
    return (
      <CreateVendorContactForm
        validationSchema={this.validationSchema}
        vendor={vendor}
        createVendorContact={this.createVendorContact}
        closeModalDialog={closeModalDialog}
      />
    );
  }
}

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(null, mapDispatchToProps)(CreateVendorContactContainer);
