import React, { useRef } from "react";
import shallow from "zustand/shallow";

import useErrorImportStore from "../../../../hooks/useErrorImportStore";
import {
  InputNumber as Input,
  InputWrapper,
  StyledEditIcon,
} from "../components";

function InputNumber({ name, rowId, ...props }) {
  const [actions] = useErrorImportStore(
    state => [state.actions, state.importError, state.localError], //Doing this force to the component to be re-rendered when the errors get updated changes
    shallow
  );

  const inputRef = useRef();

  const placeholder = actions.isInvalidLocalField(rowId, name) ? "Invalid" : "";

  return (
    <InputWrapper>
      <Input
        {...props}
        ref={inputRef}
        isInvalid={actions.isInvalidLocalField(rowId, name)}
        placeholder={placeholder}
      />
      <StyledEditIcon onClick={() => inputRef.current.focus()} />
    </InputWrapper>
  );
}

export default InputNumber;
