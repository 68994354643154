import React, { Component } from "react";
import PropTypes from "prop-types";
import { Plugin, Template } from "@devexpress/dx-react-core";
import _get from "lodash/get";
import { Table } from "@devexpress/dx-react-grid-material-ui";

import memoizeOne from "memoize-one";
import {
  SortingState,
  CustomPaging,
  SelectionState,
  IntegratedSelection,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableRowDetail,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";

import {
  isGridTable,
  isGridRow,
  getGridTableProps,
  generateGridColumns,
  generateTableColumnExtensions,
} from "./utils";
import GridRow from "./GridRow";
import propTypes from "../../../constants/propTypes";

import {
  StyledBWTableContainer,
  StyledBWHeadRow,
  StyledBWHeadCell,
  StyledBWSelectHeader,
  StyledBWSelectCell,
  StyledBWDetailCell,
  StyledBWDetailRow,
  StyledBWToggleCell,
} from "./components";

import {
  BWGridPagingState,
  BWGridTableHeaderRow,
  BWGridPagingPanel,
  BWGridFilterState,
  BWGridFilterRow,
  BWCell,
  BWTableRow,
  BWTable,
  BWGridHeaderSortLabel,
} from "./gridInternalComponents";
import TextTypeProvider from "./gridInternalComponents/dataTypeProviders/TextTypeProvider";
import NumberTypeProvider from "./gridInternalComponents/dataTypeProviders/NumberTypeProvider";
import EmptyTypeProvider from "./gridInternalComponents/dataTypeProviders/EmptyTypeProvider";
import StaticMultiSelectTypeProvider from "./gridInternalComponents/dataTypeProviders/StaticMultiSelectTypeProvider";
import StaticSelectTypeProvider from "./gridInternalComponents/dataTypeProviders/StaticSelectTypeProvider";
import PickerTypeProvider from "./gridInternalComponents/dataTypeProviders/PickerTypeProvider";
import BWGridTable from "./gridInternalComponents/BWGridTable";
import { createDeepEqualSelector } from "../../../utils/selectors";
import {
  withRevisionReference,
  withDeletedItems,
} from "../../../withPORevision";
import withEditableCellController, {
  WithEditableCellContext,
} from "./withEditableCellController";
import { buildNoDataCell, NoDataRow } from "./gridInternalComponents/BWNoData";

// These wrapper components are required due dx-react-grid only accepts functions as props.

export const BWTableContainer = props => <StyledBWTableContainer {...props} />;

export const BWHeadRow = isLoading =>
  function StyledBWTableRow(props) {
    return <StyledBWHeadRow {...props} isLoading={isLoading} />;
  };
export const BWHeadCell = props => <StyledBWHeadCell {...props} />;
export const BWSelectHeader = props => <StyledBWSelectHeader {...props} />;
export const BWSelectCell = props => {
  const { row, onToggle } = props;
  return (
    <StyledBWSelectCell
      {...props}
      disabled={row.disabled}
      onToggle={row.disabled ? undefined : onToggle}
    />
  );
};
export const BWToggleCell = () => <StyledBWToggleCell />;
export const BWDetailCell = props => <StyledBWDetailCell {...props} />;
export const BWDetailRow = props => <StyledBWDetailRow {...props} />;

const hasEnoughRowsForPagination = gridConfig => gridConfig.totalRows > 10;

const Root = props => <Grid.Root {...props} className="grid-root" />;

const defaultRows = [];

export class BWGrid extends Component {
  state = { expandedRowIds: [], error: null, clickedRow: null };

  static contextType = WithEditableCellContext;

  componentDidMount() {
    const { setCurrentCellFunc } = this.props;
    const { setCurrentCell } = this.context;
    if (setCurrentCellFunc) {
      setCurrentCellFunc(() => setCurrentCell);
    }
  }

  componentDidUpdate({ rows: prevRows }) {
    const { rows } = this.props;
    if (_get(rows, "length") !== _get(prevRows, "length")) {
      this.setState({ expandedRowIds: [] });
    }
  }

  expandRow = (row, rowData) => {
    const { rowId } = rowData;
    const { expandedRowIds } = this.state;
    const { alwaysDisplayDetailRow, onExpand } = this.props;
    if (alwaysDisplayDetailRow) return;
    if (expandedRowIds.includes(rowId)) {
      return this.setState({
        expandedRowIds: expandedRowIds.filter(
          expandedId => expandedId !== rowId
        ),
      });
    }
    onExpand && onExpand(row);
    return this.setState({
      expandedRowIds: [...expandedRowIds, rowId],
    });
  };

  getRows = (children, template) => {
    let numGridRowsForTemplate = 0;
    let aboveTable = true;
    let isFirstRow = true;
    const isHeaderGridRow = () => aboveTable && template === "header";
    const isFooterGridRow = () => !aboveTable && template === "footer";
    const showPagingPanel = () => isFooterGridRow() && isFirstRow;
    const rows = React.Children.map(children, child => {
      let eligibleRowForTemplate = null;
      const isTable = isGridTable(child);
      const isRow = isGridRow(child);
      if (isRow) {
        eligibleRowForTemplate = this._makeRowForTemplate(
          child,
          isHeaderGridRow(),
          isFooterGridRow(),
          showPagingPanel()
        );
      }
      aboveTable = aboveTable && !isTable;
      if (eligibleRowForTemplate !== null) {
        numGridRowsForTemplate += 1;
        isFirstRow = false; // next row will not be the first row
      }
      return eligibleRowForTemplate;
    });
    return { rows, numGridRowsForTemplate };
  };

  makeGridRowsWithTemplateSet(
    children,
    template,
    hasEnoughRowsForPagination = false,
    hideFooter = false
  ) {
    const { rows, numGridRowsForTemplate } = this.getRows(children, template);

    if (numGridRowsForTemplate > 0) {
      return (
        <Plugin name="GridRowPlugin">
          <Template name={template}>{rows}</Template>
        </Plugin>
      );
    } else if (
      template === "footer" &&
      !hideFooter &&
      hasEnoughRowsForPagination
    ) {
      return (
        <Plugin name="GridRowPlugin">
          <Template name={template}>
            <GridRow showPagingPanel={true} />
          </Template>
        </Plugin>
      );
    }
    return null;
  }

  _makeRowForTemplate(
    child,
    isHeaderGridRow,
    isFooterGridRow,
    showPagingPanel
  ) {
    if (isHeaderGridRow || isFooterGridRow) {
      const { children, ...childProps } = child.props;
      return React.cloneElement(
        child,
        Object.assign(
          {
            showPagingPanel,
            isLoading: isHeaderGridRow ? this.props.isLoading : null,
          },
          childProps
        )
      );
    }
    return null;
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  renderCustomPaging(gridConfig) {
    return <CustomPaging totalCount={gridConfig.totalRows} />;
  }

  renderSortingState(gridConfig) {
    const { setSort, children } = this.props;
    const { columnOptions } = getGridTableProps(children);
    if (setSort) {
      return (
        <SortingState
          sorting={gridConfig.sort}
          onSortingChange={sort => setSort(sort, columnOptions)}
          columnExtensions={this.columnExtensionsSelector({ columnOptions })}
        />
      );
    }
  }

  renderSelectionState = (gridConfig, showSelectionColumn) => {
    const pageNumber = gridConfig.pageNumber;
    const fullSelection = gridConfig.selection;
    const selectionByPage = _get(fullSelection, pageNumber, []);

    const handleSelection = selectionByPage => {
      const newSelection = { ...fullSelection };
      newSelection[pageNumber] = selectionByPage;
      return setSelection(newSelection);
    };
    const { setSelection } = this.props;
    return (
      showSelectionColumn && (
        <SelectionState
          selection={selectionByPage}
          onSelectionChange={handleSelection}
        />
      )
    );
  };

  renderIntegratedSelection() {
    return this.props.showSelectionColumn && <IntegratedSelection />;
  }

  renderTableSelection(tableComponents) {
    const { showSelectionColumn, showSelectAll } = this.props;
    const { SelectionCell, SelectionHeader } = tableComponents;
    return (
      showSelectionColumn && (
        <TableSelection
          showSelectionColumn={showSelectionColumn}
          showSelectAll={showSelectAll}
          cellComponent={SelectionCell}
          headerCellComponent={SelectionHeader}
        />
      )
    );
  }

  renderDetailRow = memoizeOne(
    (tableComponents, expandedRowIds, alwaysDisplayDetailRow, gridRows) => {
      const {
        DetailRowComponent,
        DetailCellComponent,
        WrapperDetailRow,
      } = tableComponents;
      if (!DetailRowComponent) return null;
      const rowIds = alwaysDisplayDetailRow
        ? gridRows.map((_, rowId) => rowId)
        : expandedRowIds;
      return [
        <RowDetailState key="rowDetailState" expandedRowIds={rowIds} />,
        <TableRowDetail
          key="tableRowDetail"
          contentComponent={DetailRowComponent}
          cellComponent={DetailCellComponent}
          toggleCellComponent={BWToggleCell}
          toggleColumnWidth={24}
          rowComponent={WrapperDetailRow}
        />,
      ];
    }
  );

  isEmptyTable(rows) {
    return rows.length === 0;
  }

  get rows() {
    return this.props.rows || defaultRows;
  }

  tableComponents = memoizeOne(
    ({ tableComponents = {} }) => {
      const {
        TableComponent = BWTable,
        RowComponent = BWTableRow,
        CellComponent = BWCell,
        HeaderRowComponent = BWHeadRow,
        HeaderCellComponent = BWHeadCell,
        DetailCellComponent = BWDetailCell,
        WrapperDetailRow = BWDetailRow,
        SelectionCell = BWSelectCell,
        SelectionHeader = BWSelectHeader,
        DetailRowComponent,
        TableContainerComponent = BWTableContainer,
        HeaderSortLabelComponent = BWGridHeaderSortLabel,
        TableFilterRow = BWGridFilterRow,
        BodyComponent = Table.TableBody,
        NoDataRowComponent = NoDataRow,
      } = tableComponents;
      return {
        TableComponent,
        RowComponent,
        CellComponent,
        HeaderRowComponent,
        HeaderCellComponent,
        SelectionCell,
        SelectionHeader,
        DetailRowComponent,
        TableContainerComponent,
        DetailCellComponent,
        WrapperDetailRow,
        HeaderSortLabelComponent,
        TableFilterRow,
        BodyComponent,
        NoDataRowComponent,
      };
    },
    ([props], [lastProps]) =>
      props.tableComponents === lastProps.tableComponents
  );

  generateGridRows = memoizeOne(
    (columnOptions, rows, isLoading, clickedRow, expandedRowIds) => {
      const isEmptyTable = this.isEmptyTable(rows);
      const defaultLoadingRows = Array(10)
        .fill({})
        .map((value, index) => ({ id: index }));
      if (isEmptyTable && isLoading) return defaultLoadingRows;

      return isEmptyTable
        ? []
        : rows.map((row, index) => {
            const rowCopy = {
              ...row,
              __isClicked__: clickedRow === index,
              __expanded__: expandedRowIds.includes(index),
            };
            Object.entries(columnOptions).map(([field, options]) => {
              if ("default" in options) {
                if (rowCopy[field] === undefined || rowCopy[field] === null) {
                  rowCopy[field] = options["default"];
                }
              }
            });
            return rowCopy;
          });
    }
  );

  columnExtensionsSelector = createDeepEqualSelector(
    ({ columnOptions }) => columnOptions,
    columnOptions => generateTableColumnExtensions(columnOptions)
  );

  getGridColumns = memoizeOne(
    (rows, { children }) => {
      const { columns, columnOptions, rowMenu: menuItems } = getGridTableProps(
        children
      );
      return generateGridColumns({
        columns,
        columnOptions,
        menuItems,
        rows,
        dismissSnackNotificationAction: this.props
          .dismissSnackNotificationAction,
      });
    },
    ([rows, props], [lastRows, lastProps]) =>
      rows === lastRows && props.children === lastProps.children
  );

  get filterProps() {
    const {
      onFiltersChange,
      filteringBuilders: operationBuilders,
      children,
      id,
      gridConfig,
      ignoreFilterQueryParams,
    } = this.props;
    const { columnOptions } = getGridTableProps(children);
    const displayFilters = Object.keys(columnOptions).some(
      column => columnOptions[column].filter
    );
    return {
      id: id || gridConfig.dataComponentId,
      displayFilters,
      columnOptions,
      onFiltersChange,
      operationBuilders,
      ignoreFilterQueryParams,
    };
  }

  handleClick = memoizeOne((onClick, isLoading) => {
    if (!onClick) return;
    if (isLoading) return;

    return (row, tableRow, ...args) => {
      this.setState({ clickedRow: tableRow.rowId });

      onClick(row, tableRow, ...args);
    };
  });

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { rows } = this;
    const {
      children,
      gridConfig,
      hideFooter,
      rootComponent,
      isLoading,
      setPageSize,
      setPage,
      setSort,
      onReorder,
      showSelectionColumn,
      hidePaginationControl,
      pageSize,
      alwaysDisplayDetailRow,
      displayCollapseButton,
      emptyStateText,
      emptyStateProps,
      isCollapsed,
    } = this.props;
    if (this.state.error) return null;
    const { columnOptions, onClick, disableRow } = getGridTableProps(children);
    const gridRows = this.generateGridRows(
      columnOptions,
      rows,
      isLoading,
      this.state.clickedRow,
      this.state.expandedRowIds
    );
    const columns = this.getGridColumns(this.rows, this.props);

    const NoDataCellComponent = buildNoDataCell({
      isLoading,
      isCollapsed,
      isEmpty: this.isEmptyTable(this.rows),
      text: emptyStateText,
      ...emptyStateProps,
    });

    const tableComponents = this.tableComponents(this.props);

    const { TableFilterRow } = tableComponents;

    return (
      <Grid
        id={gridConfig.dataComponentId ? gridConfig.dataComponentId : ""}
        columns={columns}
        rows={gridRows}
        rootComponent={rootComponent}
      >
        <BWGridPagingState
          gridConfig={gridConfig}
          setPageSize={setPageSize}
          setPage={setPage}
          pageSize={pageSize}
        />
        {this.renderCustomPaging(gridConfig)}
        {this.renderSortingState(gridConfig)}
        {this.renderSelectionState(gridConfig, showSelectionColumn)}
        {this.renderIntegratedSelection()}
        <BWGridTable
          expandRow={this.expandRow}
          isLoading={isLoading}
          tableComponents={tableComponents}
          NoDataCellComponent={NoDataCellComponent}
          onClick={this.handleClick(onClick, isLoading)}
          onReorder={onReorder}
          columnExtensions={this.columnExtensionsSelector({ columnOptions })}
          disableRow={disableRow}
          alwaysDisplayDetailRow={alwaysDisplayDetailRow}
          displayCollapseButton={displayCollapseButton}
        />
        <BWGridTableHeaderRow
          setSort={setSort}
          isLoading={isLoading}
          tableComponents={tableComponents}
        />
        <EmptyTypeProvider {...this.filterProps} />
        <StaticMultiSelectTypeProvider {...this.filterProps} />
        <StaticSelectTypeProvider {...this.filterProps} />
        <PickerTypeProvider {...this.filterProps} />
        <TextTypeProvider {...this.filterProps} />
        <NumberTypeProvider {...this.filterProps} />
        <BWGridFilterState {...this.filterProps} />
        <TableFilterRow {...this.filterProps} />
        {this.renderDetailRow(
          tableComponents,
          this.state.expandedRowIds,
          alwaysDisplayDetailRow,
          gridRows
        )}
        {this.makeGridRowsWithTemplateSet(children, "header")}
        {this.makeGridRowsWithTemplateSet(
          children,
          "footer",
          hasEnoughRowsForPagination(gridConfig),
          hideFooter
        )}
        {this.renderTableSelection(tableComponents)}

        <BWGridPagingPanel
          gridConfig={gridConfig}
          hidePaginationControl={hidePaginationControl}
        >
          {children}
        </BWGridPagingPanel>
      </Grid>
    );
  }
}

BWGrid.defaultProps = {
  setPage: () => undefined,
  setPageSize: () => undefined,
  rootComponent: Root,
};

BWGrid.propTypes = {
  isLoading: PropTypes.bool,
  alwaysDisplayDetailRow: PropTypes.bool,
  children: PropTypes.node,
  gridConfig: PropTypes.object,
  showSelectionColumn: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  hideFooter: PropTypes.bool,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  setSort: PropTypes.func,
  setSelection: PropTypes.func,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  emptyStateText: PropTypes.string,
  emptyStateProps: PropTypes.shape({}),
  rows: PropTypes.array,
  rootComponent: propTypes.component,
  tableComponents: PropTypes.shape({
    TableComponent: PropTypes.func,
    RowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    CellComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    HeaderRowComponent: PropTypes.func,
    HeaderCellComponent: PropTypes.func,
    DetailRowComponent: propTypes.component,
  }),
  onFiltersChange: PropTypes.func,
  hidePaginationControl: PropTypes.bool,
  onReorder: PropTypes.func,
  filteringBuilders: PropTypes.objectOf(PropTypes.func.isRequired),
  isCollapsed: PropTypes.bool,
  ignoreFilterQueryParams: PropTypes.bool,
};

export default withEditableCellController(
  withRevisionReference(withDeletedItems(BWGrid))
);
