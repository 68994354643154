import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import memoizeOne from "memoize-one";
import _get from "lodash/get";
import { push } from "connected-react-router";
import queryString from "query-string";

import ProjectEditPage from "./ProjectEditPage";
import {
  initDataComponent,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
} from "../../../actions/dataComponentActions";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../reducers/dataComponentReducer";

import propTypes from "../../../constants/propTypes";
import {
  setAutoSaveComponentId,
  setHeaderTitle,
  closeModalDialog,
} from "../../../actions/layoutActions";
import {
  destroyComponents,
  initComponents,
  parseOnLoad,
  updateProject,
} from "../../../actions/projectsActions";
import { LoaderContext } from "../../ui/Loader";
import {
  getBluechipResourceById,
  getCurrentBluechipResourcesForRequestType,
} from "../../../utils/bluechipUtils";
import Area from "../../../models/Area";
import { showSnackNotificationAction } from "../../../actions/layoutActions";

import {
  onCreateArea,
  onUpdateArea,
  onDeleteArea,
  processRequests,
} from "./AreaFunctions";
import { withProjectNumber } from "../ProjectCreate/ContractSection/ProjectNumberHelper";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import { ProjectContext } from "../../hooks/useIsProjectClosed";

export const dataComponentId = "ProjectEdit";
const areasDataComponentId = "Areas";
const areaTypesDataComponentId = "select-areaTypes";
const dataClientComponentId = "ClientDetail";
export class ProjectEditContainer extends Component {
  static propTypes = {
    dataComponent: propTypes.dataComponent.isRequired,
    areaDataComponent: propTypes.dataComponent.isRequired,
    shipToDataComponent: propTypes.dataComponent.isRequired,
    contingenciesDataComponent: propTypes.dataComponent.isRequired,
    project: propTypes.project,
    projectId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    areaTypes: PropTypes.array.isRequired,
    initComponents: PropTypes.func.isRequired,
    setHeaderTitle: PropTypes.func.isRequired,
    updateProject: PropTypes.func.isRequired,
    initDataComponent: PropTypes.func.isRequired,
    performFindRequest: PropTypes.func.isRequired,
    performCreateRequest: PropTypes.func.isRequired,
    performDeleteRequest: PropTypes.func.isRequired,
    performUpdateRequest: PropTypes.func.isRequired,
    showSnackNotificationAction: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setAutoSaveComponentId: PropTypes.func.isRequired,
    destroyComponents: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    client: propTypes.client,
    role: propTypes.userRole,
    push: PropTypes.func.isRequired,
    workScopes: PropTypes.array,
    currencies: PropTypes.array,
  };

  state = {};

  static contextType = ProjectContext;

  componentDidMount() {
    const {
      projectId,
      setAutoSaveComponentId,
      performFindRequest,
      initComponents,
      initDataComponent,
      clientId,
    } = this.props;

    initComponents(dataComponentId, clientId, true);
    initDataComponent(
      areasDataComponentId,
      Area,
      ["areaType", "areaRooms"],
      "areas"
    );
    performFindRequest(dataComponentId, projectId);
    setAutoSaveComponentId(dataComponentId);
  }

  componentWillUnmount() {
    this.props.setHeaderTitle(null);
    this.props.destroyComponents(dataComponentId);
    this.props.destroyComponents(dataClientComponentId);
  }

  componentDidUpdate(prevProps) {
    const {
      project: prevProject,
      dataComponent: preDataComponent,
      areaDataComponent: preAreaDataComponent,
      shipToDataComponent: preShipToDataComponent,
      contingenciesDataComponent: preContingenciesDataComponent,
    } = prevProps;
    const {
      dataComponent,
      areaDataComponent,
      shipToDataComponent,
      contingenciesDataComponent,
      projectId,
    } = this.props;

    const {
      project,
      performFindRequest,
      showSnackNotificationAction,
      closeModalDialog,
      push,
    } = this.props;

    if (
      prevProject &&
      prevProject.property.entity.clientId !== project.property.entity.clientId
    ) {
      const returnUrl = queryString.stringify({
        returnUrl: `${window.location.pathname}${window.location.search}`,
        sectionName: "PROJECT DETAIL",
      });
      push(
        `/clients/${project.property.entity.clientId}/projects/${project.id}/settings?${returnUrl}`
      );
      window.location.reload();
    }
    processRequests(
      preAreaDataComponent,
      areaDataComponent,
      projectId,
      dataComponentId,
      "area",
      {
        performFindRequest,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );

    processRequests(
      preShipToDataComponent,
      shipToDataComponent,
      projectId,
      dataComponentId,
      "site",
      {
        performFindRequest,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );

    processRequests(
      preContingenciesDataComponent,
      contingenciesDataComponent,
      projectId,
      dataComponentId,
      "contingency",
      {
        performFindRequest,
        showSnackNotificationAction,
        closeModalDialog,
      }
    );

    processRequests(
      preDataComponent,
      dataComponent,
      projectId,
      dataComponentId,
      "project",
      {
        performFindRequest,
        showSnackNotificationAction,
        onUpdateSuccess: () => {
          this.context.setProject(project);
        },
      }
    );
  }

  patchProject = (data, formikActions) => {
    const { updateProject, project } = this.props;
    //We're sending updatedAt to trigger beforeUpdate for projectMemberContacts updates
    updateProject(project.id, { ...data, updatedAt: new Date() });
    this.setState({ formikActions });
  };

  parseProject = memoizeOne(srcProject => {
    const {
      projectMemberUsers = [],
      projectMemberContacts = [],
      areas = [],
      contingencies = [],
      addresses = [],
      contractFiles = [],
      sites = [],
      projectCurrencies = [],
      workScopes = [],
      ...project
    } = srcProject || {};
    return parseOnLoad({
      ...project,
      areas,
      contingencies,
      addresses,
      contractFiles,
      sites,
      projectCurrencies,
      workScopes,
      projectMemberUsers: projectMemberUsers.map(
        ({ memberType, userId, user: { name } }) => ({
          memberType,
          userId,
          name,
        })
      ),
      projectMemberContacts: projectMemberContacts.map(
        ({ memberType, contactId, contact: { firstName, lastName } }) => ({
          memberType,
          contactId,
          name: `${firstName} ${lastName}`,
        })
      ),
    });
  });

  render() {
    const {
      projectId,
      areaDataComponent,
      performCreateRequest,
      performDeleteRequest,
      performUpdateRequest,
      client,
      role,
    } = this.props;

    const project = this.parseProject(this.props.project);
    return (
      <LoaderContext.Provider value={{ loading: this.props.loading }}>
        <ProjectEditPage
          client={client}
          role={role}
          clientId={this.props.clientId}
          project={project}
          areaTypes={this.props.areaTypes}
          onSubmit={this.patchProject}
          workScopes={this.props.workScopes}
          currencies={this.props.currencies}
          onCreateArea={area =>
            onCreateArea(
              area,
              projectId,
              areaDataComponent,
              areasDataComponentId,
              performCreateRequest
            )
          }
          onDeleteArea={areaId =>
            onDeleteArea(
              areaId,
              areaDataComponent,
              areasDataComponentId,
              performDeleteRequest
            )
          }
          onUpdateArea={area =>
            onUpdateArea(
              area,
              areaDataComponent,
              areasDataComponentId,
              performUpdateRequest
            )
          }
        />
      </LoaderContext.Provider>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const dataComponent = getDataComponent(dataComponentId, state);
  const areaDataComponent = getDataComponent(areasDataComponentId, state);
  const shipToDataComponent = getDataComponent("DeleteSite", state);
  const contingenciesDataComponent = getDataComponent(
    "DeleteContingency",
    state
  );
  const clientDataComponent = getDataComponentFlattenedRequestState(
    dataClientComponentId,
    state,
    REQUEST_TYPES.FIND
  );

  const { projectId, clientId } = ownProps.match.params;
  const client = getBluechipResourceById(clientDataComponent, state, clientId);
  const project = getBluechipResourceById(dataComponent, state, projectId);
  const areaTypes =
    getCurrentBluechipResourcesForRequestType(
      areaTypesDataComponentId,
      state
    ) || [];
  const workScopes =
    getCurrentBluechipResourcesForRequestType("select-work-scopes", state) ||
    [];
  const currencies = getCurrentBluechipResourcesForRequestType(
    "select-currencies",
    state
  );

  const {
    auth: { role },
  } = state;

  return {
    dataComponent,
    areaDataComponent,
    shipToDataComponent,
    contingenciesDataComponent,
    project,
    areaTypes,
    projectId,
    clientId,
    client,
    nextNumber: _get(dataComponent, "requestState.list.next_number"),
    loading: !project,
    role,
    workScopes,
    currencies,
  };
};

const mapDispatchToProps = {
  initComponents,
  initDataComponent,
  setHeaderTitle,
  updateProject,
  setAutoSaveComponentId,
  performFindRequest,
  performCreateRequest,
  performDeleteRequest,
  performUpdateRequest,
  showSnackNotificationAction,
  closeModalDialog,
  destroyComponents,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withProjectNumber(ProjectEditContainer));
