import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Input } from "../../../../../forms/FormGrid";
import CheckboxWithError from "../../../../../inputs/CheckboxWithError";
import { Subtitle } from "../../../../../layout/AppLayout/ModalDialog/ModalTitle";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Label = styled.p`
  color: white;
`;

export const SubTitle = ({ isFundingPaidConfirmation }) => {
  const input = {
    name: "useTaxPayment",
    InputComponent: CheckboxWithError,
    label: <Label>Use Tax Payment</Label>,
    ignoreCaption: true,
    disabled: isFundingPaidConfirmation,
    style: {
      color: "white",
    },
  };

  return (
    <Wrapper
      style={{
        justifyContent: isFundingPaidConfirmation ? "right" : "space-between",
      }}
    >
      {isFundingPaidConfirmation ? null : <Subtitle>Cashflow Funding</Subtitle>}
      <Input input={input} />
    </Wrapper>
  );
};

SubTitle.propTypes = {
  isFundingPaidConfirmation: PropTypes.bool,
};
