import React, {
  createContext,
  memo,
  useContext,
  useRef,
  useState,
} from "react";
import _noop from "lodash/noop";

export const SelectedRowsAPIContext = createContext({
  selectedRows: [],
  updateSelectedRows: _noop,
  resetSelection: _noop,
  setDataComponentId: _noop,
});

export const useSelectedRowAPIContext = () =>
  useContext(SelectedRowsAPIContext);

function withSelectedRowsAPI(WrappedComponent) {
  const SelectedRowsWrapper = props => {
    const [selectedRows, setSelectedRows] = useState([]);
    const pageSelectionMap = useRef({});
    const selectedIdsRef = useRef();

    const updateSelectedRows = (rows, selectedIds, pageNumber) => {
      if (selectedIdsRef.current === selectedIds) {
        return;
      }
      selectedIdsRef.current = selectedIds;

      const selectedIdSet = new Set(selectedIds);

      pageSelectionMap.current[pageNumber] = (rows || []).filter(({ id }) =>
        selectedIdSet.has(id)
      );
      const selectedRows = Object.values(pageSelectionMap.current).reduce(
        (array, currentArray) => [...array, ...currentArray],
        []
      );
      setSelectedRows(selectedRows);
    };

    const resetSelection = () => setSelectedRows([]);

    const value = {
      selectedRows,
      updateSelectedRows,
      resetSelection,
    };

    return (
      <SelectedRowsAPIContext.Provider value={value}>
        <WrappedComponent
          {...props}
          selectedRows={selectedRows}
          resetSelection={resetSelection}
        />
      </SelectedRowsAPIContext.Provider>
    );
  };

  return memo(SelectedRowsWrapper);
}

export default withSelectedRowsAPI;
