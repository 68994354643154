import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { formSectionPropTypes } from "../../../layout/FormPageSection";
import TeamSectionForm from "./TeamSectionForm";

import {
  initAndRetrieveSelects,
  destroySelects,
  loadVendorContacts,
} from "../../../../actions/projectsTeamActions";
import { bindActionCreators } from "redux";
import {
  vendorContactsDCId,
  warehouseContactsDCId,
} from "../../../inputs/inputConfigs/projects";
import { openModalDialog } from "../../../../actions/layoutActions";

const handleVendorInputUpdate = (
  value,
  prevValue,
  dataComponentId,
  reloadFn
) => {
  if (value && prevValue !== value) {
    reloadFn(dataComponentId, value);
  }
};

export class TeamSectionContainer extends Component {
  static propTypes = {
    ...formSectionPropTypes,
    initAndRetrieveSelects: PropTypes.func.isRequired,
    destroySelects: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.destroySelects();
  }

  componentDidUpdate({
    client,
    values: { freightVendorId, warehouseVendorId },
  }) {
    if (this.props.client && client !== this.props.client) {
      this.props.initAndRetrieveSelects(this.props.client.scopeId);
    }

    handleVendorInputUpdate(
      this.props.values.freightVendorId,
      freightVendorId,
      vendorContactsDCId,
      this.props.loadVendorContacts
    );
    handleVendorInputUpdate(
      this.props.values.warehouseVendorId,
      warehouseVendorId,
      warehouseContactsDCId,
      this.props.loadVendorContacts
    );
  }

  render() {
    const { initAndRetrieveSelects, destroySelects, ...props } = this.props;
    return <TeamSectionForm {...props} />;
  }
}

export const mapDispatchToProps = (dispatch, { clientId }) => {
  return {
    ...bindActionCreators(
      {
        destroySelects,
        loadVendorContacts,
        openModalDialog,
      },
      dispatch
    ),
    initAndRetrieveSelects: scopeId =>
      dispatch(initAndRetrieveSelects(clientId, scopeId)),
  };
};

export default connect(null, mapDispatchToProps)(TeamSectionContainer);
