import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

const reducerMap = {
  [actionTypes.NOTE_INIT]: (state, { context = {}, filters = {} }) => ({
    ...initialState.notes,
    context: context,
    filters: {
      ...initialState.notes.filters,
      ...filters,
    },
  }),
  [actionTypes.NOTE_CLEAR]: () => ({
    ...initialState.notes,
  }),
  [actionTypes.NOTE_SET_FILTERS]: (state, { filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  }),
  [actionTypes.NOTE_CREATE_START]: state => ({
    ...state,
    noteId: null,
    isCreateActive: true,
  }),
  [actionTypes.NOTE_CREATE_DETAIL_CLOSE]: state => ({
    ...state,
    noteId: null,
    isCreateActive: false,
  }),
  [actionTypes.NOTE_DETAIL_OPEN]: (state, { noteId }) => ({
    ...state,
    isCreateActive: false,
    noteId,
  }),
  [actionTypes.NOTE_FETCH_RELATIONS_REQUEST]: state => ({
    ...state,
    isRelationsLoading: true,
  }),
  [actionTypes.NOTE_FETCH_RELATIONS_SUCCESS]: state => ({
    ...state,
    isRelationsLoading: false,
  }),
  default: state => state,
};

export default function(state = initialState.notes, action) {
  const { type, payload } = action;

  return (reducerMap[type] || reducerMap.default)(state, payload);
}
