import styled from "styled-components";
import MUIButton from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MUIListItemText from "@material-ui/core/ListItemText";

import MUICheckbox from "../../../../mui/core/Checkbox";

export const StyledTitle = styled.div`
  padding-left: 24px;
  display: flex;
  align-items: center;
`;

export const Button = styled(MUIButton)`
  text-transform: none;
`;

export const DropDownIcon = styled(ArrowDropDown)`
  color: rgba(0, 0, 0, 0.54);
  margin-left: 8px;
`;

export const Checkbox = styled(MUICheckbox)`
  padding: 4px 0;
`;

export const ListItemText = styled(MUIListItemText)`
  padding: 0;
`;
