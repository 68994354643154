const BWModels = require("benjaminwest-models");

function buildValidationSchema(roomColumns) {
  const Joi = BWModels.Joi;

  const roomIndexes = roomColumns.reduce((roomIndexes, column) => {
    const roomKeyNumber = Number(column.replace(/[^0-9]/gi, ""));
    if (!roomIndexes.includes(roomKeyNumber)) {
      roomIndexes.push(roomKeyNumber);
    }
    return roomIndexes;
  }, []);

  const rowSchema = Joi.object().keys(
    roomIndexes.reduce(
      (keys, index) => {
        return {
          ...keys,
          [`roomName${index}`]: Joi.string(),
          [`roomCount${index}`]: Joi.number()
            .empty("")
            .integer()
            .positive()
            .allow(0),
          [`roomPerRoom${index}`]: Joi.number()
            .empty("")
            .integer()
            .positive()
            .allow(0),
          [`roomPar${index}`]: Joi.number()
            .empty("")
            .positive()
            .allow(0),
        };
      },
      {
        customNumber: Joi.string().required(),
        areaId: Joi.selectFk().required(),
        specCategoryId: Joi.selectFk().required(),
        description: Joi.string()
          .max(255)
          .required(),
        priceCents: Joi.number().precision(2),
        baseQuantity: Joi.number().precision(2),
        atticStock: Joi.number().precision(2),
        overagePercent: Joi.number()
          .precision(2)
          .max(999.99),
        unitsPerCase: Joi.number().precision(2),
        unitOfMeasureId: Joi.selectFk(),
        unitsNeeded: Joi.number().precision(2),
        qcParBaseUnitsPerCase: Joi.number().precision(2),
      }
    )
  );

  return Joi.array().items(
    roomIndexes.reduce((schema, index) => {
      return schema
        .with(`roomName${index}`, [
          `roomCount${index}`,
          `roomPerRoom${index}`,
          `roomPar${index}`,
        ])
        .with(`roomCount${index}`, [
          `roomName${index}`,
          `roomPerRoom${index}`,
          `roomPar${index}`,
        ])
        .with(`roomPerRoom${index}`, [
          `roomName${index}`,
          `roomCount${index}`,
          `roomPar${index}`,
        ])
        .with(`roomPar${index}`, [
          `roomName${index}`,
          `roomCount${index}`,
          `roomPerRoom${index}`,
        ]);
    }, rowSchema)
  );
}

export default buildValidationSchema;
