import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";

import {
  openModalDialog,
  showSnackNotificationAction,
} from "../../../../actions/layoutActions";
import {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
} from "../../../../actions/dataComponentActions";
import propTypes from "../../../../constants/propTypes";
import ReportTemplates from "./ReportTemplates";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import {
  getBluechipResourcesByType,
  getResourcesV2,
} from "../../../../utils/bluechipUtils";
import { Paper } from "../../../mui/core";
import { LoaderContext } from "../../../ui/Loader";
import { useDeleteProcessRequests } from "../../../hooks/useProcessRequest";
import { SCOPES_SELECT } from "../../../../constants/DataComponents";

export const customReportDataComponentId = "dataComponentCustomReport";

export const ReportTemplatesContainer = ({
  dataComponent,
  flattenedDataComponent,
  loading,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
  scopes,
}) => {
  useDeleteProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("The template has been deleted successfully");
      setReload(customReportDataComponentId, true);
    },
    onError: () =>
      showSnackNotificationAction(
        "There was a problem while deleting the template"
      ),
  });

  const handleOpenCreateModal = useCallback(() => {
    openModalDialog(false, "CreateReportTemplate", {}, false, false, {
      width: "unset",
      layoutType: "wide",
    });
  }, [openModalDialog]);

  const handleOpenEditModal = useCallback(
    customReport => {
      openModalDialog(
        false,
        "CreateReportTemplate",
        { customReport },
        false,
        false,
        {
          width: "unset",
          layoutType: "wide",
        }
      );
    },
    [openModalDialog]
  );

  const handleDeleteTemplate = id => {
    performDeleteRequest(customReportDataComponentId, id);
  };

  return (
    <Paper>
      <LoaderContext.Provider value={loading}>
        <ReportTemplates
          dataComponent={flattenedDataComponent}
          onOpenCreateModal={handleOpenCreateModal}
          onOpenEditModal={handleOpenEditModal}
          handleDeleteTemplate={handleDeleteTemplate}
          scopes={scopes}
        />
      </LoaderContext.Provider>
    </Paper>
  );
};

ReportTemplatesContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  flattenedDataComponent: propTypes.dataComponent,
  loading: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  scopes: PropTypes.array,
};

export const mapStateToProps = state => {
  const flattenedDataComponent = getDataComponentFlattenedRequestState(
    customReportDataComponentId,
    state
  );

  const dataComponent = getDataComponent(customReportDataComponentId, state);

  const templates =
    getBluechipResourcesByType(customReportDataComponentId, state) || [];
  const scopesDataComponent = getDataComponentFlattenedRequestState(
    SCOPES_SELECT,
    state
  );
  return {
    dataComponent,
    flattenedDataComponent,
    loading: _get(flattenedDataComponent, "loading") || !templates,
    scopes: getResourcesV2(scopesDataComponent, state) || [],
  };
};

export const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  performDeleteRequest,
  setReload,
  showSnackNotificationAction,
  openModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportTemplatesContainer);
