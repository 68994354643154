import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ArchiveConfirmation from "./ArchiveConfirmation";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import * as RequestTypes from "../../../../../constants/RequestTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";

export const dataComponentId = "GLCodesGrid";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
  });
};

const ArchiveConfirmationContainer = ({
  dataComponent,
  closeModalDialog,
  setReload,
  performUpdateRequest,
  selectedIds,
  option = "archive",
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload
    );
  });

  const handleArchiveList = useCallback(() => {
    performUpdateRequest(
      dataComponentId,
      selectedIds.map(id => ({
        id,
        isArchived: option === "archive" ? true : false,
      }))
    );
  }, [option, performUpdateRequest, selectedIds]);

  return (
    <ArchiveConfirmation
      count={selectedIds.length}
      onSubmit={handleArchiveList}
      option={option}
    />
  );
};

ArchiveConfirmationContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  performUpdateRequest: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReload: PropTypes.func,
  option: PropTypes.string,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
    selectedIds: dataComponent.requestState[RequestTypes.LIST].selectedIds,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveConfirmationContainer);
