import React, { useRef } from "react";
import { AddCircle } from "@material-ui/icons";
import PropTypes from "prop-types";
import { FieldArray } from "formik";

import RenderedField from "./RenderedField";
import PageSection from "../../../../../layout/PageSection";
import propTypes from "../../../../../../constants/propTypes";

const name = "quoteFiles";

export const Grid = ({ values: { quoteStatus, ...values } }) => {
  const addFileRef = useRef();

  const rows = values[name];

  const quoteIsOpen = quoteStatus === "open";

  return (
    <PageSection
      headerText="Quote Files"
      actions={
        quoteIsOpen
          ? [
              {
                icon: <AddCircle />,
                text: "UpLoad File",
                handler: () => addFileRef.current.click(),
              },
            ]
          : []
      }
      noBorder
    >
      <FieldArray
        name={name}
        render={formikArrayHelpers => (
          <RenderedField
            formikArrayHelpers={formikArrayHelpers}
            rows={rows}
            quoteStatus={quoteStatus}
            addFileRef={addFileRef}
          />
        )}
      />
    </PageSection>
  );
};

Grid.propTypes = {
  values: PropTypes.shape({}),
  addFileRef: PropTypes.shape({}),
  client: propTypes.client,
};

export default Grid;
