import React from "react";
import styled from "styled-components";

import { AppBar, ListItemIcon, MenuItem } from "@material-ui/core";

export const headerHeight = "56px";

export const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    position: absolute;
    right: 0px;
    margin-right: 24px;
  }
`;

export const StyledAppBar = styled(({ useDarkBackground, ...props }) => (
  <AppBar {...props} />
))`
  z-index: 2;
  color: rgb(255, 255, 255);
  background-color: ${({ useDarkBackground }) =>
    useDarkBackground && "rgb(28, 61, 83)"};
  padding-right: 50px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.18);
  @media (max-width: 600px) {
    padding-right: 24px;
  }
`;

export const HeaderContentContainer = styled.div`
  display: flex;
  height: ${headerHeight};
  width: 100%;

  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const HeaderIconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -24px 0 auto;
`;

export const iconStyle = {
  fontSize: "24px",
  cursor: "pointer",
};

export const UserMenuIcon = styled(ListItemIcon)`
  font-size: 16px;
  margin-right: 16px;
`;

export const UserMenuItem = styled(MenuItem)`
  height: 10px;
  padding-right: 32px;
`;
