import { push } from "connected-react-router";

import PurchaseOrder from "../models/PurchaseOrder";

export function goToPurchaseOrderDetail(po) {
  return async (dispatch, getState) => {
    const { clientId, projectId } = getPOClientId(po, getState().resources);
    dispatch(
      push(
        `/clients/${clientId}/projects/${projectId}/purchase-orders/${po.id}`
      )
    );
  };
}

export const getPOClientId = (po, resources) => {
  let clientId = po?.project?.property?.entity?.clientId;
  if (!clientId) {
    const purchaseOrder = PurchaseOrder.query(resources).find(po.id);
    clientId = purchaseOrder
      .project()
      .property()
      .entity().clientId;
  }
  let projectId = po?.projectId;
  if (!projectId) {
    const purchaseOrder = PurchaseOrder.query(resources).find(po.id);
    projectId = purchaseOrder.projectId;
  }
  return { clientId, projectId };
};
