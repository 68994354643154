import { connect } from "formik";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";
import React from "react";

const PromptIfDirty = ({ withSubmitCount, formik: { dirty, submitCount } }) => {
  return (
    <Prompt
      when={withSubmitCount ? dirty && submitCount === 0 : dirty}
      message="You will lose any unsaved changes if you leave this page. Do you want to continue?"
    />
  );
};

PromptIfDirty.propTypes = {
  formik: PropTypes.shape({
    dirty: PropTypes.bool,
    submitCount: PropTypes.number,
  }),
  withSubmitCount: PropTypes.bool,
};

PromptIfDirty.defaultProps = {
  withSubmitCount: true,
};

export default connect(PromptIfDirty);
