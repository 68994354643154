import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Spec from "../../../../../models/Spec";
import ActionButtons from "../../../../forms/ActionButtons";
import { BWGridAPI, GridTable } from "../../../../ui/BWGrid";
import InputWrapper from "../../../../inputs/InputWrapper";
import {
  ButtonsWrapper,
  Message,
  Select,
  VendorWrapper,
  ModalWrapper,
} from "./components";
import cancelFields from "./cancelFields";
import propTypes from "../../../../../constants/propTypes";

const columns = [
  "customNumber",
  "description",
  "specCategory.name",
  "area.name",
];

const columnOptions = {
  customNumber: { title: "ID", bold: true },
  description: { width: "150px" },
  "specCategory.name": { width: "100px" },
  "area.name": { width: "80px" },
};

const { resolutions, vendors } = cancelFields;

const displayDangerButton = (resolution, vendorId) => {
  if (!resolution) return false;
  if (resolution !== "new_po") return true;
  return !!vendorId;
};

const CancelPOConfirmation = ({
  onSubmit,
  dataComponent,
  purchaseOrderId,
  title,
  resolution,
  vendorId,
  onChangeResolution,
  onChangeVendor,
}) => {
  const displayVendorSelect = resolution === "new_po";

  return (
    <Fragment>
      <Message>
        You are about to cancel PO# {title}. What should we do with the
        following specs?
      </Message>
      <Select>
        <InputWrapper
          {...resolutions}
          value={resolution}
          onChange={onChangeResolution}
        />
      </Select>
      {displayVendorSelect && (
        <VendorWrapper>
          <Message>
            A new purchase order will be generated with the selected vendor:
          </Message>
          <Select>
            <InputWrapper
              {...vendors}
              value={vendorId}
              onChange={onChangeVendor}
            />
          </Select>
        </VendorWrapper>
      )}
      <ModalWrapper>
        <BWGridAPI
          apiRoute="specs"
          dataComponent={dataComponent}
          model={Spec}
          includes={["specCategory", "area"]}
          apiFilters={{
            rootFilters: {
              $where: {
                purchaseOrderId,
              },
            },
            sort: [{ columnName: "customNumber", direction: "asc" }],
          }}
        >
          <GridTable columns={columns} columnOptions={columnOptions} />
        </BWGridAPI>
      </ModalWrapper>
      <ButtonsWrapper>
        <ActionButtons
          cancelButtonText="DISMISS"
          onDanger={displayDangerButton(resolution, vendorId) ? onSubmit : null}
          dangerButtonText="CONTINUE"
          isModal
          emphasizeCancel
        />
      </ButtonsWrapper>
    </Fragment>
  );
};

CancelPOConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChangeResolution: PropTypes.func.isRequired,
  onChangeVendor: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  vendorId: PropTypes.string,
  resolution: PropTypes.string,
  purchaseOrderId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent.isRequired,
};

export default CancelPOConfirmation;
