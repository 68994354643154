import React from "react";
import { COM_TYPE, SPEC_TYPE } from "../bidGroupVendorSpecUtils";
import EditableColumn from "../../../../ui/BWGrid/columns/EditableColumn";
import { cleanCurrencyValue } from "../../../../../utils/currencyFormatter";

export const handleCreateAlternateQuote = (
  data,
  bidGroupVendorSpec,
  gridRows,
  createAlternateQuote,
  dataComponentId,
  performCreateRequest,
  setSnackMessage
) => {
  const quoteItems = gridRows.map(row => {
    return {
      specId: row.specId,
      sampleQuantity: row.sampleQuantity,
      samplePricing: cleanCurrencyValue(row.samplePricing * 100),
      productionQuantity: row.productionQuantity,
      productionPricing: cleanCurrencyValue(row.productionPricing * 100),
    };
  });
  createAlternateQuote(
    bidGroupVendorSpec.bidGroupVendorSpecId,
    data.notes,
    quoteItems,
    dataComponentId,
    performCreateRequest,
    setSnackMessage
  );
};
export const generateGridRows = bidGroupVendorSpecs => {
  const rows = [];

  bidGroupVendorSpecs.map(bidGroupVendorSpec => {
    rows.push({
      id: bidGroupVendorSpec.id,
      specId: bidGroupVendorSpec.spec.id,
      customNumber: bidGroupVendorSpec.spec.customNumber,
      description: bidGroupVendorSpec.spec.description,
      unitOfMeasure: bidGroupVendorSpec.spec.unitOfMeasure,
      sampleQuantity: 1,
      samplePricing: undefined,
      productionQuantity: bidGroupVendorSpec.spec.totalQuantityWithoutOverage,
      productionPricing: undefined,
      type: SPEC_TYPE,
    });
    const { specDetails = [] } = bidGroupVendorSpec.spec;

    specDetails.map(specDetail => {
      const { specDetailComs = [] } = specDetail;

      specDetailComs.map(specDetailCom => {
        rows.push({
          specId: specDetailCom.spec.id,
          customNumber: specDetailCom.spec.customNumber,
          description: specDetailCom.spec.description,
          unitOfMeasure: specDetailCom.spec.unitOfMeasure,
          sampleQuantity: undefined,
          samplePricing: undefined,
          productionQuantity: undefined,
          productionPricing: undefined,
          type: COM_TYPE,
        });
      });
    });
  });

  return rows;
};
export const getQuantityEditColumn = row => {
  return (
    <EditableColumn placeholder="Qty">{row.productionQuantity}</EditableColumn>
  );
};
export const getSampleQuantityEditColumn = row => {
  if (row.sampleQuantity && row.sampleQuantity > 0) {
    return (
      <EditableColumn placeholder="Sample Qty">
        {row.sampleQuantity}
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Sample Qty" />;
};
export const getPricingEditColumn = (
  row,
  pricingField,
  placeHolder,
  currencyFormatter
) => {
  if (row[pricingField]) {
    return (
      <EditableColumn placeholder={placeHolder}>
        {currencyFormatter.format(row[pricingField])}
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder={placeHolder} />;
};
export const getInputName = (tableRow, column) => {
  if (!column) return;

  return `data[${tableRow.rowId - 1}].${column.name}`;
};
export const isEditable = (column, tableRow) => {
  const editableColumnsForCOM = ["sampleQuantity", "quantity"];
  const editableColumnsForSpec = ["samplePricing", "productionPricing"];

  if (tableRow.row.type === COM_TYPE) {
    return editableColumnsForCOM.includes(column.name);
  }

  return editableColumnsForSpec.includes(column.name);
};
