import styled from "styled-components";

export const Wrapper = styled.div`
  flex-flow: column;
  display: flex;
  height: calc(100vh - 48px);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 86%;
  flex: 1;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

export const DataSection = styled.div`
  border-bottom: inset;
`;

export const ColumnsSection = styled.div`
  display: flex;
  min-height: calc(100% - 250px);
  padding-bottom: 78px;
`;

export const ActionsSection = styled.div`
  border-top: outset;
  padding: 14px;
  position: absolute;
  background: white;
  width: 100%;
  bottom: 0;
`;

export const GroupWrapper = styled.div`
  padding: 10px;
  &:not(:last-child) {
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: inherit;
`;
