import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

const reducerMap = {
  [actionTypes.CREATE_QUEUE_REQUEST]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.CREATE_QUEUE_SUCCESS]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.CREATE_QUEUE_FAILURE]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.JOB_QUEUE_COMPLETED]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [actionTypes.INIT_QUEUE_JOB]: (state, { referenceId }) => ({
    ...state,
    [referenceId]: undefined,
  }),
  default: state => state,
};

export default function(state = initialState.notes, action) {
  const { type, payload } = action;

  return (reducerMap[type] || reducerMap.default)(state, payload);
}
