import { useCallback } from "react";
import _get from "lodash/get";

export const useCallbacks = (
  openModalDialog,
  setFieldValue,
  values,
  shipVendorAddress
) => {
  const { shipAddressId, shipToSite, projectId, shipVendorId } = values;
  const shipVendorAddressId = _get(
    shipVendorAddress,
    "id",
    shipVendorId ? "-1" : null
  );

  const handleSelectSite = useCallback(() => {
    openModalDialog(
      ["Shipping", "Select Location"],
      "SelectShipToSite",
      {
        setFieldValue,
        purchaseOrder: {
          shipAddressId,
          shipVendorId,
          shipVendorAddressId,
          shipToSite: typeof shipToSite === "boolean" ? shipToSite : true,
          projectId,
        },
      },
      false
    );
  }, [
    shipVendorId,
    openModalDialog,
    setFieldValue,
    shipAddressId,
    shipVendorAddressId,
    shipToSite,
    projectId,
  ]);

  const handleShowManagerRoutingInstructionsChange = useCallback(
    ({ target: { value } }) => {
      setFieldValue("showManagerRoutingInstructions", value);
    },
    [setFieldValue]
  );

  return [handleSelectSite, handleShowManagerRoutingInstructionsChange];
};
