import React from "react";
import styled from "styled-components";
import TableChartIcon from "@material-ui/icons/TableChart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";

const Wrapper = styled.div`
  font-size: 10px;
  position: relative;
`;

const Table = styled(TableChartIcon)``;

const Visibility = styled(VisibilityIcon)`
  position: absolute;
  left: 3px;
  width: 20px;
  height: 14px;
  top: 8px;
  background: rgb(255, 255, 255, 0.7);
`;

export const ViewIcon = () => {
  return (
    <Wrapper>
      <Table />
      <Visibility />
    </Wrapper>
  );
};

export const DateIcon = styled(AccessTimeIcon)`
  width: 16px;
`;
export const BooleanIcon = styled(CheckIcon)`
  width: 16px;
`;
export const TextIcon = () => <Wrapper>ABC</Wrapper>;
export const NumberIcon = () => <Wrapper>123</Wrapper>;
export const EnumIcon = () => <Wrapper>{"[123]"}</Wrapper>;
export const JsonIcon = () => <Wrapper>{"{ }"}</Wrapper>;
export const ArrayIcon = () => <Wrapper>{"[ ]"}</Wrapper>;
