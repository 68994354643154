import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _filter from "lodash/filter";
import _get from "lodash/get";

import Contacts from "../VendorInfo/Contacts";
import CardRight from "../CardRight";

import propTypes from "../../../../../constants/propTypes";

import Vendor from "../../../../../models/Vendor";
import memoizeOne from "memoize-one";

export const filterContacts = memoizeOne((projectContacts = []) => {
  projectContacts.filter(
    projectContact => projectContact.memberType === "Freight Vendor"
  );
});

const FreightInfo = ({
  initDataComponent,
  getVendor,
  purchaseOrderContacts,
  purchaseOrder,
  goToVendorProfile,
  vendorId,
  ...props
}) => {
  useEffect(() => {
    initDataComponent(
      "PODetailFreightVendor",
      Vendor,
      [],
      "vendors",
      undefined,
      "v2"
    );

    if (vendorId) {
      getVendor();
    }
  }, [getVendor, initDataComponent, vendorId]);

  const vendor = props.vendor || {};
  const type = "freight";
  const vendorContacts = _filter(purchaseOrderContacts, ["contactType", type]);

  return (
    <CardRight>
      <Contacts
        vendor={vendor}
        purchaseOrderContacts={purchaseOrderContacts}
        vendorContacts={vendorContacts || []}
        type="freight"
        onClickItem={goToVendorProfile}
        projectContacts={filterContacts(
          _get(purchaseOrder, "project.projectMemberContacts")
        )}
      />
    </CardRight>
  );
};

FreightInfo.propTypes = {
  purchaseOrderContacts: PropTypes.arrayOf(propTypes.purchaseOrderContact),
  purchaseOrder: propTypes.purchaseOrder,
  vendorId: PropTypes.string,
  vendor: PropTypes.shape({
    id: PropTypes.string,
  }),
  getVendor: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  goToVendorProfile: PropTypes.func.isRequired,
  poContactDataComponent: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

FreightInfo.defaultProps = {
  contacts: [],
  vendor: {},
  poContactDataComponent: {},
};

export default FreightInfo;
