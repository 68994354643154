import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "../mui/core/TextField";
import styled from "styled-components";
import _isNil from "lodash/isNil";
import _get from "lodash/get";
import { LabeledText } from "./LabeledText";
import { LabeledTextContext } from "../../withPORevision";
import useWindowDimensions from "../hooks/useWindowDimensions";

import InputLoader from "../ui/Loader/InputLoader";
import { getRequiredStyle } from "./SelectWithError/SingleAutoCompleteSelect/components";
import { removeControlCharacters } from "./utils";

export const StyledTextField = styled(TextField)`
  & .label {
    font-family: "Open Sans", sans-serif;
  }
  & .multiple-select {
    display: block;
    height: auto;
    min-height: 32px;
    white-space: break-spaces;
  }

  ${props => getRequiredStyle(props)}
`;

const parseLimit = (value, limitPrecision) => {
  if (!limitPrecision) {
    return value;
  }

  return Number(value.toFixed(limitPrecision));
};

const getLimit = ({ limit, value, message, limitPrecision }) =>
  `${value ? parseLimit(limit - value, limitPrecision) : limit} ${message}`;

const getLimitByType = (
  ignoreCaption,
  limitPrecision,
  limitType,
  limit,
  value
) => {
  if (ignoreCaption) return null;
  switch (limitType) {
    case "length":
      return getLimit({
        limit,
        value: _get(value, "length", 0),
        message: "Characters left",
      });
    case "value":
      return getLimit({
        limit,
        value: value,
        message: "Left",
        limitPrecision,
      });
    default:
      return " ";
  }
};

const DisabledInput = ({ label, value, labeledTextProps }) => {
  return (
    <LabeledText
      label={label}
      texts={[`${value ?? ""}${_get(labeledTextProps, "endAdornment", "")}`]}
    />
  );
};

DisabledInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeledTextProps: PropTypes.shape({}),
};

const getError = (error, errorMessage) => (error ? errorMessage : null);

const parseEvent = (evt, omitControlChars) => {
  const {
    target: { value, type, name, valueAsNumber, ...target },
    ...event
  } = evt;
  const parsedValue = removeControlCharacters(value, omitControlChars);
  const parsedEvent = {
    ...event,
    target: {
      ...target,
      type,
      name,
      valueAsNumber,
      value: parsedValue === "" ? null : parsedValue,
    },
  };
  return parsedEvent;
};

const createOnChange = (onChange, handleChange, omitControlChars) => evt => {
  const parsedEvent = parseEvent(evt, omitControlChars);
  onChange(parsedEvent);
  handleChange && handleChange(parsedEvent);
};

export const useLabeledText = ignoreLabeledText => {
  const labeledText = useContext(LabeledTextContext);
  return labeledText && !ignoreLabeledText;
};

export const shouldShrinkLabel = width => {
  return width <= 1490;
};

export const getShrinkLabelOption = width =>
  shouldShrinkLabel(width) ? { shrink: true } : {};

export const getHelperText = (
  ignoreCaption,
  helperText,
  error,
  errorMessage,
  limitHelperText
) => {
  const errorCaption = getError(error, errorMessage);
  if (ignoreCaption) {
    return errorCaption || null;
  }
  return errorCaption || helperText || limitHelperText || " ";
};

const handleBlur = ({
  setLocalValue,
  handler,
  onBlur,
  timerRef,
  omitControlChars,
}) => evt => {
  const {
    target: { value },
  } = parseEvent(evt, omitControlChars);
  if (onBlur) {
    const res = onBlur(evt);
    if (res && res.hasError) return;
  }
  setLocalValue(value);
  handler(evt);
  timerRef.current.isFlushed = true;
};

const handleDebouncedHandler = ({ timerRef, handler, evt, delay }) => {
  if (timerRef.current.id) clearTimeout(timerRef.current.id);
  timerRef.current.id = setTimeout(() => {
    if (timerRef.current.isFlushed) {
      return;
    }
    handler(evt);
  }, delay || 1500);
  timerRef.current.isFlushed = false;
};

const handleLocalChange = ({
  setLocalValue,
  avoidDebounce,
  avoidOnChange,
  handler,
  timerRef,
  delay,
  isValid,
  omitControlChars,
}) => evt => {
  const {
    target: { value },
  } = parseEvent(evt, omitControlChars);

  if (!isValid(value)) {
    return;
  }
  setLocalValue(value);

  if (avoidOnChange) {
    return;
  }

  if (avoidDebounce) {
    handler(evt);
    return;
  }

  handleDebouncedHandler({ timerRef, handler, evt, delay });
};

const TextInputWithError = ({
  name,
  label,
  limitPrecision,
  limitType,
  limit,
  placeholder,
  value,
  error,
  errorMessage,
  ignoreCaption,
  InputProps,
  helperText,
  options,
  submitData,
  onChange,
  handleChange,
  labeledTextProps,
  ignoreLabeledText,
  avoidDebounce,
  delay,
  onBlur,
  isDisabled,
  avoidOnChange,
  isValid = () => true,
  omitControlChars = true,
  ...inputProps
}) => {
  const [localValue, setLocalValue] = useState(value);
  const timerRef = useRef({});

  useEffect(() => setLocalValue(value), [value]);

  const { width } = useWindowDimensions();
  const limitHelperText = getLimitByType(
    ignoreCaption,
    limitPrecision,
    limitType,
    limit,
    value
  );

  const handler = useMemo(
    () => createOnChange(onChange, handleChange, omitControlChars),
    [onChange, handleChange, omitControlChars]
  );

  const isLabeledText = useLabeledText(ignoreLabeledText);

  if (isLabeledText) {
    return (
      <DisabledInput label={label} value={value} inputProps={inputProps} />
    );
  }

  return (
    <InputLoader ignoreCaption={ignoreCaption}>
      <StyledTextField
        {...{
          ...inputProps,
          name,
          label,
          placeholder,
          value: _isNil(localValue) ? "" : localValue,
          error,
          InputLabelProps: {
            ...getShrinkLabelOption(width),
            ...inputProps.InputLabelProps,
            required: false,
          },
          helperText: getHelperText(
            ignoreCaption,
            helperText,
            error,
            errorMessage,
            limitHelperText
          ),
          onChange: handleLocalChange({
            setLocalValue,
            avoidDebounce,
            avoidOnChange,
            handler,
            timerRef,
            delay,
            isValid,
            omitControlChars,
          }),
          onBlur: handleBlur({
            setLocalValue,
            handler,
            onBlur,
            timerRef,
            omitControlChars,
          }),
          InputProps,
        }}
      />
    </InputLoader>
  );
};

TextInputWithError.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  ignoreLabeledText: PropTypes.bool,
};

export default TextInputWithError;
