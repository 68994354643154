import React from "react";
import styled from "styled-components";
import Assignment from "@material-ui/icons/Assignment";

import SerifTitle from "../../../ui/Typography/SerifTitle";

const StateIcon = styled(Assignment)`
  font-size: 48px;
  margin-bottom: 16px;
`;

const Text = styled(SerifTitle)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  line-height: 28px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.24);
`;

const EmptyState = () => (
  <Wrapper>
    <StateIcon />
    <Text>No Note Selected</Text>
  </Wrapper>
);

export default EmptyState;
