import _omit from "lodash/omit";
import _omitBy from "lodash/omitBy";

const fieldsToOmit = [
  "id",
  "project",
  "area",
  "specCategory",
  "purchaseOrder",
  "shipments",
  "specDetailIdSequence",
  "overageValue",
  "totalQuantity",
  "shipmentTotalQty",
  "price",
  "totalPrice",
  "totalForecast",
  "createdAt",
  "updateAt",
  "isCommitted",
  "purchaseOrderId",
  "vendor",
  "awardedBidGroupVendorSpecQuoteId",
  "bidGroupVendorSpecQuote",
  "isAssignedToBidGroup",
  "projectCurrency",
  "unitOfMeasure",
  "statusComment",
  "bidGroups",
];

const specDetailFieldsToOmit = ["id", "specId", "createdAt", "updatedAt"];
const specDetailComFieldsToOmit = [
  "id",
  "spec",
  "specDetailId",
  "createdAt",
  "updatedAt",
];

const specRequirementFieldsToOmit = [
  "id",
  "spec",
  "specId",
  "fileId",
  "createdAt",
  "updatedAt",
  "specRequirementId",
  "requirementId",
];

const roomTypeFieldsToOmit = ["id", "specDetailId", "createdAt", "updatedAt"];

const cloneRequirements = requirements => {
  return requirements.map(specRequirement => {
    const newSpecRequirement = _omit(
      specRequirement,
      specRequirementFieldsToOmit
    );

    newSpecRequirement.requirement = { id: newSpecRequirement.requirement.id };

    newSpecRequirement.submittalFiles = newSpecRequirement.submittalFiles?.map(
      submittalFile => {
        const newSubmittalFile = _omit(
          submittalFile,
          specRequirementFieldsToOmit
        );

        newSubmittalFile.file = _omit(
          newSubmittalFile.file,
          specRequirementFieldsToOmit
        );

        return newSubmittalFile;
      }
    );

    return newSpecRequirement;
  });
};

const cloneSpecDetails = specDetails => {
  return specDetails.map(specDetail => {
    const newSpecDetail = _omit(specDetail, specDetailFieldsToOmit);

    if (newSpecDetail.specDetailComs) {
      newSpecDetail.specDetailComs = newSpecDetail.specDetailComs.map(
        specDetailCom => _omit(specDetailCom, specDetailComFieldsToOmit)
      );
    }

    if (newSpecDetail.roomTypes) {
      newSpecDetail.roomTypes = newSpecDetail.roomTypes.map(roomType =>
        _omit(roomType, roomTypeFieldsToOmit)
      );
    }

    return newSpecDetail;
  });
};

export const cloneSpec = spec => {
  const newSpec = _omitBy(_omit(spec, fieldsToOmit), (value, key) => {
    return value === null || /File/.test(key);
  });

  newSpec.description = `${newSpec.description} Copy`;
  newSpec.cloningOperation = true;
  newSpec.sourceSpecId = spec.id;

  if (newSpec.glCodes) {
    newSpec.glCodes = newSpec.glCodes.map(glCode => ({ id: glCode.id }));
  }

  if (newSpec.specRequirements) {
    newSpec.specRequirements = cloneRequirements(newSpec.specRequirements);
  }

  if (newSpec.specDetails) {
    newSpec.specDetails = cloneSpecDetails(newSpec.specDetails);
  }

  return newSpec;
};
