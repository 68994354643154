import styled from "styled-components";

import Paper from "../../../mui/core/Paper";
import SerifTitle from "../../../ui/Typography/SerifTitle";
import RegularText from "../../../ui/Typography/RegularText";
import React from "react";

const NEWS_ANIMATION_DURATION = "1s";
export const NEWS_ANIMATION_DURATION_MS = 1000;

export const Wrapper = styled(Paper)`
  padding: 24px;
  margin-bottom: 0;
  background-color: #27587a;
  color: #ffffff;
`;

export const OuterWrapper = styled(({ isVisible, ...props }) => (
  <div {...props} />
))`
  height: ${({ isVisible }) => (isVisible ? "170px" : "0")};
  transition: height ${NEWS_ANIMATION_DURATION} ease;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const HeadlineText = styled(SerifTitle)`
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
`;

export const HeadlineTextContainer = styled.div`
  margin-left: 16px;
`;

export const Link = styled(RegularText)`
  display: flex;
  color: #ffffff;
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
`;

export const DismissText = styled.span`
  margin-left: 8px;
`;

export const TimeText = styled(RegularText)`
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
`;

export const Body = styled.div`
  margin-left: 41px;
`;
