import { bindActionCreators } from "redux";

import {
  initDataComponent,
  performFindRequest,
} from "../../../../actions/dataComponentActions";
import { createSelector } from "reselect";
import { getActiveRequest } from "../../../../utils/dataComponentUtils";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";

import { fetchPurchaseOrderContacts } from "../../../../actions/purchaseOrdersContactActions";

export const companyContactsSelector = createSelector(
  ({ purchaseOrderContacts }) => purchaseOrderContacts,
  ({ contactType }) => contactType,
  (purchaseOrderContacts, contactType) =>
    purchaseOrderContacts.filter(
      contact => contact && contact.contactType === contactType
    )
);

export function genMapDispatchToProps({ dispatch, vendorId, vendorDC }) {
  return {
    getVendor: () => {
      return vendorId && dispatch(performFindRequest(vendorDC, vendorId));
    },
    ...bindActionCreators(
      { initDataComponent, fetchPurchaseOrderContacts },
      dispatch
    ),
  };
}

export function genMapStateToProps({
  state,
  vendorDC,
  contactsDC,
  vendorId,
  vendor,
  vendorSpec,
  purchaseOrderContacts,
  contactType,
}) {
  const vendorDataComponent = getDataComponentFlattenedRequestState(
    vendorDC,
    state,
    REQUEST_TYPES.FIND
  );
  const poContactDataComponent = getDataComponent(contactsDC, state);
  const filteredPurcharOrders = companyContactsSelector({
    contactType,
    purchaseOrderContacts: purchaseOrderContacts || [],
  });
  return {
    purchaseOrderContacts: filteredPurcharOrders,
    vendorId,
    vendor: vendorSpec
      ? vendor
      : getBluechipResourceById(vendorDataComponent, state, vendorId),
    poContactDataComponent,
    loading: !!getActiveRequest(poContactDataComponent),
  };
}
