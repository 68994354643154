import { BaseModel } from "@blue-chip/core";
import Program from "./Program";
import Brand from "./Brand";

export default class Flag extends BaseModel {
  static get belongsTo() {
    return [Brand];
  }
  static get hasMany() {
    return [Program];
  }
}
