import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  performFindRequest,
  initDataComponent,
} from "../../../../actions/dataComponentActions";
import { noteCreateDetailClose } from "../../../../actions/notesActions";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { getBluechipResourceById } from "../../../../utils/bluechipUtils";
import propTypes from "../../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../../constants/RequestTypes";
import Note from "../../../../models/Note";
import Project from "../../../../models/Project";
import NoteCreate from "../NoteCreate";
import EmptyState from "./EmptyState";
import NoteEdit from "../NoteEdit";
import { LoaderContext } from "../../../ui/Loader";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  background-color: #ffffff;
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

const dataComponentId = "NoteDetail";

export const NoteDetailsContainer = ({
  isCreateActive,
  noteId,
  performFindRequest,
  initDataComponent,
  note,
  project,
  noteCreateDetailClose,
  loading,
}) => {
  useEffect(() => () => noteCreateDetailClose(), [noteCreateDetailClose]);
  useEffect(() => {
    if (noteId) {
      initDataComponent(
        dataComponentId,
        Note,
        [
          "author",
          "tags",
          "purchaseOrders.vendor",
          "shipments",
          "specs",
          "invoices",
          "project.property.entity.client",
          "users",
        ],
        "notes"
      );
      performFindRequest(dataComponentId, noteId);
    }
  }, [noteId, performFindRequest, initDataComponent, noteCreateDetailClose]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <Wrapper>
        {isCreateActive ? (
          <NoteCreate />
        ) : noteId && note ? (
          <NoteEdit note={note} project={project} />
        ) : (
          <EmptyState />
        )}
      </Wrapper>
    </LoaderContext.Provider>
  );
};

NoteDetailsContainer.propTypes = {
  isCreateActive: PropTypes.bool.isRequired,
  noteId: PropTypes.string,
  performFindRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  noteCreateDetailClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  note: propTypes.note,
  project: propTypes.project,
};

const mapStateToProps = state => {
  const {
    notes: { isCreateActive, noteId },
  } = state;

  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state,
    REQUEST_TYPES.FIND
  );
  const note = getBluechipResourceById(dataComponent, state, noteId);
  return {
    isCreateActive,
    noteId,
    note,
    project: note ? Project.query(state.resources).find(note.projectId) : null,
    loading: dataComponent.loading,
  };
};

const mapDispatchToProps = {
  performFindRequest,
  initDataComponent,
  noteCreateDetailClose,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoteDetailsContainer);
