import React from "react";
import Joi from "joi-browser";
import BWModels from "benjaminwest-models";

import CreatableEmailInput from "./CreatableEmailInput";

export function isInvalidEmail(part) {
  const email = part.trim();
  const { error } = emailValidation.validate(email);
  return !!error;
}

export function isValidEmail(rawEmail) {
  const email = rawEmail.trim();
  const { error } = emailValidation.validate(email);
  return error;
}

export function validateEmail(value, prevError) {
  if (!value) return prevError;
  const error = value.split(",").some(isInvalidEmail);
  if (error) {
    const newError = prevError || getCustomJoiError();
    newError.details = [
      ...newError.details,
      {
        context: { v: value, key: "emailSentTo", label: "emailSentTo" },
        message: "emailSentTo is invalid",
        path: ["emailSentTo"],
        type: "string.email",
      },
    ];
    return newError;
  }
  return prevError;
}

export const customValidator = {
  validate(values, options) {
    const { error } = BWModels.loadSchema("Note").validate(values, options);
    return { error: validateEmail(values.emailSentTo, error) };
  },
};

const emailValidation = Joi.string().email({ minDomainAtoms: 2 });

function getRightErrorMessage(value, errorMessage) {
  if (!value) return errorMessage;
  const emailWithError = value.split(",").find(isInvalidEmail);
  if (!emailWithError) return errorMessage;
  return `${emailWithError} is not a valid email address`;
}

function getCustomJoiError() {
  const newErr = new Error("Custom Joi Error");
  newErr.isJoi = true;
  newErr.details = [];
  return newErr;
}

function EmailInput({ value, errorMessage, ...props }) {
  const customError = errorMessage
    ? getRightErrorMessage(value, errorMessage)
    : errorMessage;

  return (
    <CreatableEmailInput {...props} value={value} errorMessage={customError} />
  );
}

export default EmailInput;
