import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { StyledPaper } from "./components";
import propTypes from "../../../../constants/propTypes";
import SpecsGrid from "./SpecsGrid";
import withPORevision from "../../../../withPORevision";
import RowHeader from "./RowHeader";
export class PurchaseOrderListLayout extends PureComponent {
  state = { isCollapsed: true };

  render() {
    const {
      purchaseOrder,
      relatedPOs,
      refreshPurchaseOrderList,
      isClosedProject,
    } = this.props;
    const isCollapsed = this.state.isCollapsed;
    const setIsCollapsed = isCollapsed => this.setState({ isCollapsed });
    if (this.state.isCollapsed) {
      return (
        <RowHeader
          purchaseOrder={purchaseOrder}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      );
    }

    return (
      <StyledPaper>
        <SpecsGrid
          purchaseOrder={purchaseOrder}
          isClosedProject={isClosedProject}
          relatedPOs={relatedPOs}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          refreshPurchaseOrderList={refreshPurchaseOrderList}
        />
      </StyledPaper>
    );
  }
}

PurchaseOrderListLayout.propTypes = {
  purchaseOrder: propTypes.purchaseOrder.isRequired,
  relatedPOs: PropTypes.arrayOf(propTypes.purchaseOrder).isRequired,
  refreshPurchaseOrderList: PropTypes.func.isRequired,
  isClosedProject: PropTypes.bool,
};

export default withPORevision(PurchaseOrderListLayout);
