import React, { useCallback } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import _isNil from "lodash/isNil";

import {
  FormLabel,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
} from "../mui/core";

const RadioGroup = ({
  label,
  options,
  ignoreCaption,
  errorMessage,
  error,
  valueParser,
  onChangeParser,
  value,
  onChange,
  emptyValue,
  direction,
  formLabelProps,
  ...groupProps
}) => {
  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      onChange({ target: { name, value: onChangeParser(value) } });
    },
    [onChange, onChangeParser]
  );

  const parsedValue = _isNil(value) ? emptyValue : valueParser(value);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" {...formLabelProps}>
        {label}
      </FormLabel>
      <MUIRadioGroup
        {...groupProps}
        direction={direction}
        value={parsedValue}
        onChange={handleChange}
      >
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color="primary" />}
            label={label}
            direction={direction}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  );
};

RadioGroup.defaultProps = {
  valueParser: value => value.toString(),
  onChangeParser: value => (value === "" ? null : value),
  emptyValue: "",
  direction: "row",
};

RadioGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  valueParser: PropTypes.func,
  onChangeParser: PropTypes.func,
  emptyValue: PropTypes.string,
};

export default RadioGroup;
