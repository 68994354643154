import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";

import { EditableWrapper } from "../../../ui/BWGrid/components";
import { useIsProjectClosed } from "../../../hooks/useIsProjectClosed";

const EditIcon = styled(Edit)`
  && {
    color: #979797;
    font-size: 12px;
    width: 12px;
    height: 12px;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

const EditColumn = ({ label, onClick }) => {
  const isProjectClosed = useIsProjectClosed();
  return (
    <EditableWrapper onClick={onClick}>
      <span>{label}</span>
      {!isProjectClosed && <EditIcon />}
    </EditableWrapper>
  );
};

EditColumn.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default EditColumn;
