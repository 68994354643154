import { openModalDialog } from "./layoutActions";
import {
  fetchDataRequest,
  initDataComponent,
  performUpdateRequest,
  performCreateRequest,
} from "./dataComponentActions";
import * as ACTION_TYPES from "../constants/ActionTypes";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { uploadFile } from "./filesActions";
import User from "../models/User";

const dataComponentId = "userDataComponent";
export const resetPaswordDataComponentId = "resetPaswordDataComponentId";

export const openUserSettingsModal = ({
  password,
  office,
  team,
  userRole,
  memberType, //TODO: Remove this when BW-326 is merged
  ...user
}) => {
  return openModalDialog(
    `${user.name} Settings`,
    "UserSettings",
    { user, office, userRole },
    false,
    false,
    { width: "790px" }
  );
};

export const initUserComponent = (componentId = dataComponentId) => {
  return async dispatch => {
    dispatch(initDataComponent(componentId, User, [], "users"));
  };
};

export const updateUser = (id, user) => {
  return async dispatch => {
    return dispatch(performUpdateRequest(dataComponentId, id, user));
  };
};

export const resetPassword = data => {
  return async dispatch => {
    dispatch(
      initDataComponent(
        resetPaswordDataComponentId,
        User,
        [],
        "auth/reset-password"
      )
    );
    return dispatch(performCreateRequest(resetPaswordDataComponentId, data));
  };
};

export const uploadUserProfile = (
  { id: userId, profilePicture },
  fileId,
  file,
  metadata = {}
) => {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    let fileOptions = {
      s3Key: profilePicture && profilePicture.s3Key,
      metadata: {},
    };

    if (file) {
      const fileKey = `users/${userId}/profile-pictures`;
      const ext = file.name.split(".").pop();
      const filename = `${Date.now()}.${ext}`;
      await dispatch(
        uploadFile(
          {
            key: fileKey,
            filename,
          },
          file
        )
      );
      fileOptions = {
        filename: file.name,
        s3Key: `${fileKey}/${filename}`,
        metadata: {
          size: file.size,
          type: file.type,
        },
      };
    }

    dispatch(
      updateUser(userId, {
        profilePicture: {
          ...fileOptions,
          id: fileId,
          metadata: {
            ...fileOptions.metadata,
            ...metadata,
          },
        },
      })
    );
  };
};

export const updateNoteLastVisited = noteLastVisited => {
  return async dispatch => {
    return dispatch({
      type: ACTION_TYPES.UPDATE_NOTE_LAST_VISITED,
      payload: {
        noteLastVisited,
        hasUnreadNotes: false,
      },
    });
  };
};
