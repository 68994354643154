import React from "react";
import AddCircle from "@material-ui/icons/AddCircle";
import LibraryAdd from "@material-ui/icons/LibraryAdd";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BWGridAPI, GridHeader, GridTable } from "../../../ui/BWGrid";
import GLCode from "../../../../models/GLCode";
import Paper from "../../../mui/core/Paper";
import BulkActions from "./BulkActions";

const NotArchivedIcon = styled(RemoveIcon)`
  font-size: 14px;
  width: 14px;
  height: 14px;
  color: rgba(0, 0, 0, 0.87);
`;

const Status = styled.span`
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: right;
`;

const StatusColumn = ({ isArchived }) =>
  isArchived ? <Status>Archived</Status> : <NotArchivedIcon />;
StatusColumn.propTypes = {
  isArchived: PropTypes.bool,
};

const columns = ["code", "description", "isArchived"];
const columnOptions = {
  code: { title: "GL Codes", bold: true, filter: true },
  description: { fill: true, filter: true },
  isArchived: {
    title: "Status",
    render: StatusColumn,
  },
};

const getRowMenu = (
  onOpenEditModal,
  onOpenDuplicateModal,
  onOpenDeleteModal
) => {
  return [
    {
      text: "Edit",
      onClick: onOpenEditModal,
    },
    { text: "Duplicate", onClick: onOpenDuplicateModal },
    { text: "Delete", onClick: onOpenDeleteModal },
  ];
};

const GLCodePage = ({
  dataComponent,
  clientId,
  onOpenCreateModal,
  onOpenEditModal,
  onOpenDuplicateModal,
  onOpenDeleteModal,
  onOpenImportModal,
  onOpenBulkArchiveModal,
  onOpenBulkUnarchiveModal,
  onOpenBulkDeleteModal,
}) => {
  return (
    <Paper>
      <BWGridAPI
        dataComponent={dataComponent}
        model={GLCode}
        defaultSorting={[
          {
            columnName: "description",
            direction: "asc",
          },
        ]}
        apiRoute="gl-codes"
        showSelectAll
        showSelectionColumn
        rootFilters={{
          $where: {
            clientId,
          },
          displayArchived: true,
        }}
      >
        <GridHeader
          headerText="GL Codes"
          headerOverride={
            <BulkActions
              dataComponent={dataComponent}
              onBulkArchive={onOpenBulkArchiveModal}
              onBulkDelete={onOpenBulkDeleteModal}
              onBulkUnarchive={onOpenBulkUnarchiveModal}
            />
          }
          actions={[
            {
              text: "BULK UPLOAD",
              icon: <LibraryAdd />,
              handler: onOpenImportModal,
              keepTextExpanded: false,
            },
            {
              text: "ADD CODE",
              icon: <AddCircle />,
              handler: onOpenCreateModal,
              keepTextExpanded: false,
            },
          ]}
        />
        <GridTable
          columns={columns}
          columnOptions={columnOptions}
          rowMenu={getRowMenu(
            onOpenEditModal,
            onOpenDuplicateModal,
            onOpenDeleteModal
          )}
        />
      </BWGridAPI>
    </Paper>
  );
};
GLCodePage.propTypes = {
  clientId: PropTypes.string.isRequired,
  dataComponent: PropTypes.object.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDuplicateModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
  onOpenImportModal: PropTypes.func.isRequired,
  onOpenBulkArchiveModal: PropTypes.func.isRequired,
  onOpenBulkUnarchiveModal: PropTypes.func.isRequired,
  onOpenBulkDeleteModal: PropTypes.func.isRequired,
};
export default GLCodePage;
