import { appPageBuilder } from "./utils";
import Dashboard from "../components/pages/Dashboard";

const AppDashboard = appPageBuilder(Dashboard, "Dashboard");

export default [
  {
    path: "/dashboard",
    component: AppDashboard,
    exact: true,
  },
];
