import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { ActionButtons } from "../../../../forms";
import useActions from "../../../../hooks/useActions";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import {
  createSpecFromPDFDataComponentId,
  previewDataComponentId,
} from "../../../../../actions/specsActions";
import { LoaderContext } from "../../../../ui/Loader";

const Wrapper = styled.div`
  padding: 6px 0px 12px 24px;
`;

const Actions = ({ handleSubmit }) => {
  const { loading } = useContext(LoaderContext);

  const actions = useActions({
    closeModalDialog,
  });

  return (
    <Wrapper>
      <ActionButtons
        onCancel={actions.closeModalDialog}
        sendButtonText={"Save & Next"}
        hideCancelButton={false}
        onSend={handleSubmit}
        additionalProps={{
          cancel: { disabled: loading },
        }}
        listeners={[createSpecFromPDFDataComponentId, previewDataComponentId]}
      />
    </Wrapper>
  );
};
Actions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
export default Actions;
