import styled from "styled-components";
import RegularText from "../../../../ui/Typography/RegularText";

export const Message = styled(RegularText)`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
`;
