import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { processDeleteRequestStatus } from "../../../../../utils/dataComponentUtils";
import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import {
  performDeleteRequest,
  initDataComponent,
} from "../../../../../actions/dataComponentActions";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../../ui/DeleteConfirmation";
import Contingency from "../../../../../models/Contingency";

const dataComponentId = "DeleteContingency";

export class DeleteContingencyContainer extends Component {
  componentDidMount() {
    const { initDataComponent } = this.props;
    initDataComponent(dataComponentId, Contingency, [], "contingencies");
  }

  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent, closeModalDialog } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: () => closeModalDialog(),
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  handleDelete = () => {
    const {
      contingencyId,
      performDeleteRequest,
      index,
      formikArrayHelpers,
      closeModalDialog,
    } = this.props;
    if (contingencyId) {
      return performDeleteRequest(dataComponentId, contingencyId);
    }
    formikArrayHelpers.remove(index);
    closeModalDialog();
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.handleDelete} />;
  }
}

DeleteContingencyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  initDataComponent: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  dataComponent: PropTypes.object,
  contingencyId: PropTypes.string,
  formikArrayHelpers: PropTypes.shape({}),
  index: PropTypes.number,
};

export const mapStateToProps = state => ({
  dataComponent: getDataComponent(dataComponentId, state),
});

export const mapDispatchToProps = {
  closeModalDialog,
  performDeleteRequest,
  showSnackNotificationAction,
  initDataComponent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteContingencyContainer);
