import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { BWGridAPI, GridTable } from "../../../../ui/BWGrid";
import propTypes from "../../../../../constants/propTypes";
import Spec from "../../../../../models/Spec";
import {
  getDataComponent,
  getDataComponentFlattenedRequestState,
} from "../../../../../reducers/dataComponentReducer";
import { pushWithReturnUrl } from "../../../../../actions/navigationActions";
import { Paper } from "../../../../mui/core";
import {
  getBGAreasDataComponentId,
  getBGVendorsDataComponentId,
  refreshAreasList,
  refreshVendorsList,
} from "../../../../../actions/quotingActions";
import { getResourcesV2 } from "../../../../../utils/bluechipUtils";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";
import { BidGroupHeader } from "./BigGroupHeader";
import withSelectedRowsAPI from "../../../../ui/BWGrid/withSelectedRowsAPI";
import { useDeleteProcessRequests } from "../../../../hooks/useProcessRequest";
import { dataComponentId } from "../../UnassignedSpecs/UnassignedOnBidGroups/UnassignedSpecsContainer";
import { setReload } from "../../../../../actions/dataComponentActions";
import { columnOptions, columns } from "./gridOptions";

export const getSelectedProjectCurrencyId = selectedSpecs =>
  selectedSpecs.reduce((selectedProjectCurrencyId, { projectCurrencyId }) => {
    if (
      selectedProjectCurrencyId !== null &&
      selectedProjectCurrencyId !== projectCurrencyId
    ) {
      return null;
    }
    return projectCurrencyId;
  }, null);

export const SpecsGrid = ({
  specDataComponent,
  flattenedSpecDataComponent,
  bidGroup,
  bidGroups,
  isCollapsed,
  resetSelection,
  selectedRows,
  setIsCollapsed,
  onOpenCreateBidModal,
  areas,
  vendors,
  specCategories,
  clientId,
  projectId,
  refreshAreasList,
  refreshVendorsList,
  history,
  setReload,
  pushWithReturnUrl,
}) => {
  useEffect(() => {
    refreshAreasList(bidGroup.id);
    refreshVendorsList(bidGroup.id);
  }, [bidGroup.id, refreshAreasList, refreshVendorsList]);

  useDeleteProcessRequests(specDataComponent, {
    onSuccess: () => setReload(dataComponentId, true),
  });

  const handleOpenSpecDetail = ({ id }) => {
    pushWithReturnUrl(
      `/clients/${clientId}/projects/${projectId}/specs/${id}`,
      `BG #${bidGroup.number} - ${bidGroup.name}`,
      { navigateBetweenBG: bidGroup.id }
    );
  };
  const selectedIds = selectedRows.map(({ id }) => id);

  return (
    <Paper>
      <BidGroupHeader
        bidGroup={bidGroup}
        bidGroups={bidGroups}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        onOpenCreateBidModal={onOpenCreateBidModal}
        resetSelection={resetSelection}
        selectedIds={selectedIds}
        selectedProjectCurrencyId={getSelectedProjectCurrencyId(selectedRows)}
        pushWithReturnUrl={pushWithReturnUrl}
        history={history}
      />
      <BWGridAPI
        id={`bidGroup-${bidGroup.id}`}
        dataComponent={flattenedSpecDataComponent}
        model={Spec}
        includes={[
          "area",
          "vendor",
          "unitOfMeasure",
          "specCategory",
          "projectCurrency.currency",
        ]}
        apiRoute="specs"
        apiFilters={{
          rootFilters: {
            $where: {
              "bidGroups.id": bidGroup.id,
            },
          },
          params: {
            omitDefaultModifier: true,
            modifiers: ["withQuantityPrice"],
          },
        }}
        defaultSorting={[{ columnName: "customNumber", direction: "asc" }]}
        showSelectionColumn={true}
        showSelectAll={true}
        revisionReference="specs"
        alwaysDisplayDetailRow
        noBorder
      >
        <GridTable
          columns={columns}
          columnOptions={columnOptions({
            specCategories,
            areas,
            vendors,
          })}
          onClick={handleOpenSpecDetail}
        />
      </BWGridAPI>
    </Paper>
  );
};

SpecsGrid.propTypes = {
  specDataComponent: propTypes.dataComponent,
  flattenedSpecDataComponent: propTypes.dataComponent,
  bidGroup: propTypes.bidGroup,
  bidGroups: PropTypes.arrayOf(propTypes.bidGroup),
  isCollapsed: PropTypes.bool,
  resetSelection: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  setIsCollapsed: PropTypes.func.isRequired,
  onOpenCreateBidModal: PropTypes.func.isRequired,
  areas: PropTypes.array,
  vendors: PropTypes.array,
  specCategories: PropTypes.array,
  clientId: PropTypes.string,
  projectId: PropTypes.string,
  refreshAreasList: PropTypes.func.isRequired,
  refreshVendorsList: PropTypes.func.isRequired,
  pushWithReturnUrl: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export const specDetailDataComponentId = "bg-spec-details-id";

export const getDataComponentId = ({ id }) =>
  `${specDetailDataComponentId}-${id}`;
export const mapStateToProps = (state, { bidGroup, match }) => {
  const specDataComponent = getDataComponent(
    getDataComponentId(bidGroup),
    state
  );
  const flattenedSpecDataComponent = getDataComponentFlattenedRequestState(
    getDataComponentId(bidGroup),
    state
  );

  const areas = getResourcesV2(
    getDataComponent(getBGAreasDataComponentId(bidGroup.id), state),
    state,
    REQUEST_TYPES.LIST,
    []
  );

  const vendors = getResourcesV2(
    getDataComponent(getBGVendorsDataComponentId(bidGroup.id), state),
    state,
    REQUEST_TYPES.LIST,
    []
  );

  return {
    areas,
    vendors,
    specDataComponent,
    flattenedSpecDataComponent,
    clientId: match.params.clientId,
    projectId: match.params.projectId,
  };
};

const mapDispatchToProps = {
  pushWithReturnUrl,
  refreshAreasList,
  refreshVendorsList,
  setReload,
};

export default withSelectedRowsAPI(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecsGrid))
);
