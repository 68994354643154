import React, { Fragment, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";

import { inputs } from "../../../../components/inputs/inputConfigs";
import FormGrid from "../../../../components/forms/FormGrid";
import ActionButtons from "../../../../components/forms/ActionButtons";
import FormikForm from "../../../../components/forms/FormikForm";
import propTypes from "../../../../constants/propTypes";

const FormGridContainer = styled.div`
  padding-bottom: 20px;
`;

const FormTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  padding: 24px 24px 4px 24px;
`;

const WarningMessage = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #ff9100;
  padding: 0px 24px 4px 24px;
`;

const projectFields = (column, label, isRfqSentAt) => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.customDate(column, label),
            required: true,
            disablePast: !isRfqSentAt,
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});

const ProjectDateColumnForm = ({
  onSubmit,
  initialValues,
  column,
  label,
  isRfqSentAt = false,
}) => {
  const [loading, setLoading] = useState(false);
  const handleSubit = value => {
    setLoading(true);
    onSubmit(value);
  };

  return (
    <FormikForm
      initialValues={initialValues}
      onSubmit={handleSubit}
      validationSchema={BWModels.Joi.object({
        [column]: BWModels.Joi.date()
          .empty(null)
          .required(),
      })}
    >
      {({ handleSubmit, values, errors, ...formikProps }) => (
        <Fragment>
          <FormGridContainer>
            {!isRfqSentAt && (
              <FormTitle>{`Selecting a new date will override the previously ${label.toLowerCase()}s:`}</FormTitle>
            )}
            {!isRfqSentAt && (
              <WarningMessage>{`Only vendors with selected items will be modified.`}</WarningMessage>
            )}
            <FormGrid
              fields={projectFields(column, label, isRfqSentAt)}
              values={values}
              errors={errors}
              {...formikProps}
            />
          </FormGridContainer>

          <ActionButtons
            onSend={handleSubmit}
            isModal={true}
            loading={loading}
          />
        </Fragment>
      )}
    </FormikForm>
  );
};

ProjectDateColumnForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: propTypes.flag,
  column: PropTypes.string,
};

ProjectDateColumnForm.defaultProps = {
  label: "",
};

export default ProjectDateColumnForm;
