import React, { Fragment, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";

import { StyledMenuItem } from "../../../layout/AppLayout/DropdownMenu";
import RegularText from "../../../ui/Typography/RegularText";
import DropdownMenu from "../../../layout/AppLayout/DropdownMenuCustomHeader";
import DatePicker from "./DatePicker";
import { setNoteFilters } from "../../../../actions/notesActions";
import PropTypes from "prop-types";
import propTypes from "../../../../constants/propTypes";

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: 1450px) {
    width: unset;
  }
`;

const MenuItem = styled(StyledMenuItem)`
  padding: 12px 22px 12px 16px;
  .label {
    line-height: 20px;
  }
`;

const Value = styled(RegularText)`
  font-weight: bold;
  color: #57abff;
  margin-left: 8px;
  line-height: 24px;
`;

const options = [
  { content: "All Dates", unitOfTime: null, useSeparator: true },
  {
    content: "Today",
    unitOfTime: "day",
  },
  {
    content: "This Week",
    unitOfTime: "week",
  },
  { content: "This Month", unitOfTime: "month" },
  { content: "This Year", unitOfTime: "year" },
];

const generateOptions = (onUnitOfTimeChange, onCustomRangeSelect) => {
  return [
    ...options.map(({ unitOfTime, ...option }) => ({
      ...option,
      onClick: () => onUnitOfTimeChange(unitOfTime),
    })),
    {
      content: "Custom Range",
      onClick: onCustomRangeSelect,
    },
  ];
};

const getDropdownLabel = timeFilter =>
  timeFilter.isCustomRange
    ? "Custom Range"
    : options.find(option => option.unitOfTime === timeFilter.unitOfTime)
        .content;

export const TimeDropdown = ({ setNoteFilters, filters }) => {
  const [timeFilter, setTimeFilter] = useState({
    unitOfTime: null,
    isCustomRange: false,
  });

  const handleUnitOfTimeChange = unitOfTime => {
    setTimeFilter({
      unitOfTime,
      isCustomRange: false,
    });
    if (unitOfTime) {
      return setNoteFilters({
        timeBoundaries: {
          from: moment()
            .startOf(unitOfTime)
            .toDate(),
          to: moment()
            .endOf(unitOfTime)
            .toDate(),
        },
      });
    }
    setNoteFilters({ timeBoundaries: {} });
  };
  const handleSetCustomRange = () => {
    setTimeFilter({
      unitOfTime: null,
      isCustomRange: true,
    });
    setNoteFilters({
      timeBoundaries: {
        from: null,
        to: null,
      },
    });
  };

  const handleOnDateChange = ({ target: { value, name } }) => {
    const date =
      name === "from"
        ? moment(value)
            .startOf("day")
            .toDate()
        : moment(value)
            .endOf("day")
            .toDate();
    setNoteFilters({
      timeBoundaries: { ...filters.timeBoundaries, [name]: date },
    });
  };

  const dropdownOptions = generateOptions(
    handleUnitOfTimeChange,
    handleSetCustomRange
  );

  return (
    <Fragment>
      <DropdownMenu
        actionElem={<Value>{getDropdownLabel(timeFilter)}</Value>}
        options={dropdownOptions}
        components={{ MenuItem }}
      />
      {timeFilter.isCustomRange && (
        <DateWrapper>
          <DatePicker
            label="From"
            name="from"
            onChange={handleOnDateChange}
            value={filters.timeBoundaries.from}
          />
          <DatePicker
            label="To"
            name="to"
            onChange={handleOnDateChange}
            value={filters.timeBoundaries.to}
          />
        </DateWrapper>
      )}
    </Fragment>
  );
};
TimeDropdown.propTypes = {
  setNoteFilters: PropTypes.func.isRequired,
  filters: propTypes.notesFilters,
};

const mapStateToProps = ({ notes: { filters } }) => ({ filters });

const mapDispatchToProps = {
  setNoteFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeDropdown);
