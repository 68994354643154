import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BillFeeForm from "./BillFeeForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpsertRequestStatus } from "../../../../../utils/dataComponentUtils";
import { useDatacomponent } from "../../../../hooks/useDatacomponent";

const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  dataComponentId,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processUpsertRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const BillFeeContainer = props => {
  const {
    dataComponentId,
    closeModalDialog,
    setReload,
    fee,
    performUpdateRequest,
  } = props;
  const dataComponent = useDatacomponent(dataComponentId);
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      dataComponentId,
      closeModalDialog,
      setReload,
      formikActions
    );
  });

  const handleBillFee = useCallback(
    (
      { revenueSharingProject, workScope, project, feeSubScope, ...fee },
      formikActions
    ) => {
      performUpdateRequest(dataComponentId, fee.id, fee);
      setFormikAction(formikActions);
    },
    [dataComponentId, performUpdateRequest]
  );
  return (
    <BillFeeForm
      initialValues={{
        ...fee,
        invoiceReferenceNumber: "",
        billDate: new Date(),
        status: "Billed",
      }}
      onSubmit={handleBillFee}
      dataComponentId={dataComponent.dataComponentId}
    />
  );
};

BillFeeContainer.propTypes = {
  dataComponentId: PropTypes.string.isRequired,
  closeModalDialog: PropTypes.func,
  fee: propTypes.fee,
  performUpdateRequest: PropTypes.func,
  setReload: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

export default connect(null, mapDispatchToProps)(BillFeeContainer);
