import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import Assignment from "@material-ui/icons/Assignment";
import Email from "@material-ui/icons/Email";
import styled from "styled-components";
import { connect } from "react-redux";

import { iconStyle } from "./components";
import { IconButton } from "@material-ui/core";
export const AssignmentIcon = styled(Assignment)(iconStyle);
export const EmailIcon = styled(Email)(iconStyle);

const NoteIconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const NoteNotification = styled.div`
  width: 8px;
  height: 8px;
  top: 6px;
  right: 0px;
  border-radius: 4px;
  position: absolute;
  background: #ff1744;
`;

export const RawNoteIcon = ({ className, hasUnreadNotes }) => {
  return (
    <NoteIconWrapper>
      <AssignmentIcon className={className} />
      {hasUnreadNotes && <NoteNotification />}
    </NoteIconWrapper>
  );
};

RawNoteIcon.propTypes = {
  className: PropTypes.string,
  hasUnreadNotes: PropTypes.bool,
};

RawNoteIcon.defaultProps = {
  hasUnreadNotes: false,
};

const NoteIcon = ({ onClick, title, className, hasUnreadNotes }) => {
  return (
    <NoteIconWrapper>
      {title ? (
        <Tooltip title={title}>
          <IconButton onClick={onClick}>
            <AssignmentIcon className={className} />
          </IconButton>
        </Tooltip>
      ) : (
        <AssignmentIcon onClick={onClick} className={className} />
      )}
      {hasUnreadNotes && <NoteNotification />}
    </NoteIconWrapper>
  );
};

NoteIcon.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  hasUnreadNotes: PropTypes.bool,
};

NoteIcon.defaultProps = {
  title: "Notes",
  hasUnreadNotes: false,
};

const mapStateToProps = state => {
  const { hasUnreadNotes } = state.auth || {};
  return {
    hasUnreadNotes,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NoteIcon);
