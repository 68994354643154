import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { STATUS, StatusIndicator } from "./StatusIndicator";

export const StatusSpec = styled.div`
  display: flex;
  margin-top: 9px;
  flex-direction: row;
`;

const getStatus = (success, time, threshold) => {
  if (!success) {
    return STATUS.ERROR;
  }
  const isSlow = time > threshold;
  if (isSlow) {
    return STATUS.SLOW;
  }
  return STATUS.SUCCESS;
};

export const ServiceStatus = ({
  name,
  service,
  threshold = 500,
  isSuccess,
}) => {
  const { success, loading, data: { json, time } = {} } = useSelector(
    state => state.status[service]
  );

  const status = getStatus(
    isSuccess ? success && isSuccess(json) : success,
    time,
    threshold
  );

  return (
    <StatusSpec>
      <StatusIndicator
        loading={loading}
        status={status}
        service={typeof name === "function" ? name(json) : name}
      />
    </StatusSpec>
  );
};

ServiceStatus.propTypes = {
  name: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  service: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  isSuccess: PropTypes.func,
};
