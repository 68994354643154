import React, { useMemo } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";

import CardRight from "../../../PurchaseOrderDetail/ShippingContactSection/CardRight";
import VendorHeader from "../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo/VendorHeader";
import Contacts from "../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo/Contacts";
import propTypes from "../../../../../constants/propTypes";
import { RemitAddressMenu } from "./RemitAddressMenu/RemitAddressMenu";

const RemitAddressInfo = ({ invoice, updateInvoice }) => {
  const contacts = useMemo(
    () =>
      _get(invoice, "remitAddress.pointOfContacts", []).map(contact => {
        return {
          id: contact.id,
          vendorContact: contact,
        };
      }),
    [invoice]
  );

  return (
    <CardRight>
      <VendorHeader
        title="Remit Address Information"
        vendor={invoice.remitAddress}
        dataComponentId="InvoiceDetail"
        disabledOptions
      />
      <RemitAddressMenu
        vendor={_get(invoice, "purchaseOrder.vendor")}
        updateInvoice={updateInvoice}
      />
      <Contacts displayAddContact={false} vendorContacts={contacts} />
    </CardRight>
  );
};

RemitAddressInfo.propTypes = {
  invoice: propTypes.invoice,
  updateInvoice: PropTypes.func.isRequired,
};

export default RemitAddressInfo;
