import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Subheader from "../ui/Typography/Subheader";
import InputLabel from "../mui/core/InputLabel";
import InputLoader from "../ui/Loader/InputLoader";

export const Text = styled(Subheader)`
  color: rgba(0, 0, 0, 0.54);
  min-height: 24px;
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled(Subheader)`
  display: flex;
  justify-content: space-between;
  min-height: 30px;
`;

export const LabeledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${InputLabel} {
    width: 100%;
  }
`;

export const LabeledText = ({ label, texts }) => (
  <InputLoader>
    <LabeledTextWrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <TextWrapper>
        {texts.map((text, key) => (
          <Text key={key}>{text}</Text>
        ))}
      </TextWrapper>
    </LabeledTextWrapper>
  </InputLoader>
);

LabeledText.propTypes = {
  label: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.string, PropTypes.node, PropTypes.object),
};
