import styled from "styled-components";

import { getRequiredStyle } from "../../inputs/SelectWithError/SingleAutoCompleteSelect/components";

export default styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  line-height: 24px;

  ${({ required, emphasizeRequired }) => {
    if (required) {
      const style = getRequiredStyle({ required, emphasizeRequired });
      return `::after ${style.split(":after")[1]}`;
    }
  }}
`;
