const setRows = (cells, currentColumn, transposed) => {
  for (let row = 0; row < cells[currentColumn].length; row++) {
    if (!transposed[row]) {
      transposed[row] = [];
    }
    transposed[row][currentColumn] = cells[currentColumn][row];
  }
};

const transpose = cells => {
  const transposed = [];
  for (let column = 0; column < cells.length; column++) {
    if (!cells[column]) {
      continue;
    }
    setRows(cells, column, transposed);
  }
  return transposed;
};

const getNextCellInRow = (cells, currentRow, initialRow, initialColumn) => {
  let j = currentRow === initialRow ? initialColumn + 1 : 0;
  for (; j < cells[currentRow].length; j++) {
    if (cells[currentRow][j]) {
      return { rowId: currentRow, columnId: j };
    }
  }
};

export const getNextCell = (srcCells, rowId, columnId) => {
  const cells = transpose(srcCells);
  for (let i = rowId; i < cells.length; i++) {
    if (!cells[i]) {
      continue;
    }
    const nextCell = getNextCellInRow(cells, i, rowId, columnId);
    if (nextCell) return nextCell;
  }
  return {};
};

const getPrevCellInRow = (cells, currentRow, initialRow, initialColumn) => {
  let j =
    currentRow === initialRow
      ? initialColumn - 1
      : cells[currentRow].length - 1;
  for (; j >= 0; j--) {
    if (cells[currentRow][j]) {
      return { rowId: currentRow, columnId: j };
    }
  }
};

export const getPrevCell = (srcCells, rowId, columnId) => {
  const cells = transpose(srcCells);
  for (let i = rowId; i >= 0; i--) {
    if (!cells[i]) {
      continue;
    }
    const prevCell = getPrevCellInRow(cells, i, rowId, columnId);
    if (prevCell) return prevCell;
  }
  return {};
};

export default { getNextCell, getPrevCell };
