import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import propTypes from "../../../../constants/propTypes";
import News from "../../../../models/News";
import LatestNews from "./LatestNews";
import {
  initDataComponent,
  performRetrieveListRequest,
} from "../../../../actions/dataComponentActions";
import { getBluechipResources } from "../../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { NEWS_ANIMATION_DURATION_MS } from "./components";

const dataComponentId = "LatestNews";

export const LatestNewsContainer = ({
  latestNews,
  initDataComponent,
  performRetrieveListRequest,
  push,
  isFirstAccess,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [wasChecked, setWasChecked] = useState(false);
  const [previousNewsId, setPreviousNewsId] = useState(null);
  useEffect(() => {
    if (isVisible && !wasChecked && isFirstAccess) {
      setTimeout(
        () =>
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          }),
        NEWS_ANIMATION_DURATION_MS
      );
      setWasChecked(true);
    }
  }, [isVisible, wasChecked, isFirstAccess]);
  useEffect(() => {
    if (!latestNews && previousNewsId !== null) setPreviousNewsId(null);
    if (latestNews && latestNews.id !== previousNewsId) {
      setPreviousNewsId(latestNews.id);
    }
  }, [latestNews, previousNewsId]);
  useEffect(() => setVisible(previousNewsId !== null), [previousNewsId]);
  useEffect(() => {
    initDataComponent(dataComponentId, News, [], "news");
    performRetrieveListRequest(dataComponentId, {
      sort: [{ columnName: "createdAt", direction: "desc" }],
      pageSize: 1,
    });
  }, [initDataComponent, performRetrieveListRequest]);

  const handleDismiss = () => setVisible(false);

  const handleViewAll = () => push("/notes");

  return (
    <LatestNews
      latestNews={latestNews}
      isVisible={isVisible}
      onDismiss={handleDismiss}
      onViewAll={handleViewAll}
    />
  );
};

LatestNewsContainer.propTypes = {
  latestNews: propTypes.news,
  initDataComponent: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isFirstAccess: PropTypes.bool,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  const news = getBluechipResources(dataComponent, state) || [];
  return {
    latestNews: news[0],
    isFirstAccess: !state.router.location.search,
  };
};
const mapDispatchToProps = {
  initDataComponent,
  performRetrieveListRequest,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LatestNewsContainer);
