import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {
  BWGridLocal,
  GridRow,
  GridTable,
  withSelectedRows,
} from "../../../../ui/BWGrid";
import {
  StyledPaper,
  StyledEditIcon,
  EditLink,
  HeaderWrapper,
  Wrapper,
  AttributesWrapper,
  HeaderLeft,
  TitleWrapper,
  StyledIconButton,
  SubHeadingWrapper,
  ComItemText,
  ExpandActionWrapper,
  ExpandedIcon,
  StyledBidGroupTableActionsDiv,
} from "./components";
import propTypes from "../../../../../constants/propTypes";
import EditableColumn, {
  StyledEdit,
} from "../../../../ui/BWGrid/columns/EditableColumn";
import { WithSelectedRowsContext } from "../../../../ui/BWGrid/withSelectedRows";
import BoldText from "../../../../ui/Typography/BoldText";
import LightBoldText from "../../../../ui/Typography/LightBoldText";
import { editableCellCreator } from "./editableCellCreator";
import CurrencyInput from "../../../../inputs/CurrencyInput";
import Price from "../../../../ui/Price";
import TextInputWithError from "../../../../inputs/TextInputWithError";
import Loader from "../../../../ui/Loader";
import styled from "styled-components";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import {
  spreadBidGroupVendorSpecsWithComs,
  SPEC_TYPE,
  COM_TYPE,
} from "../bidGroupVendorSpecUtils";
import Summary from "./Summary";
import _get from "lodash/get";
import ChatBubble from "@material-ui/icons/ChatBubble";
import { Chip, IconButton, Tooltip } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import { BWActionButton } from "../../../../ui/BWActionButton";
import RegularText from "../../../../ui/Typography/RegularText";
import { BWSelectCell } from "../../../../ui/BWGrid/BWGrid";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import {
  bidGroupVendorSpecDataComponentId,
  quoteRowsAreEqual,
} from "../../../../../actions/bidGroupActions";
import { LabeledTextContext } from "../../../../../withPORevision";
import { PlaylistAddCheck } from "@material-ui/icons";
import { PONumber } from "../../../Specs/components";
import DropdownMenu from "../../../../layout/AppLayout/DropdownMenu";
import { inputs } from "../../../../inputs/inputConfigs";
import { cleanCurrencyValue } from "../../../../../utils/currencyFormatter";
import { StatusChip } from "./StatusChip";
import {
  getMarkBGVStatusAction,
  rfqSentStatusContent,
} from "../../Vendors/BidGroupVendors";
import { getOnUpdateDate } from "../../Vendors/Vendors";
import { DownloadQuoteFilesLink } from "./DownloadQuoteFiles/DownloadQuoteFilesLink";

const TYPES = [
  {
    id: SPEC_TYPE,
    name: SPEC_TYPE,
  },
  {
    id: COM_TYPE,
    name: COM_TYPE,
  },
];
const ActionButton = styled(props => (
  <BWActionButton disableRipple disableFocusRipple {...props} />
))`
  padding: 0;
  margin-left: 24px;
  margin-right: 16px;
  &:hover {
    background-color: unset;
  }
`;
export const HeaderInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const NoSpecAwardedMessage = styled(RegularText)`
  margin-right: 24px;
`;

export const defaultColumns = [
  "spec.customNumber",
  "spec.description",
  "type",
  "spec.unitOfMeasure.name",
  "productionQuantity",
  "overagePercent",
  "productionOverageQuantity",
  "allInclusive",
  "productionPricing",
  "extendedProductionPricing",
  "spec.purchaseOrder.id",
];
export const defaultColumnsWithSamplePricing = [
  "spec.customNumber",
  "spec.description",
  "type",
  "spec.unitOfMeasure.name",
  "sampleQuantity",
  "productionQuantity",
  "overagePercent",
  "sampleOverageQuantity",
  "productionOverageQuantity",
  "allInclusive",
  "samplePricing",
  "extendedSamplePricing",
  "productionPricing",
  "extendedProductionPricing",
  "spec.purchaseOrder.id",
];
export const getColumns = enableSampleQuantity => {
  if (enableSampleQuantity) {
    return defaultColumnsWithSamplePricing;
  }

  return defaultColumns;
};
export const isEditable = (column, tableRow, isLabeled) => {
  const editableColumnsForCOM = [
    "sampleQuantity",
    "productionQuantity",
    "overagePercent",
  ];
  const editableColumnsForSpec = [
    "samplePricing",
    "productionPricing",
    "overagePercent",
  ];
  if (isLabeled) return false;

  if (tableRow.row.type === COM_TYPE) {
    return editableColumnsForCOM.includes(column.name);
  }

  return editableColumnsForSpec.includes(column.name);
};
export const getDescriptionColumn = (
  row,
  bidGroupVendor,
  handleOpenAlternateQuotesModal
) => {
  const { spec = {} } = row;

  const awardedIcon = isAwardedSpec(row) ? (
    <PlaylistAddCheck
      style={{ color: "#83bd44", marginRight: "5px", fontSize: "medium" }}
    ></PlaylistAddCheck>
  ) : null;

  if (row.type === COM_TYPE) {
    return (
      <Wrapper>
        {awardedIcon}
        <ComItemText>{spec.description}</ComItemText>
      </Wrapper>
    );
  }
  if (row.notes && row.notes.length > 0) {
    return (
      <Wrapper>
        {awardedIcon}
        <BoldText>{spec.description}</BoldText>
        <StyledIconButton>
          <Tooltip title="Notes">
            <ChatBubble
              fontSize={"small"}
              onClick={() =>
                handleOpenAlternateQuotesModal(bidGroupVendor, row)
              }
            />
          </Tooltip>
        </StyledIconButton>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {awardedIcon}
      <BoldText>{spec.description}</BoldText>
    </Wrapper>
  );
};
const getTypeColumn = row => {
  if (row.type === COM_TYPE) {
    return <ComItemText>{row.type}</ComItemText>;
  }
  return <BoldText>{row.type}</BoldText>;
};
export const getAllInclusiveEditColumn = bidGroupVendor => {
  if (bidGroupVendor.bidType !== "All Inclusive") {
    return "";
  }

  return <CheckCircleIcon style={{ color: "#72B327" }} />;
};
const getSampleQuantityEditColumn = row => {
  if (row.sampleQuantity && row.sampleQuantity > 0) {
    return (
      <EditableColumn placeholder="Sample Qty">
        {row.sampleQuantity}
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Sample Qty" />;
};
const getSamplePricingEditColumn = (row, currency) => {
  if (typeof row.samplePricing == "number") {
    return (
      <EditableColumn placeholder="Sample Pricing">
        <Price number={row.samplePricing} currency={currency} />
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Sample Pricing" />;
};
const getQuantityEditColumn = row => {
  if (row.productionQuantity && row.productionQuantity > 0) {
    return (
      <EditableColumn placeholder="Qty per each">
        {row.productionQuantity}
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Qty per each" />;
};
const getOveragePercentEditColumn = overagePercent => {
  return (
    <EditableColumn placeholder="Overage">{overagePercent}</EditableColumn>
  );
};
const getProductionPricingEditColumn = (row, currency) => {
  if (typeof row.productionPricing == "number") {
    return (
      <EditableColumn placeholder="Production Pricing">
        <Price number={row.productionPricing} currency={currency} />
      </EditableColumn>
    );
  }
  return <EditableColumn placeholder="Production Pricing" />;
};
const enableAllInclusive = row => {
  return !(row.type === COM_TYPE || !row.coms || row.coms.length === 0);
};
export const renderCustomNumberRow = row => {
  const { spec = {} } = row;

  if (row.type === "COM") {
    return <LightBoldText>{spec.customNumber}</LightBoldText>;
  }
  return (
    <BoldText>
      {row.sequenceIndex > 1 ? `#${row.sequenceIndex}` : `${spec.customNumber}`}
    </BoldText>
  );
};
// eslint-disable-next-line max-lines-per-function
export const columnOptions = (
  bidGroupVendor,
  handleOpenAlternateQuotesModal,
  gridRows,
  setGridRows,
  onGoToPO,
  readOnly
) => {
  const projectCurrency = bidGroupVendor?.quoteProjectCurrency;
  const currency = projectCurrency?.currency;
  return {
    "spec.customNumber": {
      title: "Number",
      filter: true,
      sortingEnabled: false,
      render: row =>
        renderCustomNumberRow(
          row,
          bidGroupVendor,
          handleOpenAlternateQuotesModal
        ),
    },
    "spec.description": {
      title: "Description",
      filter: true,
      sortingEnabled: false,
      fill: true,
      render: row =>
        getDescriptionColumn(
          row,
          bidGroupVendor,
          handleOpenAlternateQuotesModal
        ),
    },
    type: {
      title: "Type",
      width: "200px",
      filter: "select",
      sortingEnabled: false,
      filterOptions: {
        options: TYPES,
      },
      render: row => getTypeColumn(row),
    },
    allInclusive: {
      title: "All Inclusive of COM",
      editable: !readOnly,
      wordWrapEnabled: true,
      align: "center",
      sortingEnabled: false,
      render: row => {
        if (!enableAllInclusive(row)) {
          return;
        }

        return getAllInclusiveEditColumn(bidGroupVendor);
      },
    },
    "spec.unitOfMeasure.name": {
      title: "UOM",
      sortingEnabled: false,
    },
    sampleQuantity: {
      title: "Sample Qty",
      editable: !readOnly,
      sortingEnabled: false,
      wordWrapEnabled: true,
      align: "right",
      render: row => {
        if (readOnly || row.type !== COM_TYPE) return row.sampleQuantity;
        return getSampleQuantityEditColumn(row);
      },
      editableOptions: {
        beforeSave: updateRow(gridRows, setGridRows),
      },
    },
    samplePricing: {
      title: "Sample Pricing",
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      align: "right",
      render: row => {
        if (row.type === COM_TYPE) return;
        if (readOnly)
          return <Price number={row.samplePricing} currency={currency} />;
        return getSamplePricingEditColumn(row, currency);
      },
      editableOptions: {
        beforeSave: buildBeforeSave(gridRows, setGridRows),
      },
    },
    extendedSamplePricing: {
      title: "Ext. Sample Price",
      wordWrapEnabled: true,
      sortingEnabled: false,
      align: "right",
      render: row => {
        if (row.type === COM_TYPE) return;
        return (
          row && (
            <Price number={row.extendedSamplePricing} currency={currency} />
          )
        );
      },
    },
    productionQuantity: {
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      title: "Qty per each",
      align: "right",
      render: row => {
        if (readOnly || row.type !== COM_TYPE) return row.productionQuantity;
        return getQuantityEditColumn(row);
      },
      editableOptions: {
        beforeSave: updateRow(gridRows, setGridRows),
      },
    },
    overagePercent: {
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      title: "Overage",
      align: "right",
      render: row => {
        const overagePercentValue = row.overagePercent || 0;
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
        });
        const overagePercent = `${formatter.format(overagePercentValue)}%`;
        if (readOnly) return overagePercent;
        return getOveragePercentEditColumn(overagePercent);
      },
      editableOptions: {
        beforeSave: updateRow(gridRows, setGridRows),
      },
    },
    sampleOverageQuantity: {
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      title: "Sample Overage Qty.",
      align: "right",
      render: row => {
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
        });

        const overagePercent = row.overagePercent || 0;
        const sampleQuantity = row.sampleQuantity || 0;
        return formatter.format((overagePercent / 100) * sampleQuantity);
      },
    },
    productionOverageQuantity: {
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      title: "Production Overage Qty.",
      align: "right",
      render: row => {
        const formatter = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
        });

        const overagePercent = row.overagePercent || 0;
        const productionQuantity = row.productionQuantity || 0;
        return formatter.format((overagePercent / 100) * productionQuantity);
      },
    },
    productionPricing: {
      title: "Production Pricing",
      editable: !readOnly,
      wordWrapEnabled: true,
      sortingEnabled: false,
      align: "right",
      render: row => {
        if (row.type === COM_TYPE) return;
        if (readOnly)
          return <Price number={row.productionPricing} currency={currency} />;
        return getProductionPricingEditColumn(row, currency);
      },
      editableOptions: {
        beforeSave: buildBeforeSave(gridRows, setGridRows),
      },
    },
    extendedProductionPricing: {
      title: "Ext. Production Price",
      wordWrapEnabled: true,
      sortingEnabled: false,
      align: "right",
      render: row => {
        if (row.type === COM_TYPE) return;
        return (
          row && (
            <Price number={row.extendedProductionPricing} currency={currency} />
          )
        );
      },
    },
    "spec.purchaseOrder.id": {
      title: "PO Number",
      render: ({ spec }) => PONumber(onGoToPO, readOnly)(spec),
      sortingEnabled: false,
    },
  };
};
export const rowInputs = bidGroupVendor => {
  const isDraft = bidGroupVendor.isDraft;
  const currency = bidGroupVendor?.quoteProjectCurrency?.currency;
  if (isDraft) return {};
  return {
    allInclusive: {
      InputComponent: CheckboxWithError,
      label: "All Inclusive of COM",
      name: "allInclusive",
      InputProps: {
        endAdornment: <StyledEdit />,
      },
      FloatInputProps: {
        topMargin: "0px",
      },
    },
    sampleQuantity: {
      InputComponent: TextInputWithError,
      type: "number",
      name: "sampleQuantity",
      fullWidth: true,
      InputProps: {
        endAdornment: <StyledEdit />,
      },
    },
    samplePricing: {
      InputComponent: TextInputWithError,
      name: "samplePricing",
      fullWidth: true,
      InputProps: {
        inputComponent: CurrencyInput,
        endAdornment: <StyledEdit />,
      },
      inputProps: {
        currency: currency,
        allowNegative: true,
      },
    },
    productionQuantity: {
      InputComponent: TextInputWithError,
      type: "number",
      name: "productionQuantity",
      fullWidth: true,
      InputProps: {
        endAdornment: <StyledEdit />,
      },
    },
    productionPricing: {
      InputComponent: TextInputWithError,
      name: "productionPricing",
      fullWidth: true,
      InputProps: {
        inputComponent: CurrencyInput,
        endAdornment: <StyledEdit />,
      },
      inputProps: {
        currency: currency,
        allowNegative: true,
      },
    },
    overagePercent: {
      ...inputs.overagePercent,
    },
  };
};
const quoteDetailRequiredFields = [
  "fob",
  "quoteProjectCurrencyId",
  "leadTimesFrom",
  "leadTimesTo",
  "leadTimeForSubmittal",
  "factoryLocation",
  "paymentTermId",
];

const isDisabledRow = row => {
  return row.type === COM_TYPE || !isPricingValid(row);
};

export const createSelectionCell = props => {
  const { row, selected, onToggle } = props;

  const isDisabled = isDisabledRow(row);

  if (selected && isDisabled) {
    onToggle(row, props);
  }

  if (row.type === COM_TYPE) {
    return <Table.Cell />;
  }

  return row && <BWSelectCell {...props} disabled={isDisabled} />;
};

export const getVendorCategory = (vendorCategories, vendorCategoryId) => {
  if (!vendorCategories) {
    return "";
  }

  const vendorCategory = vendorCategories.find(
    vendorCategory => vendorCategory.id === vendorCategoryId
  );

  if (!vendorCategory) {
    return "";
  }

  return vendorCategory.name;
};
export const areQuoteDetailsComplete = quote => {
  return quoteDetailRequiredFields.reduce((accum, field) => {
    return accum && _get(quote, field, null) !== null;
  }, true);
};

const isAssignedToIssuedPO = purchaseOrder =>
  purchaseOrder ? purchaseOrder.status === "Issued" : false;

export const canCreatePurchaseOrder = (bidGroupVendor, row) => {
  if (!isPricingValid(row)) {
    return false;
  }
  const {
    coms = [],
    spec: { purchaseOrder },
  } = row;

  const assignedToIssuedPO = isAssignedToIssuedPO(purchaseOrder);

  const canCreate = coms.map(com => {
    return com.sampleQuantity > 0 || com.productionQuantity > 0;
  });

  return [
    isAwardedSpec(row),
    areQuoteDetailsComplete(bidGroupVendor),
    canCreate.every(value => value === true),
    !assignedToIssuedPO,
  ].every(value => value === true);
};
const parseCurrencyValue = value =>
  typeof value == "number" ? value / 100 : value;
const isNumber = value => typeof value == "number";
export const isPricingValid = row => {
  return (
    isNumber(row.productionPricing) ||
    (row.sampleQuantity > 0 && isNumber(row.samplePricing))
  );
};
export const isAwardedSpec = row => {
  return row.spec.awardedBidGroupVendorSpecQuoteId === parseInt(row.quoteId);
};
export const hasAwardedSpec = gridRows => {
  return gridRows.reduce((accum, row) => {
    return accum || isAwardedSpec(row);
  }, false);
};
export const canCreateAlternateQuote = row => {
  return row.sequenceIndex === 1;
};
export const onCreateAlternateQuote = (
  bidGroupVendorSpecId,
  bidGroupVendor,
  quotes,
  createAlternateQuote
) => {
  createAlternateQuote(bidGroupVendorSpecId, bidGroupVendor, quotes);
};
export const createOnePurchaseOrder = (
  bidGroupVendor,
  bidGroupVendorSpec,
  addToPurchaseOrder
) => {
  addToPurchaseOrder(bidGroupVendor, [bidGroupVendorSpec]);
};
export const bulkAddToPurchaseOrder = (
  bidGroupVendor,
  selectedIds,
  addToPurchaseOrder,
  gridRows
) => {
  if (selectedIds.length === 0) {
    return;
  }

  const specs = selectedIds.map(selectedId => {
    return gridRows.find(gridRow => gridRow.id === selectedId);
  });

  addToPurchaseOrder(bidGroupVendor, specs);
};
export const setField = updateBidGroupVendorSpecQuotes => {
  return (row, columnName, value) =>
    updateBidGroupVendorSpecQuotes(row, columnName, value);
};

export const updateRow = (rows, setGridRows) => {
  // eslint-disable-next-line
  return (value, columnName, row) => {
    setGridRows(
      rows.map(currentRow => {
        if (quoteRowsAreEqual(row, currentRow)) {
          const newRow = { ...row, [columnName]: value };
          newRow.extendedProductionPricing =
            newRow.productionPricing * newRow.productionQuantity;
          newRow.extendedSamplePricing =
            newRow.samplePricing * newRow.sampleQuantity;
          if (columnName === "samplePricing") {
            newRow.samplePricing = value;
            newRow.extendedSamplePricing = value * newRow.sampleQuantity;
          }
          if (columnName === "productionPricing") {
            newRow.productionPricing = value;
            newRow.extendedProductionPricing =
              value * newRow.productionQuantity;
          }
          return newRow;
        }
        return currentRow;
      })
    );
    return value;
  };
};

export const buildBeforeSave = (rows, setGridRows) => {
  return (value, columnName, row) => {
    updateRow(rows, setGridRows)(value, columnName, row);
    return isNumber(value) ? cleanCurrencyValue(value * 100) : value;
  };
};

export const getRowMenu = (
  bidGroupVendor,
  row,
  createAlternateQuote,
  goToSpecDetail,
  isAwardedSpec,
  canCreateAlternateQuote,
  openAwardSpecModal,
  openUnawardSpecModal,
  handleOpenQuoteNotesModal,
  bidGroupVendorRef,
  actions
) => {
  if (row.type === COM_TYPE) {
    return;
  }
  const menuItems = [];
  if (isAwardedSpec(row)) {
    menuItems.push({
      text: "Un-award Spec",
      onClick: bidGroupVendorSpec => {
        openUnawardSpecModal(
          bidGroupVendor,
          [bidGroupVendorSpec],
          actions.updateSpecOnBidGroupVendor,
          bidGroupVendorRef
        );
      },
      disableIfProjectClosed: true,
    });
  } else {
    menuItems.push({
      text: "Award Spec",
      onClick: bidGroupVendorSpec => {
        openAwardSpecModal(
          bidGroupVendor,
          [bidGroupVendorSpec],
          actions.updateSpecOnBidGroupVendor,
          bidGroupVendorRef
        );
      },
      disabled: !isPricingValid(row),
      disableIfProjectClosed: true,
    });
  }
  menuItems.push({
    text: "Create Alternate Quote",
    onClick: row => {
      onCreateAlternateQuote(row, bidGroupVendor, [], createAlternateQuote);
    },
    disabled: !canCreateAlternateQuote(row),
    disableIfProjectClosed: true,
  });
  menuItems.push({
    text: "Review Spec Detail",
    onClick: spec => {
      goToSpecDetail(spec);
    },
  });
  menuItems.push({
    text: "Spec Notes",
    onClick: row => {
      handleOpenQuoteNotesModal(bidGroupVendor, row);
    },
  });
  return menuItems;
};

export const getActionButton = (
  bidGroupVendor,
  selectedIds,
  addToPurchaseOrder,
  hasAwardedSpecs,
  gridRows,
  loading,
  isClosedProject
) => {
  const totalAwardedSpecs = gridRows.reduce(
    (accum, row) => accum + (isAwardedSpec(row) ? 1 : 0),
    0
  );

  const specLabel = pluralize("Spec", totalAwardedSpecs, true);

  const awardedSpecChip = (
    <Chip
      icon={<PlaylistAddCheck style={{ color: "white" }}></PlaylistAddCheck>}
      label={`${specLabel} Awarded`}
      style={{
        backgroundColor: "#72b327",
        color: "white",
        marginRight: "10px",
      }}
    ></Chip>
  );

  const selectedRows = gridRows.filter(row => selectedIds.includes(row.id));
  const canCreatePO = selectedRows.every(row =>
    canCreatePurchaseOrder(bidGroupVendor, row)
  );

  if (canCreatePO && selectedIds.length > 0 && !isClosedProject) {
    return (
      <React.Fragment>
        {awardedSpecChip}
        <ActionButton
          icon={<AddCircle />}
          text="ADD TO PURCHASE ORDER"
          disableIfProjectClosed
          onClick={() =>
            bulkAddToPurchaseOrder(
              bidGroupVendor,
              selectedIds,
              addToPurchaseOrder,
              gridRows
            )
          }
        />
      </React.Fragment>
    );
  } else if (hasAwardedSpecs()) {
    return (
      <Loader height="30px" width="150px" loading={loading}>
        {awardedSpecChip}
        <NoSpecAwardedMessage>Select an Awarded Spec</NoSpecAwardedMessage>
      </Loader>
    );
  }
  return (
    <Loader height="30px" width="150px" loading={loading}>
      <NoSpecAwardedMessage>No Spec Awarded Yet</NoSpecAwardedMessage>
    </Loader>
  );
};
export const getSortedRows = rows => {
  const sortedSpecs = [];
  const parentSpecs = rows.filter(row => row.type === SPEC_TYPE);
  const comSpecs = rows.filter(row => row.type === COM_TYPE);

  const sortedParentSpecs = parentSpecs.sort((rowA, rowB) => {
    if (rowA.specId === rowB.specId) {
      return rowA.sequenceIndex < rowB.sequenceIndex ? -1 : 1;
    }

    return rowA.spec.customNumber.localeCompare(
      rowB.spec.customNumber,
      undefined,
      {
        numeric: true,
        sensitivity: "base",
      }
    );
  });
  sortedParentSpecs.forEach(parentSpec => {
    const specs = [];
    comSpecs.forEach(comSpec => {
      if (comSpec.quoteId === parentSpec.quoteId) {
        specs.push(comSpec);
      }
      specs.sort((specA, specB) => {
        return specA.spec.customNumber.localeCompare(
          specB.spec.customNumber,
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      });
    });
    sortedSpecs.push(parentSpec);
    specs.forEach(spec => {
      sortedSpecs.push(spec);
    });
  });
  return sortedSpecs;
};

export const buildOpenDefaultCard = (
  isCollapsed,
  bidGroupVendorId,
  setItemStatus
) => {
  if (!bidGroupVendorId) return;
  if (isCollapsed === undefined) {
    setItemStatus(`bidGroupVendor_${bidGroupVendorId}`, true);
  }
};

function MainLink({ bidGroupVendor, onOpenEditQuote }) {
  if (bidGroupVendor.isDraft) {
    return "There is already a draft quote in the vendor portal";
  }
  return (
    <EditLink onClick={() => onOpenEditQuote(bidGroupVendor)}>
      <StyledEditIcon /> Edit Quote Details
    </EditLink>
  );
}

const getHeaderActions = (selectedIds, optionActions) => {
  return (
    <StyledBidGroupTableActionsDiv>
      <DropdownMenu buttonText="Actions" options={optionActions} />
    </StyledBidGroupTableActionsDiv>
  );
};

export const getOptionsActions = (
  onOpenAwardSpecModal,
  onOpenUnawardSpecModal,
  bidGroupVendor,
  selectedRows,
  updateSpecOnBidGroupVendor,
  updateBidGroupVendor,
  bidGroupVendorRef,
  selectedIds,
  onOpenEditDate
) => {
  bidGroupVendor.status = bidGroupVendor.rfqSentAt
    ? "RFQ Sent"
    : "RFQ Not Sent";
  return [
    {
      content: "Award Multiple Specs",
      disabled: selectedIds.length === 0,
      onClick: () =>
        onOpenAwardSpecModal(
          bidGroupVendor,
          selectedRows,
          updateSpecOnBidGroupVendor,
          bidGroupVendorRef
        ),
      disableIfProjectClosed: true,
    },
    {
      content: "Un-award Multiple Specs",
      disabled: selectedIds.length === 0,
      onClick: () =>
        onOpenUnawardSpecModal(
          bidGroupVendor,
          selectedRows,
          updateSpecOnBidGroupVendor,
          bidGroupVendorRef
        ),
      disableIfProjectClosed: true,
    },
    getMarkBGVStatusAction(
      bidGroupVendor,
      "rfqSentAt",
      updateBidGroupVendor,
      onOpenEditDate,
      rfqSentStatusContent,
      "RFQ Sent",
      "RFQ Not Sent"
    ),
  ];
};
MainLink.propTypes = {
  onOpenEditQuote: PropTypes.func,
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
};

// eslint-disable-next-line max-lines-per-function
export const BidGroupTable = props => {
  const {
    loading,
    bidGroupVendor,
    vendorCategories,
    addToPurchaseOrder,
    createAlternateQuote,
    onOpenAlternateQuoteNotesModal,
    onOpenAwardSpecModal,
    onOpenUnawardSpecModal,
    goToSpecDetail,
    onOpenEditQuote,
    isCollapsed,
    isClosedProject,
    actions,
    goToPurchaseOrderDetail,
  } = props;

  const { selectedIds } = useContext(WithSelectedRowsContext);
  const bidGroupVendorRef = useRef();
  const [columns, setColumns] = useState(
    bidGroupVendor.bidGroup.enableSampleQuantity
      ? defaultColumnsWithSamplePricing
      : defaultColumns
  );
  const [productionTotal, setProductionTotal] = useState(0);
  const [extProductionTotal, setExtProductionTotal] = useState(0);
  const [gridRows, setGridRows] = useState([]);

  const toggleIsOpen = useCallback(() => {
    actions.setItemStatus(`bidGroupVendor_${bidGroupVendor.id}`, !isCollapsed);
  }, [actions, bidGroupVendor.id, isCollapsed]);

  useEffect(() => {
    buildOpenDefaultCard(isCollapsed, bidGroupVendor.id, actions.setItemStatus);
  }, [actions.setItemStatus, bidGroupVendor.id, isCollapsed]);

  useEffect(() => {
    const { bidGroupVendorSpecs = [] } = bidGroupVendor;

    const columns = getColumns(bidGroupVendor.bidGroup.enableSampleQuantity);
    const rows = spreadBidGroupVendorSpecsWithComs(
      bidGroupVendorSpecs,
      parseCurrencyValue
    );
    const sortedRows = getSortedRows(rows);
    setColumns(columns);
    setGridRows(sortedRows);
    bidGroupVendorRef.current = bidGroupVendor;
  }, [bidGroupVendor]);
  useEffect(() => {
    const total = gridRows.reduce((accum, row) => {
      return accum + (row.productionPricing || 0);
    }, 0);
    const extTotal = gridRows.reduce((accum, row) => {
      return accum + (row.extendedProductionPricing || 0);
    }, 0);

    setProductionTotal(total);
    setExtProductionTotal(extTotal);
  }, [gridRows]);
  const tableComponent = useMemo(() => {
    if (gridRows.length === 0 || !isCollapsed) {
      return {
        TableFilterRow: () => null,
        HeaderRowComponent: () => () => null,
        BodyComponent: () => null,
      };
    }

    return {
      SelectionCell: props => createSelectionCell(props),
      CellComponent: editableCellCreator(
        rowInputs(bidGroupVendor),
        bidGroupVendorSpecDataComponentId,
        {},
        (column, tableRow) =>
          isEditable(column, tableRow, bidGroupVendor.isDraft),
        bidGroupVendorRef
      ),
    };
  }, [bidGroupVendor, gridRows.length, isCollapsed]);

  const onOpenEditDate = getOnUpdateDate(
    actions.openModalDialog,
    actions.closeModalDialog,
    actions.updateBidGroupVendor
  );

  const optionActions = useMemo(() => {
    const selectedRows = gridRows.filter(row => selectedIds.includes(row.id));
    return getOptionsActions(
      onOpenAwardSpecModal,
      onOpenUnawardSpecModal,
      bidGroupVendor,
      selectedRows,
      actions.updateSpecOnBidGroupVendor,
      actions.updateBidGroupVendor,
      bidGroupVendorRef,
      selectedIds,
      onOpenEditDate
    );
  }, [
    gridRows,
    onOpenAwardSpecModal,
    onOpenUnawardSpecModal,
    bidGroupVendor,
    actions.updateSpecOnBidGroupVendor,
    actions.updateBidGroupVendor,
    selectedIds,
    onOpenEditDate,
  ]);

  return (
    <LabeledTextContext.Provider value={bidGroupVendor.isDraft}>
      <StyledPaper>
        <BWGridLocal
          id={`sbg-${bidGroupVendor.id}`}
          rows={gridRows}
          showSelectionColumn={!isClosedProject}
          showSelectAll={!isClosedProject}
          gridConfig={{ pageSize: 10, totalRows: gridRows.length }}
          isLoading={loading}
          tableComponents={tableComponent}
          editableNavigationDirection="horizontal"
          emptyStateProps={{
            intent: "empty",
            size: "small",
            intensity: "strong",
            padding: "24px",
          }}
          emptyStateText="No Specs Selected"
        >
          <GridRow>
            <HeaderWrapper>
              <HeaderInnerWrapper>
                <Wrapper>
                  <HeaderLeft>
                    <Loader height="30px" width="350px">
                      <TitleWrapper>{bidGroupVendor.vendor.name}</TitleWrapper>
                      <StatusChip
                        property={bidGroupVendor.rfqSentAt}
                        emptyTitle={"RFQ Not Sent"}
                        nonEmptyTitle={"RFQ Sent On"}
                      />
                      <StatusChip
                        property={bidGroupVendor.quoteReceivedAt}
                        nonEmptyTitle={"Quote Received On"}
                      />
                      {getHeaderActions(selectedIds, optionActions)}
                    </Loader>
                  </HeaderLeft>
                </Wrapper>
                <AttributesWrapper>
                  <HeaderLeft>
                    <Loader height="30px" width="350px">
                      <SubHeadingWrapper>
                        {getVendorCategory(
                          vendorCategories,
                          bidGroupVendor.vendor.categoryId
                        )}
                      </SubHeadingWrapper>
                    </Loader>
                  </HeaderLeft>
                  <HeaderLeft>
                    <Loader height="30px" width="350px">
                      <SubHeadingWrapper>
                        {bidGroupVendor.vendor.website}
                      </SubHeadingWrapper>
                    </Loader>
                  </HeaderLeft>
                  <HeaderLeft>
                    <MainLink
                      bidGroupVendor={bidGroupVendor}
                      onOpenEditQuote={onOpenEditQuote}
                    />
                  </HeaderLeft>
                </AttributesWrapper>
              </HeaderInnerWrapper>
              <ButtonWrapper>
                <DownloadQuoteFilesLink
                  bidGroupVendor={bidGroupVendor}
                  openModalDialog={actions.openModalDialog}
                />
                {getActionButton(
                  bidGroupVendor,
                  selectedIds,
                  addToPurchaseOrder,
                  () => hasAwardedSpec(gridRows),
                  gridRows,
                  loading,
                  isClosedProject
                )}
                <ExpandActionWrapper>
                  <IconButton aria-label="show more" onClick={toggleIsOpen}>
                    <ExpandedIcon isExpanded={isCollapsed} />
                  </IconButton>
                </ExpandActionWrapper>
              </ButtonWrapper>
            </HeaderWrapper>
          </GridRow>
          <GridTable
            columns={columns}
            columnOptions={columnOptions(
              bidGroupVendor,
              onOpenAlternateQuoteNotesModal,
              gridRows,
              setGridRows,
              goToPurchaseOrderDetail,
              isClosedProject
            )}
            rowMenu={row =>
              getRowMenu(
                bidGroupVendor,
                row,
                createAlternateQuote,
                goToSpecDetail,
                isAwardedSpec,
                canCreateAlternateQuote,
                onOpenAwardSpecModal,
                onOpenUnawardSpecModal,
                onOpenAlternateQuoteNotesModal,
                bidGroupVendorRef,
                actions,
                gridRows
              )
            }
          />
          <GridRow showPagingPanel={isCollapsed} />
          <GridRow>
            <Summary
              currency={bidGroupVendor?.quoteProjectCurrency?.currency}
              visible={gridRows.length > 0}
              bidGroupVendor={bidGroupVendor}
              extProductionTotal={extProductionTotal}
              productionTotal={productionTotal}
            />
          </GridRow>
        </BWGridLocal>
      </StyledPaper>
    </LabeledTextContext.Provider>
  );
};

BidGroupTable.propTypes = {
  onOpenEditQuote: PropTypes.func,
  bidGroupVendor: propTypes.bidGroupVendor.isRequired,
  bidGroupVendors: PropTypes.arrayOf(propTypes.bidGroupVendor),
  vendorCategories: PropTypes.arrayOf(propTypes.vendorCategory),
  loading: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  onOpenCreateBidModal: PropTypes.func,
  addToPurchaseOrder: PropTypes.func.isRequired,
  createAlternateQuote: PropTypes.func.isRequired,
  onOpenAlternateQuoteNotesModal: PropTypes.func.isRequired,
  onOpenAwardSpecModal: PropTypes.func.isRequired,
  onOpenUnawardSpecModal: PropTypes.func.isRequired,
  isClosedProject: PropTypes.bool,
  goToSpecDetail: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    setItemStatus: PropTypes.func.isRequired,
    updateSpecOnBidGroupVendor: PropTypes.func.isRequired,
    updateBidGroupVendor: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
  }),
  defaultIsCollapsed: PropTypes.bool,
  goToPurchaseOrderDetail: PropTypes.func,
  updateVendorSpecDataComponent: propTypes.dataComponent,
};

export default withSelectedRows(BidGroupTable);
