import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DiscountForm from "./DiscountForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performCreateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processCreateRequestStatus } from "../../../../../utils/dataComponentUtils";
import vendorDiscountFields from "./vendorDiscountFields";

export const dataComponentId = "DiscountsGrid";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processCreateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

const CreateDiscountContainer = ({
  dataComponent,
  closeModalDialog,
  vendorId,
  setReload,
  discount: { id: sourceId, ...discount },
  performCreateRequest,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  });

  const handleCreateCode = useCallback(
    (code, formikActions) => {
      performCreateRequest(dataComponentId, code);
      setFormikAction(formikActions);
    },
    [performCreateRequest]
  );

  return (
    <DiscountForm
      initialValues={{
        ...discount,
        vendorId,
        type: "Vendor",
      }}
      onSubmit={handleCreateCode}
      discountFields={vendorDiscountFields}
      dataComponentId={dataComponent.dataComponentId}
    />
  );
};

CreateDiscountContainer.defaultProps = {
  discount: {
    programId: undefined,
    discountPercent: undefined,
  },
};

CreateDiscountContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  vendorId: PropTypes.string,
  discount: propTypes.discount,
  performCreateRequest: PropTypes.func.isRequired,
  setReload: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performCreateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDiscountContainer);
