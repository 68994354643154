import { inputs } from "../../inputs/inputConfigs";

export default (projectId, isLabeled, role, spec) => {
  const disabledDueToInvoiceQty = spec.totalQuantityPaid > 0;
  const groups = [getBaseInputGroup(projectId, disabledDueToInvoiceQty)];
  if (role.reference !== "vendor-portal") {
    groups.push(getStaffInputGroup(isLabeled));
  }
  return { groups };
};

const getBaseInputGroup = (projectId, disabledDueToInvoiceQty) => {
  return {
    items: [
      {
        input: {
          ...inputs.customNumber,
          delay: 2000,
        },
        grid: { xs: 4 },
      },
      {
        input: { ...inputs.specCategory, isDisabled: disabledDueToInvoiceQty },
        grid: { xs: 4 },
      },
      {
        input: {
          ...inputs.area,
          APIOptions: {
            ...inputs.area.APIOptions,
            rootFilters: {
              $where: {
                projectId,
              },
            },
          },
        },

        grid: { xs: 4 },
      },
      {
        input: {
          ...inputs.specDescription,
          delay: 2000,
          resize: "vertical",
        },
        grid: { xs: 12 },
      },
    ],
  };
};

const getStaffInputGroup = isLabeled => {
  return {
    items: [
      {
        input: {
          ...inputs.statusComment,
          name: "forecastComment",
          label: "Project Forecast Notes",
          disabled: isLabeled,
          delay: 2000,
          avoidOnChange: true,
        },
        grid: { xs: 12 },
      },
      {
        input: {
          ...inputs.statusComment,
          label: "Status Comments",
          disabled: isLabeled,
          delay: 2000,
          avoidOnChange: true,
        },
        grid: { xs: 12 },
      },
    ],
  };
};
