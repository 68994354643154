import { BaseModel } from "@blue-chip/core";
import Project from "./Project";
import File from "./File";
import FundingPayment from "./FundingPayment";

export default class Funding extends BaseModel {
  static get belongsTo() {
    return [Project, File];
  }

  static get hasMany() {
    return [FundingPayment];
  }
}
