import React from "react";
import _get from "lodash/get";

import {
  DetailAndCycle,
  Subtitle,
  TitleArea,
  NavigationArea,
} from "../../layout/DetailAndCycle";

import { useAreaDetailPageContext, buildAreaRoute } from "./areaDetailutils";

const NavigationPane = () => {
  const { area, clientId, projectId, links } = useAreaDetailPageContext();

  return (
    <DetailAndCycle>
      <NavigationArea
        nextItemRoute={buildAreaRoute(clientId, projectId, _get(links, "next"))}
        lastItemRoute={buildAreaRoute(clientId, projectId, _get(links, "last"))}
      />
      <TitleArea title={_get(area, "name")} />

      <Subtitle>{_get(area, "areaType.name")}</Subtitle>
    </DetailAndCycle>
  );
};
export default NavigationPane;
