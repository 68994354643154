import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ListItemText } from "@material-ui/core";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import { BWActionButton } from "../../../../../ui/BWActionButton";
import Loader from "../../../../../ui/Loader";
import EmptyState from "../../../../../ui/EmptyState";
import { ContactList } from "../../../../PurchaseOrderDetail/ShippingContactSection/components";
import { Menu as MuiMenu } from "../../../../../mui/core";
import { usePerformRetrieveList } from "../../../../../hooks/usePerformRetrieveList";
import RemitAddress from "../../../../../../models/RemitAddress";
import { RemitAddressItem, RemitAddressListItem } from "./RemitAddressItem";
import { ListItem } from "../../../../PurchaseOrderDetail/ShippingContactSection/VendorInfo/components";
import CreateRemitAddressButton from "./CreateRemitAddressButton";
import useActions from "../../../../../hooks/useActions";
import { openModalDialog } from "../../../../../../actions/layoutActions";
import propTypes from "../../../../../../constants/propTypes";

export const MenuContainer = styled(MuiMenu)`
  left: 787px;

  ul {
    width: 100%;
    padding: 0 !important;
    width: 465px !important;
  }
  ${BWActionButton} {
    background: none;
  }
`;

const dataComponent = {
  dataComponentId: "vendorRemitAddressesDCId",
  model: RemitAddress,
  includes: ["location"],
  apiRoute: "remit-addresses",
};

export const Menu = ({ vendor, anchorEl, onClose, updateInvoice }) => {
  const actions = useActions({ openModalDialog });
  const filters = useMemo(
    () => ({
      pageSize: -1,
      sort: [{ columnName: "name", direction: "desc" }],
      rootFilters: {
        $where: {
          vendorId: vendor.id,
        },
      },
    }),
    [vendor.id]
  );

  const { isLoading, data: remitAddresses } = usePerformRetrieveList(
    dataComponent,
    filters
  );

  const menuProps = useMemo(
    () => ({
      anchorEl: anchorEl,
      open: Boolean(anchorEl),
      onClose: onClose,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    }),
    [anchorEl, onClose]
  );

  const handleCreateRemitAddress = useCallback(() => {
    actions.openModalDialog(
      "Create Remit Address",
      "CreateRemitAddressContainer",
      {
        vendorId: vendor.id,
        vendor,
        titleModal: "Create Remit Address",
        processDataComponent: ({
          requestState: {
            create: {
              rowIndex: [remitAddressId],
            },
          },
        }) => updateInvoice({ remitAddressId }),
      },
      false
    );
    onClose();
  }, [actions, onClose, updateInvoice, vendor]);

  return (
    <MenuContainer {...menuProps}>
      <ListItem button={false}>
        <ContactList>
          <Loader loading={isLoading} height="60px" count={10}>
            {remitAddresses.length > 0 &&
              remitAddresses.map((remitAddress, i) => (
                <RemitAddressItem
                  key={i}
                  remitAddress={remitAddress}
                  updateInvoice={updateInvoice}
                  onClose={onClose}
                />
              ))}
            <RemitAddressListItem onClick={handleCreateRemitAddress}>
              <ListItemText primary={<CreateRemitAddressButton />} />
            </RemitAddressListItem>
            {_isEmpty(remitAddresses) && !isLoading && (
              <div>
                <ListItemText>
                  <EmptyState>No results</EmptyState>
                </ListItemText>
              </div>
            )}
          </Loader>
        </ContactList>
      </ListItem>
    </MenuContainer>
  );
};
Menu.propTypes = {
  vendor: propTypes.vendor,
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};
