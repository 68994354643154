export const calculateTotalQuantity = (
  baseQuantity,
  atticStock,
  overageQuantity
) => {
  const total =
    (baseQuantity || 0) + (atticStock || 0) + (overageQuantity || 0);
  return Math.round(total * Math.pow(10, 3)) / Math.pow(10, 3);
};
export const calculateOverageQuantity = (
  overagePercent,
  baseQuantity,
  atticStock
) => {
  const overageQuantity =
    overagePercent * ((baseQuantity || 0) + (atticStock || 0));
  return Math.round(overageQuantity * Math.pow(10, 3)) / Math.pow(10, 3);
};
