import { BaseModel } from "@blue-chip/core";
import User from "./User";
import Contact from "./Contact";
import Vendor from "./Vendor";
import Office from "./Office";
import Property from "./Property";
import RemitAddress from "./RemitAddress";
import ProjectUserStar from "./ProjectUserStar";
import Area from "./Area";
import ConsultingCompany from "./ConsultingCompany";
import ProjectMemberUser from "./ProjectMemberUser";
import ProjectCurrency from "./ProjectCurrency";
import ProjectMemberContact from "./ProjectMemberContact";
import Contingency from "./Contingency";
import ProjectAddress from "./ProjectAddress";
import ProjectService from "./ProjectService";
import ProjectSite from "./ProjectSite";
import Spec from "./Spec";
import Fee from "./Fee";
import File from "./File";
import Program from "./Program";
import ContractFile from "./ContractFile";

export default class Project extends BaseModel {
  static get hasMany() {
    return [
      ProjectUserStar,
      User,
      Contact,
      RemitAddress,
      ProjectMemberUser,
      ProjectMemberContact,
      Area,
      Spec,
      Fee,
      Contingency,
      ProjectAddress,
      ProjectSite,
      ContractFile,
      ProjectCurrency,
    ];
  }

  static get belongsTo() {
    return [
      Property,
      Vendor,
      ConsultingCompany,
      Office,
      File,
      User,
      ProjectService,
      Program,
    ];
  }
}
