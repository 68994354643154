import React from "react";
import PropTypes from "prop-types";
import StandardConfirmation from "../../../../../ui/StandardConfirmation";

const FlagUnflagContract = ({ title, buttonText, onSubmit }) => {
  return (
    <StandardConfirmation
      title={title}
      onSubmit={onSubmit}
      sendButtonText={buttonText}
    />
  );
};

FlagUnflagContract.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default FlagUnflagContract;
