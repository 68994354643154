import React from "react";
import PropTypes from "prop-types";

import BWGridAPI from "../../../../ui/BWGrid/BWGridAPI";
import GridHeader from "../../../../ui/BWGrid/GridHeader";
import AddCircle from "@material-ui/icons/AddCircle";
import {
  HeaderText,
  StyledIcon,
  ListSectionWrapper,
  StyledGridRoot,
} from "./components";
import COMPreview from "../COMPreview";
import { tableRowBuilder } from "./TableRow";
import GridTable from "../../../../ui/BWGrid/GridTable";
import Selector from "./Selector";
import Spec from "../../../../../models/Spec";
import propTypes from "../../../../../constants/propTypes";
import VendorColumn from "../../../Specs/VendorColumn";

const renderSelector = () => <Selector />;

const getColumns = isSelectCOM => {
  const columns = [
    "preview",
    "customNumber",
    "description",
    "purchaseOrder.vendor.name",
  ];

  return isSelectCOM ? columns.concat(["selector"]) : columns;
};

const columnOptions = {
  preview: {
    title: " ",
    sortingEnabled: false,
    render: COMPreview,
    width: "",
  },
  customNumber: {
    title: "Spec Number",
    filter: true,
    fullwidth: true,
  },
  description: {
    width: "150px",
    filter: true,
  },
  "purchaseOrder.vendor.name": {
    title: "Vendor",
    fill: true,
    render: VendorColumn,
    filter: "select",
    filterOptions: {
      operator: "equal",
      options: [],
    },
  },
};

const getColumnOptions = (isSelectCOM, vendors) => {
  const selectionColumnOptions = isSelectCOM
    ? {
        selector: {
          title: " ",
          render: renderSelector,
        },
      }
    : {};

  columnOptions["purchaseOrder.vendor.name"].filterOptions.options = vendors
    ? vendors.map(({ name }) => ({ id: name, name }))
    : [];

  return {
    ...columnOptions,
    ...selectionColumnOptions,
  };
};

const getAPIFilters = (projectId, specDetail, excludeComIds) => {
  return {
    rootFilters: {
      $where: {
        id: {
          $notIn: excludeComIds,
        },
        projectId,
        "specCategory.key": "COM_ITEM",
      },
    },
    pageSize: 10,
  };
};

export const ListSection = ({
  dataComponent,
  selectHandler,
  isSelectCOM,
  currentSelectedId,
  projectId,
  specDetail,
  excludeComIds,
  vendors,
  clickCreateComItem,
  spec,
}) => {
  const headerText = (
    <HeaderText>
      <StyledIcon /> <span> {dataComponent.totalRows} COMs</span>
    </HeaderText>
  );

  const tableComponents = {
    RowComponent: tableRowBuilder(currentSelectedId, selectHandler),
  };

  const actions = spec
    ? [
        {
          text: "CREATE COM ITEM",
          icon: <AddCircle />,
          handler: clickCreateComItem,
          disableIfProjectClosed: true,
        },
      ]
    : [];

  return (
    <ListSectionWrapper>
      <BWGridAPI
        dataComponent={dataComponent}
        apiRoute="specs/"
        model={Spec}
        includes={["specDetails.preview", "purchaseOrder.vendor"]}
        tableComponents={tableComponents}
        apiFilters={getAPIFilters(projectId, specDetail, excludeComIds)}
        rootComponent={StyledGridRoot}
      >
        <GridHeader headerOverride={headerText} actions={actions} />
        <GridTable
          columns={getColumns(isSelectCOM)}
          columnOptions={getColumnOptions(isSelectCOM, vendors)}
        />
      </BWGridAPI>
    </ListSectionWrapper>
  );
};

ListSection.propTypes = {
  dataComponent: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired,
  specDetail: propTypes.specDetail,
  itemSelected: PropTypes.object,
  isSelectCOM: PropTypes.bool,
  projectId: PropTypes.string,
  currentSelectedId: PropTypes.string,
  excludeComIds: PropTypes.arrayOf(PropTypes.string),
  vendors: PropTypes.array,
  clickCreateComItem: PropTypes.func,
  spec: PropTypes.object,
};

export default React.memo(ListSection);
