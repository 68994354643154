import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import moment from "moment";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import styled from "styled-components";

import _startCase from "lodash/startCase";
import TagText from "../../ui/Tags/TagText";
import { PRIMARY_DATE_FORMAT } from "../../../constants/formats";

function getRemainingDaysMessage(row) {
  const { responseDate } = row.bidGroup.rfq;
  const remainingDays = moment(responseDate).diff(moment(), "days");

  return `${pluralize("Day", remainingDays, true)} Left`;
}

function getClosedMessage(row) {
  const { responseDate } = row.bidGroup.rfq;
  return `Closed on ${moment(responseDate).format(PRIMARY_DATE_FORMAT)}`;
}

function getExpiredMessage(row) {
  const { responseDate } = row.bidGroup.rfq;
  return `Expired on ${moment(responseDate).format(PRIMARY_DATE_FORMAT)}`;
}

function getSubmittedMessage(row) {
  const { responseDate } = row.bidGroup.rfq;
  return `Submitted ${moment(responseDate).format(PRIMARY_DATE_FORMAT)}`;
}

const messageFormatters = {
  open: getRemainingDaysMessage,
  submitted: getRemainingDaysMessage,
  "in-review": getClosedMessage,
  "no-bid": getClosedMessage,
  lost: getExpiredMessage,
  awarded: getSubmittedMessage,
};

const QuoteTimeFormatter = ({ row }) => {
  if (!row.bidGroup || !row.bidGroup.rfq?.responseDate) return "";
  const { quoteStatus } = row;
  const formatter = messageFormatters[quoteStatus];

  return formatter(row);
};

export const QuoteTimeTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={QuoteTimeFormatter}
    {...props}
    for={["quoteTimeRemaining"]}
  />
);

const statusColors = {
  open: "#57ABFF",
  submitted: "rgba(0, 0, 0, 0.24)",
  "in-review": "#FF9100",
  "no-bid": "#FF9100",
  lost: "#FF1744",
  awarded: "#72B327",
};
const Tag = styled(TagText)`
  background-color: ${({ intent }) => statusColors[intent]};
  color: #fff;
  width: 124px;
`;

const QuoteStatusFormatter = ({ value }) => {
  return <Tag intent={value}>{_startCase(value)}</Tag>;
};

QuoteStatusFormatter.propTypes = {
  value: PropTypes.string,
};

export const QuoteStatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={QuoteStatusFormatter}
    {...props}
    for={["quoteStatus"]}
  />
);
