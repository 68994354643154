import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import React from "react";

export default styled(({ isNotesFilter, hide, ...props }) => (
  <FormControlLabel
    classes={{ label: "label", root: "root" }}
    hide={`${hide}`}
    {...props}
  />
))`
  margin-right: ${({ direction }) => direction === "row" && "21px"};
  & .label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;

    ${({ isNotesFilter }) =>
      isNotesFilter
        ? `
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);
      margin-left: 12px;
    `
        : ""}
  }
`;
