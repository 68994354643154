// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`UserRole Model belongsTo relations 1`] = `
Array [
  "User",
]
`;

exports[`UserRole Model hasMany relations 1`] = `
Array [
  "Permission",
  "UserRolePermission",
]
`;
