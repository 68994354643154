import React, { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { connect as formikConnect } from "formik";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { isEmpty } from "lodash";
import { startCase } from "lodash";
import { get } from "lodash";

import {
  openModalDialog,
  closeModalDialog,
} from "../../../actions/layoutActions";
import { getDataComponent } from "../../../reducers/dataComponentReducer";
import propTypes from "../../../constants/propTypes";
import * as REQUEST_TYPES from "../../../constants/RequestTypes";
import { processUpdateRequestStatus } from "../../../utils/dataComponentUtils";
import { AutoSaveContext } from "../FormikForm";
import {
  convertServerErrorToFormikErrors,
  getDirtyFields,
} from "../../../utils/formValidationUtils";

const getUrl = location => `${location.pathname}${get(location, "search", "")}`;

export const buildBeforeUnload = ({ dirty }) => event => {
  if (dirty) event.returnValue = true;
};

export const handleNavigate = ({
  location,
  push,
  closeModalDialog,
  setLocation,
}) => {
  if (location) {
    push(getUrl(location));
    closeModalDialog();
    setLocation(null);
  }
};

const handleErrors = ({
  errors,
  resetForm,
  location,
  setLocation,
  setFlushed,
  setDiscard,
  dataComponentId,
  openModalDialog,
  closeModalDialog,
}) => {
  if (isEmpty(errors)) {
    return;
  }
  setLocation(null);

  openModalDialog(
    [
      "Unsaved changes",
      "You will lose any unsaved changes if you leave this page",
    ],
    "DirtyAutoSaveFormDialog",
    {
      cancelButtonText: "Cancel",
      onCancel: () => {
        closeModalDialog();
        setFlushed(false);
        setDiscard(false);
      },
      discardText: "Discard",
      onDiscard: () => {
        resetForm();
        closeModalDialog();
        setDiscard(true);
        setFlushed(false);
        setLocation(location);
      },
      listeners: [dataComponentId],
      errors: errors,
    },
    true,
    false
  );
};

export const handleDiscard = ({
  discard,
  location,
  push,
  setLocation,
  setFlushed,
  setDiscard,
}) => {
  if (discard) {
    push(getUrl(location), { keepAutoSaveComponentId: true });
    setLocation(null);
    setFlushed(false);
    setDiscard(false);
  }
};

export const handleFlush = ({
  dirty,
  flushed,
  location,
  setLocation,
  setFlushed,
  setDiscard,
  flushAutoSave,
  validateForm,
  resetForm,
  push,
  dataComponentId,
  openModalDialog,
  closeModalDialog,
}) => {
  if (dirty && location && !flushed) {
    setFlushed(true);
    flushAutoSave();
    validateForm().then(errors => {
      const parsedErrors = Object.keys(errors).map(
        field => `${startCase(field)}: ${errors[field]}`
      );
      handleErrors({
        errors: parsedErrors,
        resetForm,
        location,
        push,
        setLocation,
        setFlushed,
        setDiscard,
        dataComponentId,
        openModalDialog,
        closeModalDialog,
      });
    });
  }
};

export const processUpdateRequest = ({
  prevDataComponent,
  dataComponent,
  location,
  flushed,
  push,
  setLocation,
  setFlushed,
  resetForm,
  setDiscard,
  dataComponentId,
  openModalDialog,
  closeModalDialog,
}) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      if (location && flushed) {
        push(getUrl(location), { keepAutoSaveComponentId: true });
        setLocation(null);
        setFlushed(false);
      }
    },
    onError: error => {
      if (!location) return;
      const errors = convertServerErrorToFormikErrors(error);
      handleErrors({
        errors: Object.values(errors),
        resetForm,
        location,
        push,
        setLocation,
        setFlushed,
        setDiscard,
        dataComponentId,
        openModalDialog,
        closeModalDialog,
      });
    },
  });
};

const DirtyAutoSaveFormPrompt = ({
  dataComponentId,
  dataComponent,
  withSubmitCount,
  formik,
  openModalDialog,
  closeModalDialog,
  push,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [location, setLocation] = useState(null);
  const [flushed, setFlushed] = useState(false);
  const [discard, setDiscard] = useState(false);

  const { dirty, submitCount, validateForm, resetForm } = formik;

  const { flushAutoSave } = useContext(AutoSaveContext);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    const beforeUnload = buildBeforeUnload({ dirty });
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [dataComponentId, dirty, openModalDialog]);

  useEffect(() => {
    handleDiscard({
      discard,
      location,
      push,
      setLocation,
      setFlushed,
      setDiscard,
    });
  }, [discard, location, push]);

  useEffect(() => {
    handleFlush({
      dirty,
      flushed,
      location,
      setLocation,
      setFlushed,
      setDiscard,
      flushAutoSave,
      validateForm,
      resetForm,
      push,
      dataComponentId,
      openModalDialog,
      closeModalDialog,
    });
  }, [
    closeModalDialog,
    dataComponentId,
    dirty,
    flushAutoSave,
    flushed,
    formik,
    location,
    openModalDialog,
    push,
    resetForm,
    validateForm,
  ]);

  useEffect(() => {
    processUpdateRequest({
      prevDataComponent,
      dataComponent,
      location,
      flushed,
      push,
      setLocation,
      setFlushed,
      resetForm,
      setDiscard,
      dataComponentId,
      openModalDialog,
      closeModalDialog,
    });
  }, [
    closeModalDialog,
    dataComponent,
    dataComponentId,
    flushed,
    location,
    openModalDialog,
    prevDataComponent,
    push,
    resetForm,
  ]);

  return (
    <Prompt
      when={withSubmitCount ? dirty && submitCount === 0 : dirty}
      message={newLocation => {
        setLocation(newLocation);
        return discard || isEmpty(getDirtyFields(formik));
      }}
    />
  );
};

DirtyAutoSaveFormPrompt.propTypes = {
  formik: PropTypes.shape({
    dirty: PropTypes.bool,
    submitCount: PropTypes.number,
  }),
  withSubmitCount: PropTypes.bool,
  openModalDialog: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  dataComponent: propTypes.dataComponent,
};

DirtyAutoSaveFormPrompt.defaultProps = {
  withSubmitCount: true,
};

const mapStateToProps = (state, { dataComponentId }) => {
  return {
    dataComponent: getDataComponent(
      dataComponentId,
      state,
      REQUEST_TYPES.UPDATE
    ),
  };
};

const mapDispatchToProps = {
  openModalDialog,
  closeModalDialog,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikConnect(DirtyAutoSaveFormPrompt));
