import React from "react";
import { FormPageSection } from "../../../layout/FormPageSection";

import targetFields from "./targetFields";
import PropTypes from "prop-types";

export const calculateDesignerExtendedCost = (quantity, budget) => {
  if (isNaN(quantity) || isNaN(budget)) {
    return "";
  }
  return quantity * budget;
};
const TargetSectionForm = props => {
  const { values, setFieldValue } = props;

  const onDesignerQuantityChange = event => {
    const value = parseFloat(event.target.value);

    setFieldValue(
      "designerExtendedCost",
      calculateDesignerExtendedCost(value, values.designerBudget)
    );
  };
  const onDesignerBudgetChange = event => {
    const value = parseFloat(event.target.value);

    setFieldValue(
      "designerExtendedCost",
      calculateDesignerExtendedCost(values.designerQuantity, value)
    );
  };
  values.designerExtendedCost = calculateDesignerExtendedCost(
    values.designerQuantity,
    values.designerBudget
  );

  return (
    <FormPageSection
      headerText="Target"
      isPage={true}
      fields={targetFields(
        onDesignerBudgetChange,
        onDesignerQuantityChange,
        values
      )}
      values={values}
    />
  );
};
TargetSectionForm.defaultProps = {
  values: {},
  setFieldValue: () => {},
};
TargetSectionForm.propTypes = {
  values: PropTypes.shape({}),
  setFieldValue: PropTypes.func.isRequired,
};

export default TargetSectionForm;
