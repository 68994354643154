import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

const ChildrenWithTooltip = styled(
  ({ title, className, children, ...props }) => (
    <Tooltip title={title}>
      <span className={className}>{React.cloneElement(children, props)}</span>
    </Tooltip>
  )
)`
  display: none;

  @media (max-width: 1170px) {
    display: initial;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ChildrenWithoutTooltip = styled(({ className, children, ...props }) => (
  <span className={className}>{React.cloneElement(children, props)}</span>
))`
  @media (max-width: 1170px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: initial;
  }
`;

export const TooltipWrapper = ({ ...props }) => (
  <span>
    <ChildrenWithTooltip {...props} />
    <ChildrenWithoutTooltip {...props} />
  </span>
);
