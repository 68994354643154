import React, { useContext, useEffect, useState } from "react";
import _get from "lodash/get";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import styled from "styled-components";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, InputAdornment } from "@material-ui/core";

import { StyledTextField } from "../../../../inputs/TextInputWithError";
import propTypes from "../../../../../constants/propTypes";
import { LoaderContext } from "../../../Loader";
import { removeControlCharacters } from "../../../../inputs/utils";

export const FilterTextField = styled(StyledTextField)`
  margin: 0px;
  width: 100%;
  & .input {
    font-weight: normal;
    font-size: 14px;
  }
`;

export const Close = styled(CloseIcon)`
  width: 16px;
  height: 16px;
`;

export const Button = styled(IconButton)`
  padding: 0;
`;

export const clearText = (handleChange, value) => () =>
  value !== "" ? handleChange({ target: { value: "" } }) : null;

export const EditorComponent = ({
  onValueChange,
  onChange,
  value: initialValue,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const { loading } = useContext(LoaderContext);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const placeholder = _get(props, "column.placeholder", "Filter...");
  const handleChange = ({ target: { value } }) => {
    const parsedValue = removeControlCharacters(value, true);
    onValueChange ? onValueChange(parsedValue) : onChange(parsedValue);
    setValue(parsedValue);
  };

  return (
    <FilterTextField
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      InputProps={{
        readOnly: loading,
        endAdornment: (
          <InputAdornment position="end">
            <Button disabled={loading} onClick={clearText(handleChange, value)}>
              <Close />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};
EditorComponent.defaultProps = {
  value: "",
};

EditorComponent.propTypes = {
  onValueChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

const TextTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(
    column =>
      columnOptions[column].filter === true ||
      columnOptions[column].filter === "text"
  );
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={EditorComponent} />
  ) : null;
};
TextTypeProvider.propTypes = propTypes.filteringComponent;

export default TextTypeProvider;
