import React, { useContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import WarningIcon from "@material-ui/icons/ReportProblem";
import _padStart from "lodash/padStart";

import { PORevisionContext } from "../../../../withPORevision";
import { FormPageSection } from "../../../layout/FormPageSection";
import RegularText from "../../../ui/Typography/RegularText";
import { LoaderContext } from "../../../ui/Loader";
import fields from "./changeOrderFields";
import { openModalDialog } from "../../../../actions/layoutActions";
import PropTypes from "prop-types";
import useActions from "../../../hooks/useActions";

export const ChangesLabel = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  background: #ff9100;
  border-radius: 2px;
  padding: 10px 12px 10px 8px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const Text = styled(RegularText)`
  margin-left: 8px;
  line-height: 16px;
  font-weight: 600;
`;

const Icon = styled(WarningIcon)`
  font-size: 20px;
`;

const statusesWhenIsRequired = ["Approved"];

const getLastRevision = (revisions, currentRevisionId) =>
  revisions
    ? revisions.find(revision => currentRevisionId === revision.id)
    : {};

const getOnClick = (lastRevision, handleOpenChangesMade) =>
  lastRevision.changesMade > 0 ? handleOpenChangesMade : undefined;

const ChangeOrderDetailsForm = ({ purchaseOrder, ...formikProps }) => {
  const { isActive } = useContext(PORevisionContext);

  const actions = useActions({ openModalDialog });
  const [lastRevision, setLastRevision] = useState(
    getLastRevision(purchaseOrder.revisions, purchaseOrder.currentRevisionId)
  );

  useEffect(() => {
    setLastRevision(
      getLastRevision(purchaseOrder.revisions, purchaseOrder.currentRevisionId)
    );
  }, [purchaseOrder]);

  const handleOpenChangesMade = useCallback(() => {
    actions.openModalDialog(
      `Change Order ${_padStart(lastRevision.version, 3, "0")}`,
      "RevisionActivities",
      {
        revisionId: lastRevision.id,
        changesMade: lastRevision.changesMade,
        purchaseOrder,
      },
      true,
      true,
      {
        subtitle: `${
          lastRevision.type ? `${lastRevision.type} -` : ""
        }  ${lastRevision.changesMade || 0} Changes`,
      }
    );
  }, [actions, lastRevision, purchaseOrder]);

  if (!isActive) return null;
  const { revisionStatus } = formikProps.values;

  return (
    <FormPageSection
      fields={fields(statusesWhenIsRequired.includes(revisionStatus))}
      headerText="Change Order Details"
    >
      <LoaderContext.Consumer>
        {({ loading }) =>
          !loading && (
            <ChangesLabel
              onClick={getOnClick(lastRevision, handleOpenChangesMade)}
              disabled={lastRevision.changesMade === 0}
            >
              <Icon />
              <Text>{lastRevision.changesMade} Changes Made</Text>
            </ChangesLabel>
          )
        }
      </LoaderContext.Consumer>
    </FormPageSection>
  );
};

ChangeOrderDetailsForm.propTypes = {
  purchaseOrder: PropTypes.object,
};

export default ChangeOrderDetailsForm;
