import { appPageBuilder } from "./utils";
import Capacity from "../components/pages/Capacity";

const AppCapacity = appPageBuilder(Capacity, "Capacity");

export default [
  {
    path: "/capacity",
    component: AppCapacity,
    exact: true,
  },
];
