import React, { useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import moment from "moment";

import Paper from "../../../mui/core/Paper";
import GridAPI from "../../../ui/Grid/GridAPI";
import PurchaseOrder from "../../../../models/PurchaseOrder";
import { useHeaderComponent } from "../../../ui/BWGrid/GridHeader";
import {
  currencyTypeBuilder,
  poLinkTypeBuilder,
} from "../../../ui/Grid/utilComponents/dataTypeProviders";
import propTypes from "../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { goToPurchaseOrderDetail } from "../../../../actions/dashboardActions";
import {
  buildTextTypeProvider,
  buildStaticSelectTypeProvider,
} from "../../../ui/Grid/filterDataTypeProviders";
import { UpdateNowButton } from "./UpdateNowButton";
import { PRIMARY_DATE_FORMAT } from "../../../../constants/formats";

export const dataComponentId = "purchase-order-requiring-refund";

export const statusFilter = ({ value }) => {
  return value ? { status: value } : {};
};

const PurchaseOrdersRequiringRefundsContainer = ({
  dataComponent,
  projectIds,
  projectView,
  loggedInUserId,
  goToPurchaseOrderDetail,
}) => {
  const query = projectView
    ? {
        projectId: { $in: projectIds },
      }
    : {
        $or: [
          { "project.membersUsers.id": loggedInUserId },
          { "project.projectLeadId": loggedInUserId },
          { projectId: { $in: projectIds } },
        ],
      };
  query.revisionStatus = null;
  const projectColumn = projectView
    ? []
    : [
        {
          name: "project.number",
          title: "Project Number",
          filter: "text",
          width: "150px",
          bold: true,
        },
      ];

  const filteringDataTypeProviders = useMemo(
    () => [
      buildTextTypeProvider(["number", "vendor.name", "project.number"]),
      buildStaticSelectTypeProvider(["status"]),
    ],
    []
  );
  return (
    <Paper noBorder>
      <GridAPI
        apiRoute="purchase-orders-requiring-refund"
        model={PurchaseOrder}
        includes={[
          "project.property.entity",
          "vendor",
          "projectCurrency.currency",
        ]}
        apiFilters={{
          rootFilters: { $where: query },
          params: { omitDefaultModifier: true },
        }}
        dataComponent={dataComponent}
        columns={[
          ...projectColumn,
          {
            name: "number",
            title: "PO Number",
            filter: "text",
          },
          {
            name: "status",
            title: "PO Status",
            filter: "select",
            width: "150px",
            filterOptions: {
              initialOption: "Issued",
              options: [
                ...BWModels.loadSchema("PurchaseOrder").__utils.statuses,
              ],
              operator: statusFilter,
            },
          },
          {
            name: "vendor.name",
            title: "Vendor",
            filter: "text",
          },
          {
            name: "totalPriceWithoutOverage",
            title: "PO Total",
          },
          {
            name: "maximumOverageTotal",
            title: "Maximum Allowable Overage",
          },
          {
            name: "extraFreightWarehousing",
            title: "Extra Freight/Warehousing",
          },
          {
            name: "taxesWithOverage",
            title: "Tax",
          },
          {
            name: "merchandiseDepositsToDate",
            title: "Deposits Paid",
          },
          {
            name: "refundAmount",
            title: "Refund Amount",
          },
        ]}
        columnExtensions={[]}
        tableComponents={{
          GridHeader: useHeaderComponent({
            headerText: `${dataComponent.totalRows} POs Requiring Refunds`,
            children: `Last updated at ${moment(
              dataComponent.lastRefreshedAt
            ).format(PRIMARY_DATE_FORMAT + " h:mm A")}`,
            actions: [
              {
                actionOverride: (
                  <UpdateNowButton
                    lastRefreshedAt={dataComponent.lastRefreshedAt}
                  />
                ),
              },
            ],
          }),
        }}
        dataTypeProviders={[
          poLinkTypeBuilder("number", goToPurchaseOrderDetail),
          currencyTypeBuilder([
            "totalPriceWithoutOverage",
            "maximumOverageTotal",
            "extraFreightWarehousing",
            "taxesWithOverage",
            "merchandiseDepositsToDate",
            "refundAmount",
          ]),
        ]}
        filteringDataTypeProviders={filteringDataTypeProviders}
      />
    </Paper>
  );
};

PurchaseOrdersRequiringRefundsContainer.propTypes = {
  projectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataComponent: propTypes.dataComponent,
  goToPurchaseOrderDetail: PropTypes.func.isRequired,
  projectView: PropTypes.bool,
  loggedInUserId: PropTypes.string,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  goToPurchaseOrderDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrdersRequiringRefundsContainer);
