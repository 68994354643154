import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SettingsPage from "./SettingsPage";
import { roleCan } from "../../../utils/roleUtils";
import propTypes from "../../../constants/propTypes";

const SettingsContainer = ({ push, role }) => {
  useEffect(() => {
    if (!roleCan(role, ["navigation-settings", "access-to-settings-section"])) {
      push("/dashboard");
    }
  }, [push, role]);

  return <SettingsPage role={role} />;
};

SettingsContainer.propTypes = {
  push: PropTypes.func.isRequired,
  role: propTypes.userRole,
};

const mapStateToProps = ({ auth: { role } }) => {
  return {
    role,
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(SettingsContainer));
