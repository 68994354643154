import React from "react";
import BWModels from "benjaminwest-models";

import Date from "../../../../ui/BWGrid/columns/Date";
import { formatWithNegatives } from "./FeePayments/PaymentsList";
import { showAction } from "../../../ProjectDetail/Accounting/Fees/tableOptions";
import { PRIMARY_DATE_FORMAT } from "../../../../../constants/formats";

export const columns = [
  "status",
  "amount",
  "amountPaid",
  "billingDate",
  "invoiceReferenceNumber",
  "billDate",
  "paidDate",
  "paidReference",
];

export const columnOptions = formatter => ({
  status: {
    filter: "multiselect",
    minWidth: "150px",
    filterOptions: {
      options: [...BWModels.loadSchema("Fee").__utils.statuses],
      operator: "multiselect",
    },
  },
  amount: {
    filter: "text",
    render: ({ amount }) => {
      return formatWithNegatives(amount, formatter);
    },
  },
  amountPaid: {
    filter: "text",
    render: ({ amountPaid }) => {
      return formatWithNegatives(amountPaid, formatter);
    },
  },
  billingDate: {
    filter: "picker",
    render: ({ billingDate }) =>
      billingDate && <Date date={billingDate} format={PRIMARY_DATE_FORMAT} />,
  },
  invoiceReferenceNumber: {
    title: "Invoice #",
    filter: "text",
  },
  billDate: {
    filter: "picker",
    render: ({ billDate }) =>
      billDate && <Date date={billDate} format={PRIMARY_DATE_FORMAT} />,
  },
  paidDate: {
    filter: "picker",
    render: ({ paidDate }) =>
      paidDate && <Date date={paidDate} format={PRIMARY_DATE_FORMAT} />,
  },
  paidReference: {
    title: "Payment Ref.",
  },
});

export const rowMenu = ({
  openPaymentsDialog,
  dataComponentId,
  performAfterCreatePayment,
  handleOpenVoidModal,
}) => fee => [
  {
    text: "Manage Payments",
    onClick: () =>
      openPaymentsDialog({ fee, dataComponentId, performAfterCreatePayment }),
    disabled: Boolean(fee.sharedFromFeeId),
  },
  {
    text: "Void Fee",
    onClick: handleOpenVoidModal,
    disabled:
      Boolean(fee.sharedFromFeeId) || !showAction({ actionName: "void" }, fee),
  },
];
