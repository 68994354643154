import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";

import propTypes from "../../../../../constants/propTypes";

const EmptyTypeProvider = ({ columnOptions, displayFilters }) => {
  const columns = Object.keys(columnOptions).filter(
    column => !columnOptions[column].filter
  );
  return displayFilters ? (
    <DataTypeProvider for={columns} editorComponent={() => null} />
  ) : null;
};
EmptyTypeProvider.propTypes = propTypes.filteringComponent;

export default EmptyTypeProvider;
