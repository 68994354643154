import { BaseModel } from "@blue-chip/core";
import Discount from "./Discount";
import PurchaseOrder from "./PurchaseOrder";

export default class PurchaseOrderDiscount extends BaseModel {
  static plural = "purchaseOrderDiscounts";

  static get belongsTo() {
    return [Discount, PurchaseOrder];
  }
}
