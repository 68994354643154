import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { List, RootRef } from "@material-ui/core";

import ColumnEdit from "./ColumnEdit";

export const treeProperties = (root, columns) => {
  const properties = [];
  const recursion = (node, prefix = "") => {
    if (!node) {
      return;
    }

    Object.keys(node.properties).forEach(key => {
      const prop = node.properties[key];
      prop.selectName = `${prefix}${prop.columnName}`;
      prop.entityName = node.entityName;

      const formProp =
        columns.find(({ selectName }) => selectName === prop.selectName) || {};
      prop.editName = formProp.editName ?? prop.editName;
      prop.sequenceIndex =
        formProp.sequenceIndex ?? prop.sequenceIndex ?? columns.length;

      properties.push(prop);
    });

    Object.values(node.children).forEach(node => {
      recursion(node, `${prefix}${node.relationName}.`);
    });
  };

  recursion(root);

  return properties
    .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
    .map((property, index) => {
      property.sequenceIndex = index;
      return property;
    });
};

export const onDragEnd = (columns, setFieldValue) => ({
  source,
  destination,
}) => {
  if (!destination) {
    return;
  }

  const [reorderedItem] = columns.splice(source.index, 1);
  columns.splice(destination.index, 0, reorderedItem);

  columns.forEach((column, index) => (column.sequenceIndex = index));
  columns.sort((a, b) => a.sequenceIndex - b.sequenceIndex);

  setFieldValue("columns", columns);
};

const DraggableList = ({ columns, setFieldValue, ...formikProps }) => {
  return columns.map(column => (
    <ColumnEdit
      key={column.selectName}
      column={column}
      setFieldValue={setFieldValue}
      {...formikProps}
    />
  ));
};

const Summary = ({ columns, setFieldValue, ...formikProps }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd(columns, setFieldValue)}>
      <Droppable droppableId="columns">
        {provided => (
          <RootRef rootRef={provided.innerRef}>
            <List {...provided.droppableProps}>
              <DraggableList
                columns={columns}
                setFieldValue={setFieldValue}
                {...formikProps}
              />
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Summary.propTypes = {
  root: PropTypes.object,
};

export default Summary;
