import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";

import { useUpdateProcessRequests } from "../../../hooks/useProcessRequest";
import UpdatePassword from "./ResetPassword";
import { Container, TabContainer } from "./components";
import Tab from "../../../mui/core/Tab";
import Tabs from "../../../mui/core/Tabs";
import { UserDetailsForm } from "./UserDetailsForm";
import propTypes from "../../../../constants/propTypes";

export const updateUserDataComponentId = "userDataComponent";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <TabContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && children}
    </TabContainer>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
};

const AccountSettings = ({
  userData,
  dataComponent,
  isCellNumberVerified,
  actions,
}) => {
  const [activeTab, setActiveTab] = useState("tab-0");
  useEffect(() => {
    actions.initUserComponent();
  }, [actions, actions.initUserComponent]);

  const handleChangeTab = (_, tab) => {
    setActiveTab(tab);
  };

  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      actions.validateAuthentication();
      actions.showSnackNotificationAction(
        "The account has been saved successfully"
      );
      actions.closeModalDialog();
    },
  });

  return (
    <Container>
      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label="Details" value="tab-0" />
          <Tab label="Security" value="tab-1" />
        </Tabs>
      </AppBar>
      <TabPanel value={activeTab} index="tab-0">
        <UserDetailsForm
          userData={userData}
          isCellNumberVerified={isCellNumberVerified}
          actions={actions}
        />
      </TabPanel>
      <TabPanel value={activeTab} index="tab-1">
        <UpdatePassword />
      </TabPanel>
    </Container>
  );
};

AccountSettings.propTypes = {
  userData: PropTypes.object,
  dataComponent: propTypes.dataComponent,
  actions: PropTypes.object,
  isCellNumberVerified: PropTypes.bool,
};

export default AccountSettings;
