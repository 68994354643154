import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _isNil from "lodash/isNil";

import BankAccountForm from "./BankAccountForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";

import { LoaderContext } from "../../../../ui/Loader";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { handleRequestError } from "../../../../../utils/formValidationUtils";
import { processUpdateRequestStatus } from "../../../../../utils/dataComponentUtils";
import { dataComponentId } from "../ProjectsContainer";

export const processRequestResponse = (
  prevDataComponent,
  dataComponent,
  closeModalDialog,
  setReload,
  formikActions
) => {
  processUpdateRequestStatus(prevDataComponent, dataComponent, {
    onSuccess: () => {
      closeModalDialog();
      setReload(dataComponentId, true);
    },
    onError: error => handleRequestError(error, formikActions),
  });
};

export const EditBankAccountContainer = ({
  dataComponent,
  closeModalDialog,
  project,
  setReload,
  performUpdateRequest,
  loading,
}) => {
  const [prevDataComponent, setPrevDataComponent] = useState(dataComponent);
  const [formikActions, setFormikAction] = useState(null);

  useEffect(() => {
    setPrevDataComponent(dataComponent);
  }, [dataComponent]);

  useEffect(() => {
    processRequestResponse(
      prevDataComponent,
      dataComponent,
      closeModalDialog,
      setReload,
      formikActions
    );
  }, [
    closeModalDialog,
    dataComponent,
    formikActions,
    prevDataComponent,
    setReload,
  ]);

  const handleEditProject = useCallback(
    (project, formikActions) => {
      if (_isNil(project.accountingBankAccount)) {
        formikActions.setErrors({
          accountingBankAccount: "This field can't be empty",
        });
        return;
      }
      performUpdateRequest(dataComponentId, project.id, {
        ...project,
      });
      setFormikAction(formikActions);
    },
    [performUpdateRequest]
  );

  const initialValues = {
    id: project.id,
    accountingBankAccount: project.accountingBankAccount,
  };

  return (
    <LoaderContext.Provider value={{ loading }}>
      <BankAccountForm
        initialValues={initialValues}
        onSubmit={handleEditProject}
      />
    </LoaderContext.Provider>
  );
};

EditBankAccountContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  closeModalDialog: PropTypes.func,
  project: propTypes.project,
  performUpdateRequest: PropTypes.func,
  loading: PropTypes.bool,
  setReload: PropTypes.func,
};

export const mapDispatchToProps = {
  closeModalDialog,
  performUpdateRequest,
  setReload,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBankAccountContainer);
