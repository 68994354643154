import React from "react";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import styled from "styled-components";

import { ActionButtons, FormGrid } from "../../../../../forms";
import { dataComponentId } from "../../../connect";
import shipToSiteFields from "./shipToSiteFields";

const FormWrapper = styled.div`
  padding-bottom: 28px;
`;

const Form = ({
  shipToSite,
  onSubmit,
  setFieldValue,
  vendorSites,
  shipVendorId,
}) => {
  const fields = useMemo(
    () =>
      shipToSiteFields(shipToSite, vendorSites, shipVendorId, setFieldValue),
    [setFieldValue, shipToSite, shipVendorId, vendorSites]
  );

  return (
    <Fragment>
      <FormWrapper>
        <FormGrid fields={fields} />
      </FormWrapper>
      <ActionButtons
        listeners={[dataComponentId]}
        isModal={true}
        sendButtonText="CHANGE"
        onSend={onSubmit}
      />
    </Fragment>
  );
};

Form.propTypes = {
  shipToSite: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  vendorSites: PropTypes.array.isRequired,
  shipVendorId: PropTypes.string,
};

export default Form;
