import React from "react";
import PropTypes from "prop-types";

import WrapperDetailRow from "./WrapperDetailRow";
import RowComponent from "./RowComponent";
import { buildTextAreaColumn } from "../gridOptions";
import memoizeOne from "memoize-one";
import { Cell } from "../../../../ui/BWGrid/helperComponents/EditableTextDetailRow/components";

const DetailCellComponent = ({ children }) => (
  <Cell column={{}} colSpan={8}>
    {children}
  </Cell>
);

DetailCellComponent.propTypes = {
  children: PropTypes.object,
};

const tableComponents = memoizeOne(onUpdateAttributes => {
  return {
    DetailRowComponent: detailRowComponent(onUpdateAttributes),
    WrapperDetailRow,
    RowComponent,
    DetailCellComponent,
  };
});

const detailRowComponent = memoizeOne(onUpdateAttributes => props =>
  buildTextAreaColumn(
    onUpdateAttributes,
    "",
    "forecastComment"
  )(props.row, "forecastComment")
);

export default tableComponents;
