import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Loader from "../../../../ui/Loader";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";

export const SummaryWrapper = styled.div`
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
export const SummaryLabelWrapper = styled.div`
  margin-right: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: rgba(0, 0, 0, 0.38);
`;
export const SummaryValueWrapper = styled.div`
  margin-right: 18px;
  font-family: Domine, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
`;

export const getPotentialTariff = (
  currencyFormatter,
  bidGroupVendor,
  extProductionTotal
) => {
  if (!bidGroupVendor.potentialTariff) {
    return "--";
  }

  const potentialTariff =
    bidGroupVendor.potentialTariffType === "$"
      ? bidGroupVendor.potentialTariff
      : (bidGroupVendor.potentialTariff / 100) * extProductionTotal;

  return currencyFormatter.format(potentialTariff);
};

const Summary = ({
  visible,
  bidGroupVendor,
  productionTotal,
  extProductionTotal,
  currency,
}) => {
  const currencyFormatter = useCurrencyFormatter(currency);
  if (!visible) return null;
  if (bidGroupVendor.isDraft) {
    return (
      <SummaryWrapper>
        <Loader height="28px" width="600px">
          <SummaryLabelWrapper>Potential Tariff</SummaryLabelWrapper>
          <SummaryValueWrapper>--</SummaryValueWrapper>
          <SummaryLabelWrapper>Production Total</SummaryLabelWrapper>
          <SummaryValueWrapper>--</SummaryValueWrapper>
          <SummaryLabelWrapper>Ext. Production Total</SummaryLabelWrapper>
          <SummaryValueWrapper>--</SummaryValueWrapper>
        </Loader>
      </SummaryWrapper>
    );
  }
  return (
    <SummaryWrapper>
      <Loader height="28px" width="600px">
        <SummaryLabelWrapper>Potential Tariff</SummaryLabelWrapper>
        <SummaryValueWrapper>
          {getPotentialTariff(
            currencyFormatter,
            bidGroupVendor,
            extProductionTotal
          )}
        </SummaryValueWrapper>
        <SummaryLabelWrapper>Production Total</SummaryLabelWrapper>
        <SummaryValueWrapper>
          {currencyFormatter.format(productionTotal)}
        </SummaryValueWrapper>
        <SummaryLabelWrapper>Ext. Production Total</SummaryLabelWrapper>
        <SummaryValueWrapper>
          {currencyFormatter.format(extProductionTotal)}
        </SummaryValueWrapper>
      </Loader>
    </SummaryWrapper>
  );
};

Summary.propTypes = {
  currency: PropTypes.shape({}),
  visible: PropTypes.bool,
  bidGroupVendor: PropTypes.shape({}),
  productionTotal: PropTypes.number,
  extProductionTotal: PropTypes.number,
};

export default Summary;
