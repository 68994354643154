import React, { Fragment, useMemo } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { numericOnBlur, numericOnChange } from "./utils";
import useScopedCurrency from "../hooks/useScopedCurrency";

const Prefix = styled.span`
  color: rgba(0, 0, 0, 0.54);
  margin-right: 4px;
`;

const CurrencyInput = ({
  inputRef,
  onChange,
  onBlur,
  maskProps = {},
  decimalScale,
  currency,
  allowNegative = false,
  onFocus,
  ...props
}) => {
  let scopedCurrency = useScopedCurrency();

  const name = props.name;
  const bindedOnChange = useMemo(() => numericOnChange(onChange, name), [
    onChange,
    name,
  ]);

  const bindedOnBlur = useMemo(() => numericOnBlur(onBlur, name), [
    onBlur,
    name,
  ]);

  const handleFocus = event => {
    event.target.select();
    onFocus(event);
  };

  const prefix = get(
    maskProps,
    "prefix",
    currency ? currency.symbol : scopedCurrency.symbol
  );

  return (
    <Fragment>
      {!isEmpty(String(props.value)) && !isEmpty(prefix) && (
        <Prefix>{prefix}</Prefix>
      )}
      <NumberFormat
        {...props}
        getInputRef={inputRef}
        onBlur={bindedOnBlur}
        onValueChange={bindedOnChange}
        decimalScale={decimalScale || 2}
        fixedDecimalScale
        thousandSeparator={","}
        decimalSeparator={"."}
        allowNegative={allowNegative}
        onFocus={handleFocus}
        {...maskProps}
        prefix=""
      />
    </Fragment>
  );
};

CurrencyInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  maskProps: PropTypes.shape({}),
  decimalScale: PropTypes.number,
  currency: PropTypes.object,
};

export default CurrencyInput;
