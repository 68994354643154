import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

import SearchResult from "./SearchResult";
import { StyledPopper, StyledMenuList } from "./components";

const instructions = (
  <div
    style={{
      padding: "0px 16px 16px 16px",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.54)",
    }}
  >
    <p>Search for any Project, PO, Spec, Invoice, Shipment, or BidGroup.</p>
    <br />
    <p>
      You can search across any of these 6 types simply by entering any part of
      the ID/Number that identifies each. E.g. ‘30’, etc.
    </p>
    <br />
    <p>
      You can search only for a specific type by prefixing your Search with
      either of “Project, PO, Spec, Invoice, Ship, BG” followed by “#” and the
      beginning of the ID/Number. E.g. ‘Spec #BAL’, ‘Project #20’, etc. What
      follows the “#” must be the start of the ID/Number, it will not search
      within the ID/Number as in the first option.
    </p>
  </div>
);

const isLoadingOrEmpty = (showInstructions, loading, results) =>
  !showInstructions && (loading || (!loading && results.length === 0));

const ResultList = ({
  openMenu,
  onClose,
  anchorEl,
  results,
  onClick,
  loading,
  menuEl,
  showInstructions,
}) => {
  const items = results.map(item => (
    <SearchResult key={item.id} onClick={onClick} item={item} />
  ));
  const infoItem = (
    <MenuItem>
      {loading ? "Loading..." : results.length === 0 && "No Results"}{" "}
    </MenuItem>
  );

  const showInfoItem = isLoadingOrEmpty(showInstructions, loading, results);

  return (
    <StyledPopper
      open={openMenu}
      onClose={onClose}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <StyledMenuList ref={menuEl}>
              {showInfoItem ? infoItem : items}
            </StyledMenuList>
            {showInstructions ? instructions : null}
          </Paper>
        </Grow>
      )}
    </StyledPopper>
  );
};

ResultList.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
};

ResultList.defaultProps = {
  results: [],
};

export default ResultList;
