import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import propTypes from "../../../../constants/propTypes";
import {
  showSnackNotificationAction,
  closeModalDialog,
} from "../../../../actions/layoutActions";
import { performDeleteRequest } from "../../../../actions/dataComponentActions";
import { processDeleteRequestStatus } from "../../../../utils/dataComponentUtils";
import { getDataComponent } from "../../../../reducers/dataComponentReducer";
import DeleteConfirmation from "../../../ui/DeleteConfirmation";

const dataComponentId = "EntitiesGrid";

export class DeleteEntityContainer extends Component {
  componentDidUpdate({ dataComponent: prevDataComponent }) {
    const { dataComponent } = this.props;

    processDeleteRequestStatus(prevDataComponent, dataComponent, {
      onSuccess: this.handleDeleteRequestSuccess,
      onError: this.handleDeleteRequestError,
    });
  }

  handleDeleteRequestSuccess = () => {
    const { closeModalDialog, backToEntities, push, clientId } = this.props;
    if (backToEntities) {
      push(`/clients/${clientId}/entities`);
    }
    closeModalDialog();
  };

  handleDeleteRequestError = ({ data }) => {
    const { showSnackNotificationAction } = this.props;

    data.errors.map(error => {
      if (error.global) {
        showSnackNotificationAction(error.title);
      }
    });
  };

  deleteEntity = () => {
    const { entityId, performDeleteRequest } = this.props;
    performDeleteRequest(dataComponentId, entityId);
  };

  render() {
    const { title } = this.props;
    return <DeleteConfirmation title={title} onDelete={this.deleteEntity} />;
  }
}

DeleteEntityContainer.propTypes = {
  title: PropTypes.string.isRequired,
  backToEntities: PropTypes.bool,
  entityId: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  dataComponent: propTypes.dataComponent.isRequired,
  performDeleteRequest: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const dataComponent = getDataComponent(dataComponentId, state);
  return {
    dataComponent,
  };
};

const mapDispatchToProps = {
  performDeleteRequest,
  closeModalDialog,
  showSnackNotificationAction,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEntityContainer);
