import { useEffect } from "react";
import * as REQUEST_TYPES from "../../../../../constants/RequestTypes";

import {
  fetchGlobalVendors,
  dataComponentId,
} from "../../../../../actions/vendorPortalActions";
import useActions from "../../../../hooks/useActions";
import useVendorScopeStore from "../../../../hooks/useVendorScopeStore";
import { useV2DatacomponentResources } from "../../../../hooks/useResources";

const VendorPicker = () => {
  const actions = useActions({ fetchGlobalVendors });
  const selectVendor = useVendorScopeStore(state => state.selectVendor);

  useEffect(() => {
    actions.fetchGlobalVendors();
  }, [actions]);

  const vendorList = useV2DatacomponentResources(
    dataComponentId,
    [],
    REQUEST_TYPES.LIST
  );

  useEffect(() => {
    if (vendorList.length > 0) {
      selectVendor(vendorList[0]);
    }
  }, [vendorList, selectVendor]);

  return null;
};

export default VendorPicker;
