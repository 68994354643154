import React from "react";
import PropTypes from "prop-types";
import { inputs } from "../../inputs/inputConfigs";
import { Label, Header, StyledHeaderRow, StyledHeaderCell } from "./components";
import Select from "../../mui/core/Select";
import { MenuItem } from "../../mui/core";

const Filter = ({
  projectReportCategoryId,
  reportCategories,
  handleChangeReportCategoryId,
}) => (
  <Header>
    <StyledHeaderRow>
      <Label>Category:</Label>
      <StyledHeaderCell>
        <Select
          {...inputs.projectReportCategoryId}
          value={projectReportCategoryId}
          onChange={({ target: { value } }) =>
            handleChangeReportCategoryId(value)
          }
        >
          <MenuItem value={"-1"} key={-1}>
            All
          </MenuItem>
          {reportCategories.map(({ id, name }, key) => (
            <MenuItem value={id} key={key}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </StyledHeaderCell>
    </StyledHeaderRow>
  </Header>
);

Filter.propTypes = {
  projectReportCategoryId: PropTypes.string,
  handleChangeReportCategoryId: PropTypes.func,
  reportCategories: PropTypes.arrayOf(PropTypes.object),
};

Filter.defaultProps = {
  reportCategories: [],
};

export default Filter;
