import React from "react";
import PropTypes from "prop-types";

import DangerConfirmation from "./DangerConfirmation";

const DeleteConfirmation = ({ title, onDelete, ...buttonsProps }) => {
  return (
    <DangerConfirmation
      dangerButtonText="Delete"
      actionMessage={`delete ${title}`}
      onDanger={onDelete}
      {...buttonsProps}
    />
  );
};

DeleteConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmation;
