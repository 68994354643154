import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash/get";

import RequirementForm from "./RequirementForm";
import { closeModalDialog } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";

const CreateEditRequirementContainer = ({
  formikArrayHelpers,
  closeModalDialog,
  selectedRequirements,
  specRequirement,
  index,
}) => {
  const isCreate = !specRequirement;
  const action = isCreate ? "create" : "edit";

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    _get(specRequirement, "requirement")
  );

  const handleCreate = () => {
    selectedItems.forEach(item => {
      formikArrayHelpers.push({
        requirement: item,
        submittalApprovedId: null,
        files: 0,
      });
    });
    closeModalDialog();
  };

  const handleEdit = () => {
    formikArrayHelpers.replace(index, {
      ...specRequirement,
      requirementId: undefined,
      requirement: selectedItem,
    });
    closeModalDialog();
  };

  const formMap = {
    create: {
      submitHandler: handleCreate,
      selectHandler: setSelectedItems,
      value: selectedItems,
    },
    edit: {
      submitHandler: handleEdit,
      selectHandler: setSelectedItem,
      value: selectedItem,
    },
  };

  const handleSelectedItem = ({ target: { value } }) => {
    formMap[action].selectHandler(value);
  };

  return (
    <RequirementForm
      onSubmit={formMap[action].submitHandler}
      selectedRequirements={selectedRequirements}
      specRequirement={specRequirement}
      value={formMap[action].value}
      onChange={handleSelectedItem}
    />
  );
};
CreateEditRequirementContainer.propTypes = {
  closeModalDialog: PropTypes.func,
  formikArrayHelpers: PropTypes.shape({}),
  specRequirement: propTypes.specRequirement,
  selectedRequirements: PropTypes.arrayOf(propTypes.requirement),
  index: PropTypes.number,
};

const mapDispatchToProps = {
  closeModalDialog,
};

export default connect(
  null,
  mapDispatchToProps
)(CreateEditRequirementContainer);
