import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { Button } from "../../../mui/core";
import CategoryList from "./CategoryList";
import CheckboxList from "./CheckboxList";
import { noteCreateStart } from "../../../../actions/notesActions";
import { generateAndDownloadReport } from "../../../../actions/reportsActions";
import { buildQuery } from "../NoteList/queryFunctions";
import propTypes from "../../../../constants/propTypes";
import { getDataComponentFlattenedRequestState } from "../../../../reducers/dataComponentReducer";
import { dataComponentId } from "../NoteList/NoteListContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tooltip } from "@material-ui/core";

import ExportConfirm from "./ExportConfirm";
import NoteIcon from "../NoteIcon";

import { TooltipWrapper } from "./TooltipWrapper";

const IconContainer = styled.div`
  margin-right: 8px;

  @media (max-width: 1170px) {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 269px;
  padding: 0 24px;
  overflow: auto;
  max-height: 75vh;
  min-width: min-content;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ExportButton = styled(props => (
  <div style={{ textAlign: "center" }}>
    <Button {...props} />
  </div>
))`
  justify-content: flex-start;
  padding: 0;
  &:hover {
    background-color: unset;
  }

  @media (max-width: 1170px) {
    justify-content: space-around;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: rgba(0, 0, 0, 0.26);
`;

const FlowingText = styled.div`
  @media (max-width: 1170px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: initial;
  }
`;

const NoteIconWrapper = styled.div`
  display: none;
  @media (max-width: 1170px) {
    display: initial;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavBarContainer = ({
  noteCreateStart,
  isCreateActive,
  generateAndDownloadReport,
  filters,
  context,
  loggedInUserId,
  notesCount,
  notesAreExporting,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleExportNotes = () => {
    generateAndDownloadReport({
      format: "pdf",
      templateName: "notes",
      filter: {
        $where: buildQuery(filters, context, { loggedInUserId }),
      },
    });
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Button
        variant="contained"
        color="primary"
        onClick={noteCreateStart}
        disabled={isCreateActive}
      >
        <FlowingText>CREATE NOTE</FlowingText>
        <Tooltip title="Create Note">
          <NoteIconWrapper>
            <NoteIcon category={"Create Note"} />
          </NoteIconWrapper>
        </Tooltip>
      </Button>
      <CategoryList />
      <CheckboxList />
      <ExportButton
        disabled={!notesCount || notesAreExporting}
        color="secondary"
        disableRipple
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <IconContainer>
          {notesAreExporting ? (
            <StyledCircularProgress size={24} />
          ) : (
            <TooltipWrapper title="Export Notes">
              <AssignmentIcon />
            </TooltipWrapper>
          )}
        </IconContainer>
        <FlowingText>EXPORT NOTES</FlowingText>
      </ExportButton>
      <ExportConfirm
        count={notesCount}
        anchorEl={anchorEl}
        onSubmit={handleExportNotes}
        onClose={() => setAnchorEl(null)}
      />
    </Wrapper>
  );
};

NavBarContainer.propTypes = {
  noteCreateStart: PropTypes.func.isRequired,
  generateAndDownloadReport: PropTypes.func.isRequired,
  isCreateActive: PropTypes.bool.isRequired,
  notesCount: PropTypes.number,
  notesAreExporting: PropTypes.bool,
  loggedInUserId: PropTypes.string,
  filters: propTypes.notesFilters,
  context: propTypes.notesContext,
};

const mapStateToProps = state => {
  const { isCreateActive, filters, context } = state.notes;
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );

  return {
    notesCount: dataComponent.totalRows,
    loggedInUserId: state.auth.userId,
    filters,
    context,
    isCreateActive,
    notesAreExporting: state.reports.loaders["notes-pdf-false"],
  };
};

const mapDispatchToProps = {
  noteCreateStart,
  generateAndDownloadReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer);
