import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocationIcon from "@material-ui/icons/LocationOn";
import styled from "styled-components";

import propTypes from "../../../../../constants/propTypes";
import BWGrid from "../../../../ui/BWGrid/BWGrid";
import GridHeader from "../../../../ui/BWGrid/GridHeader";
import GridTable from "../../../../ui/BWGrid/GridTable";
import LocationCombined from "../../../../ui/LocationCombined";

const Wrapper = styled.div`
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
`;

const columns = ["name", "location.address"];
const columnsOptions = {
  name: { bold: true },
  "location.address": {
    fill: true,
    title: "Address",
    render: LocationCombined,
  },
};
const getRowMenu = (onEdit, onDelete) => row => {
  if (row.isSelectedProperty) return null;
  return [
    { text: "Edit", onClick: onEdit },
    { separator: true },
    { text: "Delete", onClick: onDelete },
  ];
};

const ProjectAddressGrid = ({
  rows,
  onOpenAddModal,
  onOpenEditModal,
  onOpenDeleteModal,
}) => {
  const rowMenu = useMemo(
    () => getRowMenu(onOpenEditModal, onOpenDeleteModal),
    [onOpenEditModal, onOpenDeleteModal]
  );
  const gridConfig = useMemo(() => ({ pageSize: 0, totalRows: rows.length }), [
    rows.length,
  ]);

  const gridHeaderActions = useMemo(
    () => [
      {
        text: "ADD ADDRESS",
        icon: <LocationIcon />,
        handler: onOpenAddModal,
      },
    ],
    [onOpenAddModal]
  );

  return (
    <Wrapper>
      <BWGrid gridConfig={gridConfig} rows={rows}>
        <GridHeader
          subheaderText="Project Addresses"
          actions={gridHeaderActions}
        />
        <GridTable
          columns={columns}
          columnOptions={columnsOptions}
          rowMenu={rowMenu}
        />
      </BWGrid>
    </Wrapper>
  );
};
ProjectAddressGrid.propTypes = {
  rows: PropTypes.arrayOf(propTypes.contingency),
  onOpenAddModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenDeleteModal: PropTypes.func.isRequired,
};

export default ProjectAddressGrid;
