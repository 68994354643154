import Project from "../models/Project";
import Client from "../models/Client";
import ProjectService from "../models/ProjectService";
import {
  initDataComponent,
  performRetrieveListRequest,
  performCreateRequest,
  performUpdateRequest,
  fetchDataRequest,
  performFindRequest,
  destroyDataComponentResource,
  fetchDataSuccess,
  fetchDataFailed,
} from "./dataComponentActions";
import * as REQUEST_TYPES from "../constants/RequestTypes";
import { uploadFile } from "./filesActions";
import ProjectAddress from "../models/ProjectAddress";
import ProjectAreaPrice from "../models/ProjectAreaPrice";
import ProjectSite from "../models/ProjectSite";
import BidGroup from "../models/BidGroup";
import PurchaseOrder from "../models/PurchaseOrder";
import FundingPayment from "../models/FundingPayment";
import { sendPost } from "./dataComponents/v2";
import { getDataComponent } from "../reducers/dataComponentReducer";
import WorkScope from "../models/WorkScope";
export const dataComponentId = "ProjectDetail";

const dataClientComponentId = "ClientDetail";
const dataBillingComponentId = "billingPage";
const dataComponentIdCreate = "ProjectCreate";
const dataComponentIdUpdate = "ProjectEdit";
export const dataComponentIdDeleteAddress = "DeleteAddress";
export const dataComponentIdDeleteSite = "DeleteSite";

export const rfqFilterDataComponent = "RFQFilterDataComponent";
export const poFilterDataComponent = "POFilterDataComponent";

export const selects = [
  {
    dataComponentId: "select-services",
    model: ProjectService,
    includes: [],
    apiRoute: "project-services",
  },
  {
    dataComponentId: "select-work-scopes",
    model: WorkScope,
    includes: ["workScopeSubWorkScopes.[workScope,subWorkScope]"],
    apiRoute: "work-scopes",
  },
];

export function initProjectDetail(projectId) {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        Project,
        [
          "property.[entity.client.scope,location]",
          "projectMemberUsers.user",
          "projectMemberContacts.contact",
          "areas.[areaType,areaRooms]",
          "remitAddresses",
          "projectLead",
          "projectManager",
          "designer",
          "generalContractor",
          "otherConsultant",
          "office.scope",
          "notesAuthor",
          "projectCurrencies.currency",
          "projectSubWorkScopes.workScopeSubWorkScope.[workScope,subWorkScope]",
          "workScopes.workScopeSubWorkScopes.subWorkScope",
          "clientPricing.pricingTiers",
          "contractFiles",
        ],
        "projects"
      )
    );
    dispatch(performFindRequest(dataComponentId, projectId));
  };
}

export function initComponents(dataComponentId, clientId) {
  return async dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        Project,
        [
          "projectMemberUsers.user",
          "projectMemberContacts.contact",
          "areas.[areaType,areaRooms]",
          "addresses.location",
          "sites.[location,contacts]",
          "property.entity",
          "contingencies",
          "contractFiles.file",
          "waiverFile",
          "projectLead",
          "projectCurrencies",
          "projectSubWorkScopes.workScopeSubWorkScope.[workScope,subWorkScope]",
          "workScopes.workScopeSubWorkScopes.subWorkScope",
          "clientPricing.pricingTiers",
        ],
        "projects",
        false,
        "v2"
      )
    );

    dispatch(
      initDataComponent(
        dataComponentIdDeleteAddress,
        ProjectAddress,
        [],
        "project-addresses"
      )
    );

    dispatch(
      initDataComponent(
        dataComponentIdDeleteSite,
        ProjectSite,
        [],
        "project-sites"
      )
    );

    dispatch(
      initDataComponent(
        dataClientComponentId,
        Client,
        ["clientPricings.pricingTiers"],
        "clients",
        false,
        "v2"
      )
    );
    dispatch(performFindRequest(dataClientComponentId, clientId));

    dispatch(
      performRetrieveListRequest(dataComponentId, {
        params: { nextNumber: true, clientId },
      })
    );

    selects.forEach(
      ({ dataComponentId, model, includes, apiRoute, rootFilters }) => {
        const proccessedFilters =
          typeof rootFilters === "function"
            ? rootFilters({ clientId })
            : rootFilters;
        dispatch(
          initDataComponent(
            dataComponentId,
            model,
            includes,
            apiRoute,
            false,
            "v2"
          )
        );

        dispatch(
          performRetrieveListRequest(dataComponentId, {
            rootFilters: proccessedFilters,
            pageSize: -1,
            sort: [{ columnName: "name", direction: "asc" }],
          })
        );
      }
    );
  };
}

export function initRFQListFilter(projectId) {
  return function(dispatch) {
    dispatch(
      initDataComponent(
        rfqFilterDataComponent,
        BidGroup,
        [],
        "bid-groups",
        false,
        "v2"
      )
    );

    dispatch(
      performRetrieveListRequest(rfqFilterDataComponent, {
        rootFilters: {
          $where: {
            projectId,
          },
        },
        pageSize: -1,
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
        fields: ["bid_groups.id", "rfqNumber", "sequenceIndex"],
      })
    );
  };
}

export function initPOListFilter(projectId) {
  return function(dispatch) {
    dispatch(
      initDataComponent(
        poFilterDataComponent,
        PurchaseOrder,
        [],
        "purchase-orders",
        false,
        "v2"
      )
    );

    dispatch(
      performRetrieveListRequest(poFilterDataComponent, {
        rootFilters: {
          $where: { projectId, status: { $notEqual: "Canceled" } },
        },
        pageSize: -1,
        sort: [{ columnName: "sequenceIndex", direction: "asc" }],
        params: {
          omitDefaultModifier: true,
        },
        fields: ["purchase_orders.id", "sequenceIndex"],
      })
    );
  };
}

export function destroyComponents(dataComponentId) {
  return dispatch => {
    dispatch(destroyDataComponentResource(dataComponentId, REQUEST_TYPES.FIND));
    dispatch(
      destroyDataComponentResource(dataClientComponentId, REQUEST_TYPES.LIST)
    );
    selects.forEach(({ dataComponentId }) => {
      dispatch(
        destroyDataComponentResource(dataComponentId, REQUEST_TYPES.LIST)
      );
    });
  };
}

export function uploadFiles(project, projectId) {
  return async dispatch => {
    const files = ["waiverFile"]
      .filter(field => project[field] && project[field].isUpdated)
      .map(field => {
        const { isUpdated, ...file } = project[field];
        return {
          field,
          file,
        };
      });
    const key = projectId ? `projects/${projectId}` : "projects/temp";
    return await Promise.all(
      files.map(async ({ field, file: { file, ...fileWrapper } }) => {
        const ext = file.name.split(".").pop();
        const filename = projectId
          ? `${field}.${ext}`
          : `${Date.now()}-${field}.${ext}`;
        await dispatch(uploadFile({ key, filename }, file));
        return {
          ...fileWrapper,
          field,
          s3Key: `${key}/${filename}`,
          filename: file.name,
        };
      })
    );
  };
}

export function uploadContracts(project, projectId) {
  return async dispatch => {
    const contractFiles = project.contractFiles;
    if (!contractFiles) return;

    const key = projectId ? `projects/${projectId}/contracts` : "projects/temp";
    return await Promise.all(
      contractFiles.map(async ({ file: { file }, ...contractFile }) => {
        if (!file) return contractFile;

        const filename = `${Date.now()}-${file.name}`;
        await dispatch(uploadFile({ key, filename }, file));
        return {
          ...contractFile,
          file: {
            s3Key: `${key}/${filename}`,
            filename: file.name,
            metadata: { size: file.size },
          },
        };
      })
    );
  };
}

export const fetchForecastByArea = (projectId, dataComponentId) => {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        ProjectAreaPrice,
        [],
        `projects/${projectId}/area-prices`,
        undefined,
        "v2"
      )
    );
    dispatch(performRetrieveListRequest(dataComponentId));
  };
};
export const fetchFundingRequests = (projectId, dataComponentId) => {
  return dispatch => {
    dispatch(
      initDataComponent(
        dataComponentId,
        FundingPayment,
        [],
        "fundings",
        undefined,
        "v2"
      )
    );
    dispatch(
      performRetrieveListRequest(dataComponentId, {
        pageSize: -1,
        sort: [{ columnName: "receivedAt", direction: "desc" }],
        rootFilters: {
          $where: {
            projectId: projectId,
            status: { $notIn: ["Void"] },
          },
        },
      })
    );
  };
};

function percentToDecimal(project, property) {
  if (project[property]) {
    const significanDecimals = 1000000;
    project[property] =
      Math.round(project[property] * 100 * significanDecimals) /
      significanDecimals;
  }
}

function decimalToPercent(project, property) {
  if (project[property]) {
    project[property] = project[property] / 100;
  }
}

export function parseOnLoad(project) {
  percentToDecimal(project, "salesTaxPercent");
  percentToDecimal(project, "salesTaxTolerancePercent");
  percentToDecimal(project, "useTaxPercent");
  percentToDecimal(project, "feeAmount");
  percentToDecimal(project, "overLimitFee");
  return project;
}

export function parseOnSaveProjectValues(project) {
  decimalToPercent(project, "salesTaxPercent");
  decimalToPercent(project, "salesTaxTolerancePercent");
  decimalToPercent(project, "useTaxPercent");
  decimalToPercent(project, "feeAmount");
  decimalToPercent(project, "overLimitFee");
  return project;
}

export function checkBrandDependencies(project) {
  if (project.brandId === null) {
    project.flagId = null;
  }
  if (project.flagId === null) {
    project.programId = null;
  }
}

export function duplicateProject(projectId, dataComponentId) {
  return async function(dispatch, getState) {
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.CREATE));
    const dataComponent = getDataComponent(dataComponentId, getState());
    const apiRoot = "/";

    try {
      const requestStatePayload = await sendPost(
        dataComponent,
        {},
        `${apiRoot}projects/${projectId}/clone`,
        REQUEST_TYPES.CREATE
      );

      dispatch(
        fetchDataSuccess(
          dataComponentId,
          REQUEST_TYPES.CREATE,
          requestStatePayload
        )
      );
      return requestStatePayload;
    } catch (error) {
      dispatch(fetchDataFailed(dataComponentId, REQUEST_TYPES.CREATE, error));
      throw error;
    }
  };
}

export function createProject({ ...project }) {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentIdCreate, REQUEST_TYPES.CREATE));
    const files = await dispatch(uploadFiles(project));
    files.reduce((project, { field, ...file }) => {
      project[field] = file;
      return project;
    }, project);

    project.contractFiles = await dispatch(uploadContracts(project));

    const userAndContactProjects = {
      projectMemberUsers: project.projectMemberUsers.map(
        ({ memberType, userId }) => ({
          memberType,
          userId,
        })
      ),
      projectMemberContacts: project.projectMemberContacts.map(
        ({ memberType, contactId }) => ({
          memberType,
          contactId,
        })
      ),
    };

    if (project.workScopes) {
      project.workScopes = project.workScopes.map(({ id }) => ({
        id,
      }));
    }
    if (project.projectSubWorkScopes) {
      project.projectSubWorkScopes = project.projectSubWorkScopes.map(
        ({ workScopeSubWorkScope, ...projectSubWorkScope }) => ({
          ...projectSubWorkScope,
        })
      );
    }
    checkBrandDependencies(project);
    dispatch(
      performCreateRequest(
        dataComponentIdCreate,
        parseOnSaveProjectValues({
          ...project,
          ...userAndContactProjects,
        })
      )
    );
  };
}

export function initEditProject(dataComponentId = dataComponentIdUpdate) {
  return initDataComponent(dataComponentId, Project, [], "projects");
}

export function updateProject(
  projectId,
  { ...project },
  dataComponentId = dataComponentIdUpdate
) {
  return async dispatch => {
    dispatch(fetchDataRequest(dataComponentId, REQUEST_TYPES.UPDATE));
    const files = await dispatch(uploadFiles(project, projectId));
    files.reduce((project, { field, ...file }) => {
      project[field] = file;
      return project;
    }, project);

    project.contractFiles = await dispatch(uploadContracts(project, projectId));

    const attributes = {};
    if (project.projectMemberUsers) {
      attributes.projectMemberUsers = project.projectMemberUsers.map(
        ({ memberType, userId }) => ({
          memberType,
          userId,
        })
      );
    }
    if (project.projectMemberContacts) {
      attributes.projectMemberContacts = project.projectMemberContacts.map(
        ({ memberType, contactId }) => ({
          memberType,
          contactId,
        })
      );
    }
    if (project.areas) {
      attributes.areas = project.areas.map(({ areaType, ...area }) => ({
        ...area,
        areaTypeId: areaType.id,
      }));
    }
    if (project.workScopes) {
      attributes.workScopes = project.workScopes.map(({ id }) => ({
        id,
      }));
    }
    if (project.projectSubWorkScopes) {
      attributes.projectSubWorkScopes = project.projectSubWorkScopes.map(
        ({ workScopeSubWorkScope, ...projectSubWorkScope }) => ({
          ...projectSubWorkScope,
        })
      );
    }
    checkBrandDependencies(project);
    dispatch(
      performUpdateRequest(
        dataComponentId,
        projectId,
        parseOnSaveProjectValues({
          ...project,
          ...attributes,
        })
      )
    );
  };
}

export function fetchProjects(
  options = {},
  dataComponentId = dataBillingComponentId
) {
  return async dispatch => {
    const { includes, ...filterOptions } = options;
    dispatch(
      initDataComponent(
        dataComponentId,
        Project,
        includes,
        "projects",
        undefined,
        "v2"
      )
    );
    dispatch(performRetrieveListRequest(dataComponentId, filterOptions));
  };
}

export const fetchClientById = (dataComponentId, clientId) => {
  return dispatch => {
    dispatch(
      initDataComponent(dataComponentId, Client, [], "clients", false, "v2")
    );
    dispatch(performFindRequest(dataClientComponentId, clientId));
  };
};
