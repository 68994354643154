import React, { useEffect } from "react";
import shallow from "zustand/shallow";
import _get from "lodash/get";

import { setModalDialogTitle } from "../../../../../actions/layoutActions";
import propTypes from "../../../../../constants/propTypes";
import useActions from "../../../../hooks/useActions";
import useAddSpecToPOStore, {
  FORM_IDS,
} from "../../../../hooks/useAddSpecToPOStore";
import MainOptions from "./MainOptions";
import CreateSpec from "./CreateSpec";
import MoveSpec from "./MoveSpec";
import CopySpec from "./CopySpec";
import { destroyFilters } from "../../../../../actions/purchaseOrderSpecsActions";
import DuplicateSpec from "./DuplicateSpec";

const specToPoStoreSelector = state => [state.formId, state.actions];

const AddSpecToPO = ({ purchaseOrder }) => {
  const [formId, stateActions] = useAddSpecToPOStore(
    specToPoStoreSelector,
    shallow
  );

  const actions = useActions({ setModalDialogTitle, destroyFilters });

  useEffect(() => {
    stateActions.setPO(purchaseOrder);
    actions.setModalDialogTitle([
      "Add Spec",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ]);
    return () => {
      stateActions.resetStore();
      actions.destroyFilters();
    };
  }, [stateActions, actions, purchaseOrder]);

  switch (formId) {
    case FORM_IDS.MAIN: {
      return <MainOptions />;
    }
    case FORM_IDS.CREATE_SPEC: {
      return <CreateSpec />;
    }
    case FORM_IDS.DUPLICATE_SPEC: {
      return <DuplicateSpec />;
    }
    case FORM_IDS.COPY_SPEC: {
      return <CopySpec />;
    }
    case FORM_IDS.MOVE_SPEC: {
      return <MoveSpec />;
    }
  }
};

AddSpecToPO.propTypes = { purchaseOrder: propTypes.purchaseOrder };

export default AddSpecToPO;
