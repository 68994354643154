import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { openBulkDeleteSpecDetails } from "../../../../../actions/specDetailActions";
import SelectionActions from "./SelectionActions";

export const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ openBulkDeleteSpecDetails }, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SelectionActions);
