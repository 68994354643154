import React, {
  createContext,
  memo,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import _isEmpty from "lodash/isEmpty";

import verticalNavigationFunctions from "./verticalNavigationFuntions";
import horizontalNavigationFuntions from "./horizontalNavigationFuntions";

export const defaultValue = {
  addCell: _noop,
  removeCell: _noop,
  openNextCell: _noop,
  openPrevCell: _noop,
  setCurrentCell: _noop,
  currentCell: {},
};

const navigationFns = {
  vertical: verticalNavigationFunctions,
  horizontal: horizontalNavigationFuntions,
};

export const WithEditableCellContext = createContext(defaultValue);

const createNavigate = ({
  currentCell,
  getCell,
  cellsInfo,
  setCurrentCell,
}) => fallback => {
  const { rowId, columnId } = currentCell;
  const cell = getCell(cellsInfo.current, rowId, columnId);

  setCurrentCell(cell);
  if (_isEmpty(cell) && fallback) {
    fallback();
  }
};

function withEditableCellController(WrappedComponent) {
  const EditableCellControllerWrapper = ({
    editableNavigationDirection,
    ...props
  }) => {
    const [currentCell, setCurrentCell] = useState({});
    const { getNextCell, getPrevCell } = navigationFns[
      editableNavigationDirection
    ];

    // A matrix with booleans values that check what cells are editable and its order
    const cellsInfo = useRef([]);

    const addCell = useCallback((rowId, columnId) => {
      if (!cellsInfo.current[columnId]) {
        cellsInfo.current[columnId] = [];
      }
      cellsInfo.current[columnId][rowId] = true;
      return cellsInfo.current;
    }, []);

    const removeCell = useCallback((rowId, columnId) => {
      cellsInfo.current[columnId][rowId] = false;
      return cellsInfo.current;
    }, []);

    const openNextCell = useCallback(
      createNavigate({
        currentCell,
        setCurrentCell,
        getCell: getNextCell,
        cellsInfo,
      }),
      [currentCell, getNextCell]
    );

    const openPrevCell = useCallback(
      createNavigate({
        currentCell,
        setCurrentCell,
        getCell: getPrevCell,
        cellsInfo,
      }),
      [currentCell, getPrevCell]
    );

    const value = useMemo(
      () => ({
        addCell,
        removeCell,
        setCurrentCell,
        currentCell,
        openNextCell,
        openPrevCell,
      }),
      [addCell, removeCell, currentCell, openNextCell, openPrevCell]
    );

    return (
      <WithEditableCellContext.Provider value={value}>
        <WrappedComponent {...props} />
      </WithEditableCellContext.Provider>
    );
  };

  EditableCellControllerWrapper.defaultProps = {
    editableNavigationDirection: "vertical",
  };

  EditableCellControllerWrapper.propTypes = {
    editableNavigationDirection: PropTypes.oneOf(["vertical", "horizontal"]),
  };

  return memo(EditableCellControllerWrapper);
}

export default withEditableCellController;
