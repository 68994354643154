import React from "react";
import styled from "styled-components";
import Cell from "../../../../ui/Grid/utilComponents/tableComponents/Cell";

export const StyledModalContent = styled.div`
  padding-top: 24px;
`;

export const StyledContentTitle = styled.span`
  font-weight: bold;
  margin-left: 24px;
`;

const StyledCell = styled(
  ({ rowSpan, showBorderBottom, wrapCell, isNotesColumn, ...props }) => (
    <Cell {...props} rowSpan={rowSpan} />
  )
)`
  border-bottom: ${({ showBorderBottom }) =>
    showBorderBottom ? "1px solid rgba(224, 224, 224, 1)" : "none"};

  ${({ isNotesColumn }) =>
    isNotesColumn &&
    ` 
      vertical-align: top;
      padding-top: 12px;
    `};

  ${({ wrapCell }) =>
    wrapCell &&
    `
    & > span {
      width: 200px;
      white-space: break-spaces;
    }`}
`;

export const CustomAlternateQuoteCell = ({ ...props }) => {
  const customProps = {
    showBorderBottom: props.row.displayBorderBottom,
  };

  const isNotesColumn = props.column.name === "notes";
  if (isNotesColumn && !props.row.displayNotes) {
    return null;
  }

  if (isNotesColumn) {
    customProps.rowSpan = props.row.quoteItemsNumber;
    customProps.showBorderBottom = !props.row.isLastQuote;
    customProps.wrapCell = true;
    customProps.isNotesColumn = true;
  }

  if (props.column.name === "description") {
    customProps.wrapCell = true;
  }

  return <StyledCell {...props} {...customProps} />;
};
