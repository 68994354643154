// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Invoice Model belongsTo relations 1`] = `
Array [
  "PurchaseOrder",
  "RemitAddress",
  "VendorContact",
]
`;

exports[`Invoice Model hasMany relations 1`] = `
Array [
  "GLCode",
  "InvoiceSpec",
]
`;
