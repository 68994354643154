import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ActionButtons from "../../../../forms/ActionButtons";
import { InputFile } from "../../../../inputs/FilePickerWithError";
import CheckboxWithError from "../../../../inputs/CheckboxWithError";
import { getImportRequestCallbacks } from "./importFunctions";
import { useQueueProcessRequests } from "../../../../hooks/useQueueProcess";
import { useSelector } from "react-redux";
import { queueId } from "../../../../../actions/shippingPaymentsActions";

export const shippingPaymentsFileKey = "billing-shipping-payments";

const Container = styled.div`
  padding: 16px;
`;

const TextWrapper = styled.div`
  white-space: pre-wrap;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  padding: 8px;
  max-height: calc(65vh - 72px);
  overflow-y: auto;

  @media (max-width: 600px) {
    max-height: calc(65vh - 120px);
  }
`;

export const Checkbox = styled(CheckboxWithError)`
  padding: 16px 0px 16px 0px;
`;

export const handleClick = (loading, inputEl) => evt => {
  if (loading) return;
  evt.stopPropagation();
  inputEl.current.click();
};

export const ImportShippingPayments = ({
  importShippingPaymentsFromXls,
  closeModalDialog,
  openModalDialog,
  setReload,
  showSnackNotificationAction,
  performRetrieveListRequest,
}) => {
  const [reupload, setReupload] = useState(false);
  const inputEl = useRef(null);

  const importQueue = useSelector(state => state.queues[queueId]) || {};
  const loading = importQueue.status === "PROCESSING";
  const importSummary = importQueue.returnvalue;

  const importRequestCallbacks = useMemo(
    () =>
      getImportRequestCallbacks({
        importSummary,
        openModalDialog,
        setReload,
        showSnackNotificationAction,
        performRetrieveListRequest,
        inputEl,
      }),
    [
      importSummary,
      openModalDialog,
      performRetrieveListRequest,
      setReload,
      showSnackNotificationAction,
    ]
  );

  useQueueProcessRequests(importQueue, importRequestCallbacks);

  const handleChange = useCallback(
    evt => {
      const [fileToUpload] = evt.target.files;
      if (fileToUpload) {
        importShippingPaymentsFromXls(fileToUpload, reupload);
      }
    },
    [importShippingPaymentsFromXls, reupload]
  );

  return (
    <Container>
      <TextWrapper>
        <p>
          <b>Please only upload XLS and XLSX files exported from UPS system.</b>
        </p>
        <br />
        <p>
          <b>
            The Project Number column is used for finding projects in the
            database.
          </b>
        </p>
        <br />
        <p>
          A file should be uploaded only once. If there was an issue with an
          uploaded file (e.g. project not found), you may correct the data in
          the file and reupload it by marking the checkbox down below. The data
          related to the previous upload will be removed and replaced with the
          data extracted from the new upload.
        </p>
        <br />
        <span>
          Here is the list of columns that the importing script will read from
          the uploaded file:
        </span>
        <ul>
          <li>Invoice Date</li>
          <li>invoice #</li>
          <li>Shipment Date</li>
          <li>Tracking Number</li>
          <li>Project Number</li>
          <li>Project Name</li>
          <li>BW Location</li>
          <li>Misc Info</li>
          <li>SumOfAmount Charged</li>
          <li>Sending Company</li>
          <li>Sender Address</li>
          <li>Sender Address 2</li>
          <li>Sender City</li>
          <li>Sender State</li>
          <li>Sender Zip Code</li>
          <li>Receiver Attn</li>
          <li>Receiver Name</li>
          <li>Receiver Address</li>
          <li>Receiver Address 2</li>
          <li>Receiver City</li>
          <li>Receiver State</li>
          <li>Receiver Zip Code</li>
          <li>Receiver Country</li>
        </ul>
      </TextWrapper>
      <Checkbox
        label={"Reupload"}
        value={reupload}
        onChange={() => (loading ? null : setReupload(!reupload))}
      />
      <ActionButtons
        sendButtonText="Upload"
        onSend={handleClick(loading, inputEl)}
        onCancel={closeModalDialog}
        additionalProps={{
          send: {
            isLoading: loading,
          },
        }}
      />
      <InputFile
        type="file"
        ref={inputEl}
        onChange={handleChange}
        accept=".xls,.xlsx"
      />
    </Container>
  );
};

ImportShippingPayments.propTypes = {
  importShippingPaymentsFromXls: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
  openModalDialog: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  performRetrieveListRequest: PropTypes.func.isRequired,
};
