import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import propTypes from "../../../../../../constants/propTypes";
import { LoaderContext } from "../../../../../ui/Loader";
import {
  initFeeSummaryDataComponent,
  fetchFeeSummaries,
} from "../../../../../../actions/feeSummaryActions";
import { getBluechipResources } from "../../../../../../utils/bluechipUtils";
import { getDataComponentFlattenedRequestState } from "../../../../../../reducers/dataComponentReducer";
import FeeSummary from "./FeeSummary";
export const dataComponentId = "freeSummaryId";

const FeeSummaryContainer = ({
  fetchFeeSummaries,
  initFeeSummaryDataComponent,
  projectId,
  feeSummary,
  dataComponent: { loading },
}) => {
  useEffect(() => {
    initFeeSummaryDataComponent();
    fetchFeeSummaries();
  }, [fetchFeeSummaries, initFeeSummaryDataComponent, projectId]);

  return (
    <LoaderContext.Provider value={{ loading }}>
      <FeeSummary feeSummary={feeSummary} />
    </LoaderContext.Provider>
  );
};

FeeSummaryContainer.propTypes = {
  fetchFeeSummaries: PropTypes.func.isRequired,
  initFeeSummaryDataComponent: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  feeSummary: propTypes.feeSummary,
  dataComponent: propTypes.dataComponent,
};

FeeSummaryContainer.defaultProps = {
  feeSummary: {},
};

export const mapStateToProps = state => {
  const dataComponent = getDataComponentFlattenedRequestState(
    dataComponentId,
    state
  );
  const results = getBluechipResources(dataComponent, state);
  return {
    dataComponent,
    feeSummary: results ? results[0] : {},
  };
};

export const mapDispatchToProps = (dispatch, { projectId }) =>
  bindActionCreators(
    {
      initFeeSummaryDataComponent: initFeeSummaryDataComponent(projectId),
      fetchFeeSummaries,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeSummaryContainer);
