import inputs from "../../../../inputs/inputConfigs/submittals";

export default disableSelectFile => ({
  groups: [
    {
      items: [
        {
          input: {
            ...inputs.submittalFile,
            disabled: disableSelectFile,
            inputFileProps: { disabled: disableSelectFile, isForm: true },
          },
          grid: { xs: 12 },
        },
        {
          input: inputs.submittalCreatedAt,
          grid: { xs: 6 },
        },
        {
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.submittalDescription,
            label: "Received",
            name: "receivedDescription",
          },
          grid: { xs: 6 },
        },
        {
          input: {
            ...inputs.submittalDescription,
            label: "Approved",
            name: "approvedDescription",
          },
          grid: { xs: 6 },
        },
      ],
    },
  ],
});
