import * as actionTypes from "../constants/ActionTypes";
import initialState from "./initialState";

export default function bidGroupsReducer(
  state = initialState.editableTextDetailRow,
  action
) {
  switch (action.type) {
    case actionTypes.SET_EDITABLE_ROW: {
      return {
        ...state,
        [action.payload.dataComponentId]: {
          isEditing: action.payload.isEditing,
        },
      };
    }
    default:
      return state;
  }
}
