import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import { FormikForm, ActionButtons, PromptIfDirty } from "../../forms";
import GeneralInformationSection from "./GeneralInformationSection/GeneralInformationSectionLayout";
import propTypes from "../../../constants/propTypes";
import { Content } from "../../layout/Content";
import { NavigationArea } from "../../layout/DetailAndCycle";
import useUserRole from "../../hooks/useUserRole";
import { isSuperAdmin } from "../../../utils/roleUtils";

export const getVendorSchema = (userRole, isUpdate) => {
  const schema = BWModels.loadSchema("Vendor");
  if (isSuperAdmin(userRole) && !isUpdate) {
    return schema.keys({
      scopeId: BWModels.Joi.selectFk().required(),
    });
  }
  return schema;
};

const initialValues = {
  name: "",
  categoryId: "",
  contacts: [],
  brands: [],
  location: {
    address: undefined,
    zip: undefined,
  },
  status: [],
  comments: "",
};

const VendorForm = ({
  vendor,
  onSubmit,
  goBack,
  isUpdate,
  sendButtonText,
  dataComponentId,
}) => {
  const userRole = useUserRole();
  const ignoreValidationFieldNames =
    userRole?.name !== "Super Admin" ? ["parentId", "scopeId"] : ["parentId"];
  return (
    <Content>
      {isUpdate && <NavigationArea hideActions />}
      <FormikForm
        onSubmit={onSubmit}
        initialValues={vendor && vendor.location ? vendor : initialValues}
        validationSchema={getVendorSchema(userRole, isUpdate)}
        ignoreValidationFieldNames={ignoreValidationFieldNames}
        autoSave={isUpdate}
        enableReinitialize={isUpdate}
      >
        {({ handleSubmit, values, errors, ...formikProps }) => (
          <Fragment>
            <GeneralInformationSection
              values={values}
              errors={errors}
              vendorSchema={getVendorSchema(userRole, isUpdate)}
              userRole={userRole}
              isUpdate={isUpdate}
              {...formikProps}
            />
            {!isUpdate && (
              <ActionButtons
                onCancel={goBack}
                onSend={handleSubmit}
                sendButtonText={sendButtonText}
                listeners={[dataComponentId]}
              />
            )}
            <PromptIfDirty />
          </Fragment>
        )}
      </FormikForm>
    </Content>
  );
};

VendorForm.propTypes = {
  goBack: PropTypes.func,
  onSubmit: PropTypes.func,
  vendor: propTypes.vendor,
  isUpdate: PropTypes.bool,
  sendButtonText: PropTypes.string,
  dataComponentId: PropTypes.string.isRequired,
  userRole: propTypes.userRole,
};

export default memo(VendorForm);
