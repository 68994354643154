import React from "react";
import PropTypes from "prop-types";
import Joi from "joi-browser";

import propTypes from "../../../constants/propTypes";
import {
  Title,
  Page,
  Instructions,
  AuthFormWrapper,
  AuthFormContainer,
  Container,
} from "../Login/components";
import inputs from "../../inputs/inputConfigs/login";
import { FormikForm, FormGrid } from "../../forms";
import ActionButtons from "../../forms/ActionButtons";

const createForgotPasswordFields = () => ({
  groups: [
    {
      items: [{ input: inputs.email }],
    },
  ],
});

const ForgotPasswordPage = ({
  handleSendPasswordResetEmail,
  handleCancel,
  defaultEmail,
  resetPassword,
  loading,
}) => {
  const ACTION = resetPassword ? "RESET" : "CREATE";

  return (
    <Page>
      <Container>
        <Title />
        <FormikForm
          initialValues={{
            email: defaultEmail,
          }}
          onSubmit={handleSendPasswordResetEmail}
          validationSchema={Joi.object({
            email: Joi.string().email({ minDomainAtoms: 2 }),
          })}
          ignoreValidationFieldNames={[
            "name",
            "officeId",
            "userRoleId",
            "phoneNumber",
          ]}
        >
          {({ handleSubmit, values, errors, ...formikProps }) => (
            <AuthFormContainer>
              <AuthFormWrapper>
                <Instructions>
                  {!resetPassword &&
                    "You account doesn't have a password set yet. "}
                  Please provide the email address to which your account is
                  associated.
                </Instructions>

                <FormGrid
                  fields={createForgotPasswordFields()}
                  values={values}
                  errors={errors}
                  {...formikProps}
                />

                <ActionButtons
                  onSend={handleSubmit}
                  sendButtonText={`REQUEST PASSWORD ${ACTION} CODE`}
                  onCancel={() => handleCancel(values.email)}
                  isStacked={true}
                  isModal={true}
                  loading={loading}
                  hideCancelButton={loading}
                />
              </AuthFormWrapper>
            </AuthFormContainer>
          )}
        </FormikForm>
      </Container>
    </Page>
  );
};

ForgotPasswordPage.propTypes = {
  errorPayload: propTypes.errorPayload,
  defaultEmail: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSendPasswordResetEmail: PropTypes.func.isRequired,
};

export default ForgotPasswordPage;
