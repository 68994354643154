import React, { Fragment } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import styled from "styled-components";
import Settings from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import propTypes from "../../../constants/propTypes";

import { PageHeader, TitleArea, AttributeList } from "../../layout/PageHeader";
import { Button } from "../../layout/PageHeader/TitleArea";
import { ActionIcon, StatusWrapper } from "../ProjectDetail/components";
import VendorStatusTag from "../Vendors/VendorStatusTag";

const LocationBlock = styled.div`
  margin-top: 15px;
`;

const Website = styled.p`
  padding-top: 4px;
`;

const navigationTabs = [
  { path: "/projects", label: "Projects" },
  { path: "/contacts", label: "Contacts" },
  { path: "/discounts", label: "Discounts" },
  {
    path: "/remit-addresses",
    label: "Remit Addresses",
  },
  {
    path: "/ship-to-sites",
    label: "Ship to Sites",
  },
];

export const Title = ({ name }) => {
  return <StatusWrapper>{name}</StatusWrapper>;
};
Title.propTypes = {
  name: PropTypes.string,
};

export const Status = ({ status }) => {
  return (
    <div style={{ paddingTop: "4px" }}>
      {status &&
        status.map(status => (
          <VendorStatusTag key={status}>{status}</VendorStatusTag>
        ))}
    </div>
  );
};
Status.propTypes = {
  status: PropTypes.arrayOf(PropTypes.string),
};

const VendorDetailPage = ({
  vendor,
  category,
  parentVendor,
  onEditVendor,
  route,
}) => {
  const { name, status, location = {}, website } = vendor;
  const completeAddress = `${location.address} ${location.address2 || ""} ${
    location.city
  }, ${location.state} ${location.zip} ${location.country}`;

  return (
    <Fragment>
      <PageHeader navigationTabs={navigationTabs}>
        <TitleArea
          title={<Title name={name} />}
          info={<Status status={status} />}
        >
          <Tooltip title="Edit">
            <Button onClick={onEditVendor}>
              <ActionIcon Icon={Settings} />
            </Button>
          </Tooltip>
        </TitleArea>
        <div>
          <AttributeList
            attributes={[
              { key: "Parent", value: parentVendor.name },
              { key: "Category", value: category.name },
            ]}
          />
        </div>
        <LocationBlock>
          <p>{completeAddress}</p>
          {website && <Website>{website}</Website>}
        </LocationBlock>
      </PageHeader>

      {renderRoutes(route.routers, { vendor })}
    </Fragment>
  );
};

VendorDetailPage.propTypes = {
  vendor: PropTypes.object,
  category: PropTypes.object,
  parentVendor: PropTypes.object,
  onEditVendor: PropTypes.func.isRequired,
  onDeleteVendor: PropTypes.func.isRequired,
  route: propTypes.route,
};

VendorDetailPage.defaultProps = {
  vendor: { location: {} },
  category: {},
  parentVendor: {},
};

export default VendorDetailPage;
