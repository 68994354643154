import { connect } from "react-redux";
import { downloadQuoteFiles } from "../../../../../../actions/filesActions";

import { DownloadQuoteFiles } from "./DownloadQuoteFiles";

export const mapStateToProps = (state, { bidGroupVendor: { id } }) => {
  return { loading: state.files.loaders[id] };
};

const mapDispatchToProps = {
  downloadQuoteFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(DownloadQuoteFiles);
