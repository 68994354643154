import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
const _groupBy = require("lodash/groupBy");

import TagText from "./TagText";
import { BWIntent } from "../BWIntent";

const StyledTagText = styled(TagText)`
  margin-right: 4px;
  align-self: center;
`;

const TagScope = ({
  workScopes = [],
  projectSubWorkScopes = [],
  isPublicSubScopeNotes,
}) => {
  const groupedProjectSubWorkScopes = _groupBy(
    projectSubWorkScopes,
    "workScopeSubWorkScope.workScope.name"
  );
  return workScopes.map(({ name }, i) => {
    const projectSubWorkScopes = groupedProjectSubWorkScopes[name] || [];
    return (
      <StyledTagText key={i} intent={BWIntent.PRIMARY}>
        {name}
        {projectSubWorkScopes.length ? ":" : ""}{" "}
        {projectSubWorkScopes
          .map(
            ({
              workScopeSubWorkScope: {
                subWorkScope: { name },
              },
              description,
            }) =>
              `${name}${
                isPublicSubScopeNotes && description ? ` - ${description}` : ""
              }`
          )
          .join(", ")}
      </StyledTagText>
    );
  });
};

TagScope.propTypes = {
  projectSubWorkScopes: PropTypes.array,
  isPublicSubScopeNotes: PropTypes.bool,
};

export default TagScope;
