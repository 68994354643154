import React, { useEffect, useMemo } from "react";
import shallow from "zustand/shallow";

import useAddSpecToPOStore from "../../../../../hooks/useAddSpecToPOStore";
import useActions from "../../../../../hooks/useActions";
import {
  closeModalDialog,
  showSnackNotificationAction,
  setModalDialogTitle,
} from "../../../../../../actions/layoutActions";
import {
  initDataComponent,
  performCreateRequest,
  performRetrieveListRequest,
  setReload,
  destroyDataComponent,
  destroyDataComponentResource,
} from "../../../../../../actions/dataComponentActions";
import {
  createQueueProcess,
  initQueueProcess,
} from "../../../../../../actions/queuesActions";
import {
  fetchUnitOfMeasures,
  fetchVendors,
  importSpecs,
  uploadSpecsFromXls,
} from "../../../../../../actions/specsActions";
import { fetchProjectCurrencies } from "../../../../../../actions/currencyActions";
import { fetchAreasByProject } from "../../../../../../actions/areasActions";
import ImportSpecContainer from "./ImportSpecContainer";
import { GridWrapper } from "../components";
import {
  specsGridDataComponentId,
  specShipmentsDataComponentId,
} from "../../../../../../actions/purchaseOrderSpecsActions";
import _get from "lodash/get";
import { fetchPurchaseOrder } from "../../../../../../actions/purchaseOrdersActions";

const specToPOStoreSelector = state => [state.purchaseOrder, state.actions];

const CopySpec = () => {
  const [purchaseOrder, stateActions] = useAddSpecToPOStore(
    specToPOStoreSelector,
    shallow
  );

  const boundActions = useActions({
    closeModalDialog,
    initDataComponent,
    performCreateRequest,
    performRetrieveListRequest,
    showSnackNotificationAction,
    createQueueProcess,
    initQueueProcess,
    importSpecs,
    fetchPurchaseOrder,
    uploadSpecsFromXls,
    fetchAreasByProject,
    setReload,
    setModalDialogTitle,
    fetchProjectCurrencies,
    destroyDataComponent,
    destroyDataComponentResource,
    fetchUnitOfMeasures,
    fetchVendors,
  });

  const actions = useMemo(
    () => ({
      ...boundActions,
      closeModalDialog: () => {
        boundActions.setReload(specsGridDataComponentId, true);
        boundActions.setReload(specShipmentsDataComponentId, true);
        boundActions.fetchPurchaseOrder(
          _get(purchaseOrder, "id"),
          _get(purchaseOrder, "project.id")
        );
        boundActions.showSnackNotificationAction(
          "The selected Specs has been imported successfully"
        );
        boundActions.closeModalDialog();
      },
      openImportSpecOptionsModal: stateActions.openMainOptions,
    }),
    [boundActions, purchaseOrder, stateActions.openMainOptions]
  );

  useEffect(() => {
    actions.setModalDialogTitle([
      "Copy a spec from another project",
      `PO #${purchaseOrder.number} ${_get(purchaseOrder, "vendor.name")}`,
    ]);
  }, [actions, purchaseOrder]);

  const revisionIsActive = !!purchaseOrder.revisionStatus;

  return (
    <GridWrapper>
      <ImportSpecContainer
        actions={actions}
        isFromProjects
        isImportToPO={true}
        revisionIsActive={revisionIsActive}
        poProjectCurrencyId={purchaseOrder.projectCurrencyId}
        step="selection"
        project={purchaseOrder.project}
        defaultValues={{
          purchaseOrderId: purchaseOrder.id,
          vendorId: purchaseOrder.vendorId,
        }}
      />
    </GridWrapper>
  );
};

export default CopySpec;
