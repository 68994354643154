import React, { Component } from "react";
import { FilteringState } from "@devexpress/dx-react-grid";
import memoizeOne from "memoize-one";
import { parse, stringify } from "query-string";
import { withRouter } from "react-router-dom";

import _get from "lodash/get";
import _noop from "lodash/noop";
import _isEqual from "lodash/isEqual";
import _snakeCase from "lodash/snakeCase";

import propTypes from "../../../../constants/propTypes";
import { operationBuildersAPI } from "../filterFunctions";

const getFilterLabel = id => `${_snakeCase(id)}_filters`;

const parseInitialFilters = ({ history, id }) => {
  const queryString = parse(history.location.search)[getFilterLabel(id)];
  return JSON.parse(queryString || "[]");
};

const defaultOperators = {
  text: "ilike",
  multiselect: "in",
  select: "equal",
  picker: "between",
  integerContains: "integerContains",
};

const updateQueryStringFilters = ({
  setQueryStringFilters,
  omitSetPage,
  filters,
  id,
}) => {
  if (!omitSetPage) {
    setQueryStringFilters(id, filters);
  }
};

export const flushFilterChange = (debouncedFn, shouldFlush) => {
  if (debouncedFn?.flush && shouldFlush) debouncedFn.flush();
};

class BWGridFilterState extends Component {
  state = {
    filters: [],
  };

  loadQueryStringFilters() {
    const {
      onFiltersChange,
      columnOptions,
      operationBuilders,
      history,
      id,
    } = this.props;

    const queryStringFilters = parseInitialFilters({ history, id });

    if (!_isEqual(queryStringFilters, this.state.filters)) {
      this.handleFiltersChange(
        columnOptions,
        queryStringFilters.length > 0 ? onFiltersChange : _noop,
        operationBuilders
      )(queryStringFilters, true, true);
    }
  }

  componentDidMount() {
    this.loadQueryStringFilters();
  }

  componentDidUpdate() {
    const { ignoreFilterQueryParams } = this.props;
    if (!ignoreFilterQueryParams) {
      this.loadQueryStringFilters();
    }
  }

  setQueryStringFilters = (id, filters) => {
    const { history, ignoreFilterQueryParams } = this.props;

    if (ignoreFilterQueryParams) {
      return;
    }

    const setQueryParams = (label, value) => {
      const currentSearch = parse(history.location.search);
      delete currentSearch[label];
      const searchString = stringify(Object.assign({}, currentSearch, value));
      history.replace({ search: searchString });
    };

    setQueryParams(id, {
      [getFilterLabel(id)]: JSON.stringify(filters),
    });
  };

  handleFiltersChange = memoizeOne(
    (columnOptions, onFiltersChange, operationBuilders) => (
      filters,
      omitSetPage,
      isLoadQueryStringFilters
    ) => {
      // Check if any filter was removed from the filters list in the last change
      // and what filters where removed
      const previousFilters = this.state.filters;
      const fieldsPresentInFilters = filters.map(filter => filter.columnName);
      const removeFilters = previousFilters.filter(
        filter => !fieldsPresentInFilters.includes(filter.columnName)
      );

      // Build a where object using the operation indicating in the columnOptions prop
      // If you set filter: true in a column it will assume that the default query operator is ILIKE
      // If you set filter: ()=>{...somethings} it will use that function to build the query
      // If you set filter: "operator" it find and use one of the predefined operators availables.
      const where = filters.reduce((where, filter) => {
        const options = columnOptions[filter.columnName];
        const filterType = options.filter === true ? "text" : options.filter;

        const filteringOperator = _get(
          options,
          "filterOptions.operator",
          defaultOperators[filterType]
        );

        const filteringFn =
          typeof filteringOperator === "function"
            ? filteringOperator
            : operationBuilders[filteringOperator];

        return {
          ...where,
          ...filteringFn(filter),
        };
      }, {});

      this.setState({ filters });

      updateQueryStringFilters({
        setQueryStringFilters: this.setQueryStringFilters,
        omitSetPage,
        filters,
        id: this.props.id,
      });

      // before sent the generated query it sets the removed filters to undefined
      // so then the listener will take those filters into account
      const debouncedFn = onFiltersChange(
        removeFilters.reduce(
          (where, filter) => {
            delete where[filter.columnName];
            return where;
          },
          { ...where }
        ),
        [...filters],
        omitSetPage
      );
      flushFilterChange(debouncedFn, isLoadQueryStringFilters);
    },
    _isEqual
  );

  render() {
    const {
      onFiltersChange,
      columnOptions,
      displayFilters,
      operationBuilders,
    } = this.props;
    return displayFilters ? (
      <FilteringState
        filters={this.state.filters}
        onFiltersChange={this.handleFiltersChange(
          columnOptions,
          onFiltersChange,
          operationBuilders
        )}
      />
    ) : null;
  }
}

BWGridFilterState.defaultProps = { operationBuilders: operationBuildersAPI };

BWGridFilterState.propTypes = propTypes.filteringComponent;

export default withRouter(BWGridFilterState);
