import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isEmpty } from "lodash";

import inputs from "../../inputs/inputConfigs/login";
import FormGrid from "../../forms/FormGrid";
import ActionButtons from "../../forms/ActionButtons";
import { BWLink } from "../../ui/BWLink";
import { Instructions } from "./components";
import { LoginContext } from "./LoginContainer";

const createCredentialFields = emailVerified => ({
  groups: [
    {
      items: [
        emailVerified
          ? { input: inputs.password, grid: { xs: 12 } }
          : { input: { ...inputs.email, autoFocus: true }, grid: { xs: 12 } },
      ],
    },
  ],
});
const ActionButtonsWrapper = styled.div`
  margin-top: 13px;
  & > div {
    width: unset;
  }

  & > div:first-child {
    justify-content: center;
  }

  & > div:first-child > button {
    width: 100%;
  }
`;

export const CredentialsCard = ({ formikProps }) => {
  const {
    emailVerified,
    loading,
    handleForgotPassword,
    handleBack,
  } = useContext(LoginContext);
  const { values, errors, handleSubmit } = formikProps;
  const { email, password } = values;

  const { instructions, ButtonComponent } = emailVerified
    ? credentialSettings.password
    : credentialSettings.email;

  return (
    <Fragment>
      <Instructions>{instructions}</Instructions>
      <FormGrid
        fields={createCredentialFields(emailVerified)}
        values={values}
        errors={errors}
        {...formikProps}
      />
      <ActionButtonsWrapper>
        <ButtonComponent
          handleSubmit={handleSubmit}
          handleForgotPassword={handleForgotPassword}
          handleBack={handleBack}
          loading={loading}
          email={email}
          password={password}
        />
      </ActionButtonsWrapper>
    </Fragment>
  );
};

const EmailButton = ({ handleSubmit, email, loading }) => (
  <ActionButtons
    onSend={handleSubmit}
    disabled={isEmpty(email)}
    sendButtonText="Continue"
    hideCancelButton
    loading={loading}
    isModal
    isStacked
  />
);
EmailButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  loading: PropTypes.bool,
};
export const PasswordButton = ({
  handleSubmit,
  handleForgotPassword,
  handleBack,
  email,
  password,
  loading,
}) => (
  <Fragment>
    <ActionButtons
      onSend={handleSubmit}
      disabled={isEmpty(password)}
      sendButtonText="Continue"
      cancelButtonText="Back"
      onCancel={handleBack}
      loading={loading}
      isModal
    />
    <BWLink onClick={() => handleForgotPassword(email)} disabled={loading}>
      RESET PASSWORD
    </BWLink>
  </Fragment>
);

PasswordButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleForgotPassword: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  loading: PropTypes.bool,
};

const credentialSettings = {
  email: {
    instructions:
      "Please enter the email address associated with your account.",
    ButtonComponent: EmailButton,
  },
  password: {
    instructions:
      "Please enter the password currently assigned to your account. If you forgot your password, you can click on the RESET PASSWORD button down below.",
    ButtonComponent: PasswordButton,
  },
};
