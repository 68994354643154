// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Flag model belongsTo relations 1`] = `
Array [
  "Brand",
]
`;

exports[`Flag model hasMany relations 1`] = `
Array [
  "Program",
]
`;
