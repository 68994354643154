import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import { isEmpty } from "lodash";

import { Title } from "./CancelCOIssueSummary";

export const InvoicePayments = ({
  invoicePayments = {},
  changeOrder = false,
}) => {
  const { specPayments, specPaymentsInChangeOrder } = invoicePayments;

  const rows = changeOrder ? specPaymentsInChangeOrder : specPayments;
  const canCancelIssue = isEmpty(invoicePayments) || isEmpty(rows);
  if (canCancelIssue) {
    return (
      <Title>
        Cancelling Purchase Order will remove all Change Orders and revert
        Purchase Order to first Pre-Issue state. These changes are not
        reversible. Are you sure?
      </Title>
    );
  }

  const invoices = groupBy(rows, "invoiceNumber");

  const title = changeOrder
    ? "Change Order Issue cannot be cancelled as there are payments against shipments added in the CO. The following payments need to be cancelled prior to cancelling CO Issue."
    : "Purchase Order Issue cannot be cancelled as it has paid invoices linked to it. The following payments need to be cancelled prior to cancelling PO Issue.";

  return (
    <Fragment>
      <Title>{title}</Title>
      <h3>
        <span style={{ paddingRight: "24px" }}>
          {Object.keys(invoices).length}{" "}
          {pluralize("Payment", Object.keys(invoices).length)}
        </span>
      </h3>
      {Object.keys(invoices).map(invoiceNumber => (
        <Fragment key={invoiceNumber}>
          <h4 style={{ marginTop: "15px" }}>
            <span style={{ paddingRight: "24px" }}>
              Invoice #{invoiceNumber}
            </span>
          </h4>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td style={{ paddingRight: "24px", width: "80%" }}>
                  <b>Spec</b>
                </td>
                <td>
                  <b>Qty.</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {invoices[invoiceNumber].map(
                ({ invoiceNumber, customNumber, quantity }, i) => (
                  <tr key={`${invoiceNumber}-${i}`}>
                    <td>{customNumber}</td>
                    <td>{quantity}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Fragment>
      ))}
    </Fragment>
  );
};
InvoicePayments.propTypes = {
  invoicePayments: PropTypes.object,
  changeOrder: PropTypes.bool,
};
