import { push, replace } from "connected-react-router";
import queryString from "query-string";
import pick from "lodash.pick";

export const pushWithReturnUrl = (url, sectionName, props = {}) => {
  const returnUrl = queryString.stringify({
    returnUrl: `${window.location.pathname}${window.location.search}`,
    sectionName: sectionName,
    ...props,
  });
  return push(`${url}?${returnUrl}`);
};

export const replaceKeepingSearch = (url, state, queryProps = {}) => {
  const returnUrl = queryString.stringify({
    ...pick(queryString.parse(window.location.search), [
      "returnUrl",
      "sectionName",
    ]),
    ...queryProps,
  });
  return replace(`${url}?${returnUrl}`, state);
};
